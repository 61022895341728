import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { tourviewdetail } from "../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../services/schoolhead";
import { Reftourdetail } from "../../../../services/referee";
import { tourdetails } from "../../../../services/register";
import { useNavigate, useParams } from "react-router-dom";
import {
  admininterschoolagecatcreate,
  interschoolagecatcreate,
  interschoolagecatlist,
  interschoolagecatpayment,
  interschooltourpaidornot,
} from "../../../../services/interschool";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Swal from "sweetalert2";
import moment from "moment";

export const InterschoolPayment = () => {
  const { id, tid } = useParams();
  const [agecatlist, setAgeCat] = useState([]);
  const [tname, setTName] = useState("");
  const [tfees, setTFees] = useState("0");
  const [ttype, setTType] = useState("");
  const [tdate, setTDate] = useState("");
  const [meettype, setMeetType] = useState("");
  const [tfree, setTFree] = useState("");
  const [disper, setDisPer] = useState(0);
  const [disamt, setDisAmt] = useState(0);

  const [selcat, setSelCat] = useState([]);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [tteam, setTTeam] = useState(0);
  const [finalamt, setFinalAmt] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const [schoolid, setSchoolID] = useState("");

  const [loading, setLoading] = useState(false);

  const handleClick = (id, check) => {
    for (let i = 0; i < agecatlist.length; i++) {
      if (agecatlist[i].category_id === id) {
        agecatlist[i].selected = !check;
        if (check === false) {
          agecatlist[i].team = "";
        }
      }
      setAgeCat([...agecatlist]);
    }
  };

  const handleTeam = (tm, index) => {
    const regex = /^[0-9\b]+$/;
    if (tm === "" || regex.test(tm)) {
      for (let i = 0; i < agecatlist.length; i++) {
        if (agecatlist[i].category_id === index) {
          agecatlist[i].team = tm;
        }
        setAgeCat([...agecatlist]);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((res) => {
        if (res.status === true && res.data.tournament !== null) {
          setTName(res.data.tournament.meet_name);
          setTFees(res.data.tournament.registration_fees);
          setTType(res.data.tournament.agecat_or_std);
          setTFree(res.data.tournament.free_tournament);
          setMeetType(res.data.tournament.meet_type);
          setTDate(res.data.tournament.entry_deadline_date);
          if (res.data.mappedschools.length !== 0) {
            const mdata = res.data.mappedschools.find((item) => {
              return item.school_id === parseInt(tid);
            });
            setDisPer(mdata.discount);
          }
          if (res.data.tournament.meet_type === "Inter School Tournament") {
            setSchoolID(tid);

            const temp = new FormData();
            temp.append("tournament_meet_id", id);
            temp.append("school_id", tid);
            interschoolagecatlist(temp).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  let arr = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr.push({
                      category_id: response.data[i].category_id,
                      category_name: response.data[i].category_name,
                      category_gender: response.data[i].category_gender,
                      class_or_age_category_name:
                        response.data[i].class_or_age_category_name,
                      selected: false,
                      team: "",
                    });
                  }
                  setAgeCat(arr);
                }
              }
            });

            const temp1 = new FormData();
            temp1.append("tournament_meet_id", id);
            temp1.append("school_id", tid);

            interschooltourpaidornot(temp1).then((response) => {
              if (response.status === true) {
                if (response.data !== null) {
                  localStorage.setItem("Age", "2");
                  navigate("/superadmin/intercategorylist/" + id + "/" + tid);
                }
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTFees(response.data.tournament.registration_fees);
          setTType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setMeetType(response.data.tournament.meet_type);
          setTDate(response.data.tournament.entry_deadline_date);

          if (
            response.data.tournament.meet_type === "Inter School Tournament"
          ) {
            schprofileview(id).then((res) => {
              if (res.status === true) {
                setSchoolID(res.data.school_id);
                if (response.data.mappedschools.length !== 0) {
                  const mdata = response.data.mappedschools.find((item) => {
                    return item.school_id === res.data.school_id;
                  });
                  setDisPer(mdata.discount);
                }
                const temp = new FormData();
                temp.append("tournament_meet_id", id);
                temp.append("school_id", res.data.school_id);
                interschoolagecatlist(temp).then((response) => {
                  if (response.status === true) {
                    if (response.data.length !== 0) {
                      let arr = [];
                      for (let i = 0; i < response.data.length; i++) {
                        arr.push({
                          category_id: response.data[i].category_id,
                          category_name: response.data[i].category_name,
                          category_gender: response.data[i].category_gender,
                          class_or_age_category_name:
                            response.data[i].class_or_age_category_name,
                          selected: false,
                          team: "",
                        });
                      }
                      setAgeCat(arr);
                    }
                  }
                });

                const temp1 = new FormData();
                temp1.append("tournament_meet_id", id);
                temp1.append("school_id", res.data.school_id);

                interschooltourpaidornot(temp1).then((response) => {
                  if (response.status === true) {
                    if (response.data !== null) {
                      localStorage.setItem("Age", "2");
                      navigate("/schoolhead/intercategorylist/" + id);
                    }
                  }
                });
              }
            });
          }
        }
      });
    }
  }, []);

  function backlist() {
    if(localStorage.getItem("Role") === "1"){
      dotracking(
        "back button in agecategory payment for " +
          tname +
          "(" +
          id +
          ") tournament $$ " +
          trackobj.tourschoollistheading
      );
      navigate("/superadmin/tournamentschoollist/"+id);

    }else if(localStorage.getItem("Role") === "4"){
      if (localStorage.getItem("Age") === "1") {
        dotracking(
          "back button in agecategory payment for " +
            tname +
            "(" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else {
        dotracking(
          "back button in agecategory payment for " +
            tname +
            "(" +
            id +
            ") tournament $$ " +
            trackobj.dashheading
        );
        navigate("/schoolhead/dashboard");
      }
    }
   
  }

  function agecatpaynow() {
    setErrorMessage("");
    setSelCat([]);
    setTotal(0);
    setDisAmt(0);
    setTTeam(0);
    setFinalAmt(0);
    let fdata = [];
    let tamt = 0;
    fdata = agecatlist.filter((item) => item.selected === true);
    for (let i = 0; i < fdata.length; i++) {
      tamt = tamt + parseInt(fdata[i].team);
    }
    var ageamt = 0;
    var agedisamt = 0;
    ageamt = tamt * tfees;
    agedisamt = (ageamt * disper) / 100;
    setTTeam(tamt);
    setTotal(ageamt);
    setDisAmt(agedisamt);
    setFinalAmt(ageamt - agedisamt);
    if (fdata.length !== 0) {
      let steam = fdata.find((item) => item.team === "");
      if (steam === undefined) {
        steam = "";
      }
      if (steam === "") {
        setSelCat(fdata);
        setShow(true);
      } else {
        setErrorMessage(
          "Please Enter Number of Team for " + steam.category_name
        );
      }
    } else {
      setErrorMessage("Please select atleast one category");
    }
  }

  function contpayment() {
    var catid = "";
    var cname = "";
    var caid = "";
    const fdata = agecatlist.filter((item) => item.selected === true);
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    temp.append("total_amount", finalamt);
    temp.append("over_all_amount", total);
    temp.append("discount_amount", disamt);
    temp.append("school_id", schoolid);
    temp.append("school_head_id", localStorage.getItem("UID"));
    temp.append("category_count", fdata.length);
    if (fdata.length !== 0) {
      for (let i = 0; i < fdata.length; i++) {
        if (cname === "") {
          cname = fdata[i].category_name + " with " + fdata[i].team + " Team";
        } else {
          cname =
            cname +
            " , " +
            fdata[i].category_name +
            " with " +
            fdata[i].team +
            " Team";
        }
        if (caid === "") {
          caid = fdata[i].category_id;
        } else {
          caid = caid + " , " + fdata[i].category_id;
        }
        temp.append("category_id_" + [i + 1], fdata[i].category_id);
        temp.append("team_count_" + [i + 1], fdata[i].team);
        let mamt = 0;
        mamt = parseInt(fdata[i].team) * tfees;
        temp.append("team_amount_" + [i + 1], mamt);
      }
      catid = cname + " (" + caid + ")";
    }
    setLoading(true);

    dotracking(
      "paynow button in agecategory payment for " +
        catid +
        " in " +
        tname +
        "(" +
        id +
        ") tournament $$ " +
        trackobj.touragecatpaymentheading
    );
    if (localStorage.getItem("Role") === "1") {
      admininterschoolagecatcreate(temp).then((response) => {
        if (response.status === true) {
          setShow(false);
          setLoading(false);
          dotracking(
            "payment success in agecategory payment for " +
              tname +
              "(" +
              id +
              ") tournament $$ " +
              trackobj.categorylistheading
          );
          navigate("/superadmin/intercategorylist/" + id + "/" + tid);
        }
      });
    } else {
      interschoolagecatcreate(temp).then((response) => {
        if (response.status === true) {
          setShow(false);
          setLoading(false);
          if (tfree === "N") {
            paynow(
              response.data.RAZORPAY_KEY,
              response.data.order_id,
              response.data.entries.id
            );
          } else if (tfree === "Y") {
            dotracking(
              "payment success in agecategory payment for " +
                tname +
                "(" +
                id +
                ") tournament $$ " +
                trackobj.categorylistheading
            );
            navigate("/schoolhead/intercategorylist/" + id);
          }
        }
      });
    }
  }
  const navigate = useNavigate();
  async function paynow(pkey, ordid, pid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Schoolhead Payment",
      image: require("../../../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("school_id", schoolid);
        temp.append("school_head_id", localStorage.getItem("UID"));
        temp.append("paid_id", pid);
        temp.append("tournament_meet_id", id);
        setLoading(true);
        interschoolagecatpayment(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);

            Swal.fire({
              html:
                "<h4> You have successfully paid for the tournament - <span class='text-success'> " +
                tname +
                ". </span> </h4><h4>Now you can create your teams for all the categories and you can add the players for each teams.</h4>",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#4ecc48",
              cancelButtonColor: "#757575",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                dotracking(
                  "payment success in agecategory payment for " +
                    tname +
                    "(" +
                    id +
                    ") tournament $$ " +
                    trackobj.categorylistheading
                );
                if (localStorage.getItem("Role") === "1") {
                  navigate(
                    "/superadmin/intercategorylist/" + id + "/" + schoolid
                  );
                } else {
                  navigate("/schoolhead/intercategorylist/" + id);
                }
              }
            });
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Agecategory Payment</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Agecategory Payment
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {meettype !== "Inter School Tournament" && meettype !== "" && (
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  <div className="text-left">
                    <h3>{tname}</h3>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="text-right">
                    <a className="btn btn-gray me-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-0">
            <div className="card-body">
              <h4 className="errortext tx-20">
                {tname} is an {meettype}
              </h4>
            </div>
          </div>
        </div>
      )}

      {meettype === "Inter School Tournament" &&
        meettype !== "" &&
        moment(new Date()).format("yyyy-MM-DD") > tdate && (
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-10">
                    <div className="text-left">
                      <h3>{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="text-right">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-0">
              <div className="card-body">
                <h4 className="errortext tx-20">Registeration is Closed</h4>
              </div>
            </div>
          </div>
        )}

      {!loading &&
        meettype === "Inter School Tournament" &&
        moment(new Date()).format("yyyy-MM-DD") <= tdate && (
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="text-left">
                  <h3>{tname}</h3>
                </div>
              </div>
            </div>

            <div className="card mt-0">
              {agecatlist.length === 0 && (
                <div className="card-body">
                  <h4 className="errortext">Category will be updated soon</h4>
                </div>
              )}
              {agecatlist.length !== 0 && (
                <div className="card-body">
                  {tfree === "N" && (
                    <>
                      {localStorage.getItem("Role") !== "1" && (
                        <h4 className="text-success">
                          Please select and pay for the category that you
                          required to create your teams
                        </h4>
                      )}
                      {localStorage.getItem("Role") === "1" && (
                        <h4 className="text-success">
                          Please select and submit the category that you
                          required to create your teams
                        </h4>
                      )}
                      <h4>
                        <span className="text-danger">
                          {" "}
                          Amount : {tfees} /- For each category
                        </span>

                        {disper !== 0 && (
                          <span className="text-secondary">
                            {" "}
                            ( Discount - {disper} % )
                          </span>
                        )}
                      </h4>
                    </>
                  )}
                  {tfree === "Y" && (
                    <>
                      <h4 className="text-success">
                        Please select and submit the category that you required
                        to create your teams
                      </h4>
                      <h4 className="text-danger">Amount : Free</h4>
                    </>
                  )}

                  <div className="row mt-6">
                    {agecatlist.map((item, i) => {
                      return (
                        <div className="col-md-3 mt-6" key={i}>
                          {item.selected && (
                            <>
                              <input
                                checked
                                className="radiobutton"
                                type="radio"
                                value={item.category_id}
                              />
                              <label
                                className="radiobutton-label"
                                htmlFor="radio3"
                              >
                                {item.category_name}
                                <span>{item.category_gender}</span>
                                {ttype == 1 && (
                                  <span className="tx-16">
                                    Class : {item.class_or_age_category_name}
                                  </span>
                                )}
                                {ttype == 2 && (
                                  <span className="tx-16">
                                    Age Category :{" "}
                                    {item.class_or_age_category_name}
                                  </span>
                                )}
                                <a
                                  className="btn btn-export"
                                  onClick={() =>
                                    handleClick(item.category_id, item.selected)
                                  }
                                >
                                  Click Here To Un Select
                                </a>
                              </label>
                              <input
                                type="text"
                                maxLength={3}
                                id={i}
                                className="formtxt-control"
                                placeholder="Enter Number of teams"
                                value={item.team}
                                onChange={(e) =>
                                  handleTeam(e.target.value, item.category_id)
                                }
                              />
                            </>
                          )}
                          {!item.selected && (
                            <>
                              <input
                                className="radiobutton"
                                type="radio"
                                value={item.category_id}
                              />
                              <label
                                className="radiobutton-label"
                                htmlFor="radio3"
                              >
                                {item.category_name}
                                <span>{item.category_gender}</span>
                                {ttype == 1 && (
                                  <span className="text-success tx-16">
                                    Class : {item.class_or_age_category_name}
                                  </span>
                                )}
                                {ttype == 2 && (
                                  <span className="text-success tx-16">
                                    Age Category :{" "}
                                    {item.class_or_age_category_name}
                                  </span>
                                )}
                                <a
                                  className="btn btn-export"
                                  onClick={() =>
                                    handleClick(item.category_id, item.selected)
                                  }
                                >
                                  Click Here To Select
                                </a>
                              </label>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {errorMessage !== "" && (
                    <div className="text-center mt-2">
                      <span className="errortext tx-20">{errorMessage}</span>
                    </div>
                  )}
                  <hr />
                  <div className="text-right">
                    {tfree === "N" && localStorage.getItem("Role") !== "1" && (
                      <a
                        className="btn btn-success me-2"
                        onClick={agecatpaynow}
                      >
                        Submit & Pay
                      </a>
                    )}
                    {tfree === "N" && localStorage.getItem("Role") === "1" && (
                      <a
                        className="btn btn-success me-2"
                        onClick={agecatpaynow}
                      >
                        Submit Entries
                      </a>
                    )}
                    {tfree === "Y" && (
                      <a
                        className="btn btn-success me-2"
                        onClick={agecatpaynow}
                      >
                        Submit
                      </a>
                    )}

                    <a className="btn btn-gray me-2" onClick={backlist}>
                      Back
                    </a>
                  </div>
                </div>
              )}
            </div>

            <Modal show={show} size="payment">
              <Modal.Body className="mod-body">
                <h4>Selected Categories</h4>
                <div className="table-responsive">
                  <table className="sp-league-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th className="text-center">SNo</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">Team Count</th>
                        <th className="text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selcat.map((item, j) => {
                        return (
                          <>
                            <tr className="sp-row-no-0 even" role="row" key={j}>
                              <td className="data-pct">{j + 1}</td>
                              <td className="data-pct">{item.category_name}</td>
                              <td className="data-pct">{item.team}</td>
                              {tfree === "N" && (
                                <td className="data-pct">
                                  {item.team * tfees}
                                </td>
                              )}
                              {tfree === "Y" && (
                                <td className="data-pct">Free</td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                      {tfree === "N" && (
                        <>
                          {disper !== 0 && (
                            <>
                              <tr className="sp-row-no-0 even tx-20" role="row">
                                <td class="data-pct table-info"></td>
                                <td class="data-pct table-info">Sub Total</td>
                                <td class="data-pct table-info">{tteam}</td>
                                <td class="data-pct table-info">{total}</td>
                              </tr>
                              <tr className="sp-row-no-0 even" role="row">
                                <td class="data-pct table-danger"></td>
                                <td class="data-pct table-danger">Discount</td>
                                <td class="data-pct table-danger">
                                  {disper} %
                                </td>
                                <td class="data-pct table-danger">{disamt}</td>
                              </tr>
                            </>
                          )}
                          <tr className="sp-row-no-0 even tx-20" role="row">
                            <td class="table-success"></td>
                            <td class="table-success">Total</td>
                            <td class="table-success"></td>
                            <td class="table-success">{finalamt}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                <br />
                {tfree === "N" && (
                  <h4 className="errortext text-center">
                    Are you sure you want to submit & pay
                  </h4>
                )}
                {tfree === "Y" && (
                  <h4 className="errortext text-center">
                    Are you sure you want to submit
                  </h4>
                )}
                <div className="text-right">
                  {tfree === "N" && localStorage.getItem("Role") !== "1" && (
                    <a className="btn btn-success me-2" onClick={contpayment}>
                      Continue to Payment
                    </a>
                  )}
                  {tfree === "N" && localStorage.getItem("Role") === "1" && (
                    <a className="btn btn-success me-2" onClick={contpayment}>
                      Submit
                    </a>
                  )}
                  {tfree === "Y" && (
                    <a className="btn btn-success me-2" onClick={contpayment}>
                      Submit
                    </a>
                  )}
                  <a
                    onClick={() => setShow(false)}
                    className="btn btn-secondary"
                  >
                    Edit
                  </a>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
    </div>
  );
};
