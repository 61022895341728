import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import { athleticschampionslist } from "../../../../../services/common";
import {
  mysclperformance,
  tourdetails,
} from "../../../../../services/register";
import { Playerprofiledetails } from "../../../../../services/player";
import { coachtourviewdetail } from "../../../../../services/coach";

export const AdminOverallChampionlist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");
  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [bronze, setBronze] = useState(0);
  const [performance, setPerformance] = useState([]);
  const [total, setTotal] = useState(0);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "School",
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gold Medals",
      field: "total_gold",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Silver Medals",
      field: "total_silver",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Bronze Medals",
      field: "total_bronze",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Medals",
      field: "total_medals",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Points",
      field: "total_points",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      // schprofileview().then((response) => {
      //   if (response.status === true) {
      //     const temp1 = new FormData();
      //     temp1.append("tournament_id", id);
      //     temp1.append("school_id", response.data.school_id);
      //     mysclperformance(temp1).then((response) => {
      //       if (response.status === true) {
      //         setGold(response.data.overall_champions_my_school[0].total_gold);
      //         setSilver(
      //           response.data.overall_champions_my_school[0].total_silver
      //         );
      //         setBronze(
      //           response.data.overall_champions_my_school[0].total_bronze
      //         );
      //         setTotal(
      //           parseInt(
      //             response.data.overall_champions_my_school[0].total_gold
      //           ) +
      //             parseInt(
      //               response.data.overall_champions_my_school[0].total_silver
      //             ) +
      //             parseInt(
      //               response.data.overall_champions_my_school[0].total_bronze
      //             )
      //         );
      //         setPerformance(response.data.overall_champions_my_school);
      //       }
      //     });
      //   }
      // });
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      Playerprofiledetails(localStorage.getItem("Name")).then((response) => {
        if (response.status === true) {
          if (response.data.athletelist !== null) {
            const temp1 = new FormData();
            temp1.append("tournament_id", id);
            temp1.append("school_id", response.data.athletelist.school_id);
            mysclperformance(temp1).then((response) => {
              if (response.status === true) {
                setGold(
                  response.data.overall_champions_my_school[0].total_gold
                );
                setSilver(
                  response.data.overall_champions_my_school[0].total_silver
                );
                setBronze(
                  response.data.overall_champions_my_school[0].total_bronze
                );
                setTotal(
                  parseInt(
                    response.data.overall_champions_my_school[0].total_gold
                  ) +
                    parseInt(
                      response.data.overall_champions_my_school[0].total_silver
                    ) +
                    parseInt(
                      response.data.overall_champions_my_school[0].total_bronze
                    )
                );
                setPerformance(response.data.overall_champions_my_school);
              }
            });
          }
        }
      });

      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 
    listathletics();
  }, []);

  function listathletics() {
    setFilterProduct([]);
    const temp = new FormData();
    temp.append("tournament_id", id);
    athleticschampionslist(temp).then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.overall_champions.length !== 0) {
          for (let i = 0; i < response.data.overall_champions.length; i++) {
            arr.push({
              school_name: response.data.overall_champions[i].school_name,
              school_id: response.data.overall_champions[i].school_id,
              total_gold: response.data.overall_champions[i].total_gold,
              total_silver: response.data.overall_champions[i].total_silver,
              total_bronze: response.data.overall_champions[i].total_bronze,
              total_points: response.data.overall_champions[i].total_points,
              total_medals:
                parseInt(response.data.overall_champions[i].total_gold) +
                parseInt(response.data.overall_champions[i].total_silver) +
                parseInt(response.data.overall_champions[i].total_bronze),
            });
          }
          setFilterProduct(arr);
        }
      }
    });
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in overall champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/superadmin/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in overall champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/cityregional/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      if (urllink === "overallchampion") {
        dotracking(
          "back button in overall champions list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        navigate("/schoolhead/tournamentdetails/" + id);
      } else {
        dotracking(
          "back button in overall champions list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.resultheading
        );
        navigate("/schoolhead/athleticsresults/" + id);
      }
    } else if (localStorage.getItem("Role") === "5") {
      dotracking(
        "back button in overall champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/referee/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "6") {
      dotracking(
        "back button in overall champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewtourheading
      );
      if (urllink === "registeredoverallchampion") {
        navigate("/player/registeredviewtournament/" + id);
      } else if (urllink === "eligibleoverallchampion") {
        navigate("/player/eligibleviewtournament/" + id);
      } else {
        navigate("/player/tournamentdetail/" + id);
      }
    }else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "back button in overall champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/coach/athleticsresults/" + id);
    }
  }
  const gridRef = useRef();

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Champions List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item">
              <a>Result List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Champions List
            </li>
          </ol>
        </div>

        {localStorage.getItem("Role") === "6" && (
          <div className="col-md-12">
            <form className="card">
              <div className="card-body">
                <div className="row">
                  <div className="row pb-3">
                    <div className="col-md-10">
                      <div className="text-left">
                        <h3 className="mb-2">{tname}</h3>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="text-right">
                        <a onClick={backlist} className="btn btn-gray">
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <h4 className="mb-2 errortext">MY SCHOOL PERFORMANCE</h4>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/gold1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{gold}</h2>
                                      <span className="teamach">
                                        <strong>Gold Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/silver1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{silver}</h2>
                                      <span className="teamach">
                                        <strong>Silver Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/bronze1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{bronze}</h2>
                                      <span className="teamach">
                                        <strong>Bronze Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <table className="sp-champion-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr>
                        <th className="text-center">Gold Medals</th>
                        <th className="text-center">Silver Medals</th>
                        <th className="text-center">Bronze Medals</th>
                        <th className="text-center">Total Medals</th>
                        <th className="text-center">Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                      {performance.map((item, j) => {
                         const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr className={`sp-row-no-0 ${getRowColor()}`} key={j}>
                              <td className="data-pct">{item.total_gold}</td>
                              <td className="data-pct">
                                {item.total_silver}
                              </td>
                              <td className="data-pct">
                                {item.total_bronze}
                              </td>
                              <td className="data-pct">{total}</td>
                              <td className="data-pct">
                                {item.total_points}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="col-md-12">
          <form className="card">
            <div className="card-body">
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "4" || localStorage.getItem("Role") === "7") && (
                <div className="row pb-3">
                  <div className="col-md-10">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="text-right">
                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
              )}

              <h4>OVERALL CHAMPIONSHIP LIST</h4>
              <div className="table-responsive">
                <table className="sp-league-table sp-data-table">
                  <thead className="sp-table-header">
                    <tr role="row">
                      <th className="text-center">Rank</th>
                      <th className="text-center">School</th>
                      <th className="text-center">Gold Medals</th>
                      <th className="text-center">Silver Medals</th>
                      <th className="text-center">Bronze Medals</th>
                      <th className="text-center">Total Medals</th>
                      <th className="text-center">Total Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterProduct.map((item, j) => {
                      const getRowColor = () => {
                        return j % 2 === 0 ? "even" : "odd";
                      };
                      return (
                        <>
                          <tr
                            className={`sp-row-no-0 ${getRowColor()}`}
                            key={j}
                          >
                            <td className="data-pct">{j + 1}</td>
                            <td className="data-pct">{item.school_name}</td>
                            <td className="data-pct">{item.total_gold}</td>
                            <td className="data-pct">{item.total_silver}</td>
                            <td className="data-pct">{item.total_bronze}</td>
                            <td className="data-pct">{item.total_medals}</td>
                            <td className="data-pct">{item.total_points}</td>
                          </tr>
                        </>
                      );
                    })}
                    {/* {roundsfilterProduct.map((item, j) => {
                    const getRowColor = () => {
                      return j % 2 === 0 ? "even" : "odd";
                    };
                    return (
                      <>
                        <tr
                          className={`sp-row-no-0 ${getRowColor()}`}
                          role="row"
                          key={j}
                        >
                          <td className="data-pct">{item.place}</td>
                          <td className="data-pct">{item.age_category_name}</td>
                          <td className="data-pct">{item.bib_no}</td>
                          <td className="data-pct">{item.event}</td>
                          <td className="data-pct">{item.time_distance}</td>
                          <td className="data-pct">{item.heats_finals}</td>
                        </tr>
                      </>
                    );
                  })} */}
                  </tbody>
                </table>
              </div>
              {/* <table className="table table-bordered table-hover mt-3">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>School</th>
                    <th>Gold Medals</th>
                    <th>Silver Medals</th>
                    <th>Bronze Medals</th>
                    <th>Total Medals</th>
                    <th>Total Points</th>
                  </tr>
                </thead>
                <tbody>
                  {filterProduct.map((item, j) => {
                    return (
                      <>
                        <tr key={j}>
                          <td className="text-center">{j + 1}</td>
                          <td>{item.school_name}</td>
                          <td className="text-center">{item.total_gold}</td>
                          <td className="text-center">{item.total_silver}</td>
                          <td className="text-center">{item.total_bronze}</td>
                          <td className="text-center">{item.total_medals}</td>
                          <td className="text-center">{item.total_points}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table> */}
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
