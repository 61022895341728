import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  perfcriteriacreate,
  perfcriteriaplayerphotocreate,
  perfcriteriasportslist,
  tourcategorybasedplayerlist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import Select from "react-select";
import { toast } from "react-toastify";
import { coachtourviewdetail } from "../../../../../services/coach";

export const PerformancePlayerPhotoAdd = () => {
  const navigate = useNavigate();
  const { id, eid } = useParams();
  const [tname, setTName] = useState("");
  const [catname, setCatname] = useState("");
  const [sportid, setSportID] = useState("");
  const [crlist, setCRList] = useState([]);

  const [criteriaid, setCriteriaID] = useState("");
  const [plist, setPList] = useState([]);

  const [playerid, setPlayerID] = useState("");
  const [plalist, setPlaList] = useState("");

  const [errorMsg, setErrorMsg] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [photolist, setPhotoLink] = useState([]);

  useEffect(() => {
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    }
  }, []);

  function listall(sid) {
    perfcriteriasportslist(sid).then((response) => {
      if (response.status === true) {
        setCRList(response.data);
      }
    });

    tourcategorybasedplayerlist(id, eid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          setPlaList(response.data);
          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].user_id,
              label:
                response.data[i].first_name +
                " (" +
                response.data[i].username +
                ") - " +
                response.data[i].class +
                " - " +
                response.data[i].section +
                " - " +
                response.data[i].team_name,
            });
          }
          setPList(arr);
        }
      }
    });
  }
  function backlist() {
    dotracking(
      "back button in add performance player photo for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/performancelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/performancelist/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/performancelist/" + id + "/" + eid);
    }
  }

  function handlePlayer(e) {
    if (e !== null) {
      setPlayerID(e);
    } else {
      setPlayerID("");
    }
  }

  function photofile(event) {
    let arr = [];
    let arr1 = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length !== 0) {
        if (event.target.files[i].name.match(/\.(jpg|jpeg|png)$/i)) {
          var size = event.target.files[i].size / 1024 / 1024;
          if (size > 50) {
            arr1.push(
              event.target.files[i].name + " size should be less than 50MB"
            );
            setErrorMsg([...arr1]);
          } else {
            arr.push(event.target.files[i]);
          }
        } else {
          arr1.push(
            event.target.files[i].name + " should be jpg or jpeg or png format"
          );
        }
      }
    }
    setPhoto(arr);
    setPhoto([...photo, ...arr]);
    setErrorMsg(arr1);
    setErrorMsg([...errorMsg, ...arr1]);
  }

  function addperformance(e) {
    e.preventDefault();
    if (criteriaid !== "") {
      if (playerid !== "" || photo.length !== 0) {
        var gdata = "";
        var ply = "";
        var plyid = "";

        const tempData = new FormData();
        tempData.append("admin_id", localStorage.getItem("UID"));
        tempData.append("tournament_id", id);
        tempData.append("category_id", eid);
        tempData.append("criteria_id", criteriaid);
        tempData.append("player_count", playerid.length);
        gdata = catname + " (" + eid + ") category in ";
        if (playerid.length !== 0) {
          for (let i = 0; i < playerid.length; i++) {
            tempData.append("player_arr_" + [i + 1], playerid[i].value);
            const fdata = plalist.find((item) => {
              return item.user_id === parseInt(playerid[i].value);
            });
            tempData.append("team_arr_" + [i + 1], fdata.id);

            if (ply === "") {
              ply = playerid[i].label;
            } else {
              ply = ply + ", " + playerid[i].value;
            }

            if (plyid === "") {
              plyid = playerid[i].label;
            } else {
              plyid = plyid + ", " + playerid[i].value;
            }
          }
          gdata = gdata + ply + " (" + plyid + ") ";
        }
        tempData.append("photo_count", photo.length);
        if (photo.length !== 0) {
          for (let i = 0; i < photo.length; i++) {
            tempData.append("image_links_" + [i + 1], photo[i]);
            gdata = gdata + " and " + photo[i] + " in ";
          }
        }
        dotracking(
          "submit button in performance player and photo for " +
            gdata +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.perflistheading
        );
        perfcriteriaplayerphotocreate(tempData).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            if (localStorage.getItem("Role") === "1") {
              navigate("/superadmin/performancelist/" + id + "/" + eid);
            } else if (localStorage.getItem("Role") === "3") {
              navigate("/cityregional/performancelist/" + id + "/" + eid);
            } else if (localStorage.getItem("Role") === "7") {
              navigate("/coach/performancelist/" + id + "/" + eid);
            }
          }
        });
      } else {
        toast.error("Either Select Player or Photo");
      }
    } else {
      toast.error("Please Select Criteria Type");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Performance</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Category</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Performance
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-md-12">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                    <h4 className="text-success tx-20">Category - {catname}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Criteria Type <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={criteriaid}
                      onChange={(e) => setCriteriaID(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Criteria Type ---
                      </option>
                      {crlist.map((data, i) => {
                        return (
                          <option value={data.id} key={i}>
                            {data.criteria_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Players</label>
                    <Select
                      className="dropdown"
                      placeholder="Select Players"
                      value={playerid}
                      options={plist}
                      onChange={handlePlayer}
                      isSearchable={true}
                      isMulti
                      isClearable
                      hidden
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Photos</label>
                    <input
                      type="file"
                      className="form-control"
                      multiple
                      onChange={photofile}
                      accept=".jpg,.jpeg,.png"
                    />
                    {photo.map((data, i) => {
                      return <li key={i}>{data.name}</li>;
                    })}
                  </div>
                </div>

                {errorMsg.map((data, i) => {
                  return (
                    <span className="errortext" key={i}>
                      {data}
                    </span>
                  );
                })}
              </div>
              <div className="text-center mt-2">
                <a onClick={addperformance} className="btn btn-success me-2">
                  Submit
                </a>
                <a onClick={backlist} className="btn btn-gray">
                  <i className="fe fe-corner-up-left"></i> Back
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
