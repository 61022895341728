import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import {
  othereventdelete,
  othereventlist,
} from "../../../../services/superadmin";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { coachothereventlist, coachviewprofile } from "../../../../services/coach";
import { eventslistmanager } from "../../../../services/cityregionaladmin";

export const ListWorkshop = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const columnDefs = [
    {
      headerName: "Actions",
      width: 180,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params.data)}
              title="View Other Event"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>

            {localStorage.getItem("Role") === "1" && (
              <>
                <a
                  onClick={() => playerdet(params.value, params.data)}
                  title="Entry List"
                  className="btn-sm btn-gray me-2"
                >
                  <i className="fas fa-users fa-lg"></i>
                </a>
                <a
                  onClick={() => deleteeve(params.value, params.data)}
                  title="Delete Other Event"
                  className="btn-sm btn-danger me-2"
                >
                  <i className="fa fa-trash fa-lg"></i>
                </a>
                <a
                  onClick={() => sendlink(params.value, params.data)}
                  title="Send Registeration Link"
                  className="btn-sm btn-import me-2"
                >
                  <i className="fa fa-paper-plane fa-lg"></i>
                </a>
              </>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Event Name",
      width: 300,
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Event Type",
      width: 150,
      field: "entryeventname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entryopen",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entrydeadline",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Free Event",
      width: 150,
      field: "free_or_paid_events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 150,
      field: "fees",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const navigate = useNavigate();
  function viewdet(vid, params) {
    dotracking(
      "view icon in other events list for " +
        params.event_name +
        " (" +
        vid +
        ") $$ " +
        trackobj.workdetheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/othereventdetail/" + vid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/othereventdetail/" + vid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/othereventdetail/" + vid);
    }
  }

  function playerdet(tid, tdata) {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "player entry list icon in other event list for " +
          tdata.event_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.tourplayerlistheading
      );
      navigate("/superadmin/eventsentrylist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/eventsentrylist/" + tid);
      // } else if (localStorage.getItem("Role") === "4") {
      //   localStorage.setItem("Page", "1");
      //   navigate("/schoolhead/eventsentrylist/" + tid);
      // } else if (localStorage.getItem("Role") === "5") {
      //   navigate("/referee/eventsentrylist/" + tid);
    }
  }

  function sendlink(sid, tdata) {
    dotracking(
      "send registeration link icon in tournament list for " +
        tdata.event_name +
        " (" +
        sid +
        ") tournament $$ " +
        trackobj.workreglinkheading
    );
    navigate("/superadmin/eventsendregisterlink/" + sid);
  }

  function deleteeve(vid, params) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("Role") === "1") {
          dotracking(
            "delete icon in other events list for " +
              params.event_name +
              " (" +
              vid +
              ") $$ " +
              trackobj.worklistheading
          );
          othereventdelete(vid).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      }
    });
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    if(localStorage.getItem("Role")==="7"){
      coachviewprofile().then((res)=>{
        if(res.status===true){
          coachothereventlist(res.data.school_id).then((response) => {
            if (response.status === true) {
              if (response.data.events.length !== 0) {
                let arr = [];
                for (let i = 0; i < response.data.events.length; i++) {
                  var free = "";
                  if (response.data.events[i].free_or_paid_events === "Y") {
                    free = "Yes";
                  } else if (response.data.events[i].free_or_paid_events === "N") {
                    free = "No";
                  }
                  arr.push({
                    id: response.data.events[i].id,
                    event_name: response.data.events[i].event_name,
                    startdate: response.data.events[i].startdate,
                    enddate: response.data.events[i].enddate,
                    entryopen: response.data.events[i].entryopen,
                    entrydeadline: response.data.events[i].entrydeadline,
                    free_or_paid_events: free,
                    fees: response.data.events[i].fees,
                    entryeventname: response.data.events[i].entryeventname,
                  });
                }
                setFilterProduct(arr);
              }
            }
          });
        }
      })

      
    }else if(localStorage.getItem("Role")==="3"){
      eventslistmanager(localStorage.getItem("UID")).then((response)=>{
        if (response.status === true) {
          if (response.data.events.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.events.length; i++) {
              var free = "";
              if (response.data.events[i].free_or_paid_events === "Y") {
                free = "Yes";
              } else if (response.data.events[i].free_or_paid_events === "N") {
                free = "No";
              }
              arr.push({
                id: response.data.events[i].id,
                event_name: response.data.events[i].event_name,
                startdate: response.data.events[i].startdate,
                enddate: response.data.events[i].enddate,
                entryopen: response.data.events[i].entryopen,
                entrydeadline: response.data.events[i].entrydeadline,
                free_or_paid_events: free,
                fees: response.data.events[i].fees,
                entryeventname: response.data.events[i].entryeventname,
              });
            }
            setFilterProduct(arr);
          }
        }
      })

      
    }else{
      othereventlist().then((response) => {
        if (response.status === true) {
          if (response.data.events.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.events.length; i++) {
              var free = "";
              if (response.data.events[i].free_or_paid_events === "Y") {
                free = "Yes";
              } else if (response.data.events[i].free_or_paid_events === "N") {
                free = "No";
              }
              arr.push({
                id: response.data.events[i].id,
                event_name: response.data.events[i].event_name,
                startdate: response.data.events[i].startdate,
                enddate: response.data.events[i].enddate,
                entryopen: response.data.events[i].entryopen,
                entrydeadline: response.data.events[i].entrydeadline,
                free_or_paid_events: free,
                fees: response.data.events[i].fees,
                entryeventname: response.data.events[i].entryeventname,
              });
            }
            setFilterProduct(arr);
          }
        }
      });
    }
   
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Other Events List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Other Events</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Other Events List
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
