import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Select from "react-select";
import JoditEditor from "jodit-react";
import "../../../../assets/css/cms.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  cmsgallerylist,
  cmsresourcecreate,
  cmsresourcedelete,
  cmsresourcelist,
  cmsselectresource,
  coachcmsresourcelist,
  medalslist,
  photoslist,
  playsessioncreate,
  playsessionupdate,
  playsessionview,
  schooldetails,
  schoolstatementcreate,
  schoolstatementupdate,
  schoolstatementview,
  storycreate,
  storydelete,
  storylist,
  storyupdate,
  storyview,
  toureventlist,
} from "../../../../services/superadmin";
import Swal from "sweetalert2";
import { coachviewprofile } from "../../../../services/coach";
import { toast } from "react-toastify";
import moment from "moment";
import {
  cmstaglist,
  cmstagview,
  schoolpublishtrack,
} from "../../../../services/common";
import WebUrl from "../../../../AWebUrl";
import { schooldetailcityreg } from "../../../../services/cityregionaladmin";
export const CMSDetails = () => {
  const { id } = useParams();
  const [schname, setSchName] = useState("");
  const [schid, setSchId] = useState("");

  const [schcode, setSchCode] = useState("");
  const [schlogo, setSchLogo] = useState("");

  const [spublish, setSPublish] = useState("");
  const [puburl, setPubUrl] = useState("");

  const [tlist, setTList] = useState([]);
  const [elist, setEList] = useState([]);
  const [tpath, setTPath] = useState("");

  const [stlist, setStList] = useState([]);
  const [stpath, setStPath] = useState("");

  const [gllist, setGList] = useState([]);
  const [gtpath, setGtPath] = useState("");

  const [reslist, setResList] = useState([]);
  const [creslist, setCResList] = useState([]);

  const [respath, setResPath] = useState("");

  const [scolor, setSColor] = useState("#fff"); // Default background color
  const [tcolor, setTColor] = useState("#fff"); // Default background color
  const [mcolor, setMColor] = useState("#5f6387"); // Default background color
  const [sscolor, setSSColor] = useState("#fff"); // Default background color
  const [gcolor, setGColor] = useState("#fff"); // Default background color
  const [rcolor, setRColor] = useState("#f6f6f8"); // Default background color

  const colorOptions = [
    { label: "Red", value: "#FF0000" },
    { label: "Blue", value: "#0000FF" },
    { label: "Green", value: "#00FF00" },
    { label: "Yellow", value: "#FFFF00" },
    { label: "Magenta", value: "#800080" },
    { label: "White", value: "#fff" },
    { label: "Jet Gray", value: "#5f6387" },
    { label: "White Smoke", value: "#f6f6f8" },
    { label: "Dark Sky Blue", value: "#3498db" },
    { label: "Mint Green", value: "#2ecc71" },
    { label: "Orangy Red", value: "#e74c3c" },
    { label: "Yellowish Orange", value: "#f39c12" },
    { label: "Purple-Magenta", value: "#9b59b6" },
    { label: "Cyan-Blue", value: "#34495e" },
  ];

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      schooldetails(id).then((response) => {
        if (response.status === true) {
          setSchCode(response.data.details.school_unique_no);
          setSchName(response.data.details.school_name);
          setSPublish(response.data.details.website_publised_status);
          setPubUrl(response.data.details.website_url);
          if (response.data.details.school_logo !== null) {
            setSchLogo(
              response.data.s3_logo + response.data.details.school_logo
            );
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      schooldetailcityreg(id).then((response) => {
        if (response.status === true && response.data !== null) {
          setSchName(response.data.details.school_name);
          setSchCode(response.data.details.school_unique_no);
          setSPublish(response.data.details.website_publised_status);
          setPubUrl(response.data.details.website_url);
          if (response.data.details.school_logo !== null) {
            setSchLogo(
              response.data.s3_logo + response.data.details.school_logo
            );
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((response) => {
        if (response.status === true) {
          setSchCode(response.data.school_unique_no);
          setSchName(response.data.school_name);
          listcoachresource(response.data.school_id, "");
          listall(response.data.school_id);
          setSchId(response.data.school_id);
          liststatement(response.data.school_id);
          listmedal(response.data.school_id);
          liststory(response.data.school_id);
        }
      });
    }
    if (localStorage.getItem("Role") !== "7") {
      setSchId(id);
      listresource(id, "");
      listcoachresource(id, "");
      listall(id);
      liststatement(id);
      listmedal(id);
      liststory(id);
    }
    cmstaglist().then((response) => {
      if (response.status === true) {
        let arr1 = [];
        if (response.data.tags_list.length > 0) {
          for (let i = 0; i < response.data.tags_list.length; i++) {
            arr1.push({
              label: response.data.tags_list[i].tag_name,
              value: response.data.tags_list[i].id,
            });
          }
        }
        setGTagList(arr1);
      }
    });
  }, []);

  function listall(lid) {
    toureventlist(lid).then((response) => {
      if (response.status === true) {
        setTPath(response.data.tournament_logo_path);
        setTList(response.data.tournament_list);
        setEList(response.data.event_list);
        setTCount(response.data.all_tournament_list.length);
      }
    });
    cmsgallerylist(lid).then((response) => {
      if (response.status === true) {
        setGtPath(response.data.img_path);
        setGList(response.data.gallery_list);
      }
    });

    const temp = new FormData();
    temp.append("year", 0);
    temp.append("sports", 0);
    temp.append("school_id", lid);

    medalslist(temp).then((res) => {
      if (res.status === true) {
        let mcnt = 0;
        let tcnt = 0;
        let scnt = 0;
        for (let i = 0; i < res.data.length; i++) {
          if (typeof res.data[i].meet_count === "string") {
            tcnt += parseInt(res.data[i].meet_count);
          } else {
            tcnt += res.data[i].meet_count;
          }

          if (typeof res.data[i].participants === "string") {
            scnt += parseInt(res.data[i].participants);
          } else {
            scnt += res.data[i].participants;
          }

          if (typeof res.data[i].total_achievements === "string") {
            mcnt += parseInt(res.data[i].total_achievements);
          } else {
            mcnt += res.data[i].total_achievements;
          }
        }
        setMCount(mcnt);
        // setTCount(tcnt);
        setSCount(scnt);
      }
    });
  }
  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/resources/logo-pathway.png");
    ev.target.onerror = null;
  }

  function errortour(ev) {
    ev.target.src = require("../../../../assets/images/resources/logo_epl.png");
    ev.target.onerror = null;
  }

  function errorimghandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function liststatement(lid) {
    setSchShow(false);
    schoolstatementview(lid, 1).then((response) => {
      if (response.status === true) {
        if (response.data.school_statement !== null) {
          setSchShow(true);
          setStatId(response.data.school_statement.id);
          setSHead(response.data.school_statement.heading);
          setDesc(response.data.school_statement.description);
          if (response.data.school_statement.fb_url !== null) {
            setSFace(response.data.school_statement.fb_url);
          }
          if (response.data.school_statement.insta_url !== null) {
            setSInsta(response.data.school_statement.insta_url);
          }
          if (response.data.school_statement.youtube_url !== null) {
            setSYoutube(response.data.school_statement.youtube_url);
          }

          if (response.data.school_statement.image_path !== null) {
            setPhotoUrl(
              response.data.logo_path +
                response.data.school_statement.image_path
            );
            setImage(
              response.data.logo_path +
                response.data.school_statement.image_path
            );
          }
        }
      }
    });
  }

  function listmedal(lid) {
    setMedalShow(false);
    playsessionview(lid, 1).then((res) => {
      if (res.status === true) {
        if (res.data.play_sessions !== null) {
          setMedalShow(true);
          setMedalId(res.data.play_sessions.id);
          setPlaySes(res.data.play_sessions.play_sessions);
        }
      }
    });
  }

  function liststory(lid) {
    storylist(lid, 1).then((res) => {
      if (res.status === true) {
        setStPath(res.data.logo_path);
        setStList(res.data.success_stories_list);
      }
    });
  }

  const [stags, setSTags] = useState([]);
  function handlefilter(e) {
    setRTags("");
    if (e != null) {
      setSTags(e);
      let tid = "";
      if (e.length > 0) {
        for (let i = 0; i < e.length; i++) {
          if (tid === "") {
            tid = e[i].value;
          } else {
            tid = tid + "," + e[i].value;
          }
        }
      }

      const lastElement = e.slice(-1)[0];
      cmstagview(lastElement.value).then((response) => {
        if (response.status === true) {
          if (response.data.recomanded_tags.length > 0) {
            let arr = "";
            for (let i = 0; i < response.data.recomanded_tags.length; i++) {
              if (arr == "") {
                arr = response.data.recomanded_tags[i].tag_name;
              } else {
                arr = arr + " , " + response.data.recomanded_tags[i].tag_name;
              }
            }
            setRTags(arr);
          }
        }
      });
      listcoachresource(schid, tid);
    } else {
      setRTags("");
      listcoachresource(schid, "");
    }
  }

  const [atags, setATags] = useState([]);
  function handleAfilter(e) {
    if (e != null) {
      setATags(e);
      let tid = "";
      if (e.length > 0) {
        for (let i = 0; i < e.length; i++) {
          if (tid === "") {
            tid = e[i].value;
          } else {
            tid = tid + "," + e[i].value;
          }
        }
      }
      listresource(schid, tid);
    } else {
      listresource(schid, "");
    }
  }

  function listcoachresource(lid, filt) {
    setCResList([]);
    coachcmsresourcelist(lid, filt, 1).then((res) => {
      if (res.status === true) {
        setResPath(res.data.logo_path);
        let arr = [];
        if (res.data.resources_list.length > 0) {
          for (let i = 0; i < res.data.resources_list.length; i++) {
            arr.push({
              resources_id: res.data.resources_list[i].resources_id,
              tag_name: res.data.resources_list[i].tag_name,
              uploaded_by: res.data.resources_list[i].uploaded_by,
              status: res.data.resources_list[i].status,
              selected_status: res.data.resources_list[i].selected_status,
              file_type: res.data.resources_list[i].file_type,
              file_or_url: res.data.resources_list[i].file_or_url,
              file_name: res.data.resources_list[i].file_name,
              remarks: res.data.resources_list[i].remarks,
              mark_as_star: res.data.resources_list[i].mark_as_star,
              file_select: false,
            });
          }
        }
        setCResList(arr);
      }
    });
  }

  function listresource(lid, filt) {
    setResList([]);
    cmsresourcelist(lid, filt, 1).then((res) => {
      if (res.status === true) {
        setResPath(res.data.logo_path);
        setResList(res.data.resources_list);
      }
    });
  }
  const [shead, setSHead] = useState("");
  const [desc, setDesc] = useState("");
  const [simage, setImage] = useState("");
  const [statid, setStatId] = useState("");

  const [sface, setSFace] = useState("");
  const [sinsta, setSInsta] = useState("");
  const [syoutube, setSYoutube] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [cirerror, setCirError] = useState("");
  const [photourl, setPhotoUrl] = useState("");

  const [schoolshow, setSchShow] = useState(false);

  function circfile(event) {
    setPhotoUrl("");
    setCirError("");
    setImage("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setImage(event.target.files[0]);
        // const selectedFile = event.target.files[0];
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const img = new Image();
        //   img.src = e.target.result;
        //   img.onload = function () {
        //     const minWidth = 500;
        //     const maxWidth = 700;
        //     const minHeight = 500;
        //     const maxHeight = 700;
        //     const width = img.width;
        //     const height = img.height;
        //     if (
        //       width >= minWidth &&
        //       width <= maxWidth &&
        //       height >= minHeight &&
        //       height <= maxHeight
        //     ) {
        //       setCirError("");
        //     } else {
        //       setCirError(
        //         `Image dimensions should be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels`
        //       );
        //     }
        //   };
        // };
        // reader.readAsDataURL(selectedFile);
      } else {
        setCirError("Image should be .jpg or .jpeg or .png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setCirError("Image size should be less than 5MB");
      }
    }
  }

  const [show, setShow] = useState(false);
  function handleshow() {
    setSHead("");
    setDesc("");
    setImage("");
    setSFace("");
    setSInsta("");
    setSYoutube("");
    setCirError("");
    setPhotoUrl("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    liststatement(schid);
  }

  function handleedit() {
    liststatement(schid);
    setShow(true);
  }

  function addstatement(e) {
    e.preventDefault();
    setSubmitted(true);
    if (cirerror == "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("heading", shead);
      temp.append("description", desc);
      temp.append("statement_image", simage);
      temp.append("fb_url", sface);
      temp.append("insta_url", sinsta);
      temp.append("youtube_url", syoutube);
      temp.append("school_id", id);
      temp.append("school_or_funder", 1);

      if (statid === "") {
        dotracking(
          "add button in school statement $$ " + trackobj.addcmsheading
        );
        schoolstatementcreate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            liststatement(schid);
          }
        });
      } else {
        temp.append("school_statement_id", statid);
        dotracking(
          "update button in school statement " +
            shead +
            " (" +
            statid +
            ") $$ " +
            trackobj.addcmsheading
        );
        schoolstatementupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            liststatement(schid);
          }
        });
      }
    }
  }
  const navigate = useNavigate();
  function backlist() {
    if (localStorage.getItem("Role") === "7") {
      dotracking("back button in cms details $$ " + trackobj.cmsheading);
      navigate("/coach/cmsdetails");
    } else {
      dotracking("back button in cms details $$ " + trackobj.cmsheading);
    }
  }

  const [mshow, setMShow] = useState(false);
  const [platses, setPlaySes] = useState("");
  const [mcount, setMCount] = useState("");
  const [tcount, setTCount] = useState("");
  const [scount, setSCount] = useState("");
  const [medalshow, setMedalShow] = useState(false);
  const [medalid, setMedalId] = useState("");

  const [merrormsg, setMErrorMsg] = useState("");

  function handlemshow() {
    setPlaySes("");
    setMErrorMsg("");
    setMShow(true);
  }
  function handlemclose() {
    setMShow(false);
    listmedal(schid);
  }

  function handlemeditshow() {
    setMShow(true);
    listmedal(schid);
  }

  const handlechange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPlaySes(e.target.value);
    }
  };

  function addmedals(e) {
    e.preventDefault();
    if (platses !== "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("play_session", platses);
      temp.append("school_id", id);
      temp.append("school_or_funder", 1);

      if (medalid === "") {
        dotracking("add button in no of sports represented $$ " + trackobj.addcmsheading);

        playsessioncreate(temp).then((response) => {
          if (response.status === true) {
            handlemclose();
            toast.success(response.message);
            listmedal(schid);
          }
        });
      } else {
        temp.append("school_statement_id", medalid);
        dotracking(
          "update button in no of sports represented " +
            platses +
            " (" +
            medalid +
            ") $$ " +
            trackobj.addcmsheading
        );

        playsessionupdate(temp).then((response) => {
          if (response.status === true) {
            handlemclose();
            toast.success(response.message);
            listmedal(schid);
          }
        });
      }
    } else {
      setMErrorMsg("Please Enter No of Sports Represented");
    }
  }
  //Success Stories
  const [stitle, setSTitle] = useState("");
  const [sdesc, setSDesc] = useState("");
  const [sphto, setSImage] = useState("");
  const [sdesc1, setSDesc1] = useState("");
  const [stid, setStId] = useState("");

  const [check, setCheck] = useState(false);

  const [photoerror, setPhotoError] = useState("");
  const [sphotourl, setSPhotoUrl] = useState("");
  function storyfile(event) {
    setSPhotoUrl("");
    setPhotoError("");
    setSImage("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setSImage(event.target.files[0]);
        // const selectedFile = event.target.files[0];
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const img = new Image();
        //   img.src = e.target.result;
        //   img.onload = function () {
        //     if (img.width === 400 && img.height === 400) {
        //       setPhotoError("");
        //     } else {
        //       setPhotoError(`Image dimensions should be 400x400 pixels`);
        //     }
        //   };
        // };
        // reader.readAsDataURL(selectedFile);
      } else {
        setPhotoError("Image should be .jpg or .jpeg or .png format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setPhotoError("Image size should be less than 5MB");
      // }
    }
  }

  const [sshow, setSShow] = useState(false);
  function handlesshow() {
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setPhotoError("");
    setSPhotoUrl("");
    setCheck("");
    setSShow(true);
  }
  function handlesclose() {
    setSShow(false);
    setCheck("");
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setPhotoError("");
    setSPhotoUrl("");
  }

  const [syshow, setSyShow] = useState(false);

  function handlestory(sid, stype) {
    storyview(sid).then((response) => {
      if (response.status === true) {
        if (stype === 1) {
          setSyShow(true);
        } else if (stype === 2) {
          setSShow(true);
        }

        setSTitle(response.data.success_stories_details.article_title);
        setSDesc(response.data.success_stories_details.article_description);
        if (response.data.success_stories_details.article_image !== null) {
          setSPhotoUrl(
            response.data.logo_path +
              response.data.success_stories_details.article_image
          );
          setSImage(
            response.data.logo_path +
              response.data.success_stories_details.article_image
          );
        }

        setSDesc1(response.data.success_stories_details.article_content);
        setStId(response.data.success_stories_details.id);
      }
    });
  }

  function handlestoryclose() {
    setSyShow(false);
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setPhotoError("");
    setSPhotoUrl("");
  }
  function addstory(e) {
    e.preventDefault();
    setCheck(true);
    if (photoerror == "" && sphto != "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("school_id", id);
      temp.append("article_title", stitle);
      temp.append("article_description", sdesc);
      temp.append("article_image", sphto);
      temp.append("article_content", sdesc1);
      temp.append("school_or_funder", 1);
      if (stid === "") {
        dotracking(
          "add button in success stories $$ " + trackobj.addcmsheading
        );

        storycreate(temp).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(schid);
          }
        });
      } else {
        temp.append("article_id", stid);
        dotracking(
          "update button in success stories for " +
            stitle +
            " (" +
            stid +
            ") $$ " +
            trackobj.addcmsheading
        );

        storyupdate(temp).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(schid);
          }
        });
      }
    }
  }

  function deletestory(did) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      width: "800px",
      confirmButtonColor: "#4ecc48",
      cancelButtonColor: "#ac0404",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete button in success story $$ " + trackobj.addcmsheading
        );
        storydelete(localStorage.getItem("UID"), did).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(schid);
          }
        });
      }
    });
  }

  //Photos,Videos and Resources
  const [ptype, setPType] = useState("");
  const [photo, setPhoto] = useState([]);
  const [photolist, setPhotoLink] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  function photofile(event) {
    let arr1 = [];
    let arr = [];
    let arr2 = [];
    setResCheck("");

    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length !== 0) {
        if (event.target.files[i].name.match(/\.(jpg|jpeg|png)$/i)) {
          var size = event.target.files[i].size / 1024 / 1024;
          if (size > 50) {
            arr1.push(
              event.target.files[i].name + " size should be less than 50MB"
            );
            setErrorMsg([...arr1]);
          } else {
            arr2.push(URL.createObjectURL(event.target.files[i]));
            arr.push(event.target.files[i]);
          }
        } else {
          arr1.push(
            event.target.files[i].name + " should be jpg or jpeg or png format"
          );
        }
      }
    }
    setPhoto(arr);
    setPhotoLink(arr2);
    setPhoto([...photo, ...arr]);
    setPhotoLink([...photolist, ...arr2]);
    setErrorMsg(arr1);
    setErrorMsg([...errorMsg, ...arr1]);
  }

  function deletecurphoto(did) {
    photo.splice(did, 1);
    setPhoto([...photo]);
    photolist.splice(did, 1);
    setPhotoLink([...photolist]);
  }
  const [gtags, setGTags] = useState("");
  const [glist, setGTagList] = useState([]);

  const [rtags, setRTags] = useState([]);
  const [rlist, setRTagList] = useState([]);
  const [gdesc, setGDesc] = useState("");

  const [fvideo, setVideoFile] = useState("");
  const [fvideourl, setVideoUrl] = useState("");
  const [formvideoerror, setVideoErrors] = useState("");

  function videofile(e) {
    setVideoFile("");
    setVideoErrors("");

    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(mp4|mp3|wav|mkv)$/i)) {
        setVideoFile(e.target.files[0]);
        // setVideoUrl(URL.createObjectURL(e.target.files[0]));
        // var video = document.getElementById("video");
        // var f = e.target.files[0];
        // if (f) {
        //   var r = new FileReader();
        //   r.onload = function (e) {
        //     var contents = e.target.result;
        //     var uint8Array = new Uint8Array(contents);
        //     var arrayBuffer = uint8Array.buffer;
        //     var blob = new Blob([arrayBuffer]);
        //     video.src = URL.createObjectURL(blob);
        //   };
        //   r.readAsArrayBuffer(f);
        // }
      } else {
        setVideoErrors("Video should be mp4 or mp3 or wav or mkv format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 50) {
        setVideoErrors("Video size should be less than 50MB");
      }
    }
  }

  const [reserror, setResError] = useState("");
  const [rfile, setRFile] = useState("");

  function resfile(event) {
    setResError("");
    setRFile("");
    setResCheck("");

    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf)$/i)) {
        setRFile(event.target.files[0]);
      } else {
        setResError("Files should be .pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setResError("Files size should be less than 5MB");
      }
    }
  }
  const [rescheck, setResCheck] = useState("");
  function addresouce(e) {
    e.preventDefault();
    setResCheck("");
    var chk = "";
    if (ptype != "") {
      if (gtags != "") {
        var ctype = "";
        var gtype = "file";
        var fcount = 1;
        if (ptype == 1) {
          ctype = "Photo";
          fcount = photo.length;
          if (photo.length == 0) {
            chk = "Please Select Photo";
            setResCheck("Please Select Photo");
          }
        }

        if (ptype == 2) {
          ctype = "Video";
          fcount = 1;
          if (fvideo !== "" && fvideourl !== "") {
            chk = "Either Upload Video or Enter Video URL";
            setResCheck("Either Upload Video or Enter Video URL");
          }
          if (fvideo == "" && fvideourl == "") {
            chk = "Please Upload Video or Enter Video URL";
            setResCheck("Please Upload Video or Enter Video URL");
          }

          if (fvideo !== "") {
            gtype = "file";
          }
          if (fvideourl !== "") {
            gtype = "url";
          }
        }

        if (ptype == 3) {
          ctype = "PDF";
          fcount = 1;
          if (rfile == "") {
            chk = "Please Select Files";
            setResCheck("Please Select Files");
          }
        }
        if (chk == "") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("school_id", schid);
          if (localStorage.getItem("Role") === "1") {
            temp.append("uploaded_by", 1);
            temp.append("mark_as_star", 0);
          } else if (localStorage.getItem("Role") === "3") {
            temp.append("uploaded_by", 1);
            temp.append("mark_as_star", 0);
          } else if (localStorage.getItem("Role") === "7") {
            temp.append("uploaded_by", 2);
            temp.append("mark_as_star", status);
          }
          temp.append("file_type", ctype);
          temp.append("description", gdesc);
          temp.append("file_or_url", gtype);
          temp.append("files_count", fcount);
          temp.append("tags_count", gtags.length);
          for (var i = 0; i < gtags.length; i++) {
            temp.append("tags_id_" + [i + 1], gtags[i].value);
          }
          if (ptype == 1) {
            for (var i = 0; i < photo.length; i++) {
              temp.append("resource_file_" + [i + 1], photo[i]);
            }
          } else if (ptype == 2) {
            if (gtype == "file") {
              temp.append("resource_file_1", fvideo);
            } else {
              temp.append("resource_file_1", fvideourl);
            }
          } else if (ptype == 3) {
            temp.append("resource_file_1", rfile);
          }
          temp.append("school_or_funder", 1);

          dotracking(
            "submit button in photos/videos and resources $$ " +
              trackobj.addcmsheading
          );
          cmsresourcecreate(temp).then((res) => {
            if (res.status === true) {
              handlegclose();
              toast.success(res.message);
              listresource(schid, "");
              listcoachresource(schid, "");
            }
          });
        }
      } else {
        setResCheck("Please Select Tags");
      }
    } else {
      setResCheck("Please Select Type");
    }
  }

  function deleteres(rid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      // width: "800px",
      confirmButtonColor: "#4ecc48",
      cancelButtonColor: "#ac0404",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        var rdata = "";
        if (localStorage.getItem("Role") === "1") {
          rdata = 1;
        } else if (localStorage.getItem("Role") === "3") {
          rdata = 1;
        } else if (localStorage.getItem("Role") === "7") {
          rdata = 2;
        }
        dotracking(
          "delete icon in photos/videos and resources $$ " +
            trackobj.addcmsheading
        );
        cmsresourcedelete(localStorage.getItem("UID"), rid, rdata).then(
          (response) => {
            if (response.status === true) {
              toast.success(response.message);
              listresource(schid, "");
              listcoachresource(schid, "");
            }
          }
        );
      }
    });
  }

  const [gshow, setGShow] = useState(false);

  function handlegshow() {
    setGTags("");
    setPhoto([]);
    setPhotoLink([]);
    setPType("");
    setErrorMsg([]);
    setVideoFile("");
    setVideoUrl("");
    setVideoErrors("");
    setResError("");
    setResCheck("");
    setRFile("");
    setStatus("0");
    setGShow(true);
  }
  function handlegclose() {
    setGShow(false);
    setPhoto([]);
    setPhotoLink([]);
    setPType("");
    setErrorMsg([]);
    setVideoFile("");
    setVideoUrl("");
    setVideoErrors("");
    setResError("");
    setResCheck("");
    setRFile("");
    setStatus("0");
    setGTags("");
  }

  const [gcshow, setGCShow] = useState(false);
  function handlegcshow() {
    setGCShow(true);
    const updatedCreslist = creslist.map((item) => ({
      ...item,
      file_select: false,
    }));
    setCResList(updatedCreslist);
  }

  function handlegcclose() {
    setGCShow(false);
  }
  const [gcpshow, setGCPShow] = useState(false);
  const [gphoto, setGallery] = useState([]);
  function handlegcpopen(cid) {
    setGCPShow(true);
    photoslist(cid).then((response) => {
      if (response.status === true) {
        if (response.data.tournamentlist.length !== 0) {
          let arr1 = [];
          for (let i = 0; i < response.data.tournamentlist.length; i++) {
            arr1.push({
              id: response.data.tournamentlist[i].id,
              src:
                response.data.tournamentimage_path +
                response.data.tournamentlist[i].imagename,
            });
          }
          setGallery(arr1);
        }
      }
    });
  }
  function handlegcpclose() {
    setGCPShow(false);
    setGallery([]);
  }

  const [coacherr, setCoachErr] = useState("");
  function submitcoachphoto() {
    const cdata = creslist.filter((obj) => {
      return obj.file_select === true;
    });
    if (cdata !== undefined) {
      if (cdata.length > 0) {
        let carr = "";
        for (var i = 0; i < cdata.length; i++) {
          if (carr === "") {
            carr = "&resources_id_" + [i + 1] + "=" + cdata[i].resources_id;
          } else {
            carr =
              carr + "&resources_id_" + [i + 1] + "=" + cdata[i].resources_id;
          }
        }
        let temp =
          "admin_id=" +
          localStorage.getItem("UID") +
          "&selected_count=" +
          cdata.length +
          carr;

        cmsselectresource(temp).then((res) => {
          if (res.status === true) {
            handlegcclose();
            toast.success(res.message);
            listresource(schid, "");
            listcoachresource(schid, "");
          }
        });
      } else {
        setCoachErr("Please select the resource");
      }
    } else {
      setCoachErr("Please select the resource");
    }
  }

  function handletype(e) {
    setPType(e.target.value);
    setRFile("");
    setResError("");
    setVideoUrl("");
    setVideoFile("");
    setVideoErrors("");
    setPhoto([]);
    setPhotoLink([]);
    setErrorMsg([]);
  }

  function handleTags(e, htype) {
    setGTags("");
    setRTags("");
    setRTagList([]);
    let arr = [];

    if (htype == 1) {
      if (e != null) {
        setGTags(e);

        for (let i = 0; i < e.length; i++) {
          cmstagview(e[i].value).then((response) => {
            if (response.status === true) {
              if (response.data.recomanded_tags.length > 0) {
                for (let i = 0; i < response.data.recomanded_tags.length; i++) {
                  arr.push({
                    value: response.data.recomanded_tags[i].recomanded_tags_id,
                    label: response.data.recomanded_tags[i].tag_name,
                  });
                }

                setRTags([...arr]);
              }
            }
          });
        }
      } else {
        setGTags("");
        setRTags("");
        setRTagList([]);
      }
    } else if (htype == 2) {
      const arr1 = [e];
      const combinedTags = [...arr1, ...gtags];
      setGTags(combinedTags);
      for (let i = 0; i < combinedTags.length; i++) {
        cmstagview(combinedTags[i].value).then((response) => {
          if (response.status === true) {
            if (response.data.recomanded_tags.length > 0) {
              for (let i = 0; i < response.data.recomanded_tags.length; i++) {
                arr.push({
                  value: response.data.recomanded_tags[i].recomanded_tags_id,
                  label: response.data.recomanded_tags[i].tag_name,
                });
                // if (arr == "") {
                //   arr = response.data.recomanded_tags[i].tag_name;
                // } else {
                //   arr = arr + " , " + response.data.recomanded_tags[i].tag_name;
                // }
              }

              setRTags([...arr]);
            }
          }
        });
      }
    }
  }

  const [status, setStatus] = useState("0");
  function Selectstatus(e) {
    setStatus(e.target.value);
  }
  function setone(gid) {
    setCoachErr("");
    creslist.find((item) => {
      if (item.resources_id == gid) {
        item.file_select = !item.file_select;
      }
    });
    setCResList([...creslist]);
  }
  function handlepublish(e) {
    e.preventDefault();
    Swal.fire({
      html: "<h3>Do you want to publish this webpage?</h3>",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        var sl = schname.toLowerCase();
        sl = sl.trim();
        sl = sl.replace(/ /g, "_");
        var sllink = WebUrl + sl + "/" + schcode;
        // if(id == 10030){
        Swal.fire({
          html:
            "<h3>Webpage has been published successfully</h3> <br/> <b><span style='color:#000'>WebPage: </span><a style='color:#035399'>" +
            sllink +
            "</a></b> <br/><br/><h4 style='color:red'>Do you want to view this webpage?</h4>",
          showCancelButton: true,
          width: "800px",
          confirmButtonColor: "#4ecc48",
          cancelButtonColor: "#ac0404",
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            dotracking(
              "publish button in cms details for " +
                schname +
                " (" +
                schcode +
                ") school $$ " +
                trackobj.addcmsheading
            );
            var sk = sl + "/" + schcode;
            schoolpublishtrack(localStorage.getItem("UID"), schid, sk).then(
              (res) => {
                if (res.status === true) {
                  window.open(sllink, "_blank");
                }
              }
            );
            // window.open(sllink, "_blank");
          }
        });
        // } else if(id == 10274){
        //   Swal.fire({
        //     html: "<h3>Webpage has been published successfully</h3> <br/> <b><span style='color:#000'>WebPage: </span><a style='color:#035399'>https://sportingindia.tech/demo/Sportzvillage/mother_terasa_modern_public_school/10566/</a></b> <br/><br/><h4 style='color:red'>Do you want to view this webpage?</h4>",
        //     showCancelButton: true,
        //     width: "800px",
        //     confirmButtonColor: "#4ecc48",
        //     cancelButtonColor: "#ac0404",
        //     confirmButtonText: "Yes",
        //     allowOutsideClick: false,
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       window.open(
        //         "https://sportingindia.tech/demo/Sportzvillage/mother_terasa_modern_public_school/10566/",
        //         "_blank"
        //       );
        //     }
        //   });
        // }
      }
    });
  }

  const [ishow, setIShow] = useState(false);
  const [pimage, setPImage] = useState(false);
  const [pimgtype, setPImgType] = useState("");
  const [pimgurl, setPImgUrl] = useState("");
  const [prem, setPRem] = useState("");

  function handlepop(img, itype, ifile, irem) {
    if (itype === "Video") {
      if (ifile === "url") {
        let newstr1 = img.substr(img.length - 11);
        let curvideo = "";
        let res = img.substring(0, 23);
        curvideo = "https://www.youtube.com";
        if (curvideo == res) {
          setPImage(`https://www.youtube.com/embed/${newstr1}`);
        }
      } else {
        setPImage(respath + img);
      }
    } else {
      setPImage(respath + img);

      // setPImage(img+"#toolbar=0&navpanes=0&statusbar=0&view=Fit;readonly=true");
    }
    setPRem(irem);
    setPImgUrl(ifile);
    setIShow(true);
    setPImgType(itype);
  }

  function imageclose() {
    setIShow(false);
    setPImage("");
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add CMS</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add CMS
          </li>
        </ol>
      </div>
      <h3 className="errortext text-center">
        {schname} - {schcode}
      </h3>
      {(localStorage.getItem("Role") === "1" ||
        localStorage.getItem("Role") === "3") && (
        <>
          <Tabs
            // activeKey={tabselected}
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey={1} title="School Statement">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>School Statement / Mission Statement</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={scolor}
                        onChange={(e) => setSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      {!schoolshow && (
                        <div className="text-right">
                          <a className="btn btn-success" onClick={handleshow}>
                            <i className="fa fa-plus"></i> Add School Statement
                          </a>
                        </div>
                      )}
                      {schoolshow && (
                        <div className="text-right">
                          <a className="btn btn-secondary" onClick={handleedit}>
                            <i className="fa fa-plus"></i> Edit School Statement
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-cursor">
                <div className="custom-cursor__cursor"></div>
                <div className="custom-cursor__cursor-two"></div>

                <div className="page-wrapper">
                  <header className="main-header main-header--two sticky-header sticky-header--two sticky-header--normal">
                    <div className="container-fluid">
                      <div className="main-header__inner">
                        <div className="main-header__logo logo-retina">
                          <a>
                            <img
                              src={schlogo}
                              onError={(e) => errorhandle(e)}
                              className="main-header__logo__light"
                            />
                          </a>
                        </div>
                        <div className="main-header__center-wrap">
                          <h3 className="mb-0">{schname}</h3>
                        </div>
                        <div className="main-header__right-wrap">
                          <div className="main-header__right">
                            <a>
                              <img
                                src={require("../../../../assets/images/logo_sv.png")}
                                alt=""
                                width="150"
                                className="main-header__logo__light"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                  {schoolshow && (
                    <section
                      className="main-slider-two"
                      id="home"
                      style={{ backgroundColor: scolor }}
                    >
                      <div className="main-slider-two__inner">
                        <div
                          className="main-slider-two__inner__bg"
                          style={{
                            backgroundImage: `url(${require("../../../../assets/images/resources/main-slider-shape-bg-2-2.png")})`,
                          }}
                        ></div>
                      </div>
                      <div className="main-slider-two__carousel easilon-owl__carousel owl-carousel owl-loaded owl-drag">
                        <div className="owl-stage-outer">
                          <div
                            className="owl-stage"
                            style={{
                              // transform: "translate3d(-2698px, 0px, 0px)",
                              transition: "all 0s ease 0s",
                              // width: "6745px",
                            }}
                          >
                            <div
                              className="owl-item active"
                              // style={{ width: "1100px" }}
                            >
                              <div className="item">
                                <div className="">
                                  {/* main-slider-two__item */}
                                  <div
                                    className="main-slider-two__bg"
                                    style={{
                                      backgroundImage: `url(${require("../../../../assets/images/resources/main-slider-shape-bg-2-1.png")})`,
                                    }}
                                  ></div>
                                  <div className="main-slider-two__social social-links-two">
                                    <a href={sface} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-facebook-f"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Facebook</span>
                                    </a>
                                    <a href={sinsta} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-instagram"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Instagram</span>
                                    </a>
                                    <a href={syoutube} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-youtube"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Youtube</span>
                                    </a>
                                  </div>
                                  <div className="main-slider-two__container container">
                                    <div className="row gutter-y-60 align-items-center">
                                      <div className="col-xxl-6 col-xl-9">
                                        <div className="main-slider-two__content">
                                          <div className="main-slider-two__top"></div>
                                          <h2 className="main-slider-two__title">
                                            {shead}
                                          </h2>
                                          <div className="main-slider-two__text-box">
                                            <p className="main-slider-two__text">
                                              {desc}
                                            </p>
                                          </div>
                                          <div className="main-slider-two__content__shape-box">
                                            <div className="main-slider-two__content__shape-circle"></div>
                                          </div>
                                        </div>
                                      </div>
                                      {simage !== "" && (
                                        <div className="col-xxl-6 col-xl-3">
                                          <div className="main-slider-two__image">
                                            <div className="main-slider-two__image__inner">
                                              <img
                                                src={simage}
                                                // src={require("../../../../assets/images/resources/main-slider-image-2-1.jpg")}
                                                alt="slider image"
                                              />
                                            </div>
                                            <div className="main-slider-two__image__shape-box">
                                              <img
                                                src={require("../../../../assets/images/resources/main-slider-shape-2-2.png")}
                                                alt="shape"
                                                className="main-slider-two__image__shape-two"
                                              />
                                              <img
                                                src={require("../../../../assets/images/resources/main-slider-shape-2-3.png")}
                                                alt="shape"
                                                className="main-slider-two__image__shape-three"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>

              <Modal show={show} size="xl">
                <Modal.Header>
                  {statid === "" && <h4>Add School Statement</h4>}
                  {statid !== "" && <h4>Edit School Statement</h4>}
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Heading <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Heading"
                          value={shead}
                          onChange={(e) => setSHead(e.target.value)}
                        />
                        {submitted && !shead && (
                          <span className="errortext">Heading is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Description <span className="errortext"> *</span>
                        </label>
                        <textarea
                          rows={2}
                          className="form-control"
                          placeholder="Description"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        />
                        {submitted && !desc && (
                          <span className="errortext">
                            Description is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Facebook Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Facebook Link"
                          value={sface}
                          onChange={(e) => setSFace(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Instagram Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Instagram Link"
                          value={sinsta}
                          onChange={(e) => setSInsta(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Youtube Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Youtube Link"
                          value={syoutube}
                          onChange={(e) => setSYoutube(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Image{" "}
                          <span className="errortext">
                            {" "}
                            * (Max Size 5mb,Accepted files are jpg, jpeg, png){" "}
                          </span>{" "}
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                          onChange={circfile}
                        />
                        {photourl !== "" && (
                          <img
                            className="profile_img mt-2"
                            src={photourl}
                            width="100%"
                            alt="photo"
                          />
                        )}

                        {submitted && !simage && (
                          <span className="errortext">Image is required</span>
                        )}
                        {cirerror !== "" && (
                          <span className="errortext">{cirerror}</span>
                        )}
                      </div>
                    </div>

                    <div className="text-right border-top ">
                      {statid === "" && (
                        <a
                          className="btn btn-success me-1 mt-2"
                          onClick={addstatement}
                        >
                          <i className="fa fa-edit"></i> Submit
                        </a>
                      )}
                      {statid !== "" && (
                        <a
                          className="btn btn-success me-1 mt-2"
                          onClick={addstatement}
                        >
                          <i className="fa fa-edit"></i> Update
                        </a>
                      )}

                      <a className="btn btn-danger mt-2" onClick={handleclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Tab>

            <Tab eventKey={2} title="Tournaments & Events">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <h4>Tournaments & Events</h4>
                    </div>
                    <div className="col-md-2">
                      {/* <select
                        value={tcolor}
                        onChange={(e) => setTColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
              {tlist.length !== 0 && (
                <div className="card mt-3" style={{ backgroundColor: tcolor }}>
                  <div className="row gutter-y-30 mt-3 px-3 py-3">
                    {tlist.map((item, i) => {
                      return (
                        <div className="col-md-3 col-lg-3" key={i}>
                          <div
                            className="blog-card blog-card--two wow fadeInUp animated"
                            data-wow-duration="1500ms"
                            data-wow-delay="00ms"
                            style={{
                              visibility: "visible",
                              animationDuration: "1500ms",
                              animationDelay: "0ms",
                              animationName: "fadeInUp",
                            }}
                          >
                            <div className="blog-card__content text-center">
                              <div className="blog-card__date">
                                <span className="blog-card__date__day">
                                  {moment(item.start_date).format("YYYY")}
                                </span>
                                <span className="blog-card__date__month">
                                  {moment(item.start_date).format("DD, MMMM")}
                                </span>
                              </div>
                              <div className="blog-card__image">
                                {item.tournament_logo !== null && (
                                  <img
                                    src={tpath + item.tournament_logo}
                                    onError={(e) => errortour(e)}
                                    height="150px"
                                  />
                                )}
                                {item.tournament_logo == null && (
                                  <img
                                    src={require("../../../../assets/images/resources/logo_epl.png")}
                                    height="150px"
                                  />
                                )}
                              </div>
                              <h3 className="blog-card__title">
                                <a>{item.meet_name}</a>
                              </h3>
                            </div>
                            {/* <div className="blog-card__content_bottom text-center">
     <a  className="btn btn-primary">
       Register now
     </a>
   </div> */}
                          </div>
                        </div>
                      );
                    })}

                    {elist.map((item, i) => {
                      return (
                        <div className="col-md-3 col-lg-3" key={i}>
                          <div
                            className="blog-card blog-card--two wow fadeInUp animated"
                            data-wow-duration="1500ms"
                            data-wow-delay="00ms"
                            style={{
                              visibility: "visible",
                              animationDuration: "1500ms",
                              animationDelay: "0ms",
                              animationName: "fadeInUp",
                            }}
                          >
                            <div className="blog-card__content text-center">
                              <div className="blog-card__date">
                                <span className="blog-card__date__day">
                                  {moment(item.start_date).format("YYYY")}
                                </span>
                                <span className="blog-card__date__month">
                                  {moment(item.start_date).format("DD, MMMM")}
                                </span>
                              </div>
                              <div className="blog-card__image">
                                <img
                                  src={require("../../../../assets/images/resources/logo-pathway.png")}
                                />
                              </div>
                              <h3 className="blog-card__title">
                                <a>{item.event_name}</a>
                              </h3>
                            </div>
                            {/* <div className="blog-card__content_bottom text-center">
     <a  className="btn btn-primary">
       Register now
     </a>
   </div> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* {elist.length !== 0 && (
                <div className="row gutter-y-30 mt-2"> */}

                  {/* </div>
              )} */}
                </div>
              )}
            </Tab>
            <Tab eventKey={3} title="Medals">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Medals</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={mcolor}
                        onChange={(e) => setMColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      <div className="text-right">
                        {!medalshow && (
                          <a className="btn btn-success" onClick={handlemshow}>
                            <i className="fa fa-plus"></i> Add
                          </a>
                        )}
                        {medalshow && (
                          <a
                            className="btn btn-secondary"
                            onClick={handlemeditshow}
                          >
                            <i className="fa fa-plus"></i> Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="funfact-one"
                style={{ backgroundColor: mcolor }}
              >
                <div className="funfact-one__bg"></div>
                <div className="container">
                  <div className="row gutter-y-40">
                    <div
                      className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="00ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "1500ms",
                        animationDelay: "0ms",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="funfact-one__item">
                        <h3 className="funfact-one__item__number count-box counted">
                          <span
                            className="count-text"
                            data-stop="2000"
                            data-speed="1500"
                          >
                            {platses}
                          </span>
                          <span>hrs</span>
                        </h3>
                        <h4 className="funfact-one__item__title">
                          No of Sports Represented
                        </h4>
                      </div>
                    </div>
                    <div
                      className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "1500ms",
                        animationDelay: "0ms",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="funfact-one__item">
                        <h3 className="funfact-one__item__number count-box counted">
                          <span
                            className="count-text"
                            data-stop="137"
                            data-speed="1500"
                          >
                            {scount}
                          </span>
                        </h3>
                        <h4 className="funfact-one__item__title">
                        Total Participation
                        </h4>
                      </div>
                    </div>
                    <div
                      className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="200ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "1500ms",
                        animationDelay: "0ms",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="funfact-one__item">
                        <h3 className="funfact-one__item__number count-box counted">
                          <span
                            className="count-text"
                            data-stop="2"
                            data-speed="1500"
                          >
                            {tcount}
                          </span>
                        </h3>
                        <h4 className="funfact-one__item__title">
                          Tournaments Participated
                        </h4>
                      </div>
                    </div>
                    <div
                      className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "1500ms",
                        animationDelay: "0ms",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="funfact-one__item">
                        <h3 className="funfact-one__item__number count-box counted">
                          <span
                            className="count-text"
                            data-stop="7"
                            data-speed="1500"
                          >
                            {mcount}
                          </span>
                        </h3>
                        <h4 className="funfact-one__item__title">Medals</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Modal show={mshow} size="lg">
                <Modal.Header>
                  <h4>Add No of Sports Represented</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          No of Sports Represented <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="No of Sports Represented"
                          value={platses}
                          onChange={handlechange}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    {merrormsg !== "" && (
                      <span className="errortext">{merrormsg}</span>
                    )}
                    <div className="text-right border-top ">
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={addmedals}
                      >
                        <i className="fa fa-edit"></i> Submit
                      </a>

                      <a className="btn btn-danger mt-2" onClick={handlemclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Tab>
            <Tab eventKey={4} title="Success Stories">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Success Stories</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={sscolor}
                        onChange={(e) => setSSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handlesshow}>
                          <i className="fa fa-plus"></i> Add Success Stories
                        </a>
                      </div>
                    </div>
                  </div>
                  <Modal show={sshow} size="xl">
                    <Modal.Header>
                      {stid === "" && <h4>Add Success Stories</h4>}
                      {stid !== "" && <h4>Edit Success Stories</h4>}
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Title <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              value={stitle}
                              onChange={(e) => setSTitle(e.target.value)}
                            />
                            {check && !stitle && (
                              <span className="errortext">
                                Title is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Description <span className="errortext"> *</span>
                            </label>
                            <textarea
                              rows={2}
                              className="form-control"
                              placeholder="Description"
                              value={sdesc}
                              onChange={(e) => setSDesc(e.target.value)}
                            />
                            {check && !sdesc && (
                              <span className="errortext">
                                Description is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">Content</label>
                          <JoditEditor
                            // ref={editor}
                            value={sdesc1}
                            onChange={(sdesc1) => setSDesc1(sdesc1)}
                            tabIndex={1}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Image{" "}
                              <span className="errortext">
                                {" "}
                                * (Accepted files are jpg, jpeg, png){" "}
                              </span>{" "}
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              accept=".jpg, .jpeg, .png"
                              onChange={storyfile}
                            />
                            {sphotourl !== "" && (
                              <img
                                className="profile_img mt-2"
                                src={sphotourl}
                                width="100%"
                                alt="photo"
                              />
                            )}

                            {check && !sphto && (
                              <span className="errortext">
                                Image is required
                              </span>
                            )}
                            {photoerror !== "" && (
                              <span className="errortext">{photoerror}</span>
                            )}
                          </div>
                        </div>
                        <div className="text-right border-top mt-2">
                          {stid === "" && (
                            <a
                              className="btn btn-success me-1 mt-2"
                              onClick={addstory}
                            >
                              <i className="fa fa-edit"></i> Submit
                            </a>
                          )}
                          {stid !== "" && (
                            <a
                              className="btn btn-success me-1 mt-2"
                              onClick={addstory}
                            >
                              <i className="fa fa-edit"></i> Update
                            </a>
                          )}
                          <a
                            className="btn btn-danger mt-2"
                            onClick={handlesclose}
                          >
                            <i className="fa fa-times"></i> Close
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal show={syshow} size="xl">
                    <Modal.Header>
                      <h4>Success Stories Details</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="owl-item active">
                        <div className="item">
                          <div className="testimonial-card-two @@extraClassName">
                            <div className="testimonial-card-two__inner">
                              <div className="row">
                                <div className="col-md-6">
                                  <img src={sphto} width="100%" />
                                </div>
                                <div className="col-md-6">
                                  <div className="testimonial-card-two__bottom">
                                    <div className="testimonial-card-two__identity">
                                      <h4 className="testimonial-card-two__name">
                                        {stitle}
                                      </h4>
                                      <p className="testimonial-card-two__quote">
                                        {sdesc}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="testimonial-card-two__quote">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: sdesc1,
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                              {/* <div className="testimonial-card-two__top">
                                <div className="testimonial-card-two__image">
                                  <img src={sphto} width="100%" />
                                </div>
                              </div>
                              <div className="testimonial-card-two__bottom">
                                <div className="testimonial-card-two__identity">
                                  <h4 className="testimonial-card-two__name">
                                    {stitle}
                                  </h4>
                                  <p className="testimonial-card-two__quote">
                                    {sdesc}
                                  </p>
                                </div>
                              </div>
                              <p className="testimonial-card-two__quote">
                                <div
                                  dangerouslySetInnerHTML={{ __html: sdesc1 }}
                                />
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="text-right">
                        <a
                          className="btn btn-danger"
                          onClick={handlestoryclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              {stlist.length !== 0 && (
                <div className="card mt-3" style={{ backgroundColor: sscolor }}>
                  <div className="row mt-2 px-3 py-3">
                    {stlist.map((item, i) => {
                      return (
                        <div className="col-md-4 mt-2" key={i}>
                          <div
                            className="owl-item active"
                            style={{ marginRight: "30px" }}
                          >
                            <div className="item">
                              <div className="testimonial-card-two @@extraClassName">
                                <div
                                  className="testimonial-card-two__inner"
                                  onClick={() => handlestory(item.id, 1)}
                                >
                                  <div className="testimonial-card-two__top">
                                    <div className="testimonial-card-two__image">
                                      <img
                                        src={stpath + item.article_image}
                                        // src={require("../../../../assets/images/sindhu.jpg")}
                                      />
                                    </div>
                                    <svg
                                      className="testimonial-card-two__icon"
                                      viewBox="0 0 56 41"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M0 25.4448H11.1378L3.71254 40.2951H14.8503L22.2756 25.4448V3.16919H0V25.4448Z"></path>
                                      <path d="M29.7004 3.16919V25.4448H40.8382L33.413 40.2951H44.5508L51.976 25.4448V3.16919H29.7004Z"></path>
                                      <path d="M14.307 21.7756H3.66919V0.5H24.9448V22.1576L17.7105 36.6259H7.69075L14.7542 22.4992L15.116 21.7756H14.307Z"></path>
                                      <path d="M44.0082 21.7756H33.3704V0.5H54.646V22.1576L47.4117 36.6259H37.3919L44.4554 22.4992L44.8172 21.7756H44.0082Z"></path>
                                    </svg>
                                  </div>
                                  <div className="testimonial-card-two__bottom">
                                    <div className="testimonial-card-two__identity">
                                      <h4 className="testimonial-card-two__name">
                                        {item.article_title}
                                      </h4>
                                      <p className="testimonial-card-two__quote">
                                        {/* <span>Badminton</span> <br /> */}
                                        {item.article_description}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <p className="testimonial-card-two__quote">
                                  <span>Athletics </span>
                                  <br /> Gold Medal - xx international
                                </p> */}
                                </div>
                                <div className="text-center mt-4">
                                  <a
                                    onClick={() => handlestory(item.id, 2)}
                                    className="btn btn-sm btn-secondary me-2"
                                    title="Edit Story"
                                  >
                                    <i className="fa fa-edit"></i> Edit
                                  </a>
                                  <a
                                    className="btn btn-sm btn-danger me-2"
                                    onClick={() => deletestory(item.id)}
                                    title="Delete Story"
                                  >
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Tab>

            <Tab eventKey={5} title="Gallery">
              <div className="card mt-0">
                <div className="card-body">
                  {/* <h4 className="text-center">Gallery</h4> */}
                  <div className="row">
                    <div className="col-md-10">
                      <h4 className="text-center">Gallery</h4>
                    </div>
                    <div className="col-md-2">
                      {/* <select
                        value={gcolor}
                        onChange={(e) => setGColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>

                  {/* <Modal show={gshow} size="lg">
                <Modal.Header>
                  <h4>Add Gallery</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Tournament <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-select"
                          value={gtags}
                          onChange={(e) => setGTags(e.target.value)}
                        >
                          <option value="">Select Tournament</option>
                          <option value="1">EduSports Partner League</option>
                          <option value="2">CISCE TN & PY Regional Sports</option>
                          <option value="3">PathwayZ Summer Camp</option>
                        </select>
                      </div>
                    </div>
                  <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Tags <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-select"
                          value={gtags}
                          onChange={(e) => setGTags(e.target.value)}
                        >
                          <option value="">Select Tags</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Photos <span className="errortext"> *</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          multiple
                          onChange={galleryfile}
                          accept=".jpg,.jpeg,.png"
                        />
                      </div>
                    </div>
                   
                    {gallerylist.length !== 0 && (
                      <div className="row">
                        {gallerylist.map((data, i) => {
                          return (
                            <div className="col-md-2 mt-4" key={i}>
                              <a
                                className="photo-mark"
                                onClick={() => deletecurgallery(i)}
                              >
                                <i className="fa fa-trash fa-lg"></i>
                              </a>
                              <img
                                className="photoimg"
                                src={data}
                                height="100px"
                                alt="Photo"
                                key={i}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {gerrorMsg != "" && (
                      <span className="errortext">{gerrorMsg}</span>
                    )}
                    <div className="text-right border-top mt-2">
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={addgallery}
                      >
                        <i className="fa fa-edit"></i> Submit
                      </a>

                      <a className="btn btn-danger mt-2" onClick={handlegclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>*/}
                </div>
              </div>

              <section
                className="work-process-one section-space position-relative"
                style={{ backgroundColor: gcolor }}
              >
                <div
                  className="why-choose-one__bg"
                  style={{
                    backgroundImage: `url(${require("../../../../assets/images/resources/why-choose-bg-1-1.png")})`,
                  }}
                ></div>
                <div className="container">
                  <div className="row gutter-y-30">
                    {gllist.map((data, i) => {
                      return (
                        <div
                          className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated"
                          data-wow-duration="1500ms"
                          data-wow-delay="00ms"
                          style={{
                            visibility: "visible",
                            animationDuration: "1500ms",
                            animationDelay: "0ms",
                            animationName: "fadeInUp",
                          }}
                          key={i}
                        >
                          <div className="work-process-one__item">
                            <div className="work-process-one__item__image">
                              <img
                                src={gtpath + data.imagename}
                                // src={require("../../../../assets/images/resources/1.jpg")}
                                alt="work process"
                                onError={(e) => errorimghandle(e)}
                              />
                              <button
                                id="openGalleryBtn"
                                className="btn btn-primary work-process-one__item__number"
                                onClick={() =>
                                  handlegcpopen(data.tournament_id)
                                }
                              >
                                Open Gallery
                              </button>
                              <svg
                                className="work-process-one__item__shape"
                                viewBox="0 0 242 67"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
                              </svg>
                              <svg
                                className="work-process-one__item__circle"
                                width="136"
                                height="32"
                                viewBox="0 0 136 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
                              </svg>
                            </div>
                            <div className="work-process-one__item__content">
                              <h3 className="work-process-one__item__title">
                                {data.meet_name}
                              </h3>
                              <p className="work-process-one__item__text">
                                {data.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Modal show={gcpshow} size="xl">
                <Modal.Header>
                  <h4>Gallery</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    {gphoto.map((item, index) => {
                      return (
                        <div className="col-md-2 mt-4" key={index}>
                          <img
                            className="photoimg"
                            src={item.src}
                            height="100px"
                            alt="Photo"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="text-right border-top mt-2">
                    <a className="btn btn-danger mt-2" onClick={handlegcpclose}>
                      <i className="fa fa-times"></i> Close
                    </a>
                  </div>
                </Modal.Footer>
              </Modal>
            </Tab>

            <Tab eventKey={6} title="Photos/Videos and Resources">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5">
                      <h4>Photos/Videos and Resources</h4>
                    </div>

                    <div className="col-md-7">
                      <div className="text-right">
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={handlegshow}
                        >
                          <i className="fa fa-plus"></i> Add Photos/Videos and
                          Resources
                        </a>
                        <a
                          className="btn btn-secondary mt-1"
                          onClick={handlegcshow}
                        >
                          <i className="fa fa-plus"></i> Add Photos From Coach
                          {/* Repository */}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label">Search By Tags</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Tags"
                        value={atags}
                        options={glist}
                        onChange={handleAfilter}
                        isSearchable={true}
                        isClearable
                        isMulti
                        hidden
                      />
                    </div>
                    <div className="col-md-3">
                      {/* <label className="form-label">Background Color</label>
                    <select
                        value={rcolor}
                        onChange={(e) => setRColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                  <Modal show={gshow} size="lg">
                    <Modal.Header>
                      <h4>Add Photos/Videos and Resources</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Select Type <span className="errortext"> *</span>
                            </label>
                            <select
                              className="form-select"
                              value={ptype}
                              onChange={handletype}
                            >
                              <option value="">Select Type</option>
                              <option value="1">Photos</option>
                              <option value="2">Videos</option>
                              <option value="3">Files</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Select Tags <span className="errortext"> *</span>
                            </label>
                            <Select
                              className="dropdown"
                              placeholder="Select Tags"
                              value={gtags}
                              options={glist}
                              onChange={(e) => handleTags(e, 1)}
                              isSearchable={true}
                              isMulti
                              isClearable
                              hidden
                            />
                          </div>
                        </div>
                        {rtags !== "" && (
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Recomanded Tags
                                {/* <span className="errortext"> *</span> */}
                              </label>
                              <div className="row">
                                {rtags.map((item, index) => {
                                  return (
                                    <div className="col-md-4 mt-2">
                                      <a
                                        key={index}
                                        onClick={() => handleTags(item, 2)}
                                        className="btn btn-sm btn-export"
                                      >
                                        {item.label}
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                        <div clabellassName="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Description
                              {/* <span className="errortext"> *</span> */}
                            </label>
                            <textarea
                              rows={3}
                              className="form-control"
                              placeholder="Enter Description"
                              value={gdesc}
                              onChange={(e) => setGDesc(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        {ptype === "1" && (
                          <>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Photos{" "}
                                  <span className="errortext">
                                    {" "}
                                    * (Max size 50mb,Accepted files are jpg,
                                    jpeg, png)
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  multiple
                                  onChange={photofile}
                                  accept=".jpg,.jpeg,.png"
                                />
                              </div>
                            </div>

                            {photolist.length !== 0 && (
                              <div className="row">
                                {photolist.map((data, i) => {
                                  return (
                                    <div className="col-md-2 mt-4" key={i}>
                                      <a
                                        className="photo-mark"
                                        onClick={() => deletecurphoto(i)}
                                      >
                                        <i className="fa fa-trash fa-lg"></i>
                                      </a>
                                      <img
                                        className="photoimg"
                                        src={data}
                                        height="100px"
                                        alt="Photo"
                                        key={i}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        )}

                        {ptype === "2" && (
                          <>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Video Url{" "}
                                  <span className="errortext">* </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Video Url"
                                  onChange={(e) => setVideoUrl(e.target.value)}
                                  value={fvideourl}
                                />
                              </div>
                            </div>
                            <span className="errortext text-center">(OR)</span>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Upload Video{" "}
                                  <span className="errortext">
                                    *{" "}
                                    <span>
                                      (Accepted Files are .mp4,.mp3,.wav,.mkv &
                                      Max Upload Size 50MB )
                                    </span>
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={videofile}
                                  accept=".mp3,.mp4,.wav,.mkv"
                                />
                                {formvideoerror !== "" && (
                                  <span className="errortext">
                                    {formvideoerror}
                                  </span>
                                )}
                                {/* {fvideourl !== "" && (
                       <div className="mt-2">
                         <video
                          id="video"
                          width="100%"
                          controls
                        />
                       </div>
                      )} */}
                              </div>
                            </div>
                          </>
                        )}

                        {ptype === "3" && (
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Files{" "}
                                <span className="errortext">
                                  {" "}
                                  * (Max size 5mb,Accepted Files are .pdf)
                                </span>{" "}
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                accept=".pdf"
                                onChange={resfile}
                              />

                              {reserror !== "" && (
                                <span className="errortext">{reserror}</span>
                              )}
                            </div>
                          </div>
                        )}
                        {rescheck != "" && (
                          <span className="errortext">{rescheck}</span>
                        )}
                        <div className="text-right border-top mt-2">
                          <a
                            className="btn btn-success me-1 mt-2"
                            onClick={addresouce}
                          >
                            <i className="fa fa-edit"></i> Submit
                          </a>

                          <a
                            className="btn btn-danger mt-2"
                            onClick={handlegclose}
                          >
                            <i className="fa fa-times"></i> Close
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  {/* <Modal show={gcshow} size="xl">
                    <Modal.Header>
                      <h4>Add Photos/Videos and Resources</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                            <div className="card-body pt-2 pb-0 px-3">
                              <div className="d-flex align-items-center">
                                <img
                                  src={require("../../../../assets/images/folder.png")}
                                  alt=""
                                  className="folder-img"
                                />
                              </div>
                              <h5 className="my-0">Repository</h5>
                              <p className="mb-1 mt-4">
                                <span>Sample Repository</span>{" "}
                              </p>
                            </div>
                            <div className="card-footer text-center py-2 px-3">
                              <a
                                className="btn btn-sm btn-success me-2"
                                title="Add Photos"
                                onClick={() => addlist(1, "Repository")}
                              >
                                <i className="fa fa-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                          <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                            <div className="card-body pt-2 pb-0 px-3">
                              <div className="d-flex align-items-center">
                                <img
                                  src={require("../../../../assets/images/folder.png")}
                                  alt=""
                                  className="folder-img"
                                />
                              </div>
                              <h5 className="my-0">Repository 2</h5>
                              <p className="mb-1 mt-4">
                                <span>Sample Repository 2</span>{" "}
                              </p>
                            </div>
                            <div className="card-footer text-center py-2 px-3">
                              <a
                                className="btn btn-sm btn-success me-2"
                                title="Add Photos"
                                onClick={() => addlist(2, "Repository 2")}
                              >
                                <i className="fa fa-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                          <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                            <div className="card-body pt-2 pb-0 px-3">
                              <div className="d-flex align-items-center">
                                <img
                                  src={require("../../../../assets/images/folder.png")}
                                  alt=""
                                  className="folder-img"
                                />
                              </div>
                              <h5 className="my-0">Repository 3</h5>
                              <p className="mb-1 mt-4">
                                <span>Sample Repository 3</span>{" "}
                              </p>
                            </div>
                            <div className="card-footer text-center py-2 px-3">
                              <a
                                className="btn btn-sm btn-success me-2"
                                title="Add Photos"
                                onClick={() => addlist(3, "Repository 3")}
                              >
                                <i className="fa fa-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="text-right border-top mt-2">
                        <a
                          className="btn btn-danger mt-2"
                          onClick={handlegcclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </Modal.Footer>
                  </Modal> */}

                  <Modal show={gcshow} size="xl">
                    <Modal.Header>
                      <h4>Add Photos/Videos and Resources from Coach</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label">Search By Tags</label>
                          <Select
                            className="dropdown"
                            placeholder="Select Tags"
                            value={stags}
                            options={glist}
                            onChange={handlefilter}
                            isSearchable={true}
                            isClearable
                            isMulti
                            hidden
                          />
                        </div>
                        {rtags !== "" && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                Recomanded Tags
                                {/* <span className="errortext"> *</span> */}
                              </label>
                              <span>
                                <b>{rtags}</b>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row mt-5">
                        {creslist.map((item, index) => {
                          return (
                            <div
                              className="col-md-2 mt-4"
                              style={{
                                visibility: "visible",
                                animationDuration: "1500ms",
                                animationDelay: "0ms",
                                animationName: "fadeInUp",
                                border: item.file_select
                                  ? "3px solid blue"
                                  : "none",
                              }}
                              key={index}
                            >
                              {item.file_select && (
                                <input
                                  type="checkbox"
                                  checked={item.file_select}
                                  className="gallerycheck"
                                />
                              )}
                              {item.file_type === "Photo" && (
                                <img
                                  onClick={(e) => setone(item.resources_id)}
                                  className="photoimg"
                                  src={respath + item.file_name}
                                  // src={require("../../../../assets/images/resources/12.jpg")}
                                  height="100px"
                                  alt="Photo"
                                />
                              )}

                              {item.file_type === "Video" && (
                                <img
                                  onClick={(e) => setone(item.resources_id)}
                                  className="photoimg"
                                  //  src={respath + item.file_name}
                                  src={require("../../../../assets/images/video.png")}
                                  height="100px"
                                  alt="Photo"
                                />
                              )}

                              {item.file_type === "PDF" && (
                                <img
                                  onClick={(e) => setone(item.resources_id)}
                                  className="photoimg"
                                  //  src={respath + item.file_name}
                                  src={require("../../../../assets/images/pdf.png")}
                                  height="100px"
                                  alt="Photo"
                                />
                              )}
                              {item.mark_as_star === 1 && (
                                <div className="photo-star">
                                  <a>
                                    <i className="fa fa-star fa-lg"></i>
                                  </a>
                                </div>
                              )}
                              <div>
                                <span>
                                  <b>{item.tag_name}</b>
                                </span>
                              </div>

                              <div>
                                <span>
                                  <b>{item.remarks}</b>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        {coacherr !== "" && (
                          <span className="errortext">{coacherr}</span>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="text-right border-top mt-2">
                        <a
                          className="btn btn-success mt-2 me-2"
                          onClick={submitcoachphoto}
                        >
                          <i className="fa fa-plus"></i> Add
                        </a>
                        <a
                          className="btn btn-danger mt-2"
                          onClick={handlegcclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>

              <section
                className="services-three section-space"
                id="services"
                style={{ backgroundColor: rcolor }}
              >
                <div
                  className="services-three__bg"
                  style={{
                    backgroundImage: `url(${require("../../../../assets/images/resources/services-bg-3-1.png")})`,
                  }}
                ></div>
                {reslist.length !== 0 && (
                  <div className="container">
                    <div className="row gutter-y-30">
                      {reslist.map((item, index) => {
                        return (
                          <div
                            className="col-lg-4 col-md-6 wow fadeInUp animated"
                            data-wow-duration="1500ms"
                            data-wow-delay="100ms"
                            key={index}
                          >
                            <div className="services-three__card" height="300px">
                              <a
                                className="gallery-mark"
                                onClick={() => deleteres(item.resources_id)}
                              >
                                <i className="fa fa-trash fa-lg"></i>
                              </a>
                              {item.file_type === "Photo" && (
                                <a
                                  className="services-three__card__image"
                                  onClick={(e) =>
                                    handlepop(
                                      item.file_name,
                                      item.file_type,
                                      item.file_or_url,
                                      item.remarks
                                    )
                                  }
                                >
                                  <img
                                    src={respath + item.file_name} height="300px"
                                    // src={require("../../../../assets/images/resources/12.jpg")}
                                    alt="business loan"
                                  />
                                  <img height="300px"
                                    src={respath + item.file_name}
                                    alt="business loan"
                                  />
                                  <span className="services-three__card__overlay"></span>
                                </a>
                              )}
                              {item.file_type === "Video" && (
                                <a
                                  className="services-three__card__image"
                                  onClick={(e) =>
                                    handlepop(
                                      item.file_name,
                                      item.file_type,
                                      item.file_or_url,
                                      item.remarks
                                    )
                                  }
                                >
                                  <img height="300px"
                                    src={require("../../../../assets/images/video.png")}
                                    alt="business loan"
                                  />
                                  <img height="300px"
                                    src={require("../../../../assets/images/video.png")}
                                    alt="business loan"
                                  />
                                  <span className="services-three__card__overlay"></span>
                                </a>
                              )}

                              {item.file_type === "PDF" && (
                                <a
                                  className="services-three__card__image"
                                  onClick={(e) =>
                                    handlepop(
                                      item.file_name,
                                      item.file_type,
                                      item.file_or_url,
                                      item.remarks
                                    )
                                  }
                                >
                                  <img height="300px"
                                    src={require("../../../../assets/images/pdf.png")}
                                    alt="business loan"
                                  />
                                  <img height="300px"
                                    src={require("../../../../assets/images/pdf.png")}
                                    alt="business loan"
                                  />
                                  <span className="services-three__card__overlay"></span>
                                </a>
                              )}

                              <div className="services-three__card__content">
                                <h3 className="services-three__card__title">
                                  <a>{item.remarks}</a>
                                </h3>
                                {/* <a
                                    className="services-three__card__btn"
                                   
                                  >
                                    <span className="services-three__card__btn__icon">
                                      <i className="icon-arrow-right"></i>
                                    </span>
                                  </a> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </section>

              <Modal show={ishow} size="xl" onHide={imageclose}>
                <Modal.Header>
                  <h4> Resources</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        {pimgtype === "Video" && pimgurl === "url" && (
                          <iframe
                            width="100%"
                            height="300"
                            src={pimage}
                            frameBorder="0"
                            // allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        )}

                        {pimgtype === "Video" && pimgurl !== "url" && (
                          <video controls width="100%" height="300">
                            <source src={pimage} type="video/mp4" />
                          </video>
                        )}

                        {pimgtype === "PDF" && (
                          <iframe
                            width="100%"
                            height="400"
                            src={pimage}
                            allowFullScreen
                          />
                        )}

                        {pimgtype === "Photo" && (
                          <img src={pimage} alt="Photo" width="100%" />
                        )}
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <p>{prem}</p>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={imageclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Tab>

            <Tab eventKey={7} title="Review & Publish">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Review & Publish</h4>
                    </div>
                    <div className="col-md-6 text-right">
                      {spublish != 0 && (
                        <a
                          className="btn btn-secondary"
                          href={WebUrl + puburl}
                          title={WebUrl + puburl}
                          target="_blank"
                        >
                          View Webpage
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="custom-cursor">
                <div className="custom-cursor__cursor"></div>
                <div className="custom-cursor__cursor-two"></div>

                {/* <div className="preloader">
    <div className="preloader__image" style="background-image: url(assets/images/favicons/favicon.ico);"></div>
</div> */}

                <div className="page-wrapper">
                  {/* <section className="main-header main-header--two sticky-header sticky-header--two sticky-header--normal">
                    <div className="container-fluid">
                      <div className="main-header__inner">
                        <div className="main-header__logo logo-retina">
                          <a>
                            <img
                              src={schlogo}
                              // src={require("../../../../assets/images/resources/logo_school2.png")}
                              className="main-header__logo__light"
                              onError={(e) => errorhandle(e)}
                            />
                          </a>
                        </div>
                        <div className="main-header__center-wrap">
                          <h3 className="mb-0">{schname}</h3>
                        </div>
                        <div className="main-header__right-wrap">
                          <div className="main-header__right">
                            <a>
                              <img
                                src={require("../../../../assets/images/logo_sv.png")}
                                width="150"
                                className="main-header__logo__light"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}

                  <header className="main-header main-header--two sticky-header sticky-header--two sticky-header--normal">
                    <div className="container-fluid">
                      <div className="main-header__inner">
                        <div className="main-header__logo logo-retina">
                          <a>
                            <img
                              src={schlogo}
                              onError={(e) => errorhandle(e)}
                              className="main-header__logo__light"
                            />
                          </a>
                        </div>
                        <div className="main-header__center-wrap">
                          <h3 className="mb-0">{schname}</h3>
                        </div>
                        <div className="main-header__right-wrap">
                          <div className="main-header__right">
                            <a>
                              <img
                                src={require("../../../../assets/images/logo_sv.png")}
                                alt=""
                                width="150"
                                className="main-header__logo__light"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                  {schoolshow && (
                    <section
                      className="main-slider-two mt-6"
                      id="home"
                      style={{ backgroundColor: scolor }}
                    >
                      <div className="main-slider-two__inner">
                        <div
                          className="main-slider-two__inner__bg"
                          style={{
                            backgroundImage: `url(${require("../../../../assets/images/resources/main-slider-shape-bg-2-2.png")})`,
                          }}
                        ></div>
                      </div>
                      <div className="main-slider-two__carousel easilon-owl__carousel owl-carousel owl-loaded owl-drag">
                        <div className="owl-stage-outer">
                          <div
                            className="owl-stage"
                            style={{
                              // transform: "translate3d(-2698px, 0px, 0px)",
                              transition: "all 0s ease 0s",
                              // width: "6745px",
                            }}
                          >
                            <div
                              className="owl-item active"
                              // style={{ width: "1100px" }}
                            >
                              <div className="item">
                                <div className="">
                                  {/* main-slider-two__item */}
                                  <div
                                    className="main-slider-two__bg"
                                    style={{
                                      backgroundImage: `url(${require("../../../../assets/images/resources/main-slider-shape-bg-2-1.png")})`,
                                    }}
                                  ></div>
                                  <div className="main-slider-two__social social-links-two">
                                    <a href={sface} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-facebook-f"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Facebook</span>
                                    </a>
                                    <a href={sinsta} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-instagram"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Instagram</span>
                                    </a>
                                    <a href={syoutube} target="_blank">
                                      <span className="social-links-two__icon">
                                        <i
                                          className="fab fa-youtube"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="sr-only">Youtube</span>
                                    </a>
                                  </div>
                                  <div className="main-slider-two__container container">
                                    <div className="row gutter-y-60 align-items-center">
                                      <div className="col-xxl-6 col-xl-7">
                                        <div className="main-slider-two__content">
                                          <div className="main-slider-two__top"></div>
                                          <h2 className="main-slider-two__title">
                                            {shead}
                                          </h2>
                                          <div className="main-slider-two__text-box">
                                            <p className="main-slider-two__text">
                                              {desc}
                                            </p>
                                          </div>
                                          <div className="main-slider-two__content__shape-box">
                                            <div className="main-slider-two__content__shape-circle"></div>
                                          </div>
                                        </div>
                                      </div>
                                      {simage !== "" && (
                                        <div className="col-xxl-6 col-xl-5">
                                          <div className="main-slider-two__image">
                                            <div className="main-slider-two__image__inner">
                                              <img
                                                src={simage}
                                                // src={require("../../../../assets/images/resources/main-slider-image-2-1.jpg")}
                                                alt="slider image"
                                              />
                                            </div>
                                            <div className="main-slider-two__image__shape-box">
                                              <img
                                                src={require("../../../../assets/images/resources/main-slider-shape-2-2.png")}
                                                alt="shape"
                                                className="main-slider-two__image__shape-two"
                                              />
                                              <img
                                                src={require("../../../../assets/images/resources/main-slider-shape-2-3.png")}
                                                alt="shape"
                                                className="main-slider-two__image__shape-three"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  <section className="company-info-one py-5 d-none">
                    <div className="container">
                      <div className="company-info-one__inner">
                        {/* <div className="company-info-one__inner__bg" style="background-image: url(assets/images/backgrounds/company-info-bg-1-1.jpg);"></div> */}
                        <div className="row gutter-y-50">
                          <div
                            className="col-lg-12 col-sm-12 text-center wow fadeInUp"
                            data-wow-duration="1500ms"
                            data-wow-delay="00ms"
                          >
                            <div className="company-info-one__item">
                              <h4 className="company-info-one__item__title">
                                School Statement / Mission Statement
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="blog-two section-space"
                    id="blog"
                    style={{ backgroundColor: tcolor }}
                  >
                    <div className="container">
                      <div className="sec-title sec-title--center">
                        <h3 className="sec-title__title">
                          Tournament and Events
                        </h3>
                        <div className="sec-title__top">
                          <div className="sec-title__shape">
                            <div className="sec-title__shape__one"></div>
                            <div className="sec-title__shape__two"></div>
                          </div>
                          <div className="sec-title__shape">
                            <div className="sec-title__shape__one"></div>
                            <div className="sec-title__shape__two"></div>
                          </div>
                        </div>
                      </div>
                      {tlist.length !== 0 && (
                        <div className="row gutter-y-30">
                          {tlist.map((item, i) => {
                            return (
                              <div className="col-md-3 col-lg-3" key={i}>
                                <div
                                  className="blog-card blog-card--two wow fadeInUp animated"
                                  data-wow-duration="1500ms"
                                  data-wow-delay="00ms"
                                  style={{
                                    visibility: "visible",
                                    animationDuration: "1500ms",
                                    animationDelay: "0ms",
                                    animationName: "fadeInUp",
                                  }}
                                >
                                  <div className="blog-card__content text-center">
                                    <div className="blog-card__date">
                                      <span className="blog-card__date__day">
                                        {moment(item.start_date).format("YYYY")}
                                      </span>
                                      <span className="blog-card__date__month">
                                        {moment(item.start_date).format(
                                          "DD, MMMM"
                                        )}
                                      </span>
                                    </div>

                                    <div className="blog-card__image">
                                      {item.tournament_logo !== null && (
                                        <img
                                          src={tpath + item.tournament_logo}
                                          onError={(e) => errortour(e)}
                                          height="150px"
                                        />
                                      )}
                                      {item.tournament_logo == null && (
                                        <img
                                          src={require("../../../../assets/images/resources/logo_epl.png")}
                                          height="150px"
                                        />
                                      )}
                                    </div>
                                    <h3 className="blog-card__title">
                                      <a>{item.meet_name}</a>
                                    </h3>
                                  </div>
                                  {/* <div className="blog-card__content_bottom text-center">
     <a  className="btn btn-primary">
       Register now
     </a>
   </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {elist.length !== 0 && (
                        <div className="row gutter-y-30 mt-2">
                          {elist.map((item, i) => {
                            return (
                              <div className="col-md-3 col-lg-3" key={i}>
                                <div
                                  className="blog-card blog-card--two wow fadeInUp animated"
                                  data-wow-duration="1500ms"
                                  data-wow-delay="00ms"
                                  style={{
                                    visibility: "visible",
                                    animationDuration: "1500ms",
                                    animationDelay: "0ms",
                                    animationName: "fadeInUp",
                                  }}
                                >
                                  <div className="blog-card__content text-center">
                                    <div className="blog-card__date">
                                      <span className="blog-card__date__day">
                                        {moment(item.start_date).format("YYYY")}
                                      </span>
                                      <span className="blog-card__date__month">
                                        {moment(item.start_date).format(
                                          "DD, MMMM"
                                        )}
                                      </span>
                                    </div>
                                    <div className="blog-card__image">
                                      <img
                                        src={require("../../../../assets/images/resources/logo-pathway.png")}
                                      />
                                    </div>
                                    <h3 className="blog-card__title">
                                      <a>{item.event_name}</a>
                                    </h3>
                                  </div>
                                  {/* <div className="blog-card__content_bottom text-center">
     <a  className="btn btn-primary">
       Register now
     </a>
   </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <img
                      src={require("../../../../assets/images/resources/about-shape-2-1.png")}
                      alt="shape"
                      className="about-two__shape"
                    />
                  </section>

                  <section
                    className="funfact-one"
                    style={{ backgroundColor: mcolor }}
                  >
                    <div className="funfact-one__bg"></div>
                    <div className="container">
                      <div className="row gutter-y-40">
                        <div
                          className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                          data-wow-duration="1500ms"
                          data-wow-delay="00ms"
                          style={{
                            visibility: "visible",
                            animationDuration: "1500ms",
                            animationDelay: "0ms",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="funfact-one__item">
                            <h3 className="funfact-one__item__number count-box counted">
                              <span
                                className="count-text"
                                data-stop="2000"
                                data-speed="1500"
                              >
                                {platses}
                              </span>
                              <span>hrs</span>
                            </h3>
                            <h4 className="funfact-one__item__title">
                            No of Sports Represented
                            </h4>
                          </div>
                        </div>
                        <div
                          className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                          data-wow-duration="1500ms"
                          data-wow-delay="100ms"
                          style={{
                            visibility: "visible",
                            animationDuration: "1500ms",
                            animationDelay: "0ms",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="funfact-one__item">
                            <h3 className="funfact-one__item__number count-box counted">
                              <span
                                className="count-text"
                                data-stop="137"
                                data-speed="1500"
                              >
                                {scount}
                              </span>
                            </h3>
                            <h4 className="funfact-one__item__title">
                            Total Participation
                            </h4>
                          </div>
                        </div>
                        <div
                          className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                          data-wow-duration="1500ms"
                          data-wow-delay="200ms"
                          style={{
                            visibility: "visible",
                            animationDuration: "1500ms",
                            animationDelay: "0ms",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="funfact-one__item">
                            <h3 className="funfact-one__item__number count-box counted">
                              <span
                                className="count-text"
                                data-stop="2"
                                data-speed="1500"
                              >
                                {tcount}
                              </span>
                            </h3>
                            <h4 className="funfact-one__item__title">
                              Tournaments Participated
                            </h4>
                          </div>
                        </div>
                        <div
                          className="funfact-one__col col-lg-3 col-sm-6 wow fadeInUp animated"
                          data-wow-duration="1500ms"
                          data-wow-delay="300ms"
                          style={{
                            visibility: "visible",
                            animationDuration: "1500ms",
                            animationDelay: "0ms",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="funfact-one__item">
                            <h3 className="funfact-one__item__number count-box counted">
                              <span
                                className="count-text"
                                data-stop="7"
                                data-speed="1500"
                              >
                                {mcount}
                              </span>
                            </h3>
                            <h4 className="funfact-one__item__title">Medals</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="testimonials-two @@extraClassName"
                    id="testimonials"
                    style={{ backgroundColor: sscolor }}
                  >
                    <div className="container">
                      <div className="row gutter-y-50 align-items-center">
                        <div className="col-xl-3">
                          <div className="testimonials-two__content">
                            <div className="sec-title @@extraClassName">
                              <div className="sec-title__top">
                                <div className="sec-title__shape">
                                  <div className="sec-title__shape__one"></div>
                                  <div className="sec-title__shape__two"></div>
                                </div>
                                <h6 className="sec-title__tagline">
                                  Glory Narratives
                                </h6>
                              </div>
                              <h3 className="sec-title__title">
                                Success <br /> Stories
                              </h3>
                            </div>
                            <div className="testimonials-two__custome-navs"></div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          {stlist.length !== 0 && (
                            <div className="row">
                              {stlist.map((item, i) => {
                                return (
                                  <div className="col-md-6 mt-2" key={i}>
                                    <div
                                      className="owl-item active"
                                      style={{
                                        marginRight: "30px",
                                      }}
                                    >
                                      <div className="item">
                                        <div className="testimonial-card-two @@extraClassName">
                                          <div
                                            className="testimonial-card-two__inner"
                                            onClick={() =>
                                              handlestory(item.id, 1)
                                            }
                                          >
                                            <div className="testimonial-card-two__top">
                                              <div className="testimonial-card-two__image">
                                                <img
                                                  src={
                                                    stpath + item.article_image
                                                  }
                                                  // src={require("../../../../assets/images/sindhu.jpg")}
                                                />
                                              </div>
                                              <svg
                                                className="testimonial-card-two__icon"
                                                viewBox="0 0 56 41"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M0 25.4448H11.1378L3.71254 40.2951H14.8503L22.2756 25.4448V3.16919H0V25.4448Z"></path>
                                                <path d="M29.7004 3.16919V25.4448H40.8382L33.413 40.2951H44.5508L51.976 25.4448V3.16919H29.7004Z"></path>
                                                <path d="M14.307 21.7756H3.66919V0.5H24.9448V22.1576L17.7105 36.6259H7.69075L14.7542 22.4992L15.116 21.7756H14.307Z"></path>
                                                <path d="M44.0082 21.7756H33.3704V0.5H54.646V22.1576L47.4117 36.6259H37.3919L44.4554 22.4992L44.8172 21.7756H44.0082Z"></path>
                                              </svg>
                                            </div>
                                            <div className="testimonial-card-two__bottom">
                                              <div className="testimonial-card-two__identity">
                                                <h4 className="testimonial-card-two__name">
                                                  {item.article_title}
                                                </h4>
                                                <p className="testimonial-card-two__quote">
                                                  {/* <span>Badminton</span> <br /> */}
                                                  {item.article_description}
                                                </p>
                                              </div>
                                            </div>
                                            {/* <p className="testimonial-card-two__quote">
                                  <span>Athletics </span>
                                  <br /> Gold Medal - xx international
                                </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="work-process-one section-space position-relative"
                    style={{ backgroundColor: gcolor }}
                  >
                    <div
                      className="why-choose-one__bg"
                      style={{
                        backgroundImage: `url(${require("../../../../assets/images/resources/why-choose-bg-1-1.png")})`,
                      }}
                    ></div>
                    <div className="container">
                      <div className="sec-title sec-title--center">
                        <h3 className="sec-title__title">Gallery</h3>
                        <div className="sec-title__top">
                          <div className="sec-title__shape">
                            <div className="sec-title__shape__one"></div>
                            <div className="sec-title__shape__two"></div>
                          </div>
                          <div className="sec-title__shape">
                            <div className="sec-title__shape__one"></div>
                            <div className="sec-title__shape__two"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row gutter-y-30">
                        {gllist.map((data, i) => {
                          return (
                            <div
                              className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated"
                              data-wow-duration="1500ms"
                              data-wow-delay="00ms"
                              style={{
                                visibility: "visible",
                                animationDuration: "1500ms",
                                animationDelay: "0ms",
                                animationName: "fadeInUp",
                              }}
                              key={i}
                            >
                              <div className="work-process-one__item">
                                <div className="work-process-one__item__image">
                                  <img
                                    src={gtpath + data.imagename}
                                    // src={require("../../../../assets/images/resources/1.jpg")}
                                    alt="work process"
                                    onError={(e) => errorimghandle(e)}
                                  />
                                  <button
                                    id="openGalleryBtn"
                                    className="btn btn-primary work-process-one__item__number"
                                    onClick={() =>
                                      handlegcpopen(data.tournament_id)
                                    }
                                  >
                                    Open Gallery
                                  </button>
                                  <svg
                                    className="work-process-one__item__shape"
                                    viewBox="0 0 242 67"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
                                  </svg>
                                  <svg
                                    className="work-process-one__item__circle"
                                    width="136"
                                    height="32"
                                    viewBox="0 0 136 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
                                  </svg>
                                </div>
                                <div className="work-process-one__item__content">
                                  <h3 className="work-process-one__item__title">
                                    {data.meet_name}
                                  </h3>
                                  <p className="work-process-one__item__text">
                                    {data.location}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>

                  <section
                    className="services-three section-space"
                    id="services"
                    style={{ backgroundColor: rcolor }}
                  >
                    <div
                      className="services-three__bg"
                      style={{
                        backgroundImage: `url(${require("../../../../assets/images/resources/services-bg-3-1.png")})`,
                      }}
                    ></div>
                    <div className="container">
                      <div className="services-three__top">
                        <div className="row gutter-y-50 align-col-xl-4 col-md-6s-center">
                          <div className="col-xl-12 col-lg-12">
                            <div className="sec-title @@extraClassName">
                              <h3 className="sec-title__title">Resources</h3>
                              <div className="sec-title__top">
                                <div className="sec-title__shape">
                                  <div className="sec-title__shape__one"></div>
                                  <div className="sec-title__shape__two"></div>
                                </div>
                                <div className="sec-title__shape">
                                  <div className="sec-title__shape__one"></div>
                                  <div className="sec-title__shape__two"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      {reslist.length !== 0 && (
                        <div className="container">
                          <div className="row gutter-y-30">
                            {reslist.map((item, index) => {
                              return (
                                <div
                                  className="col-lg-4 col-md-6 wow fadeInUp animated"
                                  data-wow-duration="1500ms"
                                  data-wow-delay="100ms"
                                  key={index}
                                >
                                  <div className="services-three__card">
                                    <a
                                      className="gallery-mark"
                                      onClick={() =>
                                        deleteres(item.resources_id)
                                      }
                                    >
                                      <i className="fa fa-trash fa-lg"></i>
                                    </a>
                                    {item.file_type === "Photo" && (
                                      <a className="services-three__card__image">
                                        <img
                                          src={respath + item.file_name} height="300px"
                                          // src={require("../../../../assets/images/resources/12.jpg")}
                                          alt="business loan"
                                        />
                                        <img
                                          src={respath + item.file_name} height="300px"
                                          alt="business loan"
                                        />
                                        <span className="services-three__card__overlay"></span>
                                      </a>
                                    )}
                                    {item.file_type === "Video" && (
                                      <a className="services-three__card__image">
                                        <img height="300px"
                                          src={require("../../../../assets/images/video.png")}
                                          alt="business loan"
                                        />
                                        <img height="300px"
                                          src={require("../../../../assets/images/video.png")}
                                          alt="business loan"
                                        />
                                        <span className="services-three__card__overlay"></span>
                                      </a>
                                    )}

                                    {item.file_type === "PDF" && (
                                      <a className="services-three__card__image">
                                        <img height="300px"
                                          src={require("../../../../assets/images/pdf.png")}
                                          alt="business loan"
                                        />
                                        <img height="300px"
                                          src={require("../../../../assets/images/pdf.png")}
                                          alt="business loan"
                                        />
                                        <span className="services-three__card__overlay"></span>
                                      </a>
                                    )}

                                    <div className="services-three__card__content">
                                      <h3 className="services-three__card__title">
                                        <a>{item.remarks}</a>
                                      </h3>
                                      {/* <a className="services-three__card__btn">
                                        <span className="services-three__card__btn__icon">
                                          <i className="icon-arrow-right"></i>
                                        </span>
                                      </a>*/}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                  {spublish == 0 && (
                    <div className="text-center mt-2">
                      <a className="btn btn-secondary" onClick={handlepublish}>
                        {/*  */}
                        Publish School Webpage
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </>
      )}

      {localStorage.getItem("Role") === "7" && (
        <>
          <div className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4>Photos/Videos and Resources</h4>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <a className="btn btn-success me-2" onClick={handlegshow}>
                      <i className="fa fa-plus"></i> Add Photos/Videos and
                      Resources
                    </a>
                    {/* <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label className="form-label">Search By Tags</label>
                  <Select
                    className="dropdown"
                    placeholder="Select Tags"
                    value={stags}
                    options={glist}
                    onChange={handlefilter}
                    isSearchable={true}
                    isClearable
                    isMulti
                    hidden
                  />
                </div>
              </div>
              <Modal show={gshow} size="lg">
                <Modal.Header>
                  <h4>Add Photos/Videos and Resources</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-select"
                          value={ptype}
                          onChange={handletype}
                        >
                          <option value="">Select Type</option>
                          <option value="1">Photos</option>
                          <option value="2">Videos</option>
                          <option value="3">Files</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Tags <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select Tags"
                          value={gtags}
                          options={glist}
                          onChange={(e) => handleTags(e, 1)}
                          isSearchable={true}
                          isMulti
                          isClearable
                          hidden
                        />
                      </div>
                    </div>
                    {rtags !== "" && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Recomanded Tags
                            {/* <span className="errortext"> *</span> */}
                          </label>

                          {rtags.map((item, index) => {
                            return (
                              <div className="col-md-4 mt-2">
                                <a
                                  key={index}
                                  onClick={() => handleTags(item, 2)}
                                  className="btn btn-sm btn-export"
                                >
                                  {item.label}
                                </a>
                              </div>
                            );
                          })}

                          {/* <span>
                            <b>{rtags}</b>
                          </span> */}
                          {/* <Select
     className="dropdown"
     placeholder="Select Recomanded Tags"
     value={rtags}
     options={rlist}
     onChange={handleRTags}
     isSearchable={true}
     isMulti
     isClearable
     hidden
   /> */}
                        </div>
                      </div>
                    )}

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Description
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Enter Description"
                          value={gdesc}
                          onChange={(e) => setGDesc(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          {/* Display the file in Superadmin{" "} */}
                          Marked the file as star
                          <span className="errortext"> *</span>
                        </label>
                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="yes"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Yes
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="yes"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {ptype === "1" && (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Photos{" "}
                              <span className="errortext">
                                {" "}
                                * (Max size 50mb,Accepted files are jpg, jpeg,
                                png)
                              </span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              multiple
                              onChange={photofile}
                              accept=".jpg,.jpeg,.png"
                            />
                          </div>
                        </div>

                        {photolist.length !== 0 && (
                          <div className="row">
                            {photolist.map((data, i) => {
                              return (
                                <div className="col-md-2 mt-4" key={i}>
                                  <a
                                    className="photo-mark"
                                    onClick={() => deletecurphoto(i)}
                                  >
                                    <i className="fa fa-trash fa-lg"></i>
                                  </a>
                                  <img
                                    className="photoimg"
                                    src={data}
                                    height="100px"
                                    alt="Photo"
                                    key={i}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}

                    {ptype === "2" && (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Video Url <span className="errortext">* </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Video Url"
                              onChange={(e) => setVideoUrl(e.target.value)}
                              value={fvideourl}
                            />
                          </div>
                        </div>
                        <span className="errortext text-center">(OR)</span>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Upload Video{" "}
                              <span className="errortext">
                                *{" "}
                                <span>
                                  (Accepted Files are .mp4,.mp3,.wav,.mkv & Max
                                  Upload Size 50MB )
                                </span>
                              </span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              onChange={videofile}
                              accept=".mp3,.mp4,.wav,.mkv"
                            />
                            {formvideoerror !== "" && (
                              <span className="errortext">
                                {formvideoerror}
                              </span>
                            )}
                            {/* {fvideourl !== "" && (
                       <div className="mt-2">
                         <video
                          id="video"
                          width="100%"
                          controls
                        />
                       </div>
                      )} */}
                          </div>
                        </div>
                      </>
                    )}

                    {ptype === "3" && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Files{" "}
                            <span className="errortext">
                              {" "}
                              * (Max size 5mb,Accepted Files are .pdf)
                            </span>{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf"
                            onChange={resfile}
                          />

                          {reserror !== "" && (
                            <span className="errortext">{reserror}</span>
                          )}
                        </div>
                      </div>
                    )}
                    {rescheck != "" && (
                      <span className="errortext">{rescheck}</span>
                    )}
                    <div className="text-right border-top mt-2">
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={addresouce}
                      >
                        <i className="fa fa-edit"></i> Submit
                      </a>

                      <a className="btn btn-danger mt-2" onClick={handlegclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <Modal show={ishow} size="xl" onHide={imageclose}>
            <Modal.Header>
              <h4> Resources</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    {pimgtype === "Video" && pimgurl === "url" && (
                      <iframe
                        width="100%"
                        height="300"
                        src={pimage}
                        frameBorder="0"
                        // allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    )}

                    {pimgtype === "Video" && pimgurl !== "url" && (
                      <video controls width="100%" height="300">
                        <source src={pimage} type="video/mp4" />
                      </video>
                    )}

                    {pimgtype === "PDF" && (
                      <iframe
                        width="100%"
                        height="400"
                        src={pimage}
                        allowFullScreen
                      />
                    )}

                    {pimgtype === "Photo" && (
                      <img src={pimage} alt="Photo" width="100%" />
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12}>
                  <p>{prem}</p>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-danger" onClick={imageclose}>
                Close
              </a>
            </Modal.Footer>
          </Modal>

          <div className="row">
            {creslist.map((data, i) => {
              return (
                <div className="col-md-2 mt-4" key={i}>
                  {data.mark_as_star === 1 && (
                    <a className="photogal-star">
                      <i className="fa fa-star fa-lg"></i>
                    </a>
                  )}

                  <a
                    className="photogal-mark"
                    onClick={() => deleteres(data.resources_id)}
                  >
                    <i className="fa fa-trash fa-lg"></i>
                  </a>

                  {data.file_type === "Photo" && (
                    <img
                      onClick={() =>
                        handlepop(
                          data.file_name,
                          data.file_type,
                          data.file_or_url,
                          data.remarks
                        )
                      }
                      className="photoimg"
                      src={respath + data.file_name}
                      height="100px"
                      alt="Photo"
                    />
                  )}
                  {data.file_type === "Video" && (
                    <img
                      onClick={() =>
                        handlepop(
                          data.file_name,
                          data.file_type,
                          data.file_or_url,
                          data.remarks
                        )
                      }
                      className="photoimg"
                      src={require("../../../../assets/images/video.png")}
                      height="100px"
                      alt="Photo"
                    />
                  )}

                  {data.file_type === "PDF" && (
                    <img
                      onClick={() =>
                        handlepop(
                          data.file_name,
                          data.file_type,
                          data.file_or_url,
                          data.remarks
                        )
                      }
                      className="photoimg"
                      src={require("../../../../assets/images/pdf.png")}
                      height="100px"
                      alt="Photo"
                    />
                  )}
                  <span>
                    <b>{data.tag_name}</b>
                  </span>
                  {/* <span className="mt-1">{data.remarks}</span> */}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
