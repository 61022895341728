import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { reasonlist, rejectedreasoncreate, rejectedreasondetails, rejectedreasonlist, rejectedreasonstatus, rejectedreasonupdate, rejectedreasonvalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const RejectedResMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [reaid, setId] = useState(0);
    const [reason, setReason] = useState("");
    const [order, setOrder] = useState("");
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
      }, []);

    const columnDefs = [
        {
            headerName: "Reason",
            field: "reason_name",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        // {
        //     headerName: "Order",
        //     width: 150,
        //     field: "order_no",
        //     sortable: true,
        //     resizable: true,
        //     filter: true,
        //     floatingFilter: true,
        //     cellStyle: { textAlign: "left" },
        // },
        {
            headerName: "Status",
            field: "status",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deleterejrea(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "id",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a
                            onClick={() => viewreject(params.value)} title="Edit Reason"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>

                );
            },
        },
    ];

    function handleshow() {
        setId(0);
        setReason("");
        setOrder("");
        setErrorMsg("");
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setReason("");
        setOrder("");
        setErrorMsg("");
    }

    function viewreject(aid) {
        setShow(true)
        
        rejectedreasondetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.id)
                setReason(response.data.reason_name)
                setOrder(response.data.order_no)
                setStatus(response.data.status + "")
                dotracking("edit button in rejected reason list for " + response.data.reason_name + " (" + aid + ") $$ " + trackobj.reasonlistheading)
            }
        })
    }

    function deleterejrea(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("status", 0)
                    dotracking(
                        "status icon in rejected reason list from Active to Inactive for " + list.data.reason_name + " (" + list.data.id + ") $$ " + trackobj.reasonlistheading
                    )
                    rejectedreasonstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("status", 1)
                    dotracking(
                        "status icon in rejected reason list from Inactive to Active for " + list.data.reason_name + " (" + list.data.id + ") $$ " + trackobj.reasonlistheading
                    )
                    rejectedreasonstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }
    useEffect(() => {
        listall();
    }, []);

    function listall() {
        setFilterProduct([]);
        rejectedreasonlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }


    const handleOrder = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setOrder(e.target.value);
        }
    };
    function submitrejrea(e) {
        e.preventDefault();
        setErrorMsg("");
        if (reason !== "") {
            if (order !== "") {
                if (reaid === 0) {
                    // rejectedreasonvalidate().then((res)=>{
                    //     if(res.status === true){
                            const temp = new FormData();
                            temp.append("reason_name", reason)
                            // temp.append("order_no", order)
                            temp.append("status", status)
                            dotracking("add button in rejected reason for " + reason + " $$ " + trackobj.reasonlistheading)
                            rejectedreasoncreate(temp).then((response) => {
                                if (response.status === true) {
                                    handleclose();
                                    listall();
                                    toast.success(response.message);
                                }
                            })
                        // }else{
                        //     Swal.fire({
                        //         text: agename + " " + res.message,
                        //         icon: "warning",
                        //         showCancelButton: false,
                        //         confirmButtonColor: "#4ecc48",
                        //         cancelButtonColor: "#6259ca",
                        //         confirmButtonText: "Ok",
                        //         allowOutsideClick: false,
                        //     }).then((result) => {
                        //         if (result.isConfirmed) {
                        //             setId(0);
                        //             setReason("");
                        //             setOrder("");
                        //             setErrorMsg("");
                        //         }
                        //     });
                        // }
                    // })
                    
                } else if (reaid !== 0) {
                    const temp = new FormData();
                    temp.append("id", reaid)
                    temp.append("reason_name", reason)
                    // temp.append("order_no", order)
                    temp.append("status", status)
                    dotracking("update button in rejected reason for " + reason + " (" + reaid + ") $$ " + trackobj.reasonlistheading)
                    rejectedreasonupdate(temp).then((response) => {
                        if (response.status === true) {
                            handleclose();
                            listall();
                            toast.success(response.message);
                        }
                    })
                }
            } else {
                setErrorMsg("Please Enter Order")
            }
        } else {
            setErrorMsg("Please Enter Reason")
        }
    }
    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Rejected Reason Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        Rejected Reason Lists
                    </li>
                </ol>
            </div>
            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            {localStorage.getItem("Subrole") === "1" &&
                                <div className="text-right">
                                    <a className="btn btn-success" onClick={handleshow}>
                                        <i className="fa fa-plus"></i> Add Reason
                                    </a>
                                </div>
                            }
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>

                                    {reaid !== 0 &&
                                        <h4> Reject Reason Details</h4>
                                    }
                                    {reaid === 0 &&
                                        <h4>Add Reject Reason</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Reason{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Reason"
                                                    className="form-control"
                                                    value={reason}
                                                    onChange={(e) => setReason(e.target.value)}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>

                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="text-danger">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {reaid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitrejrea}
                                        >
                                            Update
                                        </a>
                                    }
                                    {reaid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitrejrea}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>


                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
