import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  multipleroleslist,
  multirolesstatus,
} from "../../../../services/superadmin";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { funderlistcity } from "../../../../services/cityregionaladmin";

export const ListFunder = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "UID",
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = "";
        if (params.data.first_name !== null) {
          evt = params.data.first_name;
        }
        if (params.data.last_name !== null) {
          if (params.data.last_name !== "") {
            if (evt === "") {
              evt = params.data.last_name;
            } else if (evt !== "") {
              evt = evt + " " + params.data.last_name;
            }
          }
        }
        return evt;
      },
    },
    {
      headerName: "Email",
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      field: "funder_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deletecrm(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data)}
              title="Edit Funder"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnCDefs = [
    {
      headerName: "UID",
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = "";
        if (params.data.first_name !== null) {
          evt = params.data.first_name;
        }
        if (params.data.last_name !== null) {
          if (params.data.last_name !== "") {
            if (evt === "") {
              evt = params.data.last_name;
            } else if (evt !== "") {
              evt = evt + " " + params.data.last_name;
            }
          }
        }
        return evt;
      },
    },
    {
      headerName: "Email",
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      field: "funder_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data)}
              title="Edit Funder"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  function deletecrm(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("remarks", "Active");
          temp.append("status", 0);
          temp.append("role_id", 8);
          temp.append("user_id", list.data.user_id);
          temp.append("admin_id", localStorage.getItem("UID"));
          dotracking(
            "status icon in funder list from Active to Inactive for " +
              list.data.first_name +
              " " +
              list.data.last_name +
              " (" +
              list.data.username +
              " - " +
              list.data.user_id +
              ") $$ " +
              trackobj.funlistheading
          );

          multirolesstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("remarks", "Inactive");
          temp.append("status", 1);
          temp.append("role_id", 8);
          temp.append("user_id", list.data.user_id);
          temp.append("admin_id", localStorage.getItem("UID"));
          dotracking(
            "status icon in funder list from Inactive to Active for " +
              list.data.first_name +
              " " +
              list.data.last_name +
              " (" +
              list.data.username +
              " - " +
              list.data.user_id +
              ") $$ " +
              trackobj.funlistheading
          );
          multirolesstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  function viewlist(eid, edata) {
    dotracking(
      "view icon in funder list for " +
        edata.first_name +
        " " +
        edata.last_name +
        " (" +
        edata.username +
        " - " +
        eid +
        ") $$ " +
        trackobj.fundetheading
    );

    const url = window.location.href;
    const head = url.split("/#/");
    if (localStorage.getItem("Role") === "1") {
      window.open(`${head[0]}/#/superadmin/viewfunder/` + eid, "_blank");
    } else if (localStorage.getItem("Role") === "3") {
      window.open(`${head[0]}/#/cityregional/viewfunder/` + eid, "_blank");
    }
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    if (localStorage.getItem("Role") === "1") {
      multipleroleslist(8, 1).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      funderlistcity(8, localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setFilterProduct(response.data);
        }
      });
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Funder Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Funder</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Funder Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style mt-2">
                {localStorage.getItem("Role") === "1" && (
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                )}
                {localStorage.getItem("Role") === "3" && (
                  <AgGridReact
                    columnDefs={columnCDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
