import { AgGridReact } from "ag-grid-react";
import React, { Fragment, useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import {
  addathletedelete,
  addathleteplayerlist,
  addeventsplayerlist,
} from "../../../../../services/common";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { Reftourdetail } from "../../../../../services/referee";
export const AddedPlayersList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");
  const [schoolid, setSchool] = useState("");

  const { id, eid } = useParams();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Username",
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Age Category",
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      field: "table_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.data)}
              title="Delete Player"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  function viewdet(vdata) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in tournament player list for (" +
            vdata.table_id +
            ") " +
            vdata.first_name +
            " (" +
            vdata.user_id +
            ") " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        addathletedelete(vdata.table_id, localStorage.getItem("UID")).then(
          (response) => {
            if (response.status === true) {
              if (localStorage.getItem("Role") === "4") {
                listall(schoolid);
              } else {
                listall(eid);
              }

              toast.success(response.message);
            }
          }
        );
      }
    });
  }
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      listall(eid);
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      listall(eid);
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      schprofileview().then((resp) => {
        if (resp.status === true) {
          setSchool(resp.data.school_id);
          listall(resp.data.school_id);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      listall(eid);
    }
  }, []);

  function listall(sid) {
    addeventsplayerlist(id, sid).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.details);
      }
    });
  }
  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourschoollistheading
      );
      navigate("/superadmin/athleticschoollist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/cityregional/tournamentschoollist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      dotracking(
        "back button in tournament player list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/schoolhead/tournamentlist");
    }
  }

  function summlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "summary button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/superadmin/summary/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "summary button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/cityregional/summary/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      dotracking(
        "summary button in tournament player list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/schoolhead/summary/" + id);
    }
  }
  function addlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "add players button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touraddaplayerheading
      );
    } else {
      dotracking(
        "add players button in tournament player list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touraddaplayerheading
      );
    }

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/addathletes/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/addathletes/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/addathletes/" + id);
    }
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Added Players</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Added Players
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7">
                    <h3>{tname}</h3>
                  </div>
                  <div className="col-md-5">
                    <div className="text-right">
                      {localStorage.getItem("Role") !== "5" && (
                        <>
                          <a className="btn btn-success me-2" onClick={addlist}>
                            <i className="fa fa-plus"></i> Add Players
                          </a>
                        </>
                      )}
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3" ||
                        localStorage.getItem("Role") === "4") && (
                        <a
                          className="btn btn-secondary me-2"
                          onClick={summlist}
                        >
                          <i className="fa-solid fa-list-alt"></i> Review &
                          Submit
                        </a>
                      )}

                      <a className="btn btn-gray me-2" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
