import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  playertourpayment,
  playertourregister,
  tourdetails,
} from "../services/register";
import moment from "moment";
import Swal from "sweetalert2";
import { Existsornotplayer, Playerviewdetails } from "../services/player";
import { logindata } from "../services/login";
import dotracking from "./trackinglogs";
import Select from "react-select";

export const TournamentEntry = () => {
  const { id } = useParams();
  const { schoolid } = useParams();

  const navigate = useNavigate();

  const [tname, setTName] = useState("");
  const [tloc, setTLoc] = useState("");
  const [tcode, setTCode] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [odate, setODate] = useState("");
  const [ddate, setDDate] = useState("");
  const [eclosed, setEclosed] = useState(false);
  const [free, setFree] = useState("");

  const [agecat, setAgeCat] = useState("");
  const [agetype, setAgeType] = useState("");

  const [school, setSchool] = useState("");
  const [sports, setSports] = useState("");
  const [tourcls, setTCls] = useState("");

  const [cir, setCir] = useState("");

  const [amt, setAmount] = useState("0");

  const [user, setUser] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [sname, setSName] = useState("");
  const [clsname, setClsName] = useState("");
  const [secname, setSecName] = useState("");

  const [tshow, setTShow] = useState(true);
  const [tinvalid, setTInvalid] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [paypage, setPaypage] = useState(false);
  const [pass, setPassword] = useState("");

  function regsubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (user !== "") {
      if (tourtype === "") {
        Existsornotplayer(user, id).then((response) => {
          if (response.status === true) {
            setTShow(false);
            setTInvalid(true);
            Playerviewdetails(user).then((res) => {
              if (res.status === true) {
                setPassword(res.data.password);
                setFName(res.data.athletelist.first_name);
                setLName(res.data.athletelist.last_name);
                if (res.data.athletelist.date_of_birth !== null) {
                  setDOB(
                    moment(res.data.athletelist.date_of_birth).format(
                      "DD-MM-YYYY"
                    )
                  );
                }
                setGender(res.data.athletelist.gender);
                setMobNo(res.data.athletelist.mobile_no);
                setEmail(res.data.athletelist.email_id);
                setSName(res.data.athletelist.school_name);
                setClsName(res.data.athletelist.class_name);
                setSecName(res.data.athletelist.section);
              }
            });
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg(tourtype);
      }
    } else {
      setErrorMsg("Please Enter Username");
    }
  }

  function backsubmit() {
    setTShow(true);
    setTInvalid(true);
    setUser("");
    setFName("");
    setLName("");
    setDOB("");
    setGender("");
    setEmail("");
    setMobNo("");
    setSName("");
    setClsName("");
    setSecName("");
    setErrorMsg("");
    setCheck(false);
  }

  function playersubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    var valid = "";
    if (
      sports.toUpperCase() === "BADMINTON" ||
      sports.toUpperCase() === "TENNIS" ||
      sports.toUpperCase() === "TABLE TENNIS" ||
      sports.toUpperCase() === "SQUASH"
    ) {
      if (catid.length !== 0) {
        valid = "";
      } else {
        valid = "Please Select Category Type";
        setErrorMsg("Please Select Category Type");
      }
    }
    if (valid === "") {
      if (check === true) {
        setLoading(true);
        const temp = new FormData();
        temp.append("username", user);
        temp.append("tournament_meet_id", id);
        temp.append("school_id", schoolid);
        dotracking(
          "submit button in tournament register for " +
            tname +
            " (" +
            id +
            ") tournament from player " +
            user +
            " in school " +
            schoolid
        );
        if (
          sports.toUpperCase() === "BADMINTON" ||
          sports.toUpperCase() === "TENNIS" ||
          sports.toUpperCase() === "TABLE TENNIS" ||
          sports.toUpperCase() === "SQUASH"
        ) {
          let sdata = "";
          let ddata = "";
          let sddata = "";

          if (catid.length !== 0) {
            sdata = catid.find((item) => item.value === "1");
            ddata = catid.find((item) => item.value === "2");
            sddata = catid.find((item) => item.value === "3");
          }
          if (sdata !== undefined) {
            temp.append("singles", 1);
          } else {
            temp.append("singles", 0);
          }
          if (ddata !== undefined) {
            temp.append("doubles", 1);
          } else {
            temp.append("doubles", 0);
          }
          if (sddata !== undefined) {
            temp.append("mixed_doubles", 1);
          } else {
            temp.append("mixed_doubles", 0);
          }
        } else {
          temp.append("singles", 0);
          temp.append("doubles", 0);
          temp.append("mixed_doubles", 0);
        }
        playertourregister(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);

            if (free === "N") {
              paynow(
                response.data.RAZORPAY_KEY,
                response.data.entries.order_id,
                response.data.entries.user_id
              );
            } else if (free === "Y") {
              setPaypage(true);
            }
          } else {
            setLoading(false);
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg("Please Select Disclaimer");
      }
    }
  }
  const [check, setCheck] = useState(true);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  async function paynow(pkey, ordid, uid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Tournament Registration",
      image: require("../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("user_id", uid);
        temp.append("tournament_meet_id", id);
        setLoading(true);
        playertourpayment(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);
            setTInvalid("");
            setPaypage(true);
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  const [tourtype, setTourType] = useState("");

  useEffect(() => {
    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        if (response.data.tournament.circular_file_1 !== null) {
          setCir(
            response.data.circular_path +
              response.data.tournament.circular_file_1
          );
        }
        setFree(response.data.tournament.free_tournament);
        if (
          response.data.tournament.sports_type.toUpperCase() === "ATHLETICS" ||
          response.data.tournament.sports_type.toUpperCase() === "BADMINTON" ||
          response.data.tournament.sports_type.toUpperCase() === "TENNIS" ||
          response.data.tournament.sports_type.toUpperCase() ===
            "TABLE TENNIS" ||
          response.data.tournament.sports_type.toUpperCase() === "SQUASH"
        ) {
          if (
            response.data.tournament.meet_type === "Inter School Tournament"
          ) {
            setTourType(
              "Player Registration is not available for the tournament " +
                response.data.tournament.meet_name
            );
          }
        }
        setAgeType(response.data.tournament.agecat_or_std);
        setTName(response.data.tournament.meet_name);
        setTLoc(response.data.tournament.location);
        setTCode(response.data.tournament.meet_code);
        setSDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY")
        );
        setEDate(
          moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setODate(
          moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
        );
        if (
          moment(new Date()).format("yyyy-MM-DD") >
          response.data.tournament.entry_deadline_date
        ) {
          setEclosed(true);
        }
        setDDate(
          moment(response.data.tournament.entry_deadline_date).format(
            "DD-MM-YYYY"
          )
        );
        setAgeCat(response.data.tournament.categories);
        setSports(response.data.tournament.sports_type);
        setAmount(response.data.tournament.registration_fees);

        if (response.data.tournament.registration_fees === 0) {
          setAmount("0");
        }
        if (response.data.tournament.agecat_or_std === 1) {
          let arr1 = "";

          setTCls(response.data.tournament.categories);
        }

        if (response.data.mappedschools !== null) {
          for (let i = 0; i < response.data.mappedschools.length; i++) {
            if (response.data.mappedschools[i].school_id + "" === schoolid) {
              setTInvalid(true);
              setSchool(
                response.data.mappedschools[i].school_unique_no +
                  " - " +
                  response.data.mappedschools[i].school_name
              );
              break;
            } else {
              setTInvalid(false);
            }
          }
        }
      }
    });
  }, []);
  const [loading, setLoading] = useState(false);

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button from tournament register,redirect to Player dashboard"
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        setErrorMsg(response.message);
      }
    });
  }

  const [catid, setCatId] = useState([]);
  const [catlist, setCatList] = useState([
    { value: "1", label: "Singles" },
    { value: "2", label: "Doubles" },
    { value: "3", label: "Mixed Doubles" },
  ]);
  function handleCategory(e) {
    if (e === null) {
      setCatId([]);
    } else {
      setCatId(e);
    }
  }

  return (
    <div id="loginpage" className="app sidebar-mini rtl">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      <main className="md-overflow">
        <div className="container">
          <div className="row h-95">
            <div className="col-md-4 pe-0 tab-100 order_a mob-100">
              <div className="side slideup ">
                <div className="img-top">
                  <img
                    src={require("../assets/images/tagline.png")}
                    alt="tagline"
                    className="tagline_logo"
                  />
                </div>
                <article className="text-center">
                  <a href="http://pathwayz.sportzvillage.com/" target="_blank">
                    <img
                      src={require("../assets/images/pathwayz_logo.png")}
                      alt="pathwayz"
                      className="pathwayz_logo"
                    />
                  </a>
                </article>
                <div className="social">
                  <span>Follow Us</span>
                  <a
                    href="https://www.facebook.com/SportzVillage/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@sportzvillage7534"
                    target="_blank"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/sportz-village/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                  <a href="https://twitter.com/sportzvillage" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </div>
              </div>
              <div className="border-b slideup"></div>
            </div>
            {eclosed && (
              <div className="col-md-8 ps-0 tab-100 mob-100">
                <div className="slidedown border-t"></div>
                <div className="form slidedown formregis">
                  <h3 className="text-center text-danger">
                    Registeration Closed
                  </h3>
                </div>
              </div>
            )}
            {!paypage && !eclosed && (
              <>
                {!tinvalid && (
                  <div className="col-md-8 ps-0 tab-100 mob-100">
                    <div className="slidedown border-t"></div>
                    <div className="form slidedown formregis">
                      <h3 className="text-center">
                        There is no tournament for this school
                      </h3>
                    </div>
                  </div>
                )}
                {tinvalid && (
                  <div className="col-md-8 ps-0 tab-100 mob-100">
                    <div className="slidedown border-t"></div>
                    <div className="form slidedown formregis">
                      {tshow && (
                        <div>
                          <div className="form-text login mb-3">
                            <h4 className="mt-0 mb-4">Tournament Details</h4>
                          </div>

                          <div className="row" id="tournamentbox">
                            <div className="col-md-12">
                              <div className="card bg-none">
                                <div className="card-body p-0">
                                  <div className="clearfix pb-3 border-bottom">
                                    <div className="float-left">
                                      <h1 className="card-title tourheader mb-0">
                                        {tname}
                                      </h1>
                                    </div>

                                    {/* <div className="float-right">
                                <h3 className="card-title">Amount: <b className='matchstatus px-2 py-1'>{amt}</b></h3>
                              </div> */}
                                  </div>
                                  <div className="row datedetails mx-0">
                                    <div className="col-md-3 col-sm-6">
                                      <h3 className="card-title">
                                        Start Date <span>{sdate}</span>
                                      </h3>
                                    </div>
                                    <div className="col-md-3 col-sm-6 border-left">
                                      <h3 className="card-title">
                                        End Date <span>{edate}</span>
                                      </h3>
                                    </div>
                                    <div className="col-md-3 col-sm-6 border-left">
                                      <h3 className="card-title">
                                        Entry Last Date <span>{ddate}</span>
                                      </h3>
                                    </div>
                                    {sports.toUpperCase() !== "ATHLETICS" && (
                                      <div className="col-md-3 col-sm-6 border-left">
                                        <h3 className="card-title">
                                          Amount{" "}
                                          <span>
                                            {free === "N" && (
                                              <b className="matchstatus px-2 py-1">
                                                Rs. {amt} /-
                                              </b>
                                            )}
                                            {free === "Y" && (
                                              <b className="matchstatus px-2 py-1">
                                                Free
                                              </b>
                                            )}
                                          </span>
                                        </h3>
                                      </div>
                                    )}
                                  </div>
                                  <div className="schoolname border-bottom px-3">
                                    <h3>
                                      School Name :{" "}
                                      <b className="font-weight-bold text-muted">
                                        {school}
                                      </b>
                                    </h3>
                                  </div>
                                  <div className="row datedetails  mx-0">
                                    {agetype === 1 && (
                                      <div className="col-md-12">
                                        <h3 className="card-title">
                                          Class :{" "}
                                          <b className="font-weight-bold text-muted">
                                            {tourcls}
                                          </b>
                                        </h3>
                                      </div>
                                    )}

                                    {agetype === 2 && (
                                      <div className="col-md-12">
                                        <h3 className="card-title">
                                          Age Categories :{" "}
                                          <b className="font-weight-bold text-muted">
                                            {agecat}
                                          </b>
                                        </h3>
                                      </div>
                                    )}
                                  </div>

                                  <div className="row datedetails mx-0">
                                    <div className="col-md-6">
                                      <h3 className="card-title">
                                        Sports :{" "}
                                        <b className="font-weight-bold text-muted">
                                          {sports}
                                        </b>
                                      </h3>
                                    </div>
                                    {cir !== "" && (
                                      <div className="col-md-6 border-left">
                                        <h3 className="card-title">
                                          Circular :{" "}
                                          <a
                                            href={cir}
                                            target="_blank"
                                            download={cir}
                                            className="text-danger"
                                          >
                                            Click Here{" "}
                                            <i className="fa fa-download"></i>
                                          </a>
                                        </h3>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <form id="loginform" className="login registrabtn">
                            <div className="row">
                              <div className="col-md-12 text-center">
                                <h4>New to Student Athlete Hub ?</h4>
                                <button
                                  className="form-btn login btn-info-gradient mx-auto"
                                  type="button"
                                  onClick={() =>
                                    navigate("/register/" + id + "/" + schoolid)
                                  }
                                >
                                  <span>Click to Register</span>
                                </button>
                              </div>
                            </div>
                            <h5 className="seperation">(OR)</h5>
                            <h4>Register with Username (UID)</h4>
                            <div className="input-field">
                              <label>
                                <i className="fa-solid fa-user"></i>Username
                              </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="Enter Username"
                                    required
                                    autoFocus
                                    autoComplete="off"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <button
                                    className="form-btn login mt-0 btn-success"
                                    type="button"
                                    onClick={regsubmit}
                                  >
                                    <span>Register</span>
                                  </button>
                                </div>
                                {errorMsg !== "" && (
                                  <span className="errortext">{errorMsg}</span>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      )}

                      {!tshow && (
                        <div>
                          <div className="form-text login">
                            <h4>Player Details</h4>
                          </div>
                          <div className="row tournamententry">
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">Username</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">{user}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">Full Name</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {fname}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Last Name</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{lname}</span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">
                                      Date of Birth
                                    </b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">{dob}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">Gender</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {gender}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">
                                      Contact Number
                                    </b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {mobno}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">
                                      Email Address
                                    </b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">School</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {sname}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">Class</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {clsname}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">Section</b>
                                  </div>
                                  <div className="col-md-9">
                                    <span className="text-inverse">
                                      {secname}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b className="text-inverse">
                                      Category Type
                                    </b>
                                  </div>
                                  <div className="col-md-9">
                                    <Select
                                      className="dropdown"
                                      placeholder="Select Category Type"
                                      value={catid}
                                      options={catlist}
                                      onChange={handleCategory}
                                      isSearchable={true}
                                      isMulti
                                      isClearable
                                      hidden
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form id="loginform" className="login mt-5">
                            <h4>Disclaimer</h4>
                            <div className="disc-field">
                              <input
                                type="checkbox"
                                onChange={checkterm}
                                checked={check}
                              />
                              <label className="pl-3">
                                By registering my child, I acknowledge and
                                accept that participation involves inherent
                                risks, and I release the school and organizers
                                from liability for any injuries during the event
                                and grant permission to use event photographs
                                for promotional purposes.
                              </label>
                            </div>

                            {errorMsg !== "" && (
                              <span className="errortext">{errorMsg}</span>
                            )}
                            <div className="row">
                              <div className="col-md-12 text-right">
                                <button
                                  className="form-btn btn-secondary me-2"
                                  type="button"
                                  onClick={backsubmit}
                                >
                                  <span>Back</span>
                                </button>
                                <button
                                  className="form-btn btm-danger me-2"
                                  type="button"
                                  onClick={playersubmit}
                                >
                                  <span>Register</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {paypage && !eclosed && (
              <div className="col-md-8 ps-0 tab-100 mob-100">
                <div className="slidedown border-t"></div>
                <div className="form slidedown">
                  <div className="text-center background-text">
                    <h1 className="mb-3 font-weight-semibold text-danger">
                      Registration successful !
                    </h1>
                  </div>
                  <div className="background-text">
                    <h4 className="mb-3 font-weight-semibold">
                      Hi {fname} {lname},
                    </h4>
                    <div className="successmeg">
                      <p>
                        You have successfully registered for the{" "}
                        <b className="text-success">{tname}</b>. Kindly check
                        your email.
                      </p>
                    </div>
                  </div>

                  <div className="text-center">
                    <a onClick={dashlogin} className="btn btn-gray">
                      Go to Dashboard
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
