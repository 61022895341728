import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tourdetails } from "../../../services/register";
import { useState } from "react";
import { highlightslist } from "../../../services/superadmin";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import { playertourbanner } from "../../../services/player";
import bannerlog from "../../trackingbanner";

export const HighlightDetailPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tname, setTName] = useState("");
  const [filterProduct, setFilterProduct] = useState([]);
  const [tourbanner, setTourBanner] = useState("");
  const [linkbanner, setLinkBanner] = useState("");
  const [bannid, setBannerID] = useState("");

  useEffect(() => {
    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        setTName(response.data.tournament.meet_name);
      }
    });

    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    temp.append("pagename", "Highlights");
    playertourbanner(temp).then((res) => {
      if (res.status === true && res.data.banners.length !== 0) {
        setBannerID(res.data.banners[0].banner_id);
        setTourBanner(res.data.banner_path + res.data.banners[0].b_file);
        if (res.data.banners[0].banner_link !== null) {
          setLinkBanner(res.data.banners[0].banner_link);
        }

      }
    });

    highlightslist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  function backlist() {
    dotracking(
      "back button in highlights detail for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.highheading
    );
    navigate("/player/highlights");
  }

  function banlink() {
    dotracking("banner in highlights for " + tourbanner + " (" + bannid + ") in " + tname + " (" + id + ") tournament $$ " + trackobj.highdetheading);
    bannerlog(bannid,trackobj.bannerhighlights);
    if(linkbanner !== ""){
      window.open(linkbanner, "_blank");
    }
    
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Highlights</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Highlights
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-2">
                  <div className="text-right">
                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              {tourbanner !== "" && (
                <a onClick={banlink}>
                  <img
                    src={tourbanner}
                    className="ag-courses-item_img"
                    alt="bannerimg"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {filterProduct.length === 0 && (
          <div className="col-md-12">
            <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
              <div className="card-body pt-2 pb-0 px-3">
                <div className="text-center">
                  <h4 className="text-danger">
                    Highlights will be updated soon
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
        {filterProduct.map((item, i) => {
          return (
            <div className="col-md-12" key={i}>
              <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                {/* <div className="card-body pt-2 pb-0 px-3"> */}
                {/* <div className="text-center"> */}
                <img
                  src={require("../../../assets/images/Highlights.jpg")}
                  alt=""
                  className="high-img mt-2 px-3 w-100"
                />
                {/* </div> */}
                {/* </div> */}
                <div className="text-center py-1 px-3">
                  <a
                    className="btn btn-success w-100"
                    href={item.highlight_links}
                    target="_blank"
                  >
                    <i className="fas fa-eye me-2"></i>Click Here
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
