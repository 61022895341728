import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { classcreate, classdetails, classlist, classstatus, classupdate, classvalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ClassMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [clsid, setId] = useState(0);
    const [clsname, setClsName] = useState("");
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "Class",
            field: "class",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "is_active",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deleteclass(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "id",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a onClick={() => viewclass(params.value)} title="Edit Class"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>
                );
            },
        },
    ];

    function viewclass(aid) {
        setShow(true)
        classdetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.id)
                setClsName(response.data.class)
                setStatus(response.data.is_active + "")
                dotracking("edit button in class for "+ response.data.class + " (" + aid + ") $$ " + trackobj.classlistheading)

            }
        })
    }

    function handleshow() {
        setId(0);
        setClsName("")
        setStatus("1")
        setErrorMsg("")
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setClsName("")
        setStatus("1")
        setErrorMsg("")
    }

    function deleteclass(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 0)
                    dotracking(
                        "status icon in class list from Active to Inactive for " + list.data.class + " (" + list.data.id + ") $$ " + trackobj.classlistheading
                    )
                    classstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 1)
                    dotracking(
                        "status icon in class list from Inactive to Active for " + list.data.class + " (" + list.data.id + ") $$ " + trackobj.classlistheading
                    )
                    classstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }
    useEffect(() => {
        listall();
    }, []);

    function listall() {
        setFilterProduct([]);
        classlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function submitclass(e) {
        e.preventDefault();
        setErrorMsg("")
        if (clsname !== "") {

            if (clsid === 0) {
                const temp1 = new FormData();
                temp1.append("class_name", clsname)
                classvalidate(temp1).then((res) => {
                    if (res.status === true) {
                        const temp = new FormData();
                        temp.append("class", clsname)
                        temp.append("is_active", status)
                        dotracking("add button in class list for " + clsname + " $$ " + trackobj.classlistheading)
                        classcreate(temp).then((response) => {
                            if (response.status === true) {
                                handleclose();
                                listall();
                                toast.success(response.message);
                            }
                        })
                    } else {
                        Swal.fire({
                            text: clsname + " " + res.message,
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#4ecc48",
                            cancelButtonColor: "#6259ca",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setId(0);
                                setClsName("")
                                setStatus("1")
                                setErrorMsg("")
                            }
                        });
                    }
                })

            } else if (clsid !== 0) {
                const temp = new FormData();
                temp.append("id", clsid)
                temp.append("class", clsname)
                temp.append("is_active", status)
                dotracking("update button in class list for " + clsname + " (" + clsid + ") $$ " + trackobj.classlistheading)
                classupdate(temp).then((response) => {
                    if (response.status === true) {
                        handleclose();
                        listall();
                        toast.success(response.message);
                    }
                })
            }
        } else {
            setErrorMsg("Please Enter Class Name")
        }
    }

    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Class Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        Class Lists
                    </li>
                </ol>
            </div>
            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            <div className="text-right">
                                <a className="btn btn-success" onClick={handleshow}>
                                    <i className="fa fa-plus"></i> Add Class
                                </a>
                            </div>
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {clsid !== 0 &&
                                        <h4> Class Details</h4>
                                    }
                                    {clsid === 0 &&
                                        <h4> Add Class</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Class Name{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Class Name"
                                                    className="form-control"
                                                    value={clsname}
                                                    onChange={(e) => setClsName(e.target.value)}
                                                />
                                            </div>
                                        </Col>


                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>

                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {clsid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitclass}
                                        >
                                            Update
                                        </a>
                                    }
                                    {clsid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitclass}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
