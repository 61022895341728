import React, { useEffect, useState } from "react";
import {
  schedulelist,
  schedulenoknocklist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../services/superadmin";
import { useNavigate, useParams } from "react-router-dom";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import moment from "moment/moment";
import { tourdetails } from "../../../../services/register";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Reftourdetail } from "../../../../services/referee";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../services/coach";

export const Schedule = () => {
  const { id } = useParams();
  const { eid } = useParams();

  const navigate = useNavigate();

  const [catname, setCatname] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);
  const [filterday, setFilterDay] = useState("");
  const [qualchk, setQualChk] = useState("");

  const [qualround, setQualRound] = useState([]);
  const [qualday, setQualDay] = useState("");

  const [semiround, setSemiRound] = useState([]);
  const [semiday, setSemiDay] = useState("");

  const [fround, setFinalRound] = useState([]);
  const [ofround, setOFinalRound] = useState([]);

  const [finalday, setFinalDay] = useState("");

  const [knockday, setKnockDay] = useState("");

  const [lshow, setLShow] = useState(false);
  const [qshow, setQShow] = useState(false);
  const [sshow, setSShow] = useState(false);
  const [fshow, setFShow] = useState(false);
  const [kshow, setKShow] = useState(false);

  const [tname, setTName] = useState("");
  let arr = [];
  let arr2 = [];
  let arr3 = [];
  let arr4 = [];
  let arr5 = [];

  const [wteam, setWTeam] = useState("");
  const [rteam, setRTeam] = useState("");
  const [srteam, setSRTeam] = useState("");
  const [urllink,setUrlLink]=useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
        if (res.data.category_details.quoter_final_status === "4") {
          const temp = new FormData();
          temp.append("tournament_id", id);
          temp.append("category_id", eid);
          schedulenoknocklist(temp).then((response) => {
            if (response.status === true) {
              if (response.data.no_knockout_data.length !== 0) {
                setWTeam(response.data.no_knockout_data[0].winner_team_name);
                setRTeam(response.data.no_knockout_data[0].runner_team_name);
                setSRTeam(response.data.no_knockout_data[0].two_runner_team_name);
              }
            }
          });
        }
      }
    });

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 
    schedulelist(id, eid).then((response) => {
      if (response.status === true) {
        const temp7 = new FormData();
        temp7.append("category_id", eid);
        tourcategorydetail(temp7).then((response) => {
          if (response.status === true) {
            setQualChk(response.data.category_details.quoter_final_status);
            setLShow(true);
            if (response.data.category_details.quoter_final_status === "1") {
              setQShow(true);
              setSShow(true);
              setFShow(true);
            } else if (
              response.data.category_details.quoter_final_status === "2"
            ) {
              setSShow(true);
              setFShow(true);
            } else if (
              response.data.category_details.quoter_final_status === "3"
            ) {
              setFShow(true);
            } else if (
              response.data.category_details.quoter_final_status === "4"
            ) {
              setKShow(true);
            }
          }
        });
        if (response.data.pool_arr.length !== 0) {
          for (let i = 0; i < response.data.pool_arr.length; i++) {
            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              if (response.data.data_fixtures[j].match_round === 1) {
                if (
                  response.data.pool_arr[i].meet_pool_id ===
                  response.data.data_fixtures[j].pool_id
                ) {
                  var dte = "";
                  if (response.data.data_fixtures[j].match_date !== null) {
                    dte = response.data.data_fixtures[j].match_date;
                  }
                  arr.push({
                    pool_name: response.data.pool_arr[i].pool_name,
                    team1_name: response.data.data_fixtures[j].team1_name,
                    team1_score: response.data.data_fixtures[j].team1_score,
                    team2_name: response.data.data_fixtures[j].team2_name,
                    team2_score: response.data.data_fixtures[j].team2_score,
                    match_date: dte,
                    match_time: response.data.data_fixtures[j].match_time,
                    winner_name: response.data.data_fixtures[j].winner_name,
                  });
                }
              }
            }
          }
          setLShow(true);

          for (let j = 0; j < response.data.data_fixtures.length; j++) {
            // if (response.data.fixture_info.qualification_check === 1) {
            //   setQShow(true);
            if (response.data.data_fixtures[j].match_round === 2) {
              var dte = "";
              if (response.data.data_fixtures[j].match_date !== null) {
                dte = response.data.data_fixtures[j].match_date;
              }
              arr2.push({
                team1_name: response.data.data_fixtures[j].team1_name,
                team1_score: response.data.data_fixtures[j].team1_score,
                team2_name: response.data.data_fixtures[j].team2_name,
                team2_score: response.data.data_fixtures[j].team2_score,
                match_date: dte,
                match_time: response.data.data_fixtures[j].match_time,
                winner_name: response.data.data_fixtures[j].winner_name,
              });
            }
            // }
            if (response.data.data_fixtures[j].match_round === 3) {
              var dte = "";
              if (response.data.data_fixtures[j].match_date !== null) {
                dte = response.data.data_fixtures[j].match_date;
              }
              arr3.push({
                team1_name: response.data.data_fixtures[j].team1_name,
                team1_score: response.data.data_fixtures[j].team1_score,
                team2_name: response.data.data_fixtures[j].team2_name,
                team2_score: response.data.data_fixtures[j].team2_score,
                match_date: dte,
                match_time: response.data.data_fixtures[j].match_time,
                winner_name: response.data.data_fixtures[j].winner_name,
              });
            } else if (response.data.data_fixtures[j].match_round === 4) {
              var dte = "";
              if (response.data.data_fixtures[j].match_date !== null) {
                dte = response.data.data_fixtures[j].match_date;
              }
              arr4.push({
                team1_name: response.data.data_fixtures[j].team1_name,
                team1_score: response.data.data_fixtures[j].team1_score,
                team2_name: response.data.data_fixtures[j].team2_name,
                team2_score: response.data.data_fixtures[j].team2_score,
                match_date: dte,
                match_time: response.data.data_fixtures[j].match_time,
                winner_name: response.data.data_fixtures[j].winner_name,
              });
            }else if (response.data.data_fixtures[j].match_round === 5) {
              var dte = "";
              if (response.data.data_fixtures[j].match_date !== null) {
                dte = response.data.data_fixtures[j].match_date;
              }
              arr5.push({
                team1_name: response.data.data_fixtures[j].team1_name,
                team1_score: response.data.data_fixtures[j].team1_score,
                team2_name: response.data.data_fixtures[j].team2_name,
                team2_score: response.data.data_fixtures[j].team2_score,
                match_date: dte,
                match_time: response.data.data_fixtures[j].match_time,
                winner_name: response.data.data_fixtures[j].winner_name,
              });
            }
          }
          if (arr.length !== 0) {
            setFilterDay(arr[0].match_date);
          }
          setFilterProduct(arr);
          if (arr2.length !== 0) {
            setQualDay(arr2[0].match_date);
          }
          setQualRound(arr2);

          if (arr3.length !== 0) {
            setSemiDay(arr3[0].match_date);
          }
          setSemiRound(arr3);
          if (arr4.length !== 0) {
            setFinalDay(arr4[0].match_date);
          }
          setFinalRound(arr4);


          if (arr5.length !== 0) {
            setFinalDay(arr4[0].match_date);
          }
          setOFinalRound(arr5);
        }
      }
    });
  }, []);

  function backlist() {
    if (localStorage.getItem("Role") === "6") {
      if(urllink === "fixturelist"){
        dotracking(
          "back button in schedule list for "+ catname + " (" + eid + ") category in " + tname + " (" + id + ") tournament $$ " + trackobj.viewtourheading
        )
        navigate("/player/tournamentdetail/" + id)

      }else if(urllink === "schedulelist"){
        dotracking(
          "back button in schedule list for "+ catname + " (" + eid + ") category in " + tname + " (" + id + ") tournament $$ " + trackobj.reglistheading
        )
        navigate("/player/registeredlist")

      }
    }else if (localStorage.getItem("Role") === "4"){
      dotracking(
        "back button in schedule list for "+ catname + " (" + eid + ") category in " + tname + " (" + id + ") tournament $$ " + trackobj.categorylistheading
      )
    }else{
      dotracking(
        "back button in schedule list for "+ catname + " (" + eid + ") category in " + tname + " (" + id + ") tournament $$ " + trackobj.fixallocateheading
      )
    }
   
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/categorylist/" + id );
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/scheduleallocate/" + id + "/" + eid);
    } 
  }

  const [aact, setAAct] = useState(true);
  const [lact, setLAct] = useState(false);
  const [qact, setQAct] = useState(false);
  const [sact, setSAct] = useState(false);
  const [fact, setFAct] = useState(false);
  const [kact, setKAct] = useState(false);

  function tabschedule(head) {
    setAAct(false);
    setLAct(false);
    setQAct(false);
    setSAct(false);
    setFAct(false);
    setKAct(false);
    setLShow(false);
    setQShow(false);
    setSShow(false);
    setFShow(false);
    setKShow(false);
    if (head === "All") {
      setLShow(true);
      setAAct(true);
      if (qualchk === "1") {
        setQShow(true);
        setSShow(true);
        setFShow(true);
      } else if (qualchk === "2") {
        setSShow(true);
        setFShow(true);
      } else if (qualchk === "3") {
        setFShow(true);
      }
    } else if (head === "League") {
      setLAct(true);
      setLShow(true);
    } else if (head === "Quarter") {
      // if (qualround.length !== 0) {
      setQShow(true);
      // }
      setQAct(true);
    } else if (head === "Semi") {
      setSShow(true);
      setSAct(true);
    } else if (head === "Final") {
      setFShow(true);
      setFAct(true);
    } else if (head === "Knockout") {
      setKShow(true);
      setKAct(true);
    }
  }
  const downloadPDF = () => {
    dotracking("download PDF button in schedule list for " + catname + " (" + eid + ") in " + tname + " (" + id + ") tournament $$ " + trackobj.fixlistheading)
    
    const pdf = new jsPDF();

    const content = document.getElementById("pdf-content");

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save("Fixture.pdf");
    });
  };

  return (
    <div className="side-app" id="pdf-content">
      <div className="row">
        <div className="col-12">
          <div className="card mt-0 bg-transparent">
            <div className="card-body w-100 px-0 pt-4 border-bottom">
              <div className="row">
                <div className="col-md-8 align-self-center">
                  <h3>{tname}</h3>
                  <h4 className="text-success tx-20">Category - {catname}</h4>
                </div>
                <div className="col-md-4">
                  <div className="text-right">
                    <a className="btn btn-gray me-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                    <a className="btn btn-secondary me-2" onClick={downloadPDF}>
                      <i className="fa fa-file-pdf"></i> Download PDF
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="" id="scheduleitems">
        <div className="scheduleitems_head">
          <ul
            className="nav nav-pills nav-pills-circle"
            id="tabs_2"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={
                  aact ? "nav-link border active me-2" : "nav-link border me-2"
                }
                onClick={() => tabschedule("All")}
              >
                <span className="nav-link-icon d-block">All</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  lact ? "nav-link border active me-2" : "nav-link border me-2"
                }
                onClick={() => tabschedule("League")}
              >
                <span className="nav-link-icon d-block">Leagues</span>
              </a>
            </li>
            {qualchk === "1" && (
              <li className="nav-item">
                <a
                  className={
                    qact
                      ? "nav-link border active me-2"
                      : "nav-link border me-2"
                  }
                  onClick={() => tabschedule("Quarter")}
                >
                  <span className="nav-link-icon d-block">Quarter Finals</span>
                </a>
              </li>
            )}
            {qualchk >= "1" && qualchk < "3" && (
              <li className="nav-item">
                <a
                  className={
                    sact
                      ? "nav-link border active me-2"
                      : "nav-link border me-2"
                  }
                  onClick={() => tabschedule("Semi")}
                  sact
                >
                  <span className="nav-link-icon d-block">Semi Finals</span>
                </a>
              </li>
            )}
            {qualchk >= "1" && qualchk !== "4" && (
              <li className="nav-item">
                <a
                  className={
                    fact ? "nav-link border active" : "nav-link border"
                  }
                  onClick={() => tabschedule("Final")}
                >
                  <span className="nav-link-icon d-block">Finals</span>
                </a>
              </li>
            )}
            {qualchk === "4" && (
              <li className="nav-item">
                <a
                  className={
                    kact ? "nav-link border active" : "nav-link border"
                  }
                  onClick={() => tabschedule("Knockout")}
                >
                  <span className="nav-link-icon d-block">No Knockout</span>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="scheduleitems_inner">
          {lshow && (
            <div className="scheduleitems_item">
              <div className="scheduleitems_date d-flex justify-content-between align-self-center">
                {filterday !== "" && (
                  <h4>
                    {moment(filterday).format("DD MMMM yyyy")} (
                    <span>
                      {new Date(filterday).toLocaleDateString("en-Us", {
                        weekday: "long",
                      })}
                    </span>
                    )
                  </h4>
                )}
                <h4>League Match</h4>
              </div>
              <hr id="eight" data-symbol="✂" />
              <div className="row">
                {filterProduct.length === 0 && (
                  <div className="col-md-4 col-lg-4">
                    <div className="scheduleitems_box bg-white">
                      <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                        <h5>Match 1</h5>
                      </div>
                      <div className="scheduleitems_box_2">
                        <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                        <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                      </div>
                      <div className="scheduleitems_box_3 text-center">
                        <p>-</p>
                      </div>
                      <span className="scheduleitems_ft">ft</span>
                    </div>
                  </div>
                )}

                {filterProduct.map((data, i) => {
                  return (
                    <div className="col-md-4 col-lg-4" key={i}>
                      <div className="scheduleitems_box bg-white">
                        <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                          <h5>
                            Match {i + 1}, {data.pool_name}
                          </h5>
                          <h5>{data.match_time}</h5>
                        </div>
                        <div className="scheduleitems_box_2">
                          <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team1_name}
                            </h5>
                            <h4>{data.team1_score}</h4>
                          </div>
                          <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team2_name}
                            </h5>
                            <h4>{data.team2_score}</h4>
                          </div>
                        </div>
                        <div className="scheduleitems_box_3 text-center">
                          <p>{data.winner_name} Won</p>
                        </div>
                        <span className="scheduleitems_ft">ft</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {qshow && (
            <div className="scheduleitems_item">
              <div className="scheduleitems_date d-flex justify-content-between align-self-center">
                {qualday !== "" && (
                  <h4>
                    {moment(qualday).format("DD MMMM yyyy")} (
                    <span>
                      {new Date(qualday).toLocaleDateString("en-Us", {
                        weekday: "long",
                      })}
                    </span>
                    )
                  </h4>
                )}
                <h4>Quarter Final Match</h4>
              </div>
              <hr id="eight" data-symbol="✂" />
              <div className="row">
                {qualround.length === 0 && (
                  <div className="col-md-4 col-lg-4">
                    <div className="scheduleitems_box bg-white">
                      <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                        <h5>Match 1</h5>
                      </div>
                      <div className="scheduleitems_box_2">
                        <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                        <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                      </div>
                      <div className="scheduleitems_box_3 text-center">
                        <p>-</p>
                      </div>
                      <span className="scheduleitems_ft">ft</span>
                    </div>
                  </div>
                )}
                {qualround.map((data, i) => {
                  return (
                    <div className="col-md-4 col-lg-4" key={i}>
                      <div className="scheduleitems_box bg-white">
                        <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                          <h5>Match {i + 1}</h5>
                          <h5>{data.match_time}</h5>
                        </div>
                        <div className="scheduleitems_box_2">
                          <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team1_name}
                            </h5>
                            <h4>{data.team1_score}</h4>
                          </div>
                          <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team2_name}
                            </h5>
                            <h4>{data.team2_score}</h4>
                          </div>
                        </div>
                        <div className="scheduleitems_box_3 text-center">
                          <p>{data.winner_name} Won</p>
                        </div>
                        <span className="scheduleitems_ft">ft</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {sshow && (
            <div className="scheduleitems_item">
              <div className="scheduleitems_date d-flex justify-content-between align-self-center">
                {semiday !== "" && (
                  <h4>
                    {moment(semiday).format("DD MMMM yyyy")} (
                    <span>
                      {new Date(semiday).toLocaleDateString("en-Us", {
                        weekday: "long",
                      })}
                    </span>
                    )
                  </h4>
                )}
                <h4>Semifinal Match</h4>
              </div>
              <hr id="eight" data-symbol="✂" />
              <div className="row">
                {semiround.length === 0 && (
                  <div className="col-md-4 col-lg-4">
                    <div className="scheduleitems_box bg-white">
                      <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                        <h5>Match 1</h5>
                      </div>
                      <div className="scheduleitems_box_2">
                        <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                        <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                      </div>
                      <div className="scheduleitems_box_3 text-center">
                        <p>-</p>
                      </div>
                      <span className="scheduleitems_ft">ft</span>
                    </div>
                  </div>
                )}
                {semiround.map((data, i) => {
                  return (
                    <div className="col-md-4 col-lg-4" key={i}>
                      <div className="scheduleitems_box bg-white">
                        <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                          <h5>Match {i + 1}</h5>
                          <h5>{data.match_time}</h5>
                        </div>
                        <div className="scheduleitems_box_2">
                          <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team1_name}
                            </h5>
                            <h4>{data.team1_score}</h4>
                          </div>
                          <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team2_name}
                            </h5>
                            <h4>{data.team2_score}</h4>
                          </div>
                        </div>
                        <div className="scheduleitems_box_3 text-center">
                          <p>{data.winner_name} Won</p>
                        </div>
                        <span className="scheduleitems_ft">ft</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {fshow && (
            <div className="scheduleitems_item">
              <div className="scheduleitems_date d-flex justify-content-between align-self-center">
                {finalday !== "" && (
                  <h4>
                    {moment(finalday).format("DD MMMM yyyy")} (
                    <span>
                      {new Date(finalday).toLocaleDateString("en-Us", {
                        weekday: "long",
                      })}
                    </span>
                    )
                  </h4>
                )}
                <h4>Final Match</h4>
              </div>
              <hr id="eight" data-symbol="✂" />
              <div className="row">
                
              {ofround.length === 0 && (
                 <>
                  <div className="col-md-4 col-lg-4">
                    <div className="scheduleitems_box bg-white">
                      <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                        <h5>3rd Place</h5>
                      </div>
                      <div className="scheduleitems_box_2">
                        <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                        <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                      </div>
                      <div className="scheduleitems_box_3 text-center">
                        <p>-</p>
                      </div>
                      <span className="scheduleitems_ft">ft</span>
                    </div>
                  </div>

                  

                  
                 </>
                )}
                {fround.length === 0 && (
                 <>
                  <div className="col-md-4 col-lg-4">
                    <div className="scheduleitems_box bg-white">
                      <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                        <h5>Finals</h5>
                      </div>
                      <div className="scheduleitems_box_2">
                        <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                        <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                          <h5>
                            <i className="fas fa-futbol me-2"></i>TBC
                          </h5>
                        </div>
                      </div>
                      <div className="scheduleitems_box_3 text-center">
                        <p>-</p>
                      </div>
                      <span className="scheduleitems_ft">ft</span>
                    </div>
                  </div>

                  

                  
                 </>
                )}
                {ofround.map((data, i) => {
                  return (
                    <div className="col-md-4 col-lg-4" key={i}>
                      <div className="scheduleitems_box bg-white">
                        <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                          <h5>3rd Place</h5>
                          <h5>{data.match_time}</h5>
                        </div>
                        <div className="scheduleitems_box_2">
                          <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team1_name}
                            </h5>
                            <h4>{data.team1_score}</h4>
                          </div>
                          <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team2_name}
                            </h5>
                            <h4>{data.team2_score}</h4>
                          </div>
                        </div>
                        <div className="scheduleitems_box_3 text-center">
                        <p>{data.winner_name} Won</p>
                        </div>
                        <span className="scheduleitems_ft">ft</span>
                      </div>
                    </div>
                  );
                })}
                {fround.map((data, i) => {
                  return (
                    <div className="col-md-4 col-lg-4" key={i}>
                      <div className="scheduleitems_box bg-white">
                        <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                          <h5>Finals</h5>
                          <h5>{data.match_time}</h5>
                        </div>
                        <div className="scheduleitems_box_2">
                          <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team1_name}
                            </h5>
                            <h4>{data.team1_score}</h4>
                          </div>
                          <div className="scheduleitems_team_2 d-flex justify-content-between align-self-center">
                            <h5>
                              <i className="fas fa-futbol me-2"></i>
                              {data.team2_name}
                            </h5>
                            <h4>{data.team2_score}</h4>
                          </div>
                        </div>
                        <div className="scheduleitems_box_3 text-center">
                          <p>{data.winner_name} Won</p>
                        </div>
                        <span className="scheduleitems_ft">ft</span>
                      </div>
                    </div>
                  );
                })}




              </div>
            </div>
          )}

          {kshow && (
            <div className="scheduleitems_item">
              <div className="scheduleitems_date d-flex justify-content-between align-self-center">
                {knockday !== "" && (
                  <h4>
                    {moment(knockday).format("DD MMMM yyyy")} (
                    <span>
                      {new Date(knockday).toLocaleDateString("en-Us", {
                        weekday: "long",
                      })}
                    </span>
                    )
                  </h4>
                )}
                <h4>No Knockout Matches & Direct Winners from the group Matches</h4>
              </div>
              <hr id="eight" data-symbol="✂" />
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="scheduleitems_box bg-white">
                    <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                      <h5>Winner</h5>
                    </div>
                    <div className="scheduleitems_box_2">
                      <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                        {wteam === "" && <h5>TBC</h5>}
                        {wteam !== "" && <h5>{wteam}</h5>}
                      </div>
                    </div>
                    <img
                      className="scheduleitems_medal"
                      src={require("../../../../assets/images/goldcup.png")}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-lg-4">
                  <div className="scheduleitems_box bg-white">
                    <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                      <h5>Runner</h5>
                    </div>
                    <div className="scheduleitems_box_2">
                      <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                        {rteam === "" && <h5>TBC</h5>}
                        {rteam !== "" && <h5>{rteam}</h5>}
                      </div>
                    </div>
                    <img
                      className="scheduleitems_medal"
                      src={require("../../../../assets/images/silvercup.png")}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-lg-4">
                  <div className="scheduleitems_box bg-white">
                    <div className="scheduleitems_box_1 d-flex justify-content-between align-self-center border-bottom">
                      <h5>2nd Runner</h5>
                    </div>
                    <div className="scheduleitems_box_2">
                      <div className="scheduleitems_team_1 d-flex justify-content-between align-self-center">
                        {srteam === "" && <h5>TBC</h5>}
                        {srteam !== "" && <h5>{srteam}</h5>}
                      </div>
                    </div>
                    <img
                      className="scheduleitems_medal"
                      src={require("../../../../assets/images/bronzecup.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
