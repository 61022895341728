import React, { Fragment } from "react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { useEffect } from "react";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addathleteplayerlist,
  checkpaymentpaidornot,
} from "../../../../../services/common";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  crmprofileview,
  crmtourviewdetail,
} from "../../../../../services/cityregionaladmin";
import { tourviewdetail } from "../../../../../services/superadmin";
export const ConfirmationPlayer = () => {
  const [tname, setTName] = useState("");
  const { id, eid } = useParams();
  const navigate = useNavigate();
  const [plist, setPList] = useState([]);
  const [s3path, setS3Path] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });

      addathleteplayerlist(id, eid).then((response) => {
        if (response.status === true) {
          setPList(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });

      checkpaymentpaidornot(id, eid, 0).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Pending") {
            navigate("/cityregional/addathletes/" + id + "/" + eid);
          }
        }
      });
      addathleteplayerlist(id, eid).then((response) => {
        if (response.status === true) {
          setPList(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });

      schprofileview().then((resp) => {
        if (resp.status === true) {
          checkpaymentpaidornot(id, resp.data.school_id, 0).then((response) => {
            if (response.status === true) {
              if (response.data.Payment_Status === "Pending") {
                navigate("/schoolhead/addathletes/" + id);
              }
            }
          });
          addathleteplayerlist(id, resp.data.school_id).then((response) => {
            if (response.status === true) {
              setPList(response.data.details);
              setS3Path(response.data.s3path);
            }
          });
        }
      });
    }
  }, []);
  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in confirmation for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/superadmin/tournamentlist");
    }else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in confirmation for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if(localStorage.getItem("Page") === "1"){
        dotracking(
          "back button in confirmation for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      }else if(localStorage.getItem("Page") === "3"){
        dotracking(
        "back button in confirmation for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.comtourlistheading
      );
      navigate("/schoolhead/completedtournament");
        }
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Confirmation</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Confirmation
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <a className="btn btn-gray mx-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              {localStorage.getItem("Role") !== "1" && (
                <div className="row mt-5">
                  <div className="text-center text-white background-text ">
                    <h1 className="mb-3 text-success">
                      Thank you for submitting all the entries !
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div id="playerlist">
                <div className="row">
                  {plist.map((data, i) => {
                    return (
                      <div className="col-md-4" key={i}>
                        <div className="box">
                          <div className="box__head">
                            <h1>{data.first_name}</h1>
                          </div>
                          <div className="box__content">
                            <h2>
                              <b>Player UID</b> <span>{data.username}</span>
                            </h2>
                            <h2>
                              <b>Class</b> <span>{data.classname}</span>
                            </h2>
                            <h2>
                              <b>Section</b> <span>{data.section}</span>
                            </h2>
                            <h2>
                              <b>Age Category</b>{" "}
                              <span>{data.age_category}</span>
                            </h2>
                            <h2>
                              <b>BIB Number</b>{" "}
                              <span>{data.bib_no}</span>
                            </h2>
                          </div>
                          <div className="box_footer">
                            <h4>Events :-</h4>
                            <p>{data.events}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
