import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const apiUrl = process.env.REACT_APP_API_PROXY;
export async function sahassessmentdatelist(data, toke) {
  try {
    return fetch(`${apiUrl}/esonline-int/retrieve_data_via_api/api/v1/stdnt_asmnt_data/?data_req=${data}&token=${toke}`,{
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => catcherror(error));

  } catch (error) {
    catcherror(error);
  }
}

// export async function sahassessmentdatelist(data, toke) {
//   try {
//     return await axios
//       .get(
//         `${apiUrl}/esonline-int/retrieve_data_via_api/api/v1/stdnt_asmnt_data?data_req=${data}&token=${toke}`
//       )
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         catcherror(error);
//       });
//   } catch (error) {
//     catcherror(error);
//   }
// }

export async function schoolteamplayeryesorno(id) {
  try {
    return await axios
      .get(`${baseUrl}/school-head-user-list/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error);
  if (error.response.status === 401) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title:
        "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //       window.location.reload();
      //   }
    });
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
