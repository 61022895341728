import React, { useEffect, useState } from "react";
import {
  blocked,
  gettokenfromapi,
  mergerornotplayer,
  paymentdetailadmin,
  playermappedintotour,
  reasonlist,
  sendpaymentlink,
  similarmergeplayer,
  similarplayerlist,
  similarunmergeplayer,
  tourparticipatedplayerlist,
  unblocked,
  viewprofile,
} from "../../../../services/superadmin";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Modal, Table } from "react-bootstrap";
import { schoolviewplayer } from "../../../../services/schoolhead";
import {
  citymergerornotplayer,
  cityplayerview,
  citysendpaymentlink,
  citysimilarmergeplayer,
  citysimilarunmergeplayer,
} from "../../../../services/cityregionaladmin";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import Swal from "sweetalert2";
import { param } from "jquery";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachplayerview } from "../../../../services/coach";
import { funderplayerdetail } from "../../../../services/funder";
import {
  playerotherachievementlist,
  Playerprofiledetails,
} from "../../../../services/player";
import { sahassessmentdatelist } from "../../../../services/sahapi";

export const Viewathlete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [uname, setUser] = useState("");
  const [status, setStatus] = useState("");
  const [school, setSchool] = useState("");
  const [clsname, setClass] = useState("");
  const [secname, setSection] = useState("");

  const [regdate, setRegDate] = useState("");

  const [realist, setReaList] = useState([]);
  const [rreason, setRReason] = useState("");
  const [rremark, setRemarks] = useState("");

  const [urlhead, setUrl] = useState("");

  const [maplist, setMapList] = useState([]);
  const [simlist, setSimList] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);

  const [fitList, setFitList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [assdate, setAssDate] = useState("");
  const [skildate, setSkillDate] = useState("");


  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Actions",
      width: 150,
      field: "link",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        if (localStorage.getItem("Role") === "4") {
          if (params.value === "Link") {
            return (
              <div className="text-center">
                <a className="text-success">-</a>
              </div>
            );
          } else if (params.value !== "") {
            return (
              <div className="text-center">
                <a className="text-danger">{params.value}</a>
              </div>
            );
          }
        } else if (localStorage.getItem("Role") === "5") {
          if (params.value === "Link") {
            return (
              <div className="text-center">
                <a className="text-success">-</a>
              </div>
            );
          } else if (params.value !== "") {
            return (
              <div className="text-center">
                <a className="text-danger">{params.value}</a>
              </div>
            );
          }
        } else {
          if (params.value === "Link") {
            return (
              <div className="text-center">
                <a
                  className="btn-sm btn-success"
                  onClick={() => sendpayment(params)}
                >
                  Send Payment Link
                </a>
              </div>
            );
          } else if (params.value !== "") {
            return (
              <div className="text-center">
                <a className="text-danger">{params.value}</a>
              </div>
            );
          }
        }
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entryopen",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entrydeadline",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "School",
      width: 170,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment ID",
      width: 150,
      field: "payment_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order ID",
      width: 150,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Date",
      width: 190,
      field: "payment_date_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy h:mm:ss A");
        }
      },
    },
  ];

  const columnPDefs = [
    {
      headerName: "Tournament",
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      field: "level",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament Date",
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Group",
      field: "category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Position",
      field: "position",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnADefs = [
    {
      headerName: "Action",
      field: "date",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              title="Assessment Detail"
              className="btn-sm btn-secondary"
              onClick={() => viewasslist(params.value)}
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Assessment Date",
      field: "date",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          let origString = params.value;
          let stringToAdd = "-";
          let indexPosition = 2;
          let newString1 =
            origString.slice(0, indexPosition) +
            stringToAdd +
            origString.slice(indexPosition);
          let indexPosition1 = 5;
          let newString =
            newString1.slice(0, indexPosition1) +
            stringToAdd +
            newString1.slice(indexPosition1);
          return newString;
        }
      },
    },
    {
      headerName: "School",
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      field: "std",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      field: "section",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "height",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      field: "overall_grade",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

   
  ];
  function sendpayment(datas) {
    Swal.fire({
      title: "Are you sure want to send a payment link to respective email?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("Role") === "1") {
          dotracking(
            "send payment link in view player for tournament " +
              datas.data.id +
              " in player " +
              fname +
              " (" +
              uname +
              " - " +
              datas.data.user_id +
              ") $$ " +
              trackobj.playerdetheading
          );
          sendpaymentlink(datas.data.id, datas.data.user_id).then(
            (response) => {
              if (response.status === true) {
                toast.success(response.message);
              }
            }
          );
        } else if (localStorage.getItem("Role") === "3") {
          dotracking(
            "send payment link in view player for tournament " +
              datas.data.id +
              " in player " +
              datas.data.first_name +
              " (" +
              datas.data.username +
              " - " +
              datas.data.user_id +
              ") $$ " +
              trackobj.playerdetheading
          );
          citysendpaymentlink(datas.data.id, datas.data.user_id).then(
            (response) => {
              if (response.status === true) {
                toast.success(response.message);
              }
            }
          );
        }
      }
    });
  }
  const [sishow, setSiShow] = useState("");

  const [tname, setTName] = useState("");
  const [ordid, setOrdID] = useState("");
  const [payid, setPayID] = useState("");
  const [paydate, setPayDate] = useState("");
  const [payst, setPayst] = useState("");
  const [payamt, setPayAmt] = useState(0);

  const [assList, setAssList] = useState([]);

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");

    setUrl(link[1]);
    if (link[1] === "paymentdetail") {
      paymentdetailadmin(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          if (response.data.date_of_birth !== null) {
            setDOB(moment(response.data.date_of_birth).format("DD-MM-yyyy"));
          }

          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          setUser(response.data.username);
          setStatus(response.data.status);
          setSchool(response.data.school_name);
          setClass(response.data.class_name);
          setSection(response.data.section);

          setTName(response.data.meet_name);
          setOrdID(response.data.order_id);
          setPayID(response.data.payment_id);
          if (response.data.payment_date_time !== null) {
            setPayDate(
              moment(response.data.payment_date_time).format(
                "DD-MM-yyyy h:mm:ss A"
              )
            );
          }
          setPayst(response.data.pay_status);
          setPayAmt(response.data.registration_fees);
        }
      });

      viewprofile(id).then((response) => {
        // if (response.data.playerlist.photograph !== null) {
        //   setPhoto(
        //     response.data.photo_path + response.data.playerlist.photograph
        //   );
        // }
      });
    } else {
      if (localStorage.getItem("Role") === "1") {
        reasonlist().then((res) => {
          if (res.status === true) {
            setReaList(res.data);
          }
        });
        mergerornotplayer(id).then((response) => {
          if (response.status === false) {
            setSiShow(response.data.msg);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        citymergerornotplayer(id).then((response) => {
          if (response.status === false) {
            setSiShow(response.data.msg);
          }
        });
      }
      listsimilar();
      if (localStorage.getItem("Role") !== "8") {
        tourparticipatedplayerlist(id).then((response) => {
          if (response.status === true) {
            let arr = [];
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                var lnk = "";
                if (response.data[i].payment_status === "1") {
                  lnk = "Paid";
                }
                if (response.data[i].payment_status === "0") {
                  if (
                    moment(new Date()).format("yyyy-MM-DD") <=
                    response.data[i].entry_deadline_date
                  ) {
                    lnk = "Link";
                  } else {
                    lnk = "Entry Closed";
                  }
                }

                arr.push({
                  meet_name: response.data[i].meet_name,
                  meet_code: response.data[i].meet_code,
                  location: response.data[i].location,
                  start_date: response.data[i].start_date,
                  end_date: response.data[i].end_date,
                  entry_open: response.data[i].entry_open,
                  entry_deadline_date: response.data[i].entry_deadline_date,
                  sports_type: response.data[i].sports_type,
                  school_name: response.data[i].school_name,
                  pay_status: response.data[i].pay_status,
                  payment_id: response.data[i].payment_id,
                  order_id: response.data[i].order_id,
                  payment_date_time: response.data[i].payment_date_time,
                  id: response.data[i].id,
                  user_id: response.data[i].user_id,
                  link: lnk,
                });
              }
            }
            setFilterProduct(arr);
          }
        });

        playermappedintotour(id).then((response) => {
          if (response.status === true) {
            setMapList(response.data);
          }
        });
      }
    }
  }, []);
  function listsimilar() {
    if (localStorage.getItem("Role") === "1") {
      viewprofile(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          setLName(response.data.playerlist.last_name);
          if (response.data.playerlist.date_of_birth !== null) {
            setDOB(
              moment(response.data.playerlist.date_of_birth).format(
                "DD-MM-yyyy"
              )
            );
          }
          if (response.data.playerlist.registerdate !== null) {
            setRegDate(
              moment(response.data.playerlist.registerdate).format("DD-MM-yyyy")
            );
          }
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_name);
          setClass(response.data.playerlist.classname);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }

          let sim = [];
          sim.push({
            user_id: id,
            username: response.data.playerlist.username,
            first_name: response.data.playerlist.first_name,
            gender: response.data.playerlist.gender,
            date_of_birth: response.data.playerlist.date_of_birth,
            mobile_no: response.data.playerlist.mobile_no,
            email_id: response.data.playerlist.email_id,
            school_id: response.data.playerlist.school_id,
            school_name: response.data.playerlist.school_name,
            class_id: response.data.playerlist.class,
            class_name: response.data.playerlist.class_name,
            section: response.data.playerlist.section,
            registerdate: response.data.playerlist.registerdate,
            mergedornot: "",
            merged_main_username: "",
            merged_main_userid: "",
            check: true,
          });
          setSimList([...sim]);

          similarplayerlist(id).then((res) => {
            if (res.status === true) {
              if (res.data.duplicatedetails.length !== 0) {
                for (let i = 0; i < res.data.duplicatedetails.length; i++) {
                  sim.push({
                    user_id: res.data.duplicatedetails[i].user_id,
                    username: res.data.duplicatedetails[i].username,
                    first_name: res.data.duplicatedetails[i].first_name,
                    gender: res.data.duplicatedetails[i].gender,
                    date_of_birth: res.data.duplicatedetails[i].date_of_birth,
                    mobile_no: res.data.duplicatedetails[i].mobile_no,
                    email_id: res.data.duplicatedetails[i].email_id,
                    school_id: res.data.duplicatedetails[i].school_id,
                    school_name: res.data.duplicatedetails[i].school_name,
                    class_id: res.data.duplicatedetails[i].class,
                    class_name: res.data.duplicatedetails[i].class_name,
                    section: res.data.duplicatedetails[i].section,
                    registerdate: res.data.duplicatedetails[i].registerdate,
                    mergedornot: res.data.duplicatedetails[i].mergedornot,
                    merged_main_username:
                      res.data.duplicatedetails[i].merged_main_username,

                    merged_main_userid:
                      res.data.duplicatedetails[i].merged_main_userid,
                    check: false,
                    radiouser: false,
                    radioname: false,
                    radiogender: false,
                    radiodob: false,
                    radiomob: false,
                    radioemail: false,
                    radiosch: false,
                  });
                }
              }
              setSimList([...sim]);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      cityplayerview(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          setLName(response.data.playerlist.last_name);
          setDOB(
            moment(response.data.playerlist.date_of_birth).format("DD-MM-yyyy")
          );
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_name);
          setClass(response.data.playerlist.classname);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.registerdate !== null) {
            setRegDate(
              moment(response.data.playerlist.registerdate).format("DD-MM-yyyy")
            );
          }
          let sim = [];
          sim.push({
            user_id: id,
            username: response.data.playerlist.username,
            first_name: response.data.playerlist.first_name,
            gender: response.data.playerlist.gender,
            date_of_birth: response.data.playerlist.date_of_birth,
            mobile_no: response.data.playerlist.mobile_no,
            email_id: response.data.playerlist.email_id,
            school_id: response.data.playerlist.school_id,
            school_name: response.data.playerlist.school_name,
            class_id: response.data.playerlist.class,
            class_name: response.data.playerlist.class_name,
            section: response.data.playerlist.section,
            registerdate: response.data.playerlist.registerdate,
            mergedornot: "",
            merged_main_username: "",
            merged_main_userid: "",
            check: true,
          });
          setSimList([...sim]);

          similarplayerlist(id).then((res) => {
            if (res.status === true) {
              if (res.data.duplicatedetails.length !== 0) {
                for (let i = 0; i < res.data.duplicatedetails.length; i++) {
                  sim.push({
                    user_id: res.data.duplicatedetails[i].user_id,
                    username: res.data.duplicatedetails[i].username,
                    first_name: res.data.duplicatedetails[i].first_name,
                    gender: res.data.duplicatedetails[i].gender,
                    date_of_birth: res.data.duplicatedetails[i].date_of_birth,
                    mobile_no: res.data.duplicatedetails[i].mobile_no,
                    email_id: res.data.duplicatedetails[i].email_id,
                    school_id: res.data.duplicatedetails[i].school_id,
                    school_name: res.data.duplicatedetails[i].school_name,
                    class_id: res.data.duplicatedetails[i].class,
                    class_name: res.data.duplicatedetails[i].class_name,
                    section: res.data.duplicatedetails[i].section,
                    registerdate: res.data.duplicatedetails[i].registerdate,
                    mergedornot: res.data.duplicatedetails[i].mergedornot,
                    merged_main_username:
                      res.data.duplicatedetails[i].merged_main_username,

                    merged_main_userid:
                      res.data.duplicatedetails[i].merged_main_userid,
                    check: false,
                    radiouser: false,
                    radioname: false,
                    radiogender: false,
                    radiodob: false,
                    radiomob: false,
                    radioemail: false,
                    radiosch: false,
                  });
                }
              }
              setSimList([...sim]);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schoolviewplayer(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          setLName(response.data.playerlist.last_name);

          if (response.data.playerlist.date_of_birth !== null) {
            setDOB(
              moment(response.data.playerlist.date_of_birth).format(
                "DD-MM-yyyy"
              )
            );
          }
          if (response.data.playerlist.registerdate !== null) {
            setRegDate(
              moment(response.data.playerlist.registerdate).format("DD-MM-yyyy")
            );
          }
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_name);
          setClass(response.data.playerlist.classname);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }

          let sim = [];
          sim.push({
            user_id: id,
            username: response.data.playerlist.username,
            first_name: response.data.playerlist.first_name,
            gender: response.data.playerlist.gender,
            date_of_birth: response.data.playerlist.date_of_birth,
            mobile_no: response.data.playerlist.mobile_no,
            email_id: response.data.playerlist.email_id,
            school_id: response.data.playerlist.school_id,
            school_name: response.data.playerlist.school_name,
            class_id: response.data.playerlist.class,
            class_name: response.data.playerlist.class_name,
            section: response.data.playerlist.section,
            registerdate: response.data.playerlist.registerdate,
            mergedornot: "",
            merged_main_username: "",
            merged_main_userid: "",
            check: true,
          });
          setSimList([...sim]);

          similarplayerlist(id).then((res) => {
            if (res.status === true) {
              if (res.data.duplicatedetails.length !== 0) {
                for (let i = 0; i < res.data.duplicatedetails.length; i++) {
                  sim.push({
                    user_id: res.data.duplicatedetails[i].user_id,
                    username: res.data.duplicatedetails[i].username,
                    first_name: res.data.duplicatedetails[i].first_name,
                    gender: res.data.duplicatedetails[i].gender,
                    date_of_birth: res.data.duplicatedetails[i].date_of_birth,
                    mobile_no: res.data.duplicatedetails[i].mobile_no,
                    email_id: res.data.duplicatedetails[i].email_id,
                    school_id: res.data.duplicatedetails[i].school_id,
                    school_name: res.data.duplicatedetails[i].school_name,
                    class_id: res.data.duplicatedetails[i].class,
                    class_name: res.data.duplicatedetails[i].class_name,
                    section: res.data.duplicatedetails[i].section,
                    registerdate: res.data.duplicatedetails[i].registerdate,
                    mergedornot: res.data.duplicatedetails[i].mergedornot,
                    merged_main_username:
                      res.data.duplicatedetails[i].merged_main_username,

                    merged_main_userid:
                      res.data.duplicatedetails[i].merged_main_userid,
                    check: false,
                    radiouser: false,
                    radioname: false,
                    radiogender: false,
                    radiodob: false,
                    radiomob: false,
                    radioemail: false,
                    radiosch: false,
                  });
                }
              }
              setSimList([...sim]);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachplayerview(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          setLName(response.data.playerlist.last_name);
          setDOB(
            moment(response.data.playerlist.date_of_birth).format("DD-MM-yyyy")
          );
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_name);
          setClass(response.data.playerlist.classname);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.registerdate !== null) {
            setRegDate(
              moment(response.data.playerlist.registerdate).format("DD-MM-yyyy")
            );
          }
          let sim = [];
          sim.push({
            user_id: id,
            username: response.data.playerlist.username,
            first_name: response.data.playerlist.first_name,
            gender: response.data.playerlist.gender,
            date_of_birth: response.data.playerlist.date_of_birth,
            mobile_no: response.data.playerlist.mobile_no,
            email_id: response.data.playerlist.email_id,
            school_id: response.data.playerlist.school_id,
            school_name: response.data.playerlist.school_name,
            class_id: response.data.playerlist.class,
            class_name: response.data.playerlist.class_name,
            section: response.data.playerlist.section,
            registerdate: response.data.playerlist.registerdate,
            mergedornot: "",
            merged_main_username: "",
            merged_main_userid: "",
            check: true,
          });
          setSimList([...sim]);

          similarplayerlist(id).then((res) => {
            if (res.status === true) {
              if (res.data.duplicatedetails.length !== 0) {
                for (let i = 0; i < res.data.duplicatedetails.length; i++) {
                  sim.push({
                    user_id: res.data.duplicatedetails[i].user_id,
                    username: res.data.duplicatedetails[i].username,
                    first_name: res.data.duplicatedetails[i].first_name,
                    gender: res.data.duplicatedetails[i].gender,
                    date_of_birth: res.data.duplicatedetails[i].date_of_birth,
                    mobile_no: res.data.duplicatedetails[i].mobile_no,
                    email_id: res.data.duplicatedetails[i].email_id,
                    school_id: res.data.duplicatedetails[i].school_id,
                    school_name: res.data.duplicatedetails[i].school_name,
                    class_id: res.data.duplicatedetails[i].class,
                    class_name: res.data.duplicatedetails[i].class_name,
                    section: res.data.duplicatedetails[i].section,
                    registerdate: res.data.duplicatedetails[i].registerdate,
                    mergedornot: res.data.duplicatedetails[i].mergedornot,
                    merged_main_username:
                      res.data.duplicatedetails[i].merged_main_username,

                    merged_main_userid:
                      res.data.duplicatedetails[i].merged_main_userid,
                    check: false,
                    radiouser: false,
                    radioname: false,
                    radiogender: false,
                    radiodob: false,
                    radiomob: false,
                    radioemail: false,
                    radiosch: false,
                  });
                }
              }
              setSimList([...sim]);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      funderplayerdetail(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          setLName(response.data.playerlist.last_name);
          setDOB(
            moment(response.data.playerlist.date_of_birth).format("DD-MM-yyyy")
          );
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_name);
          setClass(response.data.playerlist.classname);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.registerdate !== null) {
            setRegDate(
              moment(response.data.playerlist.registerdate).format("DD-MM-yyyy")
            );
          }

          Playerprofiledetails(response.data.playerlist.username).then(
            (res2) => {
              if (res2.status === true) {
                if (res2.data.athletelist !== null) {
                  if (res2.data.athletelist.old_user_id !== null) {
                    const pltemp = new FormData();
                    pltemp.append(
                      "data_req",
                      "asmnt_data" + res2.data.athletelist.old_user_id
                    );
                    gettokenfromapi(pltemp).then((res) => {
                      if (res.status === true) {
                        sahassessmentdatelist(
                          "asmnt_data&esid=" +
                            res2.data.athletelist.old_user_id,
                          res.data
                        ).then((response) => {
                          // if (response.assmts.length !== 0) {
                          //   setAssList(response.assmts);
                          // }
                          // if (response.assmts[0][0].assmts.length !== 0) {
                          //   const fdata = response.assmts[0][0].assmts.filter(
                          //     (data) => {
                          //       return data.type === "fitness";
                          //     }
                          //   );
                          //   setFitList(fdata);
                          //   let origString = response.assmts[0][0].date;
                          //   let stringToAdd = "-";
                          //   let indexPosition = 2;
                          //   let newString1 =
                          //     origString.slice(0, indexPosition) +
                          //     stringToAdd +
                          //     origString.slice(indexPosition);
                          //   let indexPosition1 = 5;
                          //   let newString =
                          //     newString1.slice(0, indexPosition1) +
                          //     stringToAdd +
                          //     newString1.slice(indexPosition1);
                          //   setAssDate(newString);
                          //   setSkillDate(newString);
                          //   const sdata = response.assmts[0][0].assmts.filter(
                          //     (data) => {
                          //       return data.type === "skill";
                          //     }
                          //   );
      
                          //   setSkillList(sdata);
                          // }
                        });
                      }
                    });
                  }
                }
              }
            }
          );
        }
      });

      playerotherachievementlist(id).then((response) => {
        if (response.status === true) {
          let arr = [];
          let arr1 = [];
          if (response.data.length !== 0) {
            if (response.data.our_achievements.length !== 0) {
              arr = response.data.our_achievements;
            }
            if (response.data.other_achivements.length !== 0) {
              arr1 = response.data.other_achivements;
            }
            let arr2 = arr.concat(arr1);
            setFilterProduct(arr2);
          }
        }
      });
    }
  }
  function mergecheck(e, index) {
    if (simlist.length !== 0) {
      for (let i = 0; i < simlist.length; i++) {
        if (simlist[i].user_id !== id) {
          if (i === index) {
            simlist[i].check = e.target.checked;
            setSimList([...simlist]);
          } else {
            simlist[i].check = false;
            setSimList([...simlist]);
          }
        } else {
          simlist[i].check = true;
          setSimList([...simlist]);
        }
      }
    }
  }

  function unmergerplayer(eid, euser, ename) {
    if (eid !== "") {
      Swal.fire({
        title: "Are you sure want to delete the merge player?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete button in view player for " +
              ename +
              " (" +
              euser +
              " - " +
              eid +
              ") in " +
              fname +
              " (" +
              uname +
              " - " +
              id +
              ") $$ " +
              trackobj.playerdetheading
          );

          if (localStorage.getItem("Role") === "1") {
            similarunmergeplayer(id, eid).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                listsimilar();
              } else {
                toast.error(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "3") {
            citysimilarunmergeplayer(id, eid).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                listsimilar();
              } else {
                toast.error(response.message);
              }
            });
          }
        }
      });
    }
  }
  const [mshow, setMShow] = useState(false);
  function mergedata() {
    if (simlist.length !== 0) {
      var cnt = 0;

      for (let i = 0; i < simlist.length; i++) {
        if (simlist[i].user_id !== id) {
          if (simlist[i].check === true) {
            cnt = cnt + 1;
          }
        }

        simlist[i].radiouser = false;
        simlist[i].radioname = false;
        simlist[i].radiogender = false;
        simlist[i].radiodob = false;
        simlist[i].radioemail = false;
        simlist[i].radiomob = false;
        simlist[i].radiosch = false;
        simlist[i].radiocls = false;
        simlist[i].radiosec = false;
      }
      if (cnt === 0) {
        toast.error("Please Select the checkbox to merger the player details");
      } else {
        setMShow(true);
        // Swal.fire({
        //   title: "Are you sure want to merge the players?",
        //   showCancelButton: true,
        //   confirmButtonColor: "#d71635",
        //   confirmButtonText: "Ok",
        //   allowOutsideClick: false,
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     const temp = new FormData();
        //     temp.append("main_user_id", id);
        //     temp.append("duplicate_user_ids", muid);
        //     if (localStorage.getItem("Role") === "1") {
        //       similarmergeplayer(temp).then((response) => {
        //         if (response.status === true) {
        //           toast.success(response.message);
        //           listsimilar();
        //         } else {
        //           toast.error(response.message);
        //         }
        //       });
        //     } else if (localStorage.getItem("Role") === "3") {
        //       citysimilarmergeplayer(temp).then((response) => {
        //         if (response.status === true) {
        //           toast.success(response.message);
        //           listsimilar();
        //         } else {
        //           toast.error(response.message);
        //         }
        //       });
        //     }
        //   }
        // });
      }
    }
  }

  function mergeClose() {
    setMShow(false);
    setErrorMsg("");
    setMUName("");
    setMGender("");
    setMDOB("");
    setMMob("");
    setMEmail("");
    setMSch("");
    setMClass("");
    setMClassName("");
    setMSection("");
    setMUID("");
    setMDUID("");
  }
  const [muname, setMUName] = useState("");
  const [muid, setMUID] = useState("");
  const [mduid, setMDUID] = useState("");
  function mradiouser(index, ume) {
    if (simlist.length !== 0) {
      for (let i = 0; i < simlist.length; i++) {
        if (i === index) {
          setMUName(ume);
          setMUID(simlist[i].user_id);
          setMFName(simlist[i].first_name);
          setMGender(simlist[i].gender);
          setMDOB(simlist[i].date_of_birth);
          setMMob(simlist[i].mobile_no);
          setMEmail(simlist[i].email_id);
          setMSch(simlist[i].school_id);
          setMClass(simlist[i].class_id);
          setMClassName(simlist[i].class_name);
          setMSection(simlist[i].section);

          simlist[i].radiouser = true;
          setSimList([...simlist]);
        } else {
          if (simlist[i].check === true) {
            setMDUID(simlist[i].user_id);
          }
          simlist[i].radiouser = false;
          simlist[i].radioname = false;
          simlist[i].radiogender = false;
          simlist[i].radiodob = false;
          simlist[i].radiomob = false;
          simlist[i].radioemail = false;
          simlist[i].radiocls = false;
          simlist[i].radiosch = false;
          simlist[i].radiosec = false;

          setSimList([...simlist]);
        }
      }
    }
  }

  const [mfname, setMFName] = useState("");

  function mradioname(index, ume) {
    if (muname !== "") {
      simlist[index].radioname = !ume;
      setSimList([...simlist]);
      if (simlist[index].radioname === true) {
        setMFName(simlist[index].first_name);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radioname = true;
              setSimList([...simlist]);
              setMFName(simlist[i].first_name);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mgender, setMGender] = useState("");
  function mradiogender(index, ume) {
    if (muname !== "") {
      simlist[index].radiogender = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiogender === true) {
        setMGender(simlist[index].gender);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiogender = true;
              setSimList([...simlist]);
              setMGender(simlist[i].gender);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mdob, setMDOB] = useState("");
  function mradiodob(index, ume) {
    if (muname !== "") {
      simlist[index].radiodob = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiodob === true) {
        setMDOB(simlist[index].date_of_birth);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiodob = true;
              setSimList([...simlist]);
              setMDOB(simlist[i].date_of_birth);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mmob, setMMob] = useState("");
  function mradiomob(index, ume) {
    if (muname !== "") {
      simlist[index].radiomob = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiomob === true) {
        setMMob(simlist[index].mobile_no);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiomob = true;
              setSimList([...simlist]);
              setMMob(simlist[i].mobile_no);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [memail, setMEmail] = useState("");
  function mradioemail(index, ume) {
    if (muname !== "") {
      simlist[index].radioemail = !ume;
      setSimList([...simlist]);
      if (simlist[index].radioemail === true) {
        setMEmail(simlist[index].email_id);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radioemail = true;
              setSimList([...simlist]);
              setMEmail(simlist[i].email_id);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [msch, setMSch] = useState("");
  function mradiosch(index, ume) {
    if (muname !== "") {
      simlist[index].radiosch = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiosch === true) {
        setMSch(simlist[index].school_id);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiosch = true;
              setSimList([...simlist]);
              setMSch(simlist[i].school_id);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mcls, setMClass] = useState("");
  const [mclsname, setMClassName] = useState("");

  function mradioclass(index, ume) {
    if (muname !== "") {
      simlist[index].radiocls = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiocls === true) {
        setMClass(simlist[index].class_id);
        setMClassName(simlist[index].class_name);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiocls = true;
              setSimList([...simlist]);
              setMClass(simlist[i].class_id);
              setMClassName(simlist[index].class_name);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [msec, setMSection] = useState("");
  function mradiosection(index, ume) {
    if (muname !== "") {
      simlist[index].radiosec = !ume;
      setSimList([...simlist]);
      if (simlist[index].radiosec === true) {
        setMSection(simlist[index].section);
      } else {
        for (let i = 0; i < simlist.length; i++) {
          if (simlist[i].check === true) {
            if (i !== index) {
              simlist[i].radiosec = true;
              setSimList([...simlist]);
              setMSection(simlist[i].section);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fshow, setFShow] = useState(false);

  function mPClose() {
    setFShow(false);
  }
  function mergeplayer(e) {
    e.preventDefault();
    setFShow(true);
  }

  function mergedprofiles(e) {
    e.preventDefault();
    setErrorMsg([]);

    if (muname !== "") {
      setLoading(true);
      const temp = new FormData();
      temp.append("main_user_id", muid);
      temp.append("duplicate_user_ids", mduid);
      temp.append("email_id", memail);
      temp.append("mobile_no", mmob);
      temp.append("first_name", mfname);
      temp.append("date_of_birth", mdob);
      temp.append("gender", mgender);
      temp.append("class", mcls);
      temp.append("section", msec);
      temp.append("username", muname);
      dotracking(
        "merge button in view player for " +
          fname +
          " (" +
          uname +
          " - " +
          id +
          ") $$ " +
          trackobj.playerdetheading
      );

      similarmergeplayer(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          mPClose();
          mergeClose();
          toast.success(response.message);
          listsimilar();
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }
  const [bshow, setBShow] = useState(false);
  function blockShow() {
    setRReason("");
    setRemarks("");
    setBShow(true);
  }

  function blockClose() {
    setRReason("");
    setRemarks("");
    setBShow(false);
  }

  function blockathlete() {
    dotracking(
      "block button in view player for " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        ") $$ " +
        trackobj.playerdetheading
    );
    blocked(id, rreason, rremark).then((response) => {
      if (response.status === true) {
        setBShow(false);
        navigate("/superadmin/blockedplayers");
        toast.success(response.message);
      }
    });
  }

  const [ubshow, setUBShow] = useState(false);
  function unblockShow() {
    setRReason("");
    setRemarks("");
    setUBShow(true);
  }

  function unblockClose() {
    setRReason("");
    setRemarks("");
    setUBShow(true);
  }

  function unblockathlete() {
    dotracking(
      "unblock button in view player for " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        ") $$ " +
        trackobj.playerdetheading
    );
    unblocked(id, rreason, rremark).then((response) => {
      if (response.status === true) {
        setUBShow(false);
        navigate("/superadmin/allplayers");
        toast.success(response.message);
      }
    });
  }

  function editlist() {
    dotracking(
      "edit button in view player for " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        ") $$ " +
        trackobj.editplayerheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/editplayer/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/editplayer/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/editplayer/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/editplayer/" + id);
    }
  }

  function perflist() {
    dotracking(
      "overall performance button in view player for " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        ") $$ " +
        trackobj.overperfheading
    );
    if (urlhead === "viewplayer") {
      localStorage.setItem("PID", "1");
    } else if (urlhead === "tournamentplayer") {
      localStorage.setItem("PID", "2");
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/overallperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/overallperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/overallperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/overallperformanceplayer/" + id);
    }
  }

  function perftourlist() {
    dotracking(
      "tournament wise performance button in view player for " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        ") $$ " +
        trackobj.tourperfheading
    );
    if (urlhead === "viewplayer") {
      localStorage.setItem("PID", "1");
    } else if (urlhead === "tournamentplayer") {
      localStorage.setItem("PID", "2");
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/viewperformanceplayer/" + id);
    }
  }

  function backlist() {
    if (urlhead === "tournamentplayer") {
      dotracking(
        "back button in tournament player detail for player " +
          fname +
          " (" +
          uname +
          " - " +
          id +
          ") $$ " +
          trackobj.tourplayerlistheading
      );
      navigate(-1);
    } else if (urlhead === "paymentdetail") {
      dotracking(
        "back button in payment detail for player " +
          fname +
          " (" +
          uname +
          " - " +
          id +
          ") $$ " +
          trackobj.paylistheading
      );
      navigate("/superadmin/payment");
    } else {
      if (localStorage.getItem("Role") === "1") {
        if (status === 1) {
          dotracking(
            "back button in view player for " +
              fname +
              " (" +
              uname +
              " - " +
              id +
              ") $$ " +
              trackobj.playerlistheading
          );
          navigate("/superadmin/allplayers");
        } else if (status === 3) {
          dotracking(
            "back button in view player for " +
              fname +
              " (" +
              uname +
              " - " +
              id +
              ") $$ " +
              trackobj.blockedplalistheading
          );
          navigate("/superadmin/blockedplayers");
        }
      } else if (localStorage.getItem("Role") === "3") {
        dotracking(
          "back button in view player for " +
            fname +
            " (" +
            uname +
            " - " +
            id +
            ") $$ " +
            trackobj.playerlistheading
        );
        navigate("/cityregional/allplayers");
      } else if (localStorage.getItem("Role") === "4") {
        dotracking(
          "back button in view player for " +
            fname +
            " (" +
            uname +
            " - " +
            id +
            ") $$ " +
            trackobj.playerlistheading
        );
        navigate("/schoolhead/allplayers");
      } else if (localStorage.getItem("Role") === "7") {
        dotracking(
          "back button in view player for " +
            fname +
            " (" +
            uname +
            " - " +
            id +
            ") $$ " +
            trackobj.playerlistheading
        );
        navigate("/coach/allplayers");
      } else if (localStorage.getItem("Role") === "8") {
        dotracking(
          "back button in view player for " +
            fname +
            " (" +
            uname +
            " - " +
            id +
            ") $$ " +
            trackobj.benelistheading
        );
        navigate("/funder/benificiarieslist");
      }
    }
  }

  function viewasslist(vid) {
    dotracking(
      "view icon in assessment list for (" +
        vid +
        ") " +
        fname +
        " (" +
        uname+
        ") $$ " +
        trackobj.assdetheading
    );
    navigate("/funder/assessmentdetail/" + vid + "/" + id);
  }

  function viewlist(vid, vname, vuser) {
    dotracking(
      "view icon in similar profile list of view player for player " +
        fname +
        " (" +
        uname +
        " - " +
        id +
        "), redirect to the player " +
        vname +
        " (" +
        vuser +
        " - " +
        vid +
        ") $$ " +
        trackobj.playerlistheading
    );
    const url = window.location.href;
    const head = url.split("/#/");
    if (localStorage.getItem("Role") === "1") {
      window.open(`${head[0]}/#/superadmin/viewplayer/` + vid, "_blank");
    } else if (localStorage.getItem("Role") === "3") {
      window.open(`${head[0]}/#/cityregional/viewplayer/` + vid, "_blank");
    } else if (localStorage.getItem("Role") === "4") {
      window.open(`${head[0]}/#/schoolhead/viewplayer/` + vid, "_blank");
    } else if (localStorage.getItem("Role") === "7") {
      window.open(`${head[0]}/#/coach/viewplayer/` + vid, "_blank");
    }
  }

  const [photo, setPhoto] = useState("");

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Player Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Player</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Player Details
          </li>
        </ol>
      </div>
      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="text-right">
                  {localStorage.getItem("Role") !== "8" && (
                    <>
                      <a
                        className="btn btn-success me-2 mt-1"
                        onClick={perflist}
                      >
                        Overall Performance
                      </a>
                      <a
                        className="btn btn-secondary me-2 mt-1"
                        onClick={perftourlist}
                      >
                        Tournament Wise Performance
                      </a>
                    </>
                  )}

                  <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                    Back
                  </a>
                </div>
                {urlhead !== "paymentdetail" &&
                  localStorage.getItem("Role") !== "8" && (
                    <>
                      <div className="row">
                        {sishow === "" && (
                          <h4>
                            Similar Profiles (Based on DOB,Gender and School)
                          </h4>
                        )}
                        {sishow !== "" && (
                          <h4 className="text-center text-danger">{sishow}</h4>
                        )}
                      </div>
                      {sishow === "" && (
                        <div className="table-responsive mt-2">
                          <Table bordered>
                            <thead>
                              <tr>
                                <th>Merge</th>
                                <th>Username</th>
                                <th>Fullname</th>
                                <th>Gender</th>
                                <th>D.O.B</th>
                                <th>Contact Number</th>
                                <th>Email</th>
                                <th>School</th>
                                <th>Reg Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {simlist.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    {data.user_id !== id && (
                                      <>
                                        <td className="text-center">
                                          {data.mergedornot !== "" && (
                                            <>
                                              {data.merged_main_userid !==
                                                parseInt(id) && (
                                                <>
                                                  {data.merged_main_userid ===
                                                    data.user_id && (
                                                    <span className="text-success">
                                                      Main Profile
                                                    </span>
                                                  )}
                                                  {data.merged_main_userid !==
                                                    data.user_id && (
                                                    <span className="text-danger">
                                                      Already merged to{" "}
                                                      {
                                                        data.merged_main_username
                                                      }
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                              {data.merged_main_userid ===
                                                parseInt(id) && (
                                                <>
                                                  <span className="text-success me-2">
                                                    {data.mergedornot}
                                                  </span>
                                                  <a
                                                    className="btn btn-danger"
                                                    onClick={() =>
                                                      unmergerplayer(
                                                        data.user_id,
                                                        data.username,
                                                        data.first_name
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-trash fa-lg"></i>
                                                  </a>
                                                </>
                                              )}
                                            </>
                                          )}
                                          {data.mergedornot === "" && (
                                            <input
                                              type="radio"
                                              checked={data.check}
                                              onChange={(e) => mergecheck(e, i)}
                                            />
                                          )}
                                        </td>

                                        <td>{data.username}</td>
                                        <td>{data.first_name}</td>
                                        <td>{data.gender}</td>
                                        <td>
                                          {moment(data.date_of_birth).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </td>
                                        <td>{data.mobile_no}</td>
                                        <td>{data.email_id}</td>
                                        <td>{data.school_name}</td>
                                        <td>
                                          {data.registerdate !== null && (
                                            <>
                                              {moment(data.registerdate).format(
                                                "DD-MM-yyyy"
                                              )}
                                            </>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <a
                                            className="btn-sm btn-info"
                                            onClick={() =>
                                              viewlist(
                                                data.user_id,
                                                data.first_name,
                                                data.username
                                              )
                                            }
                                            target="_blank"
                                          >
                                            <i className="fa fa-eye fa-lg"></i>
                                          </a>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          {localStorage.getItem("Role") === "1" && (
                            <div className="text-right">
                              <a
                                className="btn btn-success"
                                onClick={mergedata}
                              >
                                Merge
                              </a>
                            </div>
                          )}
                        </div>
                      )}

                      <hr />
                    </>
                  )}

                <Modal show={mshow} size="xl">
                  <Modal.Header>
                    <h5>Merge Player Details</h5>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={mergeClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <div className="table-responsive mt-2">
                      <h5 className="text-success">
                        Select Username (UID) of main profile
                      </h5>
                      {/* <div  id="mergelist" className="row">
                        <div className="col-md-3">
                        <div className="card">
                             
                             <div className="">
                               <h2>
                                 <b>Player UID</b>
                               </h2>
                               <h2>
                                 <b>Class</b>
                               </h2>
                               <h2>
                                 <b>Section</b>
                               </h2>
                               <h2>
                                 <b>Gender</b>
                               </h2>
                               <h2>
                                 <b>DOB</b>
                               </h2>
                             </div>
                           </div>
                        </div>
                      </div> */}
                      <Table bordered>
                        <tbody>
                          <tr>
                            <th>
                              <b>Username (UID)</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser !== true && (
                                        <>
                                          <div
                                            style={{
                                              float: "left",
                                              width: "50%",
                                            }}
                                          >
                                            {data.username}
                                          </div>
                                          <div
                                            className="text-right"
                                            style={{
                                              float: "right",
                                              width: "50%",
                                            }}
                                          >
                                            <a
                                              className="btn btn-secondary"
                                              onClick={() =>
                                                mradiouser(i, data.username)
                                              }
                                            >
                                              Select if Main UID
                                            </a>
                                          </div>
                                        </>
                                      )}
                                      {data.radiouser === true && (
                                        <>
                                          <span className="font-weight-bold text-success">
                                            {data.username}
                                            <i className="fa fa-check ml-2"></i>
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>Fullname</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.first_name}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radioname}
                                            onClick={() =>
                                              mradioname(i, data.radioname)
                                            }
                                          />
                                          {data.first_name}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>Gender </b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.gender}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiogender}
                                            onClick={() =>
                                              mradiogender(i, data.radiogender)
                                            }
                                          />
                                          {data.gender}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>DOB</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {moment(data.date_of_birth).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiodob}
                                            onClick={() =>
                                              mradiodob(i, data.radiodob)
                                            }
                                          />
                                          {moment(data.date_of_birth).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>Contact</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.mobile_no}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiomob}
                                            onClick={() =>
                                              mradiomob(i, data.radiomob)
                                            }
                                          />
                                          {data.mobile_no}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>Email</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.email_id}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radioemail}
                                            onClick={() =>
                                              mradioemail(i, data.radioemail)
                                            }
                                          />
                                          {data.email_id}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <th>
                              <b>Class</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.class_name}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiocls}
                                            onClick={() =>
                                              mradioclass(i, data.radiocls)
                                            }
                                          />
                                          {data.class_name}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>

                          <tr>
                            <th>
                              <b>Section</b>
                            </th>
                            {simlist.map((data, i) => {
                              return (
                                <>
                                  {data.check === true && (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.section}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiosec}
                                            onClick={() =>
                                              mradiosection(i, data.radiosec)
                                            }
                                          />
                                          {data.section}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                        </tbody>
                      </Table>
                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-success" onClick={mergeplayer}>
                      Merge Player
                    </button>
                    <button onClick={mergeClose} className="btn btn-gray">
                      Cancel
                    </button>
                  </Modal.Footer>
                </Modal>

                <Modal show={fshow} size="large">
                  <Modal.Header>
                    <h5>Merge Profile Confirmation</h5>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={mPClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <div className="alignMe">
                      <h4 className="h4 mb-0 mt-3">
                        <b>Username (UID) </b>{" "}
                        <span className="font-weight-bold text-success">
                          {muname}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Fullname </b>{" "}
                        <span className="font-weight-bold text-success">
                          {mfname}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Gender </b>{" "}
                        <span className="font-weight-bold text-success">
                          {mgender}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>DOB </b>{" "}
                        <span className="font-weight-bold text-success">
                          {moment(mdob).format("DD-MM-yyyy")}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Contact </b>{" "}
                        <span className="font-weight-bold text-success">
                          {mmob}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Email </b>{" "}
                        <span className="font-weight-bold text-success">
                          {memail}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Class </b>{" "}
                        <span className="font-weight-bold text-success">
                          {mclsname}
                        </span>
                      </h4>
                      <h4 className="h4 mb-0 mt-3">
                        <b>Section </b>{" "}
                        <span className="font-weight-bold text-success">
                          {msec}
                        </span>
                      </h4>
                    </div>

                    <br />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-secondary"
                      onClick={mergedprofiles}
                    >
                      Ok
                    </button>
                    <button onClick={mPClose} className="btn btn-gray">
                      Cancel
                    </button>
                  </Modal.Footer>
                </Modal>

                <h4>Personal Detail</h4>
                <div className="row tournamentitem">
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Full Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{fname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Last Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{lname}</span>
                      </div>
                    </div>
                  </div>
                </div> */}

                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Contact Number</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mobno}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email Address</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Username (UID)</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Registered Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{regdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {urlhead !== "paymentdetail" && (
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Status</b>
                          </div>
                          <div className="col-md-8">
                            {status === 1 && (
                              <span
                                className="text-inverse"
                                style={{ color: "green", fontWeight: "700" }}
                              >
                                Approved
                              </span>
                            )}
                            {status === 4 && (
                              <span
                                className="text-inverse"
                                style={{ color: "red", fontWeight: "700" }}
                              >
                                Merged Player
                              </span>
                            )}
                            {status === 3 && (
                              <span
                                className="text-inverse"
                                style={{ color: "#d5a20a", fontWeight: "700" }}
                              >
                                Blocked
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                <h4>School Detail</h4>
                <div className="row tournamentitem">
                  <div className="col-md-12">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-2 col-md-6">
                          <b className="text-inverse">School</b>
                        </div>
                        <div className="col-md-10 col-md-6">
                          <span className="text-inverse">{school}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Class</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{clsname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Section</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{secname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>File Uploads</h4>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Photograph </label>

                    <img
                      className="profile_img mb-2"
                      src={photo}
                      height="160"
                      alt="photo"
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                </div>

                {localStorage.getItem("Role") === "8" && (
                  <>
                    <hr />
                    <div className="container">
                      <div className="ag_courses_head">
                        <div className="row">
                          <h4>Fitness Assessment</h4>
                        </div>
                      </div>
                      {fitList.length === 0 && skillList.length === 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {fitList.length === 0 && skillList.length !== 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Fitness Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {fitList.length !== 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="text-success">
                      {/* Assessment Date - {moment(assdate).format("DD-MM-YYYY")} */}
                      Assessment Date - {assdate}
                    </h3>

                    <h4>5 Point Grading System</h4>
                    <span className="errortext">
                      A - 5 Points (Excellent), B - 4 Points (Very Good), C - 3
                      Points (Good) , D - 2 Points (Average) , E - 1 Point
                      (Needs Improvement)
                    </span>
                    {fitList.map((item, j) => {
                      return (
                        <>
                          <table
                            className="table table-bordered table-hover mt-3"
                            key={j}
                          >
                            <thead>
                              <tr>
                                <th>Parameters Mesured</th>
                                <th>Grade</th>
                                <th>Score</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.attributes.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.fst_theme_name}</td>
                                    <td>{data.grade}</td>
                                    <td>{data.score}</td>
                                    <td>{data.name}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <thead>
                              <tr>
                                <th>FQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.fq}</th>
                              </tr>
                              <tr>
                                <th>SQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.sq}</th>
                              </tr>
                              <tr>
                                <th>TQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.tq}</th>
                              </tr>
                            </thead>
                          </table>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="ag_courses_head ">
                  <h4>Remedial Actions</h4>
                </div>

                {fitList.map((item, j) => {
                  return (
                    <>
                      <div className="row" key={j}>
                        {item.attributes.map((data, i) => {
                          return (
                            <div className="col-md-4" key={i}>
                              <div className="our_solution_category">
                                <div className="solution_cards_box">
                                  <div className="solution_card">
                                    <div className="hover_color_bubble"></div>
                                    <div className="so_top_icon">
                                      {data.fst_theme_name.includes(
                                        "Anaerobic"
                                      ) && (
                                        <img
                                          src={require("../../../../assets/images/Anaerobic.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Aerobic"
                                      ) && (
                                        <img
                                        src={require("../../../../assets/images/Aerobic.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Flexibility"
                                      ) && (
                                        <img
                                          src={require("../../../../assets/images/Fitness.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Abdominal"
                                      ) && (
                                        <img
                                          src={require("../../../../assets/images/Abdominal.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {(data.fst_theme_name.includes("Lower") ||
                                        data.fst_theme_name.includes("LB")) && (
                                        <img
                                          src={require("../../../../assets/images/Lower.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {(data.fst_theme_name.includes("Upper") ||
                                        data.fst_theme_name.includes("UB")) && (
                                        <img
                                          src={require("../../../../assets/images/Upper.png")}
                                          alt="fitness"
                                        />
                                      )}
                                    </div>
                                    <div className="solu_title">
                                      <h3>{data.fst_theme_name}</h3>
                                    </div>
                                    <div className="solu_description">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: data.student_fdbk,
                                        }}
                                        title={data.student_fdbk}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
          )}

          {skillList.length === 0 && fitList.length !== 0 && (
            <div className="row">
              <div className="ag_courses_head">
                <h4>Skill Assessment</h4>
              </div>
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Skill Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          {skillList.length !== 0 && (
            <div className="row">
              <div className="ag_courses_head">
                <h4>Skill Assessment</h4>
              </div>
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    {assdate === "" && (
                      <h3 className="text-success">
                        Assessment Date - {skildate}
                      </h3>
                    )}
                    <h4>5 Point Grading System</h4>
                    <span className="errortext">
                      A - 5 Points (Very Good), B - 4 Points (Good), C - 3
                      Points (Average) , D - 2 Points (Developing) , E - 1 Point
                      (Needs Improvement)
                    </span>
                    {skillList.map((item, j) => {
                      return (
                        <>
                          <table
                            className="table table-bordered table-hover mt-3"
                            key={j}
                          >
                            <thead>
                              <tr>
                                <th>Skills Mesured</th>
                                <th>Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.attributes.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.name}</td>
                                    <td>{data.grade}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <thead>
                              <tr>
                                <th>Overall Grade</th>
                                <th>{item.overall_grade}</th>
                              </tr>
                            </thead>
                          </table>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
                      {/* <div className="ag-theme-alpine ag-style mt-3">
                        <AgGridReact
                          columnDefs={columnADefs}
                          rowData={assList}
                          pagination="true"
                          paginationPageSize="10"
                          sortingOrder={sorting}
                          suppressExcelExport={true}
                          suppressDragLeaveHidesColumns={true}
                        />
                      </div> */}
                    </div>

                    <hr />

                    <h4>Achievement List</h4>
                    <div className="ag-theme-alpine ag-style">
                      <AgGridReact
                        columnDefs={columnPDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="10"
                        sortingOrder={sorting}
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                      />
                    </div>
                  </>
                )}
                {urlhead !== "paymentdetail" &&
                  localStorage.getItem("Role") !== "8" && (
                    <>
                      {maplist.length !== 0 && (
                        <>
                          <hr />
                          <h4>Mapped Detail</h4>
                          <div className="table-responsive">
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th>Tournament</th>
                                  <th>Date and Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {maplist.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{data.meet_name} </td>
                                      <td>
                                        {moment(data.created_at).format(
                                          "DD-MM-yyyy h:mm:ss A"
                                        )}{" "}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                      <hr />

                      <h4>Tournament List</h4>
                      <div className="ag-theme-alpine ag-style">
                        <AgGridReact
                          columnDefs={columnDefs}
                          rowData={filterProduct}
                          pagination="true"
                          paginationPageSize="10"
                          sortingOrder={sorting}
                          suppressExcelExport={true}
                          suppressDragLeaveHidesColumns={true}
                        />
                      </div>
                    </>
                  )}

                {urlhead === "paymentdetail" && (
                  <>
                    <hr />
                    <h4>Payment Details</h4>

                    <div className="row tournamentitem">
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-2 col-md-6">
                              <b className="text-inverse">Tournament Name</b>
                            </div>
                            <div className="col-lg-10 col-md-6">
                              <span className="text-inverse">{tname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Order ID</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{ordid}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Payment ID</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{payid}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Payment Date</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{paydate}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Payment Status</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{payst}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Amount</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{payamt}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="card-footer text-right border-top">
                  {urlhead !== "tournamentplayer" && (
                    <>
                      {status === 1 && localStorage.getItem("Role") === "1" && (
                        <a
                          className="btn btn-danger me-2"
                          title="Block Player"
                          onClick={blockShow}
                        >
                          Block
                        </a>
                      )}
                      {status === 3 && localStorage.getItem("Role") === "1" && (
                        <a
                          className="btn btn-success me-2"
                          title="UnBlock Player"
                          onClick={unblockShow}
                        >
                          Un Block
                        </a>
                      )}
                      {localStorage.getItem("Role") === "1" &&
                        urlhead !== "paymentdetail" && (
                          <a
                            onClick={() => editlist()}
                            title="Edit Player"
                            className="btn btn-secondary me-2"
                          >
                            Edit
                          </a>
                        )}
                    </>
                  )}
                </div>

                <Modal show={bshow} size="large">
                  <Modal.Header>
                    <h5>Block Player Profile</h5>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={blockClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <div>
                      <div className="rep__container">
                        <div className="col-lg">
                          <p>Reason</p>
                          <select
                            className="form-control"
                            value={rreason}
                            onChange={(e) => setRReason(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Reason
                            </option>
                            {realist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.reason_name}
                                </option>
                              );
                            })}
                          </select>
                          {/*<p>Remarks</p>
                         <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter the remarks"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        ></Form.Control> */}
                        </div>
                      </div>
                    </div>
                    <br />
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-danger" onClick={blockathlete}>
                      Block
                    </button>
                    <button onClick={blockClose} className="btn btn-gray">
                      Cancel
                    </button>
                  </Modal.Footer>
                </Modal>

                <Modal show={ubshow} size="large">
                  <Modal.Header>
                    <h6 className="rep-head">UnBlock Player Profile</h6>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={unblockClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <div>
                      <div className="rep__container">
                        <div className="col-lg">
                          <p>Reason</p>
                          <select
                            value={rreason}
                            onChange={(e) => setRReason(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Reason
                            </option>
                            {realist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.reason_name}
                                </option>
                              );
                            })}
                          </select>
                          {/* <p>Remarks</p>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter the remarks"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        ></Form.Control> */}
                        </div>
                      </div>
                    </div>
                    <br />
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-danger" onClick={unblockathlete}>
                      UnBlock
                    </button>
                    <button onClick={unblockClose} className="btn btn-gray">
                      Cancel
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

{
  /* <Modal show={mshow} size="xl">
                <Modal.Header>
                  <h5>Merge Player Details</h5>
                  <i
                    className="fa fa-window-close fa-lg"
                    onClick={mergeClose}
                    aria-hidden="true"
                  ></i>
                </Modal.Header>
                <Modal.Body className="mod-body">
                  <div className="table-responsive mt-2">
                    <Table bordered>
                      <tbody>
                        <tr>
                          <th>
                            <b>Username</b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {uname}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radiouser}
                                      onClick={() =>
                                        mradiouser(i, data.username)
                                      }
                                    />
                                    {data.username}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>Fullname</b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {fname}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radioname}
                                      onClick={() =>
                                        mradioname(i, data.first_name)
                                      }
                                    />
                                    {data.first_name}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>Gender </b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {gender}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radiogender}
                                      onClick={() =>
                                        mradiogender(i, data.gender)
                                      }
                                    />
                                    {data.gender}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>DOB</b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {dob}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radiodob}
                                      onClick={() =>
                                        mradiodob(i, data.date_of_birth)
                                      }
                                    />
                                    {moment(data.date_of_birth).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>Contact</b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {mobno}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radiomob}
                                      onClick={() =>
                                        mradiomob(i, data.mobile_no)
                                      }
                                    />
                                    {data.mobile_no}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>Email</b>
                            <br />
                            <span className="font-weight-bold text-muted">
                              {email}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radioemail}
                                      onClick={() =>
                                        mradioemail(i, data.email_id)
                                      }
                                    />
                                    {data.email_id}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <th>
                            <b>School</b>
                            <br />{" "}
                            <span className="font-weight-bold text-muted">
                              {school}
                            </span>
                          </th>
                          {simlist.map((data, i) => {
                            return (
                              <>
                                {data.check === true && (
                                  <td key={i}>
                                    <input
                                      type="radio"
                                      className="me-2"
                                      checked={data.radiosch}
                                      onClick={() =>
                                        mradiosch(i, data.school_name)
                                      }
                                    />
                                    {data.school_name}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-success" onClick={mergeplayer}>
                    Merge Player
                  </button>
                  <button onClick={mergeClose} className="btn btn-gray">
                    Cancel
                  </button>
                </Modal.Footer>
              </Modal> */
}

// if (muname !== "") {
//   if (simlist.length !== 0) {
//     for (let i = 0; i < simlist.length; i++) {
//       if (i === index) {
//         setMGender(ume);
//         simlist[i].radiogender = true;
//         setSimList([...simlist]);
//       } else {
//         simlist[i].radiogender = false;
//         setSimList([...simlist]);
//       }
//     }
//   }
// } else {
//   setErrorMsg("Please select Username (UID) of Main Player");
// }
// }
