import React, { useEffect, useState } from "react";
import {
  agecatactivelist,
  multipleroleslist,
  schoolactivelist,
  sportsactivelist,
  touragecategory,
  tourassigncrm,
  tourassigncrmdeletelist,
  tourassigncrmlist,
  tourassignrefdeletelist,
  tourassignreferee,
  tourassignrefereelist,
  tourassignschool,
  tourassignschooldeletelist,
  tourclassdelete,
  tourstandardclassadd,
  tourtypeactivelist,
  tourtypelist,
  tourupdate,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  classlist,
  districtlist,
  statelist,
} from "../../../../../services/master";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  agecategorytourcityreg,
  crmagecatelist,
  crmassignrefdeletelist,
  crmassignrefereelist,
  crmrefereelist,
  crmsportslist,
  crmtourrefereeassign,
  crmtourtypelist,
  crmtourupdate,
  crmtourviewdetail,
  schoolactivelistcityreg,
  schoollistcityreg,
} from "../../../../../services/cityregionaladmin";
import ImageUrl from "../../../../../ImageUrl";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { interschooltourpaidornot } from "../../../../../services/interschool";
import { agecategorytourcoach, coachagecatelist, coachassignrefdeletelist, coachassignrefereelist, coachrefereelist, coachsportslist, coachtourrefereeassign, coachtourtypelist, coachtourupdate, coachtourviewdetail, schoolactivelistcoach } from "../../../../../services/coach";

export const EditTour = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [typelist, setTypeList] = useState([]);
  const [spotypelist, setSpoTypeList] = useState([]);
  const [schlist, setSchList] = useState([]);
  const [curdate, setCurdate] = useState("");

  const [tcls, setTCls] = useState([]);
  const [tclslist, setTClsList] = useState([]);

  const [clslist, setClsList] = useState([]);

  const [agecatlist, setAgeCatList] = useState([]);
  const [slist, setStateList] = useState([]);
  const [dlist, setDistList] = useState([]);

  const [mschool, setMSch] = useState([]);

  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mcode, setMCode] = useState("");
  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [meodate, setMEODate] = useState("");
  const [meddate, setMEDDate] = useState("");
  const [msport, setMSport] = useState("");
  const [msportid, setMSportId] = useState("");
  const [mfees, setMFees] = useState("");
  const [mfree, setMFree] = useState("");

  const [mulsp, setMulSp] = useState(0);

  const [mschid, setMSchId] = useState("");
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");
  const [mgate, setMGate] = useState("N");
  const [matype, setMAType] = useState("");
  const [mcat, setMCat] = useState("");
  const [mleatype, setMLeaque] = useState("");
  const [mcir, setMCir] = useState("");
  const [reptime, setRepTime] = useState("");

  const [refid, setRefid] = useState("");
  const [reflist, setRefList] = useState([]);

  const [crmid, setCRMid] = useState("");
  const [crmlist, setCRMList] = useState([]);

  let arr = [];
  let arr1 = [];

  useEffect(() => {
    setCurdate(moment(new Date()).format("YYYY-MM-DD"));
    statelist().then((response) => {
      if (response.status === true) {
        setStateList(response.data);
      }
    });
    classlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          arr1 = [];
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].id,
              label: response.data[i].class,
            });
          }
        }
        setClsList(arr1);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourtypeactivelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
        }
      });

      sportsactivelist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      schoolactivelist().then((response) => {
        if (response.status === true) {
          if (response.data.details.length !== 0) {
            arr1 = [];
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label: response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });

      agecatactivelist().then((response) => {
        if (response.status === true) {
          let agearr = [];
          if (response.data.length !== 0) {
           
            for (let i = 0; i < response.data.length; i++) {
              agearr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(agearr);
        }
      });

      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          if (response.data.tournament.reporting_time !== null) {
            setRepTime(response.data.tournament.reporting_time);
          }
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.state);
          setMDist(response.data.tournament.district);

          setMSDate(response.data.tournament.start_date);
          setMEDate(response.data.tournament.end_date);
          setMEODate(response.data.tournament.entry_open);
          setMEDDate(response.data.tournament.entry_deadline_date);
          setRelayFees(response.data.tournament.relay_fees);
          setMFees(response.data.tournament.registration_fees);
          setMFree(response.data.tournament.free_tournament);
          setMAType(response.data.tournament.agecat_or_std + "");
          if(response.data.tournament.dob_start_date !== null){
            setMSDob(response.data.tournament.dob_start_date);
          }
          if(response.data.tournament.dob_end_date !== null){
            setMEDob(response.data.tournament.dob_end_date);
          }
          
          setMSportId(response.data.tournament.sports_type_no);
          setMSport(response.data.tournament.sports_type);

          setMGate(response.data.tournament.gateway_charges);

          // if (response.data.tournament.agecat_or_std === 1) {
          //     let arr1 = [];
          //     if (response.data.age_category.length !== 0) {
          //         for (let j = 0; j < response.data.age_category.length; j++) {
          //             arr1.push({
          //                 value: response.data.age_category[j].standard_id,
          //                 label: response.data.age_category[j].standard_name,
          //             });
          //         }
          //     }
          //     setTClsList(arr1)
          // }
          agecatactivelist().then((res1) => {
            if (res1.status === true) {
              if (res1.data.length !== 0) {
                let agearr = [];

                if (response.data.tournament.agecat_or_std === 1) {
                  var catg = response.data.tournament.categories.split(",");
                  for (let j = 0; j < catg.length; j++) {
                    for (let i = 0; i < res1.data.length; i++) {
                      if (catg[j] === res1.data[i].age_category_name) {
                        agearr.push({
                          value: res1.data[i].id,
                          label: res1.data[i].age_category_name,
                        });
                      }
                    }
                  }
                  setMCat(agearr);
                } else if (response.data.tournament.agecat_or_std === 2) {
                  
                  let marr=[];
                  for (let i = 0; i < res1.data.length; i++) {
                    
                    if (response.data.age_category.length !== 0) {
                      for (
                        let j = 0;
                        j < response.data.age_category.length;
                        j++
                      ) {
                        if (
                          response.data.age_category[j].age_categories ===
                          res1.data[i].age_category_name
                        ) {
                          marr.push({
                            value: res1.data[i].id,
                            label: res1.data[i].age_category_name,
                          });
                        }
                      }
                    }
                  }
                  setMCat(marr);
                }
              }
            }
          });
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }

          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }

          districtlist(response.data.tournament.state).then((res) => {
            if (res.status === true) {
              setDistList(res.data);
            }
          });
        }
      });
      // if
      multipleroleslist(3, 1).then((response) => {
        if (response.status === true) {
          let carr = [];
          if (response.data.length !== 0) {
          
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              carr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setCRMList(carr);
        }
      });

      multipleroleslist(5, 1).then((response) => {
        if (response.status === true) {
          let rarr = [];
          if (response.data.length !== 0) {
           
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              rarr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(rarr);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourtypelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
        }
      });

      crmsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      crmagecatelist().then((response) => {
        if (response.status === true) {
          let agearr = [];
          if (response.data.length !== 0) {
            
            for (let i = 0; i < response.data.length; i++) {
              agearr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(agearr);
        }
      });

      //   schoolactivelistcityreg().then((response) => {
      //     if (response.status === true) {
      //       if (response.data.length !== 0) {
      //         arr1 = [];
      //         for (let i = 0; i < response.data.length; i++) {
      //           arr1.push({
      //             value: response.data[i].id,
      //             label: response.data[i].school_unique_no + " - " + response.data[i].school_name,
      //           });
      //         }
      //       }
      //       setSchList(arr1)
      //     }
      //   })

      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          if (response.data.tournament.reporting_time !== null) {
            setRepTime(response.data.tournament.reporting_time);
          }
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.state);
          setMDist(response.data.tournament.district);

          setMSDate(response.data.tournament.start_date);
          setMEDate(response.data.tournament.end_date);
          setMEODate(response.data.tournament.entry_open);
          setMEDDate(response.data.tournament.entry_deadline_date);
          setRelayFees(response.data.tournament.relay_fees);

          setMFees(response.data.tournament.registration_fees);
          setMFree(response.data.tournament.free_tournament);
          setMAType(response.data.tournament.agecat_or_std + "");

          if(response.data.tournament.dob_start_date !== null){
            setMSDob(response.data.tournament.dob_start_date);
          }
          if(response.data.tournament.dob_end_date !== null){
            setMEDob(response.data.tournament.dob_end_date);
          }
          setMSportId(response.data.tournament.sports_type_no);
          setMSport(response.data.tournament.sports_type);

          setMGate(response.data.tournament.gateway_charges);

          // crmagecatelist().then((res1) => {
          //     if (res1.status === true) {
          //         if (res1.data.length !== 0) {
          //             arr = [];

          //             if (response.data.tournament.agecat_or_std === 1) {
          //                 var catg = response.data.tournament.categories.split(",")
          //                 for (let j = 0; j < catg.length; j++) {
          //                     for (let i = 0; i < res1.data.length; i++) {
          //                         if (catg[j] === res1.data[i].age_category_name) {
          //                             arr.push({
          //                                 value: res1.data[i].id,
          //                                 label: res1.data[i].age_category_name,
          //                             });
          //                         }
          //                     }
          //                 }
          //                 setMCat(arr);

          //             } else if (response.data.tournament.agecat_or_std === 2) {
          //                 for (let i = 0; i < res1.data.length; i++) {
          //                     if (response.data.age_category.length !== 0) {
          //                         for (let j = 0; j < response.data.age_category.length; j++) {
          //                             if (response.data.age_category[j].age_categories === res1.data[i].age_category_name) {
          //                                 arr.push({
          //                                     value: res1.data[i].id,
          //                                     label: res1.data[i].age_category_name,
          //                                 });
          //                             }
          //                         }
          //                     }

          //                 }
          //                 setMCat(arr);

          //             }

          //         }
          //     }
          // })

          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }

          districtlist(response.data.tournament.state).then((res) => {
            if (res.status === true) {
              setDistList(res.data);
            }
          });
        }
      });
      crmrefereelist(5, 1).then((response) => {
        if (response.status === true) {
          let rarr = [];
          if (response.data.length !== 0) {
            
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              rarr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(rarr);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourtypelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
        }
      });

      coachsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      coachagecatelist().then((response) => {
        if (response.status === true) {
          let agearr = [];
          if (response.data.length !== 0) {
            
            for (let i = 0; i < response.data.length; i++) {
              agearr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(agearr);
        }
      });

      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          if (response.data.tournament.reporting_time !== null) {
            setRepTime(response.data.tournament.reporting_time);
          }
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.state);
          setMDist(response.data.tournament.district);

          setMSDate(response.data.tournament.start_date);
          setMEDate(response.data.tournament.end_date);
          setMEODate(response.data.tournament.entry_open);
          setMEDDate(response.data.tournament.entry_deadline_date);
          setRelayFees(response.data.tournament.relay_fees);

          setMFees(response.data.tournament.registration_fees);
          setMFree(response.data.tournament.free_tournament);
          setMAType(response.data.tournament.agecat_or_std + "");

          if(response.data.tournament.dob_start_date !== null){
            setMSDob(response.data.tournament.dob_start_date);
          }
          if(response.data.tournament.dob_end_date !== null){
            setMEDob(response.data.tournament.dob_end_date);
          }
          setMSportId(response.data.tournament.sports_type_no);
          setMSport(response.data.tournament.sports_type);

          setMGate(response.data.tournament.gateway_charges);

          // crmagecatelist().then((res1) => {
          //     if (res1.status === true) {
          //         if (res1.data.length !== 0) {
          //             arr = [];

          //             if (response.data.tournament.agecat_or_std === 1) {
          //                 var catg = response.data.tournament.categories.split(",")
          //                 for (let j = 0; j < catg.length; j++) {
          //                     for (let i = 0; i < res1.data.length; i++) {
          //                         if (catg[j] === res1.data[i].age_category_name) {
          //                             arr.push({
          //                                 value: res1.data[i].id,
          //                                 label: res1.data[i].age_category_name,
          //                             });
          //                         }
          //                     }
          //                 }
          //                 setMCat(arr);

          //             } else if (response.data.tournament.agecat_or_std === 2) {
          //                 for (let i = 0; i < res1.data.length; i++) {
          //                     if (response.data.age_category.length !== 0) {
          //                         for (let j = 0; j < response.data.age_category.length; j++) {
          //                             if (response.data.age_category[j].age_categories === res1.data[i].age_category_name) {
          //                                 arr.push({
          //                                     value: res1.data[i].id,
          //                                     label: res1.data[i].age_category_name,
          //                                 });
          //                             }
          //                         }
          //                     }

          //                 }
          //                 setMCat(arr);

          //             }

          //         }
          //     }
          // })

          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }

          districtlist(response.data.tournament.state).then((res) => {
            if (res.status === true) {
              setDistList(res.data);
            }
          });
        }
      });
      coachrefereelist(5, 1).then((response) => {
        if (response.status === true) {
          let rarr = [];
          if (response.data.length !== 0) {
            
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              rarr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(rarr);
        }
      });
    }
    if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          if (response.data.tournament.reporting_time !== null) {
            setRepTime(response.data.tournament.reporting_time);
          }
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.state);
          setMDist(response.data.tournament.district);

          setMSDate(response.data.tournament.start_date);
          setMEDate(response.data.tournament.end_date);
          setMEODate(response.data.tournament.entry_open);
          setMEDDate(response.data.tournament.entry_deadline_date);
          setRelayFees(response.data.tournament.relay_fees);

          setMFees(response.data.tournament.registration_fees);
          setMFree(response.data.tournament.free_tournament);
          setMAType(response.data.tournament.agecat_or_std + "");

          if(response.data.tournament.dob_start_date !== null){
            setMSDob(response.data.tournament.dob_start_date);
          }
          if(response.data.tournament.dob_end_date !== null){
            setMEDob(response.data.tournament.dob_end_date);
          }
          setMSportId(response.data.tournament.sports_type_no);
          setMSport(response.data.tournament.sports_type);

          setMGate(response.data.tournament.gateway_charges);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }

          districtlist(response.data.tournament.state).then((res) => {
            if (res.status === true) {
              setDistList(res.data);
            }
          });
        }
      });
    }
    listref();
    listcrm();
    listschool();
    listclass();
  }, []);

  const [cagecat, setCAgeCat] = useState("");
  function listclass() {
    setTClsList([]);
    if (localStorage.getItem("Role") === "1") {
      touragecategory(id).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setCAgeCat(response.data[0].age_categories);
          }
          setTClsList(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      agecategorytourcityreg(id).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setCAgeCat(response.data[0].age_categories);
          }
          setTClsList(response.data);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      agecategorytourcoach(id).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setCAgeCat(response.data[0].age_categories);
          }
          setTClsList(response.data);
        }
      });
    }
  }
  const [filterProduct, setFilterProduct] = useState([]);

  function listref() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "1") {
      tourassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }

  const [CRMProduct, setCRMProduct] = useState([]);

  function listcrm() {
    setCRMProduct([]);
    if (localStorage.getItem("Role") === "1") {
      tourassigncrmlist(id).then((response) => {
        if (response.status === true) {
          setCRMProduct(response.data);
        }
      });
    }
  }
  function selectstate(e) {
    e.preventDefault();
    setMState(e.target.value);
    setDistList([]);
    setMDist("");
    if (e.target.value !== "") {
      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDistList(response.data);
        }
      });
    }
  }

  const [cirerror, setCirError] = useState("");
  function circfile(event) {
    setCirError("");
    setMCir("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf)$/i)) {
        setMCir(event.target.files[0]);
      } else {
        setCirError("Circular File should be pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setCirError("Circular File size should be less than 5MB");
      }
    }
  }

  const [logoerror, setLogoError] = useState("");
  const [lfile, setLFile] = useState("");

  function logofile(event) {
    setLogoError("");
    setMCir("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLFile(event.target.files[0]);
      } else {
        setLogoError("Logo should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setLogoError("Logo size should be less than 5MB");
      }
    }
  }


  function handleType(e) {
    setMType(e.target.value);
    setMSchId("");
  }
  function handleAge(e) {
    setMCat(e);
  }

  function handleCls(e) {
    setTCls(e);
  }

  function handleSport(e) {
    setMSportId(e.target.value);
    setMSport("");
    const data = spotypelist.find(
      (item) => item.sports_cat_uid == e.target.value
    );
    setMSport(data.sports_category);
  }
  function handleSchool(e) {
    setMSchId(e);
  }

  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function toursubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMsg("");
    var serror = "";
    // if (mfees === "0") {
    //   serror = "required";
    //   setErrorMsg("Fees must be greater than zero");
    // }


    if (msport.toUpperCase() !== "ATHLETICS") {
      if (mfree === "N") {
        if (mfees === "") {
          serror = "required";
          setErrorMsg("Fees is required");
        }
        if (mfees === "0") {
          serror = "required";
          setErrorMsg("Fees must be greater than zero");
        }
      }
      if (mleatype === "") {
        serror = "required";
        setErrorMsg("Please Select League Type");
      }
    }

    if (
      serror === "" &&
      mname !== "" &&
      mcode !== "" &&
      mtype !== "" &&
      mstate !== "" &&
      mdist !== "" &&
      msdate !== "" &&
      medate !== "" &&
      matype !== "" &&
      meodate !== "" &&
      meddate !== "" &&
      msportid !== "" &&
      mgate !== "" &&
      reptime !== ""
    ) {
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("meet_name", mname);
      temp.append("location", mloc);
      temp.append("meet_code", mcode);
      // temp.append("meet_type", mtype)
      temp.append("state", mstate);
      temp.append("district", mdist);
      temp.append("start_date", msdate);
      temp.append("end_date", medate);
      temp.append("entry_deadline_date", meddate);
      temp.append("entry_opens", meodate);
      temp.append("sports_type", msport);
      temp.append("sports_type_no", msportid);
      // temp.append("agecat_or_std", matype)
      // temp.append("standard", clsid)
      // temp.append("categories", catid)
      temp.append("dob_start_date", msdob);
      temp.append("dob_end_date", medob);
      temp.append("gateway_charges", mgate);
      temp.append("free_tournament", mfree);

      // temp.append("registration_fees", mfees);
      if (msport.toUpperCase() === "ATHLETICS") {
        temp.append("registration_fees", 0);
      } else {
        if (mfree === "N") {
          temp.append("registration_fees", mfees);
        } else {
          temp.append("registration_fees", 0);
        }
      }
      if (relayfees !== "") {
        temp.append("relay_fees", relayfees);
      } else {
        temp.append("relay_fees", 0);
      }
      temp.append("payment_gateway", "Razorpay");
      temp.append("league_type", mleatype);
      // temp.append("school", schid)
      temp.append("circular_file_1", mcir);
      temp.append("reporting_time", reptime);
      temp.append("tournament_logo", lfile);
      temp.append("multi_sports", mulsp);

      temp.append("circular_file_1", mcir);

      dotracking(
        "submit button in edit tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      if (localStorage.getItem("Role") === "1") {
        tourupdate(temp).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            navigate("/superadmin/tournamentlist");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        crmtourupdate(temp).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            navigate("/cityregional/tournamentlist");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      }else if (localStorage.getItem("Role") === "7") {
        coachtourupdate(temp).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            navigate("/coach/tournamentlist");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    }
  }

  function handleCRM(e) {
    setCRMid(e);
  }
  function handleRef(e) {
    setRefid(e);
  }

  const [clerrorMsg, setClErrorMsg] = useState("");

  function assignclass() {
    setClErrorMsg("");

    if (tcls.length !== 0) {
      var rid = "";
      var rname = "";
      for (let i = 0; i < tcls.length; i++) {
        if (rid === "") {
          rid = tcls[i].value;
          rname = tcls[i].label;
        } else {
          rid = rid + "," + tcls[i].value;
          rname = rname + "," + tcls[i].label;
        }
      }
      // if (localStorage.getItem("Role") === "1") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("categories", cagecat);
      temp.append("standard", rid);
      dotracking(
        rname +
          " (" +
          rid +
          ") class submit button in edit tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.edittourheading
      );
      tourstandardclassadd(temp).then((response) => {
        if (response.status === true) {
          listclass();
          setTCls("");
          toast.success(response.message);
        } else {
          setClErrorMsg(response.message);
        }
      });
    } else {
      setClErrorMsg("Please Select Class");
    }
  }

  const [rerrorMsg, setRErrorMsg] = useState("");

  function assignref() {
    setRErrorMsg("");

    if (refid !== "") {
      var rid = "";
      var refname = "";
      if (refid.length !== 0) {
        for (let i = 0; i < refid.length; i++) {
          if (rid === "") {
            rid = refid[i].value;
            refname = refid[i].label;
          } else {
            rid = rid + "," + refid[i].value;
            refname = refname + "," + refid[i].label;
          }
        }
      }
      dotracking(
        refname +
          " (" +
          rid +
          ") referee submit button in edit tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.edittourheading
      );

      if (localStorage.getItem("Role") === "1") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);
        tourassignreferee(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setRErrorMsg(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);

        crmtourrefereeassign(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setRErrorMsg(response.message);
          }
        });
      }else if (localStorage.getItem("Role") === "7") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);

        coachtourrefereeassign(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setRErrorMsg(response.message);
          }
        });
      }
    } else {
      setRErrorMsg("Please Select Referee");
    }
  }

  const [cerrorMsg, setCErrorMsg] = useState("");
  function assigncrm() {
    setCErrorMsg("");
    if (crmid !== "") {
      var rid = "";
      var crmname = "";
      if (crmid.length !== 0) {
        for (let i = 0; i < crmid.length; i++) {
          if (rid === "") {
            rid = crmid[i].value;
            crmname = crmid[i].label;
          } else {
            rid = rid + "," + crmid[i].value;
            crmname = crmname + "," + crmid[i].label;
          }
        }
      }
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("assigned_crm", rid);
      dotracking(
        crmname +
          " (" +
          rid +
          ") city manager submit button in edit tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.edittourheading
      );

      tourassigncrm(temp).then((response) => {
        if (response.status === true) {
          listcrm();
          setCRMid("");
          toast.success(response.message);
        } else {
          setCErrorMsg(response.message);
        }
      });
    } else {
      setCErrorMsg("Please Select Manager");
    }
  }

  function listschool() {
    setSchList([]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let sarr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                sarr.push({
                  id: response.data.mappedschools[i].id,
                  school_id: response.data.mappedschools[i].school_id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                  delicon: "",
                });
                if (response.data.tournament.meet_type === "Inter School Tournament") {
                const itemp = new FormData();
                  itemp.append("tournament_meet_id", id);
                  itemp.append("school_id", response.data.mappedschools[i].school_id);
                  interschooltourpaidornot(itemp).then((res1) => {
                    if (res1.status === true && res1.data !== null) {
                      sarr[i].delicon = res1.data;
                      setMSch([...sarr]);
                    }
                  });
                }
              }
              setMSch(sarr);
            }
          }
        }
      });
      schoolactivelist().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let cmarr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                cmarr.push({
                  id: response.data.mappedschools[i].id,
                  school_id: response.data.mappedschools[i].school_id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                  delicon: "",
                });
              }
              setMSch(cmarr);
            }
          }
        }
      });
      schoollistcityreg().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr1.push({
                value: response.data[i].id,
                label:
                  response.data[i].school_unique_no +
                  " - " +
                  response.data[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let smarr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                smarr.push({
                  id: response.data.mappedschools[i].id,
                  school_id: response.data.mappedschools[i].school_id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                  delicon: "",
                });
              }
              setMSch(smarr);
            }
          }
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let cmarr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                cmarr.push({
                  id: response.data.mappedschools[i].id,
                  school_id: response.data.mappedschools[i].school_id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                  delicon: "",
                });
              }
              setMSch(cmarr);
            }
          }
        }
      });
      schoolactivelistcoach().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } 
  }

  const [schid, setSchid] = useState("");
  function handleSchool(e) {
    setSchid(e);
  }

  const [serrorMsg, setSErrorMsg] = useState("");
  function assignschool() {
    setSErrorMsg("");
    if (schid !== "") {
      var rid = "";
      var schname = "";
      if (schid.length !== 0) {
        for (let i = 0; i < schid.length; i++) {
          if (rid === "") {
            rid = schid[i].value;
            schname = schid[i].label;
          } else {
            rid = rid + "," + schid[i].value;
            schname = schname + "," + schid[i].label;
          }
        }
      }
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("school", rid);
      dotracking(
        schname +
          " (" +
          rid +
          ") school submit button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.edittourheading
      );
      tourassignschool(temp).then((response) => {
        if (response.status === true) {
          listschool();
          setSchid("");
          toast.success(response.message);
        } else {
          setSErrorMsg(response.message);
        }
      });
    } else {
      setSErrorMsg("Please Select School");
    }
  }

  function deleteref(rid, rname) {
    dotracking(
      rname +
        " (" +
        rid +
        ") referee delete button in edit tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.edittourheading
    );
    if (localStorage.getItem("Role") === "1") {
      tourassignrefdeletelist(id, rid).then((response) => {
        if (response.status === true) {
          listref();
          toast.success(response.message);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmassignrefdeletelist(id, rid).then((response) => {
        if (response.status === true) {
          listref();
          toast.success(response.message);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachassignrefdeletelist(id, rid).then((response) => {
        if (response.status === true) {
          listref();
          toast.success(response.message);
        }
      });
    }
  }

  function deletecls(rid, rname) {
    dotracking(
      rname +
        " (" +
        rid +
        ") class delete button in edit tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.edittourheading
    );
    // if (localStorage.getItem("Role") === "1") {
    tourclassdelete(rid).then((response) => {
      if (response.status === true) {
        listclass();
        toast.success(response.message);
      }
    });
    // }
  }

  function deletecrm(rid, rname) {
    dotracking(
      rname +
        " (" +
        rid +
        ") city manager delete button in edit tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.edittourheading
    );
    if (localStorage.getItem("Role") === "1") {
      tourassigncrmdeletelist(id, rid).then((response) => {
        if (response.status === true) {
          listcrm();
          toast.success(response.message);
        }
      });
    }
  }


  function deletesch(rid, rname) {
    dotracking(
      rname +
        " (" +
        rid +
        ") school delete button in edit tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.edittourheading
    );
    if (localStorage.getItem("Role") === "1") {
      const temp = new FormData();
      temp.append("tournament_id",id)
      temp.append("school_id",rid)
      tourassignschooldeletelist(temp).then((response) => {
        if (response.status === true) {
          listschool();
          toast.success(response.message);
        }
      });
    }
  }


  const [relayfees, setRelayFees] = useState("");
  const relayhandlefees = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setRelayFees(e.target.value);
    }
  };

  const handlefees = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMFees(e.target.value);
    }
  };


  function backlist() {
    dotracking(
      "back button in edit tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentdetails/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Tournament Details
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tournament Name"
                      value={mname}
                      onChange={(e) => setMName(e.target.value)}
                    />
                    {submitted && !mname && (
                      <span className="errortext">
                        Tournament Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Code <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tournament Code"
                      value={mcode}
                      onChange={(e) => setMCode(e.target.value.toUpperCase())}
                      maxLength={4}
                      readOnly
                    />
                    {submitted && !mcode && (
                      <span className="errortext">
                        Tournament Code is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Type <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mtype}
                      onChange={handleType}
                      disabled
                    >
                      <option value="" defaultValue disabled>
                        --- Select Tournament Type ---
                      </option>
                      {typelist.map((data, i) => {
                        return (
                          <option value={data.id} key={i}>
                            {data.meet_type}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !mtype && (
                      <span className="errortext">
                        Tournament Type is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      State <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mstate}
                      onChange={selectstate}
                      disabled
                    >
                      <option value="" defaultValue disabled>
                        --- Select State ---
                      </option>
                      {slist.map((data, i) => {
                        return (
                          <option value={data.stateuid} key={i}>
                            {data.statename}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !mstate && (
                      <span className="errortext">State is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      District <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mdist}
                      onChange={(e) => setMDist(e.target.value)}
                      disabled
                    >
                      <option value="" defaultValue disabled>
                        --- Select District ---
                      </option>
                      {dlist.map((data, i) => {
                        return (
                          <option value={data.districtuid} key={i}>
                            {data.districtname}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !mdist && (
                      <span className="errortext">District is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Start on <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={msdate}
                      // min={curdate}
                      onChange={(e) => setMSDate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !msdate && (
                      <span className="errortext">
                        Tournament Start On is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Ends at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={medate}
                      min={msdate}
                      onChange={(e) => setMEDate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !medate && (
                      <span className="errortext">
                        Tournament Ends at is required
                      </span>
                    )}
                  </div>
                </div>

                {localStorage.getItem("Role") === "1" &&
                <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    Entry Opens at <span className="errortext"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={meodate}
                    max={medate}
                    onChange={(e) => setMEODate(e.target.value)}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                  {submitted && !meodate && (
                    <span className="errortext">
                      Entry Opens at is required
                    </span>
                  )}
                </div>
              </div>

                }
                {localStorage.getItem("Role") !== "1" &&
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Opens at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={meodate}
                      min={curdate}
                      max={medate}
                      onChange={(e) => setMEODate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !meodate && (
                      <span className="errortext">
                        Entry Opens at is required
                      </span>
                    )}
                  </div>
                </div>
                }

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Last Date <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={meddate}
                      min={meodate}
                      max={medate}
                      onChange={(e) => setMEDDate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !meddate && (
                      <span className="errortext">
                        Entry Last Date is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Age Category Type <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={matype}
                      onChange={(e) => setMAType(e.target.value)}
                      disabled
                    >
                      <option value="" defaultValue disabled>
                        --Select Age Category Type--
                      </option>
                      <option value="2">By Date of Birth</option>
                      <option value="1">By Class / Grade</option>
                    </select>
                    {submitted && !matype && (
                      <span className="errortext">
                        Age Category Type is required
                      </span>
                    )}
                  </div>
                </div>

                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Categories <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Categories"
                        value={mcat}
                        options={agecatlist}
                        onChange={handleAge}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                        isDisabled
                      />
                      {submitted && !mcat && (
                        <span className="errortext">
                          Age Categories is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* {matype === "1" &&
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Class <span className='errortext'> *</span></label>
                                            <Select
                                                className="dropdown"
                                                placeholder="Select Class"
                                                value={tcls}
                                                options={clslist}
                                                onChange={handleCls}
                                                isSearchable={true}
                                                isMulti
                                                isClearable
                                                hidden
                                                isDisabled
                                            />
                                            {submitted && !mcat &&
                                                <span className='errortext'>Age Categories is required</span>
                                            }
                                        </div>
                                    </div>
                                } */}

                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        DOB Start Date <span className="errortext"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={msdob}
                        // disabled
                        onChange={(e) => setMSDob(e.target.value)}
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                      {submitted && !msdob && (
                        <span className="errortext">
                          DOB Start Date is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        DOB End Date <span className="errortext"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={medob}
                        min={msdob}
                        // disabled
                        onChange={(e) => setMEDob(e.target.value)}
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                      {submitted && !medob && (
                        <span className="errortext">
                          DOB End Date is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Multi Sports <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mulsp}
                      onChange={(e)=>setMulSp(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Multi Sports ---
                      </option>
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>

                    </select>
                    
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Sports <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={msportid}
                      onChange={handleSport}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Sports ---
                      </option>
                      {spotypelist.map((data, i) => {
                        return (
                          <option value={data.sports_cat_uid} key={i}>
                            {data.sports_category}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !msportid && (
                      <span className="errortext">Sports is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Free Tournament </label>
                    <select
                      className="form-control custom-select"
                      value={mfree}
                      onChange={(e) => setMFree(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Free Tournament--
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Payment Gateway Charges{" "}
                      <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mgate}
                      onChange={(e) => setMGate(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Payment Gateway--
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                    {submitted && !mgate && (
                      <span className="errortext">
                        Payment Gateway Charges is required
                      </span>
                    )}
                  </div>
                </div> */}
                {msport.toUpperCase() === "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Relay Fees</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Relay Fees"
                        maxLength={10}
                        value={relayfees}
                        onChange={relayhandlefees}
                      />
                    </div>
                  </div>
                )}

                {mfree === "N" && msport.toUpperCase() !== "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Fees <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fees"
                        value={mfees}
                        onChange={handlefees}
                      />
                      {submitted && !mfees && (
                        <span className="errortext">Fees is required</span>
                      )}
                    </div>
                  </div>
                )}

                
                {msport.toUpperCase() !== "ATHLETICS" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          League Format <span className="errortext"> *</span>
                        </label>
                        {/* <input type="text" className="form-control" placeholder="League Type" value={mleatype}
                      onChange={(e) => setMLeaque(e.target.value)} /> */}
                        <select
                          className="form-control custom-select"
                          value={mleatype}
                          onChange={(e) => setMLeaque(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --Select League Format--
                          </option>
                          <option value="Roundrobin">Round-Robin</option>
                          <option value="Knockout">Knock Out</option>
                        </select>
                        {submitted && !mleatype && (
                          <span className="errortext">
                            League Format is required
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* {mtype === "Inter School Tournament" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={mschid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                        isDisabled
                      />
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}

                {mtype !== "Inter School Tournament" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={mschid}
                        onChange={(e) => setMSchId(e.target.value)}
                        disabled
                      >
                        <option value="" defaultValue disabled>
                          --Select School--
                        </option>
                        {schlist.map((data, i) => {
                          return (
                            <option value={data.value} key={i}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )} */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Reporting Time <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Reporting Time"
                      value={reptime}
                      onChange={(e) => setRepTime(e.target.value)}
                    />
                    {submitted && !reptime && (
                      <span className="errortext">
                        Reporting Time is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Upload Circular </label>
                    {/* <a href={mcir} target="_blank" download={mcir}>
                      <b className="blink">
                        Click Here! <i className="fa fa-download ms-2"></i>
                      </b>
                    </a> */}
                    <input
                      type="file"
                      className="form-control"
                      accept=".pdf"
                      onChange={circfile}
                    />
                    {/* {submitted && !mcir &&
                                            <span className='errortext'>Circular is required</span>
                                        } */}
                    {cirerror !== "" && (
                      <span className="errortext">{cirerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Logo <span className="errortext"> *</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={logofile}
                    />

                    {logoerror !== "" && (
                      <span className="errortext">{logoerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Tournament Location </label>
                    <textarea
                      rows={3}
                      className="form-control"
                      placeholder="Tournament Location"
                      value={mloc}
                      onChange={(e) => setMLoc(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}

              <div className="card-footer text-right border-bottom">
                <a onClick={backlist} className="btn btn-gray me-2">
                  Back
                </a>
                <button
                  type="submit"
                  className="btn btn-success"
                  title="Update Tournament"
                  onClick={toursubmit}
                >
                  Submit
                </button>
              </div>
              {/* {matype === "1" && (
                <div className="border-bottom">
                  <div className="row">
                    <div className="col-md-4 border-right">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Select Class{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Class"
                                value={tcls}
                                options={clslist}
                                onChange={handleCls}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                            {clerrorMsg !== "" && (
                              <span className="errortext">{clerrorMsg}</span>
                            )}
                            <div className="form-group">
                              <a
                                className="btn btn-success"
                                title="Update Class"
                                onClick={assignclass}
                              >
                                Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div className="col-md-8 border-left">
                      <div className="details-group refereebox deletereferee">
                        <div className="row">
                          {tclslist.length !== 0 && (
                            <h4 className="mt-2">Class List</h4>
                          )}
                          {tclslist.map((data, i) => {
                            return (
                              <div
                                className="col-md-3 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">
                                    {data.standard_name}{" "}
                                  </h4>
                                  <a
                                    className="deleterefer"
                                    onClick={() =>
                                      deletecls(data.id, data.standard_name)
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              )} */}

              {/* <h4>Referee Details</h4> */}
              <div className="row">
                <div className="col-md-4 border-right">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-12">
                        {localStorage.getItem("Role") === "1" && (
                          <>
                            <div className="form-group">
                              <label className="form-label">
                                Select Manager{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Manager"
                                value={crmid}
                                options={crmlist}
                                onChange={handleCRM}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                            {cerrorMsg !== "" && (
                              <span className="errortext">{cerrorMsg}</span>
                            )}
                            <div className="form-group">
                              <a
                                className="btn btn-success"
                                title="Update Manager"
                                onClick={assigncrm}
                              >
                                Submit
                              </a>
                            </div>
                          </>
                        )}
                        {(localStorage.getItem("Role") === "1" || localStorage.getItem("Role") === "3") && (
                          <>
                            <div className="form-group">
                              <label className="form-label">
                                Select School{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select School"
                                value={schid}
                                options={schlist}
                                onChange={handleSchool}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                            {serrorMsg !== "" && (
                              <span className="errortext">{serrorMsg}</span>
                            )}
                            <div className="form-group">
                              <a
                                className="btn btn-success"
                                title="Update School"
                                onClick={assignschool}
                              >
                                Submit
                              </a>
                            </div>
                          </>
                        )}
                        <div className="form-group">
                          <label className="form-label">
                            Select Referees{" "}
                            <span className="errortext"> *</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select Referees"
                            value={refid}
                            options={reflist}
                            onChange={handleRef}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                        </div>
                        {rerrorMsg !== "" && (
                          <span className="errortext">{rerrorMsg}</span>
                        )}
                        <div className="form-group">
                          <a
                            className="btn btn-success"
                            title="Update Referee"
                            onClick={assignref}
                          >
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 border-left">
                  <div className="details-group refereebox deletereferee">
                    <div className="row">
                      {localStorage.getItem("Role") === "1" && (
                        <>
                          {CRMProduct.length !== 0 && (
                            <h4 className="mt-2">Manager List</h4>
                          )}
                          {CRMProduct.map((data, i) => {
                            return (
                              <div
                                className="col-md-4 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">
                                    {data.first_name} {data.last_name}
                                  </h4>
                                  <h3 className="text-gray mb-0">
                                    {data.username}
                                  </h3>
                                  <a
                                    className="deleterefer"
                                    onClick={() =>
                                      deletecrm(
                                        data.user_id,
                                        data.first_name + " " + data.last_name
                                      )
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                          {CRMProduct.length !== 0 && (
                            <div className="border-bottom"></div>
                          )}
                        </>
                      )}

                      {localStorage.getItem("Role") === "1" && (
                        <>
                          {mschool.length !== 0 && (
                            <h4 className="mt-2">School List</h4>
                          )}
                          {mschool.map((data, i) => {
                            return (
                              <div
                                className="col-md-4 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">{data.school}</h4>
                                  
                                  {mtype === "Inter School Tournament" && data.delicon ==="" &&(
                                    <a
                                      className="deleterefer"
                                      onClick={() =>
                                        deletesch(
                                          data.school_id,
                                          data.school
                                        )
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </a>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {mschool.length !== 0 && (
                            <div className="border-bottom"></div>
                          )}
                        </>
                      )}

                      {filterProduct.length !== 0 && (
                        <h4 className="mt-2">Referee List</h4>
                      )}

                      {filterProduct.map((data, i) => {
                        return (
                          <div className="col-md-4 align-self-center" key={i}>
                            <div className="form-group">
                              <h4 className="mb-1">
                                {data.first_name} {data.last_name}
                              </h4>
                              <h3 className="text-gray mb-0">
                                {data.username}
                              </h3>
                              <a
                                className="deleterefer"
                                onClick={() =>
                                  deleteref(
                                    data.user_id,
                                    data.first_name + " " + data.last_name
                                  )
                                }
                              >
                                <i className="fa fa-close"></i>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
