import React, { useEffect, useState } from "react";
import {
  blocked,
  reasonlist,
  unblocked,
  viewprofile,
} from "../../../../services/superadmin";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import { crmallrefereeview } from "../../../../services/cityregionaladmin";
import ImageUrl from "../../../../ImageUrl";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ViewReferee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [uname, setUser] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(false);
  const [ishow, setImage] = useState(false);

  const [school, setSchool] = useState("");

  const [realist, setReaList] = useState([]);
  const [rreason, setRReason] = useState("");
  const [rremark, setRemarks] = useState("");

  useEffect(() => {
    crmallrefereeview(id).then((response) => {
      if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          setDOB(moment(response.data.dob).format("DD-MM-yyyy"));
          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          setUser(response.data.user_id);
          setAddress(response.data.address);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCity(response.data.cityname);
          setPincode(response.data.pincode);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }
          if (response.data.school_name !== null) {
            setSchool(response.data.school_name);
          }
          // setClass(response.data.class);
          // setSection(response.data.section);
      }
    });
  }, []);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function editpage(){
    dotracking("edit button in view referee for " + fname + " " + lname + " (" + email + " - "+ id + ") $$ " + trackobj.refereeeditheading);
    navigate("/superadmin/editreferee/" + id)
  }
  function backlist(){
    dotracking("back button in view referee for " + fname + " " + lname + " (" + email + " - "+ id + ") $$ " + trackobj.refereelistheading);
    if(localStorage.getItem("Role") === "1"){
      navigate("/superadmin/refereelist");
    }else if(localStorage.getItem("Role") === "3"){
      navigate("/cityregional/refereelist");
    }else if(localStorage.getItem("Role") === "4"){
      navigate("/schoolhead/refereelist");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Referee Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Referee</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Referee Details
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <h4>Primary Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">First Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{fname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Last Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{lname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{dob}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Contact Number</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mobno}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Email Address</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <h4>Personal Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-2 col-md-6">
                        <b className="text-inverse">Address</b>
                      </div>
                      <div className="col-md-10 col-md-6">
                        <span className="text-inverse">{address}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {school !== "" && (
                  <div className="col-md-12">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-2 col-md-6">
                          <b className="text-inverse">School</b>
                        </div>
                        <div className="col-md-10 col-md-6">
                          <span className="text-inverse">{school}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{state}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{district}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">City</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{city}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Pincode</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{pincode}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4>Photograph</h4>
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="row">
                    {/* <div className="col-lg-2 col-md-6">
                      <b className="text-inverse">Photo Proof</b>
                    </div> */}
                    <div className="col-md-3">
                      <div className="link-btn">
                        <a>
                          <img
                            className="profile_img"
                            src={photo}
                            height="160"
                            alt="photo"
                            onClick={(e) => ImageShow(photo)}
                            onError={(e) => errorhandle(e)}
                          />
                        </a>
                        <div>
                          {/* <a
                            onClick={(e) => ImageShow(photo)}
                            className="btn btn-info btn-sm me-2 mt-2"
                            target="_blank"
                          >
                            View
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="card-footer text-right border-top">
                {localStorage.getItem("Role") === "1" && (
                  <a
                    className="btn btn-success me-2"
                    onClick={editpage}
                  >
                    Edit
                  </a>
                )}

                <a className="btn btn-gray" onClick={backlist}>
                  Back
                </a>
              </div>
            </div>
          </form>
          <Modal show={ishow} onHide={ImageClose}>
            <Modal.Body>
              <img
                className="profile_img prof-img"
                src={file}
                height="160"
                alt="photo"
                onError={(e) => errorhandle(e)}
              />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={ImageClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
