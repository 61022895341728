import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const FunderSidebar = () => {
  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [touract, setTourAct] = useState(false);

  const [schact, setSchAct] = useState(false);
  const [stlistact, setSTListAct] = useState(false);

  const [peract, setPerAct] = useState(false);
  const [keyact, setKeyAct] = useState(false);
  const [achact, setAchAct] = useState(false);
  const [benact, setBenAct] = useState(false);



  const [upact, setUpAct] = useState(false);
  const [upcmsact, setUPCmsAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setSchAct(false);
    setSTListAct(false);

    setTourAct(false);

    setUpAct(false);
    setUPCmsAct(false);

    setPerAct(false);
    setKeyAct(false);
    setAchAct(false);
    setBenAct(false);
    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/funder/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tournaments") {
      setTourAct(true);
      //   dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/funder/tournamentlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "School") {
      setSchAct(true);
        dotracking("school list menu $$ " + trackobj.schoollistheading);
      navigate("/funder/schoollist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "CMS") {
      setUpAct(true);
      setUPCmsAct(true);
      dotracking("cms menu $$ " + trackobj.addcmsheading);
      navigate("/funder/addfundercms/" + localStorage.getItem("UID"));
    }else if (head === "Performance") {
      setPerAct(true);
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } 
  }
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();
  const { cid } = useParams();

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);

    setSchAct(false);
    setSTListAct(false);

    setTourAct(false);

    setUpAct(false);
    setUPCmsAct(false);

    setPerAct(false);
    setKeyAct(false);
    setAchAct(false);
    setBenAct(false);
    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/funder/dashboard");
    } else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/funder/contact");
    } else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/funder/changepassword");
    } else if (head === "schoollist") {
      setSchAct(true);
      setSTListAct(true);
      navigate("/funder/schoollist");
    // } else if (head === "allplayers") {
    //   setSchAct(true);
    //   setSTListAct(true);
    //   navigate("/funder/allplayers/" + id);
    } else if (head === "viewplayer") {
      setPerAct(true);
      setBenAct(true);
      navigate("/funder/viewplayer/" + id);
    } else if (head === "addfundercms") {
      setUpAct(true);
      setUPCmsAct(true);
      navigate("/funder/addfundercms/" + id);
    } else if (head === "funderdetails") {
      setUpAct(true);
      setUPCmsAct(true);
      navigate("/funder/funderdetails");
    } else if (head === "tournamentlist") {
      setTourAct(true);
      navigate("/funder/tournamentlist");
    }else if (head === "tournamentdetails") {
      setTourAct(true);
      navigate("/funder/tournamentdetails/"+id);
    }else if (head === "categorylist") {
      setTourAct(true);
      navigate("/funder/categorylist/"+id);
    }else if (head === "poollist") {
      setTourAct(true);
      navigate("/funder/poollist/"+id + "/" + eid);
    }else if (head === "scheduleallocate") {
      setTourAct(true);
      navigate("/funder/scheduleallocate/"+id + "/" + eid);
    }else if (head === "schedulelist") {
      setTourAct(true);
      navigate("/funder/schedulelist/"+id + "/" + eid);
    }else if (head === "pointslist") {
      setTourAct(true);
      navigate("/funder/pointslist/"+id + "/" + eid);
    }else if (head === "results") {
      setTourAct(true);
      navigate("/funder/results/"+id);
    }else if (head === "athleticsresults") {
      setTourAct(true);
      navigate("/funder/athleticsresults/"+id);
    }else if (head === "assessmentdetail") {
      setPerAct(true);
      setBenAct(true);
      navigate("/funder/assessmentdetail/"+id + "/" + eid);
    }else if (head === "keyhighlights") {
      setPerAct(true);
      setKeyAct(true);
      if (type === true) {
        dotracking(
          "key highlights in sports performance menu $$ " + trackobj.keylistheading
        );
      }
      navigate("/funder/keyhighlights");
    }else if (head === "achievementlist") {
      setPerAct(true);
      setAchAct(true);
      if (type === true) {
        dotracking(
          "achievement list in sports performance menu $$ " + trackobj.achievelistheading
        );
      }
      navigate("/funder/achievementlist");
    }else if (head === "benificiarieslist") {
      setPerAct(true);
      setBenAct(true);
      if (type === true) {
        dotracking(
          "benificiaries list in sports performance menu $$ " + trackobj.benelistheading
        );
      }
      navigate("/funder/benificiarieslist");
    }else if (head === "othereventdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/funder/othereventdetail/"+id);
    }


    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1], false);
  }, []);

  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="School"
                className={
                  schact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("School")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-bank"></i>
                <div className="slider-text">School</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournament"
                className={
                  touract ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Performance"
                className={
                  peract ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Performance")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon fa fa-bar-chart"></i>
                <div className="slider-text">Sport Performance</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="CMS"
                className={
                  upact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("CMS")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-upload-outline"></i>
                <div className="slider-text">CMS</div>
              </li>
              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-6"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Profile</div>
              </li> */}
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard",true)}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-bank"></i>
                  <div className="slider-text">School</div>
                </h2>
                <div
                  className={
                    schact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-bank"></i> School
                      </h4>{" "}
                      <a
                        className={
                          stlistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoollist", true)}
                      >
                        School List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touract
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>
                        Tournaments
                      </h4>{" "}
                      <a
                        className={touract ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Tournament List
                      </a>
                    </div>
                  </div>
                </div>


                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-bar-chart"></i>
                  <div className="slider-text">Performance</div>
                </h2>
                <div
                  className={
                    peract
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-bar-chart"></i>{" "}
                        Performance
                      </h4>{" "}
                      <a
                        className={
                          keyact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("keyhighlights", true)}
                      >
                        Key Highlights
                      </a>
                      <a
                        className={
                          achact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementlist", true)}
                      >
                        Achievements List
                      </a>
                      <a
                        className={
                          benact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("benificiarieslist", true)}
                      >
                        Benificiaries List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-upload-outline"></i>
                  <div className="slider-text">CMS</div>
                </h2>
                <div
                  className={
                    upact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-upload-outline"></i>{" "}
                        CMS
                      </h4>{" "}
                      <a
                        className={
                          upcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addfundercms", true)}
                      >
                        Funder CMS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
