import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import ImageUrl from "../../../../ImageUrl";
import Select from "react-select";
import {
  MyUniquePerformance,
  tourdetails,
} from "../../../../services/register";
import {
  highlightslist,
  photoslist,
  schedulelist,
  schedulenoknocklist,
  winnerplayerlist,
} from "../../../../services/superadmin";
import {
  Playerprofiledetails,
  playerathleticsresult,
  playercertificatetracking,
  playergetcategory,
  playerresultlist,
  playertourbanner,
  playertourcertificate,
  playertourlist,
} from "../../../../services/player";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback } from "react";
import dotracking from "../../../trackinglogs";
import { Col, Row } from "react-bootstrap";
import { saveAs } from "file-saver";
import trackobj from "../../../trackingpages.json";
import baseUrl from "../../../../Api";
import bannerlog from "../../../trackingbanner";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export const ViewTourPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mcode, setMCode] = useState("");
  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [meodate, setMEODate] = useState("");
  const [meddate, setMEDDate] = useState("");
  const [msport, setMSport] = useState("");
  const [mschool, setMSch] = useState([]);
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");
  const [mgate, setMGate] = useState("");
  const [mcat, setMCat] = useState("");
  const [mleatype, setMLeaque] = useState("");
  const [mcir, setMCir] = useState("");
  const [reptime, setRepTime] = useState("");

  const [ageid, setAID] = useState("");
  const [agetype, setAType] = useState("");
  const [tourcls, setTCls] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);

  const [reslist, setResList] = useState([]);

  const [certlist, setCertList] = useState([]);
  const [certs3, setCertS3] = useState("");

  const [wgold, setWGold] = useState("");
  const [wsilver, setWSilver] = useState("");
  const [wbronze, setWBronze] = useState("");
  const [wlist, setWList] = useState(false);

  const [photolist, setPhotoLink] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [performfilterProduct, setPerformFilterProduct] = useState([]);
  const [roundsfilterProduct, setRoundsFilterProduct] = useState([]);

  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [bronze, setBronze] = useState(0);
  const [performance, setPerformance] = useState([]);
  const [total, setTotal] = useState(0);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const [pplist, setPPList] = useState([]);

  function openPLightbox(index, ilist) {
    setCurrentImage(index);
    if (ilist.length !== 0) {
      setPPList(ilist);
    }

    setViewerIsOpen(true);
  }

  const [pshow, setPShow] = useState(false);
  const handlePclose = () => {
    setPShow(false);
    setShow(false);
    dotracking(
      "close share " +
        shareTitle +
        " in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
  };
  const [shareUrl, setShare] = useState("");
  const [shareTitle, setTitle] = useState("");

  function handlePshow(timg, title) {
    setShare(timg);
    setTitle(title);
    setPShow(true);
    setShow(false);
    dotracking(
      "share " +
        title +
        " in view tournament for " +
        timg +
        " in " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const [urllink, setUrlLink] = useState("");
  const [tourbanner, setTourBanner] = useState("");
  const [linkbanner, setLinkBanner] = useState("");
  const [bannid, setBannerID] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    playertourlist().then((response) => {
      if (response.status === true) {
        if (response.data.registered_tour.length !== 0) {
          const fdata = response.data.registered_tour.find((item) => {
            return item.id === parseInt(id);
          });

          if (fdata !== undefined) {
            const temp = new FormData();
            temp.append("tournament_meet_id", id);
            temp.append("pagename", "Tournaments - Registered List");
            playertourbanner(temp).then((res) => {
              if (res.status === true && res.data.banners.length !== 0) {
                setBannerID(res.data.banners[0].banner_id);
                setTourBanner(
                  res.data.banner_path + res.data.banners[0].b_file
                );
                if (res.data.banners[0].banner_link !== null) {
                  setLinkBanner(res.data.banners[0].banner_link);
                }
              }
            });
          }
        }
      }
    });

    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        setMName(response.data.tournament.meet_name);
        if (response.data.tournament.location !== null) {
          setMLoc(response.data.tournament.location);
        }
        setRepTime(response.data.tournament.reporting_time);

        setMCode(response.data.tournament.meet_code);
        setMType(response.data.tournament.meet_type);
        setMState(response.data.tournament.statename);
        setMDist(response.data.tournament.districtname);
        setMSDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY")
        );
        setMEDate(
          moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setMEODate(
          moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
        );
        setMEDDate(
          moment(response.data.tournament.entry_deadline_date).format(
            "DD-MM-YYYY"
          )
        );
        setAID(response.data.tournament.agecat_or_std);
        if (response.data.tournament.agecat_or_std === 1) {
          setAType("By Class / Grade");
        } else if (response.data.tournament.agecat_or_std === 2) {
          setAType("By Date of Birth");
        }
        if (response.data.tournament.agecat_or_std === 1) {
          let arr1 = "";
          if (response.data.age_category.length !== 0) {
            for (let j = 0; j < response.data.age_category.length; j++) {
              if (arr1 === "") {
                arr1 = response.data.age_category[j].standard_name;
              } else if (arr1 !== "") {
                arr1 =
                  arr1 + " , " + response.data.age_category[j].standard_name;
              }
            }
          }
          setTCls(arr1);
        }
        setMSDob(
          moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
        );
        setMEDob(
          moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
        );
        setMSport(response.data.tournament.sports_type);

        if (response.data.tournament.gateway_charges === "N") {
          setMGate("No");
        } else if (response.data.tournament.gateway_charges === "Y") {
          setMGate("Yes");
        }
        setMCat(response.data.tournament.categories);
        setMLeaque(response.data.tournament.league_type);
        if (response.data.tournament.circular_file_1 !== null) {
          setMCir(
            // response.data.circular_path +
            ImageUrl +
              "tournament/circular/" +
              response.data.tournament.circular_file_1
          );
        }
      }
    });

    highlightslist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
    if (localStorage.getItem("UID") !== null) {
      playergetcategory(id, localStorage.getItem("UID")).then((res) => {
        if (res.status === true) {
          if (res.data.length !== 0) {
            const temp = new FormData();
            temp.append("tournament_id", id);
            temp.append("category_id", res.data[0].category_id);
            schedulenoknocklist(temp).then((response) => {
              if (response.status === true) {
                if (response.data.no_knockout_data.length !== 0) {
                  setWList(true);
                  setWGold(response.data.no_knockout_data[0].winner_team_name);
                  if (response.data.no_knockout_data[0].runner_team_id !== 0) {
                    setWSilver(
                      response.data.no_knockout_data[0].runner_team_name
                    );
                  }
                  if (
                    response.data.no_knockout_data[0].two_runner_team_id !== 0
                  ) {
                    setWBronze(
                      response.data.no_knockout_data[0].two_runner_team_name
                    );
                  }
                }
              }
            });
            let arr = [];
            let arr1 = [];
            schedulelist(id, res.data[0].category_id).then((response) => {
              if (response.status === true) {
                if (response.data.data_fixtures.length !== 0) {
                  for (let i = 0; i < response.data.data_fixtures.length; i++) {
                    if (response.data.data_fixtures[i].match_round !== 4) {
                      arr.push(response.data.data_fixtures[i]);
                    }
                    if (response.data.data_fixtures[i].match_round === 4) {
                      arr1.push(response.data.data_fixtures[i]);
                      if (response.data.data_fixtures[i].winner_name !== null) {
                        setWList(true);
                        setWGold(response.data.data_fixtures[i].winner_name);
                      }

                      if (
                        response.data.data_fixtures[i].winner_id ===
                        response.data.data_fixtures[i].team1_id
                      ) {
                        setWSilver(response.data.data_fixtures[i].team2_name);
                      }
                      if (
                        response.data.data_fixtures[i].winner_id ===
                        response.data.data_fixtures[i].team2_id
                      ) {
                        setWSilver(response.data.data_fixtures[i].team1_name);
                      }
                    }
                  }

                  setResList(arr);
                }
              }
            });
          }
        }
      });
    }

    // playerresultlist().then((response) => {
    //   if (response.status === true) {
    //     setResList(response.data);
    //   }
    // });

    // winnerplayerlist(id).then((response) => {
    //   if (response.status === true) {
    //     if (response.data.length !== 0) {
    //       setWList(true);
    //       setWGold(response.data[0].gold_team_name);
    //       setWSilver(response.data[0].bronze_team_name);
    //       setWBronze(response.data[0].silver_team_name);
    //     }
    //   }
    // });

    photoslist(id).then((response) => {
      if (response.status === true) {
        if (response.data.tournamentlist.length !== 0) {
          let arr1 = [];
          for (let i = 0; i < response.data.tournamentlist.length; i++) {
            arr1.push({
              id: response.data.tournamentlist[i].id,
              src:
                response.data.tournamentimage_path +
                response.data.tournamentlist[i].imagename,
              width: 1,
              height: 1,
            });
          }
          setPhotoLink(arr1);
        }
      }
    });

    playertourcertificate(id).then((response) => {
      if (response.status === true) {
        setCertList(response.data.details);
        setCertS3(response.data.s3);
      }
    });
    if (localStorage.getItem("UID") !== null) {
      playerathleticsresult(id, localStorage.getItem("UID")).then(
        (response) => {
          if (response.status === true) {
            setPerformFilterProduct(response.data.finals);
            setRoundsFilterProduct(response.data.heats);
          }
        }
      );
    }

    if (localStorage.getItem("Name") !== null) {
      Playerprofiledetails(localStorage.getItem("Name")).then((res) => {
        if (res.status === true) {
          if (res.data.athletelist !== null) {
            const temp1 = new FormData();
            temp1.append("tournament_id", id);
            temp1.append("user_id", res.data.athletelist.user_id);
            MyUniquePerformance(temp1).then((response) => {
              if (response.status === true) {
                if (
                  response.data.overall_champions_my_school[0]
                    .total_gold_medals !== null
                ) {
                  setGold(
                    response.data.overall_champions_my_school[0]
                      .total_gold_medals
                  );
                }
                if (
                  response.data.overall_champions_my_school[0]
                    .total_silver_medals !== null
                ) {
                  setSilver(
                    response.data.overall_champions_my_school[0]
                      .total_silver_medals
                  );
                }
                if (
                  response.data.overall_champions_my_school[0]
                    .total_bronze_medals !== null
                ) {
                  setBronze(
                    response.data.overall_champions_my_school[0]
                      .total_bronze_medals
                  );
                }

                setTotal(
                  parseInt(
                    response.data.overall_champions_my_school[0]
                      .total_gold_medals
                  ) +
                    parseInt(
                      response.data.overall_champions_my_school[0]
                        .total_silver_medals
                    ) +
                    parseInt(
                      response.data.overall_champions_my_school[0]
                        .total_bronze_medals
                    )
                );
                setPerformance(response.data.overall_champions_my_school);
              }
            });
          }
        }
      });
    }
  }, []);

  function overperflist() {
    if(localStorage.getItem("Role") === "6"){
    dotracking(
      "overall performance button in profile " +
      localStorage.getItem("User") +
        +" (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.overperfheading
    );
    
    navigate("/player/overallperformanceplayer");
    }
  }

  function perftourlist() {
    if(localStorage.getItem("Role") === "6"){
    dotracking(
      "tournament wise performance button in profile " +
      localStorage.getItem("User") +
        +" (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.tourperfheading
    );
    navigate("/player/viewperformanceplayer");
    }
  }



  function backlist() {
    if (urllink === "tournamentdetail" || urllink === "viewtournament") {
      dotracking(
        "back button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.dashheading
      );
      navigate("/player/dashboard");
    } else if (urllink === "registeredviewtournament") {
      dotracking(
        "back button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.reglistheading
      );
      navigate("/player/registeredlist");
    } else if (urllink === "eligibleviewtournament") {
      dotracking(
        "back button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.elilistheading
      );
      navigate("/player/tournamentlist");
    }
  }

  function orderlist() {
    dotracking(
      "order of events button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourorderlistheading
    );
    if (urllink === "tournamentdetail" || urllink === "viewtournament") {
      navigate("/player/orderofeventlist/" + id);
    } else if (urllink === "registeredviewtournament") {
      navigate("/player/registeredorderofeventlist/" + id);
    } else if (urllink === "eligibleviewtournament") {
      navigate("/player/eligibleorderofeventlist/" + id);
    }
  }

  function overlist() {
    dotracking(
      "overall champions list button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.overallchampionheading
    );
    if (urllink === "registeredviewtournament") {
      navigate("/player/registeredoverallchampion/" + id);
    } else if (urllink === "eligibleviewtournament") {
      navigate("/player/eligibleoverallchampion/" + id);
    } else {
      navigate("/player/overallchampionlist/" + id);
    }
  }

  function indlist() {
    dotracking(
      "individual champions list button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.individualchampionheading
    );
    if (urllink === "registeredviewtournament") {
      navigate("/player/registeredindividualchampion/" + id);
    } else if (urllink === "eligibleviewtournament") {
      navigate("/player/eligibleindividualchampion/" + id);
    } else {
      navigate("/player/individualchampionlist/" + id);
    }
  }

  function fixlist() {
    playergetcategory(id, localStorage.getItem("UID")).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          dotracking(
            "fixture button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.fixlistheading
          );
          if (urllink === "tournamentdetail" || urllink === "viewtournament") {
            navigate(
              "/player/fixturelist/" + id + "/" + res.data[0].category_id
            );
          } else {
            navigate(
              "/player/schedulelist/" + id + "/" + res.data[0].category_id
            );
          }
        }
      }
    });
  }

  function perflist() {
    dotracking(
      "player performance button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );
    if (urllink === "tournamentdetail" || urllink === "viewtournament") {
      localStorage.setItem("PPer", 1);
    } else if (urllink === "registeredviewtournament") {
      localStorage.setItem("PPer", 2);
    } else if (urllink === "eligibleviewtournament") {
      localStorage.setItem("PPer", 3);
    }
    navigate("/player/performanceplayerlist/" + id);
  }

  function perfphoto() {
    dotracking(
      "performance photo button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
    if (urllink === "tournamentdetail" || urllink === "viewtournament") {
      localStorage.setItem("PPer", 1);
    } else if (urllink === "registeredviewtournament") {
      localStorage.setItem("PPer", 2);
    } else if (urllink === "eligibleviewtournament") {
      localStorage.setItem("PPer", 3);
    }
    navigate("/player/performancephotos/" + id);
  }

  function certcheck(cname) {
    dotracking(
      "download button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament" +
        " with certificate " +
        cname +
        " (" +
        cerid +
        ") $$ " +
        trackobj.viewtourheading
    );
    window.open(baseUrl + "/certificate-download-in-pdf/" + cerid, "_blank");
    // saveAs(certs3 + cname, cname);
    playercertificatetracking(id, cerid, 2).then((response) => {
      if (response.status === true) {
      }
    });
  }

  const [show, setShow] = useState(false);
  const [certimg, setCertImg] = useState("");
  const [cerid, setCerId] = useState("");

  function handleShow(img, cid) {
    dotracking(
      "view certificate button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament" +
        " with certificate " +
        img +
        " (" +
        cid +
        ") $$ " +
        trackobj.viewtourheading
    );

    setCertImg(img);
    setCerId(cid);
    setShow(true);
    playercertificatetracking(id, cid, 1).then((response) => {
      if (response.status === true) {
      }
    });
  }

  function handleclose() {
    setShow(false);
    setCerId("");
    setCertImg("");
  }

  function banlink() {
    dotracking(
      "banner in view tournament for " +
        tourbanner +
        " (" +
        bannid +
        ") in " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    bannerlog(bannid, trackobj.bannertourlist);
    if (linkbanner !== "") {
      window.open(linkbanner, "_blank");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Details
          </li>
        </ol>
      </div>

      <div className="row ">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              {tourbanner !== "" && (
                <a onClick={banlink}>
                  <img
                    src={tourbanner}
                    className="ag-courses-item_img mb-2"
                    alt="bannerimg"
                  />
                </a>
              )}

              {msport.toUpperCase() === "ATHLETICS" && msport !== "" && (
                <>
                  <a
                    onClick={orderlist}
                    className="btnfixture me-2"
                    title="Order Of Events"
                  >
                    <i className="fa fa-calendar"></i> Order Of Events
                  </a>
                  <a
                    onClick={overlist}
                    className="btn btn-export me-2"
                    title="Overall Champions"
                  >
                    <i className="fas fa-trophy"></i> Overall Champions
                  </a>
                  <a
                    onClick={indlist}
                    className="btn btn-secondary"
                    title="Individual Champions"
                  >
                    <i className="fas fa-trophy"></i> Individual Champions
                  </a>
                </>
              )}
              {msport.toUpperCase() !== "ATHLETICS" && msport !== "" && (
                <>
                  <a
                    onClick={fixlist}
                    className="btnfixture me-2"
                    title="Fixture"
                  >
                    <i className="fas fa-object-group"></i> Fixtures
                  </a>
                  <a
                    onClick={perflist}
                    className="btn btn-export me-2"
                    title="Player Performance"
                  >
                    <i className="fas fa-list"></i> Player Performance
                  </a>
                  {/* <a onClick={perfphoto} className="btn btn-secondary" title="Performance Photo">
                    <i className="fa fa-picture-o"></i> Performance Photo
                  </a> */}
                </>
              )}

              <a className="btn btn-warning me-2" onClick={overperflist}>
                <i className="fas fa-list"></i> Overall Performance
              </a>
              <a className="btn btn-secondary me-2" onClick={perftourlist}>
                <i className="fas fa-list"></i> Tournament Wise Performance
              </a>

              <a
                className="btn btn-gray"
                onClick={backlist}
                style={{ float: "right" }}
              >
                <i className="fe fe-corner-up-left"></i> Back
              </a>

              <div className="row tournamentitem mt-2">
                <div className="col-md-12">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-2 col-md-6">
                        <b className="text-inverse">Tournament Name</b>
                      </div>
                      <div className="col-md-10 col-md-6">
                        <span className="text-inverse">{mname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Location</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mloc}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Tournament Code</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mcode}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Tournament Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mtype}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mstate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mdist}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msdate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{medate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Opens at</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{meodate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Last Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{meddate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Sports Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msport}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Age Category Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{agetype}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Age Categories</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mcat}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ageid === 1 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Class</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tourcls}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB Start Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{msdob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB End Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{medob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">League Format</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mleatype}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Reporting Time</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{reptime}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Payment Gateway Charges</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse">{mgate}</span>
                      </div>
                    </div>
                  </div>
                </div> */}
                {mcir !== "" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Circular <br />
                            Document
                          </b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">
                            <a href={mcir} target="_blank" download={mcir}>
                              <b className="blink">
                                Click Here!{" "}
                                <i className="fa fa-download ms-2"></i>
                              </b>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr />

              <div className="row">
                <h4>Photos</h4>
                {photolist.length === 0 && (
                  <div className="col-md-12">
                    <div className="text-center">
                      <h4 className="text-danger">
                        Photos will be updated soon
                      </h4>
                    </div>
                  </div>
                )}
                {/* <Gallery photos={photolist} onClick={openLightbox} /> */}
                <div className="row">
                  {photolist.map((data, i) => {
                    return (
                      <div className="col-md-2 mt-4" key={i}>
                        <a
                          className="photo-share"
                          onClick={() =>
                            handlePshow(data.src, "Hey, check out my photos!")
                          }
                        >
                          <i className="fas fa-share fa-lg"></i>
                        </a>

                        <img
                          className="photoimg"
                          src={data.src}
                          height="100px"
                          onClick={() => openPLightbox(i, photolist)}
                          alt="Photo"
                        />
                      </div>
                    );
                  })}
                </div>

                <div className={pshow ? "mymodal open" : "mymodal"}>
                  <div className="modoverlay"></div>
                  <div className="mymodal-content">
                    <h4 className="text-center">Share {shareTitle}</h4>
                    <span className="close-btn" onClick={handlePclose}>
                      &times;
                    </span>
                    <div className="border-bottom"></div>
                    <Row className="row-sm mt-3">
                      <div className="text-center">
                        <WhatsappShareButton
                          url={shareUrl}
                          title={shareTitle}
                          className="me-3"
                        >
                          <WhatsappIcon size={44} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton
                          url={shareUrl}
                          quote={shareTitle}
                          className="me-3"
                        >
                          <FacebookIcon size={44}></FacebookIcon>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={shareUrl}
                          title={shareTitle}
                          className="me-3"
                        >
                          <TwitterIcon size={44}></TwitterIcon>
                        </TwitterShareButton>
                      </div>
                    </Row>
                  </div>
                </div>

                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={pplist.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>

              <hr />
              <div className="row mt-3">
                <h4>Certificates</h4>
                {certlist.length === 0 && (
                  <div className="col-md-12">
                    <div className="text-center">
                      <h4 className="text-danger">
                        Certificates will be updated soon
                      </h4>
                    </div>
                  </div>
                )}
                {certlist.map((item, i) => {
                  return (
                    <div className="col-lg-3 col-md-3" key={i}>
                      <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                        <div className="card-body pt-2 pb-0 px-3">
                          <a
                            className="cert-share"
                            onClick={() =>
                              handlePshow(
                                certs3 + item.certificate_name,
                                "Hey, check out my certificate!"
                              )
                            }
                          >
                            <i className="fas fa-share fa-lg"></i>
                          </a>
                          <div className="text-center">
                            {item.type === "P" && (
                              <img
                                src={require("../../../../assets/images/participate.png")}
                                alt="participate"
                                title="Participate Certificate"
                                className="folder-img"
                              />
                            )}
                            {item.type === "M" && (
                              <img
                                src={require("../../../../assets/images/merit.png")}
                                alt="merit"
                                title="Merit Certificate"
                                className="folder-img"
                              />
                            )}
                          </div>
                        </div>
                        <div className="card-footer text-center py-2 px-3">
                          <a
                            className="btn btn-outline-success w-100"
                            title="View Certificate"
                            onClick={() =>
                              handleShow(
                                item.certificate_name,
                                item.certificate_uid
                              )
                            }
                          >
                            <i className="fa fa-eye me-2"></i>Click Here
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={show ? "mymodal open" : "mymodal"}>
                <div className="modoverlay"></div>
                <div className="mymodal-content">
                  <h4 className="text-left">Certificate</h4>
                  {/* <span className="close-btn" onClick={handleclose}>
                    &times;
                  </span> */}
                  <div className="border-bottom"></div>
                  <Row className="row-sm mt-3">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={certs3 + certimg} alt="certificate" />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <a
                      className="btn btn-secondary me-2"
                      onClick={() =>
                        handlePshow(
                          certs3 + certimg,
                          "Hey, check out my certificate!"
                        )
                      }
                    >
                      Share
                    </a>
                    <a
                      className="btn btn-success me-2"
                      onClick={() => certcheck(certimg)}
                    >
                      Download
                    </a>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                  </div>
                </div>
              </div>

              {/* <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  <h4>Certificate</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={certs3 + certimg} alt="certificate" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-success"
                    onClick={() => certcheck(certimg)}
                  >
                    Download
                  </a>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal> */}

              <hr />

              <div className="row">
                <h4>Highlights</h4>
                {filterProduct.length === 0 && (
                  <div className="col-md-12">
                    <div className="text-center">
                      <h4 className="text-danger">
                        Highlights will be updated soon
                      </h4>
                    </div>
                  </div>
                )}
                {filterProduct.map((item, i) => {
                  return (
                    <div className="col-md-12" key={i}>
                      <div className="shadow-1 border radius-5 p-0 mt-4 mb-0">
                        {/* <div className="card-body pt-2 pb-0 px-3">
                          <div className="text-center"> */}
                        <img
                          src={require("../../../../assets/images/Highlights.jpg")}
                          alt=""
                          className="high-img w-100"
                        />
                        {/* </div>
                        </div> */}
                      </div>
                      <div className="text-center py-1">
                        <a
                          className="btn btn-success w-100"
                          href={item.highlight_links}
                          target="_blank"
                        >
                          <i className="fas fa-eye me-2"></i>Click Here
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>

              <hr />

              <div className="row">
                <h4>Results</h4>
                {(reslist.length === 0 ||
                  roundsfilterProduct.length === 0 ||
                  performfilterProduct.length === 0) && (
                  <div className="col-md-12">
                    <div className="text-center">
                      <h4 className="text-danger">
                        Results will be updated soon
                      </h4>
                    </div>
                  </div>
                )}
                {msport.toUpperCase() === "ATHLETICS" &&
                  (roundsfilterProduct.length !== 0 ||
                    performfilterProduct.length !== 0) && (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/gold1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{gold}</h2>
                                            <span className="teamach">
                                              <strong>Gold Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/silver1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{silver}</h2>
                                            <span className="teamach">
                                              <strong>Silver Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/bronze1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{bronze}</h2>
                                            <span className="teamach">
                                              <strong>Bronze Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="sp-league-table sp-data-table">
                          <thead className="sp-table-header">
                            <tr role="row">
                              <th className="text-center">Place</th>
                              <th className="text-center">Age Category</th>
                              <th className="text-center">Bib No</th>
                              <th className="text-center">Event</th>
                              <th className="text-center">Time / distance</th>
                              <th className="text-center">Round</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roundsfilterProduct.map((item, j) => {
                              const getRowColor = () => {
                                return j % 2 === 0 ? "even" : "odd";
                              };
                              return (
                                <>
                                  <tr
                                    className={`sp-row-no-0 ${getRowColor()}`}
                                    role="row"
                                    key={j}
                                  >
                                    <td className="data-pct">{item.place}</td>
                                    <td className="data-pct">
                                      {item.age_category_name}
                                    </td>
                                    <td className="data-pct">{item.bib_no}</td>
                                    <td className="data-pct">{item.event}</td>
                                    <td className="data-pct">
                                      {item.time_distance}
                                    </td>
                                    <td className="data-pct">
                                      {item.heats_finals}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                            <tr></tr>
                            {performfilterProduct.map((item, j) => {
                              const getRowColor = () => {
                                return j % 2 === 0 ? "even" : "odd";
                              };
                              return (
                                <>
                                  <tr
                                    className={`sp-row-no-0 ${getRowColor()}`}
                                    role="row"
                                    key={j}
                                  >
                                    <td className="data-pct">{item.place}</td>
                                    <td className="data-pct">
                                      {item.age_category_name}
                                    </td>
                                    <td className="data-pct">{item.bib_no}</td>
                                    <td className="data-pct">{item.event}</td>
                                    <td className="data-pct">
                                      {item.time_distance}
                                    </td>
                                    <td className="data-pct">
                                      {item.heats_finals}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {msport.toUpperCase() !== "ATHLETICS" && (
                  <>
                    {wlist && (
                      <div id="playerlist">
                        <div id="winners">
                          <div className="before"></div>
                          <div className="after"></div>
                          {/* <hr /> */}
                          <div className="row">
                            <div className="col-md-4">
                              <div className="card__content grid">
                                <div className="card__pricing">
                                  <img
                                    src={require("../../../../assets/images/goldcup.png")}
                                    alt=""
                                    className="card__headercup-img"
                                  />
                                </div>
                                <header className="card__header">
                                  <div className="card__header-circle grid">
                                    <img
                                      src={require("../../../../assets/images/gold.png")}
                                      alt=""
                                      className="card__header-img"
                                    />
                                  </div>
                                  <h1 className="card__header-title">
                                    {wgold}
                                  </h1>
                                </header>
                                <ul className="card__list grid">
                                  <li className="card__list-item gold">
                                    <p className="card__list-description text-white">
                                      <b>Place :</b> Gold Medal
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {wsilver !== "" && (
                              <div className="col-md-4">
                                <div className="card__content grid">
                                  <div className="card__pricing">
                                    <img
                                      src={require("../../../../assets/images/silvercup.png")}
                                      alt=""
                                      className="card__headercup-img"
                                    />
                                  </div>
                                  <header className="card__header">
                                    <div className="card__header-circle grid">
                                      <img
                                        src={require("../../../../assets/images/silver.png")}
                                        alt=""
                                        className="card__header-img"
                                      />
                                    </div>
                                    <h1 className="card__header-title">
                                      {wsilver}
                                    </h1>
                                  </header>
                                  <ul className="card__list grid">
                                    <li className="card__list-item silver">
                                      <p className="card__list-description">
                                        <b>Place :</b> Silver Medal
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                            {wbronze !== "" && (
                              <div className="col-md-4">
                                <div className="card__content grid">
                                  <div className="card__pricing">
                                    <img
                                      src={require("../../../../assets/images/bronzecup.png")}
                                      alt=""
                                      className="card__headercup-img"
                                    />
                                  </div>
                                  <header className="card__header">
                                    <div className="card__header-circle grid">
                                      <img
                                        src={require("../../../../assets/images/bronze.png")}
                                        alt=""
                                        className="card__header-img"
                                      />
                                    </div>
                                    {/* <span className="card__header-subtitle">Most popular</span> */}
                                    <h1 className="card__header-title">
                                      {wbronze}
                                    </h1>
                                  </header>
                                  <ul className="card__list grid">
                                    <li className="card__list-item bronze">
                                      <p className="card__list-description text-white">
                                        <b>Place :</b> Bronze Medal
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-4"></div>

                    {reslist.map((data, i) => {
                      return (
                        <div className="col-md-11 mx-auto" key={i}>
                          <div className="match">
                            <div className="match-header">
                              <div className="match-tournament">
                                <img
                                  src={require("../../../../assets/images/results/tournament_name.png")}
                                  alt="profile-user"
                                  className=""
                                />
                                {mname}
                              </div>
                              <div className="match-actions">
                                <div className="rounddetails">
                                  {data.match_round === 1 && (
                                    <i className="material-icons-outlined">
                                      League
                                    </i>
                                  )}
                                  {data.match_round === 2 && (
                                    <i className="material-icons-outlined">
                                      QuaterFinal
                                    </i>
                                  )}
                                  {data.match_round === 3 && (
                                    <i className="material-icons-outlined">
                                      SemiFinal
                                    </i>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="match-content">
                              <div className="column">
                                <div className="team team--home">
                                  <div className="team-logo">
                                    <img
                                      src={require("../../../../assets/images/results/team.png")}
                                      alt="profile-user"
                                      className=""
                                    />
                                  </div>
                                  <h2 className="team-name">
                                    {data.team1_name}
                                  </h2>
                                </div>
                              </div>
                              <div className="column">
                                <div className="match-details">
                                  <div className="match-date">
                                    <strong>
                                      {data.match_date !== null && (
                                        <>
                                          {moment(data.match_date).format(
                                            "DD MMM YYYY"
                                          )}
                                        </>
                                      )}
                                    </strong>
                                  </div>
                                  <div className="match-score">
                                    <span className="match-score-number match-score-number--leading">
                                      {data.team1_score} - {data.team2_score}
                                    </span>
                                  </div>

                                  <div className="match-referee">
                                    Category:{" "}
                                    <strong>{data.category_name}</strong>
                                  </div>

                                  {data.winner_id === 0 && (
                                    <div className="match-draw-place alert alert-success ">
                                      {data.winner_name}
                                    </div>
                                  )}
                                  {data.winner_id !== 0 && (
                                    <div className="match-bet-place alert alert-success ">
                                      {data.winner_name} Won!
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="column">
                                <div className="team team--away">
                                  <div className="team-logo">
                                    <img
                                      src={require("../../../../assets/images/results/team.png")}
                                      alt="profile-user"
                                      className=""
                                    />
                                  </div>
                                  <h2 className="team-name">
                                    {data.team2_name}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}

                {/* {reslist.map((data, i) => {
                  return (
                    <div className="col-md-11 mx-auto" key={i}>
                      <div className="match">
                        <div className="match-header">
                          <div className="match-tournament">
                            <img
                              src={require("../../../../assets/images/results/tournament_name.png")}
                              alt="profile-user"
                              className=""
                            />
                            {data.meet_name}
                          </div>
                          <div className="match-actions">
                            <div className="rounddetails">
                              <i className="material-icons-outlined">
                                {data.round} Round
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="match-content">
                          <div className="column">
                            <div className="team team--home">
                              <div className="team-logo">
                                <img
                                  src={require("../../../../assets/images/results/team.png")}
                                  alt="profile-user"
                                  className=""
                                />
                              </div>
                              <h2 className="team-name">{data.team_name}</h2>
                            </div>
                          </div>
                          <div className="column">
                            <div className="match-details">
                              <div className="match-date">
                                <strong>
                                  {moment(data.date).format("DD MMM YYYY")}
                                </strong>
                              </div>
                              <div className="match-score">
                                <span className="match-score-number match-score-number--leading">
                                  {data.score}
                                </span>
                              </div>

                              <div className="match-referee">
                                Category: <strong>{data.category}</strong>
                              </div>

                              <div className="match-bet-place alert alert-success ">
                                {data.won_team_name} Won!
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <div className="team team--away">
                              <div className="team-logo">
                                <img
                                  src={require("../../../../assets/images/results/team.png")}
                                  alt="profile-user"
                                  className=""
                                />
                              </div>
                              <h2 className="team-name">
                                {data.second_team_name}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
