import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Playerviewdetails } from "../../services/player";
import moment from "moment";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";
import { logindata } from "../../services/login";
import {
  eventdetailswithstring,
  eventexistplayerornot,
  playereventpayment,
  playereventregister,
} from "../../services/register";

export const EventEntry = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventid, setEventID] = useState("");
  const [tname, setTName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [ddate, setDDate] = useState("");
  const [eclosed, setEclosed] = useState(false);
  const [cir, setCir] = useState("");
  const [amt, setAmount] = useState("0");
  const [free, setFree] = useState("");
  const [ebanner, setEBanner] = useState("");
  const [eventtype, setEventType] = useState("");
  const [extlink, setExtLink] = useState("");


  const [user, setUser] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [sname, setSName] = useState("");
  const [clsname, setClsName] = useState("");
  const [secname, setSecName] = useState("");

  const [tshow, setTShow] = useState(true);

  const [errorMsg, setErrorMsg] = useState("");
  const [paypage, setPaypage] = useState(false);
  const [pass, setPassword] = useState("");

  function regsubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (user !== "") {
      eventexistplayerornot(user, eventid).then((response) => {
        if (response.status === true) {
          setTShow(false);
          Playerviewdetails(user).then((res) => {
            if (res.status === true) {
              setPassword(res.data.password);
              setFName(res.data.athletelist.first_name);
              setLName(res.data.athletelist.last_name);
              if (res.data.athletelist.date_of_birth !== null) {
                setDOB(
                  moment(res.data.athletelist.date_of_birth).format(
                    "DD-MM-YYYY"
                  )
                );
              }
              setGender(res.data.athletelist.gender);
              setMobNo(res.data.athletelist.mobile_no);
              setEmail(res.data.athletelist.email_id);
              setSName(res.data.athletelist.school_name);
              setClsName(res.data.athletelist.class_name);
              setSecName(res.data.athletelist.section);
            }
          });
        } else {
          setErrorMsg(response.message);
        }
      });
    } else {
      setErrorMsg("Please Enter Username");
    }
  }

  function backsubmit() {
    setTShow(true);
    setUser("");
    setFName("");
    setLName("");
    setDOB("");
    setGender("");
    setEmail("");
    setMobNo("");
    setSName("");
    setClsName("");
    setSecName("");
    setErrorMsg("");
    setCheck(false);
  }

  function playersubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (check === true) {
      setLoading(true);
      const temp = new FormData();
      temp.append("username", user);
      temp.append("tournament_meet_id", eventid);
      dotracking(
        "submit button in event register for " +
          tname +
          " (" +
          eventid +
          ") event from player " +
          user
      );

      playereventregister(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          if (free === "N") {
            paynow(
              response.data.RAZORPAY_KEY,
              response.data.entries.order_id,
              response.data.entries.user_id
            );
          } else if (free === "Y") {
            setPaypage(true);
          }
        } else {
          setLoading(false);
          setErrorMsg(response.message);
        }
      });
    } else {
      setErrorMsg("Please Select Disclaimer");
    }
  }
  const [check, setCheck] = useState(true);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  async function paynow(pkey, ordid, uid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Event Registration",
      image: require("../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("user_id", uid);
        temp.append("tournament_meet_id", eventid);
        setLoading(true);
        playereventpayment(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);
            setPaypage(true);
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  useEffect(() => {
    const temp = new FormData();
    temp.append("event_id_string", id);
    eventdetailswithstring(temp).then((response) => {
      if (response.status === true) {
        setEventID(response.data.event_info.id);
        if (response.data.event_info.circular_file !== null) {
          setCir(
            response.data.s3_path + response.data.event_info.circular_file
          );
        }
        if (response.data.event_info.logo !== null) {
          setEBanner(
            response.data.logo_s3_path + response.data.event_info.logo
          );
        }
        setEventType(response.data.event_info.internalorexternal);
        if(response.data.event_info.internalorexternal === 'external'){
          setExtLink(response.data.event_info_map.meet_url);
        }
        setFree(response.data.event_info.free_or_paid_events);
        setTName(response.data.event_info.event_name);
        setSDate(
          moment(response.data.event_info.start_date).format("DD-MM-YYYY")
        );
        setEDate(
          moment(response.data.event_info.end_date).format("DD-MM-YYYY")
        );
        if (
          moment(new Date()).format("yyyy-MM-DD") >
          response.data.event_info.entry_end_date
        ) {
          setEclosed(true);
        }
        setDDate(
          moment(response.data.event_info.entry_end_date).format("DD-MM-YYYY")
        );
        setAmount(response.data.event_info.fees);
      }
    });
  }, []);
  const [loading, setLoading] = useState(false);

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button from event register,redirect to Player dashboard"
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        setErrorMsg(response.message);
      }
    });
  }

  function evntreg() {
    if (eventtype === "external") {
      dotracking(
        "register button in event register for " +
          tname +
          " (" +
          eventid +
          ") event $$ External Event"
      );
      window.open(extlink, "_blank");
    } else {
      dotracking(
        "register button in event register for " +
          tname +
          " (" +
          eventid +
          ") event $$ " +
          trackobj.eventregtemp
      );
      navigate("/eventregister/" + id);
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../assets/images/tagline.png");
    ev.target.onerror = null;
  }
  return (
    <div id="loginpage" className="app sidebar-mini rtl">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      <main className="md-overflow">
        <div className="eventcon">
          <div className="row h-95">
            <div className="col-md-6 pe-0 tab-100 order_a mob-100">
              {ebanner !== "" && (
                <div
                  className="eventside slideup"
                  style={{ backgroundImage: `url(${ebanner})` }}
                  onError={(e) => errorhandle(e)}
                ></div>
              )}
              {ebanner === "" && (
                <div className="side slideup">
                  <div className="img-top">
                    <img
                      src={require("../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <a
                      href="http://pathwayz.sportzvillage.com/"
                      target="_blank"
                    >
                      <img
                        src={require("../../assets/images/pathwayz_logo.png")}
                        alt="pathwayz"
                        className="pathwayz_logo"
                      />
                    </a>
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
              )}
              <div className="border-b slideup"></div>
            </div>
            {eclosed && (
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="form slidedown formregis">
                  <h3 className="text-center text-danger">
                    Registeration Closed
                  </h3>
                </div>
              </div>
            )}
            {/* {eventtype === "external" && eventtype !== "" && (
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="form slidedown formregis">
                  <h3 className="text-center text-danger">
                    There is no Registeration for this event - {tname}
                  </h3>
                </div>
              </div>
            )} */}
            {!paypage && !eclosed  && (
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="form slidedown formregis">
                  {tshow && (
                    <div>
                      <div className="form-text login mb-3">
                        <h4 className="mt-0 mb-4">Event Details</h4>
                      </div>

                      <div className="row" id="tournamentbox">
                        <div className="col-md-12">
                          <div className="card bg-none">
                            <div className="card-body p-0">
                              <div className="clearfix pb-3 border-bottom">
                                <div className="float-left">
                                  <h1 className="card-title tourheader mb-0">
                                    {tname}
                                  </h1>
                                </div>
                              </div>
                              <div className="row datedetails mx-0">
                                <div className="col-md-3 col-sm-6">
                                  <h3 className="card-title">
                                    Start Date <span>{sdate}</span>
                                  </h3>
                                </div>
                                <div className="col-md-3 col-sm-6 border-left">
                                  <h3 className="card-title">
                                    End Date <span>{edate}</span>
                                  </h3>
                                </div>
                                <div className="col-md-3 col-sm-6 border-left">
                                  <h3 className="card-title">
                                    Entry Last Date <span>{ddate}</span>
                                  </h3>
                                </div>
                                <div className="col-md-3 col-sm-6 border-left">
                                  <h3 className="card-title">
                                    Amount{" "}
                                    <span>
                                      {free === "N" && (
                                        <b className="matchstatus px-2 py-1">
                                          Rs. {amt} /-
                                        </b>
                                      )}
                                      {free === "Y" && (
                                        <b className="matchstatus px-2 py-1">
                                          Free
                                        </b>
                                      )}
                                    </span>
                                  </h3>
                                </div>
                              </div>

                              <div className="row datedetails mx-0">
                                {cir !== "" && (
                                  <div className="col-md-6">
                                    <h3 className="card-title">
                                      Circular :{" "}
                                      <a
                                        href={cir}
                                        target="_blank"
                                        download={cir}
                                        className="text-danger"
                                      >
                                        Click Here{" "}
                                        <i className="fa fa-download"></i>
                                      </a>
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <form id="loginform" className="login registrabtn">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            {eventtype !== "external" && (
                              <h4>New to Student Athlete Hub ?</h4>
                            )}
                            <button
                              className="form-btn login btn-info-gradient mx-auto"
                              type="button"
                              onClick={evntreg}
                            >
                              <span>Click to Register</span>
                            </button>
                          </div>
                        </div>
                        {eventtype !== "external" && (
                          <>
                            <h5 className="seperation">(OR)</h5>
                            <h4>Register with Username (UID)</h4>
                            <div className="input-field">
                              <label>
                                <i className="fa-solid fa-user"></i>Username
                              </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="Enter Username"
                                    required
                                    autoFocus
                                    autoComplete="off"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <button
                                    className="form-btn login mt-0 btn-success"
                                    type="button"
                                    onClick={regsubmit}
                                  >
                                    <span>Register</span>
                                  </button>
                                </div>
                                {errorMsg !== "" && (
                                  <span className="errortext">{errorMsg}</span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  )}

                  {!tshow && (
                    <div>
                      <div className="form-text login">
                        <h4>Player Details</h4>
                      </div>
                      <div className="row tournamententry">
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Username</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{user}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Child Name</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{fname}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Date of Birth</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{dob}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Gender</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{gender}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Contact Number</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{mobno}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Email Address</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">School</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{sname}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Class</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{clsname}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-3">
                                <b className="text-inverse">Section</b>
                              </div>
                              <div className="col-md-9">
                                <span className="text-inverse">{secname}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form id="loginform" className="login mt-5">
                        <h4>Disclaimer</h4>
                        <div className="disc-field">
                          <input
                            type="checkbox"
                            onChange={checkterm}
                            checked={check}
                          />
                          <label className="pl-3">
                            By registering my child, I acknowledge and accept
                            that participation involves inherent risks, and I
                            release the school and organizers from liability for
                            any injuries during the event and grant permission
                            to use event photographs for promotional purposes.
                          </label>
                        </div>

                        {errorMsg !== "" && (
                          <span className="errortext">{errorMsg}</span>
                        )}
                        <div className="row">
                          <div className="col-md-12 text-right">
                            <button
                              className="form-btn btn-secondary me-2"
                              type="button"
                              onClick={backsubmit}
                            >
                              <span>Back</span>
                            </button>
                            <button
                              className="form-btn btm-danger me-2"
                              type="button"
                              onClick={playersubmit}
                            >
                              <span>Register</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            )}

            {paypage && !eclosed && (
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="form slidedown">
                  <div className="text-center background-text">
                    <h1 className="mb-3 font-weight-semibold text-danger">
                      Registration successful !
                    </h1>
                  </div>
                  <div className="background-text">
                    <h4 className="mb-3 font-weight-semibold">
                      Hi {fname} {lname},
                    </h4>
                    <div className="successmeg">
                      <p>
                        You have successfully registered for the{" "}
                        <b className="text-success">{tname}</b>. Kindly check
                        your email.
                      </p>
                    </div>
                  </div>

                  <div className="text-center">
                    <a onClick={dashlogin} className="btn btn-gray">
                      Go to Dashboard
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
