import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  schedulelist,
  touragecategory,
  tourcategorybasedteam,
  tourcategorydelete,
  tourcategorydetail,
  tourcategorydetailbasedteam,
  tourcategorylist,
  tourcategorymapped,
  tourcategorymappeddelete,
  tourclassstdlist,
  tourpointslist,
  tourteamlist,
  tourviewdetail,
  winnercategorydelete,
  winnercategoryplayerlist,
} from "../../../../services/superadmin";
import {
  agecategorytourcityreg,
  crmtourviewdetail,
  teamlistcityreg,
} from "../../../../services/cityregionaladmin";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  scheadtourviewdetail,
  schooltourteamlist,
  schprofileview,
} from "../../../../services/schoolhead";
import { Refteamlist, Reftourdetail } from "../../../../services/referee";
import Select from "react-select";
import { tourclasslist, tourdetails } from "../../../../services/register";
import exportUrl from "../../../../exportUtl";
import dotracking from "../../../trackinglogs";
import moment from "moment";
import trackobj from "../../../trackingpages.json";
import { interschoolagecatselectlist } from "../../../../services/interschool";
import {
  agecategorytourcoach,
  coachtourviewdetail,
  teamlistcoach,
} from "../../../../services/coach";

export const CategoryList = () => {
  const navigate = useNavigate();
  const [filterProduct, setFilterProduct] = useState([]);
  const { id, tid } = useParams();

  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [edate, setEDate] = useState(false);
  const [ttype, setTType] = useState("");
  const [tfree, setTFree] = useState("");
  const [sports, setSports] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const [cname, setCName] = useState("");
  const [gender, setGender] = useState("");
  const [cid, setId] = useState(0);
  const [clsid, setClassId] = useState([]);
  const [clslist, setClsList] = useState([]);
  const [stdlist, setStdList] = useState([]);
  const [quachk, setQuachk] = useState("");

  const [agecatlist, setAgeCatList] = useState([]);
  const [mcat, setMCat] = useState("");
  const [matype, setMAType] = useState(0);

  const [cattypelist, setCatTypeList] = useState([]);
  const [cattype, setCatType] = useState("");

  const [show, setShow] = useState(false);
  function handleShow() {
    setId(0);
    setCName("");
    setGender("");
    setErrorMsg("");
    setMCat("");
    setQuachk("");
    setSchGen(false);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setErrorMsg("");
    setCName("");
    setGender("");
    setId(0);
    setClassId("");
    setQuachk("");
    setSchGen(false);
  }

  function Selectgender(e) {
    setGender(e.target.value);
    setCatTypeList([]);
    setCatType("");
    if (
      sports.toUpperCase() === "BADMINTON" ||
      sports.toUpperCase() === "TENNIS" ||
      sports.toUpperCase() === "TABLE TENNIS" ||
      sports.toUpperCase() === "SQUASH"
    ) {
      if (e.target.value === "Mixed") {
        setCatType({
          value: "3",
          label: "Mixed Doubles",
        });
        setCatTypeList([
          {
            value: "3",
            label: "Mixed Doubles",
          },
        ]);
      } else {
        setCatTypeList([
          {
            value: "1",
            label: "Singles",
          },
          {
            value: "2",
            label: "Doubles",
          },
        ]);
      }
    }
  }

  function handleAge(e) {
    setErrorMsg("");
    var msg = "";
    if (
      sports.toUpperCase() === "BADMINTON" ||
      sports.toUpperCase() === "TENNIS" ||
      sports.toUpperCase() === "TABLE TENNIS" ||
      sports.toUpperCase() === "SQUASH"
    ) {
      if (e.length < 3) {
        setMCat(e);
      } else {
        msg = "Maximum 2 Age Categories Allowed";
        setErrorMsg("Maximum 2 Age Categories Allowed");
      }
      if (msg === "") {
      }
    } else {
      setMCat(e);
    }
  }

  function handleCatType(e) {
    if (e !== null) {
      setCatType(e);
    } else {
      setCatType("");
    }
  }

  function handleCls(e) {
    setClassId(e);
  }
  let arr1 = [];
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    listall(link[1]);

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setMAType(response.data.tournament.agecat_or_std);
          setTType(response.data.tournament.meet_type);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
          if (response.data.tournament.agecat_or_std === 2) {
            touragecategory(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].age_categories,
                    });
                  }
                }
                setAgeCatList(arr1);
              }
            });
          } else {
            const temp = new FormData();
            temp.append("tournament_id", id);
            tourclassstdlist(temp).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].standard_name,
                    });
                  }
                }
                setClsList(arr1);
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMAType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
          if (response.data.tournament.agecat_or_std === 2) {
            agecategorytourcityreg(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].age_categories,
                    });
                  }
                }
                setAgeCatList(arr1);
              }
            });
          } else {
            const temp = new FormData();
            temp.append("tournament_id", id);
            tourclassstdlist(temp).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].standard_name,
                    });
                  }
                }
                setClsList(arr1);
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMAType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMAType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMAType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
          if (response.data.tournament.agecat_or_std === 2) {
            agecategorytourcoach(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].age_categories,
                    });
                  }
                }
                setAgeCatList(arr1);
              }
            });
          } else {
            const temp = new FormData();
            temp.append("tournament_id", id);
            tourclassstdlist(temp).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr1 = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                      value: response.data[i].id,
                      label: response.data[i].standard_name,
                    });
                  }
                }
                setClsList(arr1);
              }
            });
          }
        }
      });
    }else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by);
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMAType(response.data.tournament.agecat_or_std);
          setTFree(response.data.tournament.free_tournament);
          setSports(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.end_date
          ) {
            setEDate(true);
          }
        }
      });
    }
  }, []);

  let arr = [];
  function listall(ltype) {
    setFilterProduct([]);
    tourdetails(id).then((res) => {
      if (res.status === true && res.data.tournament !== null) {
        if (
          res.data.tournament.meet_type === "Inter School Tournament" &&
          localStorage.getItem("Role") === "4"
        ) {
          schprofileview(localStorage.getItem("UID")).then((res) => {
            if (res.status === true) {
              const temp = new FormData();
              temp.append("tournament_meet_id", id);
              temp.append("school_id", res.data.school_id);
              interschoolagecatselectlist(temp).then((response) => {
                if (response.status === true) {
                  let arr = [];
                  if (response.data.length !== 0) {
                    for (let i = 0; i < response.data.length; i++) {
                      arr.push({
                        id: response.data[i].category_id,
                        category_name: response.data[i].category_name,
                        category_gender: response.data[i].category_gender,
                        quoter_final_status:
                          response.data[i].quoter_final_status,
                        allowed_team_count: response.data[i].allowed_team_count,
                        added_team_count: response.data[i].added_team_count,
                        teams: [],
                        winnerlist: [],
                        pointlist: [],
                      });
                      schooltourteamlist(id, response.data[i].category_id).then(
                        (res) => {
                          if (res.status === true) {
                            arr[i].teams = res.data;
                            setFilterProduct([...arr]);
                          }
                        }
                      );

                      tourpointslist(id, response.data[i].category_id).then(
                        (res) => {
                          if (res.status === true) {
                            arr[i].pointlist = res.data;
                            setFilterProduct([...arr]);
                          }
                        }
                      );

                      winnercategoryplayerlist(
                        id,
                        response.data[i].category_id
                      ).then((response) => {
                        if (response.status === true) {
                          arr[i].winnerlist = response.data;
                          setFilterProduct([...arr]);
                        }
                      });
                    }
                  }
                  setFilterProduct(arr);
                }
              });
            }
          });
        } else if (
          res.data.tournament.meet_type === "Inter School Tournament" &&
          localStorage.getItem("Role") !== "4" &&
          ltype !== "categorylist"
        ) {
          const temp = new FormData();
          temp.append("tournament_meet_id", id);
          temp.append("school_id", tid);
          interschoolagecatselectlist(temp).then((response) => {
            if (response.status === true) {
              let arr = [];
              if (response.data.length !== 0) {
                for (let i = 0; i < response.data.length; i++) {
                  arr.push({
                    id: response.data[i].category_id,
                    category_name: response.data[i].category_name,
                    category_gender: response.data[i].category_gender,
                    quoter_final_status: response.data[i].quoter_final_status,
                    allowed_team_count: response.data[i].allowed_team_count,
                    added_team_count: response.data[i].added_team_count,
                    teams: [],
                    winnerlist: [],
                    pointlist: [],
                  });

                  if (localStorage.getItem("Role") === "1") {
                    tourteamlist(id, response.data[i].category_id).then(
                      (res1) => {
                        if (res1.status === true) {
                          arr[i].teams = res1.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "3") {
                    teamlistcityreg(id, response.data[i].category_id).then(
                      (res1) => {
                        if (res1.status === true) {
                          arr[i].teams = res1.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "5") {
                    Refteamlist(id, response.data[i].category_id).then(
                      (res1) => {
                        if (res1.status === true) {
                          arr[i].teams = res1.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "7") {
                    teamlistcoach(id, response.data[i].category_id).then(
                      (res1) => {
                        if (res1.status === true) {
                          arr[i].teams = res1.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  }

                  winnercategoryplayerlist(
                    id,
                    response.data[i].category_id
                  ).then((response1) => {
                    if (response1.status === true) {
                      arr[i].winnerlist = response1.data;
                      setFilterProduct([...arr]);
                    }
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        } else {
          const temp = new FormData();
          temp.append("tournament_id", id);
          tourcategorylist(temp).then((response) => {
            if (response.status === true) {
              let arr = [];
              if (response.data.category_list.length !== 0) {
                for (let i = 0; i < response.data.category_list.length; i++) {
                  arr.push({
                    id: response.data.category_list[i].id,
                    category_name: response.data.category_list[i].category_name,
                    category_gender:
                      response.data.category_list[i].category_gender,
                    quoter_final_status:
                      response.data.category_list[i].quoter_final_status,
                    status: response.data.category_list[i].status,
                    teams: [],
                    winnerlist: [],
                    pointlist: [],
                  });
                  if (localStorage.getItem("Role") === "1") {
                    tourteamlist(id, response.data.category_list[i].id).then(
                      (res) => {
                        if (res.status === true) {
                          arr[i].teams = res.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "3") {
                    teamlistcityreg(id, response.data.category_list[i].id).then(
                      (res) => {
                        if (res.status === true) {
                          arr[i].teams = res.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "4") {
                    schooltourteamlist(
                      id,
                      response.data.category_list[i].id
                    ).then((res) => {
                      if (res.status === true) {
                        arr[i].teams = res.data;
                        setFilterProduct([...arr]);
                      }
                    });
                  } else if (localStorage.getItem("Role") === "5") {
                    Refteamlist(id, response.data.category_list[i].id).then(
                      (res) => {
                        if (res.status === true) {
                          arr[i].teams = res.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  } else if (localStorage.getItem("Role") === "7") {
                    teamlistcoach(id, response.data.category_list[i].id).then(
                      (res) => {
                        if (res.status === true) {
                          arr[i].teams = res.data;
                          setFilterProduct([...arr]);
                        }
                      }
                    );
                  }

                  winnercategoryplayerlist(
                    id,
                    response.data.category_list[i].id
                  ).then((response) => {
                    if (response.status === true) {
                      arr[i].winnerlist = response.data;
                      setFilterProduct([...arr]);
                    }
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      }
    });
  }
  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "categorylist") {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/superadmin/tournamentlist");
      } else {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/superadmin/tournamentschoollist/" + id);
      }
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in category list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (
        localStorage.getItem("Cat") === "1" ||
        localStorage.getItem("Age") === "1"
      ) {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Cat") === "2") {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        navigate("/schoolhead/tournamentdetails/" + id);
      } else if (localStorage.getItem("Cat") === "3") {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        navigate("/schoolhead/completedtournament");
      } else if (localStorage.getItem("Age") === "2") {
        dotracking(
          "back button in category list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.dashheading
        );
        navigate("/schoolhead/dashboard");
      }
      // navigate(-1);
    } else if (localStorage.getItem("Role") === "5") {
      dotracking(
        "back button in category list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "back button in category list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/coach/tournamentlist");
    }else if (localStorage.getItem("Role") === "8") {
      // dotracking(
      //   "back button in category list for " +
      //     tname +
      //     " (" +
      //     id +
      //     ") tournament $$ " +
      //     trackobj.tourlistheading
      // );
      navigate("/funder/tournamentlist");
    }
  }

  function submitcat(e) {
    e.preventDefault();
    setErrorMsg("");
    if (cname !== "") {
      if (gender !== "") {
        var valid = "";
        if (matype === 1) {
          if (clsid.length === 0) {
            valid = "Class Required";
            setErrorMsg("Please Select Class");
          }
        } else if (matype === 2) {
          if (mcat === "") {
            valid = "Category Required";
            setErrorMsg("Please Select Age Category");
          }
          if (mcat.length === 0) {
            valid = "Category Required";
            setErrorMsg("Please Select Age Category");
          }
        }
        var gtype = "";
        if (valid === "") {
          if (
            sports.toUpperCase() === "BADMINTON" ||
            sports.toUpperCase() === "TENNIS" ||
            sports.toUpperCase() === "TABLE TENNIS" ||
            sports.toUpperCase() === "SQUASH"
          ) {
            if (cattype === "") {
              setErrorMsg("Please Select Group Type");
              valid = "Please Select Group Type";
            }
            if (cattype !== "") {
              valid = "";
              if (gender.toUpperCase() === "MIXED") {
                gtype = "3";
              } else {
                gtype = cattype.value;
              }
            }
          }
        }

        if (valid === "") {
          if (cid === 0) {
            if (quachk !== "") {
              const temp = new FormData();
              temp.append("admin_id", localStorage.getItem("UID"));
              temp.append("tournament_id", id);
              temp.append("category_name", cname);
              temp.append("category_gender", gender);
              temp.append("quoter_final_status", quachk);
              if (matype === 1) {
                temp.append("class_or_age_category_count", clsid.length);
                if (clsid.length > 0) {
                  for (let i = 0; i < clsid.length; i++) {
                    temp.append(
                      "class_or_age_category_id_" + [i + 1],
                      clsid[i].value
                    );
                    temp.append(
                      "class_or_age_category_name_" + [i + 1],
                      clsid[i].label
                    );
                  }
                }
              } else if (matype === 2) {
                if (
                  sports.toUpperCase() === "BADMINTON" ||
                  sports.toUpperCase() === "TENNIS" ||
                  sports.toUpperCase() === "TABLE TENNIS" ||
                  sports.toUpperCase() === "SQUASH"
                ) {
                  temp.append("class_or_age_category_count", mcat.length);
                  if (mcat.length > 0) {
                    for (let i = 0; i < mcat.length; i++) {
                      temp.append(
                        "class_or_age_category_id_" + [i + 1],
                        mcat[i].value
                      );
                      temp.append(
                        "class_or_age_category_name_" + [i + 1],
                        mcat[i].label
                      );
                    }
                  }
                } else {
                  temp.append("class_or_age_category_count", 1);
                  temp.append("class_or_age_category_id_1", mcat.value);
                  temp.append("class_or_age_category_name_1", mcat.label);
                }
              }

              if (
                sports.toUpperCase() === "BADMINTON" ||
                sports.toUpperCase() === "TENNIS" ||
                sports.toUpperCase() === "TABLE TENNIS" ||
                sports.toUpperCase() === "SQUASH"
              ) {
                temp.append("group_type", gtype);
              } else {
                temp.append("group_type", 0);
              }
              dotracking(
                "add button in category list for " +
                  cname +
                  " category with gender " +
                  gender +
                  " in " +
                  tname +
                  " (" +
                  id +
                  ") tournament $$ " +
                  trackobj.categorylistheading
              );
              tourcategorybasedteam(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall(urllink);
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.message);
                }
              });
            } else {
              setErrorMsg("Please Select Qualification Type");
            }
          } else {
            const temp = new FormData();
            temp.append("category_id", cid);
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_id", id);
            temp.append("category_name", cname);
            temp.append("category_gender", gender);
            temp.append("quoter_final_status", quachk);
            if (matype === 1) {
              temp.append("class_or_age_category_count", clsid.length);
              if (clsid.length > 0) {
                for (let i = 0; i < clsid.length; i++) {
                  temp.append(
                    "class_or_age_category_id_" + [i + 1],
                    clsid[i].value
                  );
                  temp.append(
                    "class_or_age_category_name_" + [i + 1],
                    clsid[i].label
                  );
                }
              }
            } else if (matype === 2) {
              if (
                sports.toUpperCase() === "BADMINTON" ||
                sports.toUpperCase() === "TENNIS" ||
                sports.toUpperCase() === "TABLE TENNIS" ||
                sports.toUpperCase() === "SQUASH"
              ) {
                temp.append("class_or_age_category_count", mcat.length);
                if (mcat.length > 0) {
                  for (let i = 0; i < mcat.length; i++) {
                    temp.append(
                      "class_or_age_category_id_" + [i + 1],
                      mcat[i].value
                    );
                    temp.append(
                      "class_or_age_category_name_" + [i + 1],
                      mcat[i].label
                    );
                  }
                }
              } else {
                temp.append("class_or_age_category_count", 1);
                temp.append("class_or_age_category_id_1", mcat.value);
                temp.append("class_or_age_category_name_1", mcat.label);
              }
            }
            if (
              sports.toUpperCase() === "BADMINTON" ||
              sports.toUpperCase() === "TENNIS" ||
              sports.toUpperCase() === "TABLE TENNIS" ||
              sports.toUpperCase() === "SQUASH"
            ) {
              temp.append("group_type", gtype);
            } else {
              temp.append("group_type", 0);
            }
            dotracking(
              "update button in edit category for " +
                cname +
                "(" +
                cid +
                ") category with gender " +
                gender +
                " in " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.categorylistheading
            );

            tourcategorydetailbasedteam(temp).then((response) => {
              if (response.status === true) {
                handleclose();
                listall(urllink);
                toast.success(response.message);
              } else {
                setErrorMsg(response.message);
              }
            });
          }
        }
      } else {
        setErrorMsg("Please Select Gender");
      }
    } else {
      setErrorMsg("Please Enter Category Name");
    }
  }

  function teamlist(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") category create team button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "categorylist") {
        navigate("/superadmin/teamlist/" + id + "/" + rid);
      } else {
        navigate("/superadmin/interteamlist/" + id + "/" + rid + "/" + tid);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamlist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamlist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/teamlist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "7") {
      // navigate("/coach/teamlist/" + id + "/" + rid);
      if (urllink === "categorylist") {
        navigate("/coach/teamlist/" + id + "/" + rid);
      } else {
        navigate("/coach/interteamlist/" + id + "/" + rid + "/" + tid);
      }
    }
  }

  function poollist(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") category create pool button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.poollistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/poollist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/poollist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/poollist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/poollist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/poollist/" + id + "/" + rid);
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/scheduleallocate/" + id + "/" + rid);
    }
  }

  function resdet(tid, caname) {
    dotracking(
      caname +
        " (" +
        tid +
        ") category result icon in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.resultheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/resultlist/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/resultlist/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/resultlist/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/resultlist/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/resultlist/" + id + "/" + tid);
    }
  }

  function resdetele(did, caname) {
    dotracking(
      caname +
        " (" +
        did +
        ") category result icon in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          localStorage.getItem("Role") === "1" ||
          localStorage.getItem("Role") === "3"
        ) {
          winnercategorydelete(id, did).then((response) => {
            if (response.status === true) {
              listall(urllink);
              toast.success(response.message);
            }
          });
        }
      }
    });
  }

  function pointsdet(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") category upload result button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.pointlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/pointslist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/pointslist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/pointslist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/pointslist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/pointslist/" + id + "/" + rid);
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/pointslist/" + id + "/" + rid);
    }
  }

  function perflist(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") performance list button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/performancelist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/performancelist/" + id + "/" + rid);
      // } else if (localStorage.getItem("Role") === "4") {
      //   navigate("/schoolhead/performancelist/" + id + "/" + rid);
      // } else if (localStorage.getItem("Role") === "5") {
      //   navigate("/referee/performancelist/" + id + "/" + rid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/performancelist/" + id + "/" + rid);
    }
  }

  function fixlist(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") category fixtures button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.fixlistheading
    );
    navigate("/schoolhead/schedulelist/" + id + "/" + rid);
  }

  function resultlist(rid, caname) {
    dotracking(
      caname +
        " (" +
        rid +
        ") category upload result button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.resultheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/resultupload/" + id + "/" + rid);
      // } else if (localStorage.getItem("Role") === "3") {
      //   navigate("/cityregional/resultupload/" + id + "/" + rid);
    }
  }
  const [schgen, setSchGen] = useState(false);
  function editcat(tid, caname) {
    const temp1 = new FormData();
    temp1.append("category_id", tid);
    dotracking(
      caname +
        " (" +
        tid +
        ") category edit button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );

    tourcategorydetail(temp1).then((response) => {
      if (response.status === true) {
        handleShow();
        schedulelist(id, tid).then((res) => {
          if (res.status === true) {
            if (res.data.data_fixtures.length !== 0) {
              setSchGen(true);
            }
          }
        });

        setId(response.data.category_details.id);
        setCName(response.data.category_details.category_name);
        setGender(response.data.category_details.category_gender);
        setQuachk(response.data.category_details.quoter_final_status);

        if (
          sports.toUpperCase() === "BADMINTON" ||
          sports.toUpperCase() === "TENNIS" ||
          sports.toUpperCase() === "TABLE TENNIS" ||
          sports.toUpperCase() === "SQUASH"
        ) {
          if (response.data.category_details.category_gender === "Mixed") {
            setCatType({
              value: "3",
              label: "Mixed Doubles",
            });
            setCatTypeList([
              {
                value: "3",
                label: "Mixed Doubles",
              },
            ]);
          } else {
            if (response.data.category_mapped_details.length !== 0) {
              if (response.data.category_mapped_details[0].group_type === 1) {
                setCatType({
                  value: "1",
                  label: "Singles",
                });
              } else {
                setCatType({
                  value: "2",
                  label: "Doubles",
                });
              }
            }
            setCatTypeList([
              {
                value: "1",
                label: "Singles",
              },
              {
                value: "2",
                label: "Doubles",
              },
            ]);
          }
        }

        const temp = new FormData();
        temp.append("tournament_id", id);
        temp.append("category_id", tid);
        tourcategorymapped(temp).then((response) => {
          if (response.status === true) {
            setStdList(response.data.category_mapped_list);
            let arr = [];
            if (matype === 2) {
              for (
                let i = 0;
                i < response.data.category_mapped_list.length;
                i++
              ) {
                arr.push({
                  value:
                    response.data.category_mapped_list[i]
                      .class_or_age_category_id,
                  label:
                    response.data.category_mapped_list[i]
                      .class_or_age_category_name,
                });
              }
              setMCat(arr);
            }
          }
        });
      }
    });
  }
  function deletecat(tid, caname) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          localStorage.getItem("Role") === "1" ||
          localStorage.getItem("Role") === "3" ||
          (tcreate === parseInt(localStorage.getItem("UID")) &&
            localStorage.getItem("Role") === "7")
        ) {
          const temp = new FormData();
          temp.append("category_id", tid);
          dotracking(
            caname +
              " (" +
              tid +
              ") category delete button in category list for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.categorylistheading
          );

          tourcategorydelete(temp).then((response) => {
            if (response.status === true) {
              listall(urllink);
              toast.success(response.message);
            }
          });
        }
      }
    });
  }

  function deletestd(tid, cat, sname) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          localStorage.getItem("Role") === "1" ||
          localStorage.getItem("Role") === "3" ||
          (localStorage.getItem("Role") === "7" &&
            tcreate === parseInt(localStorage.getItem("UID")))
        ) {
          dotracking(
            sname +
              " (" +
              tid +
              ") standard delete icon in edit category for category (" +
              cat +
              ") in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.categorylistheading
          );
          const temp = new FormData();
          temp.append("class_or_age_category_id", tid);
          tourcategorymappeddelete(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              setStdList([]);
              const temp = new FormData();
              temp.append("tournament_id", id);
              temp.append("category_id", cat);
              tourcategorymapped(temp).then((response) => {
                if (response.status === true) {
                  setStdList(response.data.category_mapped_list);
                }
              });
            }
          });
        }
      }
    });
  }

  function fixexport() {
    dotracking(
      "overall fixture export button in category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    window.open(exportUrl + "export-overall-match-fixtures/" + id, "_blank");
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Category Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Tournament List</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Category Lists
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-5">
                  <div className="text-right">
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") &&
                      urllink === "categorylist" && (
                        <>
                          <a
                            className="btn btn-success me-2 mt-1"
                            onClick={handleShow}
                          >
                            <i className="fa fa-plus"></i> Add Category
                          </a>
                        </>
                      )}

                    {localStorage.getItem("Role") === "7" &&
                      tcreate === parseInt(localStorage.getItem("UID")) &&
                      urllink === "categorylist" && (
                        <>
                          <a
                            className="btn btn-success me-2 mt-1"
                            onClick={handleShow}
                          >
                            <i className="fa fa-plus"></i> Add Category
                          </a>
                        </>
                      )}
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") &&
                      urllink === "categorylist" && (
                        <a
                          className="btn btn-secondary me-2 mt-1"
                          onClick={fixexport}
                        >
                          <i className="fa-solid fa-file-export"></i> Overall
                          Fixture Export
                        </a>
                      )}

                    <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <div className="row teamslist mt-4">
                {filterProduct.length === 0 && (
                  <div className="col-md-4">
                    <div className="card shadow teamslist">
                      <div className="card-header bg-transparent">
                        <h3 className="card-title">No Category Found</h3>
                      </div>
                    </div>
                  </div>
                )}

                {filterProduct.length !== 0 &&
                  localStorage.getItem("Role") === "4" &&
                  urllink !== "categorylist" &&
                  ttype === "Inter School Tournament" && (
                    <>
                      {tfree === "N" && (
                        <span className="text-success tx-20">
                          Selected and Paid Categories for the tournament -{" "}
                          {tname}
                        </span>
                      )}
                      {tfree === "Y" && (
                        <span className="text-success tx-20">
                          Selected Categories for the tournament - {tname}
                        </span>
                      )}
                    </>
                  )}
                {filterProduct.length !== 0 &&
                  urllink !== "categorylist" &&
                  localStorage.getItem("Role") !== "4" &&
                  ttype === "Inter School Tournament" && (
                    <span className="text-success tx-20">
                      Selected Categories for the tournament - {tname}
                    </span>
                  )}

                {filterProduct.map((data, i) => {
                  return (
                    <div className="col-md-4" key={i}>
                      <div className="card ">
                        <div className="card-header">
                          <h3 className="card-title">{data.category_name} </h3>
                          {(localStorage.getItem("Role") === "1" ||
                            localStorage.getItem("Role") === "3") &&
                            urllink === "categorylist" && (
                              <>
                                {edate && (
                                  <a
                                    onClick={() =>
                                      resdet(data.id, data.category_name)
                                    }
                                    title="Results"
                                    className="winner-mark me-5"
                                  >
                                    <i className="fas fa-trophy fa-lg"></i>
                                  </a>
                                )}
                              </>
                            )}

                          {localStorage.getItem("Role") === "7" &&
                            tcreate === parseInt(localStorage.getItem("UID")) &&
                            urllink === "categorylist" && (
                              <>
                                {edate && (
                                  <a
                                    onClick={() =>
                                      resdet(data.id, data.category_name)
                                    }
                                    title="Results"
                                    className="winner-mark me-5"
                                  >
                                    <i className="fas fa-trophy fa-lg"></i>
                                  </a>
                                )}
                              </>
                            )}
                          {(localStorage.getItem("Role") === "4" ||
                            localStorage.getItem("Role") === "5") &&
                            urllink === "categorylist" && (
                              <>
                                {edate && (
                                  <a
                                    onClick={() =>
                                      resdet(data.id, data.category_name)
                                    }
                                    title="Results"
                                    className="winner-mark"
                                  >
                                    <i className="fas fa-trophy fa-lg"></i>
                                  </a>
                                )}
                              </>
                            )}

                          {localStorage.getItem("Role") === "1" &&
                            urllink === "categorylist" && (
                              <>
                                {/* data.winnerlist.length > 0 */}
                                {edate && (
                                  <a
                                    onClick={() =>
                                      resdetele(data.id, data.category_name)
                                    }
                                    title="Delete Results"
                                    className="delete-mark"
                                  >
                                    <i className="fa fa-trash fa-lg"></i>
                                  </a>
                                )}
                              </>
                            )}
                        </div>
                        <div className="card-body alignMe">
                          <h4 className="h4 mb-0 mt-3">
                            <b>Gender</b> <span>{data.category_gender}</span>
                          </h4>

                          {urllink !== "categorylist" &&
                            ttype === "Inter School Tournament" && (
                              <>
                                <h4 className="h4 mb-0 mt-3">
                                  <b>Teams Allowed</b>{" "}
                                  <span>{data.allowed_team_count}</span>
                                </h4>
                                <h4 className="h4 mb-0 mt-3">
                                  <b>Team Added</b>{" "}
                                  <span>{data.added_team_count}</span>
                                </h4>
                              </>
                            )}
                        </div>

                        <div className="card-footer text-center">
                          {(localStorage.getItem("Role") === "1" ||
                            localStorage.getItem("Role") === "3") && 
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  editcat(data.id, data.category_name)
                                }
                                className="btn btn-secondary btn-sm mb-1"
                                title="Edit Category"
                              >
                                <i className="fa fa-pencil-square-o"> </i> Edit
                              </a>
                            )}

                          {localStorage.getItem("Role") === "7" &&
                            parseInt(localStorage.getItem("UID")) === tcreate &&
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  editcat(data.id, data.category_name)
                                }
                                className="btn btn-secondary btn-sm mb-1"
                                title="Edit Category"
                              >
                                <i className="fa fa-pencil-square-o"> </i> Edit
                              </a>
                            )}

                          {localStorage.getItem("Role") !== "4" &&
                            localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  teamlist(data.id, data.category_name)
                                }
                                className="btn btn-success btn-sm ml-1 mb-1"
                                title="Add Team"
                              >
                                <i className="fa fa-list fa-lg"> </i> Team
                              </a>
                            )}

                          {localStorage.getItem("Role") === "7" &&
                            tcreate === parseInt(localStorage.getItem("UID")) &&
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  teamlist(data.id, data.category_name)
                                }
                                className="btn btn-success btn-sm ml-1 mb-1"
                                title="Add Team"
                              >
                                <i className="fa fa-list fa-lg"> </i> Team
                              </a>
                            )}
                          {localStorage.getItem("Role") === "4" &&
                            ttype !== "Inter School Tournament" && (
                              <a
                                onClick={() =>
                                  teamlist(data.id, data.category_name)
                                }
                                className="btn btn-success btn-sm ml-1 mb-1"
                                title="Add Team"
                              >
                                <i className="fa fa-list fa-lg"> </i> Team
                              </a>
                            )}
                          {localStorage.getItem("Role") === "4" &&
                            urllink !== "categorylist" &&
                            ttype === "Inter School Tournament" && (
                              <a
                                onClick={() =>
                                  teamlist(data.id, data.category_name)
                                }
                                className="btnfixture btn-sm ml-1 mb-1"
                                title="Add Team"
                              >
                                <i className="fa fa-list fa-lg"> </i> Add Team &
                                Players
                              </a>
                            )}
                          {localStorage.getItem("Role") !== "4" &&
                            ttype === "Inter School Tournament" &&
                            urllink !== "categorylist" && (
                              <a
                                onClick={() =>
                                  teamlist(data.id, data.category_name)
                                }
                                className="btnfixture btn-sm ml-1 mb-1"
                                title="Add Team"
                              >
                                <i className="fa fa-list fa-lg"> </i> Add Team &
                                Players
                              </a>
                            )}
                          {data.teams.length === 0 && (
                            <>
                              {(localStorage.getItem("Role") === "1" ||
                                localStorage.getItem("Role") === "3") &&
                                urllink === "categorylist" && (
                                  <a
                                    onClick={() =>
                                      deletecat(data.id, data.category_name)
                                    }
                                    className="btn btn-danger btn-sm ml-1 mb-1"
                                    title="Delete Category"
                                  >
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                )}

                              {localStorage.getItem("Role") === "7" &&
                                parseInt(localStorage.getItem("UID")) ===
                                  tcreate &&
                                urllink === "categorylist" && (
                                  <a
                                    onClick={() =>
                                      deletecat(data.id, data.category_name)
                                    }
                                    className="btn btn-danger btn-sm ml-1 mb-1"
                                    title="Delete Category"
                                  >
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                )}
                            </>
                          )}

                          {urllink === "categorylist" && localStorage.getItem("Role") !== "8" &&(
                            <a
                              onClick={() =>
                                poollist(data.id, data.category_name)
                              }
                              className="btn btn-warning btn-sm ml-1 mb-1"
                              title="Pool List"
                            >
                              <i className="fas fa-object-group"></i> Pool
                            </a>
                          )}
                          {urllink === "categorylist" && localStorage.getItem("Role") === "8" &&(
                            <a
                              onClick={() =>
                                poollist(data.id, data.category_name)
                              }
                              className="btn btn-warning btn-sm ml-1 mb-1"
                              title="Pool List"
                            >
                              <i className="fas fa-object-group"></i> Fixture
                            </a>
                          )}
                          {urllink === "categorylist" && (
                            <a
                              onClick={() =>
                                pointsdet(data.id, data.category_name)
                              }
                              className="btn btn-gray btn-sm ml-1 mb-1"
                              title="Points List"
                            >
                              <i className="fa fa-list fa-lg"></i> Points
                            </a>
                          )}

                          {localStorage.getItem("Role") === "4" &&
                            ttype === "Inter School Tournament" &&
                            data.pointlist.length !== 0 && (
                              <a
                                onClick={() =>
                                  pointsdet(data.id, data.category_name)
                                }
                                className="btn btn-gray btn-sm ml-1 mb-1"
                                title="Points List"
                              >
                                <i className="fa fa-list fa-lg"></i> Points
                              </a>
                            )}
                          {localStorage.getItem("Role") === "4" &&
                            ttype === "Inter School Tournament" &&
                            data.winnerlist.length !== 0 && (
                              <a
                                onClick={() =>
                                  fixlist(data.id, data.category_name)
                                }
                                className="btn btn-export btn-sm ml-1 mb-1"
                                title="Fixture"
                              >
                                <i className="fas fa-object-group"></i> Fixture
                              </a>
                            )}

                          {localStorage.getItem("Role") === "4" &&
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  fixlist(data.id, data.category_name)
                                }
                                className="btn btn-export btn-sm ml-1 mb-1"
                                title="Fixture"
                              >
                                <i className="fas fa-object-group"></i> Fixture
                              </a>
                            )}
                          {(localStorage.getItem("Role") === "1" ||
                            localStorage.getItem("Role") === "3") &&
                            urllink === "categorylist" && (
                              <a
                                onClick={() =>
                                  perflist(data.id, data.category_name)
                                }
                                className="btn btn-export btn-sm ml-1 mb-1"
                                title="Performance based players and photos"
                              >
                                <i className="fa fa-list fa-lg"></i> Performance
                              </a>
                            )}

                          {/* {localStorage.getItem("Role") === "1" && (
                      <a
                        onClick={() => resultlist(data.id,data.category_name)}
                        className="btn btn-export btn-sm ml-1 mb-1"
                        title="Upload Result"
                      >
                        <i className="fa fa-upload"></i> Result
                      </a>
                    )} */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {cid === 0 && <h4> Add Category</h4>}
                  {cid !== 0 && <h4> Category Details</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Category Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Category Name"
                          className="form-control"
                          value={cname}
                          onChange={(e) => setCName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Gender <span className="errortext"> *</span>
                        </label>
                        {/* {(sports.toUpperCase() === "BADMINTON" ||
                          sports.toUpperCase() === "TENNIS" ||
                          sports.toUpperCase() === "TABLE TENNIS" ||
                          sports.toUpperCase() === "SQUASH") && ( */}
                          <div className="row" onChange={Selectgender}>
                            <div className="col-md-4">
                              <label className="custom-control custom-radio custom-control-md">
                                <input
                                  type="radio"
                                  className="reg-chk"
                                  id="active"
                                  value="Male"
                                  checked={gender === "Male"}
                                />{" "}
                                Male
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label className="custom-control custom-radio custom-control-md">
                                <input
                                  type="radio"
                                  className="reg-chk"
                                  id="active"
                                  value="Female"
                                  checked={gender === "Female"}
                                />{" "}
                                Female
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label className="custom-control custom-radio custom-control-md">
                                <input
                                  type="radio"
                                  className="reg-chk"
                                  id="active"
                                  value="Mixed"
                                  checked={gender === "Mixed"}
                                />{" "}
                                Mixed
                              </label>
                            </div>
                          </div>
                        {/* )} */}
                        {/* {sports.toUpperCase() !== "BADMINTON" &&
                          sports.toUpperCase() !== "TENNIS" &&
                          sports.toUpperCase() !== "TABLE TENNIS" &&
                          sports.toUpperCase() !== "SQUASH" && (
                            <div className="row" onChange={Selectgender}>
                              <div className="col-md-6">
                                <label className="custom-control custom-radio custom-control-md">
                                  <input
                                    type="radio"
                                    className="reg-chk"
                                    id="active"
                                    value="Male"
                                    checked={gender === "Male"}
                                  />{" "}
                                  Male
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="custom-control custom-radio custom-control-md">
                                  <input
                                    type="radio"
                                    className="reg-chk"
                                    id="active"
                                    value="Female"
                                    checked={gender === "Female"}
                                  />{" "}
                                  Female
                                </label>
                              </div>
                            </div>
                          )} */}
                      </div>
                    </Col>
                    {matype === 2 &&
                      sports.toUpperCase() !== "BADMINTON" &&
                      sports.toUpperCase() !== "TENNIS" &&
                      sports.toUpperCase() !== "TABLE TENNIS" &&
                      sports.toUpperCase() !== "SQUASH" && (
                        <>
                          <Col lg={12} md={12}>
                            <div className="form-group">
                              <label className="mb-2">
                                Age Category
                                <span className="errortext"> *</span>
                              </label>

                              <Select
                                className="dropdown"
                                placeholder="Select Age Category"
                                value={mcat}
                                options={agecatlist}
                                onChange={handleAge}
                                isSearchable={true}
                                isClearable
                                hidden
                              />
                            </div>
                          </Col>
                          {cid !== 0 && (
                            <div className="details-group refereebox deletereferee">
                              <div className="row">
                                {stdlist.map((data, i) => {
                                  return (
                                    <div
                                      className="col-md-4 align-self-center"
                                      key={i}
                                    >
                                      <div className="form-group">
                                        <h4 className="mb-1">
                                          {data.class_or_age_category_name}{" "}
                                        </h4>
                                        <a
                                          className="deleterefer"
                                          onClick={() =>
                                            deletestd(
                                              data.id,
                                              data.category_id,
                                              data.class_or_age_category_name
                                            )
                                          }
                                        >
                                          <i className="fa fa-close"></i>
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      )}

                    {matype === 2 &&
                      (sports.toUpperCase() === "BADMINTON" ||
                        sports.toUpperCase() === "TENNIS" ||
                        sports.toUpperCase() === "TABLE TENNIS" ||
                        sports.toUpperCase() === "SQUASH") && (
                        <>
                          <Col lg={12} md={12}>
                            <div className="form-group">
                              <label className="mb-2">
                                Age Category
                                <span className="errortext"> *</span>
                              </label>

                              <Select
                                className="dropdown"
                                placeholder="Select Age Category"
                                value={mcat}
                                options={agecatlist}
                                onChange={handleAge}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                          </Col>
                          {cid !== 0 && (
                            <div className="details-group refereebox deletereferee">
                              <div className="row">
                                {stdlist.map((data, i) => {
                                  return (
                                    <div
                                      className="col-md-4 align-self-center"
                                      key={i}
                                    >
                                      <div className="form-group">
                                        <h4 className="mb-1">
                                          {data.class_or_age_category_name}{" "}
                                        </h4>
                                        <a
                                          className="deleterefer"
                                          onClick={() =>
                                            deletestd(
                                              data.id,
                                              data.category_id,
                                              data.class_or_age_category_name
                                            )
                                          }
                                        >
                                          <i className="fa fa-close"></i>
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    {matype === 1 && (
                      <>
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <label className="mb-2">
                              Class<span className="errortext"> *</span>
                            </label>
                            <Select
                              className="dropdown"
                              placeholder="Select Class"
                              value={clsid}
                              options={clslist}
                              onChange={handleCls}
                              isSearchable={true}
                              isMulti
                              isClearable
                              hidden
                            />
                          </div>
                        </Col>
                        {cid !== 0 && (
                          <div className="details-group refereebox deletereferee">
                            <div className="row">
                              {stdlist.map((data, i) => {
                                return (
                                  <div
                                    className="col-md-3 align-self-center"
                                    key={i}
                                  >
                                    <div className="form-group">
                                      <h4 className="mb-1">
                                        {data.class_or_age_category_name}{" "}
                                      </h4>
                                      <a
                                        className="deleterefer"
                                        onClick={() =>
                                          deletestd(
                                            data.id,
                                            data.category_id,
                                            data.class_or_age_category_name
                                          )
                                        }
                                      >
                                        <i className="fa fa-close"></i>
                                      </a>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(sports.toUpperCase() === "BADMINTON" ||
                      sports.toUpperCase() === "TENNIS" ||
                      sports.toUpperCase() === "TABLE TENNIS" ||
                      sports.toUpperCase() === "SQUASH") && (
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Group Type<span className="errortext"> *</span>
                          </label>

                          <Select
                            className="dropdown"
                            placeholder="Select Type"
                            value={cattype}
                            options={cattypelist}
                            onChange={handleCatType}
                            isSearchable={true}
                            isClearable
                            hidden
                          />
                        </div>
                      </Col>
                    )}
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Qualification Type{" "}
                          <span className="errortext"> *</span>
                        </label>
                        {schgen && (
                          <select
                            className="form-control custom-select"
                            value={quachk}
                            disabled
                            onChange={(e) => setQuachk(e.target.value)}
                          >
                            <option value="" defaultValue disabled>
                              --Select Qualification Type--
                            </option>
                            <option value="1">
                              First Two team Will Qualify
                            </option>
                            <option value="2">First Team Will qualify</option>
                            <option value="3">Direct Finals</option>
                            <option value="4">No Knockout</option>
                          </select>
                        )}
                        {!schgen && (
                          <select
                            className="form-control custom-select"
                            value={quachk}
                            onChange={(e) => setQuachk(e.target.value)}
                          >
                            <option value="" defaultValue disabled>
                              --Select Qualification Type--
                            </option>
                            <option value="1">
                              First Two team Will Qualify
                            </option>
                            <option value="2">First Team Will qualify</option>
                            <option value="3">Direct Finals</option>
                            <option value="4">No Knockout</option>
                          </select>
                        )}
                      </div>
                    </Col>

                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {cid === 0 && (
                    <a className="btn btn-success" onClick={submitcat}>
                      Add
                    </a>
                  )}

                  {cid !== 0 && (
                    <a className="btn btn-success" onClick={submitcat}>
                      Update
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
