import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { crmprofileview } from "../../../services/cityregionaladmin";
import { REFprofileview } from "../../../services/referee";
import {
  SCHADMINprofileview,
  schcrmlist,
  schprofileview,
} from "../../../services/schoolhead";
import moment from "moment";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import { coachviewprofile } from "../../../services/coach";
export const Profile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [sname, setSchool] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(false);
  const [ishow, setImage] = useState(false);
  const [crnname, setCRM] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "3") {
      crmprofileview().then((response) => {
        if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          setDOB(response.data.dob);
          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          // setSchool(response.data.user_id);
          setAddress(response.data.address);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCity(response.data.cityname);
          setPincode(response.data.pincode);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      REFprofileview().then((response) => {
        if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          setDOB(response.data.dob);
          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          if (response.data.school_name !== null) {
            setSchool(response.data.school_name);
          }
          setAddress(response.data.address);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCity(response.data.cityname);
          setPincode(response.data.pincode);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schprofileview().then((response) => {
        if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          setDOB(response.data.dob);
          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          setSchool(response.data.school_name);
          setAddress(response.data.address);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCRM();
          setCity(response.data.cityname);
          setPincode(response.data.pincode);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }
          if (response.data.city_admin_id !== null) {
            schcrmlist(3, 1).then((res) => {
              if (res.status === true) {
                if (res.data.length !== 0) {
                  const data = res.data.find(
                    (item) => item.user_id == response.data.city_admin_id
                  );
                  if (data !== undefined) {
                    if (data.last_name !== null) {
                      setCRM(data.first_name + " " + data.last_name);
                    } else {
                      setCRM(data.first_name);
                    }
                  }
                }
              }
            });
          }
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((response) => {
        if (response.status === true) {

          setFName(response.data.first_name);
          setLName(response.data.last_name);
          setDOB(response.data.dob);
          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          setSchool(response.data.school_name);
          setAddress(response.data.address);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCRM();
          setCity(response.data.cityname);
          setPincode(response.data.pincode);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }
         
        }
      });
    }
  }, []);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }

  function editlist() {
    dotracking(
      "edit button in profile for " +
        fname +
        " " +
        lname +
        " (" +
        email +
        ") $$ " +
        trackobj.profileeditheading
    );
    if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/editprofile");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/editprofile");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/editprofile");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/editprofile");
    }
  }
  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Profile</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Profile</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Profile
            </li>
          </ol>
        </div>

        <div className="row" id="user-profile">
          <div className="col-lg-12">
            <div className="card profilecard">
              <div className="card-body">
                <div className="wideget-user">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 align-self-center">
                      <div className="wideget-user-desc d-flex">
                        <div className="wideget-user-img">
                          <img
                            className=""
                            src={photo}
                            alt="img"
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                        <div className="user-wrap align-self-center">
                          <h3>{localStorage.getItem("User")}</h3>
                          <h3 className="mb-0 text-muted">
                            UID - {localStorage.getItem("Name")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">Primary Details</h3>
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">First Name</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{fname}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Last Name</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{lname}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Gender</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{gender}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Date of Birth</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">
                              {moment(dob).format("DD-MM-yyyy")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Contact Number</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mobno}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Email Address</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                                            <div className="details-group">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <b className="text-inverse">Username (UID)</b>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span className="text-inverse">{uname}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">
                  Personal Details
                </h3>
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    {localStorage.getItem("Role") === "5" && (
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-2 col-md-6">
                              <b className="text-inverse">Address</b>
                            </div>
                            <div className="col-md-10 col-md-6">
                              <span className="text-inverse">{address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {localStorage.getItem("Role") === "4" && (
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-2 col-md-6">
                              <b className="text-inverse">School</b>
                            </div>
                            <div className="col-md-10 col-md-6">
                              <span className="text-inverse">{sname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {localStorage.getItem("Role") === "5" && sname !== "" && (
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-2 col-md-6">
                              <b className="text-inverse">School</b>
                            </div>
                            <div className="col-md-10 col-md-6">
                              <span className="text-inverse">{sname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {localStorage.getItem("Role") === "4" && sname !== "" && (
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-2 col-md-6">
                              <b className="text-inverse">Manager</b>
                            </div>
                            <div className="col-md-10 col-md-6">
                              <span className="text-inverse">{crnname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">State</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{state}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">District</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{district}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">City</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{city}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {localStorage.getItem("Role") === "5" && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-4">
                              <b className="text-inverse">Pincode</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{pincode}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {localStorage.getItem("Role") !== "6" && (
            <div className="text-right">
              <a className="btn btn-success" onClick={editlist}>
                Edit
              </a>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
