import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import {
  Playerprofiledetails,
  playerhighlights,
} from "../../../services/player";

export const Achievements = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Tournament Type",
      field: "meet_type",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gold",
      field: "Gold",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Silver",
      field: "Silver",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Bronze",
      field: "Bronze",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Medals",
      field: "Total",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    Playerprofiledetails(localStorage.getItem("Name")).then((response) => {
      if (response.status === true) {
        if (response.data.athletelist !== null) {
          playerhighlights(
            localStorage.getItem("UID"),
            response.data.athletelist.school_id
          ).then((response) => {
            if (response.status === true) {
              setFilterProduct(response.data);
            }
          });
        }
      }
    });
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Key Highlights</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Role") === "6" && (
            <li className="breadcrumb-item">
              <a>Achievements</a>
            </li>
          )}
          {localStorage.getItem("Role") !== "6" && (
            <li className="breadcrumb-item">
              <a>Sports Performance</a>
            </li>
          )}

          <li className="breadcrumb-item active" aria-current="page">
            Key Highlights
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  // onGridReady={(params) => {
                  //   params.api.sizeColumnsToFit();
                  // }}
                  // domLayout="autoWidth"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
