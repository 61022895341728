import React, { Fragment } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  resultdataupload,
  resultuploadloglist,
  tourcategorydetail,
  tourlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import exportUrl from "../../../../exportUtl";
import { useMemo } from "react";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const UploadResult = () => {
  const { id } = useParams();
  const { eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const [resfile, setResult] = useState("");
  const [mtype, setMType] = useState("");
  const [s3path, setS3Path] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [tname, setTName] = useState("");
  const [catname, setCatname] = useState("");

  const [failid, setFailId] = useState(0);
  const columnDefs = [
    {
      headerName: "Upload Excel",
      width: 170,
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={()=>{ dotracking("click to download link button in result upload for " + catname + " (" +eid + ") category in "+ tname + " (" + id + ") category and file "+ params.value + " (" + params.data.id + ") $$ " + trackobj.resultuploadheading );}}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  let arr = [];
  useEffect(() => {
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }

    listall();
  }, []);

  function failedent(fid) {
    dotracking("failed click to download link button in result upload for " + catname + " (" +eid + ") category in "+ tname + " (" + id + ") category and file failed " + fid + " $$ " + trackobj.resultuploadheading);
    window.open(exportUrl + "export-failed-results/" + fid, "_blank");
  }

  function listall() {
    resultuploadloglist(id).then((res) => {
      if (res.status === true) {
        setFilterProduct(res.data.details);
        setS3Path(res.data.s3path);
      }
    });
  }
  function datafile(event) {
    setFileMsg("");
    setResult("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setResult(event.target.files[0]);
      } else {
        setFileMsg("Result File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("Base Data File size should be less than 5MB");
      // }
    }
  }
  const navigate = useNavigate();
  function backlist() {
    dotracking("back button in result upload for " + catname + " ("+ eid + ") category in "+ tname + " (" + id + ") tournament $$ "+ trackobj.categorylistheading);
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + id);
    }
  }
  function resultupload() {
    setErrorMsg("");
    if (mtype !== "") {
      if (resfile !== "") {
        if (fileMsg === "") {
          setLoading(true);

          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("tournament_id", id);
          temp.append("results_excel", resfile);
          temp.append("medal_or_match", mtype);
          temp.append("category_id", eid);
          resultdataupload(temp).then((response) => {
            if (response.status === true) {
              setLoading(false);
              listall();
              setResult("");
              setMType("");
              setTRecord(response.data.total_rows);
              setIRecord(response.data.inserted_count);
              setURecord(response.data.failed_count);
              setStatus(response.data.status);
              setFailId(response.data.uploaded_excel_id);
              toast.success(response.data.reason);
            } else {
              setLoading(false);
              setErrorMsg(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Result File");
      }
    } else {
      setErrorMsg("Please Select Result Type");
    }
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Result Upload</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Result Upload
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="card">
                <div className="row mt-3">
                  <div className="col-md-10">
                    <h3 >{tname}</h3>
                    <h4 className="text-success tx-20">Category - {catname}</h4>
                  </div>
                  <div className="col-md-2">
                    <div className="text-right">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Form className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Result Type <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control"
                        value={mtype}
                        onChange={(e) => setMType(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --Select Result Type--
                        </option>
                        <option value="1">Medal Info</option>
                        <option value="2">Match Wise Results</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Upload Offline Entry File{" "}
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                     
                      <a
                        className="btn btn-success me-2"
                        onClick={resultupload}
                      >
                        Submit
                      </a>
                      <a
                        className="btn btn-secondary me-2"
                        href={require("../../../../assets/images/Medal_Info_Sample_Excel.xlsx")}
                        download
                        onClick={() => {
                          dotracking("sample medal info excel button in result upload for " + catname + " (" + eid + ") category in "+ tname + " (" + id + ") tournament $$ " + trackobj.resultuploadheading);
                        }}
                      >
                        Sample Medal Info Excel
                      </a>
                      <a
                        className="btn btn-secondary"
                        href={require("../../../../assets/images/Sample_Results_Ecxel.xlsx")}
                        download
                        onClick={() => {
                          dotracking("sample match wise excel button in result upload for " + catname + " (" + eid + ") category in "+ tname + " (" + id + ") tournament $$ " + trackobj.resultuploadheading);
                        }}
                      >
                        Sample Match Wise Excel
                      </a>
                    </div>
                    {/* <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        Back
                      </a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>
                      <a
                        className="btn btn-secondary"
                        href={require("../../../../assets/images/Sample_Offline_Tournament_Entry_Excel.xlsx")}
                        download
                      >
                        Sample Excel
                      </a>
                    </div> */}
                  </div>
                </Form>
              </div>
              <div className="col-md-6">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 320,
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="4"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </div>

            {mtype !== "1" && (
              <div className="row row-cards mt-3">
                <div className="">
                  <h4>Upload Summary</h4>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card bg-custom">
                    <div className="card-body text-center feature">
                      <p className="card-text mt-3 mb-3">
                        <strong>Total Records</strong>
                      </p>
                      <p className="h3 text-center text-info">{trecord}</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card bg-custom">
                    <div className="card-body text-center feature">
                      <p className="card-text mt-3 mb-3">
                        <strong>Successful Records</strong>
                      </p>
                      <p className="h3 text-center text-primary-1">{irecord}</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card bg-custom">
                    <div className="card-body text-center feature">
                      <p className="card-text mt-3 mb-3">
                        <strong>Failure Records</strong>
                      </p>
                      <p className="h3 text-center text-teal">{urecord}</p>

                      {urecord !== 0 && (
                        <a
                          className="excelfailed text-danger"
                          onClick={() => failedent(failid)}
                        >
                          <b>Click to Download</b>
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card bg-custom">
                    <div className="card-body text-center feature">
                      <p className="card-text mt-3 mb-3">
                        <strong>Upload Status</strong>
                      </p>
                      {strecord === "Success" && (
                        <p className="h2 badge badge-success">
                          <b>{strecord}</b>
                        </p>
                      )}
                      {strecord !== "Success" && (
                        <p className="h2 badge badge-danger">
                          <b>{strecord}</b>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};
