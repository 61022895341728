import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  assessmentdatedetail,
  assessmentdatelist,
  assessmentheading,
  gettokenfromapi,
} from "../../../../services/superadmin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { get } from "jquery";
import { sahassessmentdatelist } from "../../../../services/sahapi";

export const AssessmentAdmin = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [assid, setAssId] = useState(0);
  const [assname, setAssName] = useState("");
  const [assdate, setAssDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Assessment Date",
      field: "assessment_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return moment(new Date(params.value)).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Date",
      field: "assessment_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          const dte = moment(new Date(params.value)).format("DD-MM-YYYY");
          const curdte = dte.replace(/-/g, "");
          return curdte;
          // ;
        }
      },
    },
    {
      headerName: "Actions",
      field: "assessment_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params.data.assessment_date)}
              title="Assessment Detail"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
            <a
              title="Add Assessment"
              onClick={() => adddet(params.value, params.data.assessment_date)}
              className="btn-sm btn-success"
            >
              <i className="fa fa-plus fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    listall();
  }, []);
  const navigate = useNavigate();
  function adddet(aid, adate) {
    dotracking(
      "add icon in assessment date list for " +
        adate +
        " (" +
        aid +
        ") $$ " +
        trackobj.assuploadheading
    );
    const dte = moment(new Date(adate)).format("DD-MM-YYYY");
    const curdte = dte.replace(/-/g, "");
    navigate("/superadmin/assessmentdetail/" + curdte);
  }
  function viewdet(vid, vdate) {
    const temp = new FormData();
    temp.append("date_id", vid);
    dotracking(
      "edit icon in assessment date list for " +
        vdate +
        " (" +
        vid +
        ") $$ " +
        trackobj.assdateheading
    );
    assessmentdatedetail(temp).then((response) => {
      if (response.status === true) {
        setAssId(response.data.assessmentDate.id);
        setAssName(response.data.assessmentDate.assessment_heading);
        setAssDate(response.data.assessmentDate.assessment_date);
        setShow(true);
      }
    });
  }

  function listall() {
    setFilterProduct([]);
    const temp = new FormData();
    temp.append("data_req", "available_dates");
    gettokenfromapi(temp).then((res) => {
      if (res.status === true) {
        sahassessmentdatelist("available_dates", res.data).then((response) => {
          if (response.status === true) {
            let arr = [];
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  assessment_date: response.data[i],
                });
              }
              setFilterProduct(arr);
            }
          }
        });
      }
    });
    // assessmentdatelist().then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data.assessmentDate);
    //   }
    // });
  }
  const [show, setShow] = useState(false);

  function handleshow() {
    setErrorMsg("");
    setAssId(0);
    setAssName("");
    setAssDate("");
    setShow(true);
  }
  function handleclose() {
    if (assid !== 0) {
      dotracking(
        "close button in edit assessment date " +
          assdate +
          " (" +
          assid +
          ") $$ " +
          trackobj.assdateheading
      );
    }
    setShow(false);
    setAssId(0);
    setErrorMsg("");
    setAssName("");
    setAssDate("");
  }

  function asssubmit() {
    setErrorMsg("");
    if (assname !== "") {
      if (assdate !== "") {
        const temp = new FormData();
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("assessment_heading", assname);
        temp.append("assessment_date", assdate);
        temp.append(
          "assessment_date_string",
          moment(assdate).format("DDMMYYYY")
        );
        temp.append("assessment_date_id", assid);
        if (assid === 0) {
          dotracking(
            "submit button in add assessment for " +
              assname +
              " - " +
              assdate +
              " $$ " +
              trackobj.assuploadheading
          );
        } else {
          dotracking(
            "update button in edit assessment for " +
              assname +
              " - " +
              assdate +
              " (" +
              assid +
              ") $$ " +
              trackobj.assuploadheading
          );
        }
        assessmentheading(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            listall();
          }
        });
      } else {
        setErrorMsg("Please Select Assessment Date");
      }
    } else {
      setErrorMsg("Please Enter Assessment Name");
    }
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Assessment Date List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Assessment Date List
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                {/* <div className="text-right">
                  <a className="btn btn-success" onClick={handleshow}>
                    <i className="fa fa-plus"></i> Add Assessment Date
                  </a>
                </div> */}
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>

                <Modal show={show} size="lg">
                  <Modal.Header>
                    {assid === 0 && <h4> Add Assessment Date</h4>}
                    {assid !== 0 && <h4> Assessment Date Detail</h4>}
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Assessment Name{" "}
                            <span className="errortext"> *</span>
                          </label>

                          <input
                            type="text"
                            placeholder="Assessment Name"
                            className="form-control"
                            value={assname}
                            onChange={(e) => setAssName(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Assessment Date{" "}
                            <span className="errortext"> *</span>
                          </label>

                          <input
                            type="date"
                            className="form-control"
                            value={assdate}
                            onChange={(e) => setAssDate(e.target.value)}
                          />
                        </div>
                      </Col>
                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    {assid === 0 && (
                      <a className="btn btn-success" onClick={asssubmit}>
                        Submit
                      </a>
                    )}
                    {assid !== 0 && (
                      <a className="btn btn-success" onClick={asssubmit}>
                        Update
                      </a>
                    )}
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
