import React, { useEffect, useState } from "react";
import {
  Validatetouradd,
  agecatactivelist,
  multipleroleslist,
  schoolactivelist,
  sportsactivelist,
  touradd,
  tourassigncrm,
  tourassigncrmdeletelist,
  tourassigncrmlist,
  tourassignrefdeletelist,
  tourassignreferee,
  tourassignrefereelist,
  tourcodecount,
  tourtypeactivelist,
  tourtypelist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  classlist,
  districtlist,
  statelist,
} from "../../../../../services/master";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  crmagecatelist,
  crmsportslist,
  crmtouradd,
  crmtourtypelist,
  schoolactivelistcityreg,
  schoollistcityreg,
} from "../../../../../services/cityregionaladmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import {
  coachagecatelist,
  coachsportslist,
  coachtouradd,
  coachtourtypelist,
  coachviewprofile,
  schoolactivelistcoach,
} from "../../../../../services/coach";

export const AddTour = () => {
  const navigate = useNavigate();
  const [typelist, setTypeList] = useState([]);
  const [spotypelist, setSpoTypeList] = useState([]);
  const [schlist, setSchList] = useState([]);
  const [ischlist, setISchList] = useState([]);

  const [curdate, setCurdate] = useState("");

  const [tcls, setTCls] = useState([]);
  const [clslist, setClsList] = useState([]);

  const [agecatlist, setAgeCatList] = useState([]);
  const [slist, setStateList] = useState([]);
  const [dlist, setDistList] = useState([]);

  const [mschool, setMSch] = useState([]);

  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mprefix, setMPrefix] = useState("");
  const [mdtprefix, setMDtPrefix] = useState("");

  const [mcode, setMCode] = useState("");
  const [msuffix, setMSuffix] = useState("");

  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [meodate, setMEODate] = useState("");
  const [meddate, setMEDDate] = useState("");
  const [msport, setMSport] = useState("");
  const [msportid, setMSportId] = useState("");

  const [mulsp, setMulSp] = useState(0);

  const [mfees, setMFees] = useState("");
  const [relayfees, setRelayFees] = useState("");

  const [mschid, setMSchId] = useState("");
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");
  const [mgate, setMGate] = useState("N");
  const [mfree, setMFree] = useState("N");

  const [matype, setMAType] = useState("");
  const [mcat, setMCat] = useState("");
  const [mleatype, setMLeaque] = useState("");
  const [mcir, setMCir] = useState("");
  const [reptime, setRepTime] = useState("");

  const [refid, setRefid] = useState("");
  const [reflist, setRefList] = useState([]);

  const [crmid, setCRMid] = useState("");
  const [crmlist, setCRMList] = useState([]);

  let arr = [];
  let arr1 = [];

  useEffect(() => {
    setCurdate(moment(new Date()).format("YYYY-MM-DD"));

    tourcodecount().then((response) => {
      if (response.status === true) {
        setMSuffix(response.data);
      }
    });

    statelist().then((response) => {
      if (response.status === true) {
        setStateList(response.data);
      }
    });
    classlist().then((response) => {
      if (response.status === true) {
        arr1 = [];
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].id,
              label: response.data[i].class,
            });
          }
        }
        setClsList(arr1);
      }
    });

    if (localStorage.getItem("Role") === "1") {
      tourtypeactivelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
        }
      });

      sportsactivelist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      schoolactivelist().then((response) => {
        if (response.status === true) {
          arr1 = [];
          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });

      agecatactivelist().then((response) => {
        if (response.status === true) {
          arr = [];
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourtypelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
        }
      });

      crmsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      crmagecatelist().then((response) => {
        if (response.status === true) {
          arr = [];
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });

      schoollistcityreg().then((response) => {
        if (response.status === true) {
          arr1 = [];
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr1.push({
                value: response.data[i].id,
                label:
                  response.data[i].school_unique_no +
                  " - " +
                  response.data[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourtypelist().then((response) => {
        if (response.status === true) {
          setTypeList(response.data);
          setMType("");
        }
      });

      coachsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });

      coachagecatelist().then((response) => {
        if (response.status === true) {
          arr = [];

          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });

      coachviewprofile().then((res) => {
        if (res.status === true) {
          schoolactivelistcoach().then((response) => {
            if (response.status === true) {
              let arr3 = [];

              if (response.data.details.length !== 0) {
                arr1 = [];
                for (let i = 0; i < response.data.details.length; i++) {
                  arr3.push({
                    value: response.data.details[i].id,
                    label:
                      response.data.details[i].school_unique_no +
                      " - " +
                      response.data.details[i].school_name,
                  });
                  if (response.data.details[i].id === res.data.school_id) {
                    arr1.push({
                      value: response.data.details[i].id,
                      label:
                        response.data.details[i].school_unique_no +
                        " - " +
                        response.data.details[i].school_name,
                    });
                  }
                }
              }
              setMSchId(arr1[0]);
              setSchList(arr1);
              setISchList(arr3);
            }
          });
        }
      });
    }
  }, []);

  function selectstate(e) {
    e.preventDefault();
    setMState(e.target.value);
    setDistList([]);
    setMDist("");
    setMPrefix("");
    setMDtPrefix("");
    if (e.target.value !== "") {
      statelist().then((response) => {
        if (response.status === true) {
          var data = response.data.filter(
            (item) => item.stateuid === parseInt(e.target.value)
          );
          setMPrefix(data[0].state_description);
        }
      });

      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDistList(response.data);
        }
      });
    }
  }

  function selectdist(e) {
    setMDist(e.target.value);
    if (e.target.value !== "") {
      districtlist(mstate).then((response) => {
        if (response.status === true) {
          var data = response.data.filter(
            (item) => item.districtuid === parseInt(e.target.value)
          );
          var sub = "";
          sub = data[0].districtname.substring(0, 2).toUpperCase();
          setMDtPrefix(sub);
        }
      });
    }
  }
  const [cirerror, setCirError] = useState("");
  function circfile(event) {
    setCirError("");
    setMCir("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf)$/i)) {
        setMCir(event.target.files[0]);
      } else {
        setCirError("Circular File should be pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setCirError("Circular File size should be less than 5MB");
      }
    }
  }

  const [logoerror, setLogoError] = useState("");
  const [lfile, setLFile] = useState("");

  function logofile(event) {
    setLogoError("");
    setMCir("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLFile(event.target.files[0]);
      } else {
        setLogoError("Logo should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setLogoError("Logo size should be less than 5MB");
      }
    }
  }

  function handleType(e) {
    setMType(e.target.value);
    setMSchId("");
  }
  function handleAge(e) {
    setMCat(e);
  }

  function handleCls(e) {
    setTCls(e);
  }

  function handlemtype(e) {
    if (msportid === "") {
      toast.success("Please Select Sports");
    } else {
      setMAType(e.target.value);
    }
  }

  function handleSport(e) {
    setMSportId(e.target.value);
    setMSport("");
    const data = spotypelist.find(
      (item) => item.sports_cat_uid == e.target.value
    );
    setMSport(data.sports_category);
  }
  function handleSchool(e) {
    if (mtype === "2") {
      setMSchId(e);
    } else if (mtype === "3") {
      setMSchId(e);
      if (localStorage.getItem("Role") === "1") {
        if (e.value !== "") {
          schoolactivelist().then((response) => {
            if (response.status === true) {
              var data = response.data.details.filter(
                (item) => item.id === parseInt(e.value)
              );
              setMCode(data[0].school_unique_no);
            }
          });
        }
      } else if (localStorage.getItem("Role") === "3") {
        if (e.value !== "") {
          schoolactivelistcityreg().then((response) => {
            if (response.status === true) {
              var data = response.data.details.filter(
                (item) => item.id === parseInt(e.value)
              );
              setMCode(data[0].school_unique_no);
            }
          });
        }
      }
    }
    //
  }

  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function toursubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMsg("");
    var serror = "";
    if (mtype === "2") {
      if (mschid === "") {
        serror = "required";
      }
    }
    if (mtype === "3") {
      if (mschid === "") {
        serror = "required";
      }
    }

    if (msport.toUpperCase() !== "ATHLETICS") {
      if (mfree === "N") {
        if (mfees === "") {
          serror = "required";
          setErrorMsg("Fees is required");
        }
        if (mfees === "0") {
          serror = "required";
          setErrorMsg("Fees must be greater than zero");
        }
      }
      if (mleatype === "") {
        serror = "required";
        setErrorMsg("Please Select League Type");
      }
    }

    if (
      serror === "" &&
      // mfees !== "" &&
      mname !== "" &&
      mtype !== "" &&
      mstate !== "" &&
      mdist !== "" &&
      msdate !== "" &&
      medate !== "" &&
      matype !== "" &&
      meodate !== "" &&
      meddate !== "" &&
      msportid !== "" &&
      mgate !== "" &&
      reptime !== ""
    ) {
      var schid = "";
      if (mtype === "2") {
        if (mschid.length !== 0) {
          for (let i = 0; i < mschid.length; i++) {
            if (schid === "") {
              schid = mschid[i].value;
            } else {
              schid = schid + "," + mschid[i].value;
            }
          }
        }
      } else {
        schid = mschid.value;
      }
      var valid = "";
      var catid = "";
      var clsid = "";

      if (matype === "2") {
        if (msdob === "") {
          valid = "required";
        }
        if (medob === "") {
          valid = "required";
        }
        if (mcat === "") {
          valid = "required";
        }
        if (mcat.length !== 0) {
          for (let i = 0; i < mcat.length; i++) {
            if (catid === "") {
              catid = mcat[i].label;
            } else {
              catid = catid + "," + mcat[i].label;
            }
          }
        }
      } else if (matype === "1") {
        if (tcls.length !== 0) {
          for (let i = 0; i < tcls.length; i++) {
            if (clsid === "") {
              clsid = tcls[i].value;
              catid = tcls[i].label;
            } else {
              clsid = clsid + "," + tcls[i].value;
              catid = catid + "," + tcls[i].label;
            }
          }
        } else {
          valid = "required";
        }
      }

      if (valid === "") {
        const temp1 = new FormData();
        temp1.append("meet_name", mname);
        temp1.append("start_date", msdate);
        temp1.append("end_date", medate);
        Validatetouradd(temp1).then((response) => {
          if (response.status === true) {
            const temp = new FormData();
            temp.append("meet_name", mname);
            temp.append("location", mloc);
            temp.append("meet_code", mprefix + mdtprefix + mcode + msuffix);
            temp.append("meet_type", mtype);
            temp.append("state", mstate);
            temp.append("district", mdist);
            temp.append("start_date", msdate);
            temp.append("end_date", medate);
            temp.append("entry_deadline_date", meddate);
            temp.append("entry_opens", meodate);
            temp.append("sports_type", msport);
            temp.append("sports_type_no", msportid);
            temp.append("agecat_or_std", matype);
            temp.append("standard", clsid);
            temp.append("categories", catid);
            temp.append("dob_start_date", msdob);
            temp.append("dob_end_date", medob);
            temp.append("gateway_charges", mgate);
            temp.append("free_tournament", mfree);
            temp.append("multi_sports", mulsp);

            if (msport.toUpperCase() === "ATHLETICS") {
              temp.append("registration_fees", 0);
            } else {
              if (mfree === "N") {
                temp.append("registration_fees", mfees);
              } else {
                temp.append("registration_fees", 0);
              }
            }

            temp.append("payment_gateway", "Razorpay");
            temp.append("league_type", mleatype);
            temp.append("school", schid);
            temp.append("circular_file_1", mcir);
            temp.append("tournament_logo", lfile);

            temp.append("reporting_time", reptime);
            if (relayfees !== "") {
              temp.append("relay_fees", relayfees);
            } else {
              temp.append("relay_fees", 0);
            }
            if (msport.toUpperCase() === "ATHLETICS") {
              dotracking(
                "submit button in add tournament for " +
                  mname +
                  " (" +
                  mcode +
                  ") tournament $$ " +
                  trackobj.touraddageheading
              );
            } else {
              if (matype === "1") {
                dotracking(
                  "submit button in add tournament for " +
                    mname +
                    " (" +
                    mcode +
                    ") tournament $$ " +
                    trackobj.tourlistheading
                );
              } else {
                dotracking(
                  "submit button in add tournament for " +
                    mname +
                    " (" +
                    mcode +
                    ") tournament $$ " +
                    trackobj.touraddageheading
                );
              }
            }

            if (localStorage.getItem("Role") === "1") {
              touradd(temp).then((response) => {
                if (response.status === true) {
                  setSubmitted(false);
                  if (msport.toUpperCase() === "ATHLETICS") {
                    navigate(
                      "/superadmin/athleticsagecategory/" +
                        response.data.tournament.id
                    );
                  } else {
                    if (matype === "1") {
                      navigate("/superadmin/tournamentlist");
                    } else {
                      navigate(
                        "/superadmin/tournamentagecategory/" +
                          response.data.tournament.id
                      );
                    }
                  }
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.message);
                }
              });
            } else if (localStorage.getItem("Role") === "3") {
              crmtouradd(temp).then((response) => {
                if (response.status === true) {
                  setSubmitted(false);
                  if (msport.toUpperCase() === "ATHLETICS") {
                    navigate(
                      "/cityregional/athleticsagecategory/" +
                        response.data.tournament.id
                    );
                  } else {
                    if (matype === "1") {
                      navigate("/cityregional/tournamentlist");
                    } else {
                      navigate(
                        "/cityregional/tournamentagecategory/" +
                          response.data.tournament.id
                      );
                    }
                  }
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.message);
                }
              });
            } else if (localStorage.getItem("Role") === "7") {
              coachtouradd(temp).then((response) => {
                if (response.status === true) {
                  setSubmitted(false);
                  if (msport.toUpperCase() === "ATHLETICS") {
                    navigate(
                      "/coach/athleticsagecategory/" +
                        response.data.tournament.id
                    );
                  } else {
                    if (matype === "1") {
                      navigate("/coach/tournamentlist");
                    } else {
                      navigate(
                        "/coach/tournamentagecategory/" +
                          response.data.tournament.id
                      );
                    }
                  }
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.message);
                }
              });
            }
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    }
  }

  const handleEDate = (e) => {
    if (msdate !== "") {
      setMEDate(e.target.value);
      validate(mname, msdate, e.target.value);
    } else {
      toast.error("Please Select Tournament Start On");
    }
  };

  const handleBlur = (e) => {
    validate(e.target.value, msdate, medate);
  };

  function validate(nme, start, end) {
    if (nme !== "" && start !== "" && end !== "") {
      const temp1 = new FormData();
      temp1.append("meet_name", nme);
      temp1.append("start_date", start);
      temp1.append("end_date", end);
      Validatetouradd(temp1).then((response) => {
        if (response.status === false) {
          setErrorMsg(response.message);
          toast.error(response.message);
        }
      });
    }
  }

  const handleOpen = (e) => {
    if (medate !== "") {
      setMEODate(e.target.value);
    } else {
      toast.error("Please Select Tournament Ends At");
    }
  };

  const handleLast = (e) => {
    if (medate !== "") {
      setMEDDate(e.target.value);
    } else {
      toast.error("Please Select Entry Open at");
    }
  };
  const handlefees = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMFees(e.target.value);
    }
  };

  const relayhandlefees = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setRelayFees(e.target.value);
    }
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Tournament</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Tournament
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tournament Name"
                      value={mname}
                      onChange={(e) => setMName(e.target.value)}
                      onBlur={handleBlur}
                    />
                    {submitted && !mname && (
                      <span className="errortext">
                        Tournament Name is required
                      </span>
                    )}
                  </div>
                </div>
                {localStorage.getItem("Role") === "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Tournament Type <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={mtype}
                        onChange={handleType}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Tournament Type ---
                        </option>
                        {typelist.map((data, i) => {
                          return (
                            <option value={data.id} key={i}>
                              {data.meet_type}
                            </option>
                          );
                        })}
                      </select>
                      {submitted && !mtype && (
                        <span className="errortext">
                          Tournament Type is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {localStorage.getItem("Role") !== "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Tournament Type <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={mtype}
                        onChange={handleType}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Tournament Type ---
                        </option>
                        {typelist.map((data, i) => {
                          return (
                            <option value={data.id} key={i}>
                              {data.meet_type}
                            </option>
                          );
                        })}
                      </select>
                      {submitted && !mtype && (
                        <span className="errortext">
                          Tournament Type is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      State <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mstate}
                      onChange={selectstate}
                    >
                      <option value="" defaultValue disabled>
                        --- Select State ---
                      </option>
                      {slist.map((data, i) => {
                        return (
                          <option value={data.stateuid} key={i}>
                            {data.statename}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !mstate && (
                      <span className="errortext">State is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      District <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mdist}
                      onChange={selectdist}
                    >
                      <option value="" defaultValue disabled>
                        --- Select District ---
                      </option>
                      {dlist.map((data, i) => {
                        return (
                          <option value={data.districtuid} key={i}>
                            {data.districtname}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !mdist && (
                      <span className="errortext">District is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Start on <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={msdate}
                      min={localStorage.getItem("Role") === "1" ? "" : curdate}
                      onChange={(e) => setMSDate(e.target.value)}
                      onBlur={(e) => validate(mname, e.target.value, medate)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !msdate && (
                      <span className="errortext">
                        Tournament Start On is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Ends at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={medate}
                      min={msdate}
                      onChange={handleEDate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !medate && (
                      <span className="errortext">
                        Tournament Ends at is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Opens at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={meodate}
                      min={curdate}
                      max={medate}
                      onChange={handleOpen}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !meodate && (
                      <span className="errortext">
                        Entry Opens at is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Last Date <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={meddate}
                      min={meodate}
                      max={medate}
                      onChange={handleLast}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !meddate && (
                      <span className="errortext">
                        Entry Last Date is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Multi Sports <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mulsp}
                      onChange={(e) => setMulSp(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Multi Sports ---
                      </option>
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Sports <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={msportid}
                      onChange={handleSport}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Sports ---
                      </option>
                      {spotypelist.map((data, i) => {
                        return (
                          <option value={data.sports_cat_uid} key={i}>
                            {data.sports_category}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !msportid && (
                      <span className="errortext">Sports is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Age Category Type <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={matype}
                      onChange={handlemtype}
                    >
                      <option value="" defaultValue disabled>
                        --Select Age Category Type--
                      </option>
                      <option value="2">By Date of Birth</option>
                      {msport.toUpperCase() !== "ATHLETICS" && (
                        <option value="1">By Class / Grade</option>
                      )}
                    </select>
                    {submitted && !matype && (
                      <span className="errortext">
                        Age Category Type is required
                      </span>
                    )}
                  </div>
                </div>
                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Categories <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Categories"
                        value={mcat}
                        options={agecatlist}
                        onChange={handleAge}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !mcat && (
                        <span className="errortext">
                          Age Categories is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {matype === "1" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Class <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Class"
                        value={tcls}
                        options={clslist}
                        onChange={handleCls}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !tcls && (
                        <span className="errortext">Class is required</span>
                      )}
                    </div>
                  </div>
                )}

                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        DOB Start Date <span className="errortext"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={msdob}
                        onChange={(e) => setMSDob(e.target.value)}
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                      {submitted && !msdob && (
                        <span className="errortext">
                          DOB Start Date is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {matype === "2" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        DOB End Date <span className="errortext"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={medob}
                        min={msdob}
                        onChange={(e) => setMEDob(e.target.value)}
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                      {submitted && !medob && (
                        <span className="errortext">
                          DOB End Date is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Free Tournament</label>
                    <select
                      className="form-control custom-select"
                      value={mfree}
                      onChange={(e) => setMFree(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Free Tournament--
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                {msport.toUpperCase() === "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Relay Fees</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Relay Fees"
                        maxLength={10}
                        value={relayfees}
                        onChange={relayhandlefees}
                      />
                    </div>
                  </div>
                )}
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Payment Gateway Charges{" "}
                      <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={mgate}
                      onChange={(e) => setMGate(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Payment Gateway--
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                    {submitted && !mgate && (
                      <span className="errortext">
                        Payment Gateway Charges is required
                      </span>
                    )}
                  </div>
                </div> */}
                {mfree === "N" && msport.toUpperCase() !== "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Fees <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fees"
                        value={mfees}
                        onChange={handlefees}
                      />
                      {submitted && !mfees && (
                        <span className="errortext">Fees is required</span>
                      )}
                    </div>
                  </div>
                )}

                {msport.toUpperCase() !== "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        League Format <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={mleatype}
                        onChange={(e) => setMLeaque(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --Select League Format--
                        </option>
                        <option value="Roundrobin">Round-Robin</option>
                        <option value="Knockout">Knock Out</option>
                      </select>
                      {submitted && !mleatype && (
                        <span className="errortext">
                          League Format is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {mtype === "2" && localStorage.getItem("Role") !== "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={mschid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}

                {mtype === "2" && localStorage.getItem("Role") === "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={mschid}
                        options={ischlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}
                {/* && localStorage.getItem("Role") !== "7" */}
                {mtype === "3" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={mschid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}
                {/* {mtype === "3" && localStorage.getItem("Role") === "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={mschid}
                        options={ischlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !mschid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )} */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Upload Circular </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".pdf"
                      onChange={circfile}
                    />
                    {/* {submitted && !mcir &&
                      <span className='errortext'>Circular is required</span>
                    } */}
                    {cirerror !== "" && (
                      <span className="errortext">{cirerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Code <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prefix"
                      value={mprefix + mdtprefix + mcode + msuffix}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Reporting Time <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Reporting Time"
                      value={reptime}
                      onChange={(e) => setRepTime(e.target.value)}
                    />
                    {submitted && !reptime && (
                      <span className="errortext">
                        Reporting Time is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Logo <span className="errortext"> *</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={logofile}
                    />

                    {logoerror !== "" && (
                      <span className="errortext">{logoerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Tournament Location </label>
                    <textarea
                      rows={3}
                      type="text"
                      className="form-control"
                      placeholder="Tournament Location"
                      value={mloc}
                      onChange={(e) => setMLoc(e.target.value)}
                    />
                    {/* {submitted && !mloc &&
                      <span className='errortext'>Tournament Location is required</span>
                    } */}
                  </div>
                </div>
              </div>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </div>
            <div className="card-footer text-center border-top">
              <button
                type="submit"
                className="btn btn-success"
                onClick={toursubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
