import React from "react";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { Reftourdetail } from "../../../../../services/referee";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../../services/coach";
import {
  leadercreate,
  leaderlist,
  leaderupdate,
} from "../../../../../services/common";
import moment from "moment";

export const Leaderboard = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [hname, setHName] = useState("");
  const [csport, setCSport] = useState("");
  const [tsport, setTSport] = useState("");
  const [bsport, setBsport] = useState("");
  const [fsport, setFSport] = useState("");
  const [asport, setASport] = useState("");

  const [teamid, setTeamId] = useState("");

  function highedit(tid) {
    if (filterProduct.length > 0) {
      const data = filterProduct.find((item) => item.id == tid);
      if (data !== undefined) {
        setShow(true);
        setHName(data.team_house_name);
        if (data.cricket !== null) {
          setCSport(data.cricket);
        }
        if (data.tennis !== null) {
          setTSport(data.tennis);
        }
        if (data.badminton !== null) {
          setBsport(data.badminton);
        }
        if (data.football !== null) {
          setFSport(data.football);
        }
        if (data.athletics !== null) {
          setASport(data.athletics);
        }
        
        setTeamId(tid);
      }
    }
  }

  const [tname, setTName] = useState("");
  const [edate, setEDate] = useState("");

  const [show, setShow] = useState(false);
  function handleShow() {
    setErrorMsg("");
    setHName("");
    setCSport("");
    setTSport("");
    setBsport("");
    setFSport("");
    setASport("");
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setErrorMsg("");
    setHName("");
    setCSport("");
    setTSport("");
    setBsport("");
    setFSport("");
    setASport("");
  }

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in leaderboard for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentlist");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          const endDate = new Date(response.data.tournament.end_date);
          endDate.setDate(endDate.getDate() + 10);
          setEDate(moment(endDate).format("yyyy-MM-DD"));
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);

          const endDate = new Date(response.data.tournament.end_date);
          endDate.setDate(endDate.getDate() + 10);
          setEDate(moment(endDate).format("yyyy-MM-DD"));
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          const endDate = new Date(response.data.tournament.end_date);
          endDate.setDate(endDate.getDate() + 10);
          setEDate(moment(endDate).format("yyyy-MM-DD"));
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          const endDate = new Date(response.data.tournament.end_date);
          endDate.setDate(endDate.getDate() + 10);
          setEDate(moment(endDate).format("yyyy-MM-DD"));
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          const endDate = new Date(response.data.tournament.end_date);
          endDate.setDate(endDate.getDate() + 10);
          setEDate(moment(endDate).format("yyyy-MM-DD"));
        }
      });
    }
    listall();
  }, []);

  const [errorMsg, setErrorMsg] = useState("");
  function submithigh() {
    setErrorMsg("");
    if (hname !== "") {
      let ctot=0;
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("team_house_name", hname);
        
        if (csport === "") {
          temp.append("cricket", 0);
        } else {
          ctot=ctot+parseInt(csport);
          temp.append("cricket", csport);
        }

        if (tsport === "") {
          temp.append("tennis", 0);
        } else {
          ctot=ctot+parseInt(tsport);
          temp.append("tennis", tsport);
        }

        if (bsport === "") {
          temp.append("badminton", 0);
        } else {
          ctot=ctot+parseInt(bsport);
          temp.append("badminton", bsport);
        }

        if (fsport === "") {
          temp.append("football", 0);
        } else {
          ctot=ctot+parseInt(fsport);
          temp.append("football", fsport);
        }

        if (asport === "") {
          temp.append("athletics", 0);
        } else {
          ctot=ctot+parseInt(asport);
          temp.append("athletics", asport);
        }
        // parseInt(tsport) + 
        
        temp.append("total", ctot);
        if (teamid == "") {
          temp.append("leaderboard_end_date", edate);
          dotracking(
            "add button in add leaderboard for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.leadheading
          );
          leadercreate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            } else {
              setErrorMsg(response.message);
            }
          });
        } else {
          temp.append("leader_board_id", teamid);
          dotracking(
            "update button in add leaderboard for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.leadheading
          );
          leaderupdate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            } else {
              setErrorMsg(response.message);
            }
          });
        }
      
    } else {
      setErrorMsg("Please Enter Team House Name");
    }
  }

  function listall() {
    leaderlist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.leaderboard_list);
      }
    });
  }

  const handleCricket = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setCSport(e.target.value);
    }
  };

  const handleTennis = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setTSport(e.target.value);
    }
  };

  const handleBadminton = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setBsport(e.target.value);
    }
  };

  const handleFootball = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFSport(e.target.value);
    }
  };

  const handleAthletics = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setASport(e.target.value);
    }
  };

  

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Leaderboard</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Leaderboard
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="text-left">
                    <h3>{tname}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "7") && (
                      <a className="btn btn-success me-2" onClick={handleShow}>
                        <i className="fe fe-plus"></i> Add Leaderboard
                      </a>
                    )}

                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {teamid == "" && <h4> Add Leaderboard</h4>}
                  {teamid != "" && <h4> Leaderboard Details</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Team House Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Team House Name"
                          className="form-control"
                          value={hname}
                          onChange={(e) => setHName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Cricket Points
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="Cricket Points"
                          className="form-control"
                          value={csport}
                          onChange={handleCricket}
                        />
                      </div>
                    </Col>

                    {/*<Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Tennis Points
                        </label>
                        <input
                          type="text"
                          placeholder="Tennis Points"
                          className="form-control"
                          value={tsport}
                          onChange={handleTennis}
                        />
                      </div>
                    </Col>*/}

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Badminton Points
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="Badminton Points"
                          className="form-control"
                          value={bsport}
                          onChange={handleBadminton}
                        />
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Football Points
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="Football Points"
                          className="form-control"
                          value={fsport}
                          onChange={handleFootball}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Athletics Points
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="Athletics Points"
                          className="form-control"
                          value={asport}
                          onChange={handleAthletics}
                        />
                      </div>
                    </Col>
                    {/* <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Total Points <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Total Points"
                          className="form-control"
                          value={totsport}
                          onChange={handleTotal}
                        />
                      </div>
                    </Col> */}
                  </Row>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {teamid == "" && (
                    <a className="btn btn-success" onClick={submithigh}>
                      Add
                    </a>
                  )}

                  {teamid != "" && (
                    <a className="btn btn-success" onClick={submithigh}>
                      Update
                    </a>
                  )}
                </Modal.Footer>
              </Modal>

              <div className="table-responsive">
                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "7") && (
                  <table className="sp-league-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th className="text-center">Team</th>
                        <th className="text-center">Cricket</th>
                        {/* <th className="text-center">Tennis</th> */}
                        <th className="text-center">Athletics</th>
                        <th className="text-center">Badminton</th>
                        <th className="text-center">Football</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterProduct.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              role="row"
                              key={j}
                            >
                              <td className="data-pct">
                                {item.team_house_name}
                              </td>
                              <td className="data-pct">{item.cricket}</td>
                              {/* <td className="data-pct">{item.tennis}</td> */}
                              <td className="data-pct">{item.athletics}</td>
                              <td className="data-pct">{item.badminton}</td>
                              <td className="data-pct">{item.football}</td>
                              <td className="data-pct">{item.total}</td>
                              <td className="data-pct">
                                <a
                                  onClick={() => highedit(item.id)}
                                  title="Edit Leaderboard"
                                  className="btn-sm btn-secondary me-2"
                                >
                                  <i className="fa fa-edit fa-lg"></i>
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {(localStorage.getItem("Role") === "3" ||
                  localStorage.getItem("Role") === "4" ||
                  localStorage.getItem("Role") === "5" ||
                  localStorage.getItem("Role") === "6") && (
                  <table className="sp-league-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th className="text-center">Team</th>
                        <th className="text-center">Cricket</th>
                        {/* <th className="text-center">Tennis</th> */}
                        <th className="text-center">Athletics</th>
                        <th className="text-center">Badminton</th>
                        <th className="text-center">Football</th>
                        <th className="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterProduct.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              role="row"
                              key={j}
                            >
                              <td className="data-pct">
                                {item.team_house_name}
                              </td>
                              <td className="data-pct">{item.cricket}</td>
                              {/* <td className="data-pct">{item.tennis}</td> */}
                              <td className="data-pct">{item.athletics}</td>
                              <td className="data-pct">{item.badminton}</td>
                              <td className="data-pct">{item.football}</td>
                              <td className="data-pct">{item.total}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
