import { AgGridReact } from "ag-grid-react";
import React, { Fragment, useMemo } from "react";
import { useState } from "react";
import { allplayerlist } from "../../../../services/superadmin";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const BlockedPlayers = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const sorting = useMemo(() => {
    return ['asc', 'desc',];
  }, []);

  const columnDefs = [
    {
      headerName: "Username",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Last Name",
    //   width: 170,
    //   field: "last_name",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return moment(params.value).format("DD-MM-yyyy")
        }
      },
    },
    {
      headerName: "Mobile",
      width: 160,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 160,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    // {
    //   headerName: "Registered Date",
    //   width: 150,
    //   field: "registerdate",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     if (params.value !== null) {
    //       return moment(params.value).format("DD-MM-yyyy")
    //     }
    //   },
    // },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className='text-center'>
            <a onClick={()=>viewdet(params.value,params)} title="View Player"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>

          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  function viewdet(vid,vdata){
    dotracking("view icon button in blocked player list for " + vdata.data.first_name + " (" + vdata.data.username + " - " + vid + ") $$ " + trackobj.playerdetheading);
    navigate("/superadmin/viewplayer/" + vid)
  }
  useEffect(() => {
    allplayerlist(3, 6).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data)
      }
    })
  }, [])
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Blocked Players</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Players</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Blocked Players
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">

                <div
                  className="ag-theme-alpine ag-style"
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
