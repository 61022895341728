import React, { Fragment, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import {
  Playerprofiledetails,
  Playerprofileupdate,
} from "../../../services/player";
import {
  classlist,
  schoollistmaster,
  sportslist,
} from "../../../services/master";
import moment from "moment";
import { toast } from "react-toastify";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import { useNavigate } from "react-router-dom";

export const PlayerProfile = () => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [school, setSchool] = useState("");
  const [slist, setSList] = useState([]);

  const [sclist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [curdate, setCurDate] = useState("");

  const [splist, setSportList] = useState([]);
  const [stusport, setStuSport] = useState("");
  const [stupos, setStuPos] = useState("");

  const [stufq, setStuFQ] = useState("");
  const [stunfq, setStuNFQ] = useState("0.73");

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    var a = new Date().getFullYear() - 7;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));

    classlist().then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });

    sportslist().then((response) => {
      if (response.status === true) {
        setSportList(response.data);
      }
    });

    schoollistmaster().then((response) => {
      if (response.status === true) {
        setSList(response.data.details);
      }
    });

    viewlist();
  }, []);

  function viewlist() {
    Playerprofiledetails(localStorage.getItem("Name")).then((response) => {
      if (response.status === true) {
        if (response.data.athletelist !== null) {
          setFName(response.data.athletelist.first_name);
          setLName(response.data.athletelist.last_name);
          setDOB(response.data.athletelist.date_of_birth);
          setStuClass(response.data.athletelist.class);
          setSchool(response.data.athletelist.school_id);
          setStuSec(response.data.athletelist.section);
          setGender(response.data.athletelist.gender);
          setEmail(response.data.athletelist.email_id);
          setMobNo(response.data.athletelist.mobile_no);
          if (response.data.athletelist.photograph !== null) {
            setPhotoUrl(
              response.data.photo_path + response.data.athletelist.photograph
            );
          }
          if (response.data.athletelist.sportsposition !== null) {
            setStuPos(response.data.athletelist.sportsposition);
          }

          if (response.data.athletelist.sports_typeid !== 0) {
            setStuSport(response.data.athletelist.sports_typeid);
          }
          if (response.data.athletelist.fq !== null) {
            setStuFQ(response.data.athletelist.fq);
          }

          setStuNFQ(response.data.athletelist.fq_bench_mark);
        }
      }
    });
  }
  const [photoerror, setPhotoError] = useState("");
  const [photourl, setPhotoUrl] = useState("");
  const [photo, setPhoto] = useState("");

  function photofile(event) {
    setPhotoError("");
    setPhoto("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setPhoto(event.target.files[0]);
      } else {
        setPhotoError("Photograph should be jpg, jpeg, or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoError("Photograph size should be less than 5MB");
      }
    }
  }

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/users.png");
    ev.target.onerror = null;
  }
  const navigate = useNavigate();
  function perflist() {
    dotracking(
      "overall performance button in profile " +
        fname +
        +" (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.overperfheading
    );
    navigate("/player/overallperformanceplayer");
  }

  function perftourlist() {
    dotracking(
      "tournament wise performance button in profile " +
        fname +
        +" (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.tourperfheading
    );
    navigate("/player/viewperformanceplayer");
  }

  function subbmitprofile() {
    setErrorMsg("");
    setSubmitted(true);
    if (
      fname !== "" &&
      gender !== "" &&
      dob !== "" &&
      mobno !== "" &&
      email !== "" &&
      school !== "" &&
      stuclass !== "" &&
      stusec !== ""
    ) {
      const temp = new FormData();
      temp.append("user_id", localStorage.getItem("UID"));
      temp.append("first_name", fname);
      temp.append("date_of_birth", dob);
      temp.append("gender", gender);
      temp.append("email_id", email);
      temp.append("mobile_no", mobno);
      temp.append("school_id", school);
      temp.append("class", stuclass);
      temp.append("section", stusec);
      temp.append("photograph", photo);
      temp.append("position", stupos);
      temp.append("sports_pos_id", 0);
      temp.append("sports_typeid", stusport);
      if (stusport !== "") {
        const sname = splist.find(
          (item) => item.sports_cat_uid === parseInt(stusport)
        );
        temp.append("sports", sname.sports_category);
      } else {
        temp.append("sports", "");
      }

      temp.append("fq", stufq);
      if (stunfq !== null) {
        temp.append("fq_bench_mark", stunfq);
      } else {
        temp.append("fq_bench_mark", "0.73");
      }
      dotracking(
        "update profile button in profile " +
          fname +
          +" (" +
          localStorage.getItem("UID") +
          ") $$ " +
          trackobj.profileheading
      );

      Playerprofileupdate(temp).then((response) => {
        if (response.status === true) {
          setSubmitted(false);
          localStorage.setItem("User", fname);
          viewlist();
          toast.success(response.message);
        } else {
          setErrorMsg(response.message);
        }
      });
    }
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Profile</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Profile</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Profile
            </li>
          </ol>
        </div>

        <div className="row" id="user-profile">
          <div className="col-lg-12">
            <div className="card profilecard">
              <div className="card-body">
                <div className="wideget-user">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 align-self-center">
                      <div className="wideget-user-desc d-flex">
                        <div className="wideget-user-img">
                          <img
                            src={photourl}
                            alt="img"
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                        <div className="user-wrap align-self-center">
                          <h3>{localStorage.getItem("User")}</h3>
                          <h3 className="mb-0 text-muted">
                            UID - {localStorage.getItem("Name")}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 align-self-center">
                      <div className="wideget-user-info">
                        <div className="wideget-user-warap">
                          <div className="wideget-user-warap-l">
                            <h4 className="text-danger">0</h4>
                            <p className="mb-0">Tournaments</p>
                          </div>
                          <div className="wideget-user-warap-r">
                            <h4 className="text-danger">0</h4>
                            <p className="mb-0">Achievements</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">Personal Detail</h3>
                {/* <div className="card-options">
                                    <button id="add__new__list" type="button" className="btn btn-md btn-info-gradient"><i className="fas fa-edit"></i> Edit Details</button>
                                </div> */}
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          First Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={fname}
                          onChange={(e) => setFName(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !fname && (
                          <span className="errortext">
                            First Name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Gender <span className="errortext"> *</span>
                        </label>
                        <select
                          value={gender}
                          className="form-control"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Gender ---
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {submitted && !gender && (
                          <span className="errortext">Gender is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Date of Birth <span className="errortext"> *</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={dob}
                          max={curdate}
                          onChange={(e) => setDOB(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                        {submitted && !dob && (
                          <span className="errortext">
                            Date of Birth is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Contact Number <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact Number"
                          value={mobno}
                          onChange={handleChange}
                          maxLength={10}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !mobno && (
                          <span className="errortext">
                            Contact Number is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email Address <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !email && (
                          <span className="errortext">
                            Email Address is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">School Detail</h3>
                {/* <div className="card-options">
                                    <button id="add__new__list" type="button" className="btn btn-md btn-info-gradient"><i className="fas fa-edit"></i> Edit Details</button>
                                </div> */}
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          School <span className="errortext"> *</span>
                        </label>
                        <select
                          value={school}
                          className="form-control"
                          onChange={(e) => setSchool(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select School ---
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.id} key={i}>
                                {data.school_name}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !school && (
                          <span className="errortext">School is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Class <span className="errortext"> *</span>
                        </label>
                        <select
                          value={stuclass}
                          className="form-control"
                          onChange={(e) => setStuClass(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Class ---
                          </option>
                          {sclist.map((data, i) => {
                            return (
                              <option value={data.id} key={i}>
                                {data.class}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !stuclass && (
                          <span className="errortext">Class is required</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Section <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Section"
                          value={stusec}
                          onChange={(e) =>
                            setStuSec(e.target.value.toUpperCase())
                          }
                        />

                        {submitted && !stusec && (
                          <span className="errortext">Section is required</span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">City <span className='errortext'> *</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Section" value={stusec}
                                                    onChange={(e) => setStuSec(e.target.value.toUpperCase())}
                                                />

                                                {submitted && !stusec &&
                                                    <span className='text-danger'>Section is required</span>
                                                }
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">Sports Detail</h3>
                {/* <div className="card-options">
                                    <button id="add__new__list" type="button" className="btn btn-md btn-info-gradient"><i className="fas fa-edit"></i> Edit Details</button>
                                </div> */}
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Sports </label>
                        <select
                          value={stusport}
                          className="form-control"
                          onChange={(e) => setStuSport(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Sports ---
                          </option>
                          {splist.map((data, i) => {
                            return (
                              <option value={data.sports_cat_uid} key={i}>
                                {data.sports_category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Position </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Position"
                          value={stupos}
                          onChange={(e) => setStuPos(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">Fitness Detail</h3>
                {/* <div className="card-options">
                                        <button id="add__new__list" type="button" className="btn btn-md btn-info-gradient"><i className="fas fa-edit"></i> Edit Details</button>
                                    </div> */}
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Your FQ </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your FQ"
                          value={stufq}
                          onChange={(e) => setStuFQ(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {new Date().getFullYear()} National FQ Benchmark{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter National FQ Benchmark"
                          value={stunfq}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="card-title font-weight-bold">File Uploads</h3>
              </div>
              <div className="card-body">
                <div className="border-0">
                  <div className="row tournamentitem">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Photograph </label>

                        <img
                          className="profile_img mb-2"
                          src={photourl}
                          height="160"
                          alt="photo"
                          onError={(e) => errorhandle(e)}
                        />
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg,.jpeg,.png"
                          onChange={photofile}
                        />
                        {photoerror !== "" && (
                          <span className="errortext">{photoerror}</span>
                        )}
                        {errorMsg !== "" && (
                          <span className="errortext">{errorMsg}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={perflist}
              >
                Overall Performance
              </button>

              <button
                type="submit"
                className="btn btn-secondary me-2"
                onClick={perftourlist}
              >
                Tournament Wise Performance
              </button>

              <button
                type="submit"
                className="btn btn-success"
                onClick={subbmitprofile}
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
