import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function sendhighlights(id) {
  try {
    return await axios
      .get(`${baseUrl}/send-highlights-meet-mail/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function sendphotos(id) {
  try {
    return await axios
      .get(`${baseUrl}/send-galleryphotos-meet-mail/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function senddigitalcertificates(id) {
  try {
    return await axios
      .get(`${baseUrl}/send-download-certificate-mail/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function sendschoolheadtour(id) {
  try {
    return await axios
      .get(`${baseUrl}/notification-schlhead-aftermeet-mail/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function sendnewtournoti(id) {
    try {
      return await axios
        .get(`${baseUrl}/send-meet-notification-mail/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function sendremaindertour(id) {
    try {
      return await axios
        .get(`${baseUrl}/remainder-of-meet/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function sendschedulartour(id) {
    try {
      return await axios
        .get(`${baseUrl}/tournament-schedule-ready/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function sendschheadhighlights(id) {
    try {
      return await axios
        .get(`${baseUrl}/send-highlights-meet-mail-schlhead/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function sendschheadphotos(id) {
    try {
      return await axios
        .get(`${baseUrl}/send-galleryphotos-meet-mail-schlhead/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  

  export async function sendschheadremainder(id) {
    try {
      return await axios
        .get(`${baseUrl}/remainder-of-meet-schlhead/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function sendschheadschedular(id) {
    try {
      return await axios
        .get(`${baseUrl}/tournament-schedule-ready-schlhead/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function sendschheadtournamentlink(id) {
    try {
      return await axios
        .get(`${baseUrl}/send-school-head-cred-for-inter-school/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  function catcherror(error) {
  if (error.response.status === 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
