import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";

import ImageUrl from "../../../../ImageUrl";
import {
  multirolesdetails,
  schoolmanagerlist,
} from "../../../../services/superadmin";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ViewCityManager = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [uname, setUser] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(false);
  const [ishow, setImage] = useState(false);
  const [pass, setPass] = useState("");

  const [slist, setSList] = useState([]);
  const [flist, setFList] = useState([]);
  const [glist, setGList] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      multirolesdetails(3, id).then((response) => {
        if (response.status === true) {
          setFName(response.data.details.first_name);
          setLName(response.data.details.last_name);
          if (response.data.details.dob !== null) {
            setDOB(moment(response.data.details.dob).format("DD-MM-yyyy"));
          }
          setPass(response.data.password);

          setGender(response.data.details.gender);
          setEmail(response.data.details.email_id);
          setMobNo(response.data.details.mobile_no);
          setUser(response.data.details.username);
          setAddress(response.data.details.address);
          setState(response.data.details.statename);
          setDistrict(response.data.details.districtname);
          setCity(response.data.details.cityname);
          setPincode(response.data.details.pincode);
          if (response.data.details.photograph !== null) {
            setPhoto(
              response.data.photo_path + response.data.details.photograph
            );
          }
        }
      });

      schoolmanagerlist(id).then((response) => {
        if (response.status === true) {
          setSList(response.data.school_list);
          setFList(response.data.funder_list);
          setGList(response.data.group_of_schools_list);
        }
      });
    }
  }, []);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function backpage() {
    dotracking(
      "back button in view city manager for " +
        fname +
        " " +
        lname +
        " (" +
        email +
        " - " +
        id +
        ") $$ " +
        trackobj.crmlistheading
    );
    navigate("/superadmin/cityregionallist");
  }

  function editpage() {
    dotracking(
      "edit button in view city manager for " +
        fname +
        " " +
        lname +
        " (" +
        email +
        " - " +
        id +
        ") $$ " +
        trackobj.crmeditheading
    );
    navigate("/superadmin/cityregionaldetail/" + id);
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Manager Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Manager</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Manager Details
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <h4>Primary Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">First Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{fname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Last Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{lname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{dob}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Contact Number</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mobno}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Email Address</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Username</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{uname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {localStorage.getItem("Role") === "1" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Password</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pass}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <hr />
              <h4>Personal Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{state}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{district}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">City</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{city}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4>Photograph</h4>
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="link-btn">
                        <a>
                          <img
                            className="profile_img"
                            src={photo}
                            height="160"
                            alt="photo"
                            onClick={(e) => ImageShow(photo)}
                            onError={(e) => errorhandle(e)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4>School List</h4>
              <div className="row tournamentitem">
                <div className="table-responsive mt-2">
                  <table className="sp-league-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th>School</th>
                        <th>CHID</th>
                        <th>Type</th>
                        <th>Funder</th>
                        <th>Group</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slist.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              key={j}
                            >
                              <td className="data-pct">{item.school_name}</td>
                              <td className="data-pct">
                                {item.school_unique_no}
                              </td>
                              <td className="data-pct">{item.school_type}</td>
                              <td className="data-pct">{item.school_funder}</td>
                              <td className="data-pct">
                                {item.group_of_school_name}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <hr />
              <h4>Funder List</h4>
              <div className="row tournamentitem">
                <div className="table-responsive mt-2">
                  <table className="sp-champion-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th>Funder</th>
                        <th>Description</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flist.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              key={j}
                            >
                              <td className="data-pct">{item.funder_name}</td>
                              <td className="data-pct">
                                {item.funder_description}
                              </td>
                              <td className="data-pct">{item.email}</td>
                              <td className="data-pct">{item.mobile}</td>
                              <td className="data-pct">{item.address}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <hr />
              <h4>Group of Schools List</h4>
              <div className="row tournamentitem">
                <div className="table-responsive mt-2">
                  <table className="sp-group-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr role="row">
                        <th>Group</th>
                        <th>Description</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {glist.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              key={j}
                            >
                              <td className="data-pct">{item.group_of_school_name}</td>
                              <td className="data-pct">
                                {item.group_of_school_description}
                              </td>
                              <td className="data-pct">{item.email}</td>
                              <td className="data-pct">{item.mobile}</td>
                              <td className="data-pct">{item.address}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div className="card-footer text-right border-top">
                <a className="btn btn-secondary me-2" onClick={editpage}>
                  Edit
                </a>
                <a className="btn btn-gray me-2" onClick={backpage}>
                  Back
                </a>
              </div>
            </div>
          </form>
          <Modal show={ishow} onHide={ImageClose}>
            <Modal.Body>
              <img
                className="profile_img prof-img"
                src={file}
                height="160"
                alt="photo"
                onError={(e) => errorhandle(e)}
              />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-secondary text-right"
                onClick={ImageClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
