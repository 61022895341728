import React, { useEffect, useState } from "react";
import {
  poollisttour,
  scheduledateandtime,
  schedulelist,
  schedulenoknockdelete,
  schedulenoknocklist,
  schedulenoknockoutcreate,
  schedulequarfinalcreate,
  schedulequarfinallist,
  schedulequarselectedlist,
  schedulesetpoints,
  schedulewinner,
  semifinalsentriesdelete,
  tourcategorydetail,
  tourteamlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { useNavigate, useParams } from "react-router-dom";
import {
  crmtourviewdetail,
  teamlistcityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import exportUrl from "../../../../exportUtl";
import { Refteamlist, Reftourdetail } from "../../../../services/referee";
import { sendschedulartour } from "../../../../services/communication";
import Select from "react-select";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import Swal from "sweetalert2";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail, teamlistcoach } from "../../../../services/coach";
import { tourdetails } from "../../../../services/register";

export const ScheduleAllocate = () => {
  const { id } = useParams();
  const { eid } = useParams();

  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);
  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [ttype, setTType] = useState("");

  const [tsport, setMSport] = useState("");
  const [mindate, setMindate] = useState("");
  const [maxdate, setMaxdate] = useState("");
  const [catname, setCatname] = useState("");

  const [datehr, setHourlist] = useState([]);
  const [datesec, setSeclist] = useState([]);

  const [DHour, setHour] = useState("");
  const [DSec, setSecond] = useState("");
  const [DFor, setFormat] = useState("");
  const [SFormat, setSFormat] = useState("");

  let arr = [];
  useEffect(() => {
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          setMSport(response.data.tournament.sports_type);
          setMindate(
            moment(response.data.tournament.start_date)
              .subtract(5, "days")
              .format("yyyy-MM-DD")
          );

          setMaxdate(
            moment(response.data.tournament.end_date).format("yyyy-MM-DD")
          );
          listall(response.data.tournament.sports_type);
        }
      });
    }

    var hr = [];
    var add = "";
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        add = String(i).padStart(2, "0");
      } else {
        add = i;
      }
      hr.push(add);
    }
    setHourlist(hr);

    var sec = [];
    var zero = "";
    for (let j = 0; j <= 59; j++) {
      if (j < 10) {
        zero = String(j).padStart(2, "0");
      } else {
        zero = j;
      }
      sec.push(zero);
    }
    setSeclist(sec);
  }, []);

  function backlist() {
    dotracking(
      "back button in schedule allocate list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.poollistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/categorylist/" + id );
    }
  }

  function fixturelist() {
    dotracking(
      "overall fixture list button in schedule allocate list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.fixlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/schedulelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/schedulelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/schedulelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/schedulelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/schedulelist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/schedulelist/" + id + "/" + eid);
    }
  }
  const [mid, setMatchId] = useState("");
  const [mdate, setMatchDate] = useState("");
  const [mteam, setMatchTeam] = useState("");

  const [dateshow, setDateShow] = useState(false);

  function handleDelete(fid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in schedule allocate list for (" +
            fid +
            ") fixture in " +
            catname +
            " (" +
            eid +
            ") category for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.fixallocateheading
        );

        semifinalsentriesdelete(fid, localStorage.getItem("UID")).then(
          (response) => {
            if (response.status === true) {
              listall(tsport);
              toast.success(response.message);
            }
          }
        );
      }
    });
  }

  function handleDate(fid, fdate, fteam) {
    setMatchId(fid);
    if (fdate !== "") {
      setMatchDate(moment(fdate).format("yyyy-MM-DD"));
    } else {
      setMatchDate("");
    }
    setMatchTeam(fteam);
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
    setDateShow(true);
  }

  function handleDClose() {
    setMatchTeam("");
    setMatchDate("");
    setMatchId("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
    setDateShow(false);
  }

  const [t1name, setT1Name] = useState("");
  const [t1id, setT1ID] = useState("");
  const [t2name, setT2Name] = useState("");
  const [t2id, setT2ID] = useState("");
  const [winshow, setWinShow] = useState(false);

  const [wname, setWName] = useState("");
  const [t1score, setT1Score] = useState("");
  const [t2score, setT2Score] = useState("");

  function handleWinner(fid, t1, tid, t2, eid) {
    setMatchId(fid);
    setT1Name(t1);
    setT1ID(tid);
    setT2Name(t2);
    setT2ID(eid);
    setErrMsg("");
    setWName("");
    setT1Score("");
    setT2Score("");
    setWinShow(true);
  }

  function handleWClose() {
    setWinShow(false);
    setT1Name("");
    setT1ID("");
    setT2Name("");
    setT2ID("");
    setErrMsg("");
    setMatchId();
    setWName("");
    setT1Score("");
    setT2Score("");
  }
  const [volshow, setVolShow] = useState(false);
  const [totalset, setTotalSet] = useState(0);
  const [t1set1, setT1Set1] = useState(0);
  const [t1set2, setT1Set2] = useState(0);
  const [t1set3, setT1Set3] = useState(0);
  const [t1set4, setT1Set4] = useState(0);
  const [t1set5, setT1Set5] = useState(0);
  const [t1set6, setT1Set6] = useState(0);
  const [t1set7, setT1Set7] = useState(0);
  const [t1set8, setT1Set8] = useState(0);
  const [t1set9, setT1Set9] = useState(0);

  const [t2set1, setT2Set1] = useState(0);
  const [t2set2, setT2Set2] = useState(0);
  const [t2set3, setT2Set3] = useState(0);
  const [t2set4, setT2Set4] = useState(0);
  const [t2set5, setT2Set5] = useState(0);
  const [t2set6, setT2Set6] = useState(0);
  const [t2set7, setT2Set7] = useState(0);
  const [t2set8, setT2Set8] = useState(0);
  const [t2set9, setT2Set9] = useState(0);
  function handleVolley(fid, t1, tid, t2, eid, vsets, vtot, type) {
    let sets = "";
    let t1sets = "";
    let t2sets = "";
    setTotalSet(vtot);
    if (type === 4) {
      if (finalProduct.length > 0) {
        sets = finalProduct.find((set) => set.id === parseInt(fid));
        if (sets !== undefined) {
          t1sets = sets.team1sets.find((set) => set.id === parseInt(fid));
          t2sets = sets.team2sets.find((set) => set.id === parseInt(fid));
        }
      }
    }
    if (type === 3) {
      if (semiProduct.length > 0) {
        sets = semiProduct.find((set) => set.id === parseInt(fid));
        if (sets !== undefined) {
          t1sets = sets.team1sets.find((set) => set.id === parseInt(fid));
          t2sets = sets.team2sets.find((set) => set.id === parseInt(fid));
        }
      }
    }
    if (type === 2) {
      if (quarProduct.length > 0) {
        sets = quarProduct.find((set) => set.id === parseInt(fid));
        if (sets !== undefined) {
          t1sets = sets.team1sets.find((set) => set.id === parseInt(fid));
          t2sets = sets.team2sets.find((set) => set.id === parseInt(fid));
        }
      }
    }
    if (type === 1) {
      sets = filterProduct.find((set) => set.meet_pool_id === parseInt(vsets));
      if (sets !== undefined) {
        t1sets = sets.team1sets.find((set) => set.id === parseInt(fid));
        t2sets = sets.team2sets.find((set) => set.id === parseInt(fid));
      }
    }

    if (t1sets !== "") {
      setT1Set1(t1sets.team1_set1);
      setT1Set2(t1sets.team1_set2);
      setT1Set3(t1sets.team1_set3);
      setT1Set4(t1sets.team1_set4);
      setT1Set5(t1sets.team1_set5);
      setT1Set6(t1sets.team1_set6);
      setT1Set7(t1sets.team1_set7);
      setT1Set8(t1sets.team1_set8);
      setT1Set9(t1sets.team1_set9);
    }
    if (t2sets !== "") {
      setT2Set1(t2sets.team2_set1);
      setT2Set2(t2sets.team2_set2);
      setT2Set3(t2sets.team2_set3);
      setT2Set4(t2sets.team2_set4);
      setT2Set5(t2sets.team2_set5);
      setT2Set6(t2sets.team2_set6);
      setT2Set7(t2sets.team2_set7);
      setT2Set8(t2sets.team2_set8);
      setT2Set9(t2sets.team2_set9);
    }
    setMatchId(fid);
    setT1Name(t1);
    setT1ID(tid);
    setT2Name(t2);
    setT2ID(eid);
    setWName("");
    setErrMsg("");
    setVolShow(true);
  }

  function handleVClose() {
    setMatchId("");
    setT1Name("");
    setT1ID("");
    setT2Name("");
    setT2ID("");
    setErrMsg("");
    setWName("");
    setTotalSet(0);
    setT1Set1(0);
    setT1Set2(0);
    setT1Set3(0);
    setT1Set4(0);
    setT1Set5(0);
    setT1Set6(0);
    setT1Set7(0);
    setT1Set8(0);
    setT1Set9(0);
    setT2Set1(0);
    setT2Set2(0);
    setT2Set3(0);
    setT2Set4(0);
    setT2Set5(0);
    setT2Set6(0);
    setT2Set7(0);
    setT2Set8(0);
    setT2Set9(0);
    setVolShow(false);
  }

  function handleSets(e, team, set) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (team === 1) {
        if (set === 1) {
          setT1Set1(e.target.value);
        } else if (set === 2) {
          setT1Set2(e.target.value);
        } else if (set === 3) {
          setT1Set3(e.target.value);
        } else if (set === 4) {
          setT1Set4(e.target.value);
        } else if (set === 5) {
          setT1Set5(e.target.value);
        } else if (set === 6) {
          setT1Set6(e.target.value);
        } else if (set === 7) {
          setT1Set7(e.target.value);
        } else if (set === 8) {
          setT1Set8(e.target.value);
        } else if (set === 9) {
          setT1Set9(e.target.value);
        }
      } else if (team === 2) {
        if (set === 1) {
          setT2Set1(e.target.value);
        } else if (set === 2) {
          setT2Set2(e.target.value);
        } else if (set === 3) {
          setT2Set3(e.target.value);
        } else if (set === 4) {
          setT2Set4(e.target.value);
        } else if (set === 5) {
          setT2Set5(e.target.value);
        } else if (set === 6) {
          setT2Set6(e.target.value);
        } else if (set === 7) {
          setT2Set7(e.target.value);
        } else if (set === 8) {
          setT2Set8(e.target.value);
        } else if (set === 9) {
          setT2Set9(e.target.value);
        }
      }
    }
  }

  function addsets(e) {
    e.preventDefault();
    var valid = "";
    if (totalset >= 1) {
      if (t1set1 !== "" && t2set1 !== "") {
      } else {
        valid = "1";
        setErrMsg("Please Enter " + SFormat + " 1 Score");
      }
      if (t1set1 !== "" && t2set1 !== "") {
      } else {
        valid = "1";
        setErrMsg("Please Enter " + SFormat + " 1 Score");
      }
    }
    if (valid === "") {
      if (totalset >= 2) {
        if (t1set2 !== "" && t2set2 !== "") {
        } else {
          valid = "2";
          setErrMsg("Please Enter " + SFormat + " 2 Score");
        }
        if (t1set2 !== "" && t2set2 !== "") {
        } else {
          valid = "2";
          setErrMsg("Please Enter " + SFormat + " 2 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 3) {
        if (t1set3 !== "" && t2set3 !== "") {
        } else {
          valid = "3";
          setErrMsg("Please Enter " + SFormat + " 3 Score");
        }
        if (t1set3 !== "" && t2set3 !== "") {
        } else {
          valid = "3";
          setErrMsg("Please Enter " + SFormat + " 3 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 4) {
        if (t1set4 !== "" && t2set4 !== "") {
        } else {
          valid = "4";
          setErrMsg("Please Enter " + SFormat + " 4 Score");
        }
        if (t1set4 !== "" && t2set4 !== "") {
        } else {
          valid = "4";
          setErrMsg("Please Enter " + SFormat + " 4 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 5) {
        if (t1set5 !== "" && t2set5 !== "") {
        } else {
          valid = "5";
          setErrMsg("Please Enter " + SFormat + " 5 Score");
        }
        if (t1set5 !== "" && t2set5 !== "") {
        } else {
          valid = "5";
          setErrMsg("Please Enter " + SFormat + " 5 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 6) {
        if (t1set6 !== "" && t2set6 !== "") {
        } else {
          valid = "6";
          setErrMsg("Please Enter " + SFormat + " 6 Score");
        }
        if (t1set6 !== "" && t2set6 !== "") {
        } else {
          valid = "6";
          setErrMsg("Please Enter " + SFormat + " 6 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 7) {
        if (t1set7 !== "" && t2set7 !== "") {
        } else {
          valid = "7";
          setErrMsg("Please Enter " + SFormat + " 7 Score");
        }
        if (t1set7 !== "" && t2set7 !== "") {
        } else {
          valid = "7";
          setErrMsg("Please Enter " + SFormat + " 7 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset >= 8) {
        if (t1set8 !== "" && t2set8 !== "") {
        } else {
          valid = "8";
          setErrMsg("Please Enter " + SFormat + " 8 Score");
        }
        if (t1set8 !== "" && t2set8 !== "") {
        } else {
          valid = "8";
          setErrMsg("Please Enter " + SFormat + " 8 Score");
        }
      }
    }

    if (valid === "") {
      if (totalset === 9) {
        if (t1set9 !== "" && t2set9 !== "") {
        } else {
          valid = "9";
          setErrMsg("Please Enter " + SFormat + " 9 Score");
        }
        if (t1set9 !== "" && t2set9 !== "") {
        } else {
          valid = "9";
          setErrMsg("Please Enter " + SFormat + " 9 Score");
        }
      }
    }

    if (valid === "") {
      const temp = new FormData();
      temp.append("match_id", mid);
      temp.append("team1_id", t1id);
      temp.append("team1_name", t1name);
      temp.append("team2_id", t2id);
      temp.append("team2_name", t2name);
      temp.append("team1_set1", t1set1);
      temp.append("team2_set1", t2set1);
      temp.append("team1_set2", t1set2);
      temp.append("team2_set2", t2set2);
      temp.append("team1_set3", t1set3);
      temp.append("team2_set3", t2set3);
      temp.append("team1_set4", t1set4);
      temp.append("team2_set4", t2set4);
      temp.append("team1_set5", t1set5);
      temp.append("team2_set5", t2set5);
      temp.append("team1_set6", t1set6);
      temp.append("team2_set6", t2set6);
      temp.append("team1_set7", t1set7);
      temp.append("team2_set7", t2set7);
      temp.append("team1_set8", t1set8);
      temp.append("team2_set8", t2set8);
      temp.append("team1_set9", t1set9);
      temp.append("team2_set9", t2set9);
      dotracking(
        "submit button in sets for match " +
          mid +
          " $$ " +
          trackobj.fixallocateheading
      );
      schedulesetpoints(temp).then((response) => {
        if (response.status === true) {
          handleVClose();
          listall(tsport);
          toast.success(response.message);
        }
      });
    }
  }
  function Selectteam(e) {
    setWName(e.target.value);
  }

  function handleT1Score(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setT1Score(e.target.value);
    }
  }

  function handleT2Score(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setT2Score(e.target.value);
    }
  }
  function addwinner() {
    // if (wname !== "") {
    if (t1score !== "") {
      if (t2score !== "") {
        const temp = new FormData();
        temp.append("match_id", mid);
        // wname === "1"
        if (t1score === t2score) {
          temp.append("winner_team_id", "0");
          temp.append("winner_team_name", "DRAW");
        } else if (t1score > t2score) {
          temp.append("winner_team_id", t1id);
          temp.append("winner_team_name", t1name);
          temp.append("runner_team_id", t2id);
          temp.append("runner_team_name", t2name);
        } else if (t1score < t2score) {
          temp.append("winner_team_id", t2id);
          temp.append("winner_team_name", t2name);
          temp.append("runner_team_id", t1id);
          temp.append("runner_team_name", t1name);
        }

        temp.append("team1_score", t1score);
        temp.append("team2_score", t2score);
        dotracking(
          "(winner and score) add button in schedule allocate list for " +
            t1name +
            "(" +
            t1id +
            ") and " +
            t2name +
            "(" +
            t2id +
            ") in " +
            catname +
            " (" +
            eid +
            ") category for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.fixallocateheading
        );
        schedulewinner(temp).then((response) => {
          if (response.status === true) {
            handleWClose();
            listall(tsport);
            toast.success(response.message);
          }
        });
      } else {
        setErrMsg("Please Select the " + t2name + " Score");
      }
    } else {
      setErrMsg("Please Select the " + t1name + " Score");
    }
    // } else {
    //   setErrMsg("Please Select the Winner Team");
    // }
  }

  const [quarProduct, setQuarProduct] = useState([]);
  const [semiProduct, setSemiProduct] = useState([]);
  const [finalProduct, setFinalProduct] = useState([]);
  const [ofinalProduct, setOFinalProduct] = useState([]);

  const [qualchk, setQualChk] = useState("");
  const [assquar, setAssQuar] = useState(false);

  const [asssemi, setAssSemi] = useState(false);

  function listall(lsprt) {
    setFilterProduct([]);
    schedulelist(id, eid).then((response) => {
      if (response.status === true) {
        arr = [];
        if (response.data.pool_arr.length !== 0) {
          for (let i = 0; i < response.data.pool_arr.length; i++) {
            let tsets = 1;
            let tdata = "";
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              if (response.data.fixture_info.length > 0) {
                tdata = response.data.fixture_info.find(
                  (x) => x.pool_id === response.data.pool_arr[i].meet_pool_id
                );
                if (tdata !== undefined) {
                  tsets = tdata.total_sets;
                }
              }
            }

            arr.push({
              pool_name: response.data.pool_arr[i].pool_name,
              meet_pool_id: response.data.pool_arr[i].meet_pool_id,
              total_sets: tsets,
              // sets_list: "",
              teams: [],
              team1sets: [],
              team2sets: [],
            });

            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              var slist = "";
              var sname = "";
              if (
                response.data.pool_arr[i].meet_pool_id ===
                response.data.data_fixtures[j].pool_id
              ) {
                if (
                  lsprt.toUpperCase() === "VOLLEYBALL" ||
                  lsprt.toUpperCase() === "THROWBALL" ||
                  lsprt.toUpperCase() === "BADMINTON" ||
                  lsprt.toUpperCase() === "TENNIS" ||
                  lsprt.toUpperCase() === "TABLE TENNIS" ||
                  lsprt.toUpperCase() === "SQUASH"
                ) {
                  sname = "Set ";
                  setSFormat("Set");
                } else if (
                  lsprt.toUpperCase() === "SOFTBALL" ||
                  lsprt.toUpperCase() === "KHOKHO"
                ) {
                  sname = "Innings ";
                  setSFormat("Innings");
                }
                if (
                  lsprt.toUpperCase() === "VOLLEYBALL" ||
                  lsprt.toUpperCase() === "THROWBALL" ||
                  lsprt.toUpperCase() === "SOFTBALL" ||
                  lsprt.toUpperCase() === "KHOKHO" ||
                  lsprt.toUpperCase() === "BADMINTON" ||
                  lsprt.toUpperCase() === "TENNIS" ||
                  lsprt.toUpperCase() === "TABLE TENNIS" ||
                  lsprt.toUpperCase() === "SQUASH"
                ) {
                  if (tsets >= 1) {
                    slist =
                      " 1st " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set1 +
                      " - " +
                      response.data.data_fixtures[j].team2_set1;
                  }

                  if (tsets >= 2) {
                    slist =
                      slist +
                      " || 2nd " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set2 +
                      " - " +
                      response.data.data_fixtures[j].team2_set2;
                  }
                  if (tsets >= 3) {
                    slist =
                      slist +
                      " || 3rd " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set3 +
                      " - " +
                      response.data.data_fixtures[j].team2_set3;
                  }

                  if (tsets >= 4) {
                    slist =
                      slist +
                      " || 4th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set4 +
                      " - " +
                      response.data.data_fixtures[j].team2_set4;
                  }

                  if (tsets >= 5) {
                    slist =
                      slist +
                      " || 5th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set5 +
                      " - " +
                      response.data.data_fixtures[j].team2_set5;
                  }

                  if (tsets >= 6) {
                    slist =
                      slist +
                      " || 6th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set6 +
                      " - " +
                      response.data.data_fixtures[j].team2_set6;
                  }

                  if (tsets >= 7) {
                    slist =
                      slist +
                      " || 7th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set7 +
                      " - " +
                      response.data.data_fixtures[j].team2_set7;
                  }

                  if (tsets >= 8) {
                    slist =
                      slist +
                      " || 8th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set8 +
                      " - " +
                      response.data.data_fixtures[j].team2_set8;
                  }

                  if (tsets >= 9) {
                    slist =
                      slist +
                      " || 9th " +
                      sname +
                      " : " +
                      response.data.data_fixtures[j].team1_set9 +
                      " - " +
                      response.data.data_fixtures[j].team2_set9;
                  }
                  // arr[i].sets_list = slist;
                  arr[i].teams.push({
                    id: response.data.data_fixtures[j].id,
                    team1_name: response.data.data_fixtures[j].team1_name,
                    team1_id: response.data.data_fixtures[j].team1_id,
                    team2_name: response.data.data_fixtures[j].team2_name,
                    team2_id: response.data.data_fixtures[j].team2_id,
                    match_round: response.data.data_fixtures[j].match_round,
                    match_date: response.data.data_fixtures[j].match_date,
                    match_time: response.data.data_fixtures[j].match_time,
                    winner_name: response.data.data_fixtures[j].winner_name,
                    winner_id: response.data.data_fixtures[j].winner_id,
                    team1_score: response.data.data_fixtures[j].team1_score,
                    team2_score: response.data.data_fixtures[j].team2_score,
                    sets_list: slist,
                  });
                  arr[i].team1sets.push({
                    id: response.data.data_fixtures[j].id,
                    team1_name: response.data.data_fixtures[j].team1_name,
                    team1_id: response.data.data_fixtures[j].team1_id,
                    team1_set1: response.data.data_fixtures[j].team1_set1,
                    team1_set2: response.data.data_fixtures[j].team1_set2,
                    team1_set3: response.data.data_fixtures[j].team1_set3,
                    team1_set4: response.data.data_fixtures[j].team1_set4,
                    team1_set5: response.data.data_fixtures[j].team1_set5,
                    team1_set6: response.data.data_fixtures[j].team1_set6,
                    team1_set7: response.data.data_fixtures[j].team1_set7,
                    team1_set8: response.data.data_fixtures[j].team1_set8,
                    team1_set9: response.data.data_fixtures[j].team1_set9,
                  });
                  arr[i].team2sets.push({
                    id: response.data.data_fixtures[j].id,
                    team2_name: response.data.data_fixtures[j].team2_name,
                    team2_id: response.data.data_fixtures[j].team2_id,
                    team2_set1: response.data.data_fixtures[j].team2_set1,
                    team2_set2: response.data.data_fixtures[j].team2_set2,
                    team2_set3: response.data.data_fixtures[j].team2_set3,
                    team2_set4: response.data.data_fixtures[j].team2_set4,
                    team2_set5: response.data.data_fixtures[j].team2_set5,
                    team2_set6: response.data.data_fixtures[j].team2_set6,
                    team2_set7: response.data.data_fixtures[j].team2_set7,
                    team2_set8: response.data.data_fixtures[j].team2_set8,
                    team2_set9: response.data.data_fixtures[j].team2_set9,
                  });
                } else {
                  arr[i].teams.push({
                    id: response.data.data_fixtures[j].id,
                    team1_name: response.data.data_fixtures[j].team1_name,
                    team1_id: response.data.data_fixtures[j].team1_id,
                    team2_name: response.data.data_fixtures[j].team2_name,
                    team2_id: response.data.data_fixtures[j].team2_id,
                    match_round: response.data.data_fixtures[j].match_round,
                    match_date: response.data.data_fixtures[j].match_date,
                    match_time: response.data.data_fixtures[j].match_time,
                    winner_name: response.data.data_fixtures[j].winner_name,
                    winner_id: response.data.data_fixtures[j].winner_id,
                    team1_score: response.data.data_fixtures[j].team1_score,
                    team2_score: response.data.data_fixtures[j].team2_score,
                    sets_list: "",
                  });
                }
              }
            }
          }

          // if (response.data.data_fixtures.length !== 0) {
          //   setAssQuar(true);
          // }
          setFilterProduct(arr);
        }
      }
    });

    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("round", 2);
    temp.append("category_id", eid);
    schedulequarselectedlist(temp).then((response) => {
      if (response.status === true) {
        // if (response.data.fixture_info !== null) {
        //   setQualChk(response.data.fixture_info.qualification_check);
        // }
        const temp7 = new FormData();
        temp7.append("category_id", eid);
        tourcategorydetail(temp7).then((response) => {
          if (response.status === true) {
            setQualChk(response.data.category_details.quoter_final_status);
          }
        });
        arr = [];
        if (response.data.pool_arr.length !== 0) {
          for (let j = 0; j < response.data.data_fixtures.length; j++) {
            let sname = "";
            let slist = "";
            let tsets = response.data.fixture_info.total_sets;
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              sname = "Set ";
              setSFormat("Set");
            } else if (
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO"
            ) {
              sname = "Innings ";
              setSFormat("Innings");
            }
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              if (tsets >= 1) {
                slist =
                  " 1st " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set1 +
                  " - " +
                  response.data.data_fixtures[j].team2_set1;
              }

              if (tsets >= 2) {
                slist =
                  slist +
                  " || 2nd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set2 +
                  " - " +
                  response.data.data_fixtures[j].team2_set2;
              }
              if (tsets >= 3) {
                slist =
                  slist +
                  " || 3rd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set3 +
                  " - " +
                  response.data.data_fixtures[j].team2_set3;
              }

              if (tsets >= 4) {
                slist =
                  slist +
                  " || 4th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set4 +
                  " - " +
                  response.data.data_fixtures[j].team2_set4;
              }

              if (tsets >= 5) {
                slist =
                  slist +
                  " || 5th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set5 +
                  " - " +
                  response.data.data_fixtures[j].team2_set5;
              }

              if (tsets >= 6) {
                slist =
                  slist +
                  " || 6th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set6 +
                  " - " +
                  response.data.data_fixtures[j].team2_set6;
              }

              if (tsets >= 7) {
                slist =
                  slist +
                  " || 7th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set7 +
                  " - " +
                  response.data.data_fixtures[j].team2_set7;
              }

              if (tsets >= 8) {
                slist =
                  slist +
                  " || 8th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set8 +
                  " - " +
                  response.data.data_fixtures[j].team2_set8;
              }

              if (tsets >= 9) {
                slist =
                  slist +
                  " || 9th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set9 +
                  " - " +
                  response.data.data_fixtures[j].team2_set9;
              }
            }

            arr.push({
              id: response.data.data_fixtures[j].id,
              meet_pool_id: response.data.data_fixtures[j].pool_id,
              team1_name: response.data.data_fixtures[j].team1_name,
              team1_id: response.data.data_fixtures[j].team1_id,
              team2_name: response.data.data_fixtures[j].team2_name,
              team2_id: response.data.data_fixtures[j].team2_id,
              match_round: response.data.data_fixtures[j].match_round,
              match_date: response.data.data_fixtures[j].match_date,
              match_time: response.data.data_fixtures[j].match_time,
              winner_name: response.data.data_fixtures[j].winner_name,
              winner_id: response.data.data_fixtures[j].winner_id,
              team1_score: response.data.data_fixtures[j].team1_score,
              team2_score: response.data.data_fixtures[j].team2_score,
              total_sets: response.data.fixture_info.total_sets,
              sets_list: slist,
              team1sets: [],
              team2sets: [],
            });
          }

          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              if (arr[i].id === response.data.data_fixtures[j].id) {
                arr[i].team1sets.push({
                  id: response.data.data_fixtures[j].id,
                  team1_name: response.data.data_fixtures[j].team1_name,
                  team1_id: response.data.data_fixtures[j].team1_id,
                  team1_set1: response.data.data_fixtures[j].team1_set1,
                  team1_set2: response.data.data_fixtures[j].team1_set2,
                  team1_set3: response.data.data_fixtures[j].team1_set3,
                  team1_set4: response.data.data_fixtures[j].team1_set4,
                  team1_set5: response.data.data_fixtures[j].team1_set5,
                  team1_set6: response.data.data_fixtures[j].team1_set6,
                  team1_set7: response.data.data_fixtures[j].team1_set7,
                  team1_set8: response.data.data_fixtures[j].team1_set8,
                  team1_set9: response.data.data_fixtures[j].team1_set9,
                });
                arr[i].team2sets.push({
                  id: response.data.data_fixtures[j].id,
                  team2_name: response.data.data_fixtures[j].team2_name,
                  team2_id: response.data.data_fixtures[j].team2_id,
                  team2_set1: response.data.data_fixtures[j].team2_set1,
                  team2_set2: response.data.data_fixtures[j].team2_set2,
                  team2_set3: response.data.data_fixtures[j].team2_set3,
                  team2_set4: response.data.data_fixtures[j].team2_set4,
                  team2_set5: response.data.data_fixtures[j].team2_set5,
                  team2_set6: response.data.data_fixtures[j].team2_set6,
                  team2_set7: response.data.data_fixtures[j].team2_set7,
                  team2_set8: response.data.data_fixtures[j].team2_set8,
                  team2_set9: response.data.data_fixtures[j].team2_set9,
                });
              }
            }
          }
          setQuarProduct(arr);
        }
      }
    });

    const temp1 = new FormData();
    temp1.append("tournament_id", id);
    temp1.append("round", 3);
    temp1.append("category_id", eid);
    schedulequarselectedlist(temp1).then((response) => {
      if (response.status === true) {
        arr = [];
        // if (response.data.fixture_info !== null) {
        //   setQualChk(response.data.fixture_info.qualification_check);
        //   if (response.data.fixture_info.qualification_check === 2) {
        //     setAssSemi(true);
        //   }
        // }
        const temp7 = new FormData();
        temp7.append("category_id", eid);
        tourcategorydetail(temp7).then((res) => {
          if (res.status === true) {
            if (res.data.category_details.quoter_final_status === 2) {
              setAssSemi(true);
            }
            setQualChk(res.data.category_details.quoter_final_status);
          }
        });
        if (response.data.pool_arr.length !== 0) {
          for (let j = 0; j < response.data.data_fixtures.length; j++) {
            let sname = "";
            let slist = "";
            let tsets = response.data.fixture_info.total_sets;
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              sname = "Set ";
              setSFormat("Set");
            } else if (
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO"
            ) {
              sname = "Innings ";
              setSFormat("Innings");
            }
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              if (tsets >= 1) {
                slist =
                  " 1st " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set1 +
                  " - " +
                  response.data.data_fixtures[j].team2_set1;
              }

              if (tsets >= 2) {
                slist =
                  slist +
                  " || 2nd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set2 +
                  " - " +
                  response.data.data_fixtures[j].team2_set2;
              }
              if (tsets >= 3) {
                slist =
                  slist +
                  " || 3rd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set3 +
                  " - " +
                  response.data.data_fixtures[j].team2_set3;
              }

              if (tsets >= 4) {
                slist =
                  slist +
                  " || 4th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set4 +
                  " - " +
                  response.data.data_fixtures[j].team2_set4;
              }

              if (tsets >= 5) {
                slist =
                  slist +
                  " || 5th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set5 +
                  " - " +
                  response.data.data_fixtures[j].team2_set5;
              }

              if (tsets >= 6) {
                slist =
                  slist +
                  " || 6th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set6 +
                  " - " +
                  response.data.data_fixtures[j].team2_set6;
              }

              if (tsets >= 7) {
                slist =
                  slist +
                  " || 7th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set7 +
                  " - " +
                  response.data.data_fixtures[j].team2_set7;
              }

              if (tsets >= 8) {
                slist =
                  slist +
                  " || 8th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set8 +
                  " - " +
                  response.data.data_fixtures[j].team2_set8;
              }

              if (tsets >= 9) {
                slist =
                  slist +
                  " || 9th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set9 +
                  " - " +
                  response.data.data_fixtures[j].team2_set9;
              }
            }

            arr.push({
              id: response.data.data_fixtures[j].id,
              meet_pool_id: response.data.data_fixtures[j].pool_id,
              team1_name: response.data.data_fixtures[j].team1_name,
              team1_id: response.data.data_fixtures[j].team1_id,
              team2_name: response.data.data_fixtures[j].team2_name,
              team2_id: response.data.data_fixtures[j].team2_id,
              match_round: response.data.data_fixtures[j].match_round,
              match_date: response.data.data_fixtures[j].match_date,
              match_time: response.data.data_fixtures[j].match_time,
              winner_name: response.data.data_fixtures[j].winner_name,
              winner_id: response.data.data_fixtures[j].winner_id,
              team1_score: response.data.data_fixtures[j].team1_score,
              team2_score: response.data.data_fixtures[j].team2_score,
              total_sets: response.data.fixture_info.total_sets,
              sets_list: slist,
              team1sets: [],
              team2sets: [],
            });
          }

          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              if (arr[i].id === response.data.data_fixtures[j].id) {
                arr[i].team1sets.push({
                  id: response.data.data_fixtures[j].id,
                  team1_name: response.data.data_fixtures[j].team1_name,
                  team1_id: response.data.data_fixtures[j].team1_id,
                  team1_set1: response.data.data_fixtures[j].team1_set1,
                  team1_set2: response.data.data_fixtures[j].team1_set2,
                  team1_set3: response.data.data_fixtures[j].team1_set3,
                  team1_set4: response.data.data_fixtures[j].team1_set4,
                  team1_set5: response.data.data_fixtures[j].team1_set5,
                  team1_set6: response.data.data_fixtures[j].team1_set6,
                  team1_set7: response.data.data_fixtures[j].team1_set7,
                  team1_set8: response.data.data_fixtures[j].team1_set8,
                  team1_set9: response.data.data_fixtures[j].team1_set9,
                });
                arr[i].team2sets.push({
                  id: response.data.data_fixtures[j].id,
                  team2_name: response.data.data_fixtures[j].team2_name,
                  team2_id: response.data.data_fixtures[j].team2_id,
                  team2_set1: response.data.data_fixtures[j].team2_set1,
                  team2_set2: response.data.data_fixtures[j].team2_set2,
                  team2_set3: response.data.data_fixtures[j].team2_set3,
                  team2_set4: response.data.data_fixtures[j].team2_set4,
                  team2_set5: response.data.data_fixtures[j].team2_set5,
                  team2_set6: response.data.data_fixtures[j].team2_set6,
                  team2_set7: response.data.data_fixtures[j].team2_set7,
                  team2_set8: response.data.data_fixtures[j].team2_set8,
                  team2_set9: response.data.data_fixtures[j].team2_set9,
                });
              }
            }
          }
          if (arr.length !== 0) {
            setAssSemi(true);
          }

          setSemiProduct(arr);
        }
      }
    });

    const temp2 = new FormData();
    temp2.append("tournament_id", id);
    temp2.append("round", 4);
    temp2.append("category_id", eid);
    schedulequarselectedlist(temp2).then((response) => {
      if (response.status === true) {
        arr = [];
        if (response.data.pool_arr.length !== 0) {
          for (let j = 0; j < response.data.data_fixtures.length; j++) {
            let sname = "";
            let slist = "";
            let tsets = response.data.fixture_info.total_sets;
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              sname = "Set ";
              setSFormat("Set");
            } else if (
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO"
            ) {
              sname = "Innings ";
              setSFormat("Innings");
            }
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              if (tsets >= 1) {
                slist =
                  " 1st " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set1 +
                  " - " +
                  response.data.data_fixtures[j].team2_set1;
              }

              if (tsets >= 2) {
                slist =
                  slist +
                  " || 2nd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set2 +
                  " - " +
                  response.data.data_fixtures[j].team2_set2;
              }
              if (tsets >= 3) {
                slist =
                  slist +
                  " || 3rd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set3 +
                  " - " +
                  response.data.data_fixtures[j].team2_set3;
              }

              if (tsets >= 4) {
                slist =
                  slist +
                  " || 4th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set4 +
                  " - " +
                  response.data.data_fixtures[j].team2_set4;
              }

              if (tsets >= 5) {
                slist =
                  slist +
                  " || 5th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set5 +
                  " - " +
                  response.data.data_fixtures[j].team2_set5;
              }

              if (tsets >= 6) {
                slist =
                  slist +
                  " || 6th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set6 +
                  " - " +
                  response.data.data_fixtures[j].team2_set6;
              }

              if (tsets >= 7) {
                slist =
                  slist +
                  " || 7th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set7 +
                  " - " +
                  response.data.data_fixtures[j].team2_set7;
              }

              if (tsets >= 8) {
                slist =
                  slist +
                  " || 8th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set8 +
                  " - " +
                  response.data.data_fixtures[j].team2_set8;
              }

              if (tsets >= 9) {
                slist =
                  slist +
                  " || 9th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set9 +
                  " - " +
                  response.data.data_fixtures[j].team2_set9;
              }
            }

            arr.push({
              id: response.data.data_fixtures[j].id,
              meet_pool_id: response.data.data_fixtures[j].pool_id,
              team1_name: response.data.data_fixtures[j].team1_name,
              team1_id: response.data.data_fixtures[j].team1_id,
              team2_name: response.data.data_fixtures[j].team2_name,
              team2_id: response.data.data_fixtures[j].team2_id,
              match_round: response.data.data_fixtures[j].match_round,
              match_date: response.data.data_fixtures[j].match_date,
              match_time: response.data.data_fixtures[j].match_time,
              winner_name: response.data.data_fixtures[j].winner_name,
              winner_id: response.data.data_fixtures[j].winner_id,
              team1_score: response.data.data_fixtures[j].team1_score,
              team2_score: response.data.data_fixtures[j].team2_score,
              total_sets: response.data.fixture_info.total_sets,
              sets_list: slist,
              team1sets: [],
              team2sets: [],
            });
          }

          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              if (arr[i].id === response.data.data_fixtures[j].id) {
                arr[i].team1sets.push({
                  id: response.data.data_fixtures[j].id,
                  team1_name: response.data.data_fixtures[j].team1_name,
                  team1_id: response.data.data_fixtures[j].team1_id,
                  team1_set1: response.data.data_fixtures[j].team1_set1,
                  team1_set2: response.data.data_fixtures[j].team1_set2,
                  team1_set3: response.data.data_fixtures[j].team1_set3,
                  team1_set4: response.data.data_fixtures[j].team1_set4,
                  team1_set5: response.data.data_fixtures[j].team1_set5,
                  team1_set6: response.data.data_fixtures[j].team1_set6,
                  team1_set7: response.data.data_fixtures[j].team1_set7,
                  team1_set8: response.data.data_fixtures[j].team1_set8,
                  team1_set9: response.data.data_fixtures[j].team1_set9,
                });
                arr[i].team2sets.push({
                  id: response.data.data_fixtures[j].id,
                  team2_name: response.data.data_fixtures[j].team2_name,
                  team2_id: response.data.data_fixtures[j].team2_id,
                  team2_set1: response.data.data_fixtures[j].team2_set1,
                  team2_set2: response.data.data_fixtures[j].team2_set2,
                  team2_set3: response.data.data_fixtures[j].team2_set3,
                  team2_set4: response.data.data_fixtures[j].team2_set4,
                  team2_set5: response.data.data_fixtures[j].team2_set5,
                  team2_set6: response.data.data_fixtures[j].team2_set6,
                  team2_set7: response.data.data_fixtures[j].team2_set7,
                  team2_set8: response.data.data_fixtures[j].team2_set8,
                  team2_set9: response.data.data_fixtures[j].team2_set9,
                });
              }
            }
          }
          setFinalProduct(arr);
        }
      }
    });

    const otemp2 = new FormData();
    otemp2.append("tournament_id", id);
    otemp2.append("round", 5);
    otemp2.append("category_id", eid);
    schedulequarselectedlist(otemp2).then((response) => {
      if (response.status === true) {
        arr = [];
        if (response.data.pool_arr.length !== 0) {
          for (let j = 0; j < response.data.data_fixtures.length; j++) {
            let sname = "";
            let slist = "";
            let tsets = response.data.fixture_info.total_sets;
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              sname = "Set ";
              setSFormat("Set");
            } else if (
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO"
            ) {
              sname = "Innings ";
              setSFormat("Innings");
            }
            if (
              lsprt.toUpperCase() === "VOLLEYBALL" ||
              lsprt.toUpperCase() === "THROWBALL" ||
              lsprt.toUpperCase() === "SOFTBALL" ||
              lsprt.toUpperCase() === "KHOKHO" ||
              lsprt.toUpperCase() === "BADMINTON" ||
              lsprt.toUpperCase() === "TENNIS" ||
              lsprt.toUpperCase() === "TABLE TENNIS" ||
              lsprt.toUpperCase() === "SQUASH"
            ) {
              if (tsets >= 1) {
                slist =
                  " 1st " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set1 +
                  " - " +
                  response.data.data_fixtures[j].team2_set1;
              }

              if (tsets >= 2) {
                slist =
                  slist +
                  " || 2nd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set2 +
                  " - " +
                  response.data.data_fixtures[j].team2_set2;
              }
              if (tsets >= 3) {
                slist =
                  slist +
                  " || 3rd " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set3 +
                  " - " +
                  response.data.data_fixtures[j].team2_set3;
              }

              if (tsets >= 4) {
                slist =
                  slist +
                  " || 4th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set4 +
                  " - " +
                  response.data.data_fixtures[j].team2_set4;
              }

              if (tsets >= 5) {
                slist =
                  slist +
                  " || 5th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set5 +
                  " - " +
                  response.data.data_fixtures[j].team2_set5;
              }

              if (tsets >= 6) {
                slist =
                  slist +
                  " || 6th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set6 +
                  " - " +
                  response.data.data_fixtures[j].team2_set6;
              }

              if (tsets >= 7) {
                slist =
                  slist +
                  " || 7th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set7 +
                  " - " +
                  response.data.data_fixtures[j].team2_set7;
              }

              if (tsets >= 8) {
                slist =
                  slist +
                  " || 8th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set8 +
                  " - " +
                  response.data.data_fixtures[j].team2_set8;
              }

              if (tsets >= 9) {
                slist =
                  slist +
                  " || 9th " +
                  sname +
                  " : " +
                  response.data.data_fixtures[j].team1_set9 +
                  " - " +
                  response.data.data_fixtures[j].team2_set9;
              }
            }

            arr.push({
              id: response.data.data_fixtures[j].id,
              meet_pool_id: response.data.data_fixtures[j].pool_id,
              team1_name: response.data.data_fixtures[j].team1_name,
              team1_id: response.data.data_fixtures[j].team1_id,
              team2_name: response.data.data_fixtures[j].team2_name,
              team2_id: response.data.data_fixtures[j].team2_id,
              match_round: response.data.data_fixtures[j].match_round,
              match_date: response.data.data_fixtures[j].match_date,
              match_time: response.data.data_fixtures[j].match_time,
              winner_name: response.data.data_fixtures[j].winner_name,
              winner_id: response.data.data_fixtures[j].winner_id,
              team1_score: response.data.data_fixtures[j].team1_score,
              team2_score: response.data.data_fixtures[j].team2_score,
              total_sets: response.data.fixture_info.total_sets,
              sets_list: slist,
              team1sets: [],
              team2sets: [],
            });
          }

          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < response.data.data_fixtures.length; j++) {
              if (arr[i].id === response.data.data_fixtures[j].id) {
                arr[i].team1sets.push({
                  id: response.data.data_fixtures[j].id,
                  team1_name: response.data.data_fixtures[j].team1_name,
                  team1_id: response.data.data_fixtures[j].team1_id,
                  team1_set1: response.data.data_fixtures[j].team1_set1,
                  team1_set2: response.data.data_fixtures[j].team1_set2,
                  team1_set3: response.data.data_fixtures[j].team1_set3,
                  team1_set4: response.data.data_fixtures[j].team1_set4,
                  team1_set5: response.data.data_fixtures[j].team1_set5,
                  team1_set6: response.data.data_fixtures[j].team1_set6,
                  team1_set7: response.data.data_fixtures[j].team1_set7,
                  team1_set8: response.data.data_fixtures[j].team1_set8,
                  team1_set9: response.data.data_fixtures[j].team1_set9,
                });
                arr[i].team2sets.push({
                  id: response.data.data_fixtures[j].id,
                  team2_name: response.data.data_fixtures[j].team2_name,
                  team2_id: response.data.data_fixtures[j].team2_id,
                  team2_set1: response.data.data_fixtures[j].team2_set1,
                  team2_set2: response.data.data_fixtures[j].team2_set2,
                  team2_set3: response.data.data_fixtures[j].team2_set3,
                  team2_set4: response.data.data_fixtures[j].team2_set4,
                  team2_set5: response.data.data_fixtures[j].team2_set5,
                  team2_set6: response.data.data_fixtures[j].team2_set6,
                  team2_set7: response.data.data_fixtures[j].team2_set7,
                  team2_set8: response.data.data_fixtures[j].team2_set8,
                  team2_set9: response.data.data_fixtures[j].team2_set9,
                });
              }
            }
          }
          setOFinalProduct(arr);
        }
      }
    });

    listknock();
  }

  const [errorMsg, setErrMsg] = useState("");
  function adddate() {
    if (mdate !== "") {
      if (DHour !== "" && DSec !== "" && DFor !== "") {
        var time = DHour + ":" + DSec + " " + DFor;
        const temp = new FormData();
        temp.append("match_id", mid);
        temp.append("date", mdate);
        temp.append("time", time);
        dotracking(
          "(date and time) add button in schedule allocate for " +
            mteam +
            " team in " +
            catname +
            " (" +
            eid +
            ") category for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.fixallocateheading

          // "match " +
          //   mid +
          //   " (date and time) add button in schedule allocate for " +
          //   catname +
          //   " (" +
          //   eid +
          //   ") category in " +
          //   tname +
          //   " (" +
          //   id +
          //   ") tournament $$ " + trackobj.fixallocateheading
        );
        scheduledateandtime(temp).then((response) => {
          if (response.status === true) {
            listall(tsport);
            handleDClose();
            toast.success(response.message);
          }
        });
      } else {
        setErrMsg("Please Select the Time");
      }
    } else {
      setErrMsg("Please Select the Date");
    }
  }

  const [knockshow, setKnockShow] = useState(false);
  const [knocklist, setKnockList] = useState([]);
  const [noknockshow, setNoKnockShow] = useState(false);

  const [wteam, setWTeam] = useState("");
  const [rteam, setRTeam] = useState("");
  const [srteam, setSRTeam] = useState("");

  function closeknock() {
    setKnockShow(false);
    setWTeam("");
    setRTeam("");
    setSRTeam("");
    setErrMsg("");
  }
  function handleknock() {
    setKnockShow(true);
    setWTeam("");
    setRTeam("");
    setSRTeam("");
    setErrMsg("");
    if (localStorage.getItem("Role") === "1") {
      tourteamlist(id, eid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].team_name,
              });
            }
          }
          setKnockList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      teamlistcityreg(id, eid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].team_name,
              });
            }
          }
          setKnockList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Refteamlist(id, eid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].team_name,
              });
            }
          }
          setKnockList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      teamlistcoach(id, eid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].team_name,
              });
            }
          }
          setKnockList(arr);
        }
      });
    }
  }

  function listknock() {
    setWTeam("");
    setRTeam("");
    setSRTeam("");
    setNoKnockShow(false);
    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", eid);
    schedulenoknocklist(temp).then((response) => {
      if (response.status === true) {
        if (response.data.no_knockout_data.length !== 0) {
          setNoKnockShow(true);
          setWTeam(response.data.no_knockout_data[0].winner_team_name);
          setRTeam(response.data.no_knockout_data[0].runner_team_name);
          setSRTeam(response.data.no_knockout_data[0].two_runner_team_name);
        }
      }
    });
  }
  function handleWin(e) {
    setWTeam(e);
  }

  function handleRun(e) {
    setRTeam(e);
  }

  function handleSRun(e) {
    setSRTeam(e);
  }

  function deleteknock() {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = new FormData();
        temp.append("tournament_id", id);
        temp.append("category_id", eid);
        dotracking(
          "noknockout delete button in schedule allocate for " +
            catname +
            " (" +
            eid +
            ") category in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.fixallocateheading
        );
        schedulenoknockdelete(temp).then((response) => {
          if (response.status === true) {
            listknock();
            toast.success(response.message);
          }
        });
      }
    });
  }
  function addknock() {
    setErrMsg("");
    if (wteam !== "") {
      var valid = "";
      if (rteam !== "") {
        if (wteam.value === rteam.value) {
          valid = "require";
          setErrMsg("Winner and Runner Team should not be same");
        }
      }
      if (valid === "") {
        if (srteam !== "") {
          if (wteam.value === srteam.value) {
            valid = "require";
            setErrMsg("Winner and 2nd Runner Team should not be same");
          }

          if (rteam.value === srteam.value) {
            valid = "require";
            setErrMsg("Runner and 2nd Runner Team should not be same");
          }
        }
        if (valid === "") {
          const temp = new FormData();
          temp.append("tournament_id", id);
          temp.append("category_id", eid);
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("winner_team_id", wteam.value);
          temp.append("winner_team_name", wteam.label);
          var rid = 0;
          var rlabel = "-";
          if (rteam !== "") {
            rid = rteam.value;
            rlabel = rteam.label;
          }
          temp.append("runner_team_id", rid);
          temp.append("runner_team_name", rlabel);

          var srid = 0;
          var srlabel = "-";
          if (srteam !== "") {
            srid = srteam.value;
            srlabel = srteam.label;
          }

          temp.append("two_runner_team_id", srid);
          temp.append("two_runner_team_name", srlabel);
          dotracking(
            "noknockout add button in schedule allocate for winner " +
              wteam.label +
              " (" +
              wteam.value +
              ") and runner " +
              rlabel +
              " (" +
              rid +
              ") and 2nd runner " +
              srlabel +
              " (" +
              srid +
              ") in " +
              catname +
              " (" +
              eid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.fixallocateheading
          );

          schedulenoknockoutcreate(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              listknock();
              closeknock();
            }
          });
        }
      }
    } else {
      setErrMsg("Please Select the Winner");
    }
  }

  const [finalshow, setFinalShow] = useState(false);
  const [finallist, setFinalList] = useState([]);

  function closefinal() {
    setFinalShow(false);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
  }

  function handlefinal() {
    setFinalShow(true);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");

    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", eid);
    temp.append("round", 4);
    schedulequarfinallist(temp).then((response) => {
      if (response.status === true) {
        arr = [];
        let arr1 = [];
        setQuarFix(response.data.fixtures_info.id);

        if (semiProduct.length === 1) {
          poollisttour(id, eid).then((res) => {
            if (res.status === true) {
              arr = [];
              if (res.data.pool.length !== 0) {
                for (let i = 0; i < res.data.pool.length; i++) {
                  arr.push({
                    pool_name: res.data.pool[i].pool_name,
                    meet_pool_id: res.data.pool[i].meet_pool_id,
                    teamsele: "",
                    team1_name: "",
                    team1_id: "",
                    teams: res.data.pool[i].teamlist,
                  });
                }
                setFinalList(arr);
              }
            }
          });
        } else {
          if (response.data.pool_arr.length !== 0) {
            for (let i = 0; i < response.data.pool_arr.length; i++) {
              arr.push({
                pool_name: response.data.pool_arr[i].pool_name,
                meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                teamsele: "",
                team1_name: "",
                team1_id: "",
                teams: [],
              });
              if (qualchk === "3") {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    if (
                      response.data.pool_arr[i].meet_pool_id ===
                      response.data.teamlist[j].pool_id
                    ) {
                      arr[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              } else {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    arr[i].teams.push({
                      pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                      pool_id: response.data.teamlist[j].pool_id,
                      teams_name: response.data.teamlist[j].teams_name,
                    });
                  }
                }
              }
            }

            if (filterProduct.length === 1) {
              for (let i = 0; i < response.data.pool_arr.length; i++) {
                arr1.push({
                  pool_name: response.data.pool_arr[i].pool_name,
                  meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                  teamsele: "",
                  team1_name: "",
                  team1_id: "",
                  teams: [],
                });

                if (qualchk === "3") {
                  if (response.data.teamlist.length !== 0) {
                    for (let j = 0; j < response.data.teamlist.length; j++) {
                      if (
                        response.data.pool_arr[i].meet_pool_id ===
                        response.data.teamlist[j].pool_id
                      ) {
                        arr1[i].teams.push({
                          pool_mapped_teams_id:
                            response.data.teamlist[j].teams_id,
                          pool_id: response.data.teamlist[j].pool_id,
                          teams_name: response.data.teamlist[j].teams_name,
                        });
                      }
                    }
                  }
                } else {
                  if (response.data.teamlist.length !== 0) {
                    for (let j = 0; j < response.data.teamlist.length; j++) {
                      arr1[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              }

              let arr3 = [];
              arr3 = [...arr, ...arr1];
              setFinalList(arr3);
            } else {
              setFinalList(arr);
            }
          }
        }
      }
    });
  }

  function finalteams(index, value) {
    if (finallist.length !== 0) {
      for (let i = 0; i < finallist.length; i++) {
        if (i === index) {
          finallist[i].teamsele = value;
          for (let j = 0; j < finallist[i].teams.length; j++) {
            if (
              finallist[i].teams[j].pool_mapped_teams_id === parseInt(value)
            ) {
              finallist[i].team1_id = value;
              finallist[i].team1_name = finallist[i].teams[j].teams_name;
              setFinalList([...finallist]);
            }
          }
        }
      }
    }
  }

  function addfinal() {
    var t1id = "";
    var t2id = "";
    var t1name = "";
    var t2name = "";

    var t3id = "";
    var t4id = "";
    var t3name = "";
    var t4name = "";
    setErrMsg("");
    if (finallist.length !== 0) {
      const genfilter = finallist.filter((data) => {
        return data.teamsele !== "";
      });

      for (let i = 0; i < genfilter.length; i++) {
        if (i == 0) {
          if (t1id === "") {
            t1id = genfilter[i].team1_id;
          }
          if (t1name === "") {
            t1name = genfilter[i].team1_name;
          }
        }
        if (i == 1) {
          if (t2id === "") {
            t2id = genfilter[i].team1_id;
          }
          if (t2name === "") {
            t2name = genfilter[i].team1_name;
          }
        }

        if (i == 2) {
          if (t3id === "") {
            t3id = genfilter[i].team1_id;
          }
          if (t3name === "") {
            t3name = genfilter[i].team1_name;
          }
        }

        if (i == 3) {
          if (t4id === "") {
            t4id = genfilter[i].team1_id;
          }
          if (t4name === "") {
            t4name = genfilter[i].team1_name;
          }
        }
      }

      // for (let i = 0; i < finallist.length; i++) {
      // if (finallist[i].teamsele !== 0) {
      //   if (i % 2 !== 1) {
      //     if (t1id === "") {
      //       t1id = finallist[i].team1_id;
      //     }
      //     if (t1name === "") {
      //       t1name = finallist[i].team1_name;
      //     }
      //   }
      //   if (i % 2 === 1) {
      //     if (t2id === "") {
      //       t2id = finallist[i].team1_id;
      //     }

      //     if (t2name === "") {
      //       t2name = finallist[i].team1_name;
      //     }
      //   }
      // }
      // }
    }
    
    var valid = "";
    if (t1id !== t2id) {
      if (t1id !== "" && t1name !== "" && t2id !== "" && t2name !== "") {
        if (mdate !== "") {
          if (DHour !== "" && DSec !== "" && DFor !== "") {
            valid = "";
          } else {
            valid = "Please Select Time";
            setErrMsg("Please Select Time");
          }
        } else {
          valid = "Please Select Date";
          setErrMsg("Please Select Date");
        }
      } else {
        valid = "Please Select Teams";
        setErrMsg("Please Select Teams");
      }
    } else {
      valid = "Please Select Different Teams";
      setErrMsg("Please Select Different Teams");
    }

    if (valid === "") {
      var time = DHour + ":" + DSec + " " + DFor;
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("category_id", eid);
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("fixtures_info_id", quarfix);
      temp.append("team1_id", t1id);
      temp.append("team1_name", t1name);
      temp.append("team2_id", t2id);
      temp.append("team2_name", t2name);
      temp.append("date", mdate);
      temp.append("time", time);
      temp.append("match_round", 4);
      dotracking(
        "finals add button for " +
          t1name +
          " (" +
          t1id +
          ") and " +
          t2name +
          " (" +
          t2id +
          ") teams in " +
          catname +
          " (" +
          eid +
          ") category for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.fixallocateheading
      );

      schedulequarfinalcreate(temp).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          listall(tsport);
          closefinal();
        }
      });
    }
  }

  const [ofinalshow, setOFinalShow] = useState(false);
  const [ofinallist, setOFinalList] = useState([]);

  function closeofinal() {
    setOFinalShow(false);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
  }

  function handleofinal() {
    setOFinalShow(true);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");

    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", eid);
    temp.append("round", 4);
    schedulequarfinallist(temp).then((response) => {
      if (response.status === true) {
        arr = [];
        let arr1 = [];
        setQuarFix(response.data.fixtures_info.id);

        if (semiProduct.length === 1) {
          poollisttour(id, eid).then((res) => {
            if (res.status === true) {
              arr = [];
              if (res.data.pool.length !== 0) {
                for (let i = 0; i < res.data.pool.length; i++) {
                  arr.push({
                    pool_name: res.data.pool[i].pool_name,
                    meet_pool_id: res.data.pool[i].meet_pool_id,
                    teamsele: "",
                    team1_name: "",
                    team1_id: "",
                    teams: res.data.pool[i].teamlist,
                  });
                }
                setOFinalList(arr);
              }
            }
          });
        } else {
          if (response.data.pool_arr.length !== 0) {
            for (let i = 0; i < response.data.pool_arr.length; i++) {
              arr.push({
                pool_name: response.data.pool_arr[i].pool_name,
                meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                teamsele: "",
                team1_name: "",
                team1_id: "",
                teams: [],
              });
              if (qualchk === "3") {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    if (
                      response.data.pool_arr[i].meet_pool_id ===
                      response.data.teamlist[j].pool_id
                    ) {
                      arr[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              } else {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    arr[i].teams.push({
                      pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                      pool_id: response.data.teamlist[j].pool_id,
                      teams_name: response.data.teamlist[j].teams_name,
                    });
                  }
                }
              }
            }

            if (filterProduct.length === 1) {
              for (let i = 0; i < response.data.pool_arr.length; i++) {
                arr1.push({
                  pool_name: response.data.pool_arr[i].pool_name,
                  meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                  teamsele: "",
                  team1_name: "",
                  team1_id: "",
                  teams: [],
                });

                if (qualchk === "3") {
                  if (response.data.teamlist.length !== 0) {
                    for (let j = 0; j < response.data.teamlist.length; j++) {
                      if (
                        response.data.pool_arr[i].meet_pool_id ===
                        response.data.teamlist[j].pool_id
                      ) {
                        arr1[i].teams.push({
                          pool_mapped_teams_id:
                            response.data.teamlist[j].teams_id,
                          pool_id: response.data.teamlist[j].pool_id,
                          teams_name: response.data.teamlist[j].teams_name,
                        });
                      }
                    }
                  }
                } else {
                  if (response.data.teamlist.length !== 0) {
                    for (let j = 0; j < response.data.teamlist.length; j++) {
                      arr1[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              }

              let arr3 = [];
              arr3 = [...arr, ...arr1];
              setOFinalList(arr3);
            } else {
              setOFinalList(arr);
            }
          }
        }
      }
    });
  }

  function finaloteams(index, value) {
    if (ofinallist.length !== 0) {
      for (let i = 0; i < ofinallist.length; i++) {
        if (i === index) {
          ofinallist[i].teamsele = value;
          for (let j = 0; j < ofinallist[i].teams.length; j++) {
            if (
              ofinallist[i].teams[j].pool_mapped_teams_id === parseInt(value)
            ) {
              ofinallist[i].team1_id = value;
              ofinallist[i].team1_name = ofinallist[i].teams[j].teams_name;
              setOFinalList([...ofinallist]);
            }
          }
        }
      }
    }
  }

  function addofinal() {
    var t1id = "";
    var t2id = "";
    var t1name = "";
    var t2name = "";

    setErrMsg("");
    if (ofinallist.length !== 0) {
      const genfilter = ofinallist.filter((data) => {
        return data.teamsele !== "";
      });

      for (let i = 0; i < genfilter.length; i++) {
        if (i == 0) {
          if (t1id === "") {
            t1id = genfilter[i].team1_id;
          }
          if (t1name === "") {
            t1name = genfilter[i].team1_name;
          }
        }
        if (i == 1) {
          if (t2id === "") {
            t2id = genfilter[i].team1_id;
          }
          if (t2name === "") {
            t2name = genfilter[i].team1_name;
          }
        }
      }
    }
    var valid = "";
    if (t1id !== t2id) {
      if (t1id !== "" && t1name !== "" && t2id !== "" && t2name !== "") {
        if (mdate !== "") {
          if (DHour !== "" && DSec !== "" && DFor !== "") {
            valid = "";
          } else {
            valid = "Please Select Time";
            setErrMsg("Please Select Time");
          }
        } else {
          valid = "Please Select Date";
          setErrMsg("Please Select Date");
        }
      } else {
        valid = "Please Select Teams";
        setErrMsg("Please Select Teams");
      }
    } else {
      valid = "Please Select Different Teams";
      setErrMsg("Please Select Different Teams");
    }

    if (valid === "") {
      var time = DHour + ":" + DSec + " " + DFor;
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("category_id", eid);
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("fixtures_info_id", quarfix);
      temp.append("team1_id", t1id);
      temp.append("team1_name", t1name);
      temp.append("team2_id", t2id);
      temp.append("team2_name", t2name);
      temp.append("date", mdate);
      temp.append("time", time);
      temp.append("match_round", 5);
      dotracking(
        "finals add button for " +
          t1name +
          " (" +
          t1id +
          ") and " +
          t2name +
          " (" +
          t2id +
          ") teams in " +
          catname +
          " (" +
          eid +
          ") category for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.fixallocateheading
      );

      schedulequarfinalcreate(temp).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          listall(tsport);
          closeofinal();
        }
      });
    }
  }

  const [semishow, setSemiShow] = useState(false);
  const [semilist, setSemiList] = useState([]);

  function closesemi() {
    setSemiShow(false);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
  }

  function handlesemi() {
    setSemiShow(true);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", eid);
    temp.append("round", 3);
    schedulequarfinallist(temp).then((response) => {
      if (response.status === true) {
        arr = [];
        setQuarFix(response.data.fixtures_info.id);
        if (response.data.pool_arr.length !== 0) {
          for (let i = 0; i < response.data.pool_arr.length; i++) {
            arr.push({
              pool_name: response.data.pool_arr[i].pool_name,
              meet_pool_id: response.data.pool_arr[i].meet_pool_id,
              teamsele: "",
              team1_name: "",
              team1_id: "",
              teams: [],
            });
            if (response.data.teamlist.length !== 0) {
              for (let j = 0; j < response.data.teamlist.length; j++) {
                if (
                  response.data.pool_arr[i].meet_pool_id ===
                  response.data.teamlist[j].pool_id
                ) {
                  arr[i].teams.push({
                    pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                    pool_id: response.data.teamlist[j].pool_id,
                    teams_name: response.data.teamlist[j].teams_name,
                  });
                }
              }
            }
          }

          if (filterProduct.length === 1) {
            let arr1 = [];
            for (let i = 0; i < response.data.pool_arr.length; i++) {
              arr1.push({
                pool_name: response.data.pool_arr[i].pool_name,
                meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                teamsele: "",
                team1_name: "",
                team1_id: "",
                teams: [],
              });
              if (qualchk === "3") {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    if (
                      response.data.pool_arr[i].meet_pool_id ===
                      response.data.teamlist[j].pool_id
                    ) {
                      arr1[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              } else {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    arr1[i].teams.push({
                      pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                      pool_id: response.data.teamlist[j].pool_id,
                      teams_name: response.data.teamlist[j].teams_name,
                    });
                  }
                }
              }
            }
            let arr3 = [];
            arr3 = [...arr, ...arr1];
            setSemiList(arr3);
          } else if (filterProduct.length === 2) {
            let arr1 = [];
            for (let i = 0; i < response.data.pool_arr.length; i++) {
              arr1.push({
                pool_name: response.data.pool_arr[i].pool_name,
                meet_pool_id: response.data.pool_arr[i].meet_pool_id,
                teamsele: "",
                team1_name: "",
                team1_id: "",
                teams: [],
              });
              if (qualchk === "3" || qualchk === "2") {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    if (
                      response.data.pool_arr[i].meet_pool_id ===
                      response.data.teamlist[j].pool_id
                    ) {
                      arr1[i].teams.push({
                        pool_mapped_teams_id:
                          response.data.teamlist[j].teams_id,
                        pool_id: response.data.teamlist[j].pool_id,
                        teams_name: response.data.teamlist[j].teams_name,
                      });
                    }
                  }
                }
              } else {
                if (response.data.teamlist.length !== 0) {
                  for (let j = 0; j < response.data.teamlist.length; j++) {
                    arr1[i].teams.push({
                      pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                      pool_id: response.data.teamlist[j].pool_id,
                      teams_name: response.data.teamlist[j].teams_name,
                    });
                  }
                }
              }
            }
            let arr3 = [];
            arr3 = [...arr, ...arr1];
            arr3.sort((a, b) => a.meet_pool_id - b.meet_pool_id);
            setSemiList(arr3);
          } else {
            setSemiList(arr);
          }
        }
      }
    });
  }

  function semiteams(index, value) {
    if (semilist.length !== 0) {
      for (let i = 0; i < semilist.length; i++) {
        if (i === index) {
          semilist[i].teamsele = value;
          if (value !== "") {
            for (let j = 0; j < semilist[i].teams.length; j++) {
              if (
                semilist[i].teams[j].pool_mapped_teams_id === parseInt(value)
              ) {
                semilist[i].team1_id = value;
                semilist[i].team1_name = semilist[i].teams[j].teams_name;
                setSemiList([...semilist]);
              }
            }
          } else {
            semilist[i].team1_id = value;
            semilist[i].team1_name = value;
            setSemiList([...semilist]);
          }
        }
      }
    }
  }

  function addsemi() {
    var t1id = "";
    var t2id = "";
    var t1name = "";
    var t2name = "";
    setErrMsg("");
    var valid = "";

    if (semilist.length !== 0) {
      if (semilist.length !== 0) {
        const genfilter = semilist.filter((data) => {
          return data.teamsele !== "";
        });
        if (genfilter.length == 2) {
          for (let i = 0; i < genfilter.length; i++) {
            if (i == 0) {
              if (t1id === "") {
                t1id = genfilter[i].team1_id;
              }
              if (t1name === "") {
                t1name = genfilter[i].team1_name;
              }
            }
            if (i == 1) {
              if (t2id === "") {
                t2id = genfilter[i].team1_id;
              }
              if (t2name === "") {
                t2name = genfilter[i].team1_name;
              }
            }
          }
        } else {
          valid = "Please Select Only 2 Teams";
          setErrMsg(`Please Select Only 2 Teams`);
        }
      }
    }

    if (valid === "") {
      if (t1id !== "" && t1name !== "" && t2id !== "" && t2name !== "") {
        if (mdate !== "") {
          if (DHour !== "" && DSec !== "" && DFor !== "") {
            var time = DHour + ":" + DSec + " " + DFor;
            const temp = new FormData();
            temp.append("tournament_id", id);
            temp.append("category_id", eid);
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("fixtures_info_id", quarfix);
            temp.append("team1_id", t1id);
            temp.append("team1_name", t1name);
            temp.append("team2_id", t2id);
            temp.append("team2_name", t2name);
            temp.append("date", mdate);
            temp.append("time", time);
            temp.append("match_round", 3);

            dotracking(
              "semifinal add button in schedule allocate for " +
                t1name +
                " (" +
                t1id +
                ") and " +
                t2name +
                " (" +
                t2id +
                ") teams in " +
                catname +
                " (" +
                eid +
                ") category for " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.fixallocateheading
            );
            schedulequarfinalcreate(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                listall(tsport);
                closesemi();
              }
            });
          } else {
            setErrMsg("Please Select Time");
          }
        } else {
          setErrMsg("Please Select Date");
        }
      } else {
        setErrMsg("Please Select Teams");
      }
    }
  }

  const [quarshow, setQuarShow] = useState(false);
  const [quarlist, setQuarList] = useState([]);

  const [quarfix, setQuarFix] = useState("");

  function handlequar() {
    setQuarShow(true);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", eid);
    temp.append("round", 2);
    schedulequarfinallist(temp).then((response) => {
      if (response.status === true) {
        arr = [];
        setQuarFix(response.data.fixtures_info.id);
        if (response.data.pool_arr.length !== 0) {
          for (let i = 0; i < response.data.pool_arr.length; i++) {
            arr.push({
              pool_name: response.data.pool_arr[i].pool_name,
              meet_pool_id: response.data.pool_arr[i].meet_pool_id,
              teamsele: "",
              team1_name: "",
              team1_id: "",
              teams: [],
            });
            if (response.data.teamlist.length !== 0) {
              for (let j = 0; j < response.data.teamlist.length; j++) {
                if (
                  response.data.pool_arr[i].meet_pool_id ===
                  response.data.teamlist[j].pool_id
                ) {
                  arr[i].teams.push({
                    pool_mapped_teams_id: response.data.teamlist[j].teams_id,
                    pool_id: response.data.teamlist[j].pool_id,
                    teams_name: response.data.teamlist[j].teams_name,
                  });
                }
              }
            }
          }
          setQuarList(arr);
        }
      }
    });
  }

  function closequar() {
    setQuarShow(false);
    setMatchDate("");
    setHour("");
    setSecond("");
    setFormat("");
    setErrMsg("");
  }

  function quarteams(index, value) {
    if (quarlist.length !== 0) {
      for (let i = 0; i < quarlist.length; i++) {
        if (i === index) {
          quarlist[i].teamsele = value;
          for (let j = 0; j < quarlist[i].teams.length; j++) {
            if (quarlist[i].teams[j].pool_mapped_teams_id === parseInt(value)) {
              quarlist[i].team1_id = value;
              quarlist[i].team1_name = quarlist[i].teams[j].teams_name;
              setQuarList([...quarlist]);
            }
          }
        }
      }
    }
  }

  function addquar() {
    var t1id = "";
    var t2id = "";
    var t1name = "";
    var t2name = "";
    setErrMsg("");
    if (quarlist.length !== 0) {
      for (let i = 0; i < quarlist.length; i++) {
        if (quarlist[i].teamsele !== 0) {
          if (i % 2 !== 1) {
            if (t1id === "") {
              t1id = quarlist[i].team1_id;
            }
            if (t1name === "") {
              t1name = quarlist[i].team1_name;
            }
          }
          if (i % 2 === 1) {
            if (t2id === "") {
              t2id = quarlist[i].team1_id;
            }

            if (t2name === "") {
              t2name = quarlist[i].team1_name;
            }
          }
        }
      }
    }
    if (t1id !== "" && t1name !== "" && t2id !== "" && t2name !== "") {
      if (mdate !== "") {
        if (DHour !== "" && DSec !== "" && DFor !== "") {
          var time = DHour + ":" + DSec + " " + DFor;
          const temp = new FormData();
          temp.append("tournament_id", id);
          temp.append("category_id", eid);
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("fixtures_info_id", quarfix);
          temp.append("team1_id", t1id);
          temp.append("team1_name", t1name);
          temp.append("team2_id", t2id);
          temp.append("team2_name", t2name);
          temp.append("date", mdate);
          temp.append("time", time);
          temp.append("match_round", 2);
          dotracking(
            "quarter final add button in schedule allocate for for " +
              t1name +
              " (" +
              t1id +
              ") and " +
              t2name +
              " (" +
              t2id +
              ") teams in " +
              catname +
              " (" +
              eid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.fixallocateheading
          );

          schedulequarfinalcreate(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              listall(tsport);
              closequar();
            }
          });
        } else {
          setErrMsg("Please Select Time");
        }
      } else {
        setErrMsg("Please Select Date");
      }
    } else {
      setErrMsg("Please Select Teams");
    }
  }

  function schexport() {
    dotracking(
      "export button in schedule allocate for " +
        catname +
        " (" +
        eid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.fixallocateheading
    );
    window.open(
      exportUrl + "export-match-fixtures/" + id + "/" + eid,
      "_blank"
    );
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Tournament Lists</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Schedular Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-8 align-self-center">
                  <h3>{tname}</h3>
                  <h4 className="text-success tx-20">Category - {catname}</h4>
                </div>
                <div className="col-md-4">
                  <div className="text-right">
                    <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>

                    {filterProduct.length !== 0 && (
                      <a
                        className="btn btn-secondary me-2 mt-1"
                        onClick={schexport}
                      >
                        <i className="fa-solid fa-file-export"></i> Export
                      </a>
                    )}

                    <a className="btn btn-warning mt-1" onClick={fixturelist}>
                      <i className="fa fa-list"></i> Overall Fixture
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="row">
              <div className="text-center">
                <h4>League Matches</h4>
              </div>
            </div>

            {filterProduct.map((data, i) => {
              return (
                <div className="card" id="schedule_details" key={i}>
                  <div className="card-header">
                    <div className="card-title"> {data.pool_name}</div>
                  </div>
                  <div className="card-body px-3 pt-4 pb-2 ">
                    <div className="row schedule_match">
                      <div className="col-md-1 align-self-center">
                        <div className="form-group">
                          <label className="form-label mt-0 text-center">
                            Match
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 align-self-center">
                        <div className="form-group">
                          <label className="form-label mt-0">
                            Team VS Team
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-label mt-0">Date</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-label mt-0">Time</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-label mt-0">Winners</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-label mt-0">Score</label>
                            </div>
                          </div>
                          {localStorage.getItem("Role") === "7" &&
                            (tcreate === localStorage.getItem("UID") ||
                              ttype === "Intra School Tournament") && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Action
                                  </label>
                                </div>
                              </div>
                            )}
                          {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&(
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">
                                  Action
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {data.teams.length === 0 && (
                      <div className="row schedule_match">
                        <div className="col-md-1">
                          <div className="form-group">
                            <ul className="list-group">
                              <li className="listunorder1 text-center">1</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <ul className="list-group">
                              <li className="listunorder1">
                                TBC <span>VS</span> TBC
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1"></li>
                                </ul>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1"></li>
                                </ul>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1"></li>
                                </ul>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1"></li>
                                </ul>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1"></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.teams.map((data1, j) => {
                      return (
                        <div key={j}>
                          <div className="row schedule_match">
                            <div className="col-md-1">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1 text-center">
                                    {j + 1}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1">
                                    {data1.team1_name} <span>VS</span>{" "}
                                    {data1.team2_name}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      {data1.match_date !== null && (
                                        <li className="listunorder1">
                                          {moment(data1.match_date).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </li>
                                      )}
                                      {data1.match_date === null && (
                                        <li className="listunorder1"></li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.match_time}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.winner_name}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.team1_score} -{" "}
                                        {data1.team2_score}
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                {localStorage.getItem("Role") === "7" &&
                                  (tcreate === localStorage.getItem("UID") ||
                                    ttype === "Intra School Tournament") && (
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            <a
                                              className="btn btn-sm btn-secondary me-2 mt-1"
                                              onClick={() =>
                                                handleDate(
                                                  data1.id,
                                                  data1.match_date,
                                                  data1.team1_name +
                                                    " VS " +
                                                    data1.team2_name
                                                )
                                              }
                                            >
                                              <i className="fe fe-watch fs-6"></i>{" "}
                                            </a>
                                            {tsport.toUpperCase() !==
                                              "VOLLEYBALL" &&
                                              tsport.toUpperCase() !==
                                                "THROWBALL" &&
                                              tsport.toUpperCase() !==
                                                "SOFTBALL" &&
                                              tsport.toUpperCase() !==
                                                "KHOKHO" &&
                                              tsport.toUpperCase() !==
                                                "BADMINTON" &&
                                              tsport.toUpperCase() !==
                                                "TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "TABLE TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "SQUASH" && (
                                                <a
                                                  className="btn btn-sm btn-success me-2 mt-1"
                                                  onClick={() =>
                                                    handleWinner(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id
                                                    )
                                                  }
                                                >
                                                  <i className="fe fe-target fs-6"></i>{" "}
                                                </a>
                                              )}

                                            {(tsport.toUpperCase() ===
                                              "VOLLEYBALL" ||
                                              tsport.toUpperCase() ===
                                                "THROWBALL" ||
                                              tsport.toUpperCase() ===
                                                "SOFTBALL" ||
                                              tsport.toUpperCase() ===
                                                "KHOKHO" ||
                                              tsport.toUpperCase() ===
                                                "BADMINTON" ||
                                              tsport.toUpperCase() ===
                                                "TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "TABLE TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "SQUASH") && (
                                              <a
                                                className="btn btn-sm btn-warning mt-1"
                                                onClick={() =>
                                                  handleVolley(
                                                    data1.id,
                                                    data1.team1_name,
                                                    data1.team1_id,
                                                    data1.team2_name,
                                                    data1.team2_id,
                                                    data.meet_pool_id,
                                                    data.total_sets,
                                                    1
                                                  )
                                                }
                                              >
                                                <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                              </a>
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          <a
                                            className="btn btn-sm btn-secondary me-2 mt-1"
                                            onClick={() =>
                                              handleDate(
                                                data1.id,
                                                data1.match_date,
                                                data1.team1_name +
                                                  " VS " +
                                                  data1.team2_name
                                              )
                                            }
                                          >
                                            <i className="fe fe-watch fs-6"></i>{" "}
                                          </a>
                                          {tsport.toUpperCase() !==
                                            "VOLLEYBALL" &&
                                            tsport.toUpperCase() !==
                                              "THROWBALL" &&
                                            tsport.toUpperCase() !==
                                              "SOFTBALL" &&
                                            tsport.toUpperCase() !== "KHOKHO" &&
                                            tsport.toUpperCase() !==
                                              "BADMINTON" &&
                                            tsport.toUpperCase() !== "TENNIS" &&
                                            tsport.toUpperCase() !==
                                              "TABLE TENNIS" &&
                                            tsport.toUpperCase() !==
                                              "SQUASH" && (
                                              <a
                                                className="btn btn-sm btn-success me-2 mt-1"
                                                onClick={() =>
                                                  handleWinner(
                                                    data1.id,
                                                    data1.team1_name,
                                                    data1.team1_id,
                                                    data1.team2_name,
                                                    data1.team2_id
                                                  )
                                                }
                                              >
                                                <i className="fe fe-target fs-6"></i>{" "}
                                              </a>
                                            )}

                                          {(tsport.toUpperCase() ===
                                            "VOLLEYBALL" ||
                                            tsport.toUpperCase() ===
                                              "THROWBALL" ||
                                            tsport.toUpperCase() ===
                                              "SOFTBALL" ||
                                            tsport.toUpperCase() === "KHOKHO" ||
                                            tsport.toUpperCase() ===
                                              "BADMINTON" ||
                                            tsport.toUpperCase() === "TENNIS" ||
                                            tsport.toUpperCase() ===
                                              "TABLE TENNIS" ||
                                            tsport.toUpperCase() ===
                                              "SQUASH") && (
                                            <a
                                              className="btn btn-sm btn-warning mt-1"
                                              onClick={() =>
                                                handleVolley(
                                                  data1.id,
                                                  data1.team1_name,
                                                  data1.team1_id,
                                                  data1.team2_name,
                                                  data1.team2_id,
                                                  data.meet_pool_id,
                                                  data.total_sets,
                                                  1
                                                )
                                              }
                                            >
                                              <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                            </a>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {(tsport.toUpperCase() === "VOLLEYBALL" ||
                            tsport.toUpperCase() === "THROWBALL" ||
                            tsport.toUpperCase() === "SOFTBALL" ||
                            tsport.toUpperCase() === "KHOKHO" ||
                            tsport.toUpperCase() === "BADMINTON" ||
                            tsport.toUpperCase() === "TENNIS" ||
                            tsport.toUpperCase() === "TABLE TENNIS" ||
                            tsport.toUpperCase() === "SQUASH") && (
                            <div className="row schedule_match">
                              <div className="col-md-1"></div>
                              <div className="col-md-11">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1">
                                      {data1.sets_list}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>

          <Modal show={dateshow} size="lg" onHide={handleDClose}>
            <Modal.Header>
              <h4> Add Date and Time</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Date
                      <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      min={mindate}
                      max={maxdate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                      value={mdate}
                      onChange={(e) => setMatchDate(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="row-sm mx-0">
                <div className="form-group">
                  <label className="">
                    Time
                    <span className="errortext"> *</span>
                  </label>
                </div>
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Hours</label>

                    <select
                      className="form-control"
                      value={DHour}
                      onChange={(e) => setHour(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Hour
                      </option>
                      {datehr.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Minutes</label>
                    <select
                      className="form-control"
                      value={DSec}
                      onChange={(e) => setSecond(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Minutes
                      </option>
                      {datesec.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">AM/PM</label>
                    <select
                      className="form-control"
                      value={DFor}
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <option value="" disabled>
                        Select AM/PM
                      </option>

                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={adddate}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={handleDClose}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>

          <Modal show={winshow} size="lg" onHide={handleWClose}>
            <Modal.Header>
              <h4> Add Winner and Score</h4>
            </Modal.Header>
            <Modal.Body>
              {/* <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Winner <span className="errortext"> *</span>
                  </label>
                  <div className="row" onChange={Selectteam}>
                    <div className="col-md-4">
                      <label className="custom-control custom-radio custom-control-md">
                        <input
                          type="radio"
                          className="reg-chk"
                          id="active"
                          value="1"
                          checked={wname === "1"}
                        />{" "}
                        {t1name}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="custom-control custom-radio custom-control-md">
                        <input
                          type="radio"
                          className="reg-chk"
                          id="active"
                          value="2"
                          checked={wname === "2"}
                        />{" "}
                        {t2name}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="custom-control custom-radio custom-control-md">
                        <input
                          type="radio"
                          className="reg-chk"
                          id="active"
                          value="0"
                          checked={wname === "0"}
                        />{" "}
                        Draw
                      </label>
                    </div>
                  </div>
                </div>
              </Col> */}

              <Row className="row-sm mx-0">
                <Col lg={6} md={6}>
                  <div className="form-group">
                    {(tsport.toUpperCase() === "VOLLEYBALL" ||
                      tsport.toUpperCase() === "THROWBALL") && (
                      <label className="">
                        {t1name} Sets Won <span className="errortext"> *</span>
                      </label>
                    )}
                    {tsport.toUpperCase() !== "VOLLEYBALL" &&
                      tsport.toUpperCase() !== "THROWBALL" && (
                        <label className="">
                          {t1name} Score <span className="errortext"> *</span>
                        </label>
                      )}

                    <input
                      type="text"
                      placeholder={
                        tsport.toUpperCase() !== "VOLLEYBALL" &&
                        tsport.toUpperCase() !== "THROWBALL"
                          ? "Score"
                          : "Sets Won"
                      }
                      className="form-control"
                      value={t1score}
                      onChange={handleT1Score}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="form-group">
                    {(tsport.toUpperCase() === "VOLLEYBALL" ||
                      tsport.toUpperCase() === "THROWBALL") && (
                      <label className="">
                        {t2name} Sets Won <span className="errortext"> *</span>
                      </label>
                    )}
                    {tsport.toUpperCase() !== "VOLLEYBALL" &&
                      tsport.toUpperCase() !== "THROWBALL" && (
                        <label className="">
                          {t2name} Score <span className="errortext"> *</span>
                        </label>
                      )}

                    <input
                      type="text"
                      placeholder={
                        tsport.toUpperCase() !== "VOLLEYBALL" &&
                        tsport.toUpperCase() !== "THROWBALL"
                          ? "Score"
                          : "Sets Won"
                      }
                      className="form-control"
                      value={t2score}
                      onChange={handleT2Score}
                    />
                  </div>
                </Col>
              </Row>

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addwinner}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={handleWClose}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>

          <Modal show={volshow} size="lg" onHide={handleVClose}>
            <Modal.Header>
              {(tsport.toUpperCase() === "VOLLEYBALL" ||
                tsport.toUpperCase() === "THROWBALL" ||
                tsport.toUpperCase() === "BADMINTON" ||
                tsport.toUpperCase() === "TENNIS" ||
                tsport.toUpperCase() === "TABLE TENNIS" ||
                tsport.toUpperCase() === "SQUASH") && (
                <h4> Add Sets for Teams</h4>
              )}
              {(tsport.toUpperCase() === "SOFTBALL" ||
                tsport.toUpperCase() === "KHOKHO") && (
                <h4> Add Innings for Teams</h4>
              )}
            </Modal.Header>
            <Modal.Body>
              <div id="schres" className="formschres">
                <Row className="row-sm mx-0">
                  <Col lg={12} md={12}>
                    <div className="form-group">
                      <h4 className="text-success tx-20">{t1name}</h4>
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="form-group">
                      <h4 className="text-success tx-20">{t2name}</h4>
                    </div>
                  </Col>
                  {totalset >= 1 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 1 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 1 Score"
                                : "Innings 1 Score"
                            }
                            className="form-control"
                            value={t1set1}
                            onChange={(e) => handleSets(e, 1, 1)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 1 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 1 Score"
                                : "Innings 1 Score"
                            }
                            className="form-control"
                            value={t2set1}
                            onChange={(e) => handleSets(e, 2, 1)}
                          />
                        </div>
                      </Col>
                    </>
                  )}
                  {totalset >= 2 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 2 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 2 Score"
                                : "Innings 2 Score"
                            }
                            className="form-control"
                            value={t1set2}
                            onChange={(e) => handleSets(e, 1, 2)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 2 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 2 Score"
                                : "Innings 2 Score"
                            }
                            className="form-control"
                            value={t2set2}
                            onChange={(e) => handleSets(e, 2, 2)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 3 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 3 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 3 Score"
                                : "Innings 3 Score"
                            }
                            className="form-control"
                            value={t1set3}
                            onChange={(e) => handleSets(e, 1, 3)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 3 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 3 Score"
                                : "Innings 3 Score"
                            }
                            className="form-control"
                            value={t2set3}
                            onChange={(e) => handleSets(e, 2, 3)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 4 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 4 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 4 Score"
                                : "Innings 4 Score"
                            }
                            className="form-control"
                            value={t1set4}
                            onChange={(e) => handleSets(e, 1, 4)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 4 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 4 Score"
                                : "Innings 4 Score"
                            }
                            className="form-control"
                            value={t2set4}
                            onChange={(e) => handleSets(e, 2, 4)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 5 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 5 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 5 Score"
                                : "Innings 5 Score"
                            }
                            className="form-control"
                            value={t1set5}
                            onChange={(e) => handleSets(e, 1, 5)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 5 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 5 Score"
                                : "Innings 5 Score"
                            }
                            className="form-control"
                            value={t2set5}
                            onChange={(e) => handleSets(e, 2, 5)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 6 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 6 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 6 Score"
                                : "Innings 6 Score"
                            }
                            className="form-control"
                            value={t1set6}
                            onChange={(e) => handleSets(e, 1, 6)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 6 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 6 Score"
                                : "Innings 6 Score"
                            }
                            className="form-control"
                            value={t2set6}
                            onChange={(e) => handleSets(e, 2, 6)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 7 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 7 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 7 Score"
                                : "Innings 7 Score"
                            }
                            className="form-control"
                            value={t1set7}
                            onChange={(e) => handleSets(e, 1, 7)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 7 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 7 Score"
                                : "Innings 7 Score"
                            }
                            className="form-control"
                            value={t2set7}
                            onChange={(e) => handleSets(e, 2, 7)}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  {totalset >= 8 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 8 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 8 Score"
                                : "Innings 8 Score"
                            }
                            className="form-control"
                            value={t1set8}
                            onChange={(e) => handleSets(e, 1, 8)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 8 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 8 Score"
                                : "Innings 8 Score"
                            }
                            className="form-control"
                            value={t2set8}
                            onChange={(e) => handleSets(e, 2, 8)}
                          />
                        </div>
                      </Col>
                    </>
                  )}
                  {totalset === 9 && (
                    <>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 9 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 9 Score"
                                : "Innings 9 Score"
                            }
                            className="form-control"
                            value={t1set9}
                            onChange={(e) => handleSets(e, 1, 9)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="">
                            {SFormat} 9 <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder={
                              SFormat === "Set"
                                ? "Set 9 Score"
                                : "Innings 9 Score"
                            }
                            className="form-control"
                            value={t2set9}
                            onChange={(e) => handleSets(e, 2, 9)}
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                {errorMsg !== "" && (
                  <span className="errortext">{errorMsg}</span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addsets}>
                Submit
              </a>

              <a className="btn btn-secondary" onClick={handleVClose}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>

          {/* {assquar && (
            <> */}
          {qualchk === "1" && (
            <>
              <div className="card mt-2">
                <div className="card-body">
                  {localStorage.getItem("Role") === "7" &&
                    (tcreate === localStorage.getItem("UID") ||
                      ttype === "Intra School Tournament") && (
                      <div className="row">
                        <div className="text-right">
                          <a className="btn btn-success" onClick={handlequar}>
                            <i className="fa fa-user-plus"></i> Assign Quarter
                            Finals
                          </a>
                        </div>
                      </div>
                    )}

                  {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                    <div className="row">
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handlequar}>
                          <i className="fa fa-user-plus"></i> Assign Quarter
                          Finals
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="mt-4">
                    <div className="row">
                      <div className="text-center">
                        <h4>Quarter Final Matches</h4>
                      </div>
                    </div>

                    <div className="card" id="schedule_details">
                      <div className="card-body px-3 pt-4 pb-2 ">
                        <div className="row schedule_match">
                          <div className="col-md-1 align-self-center">
                            <div className="form-group">
                              <label className="form-label mt-0 text-center">
                                Match
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3 align-self-center">
                            <div className="form-group">
                              <label className="form-label mt-0">
                                Team VS Team
                              </label>
                            </div>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Date
                                  </label>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Time
                                  </label>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Winners
                                  </label>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Score
                                  </label>
                                </div>
                              </div>
                              {localStorage.getItem("Role") === "7" &&
                                (tcreate === localStorage.getItem("UID") ||
                                  ttype === "Intra School Tournament") && (
                                  <div className="col">
                                    <div className="form-group">
                                      <label className="form-label mt-0">
                                        Action
                                      </label>
                                    </div>
                                  </div>
                                )}
                              {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                                <div className="col">
                                  <div className="form-group">
                                    <label className="form-label mt-0">
                                      Action
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {quarProduct.length === 0 && (
                          <div className="row schedule_match">
                            <div className="col-md-1">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1 text-center">
                                    1
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1">
                                    TBC <span>VS</span> TBC
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1"></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1"></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1"></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1"></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1"></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {quarProduct.map((data1, j) => {
                          return (
                            <div key={j}>
                              <div className="row schedule_match">
                                <div className="col-md-1">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1 text-center">
                                        {j + 1}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.team1_name} <span>VS</span>{" "}
                                        {data1.team2_name}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          {data1.match_date !== null && (
                                            <li className="listunorder1">
                                              {moment(data1.match_date).format(
                                                "DD-MM-yyyy"
                                              )}
                                            </li>
                                          )}
                                          {data1.match_date === null && (
                                            <li className="listunorder1"></li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            {data1.match_time}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            {data1.winner_name}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            {data1.team1_score} -{" "}
                                            {data1.team2_score}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    {localStorage.getItem("Role") === "7" &&
                                      (tcreate ===
                                        localStorage.getItem("UID") ||
                                        ttype ===
                                          "Intra School Tournament") && (
                                        <div className="col">
                                          <div className="form-group">
                                            <ul className="list-group">
                                              <li className="listunorder1">
                                                <a
                                                  className="btn btn-sm btn-secondary me-2"
                                                  onClick={() =>
                                                    handleDate(
                                                      data1.id,
                                                      data1.match_date
                                                    )
                                                  }
                                                >
                                                  <i className="fe fe-watch fs-6"></i>{" "}
                                                </a>
                                                {/* <a
                                    className="btn btn-sm btn-success"
                                    onClick={() =>
                                      handleWinner(
                                        data1.id,
                                        data1.team1_name,
                                        data1.team1_id,
                                        data1.team2_name,
                                        data1.team2_id
                                      )
                                    }
                                  >
                                    <i className="fe fe-target fs-6"></i>{" "}
                                  </a> */}
                                                {tsport.toUpperCase() !==
                                                  "VOLLEYBALL" &&
                                                  tsport.toUpperCase() !==
                                                    "THROWBALL" &&
                                                  tsport.toUpperCase() !==
                                                    "SOFTBALL" &&
                                                  tsport.toUpperCase() !==
                                                    "KHOKHO" &&
                                                  tsport.toUpperCase() !==
                                                    "BADMINTON" &&
                                                  tsport.toUpperCase() !==
                                                    "TENNIS" &&
                                                  tsport.toUpperCase() !==
                                                    "TABLE TENNIS" &&
                                                  tsport.toUpperCase() !==
                                                    "SQUASH" && (
                                                    <a
                                                      className="btn btn-sm btn-success me-2"
                                                      onClick={() =>
                                                        handleWinner(
                                                          data1.id,
                                                          data1.team1_name,
                                                          data1.team1_id,
                                                          data1.team2_name,
                                                          data1.team2_id
                                                        )
                                                      }
                                                    >
                                                      <i className="fe fe-target fs-6"></i>{" "}
                                                    </a>
                                                  )}

                                                {(tsport.toUpperCase() ===
                                                  "VOLLEYBALL" ||
                                                  tsport.toUpperCase() ===
                                                    "THROWBALL" ||
                                                  tsport.toUpperCase() ===
                                                    "SOFTBALL" ||
                                                  tsport.toUpperCase() ===
                                                    "KHOKHO" ||
                                                  tsport.toUpperCase() ===
                                                    "BADMINTON" ||
                                                  tsport.toUpperCase() ===
                                                    "TENNIS" ||
                                                  tsport.toUpperCase() ===
                                                    "TABLE TENNIS" ||
                                                  tsport.toUpperCase() ===
                                                    "SQUASH") && (
                                                  <a
                                                    className="btn btn-sm btn-warning me-2"
                                                    onClick={() =>
                                                      handleVolley(
                                                        data1.id,
                                                        data1.team1_name,
                                                        data1.team1_id,
                                                        data1.team2_name,
                                                        data1.team2_id,
                                                        data1.meet_pool_id,
                                                        data1.total_sets,
                                                        2
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                                  </a>
                                                )}
                                                {semiProduct.length === 0 &&
                                                  localStorage.getItem(
                                                    "Role"
                                                  ) === "1" && (
                                                    <a
                                                      className="btn btn-sm btn-primary"
                                                      onClick={() =>
                                                        handleDelete(data1.id)
                                                      }
                                                    >
                                                      <i className="fa fa-trash fs-6"></i>{" "}
                                                    </a>
                                                  )}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                                      <div className="col">
                                        <div className="form-group">
                                          <ul className="list-group">
                                            <li className="listunorder1">
                                              <a
                                                className="btn btn-sm btn-secondary me-2"
                                                onClick={() =>
                                                  handleDate(
                                                    data1.id,
                                                    data1.match_date
                                                  )
                                                }
                                              >
                                                <i className="fe fe-watch fs-6"></i>{" "}
                                              </a>
                                              {/* <a
                                   className="btn btn-sm btn-success"
                                   onClick={() =>
                                     handleWinner(
                                       data1.id,
                                       data1.team1_name,
                                       data1.team1_id,
                                       data1.team2_name,
                                       data1.team2_id
                                     )
                                   }
                                 >
                                   <i className="fe fe-target fs-6"></i>{" "}
                                 </a> */}
                                              {tsport.toUpperCase() !==
                                                "VOLLEYBALL" &&
                                                tsport.toUpperCase() !==
                                                  "THROWBALL" &&
                                                tsport.toUpperCase() !==
                                                  "SOFTBALL" &&
                                                tsport.toUpperCase() !==
                                                  "KHOKHO" &&
                                                tsport.toUpperCase() !==
                                                  "BADMINTON" &&
                                                tsport.toUpperCase() !==
                                                  "TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "TABLE TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "SQUASH" && (
                                                  <a
                                                    className="btn btn-sm btn-success me-2"
                                                    onClick={() =>
                                                      handleWinner(
                                                        data1.id,
                                                        data1.team1_name,
                                                        data1.team1_id,
                                                        data1.team2_name,
                                                        data1.team2_id
                                                      )
                                                    }
                                                  >
                                                    <i className="fe fe-target fs-6"></i>{" "}
                                                  </a>
                                                )}

                                              {(tsport.toUpperCase() ===
                                                "VOLLEYBALL" ||
                                                tsport.toUpperCase() ===
                                                  "THROWBALL" ||
                                                tsport.toUpperCase() ===
                                                  "SOFTBALL" ||
                                                tsport.toUpperCase() ===
                                                  "KHOKHO" ||
                                                tsport.toUpperCase() ===
                                                  "BADMINTON" ||
                                                tsport.toUpperCase() ===
                                                  "TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "TABLE TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "SQUASH") && (
                                                <a
                                                  className="btn btn-sm btn-warning me-2"
                                                  onClick={() =>
                                                    handleVolley(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id,
                                                      data1.meet_pool_id,
                                                      data1.total_sets,
                                                      2
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                                </a>
                                              )}
                                              {semiProduct.length === 0 &&
                                                localStorage.getItem("Role") ===
                                                  "1" && (
                                                  <a
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() =>
                                                      handleDelete(data1.id)
                                                    }
                                                  >
                                                    <i className="fa fa-trash fs-6"></i>{" "}
                                                  </a>
                                                )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {(tsport.toUpperCase() === "VOLLEYBALL" ||
                                tsport.toUpperCase() === "THROWBALL" ||
                                tsport.toUpperCase() === "SOFTBALL" ||
                                tsport.toUpperCase() === "KHOKHO" ||
                                tsport.toUpperCase() === "BADMINTON" ||
                                tsport.toUpperCase() === "TENNIS" ||
                                tsport.toUpperCase() === "TABLE TENNIS" ||
                                tsport.toUpperCase() === "SQUASH") && (
                                <div className="row schedule_match">
                                  <div className="col-md-1"></div>
                                  <div className="col-md-11">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.sets_list}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={quarshow} size="lg" onHide={closequar}>
                <Modal.Header>
                  <h4> Add Teams For Quarter Finals</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    {quarlist.map((data, i) => {
                      return (
                        <Col lg={12} md={12} key={i}>
                          <div className="form-group">
                            <label className="mb-2">
                              {data.pool_name}
                              <span className="errortext"> *</span>
                            </label>

                            <select
                              className="form-control"
                              value={data.teamsele}
                              onChange={(e) => quarteams(i, e.target.value)}
                            >
                              <option value="" disabled>
                                Select Teams
                              </option>
                              {data.teams.map((data1, j) => {
                                return (
                                  <option
                                    value={data1.pool_mapped_teams_id}
                                    key={j}
                                  >
                                    {data1.teams_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>

                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Date
                          <span className="errortext"> *</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          min={mindate}
                          max={maxdate}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                          value={mdate}
                          onChange={(e) => setMatchDate(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="row-sm mx-0">
                    <div className="form-group">
                      <label className="">
                        Time
                        <span className="errortext"> *</span>
                      </label>
                    </div>
                  </Row>

                  <Row className="row-sm mx-0">
                    <Col lg={4} md={4}>
                      <div className="form-group">
                        <label className="">Hours</label>

                        <select
                          className="form-control"
                          value={DHour}
                          onChange={(e) => setHour(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Hour
                          </option>
                          {datehr.map((data, i) => {
                            return (
                              <option value={data} key={i}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="form-group">
                        <label className="">Minutes</label>
                        <select
                          className="form-control"
                          value={DSec}
                          onChange={(e) => setSecond(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Minutes
                          </option>
                          {datesec.map((data, i) => {
                            return (
                              <option value={data} key={i}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="form-group">
                        <label className="">AM/PM</label>
                        <select
                          className="form-control"
                          value={DFor}
                          onChange={(e) => setFormat(e.target.value)}
                        >
                          <option value="" disabled>
                            Select AM/PM
                          </option>

                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-success" onClick={addquar}>
                    Add
                  </a>

                  <a className="btn btn-secondary" onClick={closequar}>
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>
            </>
          )}
          {/* </>
          )} */}
          {qualchk >= "1" && qualchk < "3" && (
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  {localStorage.getItem("Role") === "7" &&
                    (tcreate === localStorage.getItem("UID") ||
                      ttype === "Intra School Tournament") &&
                    semiProduct.length < 2 && (
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handlesemi}>
                          <i className="fa fa-user-plus"></i> Assign Semi Finals
                        </a>
                      </div>
                    )}

                  {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&
                    semiProduct.length < 2 && (
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handlesemi}>
                          <i className="fa fa-user-plus"></i> Assign Semi Finals
                        </a>
                      </div>
                    )}
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="text-center">
                      <h4>Semi Final Matches</h4>
                    </div>
                  </div>

                  <div className="card" id="schedule_details">
                    <div className="card-body px-3 pt-4 pb-2 ">
                      <div className="row schedule_match">
                        <div className="col-md-1 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0 text-center">
                              Match
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">
                              Team VS Team
                            </label>
                          </div>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Date</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Time</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">
                                  Winners
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Score</label>
                              </div>
                            </div>
                            {localStorage.getItem("Role") === "7" &&
                              (tcreate === localStorage.getItem("UID") ||
                                ttype === "Intra School Tournament") && (
                                <div className="col">
                                  <div className="form-group">
                                    <label className="form-label mt-0">
                                      Action
                                    </label>
                                  </div>
                                </div>
                              )}
                            {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Action
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {semiProduct.length === 0 && (
                        <div className="row schedule_match">
                          <div className="col-md-1">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1 text-center">1</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">
                                  TBC <span>VS</span> TBC
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {semiProduct.map((data2, j) => {
                        return (
                          <div key={j}>
                            <div className="row schedule_match">
                              <div className="col-md-1">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1 text-center">
                                      {j + 1}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1">
                                      {data2.team1_name} <span>VS</span>{" "}
                                      {data2.team2_name}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        {data2.match_date !== null && (
                                          <li className="listunorder1">
                                            {moment(data2.match_date).format(
                                              "DD-MM-yyyy"
                                            )}
                                          </li>
                                        )}
                                        {data2.match_date === null && (
                                          <li className="listunorder1"></li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data2.match_time}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data2.winner_name}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data2.team1_score} -{" "}
                                          {data2.team2_score}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  {localStorage.getItem("Role") === "7" &&
                                    (tcreate === localStorage.getItem("UID") ||
                                      ttype === "Intra School Tournament") && (
                                      <div className="col">
                                        <div className="form-group">
                                          <ul className="list-group">
                                            <li className="listunorder1">
                                              <a
                                                className="btn btn-sm btn-secondary me-2 mt-1"
                                                onClick={() =>
                                                  handleDate(
                                                    data2.id,
                                                    data2.match_date
                                                  )
                                                }
                                              >
                                                <i className="fe fe-watch fs-6"></i>{" "}
                                              </a>
                                              {/*<a
                                    className="btn btn-sm btn-success me-2"
                                    onClick={() =>
                                      handleWinner(
                                        data2.id,
                                        data2.team1_name,
                                        data2.team1_id,
                                        data2.team2_name,
                                        data2.team2_id
                                      )
                                    }
                                  >
                                    <i className="fe fe-target fs-6"></i>{" "}
                                  </a> */}
                                              {tsport.toUpperCase() !==
                                                "VOLLEYBALL" &&
                                                tsport.toUpperCase() !==
                                                  "THROWBALL" &&
                                                tsport.toUpperCase() !==
                                                  "SOFTBALL" &&
                                                tsport.toUpperCase() !==
                                                  "KHOKHO" &&
                                                tsport.toUpperCase() !==
                                                  "BADMINTON" &&
                                                tsport.toUpperCase() !==
                                                  "TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "TABLE TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "SQUASH" && (
                                                  <a
                                                    className="btn btn-sm btn-success me-2 mt-1"
                                                    onClick={() =>
                                                      handleWinner(
                                                        data2.id,
                                                        data2.team1_name,
                                                        data2.team1_id,
                                                        data2.team2_name,
                                                        data2.team2_id
                                                      )
                                                    }
                                                  >
                                                    <i className="fe fe-target fs-6"></i>{" "}
                                                  </a>
                                                )}

                                              {(tsport.toUpperCase() ===
                                                "VOLLEYBALL" ||
                                                tsport.toUpperCase() ===
                                                  "THROWBALL" ||
                                                tsport.toUpperCase() ===
                                                  "SOFTBALL" ||
                                                tsport.toUpperCase() ===
                                                  "KHOKHO" ||
                                                tsport.toUpperCase() ===
                                                  "BADMINTON" ||
                                                tsport.toUpperCase() ===
                                                  "TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "TABLE TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "SQUASH") && (
                                                <a
                                                  className="btn btn-sm btn-warning me-2 mt-1"
                                                  onClick={() =>
                                                    handleVolley(
                                                      data2.id,
                                                      data2.team1_name,
                                                      data2.team1_id,
                                                      data2.team2_name,
                                                      data2.team2_id,
                                                      data2.meet_pool_id,
                                                      data2.total_sets,
                                                      3
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                                </a>
                                              )}
                                              {finalProduct.length === 0 &&
                                                localStorage.getItem("Role") ===
                                                  "1" && (
                                                  <a
                                                    className="btn btn-sm btn-primary mt-1"
                                                    onClick={() =>
                                                      handleDelete(data2.id)
                                                    }
                                                  >
                                                    <i className="fa fa-trash fs-6"></i>{" "}
                                                  </a>
                                                )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            <a
                                              className="btn btn-sm btn-secondary me-2 mt-1"
                                              onClick={() =>
                                                handleDate(
                                                  data2.id,
                                                  data2.match_date
                                                )
                                              }
                                            >
                                              <i className="fe fe-watch fs-6"></i>{" "}
                                            </a>
                                            {/*<a
                                   className="btn btn-sm btn-success me-2"
                                   onClick={() =>
                                     handleWinner(
                                       data2.id,
                                       data2.team1_name,
                                       data2.team1_id,
                                       data2.team2_name,
                                       data2.team2_id
                                     )
                                   }
                                 >
                                   <i className="fe fe-target fs-6"></i>{" "}
                                 </a> */}
                                            {tsport.toUpperCase() !==
                                              "VOLLEYBALL" &&
                                              tsport.toUpperCase() !==
                                                "THROWBALL" &&
                                              tsport.toUpperCase() !==
                                                "SOFTBALL" &&
                                              tsport.toUpperCase() !==
                                                "KHOKHO" &&
                                              tsport.toUpperCase() !==
                                                "BADMINTON" &&
                                              tsport.toUpperCase() !==
                                                "TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "TABLE TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "SQUASH" && (
                                                <a
                                                  className="btn btn-sm btn-success me-2 mt-1"
                                                  onClick={() =>
                                                    handleWinner(
                                                      data2.id,
                                                      data2.team1_name,
                                                      data2.team1_id,
                                                      data2.team2_name,
                                                      data2.team2_id
                                                    )
                                                  }
                                                >
                                                  <i className="fe fe-target fs-6"></i>{" "}
                                                </a>
                                              )}

                                            {(tsport.toUpperCase() ===
                                              "VOLLEYBALL" ||
                                              tsport.toUpperCase() ===
                                                "THROWBALL" ||
                                              tsport.toUpperCase() ===
                                                "SOFTBALL" ||
                                              tsport.toUpperCase() ===
                                                "KHOKHO" ||
                                              tsport.toUpperCase() ===
                                                "BADMINTON" ||
                                              tsport.toUpperCase() ===
                                                "TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "TABLE TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "SQUASH") && (
                                              <a
                                                className="btn btn-sm btn-warning me-2 mt-1"
                                                onClick={() =>
                                                  handleVolley(
                                                    data2.id,
                                                    data2.team1_name,
                                                    data2.team1_id,
                                                    data2.team2_name,
                                                    data2.team2_id,
                                                    data2.meet_pool_id,
                                                    data2.total_sets,
                                                    3
                                                  )
                                                }
                                              >
                                                <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                              </a>
                                            )}
                                            {finalProduct.length === 0 &&
                                              localStorage.getItem("Role") ===
                                                "1" && (
                                                <a
                                                  className="btn btn-sm btn-primary mt-1"
                                                  onClick={() =>
                                                    handleDelete(data2.id)
                                                  }
                                                >
                                                  <i className="fa fa-trash fs-6"></i>{" "}
                                                </a>
                                              )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {(tsport.toUpperCase() === "VOLLEYBALL" ||
                              tsport.toUpperCase() === "THROWBALL" ||
                              tsport.toUpperCase() === "SOFTBALL" ||
                              tsport.toUpperCase() === "KHOKHO" ||
                              tsport.toUpperCase() === "BADMINTON" ||
                              tsport.toUpperCase() === "TENNIS" ||
                              tsport.toUpperCase() === "TABLE TENNIS" ||
                              tsport.toUpperCase() === "SQUASH") && (
                              <div className="row schedule_match">
                                <div className="col-md-1"></div>
                                <div className="col-md-11">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data2.sets_list}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Modal show={semishow} size="lg" onHide={closesemi}>
            <Modal.Header>
              <h4> Add Teams For Semi Finals</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                {semilist.map((data, i) => {
                  return (
                    <Col lg={12} md={12} key={i}>
                      <div className="form-group">
                        <label className="mb-2">
                          {data.pool_name}
                          <span className="errortext"> *</span>
                        </label>

                        <select
                          className="form-control"
                          value={data.teamsele}
                          onChange={(e) => semiteams(i, e.target.value)}
                        >
                          <option value="">Select Teams</option>
                          {data.teams.map((data1, j) => {
                            return (
                              <option
                                value={data1.pool_mapped_teams_id}
                                key={j}
                              >
                                {data1.teams_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Date
                      <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      min={mindate}
                      max={maxdate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                      value={mdate}
                      onChange={(e) => setMatchDate(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="row-sm mx-0">
                <div className="form-group">
                  <label className="">
                    Time
                    <span className="errortext"> *</span>
                  </label>
                </div>
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Hours</label>

                    <select
                      className="form-control"
                      value={DHour}
                      onChange={(e) => setHour(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Hour
                      </option>
                      {datehr.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Minutes</label>
                    <select
                      className="form-control"
                      value={DSec}
                      onChange={(e) => setSecond(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Minutes
                      </option>
                      {datesec.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">AM/PM</label>
                    <select
                      className="form-control"
                      value={DFor}
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <option value="" disabled>
                        Select AM/PM
                      </option>

                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addsemi}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={closesemi}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>
          {qualchk >= "1" && qualchk !== "4" && (
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  <div className="text-right">
                    {localStorage.getItem("Role") === "7" &&
                      (tcreate === localStorage.getItem("UID") ||
                        ttype === "Intra School Tournament") &&
                      ofinalProduct.length === 0 && (
                        <a
                          className="btn btn-secondary me-2 mt-1"
                          onClick={handleofinal}
                        >
                          <i className="fa fa-user-plus"></i> Assign 3rd Place
                        </a>
                      )}
                    {localStorage.getItem("Role") === "7" &&
                      (tcreate === localStorage.getItem("UID") ||
                        ttype === "Intra School Tournament") &&
                      finalProduct.length === 0 && (
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={handlefinal}
                        >
                          <i className="fa fa-user-plus"></i> Assign Finals
                        </a>
                      )}

                    {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&
                      ofinalProduct.length === 0 && (
                        <a
                          className="btn btn-secondary me-2 mt-1"
                          onClick={handleofinal}
                        >
                          <i className="fa fa-user-plus"></i> Assign 3rd Place
                        </a>
                      )}
                    {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&
                      finalProduct.length === 0 && (
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={handlefinal}
                        >
                          <i className="fa fa-user-plus"></i> Assign Finals
                        </a>
                      )}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row">
                    <div className="text-center">
                      <h4>3rd Place</h4>
                    </div>
                  </div>

                  <div className="card" id="schedule_details">
                    <div className="card-body px-3 pt-4 pb-2 ">
                      <div className="row schedule_match">
                        <div className="col-md-1 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0 text-center">
                              Match
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">
                              Team VS Team
                            </label>
                          </div>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Date</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Time</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">
                                  Winners
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Score</label>
                              </div>
                            </div>
                            {localStorage.getItem("Role") === "7" &&
                              (tcreate === localStorage.getItem("UID") ||
                                ttype === "Intra School Tournament") && (
                                <div className="col">
                                  <div className="form-group">
                                    <label className="form-label mt-0">
                                      Action
                                    </label>
                                  </div>
                                </div>
                              )}
                            {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Action
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {ofinalProduct.length === 0 && (
                        <div className="row schedule_match">
                          <div className="col-md-1">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1 text-center">1</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">
                                  TBC <span>VS</span> TBC
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {ofinalProduct.map((data1, j) => {
                        return (
                          <div key={j}>
                            <div className="row schedule_match">
                              <div className="col-md-1">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1 text-center">
                                      {j + 1}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1">
                                      {data1.team1_name} <span>VS</span>{" "}
                                      {data1.team2_name}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        {data1.match_date !== null && (
                                          <li className="listunorder1">
                                            {moment(data1.match_date).format(
                                              "DD-MM-yyyy"
                                            )}
                                          </li>
                                        )}
                                        {data1.match_date === null && (
                                          <li className="listunorder1"></li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.match_time}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.winner_name}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.team1_score} -{" "}
                                          {data1.team2_score}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  {localStorage.getItem("Role") === "7" &&
                                    (tcreate === localStorage.getItem("UID") ||
                                      ttype === "Intra School Tournament") && (
                                      <div className="col">
                                        <div className="form-group">
                                          <ul className="list-group">
                                            <li className="listunorder1">
                                              <a
                                                className="btn btn-sm btn-secondary me-2 mt-1"
                                                onClick={() =>
                                                  handleDate(
                                                    data1.id,
                                                    data1.match_date
                                                  )
                                                }
                                              >
                                                <i className="fe fe-watch fs-6"></i>{" "}
                                              </a>

                                              {tsport.toUpperCase() !==
                                                "VOLLEYBALL" &&
                                                tsport.toUpperCase() !==
                                                  "THROWBALL" &&
                                                tsport.toUpperCase() !==
                                                  "SOFTBALL" &&
                                                tsport.toUpperCase() !==
                                                  "KHOKHO" &&
                                                tsport.toUpperCase() !==
                                                  "BADMINTON" &&
                                                tsport.toUpperCase() !==
                                                  "TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "TABLE TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "SQUASH" && (
                                                  <a
                                                    className="btn btn-sm btn-success me-2 mt-1"
                                                    onClick={() =>
                                                      handleWinner(
                                                        data1.id,
                                                        data1.team1_name,
                                                        data1.team1_id,
                                                        data1.team2_name,
                                                        data1.team2_id
                                                      )
                                                    }
                                                  >
                                                    <i className="fe fe-target fs-6"></i>{" "}
                                                  </a>
                                                )}

                                              {(tsport.toUpperCase() ===
                                                "VOLLEYBALL" ||
                                                tsport.toUpperCase() ===
                                                  "THROWBALL" ||
                                                tsport.toUpperCase() ===
                                                  "SOFTBALL" ||
                                                tsport.toUpperCase() ===
                                                  "KHOKHO" ||
                                                tsport.toUpperCase() ===
                                                  "BADMINTON" ||
                                                tsport.toUpperCase() ===
                                                  "TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "TABLE TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "SQUASH") && (
                                                <a
                                                  className="btn btn-sm btn-warning me-2 mt-1"
                                                  onClick={() =>
                                                    handleVolley(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id,
                                                      data1.meet_pool_id,
                                                      data1.total_sets,
                                                      4
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                                </a>
                                              )}

                                              {moment(new Date()).format(
                                                "yyyy-MM-DD"
                                              ) <= maxdate && (
                                                <a
                                                  className="btn btn-sm btn-primary mt-1"
                                                  onClick={() =>
                                                    handleDelete(data1.id)
                                                  }
                                                >
                                                  <i className="fa fa-trash fs-6"></i>{" "}
                                                </a>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            <a
                                              className="btn btn-sm btn-secondary me-2 mt-1"
                                              onClick={() =>
                                                handleDate(
                                                  data1.id,
                                                  data1.match_date
                                                )
                                              }
                                            >
                                              <i className="fe fe-watch fs-6"></i>{" "}
                                            </a>

                                            {tsport.toUpperCase() !==
                                              "VOLLEYBALL" &&
                                              tsport.toUpperCase() !==
                                                "THROWBALL" &&
                                              tsport.toUpperCase() !==
                                                "SOFTBALL" &&
                                              tsport.toUpperCase() !==
                                                "KHOKHO" &&
                                              tsport.toUpperCase() !==
                                                "BADMINTON" &&
                                              tsport.toUpperCase() !==
                                                "TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "TABLE TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "SQUASH" && (
                                                <a
                                                  className="btn btn-sm btn-success me-2 mt-1"
                                                  onClick={() =>
                                                    handleWinner(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id
                                                    )
                                                  }
                                                >
                                                  <i className="fe fe-target fs-6"></i>{" "}
                                                </a>
                                              )}

                                            {(tsport.toUpperCase() ===
                                              "VOLLEYBALL" ||
                                              tsport.toUpperCase() ===
                                                "THROWBALL" ||
                                              tsport.toUpperCase() ===
                                                "SOFTBALL" ||
                                              tsport.toUpperCase() ===
                                                "KHOKHO" ||
                                              tsport.toUpperCase() ===
                                                "BADMINTON" ||
                                              tsport.toUpperCase() ===
                                                "TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "TABLE TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "SQUASH") && (
                                              <a
                                                className="btn btn-sm btn-warning me-2 mt-1"
                                                onClick={() =>
                                                  handleVolley(
                                                    data1.id,
                                                    data1.team1_name,
                                                    data1.team1_id,
                                                    data1.team2_name,
                                                    data1.team2_id,
                                                    data1.meet_pool_id,
                                                    data1.total_sets,
                                                    4
                                                  )
                                                }
                                              >
                                                <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                              </a>
                                            )}

                                            {moment(new Date()).format(
                                              "yyyy-MM-DD"
                                            ) <= maxdate && (
                                              <a
                                                className="btn btn-sm btn-primary mt-1"
                                                onClick={() =>
                                                  handleDelete(data1.id)
                                                }
                                              >
                                                <i className="fa fa-trash fs-6"></i>{" "}
                                              </a>
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {(tsport.toUpperCase() === "VOLLEYBALL" ||
                              tsport.toUpperCase() === "THROWBALL" ||
                              tsport.toUpperCase() === "SOFTBALL" ||
                              tsport.toUpperCase() === "KHOKHO" ||
                              tsport.toUpperCase() === "BADMINTON" ||
                              tsport.toUpperCase() === "TENNIS" ||
                              tsport.toUpperCase() === "TABLE TENNIS" ||
                              tsport.toUpperCase() === "SQUASH") && (
                              <div className="row schedule_match">
                                <div className="col-md-1"></div>
                                <div className="col-md-11">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.sets_list}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row">
                    <div className="text-center">
                      <h4>Final Matches</h4>
                    </div>
                  </div>

                  <div className="card" id="schedule_details">
                    <div className="card-body px-3 pt-4 pb-2 ">
                      <div className="row schedule_match">
                        <div className="col-md-1 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0 text-center">
                              Match
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">
                              Team VS Team
                            </label>
                          </div>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Date</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Time</label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">
                                  Winners
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label mt-0">Score</label>
                              </div>
                            </div>
                            {localStorage.getItem("Role") === "7" &&
                              (tcreate === localStorage.getItem("UID") ||
                                ttype === "Intra School Tournament") && (
                                <div className="col">
                                  <div className="form-group">
                                    <label className="form-label mt-0">
                                      Action
                                    </label>
                                  </div>
                                </div>
                              )}
                            {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label mt-0">
                                    Action
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {finalProduct.length === 0 && (
                        <div className="row schedule_match">
                          <div className="col-md-1">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1 text-center">1</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">
                                  TBC <span>VS</span> TBC
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1"></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {finalProduct.map((data1, j) => {
                        return (
                          <div key={j}>
                            <div className="row schedule_match">
                              <div className="col-md-1">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1 text-center">
                                      {j + 1}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1">
                                      {data1.team1_name} <span>VS</span>{" "}
                                      {data1.team2_name}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        {data1.match_date !== null && (
                                          <li className="listunorder1">
                                            {moment(data1.match_date).format(
                                              "DD-MM-yyyy"
                                            )}
                                          </li>
                                        )}
                                        {data1.match_date === null && (
                                          <li className="listunorder1"></li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.match_time}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.winner_name}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <ul className="list-group">
                                        <li className="listunorder1">
                                          {data1.team1_score} -{" "}
                                          {data1.team2_score}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  {localStorage.getItem("Role") === "7" &&
                                    (tcreate === localStorage.getItem("UID") ||
                                      ttype === "Intra School Tournament") && (
                                      <div className="col">
                                        <div className="form-group">
                                          <ul className="list-group">
                                            <li className="listunorder1">
                                              <a
                                                className="btn btn-sm btn-secondary me-2 mt-1"
                                                onClick={() =>
                                                  handleDate(
                                                    data1.id,
                                                    data1.match_date
                                                  )
                                                }
                                              >
                                                <i className="fe fe-watch fs-6"></i>{" "}
                                              </a>
                                              {/* <a
                                          className="btn btn-sm btn-success me-2"
                                          onClick={() =>
                                            handleWinner(
                                              data1.id,
                                              data1.team1_name,
                                              data1.team1_id,
                                              data1.team2_name,
                                              data1.team2_id
                                            )
                                          }
                                        >
                                          <i className="fe fe-target fs-6"></i>{" "}
                                        </a> */}
                                              {tsport.toUpperCase() !==
                                                "VOLLEYBALL" &&
                                                tsport.toUpperCase() !==
                                                  "THROWBALL" &&
                                                tsport.toUpperCase() !==
                                                  "SOFTBALL" &&
                                                tsport.toUpperCase() !==
                                                  "KHOKHO" &&
                                                tsport.toUpperCase() !==
                                                  "BADMINTON" &&
                                                tsport.toUpperCase() !==
                                                  "TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "TABLE TENNIS" &&
                                                tsport.toUpperCase() !==
                                                  "SQUASH" && (
                                                  <a
                                                    className="btn btn-sm btn-success me-2 mt-1"
                                                    onClick={() =>
                                                      handleWinner(
                                                        data1.id,
                                                        data1.team1_name,
                                                        data1.team1_id,
                                                        data1.team2_name,
                                                        data1.team2_id
                                                      )
                                                    }
                                                  >
                                                    <i className="fe fe-target fs-6"></i>{" "}
                                                  </a>
                                                )}

                                              {(tsport.toUpperCase() ===
                                                "VOLLEYBALL" ||
                                                tsport.toUpperCase() ===
                                                  "THROWBALL" ||
                                                tsport.toUpperCase() ===
                                                  "SOFTBALL" ||
                                                tsport.toUpperCase() ===
                                                  "KHOKHO" ||
                                                tsport.toUpperCase() ===
                                                  "BADMINTON" ||
                                                tsport.toUpperCase() ===
                                                  "TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "TABLE TENNIS" ||
                                                tsport.toUpperCase() ===
                                                  "SQUASH") && (
                                                <a
                                                  className="btn btn-sm btn-warning me-2 mt-1"
                                                  onClick={() =>
                                                    handleVolley(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id,
                                                      data1.meet_pool_id,
                                                      data1.total_sets,
                                                      4
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                                </a>
                                              )}

                                              {moment(new Date()).format(
                                                "yyyy-MM-DD"
                                              ) <= maxdate && (
                                                <a
                                                  className="btn btn-sm btn-primary mt-1"
                                                  onClick={() =>
                                                    handleDelete(data1.id)
                                                  }
                                                >
                                                  <i className="fa fa-trash fs-6"></i>{" "}
                                                </a>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&(
                                    <div className="col">
                                      <div className="form-group">
                                        <ul className="list-group">
                                          <li className="listunorder1">
                                            <a
                                              className="btn btn-sm btn-secondary me-2 mt-1"
                                              onClick={() =>
                                                handleDate(
                                                  data1.id,
                                                  data1.match_date
                                                )
                                              }
                                            >
                                              <i className="fe fe-watch fs-6"></i>{" "}
                                            </a>
                                            {/* <a
                                  className="btn btn-sm btn-success me-2"
                                  onClick={() =>
                                    handleWinner(
                                      data1.id,
                                      data1.team1_name,
                                      data1.team1_id,
                                      data1.team2_name,
                                      data1.team2_id
                                    )
                                  }
                                >
                                  <i className="fe fe-target fs-6"></i>{" "}
                                </a> */}
                                            {tsport.toUpperCase() !==
                                              "VOLLEYBALL" &&
                                              tsport.toUpperCase() !==
                                                "THROWBALL" &&
                                              tsport.toUpperCase() !==
                                                "SOFTBALL" &&
                                              tsport.toUpperCase() !==
                                                "KHOKHO" &&
                                              tsport.toUpperCase() !==
                                                "BADMINTON" &&
                                              tsport.toUpperCase() !==
                                                "TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "TABLE TENNIS" &&
                                              tsport.toUpperCase() !==
                                                "SQUASH" && (
                                                <a
                                                  className="btn btn-sm btn-success me-2 mt-1"
                                                  onClick={() =>
                                                    handleWinner(
                                                      data1.id,
                                                      data1.team1_name,
                                                      data1.team1_id,
                                                      data1.team2_name,
                                                      data1.team2_id
                                                    )
                                                  }
                                                >
                                                  <i className="fe fe-target fs-6"></i>{" "}
                                                </a>
                                              )}

                                            {(tsport.toUpperCase() ===
                                              "VOLLEYBALL" ||
                                              tsport.toUpperCase() ===
                                                "THROWBALL" ||
                                              tsport.toUpperCase() ===
                                                "SOFTBALL" ||
                                              tsport.toUpperCase() ===
                                                "KHOKHO" ||
                                              tsport.toUpperCase() ===
                                                "BADMINTON" ||
                                              tsport.toUpperCase() ===
                                                "TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "TABLE TENNIS" ||
                                              tsport.toUpperCase() ===
                                                "SQUASH") && (
                                              <a
                                                className="btn btn-sm btn-warning me-2 mt-1"
                                                onClick={() =>
                                                  handleVolley(
                                                    data1.id,
                                                    data1.team1_name,
                                                    data1.team1_id,
                                                    data1.team2_name,
                                                    data1.team2_id,
                                                    data1.meet_pool_id,
                                                    data1.total_sets,
                                                    4
                                                  )
                                                }
                                              >
                                                <i className="fas fa-volleyball-ball fs-6"></i>{" "}
                                              </a>
                                            )}

                                            {moment(new Date()).format(
                                              "yyyy-MM-DD"
                                            ) <= maxdate && (
                                              <a
                                                className="btn btn-sm btn-primary mt-1"
                                                onClick={() =>
                                                  handleDelete(data1.id)
                                                }
                                              >
                                                <i className="fa fa-trash fs-6"></i>{" "}
                                              </a>
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {(tsport.toUpperCase() === "VOLLEYBALL" ||
                              tsport.toUpperCase() === "THROWBALL" ||
                              tsport.toUpperCase() === "SOFTBALL" ||
                              tsport.toUpperCase() === "KHOKHO" ||
                              tsport.toUpperCase() === "BADMINTON" ||
                              tsport.toUpperCase() === "TENNIS" ||
                              tsport.toUpperCase() === "TABLE TENNIS" ||
                              tsport.toUpperCase() === "SQUASH") && (
                              <div className="row schedule_match">
                                <div className="col-md-1"></div>
                                <div className="col-md-11">
                                  <div className="form-group">
                                    <ul className="list-group">
                                      <li className="listunorder1">
                                        {data1.sets_list}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Modal show={finalshow} size="lg" onHide={closefinal}>
            <Modal.Header>
              <h4> Add Teams For Finals</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                {finallist.map((data, i) => {
                  return (
                    <Col lg={12} md={12} key={i}>
                      <div className="form-group">
                        <label className="mb-2">
                          {data.pool_name}
                          {data.pool_name !== "Third Place" &&
                            data.pool_name !== "Fourth Place" && (
                              <span className="errortext"> *</span>
                            )}
                        </label>

                        <select
                          className="form-control"
                          value={data.teamsele}
                          onChange={(e) => finalteams(i, e.target.value)}
                        >
                          <option value="" disabled>
                            Select Teams
                          </option>
                          {data.teams.map((data1, j) => {
                            return (
                              <option
                                value={data1.pool_mapped_teams_id}
                                key={j}
                              >
                                {data1.teams_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Date
                      <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      min={mindate}
                      max={maxdate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                      value={mdate}
                      onChange={(e) => setMatchDate(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="row-sm mx-0">
                <div className="form-group">
                  <label className="">
                    Time
                    <span className="errortext"> *</span>
                  </label>
                </div>
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Hours</label>

                    <select
                      className="form-control"
                      value={DHour}
                      onChange={(e) => setHour(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Hour
                      </option>
                      {datehr.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Minutes</label>
                    <select
                      className="form-control"
                      value={DSec}
                      onChange={(e) => setSecond(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Minutes
                      </option>
                      {datesec.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">AM/PM</label>
                    <select
                      className="form-control"
                      value={DFor}
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <option value="" disabled>
                        Select AM/PM
                      </option>

                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addfinal}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={closefinal}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>

          <Modal show={ofinalshow} size="lg" onHide={closeofinal}>
            <Modal.Header>
              <h4> Add Teams For Third and Fourth</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                {ofinallist.map((data, i) => {
                  return (
                    <Col lg={12} md={12} key={i}>
                      <div className="form-group">
                        <label className="mb-2">
                          {data.pool_name}
                          {data.pool_name !== "Third Place" &&
                            data.pool_name !== "Fourth Place" && (
                              <span className="errortext"> *</span>
                            )}
                        </label>

                        <select
                          className="form-control"
                          value={data.teamsele}
                          onChange={(e) => finaloteams(i, e.target.value)}
                        >
                          <option value="" disabled>
                            Select Teams
                          </option>
                          {data.teams.map((data1, j) => {
                            return (
                              <option
                                value={data1.pool_mapped_teams_id}
                                key={j}
                              >
                                {data1.teams_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Date
                      <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      min={mindate}
                      max={maxdate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                      value={mdate}
                      onChange={(e) => setMatchDate(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="row-sm mx-0">
                <div className="form-group">
                  <label className="">
                    Time
                    <span className="errortext"> *</span>
                  </label>
                </div>
              </Row>

              <Row className="row-sm mx-0">
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Hours</label>

                    <select
                      className="form-control"
                      value={DHour}
                      onChange={(e) => setHour(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Hour
                      </option>
                      {datehr.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">Minutes</label>
                    <select
                      className="form-control"
                      value={DSec}
                      onChange={(e) => setSecond(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Minutes
                      </option>
                      {datesec.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="">AM/PM</label>
                    <select
                      className="form-control"
                      value={DFor}
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <option value="" disabled>
                        Select AM/PM
                      </option>

                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addofinal}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={closeofinal}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>

          {qualchk === "4" && (
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  {localStorage.getItem("Role") === "7" &&
                    (tcreate === localStorage.getItem("UID") ||
                      ttype === "Intra School Tournament") &&
                    !noknockshow && (
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handleknock}>
                          <i className="fa fa-user-plus"></i> Assign Winners
                        </a>
                      </div>
                    )}

                  {localStorage.getItem("Role") !== "7" && !noknockshow && localStorage.getItem("Role") !== "8" &&(
                    <div className="text-right">
                      <a className="btn btn-success" onClick={handleknock}>
                        <i className="fa fa-user-plus"></i> Assign Winners
                      </a>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="text-center">
                      <h4>
                        No Knockout Matches & Direct Winners from the group
                        Matches
                      </h4>
                    </div>
                  </div>

                  <div className="card" id="schedule_details">
                    <div className="card-body px-3 pt-4 pb-2 ">
                      <div className="row schedule_match">
                        <div className="col-md-1 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">SNo</label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">Winner</label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">Runner</label>
                          </div>
                        </div>
                        <div className="col-md-3 align-self-center">
                          <div className="form-group">
                            <label className="form-label mt-0">
                              2nd Runner
                            </label>
                          </div>
                        </div>
                        {localStorage.getItem("Role") === "7" &&
                          (tcreate === localStorage.getItem("UID") ||
                            ttype === "Intra School Tournament") && (
                            <div className="col-md-2 align-self-center">
                              <div className="form-group">
                                <label className="form-label mt-0">
                                  Action
                                </label>
                              </div>
                            </div>
                          )}
                        {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" &&(
                          <div className="col-md-2 align-self-center">
                            <div className="form-group">
                              <label className="form-label mt-0">Action</label>
                            </div>
                          </div>
                        )}
                      </div>
                      {!noknockshow && (
                        <div className="row schedule_match">
                          <div className="col-md-1">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">1</li>
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">TBC</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">TBC</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">TBC</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">-</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      {noknockshow && (
                        <div className="row schedule_match">
                          <div className="col-md-1">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1 text-center">1</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">{wteam}</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">{rteam}</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <ul className="list-group">
                                <li className="listunorder1">{srteam}</li>
                              </ul>
                            </div>
                          </div>

                          {localStorage.getItem("Role") === "7" &&
                            (tcreate === localStorage.getItem("UID") ||
                              ttype === "Intra School Tournament") && (
                              <div className="col-md-2">
                                <div className="form-group">
                                  <ul className="list-group">
                                    <li className="listunorder1">
                                      <a
                                        className="btn btn-sm btn-primary me-2"
                                        onClick={deleteknock}
                                      >
                                        <i className="fa fa-trash fs-6"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          {localStorage.getItem("Role") !== "7" && localStorage.getItem("Role") !== "8" && (
                            <div className="col-md-2">
                              <div className="form-group">
                                <ul className="list-group">
                                  <li className="listunorder1">
                                    <a
                                      className="btn btn-sm btn-primary me-2"
                                      onClick={deleteknock}
                                    >
                                      <i className="fa fa-trash fs-6"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Modal show={knockshow} size="lg" onHide={closeknock}>
            <Modal.Header>
              <h4> Add Winners</h4>
            </Modal.Header>
            <Modal.Body>
              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Winner
                      <span className="errortext"> *</span>
                    </label>

                    <Select
                      className="dropdown"
                      placeholder="Select Team"
                      value={wteam}
                      options={knocklist}
                      onChange={handleWin}
                      isSearchable={true}
                      isClearable
                      hidden
                    />
                  </div>
                </Col>
              </Row>
              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">Runner</label>

                    <Select
                      className="dropdown"
                      placeholder="Select Team"
                      value={rteam}
                      options={knocklist}
                      onChange={handleRun}
                      isSearchable={true}
                      isClearable
                      hidden
                    />
                  </div>
                </Col>
              </Row>
              <Row className="row-sm mx-0">
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">2nd Runner</label>

                    <Select
                      className="dropdown"
                      placeholder="Select Team"
                      value={srteam}
                      options={knocklist}
                      onChange={handleSRun}
                      isSearchable={true}
                      isClearable
                      hidden
                    />
                  </div>
                </Col>
              </Row>

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-success" onClick={addknock}>
                Add
              </a>

              <a className="btn btn-secondary" onClick={closeknock}>
                Cancel
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
