import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { Form } from "react-bootstrap";
import {
  achieveindupload,
  achieveplayersearch,
  achieveupload,
  achieveuploadlist,
  agecatactivelist,
  eventsmasterlist,
  schoolactivelist,
  sportsactivelist,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import exportUrl from "../../../../exportUtl";
import {
  coachagecatelist,
  coachsportslist,
  coachviewprofile,
} from "../../../../services/coach";
import Select from "react-select";
import {
  crmagecatelist,
  crmsportslist,
  schoollistcityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import { event } from "jquery";

export const AchievementUpload = () => {
  const navigate = useNavigate();
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");

  const [plist, setPList] = useState([]);
  const [plalist, setPlaList] = useState([]);

  const [schid, setSchool] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");
  const [failid, setFailId] = useState(0);

  const [urllink, setUrlLink] = useState("");
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Upload Excel",
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={() =>
                dotracking(
                  "click to download link in upload achievement for " +
                    params.value +
                    " (" +
                    params.data.id +
                    ") $$ " +
                    trackobj.achieveuploadheading
                )
              }
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      field: "id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const [s3path, setS3Path] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (link[1] === "achievementsupload") {
      if (localStorage.getItem("Role") === "7") {
        coachviewprofile().then((response) => {
          if (response.status === true) {
            setSchool(response.data.school_id);
          }
        });
      } else {
        setSchool(0);
      }
      listall();
    } else if (link[1] === "achievementindividual") {
      if (localStorage.getItem("Role") === "1") {
        schoolactivelist().then((response) => {
          if (response.status === true) {
            let arr = [];
            setSchList(response.data.details);

            if (response.data.details.length !== 0) {
              for (let i = 0; i < response.data.details.length; i++) {
                arr.push({
                  label:
                    response.data.details[i].school_unique_no +
                    " - " +
                    response.data.details[i].school_name,
                  value: response.data.details[i].id,
                });
              }
              setSList(arr);
            }
          }
        });

        sportsactivelist().then((response) => {
          if (response.status === true) {
            setSportList(response.data);
          }
        });

        agecatactivelist().then((response) => {
          if (response.status === true) {
            let arr = [];
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].id,
                  label: response.data[i].age_category_name,
                });
              }
            }
            setAgeCatList(arr);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        schoollistcityreg().then((response) => {
          if (response.status === true) {
            let arr1 = [];
            setSchList(response.data);
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr1.push({
                  value: response.data[i].id,
                  label:
                    response.data[i].school_unique_no +
                    " - " +
                    response.data[i].school_name,
                });
              }
            }
            setSList(arr1);
          }
        });

        crmsportslist().then((response) => {
          if (response.status === true) {
            setSportList(response.data);
          }
        });

        crmagecatelist().then((response) => {
          if (response.status === true) {
            let arr = [];
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].id,
                  label: response.data[i].age_category_name,
                });
              }
            }
            setAgeCatList(arr);
          }
        });
      } else if (localStorage.getItem("Role") === "7") {
        coachviewprofile(localStorage.getItem("UID")).then((response) => {
          if (response.status === true) {
            let arr1 = [];
            setSchList("");

            arr1.push({
              value: response.data.school_id,
              label:
                response.data.school_unique_no +
                " - " +
                response.data.school_name,
            });
            setSList(arr1);
            setSchool(arr1[0]);
            setSCHID({
              school_unique_no: response.data.school_unique_no,
              school_name: response.data.school_name,
              id: response.data.school_id,
            });
          }
        });

        coachsportslist().then((response) => {
          if (response.status === true) {
            setSportList(response.data);
          }
        });

        coachagecatelist().then((response) => {
          if (response.status === true) {
            let arr = [];
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].id,
                  label: response.data[i].age_category_name,
                });
              }
            }
            setAgeCatList(arr);
          }
        });
      }

      eventsmasterlist().then((response) => {
        if (response.status === true) {
          let arr = [];
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                label: response.data[i].event_name,
                value: response.data[i].event_uid,
              });
            }
            setEventList(arr);
          }
        }
      });
    }
  }, []);

  function listall() {
    setFilterProduct([]);
    setS3Path("");
    if (localStorage.getItem("Role") === "1") {
      achieveuploadlist(0).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    } else {
      achieveuploadlist(localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    }
  }
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("Achievement File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("Base Data File size should be less than 5MB");
      // }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (basefile !== "") {
      if (fileMsg === "") {
        setLoading(true);
        const temp = new FormData();
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("achievements_upload_excel", basefile);
        temp.append("from_school_id", schid);
        dotracking(
          "submit button in achievements upload $$ " +
            trackobj.achieveuploadheading
        );
        achieveupload(temp).then((response) => {
          if (response.status === true) {
            setBase("");
            setLoading(false);
            if (response.data.total_rows !== "") {
              setTRecord(response.data.total_rows);
            }
            if (response.data.inserted_count !== "") {
              setIRecord(response.data.inserted_count);
            }
            if (response.data.failed_count !== "") {
              setURecord(response.data.failed_count);
            }
            setFailId(response.data.uploaded_excel_id);
            setStatus(response.data.status);
            listall();
          } else {
            setLoading(false);
            setErrorMsg(response.message);
          }
        });
      }
    } else {
      setErrorMsg("Please Select Achievement File");
    }
  }

  function backlist() {
    dotracking(
      "back button in achievements upload $$ " + trackobj.achievelistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/achievementlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/achievementlist");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/achievementlist");
    }
  }

  function failedent(fid) {
    dotracking(
      "failed click to download link in upload achievement for " +
        fid +
        " $$ " +
        trackobj.achieveuploadheading
    );
    window.open(exportUrl + "export-failed-achievements/" + fid, "_blank");
  }

  const [user, setUser] = useState("");
  const [uid, setUID] = useState("");
  const [chid, setSCHID] = useState("");

  const [slist, setSList] = useState([]);
  const [schlist, setSchList] = useState([]);
  const [sportlist, setSportList] = useState([]);
  const [elist, setEventList] = useState("");

  function searchuser(ume) {
    setErrorMsg("");
    setPList([]);
    if (schid !== "") {
      if (ume !== "") {
        achieveplayersearch(ume, schid.value).then((response) => {
          if (response.status === true) {
            setPlaList(response.data);
            if (response.data.length > 0) {
              let arr = [];
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].user_id,
                  label:
                    response.data[i].first_name +
                    " (" +
                    response.data[i].class_name +
                    " - " +
                    response.data[i].section +
                    ") - " +
                    response.data[i].username,
                });
              }
              setPList(arr);
            }
          }
        });
      }
    } else {
      setErrorMsg("Please Select School");
    }
  }

  const [uname, setUName] = useState("");
  function onsearch(e) {
    setUser("");
    setUID("");
    setPList([]);
    setPlaList([]);
    if (e !== null) {
      setUser(e);
      setUID(e.value);
      const sdata = plalist.find((item) => {
        if (item.user_id == e.value) {
          setUName(item);
        }
      });
    } else {
      setUser("");
      setUID("");
    }
  }
  // function onplayer(puid, pname, puser, pcls, psec, eid) {
  //   setUID(puid);
  //   setUName(eid);
  //   setUser(pname + " (" + pcls + " - " + psec + ") - " + puser);
  //   setPList([]);
  // }

  function handleSch(e) {
    setErrorMsg("");
    setSCHID("");
    setSchool("");
    if (e !== null) {
      setSchool(e);
      const sdata = schlist.find((item) => {
        if (item.id == e.value) {
          setSCHID(item);
        }
      });
    } else {
      setSchool("");
      setSCHID("");
    }
  }

  function handleAge(e) {
    if (e !== null) {
      setAGroup(e);
    } else {
      setAGroup("");
    }
  }

  function handleEvent(e) {
    if (e !== null) {
      setTEvent(e);
    } else {
      setTEvent("");
    }
  }

  function handleSport(e) {
    if (e !== null) {
      setSport(e.target.value);
      const sdata = sportlist.find((item) => {
        if (item.sports_cat_uid == e.target.value) {
          setSportName(item.sports_category);
        }
      });
    } else {
      setSport("");
    }
  }

  const [tname, setTName] = useState("");
  const [level, setLevel] = useState("");
  const [mdate, setMDate] = useState("");
  const [sport, setSport] = useState("");
  const [sname, setSportName] = useState("");

  const [agroup, setAGroup] = useState("");
  const [tevent, setTEvent] = useState("");
  const [tpos, setTPos] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [loc, setLoc] = useState("");
  const [agecatlist, setAgeCatList] = useState([]);

  function invupload() {
    if (schid !== "") {
      if (uname !== "") {
        if (tname !== "") {
          if (level !== "") {
            if (mdate !== "") {
              if (sport !== "") {
                if (agroup !== "") {
                  if (tevent !== "") {
                    if (tpos !== "") {
                      if (city !== "") {
                        const temp = new FormData();
                        temp.append("admin_id", localStorage.getItem("UID"));
                        temp.append("user_id", uname.user_id);
                        temp.append("username", uname.username);

                        if (uname.esid !== null) {
                          temp.append("esid", uname.esid);
                        } else {
                          temp.append("esid", 0);
                        }
                        temp.append("chid", chid.school_unique_no);
                        temp.append("full_name", uname.first_name);
                        temp.append("grade", uname.class_name);
                        temp.append("section", uname.section);
                        temp.append("meet_name", tname);
                        temp.append("meet_level", level);
                        temp.append("meet_date", mdate);
                        temp.append("sport", sname);
                        temp.append("sports_id", sport);
                        // temp.append("agegroup", agroup.label);
                        temp.append("agegroup", agroup);
                        if (sname.toUpperCase() === "ATHLETICS") {
                          temp.append("event", tevent.label);
                        } else {
                          temp.append("event", tevent);
                        }

                        temp.append("position", tpos);
                        temp.append("gender", uname.gender);
                        temp.append("school_name", chid.school_name);
                        temp.append("school_id", chid.id);
                        temp.append("tournament_location", loc);
                        temp.append("tournament_city", city);
                        const now = moment();
                        temp.append(
                          "added_date",
                          now.format("MM/DD/YYYY HH:mm")
                        );

                        dotracking(
                          "submit button in individual achievement for " +
                            uname.first_name +
                            " (" +
                            uname.user_id +
                            ") $$ " +
                            trackobj.indachieveuploadheading
                        );
                        achieveindupload(temp).then((response) => {
                          if (response.status === true) {
                            toast.success(response.message);
                            backlist();
                          }
                        });
                      } else {
                        setErrorMsg("Please Enter Tournament City");
                      }
                    } else {
                      setErrorMsg("Please Select Position");
                    }
                  } else {
                    setErrorMsg("Please Select Event");
                  }
                } else {
                  setErrorMsg("Please Enter Age Category");
                }
              } else {
                setErrorMsg("Please Select Sport");
              }
            } else {
              setErrorMsg("Please Select Tournament Date");
            }
          } else {
            setErrorMsg("Please Enter Level");
          }
        } else {
          setErrorMsg("Please Enter Tournament Name");
        }
      } else {
        setErrorMsg("Please Select Player");
      }
    } else {
      setErrorMsg("Please Select School");
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Achievement</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Achievement
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && urllink === "achievementsupload" && (
          <>
            <div className="row">
              <div className="col-md-6">
                <Form className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Upload Achievement File{" "}
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        Back
                      </a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>
                      <a
                        className="btn btn-secondary"
                        href={require("../../../../assets/images/Sample_Achievement_Excel.xlsx")}
                        download
                      >
                        Sample Excel
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-md-6">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 320,
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="4"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              </div>
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Failed Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                    {urecord !== 0 && (
                      <a
                        className="excelfailed text-danger"
                        onClick={() => failedent(failid)}
                      >
                        <b>Click to Download</b>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {!loading && urllink !== "achievementsupload" && (
          <>
            <Form className="card">
              <div className="card-body">
                <div className="row">
                  {localStorage.getItem("Role") !== "7" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          School <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select School"
                          value={schid}
                          options={slist}
                          onChange={handleSch}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </div>
                  )}

                  {localStorage.getItem("Role") === "7" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          School <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select School"
                          value={schid}
                          options={slist}
                          onChange={handleSch}
                          isSearchable={true}
                          isClearable
                          hidden
                          isDisabled
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Player <span className="errortext"> *</span>
                      </label>

                      <Select
                        className="dropdown"
                        placeholder="Select Player"
                        value={user}
                        options={plist}
                        onInputChange={(newValue) => searchuser(newValue)}
                        onChange={onsearch}
                        isSearchable={true}
                        isClearable
                        hidden
                      />

                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Search Name/Username or ESID"
                        value={user}
                        onChange={(e) => searchuser(e.target.value)}
                      /> */}

                      {/* <ul className="suggestions">
                        {plist.map((item, i) => {
                          return (
                            <li
                              key={i}
                              onClick={() =>
                                onplayer(
                                  item.user_id,
                                  item.first_name,
                                  item.username,
                                  item.class_name,
                                  item.section,
                                  item
                                )
                              }
                            >
                              {item.esid} - {item.first_name} ({" "}
                              {item.class_name} - {item.section} ) -{" "}
                              {item.username}
                            </li>
                          );
                        })}
                      </ul> */}
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Gender
                        <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Gender ---
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Tournament Name
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Tournament Name"
                        value={tname}
                        onChange={(e) => setTName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Level
                        <span className="errortext">*</span>
                      </label>

                      <select
                        className="form-control custom-select"
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Level ---
                        </option>
                        <option value="International">International</option>
                        <option value="National">National</option>
                        <option value="State">State</option>
                        <option value="Zonal">Zonal</option>
                        <option value="District">District</option>
                        <option value="Inter-School">Inter-School</option>
                        <option value="Intra-School">Intra-School</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Tournament Date
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={mdate}
                        onChange={(e) => setMDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Category <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Age Category"
                        value={agroup}
                        onChange={(e) => setAGroup(e.target.value)}
                      />
                      {/* <Select
                        className="dropdown"
                        placeholder="Select Age Category"
                        value={agroup}
                        options={agecatlist}
                        onChange={handleAge}
                        isSearchable={true}
                        isClearable
                        hidden
                      /> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Sport
                        <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={sport}
                        onChange={handleSport}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Sports ---
                        </option>
                        {sportlist.map((data, i) => {
                          return (
                            <option value={data.sports_cat_uid} key={i}>
                              {data.sports_category}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {sname.toUpperCase() !== "ATHLETICS" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Event
                          <span className="errortext">
                            {" "}
                            * (Other than Athletics sports just mention the
                            sports name here.)
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Event"
                          value={tevent}
                          onChange={(e) => setTEvent(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  {sname.toUpperCase() === "ATHLETICS" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Event
                          <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select Event"
                          value={tevent}
                          options={elist}
                          onChange={handleEvent}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Position
                        <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={tpos}
                        onChange={(e) => setTPos(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Level ---
                        </option>
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Bronze">Bronze</option>
                        <option value="Participation">Participation</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Tournament City
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Tournament City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label"> Tournament Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Tournament Location"
                        value={loc}
                        onChange={(e) => setLoc(e.target.value)}
                      />
                    </div>
                  </div>

                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                  <hr />
                  <div className="text-right">
                    <a className="btn btn-gray me-2" onClick={backlist}>
                      Back
                    </a>
                    <a className="btn btn-success me-2" onClick={invupload}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </div>
    </Fragment>
  );
};
