import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  grpschcreate,
  grpschdetail,
  grpschlist,
  grpschupdate,
  schoollistmaster,
} from "../../../../services/master";
import Select from "react-select";

export const GroupSchoolMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [funid, setId] = useState(0);
  const [fundname, setFundName] = useState("");
  const [funddesc, setFundDesc] = useState("");
  const [fundmail, setFundMail] = useState("");
  const [fundmob, setFundMob] = useState("");
  const [fundaddr, setFundAddr] = useState("");
  const [fundsch, setFundSch] = useState("");
  const [slist, setSList] = useState([]);

  const [logofile, setLogoFile] = useState("");
  const [s3logo, setS3Logo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      field: "id",
      width: 100,
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewclass(params.value)}
              title="Edit Group of School"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
            <a
              onClick={() => viewschool(params.value,params)}
              title="School List"
              className="btn-sm btn-warning"
            >
              <i className="fa fa-list fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Group of School Name",
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Description",
      field: "group_of_school_description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

   
  ];

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }

  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

 
  

 
  const navigate = useNavigate();
  function viewschool(aid,adata) {
    dotracking(
      "school list button in group of school for " +
        adata.data.group_of_school_name +
        " (" +
        aid +
        ") $$ " +
        trackobj.groupschoollist
    );
    navigate(`/superadmin/groupschools/${aid}`);

  }

 
  function viewclass(aid) {
    setShow(true);
    grpschdetail(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.funders_details.id);
        setFundName(response.data.funders_details.group_of_school_name);
        if (
          response.data.funders_details.group_of_school_description !== null
        ) {
          setFundDesc(
            response.data.funders_details.group_of_school_description
          );
        }
        if (response.data.funders_details.email !== null) {
          setFundMail(response.data.funders_details.email);
        }
        if (response.data.funders_details.mobile !== null) {
          setFundMob(response.data.funders_details.mobile);
        }
        if (response.data.funders_details.address !== null) {
          setFundAddr(response.data.funders_details.address);
        }

        if (response.data.funders_details.logo !== null) {
          setLogoFile(
            response.data.logo_path + response.data.funders_details.logo
          );
        }

        dotracking(
          "edit button in group of school for " +
            response.data.funders_details.group_of_school_name +
            " (" +
            aid +
            ") $$ " +
            trackobj.addgroupschoollist
        );
      }
    });
  }

  function handleshow() {
    setId(0);
    setFundName("");
    setFundDesc("");
    setFundMail("");
    setFundMob("");
    setFundAddr("");
    setFundSch([]);
    setLogoFile("");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setFundName("");
    setFundDesc("");
    setFundMail("");
    setFundMob("");
    setFundAddr("");
    setFundSch([]);
    setLogoFile("");
    setErrorMsg("");
  }

  function deleteclass(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "status icon in tags list from Active to Inactive for " +
              list.data.funder_name +
              " (" +
              list.data.id +
              ") $$ " +
              trackobj.addgroupschoollist
          );
          //   cmstagdelete(localStorage.getItem('UID'), list.data.id).then((response) => {
          //       if (response.status === true) {
          //           listall();
          //           toast.success(response.message)
          //       }
          //   })
        }
      });
    }
  }
  useEffect(() => {
    listall();
    schoollistmaster().then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.details.length > 0) {
          for (let i = 0; i < response.data.details.length; i++) {
            arr.push({
              label: response.data.details[i].school_name,
              value: response.data.details[i].id,
            });
          }
          setSList(arr);
        }
      }
    });
  }, []);

  function listall() {
    grpschlist().then((response) => {
      if (response.status === true) {
        setS3Logo(response.data.logo_path);
        setFilterProduct(response.data.funders_list);
      }
    });
  }

  function handleMobile(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFundMob(e.target.value);
    }
  }

  const [lerror, setLError] = useState("");
  function logosch(event) {
    setLogoFile("");
    setLError("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLogoFile(event.target.files[0]);
      } else {
        setLError("Logo should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setLError("Logo size should be less than 5MB");
      }
    }
  }
  function submittags(e) {
    e.preventDefault();
    setErrorMsg("");
    if (fundname !== "") {
      // if (funddesc !== "") {
      //   if (fundmail !== "") {
      //     if (fundmob !== "") {
      //       if (fundaddr !== "") {
      if (funid === 0) {
        // if (logofile !== "") {
        const temp = new FormData();
        temp.append("group_of_school_name", fundname);
        temp.append("group_of_school_description", funddesc);
        temp.append("email", fundmail);
        temp.append("mobile", fundmob);
        temp.append("address", fundaddr);
        temp.append("group_of_school_logo", logofile);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("schools_count", fundsch.length);
        if (fundsch.length !== 0) {
          for (let i = 0; i < fundsch.length; i++) {
            temp.append("school_id_" + [i + 1], fundsch[i].value);
          }
        }
        dotracking(
          "add button in group of school list for " +
            fundname +
            " $$ " +
            trackobj.addgroupschoollist
        );
        grpschcreate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        });
      } else if (funid !== 0) {
        const temp = new FormData();
        temp.append("group_school_id", funid);
        temp.append("group_of_school_name", fundname);
        temp.append("group_of_school_description", funddesc);
        temp.append("email", fundmail);
        temp.append("mobile", fundmob);
        temp.append("address", fundaddr);
        temp.append("group_of_school_logo", logofile);
        temp.append("admin_id", localStorage.getItem("UID"));
        dotracking(
          "update button in group of school list for " +
            fundname +
            " (" +
            funid +
            ") $$ " +
            trackobj.addgroupschoollist
        );
        grpschupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        });
      }
      //       } else {
      //         setErrorMsg("Please Enter Funder Address");
      //       }
      //     } else {
      //       setErrorMsg("Please Enter Funder Mobile");
      //     }
      //   } else {
      //     setErrorMsg("Please Enter Funder Email");
      //   }
      // } else {
      //   setErrorMsg("Please Enter Funder Description");
      // }
    } else {
      setErrorMsg("Please Enter Group of School Name");
    }
  }

  function handleSch(e) {
    if (e !== null) {
      setFundSch(e);
    } else {
      setFundSch("");
    }
  }

  
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Group of School Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Group of School Lists
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add Group of School
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="xl" onHide={handleclose}>
                <Modal.Header>
                  {funid !== 0 && <h4> Group of School Details</h4>}
                  {funid === 0 && <h4> Add Group of School</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Group of School Name{" "}
                          <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Group of School Name"
                          className="form-control"
                          value={fundname}
                          onChange={(e) => setFundName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Group of School Description{" "}
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <textarea
                          rows={3}
                          placeholder="Group of School Description"
                          className="form-control"
                          value={funddesc}
                          onChange={(e) => setFundDesc(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Email {/*<span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-control"
                          value={fundmail}
                          onChange={(e) => setFundMail(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Mobile {/*<span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="text"
                          maxLength={10}
                          placeholder="Mobile"
                          className="form-control"
                          value={fundmob}
                          onChange={handleMobile}
                        />
                      </div>
                    </Col>
                    {funid === 0 && (
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            School {/*<span className="errortext"> *</span> */}
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={fundsch}
                            options={slist}
                            onChange={handleSch}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                        </div>
                      </Col>
                    )}

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Address {/*<span className="errortext"> *</span> */}
                        </label>
                        <textarea
                          rows={3}
                          placeholder="Address"
                          className="form-control"
                          value={fundaddr}
                          onChange={(e) => setFundAddr(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Logo
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                          onChange={logosch}
                        />
                        {funid !== 0 && logofile !== "" && (
                          <img src={logofile} width="100" height="100" />
                        )}
                        {lerror !== "" && (
                          <span className="errortext">{lerror}</span>
                        )}
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {funid !== 0 && (
                    <a className="btn btn-success" onClick={submittags}>
                      Update
                    </a>
                  )}
                  {funid === 0 && (
                    <a className="btn btn-success" onClick={submittags}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>

              <Modal show={lshow} size="lg" onHide={logoclose}>
                <Modal.Header>
                  <h4> Group of School Logo</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={schlogo} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={logoclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

             
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
