import React, { useEffect, useState } from "react";
import "@lourenci/react-kanban/dist/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  crmtourviewdetail,
  teamcreatecityreg,
  teamdetailscityreg,
  teamupdatecityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import {
  teamcreate,
  teamdetails,
  teamupdate,
  tourcategorydetail,
  tourteamplayerlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Select from "react-select";
import {
  scheadtourviewdetail,
  schheadteamdetails,
  schprofileview,
} from "../../../../services/schoolhead";
import {
  teamcreateschoolhead,
  teamupdateschoolhead,
} from "../../../../services/interschool";
import {
  coachtourviewdetail,
  coachviewprofile,
  teamcreatecoach,
  teamdetailscoach,
  teamupdatecoach,
} from "../../../../services/coach";
import {
  externaltouragecatlist,
  externaltourdetails,
  externaltourteamcreate,
  externaltourteamdetails,
  externaltourteamupdate,
} from "../../../../services/common";
export const TeamCreate = () => {
  const navigate = useNavigate();
  const { id, sid } = useParams();
  const { eid } = useParams();
  const { tid } = useParams();
  const [team, setTeam] = useState("");
  const [tcode, setTCode] = useState("");
  const [maxpla, setMaxPla] = useState("");
  const [schid, setSchool] = useState("");
  const [schlist, setSchList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [plist, setPList] = useState([]);

  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [ttype, setType] = useState("");
  const [ageid, setAgecat] = useState("");
  const [agelist, setAgeList] = useState([]);
  const [catlist, setCatList] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");

  const [catname, setCatname] = useState("");

  const handlePlayer = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMaxPla(e.target.value);
    }
  };

  function backlist() {
    if (tid === undefined) {
      dotracking(
        "back button in add team for " +
          catname +
          " (" +
          eid +
          ") category in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.teamlistheading
      );
    } else {
      dotracking(
        "back button in edit team for " +
          team +
          " (" +
          tid +
          ") team in " +
          catname +
          " (" +
          eid +
          ") category for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.teamlistheading
      );
    }

    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamdetails") {
        navigate("/superadmin/teamlist/" + id + "/" + eid);
      } else {
        if (schid !== "") {
          navigate(
            "/superadmin/interteamlist/" + id + "/" + eid + "/" + schid.value
          );
        } else {
          navigate(-1);
        }
      }

      // navigate(-1);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      if(urllink === "externalteamcreate" || urllink === "externalteamdetails"){ 
        navigate("/coach/externalteamlist/" + id);
      }else{
        navigate("/coach/teamlist/" + id + "/" + eid);
      }
      
    }
  }
  function teamsubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var shid = "";

    if (team !== "") {
      if (schid !== "") {
        if (tid === undefined) {
          if (schid !== "") {
            shid = schid.value;
          }
          const temp = new FormData();
          temp.append("team_name", team);
          temp.append("tournament_meet_id", id);
          temp.append("category_id", eid);
          temp.append("school_id", shid);
          temp.append("team_code", tcode);
          // temp.append("team_age_category_id", ageid)
          if (localStorage.getItem("Role") !== "4") {
            dotracking(
              "submit button in add team for " +
                team +
                " team in " +
                catname +
                " (" +
                eid +
                ") category for " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.teamaddplaheading
            );
          } else if (localStorage.getItem("Role") === "4") {
            dotracking(
              "submit button in add team for " +
                team +
                " team in " +
                catname +
                " (" +
                eid +
                ") category for " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.teamlistheading
            );
          }
          if (localStorage.getItem("Role") === "1") {
            teamcreate(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                if (urllink === "teamcreate") {
                  navigate(
                    "/superadmin/teamplayercreate/" +
                      id +
                      "/" +
                      response.data.team.id +
                      "/" +
                      eid
                  );
                } else {
                  navigate(
                    "/superadmin/interteamplayercreate/" +
                      id +
                      "/" +
                      response.data.team.id +
                      "/" +
                      eid
                  );
                }
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "3") {
            teamcreatecityreg(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate(
                  "/cityregional/teamplayercreate/" +
                    id +
                    "/" +
                    response.data.team.id +
                    "/" +
                    eid
                );
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "4") {
            teamcreateschoolhead(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/schoolhead/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "7") {
            teamcreatecoach(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/coach/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          }
        } else {
          shid = schid.value;
          const temp = new FormData();
          temp.append("team_id", tid);
          temp.append("team_name", team);
          temp.append("category_id", eid);
          temp.append("tournament_meet_id", id);
          temp.append("school_id", shid);
          temp.append("team_code", tcode);
          // temp.append("team_age_category_id", ageid)
          dotracking(
            "submit button in edit team for " +
              team +
              " (" +
              tid +
              ") team in " +
              catname +
              " (" +
              eid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.teamlistheading
          );

          if (localStorage.getItem("Role") === "1") {
            teamupdate(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/superadmin/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "3") {
            teamupdatecityreg(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/cityregional/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "4") {
            teamupdateschoolhead(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/schoolhead/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "7") {
            teamupdatecoach(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                navigate("/coach/teamlist/" + id + "/" + eid);
              } else {
                setErrorMsg(response.message);
              }
            });
          }
        }
      } else {
        setErrorMsg("Please Select School");
      }
    }
  }
  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (link[1] !== "externalteamcreate" && link[1] !== "externalteamdetails") {
      const tempData = new FormData();
      tempData.append("category_id", eid);
      tourcategorydetail(tempData).then((res) => {
        if (res.status === true) {
          setCatname(res.data.category_details.category_name);
        }
      });
    }

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (
            link[1] === "interteamcreate" &&
            response.data.tournament.meet_type === "Inter School Tournament"
          ) {
            if (response.data.mappedschools !== null) {
              if (response.data.mappedschools.length !== 0) {
                let arr = [];
                for (let i = 0; i < response.data.mappedschools.length; i++) {
                  arr.push({
                    value: response.data.mappedschools[i].school_id,
                    label:
                      response.data.mappedschools[i].school_name +
                      " - " +
                      response.data.mappedschools[i].school_unique_no,
                  });
                }
                setSchList(arr);
                if (sid !== null) {
                  const fdate = arr.find((item) => {
                    return item.value === parseInt(sid);
                  });
                  setSchool(fdate);
                }
              }
            }
          }
          if (tid !== undefined) {
            teamdetails(tid).then((res) => {
              if (res.status === true) {
                if (res.data.team.length !== 0) {
                  setTeam(res.data.team[0].team_name);
                  setTCode(res.data.team[0].team_code);
                  setMaxPla(res.data.team[0].no_of_players);
                  setAgecat(res.data.team[0].team_age_category_id);

                  if (response.data.mappedschools !== null) {
                    if (response.data.mappedschools.length !== 0) {
                      let arr = [];
                      for (
                        let i = 0;
                        i < response.data.mappedschools.length;
                        i++
                      ) {
                        arr.push({
                          value: response.data.mappedschools[i].school_id,
                          label:
                            response.data.mappedschools[i].school_name +
                            " - " +
                            response.data.mappedschools[i].school_unique_no,
                        });
                      }
                      setSchList(arr);
                      if (res.data.team[0].school_id !== null) {
                        const fdate = arr.find((item) => {
                          return item.value === res.data.team[0].school_id;
                        });
                        setSchool(fdate);
                      }
                    }
                  }
                }
              }
            });

            tourteamplayerlist(id, tid).then((response) => {
              if (response.status === true) {
                setPList(response.data);
              }
            });
          } else {
            if (response.data.mappedschools !== null) {
              if (response.data.mappedschools.length !== 0) {
                let arr = [];
                for (let i = 0; i < response.data.mappedschools.length; i++) {
                  arr.push({
                    value: response.data.mappedschools[i].school_id,
                    label:
                      response.data.mappedschools[i].school_name +
                      " - " +
                      response.data.mappedschools[i].school_unique_no,
                  });
                }
                setSchList(arr);
              }
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      // schoolbasedtourlist(id).then((response) => {
      //     if (response.status === true) {
      //         setSchList(response.data)
      //     }
      // })
      // agecategorytourcityreg(id).then((response) => {
      //     if (response.status === true) {
      //         setAgeList(response.data)
      //     }
      // })
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  value: response.data.mappedschools[i].school_id,
                  label:
                    response.data.mappedschools[i].school_name +
                    " - " +
                    response.data.mappedschools[i].school_unique_no,
                });
              }
              setSchList(arr);
            }
          }

          if (tid !== undefined) {
            teamdetailscityreg(tid).then((res) => {
              if (res.status === true) {
                if (res.data.team.length !== 0) {
                  setTeam(res.data.team[0].team_name);
                  setTCode(res.data.team[0].team_code);
                  setMaxPla(res.data.team[0].no_of_players);
                  setAgecat(res.data.team[0].team_age_category_id);

                  if (response.data.mappedschools !== null) {
                    if (response.data.mappedschools.length !== 0) {
                      let arr = [];
                      for (
                        let i = 0;
                        i < response.data.mappedschools.length;
                        i++
                      ) {
                        arr.push({
                          value: response.data.mappedschools[i].school_id,
                          label:
                            response.data.mappedschools[i].school_name +
                            " - " +
                            response.data.mappedschools[i].school_unique_no,
                        });
                      }
                      setSchList(arr);
                      if (res.data.team[0].school_id !== null) {
                        const fdate = arr.find((item) => {
                          return item.value === res.data.team[0].school_id;
                        });
                        setSchool(fdate);
                      }
                    }
                  }
                }
              }
            });
          } else {
            if (response.data.mappedschools !== null) {
              if (response.data.mappedschools.length !== 0) {
                let arr = [];
                for (let i = 0; i < response.data.mappedschools.length; i++) {
                  arr.push({
                    value: response.data.mappedschools[i].school_id,
                    label:
                      response.data.mappedschools[i].school_name +
                      " - " +
                      response.data.mappedschools[i].school_unique_no,
                  });
                }
                setSchList(arr);
              }
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);

          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              let arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  value: response.data.mappedschools[i].school_id,
                  label:
                    response.data.mappedschools[i].school_name +
                    " - " +
                    response.data.mappedschools[i].school_unique_no,
                });
              }
              setSchList(arr);

              if (arr.length !== 0) {
                schprofileview(localStorage.getItem("UID")).then((res) => {
                  if (res.status === true) {
                    if (res.data.school_id !== null) {
                      const fdate = arr.find((item) => {
                        return item.value === res.data.school_id;
                      });
                      setSchool(fdate);
                    }
                  }
                });
              }
            }
          }

          if (tid !== undefined) {
            schheadteamdetails(tid).then((res) => {
              if (res.status === true) {
                if (res.data.team.length !== 0) {
                  setTeam(res.data.team[0].team_name);
                  setTCode(res.data.team[0].team_code);
                  setMaxPla(res.data.team[0].no_of_players);
                  setAgecat(res.data.team[0].team_age_category_id);
                }
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      if (link[1] === "externalteamcreate" || link[1] === "externalteamdetails") {
        externaltourdetails(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setType(response.data.tournament.meet_type_name);
          }
        });
        const temp = new FormData();
        temp.append("tour_id", id);
        externaltouragecatlist(temp).then((response) => {
          if (response.status === true) {
            setCatList(response.data.agecategory);
            let arr = [];
            for (let i = 0; i < response.data.agecategory.length; i++) {
              arr.push({
                value: response.data.agecategory[i].id,
                label: response.data.agecategory[i].age_categories,
              });
            }
            setAgeList(arr);
          }
        });

        coachviewprofile().then((res) => {
          if (res.status === true) {
            setSchool(res.data.school_id);
          }
        });

        if(link[1] === "externalteamdetails"){
          externaltourteamdetails(tid).then((res) => {
            if (res.status === true) {
              if (res.data.team_details.length !== 0) {
                setTeam(res.data.team_details[0].team_name);
                let arr=[];
                arr.push({
                  value: res.data.team_details[0].age_category_id,
                  label: res.data.team_details[0].age_category_name
                })
                setAgecat(arr[0]);
              }
            }
          })
        }
      } else {
        coachtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setType(response.data.tournament.meet_type);
            if (response.data.mappedschools !== null) {
              if (response.data.mappedschools.length !== 0) {
                let arr = [];
                for (let i = 0; i < response.data.mappedschools.length; i++) {
                  arr.push({
                    value: response.data.mappedschools[i].school_id,
                    label:
                      response.data.mappedschools[i].school_name +
                      " - " +
                      response.data.mappedschools[i].school_unique_no,
                  });
                }
                setSchList(arr);
              }
            }

            if (tid !== undefined) {
              teamdetailscoach(tid).then((res) => {
                if (res.status === true) {
                  if (res.data.team.length !== 0) {
                    setTeam(res.data.team[0].team_name);
                    setTCode(res.data.team[0].team_code);
                    setMaxPla(res.data.team[0].no_of_players);
                    setAgecat(res.data.team[0].team_age_category_id);

                    if (response.data.mappedschools !== null) {
                      if (response.data.mappedschools.length !== 0) {
                        let arr = [];
                        for (
                          let i = 0;
                          i < response.data.mappedschools.length;
                          i++
                        ) {
                          arr.push({
                            value: response.data.mappedschools[i].school_id,
                            label:
                              response.data.mappedschools[i].school_name +
                              " - " +
                              response.data.mappedschools[i].school_unique_no,
                          });
                        }
                        setSchList(arr);
                        if (res.data.team[0].school_id !== null) {
                          const fdate = arr.find((item) => {
                            return item.value === res.data.team[0].school_id;
                          });
                          setSchool(fdate);
                        }
                      }
                    }
                  }
                }
              });
            } else {
              if (response.data.mappedschools !== null) {
                if (response.data.mappedschools.length !== 0) {
                  let arr = [];
                  for (let i = 0; i < response.data.mappedschools.length; i++) {
                    arr.push({
                      value: response.data.mappedschools[i].school_id,
                      label:
                        response.data.mappedschools[i].school_name +
                        " - " +
                        response.data.mappedschools[i].school_unique_no,
                    });
                  }
                  setSchList(arr);
                }
              }
            }
          }
        });
      }
    }
  }, []);

  function handleSchool(e) {
    if (e !== null) {
      setSchool(e);
    } else {
      setSchool("");
    }
  }

  function handleAge(e) {
    if (e !== null) {
      setAgecat(e);
    } else {
      setAgecat("");
    }
  }

  function extsubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (team !== "") {
      if (ageid !== "") {
        const adata = catlist.find((item) => {
          return item.id == ageid.value;
        });
        const temp = new FormData();
        
        temp.append("team_name", team);
        temp.append("age_category_id", ageid.value);
        temp.append("age_category_name", ageid.label);
        if (adata !== undefined) {
          temp.append("gender", adata.gender);
        } else {
          temp.append("gender", "");
        }
        if(tid  === undefined){
          temp.append("tournament_meet_id", id);
        temp.append("created_by_id", localStorage.getItem("UID"));
        temp.append("school_id", schid);

          dotracking(
            "submit button in add team for " +
              team +
              " team in " +
              ageid.label +
              " (" +
              ageid.value +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.teamaddplaheading
          );
          externaltourteamcreate(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              navigate("/coach/externalteamlist/" + id);
            }
          });
        }else{
          temp.append("updated_by_id", localStorage.getItem("UID"));
          temp.append("team_id", tid);
          dotracking(
            "update button in add team for " +
              team +
              " team in " +
              ageid.label +
              " (" +
              ageid.value +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.teamaddplaheading
          );
          externaltourteamupdate(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              navigate("/coach/externalteamlist/" + id);
            }
          });
        }
       
      } else {
        setErrorMsg("Please Select Age Category");
      }
    } else {
      setErrorMsg("Please Enter Team Name");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          {tid !== undefined && <h4 className="mb-0">Edit Team</h4>}
          {tid === undefined && <h4 className="mb-0">Create Team</h4>}
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Tournament Lists</a>
          </li>
          {tid !== undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              Edit Team
            </li>
          )}
          {tid === undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              Create Team
            </li>
          )}
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="text-center">
                  <h3>{tname}</h3>
                  {urllink !== "externalteamcreate" && urllink !== "externalteamdetails" && (
                    <h4 className="text-success tx-20">Category - {catname}</h4>
                  )}
                  {/* <h4>{tloc}</h4> */}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Team Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Team Name"
                      className="form-control"
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                    />
                    {submitted && !team && (
                      <span className="errortext">Team Name is required</span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Team Code <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Team Code"
                      className="form-control"
                      value={tcode}
                      onChange={(e) => setTCode(e.target.value)}
                    />
                    {submitted && !tcode && (
                      <span className="errortext">Team Code is required</span>
                    )}
                  </div>
                </div> */}
                {urllink !== "externalteamcreate" && urllink !== "externalteamdetails" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      {/* {plist.length !== 0 && (
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                        hidden
                        isDisabled
                      />
                    )}
                    {plist.length === 0 && ( */}
                      {localStorage.getItem("Role") !== "4" &&
                        urllink === "teamcreate" &&
                        ttype !== "Inter School Tournament" && (
                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={schid}
                            options={schlist}
                            onChange={handleSchool}
                            isSearchable={true}
                            isClearable
                            hidden
                          />
                        )}
                      {localStorage.getItem("Role") !== "4" &&
                        urllink === "teamdetails" && (
                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={schid}
                            options={schlist}
                            onChange={handleSchool}
                            isSearchable={true}
                            isClearable
                            hidden
                          />
                        )}
                      {localStorage.getItem("Role") !== "4" &&
                        urllink !== "teamdetails" &&
                        ttype === "Inter School Tournament" && (
                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={schid}
                            options={schlist}
                            onChange={handleSchool}
                            isSearchable={true}
                            isClearable
                            hidden
                            isDisabled
                          />
                        )}

                      {/* )} */}

                      {localStorage.getItem("Role") === "4" && (
                        <Select
                          className="dropdown"
                          placeholder="Select School"
                          value={schid}
                          options={schlist}
                          onChange={handleSchool}
                          isSearchable={true}
                          isClearable
                          hidden
                          isDisabled
                        />
                      )}
                    </div>
                  </div>
                )}

                {(urllink === "externalteamcreate" || urllink === "externalteamdetails") && localStorage.getItem("Role") === "7" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Catgeory <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Catgeory"
                        value={ageid}
                        options={agelist}
                        onChange={handleAge}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}

                {errorMsg !== "" && (
                  <span className="errortext">{errorMsg}</span>
                )}
              </div>

              <div className="card-footer text-center border-top">
                <a className="btn btn-gray me-2" onClick={backlist}>
                  Back
                </a>
                {urllink !== "externalteamcreate" && urllink !== "externalteamdetails" && (
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={teamsubmit}
                  >
                    Submit
                  </button>
                )}

                {(urllink === "externalteamcreate" || urllink === "externalteamdetails") && localStorage.getItem("Role") === "7" && (
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={extsubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
