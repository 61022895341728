import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  scheadfitnesslist,
  schprofileview,
} from "../../../../services/schoolhead";
import { useState } from "react";
import moment from "moment";
import { gettokenfromapi } from "../../../../services/superadmin";
import { sahassessmentdatelist } from "../../../../services/sahapi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const FitnessGenderList = () => {
  const [csnlist1, set1CSNList] = useState("");
  const [snlist1, set1SNList] = useState("");
  const [std1, set1Std] = useState([]);
  const [school1, set1School] = useState([]);
  const [national1, set1National] = useState([]);
  const [current1, set1Current] = useState([]);

  const [fsnlist1, set1FSNList] = useState("");
  const [fstd1, set1FStd] = useState([]);
  const [fschool1, set1FSchool] = useState([]);
  const [fnational1, set1FNational] = useState([]);
  const [fcurrent1, set1FCurrent] = useState([]);

  const [csnlist2, set2CSNList] = useState("");
  const [snlist2, set2SNList] = useState("");
  const [std2, set2Std] = useState([]);
  const [school2, set2School] = useState([]);
  const [national2, set2National] = useState([]);
  const [current2, set2Current] = useState([]);

  const [fsnlist2, set2FSNList] = useState("");
  const [fstd2, set2FStd] = useState([]);
  const [fschool2, set2FSchool] = useState([]);
  const [fnational2, set2FNational] = useState([]);
  const [fcurrent2, set2FCurrent] = useState([]);

  const [csnlist3, set3CSNList] = useState("");
  const [snlist3, set3SNList] = useState("");
  const [std3, set3Std] = useState([]);
  const [school3, set3School] = useState([]);
  const [national3, set3National] = useState([]);
  const [current3, set3Current] = useState([]);

  const [fsnlist3, set3FSNList] = useState("");
  const [fstd3, set3FStd] = useState([]);
  const [fschool3, set3FSchool] = useState([]);
  const [fnational3, set3FNational] = useState([]);
  const [fcurrent3, set3FCurrent] = useState([]);

  const [csnlist4, set4CSNList] = useState("");
  const [snlist4, set4SNList] = useState("");
  const [std4, set4Std] = useState([]);
  const [school4, set4School] = useState([]);
  const [national4, set4National] = useState([]);
  const [current4, set4Current] = useState([]);

  const [fsnlist4, set4FSNList] = useState("");
  const [fstd4, set4FStd] = useState([]);
  const [fschool4, set4FSchool] = useState([]);
  const [fnational4, set4FNational] = useState([]);
  const [fcurrent4, set4FCurrent] = useState([]);

  const [csnlist5, set5CSNList] = useState("");
  const [snlist5, set5SNList] = useState("");
  const [std5, set5Std] = useState([]);
  const [school5, set5School] = useState([]);
  const [national5, set5National] = useState([]);
  const [current5, set5Current] = useState([]);

  const [fsnlist5, set5FSNList] = useState("");
  const [fstd5, set5FStd] = useState([]);
  const [fschool5, set5FSchool] = useState([]);
  const [fnational5, set5FNational] = useState([]);
  const [fcurrent5, set5FCurrent] = useState([]);

  const [csnlist6, set6CSNList] = useState("");
  const [snlist6, set6SNList] = useState("");
  const [std6, set6Std] = useState([]);
  const [school6, set6School] = useState([]);
  const [national6, set6National] = useState([]);
  const [current6, set6Current] = useState([]);

  const [fsnlist6, set6FSNList] = useState("");
  const [fstd6, set6FStd] = useState([]);
  const [fschool6, set6FSchool] = useState([]);
  const [fnational6, set6FNational] = useState([]);
  const [fcurrent6, set6FCurrent] = useState([]);

  const [csnlist7, set7CSNList] = useState("");
  const [snlist7, set7SNList] = useState("");
  const [std7, set7Std] = useState([]);
  const [school7, set7School] = useState([]);
  const [national7, set7National] = useState([]);
  const [current7, set7Current] = useState([]);

  const [fsnlist7, set7FSNList] = useState("");
  const [fstd7, set7FStd] = useState([]);
  const [fschool7, set7FSchool] = useState([]);
  const [fnational7, set7FNational] = useState([]);
  const [fcurrent7, set7FCurrent] = useState([]);

  const [snlistover, setSNOver] = useState("");
  const [schoolover, setOverSchool] = useState([]);
  const [nationalover, setOverNational] = useState([]);
  const [currentover, setOverCurrent] = useState([]);

  const [fsnlistover, setFSNOver] = useState("");
  const [fschoolover, setFOverSchool] = useState([]);
  const [fnationalover, setFOverNational] = useState([]);
  const [fcurrentover, setFOverCurrent] = useState([]);

  const [loading, setLoading] = useState(false);

  let arr1 = [];

  const [pdata, setPData] = useState("");
  const [odata, setOData] = useState("");
  const [ndata, setNData] = useState("");

  useEffect(() => {
    setLoading(true);
    // console.log('Start',new Date().toLocaleString())
    schprofileview().then((res) => {
      if (res.status === true) {
        const temp = new FormData();
        temp.append(
          "data_req",
          "genderwise_fitness_trends" + res.data.school_unique_no
        );
        gettokenfromapi(temp).then((res2) => {
          if (res2.status === true) {
            sahassessmentdatelist(
              "genderwise_fitness_trends&chid=" + res.data.school_unique_no,
              res2.data
            ).then((response) => {
              if (response.status === true) {
                // console.log('API',new Date().toLocaleString())
                let arr = [];
                for (let i = 0; i < response.data.length; i++) {
                  if (response.data[i].report_name !== "Overall Report") {
                    if (response.data[i].std_wise_array.length !== 0) {
                      const girlsFitnessData = response.data[
                        i
                      ].std_wise_array.filter(
                        (item) => item.girls_fitness_trends_average
                      );
                      const boysFitnessData = response.data[
                        i
                      ].std_wise_array.filter(
                        (item) => item.boys_fitness_trends_average
                      );

                      for (let j = 0; j < girlsFitnessData.length; j++) {
                        if (
                          girlsFitnessData[j].girls_fitness_trends_average
                            .length !== 0
                        ) {
                          if (
                            girlsFitnessData[j].girls_fitness_trends_average[0]
                              .score_avg !== 0 ||
                            girlsFitnessData[j].girls_fitness_trends_average[1]
                              .score_avg !== 0 ||
                            girlsFitnessData[j].girls_fitness_trends_average[2]
                              .score_avg !== 0
                          ) {
                            setNData(
                              girlsFitnessData[j]
                                .girls_fitness_trends_average[2].app_date
                            );
                            setOData(
                              girlsFitnessData[j]
                                .girls_fitness_trends_average[1].app_date
                            );
                            setPData(
                              girlsFitnessData[j]
                                .girls_fitness_trends_average[0].app_date
                            );

                            arr1.push({
                              reportname: response.data[i].report_name,
                              report_name:
                                response.data[i].report_name + " (Female)",
                              std: girlsFitnessData[j].std,
                              school:
                                girlsFitnessData[j]
                                  .girls_fitness_trends_average[0].score_avg,
                              national:
                                girlsFitnessData[j]
                                  .girls_fitness_trends_average[1].score_avg,
                              current:
                                girlsFitnessData[j]
                                  .girls_fitness_trends_average[2].score_avg,
                            });
                          }
                        }
                      }

                      for (let j = 0; j < boysFitnessData.length; j++) {
                        if (
                          boysFitnessData[j].boys_fitness_trends_average[0]
                            .score_avg !== 0 ||
                          boysFitnessData[j].boys_fitness_trends_average[1]
                            .score_avg !== 0 ||
                          boysFitnessData[j].boys_fitness_trends_average[2]
                            .score_avg !== 0
                        ) {
                          setNData(
                            boysFitnessData[j].boys_fitness_trends_average[2]
                              .app_date
                          );
                          setOData(
                            boysFitnessData[j].boys_fitness_trends_average[1]
                              .app_date
                          );
                          setPData(
                            boysFitnessData[j].boys_fitness_trends_average[0]
                              .app_date
                          );

                          arr.push({
                            reportname: response.data[i].report_name,
                            report_name:
                              response.data[i].report_name + " (Male)",
                            std: boysFitnessData[j].std,
                            school:
                              boysFitnessData[j].boys_fitness_trends_average[0]
                                .score_avg,
                            national:
                              boysFitnessData[j].boys_fitness_trends_average[1]
                                .score_avg,
                            current:
                              boysFitnessData[j].boys_fitness_trends_average[2]
                                .score_avg,
                          });
                        }
                      }
                    }
                  } else {
                    if (
                      response.data[i].girls_fitness_trends_average_overall
                        .length !== 0
                    ) {
                      if (
                        response.data[i].girls_fitness_trends_average_overall[0]
                          .score_avg !== 0 ||
                        response.data[i].girls_fitness_trends_average_overall[1]
                          .score_avg !== 0 ||
                        response.data[i].girls_fitness_trends_average_overall[2]
                          .score_avg !== 0
                      ) {
                        arr1.push({
                          report_name:
                            response.data[i].report_name + " (Female)",
                          std: "",
                          school:
                            response.data[i]
                              .girls_fitness_trends_average_overall[0]
                              .score_avg,
                          national:
                            response.data[i]
                              .girls_fitness_trends_average_overall[1]
                              .score_avg,
                          current:
                            response.data[i]
                              .girls_fitness_trends_average_overall[2]
                              .score_avg,
                        });
                      }
                    }

                    if (
                      response.data[i].boys_fitness_trends_average_overall
                        .length !== 0
                    ) {
                      if (
                        response.data[i].boys_fitness_trends_average_overall[0]
                          .score_avg !== 0 ||
                        response.data[i].boys_fitness_trends_average_overall[1]
                          .score_avg !== 0 ||
                        response.data[i].boys_fitness_trends_average_overall[2]
                          .score_avg !== 0
                      ) {
                        arr.push({
                          report_name: response.data[i].report_name + " (Male)",
                          std: "",
                          school:
                            response.data[i]
                              .boys_fitness_trends_average_overall[0].score_avg,
                          national:
                            response.data[i]
                              .boys_fitness_trends_average_overall[1].score_avg,
                          current:
                            response.data[i]
                              .boys_fitness_trends_average_overall[2].score_avg,
                        });
                      }
                    }
                  }
                }
                const filtered = arr.filter((user) =>
                  user.report_name.includes("30m run")
                );
                if (filtered.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];

                  for (let i = 0; i < filtered.length; i++) {
                    stan.push(filtered[i].std);
                    sdata.push(filtered[i].school);
                    ndata.push(filtered[i].national);
                    cdata.push(filtered[i].current);
                  }
                  set1CSNList(filtered[0].reportname);
                  set1SNList(filtered[0].report_name);
                  set1Std(stan);
                  set1School(sdata);
                  set1National(ndata);
                  set1Current(cdata);
                }

                const ffiltered = arr1.filter((user) =>
                  user.report_name.includes("30m run")
                );
                if (ffiltered.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];

                  for (let i = 0; i < ffiltered.length; i++) {
                    stan.push(ffiltered[i].std);
                    sdata.push(ffiltered[i].school);
                    ndata.push(ffiltered[i].national);
                    cdata.push(ffiltered[i].current);
                  }
                  set1CSNList(ffiltered[0].reportname);
                  set1FSNList(ffiltered[0].report_name);
                  set1FStd(stan);
                  set1FSchool(sdata);
                  set1FNational(ndata);
                  set1FCurrent(cdata);
                }

                const filtered2 = arr.filter((user) =>
                  user.report_name.includes("Beep Test")
                );
                if (filtered2.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered2.length; i++) {
                    stan.push(filtered2[i].std);
                    sdata.push(filtered2[i].school);
                    ndata.push(filtered2[i].national);
                    cdata.push(filtered2[i].current);
                  }
                  set2CSNList(filtered2[0].reportname);
                  set2SNList(filtered2[0].report_name);
                  set2Std(stan);
                  set2School(sdata);
                  set2National(ndata);
                  set2Current(cdata);
                }

                const ffiltered2 = arr1.filter((user) =>
                  user.report_name.includes("Beep Test")
                );
                if (ffiltered2.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered2.length; i++) {
                    stan.push(ffiltered2[i].std);
                    sdata.push(ffiltered2[i].school);
                    ndata.push(ffiltered2[i].national);
                    cdata.push(ffiltered2[i].current);
                  }
                  set2CSNList(ffiltered2[0].reportname);
                  set2FSNList(ffiltered2[0].report_name);
                  set2FStd(stan);
                  set2FSchool(sdata);
                  set2FNational(ndata);
                  set2FCurrent(cdata);
                }

                const filtered3 = arr.filter((user) =>
                  user.report_name.includes("Medicine Ball Throw")
                );
                if (filtered3.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered3.length; i++) {
                    stan.push(filtered3[i].std);
                    sdata.push(filtered3[i].school);
                    ndata.push(filtered3[i].national);
                    cdata.push(filtered3[i].current);
                  }
                  set3CSNList(filtered3[0].reportname);
                  set3SNList(filtered3[0].report_name);
                  set3Std(stan);
                  set3School(sdata);
                  set3National(ndata);
                  set3Current(cdata);
                }

                const ffiltered3 = arr1.filter((user) =>
                  user.report_name.includes("Medicine Ball Throw")
                );
                if (ffiltered3.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered3.length; i++) {
                    stan.push(ffiltered3[i].std);
                    sdata.push(ffiltered3[i].school);
                    ndata.push(ffiltered3[i].national);
                    cdata.push(ffiltered3[i].current);
                  }
                  set3CSNList(ffiltered3[0].reportname);
                  set3FSNList(ffiltered3[0].report_name);
                  set3FStd(stan);
                  set3FSchool(sdata);
                  set3FNational(ndata);
                  set3FCurrent(cdata);
                }

                const filtered4 = arr.filter((user) =>
                  user.report_name.includes("Sit & Reach (in cm.)")
                );
                if (filtered4.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered4.length; i++) {
                    stan.push(filtered4[i].std);
                    sdata.push(filtered4[i].school);
                    ndata.push(filtered4[i].national);
                    cdata.push(filtered4[i].current);
                  }
                  set4CSNList(filtered4[0].reportname);
                  set4SNList(filtered4[0].report_name);
                  set4Std(stan);
                  set4School(sdata);
                  set4National(ndata);
                  set4Current(cdata);
                }

                const ffiltered4 = arr1.filter((user) =>
                  user.report_name.includes("Sit & Reach (in cm.)")
                );
                if (ffiltered4.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered4.length; i++) {
                    stan.push(ffiltered4[i].std);
                    sdata.push(ffiltered4[i].school);
                    ndata.push(ffiltered4[i].national);
                    cdata.push(ffiltered4[i].current);
                  }
                  set4CSNList(ffiltered4[0].reportname);
                  set4FSNList(ffiltered4[0].report_name);
                  set4FStd(stan);
                  set4FSchool(sdata);
                  set4FNational(ndata);
                  set4FCurrent(cdata);
                }

                const filtered5 = arr.filter((user) =>
                  user.report_name.includes("Standing Broad Jump")
                );
                if (filtered5.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered5.length; i++) {
                    stan.push(filtered5[i].std);
                    sdata.push(filtered5[i].school);
                    ndata.push(filtered5[i].national);
                    cdata.push(filtered5[i].current);
                  }
                  set5CSNList(filtered5[0].reportname);
                  set5SNList(filtered5[0].report_name);
                  set5Std(stan);
                  set5School(sdata);
                  set5National(ndata);
                  set5Current(cdata);
                }

                const ffiltered5 = arr1.filter((user) =>
                  user.report_name.includes("Standing Broad Jump")
                );
                if (ffiltered5.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered5.length; i++) {
                    stan.push(ffiltered5[i].std);
                    sdata.push(ffiltered5[i].school);
                    ndata.push(ffiltered5[i].national);
                    cdata.push(ffiltered5[i].current);
                  }
                  set5CSNList(ffiltered5[0].reportname);
                  set5FSNList(ffiltered5[0].report_name);
                  set5FStd(stan);
                  set5FSchool(sdata);
                  set5FNational(ndata);
                  set5FCurrent(cdata);
                }

                const filtered6 = arr.filter((user) =>
                  user.report_name.includes("Sit Ups in 30 sec")
                );
                if (filtered6.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered6.length; i++) {
                    stan.push(filtered6[i].std);
                    sdata.push(filtered6[i].school);
                    ndata.push(filtered6[i].national);
                    cdata.push(filtered6[i].current);
                  }
                  set6CSNList(filtered6[0].reportname);
                  set6SNList(filtered6[0].report_name);
                  set6Std(stan);
                  set6School(sdata);
                  set6National(ndata);
                  set6Current(cdata);
                }

                const ffiltered6 = arr1.filter((user) =>
                  user.report_name.includes("Sit Ups in 30 sec")
                );
                if (ffiltered6.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered6.length; i++) {
                    stan.push(ffiltered6[i].std);
                    sdata.push(ffiltered6[i].school);
                    ndata.push(ffiltered6[i].national);
                    cdata.push(ffiltered6[i].current);
                  }
                  set6CSNList(ffiltered6[0].reportname);
                  set6FSNList(ffiltered6[0].report_name);
                  set6FStd(stan);
                  set6FSchool(sdata);
                  set6FNational(ndata);
                  set6FCurrent(cdata);
                }

                const filtered7 = arr.filter((user) =>
                  user.report_name.includes("Sit Ups in 1 min")
                );
                if (filtered7.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filtered7.length; i++) {
                    stan.push(filtered7[i].std);
                    sdata.push(filtered7[i].school);
                    ndata.push(filtered7[i].national);
                    cdata.push(filtered7[i].current);
                  }
                  set7CSNList(filtered7[0].reportname);
                  set7SNList(filtered7[0].report_name);
                  set7Std(stan);
                  set7School(sdata);
                  set7National(ndata);
                  set7Current(cdata);
                }

                const ffiltered7 = arr1.filter((user) =>
                  user.report_name.includes("Sit Ups in 1 min")
                );
                if (ffiltered7.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < ffiltered7.length; i++) {
                    stan.push(ffiltered7[i].std);
                    sdata.push(ffiltered7[i].school);
                    ndata.push(ffiltered7[i].national);
                    cdata.push(ffiltered7[i].current);
                  }
                  set7CSNList(ffiltered7[0].reportname);
                  set7FSNList(ffiltered7[0].report_name);
                  set7FStd(stan);
                  set7FSchool(sdata);
                  set7FNational(ndata);
                  set7FCurrent(cdata);
                }

                const filteredover = arr.filter((user) =>
                  user.report_name.includes("Overall Report")
                );
                if (filteredover.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filteredover.length; i++) {
                    sdata.push(filteredover[i].school);
                    ndata.push(filteredover[i].national);
                    cdata.push(filteredover[i].current);
                  }
                  setSNOver(filteredover[0].report_name);
                  setOverSchool(sdata);
                  setOverNational(ndata);
                  setOverCurrent(cdata);
                }

                const filteredfover = arr1.filter((user) =>
                  user.report_name.includes("Overall Report")
                );
                if (filteredfover.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  var cdata = [];
                  for (let i = 0; i < filteredfover.length; i++) {
                    sdata.push(filteredfover[i].school);
                    ndata.push(filteredfover[i].national);
                    cdata.push(filteredfover[i].current);
                  }
                  setFSNOver(filteredfover[0].report_name);
                  setFOverSchool(sdata);
                  setFOverNational(ndata);
                  setFOverCurrent(cdata);
                }
              }
              // console.log('End',new Date().toLocaleString())
              setLoading(false);
            });
          }
        });
      }
    });
  }, []);

  const downloadPDF = () => {
    dotracking(
      "download PDF button in Fitness Trends $$ " + trackobj.genderfitnesstrendsheading
    );

    const content = document.getElementById("pdf-content");

    if (content) {
      html2canvas(content, { scrollY: -window.scrollY }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 296;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 10;
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight +10;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("Fitness Trends.pdf");
      });
    } 
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Fitness Trends</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Student Fitness</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Fitness Trends
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading && (
        <>
          {(snlist1 === "" ||
            fsnlist1 === "" ||
            snlist2 === "" ||
            fsnlist2 === "" ||
            snlist3 === "" ||
            fsnlist3 === "" ||
            snlist4 === "" ||
            fsnlist4 === "" ||
            snlist5 === "" ||
            fsnlist5 === "" ||
            snlist6 === "" ||
            fsnlist6 === "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-2 text-success text-danger">
                      Fitness Trends will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}

{(snlist1 !== "" ||
            fsnlist1 !== "" ||
            snlist2 !== "" ||
            fsnlist2 !== "" ||
            snlist3 !== "" ||
            fsnlist3 !== "" ||
            snlist4 !== "" ||
            fsnlist4 !== "" ||
            snlist5 !== "" ||
            fsnlist5 !== "" ||
            snlist6 !== "" ||
            fsnlist6 !== "") && (
                <div className="row">
                <div className="col-12 mt-2">
                  <div className="text-right">
                    <a className="btn btn-secondary" onClick={downloadPDF}>
                      <i className="fa fa-file-pdf"></i> Download PDF
                    </a>
                  </div>
                </div>
            </div>
          )}
        <div className="mt-2" id="pdf-content">
          {(snlist1 !== "" || fsnlist1 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist1}</h4>
                  {snlist1 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std1,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school1,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national1,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current1,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist1,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg 30m run (in sec.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {fsnlist1 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd1,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool1,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational1,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent1,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist1,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg 30m run (in sec.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(snlist2 !== "" || fsnlist2 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist2}</h4>
                  {snlist2 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std2,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school2,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national2,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current2,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist2,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Beep Test (in #laps)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}

                  {fsnlist2 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd2,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool2,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational2,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent2,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist2,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Beep Test (in #laps)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(snlist3 !== "" || fsnlist3 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist3}</h4>
                  {snlist3 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std3,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school3,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national3,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current3,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist3,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Medicine Ball Throw (in m.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}

                  {fsnlist3 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd3,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool3,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational3,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent3,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist3,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Medicine Ball Throw (in m.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(snlist4 !== "" || fsnlist4 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist4}</h4>
                  {snlist4 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std4,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school4,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national4,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current4,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist4,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Sit & Reach (in cm.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}

                  {fsnlist4 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd4,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool4,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational4,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent4,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist4,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Sit & Reach (in cm.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <br/>
          <br/>
          <br/>
          <br/>
          {(snlist5 !== "" || fsnlist5 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist5}</h4>
                  {snlist5 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std5,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school5,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national5,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current5,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist5,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Standing Broad Jump (in m.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}

                  {fsnlist5 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd5,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool5,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational5,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent5,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist5,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg Standing Broad Jump (in m.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(snlist6 !== "" || fsnlist6 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist6}</h4>
                  {snlist6 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std6,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school6,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national6,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current6,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist6,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg No. of Sit Ups in 30 sec.",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}

                  {fsnlist6 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd6,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool6,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational6,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent6,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist6,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg No. of Sit Ups in 30 sec.",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(snlist7 !== "" || fsnlist7 !== "") && (
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <h4 className="mb-2 text-success text-center">{csnlist7}</h4>
                  {snlist7 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: std7,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: school7,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: national7,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: current7,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlist7,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg No. of Sit Ups in 1 min.",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {fsnlist7 !== "" && (
                    <div className="col-md-6">
                      <Bar
                        data={{
                          labels: fstd7,
                          datasets: [
                            {
                              label: moment(pdata).format("MMM YYYY"),
                              data: fschool7,
                              backgroundColor: "#d8a3e9",
                            },
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: fnational7,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: fcurrent7,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: fsnlist7,
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Avg No. of Sit Ups in 1 min.",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
</div>
          {/* {snlistover !== "" && (
            <>
              <hr />

              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: [""],
                          datasets: [
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: schoolover,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: nationalover,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlistover,
                            },
                          },
                          scales: {
                            y: {
                              title: {
                                display: true,
                                text: "Average (in sec)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
        </>
      )}
    </div>
  );
};
