import { React, useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  photoscreate,
  photosdelete,
  photoslist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { Reftourdetail } from "../../../../../services/referee";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import Swal from "sweetalert2";
import { sendphotos } from "../../../../../services/communication";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { coachtourviewdetail } from "../../../../../services/coach";
export const PhotoUpload = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [photolist, setPhotoLink] = useState([]);
  const [show, setShow] = useState(false);

  const [ishow, setIShow] = useState(false);
  const [pimage, setPImage] = useState(false);

  const [pplist, setPPList] = useState([]);

  function openPLightbox(index, ilist) {
    setCurrentImage(index);
    if (ilist.length !== 0) {
      setPPList(ilist);
    }

    setViewerIsOpen(true);
  }
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function handleImage(img) {
    setPImage(img);
    setIShow(true);
    dotracking(
      "share photos in upload photo for " +
        img +
        " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
  }

  function imageclose() {
    setIShow(false);
    setPImage("");
    dotracking(
      "close share photos in upload photo for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
  }

  function handleShow() {
    arr1 = [];
    arr = [];
    setPhoto([]);
    setErrorMsg([]);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    arr1 = [];
    arr = [];
    setPhoto([]);
    setErrorMsg([]);
  }

  let arr = [];
  let arr1 = [];
  function photofile(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length !== 0) {
        if (event.target.files[i].name.match(/\.(jpg|jpeg|png)$/i)) {
          var size = event.target.files[i].size / 1024 / 1024;
          if (size > 50) {
            arr1.push(
              event.target.files[i].name + " size should be less than 50MB"
            );
            setErrorMsg([...arr1]);
          } else {
            arr.push(event.target.files[i]);
          }
        } else {
          arr1.push(
            event.target.files[i].name + " should be jpg or jpeg or png format"
          );
        }
      }
    }
    setPhoto(arr);
    setPhoto([...photo, ...arr]);
    setErrorMsg(arr1);
    setErrorMsg([...errorMsg, ...arr1]);
  }

  const navigate = useNavigate();
  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "back button in upload photo for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in upload photo for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in upload photo for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    }
  }
  const [loading, setLoading] = useState(false);

  function submitphoto() {
    if (photo.length !== 0) {
      setLoading(true);
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("tour_id", id);
      temp.append("image_count", photo.length);
      if (photo.length !== 0) {
        for (let i = 0; i < photo.length; i++) {
          if (photo[i] !== "") {
            temp.append("image_links_" + [i + 1], photo[i]);
          }
        }
      }
      dotracking(
        "add button in upload photo for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.photoheading
      );
      photoscreate(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          handleclose();
          toast.success(response.message);
          listall();
        }
      });
    } else {
      setErrorMsg(["Please select photo"]);
    }
  }
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listall();
  }, []);

  function listall() {
    setPhotoLink([]);
    photoslist(id).then((response) => {
      if (response.status === true) {
        if (response.data.tournamentlist.length !== 0) {
          let arr1 = [];
          for (let i = 0; i < response.data.tournamentlist.length; i++) {
            if (localStorage.getItem("Role") === "3") {
              if (
                response.data.tournamentlist[i].created_by ===
                localStorage.getItem("UID")
              )
                arr1.push({
                  id: response.data.tournamentlist[i].id,
                  src:
                    response.data.tournamentimage_path +
                    response.data.tournamentlist[i].imagename,
                  width: 1,
                  height: 1,
                });
            } else if (localStorage.getItem("Role") === "7") {
              if (
                response.data.tournamentlist[i].created_by ===
                localStorage.getItem("UID")
              )
                arr1.push({
                  id: response.data.tournamentlist[i].id,
                  src:
                    response.data.tournamentimage_path +
                    response.data.tournamentlist[i].imagename,
                  width: 1,
                  height: 1,
                });
            } else if (localStorage.getItem("Role") !== "3") {
              arr1.push({
                id: response.data.tournamentlist[i].id,
                src:
                  response.data.tournamentimage_path +
                  response.data.tournamentlist[i].imagename,
                width: 1,
                height: 1,
              });
            }
          }
          setPhotoLink(arr1);
        }
      }
    });
  }

  function deletephoto(index) {
    dotracking(
      "delete button in upload photo for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        photosdelete(photolist[index].id).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function sendall() {
    if (photolist.length !== 0) {
      dotracking(
        "send notification button in upload photo for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.photoheading
      );
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          sendphotos(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add Photos for " + tname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Photo Upload</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Photo Upload
          </li>
        </ol>
      </div>
      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="text-left">
                      <h3>{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      <a
                        className="btn btn-success me-2 mt-1"
                        onClick={handleShow}
                      >
                        <i className="fe fe-plus"></i> Add Photo
                      </a>
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3") && (
                        <a
                          className="btn btn-secondary me-2 mt-1"
                          onClick={sendall}
                        >
                          <i className="fa fa-paper-plane"></i> Send Photos
                          Notification
                        </a>
                      )}

                      <a className="btn btn-gray mt-1" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: show ? "block" : "none" }}
                  className={show ? "modal fade show" : "modal fade"}
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4> Add Photos</h4>
                      </div>
                      <div
                        className="modal-body"
                        style={{ maxHeight: "60vh", overflowY: "auto" }}
                      >
                        <Row className="row-sm mt-2">
                          <Col lg={12} md={12}>
                            <div className="form-group">
                              <label className="mb-2">
                                Photos <span className="errortext"> *</span>
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                multiple
                                onChange={photofile}
                                accept=".jpg,.jpeg,.png"
                              />
                            </div>
                          </Col>
                          {photo.map((data, i) => {
                            return (
                              <div className="col-md-6" key={i}>
                                <p>{data.name}</p>
                              </div>
                            );
                          })}
                          {errorMsg.map((data, i) => {
                            return (
                              <span className="errortext" key={i}>
                                {data}
                              </span>
                            );
                          })}
                        </Row>
                      </div>
                      <div className="modal-footer">
                        <div className="text-right">
                          <a
                            className="btn btn-danger me-2"
                            onClick={handleclose}
                          >
                            Close
                          </a>
                          <a className="btn btn-success" onClick={submitphoto}>
                            Add
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className={show ? "modal-dialog modal-lg mymodal open" : "mymodal"}>
                  <div className="modoverlay"></div>
                  <div className="mymodal-content">
                    <h4> Add Photos</h4>
                   
                    <div className="border-bottom"></div>
                    <Row className="row-sm mt-2">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Photos <span className="errortext"> *</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={photofile}
                            accept=".jpg,.jpeg,.png"
                          />
                          {photo.map((data, i) => {
                            return <li key={i}>{data.name}</li>;
                          })}
                        </div>
                      </Col>
                      {errorMsg.map((data, i) => {
                        return (
                          <span className="errortext" key={i}>
                            {data}
                          </span>
                        );
                      })}
                    </Row>
                    <div  className="text-right">
                    <a className="btn btn-danger me-2" onClick={handleclose}>
                      Close
                    </a>
                    <a className="btn btn-success" onClick={submitphoto}>
                      Add
                    </a>
                    </div>
                  </div>
                </div> */}

                {/* <Modal show={show} size="lg" onHide={handleclose}>
                  <Modal.Header>
                    <h4> Add Photos</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Photos <span className="errortext"> *</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={photofile}
                            accept=".jpg,.jpeg,.png"
                          />
                          {photo.map((data, i) => {
                            return <li key={i}>{data.name}</li>;
                          })}
                        </div>
                      </Col>
                      {errorMsg.map((data, i) => {
                        return (
                          <span className="errortext" key={i}>
                            {data}
                          </span>
                        );
                      })}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    <a className="btn btn-success" onClick={submitphoto}>
                      Add
                    </a>
                  </Modal.Footer>
                </Modal> */}
                {photolist.length === 0 && (
                  <h4 className="text-center text-danger">
                    Photos will be updated soon
                  </h4>
                )}

                <div className="row">
                  {photolist.map((data, i) => {
                    return (
                      <div className="col-md-2 mt-4" key={i}>
                        {(localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3" ||
                          localStorage.getItem("Role") === "7") && (
                          <>
                            <a
                              className="photo-upshare"
                              onClick={() => handleImage(data.src)}
                            >
                              <i className="fas fa-share fa-lg"></i>
                            </a>
                            <a
                              className="photo-mark"
                              onClick={() => deletephoto(i)}
                            >
                              <i className="fa fa-trash fa-lg"></i>
                            </a>
                          </>
                        )}
                        {(localStorage.getItem("Role") === "4" ||
                          localStorage.getItem("Role") === "5") && (
                          <a
                            className="photo-share"
                            onClick={() => handleImage(data.src)}
                          >
                            <i className="fas fa-share fa-lg"></i>
                          </a>
                        )}
                        <img
                          className="photoimg"
                          src={data.src}
                          height="100px"
                          onClick={() => openPLightbox(i, photolist)}
                          alt="Photo"
                        />
                      </div>
                    );
                  })}
                </div>

                <div className={ishow ? "mymodal open" : "mymodal"}>
                  <div className="modoverlay"></div>
                  <div className="mymodal-content">
                    <h4 className="text-center">Share Photos</h4>
                    <span className="close-btn" onClick={imageclose}>
                      &times;
                    </span>
                    <div className="border-bottom"></div>
                    <Row className="row-sm mt-3">
                      <div className="text-center">
                        <WhatsappShareButton
                          url={pimage}
                          title="Hey, check out my photos!"
                          className="me-3"
                        >
                          <WhatsappIcon size={44} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton
                          url={pimage}
                          quote="Hey, check out my photos!"
                          className="me-3"
                        >
                          <FacebookIcon size={44}></FacebookIcon>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={pimage}
                          title="Hey, check out my photos!"
                          className="me-3"
                        >
                          <TwitterIcon size={44}></TwitterIcon>
                        </TwitterShareButton>
                      </div>
                    </Row>
                  </div>
                </div>

                {/* <Modal show={ishow} size="lg" onHide={imageclose}>
                  <Modal.Header>
                    <h4> Photos</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <img src={pimage} alt="Photo" />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-gray" onClick={imageclose}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal> */}

                {/* <Gallery
                  photos={photolist}
                  onClick={(e, { index }) => deletephoto(index)}
                /> */}

                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={pplist.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
