import React, { Fragment } from "react";
import { useEffect } from "react";
import {
  Existsornotplayer,
  Playerprofiledetails,
  assessmentlatestdetailplayer,
  playerachievecount,
  playercalendarlist,
  playerothereventslist,
  playerregisteredtour,
  playerschoolteam,
  playertourbanner,
  playertourlist,
} from "../../../services/player";
import moment from "moment";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import bannerlog from "../../trackingbanner";
import { gettokenfromapi } from "../../../services/superadmin";
import { sahassessmentdatelist } from "../../../services/sahapi";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Col, Modal, Row } from "react-bootstrap";
import { schoollistmaster } from "../../../services/master";
import WebUrl from "../../../AWebUrl";

export const AthleteDashboard = () => {
  const navigate = useNavigate();
  const [filterProduct, setFilterProduct] = useState([]);
  const [regfilterProduct, setRegFilterProduct] = useState([]);
  const [eventfilterProduct, setEventFilterProduct] = useState([]);
  const [regeventfilterProduct, setRegEventFilterProduct] = useState([]);

  let arr = [];
  let farr = [];
  const [fitList, setFitList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [fittot, setFitTot] = useState([]);
  const [skilltot, setSkillTot] = useState([]);
  const [assdate, setAssDate] = useState("");
  const [skildate, setSkillDate] = useState("");

  const [achcount, setAchCount] = useState(0);
  const [schteam, setSchTeam] = useState([]);

  const [tourbanner, setTourBanner] = useState("");
  const [bannid, setBannerID] = useState("");

  const [linkbanner, setLinkBanner] = useState("");

  const [schweb, setSchWeb] = useState("");

  useEffect(() => {
    const temp = new FormData();
    temp.append("tournament_meet_id", 0);
    temp.append("pagename", "Dashboard");
    playertourbanner(temp).then((res) => {
      if (res.status === true && res.data.banners !== null) {
        setBannerID(res.data.banners.banner_id);
        setTourBanner(res.data.banner_path + res.data.banners.b_file);
        if (res.data.banners.banner_link !== null) {
          setLinkBanner(res.data.banners.banner_link);
        }
      }
    });
    const temp1 = new FormData();
    temp1.append("user_id", localStorage.getItem("UID"));
    playerothereventslist(temp1).then((response) => {
      if (response.status === true) {
        setEventFilterProduct(response.data.eligible_event);
        setRegEventFilterProduct(response.data.registerevent);
      }
    });
    playertourlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.eligible_tournaments);
        setRegFilterProduct(response.data.registered_tour);
      }
    });

    playerachievecount().then((response) => {
      if (response.status === true) {
        setAchCount(response.data.totalAchievementsCount);
      }
    });

    playerschoolteam(localStorage.getItem("UID")).then((response) => {
      if (response.status === true) {
        setSchTeam(response.data);
      }
    });
    Playerprofiledetails(localStorage.getItem("Name")).then((res2) => {
      if (res2.status === true) {
        if (res2.data.athletelist !== null) {
          if (res2.data.athletelist.old_user_id !== null) {
            const pltemp = new FormData();
            pltemp.append(
              "data_req",
              "asmnt_data" + res2.data.athletelist.old_user_id
            );
            gettokenfromapi(pltemp).then((res) => {
              if (res.status === true) {
                sahassessmentdatelist(
                  "asmnt_data&esid=" + res2.data.athletelist.old_user_id,
                  res.data
                ).then((response) => {
                  if (response.assmts[0].length !== 0) {
                    // for (let i = 0; i < response.assmts[0].length; i++) {

                    if (response.assmts[0][0].assmts.length !== 0) {
                      const fdata = response.assmts[0][0].assmts.filter(
                        (data) => {
                          return data.type === "fitness";
                        }
                      );
                      setFitList(fdata);
                      let origString = response.assmts[0][0].date;
                      let stringToAdd = "-";
                      let indexPosition = 2;
                      let newString1 =
                        origString.slice(0, indexPosition) +
                        stringToAdd +
                        origString.slice(indexPosition);
                      let indexPosition1 = 5;
                      let newString =
                        newString1.slice(0, indexPosition1) +
                        stringToAdd +
                        newString1.slice(indexPosition1);
                      setAssDate(newString);
                      setSkillDate(newString);
                      const sdata = response.assmts[0][0].assmts.filter(
                        (data) => {
                          return data.type === "skill";
                        }
                      );

                      setSkillList(sdata);
                    }
                    // }
                  }
                });
              }
            });
          }

          const tempc = new FormData();
          tempc.append("player_id", localStorage.getItem("UID"));
          tempc.append("school_id", res2.data.athletelist.school_id);
          playercalendarlist(tempc).then((response) => {
            if (response.status === true) {
              let schedule = [];
              if (response.data.tournament_and_events.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.tournament_and_events.length;
                  i++
                ) {
                  var clr = "";
                  var clink = "";
                  const url = window.location.href;
                  const head = url.split("/#/");
                  if (
                    response.data.tournament_and_events[i].type === "Tournament"
                  ) {
                    clr = "#005baa";
                    clink =
                      `${head[0]}/#/player/tournamentdetail/` +
                      response.data.tournament_and_events[i].id;
                  } else if (
                    response.data.tournament_and_events[i].type ===
                      "External_1" ||
                    response.data.tournament_and_events[i].type === "External"
                  ) {
                    clr = "#c21a1a";
                    clink =
                      `${head[0]}/#/player/externaltournamentdetail/` +
                      response.data.tournament_and_events[i].id;
                  } else {
                    clr = "#e59216";
                    clink =
                      `${head[0]}/#/player/othereventdetail/` +
                      response.data.tournament_and_events[i].id;
                  }

                  schedule.push({
                    title: response.data.tournament_and_events[i].meet_name,
                    start: response.data.tournament_and_events[i].startdate,
                    end: response.data.tournament_and_events[i].enddate,
                    description: response.data.tournament_and_events[i].type,
                    sports: response.data.tournament_and_events[i].sports_type,
                    event:
                      response.data.tournament_and_events[i].meet_event_type,
                    color: clr,
                    url: clink,
                  });
                }
                setEvents(schedule);
              }
            }
          });

          schoollistmaster().then((response) => {
            if (response.status === true) {
              const sdata = response.data.details.find((data) => {
                return data.id == res2.data.athletelist.school_id;
              });
              if (sdata !== undefined) {
                if (sdata.website_publised_status == 1) {
                  setSchWeb(WebUrl + sdata.website_url);
                }
              }
            }
          });
        }
      }
    });

    // assessmentlatestdetailplayer(localStorage.getItem("UID")).then(
    //   (response) => {
    //     if (response.status === true) {
    //       farr = [];
    //       if (response.data.fitness_assessment_details.length !== 0) {
    //         var last =
    //           response.data.fitness_assessment_details.slice(-1)[0]
    //             .assessment_type_count;

    //         for (let j = 1; j <= last; j++) {
    //           const fqfilter = response.data.fitness_assessment_details.filter(
    //             (data) => {
    //               return data.assessment_type_count === j;
    //             }
    //           );
    //           var tfq = fqfilter.slice(-1)[0].attribute_fq;

    //           const sqfilter = response.data.fitness_assessment_details.filter(
    //             (data) => {
    //               return data.assessment_type_count === j;
    //             }
    //           );
    //           var tsq = sqfilter.slice(-1)[0].attribute_sq;

    //           const tqfilter = response.data.fitness_assessment_details.filter(
    //             (data) => {
    //               return data.assessment_type_count === j;
    //             }
    //           );
    //           var ttq = tqfilter.slice(-1)[0].attribute_tq;

    //           farr.push({
    //             id: j,
    //             fq: tfq,
    //             sq: tsq,
    //             tq: ttq,
    //           });
    //         }
    //         setFitTot(farr);
    //       }
    //       if (response.data.fitness_assessment_details.length !== 0) {
    //         setAssDate(
    //           response.data.fitness_assessment_details[0].assessment_date
    //         );
    //       }
    //       setFitList(response.data.fitness_assessment_details);
    //       arr = [];
    //       if (response.data.skill_assessment_details.length !== 0) {
    //         var last =
    //           response.data.skill_assessment_details.slice(-1)[0]
    //             .assessment_type_count;

    //         for (let j = 1; j <= last; j++) {
    //           const sfilter = response.data.skill_assessment_details.filter(
    //             (data) => {
    //               return data.assessment_type_count === j;
    //             }
    //           );
    //           var lgrade = sfilter.slice(-1)[0].overall_grade;
    //           arr.push({
    //             id: j,
    //             overall_grade: lgrade,
    //           });
    //         }
    //         setSkillTot(arr);
    //       }
    //       if (response.data.skill_assessment_details.length !== 0) {
    //         setSkillDate(
    //           response.data.skill_assessment_details[0].assessment_date
    //         );
    //       }
    //       setSkillList(response.data.skill_assessment_details);
    //     }
    //   }
    // );
  }, []);

  function registeredtour(tid, tname) {
    Existsornotplayer(localStorage.getItem("Name"), tid).then((res) => {
      if (res.status === true) {
        playerregisteredtour(tid).then((response) => {
          if (response.status === false) {
            Swal.fire({
              title: response.message,
              showCancelButton: false,
              confirmButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          } else {
            localStorage.setItem("Page", "2");
            dotracking(
              "tournament registered button in player dashboard for " +
                tname +
                " (" +
                tid +
                ") tournament $$ " +
                trackobj.tourregheading
            );
            navigate("/player/tournamentregister/" + tid);
          }
        });
      } else {
        Swal.fire({
          title: res.message,
          showCancelButton: false,
          confirmButtonColor: "#6259ca",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    });
  }

  function viewlist(vid, vname) {
    dotracking(
      "view tournament button in dashboard for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    navigate("/player/tournamentdetail/" + vid);
  }

  function vieweventlist(vid, vname) {
    dotracking(
      "view event button in dashboard for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.workdetheading
    );
    navigate("/player/othereventdetail/" + vid);
  }

  function viewteam(tid, vname) {
    dotracking(
      "view team button in dashboard for " +
        vname +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.myteamheading
    );
    navigate("/player/myteams/" + tid);
  }

  function achlist() {
    dotracking("achievements card in dashboard $$ " + trackobj.achieveheading);
    navigate("/player/achievements");
  }

  function banlink() {
    dotracking(
      "banner in dashboard for " +
        tourbanner +
        " (" +
        bannid +
        ") $$ " +
        trackobj.dashheading
    );
    bannerlog(bannid, trackobj.bannerdashboard);
    if (linkbanner !== "") {
      window.open(linkbanner, "_blank");
    }
  }

  function weblink() {
    dotracking(
      "visit school website button in dashboard for " +
        schweb +
        " $$ " +
        trackobj.dashheading
    );
    if (schweb !== "") {
      window.open(schweb, "_blank");
    }
  }

  const handleEventClick = (info) => {
    const clickedEvent = {
      title: info.event.title,
      start: info.event.startStr,
      end: info.event.endStr,
      description: info.event.description,
      sports: info.event.sports,
      event: info.event.event,
    };
    openModal(clickedEvent);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent("");
    setModalIsOpen(false);
  };
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Dashboard</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </div>

        <div className="row row-cards">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-info">
                  <i className="icon-user text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">UID</p>
                <p className="h3 text-center text-info">
                  {localStorage.getItem("Name")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-primary">
                  <i className="icon icon-location-pin text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Name</p>
                <p className="h3 text-center text-primary-1">
                  {localStorage.getItem("User")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <a onClick={achlist}>
                <div className="card-body text-center feature">
                  <div className="fa-stack fa-lg icons shadow-default bg-purple">
                    <i className="fa fa-soccer-ball-o text-white"></i>
                  </div>
                  <p className="card-text mt-3 mb-3">Achievements</p>
                  <p className="h3 text-center text-purple">{achcount}</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="row row-cards">
          {schteam.map((data, i) => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3" key={i}>
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <div className="fa-stack fa-lg icons shadow-default bg-gray">
                      <i className="icon-people text-white"></i>
                    </div>
                    <p className="card-text mt-3 mb-3 text-primary">
                      <b>Team : {data.school_team_name}</b>
                    </p>
                    <p className="h4 text-center text-success">
                      Age Category : {data.agecategory}
                    </p>
                    <p className="h4 text-center text-purple">
                      Sports : {data.sports}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {tourbanner !== "" && (
          <a onClick={banlink}>
            <img
              src={tourbanner}
              className="ag-courses-item_img border-bottom"
              alt="bannerimg"
            />
          </a>
        )}

        {schweb !== "" && (
          <div className="text-center">
            <a onClick={weblink} className="btn btn-success">
              Visit School Website
            </a>
          </div>
        )}
        <div className="ag-format-container">
          <div className="ag-courses_box">
            {regfilterProduct.length !== 0 && (
              <div className="ag_courses_head mt-4">
                <h3>Registered Tournaments</h3>
              </div>
            )}
            {regfilterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.meet_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_open).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_deadline_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-left viewbtndash"
                        onClick={() => viewlist(data.id, data.meet_name)}
                      >
                        View Tournament
                      </a>
                      {data.sports_type.toUpperCase() !== "ATHLETICS" && (
                        <a
                          className="btn btn-sm btn-success float-right viewbtndash"
                          onClick={() => viewteam(data.id, data.meet_name)}
                        >
                          View Team
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {filterProduct.length !== 0 && (
              <div className="ag_courses_head">
                <h3>Eligible Tournaments</h3>
              </div>
            )}

            {filterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.meet_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_open).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_deadline_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-left viewbtndash"
                        onClick={() => viewlist(data.id, data.meet_name)}
                      >
                        View Tournament
                      </a>
                      {data.meettype !== "Inter School Tournament" && (
                        <a
                          className="btn btn-sm btn-success float-right viewbtndash"
                          onClick={() =>
                            registeredtour(data.id, data.meet_name)
                          }
                        >
                          Register
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {regeventfilterProduct.length !== 0 && (
              <div className="ag_courses_head">
                <h3>Registered Events</h3>
              </div>
            )}

            {regeventfilterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.event_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-left viewbtndash"
                        onClick={() => vieweventlist(data.id, data.event_name)}
                      >
                        View Event
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}

            {eventfilterProduct.length !== 0 && (
              <div className="ag_courses_head">
                <h3>Eligible Events</h3>
              </div>
            )}

            {eventfilterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.event_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-left viewbtndash"
                        onClick={() => vieweventlist(data.id, data.event_name)}
                      >
                        View Event
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <hr />
        <div className="container">
          <div className="ag_courses_head">
            <div className="row">
              <h4>Fitness Assessment</h4>
            </div>
          </div>
          {fitList.length === 0 && skillList.length === 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {fitList.length === 0 && skillList.length !== 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Fitness Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {fitList.length !== 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="text-success">
                      {/* Assessment Date - {moment(assdate).format("DD-MM-YYYY")} */}
                      Assessment Date - {assdate}
                    </h3>

                    <h4>5 Point Grading System</h4>
                    <span className="errortext">
                      A - 5 Points (Excellent), B - 4 Points (Very Good), C - 3
                      Points (Good) , D - 2 Points (Average) , E - 1 Point
                      (Needs Improvement)
                    </span>
                    {fitList.map((item, j) => {
                      return (
                        <>
                          <table
                            className="table table-bordered table-hover mt-3"
                            key={j}
                          >
                            <thead>
                              <tr>
                                <th>Parameters Mesured</th>
                                <th>Grade</th>
                                <th>Score</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.attributes.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.fst_theme_name}</td>
                                    <td>{data.grade}</td>
                                    <td>{data.score}</td>
                                    <td>{data.name}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <thead>
                              <tr>
                                <th>FQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.fq}</th>
                              </tr>
                              <tr>
                                <th>SQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.sq}</th>
                              </tr>
                              <tr>
                                <th>TQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.tq}</th>
                              </tr>
                            </thead>
                          </table>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="ag_courses_head ">
                  <h4>Remedial Actions</h4>
                </div>

                {fitList.map((item, j) => {
                  return (
                    <>
                      <div className="row" key={j}>
                        {item.attributes.map((data, i) => {
                          return (
                            <div className="col-md-4" key={i}>
                              <div className="our_solution_category">
                                <div className="solution_cards_box">
                                  <div className="solution_card">
                                    <div className="hover_color_bubble"></div>
                                    <div className="so_top_icon">
                                      {data.fst_theme_name.includes(
                                        "Anaerobic"
                                      ) && (
                                        <img
                                          src={require("../../../assets/images/Anaerobic.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Aerobic"
                                      ) && (
                                        <img
                                          src={require("../../../assets/images/Aerobic.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Flexibility"
                                      ) && (
                                        <img
                                          src={require("../../../assets/images/Fitness.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {data.fst_theme_name.includes(
                                        "Abdominal"
                                      ) && (
                                        <img
                                          src={require("../../../assets/images/Abdominal.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {(data.fst_theme_name.includes("Lower") ||
                                        data.fst_theme_name.includes("LB")) && (
                                        <img
                                          src={require("../../../assets/images/Lower.png")}
                                          alt="fitness"
                                        />
                                      )}
                                      {(data.fst_theme_name.includes("Upper") ||
                                        data.fst_theme_name.includes("UB")) && (
                                        <img
                                          src={require("../../../assets/images/Upper.png")}
                                          alt="fitness"
                                        />
                                      )}
                                    </div>
                                    <div className="solu_title">
                                      <h3>{data.fst_theme_name}</h3>
                                    </div>
                                    <div className="solu_description">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: data.student_fdbk,
                                        }}
                                        title={data.student_fdbk}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
          )}

          {skillList.length === 0 && fitList.length !== 0 && (
            <div className="row">
              <div className="ag_courses_head">
                <h4>Skill Assessment</h4>
              </div>
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Skill Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          {skillList.length !== 0 && (
            <div className="row">
              <div className="ag_courses_head">
                <h4>Skill Assessment</h4>
              </div>
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    {assdate === "" && (
                      <h3 className="text-success">
                        Assessment Date - {skildate}
                      </h3>
                    )}
                    <h4>5 Point Grading System</h4>
                    <span className="errortext">
                      A - 5 Points (Very Good), B - 4 Points (Good), C - 3
                      Points (Average) , D - 2 Points (Developing) , E - 1 Point
                      (Needs Improvement)
                    </span>
                    {skillList.map((item, j) => {
                      return (
                        <>
                          <table
                            className="table table-bordered table-hover mt-3"
                            key={j}
                          >
                            <thead>
                              <tr>
                                <th>Skills Mesured</th>
                                <th>Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.attributes.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.name}</td>
                                    <td>{data.grade}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <thead>
                              <tr>
                                <th>Overall Grade</th>
                                <th>{item.overall_grade}</th>
                              </tr>
                            </thead>
                          </table>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr />
          <div className="row">
            <div className="ag_courses_head">
              <h4>Tournament & Events Calender</h4>
            </div>
            <div className="col-12">
              <div className="card mt-0">
                <div className="card-body">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    eventClick={handleEventClick}
                  />

                  <Modal show={modalIsOpen} size="xl">
                    <Modal.Header>
                      <Modal.Title>Tournament & Events Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="table-responsive">
                        <table className="sp-league-table sp-data-table">
                          <thead className="sp-table-header">
                            <tr role="row">
                              <th className="text-center">Title</th>
                              <th className="text-center">Start Date</th>
                              <th className="text-center">End Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="sp-row-no-0 even" role="row">
                              <td className="data-pct">
                                {selectedEvent.title}
                              </td>
                              <td className="data-pct">
                                {moment(selectedEvent.start).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              {selectedEvent.end !== "" && (
                                <td className="data-pct">
                                  {moment(selectedEvent.end).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              )}
                              {selectedEvent.end === "" && (
                                <td className="data-pct">
                                  {moment(selectedEvent.start).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <a className="btn btn-gray" onClick={closeModal}>
                        Close
                      </a>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
