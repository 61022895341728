import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import {
  playerregisteredtour,
  playertourlist,
} from "../../../../services/player";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ListTourPlayer = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value,params.data.meet_name)}
              title="Tournament Detail"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {params.data.meettype !== "Inter School Tournament" &&
            <a
              onClick={() => registeredtour(params.value,params.data.meet_name)}
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </a>
            }
            
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entry_open",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Type",
      width: 170,
      field: "meettype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function registeredtour(tid,vname) {
    playerregisteredtour(tid).then((response) => {
      if (response.status === false) {
        Swal.fire({
          title: response.message,
          showCancelButton: false,
          confirmButtonColor: "#6259ca",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      } else {
        localStorage.setItem("Page","1");
        dotracking("tournament registered icon in eligible list for " + vname + " (" + tid + ") tournament $$ " + trackobj.tourregheading);
        navigate("/player/tournamentregister/" + tid);
      }
    });
  }
  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    playertourlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.eligible_tournaments);
      }
    });
  }

  function viewlist(vid,vname){
    dotracking("view icon in eligible list for " + vname + " (" + vid + ") tournament $$ " + trackobj.viewtourheading);
    navigate("/player/eligibleviewtournament/" + vid)
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Eligible Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Eligible Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
