import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import {
  agecatactivelist,
  datacubefilter,
  districtmasterlist,
  highestlevelplayedlist,
  schoolactivelist,
  schooltypebasedlist,
  sportsactivelist,
} from "../../../services/superadmin";
import moment from "moment";
import Select from "react-select";
import dotracking from "../../trackinglogs";
import exportUrl from "../../../exportUtl";
import trackobj from "../../trackingpages.json";
import { classlist } from "../../../services/master";
import { toast } from "react-toastify";

export const DataCube = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [genlist, setGenList] = useState([]);
  const [distlist, setDistList] = useState([]);
  const [schlist, setSchList] = useState([]);
  const [agelist, setAgeList] = useState([]);
  const [sportlist, setSportList] = useState([]);
  const [clslist, setClassList] = useState([]);
  const [levellist, setLevelList] = useState([]);
  const [clsid, setClsId] = useState([]);
  const [levelid, setLevelId] = useState([]);

  const [distid, setDistId] = useState(0);
  const [schtype, setSchType] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  const [curdate, setCurDate] = useState("");

  const [schid, setSchId] = useState(0);
  const [did, setDId] = useState(0);
  const [sid, setSId] = useState(0);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Username (UID)",
      width: 170,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  let arr = [];
  let arr1 = [];
  useEffect(() => {
    setCurDate(moment(new Date()).format("YYYY-MM-DD"));
    // setSDOB(moment(new Date()).format("YYYY-MM-DD"));
    // setEDOB(moment(new Date()).format("YYYY-MM-DD"));
    arr.push(
      {
        id: 1,
        gender: "All",
        check: 0,
      },
      {
        id: 2,
        gender: "Male",
        check: 0,
      },
      {
        id: 3,
        gender: "Female",
        check: 0,
      }
    );
    setGenList(arr);
    districtmasterlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].districtuid,
              label: response.data[i].districtname,
            });
          }
        }
        setDistList(arr);
      }
    });

    schoolactivelist().then((response) => {
      if (response.status === true) {
        if (response.data.details.length !== 0) {
          arr1 = [];
          for (let i = 0; i < response.data.details.length; i++) {
            arr1.push({
              value: response.data.details[i].id,
              label:
                response.data.details[i].school_unique_no +
                " - " +
                response.data.details[i].school_name,
            });
          }
        }
        setSchList(arr1);
      }
    });
    arr = [];
    arr.push(
      {
        id: 1,
        age_category_name: "Under 10",
        check: 0,
      },
      {
        id: 2,
        age_category_name: "Under 12",
        check: 0,
      },
      {
        id: 3,
        age_category_name: "Under 14",
        check: 0,
      },
      {
        id: 1,
        age_category_name: "Under 16",
        check: 0,
      },
      {
        id: 1,
        age_category_name: "Under 18",
        check: 0,
      }
    );
    setAgeList(arr);
    // agecatactivelist().then((response) => {
    //   if (response.status === true) {
    //     arr = [];
    //     if (response.data.length > 0) {
    //       for (let i = 0; i < response.data.length; i++) {
    //         arr.push({
    //           id: response.data[i].id,
    //           age_category_name: response.data[i].age_category_name,
    //           check: 0,
    //         });
    //       }
    //     }
    //     setAgeList(arr);
    //   }
    // });

    sportsactivelist().then((response) => {
      if (response.status === true) {
        arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              sports_cat_uid: response.data[i].sports_cat_uid,
              sports_category: response.data[i].sports_category,
              check: 0,
            });
          }
        }
        setSportList(arr1);
      }
    });

    classlist().then((response) => {
      if (response.status === true) {
        arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].id,
              label: response.data[i].class,
              // check: 0,
            });
          }
        }
        setClassList(arr1);
      }
    });

    highestlevelplayedlist().then((response) => {
      if (response.status === true) {
        arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].meet_level,
              label: response.data[i].meet_level,
              // check: "",
            });
          }
        }
        setLevelList(arr1);
      }
    });
  }, []);

  function selectgender(e, value, index) {
    if (genlist.length > 0) {
      if (e.target.checked === true) {
        genlist[index].check = value;
      } else {
        genlist[index].check = 0;
      }

      setGenList([...genlist]);
    }
  }

  function selectsport(e, value, index) {
    if (sportlist.length > 0) {
      if (e.target.checked === true) {
        sportlist[index].check = value;
      } else {
        sportlist[index].check = 0;
      }

      setSportList([...sportlist]);
    }
  }

  function selectclass(e, value, index) {
    if (clslist.length > 0) {
      if (e.target.checked === true) {
        clslist[index].check = value;
      } else {
        clslist[index].check = 0;
      }

      setClassList([...clslist]);
    }
  }
  function selectlevel(e, value, index) {
    if (levellist.length > 0) {
      if (e.target.checked === true) {
        levellist[index].check = value;
      } else {
        levellist[index].check = "";
      }

      setLevelList([...levellist]);
    }
  }

  function selectage(e, value, index) {
    if (agelist.length > 0) {
      if (e.target.checked === true) {
        agelist[index].check = value;
      } else {
        agelist[index].check = 0;
      }

      setAgeList([...agelist]);
    }
  }

  const [loading, setLoading] = useState(false);

  function filtering() {
    setFilterProduct([]);
    var valid =""
    if (sdob !== "") {
      if(edob === ""){
        valid = "Please Select End Date"
        toast.error(valid)
      }
    } 

    if (valid === "") {
      setLoading(true);

      const temp = new FormData();
      temp.append("district", did);
      temp.append("school_id", sid);
      var expo = "";
      if (genlist.length > 0) {
        const genfilter = genlist.filter((data) => {
          return data.check !== 0;
        });
        temp.append("gender_count", genfilter.length);
        expo = expo + " Gender ";
        for (let i = 0; i < genfilter.length; i++) {
          temp.append("gender_arr_" + [i + 1], genfilter[i].gender);
          expo = expo + genfilter[i].gender + " , ";
        }
      } else {
        temp.append("gender_count", 0);
      }
      if (sportlist.length > 0) {
        const sportfilter = sportlist.filter((data) => {
          return data.check !== 0;
        });
        temp.append("sports_count", sportfilter.length);
        expo = expo + " Sports ";
        for (let i = 0; i < sportfilter.length; i++) {
          temp.append("sports_arr_" + [i + 1], sportfilter[i].sports_cat_uid);
          expo = expo + sportfilter[i].sports_category + " , ";
        }
      } else {
        temp.append("sports_count", 0);
      }
      if (agelist.length > 0) {
        const agefilter = agelist.filter((data) => {
          return data.check !== 0;
        });
        temp.append("agecategory_count", agefilter.length);
        expo = expo + " Age Category ";

        for (let i = 0; i < agefilter.length; i++) {
          temp.append("age_cate_" + [i + 1], agefilter[i].age_category_name);
          expo = expo + agefilter[i].age_category_name + " , ";
        }
      } else {
        temp.append("agecategory_count", 0);
      }
      if (clsid.length > 0) {
        temp.append("standard_count", clsid.length);
        expo = expo + " Standard ";

        for (let i = 0; i < clsid.length; i++) {
          temp.append("standard_arr_" + [i + 1], clsid[i].value);
          expo = expo + clsid[i].label + " , ";
        }
      } else {
        temp.append("standard_count", 0);
      }
      if (levelid.length > 0) {
        temp.append("highest_level_count", levelid.length);
        expo = expo + " Highest Level Played ";

        for (let i = 0; i < levelid.length; i++) {
          temp.append("performance_arr_" + [i + 1], levelid[i].value);
          expo = expo + levelid[i].label + " , ";
        }
      } else {
        temp.append("highest_level_count", 0);
      }

      temp.append("dob_start", sdob);
      temp.append("dob_end", edob);
      temp.append("school_type", schtype);

      dotracking(
        "apply filter button in data cube with District " +
          did +
          " , School " +
          sid +
          " with School Type " +
          schtype +
          " , DOB Start " +
          sdob +
          " , DOB End " +
          edob +
          expo +
          " $$ " +
          trackobj.datacubeheading
      );
      datacubefilter(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          setFilterProduct(response.data);
        }
      });
    } else {
      toast.error("Please Select DOB End Date");
    }
  }

  function refreshall() {
    setDistId(0);
    setDId(0);
    setSchId(0);
    setSId(0);
    setSchType("");
    setSDOB("");
    setEDOB("");
    // setSDOB(moment(new Date()).format("YYYY-MM-DD"));
    // setEDOB(moment(new Date()).format("YYYY-MM-DD"));
    if (genlist.length > 0) {
      for (let i = 0; i < genlist.length; i++) {
        genlist[i].check = 0;
      }
      setGenList([...genlist]);
    }
    if (sportlist.length > 0) {
      for (let i = 0; i < sportlist.length; i++) {
        sportlist[i].check = 0;
      }
      setSportList([...sportlist]);
    }
    if (agelist.length > 0) {
      for (let i = 0; i < agelist.length; i++) {
        agelist[i].check = 0;
      }
      setAgeList([...agelist]);
    }

    setClsId(0);
    setLevelId(0);
    // if (clslist.length > 0) {
    //   for (let i = 0; i < clslist.length; i++) {
    //     clslist[i].check = 0;
    //   }
    //   setClassList([...clslist]);
    // }
    // if (levellist.length > 0) {
    //   for (let i = 0; i < levellist.length; i++) {
    //     levellist[i].check = "";
    //   }
    //   setLevelList([...levellist]);
    // }

    setFilterProduct([]);
    const temp = new FormData();
    temp.append("district", 0);
    temp.append("school_id", 0);
    temp.append("gender_count", 0);
    temp.append("sports_count", 0);
    temp.append("agecategory_count", 0);
    temp.append("highest_level_count", 0);
    temp.append("standard_count", 0);
    temp.append("dob_start", "");
    temp.append("dob_end", "");
    temp.append("school_type", "");

    dotracking("refresh button in data cube $$ " + trackobj.datacubeheading);

    // datacubefilter(temp).then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data);
    //   }
    // });
  }

  function handleSchool(e) {
    if (e === null) {
      setSchId(0);
      setSId(0);
    } else {
      setSchId(e);
      setSId(e.value);
    }
    //
  }

  function handleDist(e) {
    if (e === null) {
      setDistId(0);
      setDId(0);
    } else {
      setDistId(e);
      setDId(e.value);
    }
    //
  }

  function handleClass(e) {
    if (e === null) {
      setClsId([]);
    } else {
      setClsId(e);
    }
    //
  }

  function handleLevel(e) {
    if (e === null) {
      setLevelId([]);
    } else {
      setLevelId(e);
    }
    //
  }

  function exppdf() {
    var expo = "";
    var trk = "";
    if (genlist.length > 0) {
      const genfilter = genlist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Gender ";
      expo = expo + "&gender_count=" + genfilter.length;
      for (let i = 0; i < genfilter.length; i++) {
        expo = expo + "&gender_arr_" + [i + 1] + "=" + genfilter[i].gender;
        trk = trk + genfilter[i].gender + " , ";
      }
    }
    if (sportlist.length > 0) {
      const sportfilter = sportlist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Sports ";

      expo = expo + "&sports_count=" + sportfilter.length;
      for (let i = 0; i < sportfilter.length; i++) {
        expo =
          expo + "&sports_arr_" + [i + 1] + "=" + sportfilter[i].sports_cat_uid;
        trk = trk + sportfilter[i].sports_category + " , ";
      }
    }

    if (agelist.length > 0) {
      const agefilter = agelist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Age Category ";

      expo = expo + "&agecategory_count=" + agefilter.length;
      for (let i = 0; i < agefilter.length; i++) {
        expo =
          expo + "&age_cate_" + [i + 1] + "=" + agefilter[i].age_category_name;
        trk = trk + agefilter[i].age_category_name + " , ";
      }
    }
    dotracking(
      "export pdf button in data cube with District " +
        did +
        " , School " +
        sid +
        trk +
        " $$ " +
        trackobj.datacubeheading
    );

    window.open(
      exportUrl +
        "api/datacube/datacube-pdf?district=" +
        did +
        "&school_id=" +
        sid +
        expo,
      "_blank"
    );
  }

  function expexcel() {
    var expo = "";
    var trk = "";
    if (genlist.length > 0) {
      const genfilter = genlist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Gender ";
      expo = expo + "&gender_count=" + genfilter.length;
      for (let i = 0; i < genfilter.length; i++) {
        expo = expo + "&gender_arr_" + [i + 1] + "=" + genfilter[i].gender;
        trk = trk + genfilter[i].gender + " , ";
      }
    }
    if (sportlist.length > 0) {
      const sportfilter = sportlist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Sports ";

      expo = expo + "&sports_count=" + sportfilter.length;
      for (let i = 0; i < sportfilter.length; i++) {
        expo =
          expo + "&sports_arr_" + [i + 1] + "=" + sportfilter[i].sports_cat_uid;
        trk = trk + sportfilter[i].sports_category + " , ";
      }
    }

    if (agelist.length > 0) {
      const agefilter = agelist.filter((data) => {
        return data.check !== 0;
      });
      trk = trk + " Age Category ";

      expo = expo + "&agecategory_count=" + agefilter.length;
      for (let i = 0; i < agefilter.length; i++) {
        expo =
          expo + "&age_cate_" + [i + 1] + "=" + agefilter[i].age_category_name;
        trk = trk + agefilter[i].age_category_name + " , ";
      }
    }

    expo =
      expo +
      "&dob_start=" +
      sdob +
      "&dob_end=" +
      edob +
      "&school_type=" +
      schtype;

    dotracking(
      "export excel button in data cube with District " +
        did +
        " , School " +
        sid +
        " with School Type " +
        schtype +
        " , DOB Start " +
        sdob +
        " , DOB End " +
        edob +
        trk +
        " $$ " +
        trackobj.datacubeheading
    );

    window.open(
      exportUrl +
        "api/datacube/export-data-cube-player?district=" +
        did +
        "&school_id=" +
        sid +
        expo,
      "_blank"
    );
  }

  function handleSchType(e) {
    setSchType(e.target.value);
    setSchList([]);
    setSchId(0);
    setSId(0);
    if (e.target.value !== "") {
      schooltypebasedlist(e.target.value).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr1 = [];
            for (let i = 0; i < response.data.length; i++) {
              arr1.push({
                value: response.data[i].id,
                label:
                  response.data[i].school_unique_no +
                  " - " +
                  response.data[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else {
      schoolactivelist().then((response) => {
        if (response.status === true) {
          if (response.data.details.length !== 0) {
            arr1 = [];
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    }
  }

  function handleEDOB(e){
    if (sdob !== "") {
      setEDOB(e.target.value)
    } else {
      toast.error("Please Select DOB Start Date");
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Data Cube</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Data Cube</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            All Data Cube
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading && (
        <div className="row">
          <div className="col-md-3">
            <Form className="card">
              <div className="card-body">
                <div className="form-group">
                  <div className="row">
                    <a className="btn btn-export" onClick={refreshall}>
                      Refresh <i className="fa fa-refresh"></i>
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Gender</label>
                  {genlist.map((data, i) => {
                    return (
                      <div className="form-check mt-2" key={i}>
                        {" "}
                        <input
                          id="checkbox-switch-1"
                          className="form-check-input"
                          type="checkbox"
                          value={data.id === data.check}
                          checked={data.check !== 0 ? true : false}
                          onChange={(e) => selectgender(e, data.id, i)}
                        />{" "}
                        <label className="form-check-label">
                          {data.gender}
                        </label>{" "}
                      </div>
                    );
                  })}
                </div>
                <div className="form-group border-top">
                  <label className="form-label">Sports</label>
                  {sportlist.map((data, i) => {
                    return (
                      <div className="form-check mt-2" key={i}>
                        {" "}
                        <input
                          id="checkbox-switch-1"
                          className="form-check-input"
                          type="checkbox"
                          value={data.sports_cat_uid === data.check}
                          checked={data.check !== 0 ? true : false}
                          onChange={(e) =>
                            selectsport(e, data.sports_cat_uid, i)
                          }
                        />{" "}
                        <label className="form-check-label">
                          {data.sports_category}
                        </label>{" "}
                      </div>
                    );
                  })}
                </div>
                {/* <div className="form-group border-top">
                  <label className="form-label">Age Categories</label>
                  {agelist.map((data, i) => {
                    return (
                      <div className="form-check mt-2" key={i}>
                        {" "}
                        <input
                          id="checkbox-switch-1"
                          className="form-check-input"
                          type="checkbox"
                          value={data.id === data.check}
                          checked={data.check !== 0 ? true : false}
                          onChange={(e) => selectage(e, data.id, i)}
                        />{" "}
                        <label className="form-check-label">
                          {data.age_category_name}
                        </label>{" "}
                      </div>
                    );
                  })}
                </div> */}
                {/* <div className="form-group border-top">
                  <label className="form-label">Class/Standard</label>
                  {clslist.map((data, i) => {
                    return (
                      <div className="form-check mt-2" key={i}>
                        {" "}
                        <input
                          id="checkbox-switch-1"
                          className="form-check-input"
                          type="checkbox"
                          value={data.id === data.check}
                          checked={data.check !== 0 ? true : false}
                          onChange={(e) =>
                            selectclass(e, data.id, i)
                          }
                        />{" "}
                        <label className="form-check-label">
                          {data.class}
                        </label>{" "}
                      </div>
                    );
                  })}
                </div>
                <div className="form-group border-top">
                  <label className="form-label">Highest Level Played</label>
                  {levellist.map((data, i) => {
                    return (
                      <div className="form-check mt-2" key={i}>
                        {" "}
                        <input
                          id="checkbox-switch-1"
                          className="form-check-input"
                          type="checkbox"
                          value={data.id === data.check}
                          checked={data.check !== "" ? true : false}
                          onChange={(e) =>
                            selectlevel(e, data.id, i)
                          }
                        />{" "}
                        <label className="form-check-label">
                          {data.meet_level}
                        </label>{" "}
                      </div>
                    );
                  })}
                </div> */}
                <hr />
              </div>
            </Form>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="row card-body p-2">
                <div className="col-md-4">
                  <label className="form-label">District</label>
                  <Select
                    className="dropdown"
                    placeholder="Select District"
                    value={distid}
                    options={distlist}
                    onChange={handleDist}
                    isSearchable={true}
                    isClearable
                    hidden
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">School Type</label>
                  <select
                    className="form-control custom-select"
                    value={schtype}
                    onChange={handleSchType}
                  >
                    <option value="">-- Filter by School Type--</option>
                    <option value="Private">Private</option>
                    <option value="CSR">CSR</option>
                    <option value="Government">Government</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label className="form-label">School</label>
                  <Select
                    className="dropdown"
                    placeholder="Select School"
                    value={schid}
                    options={schlist}
                    onChange={handleSchool}
                    isSearchable={true}
                    isClearable
                    hidden
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Class/Standard</label>
                  <Select
                    className="dropdown"
                    placeholder="Select Class"
                    value={clsid}
                    options={clslist}
                    onChange={handleClass}
                    isSearchable={true}
                    isClearable
                    isMulti
                    hidden
                  />
                </div>

                <div className="col-md-6">
                  <label className="form-label">Highest Played Level</label>
                  <Select
                    className="dropdown"
                    placeholder="Select Highest Played Level"
                    value={levelid}
                    options={levellist}
                    onChange={handleLevel}
                    isSearchable={true}
                    isClearable
                    isMulti
                    hidden
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">DOB Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    max={curdate}
                    value={sdob}
                    onChange={(e) => setSDOB(e.target.value)}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">DOB End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min={sdob}
                    max={curdate}
                    value={edob}
                    onChange={handleEDOB}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>

                <div className="col-md-4 mt-6">
                  <div className="text-right">
                    <a className="btn btn-success me-2" onClick={filtering}>
                      Apply Filter
                    </a>
                    <a className="btn btn-export me-2" onClick={expexcel}>
                      <i className="fa-solid fa-file-export"></i> Export Excel
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={filterProduct}
                pagination="true"
                paginationPageSize="10"
                sortingOrder={sorting}
                suppressExcelExport={true}
                suppressDragLeaveHidesColumns={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
