import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function bloodlist() {
  try {
    return await axios
      .get(`${baseUrl}/bloodgrouplist`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function schoollistmaster() {
  try {
    return await axios
      .get(`${baseUrl}/schoollist`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statelist() {
    try {
      return await axios
        .get(`${baseUrl}/statelist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function districtlist(data) {
    try {
      return await axios
        .get(`${baseUrl}/districtlist/${data}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function citylist(data) {
    try {
      return await axios
        .get(`${baseUrl}/city-list/${data}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

export async function schoolview(id) {
  try {
    return await axios
      .get(`${baseUrl}/viewschool/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function classlist() {
  try {
    return await axios
      .get(`${baseUrl}/listclass`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function sectionlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/viewsection/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function sportslist() {
  try {
    return await axios
      .get(`${baseUrl}/sporttypes`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function funderlist() {
  try {
    return await axios
      .get(`${baseUrl}/cms/list-mfunder`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function funderdetail(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/view-mfunder?funder_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function fundercreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/create-funder-details`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function funderupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/update-funder-details`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function grpschlist() {
  try {
    return await axios
      .get(`${baseUrl}/cms/list-mgroup-schools`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpschdetail(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/view-mgroup-schools?group_school_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpschcreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/create-mgroup-schools-details`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpschupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/update-mgroup-schools-details`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function fundernewschool(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/cms/add-new-school-to-funder?funder_id=${id}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function funderdeleteschool(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/cms/delete-school-from-funder?funder_id=${id}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



export async function groupdeleteschool(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/cms/delete-school-from-group?group_of_school_id=${id}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



export async function groupnewschool(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/cms/add-new-school-to-group?group_of_school_id=${id}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


function catcherror(error) {
  console.log(error.response)
  if (error.response.status === 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
