import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { classlist, schoollist, schoollistmaster } from "../../../../services/master";
import { toast } from "react-toastify";
import {
  userregister,
  validatemail,
  validatemobile,
  validateregister,
} from "../../../../services/register";
import {
  adminaddplayer,
  schoolactivelist,
} from "../../../../services/superadmin";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  cityaddplayer,
  schoolactivelistcityreg,
} from "../../../../services/cityregionaladmin";
import { schooladdplayer } from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachaddplayer } from "../../../../services/coach";

export const AddPlayers = () => {
  const [curdate, setCurDate] = useState("");
  useEffect(() => {
    var a = new Date().getFullYear() - 7;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));

    classlist().then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      schoolactivelist().then((response) => {
        if (response.status === true) {
          setSchList(response.data.details);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      schoolactivelistcityreg().then((response) => {
        if (response.status === true) {
          setSchList(response.data.details);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      schoollistmaster().then((response) => {
        if (response.status === true) {
          setSchList(response.data.details);
        }
      });
    }
  }, []);

  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [sclist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");
  const [stusch, setStuSch] = useState("");
  const [schlist, setSchList] = useState([]);
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const [valemail, setValEmail] = useState("");
  const [emailerr, setEmailErr] = useState("");

  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setValEmail("Please Enter a valid Email Address");
      }

      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setEmailErr(response.message);
        }
      });
    }
  }

  const [valmob, setValMob] = useState("");
  const [mobnoerr, setMobErr] = useState("");

  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    setMobErr("");
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setMobErr(response.message);
        }
      });
    }
  }

  function selectclass(e) {
    setStuClass(e.target.value);
  }

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [errorMsg, setErrMsg] = useState("");
  function regsubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setValMob("");
    setValEmail("");
    setErrMsg("");
    var valid = "";
    if (mobno !== "") {
      if (mobno.length !== 10) {
        valid = "Not";
        setValMob("Mobile Number must be 10 characters");
      }
    }

    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        valid = "Not";
        setValEmail("Please Enter a valid Email Address");
      }
    }

    if (
      valid === "" &&
      fname !== "" &&
      stuclass !== "" &&
      stusec !== "" &&
      gender !== "" &&
      dob !== "" &&
      mobno !== "" &&
      email !== "" &&
      mobnoerr === "" &&
      emailerr === ""
    ) {
      const temp1 = new FormData();
      temp1.append("first_name", fname);
      temp1.append("school_id", stusch);
      temp1.append("dob", dob);
      temp1.append("gender", gender);
      validateregister(temp1).then((resp) => {
        if (resp.status === true) {
          setLoading(true);
          const temp = new FormData();
          temp.append("first_name", fname);
          temp.append("middle_name", "");
          temp.append("last_name", lname);
          temp.append("date_of_birth", dob);
          temp.append("gender", gender);
          temp.append("mobile_no", mobno);
          temp.append("email_id", email);
          temp.append("photograph", "");
          temp.append("section", stusec);
          temp.append("class", stuclass);
          if (localStorage.getItem("Role") !== "4") {
            temp.append("school_id", stusch);
          }
          if (chnoti === false) {
            temp.append("notification_enable", 0);
          } else if (chnoti === true) {
            temp.append("notification_enable", 1);
          }
          if (localStorage.getItem("Role") !== "4") {
            dotracking("submit button in add player for " + fname + " with DOB " +
            dob +
            " ,gender " +
            gender +
            " ,mobile " +
            mobno +
            " ,email " +
            email +
            " ,class " +
            stuclass +
            " ,section " +
            stusec + " in school " + stusch + " $$ " + trackobj.playerlistheading);
          }else{
            dotracking("submit button in add player for " + fname + " with DOB " +
            dob +
            " ,gender " +
            gender +
            " ,mobile " +
            mobno +
            " ,email " +
            email +
            " ,class " +
            stuclass +
            " ,section " +
            stusec + " in school head dashboard $$ " + trackobj.playerlistheading);
          }
          if (localStorage.getItem("Role") === "1") {
            adminaddplayer(temp).then((res) => {
              if (res.status === true) {
                setLoading(false);
                toast.success(res.message);
                navigate("/superadmin/allplayers");
              } else {
                setLoading(false);
                var arr = "";
                if (res.data.length !== 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (arr === "") {
                      arr = res.data[i];
                    } else {
                      arr = arr + " , " + res.data[i];
                    }
                  }
                }
                setErrMsg(arr);
              }
            });
          } else if (localStorage.getItem("Role") === "3") {
            cityaddplayer(temp).then((res) => {
              if (res.status === true) {
                setLoading(false);
                toast.success(res.message);
                navigate("/cityregional/allplayers");
              } else {
                setLoading(false);
                var arr = "";
                if (res.data.length !== 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (arr === "") {
                      arr = res.data[i];
                    } else {
                      arr = arr + " , " + res.data[i];
                    }
                  }
                }
                setErrMsg(arr);
              }
            });
          } else if (localStorage.getItem("Role") === "4") {
            schooladdplayer(temp).then((res) => {
              if (res.status === true) {
                setLoading(false);
                toast.success(res.message);
                navigate("/schoolhead/allplayers");
              } else {
                var arr = "";
                if (res.data.length !== 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (arr === "") {
                      arr = res.data[i];
                    } else {
                      arr = arr + " , " + res.data[i];
                    }
                  }
                }
                setLoading(false);
                setErrMsg(arr);
              }
            });
          }else if (localStorage.getItem("Role") === "7") {
            coachaddplayer(temp).then((res) => {
              if (res.status === true) {
                setLoading(false);
                toast.success(res.message);
                navigate("/coach/allplayers");
              } else {
                var arr = "";
                if (res.data.length !== 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (arr === "") {
                      arr = res.data[i];
                    } else {
                      arr = arr + " , " + res.data[i];
                    }
                  }
                }
                setLoading(false);
                setErrMsg(arr);
              }
            });
          }
        } else {
          setErrMsg(resp.message);
        }
      });
    }
  }

  const [chnoti, setChkNoti] = useState(true);
  function checknoti(e) {
    setChkNoti(e.target.checked);
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Add Players</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Players</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add Players
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                {loading && (
                  <div className="dashloader-wrapper">
                    <div className="loader"></div>
                    <h3>
                      Loading...Please do not go back or close the window.
                    </h3>
                  </div>
                )}
                {!loading && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-user"></i> First Name{" "}
                            <b>
                              <span className="errortext">*</span>
                            </b>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={fname}
                            onChange={(e) => setFName(e.target.value)}
                            autoFocus
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                          />
                          {submitted && !fname && (
                            <span className="errortext">
                              First Name is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-venus-mars"></i> Gender{" "}
                            <span className="errortext">*</span>
                          </label>
                          <select
                            value={gender}
                            className="form-control"
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="" defaultValue disabled>
                              --- Select Gender ---
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {submitted && !gender && (
                            <span className="errortext">
                              Gender is required
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
<div className="form-group">
<label className="form-label">
<i className="fa-solid fa-user"></i> Last Name <span className='errortext'>*</span>
</label>
<input type="text" className="form-control" placeholder="Last Name" value={lname}
onChange={(e) => setLName(e.target.value)} onKeyPress={LetterOnly}
onPaste={onPasteLetter} />

{submitted && !lname &&
<span className='errortext'>Last Name is required</span>
}
</div>
</div> */}
                      {localStorage.getItem("Role") !== "4" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              <i className="fa-solid fa-bank"></i> School{" "}
                              <span className="errortext">*</span>
                            </label>
                            <select
                              value={stusch}
                              className="form-control"
                              onChange={(e) => setStuSch(e.target.value)}
                            >
                              <option value="" defaultValue disabled>
                                --- Select School ---
                              </option>
                              {schlist.map((data, i) => {
                                return (
                                  <option value={data.id} key={i}>
                                    {data.school_name}
                                  </option>
                                );
                              })}
                            </select>
                            {submitted && !stusch && (
                              <span className="errortext">
                                School is required
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-book-open-reader"></i>{" "}
                            Class <span className="errortext">*</span>
                          </label>
                          <select
                            value={stuclass}
                            className="form-control"
                            onChange={selectclass}
                          >
                            <option value="" defaultValue disabled>
                              --- Select Class ---
                            </option>
                            {sclist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.class}
                                </option>
                              );
                            })}
                          </select>
                          {submitted && !stuclass && (
                            <span className="errortext">Class is required</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-group-arrows-rotate"></i>{" "}
                            Section <span className="errortext">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Section"
                            value={stusec}
                            onChange={(e) =>
                              setStuSec(e.target.value.toUpperCase())
                            }
                          />

                          {submitted && !stusec && (
                            <span className="errortext">
                              Section is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-calendar"></i> Date of
                            Birth <span className="errortext">*</span>
                          </label>
                          <input
                            type="date"
                            value={dob}
                            max={curdate}
                            className="form-control"
                            onChange={(e) => setDOB(e.target.value)}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                          {submitted && !dob && (
                            <span className="errortext">
                              Date of Birth is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-mobile-button"></i>{" "}
                            Contact Number<span className="errortext">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Contact Number"
                            value={mobno}
                            className="form-control"
                            onChange={handleChange}
                            maxLength={10}
                            onBlur={checkmob}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                          {submitted && !mobno && (
                            <span className="errortext">
                              Contact Number is required
                            </span>
                          )}
                          {valmob !== "" && mobno && (
                            <span className="errortext">{valmob}</span>
                          )}
                          {mobnoerr !== "" && mobno && (
                            <span className="errortext">{mobnoerr}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            <i className="fa-solid fa-at"></i> E-mail Address{" "}
                            <span className="errortext">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Email"
                            value={email}
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={checkemail}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                          {submitted && !email && (
                            <span className="errortext">
                              E-mail Address is required
                            </span>
                          )}
                          {valemail !== "" && email && (
                            <span className="errortext">{valemail}</span>
                          )}
                          {emailerr !== "" && email && (
                            <span className="errortext">{emailerr}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="dashdisc-field">
                      <input
                        type="checkbox"
                        onChange={checknoti}
                        checked={chnoti}
                      />
                      <label className="pl-3">
                        Send me notifications about upcoming tournaments and
                        events
                      </label>
                    </div>

                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <div className="text-right">
                      <button
                        className="btn btn-success mt-2"
                        onClick={regsubmit}
                        type="submit"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
