import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import Select from "react-select";

import moment from "moment";
import { averagelist } from "../../../services/superadmin";

export const Average = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [player, setplayer] = useState("");
  const [tplayer, setTplayer] = useState("");

  const [dash, setDash] = useState([]);
  const [prof, setProf] = useState([]);
  const [toudetails, setToudetalils] = useState([]);
  const [myteams, setMyteam] = useState([]);
  const [tePlayerslist, setTePlayerlist] = useState([]);
  const [tourRegister, setTourReg] = useState([]);
  const [regTourList, setResgTourlist] = useState([]);
  const [eligTouList, setEligToulist] = useState([]);
  const [results, setResult] = useState([]);
  const [highList, setHighlist] = useState([]);
  const [highDetails, setHighDetail] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [certificateDetail, setCertificateDetail] = useState([]);
  const [astList, setAslist] = useState([]);
  const [AstDetails, setAstDetails] = useState([]);

  const [fixtureList, setFixtureList] = useState([]);
  const [photoList, setPhotoList] = useState([]);
  const [photoDetails, setPhotoDetails] = useState([]);
  const [keyhigh, setKeyhigh] = useState([]);
  const [orderdetail, setOrderDetail] = useState([]);
  const [ochamdetail, setOChamDetail] = useState([]);
  const [ichamdetail, setIChamDetail] = useState([]);
  const [agichamdetail, setACIChamDetail] = useState([]);

  const [dashcount, setDashCount] = useState(0);
  const [profcount, setProfCount] = useState(0);
  const [toudetailscount, setToudetalilsCount] = useState(0);
  const [myteamscount, setMyteamCount] = useState(0);
  const [tePlayerslistcount, setTePlayerlistCount] = useState(0);
  const [tourRegistercount, setTourRegCount] = useState(0);
  const [regTourListcount, setResgTourlistCount] = useState(0);
  const [eligTouListcount, setEligToulistCount] = useState(0);
  const [resultscount, setResultCount] = useState(0);
  const [highListcount, setHighlistCount] = useState(0);
  const [highDetailscount, setHighDetailCount] = useState(0);
  const [certificateListcount, setCertificateListCount] = useState(0);
  const [certificateDetailcount, setCertificateDetailCount] = useState(0);
  const [astListcount, setAslistCount] = useState(0);
  const [AstDetailscount, setAstDetailsCount] = useState(0);

  const [fixtureListcount, setFixtureListCount] = useState(0);
  const [photoListcount, setPhotoListCount] = useState(0);
  const [photoDetailscount, setPhotoDetailsCount] = useState(0);

  const [keycount, setKeyCount] = useState(0);
  const [OEventcount, setOEventCount] = useState(0);
  const [OChampioncount, setOChampionCount] = useState(0);
  const [IChampioncount, setIChampionCount] = useState(0);
  const [ACIChampioncount, setAGIChampionCount] = useState(0);

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    averagelist().then((res) => {
      if (res.status === true) {
        setplayer(res.data.player_visited_count);
        setTplayer(res.data.all_visitor_count);
        setDash(res.data.avg_dashboard_time_spent_in_sec.split("-"));
        setProf(res.data.avg_Profile_time_spent_in_sec.split("-"));
        setToudetalils(
          res.data.avg_TournamentDetails_time_spent_in_sec.split("-")
        );
        setMyteam(res.data.avg_MyTeams_time_spent_in_sec.split("-"));
        setTePlayerlist(
          res.data.avg_TeamPlayersList_time_spent_in_sec.split("-")
        );
        setTourReg(
          res.data.avg_TournamentRegister_time_spent_in_sec.split("-")
        );
        setResgTourlist(
          res.data.avg_RegisteredTournamentList_time_spent_in_sec.split("-")
        );
        setEligToulist(
          res.data.avg_EligibleTournamentList_time_spent_in_sec.split("-")
        );
        setResult(res.data.avg_Results_time_spent_in_sec.split("-"));
        setHighlist(res.data.avg_HighlightList_time_spent_in_sec.split("-"));
        setHighDetail(
          res.data.avg_HighlightDetails_time_spent_in_sec.split("-")
        );
        setCertificateList(
          res.data.avg_CertificateList_time_spent_in_sec.split("-")
        );
        setCertificateDetail(
          res.data.avg_CertificateDetail_time_spent_in_sec.split("-")
        );
        setAslist(res.data.avg_AssessmentList_time_spent_in_sec.split("-"));
        setAstDetails(
          res.data.avg_AssessmentDetails_time_spent_in_sec.split("-")
        );
        setFixtureList(res.data.avg_FixtureList_time_spent_in_sec.split("-"));
        setPhotoList(res.data.avg_PhotoList_time_spent_in_sec.split("-"));
        setPhotoDetails(res.data.avg_PhotoDetails_time_spent_in_sec.split("-"));
        setKeyhigh(res.data.avg_KeyHigh_time_spent_in_sec.split("-"));
        setOrderDetail(res.data.avg_OrderEvents_time_spent_in_sec.split("-"));
        setOChamDetail(res.data.avg_OverAll_time_spent_in_sec.split("-"));
        setIChamDetail(res.data.avg_Individual_time_spent_in_sec.split("-"));
        setACIChamDetail(res.data.avg_AgeCategory_time_spent_in_sec.split("-"));

        // setDashCount(res.data.avg_dashboard_time_spent_in_sec.split("-"));
        // setProfCount(res.data.avg_Profile_time_spent_in_sec.split("-"));
        setToudetalilsCount(res.data.TournamentDetails_user_count);
        setMyteamCount(res.data.MyTeams_user_count);
        setTePlayerlistCount(res.data.TeamPlayersList_usercount);
        setTourRegCount(res.data.TournamentRegister_user_count);
        setResgTourlistCount(res.data.RegisteredTournamentList_user_count);
        setEligToulistCount(res.data.EligibleTournamentList_user_count);
        setResultCount(res.data.Resultsusercount_user_count);
        setHighlistCount(res.data.HighlightLis_user_count);
        setHighDetailCount(res.data.HighlightDetails_user_count);
        setCertificateListCount(res.data.CertificateList_user_count);
        setCertificateDetailCount(res.data.CertificateDetail_user_count);
        setAslistCount(res.data.AssessmentList_user_count);
        setAstDetailsCount(res.data.AssessmentDetails_user_count);
        setFixtureListCount(res.data.FixtureList_user_count);
        setPhotoListCount(res.data.PhotoList_user_count);
        setPhotoDetailsCount(res.data.PhotoDetails_user_count);
        setKeyCount(res.data.KeyHigh_user_count);
        setOEventCount(res.data.OrderEvents_user_count);
        setOChampionCount(res.data.OverAll_user_count);
        setIChampionCount(res.data.Individual_user_count);
        setAGIChampionCount(res.data.AgeCategory_user_count);
      }
    });
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Average Time Spend</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Datacube</a>
          </li>
          {/* <li className="breadcrumb-item">
                        <a>Tournament List</a>
                    </li> */}
          <li className="breadcrumb-item active" aria-current="page">
            Average Time Spend
          </li>
        </ol>
      </div>
      <div className="card">
        <div className="row card-body p-2">
          <h3 className="text-center">Players Overall Count</h3>
          <div className="row teamslist mt-4">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Total Player Visit</h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>{tplayer}</span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Player Visit (Unique)</h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>{player}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <h3 className="text-center">
            Players Overall Average Time Spend (in Seconds)
          </h3>
          <div className="row teamslist mt-4">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">{dash[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>{dash[1]}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{prof[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>{prof[1]}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{toudetails[0]}</h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {toudetails[1]} ({toudetailscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{myteams[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {myteams[1]} ({myteamscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{tePlayerslist[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {tePlayerslist[1]} ({tePlayerslistcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{tourRegister[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {tourRegister[1]} ({tourRegistercount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{regTourList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {regTourList[1]} ({regTourListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{eligTouList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {eligTouList[1]} ({eligTouListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{results[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {results[1]} ({resultscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{highList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {highList[1]} ({highListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{highDetails[0]}</h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {highDetails[1]} ({highDetailscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{certificateList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {certificateList[1]} ({certificateListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{certificateDetail[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {certificateDetail[1]} ({certificateDetailcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{astList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {astList[1]} ({astListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{AstDetails[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {AstDetails[1]} ({AstDetailscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{fixtureList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>{fixtureList[1]} ({fixtureListcount})</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{photoList[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {photoList[1]} ({photoListcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{photoDetails[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {photoDetails[1]} ({photoDetailscount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{keyhigh[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {keyhigh[1]} ({keycount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{orderdetail[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {orderdetail[1]} ({OEventcount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{ochamdetail[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {ochamdetail[1]} ({OChampioncount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{ichamdetail[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {ichamdetail[1]} ({IChampioncount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card ">
                <div className="card-header">
                  <h3 className="card-title">{agichamdetail[0]} </h3>
                </div>
                <div className="card-body alignMe">
                  <h4 className="text-center">
                    <span>
                      {agichamdetail[1]} ({ACIChampioncount})
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
