import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import exportUrl from "../../../../exportUtl";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  eventplayerentrylist,
  othereventdetails,
  othereventrefundpayment,
  othereventsendpaymentlink,
} from "../../../../services/superadmin";
import {
  schoolheadothereventplayerlist,
  schprofileview,
} from "../../../../services/schoolhead";

export const EventEntryplayerslist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");
  const [eodate, setEODate] = useState("");
  const [curdate, setCurDate] = useState("");
  const [tfree, setTFree] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    // {
    //   headerName: "Action",
    //   width: 100,
    //   field: "entries_id",
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     if (params.data.payment_status === "0") {
    //       if (moment(new Date()).format("yyyy-MM-DD") < sdate) {
    //         return (
    //           <div className="text-center">
    //             <a
    //               className="btn-sm btn-secondary"
    //               title="Change Payment Status"
    //               onClick={() => handleShow(params.value,params)}
    //             >
    //               <i className="fa fa-edit"></i>
    //             </a>
    //           </div>
    //         );
    //       } else {
    //         return (
    //           <div className="text-center">
    //             <a className="text-danger">Entry Closed</a>
    //           </div>
    //         );
    //       }
    //     }
    //   },
    // },
    {
      headerName: "Refund",
      width: 100,
      field: "entries_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.pay_status === "SUCCESS" && tfree === "N") {
          // if (eodate > moment(new Date()).format("yyyy-MM-DD")) {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-success"
                title="Refund Payment"
                onClick={() => handleRShow(params.value, params)}
              >
                <i className="fa fa-undo"></i> Refund
              </a>
            </div>
          );
        }
        // }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment",
      width: 150,
      field: "payment_status",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value === "Not Paid") {
          if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
            if (localStorage.getItem("Role") !== "4") {
              return (
                <div className="text-center">
                  <a
                    className="btn-sm btn-success"
                    onClick={() => sendpayment(params)}
                  >
                    Send Payment Link
                  </a>
                </div>
              );
            }
          } else {
            return (
              <div className="text-center">
                <a className="text-danger">Entry Closed</a>
              </div>
            );
          }
        } else if (params.value === "Paid") {
          return (
            <div className="text-center">
              <a className="text-success">Paid</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
  ];

  const columnPlaDefs = [
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  //   const [show, setShow] = useState(false);
  //   const [payid, setPayId] = useState("");
  //   const [ordid, setOrdId] = useState("");
  //   const [entryid, setEntryId] = useState("");

  //   function handleShow(fid,fdata) {
  //     dotracking(
  //       "edit icon for change payment status in " +
  //         tname + " (" + id +
  //         ") tournament for player " + fdata.data.first_name + " (" + fdata.data.usename + " - " + fid + ") $$ " + trackobj.tourplayerlistheading

  //     );
  //     setEntryName(fdata.data.first_name + " (" + fdata.data.usename + " - " + fid + ")");
  //     setOrdId("");
  //     setPayId("");
  //     setEntryId(fid);
  //     setErrorMsg("");
  //     setShow(true);
  //   }

  //   function handleclose() {
  //     dotracking("close button in change payment status for "+ ename + " player in " + tname + " (" + id + ") tournament $$ " + trackobj.tourplayerlistheading);
  //     setShow(false);
  //     setEntryName("")
  //     setErrorMsg("");
  //     setOrdId("");
  //     setPayId("");
  //     setEntryId("");
  //   }

  const [rshow, setRShow] = useState(false);
  const [refundtype, setRefundType] = useState("");
  const [refdate, setRefundDate] = useState("");
  const [refid, setRefundID] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [ename, setEntryName] = useState("");

  function handleRShow(fid, fdata) {
    dotracking(
      "refund icon for refund payment in " +
        tname +
        " (" +
        id +
        ") tournament for player " +
        fdata.data.first_name +
        " (" +
        fdata.data.username +
        " - " +
        fid +
        ") $$ " +
        trackobj.tourplayerlistheading
    );
    setEntryName(
      fdata.data.first_name + " (" + fdata.data.username + " - " + fid + ")"
    );
    setRefundID(fid);
    setRefundDate("");
    setErrorMsg("");
    setRShow(true);
  }

  function handleRclose() {
    dotracking(
      "close button in refund payment for " +
        ename +
        " player in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    setRShow(false);
    setEntryName("");
    setRefundID("");
    setRefundDate("");
    setErrorMsg("");
  }

  function submitrefund(e) {
    e.preventDefault();
    setErrorMsg("");
    var valid = "";
    if (refundtype !== "") {
      if (refundtype === "2") {
        if (refdate === "") {
          valid = "Not";
          setErrorMsg("Please Select Refund Date");
        }
      }
      if (valid === "") {
        const temp = new FormData();
        temp.append("id", refid);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("refunded_date", refdate);
        temp.append("refunded_type", refundtype);

        dotracking(
          "submit button in refund payment for " +
            ename +
            " player in " +
            tname +
            " (" +
            id +
            ") event $$ " +
            trackobj.tourplayerlistheading
        );
        othereventrefundpayment(temp).then((response) => {
          if (response.status === true) {
            handleRclose();
            toast.success(response.message);
            listall();
          }
        });
      }
    } else {
      setErrorMsg("Please Select Refund Type");
    }
  }

  //   function submitpay(e) {
  //     e.preventDefault();
  //     setErrorMsg("");
  //     if (payid !== "") {
  //       if (ordid !== "") {
  //         const temp = new FormData();
  //         temp.append("payment_id", payid);
  //         temp.append("order_id", ordid);
  //         temp.append("id", entryid);
  //         temp.append("payment_status", 1);
  //         temp.append("pay_status", "SUCCESS");
  //         dotracking("add button in change payment status for " + ename + " player in " + tname + " (" + id + ") tournament $$ " + trackobj.tourplayerlistheading);
  //         changetourpaymentstatus(temp).then((response) => {
  //           if (response.status === true) {
  //             handleclose();
  //             toast.success(response.message);
  //             listall();
  //           }
  //         });
  //       } else {
  //         setErrorMsg("Please Enter Order Id");
  //       }
  //     } else {
  //       setErrorMsg("Please Enter Payment Id");
  //     }
  //   }

  function sendpayment(datas) {
    Swal.fire({
      title: "Are you sure want to send a payment link to respective email?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "send payment link button in entry players list for " +
            datas.data.first_name +
            " (" +
            datas.data.username +
            " - " +
            datas.data.user_id +
            ") player" +
            " in " +
            tname +
            " (" +
            id +
            ") event $$ " +
            trackobj.worklistheading
        );
        othereventsendpaymentlink(id, datas.data.user_id).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
  }

  useEffect(() => {
    setCurDate(moment(new Date()).format("YYYY-MM-DD"));
    othereventdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.events[0].event_name);
        setSDate(response.data.events[0].start_date);
        setDDate(response.data.events[0].entry_end_date);
        setEODate(response.data.events[0].entryopen);
        setTFree(response.data.events[0].free_or_paid_events);
      }
    });

    listall();
  }, []);

  function listall() {
    if (localStorage.getItem("Role") === "4") {
      schprofileview().then((res) => {
        if (res.status === true) {
          schoolheadothereventplayerlist(res.data.school_id, id).then(
            (response) => {
              if (response.status === true) {
                setFilterProduct(response.data);
              }
            }
          );
        }
      });
    } else {
      eventplayerentrylist(id).then((response) => {
        if (response.status === true) {
          if (response.data.length > 0) {
            let arr=[];
            for(let i=0;i<response.data.length;i++){
              let stat="";
              if(response.data[i].payment_status==="1"){
                stat="Paid"
              }else{
                stat="Not Paid"
              }
              arr.push({
                entries_id:response.data[i].entries_id,
                pay_status:response.data[i].pay_status,
                payment_status:stat,
                username:response.data[i].username,
                first_name:response.data[i].first_name,
                gender:response.data[i].gender,
                date_of_birth:response.data[i].date_of_birth,
                school_name: response.data[i].school_name,
                class_name: response.data[i].class_name,
                section: response.data[i].section,
                updated_at:response.data[i].updated_at
              })
            }
          setFilterProduct(arr);

          }
        }
      });
    }
  }
  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "back button in entry players list for " +
          tname +
          " (" +
          id +
          ") event $$ " +
          trackobj.worklistheading
      );
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/othereventlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/othereventlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") event $$ " +
            trackobj.workupcominglistheading
        );
        navigate("/schoolhead/upcomingeventlist");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") event $$ " +
            trackobj.workcomplistheading
        );
        navigate("/schoolhead/completedeventslist");
      }
      // } else if (localStorage.getItem("Role") === "5") {
      //   navigate("/referee/tournamentlist");
    }
  }

  function reflist() {
    dotracking(
      "refund players list for " +
        tname +
        " (" +
        id +
        ") event $$ " +
        trackobj.refundlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/eventrefundlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/eventrefundlist/" + id);
    }
  }

  const gridRef = useRef();
  const eplayer = useCallback(() => {
    var excelParams = {
      columnKeys: [
        "pay_status",
        "payment_status",
        "username",
        "first_name",
        "gender",
        "date_of_birth",
        "school_name",
        "class_name",
        "section",
        "updated_at",
      ],
      allColumns: false,
      fileName: "Other Event Player List.csv",
    };
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Player Entry Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Other Event</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Player Entry Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-8">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                      {/* <h4>{tloc}</h4> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-right">
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3") &&
                        tfree === "N" && (
                          <a className="btn btn-warning me-2" onClick={reflist}>
                            <i className="fa-solid fa-list-alt"></i> Refund List
                          </a>
                        )}
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3") && (
                        <a className="btn btn-secondary me-2" onClick={eplayer}>
                          <i className="fa-solid fa-file-export"></i> Export
                        </a>
                      )}
                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  {(localStorage.getItem("Role") === "1" ||
                    localStorage.getItem("Role") === "3") && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {(localStorage.getItem("Role") === "4" ||
                    localStorage.getItem("Role") === "5") && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnPlaDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      //   onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                </div>

                {/* <Modal show={show} size="lg" onHide={handleclose}>
                  <Modal.Header>
                    <h4>Change Payment Status</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Payment ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment ID"
                            value={payid}
                            onChange={(e) => setPayId(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Order ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Order ID"
                            value={ordid}
                            onChange={(e) => setOrdId(e.target.value)}
                          />
                        </div>
                      </Col>

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>

                    <a onClick={submitpay} className="btn btn-success">
                      Add
                    </a>
                  </Modal.Footer>
                </Modal>*/}

                <Modal show={rshow} size="lg">
                  <Modal.Header>
                    <h4>Refund Payment</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Refund Type <span className="errortext">*</span>{" "}
                          </label>
                          <select
                            className="form-control"
                            value={refundtype}
                            onChange={(e) => setRefundType(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Select Refund Type
                            </option>
                            <option value="1">Automatic Refund</option>
                            <option value="2">Manual Refund</option>
                          </select>
                        </div>
                      </Col>
                      {refundtype === "2" && (
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <label className="mb-2">
                              Refund Date <span className="errortext">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              min={eodate}
                              max={curdate}
                              value={refdate}
                              onChange={(e) => setRefundDate(e.target.value)}
                            />
                          </div>
                        </Col>
                      )}

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleRclose}>
                      Close
                    </a>

                    <a onClick={submitrefund} className="btn btn-success">
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
