import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { rolecreate, roledetail, rolemasterlist, rolestatus, roleupdate, rolevalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const RoleMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [roleid, setId] = useState(0);
  const [rolename, setRoleName] = useState("");
  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ['asc', 'desc',];
  }, []);

  const columnDefs = [
    {
      headerName: "Role",
      field: "role",
      sortable: true,
      resizable: true,
      sorting: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deleteroles(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewroles(params.value)} title="Edit Roles"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>

        );
      },
    },
  ];

  function viewroles(aid) {
    setShow(true)
    
    roledetail(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.id)
        setRoleName(response.data.role)
        setStatus(response.data.is_active + "")
        dotracking("edit button in role for " + response.data.role + " (" + aid + ") $$ " + trackobj.rolelistheading)
      }
    })
  }

  function deleteroles(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id)
          temp.append("is_active", 0)
          dotracking(
            "status icon in role list from Active to Inactive for " + list.data.role + " (" + list.data.id + ") $$ " + trackobj.rolelistheading
          )
          rolestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message)
            }
          })
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id)
          temp.append("is_active", 1)
          dotracking(
            "status icon in role list from Inactive to Active for " + list.data.role + " (" + list.data.id + ") $$ " + trackobj.rolelistheading
          )
          rolestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message)
            }
          })
        }
      });
    }
  }


  function handleshow() {
    setId(0);
    setRoleName("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);

  }
  function handleclose() {
    setShow(false);
    setId(0);
    setRoleName("");
    setStatus("1");
    setErrorMsg("");
  }

  // function handleSwitch(list) {
  //   if (list.value === 1) {
  //     Swal.fire({
  //       text: "Are you sure want to Inactive?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#ac0404",
  //       cancelButtonColor: "#6259ca",
  //       confirmButtonText: "Inactive",
  //       allowOutsideClick: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {

  //       }
  //     });
  //   } else {
  //     Swal.fire({
  //       text: "Are you sure want to Active?",
  //       icon: "success",
  //       showCancelButton: true,
  //       confirmButtonColor: "#008000",
  //       cancelButtonColor: "#6259ca",
  //       confirmButtonText: "Active",
  //       allowOutsideClick: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {

  //       }
  //     });
  //   }
  // }
  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    rolemasterlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data)
      }
    })
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submitrole(e) {
    e.preventDefault();
    setErrorMsg("")
    if (rolename !== "") {

      if (roleid === 0) {
        rolevalidate(rolename).then((res) => {
          if (res.status === true) {
            const temp = new FormData();
            temp.append("role", rolename)
            temp.append("is_active", status)
            dotracking("add button in role list for " + rolename + " $$ " + trackobj.rolelistheading);
            rolecreate(temp).then((response) => {
              if (response.status === true) {
                handleclose();
                listall();
                toast.success(response.message);
              }
            })
          } else {
            Swal.fire({
              text: rolename + " " + res.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#4ecc48",
              cancelButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                setId(0);
                setRoleName("");
                setStatus("1");
                setErrorMsg("");
              }
            });
          }
        })

      } else if (roleid !== 0) {
        const temp = new FormData();
        temp.append("id", roleid)
        temp.append("role", rolename)
        temp.append("is_active", status)
        dotracking("update button in role list for " + rolename + " (" + roleid + ") $$ " + trackobj.rolelistheading);
        roleupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        })
      }
    } else {
      setErrorMsg("Please Enter Role Name")
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Roles Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" &&
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          }
          {localStorage.getItem("Subrole") !== "1" &&
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          }
          <li className="breadcrumb-item active" aria-current="page">
            Roles Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">

            <div className="card-body">
              {localStorage.getItem("Subrole") === "1" &&
                <div className="text-right">
                  <a className="btn btn-success" onClick={handleshow}>
                    <i className="fa fa-plus"></i> Add Role
                  </a>
                </div>
              }
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {roleid !== 0 &&
                    <h4> Role Details</h4>
                  }
                  {roleid === 0 &&
                    <h4> Add Role</h4>
                  }
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Role Name{" "}
                          <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Role Name"
                          className="form-control"
                          value={rolename}
                          onChange={(e) => setRoleName(e.target.value)}
                        />
                      </div>
                    </Col>


                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />
                              {" "} Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />
                              {" "}Inactive
                            </label>
                          </div>
                        </div>
                      </div>

                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-danger"
                    onClick={handleclose}
                  >
                    Close
                  </a>
                  {roleid !== 0 &&
                    <a
                      className="btn btn-success" onClick={submitrole}
                    >
                      Update
                    </a>
                  }
                  {roleid === 0 &&
                    <a
                      className="btn btn-success" onClick={submitrole}
                    >
                      Add
                    </a>
                  }


                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>


  );
};
