import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import {
  schooldiscountcreateupdate,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  athleticsintratourpaymentstatus,
  checkpaymentpaidornot,
  intratourpaymentstatus,
} from "../../../../../services/common";
import Swal from "sweetalert2";
import { interschooltourpaidornot } from "../../../../../services/interschool";

export const SchoolDiscountList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);

  const [show, setShow] = useState(false);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [sport, setSport] = useState("");
  const [ttype, setTType] = useState("");
  const [tname, setTName] = useState("");
  const [tfree, setTFree] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      field: "id",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
       cellRenderer: function (params) {
        if (moment(new Date()).format("yyyy-MM-DD") <= edate) {
          return (
            <div className="text-center">
              {localStorage.getItem("Role") === "1" && tfree === "N" && (
                <a
                  onClick={() => editline(params.value, params.data)}
                  title="Add Discount"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fa fa-edit fa-lg"></i>
                </a>
              )}
            </div>
          );
        }
      },
    },
    {
      headerName: "School",
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Discount (in %)",
      field: "discount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.entry_deadline_date);
          setSport(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setTFree(response.data.tournament.free_tournament);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.entry_deadline_date);
          setSport(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setTFree(response.data.tournament.free_tournament);
        }
      });
    }
    listall();
  }, []);

  function listall() {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              setFilterProduct(response.data.mappedschools);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              if (response.data.mappedschools !== null) {
                if (response.data.mappedschools.length !== 0) {
                  setFilterProduct(response.data.mappedschools);
                }
              }
            }
          }
        }
      });
    }
  }
  const [schname, setSchname] = useState("");
  const [schid, setSchId] = useState("");
  const [sid, setSID] = useState("");
  const [disamt, setDisAmt] = useState(0);

  function editline(lid, ldata) {
    setErrorMessage("");
    setSID(lid);
    setDisAmt(ldata.discount);
    setSchname(ldata.school_name);
    setSchId(ldata.school_id);
    if (sport.toUpperCase() === "ATHLETICS") {
      if (ttype === "Inter School Tournament") {
        checkpaymentpaidornot(id, ldata.school_id, 0).then((response) => {
          if (response.status === true) {
            if (response.data.Payment_Status === "Paid") {
              Swal.fire(
                "Sorry!. This school has been paid for this tournament So Discount cannot be updated."
              );
            } else {
              setShow(true);
            }
          }
        });
      } else {
        const temp = new FormData();
        temp.append("school_id", ldata.school_id);
        temp.append("tournament_meet_id", id);
        athleticsintratourpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            if (response.data.pay_status > 0) {
              Swal.fire(
                "Sorry!. There are already " +
                  response.data.pay_status +
                  " players paid for this tournament So Discount cannot be updated."
              );
            } else {
              setShow(true);
            }
          }
        });
      }
    } else {
      if (ttype === "Inter School Tournament") {
        const temp1 = new FormData();
        temp1.append("tournament_meet_id", id);
        temp1.append("school_id", ldata.school_id);
        interschooltourpaidornot(temp1).then((response) => {
          if (response.status === true) {
            if (response.data !== null)  {
              Swal.fire(
                "Sorry!. This school has been paid for this tournament So Discount cannot be updated."
              );
            } else {
              setShow(true);
            }
          }
        });
      } else {
        const temp = new FormData();
        temp.append("school_id", ldata.school_id);
        temp.append("tournament_meet_id", id);
        intratourpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            if (response.data.pay_status > 0) {
              Swal.fire(
                "Sorry!. There are already " +
                  response.data.pay_status +
                  " players paid for this tournament So Discount cannot be updated."
              );
            } else {
              setShow(true);
            }
          }
        });
      }
    }
  }
  function handleclose() {
    setShow(false);
    setErrorMessage("");
    setSID("");
    setSchId("");
    setDisAmt(0);
    setSchname("");
  }

  function handleChange(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 100) {
        setDisAmt(e.target.value);
      } else {
        setErrorMessage("Please Enter Below or Equal to 100");
      }
    }
  }

  function subdisper() {
    setErrorMessage("");
    if (disamt !== 0) {
      dotracking(
        "submit button in school discount list for " +
          schname +
          "  - " +
          schid +
          " (" +
          sid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.schooldisclist
      );

      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("school_id", schid);
      temp.append("school_row_id", sid);
      temp.append("discount_percentage", disamt);
      schooldiscountcreateupdate(temp).then((response) => {
        if (response.status === true) {
          listall();
          toast.success(response.message);
          setShow(false);
          setSID("");
          setSchId("");
          setDisAmt(0);
          setSchname("");
        }
      });
    } else {
      setErrorMessage("Please Enter Discount Percentage");
    }
  }

  function backlist() {
    dotracking(
      "back button in school discount list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.schooldisclist
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentdetails/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Discount Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            School Discount Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-3">
                  <div className="text-right">
                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  // domLayout="autoWidth"
                  // onGridReady={(params) => {
                  //   params.api.sizeColumnsToFit();
                  // }}
                />
              </div>

              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4> Add Discount </h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Discount (%) <span className="errortext"> *</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Discount %"
                              maxLength={3}
                              value={disamt}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    {errorMessage !== "" && (
                      <span className="errortext">{errorMessage}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  <a className="btn btn-success" onClick={subdisper}>
                    Submit
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
