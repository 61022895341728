import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  multipleroleslist,
  tourassigncrm,
  tourassigncrmlist,
  tourassignreferee,
  tourassignrefereelist,
  tourviewdetail,
  photoslist,
  highlightslist,
  schedulelist,
  tourassignschool,
  schoolactivelist,
} from "../../../../../services/superadmin";
import moment from "moment/moment";
import ImageUrl from "../../../../../ImageUrl";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import {
  crmassignrefereelist,
  crmrefereelist,
  crmtourrefereeassign,
  crmtourviewdetail,
  schoolactivelistcityreg,
} from "../../../../../services/cityregionaladmin";
import Select from "react-select";
import { toast } from "react-toastify";
import { Reftourdetail } from "../../../../../services/referee";
import { tourdetails } from "../../../../../services/register";
import {
  senddigitalcertificates,
  sendhighlights,
  sendnewtournoti,
  sendphotos,
  sendremaindertour,
  sendschedulartour,
  sendschheadhighlights,
  sendschheadphotos,
  sendschheadremainder,
  sendschheadschedular,
  sendschheadtournamentlink,
  sendschoolheadtour,
} from "../../../../../services/communication";
import Swal from "sweetalert2";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import {
  coachassignrefereelist,
  coachrefereelist,
  coachtourrefereeassign,
  coachtourviewdetail,
  schoolactivelistcoach,
} from "../../../../../services/coach";

export const ViewTour = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mcode, setMCode] = useState("");
  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [meodate, setMEODate] = useState("");
  const [meddate, setMEDDate] = useState("");
  const [msport, setMSport] = useState("");
  const [mschool, setMSch] = useState([]);
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");
  const [mgate, setMGate] = useState("");
  const [mcat, setMCat] = useState("");
  const [mfree, setMFree] = useState("");
  const [mfees, setMFees] = useState("");
  const [mrfees, setMRFees] = useState("");

  const [mulsp, setMulSp] = useState(0);
  const [lfile, setLFile] = useState("");

  const [mleatype, setMLeaque] = useState("");
  const [mcir, setMCir] = useState("");
  const [reptime, setRepTime] = useState("");

  const [refid, setRefid] = useState("");
  const [reflist, setRefList] = useState([]);

  const [crmid, setCRMid] = useState("");
  const [crmlist, setCRMList] = useState([]);

  const [schid, setSchid] = useState("");
  const [schlist, setSchList] = useState([]);

  const [ageid, setAID] = useState("");
  const [agetype, setAType] = useState("");
  const [tourcls, setTCls] = useState("");

  const [tourcreate, setTourCreate] = useState("");

  let arr = [];
  var link = [];
  const [photolist, setPhotoLink] = useState([]);
  const [highlist, setHighLink] = useState([]);
  const [schlink, setScheduleLink] = useState([]);

  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Token") === null) {
      navigate("/login");
    }
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }

          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }
          setMulSp(response.data.tournament.multi_sport);
          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";

            setTCls(response.data.tournament.categories);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }

          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                response.data.tournament.circular_file_1
            );
          }
        }
      });

      multipleroleslist(3, 1).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              arr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setCRMList(arr);
        }
      });

      multipleroleslist(5, 1).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              arr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                +response.data.tournament.circular_file_1
            );
          }
        }
      });
      crmrefereelist(5, 1).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              arr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                response.data.tournament.circular_file_1
            );
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                response.data.tournament.circular_file_1
            );
          }
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          setMFree(response.data.tournament.free_tournament);

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    // ImageUrl +
                    // "tournament/school/qrcode/" +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              // response.data.circular_path +
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 2) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }else{
            setTCls(response.data.tournament.categories);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                +response.data.tournament.circular_file_1
            );
          }
        }
      });
      coachrefereelist(5, 1).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              var evt = "";
              if (response.data[i].first_name !== null) {
                evt = response.data[i].first_name;
              }
              if (response.data[i].last_name !== null) {
                if (response.data[i].last_name !== "") {
                  if (evt === "") {
                    evt = response.data[i].last_name;
                  } else if (evt !== "") {
                    evt = evt + " " + response.data[i].last_name;
                  }
                }
              }
              arr.push({
                value: response.data[i].user_id,
                label: evt,
              });
            }
          }
          setRefList(arr);
        }
      });
    }else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        console.log(response);
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.created_by);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMulSp(response.data.tournament.multi_sports);
          if(response.data.tournament.tournament_logo !== null){
            setLFile(response.data.tournament_logo_path + response.data.tournament.tournament_logo);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMFees(response.data.tournament.registration_fees);
          setMRFees(response.data.tournament.relay_fees);
          if (response.data.tournament.free_tournament === "N") {
            setMFree("No");
          } else if (response.data.tournament.free_tournament === "Y") {
            setMFree("Yes");
          }

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
            setTCls(response.data.tournament.categories);
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          // if (response.data.tournament.agecat_or_std === 1) {
          //   let arr1 = "";
          //   if (response.data.age_category.length !== 0) {
          //     for (let j = 0; j < response.data.age_category.length; j++) {
          //       if (arr1 === "") {
          //         arr1 = response.data.age_category[j].standard_name;
          //       } else if (arr1 !== "") {
          //         arr1 =
          //           arr1 + " , " + response.data.age_category[j].standard_name;
          //       }
          //     }
          //   }
          //   setTCls(arr1);
          // }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          if (response.data.tournament.league_type !== null) {
            setMLeaque(response.data.tournament.league_type);
          }
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              response.data.circular_path +
                response.data.tournament.circular_file_1
            );
          }
        }
      });
    } 

    photoslist(id).then((response) => {
      if (response.status === true) {
        setPhotoLink(response.data.tournamentlist);
      }
    });
    highlightslist(id).then((response) => {
      if (response.status === true) {
        setHighLink(response.data);
      }
    });

    schedulelist(id, 0).then((response) => {
      if (response.status === true) {
        setScheduleLink(response.data.data_fixtures);
      }
    });
    listref();
    listcrm();
    listschool();
  }, []);

  const [filterProduct, setFilterProduct] = useState([]);
  const [CRMProduct, setCRMProduct] = useState([]);

  function listref() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "1") {
      tourassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachassignrefereelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }

  function listcrm() {
    setCRMProduct([]);
    if (localStorage.getItem("Role") === "1") {
      tourassigncrmlist(id).then((response) => {
        if (response.status === true) {
          setCRMProduct(response.data);
        }
      });
    }
  }

  function listschool() {
    setSchList([]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
      schoolactivelist().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
      schoolactivelistcityreg().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    // ImageUrl +
                    // "tournament/school/qrcode/" +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.mappedschools.length; i++) {
                arr.push({
                  id: response.data.mappedschools[i].id,
                  school: response.data.mappedschools[i].school_name,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.mappedschools[i].meet_school_qr_code,
                  schlink: response.data.mappedschools[i].meet_url,
                });
              }
              setMSch(arr);
            }
          }
        }
      });
      schoolactivelistcoach().then((response) => {
        if (response.status === true) {
          let arr1 = [];

          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    }
  }
  function handleCRM(e) {
    setCRMid(e);
  }

  function handleSchool(e) {
    setSchid(e);
  }

  function handleRef(e) {
    setRefid(e);
  }

  function backlist() {
    if(urllink === "tournamentdetails"){
    dotracking(
      "back button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourlistheading
    );
  }else{
    dotracking(
      "back button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.dashheading
    );
  }
    if (localStorage.getItem("Role") === "1") {
      if(urllink === "tournamentdetails"){
        navigate("/superadmin/tournamentlist");
      }else{
        navigate("/superadmin/dashboard");
      }
    } else if (localStorage.getItem("Role") === "3") {
      if(urllink === "tournamentdetails"){
        navigate("/cityregional/tournamentlist");
      }else{
        navigate("/cityregional/dashboard");
      }
      
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentlist");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "6") {
      navigate(-1);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/tournamentlist");
    }
  }

  function editpage() {
    dotracking(
      "edit button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.edittourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/edittournament/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/edittournament/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      // navigate("/schoolhead/edittournament")
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/edittournament/" + id);
    }
  }

  function editdispage() {
    dotracking(
      "edit school discount button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.schooldisclist
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/schooldiscountlist/" + id);
      // } else if (localStorage.getItem("Role") === "3") {
      //   navigate("/cityregional/schooldiscountlist/" + id);
    }
  }
  function editagepage() {
    if (localStorage.getItem("Role") === "5") {
      dotracking(
        "age category list button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touragecatlistheading
      );
    } else {
      dotracking(
        "edit age category button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touragecatlistheading
      );
    }
    if (msport.toUpperCase() === "ATHLETICS") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentagecategorylist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentagecategorylist/" + id);
        // } else if (localStorage.getItem("Role") === "4") {
        //   navigate("/schoolhead/tournamentagecategorylist/" + id)
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/tournamentagecategorylist/" + id);
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/tournamentagecategorylist/" + id);
      }
    } else {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentclassagecategorylist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentclassagecategorylist/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/tournamentclassagecategorylist/" + id);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/tournamentclassagecategorylist/" + id);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/tournamentclassagecategorylist/" + id);
      }
    }
  }

  const [errorMsg, setErrorMsg] = useState("");
  function assignref() {
    setErrorMsg("");
    if (refid !== "") {
      var rid = "";
      var refname = "";
      if (refid.length !== 0) {
        for (let i = 0; i < refid.length; i++) {
          if (rid === "") {
            rid = refid[i].value;
            refname = refid[i].label;
          } else {
            rid = rid + "," + refid[i].value;
            refname = refname + "," + refid[i].label;
          }
        }
      }
      dotracking(
        refname +
          " (" +
          rid +
          ") referee submit button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewtourheading
      );
      if (localStorage.getItem("Role") === "1") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);

        tourassignreferee(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);

        crmtourrefereeassign(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "7") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("assigned_referee", rid);

        coachtourrefereeassign(temp).then((response) => {
          if (response.status === true) {
            listref();
            setRefid("");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    } else {
      setErrorMsg("Please Select Referee");
    }
  }

  const [cerrorMsg, setCErrorMsg] = useState("");
  function assigncrm() {
    setCErrorMsg("");
    if (crmid !== "") {
      var rid = "";
      var crmname = "";
      if (crmid.length !== 0) {
        for (let i = 0; i < crmid.length; i++) {
          if (rid === "") {
            rid = crmid[i].value;
            crmname = crmid[i].label;
          } else {
            rid = rid + "," + crmid[i].value;
            crmname = crmname + "," + crmid[i].label;
          }
        }
      }
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("assigned_crm", rid);
      dotracking(
        crmname +
          " (" +
          rid +
          ") city manager submit button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewtourheading
      );
      tourassigncrm(temp).then((response) => {
        if (response.status === true) {
          listcrm();
          setCRMid("");
          toast.success(response.message);
        } else {
          setCErrorMsg(response.message);
        }
      });
    } else {
      setCErrorMsg("Please Select Manager");
    }
  }

  const [serrorMsg, setSErrorMsg] = useState("");
  function assignschool() {
    setSErrorMsg("");
    if (schid !== "") {
      var rid = "";
      var schname = "";
      if (schid.length !== 0) {
        for (let i = 0; i < schid.length; i++) {
          if (rid === "") {
            rid = schid[i].value;
            schname = schid[i].label;
          } else {
            rid = rid + "," + schid[i].value;
            schname = schname + "," + schid[i].label;
          }
        }
      }
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("school", rid);
      dotracking(
        schname +
          " (" +
          rid +
          ") school submit button in view tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewtourheading
      );
      tourassignschool(temp).then((response) => {
        if (response.status === true) {
          listschool();
          setSchid("");
          toast.success(response.message);
        } else {
          setSErrorMsg(response.message);
        }
      });
    } else {
      setSErrorMsg("Please Select School");
    }
  }

  function notisent() {
    Swal.fire({
      title: "Are you sure want to send?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "send reminder tournament notification button in view tournament for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        sendremaindertour(id).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
  }

  function photosent() {
    if (photolist.length !== 0) {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send photos notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendphotos(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add Photos for " + mname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  function sendhigh() {
    if (highlist.length !== 0) {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send highlights notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendhighlights(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add highlights for " + mname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  function sendcert() {
    // if (highlist.length !== 0) {
    Swal.fire({
      title: "Are you sure want to send?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "send certificates notification button in view tournament for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        senddigitalcertificates(id).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
    // } else {
    //   Swal.fire({
    //     title: "Add Certificates for " + mname,
    //     showCancelButton: true,
    //     confirmButtonColor: "#d71635",
    //     confirmButtonText: "Ok",
    //     allowOutsideClick: false,
    //   });
    // }
  }

  function sendschoolhead() {
    // Swal.fire({
    //   title: "Are you sure want to send?",
    //   showCancelButton: true,
    //   confirmButtonColor: "#d71635",
    //   confirmButtonText: "Ok",
    //   allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dotracking(
    //       "send school head notification button in view tournament for " +
    //         mname +
    //         " (" +
    //         id +
    //         ") tournament $$ " +
    //         trackobj.viewtourheading
    //     );
    //     sendschoolheadtour(id).then((response) => {
    //       if (response.status === true) {
    //         toast.success(response.message);
    //       }
    //     });
    //   }
    // });
  }

  function sendschhigh() {
    if (highlist.length !== 0) {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send schoolhead highlights notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendschheadhighlights(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add highlights for " + mname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  function sendschphoto() {
    if (photolist.length !== 0) {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send schoolhead photos notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendschheadphotos(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add Photos for " + mname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  function sendnewtour() {
    // if (highlist.length !== 0) {
    Swal.fire({
      title: "Are you sure want to send?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "send new tournament notification button in view tournament for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        sendnewtournoti(id).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
    // } else {
    //   Swal.fire({
    //     title: "Add Certificates for " + mname,
    //     showCancelButton: true,
    //     confirmButtonColor: "#d71635",
    //     confirmButtonText: "Ok",
    //     allowOutsideClick: false,
    //   });
    // }
  }

  function sentschedular() {
    if (msport.toUpperCase() === "ATHLETICS") {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send schedular notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendschedulartour(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      if (schlink.length !== 0) {
        Swal.fire({
          title: "Are you sure want to send?",
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            dotracking(
              "send schedular notification button in view tournament for " +
                mname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.viewtourheading
            );
            sendschedulartour(id).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: "Generate Schedular for " + mname,
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  }

  function sendschremainder() {
    if (msport.toUpperCase() !== "ATHLETICS") {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send schoolhead tournament remainder notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendschheadremainder(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function sendtourlink() {
    Swal.fire({
      title: "Are you sure want to send?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "send school tournament link notification button in view tournament for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        sendschheadtournamentlink(id).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
  }

  function sendschschedule() {
    if (msport.toUpperCase() === "ATHLETICS") {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "send schoolhead schedular notification button in view tournament for " +
              mname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.viewtourheading
          );
          sendschheadschedular(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      if (schlink.length !== 0) {
        Swal.fire({
          title: "Are you sure want to send?",
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            dotracking(
              "send schoolhead schedular notification button in view tournament for " +
                mname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.viewtourheading
            );
            sendschheadschedular(id).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: "Generate Schedular for " + mname,
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Details
          </li>
        </ol>
      </div>

      <div className="row ">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-2 col-md-6">
                        <b className="text-inverse">Tournament Name</b>
                      </div>
                      <div className="col-md-10 col-md-6">
                        <span className="text-inverse">{mname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Location</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mloc}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Tournament Code</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mcode}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Tournament Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mtype}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mstate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mdist}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msdate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{medate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Opens at</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{meodate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Last Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{meddate}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Multi Sports</b>
                      </div>
                      <div className="col-md-8">
                        {mulsp == 1 ? <span className="text-inverse">Yes</span> : <span className="text-inverse">No</span>}   
                                       
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Sports Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msport}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Age Category Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{agetype}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Age Categories</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mcat}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ageid === 1 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Class</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tourcls}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB Start Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{msdob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {ageid === 2 && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB End Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{medob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">League Format</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mleatype}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Reporting Time</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{reptime}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Free Tournament</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse">{mfree}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {mfree === "No" && msport.toUpperCase() !== "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Fees</b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">{mfees} /-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {msport.toUpperCase() === "ATHLETICS" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Relay Fees</b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">{mrfees} /-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Payment Gateway Charges</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse">{mgate}</span>
                      </div>
                    </div>
                  </div>
                </div> */}
                {mcir !== "" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Circular <br />
                            Document
                          </b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">
                            <a href={mcir} target="_blank" download={mcir}>
                              <b className="blink">
                                Click Here!{" "}
                                <i className="fa fa-download ms-2"></i>
                              </b>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

{lfile !== "" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Logo
                          </b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">
                          <img
                                      src={lfile}
                                      className="tour_fileQR"
                                    />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}



              </div>
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3" || localStorage.getItem("Role")==="7") && urllink == 'tournamentdetails'&& (
                <>
                  {mschool.length !== 0 && (
                    <div>
                      <hr />
                      <h4>School Details</h4>
                    </div>
                  )}

                  {mschool.map((data, i) => {
                    return (
                      <div className="row schooldetails mx-0" key={i}>
                        <div className="col-md-6">
                          <div className="details-group">
                            <div className="">
                              <b className="text-inverse">School Name</b>
                              <span className="text-inverse">
                                {data.school}
                              </span>
                            </div>
                          </div>
                        </div>
                        {mtype === "Intra School Tournament" && (
                          <>
                            <div className="col-md-3">
                              <div className="details-group">
                                <div className="text-center">
                                  <b className="text-inverse">QR Code</b>
                                  <a
                                    href={data.qrcode}
                                    target={"_blank"}
                                    download={data.qrcode}
                                  >
                                    <img
                                      src={data.qrcode}
                                      className="tour_fileQR"
                                    />
                                    <p className="mb-0">
                                      <b>Click to Download</b>
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="details-group">
                                <div className="text-center">
                                  <b className="text-inverse">Login URL</b>
                                  <span className="text-inverse">
                                    <a
                                      href={data.schlink}
                                      target={"_blank"}
                                      className="btn btn-secondary"
                                    >
                                      Click Here
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}

              {/* <h4>Referee Details</h4> */}
              <div className="row">
                <div className="col-md-4 border-right">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-12">
                        {localStorage.getItem("Role") === "1" && (
                          <div className="form-group">
                            <label className="form-label">
                              Select Manager{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <Select
                              className="dropdown"
                              placeholder="Select Manager"
                              value={crmid}
                              options={crmlist}
                              onChange={handleCRM}
                              isSearchable={true}
                              isMulti
                              isClearable
                              hidden
                            />
                            {cerrorMsg !== "" && (
                              <span className="errortext">{cerrorMsg}</span>
                            )}
                          </div>
                        )}
                        {localStorage.getItem("Role") === "1" && (
                          <div className="form-group">
                            <a className="btn btn-success" onClick={assigncrm}>
                              Submit
                            </a>
                          </div>
                        )}

                        {localStorage.getItem("Role") === "1" && (
                          <div className="form-group">
                            <label className="form-label">
                              Select School{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <Select
                              className="dropdown"
                              placeholder="Select School"
                              value={schid}
                              options={schlist}
                              onChange={handleSchool}
                              isSearchable={true}
                              isMulti
                              isClearable
                              hidden
                            />
                            {serrorMsg !== "" && (
                              <span className="errortext">{serrorMsg}</span>
                            )}
                          </div>
                        )}
                        {localStorage.getItem("Role") === "1" && (
                          <div className="form-group">
                            <a
                              className="btn btn-success"
                              onClick={assignschool}
                            >
                              Submit
                            </a>
                          </div>
                        )}

                        {localStorage.getItem("Role") === "1" && (
                          <>
                            <div className="form-group">
                              <label className="form-label">
                                Select Referees{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Referees"
                                value={refid}
                                options={reflist}
                                onChange={handleRef}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                            {errorMsg !== "" && (
                              <span className="errortext">{errorMsg}</span>
                            )}
                            <div className="form-group">
                              <a
                                className="btn btn-success"
                                onClick={assignref}
                              >
                                Submit
                              </a>
                            </div>
                          </>
                        )}
                        {(localStorage.getItem("Role") === "3" ||
                          localStorage.getItem("Role") === "7") &&  urllink == 'tournamentdetails' &&(
                          <>
                            <div className="form-group">
                              <label className="form-label">
                                Select Referees{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Referees"
                                value={refid}
                                options={reflist}
                                onChange={handleRef}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />
                            </div>
                            {errorMsg !== "" && (
                              <span className="errortext">{errorMsg}</span>
                            )}
                            <div className="form-group">
                              <a
                                className="btn btn-success"
                                onClick={assignref}
                              >
                                Submit
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 border-left">
                  <div className="details-group refereebox">
                    <div className="row">
                      {localStorage.getItem("Role") === "1" && (
                        <>
                          {CRMProduct.length !== 0 && (
                            <h4>Manager List</h4>
                          )}

                          {CRMProduct.map((data, i) => {
                            return (
                              <div
                                className="col-md-4 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">
                                    {data.first_name} {data.last_name}
                                  </h4>
                                  <h3 className="text-gray mb-0">
                                    {data.username}
                                  </h3>
                                </div>
                              </div>
                            );
                          })}
                          {CRMProduct.length !== 0 && (
                            <div className="border-bottom"></div>
                          )}
                        </>
                      )}

                      {localStorage.getItem("Role") === "1" && (
                        <>
                          {mschool.length !== 0 && <h4>School List</h4>}

                          {mschool.map((data, i) => {
                            return (
                              <div
                                className="col-md-4 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">{data.school}</h4>
                                  {/* <h3 className="text-gray mb-0">
                                    {data.username}
                                  </h3> */}
                                </div>
                              </div>
                            );
                          })}
                          {mschool.length !== 0 && (
                            <div className="border-bottom"></div>
                          )}
                        </>
                      )}

                      {filterProduct.length !== 0 && (
                        <h4 className="mt-2">Referee List</h4>
                      )}

                      {filterProduct.map((data, i) => {
                        return (
                          <div className="col-md-4 align-self-center" key={i}>
                            <div className="form-group">
                              <h4 className="mb-1">
                                {data.first_name} {data.last_name}
                              </h4>
                              <h3 className="text-gray mb-0">
                                {data.username}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3") && urllink == 'tournamentdetail' && (
                <>
                  <div className="text-right border-top">
                   
                    <a className="btn btn-gray mt-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                 
                </>
              )}

              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3") && urllink == 'tournamentdetails' && (
                <>
                  <div className="text-center border-top">
                    <a className="btn btn-export me-1 mt-2" onClick={notisent}>
                      <i className="fa fa-paper-plane"></i> Send Reminder
                      Tournament Notification
                    </a>
                    <a
                      className="btn btn-warning me-1 mt-2"
                      onClick={photosent}
                    >
                      <i className="fa fa-paper-plane"></i> Send Photos
                      Notification
                    </a>
                    <a className="btn btn-import me-1 mt-2" onClick={sendhigh}>
                      <i className="fa fa-paper-plane"></i> Send Highlights
                      Notification
                    </a>
                    <a className="btn btn-export me-1 mt-2" onClick={sendcert}>
                      <i className="fa fa-paper-plane"></i> Send Certificates
                      Notification
                    </a>

                    {/* <a
                      className="btn btn-primary me-1 mt-2"
                      onClick={sendnewtour}
                    >
                      <i className="fa fa-paper-plane"></i> Send New Tournament
                      Notification
                    </a> */}
                    <a
                      className="btn btn-secondary me-1 mt-2"
                      onClick={sentschedular}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schedular
                      Notification
                    </a>
                    <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                    {localStorage.getItem("Role") === "1" && (
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={editdispage}
                      >
                        <i className="fa fa-edit"></i> Edit School Discount
                      </a>
                    )}

                    {ageid === 2 && (
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={editagepage}
                      >
                        <i className="fa fa-edit"></i> Edit Age Category
                      </a>
                    )}
                    {ageid === 1 && (
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={editagepage}
                      >
                        <i className="fa fa-edit"></i> Edit Class
                      </a>
                    )}
                    <a className="btn btn-gray mt-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                  <div className="text-center border-top mt-2">
                    <a
                      className="btn btn-warning me-1 mt-2"
                      onClick={sendschoolhead}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schoolhead
                      Notification
                    </a>
                    {msport.toUpperCase() === "FOOTBALL" &&
                      mtype === "Inter School Tournament" && (
                        <a
                          className="btn btn-gray me-1 mt-2"
                          onClick={sendtourlink}
                        >
                          <i className="fa fa-paper-plane"></i> Send School
                          Tournament Link
                        </a>
                      )}

                    <a
                      className="btn btn-secondary me-1 mt-2"
                      onClick={sendschschedule}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schoolhead
                      Schedular Notification
                    </a>
                    <a
                      className="btn btn-export me-1 mt-2"
                      onClick={sendschremainder}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schoolhead
                      Tournament Reminder Notification
                    </a>
                    <a
                      className="btn btn-primary me-1 mt-2"
                      onClick={sendschphoto}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schoolhead
                      Photos Notification
                    </a>
                    <a
                      className="btn btn-import me-1 mt-2"
                      onClick={sendschhigh}
                    >
                      <i className="fa fa-paper-plane"></i> Send Schoolhead
                      Highlights Notification
                    </a>
                  </div>
                </>
              )}

              {(localStorage.getItem("Role") === "4" ||
                localStorage.getItem("Role") === "5" || localStorage.getItem("Role") === "8") && (
                <div className="text-right border-top">
                  {msport.toUpperCase() === "ATHLETICS" && ageid === 2 && (
                    <a
                      className="btn btn-secondary me-1 mt-2"
                      onClick={editagepage}
                    >
                      <i className="fa fa-list-alt"></i> Edit Age Category
                    </a>
                  )}
                  {msport.toUpperCase() === "ATHLETICS" && ageid === 1 && (
                    <a
                      className="btn btn-secondary me-1 mt-2"
                      onClick={editagepage}
                    >
                      <i className="fa fa-list-alt"></i> Edit Class
                    </a>
                  )}
                  <a className="btn btn-gray mt-2" onClick={backlist}>
                    <i className="fe fe-corner-up-left"></i> Back
                  </a>
                </div>
              )}

              {localStorage.getItem("Role") === "7" && (
                <div className="text-right border-top">
                  {tourcreate === parseInt(localStorage.getItem("UID")) && (
                    <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                  )}
                  {ageid === 2 &&
                    tourcreate === parseInt(localStorage.getItem("UID")) && (
                      <a
                        className="btn btn-secondary me-1 mt-2"
                        onClick={editagepage}
                      >
                        <i className="fa fa-list-alt"></i> Edit Age Category
                      </a>
                    )}
                  {ageid === 1 &&
                    tourcreate === parseInt(localStorage.getItem("UID")) && (
                      <a
                        className="btn btn-secondary me-1 mt-2"
                        onClick={editagepage}
                      >
                        <i className="fa fa-list-alt"></i> Edit Class
                      </a>
                    )}
                  <a className="btn btn-gray mt-2" onClick={backlist}>
                    <i className="fe fe-corner-up-left"></i> Back
                  </a>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
