import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  crmtourviewdetail,
  teamplayerdeletecityreg,
  teamselectedplayerlistcityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import {
  teamplayerdelete,
  tourcategorydetail,
  tourteamplayerlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { Col, Modal, Row } from "react-bootstrap";
import { Refteamplayerlist, Reftourdetail } from "../../../../services/referee";
import moment from "moment/moment";
import {
  scheadtourviewdetail,
  schooltourteamplayerlist,
  schprofileview,
} from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Select from "react-select";
import {
  interschoolcategoryclasslist,
  interschoolcategoryplayercreate,
  interschoolcategoryplayerdelete,
  interschoolcategoryplayerlist,
  racqetinterschoolcategoryplayercreate,
} from "../../../../services/interschool";
import {
  externalallplayerlist,
  externaltourdetails,
  externaltourteamplayercreate,
  externaltourteamplayerdelete,
  externaltourteamplayerlist,
  updateathletemobemail,
} from "../../../../services/common";
import { classlist } from "../../../../services/master";
import { tourclasslist } from "../../../../services/register";
import {
  coachtourviewdetail,
  coachviewprofile,
  teamplayerdeletecoach,
  teamselectedplayerlistcoach,
} from "../../../../services/coach";

export const TeamPlayerList = () => {
  const { id, sid } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();
  const [teamdet, setTeamDet] = useState([]);

  const [filterProduct, setFilterProduct] = useState([]);
  const [alist, setAthletelist] = useState([]);
  const [avalue, setAthlete] = useState("");
  const [ainfo, setAthInfo] = useState([]);
  const [s3path, setS3Path] = useState("");
  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [sports, setSports] = useState("");

  const [ttype, setTType] = useState("");
  const [eclosed, setEClosed] = useState(false);

  const [agetype, setAgeType] = useState("");

  const [teamname, setTeamName] = useState("");
  const [catname, setCatname] = useState("");
  const [catgroup, setCatGroup] = useState(0);

  const [schoolid, setSchoolID] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");

  const [totpla, setTotPla] = useState([]);

  function addperf(pname, usid) {
    if (localStorage.getItem("Role") === "7") {
      dotracking(
        "add performance icon in team player list for " +
          pname +
          "in " +
          teamname +
          " (" +
          tid +
          ") for " +
          catname +
          " (" +
          eid +
          ") category in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.addperfheading
      );
      if(urllink === "externalteamplayerlist"){
        navigate("/coach/externalindividualperformance/" + id + "/" + usid);
      }else{
        navigate("/coach/individualperformance/" + id + "/" + usid);
      }
      
    }
  }
  function deleteteam(pid, pname, uid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in team player list for " +
            pname +
            "in " +
            teamname +
            " (" +
            tid +
            ") for " +
            catname +
            " (" +
            eid +
            ") category in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.teamplaheading
        );
        if (localStorage.getItem("Role") === "1") {
          teamplayerdelete(pid).then((response) => {
            if (response.status === true) {
              toast.success(response.data.message);
              listall(ttype, urllink);
            }
          });
        } else if (localStorage.getItem("Role") === "3") {
          teamplayerdeletecityreg(pid).then((response) => {
            if (response.status === true) {
              toast.success(response.data.message);
              listall(ttype, urllink);
            }
          });
        } else if (
          localStorage.getItem("Role") === "4" &&
          ttype === "Inter School Tournament"
        ) {
          interschoolcategoryplayerdelete(
            pid,
            uid,
            id,
            localStorage.getItem("UID")
          ).then((response) => {
            if (response.status === true) {
              toast.success(response.data.message);
              listall(ttype, urllink);
            }
          });
        } else if (localStorage.getItem("Role") === "7") {
          if (urllink === "externalteamplayerlist") {
            externaltourteamplayerdelete(pid).then((response) => {
              if (response.status === true) {
                toast.success(response.data.message);
                listall(ttype, urllink);
              }
            });
          } else {
            teamplayerdeletecoach(pid).then((response) => {
              if (response.status === true) {
                toast.success(response.data.message);
                listall(ttype, urllink);
              }
            });
          }
        }
      }
    });
  }

  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (link[1] !== "externalteamplayerlist") {
      const tempData = new FormData();
      tempData.append("category_id", eid);
      tourcategorydetail(tempData).then((res) => {
        if (res.status === true) {
          setCatname(res.data.category_details.category_name);
          if (res.data.category_details.group_type !== null) {
            setCatGroup(res.data.category_details.group_type);
          }

          setAGender(res.data.category_details.category_gender);
        }
      });
    }
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setAgeType(response.data.tournament.agecat_or_std);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setAgeType(response.data.tournament.agecat_or_std);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setAgeType(response.data.tournament.agecat_or_std);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          setAgeType(response.data.tournament.agecat_or_std);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      if (link[1] === "externalteamplayerlist") {
        externaltourdetails(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setSports(response.data.tournament.sports_type);
            setTType(response.data.tournament.meet_type_name);
            setAgeType(response.data.tournament.agecat_or_std);
            if (
              response.data.tournament.end_date <=
              moment(new Date()).format("YYYY-MM-DD")
            ) {
              setEClosed(true);
            }
            listall(response.data.tournament.meet_type_name, link[1]);
          }
        });
      } else {
        coachtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setSports(response.data.tournament.sports_type);
            setTType(response.data.tournament.meet_type);
            setAgeType(response.data.tournament.agecat_or_std);
            if (
              response.data.tournament.entry_deadline_date <=
              moment(new Date()).format("YYYY-MM-DD")
            ) {
              setEClosed(true);
            }
            listall(response.data.tournament.meet_type, link[1]);
          }
        });
      }
    }
  }, []);
  let arr = [{ id: 1 }, { id: 2 }, { id: 3 }];
  function listall(ltype, ulink) {
    setFilterProduct([]);
    setAthletelist([]);
    setAthInfo([]);
    setS3Path("");
    if (localStorage.getItem("Role") === "1") {
      tourteamplayerlist(id, tid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setTeamName(
              response.data[0].team_name + " - " + response.data[0].team_code
            );
          }
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      teamselectedplayerlistcityreg(id, tid).then((response) => {
        if (response.status === true) {
          setTotPla(arr);
          if (response.data.length !== 0) {
            setTeamName(
              response.data[0].team_name + " - " + response.data[0].team_code
            );
          }
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schooltourteamplayerlist(id, tid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setTeamName(
              response.data[0].team_name + " - " + response.data[0].team_code
            );
          }
          setFilterProduct(response.data);
          if (ltype === "Inter School Tournament") {
            schprofileview(localStorage.getItem("UID")).then((res) => {
              if (res.status === true) {
                setSchoolID(res.data.school_id);
                const temp = new FormData();
                temp.append("tournament_meet_id", id);
                temp.append("school_id", res.data.school_id);
                temp.append("category_id", eid);

                interschoolcategoryplayerlist(temp).then((response) => {
                  if (response.status === true) {
                    setAthInfo(response.data.details);
                    setS3Path(response.data.s3);
                    if (response.data.details.length !== 0) {
                      arr = [];
                      for (let i = 0; i < response.data.details.length; i++) {
                        arr.push({
                          value: response.data.details[i].id,
                          label:
                            response.data.details[i].first_name +
                            " (" +
                            response.data.details[i].username +
                            " ) - " +
                            response.data.details[i].class_name +
                            " " +
                            response.data.details[i].section,
                        });
                      }
                      setAthletelist(arr);
                    }
                  }
                });
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Refteamplayerlist(id, tid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            setTeamName(
              response.data[0].team_name + " - " + response.data[0].team_code
            );
          }
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      if (ulink === "externalteamplayerlist") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("team_id", tid);

        externaltourteamplayerlist(temp).then((res1) => {
          if (res1.status === true) {
            if (res1.data.team_details.length !== 0) {
              setTeamDet(res1.data.team_details[0]);
              setCatname(res1.data.team_details[0].age_category_name);
              setTeamName(res1.data.team_details[0].team_name);
              setAGender(res1.data.team_details[0].gender);
            }
            setFilterProduct(res1.data.team_player_list);
            coachviewprofile().then((res) => {
              if (res.status === true) {
                setSchoolID(res.data.school_id);
                const temp1 = new FormData();
                temp1.append("school_id", res.data.school_id);
                temp1.append("gender", res1.data.team_details[0].gender);

                externalallplayerlist(temp1).then((response) => {
                  if (response.status === true) {
                    setAthInfo(response.data.all_players);
                    setS3Path(response.data.s3);
                    if (response.data.all_players.length !== 0) {
                      arr = [];
                      for (
                        let i = 0;
                        i < response.data.all_players.length;
                        i++
                      ) {
                        arr.push({
                          value: response.data.all_players[i].player_id,
                          label:
                            response.data.all_players[i].first_name +
                            " (" +
                            response.data.all_players[i].username +
                            " ) - " +
                            response.data.all_players[i].class_name +
                            " " +
                            response.data.all_players[i].section,
                        });
                      }
                      setAthletelist(arr);
                    }
                  }
                });
              }
            });
          }
        });
      } else {
        teamselectedplayerlistcoach(id, tid).then((response) => {
          if (response.status === true) {
            setTotPla(arr);
            if (response.data.length !== 0) {
              setTeamName(
                response.data[0].team_name + " - " + response.data[0].team_code
              );
            }
            setFilterProduct(response.data);
          }
        });

        coachviewprofile().then((res) => {
          if (res.status === true) {
            setSchoolID(res.data.school_id);
            const temp = new FormData();
            temp.append("tournament_meet_id", id);
            temp.append("school_id", res.data.school_id);
            temp.append("category_id", eid);

            interschoolcategoryplayerlist(temp).then((response) => {
              if (response.status === true) {
                setAthInfo(response.data.details);
                setS3Path(response.data.s3);
                if (response.data.details.length !== 0) {
                  arr = [];
                  for (let i = 0; i < response.data.details.length; i++) {
                    arr.push({
                      value: response.data.details[i].id,
                      label:
                        response.data.details[i].first_name +
                        " (" +
                        response.data.details[i].username +
                        " ) - " +
                        response.data.details[i].class_name +
                        " " +
                        response.data.details[i].section,
                    });
                  }
                  setAthletelist(arr);
                }
              }
            });
          }
        });
      }
    }
  }

  function backlist() {
    dotracking(
      "back button in team player list for " +
        teamname +
        " (" +
        tid +
        ") in " +
        catname +
        " (" +
        eid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamplayerlist") {
        navigate("/superadmin/teamlist/" + id + "/" + eid);
      } else {
        navigate("/superadmin/interteamlist/" + id + "/" + eid + "/" + sid);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/teamlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      // navigate("/coach/teamlist/" + id + "/" + eid);
      if (urllink === "externalteamplayerlist") {
        navigate("/coach/externalteamlist/" + id);
      } else if (urllink === "teamplayerlist") {
        navigate("/coach/teamlist/" + id + "/" + eid);
      } else {
        navigate("/coach/interteamlist/" + id + "/" + eid + "/" + sid);
      }
    }
  }

  const [show, setShow] = useState(false);
  function playeradd() {
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setAthlete("");
    setAthName("");
    setAthClass("");
    setAthSec("");
    setAthMob("");
    setAthEmail("");
    setAthDob("");
    setAthImage("");
  }

  const [newshow, setNewShow] = useState(false);
  const [nerrorMessage, setNErrorMessage] = useState("");
  const [agender, setAGender] = useState("");

  const [nfname, setNfname] = useState("");
  const [ngender, setNGender] = useState("");
  const [nemail, setNEmail] = useState("");
  const [nmobile, setNMobile] = useState("");
  const [ndob, setNDOB] = useState("");
  const [nclass, setNClass] = useState("");
  const [nsec, setNsec] = useState("");

  const [sclist, setClassList] = useState([]);

  function handlenewplayer() {
    setShow(false);
    setNewShow(true);
    setNfname("");
    setNGender(agender);
    setNEmail("");
    setNMobile("");
    setNDOB("");
    setNClass("");
    setNsec("");
    setScheck(false);
    setNErrorMessage("");
    if (urllink === "externalteamplayerlist") {
      var a = new Date().getFullYear() - 7;
      var d = new Date(a, 11, 31);
      setEDOB(moment(d).format("YYYY-MM-DD"));

      classlist().then((response) => {
        if (response.status === true) {
          setClassList(response.data);
        }
      });
    } else {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("category_id", eid);
      temp.append("school_id", schoolid);
      interschoolcategoryclasslist(temp).then((response) => {
        if (response.status === true) {
          setClassList(response.data.class_details);
          if (agetype === 2) {
            setSDOB(response.data.dob_start_date);
            setEDOB(response.data.dob_end_date);
          } else {
            var a = new Date().getFullYear() - 7;
            var d = new Date(a, 11, 31);
            setEDOB(moment(d).format("YYYY-MM-DD"));
          }
        }
      });
    }
  }

  function handlenewclose() {
    setShow(false);
    setNewShow(false);
    setNfname("");
    setNGender(agender);
    setNEmail("");
    setNMobile("");
    setNDOB("");
    setNClass("");
    setNsec("");
    setScheck(false);
    setNErrorMessage("");
  }

  const CheckMobile = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNMobile(e.target.value);
    }
  };

  const [valmob, setValMob] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    var obj = {};
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        setValMob("Enter a Valid  Mobile Number");
      }
    }
  }

  const [aname, setAthName] = useState("");
  const [adob, setAthDob] = useState("");
  const [aclass, setAthClass] = useState("");
  const [asec, setAthSec] = useState("");
  const [amob, setAthMob] = useState("");
  const [aemail, setAthEmail] = useState("");
  const [aimage, setAthImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setErrorMessage("");
    setAthlete(e);
    setAthName("");
    setAthDob("");
    setAthClass("");
    setAthSec("");
    setAthMob("");
    setAthEmail("");
    setAthImage("");

    setAthImage("");
    if (ainfo.length !== 0) {

      var athdata = ""
      if(urllink === "externalteamplayerlist"){
        athdata= ainfo.find((data) => {
          return data.player_id === e.value;
        });
      }else{
        athdata= ainfo.find((data) => {
          return data.id === e.value;
        });
      }
      
      setAthName(athdata.first_name);
      setAthClass(athdata.class_name);
      setAthSec(athdata.section);
      if (athdata.mobile_no !== null) {
        setAthMob(athdata.mobile_no);
      }
      if (athdata.email_id !== null) {
        setAthEmail(athdata.email_id);
      }

      setAthDob(moment(athdata.date_of_birth).format("DD-MM-YYYY"));
      if (athdata.photograph !== null) {
        setAthImage(s3path + athdata.photograph);
      }
    }
  };

  const handleMobile = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAthMob(e.target.value);
    }
  };

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function athletesubmit() {
    setErrorMessage("");
    if (avalue !== "") {
      var error = "";

      // if (amob === "") {
      //   error = "Please Enter Mobile Number";
      //   setErrorMessage("Please Enter Mobile Number");
      // } else if (aemail === "") {
      //   error = "Please Enter Email";
      //   setErrorMessage("Please Enter Email");
      // }
      if (amob !== "") {
        var obj = {};

        for (let x = 0, length = amob.length; x < length; x++) {
          var l = amob.charAt(x);
          obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
        }
        if (obj[l] === 10) {
          error = "Please Enter a valid Mobile Number";
          setErrorMessage("Enter a Valid Mobile Number");
        }
        if (amob.length !== 10) {
          error = "Mobile Number must be 10 characters";
          setErrorMessage("Mobile Number must be 10 characters");
        }
      }
      if (aemail !== "") {
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regEmail.test(aemail)) {
          error = "Please Enter a valid Email";
          setErrorMessage("Please Enter a valid Email");
        }
      }
      if (error === "") {
        const temp1 = new FormData();
        temp1.append("mobile_no", amob);
        temp1.append("email_id", aemail);
        temp1.append("user_id", avalue.value);

        updateathletemobemail(temp1).then((res) => {
          if (res.status === true) {
            if (ainfo.length !== 0) {
              for (let i = 0; i < ainfo.length; i++) {
                if (ainfo[i].id === avalue.value) {
                  ainfo[i].mobile_no = amob;
                  ainfo[i].email_id = aemail;
                }
              }
            }
          } else {
            error = res.message;
            setErrorMessage(res.message);
          }
        });
        if (urllink === "externalteamplayerlist") {
          dotracking(
            "add player button in add player for " +
              avalue.label +
              " (" +
              avalue.value +
              ") in " +
              teamdet.age_category_name +
              " (" +
              teamdet.age_category_id +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.playerlistheading
          );
          const temp = new FormData();
          temp.append("tournament_meet_id", id);
          temp.append("school_id", schoolid);
          temp.append("team_id", tid);
          temp.append("created_by_id", localStorage.getItem("UID"));
          temp.append("user_id", avalue.value);
          temp.append("category_id", teamdet.age_category_id);

          externaltourteamplayercreate(temp).then((response) => {
            if (response.status === true) {
              setAthlete("");
              setAthName("");
              setAthClass("");
              setAthSec("");
              setAthMob("");
              setAthEmail("");
              setAthDob("");
              setAthImage("");
              setShow(false);
              toast.success(response.message);
              listall(ttype, urllink);
            } else {
              setErrorMessage(response.message);
            }
          });
        } else {
          dotracking(
            "add player button in add player for " +
              avalue.label +
              " (" +
              avalue.value +
              ") in " +
              catname +
              " (" +
              eid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.playerlistheading
          );
          const temp = new FormData();
          temp.append("tournament_meet_id", id);
          temp.append("category_id", eid);
          temp.append("school_id", schoolid);
          temp.append("team_id", tid);
          temp.append("school_head_id", localStorage.getItem("UID"));
          temp.append("user_id", avalue.value);
          if (
            sports.toUpperCase() === "BADMINTON" ||
            sports.toUpperCase() === "TENNIS" ||
            sports.toUpperCase() === "TABLE TENNIS" ||
            sports.toUpperCase() === "SQUASH"
          ) {
            temp.append("group_type", catgroup); // Only for Racquet Sports

            racqetinterschoolcategoryplayercreate(temp).then((response) => {
              if (response.status === true) {
                setAthlete("");
                setAthName("");
                setAthClass("");
                setAthSec("");
                setAthMob("");
                setAthEmail("");
                setAthDob("");
                setAthImage("");
                setShow(false);
                toast.success(response.message);
                listall(ttype, urllink);
              } else {
                setErrorMessage(response.message);
              }
            });
          } else {
            interschoolcategoryplayercreate(temp).then((response) => {
              if (response.status === true) {
                setAthlete("");
                setAthName("");
                setAthClass("");
                setAthSec("");
                setAthMob("");
                setAthEmail("");
                setAthDob("");
                setAthImage("");
                setShow(false);
                toast.success(response.message);
                listall(ttype, urllink);
              } else {
                setErrorMessage(response.message);
              }
            });
          }
        }
      }
    } else {
      setErrorMessage("Please Select Player");
    }
  }

  const [scheck, setScheck] = useState(false);
  function playersub() {
    setScheck(true);
    if (
      nfname !== "" &&
      ngender !== "" &&
      // nemail !== "" &&
      // nmobile !== "" &&
      ndob !== "" &&
      nclass !== "" &&
      nsec !== "" &&
      valmob === "" &&
      nerrorMessage === ""
    ) {
      var error = "";
      if (nemail !== "") {
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regEmail.test(nemail)) {
          error = "Please Enter a valid Email";
          setNErrorMessage("Please Enter a valid Email");
        }
      }

      if (error === "") {
        const temp = new FormData();
        temp.append("first_name", nfname);
        temp.append("last_name", "");
        temp.append("middle_name", "");
        temp.append("photograph", "");
        temp.append("notification_enable", 1);
        temp.append("gender", ngender);
        temp.append("email_id", nemail);
        temp.append("mobile_no", nmobile);
        temp.append("date_of_birth", ndob);
        temp.append("class", nclass);
        temp.append("section", nsec);
        temp.append("tournament_meet_id", id);
        temp.append("team_id", tid);
        temp.append("school_id", schoolid);
        temp.append("user_id", 0);
        if (urllink === "externalteamplayerlist") {
          temp.append("category_id", teamdet.age_category_id);
          temp.append("created_by_id", localStorage.getItem("UID"));

          dotracking(
            "submit button in add new player for " +
              avalue.label +
              " (" +
              avalue.value +
              ") in " +
              teamdet.age_category_name +
              " (" +
              teamdet.age_category_id +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament with " +
              nfname +
              ",gender " +
              ngender +
              ",dob " +
              ndob +
              ",email " +
              nemail +
              ",mobile " +
              nmobile +
              " $$ " +
              trackobj.playerlistheading
          );
          externaltourteamplayercreate(temp).then((response) => {
            if (response.status === true) {
              setNewShow(false);
              setNfname("");
              setNGender(agender);
              setNEmail("");
              setNMobile("");
              setNDOB("");
              setNClass("");
              setNsec("");
              setNErrorMessage("");
              setScheck(false);
              setAthlete("");
              setAthName("");
              setAthClass("");
              setAthSec("");
              setAthMob("");
              setAthEmail("");
              setAthDob("");
              setAthImage("");
              setShow(false);
              toast.success(response.message);
              listall(ttype, urllink);
            } else {
              setErrorMessage(response.message);
            }
          });
        } else {
          temp.append("category_id", eid);
          temp.append("school_head_id", localStorage.getItem("UID"));
          dotracking(
            "submit button in add new player for " +
              avalue.label +
              " (" +
              avalue.value +
              ") in " +
              catname +
              " (" +
              eid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament with " +
              nfname +
              ",gender " +
              ngender +
              ",dob " +
              ndob +
              ",email " +
              nemail +
              ",mobile " +
              nmobile +
              " $$ " +
              trackobj.playerlistheading
          );
          if (
            sports.toUpperCase() === "BADMINTON" ||
            sports.toUpperCase() === "TENNIS" ||
            sports.toUpperCase() === "TABLE TENNIS" ||
            sports.toUpperCase() === "SQUASH"
          ) {
            temp.append("group_type", catgroup); // Only for Racquet Sports

            racqetinterschoolcategoryplayercreate(temp).then((response) => {
              if (response.status === true) {
                setNewShow(false);
                setNfname("");
                setNGender(agender);
                setNEmail("");
                setNMobile("");
                setNDOB("");
                setNClass("");
                setNsec("");
                setNErrorMessage("");
                setScheck(false);
                setAthlete("");
                setAthName("");
                setAthClass("");
                setAthSec("");
                setAthMob("");
                setAthEmail("");
                setAthDob("");
                setAthImage("");
                setShow(false);
                toast.success(response.message);
                listall(ttype, urllink);
              } else {
                setNErrorMessage(response.message);
              }
            });
          } else {
            interschoolcategoryplayercreate(temp).then((response) => {
              if (response.status === true) {
                setNewShow(false);
                setNfname("");
                setNGender(agender);
                setNEmail("");
                setNMobile("");
                setNDOB("");
                setNClass("");
                setNsec("");
                setNErrorMessage("");
                setScheck(false);
                setAthlete("");
                setAthName("");
                setAthClass("");
                setAthSec("");
                setAthMob("");
                setAthEmail("");
                setAthDob("");
                setAthImage("");
                setShow(false);
                toast.success(response.message);
                listall(ttype, urllink);
              } else {
                setNErrorMessage(response.message);
              }
            });
          }
        }
      }
    }
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Team Player Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Team Lists</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Team Player Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <div className="text-left">
                      <h3>{tname}</h3>
                      <h5 className="text-warning tx-20">
                        Category - {catname}
                      </h5>
                      <h4 className="text-success tx-20">{teamname}</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-right">
                      {ttype === "Inter School Tournament" &&
                        !eclosed &&
                        localStorage.getItem("Role") === "4" && (
                          <a className="btnfixture me-2" onClick={playeradd}>
                            <i className="fa fa-plus"></i> Add Player To The
                            Team
                          </a>
                        )}

                      {localStorage.getItem("Role") === "7" && (
                        <a className="btnfixture me-2" onClick={playeradd}>
                          <i className="fa fa-plus"></i> Add Player To The Team
                        </a>
                      )}

                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div id="playerlist">
                  <div className="row">
                    {/* {totpla.map((data, i) => {
                      return (
                        <div className="col-md-4" key={i}>
                          <div className="box" onClick={playeradd}>
                            <div className="box__content text-center">
                              <h2 className="text-center">
                                <i className="fas fa-plus-circle fa-2x"></i>
                              </h2>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    } */}
                    {filterProduct.length === 0 && (
                      <div className="col-md-4">
                        <div className="box">
                          <div className="box__content text-center">
                            <h2 className="text-center">
                              Players will be added soon
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} size="lg" onHide={handleclose}>
                      <Modal.Header>
                        <h4> Add Player To The Team</h4>
                      </Modal.Header>
                      <Modal.Body>
                        <Select
                          placeholder="Select Player"
                          value={avalue}
                          options={alist}
                          onChange={handleChange}
                          isSearchable={true}
                        />
                        {aname !== "" && (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Name</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {aname}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Class</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {aclass}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Section</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">{asec}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">DOB</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">{adob}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Mobile{" "}
                                      {/* <span className="errortext"> *</span> */}
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Mobile Number"
                                      maxLength={10}
                                      value={amob}
                                      onChange={handleMobile}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Email{" "}
                                      {/* <span className="errortext"> *</span> */}
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Email"
                                      value={aemail}
                                      onChange={(e) =>
                                        setAthEmail(e.target.value)
                                      }
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {errorMessage !== "" && (
                          <span className="errortext">{errorMessage}</span>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <a
                          onClick={handlenewplayer}
                          className="btn btn-secondary"
                        >
                          <i className="fa fa-plus"></i> Add New Player
                        </a>

                        <a className="btn btn-success" onClick={athletesubmit}>
                          Submit
                        </a>

                        <a className="btn btn-gray" onClick={handleclose}>
                          Cancel
                        </a>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={newshow}>
                      <Modal.Header>
                        <h4 className="rep-head">Add New Player</h4>
                      </Modal.Header>
                      <Modal.Body className="mod-body">
                        <div className="row mt-2">
                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Full Name{" "}
                                    <span className="errortext"> *</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Full Name"
                                    className="form-control"
                                    value={nfname}
                                    onChange={(e) => setNfname(e.target.value)}
                                    autoFocus
                                    onKeyPress={LetterOnly}
                                    onPaste={onPasteLetter}
                                  />
                                  {scheck && !nfname && (
                                    <span className="errortext">
                                      Full Name is required
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Gender <span className="errortext"> *</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    value={ngender}
                                    onChange={(e) => setNGender(e.target.value)}
                                  >
                                    <option value="" defaultValue disabled>
                                      --- Select Gender ---
                                    </option>
                                    {agender === "Male" && (
                                      <option value="Male">Male</option>
                                    )}
                                    {agender === "Female" && (
                                      <option value="Female">Female</option>
                                    )}
                                  </select>
                                  {scheck && !ngender && (
                                    <span className="errortext">
                                      Gender is required
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Date of Birth{" "}
                                    <span className="errortext"> *</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={ndob}
                                    min={sdob}
                                    max={edob}
                                    onChange={(e) => setNDOB(e.target.value)}
                                    onKeyPress={(event) => {
                                      event.preventDefault();
                                    }}
                                    onKeyDown={(event) => {
                                      event.preventDefault();
                                    }}
                                  />
                                  {scheck && !ndob && (
                                    <span className="errortext">
                                      Date of Birth is required
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Class <span className="errortext"> *</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  {urllink === "externalteamplayerlist" && (
                                    <select
                                      className="form-control"
                                      value={nclass}
                                      onChange={(e) =>
                                        setNClass(e.target.value)
                                      }
                                    >
                                      <option value="" defaultValue disabled>
                                        --- Select Class ---
                                      </option>
                                      {sclist.map((data, i) => {
                                        return (
                                          <option value={data.id} key={i}>
                                            {data.class}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  )}
                                  {urllink !== "externalteamplayerlist" && (
                                    <select
                                      className="form-control"
                                      value={nclass}
                                      onChange={(e) =>
                                        setNClass(e.target.value)
                                      }
                                    >
                                      <option value="" defaultValue disabled>
                                        --- Select Class ---
                                      </option>
                                      {sclist.map((data, i) => {
                                        return (
                                          <option value={data.class_id} key={i}>
                                            {data.class_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  )}
                                  {scheck && !nclass && (
                                    <span className="errortext">
                                      Class is required
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Section{" "}
                                    <span className="errortext"> *</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Section"
                                    className="form-control"
                                    value={nsec}
                                    onChange={(e) => setNsec(e.target.value)}
                                  />
                                  {scheck && !nsec && (
                                    <span className="errortext">
                                      Section is required
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Mobile Number{" "}
                                    {/* <span className="errortext"> *</span> */}
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Mobile Number"
                                    maxLength={10}
                                    value={nmobile}
                                    onChange={CheckMobile}
                                    onBlur={checkmob}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                  />
                                  {/* {scheck && !nmobile && (
                                    <span className="errortext">
                                      Mobile Number is required
                                    </span>
                                  )} */}
                                  {valmob !== "" && nmobile && (
                                    <span className="errortext">{valmob}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="details-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Email
                                    {/* <span className="errortext"> *</span> */}
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Email"
                                    value={nemail}
                                    onChange={(e) => setNEmail(e.target.value)}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                  />
                                  {/* {scheck && !nemail && (
                                    <span className="errortext">
                                      Email is required
                                    </span>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {nerrorMessage !== "" && (
                          <span className="errortext">{nerrorMessage}</span>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <a className="btn btn-success" onClick={playersub}>
                          Submit
                        </a>
                        <a onClick={handlenewclose} className="btn btn-gray">
                          Cancel
                        </a>
                      </Modal.Footer>
                    </Modal>

                    {filterProduct.map((data, i) => {
                      return (
                        <div className="col-md-4" key={i}>
                          <div className="box">
                            <div className="box__head">
                              <h1>{data.first_name}</h1>
                            </div>
                            <div className="box__content">
                              <h2>
                                <b>Player UID</b> <span>{data.username}</span>
                              </h2>
                              <h2>
                                <b>Class</b> <span>{data.class_name}</span>
                              </h2>
                              <h2>
                                <b>Section</b> <span>{data.section}</span>
                              </h2>
                              <h2>
                                <b>Gender</b> <span>{data.gender}</span>
                              </h2>
                              {data.date_of_birth !== null && (
                                <h2>
                                  <b>DOB</b>{" "}
                                  <span>
                                    {moment(data.date_of_birth).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </h2>
                              )}
                              {data.date_of_birth === null && (
                                <h2>
                                  <b>DOB</b> <span>{data.date_of_birth}</span>
                                </h2>
                              )}
                            </div>
                            <div className="box_footer">
                              <h4>School Name :-</h4>
                              <p>{data.school_name}</p>
                            </div>
                            {(localStorage.getItem("Role") === "1" ||
                              localStorage.getItem("Role") === "3") && (
                              <a
                                onClick={() =>
                                  deleteteam(
                                    data.members_table_id,
                                    data.first_name +
                                      " (" +
                                      data.username +
                                      ")",
                                    data.player_id
                                  )
                                }
                                title="Delete Player"
                                className="minus-mark"
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                            )}

                            {localStorage.getItem("Role") === "7" && (
                              <>
                                <a
                                  title="Add Performance"
                                  className="add-mark"
                                  onClick={() =>
                                    addperf(
                                      data.first_name +
                                        " (" +
                                        data.username +
                                        ")",
                                      data.player_id
                                    )
                                  }
                                >
                                  <i className="fa fa-user"></i>
                                </a>
                                {urllink === "externalteamplayerlist" && (
                                  <a
                                    onClick={() =>
                                      deleteteam(
                                        data.id,
                                        data.first_name +
                                          " (" +
                                          data.username +
                                          ")",
                                        data.player_id
                                      )
                                    }
                                    title="Delete Player"
                                    className="player-mark"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                )}

                                {localStorage.getItem("UID") === tcreate &&
                                  urllink !== "externalteamplayerlist" && (
                                    <a
                                      onClick={() =>
                                        deleteteam(
                                          data.members_table_id,
                                          data.first_name +
                                            " (" +
                                            data.username +
                                            ")",
                                          data.player_id
                                        )
                                      }
                                      title="Delete Player"
                                      className="player-mark"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  )}
                              </>
                            )}

                            {localStorage.getItem("Role") === "4" &&
                              !eclosed &&
                              ttype === "Inter School Tournament" && (
                                <a
                                  onClick={() =>
                                    deleteteam(
                                      data.members_table_id,
                                      data.first_name +
                                        " (" +
                                        data.username +
                                        ")",
                                      data.player_id
                                    )
                                  }
                                  title="Delete Player"
                                  className="minus-mark"
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
