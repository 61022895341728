import React, { useEffect, useState } from "react";
import {
  coachagecatelist,
  coachsportslist,
  coachviewprofile,
} from "../../../../../services/coach";
import { agecatactivelist, highestlevelplayedlist, sportsactivelist } from "../../../../../services/superadmin";
import { toast } from "react-toastify";
import Select from "react-select";
import { districtlist, statelist } from "../../../../../services/master";
import {
  externaltourcreate,
  externaltourdetails,
  externaltourupdate,
} from "../../../../../services/common";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import moment from "moment";
import { crmagecatelist, crmsportslist } from "../../../../../services/cityregionaladmin";

export const AddExternalTour = () => {
  const { id } = useParams();
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mtype, setMType] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [msport, setMSport] = useState("");
  const [mschool, setMSchool] = useState("");
  const [msportid, setMSportId] = useState("");
  const [mcat, setMCat] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");

  const [slist, setStateList] = useState([]);
  const [dlist, setDistList] = useState([]);
  const [typelist, setTypeList] = useState([]);
  const [spotypelist, setSpoTypeList] = useState([]);
  const [agecatlist, setAgeCatList] = useState([]);
  const navigate = useNavigate();
  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);

    highestlevelplayedlist().then((response) => {
      if (response.status === true) {
        setTypeList(response.data);
      }
    });

    statelist().then((response) => {
      if (response.status === true) {
        setStateList(response.data);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      sportsactivelist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });
      
      agecatactivelist().then((response) => {
        if (response.status === true) {
          let arr = [];

          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });
    }else if (localStorage.getItem("Role") === "3") {
      crmsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });
      
      crmagecatelist().then((response) => {
        if (response.status === true) {
          let arr = [];

          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachsportslist().then((response) => {
        if (response.status === true) {
          setSpoTypeList(response.data);
        }
      });
      coachviewprofile().then((response) => {
        if (response.status === true) {
          setMSchool(response.data.school_id);
        }
      });
      coachagecatelist().then((response) => {
        if (response.status === true) {
          let arr = [];

          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].age_category_name,
              });
            }
          }
          setAgeCatList(arr);
        }
      });
    }


    if (link[1] === "editexternaltournament") {
      externaltourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.state);
          setMDist(response.data.tournament.district);

          setMSDate(response.data.tournament.start_date);
          setMEDate(response.data.tournament.end_date);
          setMSport(response.data.tournament.sports_type);
          setMSportId(response.data.tournament.sports_type_no);

          if (response.data.age_category.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.age_category.length; i++) {
              arr.push({
                value: response.data.age_category[i].id,
                label: response.data.age_category[i].age_categories,
              });
            }
            setMCat(arr);
          }

          districtlist(response.data.tournament.state).then((res1) => {
            if (res1.status === true) {
              setDistList(res1.data);
            }
          });
        }
      });
    }
  }, []);

  function selectstate(e) {
    e.preventDefault();
    setMState(e.target.value);
    setDistList([]);
    setMDist("");
    if (e.target.value !== "") {
      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDistList(response.data);
        }
      });
    }
  }

  function selectdist(e) {
    setMDist(e.target.value);
  }

  const handleEDate = (e) => {
    if (msdate !== "") {
      setMEDate(e.target.value);
    } else {
      toast.error("Please Select Start Date");
    }
  };

  function handleSport(e) {
    setMSportId(e.target.value);
    setMSport("");
    const data = spotypelist.find(
      (item) => item.sports_cat_uid == e.target.value
    );
    setMSport(data.sports_category);
  }

  function handleAge(e) {
    setMCat(e);
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in external tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewexttourheading
      );
      navigate("/superadmin/externaltournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in external tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewexttourheading
      );
      navigate("/cityregional/externaltournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "back button in external tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewexttourheading
      );
      navigate("/coach/externaltournamentdetails/" + id);
    }
  }

  const toursubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setErrorMsg("");
    var catid = "";
    if (
      mname !== "" &&
      mtype !== "" &&
      msdate !== "" &&
      medate !== "" &&
      msport !== "" &&
      mcat !== "" &&
      mstate !== "" &&
      mdist !== "" &&
      mloc !== ""
    ) {
      if (urllink === "editexternaltournament") {
        const temp = new FormData();
        temp.append("tournament_id", id);
        temp.append("meet_name", mname);
        temp.append("location", mloc);
        temp.append("start_date", msdate);
        temp.append("end_date", medate);
        temp.append("sports_type", msport);
        temp.append("sports_type_no", msportid);

        temp.append("updated_by_id", localStorage.getItem("UID"));
        dotracking(
          "update button in external tournament for " +
            mname +
            " with " +
            mtype +
            " type, " +
            msdate +
            " start date, " +
            medate +
            " end date, " +
            msport +
            " sport," +
            mcat +
            " age category, " +
            mstate +
            " state," +
            mdist +
            " district $$ " +
            trackobj.viewexttourheading
        );
        externaltourupdate(temp).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            if(localStorage.getItem("Role") === "1"){
              navigate("/superadmin/externaltournamentdetails/" + id);
            }else if(localStorage.getItem("Role") === "3"){
              navigate("/cityregional/externaltournamentdetails/" + id);
            }else if(localStorage.getItem("Role") === "7"){
              navigate("/coach/externaltournamentdetails/" + id);
            }
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        if (mcat.length !== 0) {
          for (let i = 0; i < mcat.length; i++) {
            if (catid === "") {
              catid = mcat[i].label;
            } else {
              catid = catid + "," + mcat[i].label;
            }
          }
        }
        const temp = new FormData();
        temp.append("meet_name", mname);
        temp.append("location", mloc);
        temp.append("meet_type", mtype);
        temp.append("start_date", msdate);
        temp.append("end_date", medate);
        temp.append("sports_type", msport);
        temp.append("sports_type_no", msportid);
        temp.append("categories", catid);
        if (localStorage.getItem("Role") === "7") {
          temp.append("school_id", mschool);
        } else {
          temp.append("school_id", 0);
        }
        temp.append("state", mstate);
        temp.append("district", mdist);
        temp.append("created_by_id", localStorage.getItem("UID"));
        dotracking(
          "submit button in external tournament for " +
            mname +
            " with " +
            mtype +
            " type, " +
            msdate +
            " start date, " +
            medate +
            " end date, " +
            msport +
            " sport," +
            mcat +
            " age category, " +
            mstate +
            " state," +
            mdist +
            " district $$ " +
            trackobj.tourextheading
        );
        externaltourcreate(temp).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            if(localStorage.getItem("Role") === "1"){
              navigate("/superadmin/externaltournamentlist");
            }else if(localStorage.getItem("Role") === "3"){
              navigate("/cityregional/externaltournamentlist");
            }else if(localStorage.getItem("Role") === "7"){
              navigate("/coach/externaltournamentlist");
            }
            
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    }
  };
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add External Tournament</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add External Tournament
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tournament Name"
                      value={mname}
                      onChange={(e) => setMName(e.target.value)}
                    />
                    {submitted && !mname && (
                      <span className="errortext">
                        Tournament Name is required
                      </span>
                    )}
                  </div>
                </div>

                {urllink !== "editexternaltournament" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Tournament Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mtype}
                          onChange={(e) => setMType(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Tournament Type ---
                          </option>
                          {typelist.map((data, i) => {
                            return (
                              <>
                                {data.meet_level !== "Intra-School" && (
                                  <option value={data.meet_type_uid} key={i}>
                                    {data.meet_level}
                                  </option>
                                )}
                              </>
                            );
                          })}
                        </select>
                        {submitted && !mtype && (
                          <span className="errortext">
                            Tournament Type is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          State <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mstate}
                          onChange={selectstate}
                        >
                          <option value="" defaultValue disabled>
                            --- Select State ---
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !mstate && (
                          <span className="errortext">State is required</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          District <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mdist}
                          onChange={selectdist}
                        >
                          <option value="" defaultValue disabled>
                            --- Select District ---
                          </option>
                          {dlist.map((data, i) => {
                            return (
                              <option value={data.districtuid} key={i}>
                                {data.districtname}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !mdist && (
                          <span className="errortext">
                            District is required
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {urllink === "editexternaltournament" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Tournament Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mtype}
                          onChange={(e) => setMType(e.target.value)}
                          disabled
                        >
                          <option value="" defaultValue disabled>
                            --- Select Tournament Type ---
                          </option>
                          {typelist.map((data, i) => {
                            return (
                              <>
                                {data.meet_level !== "Intra-School" && (
                                  <option value={data.meet_type_uid} key={i}>
                                    {data.meet_level}
                                  </option>
                                )}
                              </>
                            );
                          })}
                        </select>
                        {submitted && !mtype && (
                          <span className="errortext">
                            Tournament Type is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          State <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mstate}
                          onChange={selectstate}
                          disabled
                        >
                          <option value="" defaultValue disabled>
                            --- Select State ---
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !mstate && (
                          <span className="errortext">State is required</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          District <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={mdist}
                          onChange={selectdist}
                          disabled
                        >
                          <option value="" defaultValue disabled>
                            --- Select District ---
                          </option>
                          {dlist.map((data, i) => {
                            return (
                              <option value={data.districtuid} key={i}>
                                {data.districtname}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !mdist && (
                          <span className="errortext">
                            District is required
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Start Date <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={msdate}
                      onChange={(e) => setMSDate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !msdate && (
                      <span className="errortext">Start Date is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      End Date <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={medate}
                      min={msdate}
                      onChange={handleEDate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !medate && (
                      <span className="errortext">End Date is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Sports <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={msportid}
                      onChange={handleSport}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Sports ---
                      </option>
                      {spotypelist.map((data, i) => {
                        return (
                          <option value={data.sports_cat_uid} key={i}>
                            {data.sports_category}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !msportid && (
                      <span className="errortext">Sports is required</span>
                    )}
                  </div>
                </div>
                {urllink === "editexternaltournament" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Categories <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Categories"
                        value={mcat}
                        options={agecatlist}
                        onChange={handleAge}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                        isDisabled
                      />
                      {submitted && !mcat && (
                        <span className="errortext">
                          Age Categories is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {urllink !== "editexternaltournament" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Age Categories <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Categories"
                        value={mcat}
                        options={agecatlist}
                        onChange={handleAge}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                      {submitted && !mcat && (
                        <span className="errortext">
                          Age Categories is required
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Tournament Location <span className="errortext"> *</span>
                    </label>
                    <textarea
                      rows={3}
                      type="text"
                      className="form-control"
                      placeholder="Tournament Location"
                      value={mloc}
                      onChange={(e) => setMLoc(e.target.value)}
                    />
                    {submitted && !mloc && (
                      <span className="errortext">
                        Tournament Location is required
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </div>
            <div className="card-footer text-center border-top">
              {urllink === "editexternaltournament" && (
                <>
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={toursubmit}
                  >
                    Update
                  </button>
                  <a type="submit" className="btn btn-gray" onClick={backlist}>
                    Back
                  </a>
                </>
              )}

              {urllink !== "editexternaltournament" && (
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={toursubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
