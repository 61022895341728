import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { scheadkeyhighlights } from "../../../services/schoolhead";
import { sportslist } from "../../../services/master";
import { fundermedalslist } from "../../../services/superadmin";
import { funderprofile } from "../../../services/funder";

export const KeyHighlights = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [fyear, setFYear] = useState([]);
  const [cyear, setCYear] = useState(0);
  const [sport, setSport] = useState(0);
  const [slist, setSportList] = useState([]);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Tournament Type",
      field: "meet_type",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Medals Won",
      field: "total_achievements",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Students Participated",
      field: "participants",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Tournaments",
      field: "meet_count",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];
  let arr = [];
  useEffect(() => {
    const startYear = 2016;
    const currentYear = new Date().getFullYear();
    const count = Math.abs(currentYear - startYear);
    let curyear = startYear;
    for (let i = 0; i <= count; i++) {
      if (curyear <= currentYear) {
        arr.push({
          year: curyear,
        });
        curyear = curyear + 1;
      }
    }
    setFYear(arr);

    sportslist().then((response) => {
      if (response.status === true) {
        setSportList(response.data);
      }
    });

    listall(0, 0);
  }, []);

  function listall(yr, sp) {
    setCYear(yr);
    setSport(sp);
    if (localStorage.getItem("Role") === "4") {
      const temp = new FormData();
      temp.append("year", yr);
      temp.append("sports", sp);
      scheadkeyhighlights(temp).then((response) => {
        if (response.status === true) {
          console.log(response);
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      funderprofile().then((res3) => {
        if (res3.status === true) {
          const temp = new FormData();
          temp.append("year", yr);
          temp.append("sports", sp);
          temp.append("funder_id", res3.data.funder_id);
          fundermedalslist(temp).then((res) => {
            if (res.status === true) {
              setFilterProduct(res.data);
            }
          });
        }
      });
    
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Key Highlights</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Sports Performance</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Key Highlights
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Year</label>
                    <select
                      className="form-control"
                      value={cyear}
                      onChange={(e) => listall(e.target.value, sport)}
                    >
                      <option value={0} defaultValue>
                        --- Select Year ---
                      </option>
                      {fyear.map((item, i) => {
                        return (
                          <option value={item.year} key={i}>
                            {item.year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Sports</label>
                    <select
                      className="form-control"
                      value={sport}
                      onChange={(e) => listall(cyear, e.target.value)}
                    >
                      <option value={0} defaultValue>
                        --- Select Sport ---
                      </option>
                      {slist.map((item, i) => {
                        return (
                          <option value={item.sports_cat_uid} key={i}>
                            {item.sports_category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                  domLayout="autoWidth"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
