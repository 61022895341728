import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { bloodgroupcreate, bloodgroupdetails, bloodgrouplist, bloodgroupstatus, bloodgroupupdate, bloodgroupvalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const BloodgroupMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [bgroupid, setId] = useState(0);
    const [bgroup, setBlood] = useState("");
    const [bgcode, setBCode] = useState("");
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "Blood Group",
            field: "groupname",
            sortable: true,
            resizable: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Short Code",
            field: "groupshortcode",
            sortable: true,
            resizable: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "isactive",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deleteblood(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "blooduid",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a onClick={() => viewblood(params.value)} title="Edit Blood Group"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>
                );
            },
        },
    ];

    function viewblood(aid) {
        setShow(true)
        bloodgroupdetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.blooduid)
                dotracking("edit button in blood group for " + response.data.groupname + " (" + aid + ") $$ " + trackobj.bloodlistheading)

                setBlood(response.data.groupname)
                setBCode(response.data.groupshortcode)
                setStatus(response.data.isactive + "")
            }
        })
    }

    function deleteblood(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("blooduid", list.data.blooduid)
                    temp.append("isactive", 0)
                    dotracking(
                         "status icon in blood group list from Active to Inactive for " + list.data.groupname + " (" + list.data.blooduid + ") $$ " + trackobj.bloodlistheading
                    )
                    bloodgroupstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("blooduid", list.data.blooduid)
                    temp.append("isactive", 1)
                    dotracking(
                        "status icon in blood group list from Inactive to Active for " + list.data.groupname + " (" + list.data.blooduid + ") $$ " + trackobj.bloodlistheading
                    )
                    bloodgroupstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }


    function handleshow() {
        setId(0);
        setBlood("");
        setBCode("");
        setStatus("1");
        setErrorMsg("");
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setBlood("");
        setBCode("");
        setStatus("1");
        setErrorMsg("");
    }


    useEffect(() => {
        listall();
    }, []);

    function listall() {
        setFilterProduct([]);
        bloodgrouplist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }


    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function submitblood(e) {
        e.preventDefault();
        setErrorMsg("")
        if (bgroup !== "") {
            if (bgroupid === 0) {
                const temp1 = new FormData();
                temp1.append("groupname", bgroup)
                bloodgroupvalidate(temp1).then((res) => {
                    if (res.status === true) {
                        const temp = new FormData();
                        temp.append("groupname", bgroup)
                        temp.append("groupshortcode", bgcode)
                        temp.append("isactive", status)
                        dotracking("add button in blood group for " + bgroup + " $$ " + trackobj.bloodlistheading);
                        bloodgroupcreate(temp).then((response) => {
                            if (response.status === true) {
                                handleclose();
                                listall();
                                toast.success(response.message)
                            }
                        })
                    } else {
                        Swal.fire({
                            text: bgroup + " " + res.message,
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#4ecc48",
                            cancelButtonColor: "#6259ca",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setId(0);
                                setBlood("");
                                setBCode("");
                                setStatus("1");
                                setErrorMsg("");
                            }
                        });
                    }
                })


            } else if (bgroupid !== 0) {
                const temp = new FormData();
                temp.append("blooduid", bgroupid)
                temp.append("groupname", bgroup)
                temp.append("groupshortcode", bgcode)
                temp.append("isactive", status)
                dotracking("update button in blood group for " + bgroup + " ("+ bgroupid +") $$ " + trackobj.bloodlistheading);
                bloodgroupupdate(temp).then((response) => {
                    if (response.status === true) {
                        handleclose();
                        listall();
                        toast.success(response.message)
                    }
                })
            }
        } else {
            setErrorMsg("Please Enter Blood Group")
        }
    }

    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Blood Group Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        Blood Group Lists
                    </li>
                </ol>
            </div>

            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            {localStorage.getItem("Subrole") === "1" &&
                                <div className="text-right">
                                    <a className="btn btn-success" onClick={handleshow}>
                                        <i className="fa fa-plus"></i> Add Blood Group
                                    </a>
                                </div>
                            }
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {bgroupid !== 0 &&
                                        <h4> Blood Group Details</h4>
                                    }
                                    {bgroupid === 0 &&
                                        <h4> Add Blood Group</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Blood Group Name{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Blood Group Name"
                                                    className="form-control"
                                                    value={bgroup}
                                                    onChange={(e) => setBlood(e.target.value)}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Short Code{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Short Code"
                                                    className="form-control"
                                                    value={bgcode}
                                                    onChange={(e) => setBCode(e.target.value)}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>
                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {bgroupid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitblood}
                                        >
                                            Update
                                        </a>
                                    }
                                    {bgroupid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitblood}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
