import React, { useEffect, useState } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  crmtourviewdetail,
  racquetteamplayerlistcityreg,
  schoolactivelistcityreg,
  teamdetailscityreg,
  teamplayercreatecityreg,
  teamplayerlistcityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import {
  racquetteamplayerlist,
  teamdetails,
  teamplayercreate,
  teamplayerlist,
  tourcategorydetail,
  tourcategorymapped,
  tourviewdetail,
} from "../../../../services/superadmin";
import {
  tourcategorymappedlist,
  tourclasslist,
} from "../../../../services/register";
import { classlist } from "../../../../services/master";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail, racquetteamplayerlistcoach, teamdetailscoach, teamplayercreatecoach, teamplayerlistcoach } from "../../../../services/coach";

export const TeamPlayerCreate = () => {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();

  const navigate = useNavigate();
  const [uuid, setUID] = useState("");
  const [user, setUser] = useState("");
  const [plasch, setSch] = useState("");
  const [placls, setCls] = useState("");
  const [plasec, setSec] = useState("");

  const [schlist, setSchList] = useState([]);

  const [tname, setTName] = useState("");
  const [sports, setSports] = useState("");

  const [agecat, setAgeCat] = useState("");

  const [catname, setCatname] = useState("");
  const [catgroup, setCatGroup] = useState(0);


  const [errorMsg, setErrorMsg] = useState("");

  function handleshow(index) {
    if (plarr.length !== 0) {
      for (let i = 0; i < plarr.length; i++) {
        if (index === i) {
          setUID(plarr[i].username);
          setUser(plarr[i].first_name);
          setSch(plarr[i].school_name);
          setCls(plarr[i].class_name);
          setSec(plarr[i].section);

          setShow(true);
        }
      }
    }
  }

  function handleclose() {
    setUser("");
    setSch("");
    setCls("");
    setSec("");
    setUID("");
    setShow(false);
  }
  let arr = [];

  let board = {
    columns: [
      {
        id: 1,
        cards: [],
      },
      {
        id: 2,
        cards: [],
      },
    ],
  };
  const [controlledBoard, setBoard] = useState({ ...board });
  const [selepla, SetSelePla] = useState("");
  const [team, setTeam] = useState("");
  const [schname, setSchName] = useState("");
  const [schid, setSchID] = useState("");

  const [urllink, setUrlLink] = useState("");
  let plarr = [];
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);
          setAgeCat(response.data.tournament.agecat_or_std);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              setSchList(response.data.mappedschools);
            }
          }
          loadclass(response.data.tournament.agecat_or_std);
          teamdetails(tid).then((res) => {
            if (res.status === true) {
              if (res.data.team.length !== 0) {
                setTeam(res.data.team[0].team_name);
                setStuSchool(res.data.team[0].school_id);
                setSchID(res.data.team[0].school_id);
                setSchName(res.data.team[0].school_name);
                const tempData1 = new FormData();
                tempData1.append("category_id", eid);
                tourcategorydetail(tempData1).then((res1) => {
                  if (res1.status === true) {
                    setCatname(res1.data.category_details.category_name);
                    if(res1.data.category_details.group_type !== null){
                    setCatGroup(res1.data.category_details.group_type);
                    }
                    loadplayer("",res.data.team[0].school_id,"",response.data.tournament.sports_type,res1.data.category_details.group_type);
                  }
                });
               
              }
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);

          setAgeCat(response.data.tournament.agecat_or_std);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              setSchList(response.data.mappedschools);
            }
          }
          loadclass(response.data.tournament.agecat_or_std);
          teamdetailscityreg(tid).then((res) => {
            if (res.status === true) {
              if (res.data.team.length !== 0) {
                setTeam(res.data.team[0].team_name);
                setStuSchool(res.data.team[0].school_id);
                setSchID(res.data.team[0].school_id);
                setSchName(res.data.team[0].school_name);
                const tempData1 = new FormData();
                tempData1.append("category_id", eid);
                tourcategorydetail(tempData1).then((res1) => {
                  if (res1.status === true) {
                    setCatname(res1.data.category_details.category_name);
                    if(res1.data.category_details.group_type !== null){
                      setCatGroup(res1.data.category_details.group_type);
                      }
                    loadplayer("",res.data.team[0].school_id,"",response.data.tournament.sports_type,res1.data.category_details.group_type);
                  }
                });
              }
            }
          });
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSports(response.data.tournament.sports_type);

          setAgeCat(response.data.tournament.agecat_or_std);
          if (response.data.mappedschools !== null) {
            if (response.data.mappedschools.length !== 0) {
              setSchList(response.data.mappedschools);
            }
          }
          loadclass(response.data.tournament.agecat_or_std);
          teamdetailscoach(tid).then((res) => {
            if (res.status === true) {
              if (res.data.team.length !== 0) {
                setTeam(res.data.team[0].team_name);
                setStuSchool(res.data.team[0].school_id);
                setSchID(res.data.team[0].school_id);
                setSchName(res.data.team[0].school_name);
                const tempData1 = new FormData();
                tempData1.append("category_id", eid);
                tourcategorydetail(tempData1).then((res1) => {
                  if (res1.status === true) {
                    setCatname(res1.data.category_details.category_name);
                    if(res1.data.category_details.group_type !== null){
                      setCatGroup(res1.data.category_details.group_type);
                      }
                    loadplayer("",res.data.team[0].school_id,"",response.data.tournament.sports_type,res1.data.category_details.group_type);
                  }
                });
              }
            }
          });
        }
      });

      
    }
  }, []);

  function loadplayer(cid,sch,pid,type,gtype) {
    if (localStorage.getItem("Role") === "1") {
      // if(type.toUpperCase() === "BADMINTON" ||
      // type.toUpperCase() === "TENNIS" ||
      // type.toUpperCase() === "TABLE TENNIS" ||
      // type.toUpperCase() === "SQUASH"){
      racquetteamplayerlist(id, tid, cid, eid, sch, pid,gtype).then((response) => {
        if (response.status === true && response.data !== null) {
          if (response.data.length !== 0) {
            arr = [];
            plarr = response.data;
            for (let i = 0; i < response.data.length; i++) {
              var name = response.data[i].first_name;
              if (response.data[i].last_name !== "") {
                if (response.data[i].last_name !== null) {
                  name = name + response.data[i].last_name;
                }
              }
              arr.push({
                id: response.data[i].user_id,
                title: name,
                description: (
                  <div className="row">
                    <div className="col-3 align-self-center text-center pr-0">
                      {response.data[i].gender === "Male" && (
                        <img
                          src={require("../../../../assets/images/user_male.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                      {response.data[i].gender === "Female" && (
                        <img
                          src={require("../../../../assets/images/user_female.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                    </div>

                    <div className="col-7 px-0 align-self-center">
                      <h4 className="playername mb-1">{name}</h4>

                      {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
                      <h4 className="playergender mb-0">
                        Gender : <span>{response.data[i].gender}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Class : <span>{response.data[i].class_name}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Section : <span>{response.data[i].section}</span>
                      </h4>
                    </div>

                    <div className="col-2 align-self-center">
                      <a
                        className="btn-sm btn-secondary me-2"
                        onClick={() => handleshow(i)}
                      >
                        <i className="fa fa-eye fa-lg"></i>
                      </a>
                      {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
                      <i className="fa fa-eye fa-lg"></i>
                    </a> */}
                    </div>
                  </div>
                ),
              });
            }

            if (selepla && selepla.length > 0) {
              arr = arr.filter(item => !selepla.some(selected => selected.id === item.id));
            }
            board = {
              columns: [
                {
                  id: 1,
                  cards: arr,
                },
                {
                  id: 2,
                  cards: selepla,
                },
              ],
            };
            setBoard({ ...board });
          }
        }
      });
    // }else{
    //   teamplayerlist(id, tid, cid, eid, sch, pid,"").then((response) => {
    //     if (response.status === true && response.data !== null) {
    //       if (response.data.length !== 0) {
    //         arr = [];
    //         plarr = response.data;
    //         for (let i = 0; i < response.data.length; i++) {
    //           var name = response.data[i].first_name;
    //           if (response.data[i].last_name !== "") {
    //             if (response.data[i].last_name !== null) {
    //               name = name + response.data[i].last_name;
    //             }
    //           }
    //           arr.push({
    //             id: response.data[i].user_id,
    //             title: name,
    //             description: (
    //               <div className="row">
    //                 <div className="col-3 align-self-center text-center pr-0">
    //                   {response.data[i].gender === "Male" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_male.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                   {response.data[i].gender === "Female" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_female.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                 </div>

    //                 <div className="col-7 px-0 align-self-center">
    //                   <h4 className="playername mb-1">{name}</h4>

    //                   {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
    //                   <h4 className="playergender mb-0">
    //                     Gender : <span>{response.data[i].gender}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Class : <span>{response.data[i].class_name}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Section : <span>{response.data[i].section}</span>
    //                   </h4>
    //                 </div>

    //                 <div className="col-2 align-self-center">
    //                   <a
    //                     className="btn-sm btn-secondary me-2"
    //                     onClick={() => handleshow(i)}
    //                   >
    //                     <i className="fa fa-eye fa-lg"></i>
    //                   </a>
    //                   {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
    //                   <i className="fa fa-eye fa-lg"></i>
    //                 </a> */}
    //                 </div>
    //               </div>
    //             ),
    //           });
    //         }
    //         board = {
    //           columns: [
    //             {
    //               id: 1,
    //               cards: arr,
    //             },
    //             {
    //               id: 2,
    //               cards: [],
    //             },
    //           ],
    //         };
    //         setBoard({ ...board });
    //       }
    //     }
    //   });
    // }
    } else if (localStorage.getItem("Role") === "3") {
      // if(type.toUpperCase() === "BADMINTON" ||
      // type.toUpperCase() === "TENNIS" ||
      // type.toUpperCase() === "TABLE TENNIS" ||
      // type.toUpperCase() === "SQUASH"){
      racquetteamplayerlistcityreg(id, tid, cid, eid, sch, pid,gtype).then((response) => {
        if (response.status === true && response.data !== null) {
          if (response.data.length !== 0) {
            arr = [];
            plarr = response.data;
            for (let i = 0; i < response.data.length; i++) {
              var name = response.data[i].first_name;
              if (response.data[i].last_name !== "") {
                if (response.data[i].last_name !== null) {
                  name = name + response.data[i].last_name;
                }
              }
              arr.push({
                id: response.data[i].user_id,
                title: name,
                description: (
                  <div className="row">
                    <div className="col-3 align-self-center text-center pr-0">
                      {response.data[i].gender === "Male" && (
                        <img
                          src={require("../../../../assets/images/user_male.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                      {response.data[i].gender === "Female" && (
                        <img
                          src={require("../../../../assets/images/user_female.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                    </div>

                    <div className="col-7 px-0 align-self-center">
                      <h4 className="playername mb-1">{name}</h4>

                      {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
                      <h4 className="playergender mb-0">
                        Gender : <span>{response.data[i].gender}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Class : <span>{response.data[i].class_name}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Section : <span>{response.data[i].section}</span>
                      </h4>
                    </div>

                    <div className="col-2 align-self-center">
                      <a
                        className="btn-sm btn-secondary me-2"
                        onClick={() => handleshow(i)}
                      >
                        <i className="fa fa-eye fa-lg"></i>
                      </a>
                      {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
                        <i className="fa fa-eye fa-lg"></i>
                      </a> */}
                    </div>
                  </div>
                ),
              });
            }

            if (selepla && selepla.length > 0) {
              arr = arr.filter(item => !selepla.some(selected => selected.id === item.id));
            }

            board = {
              columns: [
                {
                  id: 1,
                  cards: arr,
                },
                {
                  id: 2,
                  cards: selepla,
                },
              ],
            };
            setBoard({ ...board });
          }
        }
      });
    // }else{
    //   teamplayerlistcityreg(id, tid, cid, eid, sch, pid,"").then((response) => {
    //     if (response.status === true && response.data !== null) {
    //       if (response.data.length !== 0) {
    //         arr = [];
    //         plarr = response.data;
    //         for (let i = 0; i < response.data.length; i++) {
    //           var name = response.data[i].first_name;
    //           if (response.data[i].last_name !== "") {
    //             if (response.data[i].last_name !== null) {
    //               name = name + response.data[i].last_name;
    //             }
    //           }
    //           arr.push({
    //             id: response.data[i].user_id,
    //             title: name,
    //             description: (
    //               <div className="row">
    //                 <div className="col-3 align-self-center text-center pr-0">
    //                   {response.data[i].gender === "Male" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_male.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                   {response.data[i].gender === "Female" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_female.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                 </div>

    //                 <div className="col-7 px-0 align-self-center">
    //                   <h4 className="playername mb-1">{name}</h4>

    //                   {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
    //                   <h4 className="playergender mb-0">
    //                     Gender : <span>{response.data[i].gender}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Class : <span>{response.data[i].class_name}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Section : <span>{response.data[i].section}</span>
    //                   </h4>
    //                 </div>

    //                 <div className="col-2 align-self-center">
    //                   <a
    //                     className="btn-sm btn-secondary me-2"
    //                     onClick={() => handleshow(i)}
    //                   >
    //                     <i className="fa fa-eye fa-lg"></i>
    //                   </a>
    //                   {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
    //                     <i className="fa fa-eye fa-lg"></i>
    //                   </a> */}
    //                 </div>
    //               </div>
    //             ),
    //           });
    //         }
    //         board = {
    //           columns: [
    //             {
    //               id: 1,
    //               cards: arr,
    //             },
    //             {
    //               id: 2,
    //               cards: [],
    //             },
    //           ],
    //         };
    //         setBoard({ ...board });
    //       }
    //     }
    //   });
    // }
    }else if (localStorage.getItem("Role") === "7") {
      // if(type.toUpperCase() === "BADMINTON" ||
      // type.toUpperCase() === "TENNIS" ||
      // type.toUpperCase() === "TABLE TENNIS" ||
      // type.toUpperCase() === "SQUASH"){
      racquetteamplayerlistcoach(id, tid, cid, eid, sch, pid,gtype).then((response) => {
        if (response.status === true && response.data !== null) {
          if (response.data.length !== 0) {
            arr = [];
            plarr = response.data;
            for (let i = 0; i < response.data.length; i++) {
              var name = response.data[i].first_name;
              if (response.data[i].last_name !== "") {
                if (response.data[i].last_name !== null) {
                  name = name + response.data[i].last_name;
                }
              }
              arr.push({
                id: response.data[i].user_id,
                title: name,
                description: (
                  <div className="row">
                    <div className="col-3 align-self-center text-center pr-0">
                      {response.data[i].gender === "Male" && (
                        <img
                          src={require("../../../../assets/images/user_male.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                      {response.data[i].gender === "Female" && (
                        <img
                          src={require("../../../../assets/images/user_female.png")}
                          className="header-brand-img mobile-view-logo"
                          alt="player logo"
                        />
                      )}
                    </div>

                    <div className="col-7 px-0 align-self-center">
                      <h4 className="playername mb-1">{name}</h4>

                      {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
                      <h4 className="playergender mb-0">
                        Gender : <span>{response.data[i].gender}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Class : <span>{response.data[i].class_name}</span>
                      </h4>
                      <h4 className="playergender mb-0">
                        Section : <span>{response.data[i].section}</span>
                      </h4>
                    </div>

                    <div className="col-2 align-self-center">
                      <a
                        className="btn-sm btn-secondary me-2"
                        onClick={() => handleshow(i)}
                      >
                        <i className="fa fa-eye fa-lg"></i>
                      </a>
                      {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
                        <i className="fa fa-eye fa-lg"></i>
                      </a> */}
                    </div>
                  </div>
                ),
              });
            }

            if (selepla && selepla.length > 0) {
              arr = arr.filter(item => !selepla.some(selected => selected.id === item.id));
            }

            board = {
              columns: [
                {
                  id: 1,
                  cards: arr,
                },
                {
                  id: 2,
                  cards: selepla,
                },
              ],
            };
            setBoard({ ...board });
          }
        }
      });
    // }else{
    //   teamplayerlistcoach(id, tid, cid, eid, sch, pid,"").then((response) => {
    //     if (response.status === true && response.data !== null) {
    //       if (response.data.length !== 0) {
    //         arr = [];
    //         plarr = response.data;
    //         for (let i = 0; i < response.data.length; i++) {
    //           var name = response.data[i].first_name;
    //           if (response.data[i].last_name !== "") {
    //             if (response.data[i].last_name !== null) {
    //               name = name + response.data[i].last_name;
    //             }
    //           }
    //           arr.push({
    //             id: response.data[i].user_id,
    //             title: name,
    //             description: (
    //               <div className="row">
    //                 <div className="col-3 align-self-center text-center pr-0">
    //                   {response.data[i].gender === "Male" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_male.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                   {response.data[i].gender === "Female" && (
    //                     <img
    //                       src={require("../../../../assets/images/user_female.png")}
    //                       className="header-brand-img mobile-view-logo"
    //                       alt="player logo"
    //                     />
    //                   )}
    //                 </div>

    //                 <div className="col-7 px-0 align-self-center">
    //                   <h4 className="playername mb-1">{name}</h4>

    //                   {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
    //                   <h4 className="playergender mb-0">
    //                     Gender : <span>{response.data[i].gender}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Class : <span>{response.data[i].class_name}</span>
    //                   </h4>
    //                   <h4 className="playergender mb-0">
    //                     Section : <span>{response.data[i].section}</span>
    //                   </h4>
    //                 </div>

    //                 <div className="col-2 align-self-center">
    //                   <a
    //                     className="btn-sm btn-secondary me-2"
    //                     onClick={() => handleshow(i)}
    //                   >
    //                     <i className="fa fa-eye fa-lg"></i>
    //                   </a>
    //                   {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
    //                     <i className="fa fa-eye fa-lg"></i>
    //                   </a> */}
    //                 </div>
    //               </div>
    //             ),
    //           });
    //         }
    //         board = {
    //           columns: [
    //             {
    //               id: 1,
    //               cards: arr,
    //             },
    //             {
    //               id: 2,
    //               cards: [],
    //             },
    //           ],
    //         };
    //         setBoard({ ...board });
    //       }
    //     }
    //   });
    // }
    }
  }
  const [sclist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");

  function loadclass(lid) {
    if (lid === 1) {
      const tempData = new FormData();
      tempData.append("tournament_id", id);
      tempData.append("category_id", eid);
      tourcategorymappedlist(tempData).then((res) => {
        if (res.status === true) {
          let arr = [];
          if (res.data.category_mapped_list.length > 0) {
            for (let i = 0; i < res.data.category_mapped_list.length; i++) {
              arr.push({
                id: res.data.category_mapped_list[i].class_id,
                class:
                  res.data.category_mapped_list[i].class_or_age_category_name,
              });
            }
            setClassList(arr);
          }
        }
      });
    } else if (lid === 2) {
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("category_id", eid);
      tourcategorymapped(temp).then((response) => {
        if (response.status === true) {
          setClassList(response.data.category_mapped_list);
        }
      });

      // classlist().then((response) => {
      //   if (response.status === true) {
      //     setClassList(response.data);
      //   }
      // });
    }
  }
  function selectclass(e) {
    setStuClass(e.target.value);
    board = {
      columns: [
        {
          id: 1,
          cards: [],
        },
        {
          id: 2,
          cards: [],
        },
      ],
    };
    setBoard({ ...board });
    filterdata(e.target.value, stuschool, plyname);
  }

  const [stuschool, setStuSchool] = useState("");
  const [plyname, setPlyName] = useState("");

  function selectschool(e) {
    setStuSchool(e.target.value);
    board = {
      columns: [
        {
          id: 1,
          cards: [],
        },
        {
          id: 2,
          cards: [],
        },
      ],
    };
    setBoard({ ...board });
    filterdata(stuclass, e.target.value, plyname);
  }

  function selectplayer(e) {
    setPlyName(e.target.value);
    board = {
      columns: [
        {
          id: 1,
          cards: [],
        },
        {
          id: 2,
          cards: [],
        },
      ],
    };
    setBoard({ ...board });
    filterdata(stuclass, stuschool, e.target.value);
  }

  function filterdata(cls, sch, pname) {
    if (localStorage.getItem("Role") === "1") {
      loadplayer(cls,sch,pname,sports,catgroup);
      // teamplayerlist(id, tid, cls, eid, sch, pname).then((response) => {
      //   if (response.status === true && response.data !== null) {
      //     if (response.data.length !== 0) {
      //       arr = [];
      //       plarr = response.data;
      //       for (let i = 0; i < response.data.length; i++) {
      //         var name = response.data[i].first_name;
      //         if (response.data[i].last_name !== "") {
      //           if (response.data[i].last_name !== null) {
      //             name = name + response.data[i].last_name;
      //           }
      //         }
      //         arr.push({
      //           id: response.data[i].user_id,
      //           title: name,
      //           description: (
      //             <div className="row">
      //               <div className="col-3 align-self-center text-center pr-0">
      //                 {response.data[i].gender === "Male" && (
      //                   <img
      //                     src={require("../../../../assets/images/user_male.png")}
      //                     className="header-brand-img mobile-view-logo"
      //                     alt="player logo"
      //                   />
      //                 )}
      //                 {response.data[i].gender === "Female" && (
      //                   <img
      //                     src={require("../../../../assets/images/user_female.png")}
      //                     className="header-brand-img mobile-view-logo"
      //                     alt="player logo"
      //                   />
      //                 )}
      //               </div>

      //               <div className="col-7 px-0 align-self-center">
      //                 <h4 className="playername mb-1">{name}</h4>

      //                 {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
      //                 <h4 className="playergender mb-0">
      //                   Gender : <span>{response.data[i].gender}</span>
      //                 </h4>
      //                 <h4 className="playergender mb-0">
      //                   Class : <span>{response.data[i].class_name}</span>
      //                 </h4>
      //                 <h4 className="playergender mb-0">
      //                   Section : <span>{response.data[i].section}</span>
      //                 </h4>
      //               </div>

      //               <div className="col-2 align-self-center">
      //                 <a
      //                   className="btn-sm btn-secondary me-2"
      //                   onClick={() => handleshow(i)}
      //                 >
      //                   <i className="fa fa-eye fa-lg"></i>
      //                 </a>
      //                 {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
      //                     <i className="fa fa-eye fa-lg"></i>
      //                   </a> */}
      //               </div>
      //             </div>
      //           ),
      //         });
      //       }
      //       board = {
      //         columns: [
      //           {
      //             id: 1,
      //             cards: arr,
      //           },
      //           {
      //             id: 2,
      //             cards: [],
      //           },
      //         ],
      //       };
      //       setBoard({ ...board });
      //     }
      //   }
      // });
    } else if (localStorage.getItem("Role") === "3") {
      loadplayer(cls,sch,pname,sports,catgroup);
      // teamplayerlistcityreg(id, tid, cls, eid, sch, pname).then((response) => {
      //   if (response.status === true && response.data !== null) {
      //     if (response.data.length !== 0) {
      //       arr = [];
      //       plarr = response.data;
      //       for (let i = 0; i < response.data.length; i++) {
      //         var name = response.data[i].first_name;
      //         if (response.data[i].last_name !== "") {
      //           if (response.data[i].last_name !== null) {
      //             name = name + response.data[i].last_name;
      //           }
      //         }
      //         arr.push({
      //           id: response.data[i].user_id,
      //           title: name,
      //           description: (
      //             <div className="row">
      //               <div className="col-3 align-self-center text-center pr-0">
      //                 {response.data[i].gender === "Male" && (
      //                   <img
      //                     src={require("../../../../assets/images/user_male.png")}
      //                     className="header-brand-img mobile-view-logo"
      //                     alt="player logo"
      //                   />
      //                 )}
      //                 {response.data[i].gender === "Female" && (
      //                   <img
      //                     src={require("../../../../assets/images/user_female.png")}
      //                     className="header-brand-img mobile-view-logo"
      //                     alt="player logo"
      //                   />
      //                 )}
      //               </div>

      //               <div className="col-7 px-0 align-self-center">
      //                 <h4 className="playername mb-1">{name}</h4>

      //                 {/* <h4 className="playeruid mb-1">UID : <span>{response.data[i].username}</span></h4> */}
      //                 <h4 className="playergender mb-0">
      //                   Gender : <span>{response.data[i].gender}</span>
      //                 </h4>
      //                 <h4 className="playergender mb-0">
      //                   Class : <span>{response.data[i].class_name}</span>
      //                 </h4>
      //                 <h4 className="playergender mb-0">
      //                   Section : <span>{response.data[i].section}</span>
      //                 </h4>
      //               </div>

      //               <div className="col-2 align-self-center">
      //                 <a
      //                   className="btn-sm btn-secondary me-2"
      //                   onClick={() => handleshow(i)}
      //                 >
      //                   <i className="fa fa-eye fa-lg"></i>
      //                 </a>
      //                 {/* <a className="btn-sm btn-secondary me-2" onClick={() => handleshow(name, response.data[i].school_name, response.data[i].class_name, response.data[i].section)}>
      //                     <i className="fa fa-eye fa-lg"></i>
      //                   </a> */}
      //               </div>
      //             </div>
      //           ),
      //         });
      //       }
      //       board = {
      //         columns: [
      //           {
      //             id: 1,
      //             cards: arr,
      //           },
      //           {
      //             id: 2,
      //             cards: [],
      //           },
      //         ],
      //       };
      //       setBoard({ ...board });
      //     }
      //   }
      // });
    }else if (localStorage.getItem("Role") === "7") {
      loadplayer(cls,sch,pname,sports,catgroup);
    }
  }
  function backlist() {
    dotracking(
      "back button  in team player create for " +
        team +
        " (" +
        tid +
        ") team in " +
        catname +
        " (" +
        eid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamplayercreate") {
        navigate("/superadmin/teamlist/" + id + "/" + eid);
      } else {
        navigate("/superadmin/interteamlist/" + id + "/" + eid + "/" + schid);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamlist/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/teamlist/" + id + "/" + eid);
    }
  }

  function playersubmit(e) {
    e.preventDefault();
    if (selepla.length !== 0) {
      const temp = new FormData();
      temp.append("team_id", tid);
      temp.append("category_id", eid);
      temp.append("tournament_meet_id", id);
      temp.append("school_id", schid);
      temp.append("total_players_count", selepla.length);
      temp.append("group_type", catgroup);

      for (let i = 0; i < selepla.length; i++) {
        temp.append("player_id_" + [i + 1], selepla[i].id);
      }
      dotracking(
        "submit button in team player create for " +
          team +
          " (" +
          tid +
          ") team in " +
          catname +
          " (" +
          eid +
          ") category for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.teamlistheading
      );
      if (localStorage.getItem("Role") === "1") {
        teamplayercreate(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/superadmin/teamlist/" + id + "/" + eid);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        teamplayercreatecityreg(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/cityregional/teamlist/" + id + "/" + eid);
          } else {
            setErrorMsg(response.message);
          }
        });
      }else if (localStorage.getItem("Role") === "7") {
        teamplayercreatecoach(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/coach/teamlist/" + id + "/" + eid);
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    } else {
      toast.error("Please Select the Player");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Create Team Members</h4>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Team</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Create Team Members
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="text-center">
                  <h3>{tname}</h3>
                  <h4 className="text-warning tx-20">Category - {catname}</h4>
                  <h4 className="errortext tx-20">School - {schname}</h4>
                  <h4 className="text-success tx-20">Team - {team}</h4>
                </div>
                {/* <div className="col-md-4">
                  <label className="form-label">School</label>

                  <select
                    value={stuschool}
                    onChange={selectschool}
                    className="form-control"
                  >
                    <option value="">--- Select School ---</option>
                    {schlist.map((data, i) => {
                      return (
                        <option value={data.school_id} key={i}>
                          {data.school_name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                {agecat === 1 && (
                  <div className="col-md-4">
                    <label className="form-label">Class</label>
                    <select
                      value={stuclass}
                      onChange={selectclass}
                      className="form-control"
                    >
                      <option value="">--- Select Class ---</option>
                      {sclist.map((data, i) => {
                        return (
                          <option value={data.id} key={i}>
                            {data.class}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {agecat === 2 && (
                  <div className="col-md-4">
                    <label className="form-label">Age Category</label>
                    <select
                      // value={stuclass}
                      // onChange={selectclass}
                      className="form-control"
                    >
                      <option value="">--- Select Age Category ---</option>
                      {sclist.map((data, i) => {
                        return (
                          <option value={data.id} key={i}>
                            {data.class_or_age_category_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                <div className="col-md-4">
                  <label className="form-label">Players</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Player Name"
                    value={plyname}
                    onChange={selectplayer}
                  />
                </div>
                {/* <div className="col-md-4">
                  <a className="btn btn-success" onClick={filterdata}>Filter</a>
                </div> */}
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <h4>All players</h4>
                </div>
                <div className="col-md-6">
                  <h4>Selected players</h4>
                </div>
              </div>
              <ControlledBoard />

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
              <div className="card-footer text-center border-top">
                <button className="btn btn-gray me-2" onClick={backlist}>
                  Back
                </button>
                <button className="btn btn-success me-2" onClick={playersubmit}>
                  Submit
                </button>
              </div>
              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  <h4> Player Details</h4>
                </Modal.Header>

                <Modal.Body>
                  <div className="row tournamentitem">
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <b className="text-inverse">UID</b>
                          </div>
                          <div className="col-md-8 col-md-6">
                            <span className="text-inverse">{uuid}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <b className="text-inverse">Name</b>
                          </div>
                          <div className="col-md-8 col-md-6">
                            <span className="text-inverse">{user}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <b className="text-inverse">School</b>
                          </div>
                          <div className="col-md-8 col-md-6">
                            <span className="text-inverse">{plasch}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <b className="text-inverse">Class</b>
                          </div>
                          <div className="col-md-8 col-md-6">
                            <span className="text-inverse">{placls}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <b className="text-inverse">Section</b>
                          </div>
                          <div className="col-md-8 col-md-6">
                            <span className="text-inverse">{plasec}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  {/* <a className="btn btn-success">Add</a> */}

                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  function ControlledBoard() {
    const boardStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
  /* Responsive styles */
    };

    

    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
      SetSelePla(updatedBoard.columns[1].cards);
    }
    return (
      <div style={boardStyle}>
      <Board
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        children={controlledBoard}
      />
    </div>
    );
  }
};
