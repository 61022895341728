import React, { useEffect, useState } from "react";
import {
  Playerprofiledetails,
  playeroverallperformancer,
} from "../../../../services/player";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import exportUrl from "../../../../exportUtl";

export const Performancebyplayer = () => {
  const { id } = useParams();
  const [pladet, setPladet] = useState("");
  const [filterProduct, setFilterProduct] = useState([]);
  const [sportProduct, setSportProduct] = useState([]);
  const [sports, setSports] = useState("");
  const [sportsid, setSportsID] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "6") {
      Playerprofiledetails(localStorage.getItem("Name")).then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("user_id", res.data.athletelist.user_id);
          playeroverallperformancer(temp).then((response) => {
            if (response.status === true) {
              if (response.data.length > 0) {
                setPladet(response.data[0].player_details);
                setFilterProduct(response.data[0].all_data);
                if (response.data[0].all_data.length > 0) {
                  setSportsID(response.data[0].all_data[0].spots_id);
                  setSports(response.data[0].all_data[0].spots_name);
                  if (response.data[0].all_data[0].criteria_data.length !== 0) {
                    setSportProduct(response.data[0].all_data[0].criteria_data);
                  }
                }
              }
            }
          });
        }
      });
    } else {
      const temp = new FormData();
      temp.append("user_id", id);
      playeroverallperformancer(temp).then((response) => {
        if (response.status === true) {
          if (response.data.length > 0) {
            setPladet(response.data[0].player_details);
            setFilterProduct(response.data[0].all_data);
            if (response.data[0].all_data.length > 0) {
              setSportsID(response.data[0].all_data[0].spots_id);
              setSports(response.data[0].all_data[0].spots_name);
              if (response.data[0].all_data[0].criteria_data.length !== 0) {
                setSportProduct(response.data[0].all_data[0].criteria_data);
              }
            }
          }
        }
      });
    }
  }, []);

  function daywise(tspt) {
    setSportProduct([]);
    setSports(tspt);
    if (filterProduct.length > 0) {
      const temp = filterProduct.find((item) => item.spots_name === tspt);
      if (temp !== undefined) {
        setSportsID(temp.spots_id);
        setSportProduct(temp.criteria_data);
      }
    }
  }

  const downloadPDF = () => {
    dotracking(
      "download PDF button in overall performance for " +
        pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        pladet.id +
        ") player $$ " +
        trackobj.overperfheading
    );

    const pdf = new jsPDF();
    const content = document.getElementById("pdf-content");
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save(pladet.first_name + " Overall Performance.pdf");
    });
  };

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in player performance for " +
        pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        id +
        ") $$ " +
        trackobj.playerdetheading
    );
    if (localStorage.getItem("PID") === "1") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/viewplayer/" + id);
      }
    } else if (localStorage.getItem("PID") === "2") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/tournamentplayer/" + id);
      }
    } else {
      navigate(-1);
    }
  }

  function perflist() {
    dotracking(
      "tournament wise performance button in view player for " +
        pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        pladet.id +
        ") $$ " +
        trackobj.tourperfheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/viewperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/viewperformanceplayer");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/viewperformanceplayer/" + id);
    }
  }

  function exportPDF(sportsid) {
    dotracking(
      "export button in overall performance for " +
        pladet.first_name +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.overperfheading
    );
    window.open(
      exportUrl + "export-overall-performance-by-sports/" + sportsid + "/" + id,
      "_blank"
    );
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Overall Player Performance</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Player</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Overall Player Performance
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <div className="text-right">
            <a onClick={perflist} className="btn btn-secondary me-2">
              Tournament Wise Performance
            </a>

            {/* <a onClick={downloadPDF} className="btn btn-export me-2">
            <i className="fa fa-file-pdf"></i> Download PDF
            </a> */}
            <a onClick={backlist} className="btn btn-gray">
              <i className="fe fe-corner-up-left"></i> Back
            </a>
          </div>
        </div>
      </div>
      <div className="row" id="pdf-content">
        <div className="respef col-5">
          <form className="card mt-0">
            <div className="card-body">
              {/* <h4>Personal Detail</h4> */}
              <div id="playerlist">
                <div className="box__head">
                  <h1>{pladet.first_name}</h1>
                </div>

                <div className="row box__content">
                  <h2>
                    <b>Player UID</b> <span>{pladet.username}</span>
                  </h2>
                  <h2>
                    <b>Gender</b> <span>{pladet.gender}</span>
                  </h2>
                  <h2>
                    <b>Date of Birth</b>{" "}
                    <span>
                      {moment(pladet.date_of_birth).format("DD-MM-YYYY")}
                    </span>
                  </h2>
                  <h2>
                    <b>Email</b> <span>{pladet.email_id}</span>
                  </h2>
                  <h2>
                    <b>Contact</b> <span>{pladet.mobile_no}</span>
                  </h2>
                  <h2>
                    <b>Class</b> <span>{pladet.class_name}</span>
                  </h2>
                  <h2>
                    <b>Section</b> <span>{pladet.section}</span>
                  </h2>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-7">
          <Tabs
            activeKey={sports}
            // defaultActiveKey={sports}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={daywise}
          >
            {filterProduct.map((data, i) => {
              return (
                <Tab eventKey={data.spots_name} title={data.spots_name} key={i}>
                  <div className="card mt-0">
                    <div className="card-body">
                      {sportProduct.length === 0 && (
                        <h4 className="errortext">
                          Performance will be updated soon
                        </h4>
                      )}
                      {sportProduct.length !== 0 && (
                        <>
                        <div className="text-right">
                          <a
                            onClick={()=>exportPDF(sportsid)}
                            className="btn btn-export me-2"
                          >
                            <i className="fa-solid fa-file-export"></i> Export
                          </a>
                          </div>
                          <div className="table-responsive">
                            <table className="sp-champion-table sp-data-table">
                              <thead className="sp-table-header">
                                <tr role="row">
                                  <th>Criteria</th>
                                  <th>Performance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sportProduct.map((item, j) => {
                                  const getRowColor = () => {
                                    return j % 2 === 0 ? "even" : "odd";
                                  };
                                  return (
                                    <>
                                      <tr
                                        className={`sp-row-no-0 ${getRowColor()}`}
                                        key={j}
                                      >
                                        <td className="data-pct">
                                          {item.criteria_name}
                                        </td>

                                        {item.criteria_sum === null && (
                                          <td className="data-pct">-</td>
                                        )}
                                        {item.criteria_sum !== null && (
                                          <td className="data-pct">
                                            {item.criteria_sum}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
          {/* <h4>Performance Detail</h4> */}
        </div>
      </div>
    </div>
  );
};
