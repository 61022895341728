import React, { Fragment } from "react";

export const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-12 col-sm-12 text-center footers" >
              Copyright © {new Date().getFullYear()} <b><a className="text-danger">Student Athlete Hub.</a> </b>Designed by{" "}
              <b><a className="text-danger" href="https://sportingindia.tech/" target="_blank">SportingIndia</a> </b>All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};
