import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  schedulelist,
  schedulenoknocklist,
  tourcategorydetail,
  tourcategorylist,
  tourviewdetail,
} from "../../../../services/superadmin";
import moment from "moment";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import { Reftourdetail } from "../../../../services/referee";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../services/coach";
import { tourdetails } from "../../../../services/register";
export const ResultScheduleList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [medalProduct, setMedalProduct] = useState([]);

  const [wlist, setWList] = useState(false);

  const navigate = useNavigate();
  const [tname, setTName] = useState("");

  const [ulink, setULink] = useState("");

  const { id } = useParams();
  const { eid } = useParams();

  const [clslist, setClsList] = useState([]);
  const [clsid, setClsID] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }

    const temp = new FormData();
    temp.append("tournament_id", id);
    tourcategorylist(temp).then((response) => {
      if (response.status === true) {
        setClsList(response.data.category_list);
      }
    });
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setULink(link[1]);
    if (link[1] === "results") {
      selectcat(0, link[1]);
    } else {
      selectcat(eid, link[1]);
    }
  }, []);

  function backlist() {
    if (ulink === "results") {
      if (localStorage.getItem("Role") !== "4"){
        dotracking("back button in " + ulink + " for " + tname + " (" + id + ") tournament $$ " + trackobj.tourlistheading);

      }
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentlist");
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentlist");
      } else if (localStorage.getItem("Role") === "4") {
        if (localStorage.getItem("Page") === "1") {
          dotracking("back button in " + ulink + " for " + tname + " (" + id + ") tournament $$ " + trackobj.tourlistheading);
          navigate("/schoolhead/tournamentlist");
        }else if (localStorage.getItem("Page") === "3") {
          dotracking("back button in " + ulink + " for " + tname + " (" + id + ") tournament $$ " + trackobj.comtourlistheading);
          navigate("/schoolhead/completedtournament");
        }
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/tournamentlist");
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/tournamentlist");
      } else if (localStorage.getItem("Role") === "8") {
        navigate("/funder/tournamentlist");
      } 
    } else {
    dotracking("back button in " + ulink + " for " + tname + " (" + id + ") tournament $$ " + trackobj.categorylistheading);

      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/categorylist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/categorylist/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/categorylist/" + id);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/categorylist/" + id);
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/categorylist/" + id);
      }
    }
  }

  let arr = [];
  let arr1 = [];
  const [noknock, setNoKnock] = useState(false);
  const [knocklist, setKnocklist] = useState([]);
  const [catname, setCatname] = useState("");

  function selectcat(sid, uname) {
    setFilterProduct([]);
    setMedalProduct([]);
    setWList(false);
    setNoKnock(false);
    setKnocklist([]);
    setClsID(sid);
    setCatname("");
    arr = [];
    arr1 = [];
    if (uname !== "results") {
      const tempData = new FormData();
      tempData.append("category_id", sid);
      tourcategorydetail(tempData).then((res) => {
        if (res.status === true) {
          setCatname(res.data.category_details.category_name);
        }
      });
    }
    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("category_id", sid);
    schedulenoknocklist(temp).then((response) => {
      if (response.status === true) {
        if (response.data.no_knockout_data.length !== 0) {
          setNoKnock(true);
          setKnocklist(response.data.no_knockout_data);
        }
      }
    });

    schedulelist(id, sid).then((response) => {
      if (response.status === true) {
        if (response.data.data_fixtures.length !== 0) {
          for (let i = 0; i < response.data.data_fixtures.length; i++) {
            if (response.data.data_fixtures[i].match_round !== 4) {
              arr.push(response.data.data_fixtures[i]);
            }
            if (response.data.data_fixtures[i].match_round === 4) {
              arr1.push(response.data.data_fixtures[i]);
              if (response.data.data_fixtures[i].winner_name !== null) {
                setWList(true);
              }
            }
          }

          setFilterProduct(arr);
          setMedalProduct(arr1);
        }
      }
    });
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Result Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Result Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <h3>{tname}</h3>
                    {ulink !== "results" && (
                      <h4 className="text-success">Category: {catname}</h4>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="text-right">
                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
                {ulink === "results" && (
                  <div className="row border-bottom">
                    <div className="col-md-4">
                      <form>
                        <div className="form-group">
                          <label className="font-weight-bold">
                            Filter by Category
                          </label>
                          <select
                            className="form-control bg-white"
                            value={clsid}
                            onChange={(e) => selectcat(e.target.value, ulink)}
                          >
                            <option value={0}> --- Select Category --- </option>
                            {clslist.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {" "}
                                  {item.category_name}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {wlist && (
                  <div id="playerlist">
                    <div id="winners">
                      <div className="before"></div>
                      <div className="after"></div>
                      {medalProduct.map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="row mt-5">
                              {ulink === "results" && (
                                <h4 className="text-success">
                                  Category - {item.category_name}
                                </h4>
                              )}

                              <div className="col-md-4">
                                <div className="card__content grid">
                                  <div className="card__pricing">
                                    <img
                                      src={require("../../../../assets/images/goldcup.png")}
                                      alt=""
                                      className="card__headercup-img"
                                    />
                                  </div>
                                  <header className="card__header">
                                    <div className="card__header-circle grid">
                                      <img
                                        src={require("../../../../assets/images/gold.png")}
                                        alt=""
                                        className="card__header-img"
                                      />
                                    </div>
                                    <h1 className="card__header-title">
                                      {item.winner_name}
                                    </h1>
                                  </header>
                                  <ul className="card__list grid">
                                    <li className="card__list-item gold">
                                      <p className="card__list-description text-white">
                                        <b>Place :</b> Gold Medal
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="card__content grid">
                                  <div className="card__pricing">
                                    <img
                                      src={require("../../../../assets/images/silvercup.png")}
                                      alt=""
                                      className="card__headercup-img"
                                    />
                                  </div>
                                  <header className="card__header">
                                    <div className="card__header-circle grid">
                                      <img
                                        src={require("../../../../assets/images/silver.png")}
                                        alt=""
                                        className="card__header-img"
                                      />
                                    </div>
                                    {item.winner_id === item.team1_id && (
                                      <h1 className="card__header-title">
                                        {item.team2_name}
                                      </h1>
                                    )}
                                    {item.winner_id === item.team2_id && (
                                      <h1 className="card__header-title">
                                        {item.team1_name}
                                      </h1>
                                    )}
                                  </header>
                                  <ul className="card__list grid">
                                    <li className="card__list-item silver">
                                      <p className="card__list-description">
                                        <b>Place :</b> Silver Medal
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-4"></div>
                              {/* <div className="col-md-4">
                                <div className="card__content grid">
                                  <div className="card__pricing">
                                    <img
                                      src={require("../../../../assets/images/bronzecup.png")}
                                      alt=""
                                      className="card__headercup-img"
                                    />
                                  </div>
                                  <header className="card__header">
                                    <div className="card__header-circle grid">
                                      <img
                                        src={require("../../../../assets/images/bronze.png")}
                                        alt=""
                                        className="card__header-img"
                                      />
                                    </div>
                                    <h1 className="card__header-title">
                                      {item.bronze_team_name}
                                    </h1>
                                  </header>
                                  <ul className="card__list grid">
                                    <li className="card__list-item bronze">
                                      <p className="card__list-description text-white">
                                        <b>Place :</b> Bronze Medal
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div> */}
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {noknock && (
                  <div id="playerlist">
                    <div id="winners">
                      <div className="before"></div>
                      <div className="after"></div>
                      {knocklist.map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="row mt-5">
                              {ulink === "results" && (
                                <h4 className="text-success">
                                  Category - {item.category_name}
                                </h4>
                              )}

                              {item.winner_team_id !== 0 && (
                                <div className="col-md-4">
                                  <div className="card__content grid">
                                    <div className="card__pricing">
                                      <img
                                        src={require("../../../../assets/images/goldcup.png")}
                                        alt=""
                                        className="card__headercup-img"
                                      />
                                    </div>
                                    <header className="card__header">
                                      <div className="card__header-circle grid">
                                        <img
                                          src={require("../../../../assets/images/gold.png")}
                                          alt=""
                                          className="card__header-img"
                                        />
                                      </div>
                                      <h1 className="card__header-title">
                                        {item.winner_team_name}
                                      </h1>
                                    </header>
                                    <ul className="card__list grid">
                                      <li className="card__list-item gold">
                                        <p className="card__list-description text-white">
                                          <b>Place :</b> Gold Medal
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              {item.runner_team_id !== 0 && (
                                <div className="col-md-4">
                                  <div className="card__content grid">
                                    <div className="card__pricing">
                                      <img
                                        src={require("../../../../assets/images/silvercup.png")}
                                        alt=""
                                        className="card__headercup-img"
                                      />
                                    </div>
                                    <header className="card__header">
                                      <div className="card__header-circle grid">
                                        <img
                                          src={require("../../../../assets/images/silver.png")}
                                          alt=""
                                          className="card__header-img"
                                        />
                                      </div>
                                        <h1 className="card__header-title">
                                          {item.runner_team_name}
                                        </h1>
                                    </header>
                                    <ul className="card__list grid">
                                      <li className="card__list-item silver">
                                        <p className="card__list-description">
                                          <b>Place :</b> Silver Medal
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              {item.two_runner_team_id !== 0 && (
                                <div className="col-md-4">
                                  <div className="card__content grid">
                                    <div className="card__pricing">
                                      <img
                                        src={require("../../../../assets/images/bronzecup.png")}
                                        alt=""
                                        className="card__headercup-img"
                                      />
                                    </div>
                                    <header className="card__header">
                                      <div className="card__header-circle grid">
                                        <img
                                          src={require("../../../../assets/images/bronze.png")}
                                          alt=""
                                          className="card__header-img"
                                        />
                                      </div>
                                      <h1 className="card__header-title">
                                        {item.two_runner_team_name}
                                      </h1>
                                    </header>
                                    <ul className="card__list grid">
                                      <li className="card__list-item bronze">
                                        <p className="card__list-description text-white">
                                          <b>Place :</b> Bronze Medal
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </form>
            <form className="card mt-0 bg-none">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <h3>Results</h3>
                  </div>
                </div>
                <div className="row">
                  {filterProduct.length === 0 && (
                    <div className="col-md-11 mx-auto">
                      <div className="box">
                        <div className="box__content">
                          <h1 className="text-center">No Results Found</h1>
                        </div>
                      </div>
                    </div>
                  )}

                  {filterProduct.map((data, i) => {
                    return (
                      <div className="col-md-11 mx-auto" key={i}>
                        <div className="match">
                          <div className="match-header">
                            <div className="match-tournament">
                              <img
                                src={require("../../../../assets/images/results/tournament_name.png")}
                                alt="profile-user"
                                className=""
                              />
                              {tname}
                            </div>
                            <div className="match-actions">
                              <div className="rounddetails">
                                {data.match_round === 1 && (
                                  <i className="material-icons-outlined">
                                    League
                                  </i>
                                )}
                                {data.match_round === 2 && (
                                  <i className="material-icons-outlined">
                                    QuaterFinal
                                  </i>
                                )}
                                {data.match_round === 3 && (
                                  <i className="material-icons-outlined">
                                    SemiFinal
                                  </i>
                                )}
                                {data.match_round === 5 && (
                                  <i className="material-icons-outlined">
                                    Final
                                  </i>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="match-content">
                            <div className="column">
                              <div className="team team--home">
                                <div className="team-logo">
                                  <img
                                    src={require("../../../../assets/images/results/team.png")}
                                    alt="profile-user"
                                    className=""
                                  />
                                </div>
                                <h2 className="team-name">{data.team1_name}</h2>
                              </div>
                            </div>
                            <div className="column">
                              <div className="match-details">
                                {ulink === "results" && (
                                  <h4 className="text-success">
                                    Category: {data.category_name}
                                  </h4>
                                )}

                                <div className="match-date">
                                  <strong>
                                    {data.match_date !== null && (
                                      <>
                                        {moment(data.match_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </>
                                    )}
                                  </strong>
                                </div>
                                <div className="match-score">
                                  <span className="match-score-number match-score-number--leading">
                                    {data.team1_score} - {data.team2_score}
                                  </span>
                                </div>
                                {/* <div className="match-referee">
                                  Age Category: <strong>{data.category}</strong>
                                </div> */}
                                {data.winner_id === 0 && (
                                  <div className="match-draw-place alert alert-success ">
                                    {data.winner_name}
                                  </div>
                                )}
                                {data.winner_id !== 0 && (
                                  <div className="match-bet-place alert alert-success ">
                                    {data.winner_name} Won!
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="column">
                              <div className="team team--away">
                                <div className="team-logo">
                                  <img
                                    src={require("../../../../assets/images/results/team.png")}
                                    alt="profile-user"
                                    className=""
                                  />
                                </div>
                                <h2 className="team-name">{data.team2_name}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
