import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fixturedeletepoolwise,
  pooladdedteamlist,
  poolautoteamcreate,
  poolcreate,
  pooldelete,
  pooldetails,
  poollisttour,
  poolteamdelete,
  poolupdate,
  schedulecreate,
  scheduledelete,
  schedulelist,
  schedulenextroundlist,
  schedulequarselectedlist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import exportUrl from "../../../../exportUtl";
import { Reftourdetail } from "../../../../services/referee";
import { sendschedulartour } from "../../../../services/communication";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../services/coach";
import { tourdetails } from "../../../../services/register";

export const SchedulePoolList = () => {
  const navigate = useNavigate();
  const [filterProduct, setFilterProduct] = useState([]);
  const { id } = useParams();
  const { eid } = useParams();
  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [tsport, setMSport] = useState("");

  const [sdate, setSDate] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [pname, setPName] = useState("");
  const [maxteam, setMaxTeam] = useState("");
  const [catname, setCatname] = useState("");

  // const [dur7one, setDur7One] = useState("");
  // const [dur2, setDur2] = useState("");
  // const [dur7two, setDur7Two] = useState("");
  // const [dur4, setDur4] = useState("");
  const [matperteam, setMatperTeam] = useState("");
  const [totdur, setTotDur] = useState("");
  const [totsets, setTotSets] = useState("");

  const [quachk, setQuachk] = useState(0);
  const [lformat, setLFormat] = useState("0");

  const [expshow, setExpShow] = useState(false);

  const [show, setShow] = useState(false);
  function handleShow() {
    setPName("");
    setMaxTeam("");
    setErrorMsg("");
    setLFormat("0");
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setLFormat("0");
    setPName("");
    setMaxTeam("");
    setErrorMsg("");
  }

  const [sshow, setSShow] = useState(false);
  const [poolid, setPoolId] = useState("");
  function handleSShow(pid) {
    // setTeamGp("");
    // setTotMat("");
    // setDur7One("");
    // setDur7Two("");
    // setDur2("");
    // setDur4("");
    setMatperTeam("");
    setTotDur("");
    setQuachk(0);
    setPoolId(pid);
    setSShow(true);
  }

  function handleSclose() {
    setSShow(false);
    // setTeamGp("");
    // setTotMat("");
    // setDur7One("");
    // setDur7Two("");
    // setDur2("");
    // setDur4("");
    setPoolId("");
    setMatperTeam("");
    setTotDur("");
    setQuachk(0);
  }
  const handleTeam = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMaxTeam(e.target.value);
    }
  };

  // const handleGroup = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value === "" || regex.test(e.target.value)) {
  //         setTeamGp(e.target.value);
  //     }
  // };

  // const handleMatches = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value === "" || regex.test(e.target.value)) {
  //         setTotMat(e.target.value);
  //     }
  // };

  const handleDuration = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value < 61) {
      if (e.target.value === "" || regex.test(e.target.value)) {
        setTotDur(e.target.value);
      }
    } else {
      return false;
    }
  };

  // const handle7One = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value < 61) {
  //         if (e.target.value === "" || regex.test(e.target.value)) {
  //             setDur7One(e.target.value);
  //         }
  //     } else {
  //         return false;
  //     }

  // };

  // const handleTwo = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value < 61) {
  //         if (e.target.value === "" || regex.test(e.target.value)) {
  //             setDur2(e.target.value);
  //         }
  //     } else {
  //         return false;
  //     }
  // };

  // const handle7Two = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value < 61) {
  //         if (e.target.value === "" || regex.test(e.target.value)) {
  //             setDur7Two(e.target.value);
  //         }
  //     } else {
  //         return false;
  //     }
  // };

  // const handleFour = (e) => {
  //     const regex = /^[0-9\b]+$/;
  //     if (e.target.value < 61) {
  //         if (e.target.value === "" || regex.test(e.target.value)) {
  //             setDur4(e.target.value);
  //         }
  //     } else {
  //         return false;
  //     }
  // };
  const handleMPerTeam = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMatperTeam(e.target.value);
    }
  };

  const [schbut, setSchButton] = useState("");
  useEffect(() => {
    listall();

    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);
          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setMSport(response.data.tournament.sports_type);

          if (
            moment(new Date()).format("yyyy-MM-DD") >=
            response.data.tournament.start_date
          ) {
            setSDate(true);
          }
        }
      });
    }
  }, []);

  const [plist, setPList] = useState("");
  let arr = [];
  function listall() {
    setFilterProduct([]);
    poollisttour(id, eid).then((response) => {
      if (response.status === true) {
        arr = [];
        if (response.data.pool.length !== 0) {
          for (let i = 0; i < response.data.pool.length; i++) {
            var ebtn = "1";
            var tbtn = "1";

            if (
              response.data.pool[i].no_of_teams ===
              response.data.pool[i].teamlist.length
            ) {
              ebtn = "0";
              tbtn = "0";
            }

            arr.push({
              meet_pool_id: response.data.pool[i].meet_pool_id,
              pool_name: response.data.pool[i].pool_name,
              teamlist: response.data.pool[i].teamlist,
              genfixture: "",
              editbtn: ebtn,
              teambtn: tbtn,
              deletefix: [],
            });
            if (response.data.fixtures_info.length !== 0) {
              for (let j = 0; j < response.data.fixtures_info.length; j++) {
                if (
                  response.data.pool[i].meet_pool_id ===
                  response.data.fixtures_info[j].pool_id
                ) {
                  arr[i].genfixture = "Yes";
                  setExpShow(true);
                }
              }
            }

            const temp2 = new FormData();
            temp2.append("tournament_id", id);
            temp2.append("category_id", eid);
            schedulenextroundlist(temp2).then((res) => {
              if (res.status === true) {
                arr[i].deletefix = res.data.fixtures_count;
                setFilterProduct([...arr]);
              }
            });
          }
          setFilterProduct(arr);
        }
      }
    });

    schedulelist(id, eid).then((response) => {
      if (response.status === true) {
        if (response.data.data_fixtures.length !== 0) {
          setScheduleLink(response.data.data_fixtures);
        }
      }
    });
  }
  const [schlink, setScheduleLink] = useState([]);

  function handleAssignTeam(pid, plname) {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      Swal.fire({
        title: "Are you sure want to assign the team to pool?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("pool_table_id", pid);
          temp.append("tournament_meet_id", id);
          dotracking(
            plname +
              " (" +
              pid +
              ") pool assign teams button in pool list for " +
              catname +
              " (" +
              eid +
              ") category in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.poollistheading
          );
          poolautoteamcreate(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function addteampool(pid, plname) {
    dotracking(
      plname +
        "(" +
        pid +
        ") add team in pool list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.allocateteamheading
    );
    setUID(pid);
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/scheduleteam/" + id + "/" + pid + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/scheduleteam/" + id + "/" + pid + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/scheduleteam/" + id + "/" + pid + "/" + eid);
    }
  }
  const [sid, setUID] = useState("");

  function editpool(pid, plname) {
    setUID(pid);
    handleShow(true);
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      dotracking(
        plname +
          "(" +
          pid +
          ") pool edit button in pool list for " +
          catname +
          " (" +
          eid +
          ") category in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.poollistheading
      );
      pooldetails(pid).then((response) => {
        if (response.status === true) {
          setPName(response.data.pool_name);
          setMaxTeam(response.data.no_of_teams);
        }
      });
    }
  }

  function deletepool(pid, plname) {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      Swal.fire({
        title: "Are you sure want to delete pool?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            plname +
              " (" +
              pid +
              ") pool delete icon in pool list for " +
              catname +
              " (" +
              eid +
              ") category in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.poollistheading
          );
          pooldelete(pid, localStorage.getItem("UID")).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  function deletefix(pid, plname) {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      Swal.fire({
        title: "Are you sure want to delete fixture?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("tournament_id", id);
          temp.append("pool_id", pid);
          temp.append("category_id", eid);
          dotracking(
            plname +
              " (" +
              pid +
              ") pool delete fixture icon in pool list for " +
              catname +
              " (" +
              eid +
              ") category in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.poollistheading
          );
          fixturedeletepoolwise(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  function backlist() {
    dotracking(
      "back button in pool list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/categorylist/" + id);
    }
  }

  function deleteteam(tid) {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      poolteamdelete(tid, localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          listall();
          toast.success(response.message);
        }
      });
    }
  }

  function submitpool(e) {
    e.preventDefault();
    setErrorMsg("");
    if (pname !== "") {
      if (maxteam !== "") {
        if (maxteam > 1) {
          if (sid === "") {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_meet_id", id);
            temp.append("no_of_teams", maxteam);
            temp.append("pool_name", pname);
            temp.append("category_id", eid);
            dotracking(
              "add button in pool list for pool " +
                pname +
                " in " +
                catname +
                " (" +
                eid +
                ") category in " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.poollistheading
            );
            if (
              localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3" ||
              localStorage.getItem("Role") === "7"
            ) {
              poolcreate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.data.msg);
                }
              });
            }
          } else {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("pool_id", sid);
            temp.append("tournament_meet_id", id);
            temp.append("no_of_teams", maxteam);
            temp.append("pool_name", pname);
            if (
              localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3" ||
              localStorage.getItem("Role") === "7"
            ) {
              dotracking(
                "update button in pool list for " +
                  pname +
                  " (" +
                  sid +
                  ") pool in category " +
                  catname +
                  " (" +
                  eid +
                  ") category in " +
                  tname +
                  " (" +
                  id +
                  ") tournament $$ " +
                  trackobj.poollistheading
              );
              poolupdate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                } else {
                  setErrorMsg(response.data.msg);
                }
              });
            }
          }
        } else {
          setErrorMsg("No of Teams should be greater than or equal to 2");
        }
      } else {
        setErrorMsg("Please Enter No of Teams");
      }
    } else {
      setErrorMsg("Please Enter Pool Name");
    }
  }

  function submitschedule(e) {
    e.preventDefault();
    setErrorMsg("");
    var valid = "";
    if (matperteam !== "" || lformat !== "0") {
      if (matperteam !== "" && lformat !== "0") {
        setErrorMsg("Either No of Matches per Team Or Select League Format");
      } else {
        // if (totdur !== "") {
        if (quachk !== "") {
          if (
            tsport.toUpperCase() === "VOLLEYBALL" ||
            tsport.toUpperCase() === "THROWBALL" ||
            tsport.toUpperCase() === "BADMINTON" ||
            tsport.toUpperCase() === "TENNIS" ||
            tsport.toUpperCase() === "TABLE TENNIS" ||
            tsport.toUpperCase() === "SQUASH"
          ) {
            if (totsets === "") {
              setErrorMsg("Please Enter Total Sets");
              valid = "Not";
            }
          }

          if (
            tsport.toUpperCase() === "SOFTBALL" ||
            tsport.toUpperCase() === "KHOKHO"
          ) {
            if (totsets === "") {
              setErrorMsg("Please Enter Total Innings");
              valid = "Not";
            }
          }

          if (valid === "") {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_id", id);
            temp.append("category_id", eid);
            temp.append("maximum_matches_per_team", matperteam);
            temp.append("qualification_check", quachk);
            temp.append("duration_match", 20);
            temp.append("full_round_robin", lformat);
            temp.append("pool_id", poolid);
            if (
              tsport.toUpperCase() === "VOLLEYBALL" ||
              tsport.toUpperCase() === "THROWBALL" ||
              tsport.toUpperCase() === "SOFTBALL" ||
              tsport.toUpperCase() === "KHOKHO" ||
              tsport.toUpperCase() === "BADMINTON" ||
              tsport.toUpperCase() === "TENNIS" ||
              tsport.toUpperCase() === "TABLE TENNIS" ||
              tsport.toUpperCase() === "SQUASH"
            ) {
              temp.append("total_sets", totsets);
            } else {
              temp.append("total_sets", 1);
            }
            if (
              localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3" ||
              localStorage.getItem("Role") === "7"
            ) {
              const pdata = filterProduct.find(
                (item) => item.meet_pool_id === poolid
              );
              dotracking(
                "generate fixture add button in pool list for " +
                  pdata.pool_name +
                  " (" +
                  poolid +
                  ") pool in " +
                  catname +
                  " (" +
                  eid +
                  ") category in " +
                  tname +
                  " (" +
                  id +
                  ") tournament $$ " +
                  trackobj.fixallocateheading
              );
              schedulecreate(temp).then((response) => {
                if (response.status === true) {
                  handleSclose();
                  if (localStorage.getItem("Role") === "1") {
                    navigate("/superadmin/scheduleallocate/" + id + "/" + eid);
                  } else if (localStorage.getItem("Role") === "3") {
                    navigate(
                      "/cityregional/scheduleallocate/" + id + "/" + eid
                    );
                  } else if (localStorage.getItem("Role") === "7") {
                    navigate("/coach/scheduleallocate/" + id + "/" + eid);
                  }
                }
              });
            }
          }
        } else {
          setErrorMsg("Please Select Qualification Type");
        }
        // } else {
        //   setErrorMsg("Please Enter Duration in Minutes");
        // }
      }
    } else {
      setErrorMsg(
        "Please Enter No of Matches per Team Or Select League Format"
      );
    }
  }

  function schelist() {
    dotracking(
      "fixture list button in pool list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.fixallocateheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/scheduleallocate/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/scheduleallocate/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/scheduleallocate/" + id + "/" + eid);
    }
  }

  function impfixture(pid, pname) {
    dotracking(
      "import fixture button in pool list for " +
        pname +
        " (" +
        pid +
        ") pool in " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.importfixtureheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/importfixture/" + id + "/" + eid + "/" + pid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/importfixture/" + id + "/" + eid + "/" + pid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/importfixture/" + id + "/" + eid + "/" + pid);
    }
  }

  function expteam() {
    dotracking(
      "export team button in pool list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.poollistheading
    );
    window.open(exportUrl + "export-school-teams/" + id, "_blank");
  }

  function expfix() {
    dotracking(
      "export fixture button in pool list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.poollistheading
    );
    window.open(
      exportUrl + "export-match-fixtures/" + id + "/" + eid,
      "_blank"
    );
  }

  function deletefixture() {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      const temp = new FormData();
      temp.append("tournament_id", id);
      scheduledelete(temp).then((response) => {
        if (response.status === true) {
          listall();
          setPList("");
          setSchButton("");
          toast.success(response.message);
        }
      });
    }
  }

  function sentschedular() {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "7"
    ) {
      if (schlink.length !== 0) {
        Swal.fire({
          title: "Are you sure want to send?",
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            sendschedulartour(id).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: "Generate Schedular for " + tname,
          showCancelButton: true,
          confirmButtonColor: "#d71635",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Pool Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Pool</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Pools
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <h3>{tname}</h3>
              <h4 className="text-success tx-20">Category - {catname}</h4>
              <div className="row">
                <div className="col-md-12">
                  <div className="text-right">
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") &&
                      !sdate && (
                        <>
                          <a
                            className="btn btn-success me-2 mt-1"
                            onClick={handleShow}
                          >
                            <i className="fa fa-plus"></i> Add Pool
                          </a>
                        </>
                      )}

                    {localStorage.getItem("Role") === "7" &&
                      localStorage.getItem("UID") === tcreate &&
                      !sdate && (
                        <>
                          <a
                            className="btn btn-success me-2 mt-1"
                            onClick={handleShow}
                          >
                            <i className="fa fa-plus"></i> Add Pool
                          </a>
                        </>
                      )}
                    {/* <a
                      className="btn btn-export me-2"
                      onClick={() =>
                        window.open(
                          exportUrl + "export-pool-teams/" + id + "/" + eid,
                          "_blank"
                        )
                      }
                    >
                      <i className="fa-solid fa-file-export"></i> Export Pool
                    </a> */}

                    <a
                      className="btn btn-secondary me-2 mt-1"
                      onClick={expteam}
                    >
                      <i className="fa-solid fa-file-export"></i> Export Team
                    </a>
                    {expshow && (
                      <a className="btn btn-import me-2 mt-1" onClick={expfix}>
                        <i className="fa-solid fa-file-export"></i> Export
                        Fixture
                      </a>
                    )}
                    {/* {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") && (
                      <a
                        className="btn btn-import me-2"
                        onClick={sentschedular}
                      >
                        <i className="fa fa-paper-plane"></i> Send Schedular
                        Notification
                      </a>
                    )} */}

                    <a className="btn btn-warning me-2 mt-1" onClick={schelist}>
                      <i className="fa fa-list"></i> Fixtures List
                    </a>
                    <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {filterProduct.length === 0 && (
            <div className="col-md-4">
              <div className="card shadow teamslist">
                <div className="card-header bg-transparent">
                  <h3 className="card-title">No Pool Found</h3>
                </div>
              </div>
            </div>
          )}
          {filterProduct.map((data, i) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12" key={i}>
                <div className="card shadow teamslist">
                  <div className="card-header bg-transparent">
                    <h4 className="card-title my-0">{data.pool_name}</h4>
                  </div>
                  <div className="card-body p-2">
                    <div className="grid-margin">
                      {data.teamlist.map((data1, j) => {
                        return (
                          <div className="list-group list-group-flush" key={j}>
                            <div className="list-group-item d-flex  align-items-center">
                              <div className="">
                                <div className="font-weight-semibold text-capitalize">
                                  {data1.teams_name}
                                </div>
                              </div>
                              {/* {(localStorage.getItem("Role") === "1" ||
                                localStorage.getItem("Role") === "3") && (
                                <>
                                  {data.genfixture === "" &&(
                                    <div className="ml-auto">
                                      <a
                                        className="btn btn-sm btn-default-danger btn-icon text-danger"
                                        onClick={() =>
                                          deleteteam(data1.pool_mapped_teams_id)
                                        }
                                      >
                                        <i className="fas fa-trash fs-10"></i>
                                      </a>
                                    </div>
                                  )}
                                </>
                              )} */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="card-footer p-2">
                    {/* {schbut === "" && */}
                    <div className="w-100 d-flex justify-content-between">
                      {data.genfixture === "" && !sdate && (
                        <>
                          {(localStorage.getItem("Role") === "1" ||
                            localStorage.getItem("Role") === "3") && (
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              title="Delete Pool"
                              onClick={() =>
                                deletepool(data.meet_pool_id, data.pool_name)
                              }
                            >
                              <i className="fa fa-trash me-1"></i>Pool
                            </button>
                          )}

                          {localStorage.getItem("Role") === "7" &&
                            localStorage.getItem("UID") === tcreate && (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                title="Delete Pool"
                                onClick={() =>
                                  deletepool(data.meet_pool_id, data.pool_name)
                                }
                              >
                                <i className="fa fa-trash me-1"></i>Pool
                              </button>
                            )}
                        </>
                      )}
                      {/* data.editbtn === "1" &&  */}
                      {!sdate &&
                        (localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            title="Edit Pool"
                            onClick={() =>
                              editpool(data.meet_pool_id, data.pool_name)
                            }
                          >
                            Edit
                          </button>
                        )}

                      {!sdate &&
                        localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            title="Edit Pool"
                            onClick={() =>
                              editpool(data.meet_pool_id, data.pool_name)
                            }
                          >
                            Edit
                          </button>
                        )}
                      {data.teambtn === "1" &&
                        !sdate &&
                        (localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <>
                            <a
                              className="btn btn-import btn-sm"
                              onClick={() =>
                                handleAssignTeam(
                                  data.meet_pool_id,
                                  data.pool_name
                                )
                              }
                            >
                              Assign Team
                            </a>
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              title="Add Team"
                              onClick={() =>
                                addteampool(data.meet_pool_id, data.pool_name)
                              }
                            >
                              Team
                            </button>
                          </>
                        )}
                      {data.genfixture === "" &&
                        !sdate &&
                        (localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <>
                            <button
                              type="button"
                              className="btn btn-warning btn-sm"
                              title="Generate Fixture"
                              onClick={() =>
                                handleSShow(data.meet_pool_id, data.pool_name)
                              }
                            >
                              Generate
                            </button>
                          </>
                        )}

                      {!sdate &&
                        data.deletefix === 0 &&
                        (localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <button
                            type="button"
                            className="btn btn-success btn-sm me-2"
                            title="Import Fixture"
                            onClick={() =>
                              impfixture(data.meet_pool_id, data.pool_name)
                            }
                          >
                            <i className="fa-solid fa-file-import"></i> Import
                            Fixture
                          </button>
                        )}
                      {!sdate &&
                        data.genfixture === "Yes" &&
                        data.deletefix === 0 &&
                        (localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            title="Delete Fixture"
                            onClick={() =>
                              deletefix(data.meet_pool_id, data.pool_name)
                            }
                          >
                            <i className="fa fa-trash me-1"></i>Fixture
                          </button>
                        )}

                      {data.teambtn === "1" &&
                        !sdate &&
                        localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <>
                            <a
                              className="btn btn-import btn-sm"
                              onClick={() =>
                                handleAssignTeam(
                                  data.meet_pool_id,
                                  data.pool_name
                                )
                              }
                            >
                              Assign Team
                            </a>
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              title="Add Team"
                              onClick={() =>
                                addteampool(data.meet_pool_id, data.pool_name)
                              }
                            >
                              Team
                            </button>
                          </>
                        )}
                      {data.genfixture === "" &&
                        !sdate &&
                        localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <>
                            <button
                              type="button"
                              className="btn btn-warning btn-sm"
                              title="Generate Fixture"
                              onClick={() =>
                                handleSShow(data.meet_pool_id, data.pool_name)
                              }
                            >
                              Generate
                            </button>
                          </>
                        )}

                      {!sdate &&
                        data.deletefix === 0 &&
                        localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <button
                            type="button"
                            className="btn btn-success btn-sm me-2"
                            title="Import Fixture"
                            onClick={() =>
                              impfixture(data.meet_pool_id, data.pool_name)
                            }
                          >
                            <i className="fa-solid fa-file-import"></i> Import
                            Fixture
                          </button>
                        )}
                      {!sdate &&
                        data.genfixture === "Yes" &&
                        data.deletefix === 0 &&
                        localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            title="Delete Fixture"
                            onClick={() =>
                              deletefix(data.meet_pool_id, data.pool_name)
                            }
                          >
                            <i className="fa fa-trash me-1"></i>Fixture
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Modal show={show} size="lg" onHide={handleclose}>
          <Modal.Header>
            {sid === "" && <h4> Add Pool</h4>}
            {sid !== "" && <h4> Pool Details</h4>}
          </Modal.Header>
          <Modal.Body>
            <Row className="row-sm mx-0">
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Pool Name <span className="errortext"> *</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Pool Name"
                    className="form-control"
                    value={pname}
                    onChange={(e) => setPName(e.target.value)}
                  />
                </div>
              </Col>
              {sid !== "" && (
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      No of Teams <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="No Of Teams"
                      className="form-control"
                      value={maxteam}
                      disabled
                    />
                  </div>
                </Col>
              )}
              {sid === "" && (
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      No of Teams <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="No Of Teams"
                      className="form-control"
                      maxLength={3}
                      value={maxteam}
                      onChange={handleTeam}
                    />
                  </div>
                </Col>
              )}
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-danger" onClick={handleclose}>
              Close
            </a>
            {sid === "" && (
              <a className="btn btn-success" onClick={submitpool}>
                Add
              </a>
            )}

            {sid !== "" && (
              <a className="btn btn-success" onClick={submitpool}>
                Update
              </a>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={sshow} size="lg" onHide={handleSclose}>
          <Modal.Header>
            <h4> Add Fixture</h4>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-sm mx-0">
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">No of Matches per Team </label>
                  <input
                    type="text"
                    placeholder="No Of Matches per Team"
                    className="form-control"
                    maxLength={3}
                    value={matperteam}
                    onChange={handleMPerTeam}
                  />
                </div>
              </Col>

              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">League Format </label>
                  <select
                    className="form-control custom-select"
                    value={lformat}
                    onChange={(e) => setLFormat(e.target.value)}
                  >
                    <option value="0">--Select League Format--</option>
                    <option value="1">Full Round-Robin</option>
                  </select>
                </div>
              </Col>

              {/* <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Duration in Minutes (7-2-7-4){" "}
                    <span className="errortext"> *</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Duration in Minutes"
                    className="form-control"
                    value={totdur}
                    maxLength={2}
                    onChange={handleDuration}
                  />
                </div>
              </Col> */}
              {(tsport.toUpperCase() === "VOLLEYBALL" ||
                tsport.toUpperCase() === "THROWBALL" ||
                tsport.toUpperCase() === "BADMINTON" ||
                tsport.toUpperCase() === "TENNIS" ||
                tsport.toUpperCase() === "TABLE TENNIS" ||
                tsport.toUpperCase() === "SQUASH") && (
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Total Sets (Best of) <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={totsets}
                      onChange={(e) => setTotSets(e.target.value)}
                    >
                      <option value="0">--Select Total Sets (Best of)--</option>
                      <option value="1">1</option>
                      {/* <option value="2">2</option> */}
                      <option value="3">3</option>
                      {/* <option value="4">4</option> */}
                      <option value="5">5</option>
                      {/* <option value="6">6</option> */}
                      <option value="7">7</option>
                      {/* <option value="8">8</option> */}
                      <option value="9">9</option>
                    </select>
                  </div>
                </Col>
              )}
              {(tsport.toUpperCase() === "SOFTBALL" ||
                tsport.toUpperCase() === "KHOKHO") && (
                <Col lg={12} md={12}>
                  <div className="form-group">
                    <label className="mb-2">
                      Total Innings <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={totsets}
                      onChange={(e) => setTotSets(e.target.value)}
                    >
                      <option value="0">--Select Total Innings--</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </Col>
              )}
              {/* <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Qualification Type <span className="errortext"> *</span>
                  </label>
                  <select
                    className="form-control custom-select"
                    value={quachk}
                    onChange={(e) => setQuachk(e.target.value)}
                  >
                    <option value="" defaultValue disabled>
                      --Select Qualification Type--
                    </option>
                    <option value="1">
                      Top 2 teams will get qualified in each pool
                    </option>
                    <option value="2">
                      Top team will get qualified in each pool
                    </option>
                  </select>
                </div>
              </Col> */}

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-danger" onClick={handleSclose}>
              Close
            </a>

            <a onClick={submitschedule} className="btn btn-success">
              Add
            </a>
          </Modal.Footer>
        </Modal>

        {/* {plist !== "" &&
                    <div className="col-12">
                        <div className="card mt-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="text-right">
                                        {schbut === "" &&
                                            <a
                                                className="btn btn-success me-2"
                                                onClick={handleSShow}
                                            >
                                                <i className="fa fa-plus"></i> Generate Fixtures
                                            </a>
                                        }
                                        {schbut !== "" &&
                                            <a
                                                className="btn btn-danger me-2"
                                                onClick={deletefixture}
                                            >
                                                <i className="fa fa-trash"></i> Delete
                                            </a>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
      </div>
    </div>
  );
};
