import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import JoditEditor from "jodit-react";
import "../../../../assets/css/funder.css";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import WebUrl from "../../../../AWebUrl";
import { storylist, storyview } from "../../../../services/superadmin";
import { funderresourceslist } from "../../../../services/common";
import { funderprofile } from "../../../../services/funder";
import { funderdetail } from "../../../../services/master";
export const TempFunderCMSDetails = () => {
  const { id } = useParams();
  const [fundname, setFundName] = useState("");

  const [stlist, setStList] = useState([]);
  const [stpath, setStPath] = useState("");

  const [reslist, setResList] = useState([]);

  const [respath, setResPath] = useState("");

  useEffect(() => {
    funderprofile().then((res2) => {
      if (res2.status === true) {
        funderdetail(res2.data.funder_id).then((response) => {
          if (response.status === true) {
            setFundName(response.data.funders_details.funder_name);
          }
        });
      }

      storylist(res2.data.funder_id, 2).then((res) => {
        if (res.status === true) {
          setStPath(res.data.logo_path);
          setStList(res.data.success_stories_list);
        }
      });

      funderresourceslist(res2.data.funder_id).then((res) => {
        if (res.status === true) {
          setResPath(res.data.logo_path);
          setResList(res.data.resources_list);
        }
      });
    });
  }, []);

  const [ishow, setIShow] = useState(false);
  const [pimage, setPImage] = useState(false);
  const [pimgtype, setPImgType] = useState("");
  const [pimgurl, setPImgUrl] = useState("");
  const [prem, setPRem] = useState("");

  function handlepop(img, itype, ifile, irem) {
    if (itype === "Video") {
      if (ifile === "url") {
        let newstr1 = img.substr(img.length - 11);
        let curvideo = "";
        let res = img.substring(0, 23);
        curvideo = "https://www.youtube.com";
        if (curvideo == res) {
          setPImage(`https://www.youtube.com/embed/${newstr1}`);
        }
      } else {
        setPImage(respath + img);
      }
    } else {
      setPImage(respath + img);
    }
    setPRem(irem);

    setPImgUrl(ifile);
    setIShow(true);
    setPImgType(itype);
  }

  function imageclose() {
    setIShow(false);
    setPImage("");
  }

  const [sphotourl, setSPhotoUrl] = useState("");
  const [syshow, setSyShow] = useState(false);

  //Success Stories
  const [stitle, setSTitle] = useState("");
  const [sdesc, setSDesc] = useState("");
  const [sphto, setSImage] = useState("");
  const [sdesc1, setSDesc1] = useState("");
  const [stid, setStId] = useState("");
  function handlestory(sid) {
    storyview(sid).then((response) => {
      if (response.status === true) {
        setSyShow(true);
        setSTitle(response.data.success_stories_details.article_title);
        setSDesc(response.data.success_stories_details.article_description);
        if (response.data.success_stories_details.article_image !== null) {
         
          setSImage(
            response.data.logo_path +
              response.data.success_stories_details.article_image
          );
        }
        setSDesc1(response.data.success_stories_details.article_content);
        setStId(response.data.success_stories_details.id);
      }
    });
  }

  function handlestoryclose() {
    setSyShow(false);
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setSPhotoUrl("");
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add CMS</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add CMS
          </li>
        </ol>
      </div>
      <h3 className="errortext text-center">{fundname}</h3>
      {localStorage.getItem("Role") === "8" && (
        <>
          <Tabs
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey={1} title="Success Stories">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Success Stories</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={sscolor}
                        onChange={(e) => setSSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      {/* <div className="text-right">
                        <a className="btn btn-success" onClick={handlesshow}>
                          <i className="fa fa-plus"></i> Add Success Stories
                        </a>
                      </div> */}
                    </div>
                  </div>

                  <Modal show={syshow} size="xl">
                    <Modal.Header>
                      <h4>Success Stories Details</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="testi-content">
                        <div className="row">
                          <div className="col-md-6">
                            <img src={sphto} width="100%" alt="testi-img" />
                          </div>
                          <div className="col-md-6">
                            <div className="testi-inner">
                              <div className="testi-desc">
                                <div>
                                  <h3 className="testi_author">{stitle}</h3>
                                  <span className="testi_degi">{sdesc}</span>
                                </div>
                              </div>

                              <div className="testi-text">
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: sdesc1 }}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="text-right">
                        <a
                          className="btn btn-danger"
                          onClick={handlestoryclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <section className="testimonial-layout1 style2 space">
                <div className="card container">
                  <div className="row vs-carousel">
                    {stlist.map((item, i) => (
                      <div
                        className="col-md-6 slick-slide slick-cloned mt-2"
                        style={{ width: "623px" }}
                        key={i}
                      >
                        <div className="testi-item">
                          <div
                            className="testi-content"
                            onClick={() => handlestory(item.id)}
                          >
                            <div className="testi-img">
                              <img
                                src={stpath + item.article_image}
                                alt="testi-img"
                              />
                              <span className="testi_quote">
                                <i className="fas fa-quote-right"></i>
                              </span>
                            </div>
                            <div className="testi-inner">
                              <div className="testi-text">
                                <p>{item.article_description}</p>
                              </div>
                              <div className="testi-desc">
                                <div>
                                  <h3 className="testi_author">
                                    {item.article_title}
                                  </h3>
                                  {/* <span className="testi_degi">
                                  Indian Badminton
                                </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </Tab>

            <Tab eventKey={2} title="Resources">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5">
                      <h4>Resources</h4>
                    </div>

                    <div className="col-md-7">
                      {/* <div className="text-right">
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={handlegshow}
                        >
                          <i className="fa fa-plus"></i> Add Resources
                        </a>
                      </div> */}
                    </div>
                  </div>

                  {/*<div className="col-md-3">
                      <label className="form-label">Background Color</label>
                    <select
                        value={rcolor}
                        onChange={(e) => setRColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>*/}
                </div>
              </div>

              <section className="space-bottom blog-layout1">
                <div className="container">
                  <div className="row vs-carousel">
                    {reslist.map((item, i) => {
                      return (
                        <div className="col-lg-3 col-md-6" key={i}>
                          <div className="vs-blog blog-style3">
                            <div className="blog-img">
                              <a
                                onClick={(e) =>
                                  handlepop(
                                    item.file_name,
                                    item.file_type,
                                    item.file_or_url,
                                    item.remarks
                                  )
                                }
                              >
                                {item.file_type == "PDF" && (
                                  <img
                                    className="w-100"
                                    src={require("../../../../assets/images/pdf.png")}
                                    alt="Blog Img"
                                  />
                                )}
                                {item.file_type == "Video" && (
                                  <img
                                    className="w-100"
                                    src={require("../../../../assets/images/video.png")}
                                    alt="Blog Img"
                                  />
                                )}

                                {item.file_type == "Photo" && (
                                  <img
                                    className="w-100"
                                    src={respath + item.file_name}
                                    alt="Blog Img"
                                  />
                                )}
                              </a>
                            </div>
                            <div className="blog-content">
                              <h4 className="blog-title">
                                <span className="blogline">
                                  {item.tag_name}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Modal show={ishow} size="xl" onHide={imageclose}>
                <Modal.Header>
                  <h4> Resources</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        {pimgtype === "Video" && pimgurl === "url" && (
                          <iframe
                            width="100%"
                            height="300"
                            src={pimage}
                            frameBorder="0"
                            // allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        )}

                        {pimgtype === "Video" && pimgurl !== "url" && (
                          <video controls width="100%" height="300">
                            <source src={pimage} type="video/mp4" />
                          </video>
                        )}

                        {pimgtype === "PDF" && (
                          <iframe
                            width="100%"
                            height="400"
                            src={pimage}
                            allowFullScreen
                          />
                        )}

                        {pimgtype === "Photo" && (
                          <img src={pimage} alt="Photo" width="100%" />
                        )}
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <p>{prem}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={imageclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};
