import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { playerotherachievementdetail } from "../../../../services/player";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const PlayerAchievementDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [fname, setFName] = useState("");
  const [uname, setUName] = useState("");
  const [gender, setGender] = useState("");
  const [grade, setGrade] = useState("");
  const [section, setSection] = useState("");
  const [stuchid, setStuCHID] = useState("");
  const [stuesid, setStuESID] = useState("");
  const [schname, setSchName] = useState("");

  const [tname, setTName] = useState("");
  const [tlevel, setTLevel] = useState("");
  const [tdate, setTDate] = useState("");
  const [tcity, setTCity] = useState("");
  const [tloc, setLocation] = useState("");
  const [sports, setSports] = useState("");
  const [agroup, setAgeGroup] = useState("");
  const [position, setPosition] = useState("");
  const [event, setEvent] = useState("");

  useEffect(() => {
    playerotherachievementdetail(id).then((response) => {
      if (response.status === true) {
        setFName(response.data.firstname);
        setUName(response.data.username);
        setGender(response.data.gender);
        setGrade(response.data.student_grade);
        setSection(response.data.student_section);
        setStuCHID(response.data.school_chid);
        if (response.data.school_esid !== 0) {
          setStuESID(response.data.school_esid);
        }
        if (response.data.school_name !== null) {
          setSchName(response.data.school_name);
        }
        setTName(response.data.meet_name);
        setTLevel(response.data.meet_level);
        setTDate(response.data.meet_date);
        setTCity(response.data.city);
        if (response.data.location !== null) {
          setLocation(response.data.location);
        }
        setSports(response.data.sports_type);
        setAgeGroup(response.data.age_group);
        setPosition(response.data.position);
        setEvent(response.data.event);
      }
    });
  }, []);

  function backlist() {
    dotracking("back button in achievement details (" + id + ") $$ " + trackobj.othachievelistheading);
    navigate("/player/otherachievements");
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Achievement Details</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Achievement</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Achievement Details
            </li>
          </ol>
        </div>

        <div className="container">
          <div className="card">
            <div className="card-header border-bottom">
              <div className="col-md-10">
                <h3 className="card-title font-weight-bold">Primary Details</h3>
              </div>
              <div className="col-md-2 text-right">
                <a className="btn btn-gray" onClick={backlist}>
                  <i className="fe fe-corner-up-left"></i> Back
                </a>
              </div>
            </div>

            <div className="card-body">
              <div className="border-0">
                <div className="row tournamentitem">
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Full Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{fname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Username</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Grade</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{grade}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Section</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{section}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">CHID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{stuchid}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">ESID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{stuesid}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">School Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{schname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header border-bottom">
              <h3 className="card-title font-weight-bold">
                Tournament Details
              </h3>
            </div>

            <div className="card-body">
              <div className="border-0">
                <div className="row tournamentitem">
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Tournament Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Tournament Level</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tlevel}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Tournament Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Tournament City</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tcity}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Location</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{tloc}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Sports</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{sports}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Age Group</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{agroup}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Event</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{event}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="text-inverse">Position</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{position}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
