import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  athleticsofflineentrylist,
  athleticsofflineentryupload,
  offlineentrylist,
  offlineentryupload,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { useNavigate, useParams } from "react-router-dom";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import exportUrl from "../../../../../exportUtl";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import {
  scheadtourviewdetail,
  schheadteamdetails,
  schprofileview,
} from "../../../../../services/schoolhead";
import {
  interschoolagecatselectlist,
  interschoolplayersupload,
} from "../../../../../services/interschool";
import { coachtourviewdetail } from "../../../../../services/coach";

export const OfflineEntry = () => {
  const { id, eid, tid } = useParams();
  const navigate = useNavigate();
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");

  const [tname, setTName] = useState("");
  const [tsport, setSport] = useState("");
  const [ttype, setTType] = useState("");

  const [team, setTeam] = useState("");
  const [tcode, setTCode] = useState("");

  const [paid, setPaid] = useState("");
  const [schoolid, setSchoolId] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");
  const [failid, setFailId] = useState(0);

  let arr = [];

  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Upload Excel",
      width: 170,
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={() =>
                dotracking(
                  "click to download link in offline entry for " +
                    tname +
                    " (" +
                    id +
                    ") tournament and file " +
                    params.value +
                    " (" +
                    params.data.id +
                    ") $$ " +
                    trackobj.offlineheading
                )
              }
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const [s3path, setS3Path] = useState("");
  const [urllink, setUrlLink] = useState("");
  const [catname, setCatname] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSport(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSport(response.data.tournament.sports_type);
          setTType(response.data.tournament.meet_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);

          if (
            response.data.tournament.meet_type === "Inter School Tournament"
          ) {
            schheadteamdetails(tid).then((response) => {
              if (response.status === true) {
                if (response.data.team.length !== 0) {
                  setTeam(response.data.team[0].team_name);
                  setTCode(response.data.team[0].team_code);
                }
              }
            });

            const tempData = new FormData();
            tempData.append("category_id", eid);
            tourcategorydetail(tempData).then((res) => {
              if (res.status === true) {
                setCatname(res.data.category_details.category_name);
              }
            });

            schprofileview(localStorage.getItem("UID")).then((res) => {
              if (res.status === true) {
                setSchoolId(res.data.school_id);
                const temp1 = new FormData();
                temp1.append("school_id", res.data.school_id);
                temp1.append("tournament_meet_id", id);
                interschoolagecatselectlist(temp1).then((res) => {
                  if (res.status === true) {
                    if (res.data.length !== 0) {
                      setPaid(res.data[0].paid_id);
                    }
                  }
                });

                offlineentrylist(id).then((response) => {
                  if (response.status === true) {
                    if (link[1] === "teamplayerupload") {
                      const fdata = response.data.details.filter(
                        (item) => item.created_by === res.data.school_id
                      );
                      setFilterProduct(fdata);
                    } else {
                      setFilterProduct(response.data.details);
                    }

                    setS3Path(response.data.s3path);
                  }
                });
              }
            });
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((res) => {
        if (res.status === true) {
          setTName(res.data.tournament.meet_name);
          setSport(res.data.tournament.sports_type);
          setTType(res.data.tournament.meet_type);
          listall(res.data.tournament.sports_type);
        }
      });
    }
  }, []);

  function listall(stype) {
    setFilterProduct([]);
    setS3Path("");
    if (stype.toUpperCase() === "ATHLETICS") {
      athleticsofflineentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    } else {
      offlineentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
    }
  }
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("Offline Entry File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("Base Data File size should be less than 5MB");
      // }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (basefile !== "") {
      if (fileMsg === "") {
        setLoading(true);
        if (urllink !== "teamplayerupload") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("tournament_id", id);
          temp.append("offline_entries_excel", basefile);
          dotracking(
            "submit button in upload offline entry for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.offlineheading
          );
          if (tsport.toUpperCase() === "ATHLETICS") {
            athleticsofflineentryupload(temp).then((response) => {
              if (response.status === true) {
                setBase("");
                setLoading(false);
                if (response.data.total_rows !== "") {
                  setTRecord(response.data.total_rows);
                }
                if (response.data.inserted_count !== "") {
                  setIRecord(response.data.inserted_count);
                }
                if (response.data.failed_count !== "") {
                  setURecord(response.data.failed_count);
                }
                setFailId(response.data.uploaded_excel_id);
                setStatus(response.data.status);
                listall(tsport);
              } else {
                setLoading(false);
                setErrorMsg(response.message);
              }
            });
          } else {
            offlineentryupload(temp).then((response) => {
              if (response.status === true) {
                setBase("");
                setLoading(false);
                if (response.data.total_rows !== "") {
                  setTRecord(response.data.total_rows);
                }
                if (response.data.inserted_count !== "") {
                  setIRecord(response.data.inserted_count);
                }
                if (response.data.failed_count !== "") {
                  setURecord(response.data.failed_count);
                }
                setFailId(response.data.uploaded_excel_id);
                setStatus(response.data.status);
                listall(tsport);
              } else {
                setLoading(false);
                setErrorMsg(response.message);
              }
            });
          }
        } else {
          const temp = new FormData();
          temp.append("school_id", schoolid);
          temp.append("tournament_id", id);
          temp.append("entries_excel", basefile);
          temp.append("category_id", eid);
          temp.append("team_id", tid);
          temp.append("paid_id", paid);

          dotracking(
            "submit button in upload team players for " +
              catname +
              "(" +
              eid +
              " ) category in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.offlineheading
          );
          interschoolplayersupload(temp).then((response) => {
            if (response.status === true) {
              setBase("");
              setLoading(false);
              if (response.data !== null) {
                if (response.data.total_rows !== "") {
                  setTRecord(response.data.total_rows);
                }
                if (response.data.inserted_count !== "") {
                  setIRecord(response.data.inserted_count);
                }
                if (response.data.failed_count !== "") {
                  setURecord(response.data.failed_count);
                }
                setFailId(response.data.uploaded_excel_id);
                setStatus(response.data.status);
              }

              listall(tsport);
            } else {
              setLoading(false);
              setErrorMsg(response.message);
            }
          });
        }
      }
    } else {
      if (urllink !== "teamplayerupload") {
        setErrorMsg("Please Select Offline Entry File");
      } else {
        setErrorMsg("Please Upload file to add players to the team");
      }
    }
  }

  function backlist() {
    if (urllink === "teamplayerupload") {
      dotracking(
        "back button in upload offline team players for " +
          catname +
          "(" +
          eid +
          " ) category in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.teamlistheading
      );

      if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/teamlist/" + id + "/" + eid);
      }
    } else {
      dotracking(
        "back button in upload offline entry for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentlist");
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentlist");
      }
    }
  }

  function failedent(fid) {
    dotracking(
      " failed click to download link button in offline entry for " +
        tname +
        " (" +
        id +
        ") tournament and file failed (" +
        fid +
        ") $$ " +
        trackobj.offlineheading
    );
    if (tsport.toUpperCase() === "ATHLETICS") {
      window.open(
        exportUrl + "export-failed-athletics-entries/" + fid,
        "_blank"
      );
    } else {
      window.open(exportUrl + "export-failed-entries/" + fid, "_blank");
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          {urllink === "teamplayerupload" && (
            <div className="breadcrumb">
              <h4 className="mb-0">Upload Players to the team</h4>
            </div>
          )}
          {urllink !== "teamplayerupload" && (
            <div className="breadcrumb">
              <h4 className="mb-0">Offline Entry</h4>
            </div>
          )}

          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>

            {urllink !== "teamplayerupload" && (
              <li className="breadcrumb-item active" aria-current="page">
                Offline Entry
              </li>
            )}
            {urllink === "teamplayerupload" && (
              <li className="breadcrumb-item active" aria-current="page">
                Upload Players to the team
              </li>
            )}
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="card">
                <div className="text-left">
                  <h3 className="mt-3">{tname}</h3>
                  {localStorage.getItem("Role") === "4" &&
                    ttype === "Inter School Tournament" && (
                      <>
                        <h4 className="text-success tx-20">
                          Category : {catname}
                        </h4>
                        <h4 className="text-danger tx-20">
                          Team : {team} - {tcode}
                        </h4>
                      </>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <Form className="card">
                  <div className="card-body">
                    <div className="form-group">
                      {urllink !== "teamplayerupload" && (
                        <label className="form-label">
                          {" "}
                          Upload Offline Entry File{" "}
                          <span className="errortext"> *</span>
                        </label>
                      )}

                      {urllink === "teamplayerupload" && (
                        <label className="form-label">
                          {" "}
                          Upload file to add players to the team{" "}
                          <span className="errortext"> *</span>
                        </label>
                      )}

                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        Back
                      </a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>
                      {tsport.toUpperCase() !== "ATHLETICS" &&
                        tsport.toUpperCase() !== "BADMINTON" &&
                        tsport.toUpperCase() !== "TENNIS" &&
                        tsport.toUpperCase() !== "TABLE TENNIS" &&
                        tsport.toUpperCase() !== "SQUASH" && (
                          <>
                            {urllink !== "teamplayerupload" && (
                              <a
                                className="btn btn-secondary"
                                href={require("../../../../../assets/images/Racquet_Sample_Offline_Tournament_Entry_Excel.xlsx")}
                                download
                                onClick={() => {
                                  dotracking(
                                    "sample excel button in upload offline entry for " +
                                      tname +
                                      " (" +
                                      id +
                                      ") tournament $$ " +
                                      trackobj.offlineheading
                                  );
                                }}
                              >
                                Sample Excel
                              </a>
                            )}
                            {urllink === "teamplayerupload" && (
                              <a
                                className="btn btn-secondary"
                                href={require("../../../../../assets/images/Sample_Team_Player_Tournament_Entry_Excel.xlsx")}
                                download
                                onClick={() => {
                                  dotracking(
                                    "sample excel button in upload offline entry for " +
                                      tname +
                                      " (" +
                                      id +
                                      ") tournament $$ " +
                                      trackobj.offlineheading
                                  );
                                }}
                              >
                                Sample Excel
                              </a>
                            )}
                          </>
                        )}
                      {tsport.toUpperCase() === "ATHLETICS" && (
                        <a
                          className="btn btn-secondary"
                          href={require("../../../../../assets/images/Sample_Athletics_Offline_Entries_Excel.xlsx")}
                          download
                          onClick={() => {
                            dotracking(
                              "sample excel button in upload offline entry for " +
                                tname +
                                " (" +
                                id +
                                ") tournament $$ " +
                                trackobj.offlineheading
                            );
                          }}
                        >
                          Sample Excel
                        </a>
                      )}
                      {(tsport.toUpperCase() === "BADMINTON" ||
                        tsport.toUpperCase() === "TENNIS" ||
                        tsport.toUpperCase() === "TABLE TENNIS" ||
                        tsport.toUpperCase() === "SQUASH") && (
                        <a
                          className="btn btn-secondary"
                          href={require("../../../../../assets/images/Racquet_Sample_Offline_Tournament_Entry_Excel.xlsx")}
                          download
                          onClick={() => {
                            dotracking(
                              "sample excel button in upload offline entry for " +
                                tname +
                                " (" +
                                id +
                                ") tournament $$ " +
                                trackobj.offlineheading
                            );
                          }}
                        >
                          Sample Excel
                        </a>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-md-6">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 320,
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="4"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Failed Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                    {urecord !== 0 && (
                      <a
                        className="excelfailed text-danger"
                        onClick={() => failedent(failid)}
                      >
                        <b>Click to Download</b>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};
