import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  allplayerlist,
  multipleroleslist,
  rolemasterlist,
  trackingadminlist,
} from "../../../services/superadmin";

export const TrackingList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");

  const [role, setRole] = useState(0);
  const [rvalue, setRValue] = useState(0);

  const [rlist, setRList] = useState([]);

  const [user, setUser] = useState("");
  const [uvalue, setUValue] = useState("");

  const [ulist, setUList] = useState([]);

  const columnDefs = [
    {
      headerName: "Username (UID)",
      width: 200,
      field: "username",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 200,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role",
      width: 150,
      field: "role_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Page",
      width: 150,
      field: "description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      width: 300,
      field: "action",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Login Time",
      width: 200,
      field: "logged_in_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "IP Address",
      width: 150,
      field: "ip_address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 200,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Browser",
      width: 150,
      field: "browser",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  let arr = [];
  useEffect(() => {
    setSDate(moment(new Date()).format("YYYY-MM-DD"));
    setEDate(moment(new Date()).format("YYYY-MM-DD"));

    rolemasterlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].is_active === 1) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].role,
              });
            }
          }
        }
        setRList(arr);
      }
    });

    trackingadminlist(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
      0,
      ""
    ).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  function search() {
    trackingadminlist(sdate, edate, rvalue, uvalue).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  function handleRole(e) {
    setUValue("");
    setUser("");
    setUList([]);
    if (e === null) {
      setRole(0);
      setRValue(0);
    } else {
      setRole(e);
      setRValue(e.value);
      if (e.value !== 6) {
        multipleroleslist(e.value, 1).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].user_id,
                  label:
                    response.data[i].first_name +
                    " " +
                    response.data[i].last_name +
                    " (" +
                    response.data[i].username +
                    ")",
                });
              }
            }
            setUList(arr);
          }
        });
      } else {
        allplayerlist(1, 6).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              arr = [];
              for (let i = 0; i < response.data.length; i++) {
                var fname = "";
                if (response.data[i].first_name !== null) {
                  fname = response.data[i].first_name;
                }
                if (response.data[i].username !== null) {
                  fname = fname + " (" + response.data[i].username + " )";
                }
                arr.push({
                  value: response.data[i].user_id,
                  label: fname,
                });
              }
            }
            setUList(arr);
          }
        });
      }
    }
  }

  function handleUser(e) {
    if (e === null) {
      setUser("");
      setUValue("");
    } else {
      setUser(e);
      setUValue(e.value);
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tracking</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tracking</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tracking List
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={sdate}
                      onChange={(e) => setSDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">Ends Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={edate}
                      onChange={(e) => setEDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Roles</label>
                    <Select
                      className="dropdown"
                      placeholder="Select Roles"
                      value={role}
                      options={rlist}
                      onChange={handleRole}
                      isSearchable={true}
                      isClearable
                      hidden
                    />
                  </div>
                </div>
                {role !== 0 && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Username</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Username"
                        value={user}
                        options={ulist}
                        onChange={handleUser}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}
                {role === 0 && <div className="col-md-3"></div>}
                <div className="col-md-2">
                  <div className="form-group">
                    <a
                      className="btn btn-success mt-6 mx-7 me-2"
                      onClick={search}
                    >
                      Apply Filter
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="col-md-12">
          <form className="card mt-0">
            <div className="card-body">
              <div
                className="ag-theme-alpine ag-style"
                style={{
                  height: 600,
                }}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={["asc", "desc"]}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
