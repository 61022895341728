import React, { useEffect, useState } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  pooldetails,
  poolteamcreate,
  poolteamlist,
  tourcategorydetail,
  tourteamplayerlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { crmtourviewdetail, teamselectedplayerlistcityreg } from "../../../../services/cityregionaladmin";
import Swal from "sweetalert2";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourviewdetail, teamselectedplayerlistcoach } from "../../../../services/coach";

export const ScheduleTeamPlayerCreate = () => {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();

  const [errorMsg, setErrorMsg] = useState("");

  const [tname, setTName] = useState("");
  const [plname, setPlName] = useState("");
  const [catname, setCatname] = useState("");

  const navigate = useNavigate();
  const [tmname, setTMName] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);

  let arr = [];
  function handleshow(index) {
    if (plarr.length !== 0) {
      for (let i = 0; i < plarr.length; i++) {
        if (index === i) {
          setTMName(plarr[i].team_name);
          if(localStorage.getItem("Role") === "1"){
            tourteamplayerlist(id, plarr[i].team_id).then((response) => {
              if (response.status === true) {
                setFilterProduct(response.data);
              }
            });
            setShow(true);
          }else if(localStorage.getItem("Role") === "3"){
            teamselectedplayerlistcityreg(id, plarr[i].team_id).then((response) => {
              if (response.status === true) {
                setFilterProduct(response.data);
              }
            });
            setShow(true);
          }else if(localStorage.getItem("Role") === "7"){
            teamselectedplayerlistcoach(id, plarr[i].team_id).then((response) => {
              if (response.status === true) {
                setFilterProduct(response.data);
              }
            });
            setShow(true);
          }
          
        }
      }
    }
  }

  function handleclose() {
    setTMName("");
    setFilterProduct([]);
    setShow(false);
  }

  let board = {
    columns: [
      {
        id: 1,
        cards: [],
      },
      {
        id: 2,
        cards: [],
      },
    ],
  };
  const [controlledBoard, setBoard] = useState({ ...board });
  const [selepla, SetSelePla] = useState("");
  const [maxteam, SetMaxTeam] = useState(0);

  let plarr = [];
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }

    pooldetails(tid).then((response) => {
      if (response.status === true) {
        setPlName(response.data.pool_name);
        SetMaxTeam(response.data.no_of_teams);
      }
    });

    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    poolteamlist(id, eid).then((response) => {
      if (response.status === true) {
        plarr = response.data;
        if (response.data.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              id: response.data[i].team_id,
              title: response.data[i].team_name,
              description: (
                <div className="row">
                  <div className="col-3 align-self-center text-center pr-0">
                    <img
                      src={require("../../../../assets/images/results/team.png")}
                      className="header-brand-img mobile-view-logo"
                      alt="team logo"
                    />
                  </div>

                  <div className="col-7 px-0 align-self-center">
                    <h4 className="playername mb-1">
                      {response.data[i].team_name}
                    </h4>

                    <h4 className="playergender mb-0">
                      Code : <span>{response.data[i].team_code}</span>
                    </h4>
                  </div>

                  <div className="col-2 align-self-center">
                    <a
                      className="btn-sm btn-secondary me-2"
                      onClick={() => handleshow(i)}
                    >
                      <i className="fa fa-eye fa-lg"></i>
                    </a>
                  </div>
                </div>
              ),
            });
          }
          board = {
            columns: [
              {
                id: 1,
                cards: arr,
              },
              {
                id: 2,
                cards: [],
              },
            ],
          };
          setBoard({ ...board });
        }
      }
    });
  }, []);

  function backlist() {
    dotracking("back button in tournament schedule team for " + plname + " pool in " + catname + " (" + eid + ") category for " + tname + " (" + id + ") tournament $$ " + trackobj.poollistheading);
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/poollist/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/poollist/" + id + "/" + eid);
    }
  }

  function playersubmit(e) {
    e.preventDefault();
    if (selepla.length !== 0) {
      // if (selepla.length > maxteam) {
      //     Swal.fire({
      //         text: "Maximum " + maxteam + " team is allowed for " + plname + " pool.",
      //         icon: "warning",
      //         showCancelButton: false,
      //         confirmButtonColor: "#4ecc48",
      //         cancelButtonColor: "#6259ca",
      //         confirmButtonText: "Ok",
      //         allowOutsideClick: false,
      //     });
      // } else {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("pool_table_id", tid);
      temp.append("category_id", eid);
      temp.append("tournament_meet_id", id);
      temp.append("teams_count", selepla.length);
      for (let i = 0; i < selepla.length; i++) {
        temp.append("team_" + [i + 1], selepla[i].id);
      }
      dotracking("submit button in tournament schedule team for " + plname + " pool in " + catname + " (" + eid + ") category for " + tname + " (" + id + ") tournament $$ " + trackobj.poollistheading);
      poolteamcreate(temp).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          if (localStorage.getItem("Role") === "1") {
            navigate("/superadmin/poollist/" + id + "/" + eid);
          } else if (localStorage.getItem("Role") === "3") {
            navigate("/cityregional/poollist/" + id + "/" + eid);
          }else if (localStorage.getItem("Role") === "7") {
            navigate("/coach/poollist/" + id + "/" + eid);
          }
        } else {
          setErrorMsg(response.message);
        }
      });
    } else {
      toast.error("Please Select Team");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Create Fixture Team</h4>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Fixture</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Create Fixture Team
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="text-center">
                  <h3>{tname}</h3>
                  <h4 className="text-success tx-20">Pool - {plname}</h4>
                </div>
                <div className="col-md-6">
                  <h4>All Teams</h4>
                </div>
                <div className="col-md-6">
                  <h4>Selected Teams</h4>
                </div>
              </div>

              <ControlledBoard />

              <div className="card-footer text-center border-top">
                <button className="btn btn-gray me-2" onClick={backlist}>
                  Back
                </button>
                <button className="btn btn-success me-2" onClick={playersubmit}>
                  Submit
                </button>
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  <h4>
                    {" "}
                    Players in <span className="text-danger">
                      {tmname}
                    </span>{" "}
                    Team
                  </h4>
                </Modal.Header>

                <Modal.Body>
                  <div className="row tournamentitem">
                    {filterProduct.length === 0 && (
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-lg-12">
                              <b className="text-inverse">No Player Found</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {filterProduct.map((data, i) => {
                      return (
                        <div className="col-md-12" key={i}>
                          <div className="details-group">
                            <div className="row">
                              <div className="col-lg-4 col-md-6">
                                <b className="text-inverse">Player</b>
                              </div>
                              <div className="col-md-8 col-md-6">
                                <span className="text-inverse">
                                  {data.first_name}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-6">
                                <b className="text-inverse">Gender</b>
                              </div>
                              <div className="col-md-8 col-md-6">
                                <span className="text-inverse">
                                  {data.gender}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  {/* <a className="btn btn-success">Add</a> */}

                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  function ControlledBoard() {
    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
      SetSelePla(updatedBoard.columns[1].cards);
      // if (updatedBoard.columns[1].cards.length > maxteam) {
      //     Swal.fire({
      //         text: "Maximum " + maxteam + " team is allowed for " + plname + " pool.",
      //         icon: "warning",
      //         showCancelButton: false,
      //         confirmButtonColor: "#4ecc48",
      //         cancelButtonColor: "#6259ca",
      //         confirmButtonText: "Ok",
      //         allowOutsideClick: false,
      //     });
      // }
    }
    return (
      <Board
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        children={controlledBoard}
      />
    );
  }
};
