import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import {
  athleticstartresultlist,
  athleticstartresultupdate,
  athleticstouraddheattoevents,
  athletictoureventgroupheatlist,
  athletictoureventheatlist,
  athletictoureventstartexceptheatlist,
  athletictoureventstartlist,
  athletictourordereventslist,
  athletictourremoveheatevent,
} from "../../../../../services/common";
import moment, { min } from "moment";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import { coachtourviewdetail } from "../../../../../services/coach";

export const OrderEventsStartList = () => {
  const { id, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterAllProduct, setFilterAllProduct] = useState([]);

  const [filterHeat, setFilterHeat] = useState([]);

  const navigate = useNavigate();
  const [tname, setTName] = useState("");
  const [etype, setEType] = useState(0);

  const [show, setShow] = useState(false);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "user_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== "") {
          return (
            <div className="text-center">
              <a
                className="btn btn-success btn-sm me-2"
                onClick={() => editdet(params.data)}
              >
                <i className="fa fa-edit"></i>
              </a>
              <a
                className="btn btn-danger btn-sm"
                onClick={() => removedet(params.data)}
                title="Delete Player From Heats"
              >
                <i className="fa fa-trash"></i>
              </a>
            </div>
          );
        }
      },
    },
    {
      headerName: "Heat",
      width: 100,
      field: "heat_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Finalist",
      width: 100,
      field: "finals",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Place",
      width: 100,
      field: "position",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.value !== "") {
      //     return params.value;
      //   }
      // }
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 120,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null && params.value !== "") {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 100,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDDefs = [
  
    {
      headerName: "Heat",
      width: 100,
      field: "heat_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Finalist",
      width: 100,
      field: "finals",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Place",
      width: 100,
      field: "position",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.value !== "") {
      //     return params.value;
      //   }
      // }
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 120,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null && params.value !== "") {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 100,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnAllDefs = [
    {
      headerName: "Action",
      width: 80,
      field: "user_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== "") {
          return (
            <div className="text-center">
              <input
                type="checkbox"
                checked={params.data.radiochk}
                onChange={(e) => mergeheats(e, params.value, params.data)}
              />
            </div>
          );
        }
      },
    },
    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null && params.value !== "") {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 100,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnFDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "user_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== "") {
          return (
            <div className="text-center">
              <a
                className="btn btn-success btn-sm"
                onClick={() => editdet(params.data)}
              >
                <i className="fa fa-edit"></i>
              </a>
            </div>
          );
        }
      },
    },
    {
      headerName: "Place",
      width: 100,
      field: "position",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 120,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null && params.value !== "") {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 100,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnSFDefs = [
    {
      headerName: "Place",
      width: 100,
      field: "position",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Full Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 120,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null && params.value !== "") {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 100,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
  ];
  const [hbutton, setHButton] = useState(false);
  function mergeheats(e, hid, hdata) {
    setHButton(false);
    if (filterProduct.length !== 0) {
      for (let i = 0; i < filterProduct.length; i++) {
        if (filterProduct[i].user_id === hid) {
          setHButton(true);
          filterProduct[i].radiochk = e.target.checked;
          setFilterProduct([...filterProduct]);
        }
      }
    }
  }
  const [hshow, setHShow] = useState(false);
  const [hsubmit, setHSubmit] = useState(false);

  function handleheat() {
    setHSubmit(false);
    setHShow(true);
    setHNum("");
  }

  const handleHNum = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setHNum(e.target.value);
    }
  };

  const [hnum, setHNum] = useState("");
  function onsubmit() {
    setHSubmit(true);
    if (hnum !== "") {
      var gdata = "";
      const sdata = filterProduct.filter((x) => x.radiochk === true);
      if (sdata.length !== 0) {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("order_of_events_id", eid);
        temp.append("heat_name", "Heat " + hnum);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("athletes_count", sdata.length);
        for (let i = 0; i < sdata.length; i++) {
          temp.append("user_id_" + [i + 1], sdata[i].user_id);
          if (gdata === "") {
            gdata = sdata[i].first_name + " (" + sdata[i].user_id + ")";
          } else {
            gdata =
              gdata +
              " , " +
              sdata[i].first_name +
              " (" +
              sdata[i].user_id +
              ")";
          }
        }
        dotracking(
          "add heat submit button in order of events list for " +
            gdata +
            " in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourstartlistheading
        );
        athleticstouraddheattoevents(temp).then((response) => {
          if (response.status === true) {
            setHSubmit(false);
            setHShow(false);
            setHButton(false);
            setHNum("");
            listall();
          }
        });
      }
    }
  }

  const [errorMsg, setErrorMsg] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [meter, setMeter] = useState("");
  const [meter1, setMeter1] = useState("");
  const [place, setPlace] = useState("");
  const [status, setStatus] = useState("");

  const [finals, setFinals] = useState(0);
  const [uname, setUName] = useState("");

  function editdet(edata) {
    setShow(true);
    setUName(edata.user_id);
    setErrorMsg("");
    setHours("");
    setMinutes("");
    setSeconds("");
    setMeter("");
    setMeter1("");
    setPlace("");
    setStatus("");
    setFinals(0);
  }

  function removedet(edata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = new FormData();
        temp.append("heat_name_id", edata.heat_name_id);
        dotracking(
          "delete icon in order of events start list for " +
            edata.first_name +
            " (" +
            edata.user_id +
            ") in " +
            tname +
            " (" +
            id +
            ") $$ " +
            trackobj.tourstartlistheading
        );
        athletictourremoveheatevent(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall();
          }
        });
      }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listall();
  }, []);

  const [catname, setCatName] = useState("");
  const [catid, setCatID] = useState("");
  const [eventid, setEventID] = useState("");
  const [eventname, setEventName] = useState("");
  const [listtype, setListType] = useState("");

  function listall() {
    setFilterProduct([]);
    setFilterAllProduct([]);
    setFilterHeat([]);
    setHButton(false);

    athletictourordereventslist(id).then((res) => {
      if (res.status === true) {
        const odata = res.data.find(
          (item) => item.events_order_id === parseInt(eid)
        );
        if (odata !== undefined) {
          const temp = new FormData();
          temp.append("tournament_meet_id", id);
          temp.append("age_category_id", odata.age_category_id);
          temp.append("age_category", odata.age_categories);
          temp.append("gender", odata.gender);
          temp.append("event_id", odata.event_id);
          temp.append("event_name", odata.events);
          temp.append("heats_finals", odata.heat_or_final);
          temp.append("order_of_events_id", eid);
          athletictoureventstartexceptheatlist(temp).then((response) => {
            if (response.status === true) {
              setCatName(response.data.order_of_events_info.age_categories);
              setCatID(response.data.order_of_events_info.age_category_id);
              setEventID(response.data.order_of_events_info.event_id);
              setEventName(response.data.order_of_events_info.events);
              setListType(response.data.order_of_events_info.heat_or_final);
              let arr = [];
              setEType(response.data.events_info.event_type);
              if (response.data.start_list.length !== 0) {
                // let temp = 1;
                // var count = 1;
                // var obj = 0;
                // if(response.data.events_info.event_type === 1){
                //   if (response.data.age_category_info.athletes_per_track_count !== 0) {
                //     obj = response.data.age_category_info.athletes_per_track_count;
                //   } else {
                //     obj = 8;
                //   }
                // }else if(response.data.events_info.event_type === 3){
                //   if (response.data.age_category_info.athletes_per_field_count !== 0) {
                //     obj = response.data.age_category_info.athletes_per_field_count;
                //   } else {
                //     obj = 8;
                //   }
                // }

                for (let i = 0; i < response.data.start_list.length; i++) {
                  // if (count > obj) {
                  //   temp = temp + 1;
                  //   count = 1;

                  // }
                  // count = count + 1;
                  arr.push({
                    // heat: "Heat " + temp,
                    user_id: response.data.start_list[i].user_id,
                    first_name: response.data.start_list[i].first_name,
                    date_of_birth: response.data.start_list[i].date_of_birth,
                    class_name: response.data.start_list[i].class_name,
                    gender: response.data.start_list[i].gender,
                    school_id: response.data.start_list[i].school_id,
                    school_name: response.data.start_list[i].school_name,
                    username: response.data.start_list[i].username,
                    bib_no: response.data.start_list[i].bib_no,
                    finals_result_id:
                      response.data.start_list[i].finals_result_id,
                    heats_result_id:
                      response.data.start_list[i].heats_result_id,
                    position: 0,
                    time_distance: "",
                    finals: "",
                    radiochk: false,
                  });

                  setFilterProduct([...arr]);
                }
                if(response.data.order_of_events_info.heat_or_final === "Finals"){
                  if (arr.length !== 0) {
                    for (let i = 0; i < arr.length; i++) {
                      if (
                        arr[i].finals_result_id > 0 ||
                        arr[i].heats_result_id > 0
                      ) {
                        const temp = new FormData();
                        if (
                          response.data.order_of_events_info.heat_or_final ===
                          "Finals"
                        ) {
                          temp.append("results_id", arr[i].finals_result_id);
                        } else {
                          temp.append("results_id", arr[i].heats_result_id);
                        }
                        athleticstartresultlist(temp).then((res1) => {
                          if (res1.status === true) {
                            if (
                              arr[i].user_id === res1.data.results_info.user_id
                            ) {
                              arr[i].position = res1.data.results_info.place;
  
                              arr[i].time_distance =
                                res1.data.results_info.time_distance;
                              if (
                                response.data.order_of_events_info
                                  .heat_or_final === "Finals"
                              ) {
                                arr[i].finals = "Yes";
                              } else {
                                if (arr[i].finals_result_id > 0) {
                                  arr[i].finals = "Yes";
                                } else {
                                  arr[i].finals = "No";
                                }
                              }
                            }
                            setFilterProduct([...arr]);
                          }
                        });
                      }
                    }
                  }
                }
                
              }
            }
          });

          const temp1 = new FormData();
          temp1.append("tournament_meet_id", id);
          temp1.append("age_category_id", odata.age_category_id);
          temp1.append("age_category", odata.age_categories);
          temp1.append("gender", odata.gender);
          temp1.append("event_id", odata.event_id);
          temp1.append("event_name", odata.events);
          temp1.append("heats_finals", odata.heat_or_final);
          temp1.append("order_of_events_id", eid);
          athletictoureventheatlist(temp1).then((response) => {
            if (response.status === true) {
              setFilterAllProduct(response.data.start_list);
              let arr = [];
              if (response.data.start_list.length !== 0) {
                for (let j = 0; j < response.data.start_list.length; j++) {
                  arr.push({
                    user_id: response.data.start_list[j].user_id,
                    first_name: response.data.start_list[j].first_name,
                    date_of_birth: response.data.start_list[j].date_of_birth,
                    class_name: response.data.start_list[j].class_name,
                    gender: response.data.start_list[j].gender,
                    school_id: response.data.start_list[j].school_id,
                    school_name: response.data.start_list[j].school_name,
                    username: response.data.start_list[j].username,
                    bib_no: response.data.start_list[j].bib_no,
                    finals_result_id:
                      response.data.start_list[j].finals_result_id,
                    heats_result_id:
                      response.data.start_list[j].heats_result_id,
                    heat_name_id: response.data.start_list[j].heat_name_id,
                    heat_name: response.data.start_list[j].heat_name,
                    position: 0,
                    time_distance: "",
                    finals: "",
                  });
                }
              }
              setFilterHeat(arr);

              if (arr.length !== 0) {
                for (let i = 0; i < arr.length; i++) {
                  if (
                    arr[i].finals_result_id > 0 ||
                    arr[i].heats_result_id > 0
                  ) {
                    const temp = new FormData();
                    if (
                      response.data.order_of_events_info.heat_or_final ===
                      "Finals"
                    ) {
                      temp.append("results_id", arr[i].finals_result_id);
                    } else {
                      temp.append("results_id", arr[i].heats_result_id);
                    }
                    athleticstartresultlist(temp).then((res1) => {
                      if (res1.status === true) {
                        if (arr[i].user_id === res1.data.results_info.user_id) {
                          arr[i].position = res1.data.results_info.place;

                          arr[i].time_distance =
                            res1.data.results_info.time_distance;
                          if (
                            response.data.order_of_events_info.heat_or_final ===
                            "Finals"
                          ) {
                            arr[i].finals = "Yes";
                          } else {
                            if (arr[i].finals_result_id > 0) {
                              arr[i].finals = "Yes";
                            } else {
                              arr[i].finals = "No";
                            }
                          }
                        }
                        setFilterHeat([...arr]);
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    });
  }

  function heatsubmit() {
    setErrorMsg("");
    var valid = "";
    if (place !== "0") {
      if (etype !== 0) {
        if (etype === 3) {
          if (meter !== "") {
            if (meter.length >= 2) {
              if (meter1 !== "") {
                valid = "";
              } else {
                valid = "required";
                setErrorMsg("Please Enter Distance (Centimeter)");
              }
            } else {
              valid = "required";
              setErrorMsg(
                "Please Enter Distance (Centimeter) must be 2 characters"
              );
            }
          } else {
            valid = "required";
            setErrorMsg("Please Enter Distance (Meter)");
          }
        } else {
          if (hours !== "") {
            if (hours.length >= 2) {
              if (minutes !== "") {
                if (minutes.length >= 2) {
                  if (seconds !== "") {
                    if (seconds.length >= 2) {
                      valid = "";
                    } else {
                      valid = "required";
                      setErrorMsg(
                        "Please Enter Time (Milli Seconds) must be 2 characters"
                      );
                    }
                  } else {
                    valid = "required";
                    setErrorMsg("Please Enter Time (Milli Seconds)");
                  }
                } else {
                  valid = "required";
                  setErrorMsg(
                    "Please Enter Time (Seconds) must be 2 characters"
                  );
                }
              } else {
                valid = "required";
                setErrorMsg("Please Enter Time (Seconds)");
              }
            } else {
              valid = "required";
              setErrorMsg("Please Enter Time (Minutes) must be 2 characters");
            }
          } else {
            valid = "required";
            setErrorMsg("Please Enter Time (Minutes)");
          }
        }
      }
    } else {
      if (status === "") {
        valid = "required";
        setErrorMsg("Please Enter Status");
      }
    }

    if (valid === "") {
      if (place !== "") {
        let hdata =""
        if(listtype === "Finals"){
          hdata= filterProduct.find(
            (item) => item.user_id === parseInt(uname)
          );
        }else{
          hdata = filterAllProduct.find(
            (item) => item.user_id === parseInt(uname)
          );
        }
        
        if (hdata !== "") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          if (hdata.heats_result_id !== null) {
            temp.append("heats_result_id", hdata.heats_result_id);
          } else {
            temp.append("heats_result_id", 0);
          }
          if (hdata.finals_result_id !== null) {
            temp.append("finals_result_id", hdata.finals_result_id);
          } else {
            temp.append("finals_result_id", 0);
          }
          temp.append("tournament_meet_id", id);
          temp.append("user_id", hdata.user_id);
          temp.append("username", hdata.username);
          temp.append("full_name", hdata.first_name);
          temp.append("bib_no", hdata.bib_no);
          temp.append("age_category_id", catid);
          temp.append("age_category", catname);
          temp.append("event_id", eventid);
          temp.append("event_name", eventname);
          temp.append("school_id", hdata.school_id);
          var time_dist = "";
          if (place !== "0") {
            if (etype === 3) {
              time_dist = meter + "." + meter1 + "M";
              temp.append("time_distance", meter + "." + meter1 + "M");
            } else {
              time_dist = hours + ":" + minutes + "." + seconds;
              temp.append(
                "time_distance",
                hours + ":" + minutes + "." + seconds
              );
            }
          } else {
            time_dist = status;
            temp.append("time_distance", status);
          }

          temp.append("place", place);
          temp.append("heats_finals", listtype);
          temp.append("finalist_or_not", finals);
          dotracking(
            "submit button in order of events start list for " +
              time_dist +
              " Place " +
              place +
              " for " +
              hdata.first_name +
              " (" +
              hdata.username +
              " - " +
              hdata.user_id +
              ") Age Category " +
              catname +
              " (" +
              catid +
              ") Event " +
              eventname +
              " (" +
              eventid +
              ") " +
              listtype +
              " in School " +
              hdata.school_name +
              "  " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.tourstartlistheading
          );
          athleticstartresultupdate(temp).then((response) => {
            if (response.status === true) {
              setShow(false);
              listall();
              toast.success(response.data.message);
            }
          });
        }
      } else {
        valid = "required";
        setErrorMsg("Please Enter Place");
      }
    }
  }

  function backlist() {
    dotracking(
      "back button in order of events list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/orderofeventlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/orderofeventlist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/orderofeventlist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/orderofeventlist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/orderofeventlist/" + id);
    }
  }
  const gridRef = useRef();

  const onexport = useCallback(() => {
    // gridRef.current.api.exportDataAsExcel();
    var excelParams = {
      allColumns: false,
      columnKeys: [
        "position",
        "time_distance",
        "finals",
        "heat_name",
        "first_name",
        "username",
        "bib_no",
        "date_of_birth",
        "class_name",
        "gender",
        "school_name",
      ],
      fileName: "Order of Events Start List.csv",
    };
    dotracking(
      "export button in order of events Start list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourstartlistheading
    );
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  const handleHour = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setHours(e.target.value);
    }
  };

  const handleMin = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMinutes(e.target.value);
    }
  };

  const handleSec = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setSeconds(e.target.value);
    }
  };

  const handleMeter = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMeter(e.target.value);
    }
  };

  const handleCenti = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMeter1(e.target.value);
    }
  };

  const handlePlace = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPlace(e.target.value);
    }
  };

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Order of Events Start Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Order of Events Start Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-6">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                      <h4 className="text-danger">
                        Age Category - {catname} , Events - {eventname}{" "}
                        <span className="text-success">({listtype})</span>
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                        <a
                          onClick={onexport}
                          className="btn btn-secondary me-2 mt-1"
                        >
                          <i className="fa-solid fa-file-export"></i> Export
                        </a>
                      )}

                      <a onClick={backlist} className="btn btn-gray mt-1">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {listtype !== "Finals" &&
              filterHeat.length > 0 &&
              (localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3" ) && (
                <>
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: "auto" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterHeat}
                      pagination="true"
                      paginationPageSize="100"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      domLayout="autoHeight"
                    />
                  </div>
                  <hr />
                </>
              )}

            {listtype !== "Finals" &&
              filterHeat.length > 0 &&
              (localStorage.getItem("Role") === "4" ||
                localStorage.getItem("Role") === "5" ||
                localStorage.getItem("Role") === "6" || localStorage.getItem("Role") === "7") && (
                <>
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: "auto" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDDefs}
                      rowData={filterHeat}
                      pagination="true"
                      paginationPageSize="100"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      domLayout="autoHeight"
                    />
                  </div>
                  <hr />
                </>
              )}
            {listtype !== "Finals" &&
              hbutton &&
              (localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3") && (
                <div className="text-right">
                  <a className="btn btn-success" onClick={handleheat}>
                    Add Heat
                  </a>
                </div>
              )}
            <div className="ag-theme-alpine ag-style mt-2" style={{ height: "auto" }}>
              {listtype === "Finals" &&
                (localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                  <AgGridReact
                  ref={gridRef}
                    columnDefs={columnFDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="100"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoHeight"
                  />
                )}

              {listtype === "Finals" &&
                (localStorage.getItem("Role") === "4" ||
                  localStorage.getItem("Role") === "5" ||
                  localStorage.getItem("Role") === "6" ) && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnSFDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="100"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoHeight"
                  />
                )}

              {listtype !== "Finals" &&
                (localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3") && (
                  <AgGridReact
                    columnDefs={columnAllDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="100"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoHeight"
                  />
                )}

              {listtype !== "Finals" &&
                (localStorage.getItem("Role") === "4" ||
                  localStorage.getItem("Role") === "5" ||
                  localStorage.getItem("Role") === "6" || localStorage.getItem("Role") === "7") && (
                  <AgGridReact
                    columnDefs={columnAllDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="100"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoHeight"
                  />
                )}
            </div>

            <Modal show={hshow} size="lg">
              <Modal.Header>
                <h4>
                  {" "}
                  Add Heat{" "}
                  <span className="text-danger">
                    ( Age Category - {catname} , Events - {eventname} ){" "}
                  </span>
                </h4>
              </Modal.Header>
              <Modal.Body>
                <Row className="row-sm mx-0">
                  <Col lg={6} md={6}>
                    <div className="form-group">
                      <label className="mb-2">
                        Heat Number <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Heat Number"
                        className="form-control"
                        value={hnum}
                        maxLength={3}
                        onChange={handleHNum}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      {hsubmit && hnum === "" && (
                        <span className="errortext">
                          Heat Number is required
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <a className="btn btn-danger" onClick={() => setHShow(false)}>
                  Close
                </a>

                <a className="btn btn-success" onClick={onsubmit}>
                  Submit
                </a>
              </Modal.Footer>
            </Modal>

            <Modal show={show} size="lg">
              <Modal.Header>
                <h4> Edit Details</h4>
              </Modal.Header>
              <Modal.Body>
                <Row className="row-sm mx-0">
                  <Col lg={6} md={6}>
                    <div className="form-group">
                      <label className="mb-2">
                        Place <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Place"
                        className="form-control"
                        value={place}
                        maxLength={2}
                        onChange={handlePlace}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>
                  </Col>
                  {listtype !== "Finals" && place !== "0" && (
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Finalist <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control"
                          value={finals}
                          onChange={(e) => setFinals(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Finalist ---
                          </option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </Col>
                  )}
                  {etype !== 3 && etype !== 0 && place !== "0" && (
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Time{" "}
                          <span className="errortext">
                            {" "}
                            * (Format like MM:SS.MS)
                          </span>
                        </label>
                        <Row className="row-sm mx-0">
                          <Col lg={3} md={3}>
                            <input
                              type="text"
                              placeholder="Minutes"
                              className="form-control"
                              value={hours}
                              maxLength={2}
                              onChange={handleHour}
                            />
                          </Col>
                          <Col lg={1} md={1}>
                            <span className="tx-20">:</span>
                          </Col>
                          <Col lg={3} md={3}>
                            <input
                              type="text"
                              placeholder="Seconds"
                              className="form-control"
                              value={minutes}
                              maxLength={2}
                              onChange={handleMin}
                            />
                          </Col>
                          <Col lg={1} md={1}>
                            <span className="tx-20">.</span>
                          </Col>
                          <Col lg={4} md={4}>
                            <input
                              type="text"
                              placeholder="Milli Seconds"
                              className="form-control"
                              value={seconds}
                              maxLength={2}
                              onChange={handleSec}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  )}
                  {etype === 3 && etype !== 0 && place !== "0" && (
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Distance{" "}
                          <span className="errortext">
                            {" "}
                            * (Format like 88.9 M)
                          </span>
                        </label>
                        <Row className="row-sm mx-0">
                          <Col lg={5} md={5}>
                            <input
                              type="text"
                              placeholder="Meter"
                              className="form-control"
                              value={meter}
                              maxLength={3}
                              onChange={handleMeter}
                            />
                          </Col>
                          <Col lg={1} md={1}>
                            <span className="tx-20">.</span>
                          </Col>
                          <Col lg={5} md={5}>
                            <input
                              type="text"
                              placeholder="Centimeter"
                              className="form-control"
                              value={meter1}
                              maxLength={2}
                              onChange={handleCenti}
                            />
                          </Col>
                          <Col lg={1} md={1}>
                            <span className="tx-20">M</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  )}
                  {place === "0" && (
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Status"
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        />
                      </div>
                    </Col>
                  )}

                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <a className="btn btn-danger" onClick={() => setShow(false)}>
                  Close
                </a>

                <a className="btn btn-success" onClick={heatsubmit}>
                  Submit
                </a>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
