import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import {
  eventscreate,
  eventsdetail,
  eventsmasterlist,
  eventstatus,
  eventsupdate,
  eventsvalidate,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const EventMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [eventid, setId] = useState(0);
  const [eventname, setEventName] = useState("");
  const [eventtype, setEventType] = useState("");
  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Event",
      field: "event_name",
      sortable: true,
      resizable: true,
      sorting: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      field: "event_type_name",
      sortable: true,
      resizable: true,
      sorting: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deleteevent(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    // {
    //   headerName: "Actions",
    //   field: "event_uid",
    //   resizable: true,
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <div className="text-center">
    //         <a
    //           onClick={() => viewevent(params.value)}
    //           title="Edit Events"
    //           className="btn-sm btn-secondary"
    //         >
    //           <i className="fa fa-edit fa-lg"></i>
    //         </a>
    //       </div>
    //     );
    //   },
    // },
  ];

  function viewevent(aid) {
    setShow(true);
    eventsdetail(aid).then((response) => {
      if (response.status === true) {
    dotracking("edit button in events for " + response.data.event_name + " (" + aid + ") $$ " + trackobj.eventlistheading);

        setId(response.data.event_uid);
        setEventName(response.data.event_name);
        if (response.data.event_type !== null) {
          setEventType(response.data.event_type);
        }
        setStatus(response.data.is_active + "");
      }
    });
  }

  function deleteevent(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("event_uid", list.data.event_uid);
          temp.append("is_active", 0);
          dotracking(
            "status icon in events list from Active to Inactive for " + list.data.event_name + " ("
              + list.data.event_uid + ") $$ " + trackobj.eventlistheading
          );
          eventstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("event_uid", list.data.event_uid);
          temp.append("is_active", 1);
          dotracking(
            "status icon in events list from Inactive to Active for " + list.data.event_name + " ("
              + list.data.event_uid + ") $$ " + trackobj.eventlistheading
          );
          eventstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function handleshow() {
    setId(0);
    setEventName("");
    setEventType("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setEventName("");
    setEventType("");
    setStatus("1");
    setErrorMsg("");
  }
  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    eventsmasterlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submitrole(e) {
    e.preventDefault();
    setErrorMsg("");
    if (eventname !== "") {
      if (eventtype !== "") {
        if (eventid === 0) {
          
          // eventsvalidate(eventname).then((res) => {
          //   if (res.status === true) {
              const temp = new FormData();
              temp.append("event_name", eventname);
              temp.append("event_type", eventtype);
              temp.append("display_order", 0);
              var etype = ""
              if (eventtype === "1") {
                etype="Track"
                temp.append("event_type_name", "Track");
              } else if (eventtype === "2") {
                etype="Relay"
                temp.append("event_type_name", "Relay");
              }else if (eventtype === "3") {
                etype="Field"
                temp.append("event_type_name", "Field");
              }
              temp.append("is_active", status);
              dotracking("add button in events for " + eventname + " with type " + etype + " $$ " + trackobj.eventlistheading);

              eventscreate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                }
              });
            // } else {
            //   Swal.fire({
            //     text: eventname + " " + res.message,
            //     icon: "warning",
            //     showCancelButton: false,
            //     confirmButtonColor: "#4ecc48",
            //     cancelButtonColor: "#6259ca",
            //     confirmButtonText: "Ok",
            //     allowOutsideClick: false,
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //       setId(0);
            //       setEventName("");
            //       setEventType("");
            //       setStatus("1");
            //       setErrorMsg("");
            //     }
            //   });
            // }
          // });
        } else if (eventid !== 0) {
          const temp = new FormData();
          temp.append("event_uid", eventid);
          temp.append("display_order", 0);
          temp.append("event_name", eventname);
          temp.append("event_type", eventtype);
          var etype=""
          if (eventtype === "1") {
            etype="Track"
            temp.append("event_type_name", "Track");
          } else if (eventtype === "2") {
            etype="Relay"
            temp.append("event_type_name", "Relay");
          } else if (eventtype === "3") {
            etype="Field"
            temp.append("event_type_name", "Field");
          }
          temp.append("is_active", status);
          dotracking("update button in events for " + eventname + " ("+ eventid +") with type " + etype + " $$ " + trackobj.eventlistheading);

          eventsupdate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Event Type");
      }
    } else {
      setErrorMsg("Please Enter Event Name");
    }
  }

  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      allColumns: false,
      fileName: "Events List.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Events Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Events Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              {localStorage.getItem("Subrole") === "1" && (
                <div className="text-right">
                
                  <a className="btn btn-success" onClick={handleshow}>
                    <i className="fa fa-plus"></i> Add Event
                  </a>
                </div>
              )}
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {eventid !== 0 && <h4> Event Details</h4>}
                  {eventid === 0 && <h4> Add Event</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Event Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Event Name"
                          className="form-control"
                          value={eventname}
                          onChange={(e) => setEventName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Event Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={eventtype}
                          onChange={(e) => setEventType(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Type ---
                          </option>

                          <option value="1">Track</option>
                          <option value="2">Relay</option>
                          <option value="3">Field</option>

                        </select>
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {eventid !== 0 && (
                    <a className="btn btn-success" onClick={submitrole}>
                      Update
                    </a>
                  )}
                  {eventid === 0 && (
                    <a className="btn btn-success" onClick={submitrole}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
