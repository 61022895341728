import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import {
  multipleroleslist,
  multirolesdetails,
} from "../../../../services/superadmin";
import moment from "moment";
import { crmallview } from "../../../../services/cityregionaladmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ViewSchoolHead = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [crmid, setCRM] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [schname, setSchool] = useState("");
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(false);
  const [ishow, setImage] = useState(false);

  const [uname, setUName] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      multirolesdetails(4, id).then((response) => {
        if (response.status === true) {
          setFName(response.data.details.first_name);
          setLName(response.data.details.last_name);
          if (response.data.details.dob !== null) {
            setDOB(moment(response.data.details.dob).format("DD-MM-yyyy"));
          }
          setUName(response.data.details.username);
          setPass(response.data.password);
          setGender(response.data.details.gender);
          setEmail(response.data.details.email_id);
          setMobNo(response.data.details.mobile_no);
          // setCRM(response.data.details.city_admin_name);
          setState(response.data.details.statename);
          setDistrict(response.data.details.districtname);
          setCity(response.data.details.cityname);
          setSchool(response.data.details.school_name);
          if (response.data.details.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.details.photograph);
          }

          if (response.data.details.city_admin_id !== null) {
            multipleroleslist(3, 1).then((res) => {
              if (res.status === true) {
                if (res.data.length !== 0) {
                  const data = res.data.find(
                    (item) => item.user_id == response.data.details.city_admin_id
                  );
                  if (data !== undefined) {
                    if (data.last_name !== null) {
                      setCRM(data.first_name + " " + data.last_name);
                    } else {
                      setCRM(data.first_name);
                    }
                  }
                }
              }
            });
          }
        }
      });
    }else if(localStorage.getItem("Role") === "3"){
      crmallview(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.first_name);
          setLName(response.data.last_name);
          if (response.data.dob !== null) {
            setDOB(moment(response.data.dob).format("DD-MM-yyyy"));
          }

          setGender(response.data.gender);
          setEmail(response.data.email_id);
          setMobNo(response.data.mobile_no);
          // setCRM(response.data.city_admin_name);
          setState(response.data.statename);
          setDistrict(response.data.districtname);
          setCity(response.data.cityname);
          setSchool(response.data.school_name);
          if (response.data.photograph !== null) {
            setPhoto(response.data.photo_path + response.data.photograph);
          }

          // if (response.data.city_admin_id !== null) {
          //   multipleroleslist(3, 1).then((res) => {
          //     if (res.status === true) {
          //       if (res.data.length !== 0) {
          //         const data = res.data.find(
          //           (item) => item.user_id == response.data.city_admin_id
          //         );
          //         if (data !== undefined) {
          //           if (data.last_name !== null) {
          //             setCRM(data.first_name + " " + data.last_name);
          //           } else {
          //             setCRM(data.first_name);
          //           }
          //         }
          //       }
          //     }
          //   });
          // }
        }
      });
    }
  }, []);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function editlist(){
    dotracking("edit button in view schoolhead for " + fname + " " + lname + " (" + email + " - "+ id + ") $$ " + trackobj.schheadeditheading);
    if(localStorage.getItem("Role") === "1"){
      navigate("/superadmin/schoolheaddetail/" + id)
    }else if(localStorage.getItem("Role") === "3"){
      navigate("/cityregional/schoolheaddetail/" + id)
    }
  }

  function backlist(){
    dotracking("back button in view schoolhead for " + fname + " " + lname + " (" + email + " - "+ id + ") $$ " + trackobj.schheadlistheading);
    if(localStorage.getItem("Role") === "1"){
      navigate("/superadmin/schoolheadlist")
    }else if(localStorage.getItem("Role") === "3"){
      navigate("/cityregional/schoolheadlist")
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Head Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>School Head</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            School Head Details
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <h4>Primary Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">First Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{fname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Last Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{lname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{dob}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Contact Number</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mobno}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Email Address</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Username</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{uname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {localStorage.getItem("Role") === "1" &&
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Password</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{pass}</span>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>

              <hr />
              <h4>Personal Details</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{state}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{district}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">City</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{city}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">School</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{schname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {localStorage.getItem("Role") !== "3" &&
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-4">
                        <b className="text-inverse">Manager</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{crmid}</span>
                      </div>
                    </div>
                  </div>
                </div>
                }
                
              </div>
              <hr />
              <h4>Photograph</h4>
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="link-btn">
                        <a>
                          <img
                            className="profile_img"
                            src={photo}
                            height="160"
                            alt="photo"
                            onClick={(e) => ImageShow(photo)}
                            onError={(e) => errorhandle(e)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="card-footer text-right border-top">
                <a
                  className="btn btn-gray me-2"
                  onClick={backlist}
                >
                  Back
                </a>
                <a
                  className="btn btn-secondary me-2"
                  onClick={editlist}
                >
                  Edit
                </a>
              </div>
            </div>
          </form>
          <Modal show={ishow} onHide={ImageClose}>
            <Modal.Body>
              <img
                className="profile_img prof-img"
                src={file}
                height="160"
                alt="photo"
                onError={(e) => errorhandle(e)}
              />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-secondary text-right"
                onClick={ImageClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
