import React, { Fragment, useEffect, useState } from "react";
import {
  schoolheadcalenderlist,
  schoolheadothereventlist,
  schooltourlist,
  schprofileview,
} from "../../../services/schoolhead";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import { playertourbanner } from "../../../services/player";
import bannerlog from "../../trackingbanner";
import { interschooltourpaidornot } from "../../../services/interschool";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Col, Modal, Row } from "react-bootstrap";
export const SchoolDashboard = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [cfilterProduct, setCFilterProduct] = useState([]);
  const [eventProduct, setEventProduct] = useState([]);
  const [ceventProduct, setCEventProduct] = useState([]);
  const [events, setEvents] = useState([]);

  const [tourbanner, setTourBanner] = useState("");
  const [linkbanner, setLinkBanner] = useState("");
  const [bannid, setBannerID] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const temp = new FormData();
    temp.append("tournament_meet_id", 0);
    temp.append("pagename", "Dashboard");
    playertourbanner(temp).then((res) => {
      if (res.status === true && res.data.banners !== null) {
        setBannerID(res.data.banners.banner_id);
        setTourBanner(res.data.banner_path + res.data.banners.b_file);
        if (res.data.banners.banner_link !== null) {
          setLinkBanner(res.data.banners.banner_link);
        }
      }
    });

    schooltourlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.upcming_tournaments);
        setCFilterProduct(response.data.completed_tournaments);
      }
    });

    schprofileview().then((res) => {
      if (res.status === true) {
        schoolheadothereventlist(res.data.school_id).then((response) => {
          if (response.status === true) {
            setEventProduct(response.data.upcoming_events);
            setCEventProduct(response.data.complete_events);
          }
        });
        const tempc = new FormData();
        tempc.append("school_id", res.data.school_id);
        schoolheadcalenderlist(tempc).then((response) => {
          if (response.status === true) {
            let schedule = [];
            if (response.data.tournament_and_events.length !== 0) {
              for (
                let i = 0;
                i < response.data.tournament_and_events.length;
                i++
              ) {
                var clr = "";
                var clink = "";
                const url = window.location.href;
                const head = url.split("/#/");
                if (
                  response.data.tournament_and_events[i].type === "Tournament"
                ) {
                  clr = "#005baa";
                  clink =
                    `${head[0]}/#/schoolhead/tournamentdetails/` +
                    response.data.tournament_and_events[i].id;
                }else if (
                  response.data.tournament_and_events[i].type === "External_1" || response.data.tournament_and_events[i].type === "External"
                ) {
                  clr = "#c21a1a";
                  clink =
                    `${head[0]}/#/schoolhead/externaltournamentdetail/` +
                    response.data.tournament_and_events[i].id;
                }  else {
                  clr = "#e59216";
                  clink =
                    `${head[0]}/#/schoolhead/othereventdetail/` +
                    response.data.tournament_and_events[i].id;
                }
                schedule.push({
                  title: response.data.tournament_and_events[i].meet_name,
                  start: response.data.tournament_and_events[i].startdate,
                  end: response.data.tournament_and_events[i].enddate,
                  description: response.data.tournament_and_events[i].type,
                  url: clink,
                  color: clr,
                });
              }
              setEvents(schedule);
            }
          }
        });
      }
    });
  }, []);

  function banlink() {
    dotracking(
      "banner in dashboard for " +
        tourbanner +
        " (" +
        bannid +
        ") $$ " +
        trackobj.dashheading
    );
    bannerlog(bannid, trackobj.bannerdashboard);
    if (linkbanner !== "") {
      window.open(linkbanner, "_blank");
    }
  }
  function addlist(vid, vname, vstat) {
    if (vstat === 1) {
      dotracking(
        "confirmation list in tournament list for " +
          vname +
          " (" +
          vid +
          ") tournament $$ " +
          trackobj.confirmplayerheading
      );
      navigate("/schoolhead/confirmation/" + vid);
    } else {
      dotracking(
        "confirmation list in tournament list for " +
          vname +
          " (" +
          vid +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
      navigate("/schoolhead/playerlist/" + vid);
    }
  }

  function addsportlist(vid, vname, vstat) {
    // if (vstat === 1) {
    //   dotracking(
    //     "confirmation list in tournament list for " +
    //       vname +
    //       " (" +
    //       vid +
    //       ") tournament $$ " +
    //       trackobj.confirmplayerheading
    //   );
    //   navigate("/schoolhead/categorylist/" + vid);
    // } else {

    // schprofileview(localStorage.getItem("UID")).then((res) => {
    //   if (res.status === true) {
    //     const temp = new FormData();
    //     temp.append("tournament_meet_id", vid);
    //     temp.append("school_id", res.data.school_id);

    //     interschooltourpaidornot(temp).then((response) => {
    //       if (response.status === true) {
    if (vstat === 1) {
      dotracking(
        "category list in tournament list for " +
          vname +
          " (" +
          vid +
          ") tournament $$ " +
          trackobj.categorylistheading
      );
      localStorage.setItem("Age", "2");
      navigate("/schoolhead/intercategorylist/" + vid);
    } else {
      dotracking(
        "interschool tournament registeration in tournament list for " +
          vname +
          " (" +
          vid +
          ") tournament $$ " +
          trackobj.touragecatpaymentheading
      );
      localStorage.setItem("Age", "2");
      navigate("/schoolhead/interschoolpaymentlist/" + vid);
    }
    //       }
    //     });
    //   }
    // });

    // }
  }

  function viewlist(vid, vname) {
    localStorage.setItem("Page", "2");
    dotracking(
      "view button in dashboard for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );

    navigate("/schoolhead/tournamentdetails/" + vid);
  }

  function compviewlist(vid, vname, vtype) {
    localStorage.setItem("Page", "2");
    dotracking(
      "view button in dashboard for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (vtype === "External") {
      navigate("/schoolhead/achievementtournament/" + vid);
    } else {
      navigate("/schoolhead/tournamentdetails/" + vid);
    }
  }

  function vieweventlist(vid, vname) {
    dotracking(
      "view event button in dashboard for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.workdetheading
    );
    localStorage.setItem("EPage", "1");
    navigate("/schoolhead/othereventdetail/" + vid);
  }

  const handleEventClick = (info) => {
    const url = info.event.url;
    const head = url.split("/#/");
    const link = head[1].split("/");
    const curl = link[1];
    if(curl === "tournamentdetails"){
      localStorage.setItem("Page",2)
    }else{
      localStorage.setItem("EPage",1)
    }
    const clickedEvent = {
      title: info.event.title,
      start: info.event.startStr,
      end: info.event.endStr,
      description: info.event.description,
      url: info.event.url,
    };
    openModal(clickedEvent);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent("");
    setModalIsOpen(false);
  };

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Dashboard</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </div>

        <div className="row row-cards">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-info">
                  <i className="icon-people text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">UID</p>
                <p className="h3 text-center text-info">
                  {localStorage.getItem("Name")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-primary">
                  <i className="icon icon-location-pin text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Name</p>
                <p className="h3 text-center text-primary-1">
                  {localStorage.getItem("User")}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-teal">
                  <i className="fa fa-bank text-white-1 text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Sports</p>
                <p className="h3 text-center text-teal">Football</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg icons shadow-default bg-purple">
                  <i className="fa fa-soccer-ball-o text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Achievements</p>
                <p className="h3 text-center text-purple">0</p>
              </div>
            </div>
          </div> */}
        </div>
        {tourbanner !== "" && (
          <a onClick={banlink}>
            <img
              src={tourbanner}
              className="ag-courses-item_img border-bottom"
              alt="bannerimg"
            />
          </a>
        )}

        <div className="ag-format-container">
          <div className="ag-courses_box">
            {filterProduct.length !== 0 && (
              <div className="ag_courses_head mt-4">
                <h3>Upcoming Tournaments</h3>
              </div>
            )}
            {filterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.meet_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_open).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_deadline_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      {data.sports_type.toUpperCase() === "ATHLETICS" &&
                        data.meettype === "Inter School Tournament" &&
                        moment(new Date()).format("yyyy-MM-DD") <=
                          data.entry_deadline_date &&
                        data.paid_status !== 1 && (
                          <a
                            className="btn btn-sm btn-success float-left"
                            onClick={() =>
                              addlist(data.id, data.meet_name, data.paid_status)
                            }
                          >
                            Register
                          </a>
                        )}

                      {data.sports_type.toUpperCase() === "ATHLETICS" &&
                        data.meettype === "Inter School Tournament" &&
                        data.paid_status === 1 && moment(new Date()).format("yyyy-MM-DD") <=
                        data.entry_deadline_date &&(
                          <a
                            className="btn btn-sm btn-gray float-left"
                            onClick={() =>
                              addlist(data.id, data.meet_name, data.paid_status)
                            }
                          >
                            View Entries
                          </a>
                        )}

                      {data.sports_type.toUpperCase() !== "ATHLETICS" &&
                        data.meettype === "Inter School Tournament" &&
                        data.paid_status !== 1 &&
                        moment(new Date()).format("yyyy-MM-DD") <=
                          data.entry_deadline_date && (
                          <a
                            className="btn btn-sm btn-success float-left"
                            onClick={() =>
                              addsportlist(
                                data.id,
                                data.meet_name,
                                data.paid_status
                              )
                            }
                          >
                            Register
                          </a>
                        )}
                      {data.sports_type.toUpperCase() !== "ATHLETICS" &&
                        data.meettype === "Inter School Tournament" &&
                        data.paid_status === 1 &&
                        moment(new Date()).format("yyyy-MM-DD") <=
                          data.entry_deadline_date && (
                          <a
                            className="btn btn-sm btn-success float-left"
                            onClick={() =>
                              addsportlist(
                                data.id,
                                data.meet_name,
                                data.paid_status
                              )
                            }
                          >
                            Add Teams
                          </a>
                        )}
                      {moment(new Date()).format("yyyy-MM-DD") >
                        data.entry_deadline_date && (
                        <span className="errortext float-left tx-18">
                          Entry Closed
                        </span>
                      )}

                      <a
                        className="btn btn-sm btn-secondary float-right viewbtndash"
                        onClick={() => viewlist(data.id, data.meet_name)}
                      >
                        View Tournament
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}

            {cfilterProduct.length !== 0 && (
              <div className="ag_courses_head mt-4">
                <h3>Completed Tournaments</h3>
              </div>
            )}
            {cfilterProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.meet_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      {data.meet_tour_type === "Internal" && (
                        <div className="row">
                          <div className="col-md-6">
                            Start Date :
                            <span className="ag-courses-item_date">
                              {moment(data.start_date).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className="col-md-6">
                            End Date :
                            <span className="ag-courses-item_date">
                              {moment(data.end_date).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.meet_tour_type === "External" && (
                        <div className="row">
                          <div className="col-md-6">
                            Start Date :
                            <span className="ag-courses-item_date">
                              {data.startdate}
                            </span>
                          </div>
                          <div className="col-md-6">
                            End Date :
                            <span className="ag-courses-item_date">
                              {data.enddate}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ag-courses-item_date-box">
                      {data.meet_tour_type === "Internal" && (
                        <div className="row">
                          <div className="col-md-6">
                            Entry Open :
                            <span className="ag-courses-item_date">
                              {moment(data.entry_open).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className="col-md-6">
                            Entry Close :
                            <span className="ag-courses-item_date">
                              {moment(data.entry_deadline_date).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.meet_tour_type === "External" && (
                        <div className="row">
                          <div className="col-md-6">
                            Sports :
                            <span className="ag-courses-item_date">
                              {data.sports_type}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span className="errortext tx-20">
                              {data.meet_tour_type}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-right viewbtndash"
                        onClick={() =>
                          compviewlist(
                            data.id,
                            data.meet_name,
                            data.meet_tour_type
                          )
                        }
                      >
                        View Tournament
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="ag-format-container">
          <div className="ag-courses_box">
            {eventProduct.length !== 0 && (
              <div className="ag_courses_head mt-4">
                <h3>Upcoming Events</h3>
              </div>
            )}
            {eventProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.event_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-right viewbtndash"
                        onClick={() => vieweventlist(data.id, data.event_name)}
                      >
                        View Event
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}

            {ceventProduct.length !== 0 && (
              <div className="ag_courses_head mt-4">
                <h3>Completed Events</h3>
              </div>
            )}
            {ceventProduct.map((data, i) => {
              return (
                <div className="ag-courses_item me-4" key={i}>
                  <div className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>
                    <div className="ag-courses-item_title pb-2 border-bottom">
                      {data.event_name}
                    </div>

                    <div className="ag-courses-item_date-box pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          Start Date :
                          <span className="ag-courses-item_date">
                            {moment(data.start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          End Date :
                          <span className="ag-courses-item_date">
                            {moment(data.end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ag-courses-item_date-box">
                      <div className="row">
                        <div className="col-md-6">
                          Entry Open :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_start_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Entry Close :
                          <span className="ag-courses-item_date">
                            {moment(data.entry_end_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="ag-courses-item_date-box pt-2 mt-2 border-top">
                      <a
                        className="btn btn-sm btn-secondary float-right viewbtndash"
                        onClick={() => vieweventlist(data.id, data.event_name)}
                      >
                        View Event
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="ag_courses_head">
            <h4>Tournament & Events Calender</h4>
          </div>
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  eventClick={handleEventClick}
                />

                <Modal show={modalIsOpen} size="xl">
                  <Modal.Header>
                    <Modal.Title>Tournament & Events Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="table-responsive">
                      <table className="sp-league-table sp-data-table">
                        <thead className="sp-table-header">
                          <tr role="row">
                            <th className="text-center">Title</th>
                            <th className="text-center">Start Date</th>
                            <th className="text-center">End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="sp-row-no-0 even" role="row">
                            <td class="data-pct">{selectedEvent.title}</td>
                            <td class="data-pct">
                              {moment(selectedEvent.start).format("DD-MM-YYYY")}
                            </td>
                            {selectedEvent.end !== "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.end).format("DD-MM-YYYY")}
                              </td>
                            )}
                            {selectedEvent.end === "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.start).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-gray" onClick={closeModal}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
