import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  pooldetails,
  poolimportfixtures,
  poolimportfixturesexcellist,
  tourcategorydetail,
  tourpoolimport,
  tourviewdetail,
} from "../../../../services/superadmin";
import { AgGridReact } from "ag-grid-react";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import exportUrl from "../../../../exportUtl";
import moment from "moment";
import { coachtourviewdetail } from "../../../../services/coach";

export const PoolTourupload = () => {
  const { id, eid, tid } = useParams();
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");
  const [failid, setFailId] = useState(0);
  const [totsets, setTotSets] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");

  const [tcreate, setTCreate] = useState("");
  const [tname, setTName] = useState("");
  const [tsport, setMSport] = useState("");

  const [urllink, setUrlLink] = useState("");
  const [catname, setCatname] = useState("");
  const [plname, setPName] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);
  const [s3path, setS3Path] = useState("");

  const columnDefs = [
    {
      headerName: "Upload Excel",
      width: 170,
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={() =>
                dotracking(
                  "click to download link in import fixture for " +
                    plname +
                    " (" +
                    tid +
                    ") pool in " +
                    catname +
                    " (" +
                    eid +
                    ") for " +
                    tname +
                    " (" +
                    id +
                    ") tournament and file " +
                    params.value +
                    " (" +
                    params.data.id +
                    ") $$ " +
                    trackobj.importfixtureheading
                )
              }
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);

    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });

    pooldetails(tid).then((response) => {
      if (response.status === true) {
        setPName(response.data.pool_name);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTCreate(response.data.tournament.created_by+"");
          setMSport(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTCreate(response.data.tournament.created_by+"");
          setMSport(response.data.tournament.sports_type);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTCreate(response.data.tournament.created_by+"");
          setMSport(response.data.tournament.sports_type);
        }
      });
    }

    listall(link[1]);
  }, []);

  function listall(flist) {
    if (flist === "importfixture") {
      const temp = new FormData();
      temp.append("tournament_id", id);
      temp.append("category_id", eid);
      temp.append("pool_id", tid);

      poolimportfixturesexcellist(temp).then((response) => {
        if (response.status === true) {
          setS3Path(response.data.s3path);
          setFilterProduct(response.data.details);
        }
      });
    }
  }
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        if (urllink === "importfixture") {
          setFileMsg("Fixture File should be xls or xlsx format");
        } else {
          setFileMsg("Pool File should be xls or xlsx format");
        }
      }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (basefile !== "") {
      if (fileMsg === "") {
        setLoading(true);
        if (
          localStorage.getItem("Role") === "1" ||
          localStorage.getItem("Role") === "3"
        ) {
          if (urllink === "importfixture") {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_id", id);
            temp.append("category_id", eid);
            temp.append("pool_id", tid);
            temp.append("fixture_excel", basefile);
            if (
              tsport.toUpperCase() === "VOLLEYBALL" ||
              tsport.toUpperCase() === "THROWBALL" ||
              tsport.toUpperCase() === "SOFTBALL" ||
              tsport.toUpperCase() === "KHOKHO" ||
              tsport.toUpperCase() === "BADMINTON" ||
              tsport.toUpperCase() === "TENNIS" ||
              tsport.toUpperCase() === "TABLE TENNIS" ||
              tsport.toUpperCase() === "SQUASH"
            ) {
              temp.append("total_sets", totsets);
            } else {
              temp.append("total_sets", 1);
            }

            poolimportfixtures(temp).then((response) => {
              if (response.status === true) {
                setLoading(false);
                setBase("");
                setTRecord(response.data.total_rows);
                setIRecord(response.data.inserted_count);
                setURecord(response.data.failed_count);
                setStatus(response.data.status);
                setFailId(response.data.uploaded_excel_id);
                listall(urllink);
              } else {
                setLoading(false);
                setErrorMsg(response.message);
              }
            });
          } else {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_meet_id", id);
            temp.append("pool_upload_excel", basefile);

            tourpoolimport(temp).then((response) => {
              if (response.status === true) {
                setLoading(false);
                setBase("");
                setTRecord(response.data.total_rows);
                setIRecord(response.data.inserted_count);
                setURecord(response.data.updated_count);
                setStatus(response.data.status);
              } else {
                setLoading(false);
                setErrorMsg(response.message);
              }
            });
          }
        }
      }
    } else {
      if (urllink === "importfixture") {
        setErrorMsg("Please Select Fixture File");
      } else {
        setErrorMsg("Please Select Pool File");
      }
    }
  }
  const navigate = useNavigate();
  function backlist() {
    if (urllink === "importfixture") {
      dotracking(
        "back button in import fixture for " +
          plname +
          " (" +
          tid +
          ") pool in " +
          catname +
          " (" +
          eid +
          ") for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.poollistheading
      );
    } else {
      dotracking(
        "back button in import fixture for " +
          catname +
          " (" +
          eid +
          ") for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.poollistheading
      );
    }

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/poollist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/poollist/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/poollist/" + id + "/" + eid);
    }
  }

  function failedent(fid) {
    dotracking(
      " failed click to download link button in import fixture for " +
        plname +
        " (" +
        tid +
        ") pool in " +
        catname +
        " (" +
        eid +
        ") for " +
        tname +
        " (" +
        id +
        ") tournament and file failed (" +
        fid +
        ") $$ " +
        trackobj.importfixtureheading
    );
    window.open(exportUrl + "export-failed-fixture-import/" + fid, "_blank");
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            {urllink === "importfixture" && (
              <h4 className="mb-0">Fixture Upload</h4>
            )}
            {urllink !== "importfixture" && (
              <h4 className="mb-0">Pools Upload</h4>
            )}
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Pool</a>
            </li>
            {urllink === "importfixture" && (
              <li className="breadcrumb-item active" aria-current="page">
                Fixture Upload
              </li>
            )}
            {urllink !== "importfixture" && (
              <li className="breadcrumb-item active" aria-current="page">
                Pools Upload
              </li>
            )}
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row mt-0">
                    <div className="col-md-12">
                      <div className="text-left">
                        <h3>{tname}</h3>
                        <h4 className="text-success tx-20">
                          Category - {catname}
                        </h4>
                        <h4 className="text-warning tx-20">Pool - {plname}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <Form className="card">
                  <div className="card-body">
                    {(tsport.toUpperCase() === "VOLLEYBALL" ||
                    tsport.toUpperCase() === "THROWBALL" ||
                      tsport.toUpperCase() === "BADMINTON" ||
                      tsport.toUpperCase() === "TENNIS" ||
                      tsport.toUpperCase() === "TABLE TENNIS" ||
                      tsport.toUpperCase() === "SQUASH") && (
                      <div className="form-group">
                        <label className="mb-2">
                          Total Sets (Best of){" "}
                          <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={totsets}
                          onChange={(e) => setTotSets(e.target.value)}
                        >
                          <option value="0">
                            --Select Total Sets (Best of)--
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                      </div>
                    )}

                    {(tsport.toUpperCase() === "SOFTBALL" || tsport.toUpperCase() === "KHOKHO") && (
                      <div className="form-group">
                        <label className="mb-2">
                          Total Innings <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={totsets}
                          onChange={(e) => setTotSets(e.target.value)}
                        >
                          <option value="0">--Select Total Innings--</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                      </div>
                    )}
                    <div className="form-group">
                      {urllink === "importfixture" && (
                        <label className="form-label">
                          {" "}
                          Upload Fixture File{" "}
                          <span className="errortext"> *</span>
                        </label>
                      )}
                      {urllink !== "importfixture" && (
                        <label className="form-label">
                          {" "}
                          Upload Pool File <span className="errortext"> *</span>
                        </label>
                      )}

                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        Back
                      </a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>
                      {urllink === "importfixture" && (
                        <a
                          className="btn btn-secondary"
                          href={require("../../../../assets/images/Sample_Fixtures_Excel.xlsx")}
                          download
                        >
                          Sample Excel
                        </a>
                      )}
                      {urllink !== "importfixture" && (
                        <a
                          className="btn btn-secondary"
                          href={require("../../../../assets/images/Sample_pool_import.xlsx")}
                          download
                        >
                          Sample Excel
                        </a>
                      )}
                    </div>
                  </div>
                </Form>
              </div>

              <div className="col-md-6">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 320,
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="4"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Failed Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                    {urecord !== 0 && (
                      <a
                        className="excelfailed text-danger"
                        onClick={() => failedent(failid)}
                      >
                        <b>Click to Download</b>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};
