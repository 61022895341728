import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { Card, Col, Row } from "react-bootstrap";
import {
  addathleteplayerlist,
  checkpaymentpaidornot,
  paymentsuccess,
  tourentryfreepayment,
  tourentryofflinepayment,
  tourpayment,
  toursummary,
} from "../../../../../services/common";
import moment from "moment";
import { toast } from "react-toastify";
import {
  crmprofileview,
  crmtourviewdetail,
} from "../../../../../services/cityregionaladmin";
import { tourviewdetail } from "../../../../../services/superadmin";
export const SummaryPlayers = () => {
  const [tname, setTName] = useState("");
  const [tfree, setTFree] = useState("");

  const { id, eid } = useParams();
  const navigate = useNavigate();
  const [gateway, setGateway] = useState("");
  const [athlete, setAthlete] = useState([]);
  const [s3path, setS3Path] = useState("");
  const [schoolid, setSchool] = useState("");

  const [adminbutton, setAdminButton] = useState("");

  const [menamt, setMenAmt] = useState(0);
  const [womenamt, setWomenAmt] = useState(0);
  const [mencnt, setMenCnt] = useState(0);
  const [womencnt, setWomenCnt] = useState(0);

  const [cmen, setCMen] = useState(0);
  const [cwomen, setCWomen] = useState(0);
  const [cmamt, setCMAmt] = useState(0);
  const [cwamt, setCWAmt] = useState(0);
  const [rcnt, setRCnt] = useState(0);
  const [ramt, setRAmt] = useState(0);
  const [tcnt, setTCnt] = useState(0);
  const [tamt, setTAmt] = useState(0);
  const [disamt, setDisAmt] = useState(0);
  const [disper, setDisPer] = useState(0);
  const [finalamt, setFinalAmt] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTFree(response.data.tournament.free_tournament);
        }
      });

      checkpaymentpaidornot(id, eid, 0).then((response) => {
        if (response.status === true) {
          setAdminButton(response.data.Payment_Status);
         
        }
      });

      addathleteplayerlist(id, eid).then((response) => {
        if (response.status === true) {
          setAthlete(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
      toursummary(id, eid).then((response) => {
        if (response.status === true) {
          setCMen(response.data.no_of_men);
          setCWomen(response.data.no_of_women);
          setCMAmt(response.data.total_male_amount);
          setCWAmt(response.data.total_female_amount);
          setRCnt(response.data.total_relay_total_count);
          setRAmt(response.data.total_relay_total_amount);
          setTCnt(response.data.total_count);
          setTAmt(response.data.over_all_amount);
          setDisPer(response.data.discount_percentage);
          setDisAmt(response.data.discount_amount);
          setFinalAmt(response.data.final_amount);
          setMenCnt(
            response.data.no_of_men + response.data.total_relay_male_count
          );
          setWomenCnt(
            response.data.no_of_women + response.data.total_relay_female_count
          );
          setMenAmt(
            response.data.total_male_amount +
              response.data.total_relay_male_amount
          );
          setWomenAmt(
            response.data.total_female_amount +
              response.data.total_relay_female_amount
          );
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTFree(response.data.tournament.free_tournament);
        }
      });
      checkpaymentpaidornot(id, eid, 0).then((response) => {
        if (response.status === true) {
          setAdminButton(response.data.Payment_Status);
          if (response.data.Payment_Status === "Paid") {
            navigate("/cityregional/confirmation/" + id + "/" + eid);
          }
        }
      });

      addathleteplayerlist(id, eid).then((response) => {
        if (response.status === true) {
          setAthlete(response.data.details);
          setS3Path(response.data.s3path);
        }
      });
      toursummary(id, eid).then((response) => {
        if (response.status === true) {
          setCMen(response.data.no_of_men);
          setCWomen(response.data.no_of_women);
          setCMAmt(response.data.total_male_amount);
          setCWAmt(response.data.total_female_amount);
          setRCnt(response.data.total_relay_total_count);
          setRAmt(response.data.total_relay_total_amount);
          setTCnt(response.data.total_count);
          setTAmt(response.data.over_all_amount);
          setDisPer(response.data.discount_percentage);
          setDisAmt(response.data.discount_amount);
          setFinalAmt(response.data.final_amount);

          setMenCnt(
            response.data.no_of_men + response.data.total_relay_male_count
          );
          setWomenCnt(
            response.data.no_of_women + response.data.total_relay_female_count
          );
          setMenAmt(
            response.data.total_male_amount +
              response.data.total_relay_male_amount
          );
          setWomenAmt(
            response.data.total_female_amount +
              response.data.total_relay_female_amount
          );
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTFree(response.data.tournament.free_tournament);
          setTName(response.data.tournament.meet_name);
        }
      });
      schprofileview().then((resp) => {
        if (resp.status === true) {
          setSchool(resp.data.school_id);
          checkpaymentpaidornot(id, resp.data.school_id, 0).then((response) => {
            if (response.status === true) {
          setAdminButton(response.data.Payment_Status);

              if (response.data.Payment_Status === "Paid") {
                navigate("/schoolhead/confirmation/" + id);
              }
            }
          });

          addathleteplayerlist(id, resp.data.school_id).then((response) => {
            if (response.status === true) {
              setAthlete(response.data.details);
              setS3Path(response.data.s3path);
            }
          });
          toursummary(id, resp.data.school_id).then((response) => {
            if (response.status === true) {
              setCMen(response.data.no_of_men);
              setCWomen(response.data.no_of_women);
              setCMAmt(response.data.total_male_amount);
              setCWAmt(response.data.total_female_amount);
              setRCnt(response.data.total_relay_total_count);
              setRAmt(response.data.total_relay_total_amount);
              setTCnt(response.data.total_count);
              setTAmt(response.data.over_all_amount);
          setDisPer(response.data.discount_percentage);
              setDisAmt(response.data.discount_amount);
              setFinalAmt(response.data.final_amount);
              setMenCnt(
                response.data.no_of_men + response.data.total_relay_male_count
              );
              setWomenCnt(
                response.data.no_of_women +
                  response.data.total_relay_female_count
              );
              setMenAmt(
                response.data.total_male_amount +
                  response.data.total_relay_male_amount
              );
              setWomenAmt(
                response.data.total_female_amount +
                  response.data.total_relay_female_amount
              );
            }
          });
        }
      });
    }
  }, []);

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touraddaplayerheading
      );
      navigate("/superadmin/addathletes/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in tournament player list for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touraddaplayerheading
      );
      navigate("/cityregional/addathletes/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      dotracking(
        "back button in tournament player list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touraddaplayerheading
      );
      navigate("/schoolhead/addathletes/" + id);
    }
  }

  function addlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "add players button in summary for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
      navigate("/superadmin/playerlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "add players button in summary for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
      navigate("/cityregional/playerlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      dotracking(
        "add players button in summary for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
      navigate("/schoolhead/playerlist/" + id);
    }
  }
  const [loading, setLoading] = useState(false);

  function entrysubmit() {
    setLoading(true);
    const tempData = new FormData();
    tempData.append("admin_id", localStorage.getItem("UID"));
    if (localStorage.getItem("Role") !== "4") {
      tempData.append("school_id", eid);
    } else {
      tempData.append("school_id", schoolid);
    }

    tempData.append("tournament_meet_id", id);
    tempData.append("total_men", mencnt);
    tempData.append("total_women", womencnt);
    tempData.append("total_men_amount", menamt);
    tempData.append("total_women_amount", womenamt);
    tempData.append("total_relay_fees", ramt);
    tempData.append("amount", finalamt);
        tempData.append("over_all_amount", tamt);
        tempData.append("discount_amount", disamt);
    if(adminbutton === "Paid"){
      tempData.append("submit_or_update", 1);
    }else{
      tempData.append("submit_or_update", 0);
    }
    
    dotracking(
      "submit & pay button in summary for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.confirmplayerheading
    );
    if (tfree === "Y") {
      tourentryfreepayment(tempData).then((response) => {
        if (response.status === true) {
          setLoading(false);
          navigate("/superadmin/confirmation/" + id + "/" + eid);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    } else if (tfree === "N") {
      tourentryofflinepayment(tempData).then((response) => {
        if (response.status === true) {
          setLoading(false);
          navigate("/superadmin/confirmation/" + id + "/" + eid);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    }
  }
  function paysubmit() {
    if (tfree === "Y") {
      setLoading(true);
      const tempData = new FormData();
      tempData.append("admin_id", localStorage.getItem("UID"));
      if (localStorage.getItem("Role") !== "4") {
        tempData.append("school_id", eid);
      } else {
        tempData.append("school_id", schoolid);
      }

      tempData.append("tournament_meet_id", id);
      tempData.append("total_men", mencnt);
      tempData.append("total_women", womencnt);
      tempData.append("total_men_amount", menamt);
      tempData.append("total_women_amount", womenamt);
      tempData.append("total_relay_fees", ramt);
      tempData.append("amount", finalamt);
        tempData.append("over_all_amount", tamt);
        tempData.append("discount_amount", disamt);
      // if(adminbutton === "Paid"){
      //   tempData.append("submit_or_update", 1);
      // }else{
        tempData.append("submit_or_update", 0);
      // }
      dotracking(
        "submit & pay button in summary for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.confirmplayerheading
      );
      tourentryfreepayment(tempData).then((response) => {
        if (response.status === true) {
          setLoading(false);
          if (localStorage.getItem("Role") === "3") {
            navigate("/cityregional/confirmation/" + id + "/" + eid);
          } else if (localStorage.getItem("Role") === "4") {
            navigate("/schoolhead/confirmation/" + id);
          }
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    } else if (tfree === "N") {
      if (tamt !== 0) {
        setLoading(true);
        const tempData = new FormData();
        tempData.append("admin_id", localStorage.getItem("UID"));
        if (localStorage.getItem("Role") !== "4") {
          tempData.append("school_id", eid);
        } else {
          tempData.append("school_id", schoolid);
        }

        tempData.append("tournament_meet_id", id);
        tempData.append("total_men", mencnt);
        tempData.append("total_women", womencnt);
        tempData.append("total_men_amount", menamt);
        tempData.append("total_women_amount", womenamt);
        tempData.append("total_relay_fees", ramt);
        tempData.append("amount", finalamt);
        tempData.append("over_all_amount", tamt);
        tempData.append("discount_amount", disamt);

        dotracking(
          "submit & pay button in summary for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.confirmplayerheading
        );
        tourpayment(tempData).then((response) => {
          if (response.status === true) {
            setLoading(false);
            paynow(
              response.data.RAZORPAY_KEY,
              response.data.entry_table.orderid,
              response.data.entry_table.total_amount,
              response.data.entry_table.id
            );
          } else {
            setLoading(false);
            toast.error(response.message);
          }
        });
      } else {
        toast.error("Please add players before submit");
      }
    }
  }
  async function paynow(pkey, ordid, amt, pid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Tournament Registration",
      image: require("../../../../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        if (localStorage.getItem("Role") === "3") {
          temp.append("school_id", eid);
        } else {
          temp.append("school_id", schoolid);
        }
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("razorpay_payment_id", response.razorpay_payment_id);
        temp.append("totalAmount", amt);
        temp.append("current_meet_id", id);
        temp.append("order_id", ordid);
        temp.append("current_ins_id", pid);
        setLoading(true);
        paymentsuccess(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);
            if (localStorage.getItem("Role") === "3") {
              navigate("/cityregional/confirmation/" + id + "/" + eid);
            } else if (localStorage.getItem("Role") === "4") {
              navigate("/schoolhead/confirmation/" + id);
            }
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }
  function errorhandle(ev) {
    ev.target.src = require("../../../../../assets/images/users.png");
    ev.target.onerror = null;
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Summary</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Summary
            </li>
          </ol>
        </div>
        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        {!loading && (
           <div className="row">
           <div className="col-12">
             <form className="card mt-0">
               <div className="card-body">
                 <div className="row">
                   <div className="col-md-7">
                     <h3>{tname}</h3>
                   </div>
                   <div className="col-md-5">
                     <div className="text-right">
                       {(localStorage.getItem("Role") === "1" ||
                         localStorage.getItem("Role") === "3" ||
                         localStorage.getItem("Role") === "4") && (
                         <>
                           <a className="btn btn-success me-2" onClick={addlist}>
                             <i className="fa fa-plus"></i> Players
                           </a>
                         </>
                       )}
 
                       <a className="btn btn-gray me-2" onClick={backlist}>
                         <i className="fe fe-corner-up-left"></i> Back
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
             </form>
 
             <div className="row row-sm">
               <div className="col-lg-4 col-xxl-4">
                 <Card className="custom-card mb-0">
                   <div className="card-body text-center py-3 py-3 bg-light border">
                     <h4 className="main-content-title mg-b-0 text-success">
                       View Summary
                     </h4>
                   </div>
                   <div className="card custom-card cart-details summbox mb-0">
                     <Card.Body>
                       <h5 className="mb-3 tx-uppercase">
                         Player DETAILs{" "}
                         <span className="float-end">Amount (INR)</span>
                       </h5>
                       <dl className="dlist-align">
                         <dt className="">
                           Number of Men <code>({cmen})</code>
                         </dt>
                         <dd className="text-end ms-auto">{cmamt}</dd>
                       </dl>
                       <dl className="dlist-align">
                         <dt>
                           Number of Women <code>({cwomen})</code>
                         </dt>
                         <dd className="text-end ms-auto">{cwamt}</dd>
                         {/* text-danger  */}
                       </dl>
                       <dl className="dlist-align border-bottom">
                         <dt>
                           Relay Team <code>({rcnt})</code>
                         </dt>
                         <dd className="text-end ms-auto">{ramt}</dd>
                       </dl>
                       <dl className="dlist-align">
                         <dt>
                           Total {gateway}{/* <code>({tcnt})</code> */}
                         </dt>
                         <dd className="text-end errortext ms-auto">
                           <strong>{tamt}</strong>
                         </dd>
                       </dl>
                       
                       <dl className="dlist-align border-bottom">
                         <dt>
                           Discount <code>({disper} %)</code>
                         </dt>
                         <dd className="text-end ms-auto">{disamt}</dd>
                       </dl>
                       <dl className="dlist-align tx-uppercase">
                         <dt style={{ fontWeight: "bold",color: "#035399" }}>
                           Overall Amount
                         </dt>
                         <dd className="text-end errortext ms-auto">
                           <strong>{finalamt}</strong>
                         </dd>
                       </dl>
                       <div className="step-footer">
                         {/* {free === "N" && paystatus !== "paid" && ( */}
                         {localStorage.getItem("Role") !== "1" &&
                           tfree === "N" &&
                           tfree !== "" && (
                             <a
                               onClick={paysubmit}
                               className="btn btn-success btn-block"
                             >
                               Submit &amp; Pay
                             </a>
                           )}
                         {localStorage.getItem("Role") !== "1" &&
                           tfree !== "N" &&
                           tfree !== "" && (
                             <a
                               onClick={paysubmit}
                               className="btn btn-success btn-block"
                             >
                               Submit
                             </a>
                           )}
 
                         {localStorage.getItem("Role") === "1" && adminbutton === "Paid" && (
                           <a
                             onClick={entrysubmit}
                             className="btn btn-success btn-block"
                           >
                             Update Entries
                           </a>
                         )}
                         {localStorage.getItem("Role") === "1" && adminbutton !== "Paid" && (
                           <a
                             onClick={entrysubmit}
                             className="btn btn-success btn-block"
                           >
                             Submit Entries
                           </a>
                         )}
                         {/* )} */}
 
                         <div className="text-center">
                           <span>
                             * Once you submit and paid the amount for the
                             entries, entries cannot be added/edited
                           </span>
                         </div>
                       </div>
                     </Card.Body>
                   </div>
                 </Card>
               </div>
               <div className="col-lg-8 col-xxl-8 selectedathletes">
                 <h4>All Selected Players</h4>
                 <hr className="my-1" />
 
                 <Row>
                   {athlete.map((data, i) => {
                     return (
                       <Col xxl={6} lg={6} md={6} key={i}>
                         <Card className="custom-card">
                           <Card.Body className="athsummbox p-2">
                             <div className="d-flex">
                               <div className="media align-items-center pos-relative w-100">
                                 <span className="tx-16 my-auto">
                                   {data.photograph !== "" && (
                                     <img
                                       alt="photograph"
                                       src={s3path + data.photograph}
                                       className="athsummbox-img"
                                       onError={(e) => errorhandle(e)}
                                     />
                                   )}
                                   {data.photograph === "" && (
                                     <img
                                       alt="photograph"
                                       src={require("../../../../../assets/images/users.png")}
                                       className="athsummbox-img"
                                       onError={(e) => errorhandle(e)}
                                     />
                                   )}
                                 </span>
                                 <div className="media-body ms-3">
                                   <h4 className="abname">{data.first_name}</h4>
                                   <h4 className="abuid">
                                     <span>UID - </span>
                                     {data.username}
                                   </h4>
                                   <h4 className="abdob">
                                     <span>DOB - </span>
                                     {moment(data.date_of_birth).format(
                                       "DD-MM-YYYY"
                                     )}
                                   </h4>
                                   <h4 className="abdob">
                                     <span>BIB - {data.bib_no}</span>
                                     
                                   </h4>
                                   <h4 className="abdob">
                                     <span>{data.age_category}</span>
                                   </h4>
                                   <h4 className="abdob">
                                     <span>{data.events}</span>
                                   </h4>
                                 </div>
                               </div>
                             </div>
                           </Card.Body>
                         </Card>
                       </Col>
                     );
                   })}
                 </Row>
               </div>
             </div>
           </div>
         </div>
        )

        }
       
      </div>
    </Fragment>
  );
};
