import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  allbannerlist,
  bannerlistdelete,
} from "../../../../services/superadmin";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
export const Bannerlist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Banner Name",
      field: "b_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Banner Type",
    //   field: "b_type",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Tournament",
    //   field: "meet_name",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Banner Link",
      field: "banner_link",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Page Name",
    //   field: "banner_pages",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Roles",
    //   field: "roles_id",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      field: "banner_main_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => editline(params.value,params)}
              title="Edit Banner Data"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
            <a
              onClick={() => deleteline(params.value,params)}
              title="Delete Banner Data"
              className="btn-sm btn-danger me-2"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function deleteline(lid,list) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // const temp = new FormData();
        // temp.append("user_id", list.data.user_id);
        dotracking("delete icon in banner list for " + list.data.b_name + " (" + lid + ") $$ " + trackobj.bannerlistheading);
        bannerlistdelete(lid).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }
  function Addbanner() {
    dotracking("add banner button in banner list  $$ " + trackobj.banneraddheading);
    navigate("/superadmin/bannerupload");
  }
  function editline(eid,list) {
    dotracking("edit icon in banner list for " + list.data.b_name + " (" + eid + ") $$ " + trackobj.bannereditheading);

    navigate("/superadmin/editbanner/" + eid);
  }

  let arr3 = [];

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    allbannerlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.bannerdetails);
      }
    });
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Banner Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Banner</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Banner Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={Addbanner}>
                  <i className="fa fa-plus"></i> Add Banner
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
