import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const CoachSidebar = () => {
  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [plaact, setPlaAct] = useState(false);
  const [plalistact, setPlaListAct] = useState(false);
  const [addplaact, setAddPlaAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [touadd, setTouAdd] = useState(false);

  const [toulist, setTouList] = useState(false);
  const [exttouadd, setExtTouAdd] = useState(false);
  const [exttoulist, setExtTouList] = useState(false);
  const [phototou, setPhotoTou] = useState(false);
  const [achtou, setAchTou] = useState(false);

  const [workact, setWorkAct] = useState(false);
  const [addwork, setAddWork] = useState(false);
  const [listwork, setListWork] = useState(false);

  const [teamact, setTeamAct] = useState(false);
  const [stlistact, setSTListAct] = useState(false);

  const [upact, setUpAct] = useState(false);
  const [steamact, setSTeamAct] = useState(false);
  const [upachact, setUPAchAct] = useState(false);
  const [upcmsact, setUPCmsAct] = useState(false);

  const [proact, setProAct] = useState(false);
  const [pact, setPAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setProAct(false);
    setPAct(false);

    setPlaAct(false);
    setPlaListAct(false);
    setAddPlaAct(false);

    setTouAct(false);
    setTouList(false);
    setExtTouList(false);
    setTouAdd(false);
    setExtTouAdd(false);
    setPhotoTou(false);
    setAchTou(false);

    setTeamAct(false);
    setSTListAct(false);

    setUpAct(false);
    setSTeamAct(false);
    setUPAchAct(false);
    setUPCmsAct(false);

    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/coach/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tournaments") {
      setTouAct(true);
    } else if (head === "OtherEvents") {
      setWorkAct(true);
    } else if (head === "Team") {
      setTeamAct(true);
      setSTListAct(true);
      dotracking(
        "school team list in school team menu $$ " +
          trackobj.schteamplayerheading
      );
      navigate("/coach/schoolteamplayerlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Upload") {
      setUpAct(true);
    } else if (head === "Profile") {
      setProAct(true);
      setPAct(true);
      dotracking("profile menu $$ " + trackobj.profileheading);
      navigate("/coach/profile");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
      // } else if (head === "Performance") {
      //   setPerAct(true);
    } else if (head === "Player") {
      setPlaAct(true);
    }
  }
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();
  const { cid } = useParams();

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);

    setTouAct(false);
    setTouList(false);
    setExtTouList(false);
    setTouAdd(false);
    setExtTouAdd(false);
    setPhotoTou(false);
    setAchTou(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setProAct(false);
    setPAct(false);

    setPlaAct(false);
    setPlaListAct(false);
    setAddPlaAct(false);

    setTeamAct(false);
    setSTListAct(false);

    setUpAct(false);
    setSTeamAct(false);
    setUPAchAct(false);
    setUPCmsAct(false);
    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/coach/dashboard");
    } else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/coach/contact");
    } else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/coach/changepassword");
    } else if (head === "addtournament") {
      setTouAct(true);
      setTouAdd(true);
      if (type === true) {
        dotracking(
          "add tournament in tournament menu $$ " + trackobj.touraddheading
        );
      }
      navigate("/coach/addtournament");
    } else if (head === "athleticsagecategory") {
      setTouAct(true);
      setTouAdd(true);
      navigate("/coach/athleticsagecategory/" + id);
    } else if (head === "tournamentagecategory") {
      setTouAct(true);
      setTouAdd(true);
      navigate("/coach/tournamentagecategory/" + id);
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setTouList(true);
      if (type === true) {
        dotracking(
          "tournament list in tournament menu $$ " + trackobj.tourlistheading
        );
      }
      navigate("/coach/tournamentlist");
    } else if (head === "analytics") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/analytics/" + id);
    } else if (head === "offlineentry") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/offlineentry/" + id);
    } else if (head === "tournamentdetails") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/tournamentdetails/" + id);
    } else if (head === "addexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      if (type === true) {
        dotracking(
          "add external tournament in tournament menu $$ " +
            trackobj.tourextheading
        );
      }
      navigate("/coach/addexternaltournament");
    } else if (head === "editexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      navigate("/coach/editexternaltournament/" + id);
    } else if (head === "externaltournamentlist") {
      setTouAct(true);
      setExtTouList(true);
      if (type === true) {
        dotracking(
          "external tournament list in tournament menu $$ " +
            trackobj.listextheading
        );
      }
      navigate("/coach/externaltournamentlist");
    } else if (head === "externaltournamentdetails") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externaltournamentdetails/" + id);
    } else if (head === "edittournament") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/edittournament/" + id);
    } else if (head === "tournamentagecategorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/tournamentagecategorylist/" + id);
    } else if (head === "tournamentclassagecategorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/tournamentclassagecategorylist/" + id);
    } else if (head === "tournamentplayer") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/tournamentplayer/" + id);
    } else if (head === "teamcreate") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/teamcreate/" + id + "/" + eid);
    } else if (head === "externalteamcreate") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externalteamcreate/" + id);
    } else if (head === "certificatelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/certificatelist/" + id);
    } else if (head === "uploadcertificate") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/uploadcertificate/" + id);
    } else if (head === "teamlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/teamlist/" + id + "/" + tid);
    } else if (head === "interteamlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/interteamlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "externalteamlist") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externalteamlist/" + id);
    } else if (head === "teamdetails") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/teamdetails/" + id + "/" + tid + "/" + eid);
    } else if (head === "externalteamdetails") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externalteamdetails/" + id + "/" + tid);
    } else if (head === "teamplayercreate") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/teamplayercreate/" + id + "/" + tid + "/" + eid);
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/teamplayerlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "externalteamplayerlist") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externalteamplayerlist/" + id + "/" + tid);
    } else if (head === "tournamentschoollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/tournamentschoollist/" + id);
    } else if (head === "playerlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/playerlist/" + id + "/" + eid);
    } else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "orderofeventlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/orderofeventlist/" + id);
    } else if (head === "eventstartlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/eventstartlist/" + id + "/" + eid);
    } else if (head === "athleticsresults") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/athleticsresults/" + id);
    } else if (head === "overallchampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/individualchampionlist/" + id);
    } else if (head === "addathletes") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/addathletes/" + id + "/" + eid);
    } else if (head === "summary") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/summary/" + id + "/" + eid);
    } else if (head === "confirmation") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/confirmation/" + id + "/" + eid);
    } else if (head === "athleticschoollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/athleticschoollist/" + id);
    } else if (head === "entryplayerslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/entryplayerslist/" + id);
    } else if (head === "refundlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/refundlist/" + id);
    } else if (head === "eventrefundlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/eventrefundlist/" + id);
    } else if (head === "athleticsentryplayerslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/athleticsentryplayerslist/" + id);
    } else if (head === "pointslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/pointslist/" + id + "/" + eid);
    } else if (head === "categorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/categorylist/" + id);
    } else if (head === "intercategorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/intercategorylist/" + id + "/" + tid);
    } else if (head === "performancelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/performancelist/" + id + "/" + eid);
    } else if (head === "addperformance") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/addperformance/" + id + "/" + eid);
    } else if (head === "performanceplayerphotoedit") {
      setTouAct(true);
      setTouList(true);
      navigate(
        "/coach/performanceplayerphotoedit/" + id + "/" + tid + "/" + eid
      );
    } else if (head === "individualperformance") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/individualperformance/" + id + "/" + eid);
    } else if (head === "externalindividualperformance") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/coach/externalindividualperformance/" + id + "/" + eid);
    } else if (head === "highlights") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/highlights/" + id);
    } else if (head === "leaderboard") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/leaderboard/" + id);
    } else if (head === "photoupload") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/photoupload/" + id);
    } else if (head === "results") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/results/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/resultlist/" + id + "/" + eid);
    } else if (head === "poollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/poollist/" + id + "/" + eid);
    } else if (head === "importfixture") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/importfixture/" + id + "/" + eid + "/" + tid);
    } else if (head === "scheduleteam") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/scheduleteam/" + id + "/" + tid + "/" + eid);
    } else if (head === "scheduleallocate") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/scheduleallocate/" + id + "/" + eid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/coach/schedulelist/" + id + "/" + eid);
    } else if (head === "photogallery") {
      setTouAct(true);
      setPhotoTou(true);
      if (type === true) {
        dotracking(
          "photo/gallery in tournament menu $$ " + trackobj.photoheading
        );
      }
      navigate("/coach/photogallery");
    } else if (head === "photos") {
      setTouAct(true);
      setPhotoTou(true);
      navigate("/coach/photos/" + id);
    } else if (head === "achievements") {
      setTouAct(true);
      setAchTou(true);
      if (type === true) {
        dotracking(
          "achievements list in tournament menu $$ " +
            trackobj.achievelistheading
        );
      }
      navigate("/coach/achievements");
    } else if (head === "achievementsupload") {
      setTouAct(true);
      setAchTou(true);
      if (type === true) {
        dotracking(
          "achievements upload in tournament menu $$ " +
            trackobj.achieveuploadheading
        );
      }
      navigate("/coach/achievementsupload");
    } else if (head === "profile") {
      setProAct(true);
      setPAct(true);
      navigate("/coach/profile");
    } else if (head === "editprofile") {
      setProAct(true);
      setPAct(true);
      navigate("/coach/editprofile");
    } else if (head === "addplayers") {
      setPlaAct(true);
      setAddPlaAct(true);
      if (type === true) {
        dotracking(
          "add players in player menu $$ " + trackobj.addplayerheading
        );
      }
      navigate("/coach/addplayers");
    } else if (head === "allplayers") {
      setPlaAct(true);
      setPlaListAct(true);
      if (type === true) {
        dotracking(
          "all players in player menu $$ " + trackobj.playerlistheading
        );
      }
      navigate("/coach/allplayers");
    } else if (head === "viewplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/coach/viewplayer/" + id);
    } else if (head === "overallperformanceplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/coach/overallperformanceplayer/" + id);
    } else if (head === "viewperformanceplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/coach/viewperformanceplayer/" + id);
    } else if (head === "othereventdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/coach/othereventdetail/" + id);
    } else if (head === "schoolteamplayerlist") {
      setTeamAct(true);
      setSTListAct(true);
      navigate("/coach/schoolteamplayerlist");
    } else if (head === "schoolteamlist") {
      setUpAct(true);
      setSTeamAct(true);
      if (type === true) {
        dotracking(
          "school team list in upload menu $$ " + trackobj.schteamlistheading
        );
      }
      navigate("/coach/schoolteamlist");
    } else if (head === "schoolteamupload") {
      setUpAct(true);
      setSTeamAct(true);
      navigate("/coach/schoolteamupload");
    } else if (head === "achievementlist") {
      setUpAct(true);
      setUPAchAct(true);
      if (type === true) {
        dotracking(
          "achievements list in upload menu $$ " + trackobj.achievelistheading
        );
      }
      navigate("/coach/achievementlist");
    } else if (head === "achievementindividual") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/coach/achievementindividual");
    } else if (head === "achievementsupload") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/coach/achievementsupload");
    } else if (head === "cmsdetails") {
      setUpAct(true);
      setUPCmsAct(true);
      navigate("/coach/cmsdetails");
    } else if (head === "addcms") {
      setUpAct(true);
      setUPCmsAct(true);
      navigate("/coach/addcms");
    } else if (head === "addotherevent") {
      setWorkAct(true);
      setAddWork(true);
      if (type === true) {
        dotracking(
          "add other events in other events menu $$ " + trackobj.workaddheading
        );
      }
      navigate("/coach/addotherevent");
    } else if (head === "othereventlist") {
      setWorkAct(true);
      setListWork(true);
      if (type === true) {
        dotracking(
          "list other events in other events menu $$ " +
            trackobj.worklistheading
        );
      }
      navigate("/coach/othereventlist");
    } else if (head === "editotherevent") {
      setWorkAct(true);
      setListWork(true);
      navigate("/coach/editotherevent/" + id);
    } else if (head === "othereventdetail") {
      setWorkAct(true);
      setListWork(true);
      navigate("/coach/othereventdetail/" + id);
    }

    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1], false);
  }, []);

  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              {/* 
                            <li data-toggle="tooltip" data-placement="right" title="" data-original-title="Referee"
                                className={
                                    refact
                                        ? "active resp-tab-item hor_1"
                                        : "resp-tab-item hor_1"
                                } onClick={() => mainmenuactive("Referee")} aria-controls="hor_1_tab_item-5" role="tab"
                            ><i
                                className="side-menu__icon mdi mdi-account-convert"></i>
                                <div className="slider-text">Referee</div>
                            </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="OtherEvents"
                className={
                  workact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("OtherEvents")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon fa fa-desktop"></i>
                <div className="slider-text">Other Events</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Team"
                className={
                  teamact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Team")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-bank"></i>
                <div className="slider-text">School Team</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Player"
                className={
                  plaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Player")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon fa fa-user"></i>
                <div className="slider-text">Player</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Upload"
                className={
                  upact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Upload")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-upload-outline"></i>
                <div className="slider-text">Upload</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-6"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Profile</div>
              </li>
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard",true)}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>
                        Tournaments
                      </h4>{" "}
                      <a
                        className={touadd ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addtournament", true)}
                      >
                        Add Internal Tournament
                      </a>
                      <a
                        className={
                          exttouadd ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("addexternaltournament", true)
                        }
                      >
                        Add External Tournament
                      </a>
                      <a
                        className={toulist ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Internal Tournament List
                      </a>
                      <a
                        className={
                          exttoulist ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("externaltournamentlist", true)
                        }
                      >
                        External Tournament List
                      </a>
                      <a
                        className={
                          phototou ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("photogallery", true)}
                      >
                        Photos / Gallery
                      </a>
                      <a
                        className={achtou ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("achievements", true)}
                      >
                        Achievements
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-desktop"></i>
                  <div className="slider-text">Other Events</div>
                </h2>
                <div
                  className={
                    workact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-desktop"></i> Other
                        Events
                      </h4>{" "}
                      <a
                        className={addwork ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addotherevent", true)}
                      >
                        Add Other Events
                      </a>{" "}
                      <a
                        className={
                          listwork ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("othereventlist", true)}
                      >
                        Other Events List
                      </a>
                    </div>
                  </div>
                </div>
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-bank"></i>
                  <div className="slider-text">School Team</div>
                </h2>
                <div
                  className={
                    teamact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-bank"></i> School
                        Team
                      </h4>{" "}
                      <a
                        className={
                          stlistact ? "slide-item active" : "slide-item"
                        }
                      >
                        Team List
                      </a>
                    </div>
                  </div>
                </div>

                {/* <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa-solid fa-award"></i>
                  <div className="slider-text">Achievements</div>
                </h2>
                <div
                  className={
                    achact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa-solid fa-award"></i> Achievements
                      </h4>{" "}
                      <a
                        className={
                          allachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementslist",true)}
                      >
                        All Achievements
                      </a>
                      <a
                        className={
                          othachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("otherachievements",true)}
                      >
                        Other Achievements
                      </a>
                    </div>
                  </div>
                </div> */}

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-user"></i>
                  <div className="slider-text">Player</div>
                </h2>
                <div
                  className={
                    plaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-user"></i> Player
                      </h4>
                      <a
                        className={
                          addplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addplayers", true)}
                      >
                        Add Players
                      </a>
                      <a
                        className={
                          plalistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("allplayers", true)}
                      >
                        All Players
                      </a>
                    </div>
                  </div>
                </div>
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-upload-outline"></i>
                  <div className="slider-text">Upload</div>
                </h2>
                <div
                  className={
                    upact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-upload-outline"></i>{" "}
                        Upload
                      </h4>{" "}
                      <a
                        className={
                          steamact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolteamlist", true)}
                      >
                        School Team List
                      </a>
                      <a
                        className={
                          upachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementlist", true)}
                      >
                        Achievement Upload
                      </a>
                      <a
                        className={
                          upcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addcms", true)}
                      >
                        CMS
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-network"></i>
                  <div className="slider-text">Profile</div>
                </h2>
                <div
                  className={
                    proact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-network"></i>{" "}
                        Profile
                      </h4>{" "}
                      <a
                        className={pact ? "slide-item active" : "slide-item"}
                        // onClick={() => submenuactive("profile")}
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
