import { React, useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import {
  addathleteagecategorylist,
  addathletedelete,
  addathleteeventslist,
  addathletelist,
  addathleteplayers,
  addsearchathletelist,
  checkpaymentpaidornot,
  updateathletemobemail,
} from "../../../../../services/common";
import { useNavigate, useParams } from "react-router-dom";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { classlist } from "../../../../../services/master";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";

export const Addathlete = () => {
  const { id, eid } = useParams();
  const navigate = useNavigate();
  let array = [];

  const [agecat, setAgecat] = useState([]);
  const [elist, setEventlist] = useState([]);
  const [mcnt, setMcnt] = useState([]);
  const [alist, setAthletelist] = useState([]);
  const [aclist, setAthCatlist] = useState([]);

  const [apevent, setAthPerEvent] = useState("");
  const [epathlete, setEvePerAthlete] = useState(0);
  const [epathlete1, setEvePerAthlete1] = useState(0);
  const [etype, setEventType] = useState("");

  const [agename, setAgeName] = useState("");
  const [agender, setAGender] = useState("");
  const [eventformat, setEventFormat] = useState("");

  const [ename, setEventName] = useState("");
  const [schoolid, setSchool] = useState("");

  const [show, setShow] = useState(false);
  const [oneshow, setOneShow] = useState(false);
  const [ainfo, setAthInfo] = useState([]);
  const [s3path, setS3Path] = useState("");

  const [tname, setTName] = useState("");
  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      listall();
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });

      checkpaymentpaidornot(id, eid, 0).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Paid") {
            navigate("/cityregional/confirmation/" + id + "/" + eid);
          }
        }
      });
      addathleteagecategorylist(id).then((response) => {
        if (response.status === true) {
          array = [];
          if (response.data.age_categories.length !== 0) {
            for (let i = 0; i < response.data.age_categories.length; i++) {
              var act = "btn my-1 btn-gray tx-uppercase me-2";
              if (i == 0) {
                act = "btn my-1 btn-primary tx-uppercase me-2";
              }
              array.push({
                id: response.data.age_categories[i].id,
                agegender: response.data.age_categories[i].gender,
                age_categories: response.data.age_categories[i].age_categories,
                max_athlete_event:
                  response.data.age_categories[i].max_athlete_event,
                max_event_athlete:
                  response.data.age_categories[i].max_event_athlete,
                athletes_per_mixed_relay:
                  response.data.age_categories[i].athletes_per_mixed_relay,
                athletes_per_relay:
                  response.data.age_categories[i].athletes_per_relay,
                dob_start_date: response.data.age_categories[i].dob_start_date,
                dob_end_date: response.data.age_categories[i].dob_end_date,
                active: act,
              });
            }
          }
          setAgecat(array);
          setSDOB(response.data.age_categories[0].dob_start_date);
          setEDOB(response.data.age_categories[0].dob_end_date);
          setAthPerEvent(response.data.age_categories[0].max_athlete_event);
          setEvePerAthlete(response.data.age_categories[0].max_event_athlete);
          setEvePerAthlete1(
            response.data.age_categories[0].max_event_athlete_or
          );

          setAGender(response.data.age_categories[0].gender);

          setAgeName(response.data.age_categories[0].age_categories);

          agecatgoryevents(0, response.data.age_categories[0].id, eid);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });

      schprofileview().then((resp) => {
        if (resp.status === true) {
          setSchool(resp.data.school_id);
          checkpaymentpaidornot(id, resp.data.school_id, 0).then((response) => {
            if (response.status === true) {
              if (response.data.Payment_Status === "Paid") {
                navigate("/schoolhead/confirmation/" + id);
              }
            }
          });
          addathleteagecategorylist(id).then((response) => {
            if (response.status === true) {
              array = [];
              if (response.data.age_categories.length !== 0) {
                for (let i = 0; i < response.data.age_categories.length; i++) {
                  var act = "btn my-1 btn-gray tx-uppercase me-2";
                  if (i == 0) {
                    act = "btn my-1 btn-primary tx-uppercase me-2";
                  }
                  array.push({
                    id: response.data.age_categories[i].id,
                    agegender: response.data.age_categories[i].gender,
                    age_categories:
                      response.data.age_categories[i].age_categories,
                    max_athlete_event:
                      response.data.age_categories[i].max_athlete_event,
                    max_event_athlete:
                      response.data.age_categories[i].max_event_athlete,
                    athletes_per_mixed_relay:
                      response.data.age_categories[i].athletes_per_mixed_relay,
                    athletes_per_relay:
                      response.data.age_categories[i].athletes_per_relay,
                    dob_start_date:
                      response.data.age_categories[i].dob_start_date,
                    dob_end_date: response.data.age_categories[i].dob_end_date,
                    active: act,
                  });
                }
              }
              setAgecat(array);
              setSDOB(response.data.age_categories[0].dob_start_date);
              setEDOB(response.data.age_categories[0].dob_end_date);
              setAthPerEvent(response.data.age_categories[0].max_athlete_event);
              setEvePerAthlete(
                response.data.age_categories[0].max_event_athlete
              );
              setEvePerAthlete1(
                response.data.age_categories[0].max_event_athlete_or
              );
              setAGender(response.data.age_categories[0].gender);
              setAgeName(response.data.age_categories[0].age_categories);

              agecatgoryevents(
                0,
                response.data.age_categories[0].id,
                resp.data.school_id
              );
            }
          });
        }
      });
    } else {
      listall();
    }
  }, []);

  function listall() {
    addathleteagecategorylist(id).then((response) => {
      if (response.status === true) {
        array = [];
        if (response.data.age_categories.length !== 0) {
          for (let i = 0; i < response.data.age_categories.length; i++) {
            var act = "btn my-1 btn-gray tx-uppercase me-2";
            if (i == 0) {
              act = "btn my-1 btn-primary tx-uppercase me-2";
            }
            array.push({
              id: response.data.age_categories[i].id,
              agegender: response.data.age_categories[i].gender,
              age_categories: response.data.age_categories[i].age_categories,
              max_athlete_event:
                response.data.age_categories[i].max_athlete_event,
              max_event_athlete:
                response.data.age_categories[i].max_event_athlete,
              athletes_per_mixed_relay:
                response.data.age_categories[i].athletes_per_mixed_relay,
              athletes_per_relay:
                response.data.age_categories[i].athletes_per_relay,
              dob_start_date: response.data.age_categories[i].dob_start_date,
              dob_end_date: response.data.age_categories[i].dob_end_date,
              active: act,
            });
          }
        }

        setAgecat(array);
        setSDOB(response.data.age_categories[0].dob_start_date);
        setEDOB(response.data.age_categories[0].dob_end_date);
        setAthPerEvent(response.data.age_categories[0].max_athlete_event);
        setEvePerAthlete(response.data.age_categories[0].max_event_athlete);
        setEvePerAthlete1(response.data.age_categories[0].max_event_athlete_or);
        setAGender(response.data.age_categories[0].gender);
        setAgeName(response.data.age_categories[0].age_categories);

        agecatgoryevents(0, response.data.age_categories[0].id, 0);
      }
    });
  }

  var arr = [];
  function agecatgoryevents(index, catid, schid) {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "5"
    ) {
      schid = eid;
    }
    setAthPerEvent("");
    setEvePerAthlete(0);
    setEvePerAthlete1(0);
    setEventType("");
    setEventlist([]);
    setAGender("");
    setAgeName("");
    setMcnt([]);
    if (agecat.length !== 0) {
      for (let i = 0; i < agecat.length; i++) {
        if (index === i) {
          setSDOB(agecat[index].dob_start_date);
          setEDOB(agecat[index].dob_end_date);
          agecat[index].active = "btn my-1 btn-primary tx-uppercase me-2";
        } else {
          agecat[i].active = "btn my-1 btn-gray tx-uppercase me-2";
        }
      }
    }

    addathleteeventslist(id, catid).then((response) => {
      if (response.status === true) {
        setAthletelist([]);

        setAGender(response.data.age_category_events[0].gender);
        setAgeName(response.data.age_category_events[0].age_category_name);
        setEventFormat(response.data.age_category_events[0].eventtype);
        setEventName(response.data.age_category_events[0].event_name);
        arr = [];
        for (let i = 0; i < response.data.age_category_events.length; i++) {
          var clr = "navitxt";
          if (i == 0) {
            clr = "navitxt active";
          }
          arr.push({
            event_id: response.data.age_category_events[i].event_id,
            event_name: response.data.age_category_events[i].event_name,
            id: response.data.age_category_events[i].id,
            age_category_id:
              response.data.age_category_events[i].age_category_id,
            age_category_name:
              response.data.age_category_events[i].age_category_name,
            background: clr,
            athletes_per_mixed_relay:
              response.data.age_category_events[i].athletes_per_mixed_relay,
            athletes_per_relay:
              response.data.age_category_events[i].athletes_per_relay,
            max_athlete_event:
              response.data.age_category_events[i].max_athlete_event,
            max_event_athlete_field:
              response.data.age_category_events[i].max_event_athlete_field,
            max_event_athlete_field_or:
              response.data.age_category_events[i].max_event_athlete_field_or,
            max_athlete_field_event:
              response.data.age_category_events[i].max_athlete_field_event,
            max_event_athlete:
              response.data.age_category_events[i].max_event_athlete,
            max_event_athlete_or:
              response.data.age_category_events[i].max_event_athlete_or,
            eventtype: response.data.age_category_events[i].eventtype,
          });
        }
        setEventlist(arr);
        var math = 0;
        if (response.data.age_category_events[0].eventtype === 2) {
          var uevent =
            response.data.age_category_events[0].event_name.toUpperCase();
          let flag = uevent.includes("MIXED");
          if (flag) {
            if (
              response.data.age_category_events[0].athletes_per_mixed_relay ===
              0
            ) {
              math = 0;
              setAthPerEvent(0);
            } else {
              math =
                response.data.age_category_events[0].athletes_per_mixed_relay;
              setAthPerEvent(
                response.data.age_category_events[0].athletes_per_mixed_relay
              );
            }
          } else {
            if (response.data.age_category_events[0].athletes_per_relay === 0) {
              math = 0;
              setAthPerEvent(0);
            } else {
              math = response.data.age_category_events[0].athletes_per_relay;
              setAthPerEvent(
                response.data.age_category_events[0].athletes_per_relay
              );
            }
          }
          setEvePerAthlete(0);
          setEvePerAthlete1(0);
          setEventType("");
        } else if (response.data.age_category_events[0].eventtype === 3) {
          if (
            response.data.age_category_events[0].max_athlete_field_event === 0
          ) {
            math = 0;
            setAthPerEvent(0);
          } else {
            math = response.data.age_category_events[0].max_athlete_field_event;
            setAthPerEvent(
              response.data.age_category_events[0].max_athlete_field_event
            );
          }
          setEventType("field");
          setEvePerAthlete(
            response.data.age_category_events[0].max_event_athlete_field
          );
          setEvePerAthlete1(
            response.data.age_category_events[0].max_event_athlete_field_or
          );
        } else {
          if (response.data.age_category_events[0].max_athlete_event === 0) {
            math = 0;
            setAthPerEvent(0);
          } else {
            math = response.data.age_category_events[0].max_athlete_event;
            setAthPerEvent(
              response.data.age_category_events[0].max_athlete_event
            );
          }
          setEventType("track");
          setEvePerAthlete(
            response.data.age_category_events[0].max_event_athlete
          );
          setEvePerAthlete1(
            response.data.age_category_events[0].max_event_athlete_or
          );
        }
        eventathletelist(
          catid,
          response.data.age_category_events[0].events_tbl_id,
          math,
          schid
        );
      }
    });

    const tempData = new FormData();
    tempData.append("tourid", id);
    tempData.append("cate_id", catid);
    tempData.append("school_id", schid);
    addsearchathletelist(tempData).then((res) => {
      if (res.status === true) {
        setAthInfo(res.data.details);
        setS3Path(res.data.s3);
        if (res.data.details.length !== 0) {
          arr = [];
          for (let i = 0; i < res.data.details.length; i++) {
            arr.push({
              value: res.data.details[i].id,
              label:
                res.data.details[i].first_name +
                " (" +
                res.data.details[i].username +
                " ) - " +
                res.data.details[i].class_name +
                " " +
                res.data.details[i].section,
            });
          }
          setAthletelist(arr);
        }
      }
    });
  }

  function selectevent(evt, index, catid, schid) {
    setEventName("");
    setEventFormat("");
    setEventName(evt);
    setAthPerEvent("");
    setEvePerAthlete(0);
    setEvePerAthlete1(0);
    setEventType("");

    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3" ||
      localStorage.getItem("Role") === "5"
    ) {
      schid = eid;
    }
    elist[index].background = "navitxt active";

    var eventid = "";
    if (elist.length !== 0) {
      for (let j = 0; j < elist.length; j++) {
        if (elist[j].event_name === evt) {
          eventid = elist[j].id;
        } else {
          elist[j].background = "navitxt";
        }
      }
      setEventlist([...elist]);
    }

    var ageid = "";
    var aper = "";
    var eper = "";
    if (elist.length !== 0) {
      for (let j = 0; j < elist.length; j++) {
        if (elist[j].event_name === evt) {
          if (elist[j].age_category_name === agename) {
            ageid = elist[j].age_category_id;
            eper = elist[j].max_event_athlete;
            setEventFormat(elist[j].eventtype);

            if (elist[j].eventtype === 2) {
              var uevent = elist[j].event_name.toUpperCase();
              let flag = uevent.includes("MIXED");
              if (flag) {
                if (elist[j].athletes_per_mixed_relay === 0) {
                  aper = 0;
                  setAthPerEvent(0);
                } else {
                  aper = elist[j].athletes_per_mixed_relay;
                  setAthPerEvent(elist[j].athletes_per_mixed_relay);
                }
              } else {
                if (elist[j].athletes_per_relay === 0) {
                  aper = 0;
                  setAthPerEvent(0);
                } else {
                  aper = elist[j].athletes_per_relay;
                  setAthPerEvent(elist[j].athletes_per_relay);
                }
              }
              setEvePerAthlete(0);
              setEvePerAthlete1(0);
            } else if (elist[j].eventtype === 3) {
              if (elist[j].max_athlete_field_event === 0) {
                aper = 0;
                setAthPerEvent(0);
              } else {
                aper = elist[j].max_athlete_field_event;
                setAthPerEvent(elist[j].max_athlete_field_event);
              }
              setEventType("field");
              setEvePerAthlete(elist[j].max_event_athlete_field);
              setEvePerAthlete1(elist[j].max_event_athlete_field_or);
            } else {
              if (elist[j].max_athlete_event === 0) {
                aper = 0;
                setAthPerEvent(0);
              } else {
                aper = elist[j].max_athlete_event;
                setAthPerEvent(elist[j].max_athlete_event);
              }
              setEventType("track");
              setEvePerAthlete(elist[j].max_event_athlete);
              setEvePerAthlete1(elist[j].max_event_athlete_or);
            }
          }
        }
      }
    }

    const tempData = new FormData();
    tempData.append("tourid", id);
    tempData.append("cate_id", catid);
    tempData.append("school_id", schid);
    addsearchathletelist(tempData).then((res) => {
      if (res.status === true) {
        setAthletelist([]);
        setAthInfo(res.data.details);
        setS3Path(res.data.s3);
        if (res.data.details.length !== 0) {
          arr = [];
          for (let i = 0; i < res.data.details.length; i++) {
            arr.push({
              value: res.data.details[i].id,
              label:
                res.data.details[i].first_name +
                " (" +
                res.data.details[i].username +
                " ) - " +
                res.data.details[i].class_name +
                " " +
                res.data.details[i].section,
            });
          }
          setAthletelist(arr);
        }
      }
    });
    // if (agecat.length !== 0) {
    //   for (let j = 0; j < agecat.length; j++) {
    //     if (agecat[j].age_categories === agename) {
    //       ageid = agecat[j].id;
    //       eper = agecat[j].max_event_athlete;
    //       // setAthPerEvent(agecat[j].max_athlete_event);
    //       setEvePerAthlete(agecat[j].max_event_athlete);
    //       var uevent = evt.toUpperCase();
    // let flag = uevent.includes("MIXED");
    //       if (flag) {
    //         aper = agecat[j].athletes_per_mixed_relay;
    //         setAthPerEvent(agecat[j].athletes_per_mixed_relay);
    //       } else if (uevent.includes() === "RELAY") {
    //         aper = agecat[j].athletes_per_relay;
    //         setAthPerEvent(agecat[j].athletes_per_relay);
    //       } else {
    //         aper = agecat[j].max_athlete_event;
    //         setAthPerEvent(agecat[j].max_athlete_event);
    //       }
    //     }
    //   }
    // }

    if (localStorage.getItem("Role") !== "4") {
      eventathletelist(ageid, eventid, aper, eid);
    } else {
      eventathletelist(ageid, eventid, aper, schoolid);
    }
  }

  function eventathletelist(cid, eid, max, sid) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("category_id", cid);
    tempData.append("eventid", eid);
    tempData.append("school_id", sid);
    addathletelist(tempData).then((res) => {
      if (res.status === true) {
        setAthCatlist(res.data.details);
        setS3Path(res.data.s3);
        arr = [];
        var final = 0;
        if (max === 0) {
          final = 1;
        } else {
          final = max;
          if (res.data.details.length !== 0) {
            final = max - res.data.details.length;
          }
        }
        // var final = max;
        // if (res.data.details.length !== 0) {
        //   final = max - res.data.details.length;
        // }
        for (let i = 0; i < final; i++) {
          arr.push({
            id: i,
            load: "",
          });
        }
        setMcnt([...arr]);
      }
    });
  }

  function deleteathlete(did) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const athdata = aclist.find((data) => {
          return data.tbl_unique_id === did;
        });
        dotracking(
          "delete icon in add player for " +
            athdata.first_name +
            " (" +
            athdata.username +
            ") in " +
            athdata.tournament_category +
            " category and " +
            athdata.eventslist +
            " event  for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.touraddaplayerheading
        );
        addathletedelete(did, localStorage.getItem("UID")).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            var eventid = "";
            if (elist.length !== 0) {
              for (let j = 0; j < elist.length; j++) {
                if (elist[j].event_name === ename) {
                  eventid = elist[j].id;
                }
              }
            }
            var ageid = "";
            if (agecat.length !== 0) {
              for (let j = 0; j < agecat.length; j++) {
                if (agecat[j].age_categories === agename) {
                  ageid = agecat[j].id;
                }
              }
            }
            if (localStorage.getItem("Role") !== "4") {
              eventathletelist(ageid, eventid, apevent, eid);
            } else {
              eventathletelist(ageid, eventid, apevent, schoolid);
            }
          }
        });
      }
    });
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [aname, setAthName] = useState("");
  const [adob, setAthDob] = useState("");
  const [aclass, setAthClass] = useState("");
  const [asec, setAthSec] = useState("");
  const [amob, setAthMob] = useState("");
  const [aemail, setAthEmail] = useState("");
  const [abib, setAthBib] = useState("");

  const [aimage, setAthImage] = useState("");
  const [avalue, setAthlete] = useState("");

  const handleChange = (e) => {
    setErrorMessage("");
    setAthlete(e);
    setAthName("");
    setAthDob("");
    setAthClass("");
    setAthSec("");
    setAthMob("");
    setAthEmail("");
    setAthImage("");
    setAthBib("");

    setAthImage("");
    if (ainfo.length !== 0) {
      const athdata = ainfo.find((data) => {
        return data.id === e.value;
      });
      setAthName(athdata.first_name);
      setAthClass(athdata.class_name);
      setAthSec(athdata.section);
      if(athdata.mobile_no !== null){
        setAthMob(athdata.mobile_no);
      }

      if(athdata.email_id !== null){
        setAthEmail(athdata.email_id);
      }
      

      setAthDob(moment(athdata.date_of_birth).format("DD-MM-YYYY"));
      if (athdata.photograph !== null) {
        setAthImage(s3path + athdata.photograph);
      }
    }
  };

  const handleMobile = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAthMob(e.target.value);
    }
  };

  const handleBIB = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAthBib(e.target.value);
    }
  };

  function errorhandle(ev) {
    ev.target.src = require("../../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function handleShow() {
    // setAthlete("");
    // setAthName("");
    // setAthDob("");
    // setAthImage("");
    setErrorMessage("");
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setAthlete("");
    setAthName("");
    setAthClass("");
    setAthSec("");
    setAthMob("");
    setAthEmail("");
    setAthDob("");
    setAthImage("");
    setAthBib("");
  }

  function onehandleshow() {
    setOneShow(true);
  }
  function onehandleclose() {
    setOneShow(false);
  }

  const [newshow, setNewShow] = useState(false);
  const [nerrorMessage, setNErrorMessage] = useState("");

  const [nfname, setNfname] = useState("");
  const [ngender, setNGender] = useState("");
  const [nemail, setNEmail] = useState("");
  const [nmobile, setNMobile] = useState("");
  const [ndob, setNDOB] = useState("");
  const [nclass, setNClass] = useState("");
  const [nsec, setNsec] = useState("");
  const [nbib, setNBIB] = useState("");

  const [sclist, setClassList] = useState([]);

  function handlenewplayer() {
    setShow(false);
    setAthlete("");
    setAthName("");
    setAthClass("");
    setAthSec("");
    setAthMob("");
    setAthEmail("");
    setAthDob("");
    setAthImage("");
    setAthBib("");
    setNewShow(true);
    setNfname("");
    setNGender("");
    setNEmail("");
    setNMobile("");
    setNBIB("")
    setNDOB("");
    setNClass("");
    setNsec("");
    setScheck(false);
    setNErrorMessage("");
    classlist().then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });
  }

  function handlenewclose() {
    setShow(false);
    setNewShow(false);
    setNfname("");
    setNGender("");
    setNEmail("");
    setNMobile("");
    setNBIB("");
    setNDOB("");
    setNClass("");
    setNsec("");
    setScheck(false);
    setNErrorMessage("");
  }

  const CheckMobile = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNMobile(e.target.value);
    }
  };

  const CheckBIB = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNBIB(e.target.value);
    }
  };
  const [valmob, setValMob] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    var obj = {};
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        setValMob("Enter a Valid  Mobile Number");
      }
    }
  }
  const [scheck, setScheck] = useState(false);
  function playersub() {
    setScheck(true);
    setNErrorMessage("");
    if (
      nfname !== "" &&
      ngender !== "" &&
      // nemail !== "" &&
      // nmobile !== "" &&
      nbib !== "" &&
      ndob !== "" &&
      nclass !== "" &&
      nsec !== "" &&
      valmob === "" 
      // nerrorMessage === ""
    ) {
      var error = "";
      if (nemail !== "") {
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regEmail.test(nemail)) {
          error = "Please Enter a valid Email";
          setNErrorMessage("Please Enter a valid Email");
        }
      }

      if(nbib !== ""){
        if(nbib > 0){

        }else{
          error = "BIB Number must be greater than 0";
          setNErrorMessage( "BIB Number must be greater than 0");
        }
      }

      if (error === "") {
        var tcat = "";
        var tid = "";
        if (agecat.length !== 0) {
          for (let i = 0; i < agecat.length; i++) {
            if (agecat[i].active === "btn my-1 btn-primary tx-uppercase me-2") {
              tid = agecat[i].id;
              tcat = agecat[i].age_categories;
              break;
            }
          }
        }
        var eventid = "";
        if (elist.length !== 0) {
          for (let j = 0; j < elist.length; j++) {
            if (elist[j].event_name === ename) {
              eventid = elist[j].id;
            }
          }
        }

        const temp = new FormData();
        temp.append("first_name", nfname);
        temp.append("last_name", "");
        temp.append("middle_name", "");
        temp.append("photograph", "");
        temp.append("notification_enable", 1);
        temp.append("gender", ngender);
        temp.append("email_id", nemail);
        temp.append("mobile_no", nmobile);
        temp.append("date_of_birth", ndob);
        temp.append("class", nclass);
        temp.append("section", nsec);
        temp.append("tournament_meet_id", id);
        temp.append("category_id", tid);
        temp.append("tournament_category", tcat);
        if (localStorage.getItem("Role") !== "4") {
          temp.append("school_id", eid);
        } else {
          temp.append("school_id", schoolid);
        }
        var efor = 0;
        if (eventformat === 1) {
          efor = "Track";
        } else if (eventformat === 2) {
          efor = "Relay";
        } else if (eventformat === 3) {
          efor = "Field";
        }
        temp.append("event_type", efor);

        temp.append("eventslist", ename);
        temp.append("schlhead_or_crm_id", localStorage.getItem("UID"));
        temp.append("user_id", 0);
        temp.append("bib_no", nbib);

        dotracking(
          "submit button in add new player for " +
            avalue.label +
            " (" +
            avalue.value +
            ") in " +
            tcat +
            " category and " +
            ename +
            " event for " +
            tname +
            " (" +
            id +
            ") tournament with " +
            nfname +
            ",gender " +
            ngender +
            ",dob " +
            ndob +
            ",email " +
            nemail +
            ",mobile " +
            nmobile +
            " $$ " +
            trackobj.touraddaplayerheading
        );
        addathleteplayers(temp).then((response) => {
          if (response.status === true) {
            setNewShow(false);
            setNfname("");
            setNGender("");
            setNEmail("");
            setNMobile("");
            setNBIB("");
            setNDOB("");
            setNClass("");
            setNsec("");
            setNErrorMessage("");
            setScheck(false);
            setAthlete("");
            setAthName("");
            setAthClass("");
            setAthSec("");
            setAthMob("");
            setAthBib("");
            setAthEmail("");
            setAthDob("");
            setAthImage("");
            setShow(false);
            toast.success(response.message);
            const tempData = new FormData();
            tempData.append("tourid", id);
            tempData.append("cate_id", tid);
            if (localStorage.getItem("Role") !== "4") {
              tempData.append("school_id", eid);
            } else {
              tempData.append("school_id", schoolid);
            }

            addsearchathletelist(tempData).then((res) => {
              if (res.status === true) {
                setAthletelist([]);
                setAthInfo(res.data.details);
                setS3Path(res.data.s3);
                if (res.data.details.length !== 0) {
                  arr = [];
                  for (let i = 0; i < res.data.details.length; i++) {
                    arr.push({
                      value: res.data.details[i].id,
                      label:
                        res.data.details[i].first_name +
                        " (" +
                        res.data.details[i].username +
                        " ) - " +
                        res.data.details[i].class_name +
                        " " +
                        res.data.details[i].section,
                    });
                  }
                  setAthletelist(arr);
                }
              }
            });
            if (localStorage.getItem("Role") !== "4") {
              eventathletelist(tid, eventid, apevent, eid);
            } else {
              eventathletelist(tid, eventid, apevent, schoolid);
            }
          } else {
            setNErrorMessage(response.message);
          }
        });
      }
    }
  }

  function athletesubmit() {
    setErrorMessage("");
    if (avalue !== "") {
      var error = "";
      // if (aclist.length !== 0) {
      //   if (apevent <= aclist.length) {
      //     error = "Maximum 3 athletes allowed per event";
      //     setErrorMessage(
      //       "Maximum " + aclist.length + " athletes allowed per event"
      //     );
      //   }
      // }

      // if (amob === "") {
      //   error = "Please Enter Mobile Number";
      //   setErrorMessage("Please Enter Mobile Number");
      // } else if (aemail === "") {
      //   error = "Please Enter Email";
      //   setErrorMessage("Please Enter Email");
      // }
      if (amob !== "") {
        var obj = {};

        for (let x = 0, length = amob.length; x < length; x++) {
          var l = amob.charAt(x);
          obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
        }
        if (obj[l] === 10) {
          error = "Please Enter a valid Mobile Number";
          setErrorMessage("Enter a Valid Mobile Number");
        }
        if (amob.length !== 10) {
          error = "Mobile Number must be 10 characters";
          setErrorMessage("Mobile Number must be 10 characters");
        }
      }
      if(aemail !== ""){
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regEmail.test(aemail)) {
          error = "Please Enter a valid Email";
          setErrorMessage("Please Enter a valid Email");
        }
        
      }
       
      if(abib === ""){
        error = "Please Enter BIB Number";
        setErrorMessage("Please Enter BIB Number");
      }
      if(abib !== ""){
        if(abib > 0){

        }else{
          error = "BIB Number must be greater than 0";
          setErrorMessage( "BIB Number must be greater than 0");
        }
      }
      if (error === "") {
        const temp = new FormData();
        temp.append("mobile_no", amob);
        temp.append("email_id", aemail);
        temp.append("user_id", avalue.value);

        updateathletemobemail(temp).then((res) => {
          if (res.status === true) {
            if (ainfo.length !== 0) {
              for (let i = 0; i < ainfo.length; i++) {
                if (ainfo[i].id === avalue.value) {
                  ainfo[i].mobile_no = amob;
                  ainfo[i].email_id = aemail;
                }
              }
            }
          } else {
            error = res.message;
            setErrorMessage(res.message);
          }
        });

        const tempDate = new FormData();
        var tcat = "";
        var tid = "";
        if (agecat.length !== 0) {
          for (let i = 0; i < agecat.length; i++) {
            if (agecat[i].active === "btn my-1 btn-primary tx-uppercase me-2") {
              tid = agecat[i].id;
              tcat = agecat[i].age_categories;
              break;
            }
          }
        }
        var eventid = "";
        if (elist.length !== 0) {
          for (let j = 0; j < elist.length; j++) {
            if (elist[j].event_name === ename) {
              eventid = elist[j].id;
            }
          }
        }

        tempDate.append("tournament_meet_id", id);
        tempDate.append("category_id", tid);
        tempDate.append("tournament_category", tcat);
        if (localStorage.getItem("Role") !== "4") {
          tempDate.append("school_id", eid);
        } else {
          tempDate.append("school_id", schoolid);
        }

        var efor = 0;
        if (eventformat === 1) {
          efor = "Track";
        } else if (eventformat === 2) {
          efor = "Relay";
        } else if (eventformat === 3) {
          efor = "Field";
        }
        tempDate.append("event_type", efor);

        tempDate.append("eventslist", ename);
        tempDate.append("schlhead_or_crm_id", localStorage.getItem("UID"));
        tempDate.append("user_id", avalue.value);
        tempDate.append("bib_no", abib);

        dotracking(
          "add player button in add player for " +
            avalue.label +
            " (" +
            avalue.value +
            ") in " +
            tcat +
            " category and " +
            ename +
            " event for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.touraddaplayerheading
        );

        addathleteplayers(tempDate).then((response) => {
          if (response.status === true) {
            setAthlete("");
            setAthName("");
            setAthClass("");
            setAthSec("");
            setAthMob("");
            setAthBib("");
            setAthEmail("");
            setAthDob("");
            setAthImage("");
            setShow(false);
            toast.success(response.message);
            if (localStorage.getItem("Role") !== "4") {
              eventathletelist(tid, eventid, apevent, eid);
            } else {
              eventathletelist(tid, eventid, apevent, schoolid);
            }
          } else {
            setErrorMessage(response.message);
          }
        });
      }
    } else {
      setErrorMessage("Please Select Player");
    }
  }

  function backlist() {
    if (localStorage.getItem("Role") === "4") {
      dotracking(
        "back button in add player for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
    } else {
      dotracking(
        "back button in add player for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerheading
      );
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/playerlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/playerlist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/playerlist/" + id);
    }
  }

  function summlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "summary button in add player for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/superadmin/summary/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "summary button in add player for school (" +
          eid +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/cityregional/summary/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      dotracking(
        "summary button in add player for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toursummplayerheading
      );
      navigate("/schoolhead/summary/" + id);
    }
  }

  function LetterOnly(eve) {
    if (eve.key !== ".") {
      if (/^[a-zA-Z\s]*$/.test(eve.key)) {
        return true;
      } else {
        eve.preventDefault();
        return false;
      }
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Player</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Player
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-5">
                  <div className="text-right">
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3" ||
                      localStorage.getItem("Role") === "4") && (
                      <a className="btn btn-secondary me-2" onClick={summlist}>
                        <i className="fa-solid fa-list-alt"></i> Review & Submit
                      </a>
                    )}

                    <a className="btn btn-gray me-2" onClick={onehandleshow}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <h3 className="card-title">
                <span className="main-content-label tx-uppercase">
                  Select Age Categories
                </span>
              </h3>
              <div className="row">
                <div className="col-md-12">
                  {agecat.map((data, i) => {
                    return (
                      <a
                        key={i}
                        type="button"
                        className={data.active}
                        onClick={() => agecatgoryevents(i, data.id, schoolid)}
                      >
                        {" "}
                        {data.age_categories}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="col-md-12">
          <Form className="card">
            <div className="card-body">
              <h4 className="text-center text-danger">
                <span className="main-content-label tx-uppercase fw-bold">
                  Once you added the players for each events, Please do not
                  forget to submit the entries in Review & Submit page.
                </span>
              </h4>
            </div>
          </Form>
        </div>

        <div className="col-md-12">
          <form className="card eventslist">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <h3 className="card-title tx-uppercase">
                    <span className="main-content-label">Select Events</span>
                  </h3>
                  <hr />
                  {elist.map((data, i) => {
                    return (
                      <div key={i}>
                        {data.event_name !== "" && (
                          <div
                            className={data.background}
                            onClick={() =>
                              selectevent(
                                data.event_name,
                                i,
                                data.age_category_id,
                                schoolid
                              )
                            }
                          >
                            {/* <i className="fa fa-trophy icon1 me-2 tx-18"></i> */}
                            <h3 className="card-title tx-16 mb-0 lineheight1">
                              <span className="main-content-label tx-16 mb-0">
                                {data.event_name}
                              </span>
                            </h3>
                            <i className="fa fa-check-square icon1 ps-2 tx-16 ms-auto"></i>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-9">
                  <div className="custom-card">
                    <div className="bg-light py-3 mb-4">
                      <h3 className="pl-5">
                        {agename} [<span className="errortext">{ename}</span>]
                      </h3>
                      {epathlete !== 0 && (
                        <h5 className="pl-5">
                          Maximum {epathlete} {etype} event allowed per athletes{" "}
                          {apevent !== 0 && (
                            <span className="float-end errortext pr-3">
                              PLEASE SELECT {apevent} ATHLETE(S) MAXIMUM.
                            </span>
                          )}
                        </h5>
                      )}
                      {epathlete1 !== 0 && (
                        <>
                          <span className="pl-5 errortext">(OR)</span>
                          <h5 className="pl-5">
                            Maximum {epathlete1} {etype} event allowed per athletes{" "}
                          </h5>
                        </>
                      )}
                      {epathlete === 0 && (
                        <h5 className="errortext pl-5">
                          {apevent !== 0 && (
                            <>PLEASE SELECT {apevent} ATHLETE(S) MAXIMUM.</>
                          )}
                        </h5>
                      )}
                    </div>
                    <div className="row gutters-sm">
                      {aclist.map((data, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div className="athelete-card">
                              <div className="px-2 py-3 athelete-body">
                                <div className="picture text-center mb-1">
                                  {data.photograph === null && (
                                    <img
                                      src={require("../../../../../assets/images/users.png")}
                                      alt="Admin"
                                      className="rounded-circle"
                                    />
                                  )}
                                  {data.photograph === "" && (
                                    <img
                                      src={require("../../../../../assets/images/users.png")}
                                      alt="Admin"
                                      className="rounded-circle"
                                    />
                                  )}
                                  {data.photograph !== null && (
                                    <img
                                      src={s3path + data.photograph}
                                      alt="Admin"
                                      className="rounded-circle"
                                    />
                                  )}
                                </div>
                                <div className="text-center">
                                  <h3 className="mb-0">{data.first_name}</h3>
                                  <p className="ath-desc">
                                    ( {data.username} )
                                  </p>
                                  <h5>
                                    {moment(data.date_of_birth).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </h5>
                                  {data.bib_no !== null && (
                                    <h5 className="text-success">BIB : {data.bib_no}</h5>
                                  )}
                                  {data.mobile_no === null && <h5>-</h5>}
                                  {data.mobile_no === "" && <h5>-</h5>}
                                  {data.email_id === null && <h5>-</h5>}
                                  {data.mobile_no !== null && (
                                    <h5>{data.mobile_no}</h5>
                                  )}
                                  {data.email_id !== null && (
                                    <h5>{data.email_id}</h5>
                                  )}
                                  

                                  {/* <p className="mb-0">
                                    {moment(data.date_of_birth).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p> */}
                                </div>
                                <a
                                  className="deleteathlet"
                                  onClick={() =>
                                    deleteathlete(data.tbl_unique_id)
                                  }
                                >
                                  <i className="fa fa-close"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {mcnt.map((data, k) => {
                        return (
                          <div
                            className="col-md-4"
                            key={k}
                            onClick={handleShow}
                          >
                            <div className="athelete-card">
                              <div className="px-2 py-3 athelete-body">
                                <div className="tx-26 d-block py-5 lineheight356 w-100 text-center">
                                  <i className="fa fa-plus-circle errortext"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <Modal show={show}>
                        <Modal.Header>
                          <h4 className="rep-head">Add Player</h4>
                        </Modal.Header>
                        <Modal.Body className="mod-body">
                          <Select
                            placeholder="Select Player"
                            value={avalue}
                            options={alist}
                            onChange={handleChange}
                            isSearchable={true}
                          />
                          {aname !== "" && (
                            <div className="row mt-2">
                              {/* <div className="picture text-center">
                                  <img
                                    alt="avatar"
                                    className="rounded-circle"
                                    src={aimage}
                                    onError={(e) => errorhandle(e)}
                                  />
                                </div> */}
                              <div className="col-md-12">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Name</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {aname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Class</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {aclass}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Section</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {asec}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">DOB</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {adob}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        Mobile{" "}
                                        {/* <span className="errortext"> *</span> */}
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mobile Number"
                                        maxLength={10}
                                        value={amob}
                                        onChange={handleMobile}
                                        onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        Email{" "}
                                        {/* <span className="errortext"> *</span> */}
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Email"
                                        value={aemail}
                                        onChange={(e) =>
                                          setAthEmail(e.target.value)
                                        }
                                        onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="details-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        BIB Number{" "}
                                        <span className="errortext"> *</span>
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter BIB Number"
                                        maxLength={6}
                                        value={abib}
                                        onChange={handleBIB}
                                        onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="text-center mt-3">
                                  <h5 className="pro-user-username text-dark mt-2 mb-0 tx-18">
                                    {aname}
                                  </h5>

                                  <div className="text-center tx-16 mb-0">
                                    DOB : {adob}
                                  </div>
                                </div> */}
                            </div>
                          )}
                          {errorMessage !== "" && (
                            <span className="errortext">{errorMessage}</span>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <a
                            onClick={handlenewplayer}
                            className="btn btn-secondary"
                          >
                            <i className="fa fa-plus"></i> Add New Player
                          </a>

                          <a
                            className="btn btn-success"
                            onClick={athletesubmit}
                          >
                            Submit
                          </a>
                          <a onClick={handleclose} className="btn btn-gray">
                            Cancel
                          </a>
                        </Modal.Footer>
                      </Modal>

                      <Modal show={newshow}>
                        <Modal.Header>
                          <h4 className="rep-head">Add New Player</h4>
                        </Modal.Header>
                        <Modal.Body className="mod-body">
                          <div className="row mt-2">
                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Full Name{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      placeholder="Enter Full Name"
                                      className="form-control"
                                      value={nfname}
                                      onChange={(e) =>
                                        setNfname(e.target.value)
                                      }
                                      autoFocus
                                      onKeyPress={LetterOnly}
                                      // onPaste={onPasteLetter}
                                    />
                                    {scheck && !nfname && (
                                      <span className="errortext">
                                        Full Name is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Gender{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <select
                                      className="form-control"
                                      value={ngender}
                                      onChange={(e) =>
                                        setNGender(e.target.value)
                                      }
                                    >
                                      <option value="" defaultValue disabled>
                                        --- Select Gender ---
                                      </option>
                                      {agender === "Male" && (
                                        <option value="Male">Male</option>
                                      )}
                                      {agender === "Female" && (
                                        <option value="Female">Female</option>
                                      )}
                                    </select>
                                    {scheck && !ngender && (
                                      <span className="errortext">
                                        Gender is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Date of Birth{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={ndob}
                                      min={sdob}
                                      max={edob}
                                      onChange={(e) => setNDOB(e.target.value)}
                                      onKeyPress={(event) => {
                                        event.preventDefault();
                                      }}
                                      onKeyDown={(event) => {
                                        event.preventDefault();
                                      }}
                                    />
                                    {scheck && !ndob && (
                                      <span className="errortext">
                                        Date of Birth is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Class{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <select
                                      className="form-control"
                                      value={nclass}
                                      onChange={(e) =>
                                        setNClass(e.target.value)
                                      }
                                    >
                                      <option value="" defaultValue disabled>
                                        --- Select Class ---
                                      </option>
                                      {sclist.map((data, i) => {
                                        return (
                                          <option value={data.id} key={i}>
                                            {data.class}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    {scheck && !nclass && (
                                      <span className="errortext">
                                        Class is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Section{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      placeholder="Enter Section"
                                      className="form-control"
                                      value={nsec}
                                      onChange={(e) => setNsec(e.target.value)}
                                    />
                                    {scheck && !nsec && (
                                      <span className="errortext">
                                        Section is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Mobile Number{" "}
                                      {/* <span className="errortext"> *</span> */}
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Mobile Number"
                                      maxLength={10}
                                      value={nmobile}
                                      onChange={CheckMobile}
                                      onBlur={checkmob}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                    />
                                    {/* {scheck && !nmobile && (
                                      <span className="errortext">
                                        Mobile Number is required
                                      </span>
                                    )} */}
                                    {valmob !== "" && nmobile && (
                                      <span className="errortext">
                                        {valmob}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Email{" "}
                                      {/* <span className="errortext"> *</span> */}
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Email"
                                      value={nemail}
                                      onChange={(e) =>
                                        setNEmail(e.target.value)
                                      }
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                    />
                                    {/* {scheck && !nemail && (
                                      <span className="errortext">
                                        Email is required
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="details-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      BIB Number{" "}
                                      <span className="errortext"> *</span>
                                    </b>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter BIB Number"
                                      maxLength={6}
                                      value={nbib}
                                      onChange={CheckBIB}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                    />
                                    {scheck && !nbib && (
                                      <span className="errortext">
                                        BIB Number is required
                                      </span>
                                    )}
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {nerrorMessage !== "" && (
                            <span className="errortext">{nerrorMessage}</span>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <a className="btn btn-success" onClick={playersub}>
                            Submit
                          </a>
                          <a onClick={handlenewclose} className="btn btn-gray">
                            Cancel
                          </a>
                        </Modal.Footer>
                      </Modal>

                      <Modal show={oneshow} size="lg" onHide={onehandleclose}>
                        <Modal.Body>
                          <Row className="row-sm mx-0">
                            <Col lg={12} md={12}>
                              <h4 className="rep-head text-center">
                                Do you want go back / Do you want to Review &
                                Submit the entries ?
                              </h4>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <a className="btn btn-secondary" onClick={summlist}>
                            <i className="fa-solid fa-list-alt"></i> Review &
                            Submit
                          </a>
                          <a className="btn btn-gray" onClick={backlist}>
                            <i className="fe fe-corner-up-left" /> Back
                          </a>
                          <a
                            className="btn btn-danger"
                            onClick={onehandleclose}
                          >
                            Cancel
                          </a>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
