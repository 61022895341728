import React, { useEffect, useState } from "react";
import { citylist, districtlist, statelist } from "../../../../services/master";
import { useNavigate, useParams } from "react-router-dom";
import {
  schoolcreatecityreg,
  schooldetailcityreg,
  schoolupdatecityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const CityAddSchool = () => {
  const [schname, setSchName] = useState("");
  const [schcode, setSchCode] = useState("");
  const [addr, setSchAddr] = useState("");
  const [state, setState] = useState("");
  const [slist, setSList] = useState([]);
  const [dist, setDist] = useState("");
  const [dlist, setDList] = useState([]);
  const [city, setCity] = useState("");
  const [clist, setCList] = useState([]);
  const [pin, setPin] = useState("");
  const [status, setStatus] = useState(0);

  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();

  const hanglePincode = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPin(e.target.value);
    }
  };

  function selectstate(e) {
    setState(e.target.value);
    setDist("");
    setDList([]);
    setCList([]);
    setCity("");
    if (e.target.value !== "") {
      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDList(response.data);
        }
      });
      citylist(e.target.value).then((response) => {
        if (response.status === true) {
          setCList(response.data);
        }
      });
    }
  }

  useEffect(() => {
    statelist().then((response) => {
      if (response.status === true) {
        setSList(response.data);
      }
    });
    if (id !== undefined) {
      schooldetailcityreg(id).then((response) => {
        if (response.status === true && response.data !== null) {
          setSchName(response.data.details.school_name);
          setSchCode(response.data.details.school_unique_no);
          setSchAddr(response.data.details.address);
          setStatus(response.data.details.is_active);

          if (response.data.details.state !== 0) {
            setState(response.data.details.state);
            districtlist(response.data.details.state).then((response1) => {
              if (response1.status === true) {
                setDList(response1.data);
              }
            });
            citylist(response.data.details.state).then((response2) => {
              if (response2.status === true) {
                setCList(response2.data);
              }
            });
          }
          if (response.data.details.district !== 0) {
            setDist(response.data.details.district);
          }
          if (response.data.details.city !== 0) {
            setCity(response.data.details.city);
          }

          if (response.data.details.pincode !== 0) {
            setPin(response.data.details.pincode);
          }
          if (response.data.details.school_logo !== null) {
            setLogoUrl(
              response.data.s3_logo + response.data.details.school_logo
            );
          }
          if (response.data.details.princi_signature !== null) {
            setSignUrl(
              response.data.s3_signature +
                response.data.details.princi_signature
            );
          }
        }
      });
    }
  }, []);

  function backlist() {
    dotracking("back button in add school for " + schname + " $$ " + trackobj.schoollistheading);
    navigate("/cityregional/schoollist");
  }

  const [logofile, setLogoFile] = useState("");
  const [signfile, setSignFile] = useState("");
  const [logourl, setLogoUrl] = useState("");
  const [signurl, setSignUrl] = useState("");

  const [lerror, setLError] = useState("");
  function logosch(event) {
    setLogoFile("");
    setLError("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLogoFile(event.target.files[0]);
      } else {
        setLError("Logo should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setLError("Logo size should be less than 5MB");
      }
    }
  }

  const [serror, setSError] = useState("");
  function signsch(event) {
    setSignFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setSignFile(event.target.files[0]);
      } else {
        setSError("Signature should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setSError("Signature size should be less than 5MB");
      }
    }
  }

  const navigate = useNavigate();
  function submitschool(e) {
    e.preventDefault();
    setSubmitted(true);
    if (
      schname !== "" &&
      schcode !== "" &&
      addr !== "" &&
      state !== "" &&
      dist !== "" &&
      city !== "" &&
      pin !== ""
    ) {
      if (id === undefined) {
        const temp = new FormData();
        temp.append("school_name", schname);
        temp.append("address", addr);
        temp.append("pincode", pin);
        temp.append("city", city);
        temp.append("state", state);
        temp.append("district", dist);
        temp.append("school_unique_no", schcode);
        temp.append("is_active", 1);
        temp.append("logo", logofile);
        temp.append("signature", signfile);
        dotracking(
          "submit button in add school for " +
            schname +
            ", CHID " +
            schcode +
            " ,address " +
            addr +
            " ,pincode " +
            pin +
            " ,city " +
            city +
            " ,state " +
            state +
            " ,district " +
            dist + " $$ " + trackobj.schoollistheading
        );
        schoolcreatecityreg(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/cityregional/schoollist");
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        const temp = new FormData();
        temp.append("id", id);
        temp.append("school_name", schname);
        temp.append("address", addr);
        temp.append("pincode", pin);
        temp.append("city", city);
        temp.append("state", state);
        temp.append("district", dist);
        temp.append("school_unique_no", schcode);
        temp.append("is_active", status);
        temp.append("logo", logofile);
        temp.append("signature", signfile);
        dotracking("submit button in edit school for " + schname + " (" + id + ") $$ " + trackobj.schoollistheading);

        schoolupdatecityreg(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/cityregional/schoollist");
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          {id === undefined && <h4 className="mb-0">Add School</h4>}
          {id !== undefined && <h4 className="mb-0">School Details</h4>}
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>School Head</a>
          </li>
          {id === undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              Add School
            </li>
          )}
          {id !== undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              School Details
            </li>
          )}
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      School <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="School"
                      className="form-control"
                      value={schname}
                      onChange={(e) => setSchName(e.target.value)}
                    />
                    {submitted && !schname && (
                      <span className="errortext">School is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      School Code <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="School Code"
                      className="form-control"
                      value={schcode}
                      onChange={(e) => setSchCode(e.target.value)}
                    />
                    {submitted && !schcode && (
                      <span className="errortext">School Code is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Address <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Address"
                      className="form-control"
                      value={addr}
                      onChange={(e) => setSchAddr(e.target.value)}
                    />
                    {submitted && !addr && (
                      <span className="errortext">Address is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      State <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control"
                      value={state}
                      onChange={selectstate}
                    >
                      <option value="" defaultValue disabled>
                        --- Select State ---
                      </option>
                      {slist.map((data, i) => {
                        return (
                          <option value={data.stateuid} key={i}>
                            {data.statename}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !state && (
                      <span className="errortext">State is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      District <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control"
                      value={dist}
                      onChange={(e) => setDist(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --- Select District ---
                      </option>
                      {dlist.map((data, i) => {
                        return (
                          <option value={data.districtuid} key={i}>
                            {data.districtname}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !dist && (
                      <span className="errortext">District is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      City <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --- Select City ---
                      </option>
                      {clist.map((data, i) => {
                        return (
                          <option value={data.city_uid} key={i}>
                            {data.cityname}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !city && (
                      <span className="errortext">City is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Pincode <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                      maxLength={6}
                      value={pin}
                      onChange={hanglePincode}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                    {submitted && !pin && (
                      <span className="errortext">Pincode is required</span>
                    )}
                  </div>
                </div>
              </div>
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}

              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">School Logo</label>
                    {id !== undefined && (
                      <img
                        className="profile_img mb-2"
                        src={logourl}
                        height="160"
                        alt="photo"
                        onError={(e) => errorhandle(e)}
                      />
                    )}
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={logosch}
                    />
                    {lerror !== "" && (
                      <span className="errortext">{lerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Principal Signature</label>
                    {id !== undefined && (
                      <img
                        className="profile_img mb-2"
                        src={signurl}
                        height="160"
                        alt="photo"
                        onError={(e) => errorhandle(e)}
                      />
                    )}
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={signsch}
                    />
                    {serror !== "" && (
                      <span className="errortext">{serror}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-center border-top">
              <button
                type="submit"
                className="btn btn-gray me-2"
                onClick={backlist}
              >
                Back
              </button>

              <button
                type="submit"
                className="btn btn-success me-2"
                onClick={submitschool}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
