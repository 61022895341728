import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  schprofileview,
} from "../../../../services/schoolhead";
import { useState } from "react";
import { gettokenfromapi } from "../../../../services/superadmin";
import { sahassessmentdatelist } from "../../../../services/sahapi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const SchoolState = () => {
  const [snlist1, set1SNList] = useState("");
  const [std1, set1Std] = useState([]);
  const [school1, set1School] = useState([]);
  const [national1, set1National] = useState([]);

  const [snlist2, set2SNList] = useState("");
  const [std2, set2Std] = useState([]);
  const [school2, set2School] = useState([]);
  const [national2, set2National] = useState([]);

  const [snlist3, set3SNList] = useState("");
  const [std3, set3Std] = useState([]);
  const [school3, set3School] = useState([]);
  const [national3, set3National] = useState([]);

  const [snlist4, set4SNList] = useState("");
  const [std4, set4Std] = useState([]);
  const [school4, set4School] = useState([]);
  const [national4, set4National] = useState([]);

  const [snlist5, set5SNList] = useState("");
  const [std5, set5Std] = useState([]);
  const [school5, set5School] = useState([]);
  const [national5, set5National] = useState([]);

  const [snlist6, set6SNList] = useState("");
  const [std6, set6Std] = useState([]);
  const [school6, set6School] = useState([]);
  const [national6, set6National] = useState([]);

  const [snlist7, set7SNList] = useState("");
  const [std7, set7Std] = useState([]);
  const [school7, set7School] = useState([]);
  const [national7, set7National] = useState([]);

  const [snlistover, setSNOver] = useState("");
  const [schoolover, setOverSchool] = useState([]);
  const [nationalover, setOverNational] = useState([]);

  let arr = [];
  const [schname, setSchName] = useState("");
  const [loading, setLoading] = useState(false);

  const [datefor, setDateFormat] = useState("");

  useEffect(() => {
    setLoading(true);
    schprofileview().then((res) => {
      if (res.status === true) {
        
        setSchName(res.data.school_name);
        const temp = new FormData();
        temp.append(
          "data_req",
          "scl_vs_state_avg" + res.data.school_unique_no
        );
        gettokenfromapi(temp).then((res2) => {
          if (res2.status === true) {
            sahassessmentdatelist("scl_vs_state_avg&chid=" + res.data.school_unique_no,res2.data).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                    arr = [];
                    // const dfor = response.data.find((dw) => dw.report_date !== "");
    
                    // setDateFormat(dfor.report_date);
                    const filtered = response.data.filter((user) =>
                      user.report_name.includes("30m run")
                    );
                    set1SNList(filtered[0].report_name);
    
                    if (
                      filtered.length !== 0 &&
                      filtered[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered[0].std_wise_array.length; i++) {
                        if (
                          filtered[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered[0].report_name,
                            std: filtered[0].std_wise_array[i].std,
                            school:
                              filtered[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered2 = response.data.filter((user) =>
                      user.report_name.includes("Beep Test")
                    );
                    set2SNList(filtered2[0].report_name);
    
                    if (
                      filtered2.length !== 0 &&
                      filtered2[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered2[0].std_wise_array.length; i++) {
                        if (
                          filtered2[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered2[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered2[0].report_name,
                            std: filtered2[0].std_wise_array[i].std,
                            school:
                              filtered2[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered2[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered3 = response.data.filter((user) =>
                      user.report_name.includes("Medicine Ball Throw")
                    );
                    set3SNList(filtered3[0].report_name);
    
                    if (
                      filtered3.length !== 0 &&
                      filtered3[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered3[0].std_wise_array.length; i++) {
                        if (
                          filtered3[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered3[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered3[0].report_name,
                            std: filtered3[0].std_wise_array[i].std,
                            school:
                              filtered3[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered3[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered4 = response.data.filter((user) =>
                      user.report_name.includes("Sit & Reach")
                    );
                    set4SNList(filtered4[0].report_name);
    
                    if (
                      filtered4.length !== 0 &&
                      filtered4[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered4[0].std_wise_array.length; i++) {
                        if (
                          filtered4[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered4[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered4[0].report_name,
                            std: filtered4[0].std_wise_array[i].std,
                            school:
                              filtered4[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered4[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered5 = response.data.filter((user) =>
                      user.report_name.includes("Standing Broad Jump")
                    );
                    set5SNList(filtered5[0].report_name);
    
                    if (
                      filtered5.length !== 0 &&
                      filtered5[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered5[0].std_wise_array.length; i++) {
                        if (
                          filtered5[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered5[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered5[0].report_name,
                            std: filtered5[0].std_wise_array[i].std,
                            school:
                              filtered5[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered5[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered6 = response.data.filter((user) =>
                      user.report_name.includes("Sit Ups in 30 sec")
                    );
                    set6SNList(filtered6[0].report_name);
    
                    if (
                      filtered6.length !== 0 &&
                      filtered6[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered6[0].std_wise_array.length; i++) {
                        if (
                          filtered6[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered6[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                          arr.push({
                            report_name: filtered6[0].report_name,
                            std: filtered6[0].std_wise_array[i].std,
                            school:
                              filtered6[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered6[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filtered7 = response.data.filter((user) =>
                      user.report_name.includes("Sit Ups in 1 min")
                    );
                    set7SNList(filtered7[0].report_name);
    
                    if (
                      filtered7.length !== 0 &&
                      filtered7[0].std_wise_array.length !== 0
                    ) {
                      for (let i = 0; i < filtered7[0].std_wise_array.length; i++) {
                        if (
                          filtered7[0].std_wise_array[i].skl_vs_state_avg[0]
                            .scl_avg_score !== 0 ||
                          filtered7[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score !== 0
                        ) {
                        
                          arr.push({
                            report_name: filtered7[0].report_name,
                            std: filtered7[0].std_wise_array[i].std,
                            school:
                              filtered7[0].std_wise_array[i].skl_vs_state_avg[0]
                                .scl_avg_score,
                            national: filtered7[0].std_wise_array[i].skl_vs_state_avg[1]
                            .state_avg_score,
                          });
                        }
                      }
                    }
    
                    const filteredover = response.data.filter((user) =>
                      user.report_name.includes("Overall Report")
                    );
                    setSNOver(filteredover[0].report_name);
    
                    if (
                      filteredover.length !== 0 &&
                      filteredover[0].skl_vs_state_avg_overall.length !== 0
                    ) {
                      if (
                        filteredover[0].skl_vs_state_avg_overall[0].scl_avg_score !==
                          0 ||
                        filteredover[0].skl_vs_state_avg_overall[1].state_avg_score !==
                          0
                      ) {
                        arr.push({
                          report_name: filteredover[0].report_name,
                          std: "",
                          school:
                            filteredover[0].skl_vs_state_avg_overall[0].scl_avg_score,
                          national: filteredover[0].skl_vs_state_avg_overall[1]
                          .state_avg_score,
                        });
                      }
                    }
                  
    
                  const fdata = arr.filter((user) =>
                    user.report_name.includes("30m run")
                  );
                  if (fdata.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < fdata.length; i++) {
                      stan.push(fdata[i].std);
                      sdata.push(fdata[i].school);
                      ndata.push(fdata[i].national);
                    }
                    set1Std(stan);
                    set1School(sdata);
                    set1National(ndata);
                  }
    
                  const f2data = arr.filter((user) =>
                    user.report_name.includes("Beep Test")
                  );
                  if (f2data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f2data.length; i++) {
                      stan.push(f2data[i].std);
                      sdata.push(f2data[i].school);
                      ndata.push(f2data[i].national);
                    }
                    set2Std(stan);
                    set2School(sdata);
                    set2National(ndata);
                  }
    
                  const f3data = arr.filter((user) =>
                    user.report_name.includes("Medicine Ball Throw")
                  );
                  if (f3data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f3data.length; i++) {
                      stan.push(f3data[i].std);
                      sdata.push(f3data[i].school);
                      ndata.push(f3data[i].national);
                    }
                    set3Std(stan);
                    set3School(sdata);
                    set3National(ndata);
                  }
    
                  const f4data = arr.filter((user) =>
                    user.report_name.includes("Sit & Reach (in cm.)")
                  );
                  if (f4data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f4data.length; i++) {
                      stan.push(f4data[i].std);
                      sdata.push(f4data[i].school);
                      ndata.push(f4data[i].national);
                    }
                    set4Std(stan);
                    set4School(sdata);
                    set4National(ndata);
                  }
    
                  const f5data = arr.filter((user) =>
                    user.report_name.includes("Standing Broad Jump")
                  );
                  if (f5data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f5data.length; i++) {
                      stan.push(f5data[i].std);
                      sdata.push(f5data[i].school);
                      ndata.push(f5data[i].national);
                    }
                    set5Std(stan);
                    set5School(sdata);
                    set5National(ndata);
                  }
    
                  const f6data = arr.filter((user) =>
                    user.report_name.includes("Sit Ups in 30 sec")
                  );
                  if (f6data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f6data.length; i++) {
                      stan.push(f6data[i].std);
                      sdata.push(f6data[i].school);
                      ndata.push(f6data[i].national);
                    }
                    set6Std(stan);
                    set6School(sdata);
                    set6National(ndata);
                  }
    
                  const f7data = arr.filter((user) =>
                    user.report_name.includes("Sit Ups in 1 min")
                  );
                  if (f7data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f7data.length; i++) {
                      stan.push(f7data[i].std);
                      sdata.push(f7data[i].school);
                      ndata.push(f7data[i].national);
                    }
                    set7Std(stan);
                    set7School(sdata);
                    set7National(ndata);
                  }
    
                  const fodata = arr.filter((user) =>
                    user.report_name.includes("Overall Report")
                  );
                  if (fodata.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < fodata.length; i++) {
                      sdata.push(fodata[i].school);
                      ndata.push(fodata[i].national);
                    }
                    setOverSchool(sdata);
                    setOverNational(ndata);
                  }
                  // console.log('End',new Date().toLocaleString())
                  setLoading(false);
                }
              }
            });
          }
        });
      }
    });
  }, []);

  const downloadPDF = () => {
    dotracking(
      "download PDF button in School Vs State $$ " + trackobj.schstateheading
    );

    const content = document.getElementById("pdf-content");

    if (content) {
      html2canvas(content, { scrollY: -window.scrollY }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 296;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 10;
        pdf.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 10;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("School Vs State.pdf");
      });
    } 
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Vs State</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Student Fitness</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            School Vs State
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}

      {!loading && (
        <div >
        <div className="col-12 mt-2">
                  <div className="text-right">
                    <a className="btn btn-secondary" onClick={downloadPDF}>
                      <i className="fa fa-file-pdf"></i> Download PDF
                    </a>
                  </div>
                </div>
        
          {snlist1 === "" &&
            snlist2 === "" &&
            snlist3 === "" &&
            snlist4 === "" &&
            snlist5 === "" &&
            snlist6 === "" && (
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="mb-2 text-success text-danger">
                        School Vs State will be updated soon
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="row mt-2" id="pdf-content">
            {snlist1 !== "" && (
              <div className="col-md-12">
                <div className="card mt-0" style={{ height: "450px",width: "100%" }}>
                  <div className="card-body" >
                    {/* <h4 className="text-center">
                      Note: The higher the fitness level of the child, the
                      lesser the number of seconds to complete the run.
                    </h4> */}
                    <Bar
                      data={{
                        labels: std1,
                        datasets: [
                          {
                            label: schname,
                            data: school1,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national1,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "30m Run(in seconds) School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average of 30m Run(in seconds)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {snlist2 !== "" && (
              <div className="col-md-12">
                <div className="card mt-0" style={{ height: "450px",width: "100%" }}>
                  <div className="card-body" >
                    <Bar
                      data={{
                        labels: std2,
                        datasets: [
                          {
                            label: schname,
                            data: school2,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national2,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Beep Test (number of Laps) School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average of Beep Test (number of Laps)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {snlist3 !== "" && (
              <div className="col-md-12">
                <div className="card mt-0" style={{ height: "450px",width: "100%" }}>
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std3,
                        datasets: [
                          {
                            label: schname,
                            data: school3,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national3,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Medicine Ball throw (in meters) School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average of Medicine Ball throw (in meters)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
           
            {snlist6 !== "" && (
              <div className="col-md-12">
                <div className="card mt-6" style={{ height: "450px",width: "100%"}}>
                  <div className="card-body">
                    <h4 className="text-center">
                      Note: Assessment held only for S4 and S5.
                    </h4>
                    <Bar
                      data={{
                        labels: std6,
                        datasets: [
                          {
                            label: schname,
                            data: school6,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national6,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Number of Sit Ups in 30 seconds School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average Number of Sit Ups in 30 seconds",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {snlist7 !== "" && (
              <div className="col-md-12">
                <div className="card mt-0" style={{ height: "450px",width: "100%" }}>
                  <div className="card-body">
                    <h4 className="text-center">
                      Note: Assessment held for S6 and over.
                    </h4>

                    <Bar
                      data={{
                        labels: std7,
                        datasets: [
                          {
                            label: schname,
                            data: school7,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national7,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Number of Sit Ups in 1 Minute School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average Number of Sit Ups in 1 Minute",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {snlist4 !== "" && (
              <div className="col-md-12">
                <div className="card mt-0" style={{ height: "450px",width: "100%" }}>
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std4,
                        datasets: [
                          {
                            label: schname,
                            data: school4,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national4,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Sit and Reach (in cm.) School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average of Sit and Reach (in cm.)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            
            {snlist5 !== "" && (
              <div className="col-md-12">
                <div className="card mt-7" style={{ height: "450px",width: "100%"}}>
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std5,
                        datasets: [
                          {
                            label: schname,
                            data: school5,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: "State Average",
                            data: national5,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Standing Broad Jump (in meters) School Average Vs State Average",
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Average of Standing Broad Jump (in meters)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>
      )}
    </div>
  );
};
