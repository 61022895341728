import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { statelist } from "../../../../services/master";
import { districtcreate, districtdetails, districtmasterlist, districtstatus, districtupdate, districtvalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const DistrictMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [distid, setId] = useState(0);
    const [distname, setDistrict] = useState("");
    const [stateid, setState] = useState("");
    const [slist, setSList] = useState([]);
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "District",
            field: "districtname",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "State",
            field: "statename",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "isactive",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deletedistrict(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "districtuid",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a onClick={() => viewdistrict(params.value)} title="Edit District"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>
                );
            },
        },
    ];

    function viewdistrict(aid) {
        setShow(true)
        districtdetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.districtuid)
                setState(response.data.stateuid)
                setDistrict(response.data.districtname)
                setStatus(response.data.isactive + "")
                dotracking("edit button in district for " + response.data.districtname + " ("+ aid +") $$ " + trackobj.distlistheading)

            }
        })
    }

    function handleshow() {
        setId(0);
        setDistrict("");
        setState("");
        setStatus("1");
        setErrorMsg("");
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setDistrict("");
        setState("");
        setStatus("1");
        setErrorMsg("");
    }

    function deletedistrict(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("districtuid", list.data.districtuid)
                    temp.append("isactive", 0)
                    dotracking(
                        "status icon in district list from Active to Inactive for " + list.data.districtname + " (" + list.data.districtuid + ") $$ " + trackobj.distlistheading
                    )
                    districtstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("districtuid", list.data.districtuid)
                    temp.append("isactive", 1)
                    dotracking(
                        "status icon in district list from Inactive to Active for " + list.data.districtname + " (" + list.data.districtuid + ") $$ " + trackobj.distlistheading
                    )
                    districtstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }
    useEffect(() => {
        listall();
        statelist().then((response) => {
            if (response.status === true) {
                setSList(response.data)
            }
        })
    }, []);

    function listall() {
        setFilterProduct([]);
        districtmasterlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function submitdistrict(e) {
        e.preventDefault();
        setErrorMsg("")
        if (distname !== "") {
            if (stateid !== "") {
                if (distid === 0) {
                    const temp1 = new FormData();
                    temp1.append("state_id", stateid)
                    temp1.append("district", distname)
                    districtvalidate(temp1).then((res) => {
                        if (res.status === true) {
                            const temp = new FormData();
                            temp.append("stateuid", stateid)
                            temp.append("districtname", distname)
                            temp.append("isactive", status)
                            dotracking("add button for district in district list for " + distname + " $$ " + trackobj.distlistheading)
                            districtcreate(temp).then((response) => {
                                if (response.status === true) {
                                    handleclose();
                                    listall();
                                    toast.success(response.message);
                                }
                            })
                        } else {
                            Swal.fire({
                                text: distname + " " + res.message,
                                icon: "warning",
                                showCancelButton: false,
                                confirmButtonColor: "#4ecc48",
                                cancelButtonColor: "#6259ca",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setId(0);
                                    setDistrict("");
                                    setState("");
                                    setStatus("1");
                                    setErrorMsg("");
                                }
                            });
                        }
                    })

                } else if (distid !== 0) {
                    const temp = new FormData();
                    temp.append("districtuid", distid)
                    temp.append("stateuid", stateid)
                    temp.append("districtname", distname)
                    temp.append("isactive", status)
                    dotracking("update button for district in district list for " + distname + " (" + distid + ") $$ " + trackobj.distlistheading)
                    districtupdate(temp).then((response) => {
                        if (response.status === true) {
                            handleclose();
                            listall();
                            toast.success(response.message);
                        }
                    })
                }
            } else {
                setErrorMsg("Please Select State")
            }

        } else {
            setErrorMsg("Please Enter District Name")
        }
    }


    return (
        <div className="side-app">

            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">District Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        District Lists
                    </li>
                </ol>
            </div>




            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            <div className="text-right">
                                <a className="btn btn-success" onClick={handleshow}>
                                    <i className="fa fa-plus"></i> Add District
                                </a>
                            </div>
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {distid !== 0 &&
                                        <h4> District Details</h4>
                                    }
                                    {distid === 0 &&
                                        <h4> Add District</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    District Name{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="District Name"
                                                    className="form-control"
                                                    value={distname}
                                                    onChange={(e) => setDistrict(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    State{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <select className="form-control custom-select" value={stateid}
                                                    onChange={(e) => setState(e.target.value)}>
                                                    <option value="" defaultValue disabled>--- Select State ---</option>
                                                    {slist.map((data, i) => {
                                                        return (
                                                            <option value={data.stateuid} key={i}>
                                                                {data.statename}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>

                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {distid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitdistrict}
                                        >
                                            Update
                                        </a>
                                    }
                                    {distid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitdistrict}
                                        >
                                            Add
                                        </a>
                                    }


                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
