import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment/moment";
import { logindata } from "../../services/login";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";
import { Col, Modal, Row } from "react-bootstrap";
import {
  duplicateplayer,
  eventdetailswithstring,
  eventindividualregister,
  eventplayerpaidornot,
  playereventpayment,
  validatemail,
  validatemobile,
} from "../../services/register";
import { classlist, schoollistmaster } from "../../services/master";

export const EventRegister = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paypage, setPaypage] = useState(true);
  const [eventid, setEventID] = useState("");
  const [shlist, setSchList] = useState([]);
  const [sdata, setSchData] = useState("");
  const [schoolid, setSchID] = useState("");
  const [schname, setSchName] = useState("");

  const [fname, setFName] = useState("");
  const [sclist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [amt, setAmount] = useState("0");

  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const [valemail, setValEmail] = useState("");
  const [emailerr, setEmailErr] = useState("");

  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setValEmail("Please Enter a valid Email Address");
      }

      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setEmailErr(response.message);
        }
      });
    }
  }

  const [valmob, setValMob] = useState("");
  const [mobnoerr, setMobErr] = useState("");

  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    setMobErr("");
    var obj = {};
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        setValMob("Enter a Valid  Mobile Number");
      }
      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setMobErr(response.message);
        }
      });
    }
  }

  const [clsname, setClsName] = useState("");
  function selectclass(e) {
    setStuClass(e.target.value);
    if (e.target.value !== "") {
      const data = sclist.find((item) => item.id === parseInt(e.target.value));
      setClsName(data.class);
    }
  }

  const [agename, setAgeName] = useState([]);
  const [mfree, setMFree] = useState("");

  const [check, setCheck] = useState(true);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [chnoti, setChkNoti] = useState(true);
  function checknoti(e) {
    setChkNoti(e.target.checked);
  }

  const [errorMsg, setErrMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [show, setShow] = useState(false);
  const [dupdata, setDupData] = useState([]);

  function regsubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setIsProcessing(true);
    setValMob("");
    setValEmail("");
    setErrMsg("");
    var valid = "";
    var obj = {};

    if (mobno.length !== 10) {
      valid = "Not";
      setValMob("Mobile Number must be 10 characters");
    }
    if (mobno.length === 10) {
      for (let x = 0, length = mobno.length; x < length; x++) {
        var l = mobno.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        valid = "Not";
        setValMob("Enter a Valid  Mobile Number");
      }
    }
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      valid = "Not";
      setValEmail("Please Enter a valid Email Address");
    }

    if (
      valid === "" &&
      fname !== "" &&
      schoolid !== "" &&
      stuclass !== "" &&
      stusec !== "" &&
      gender !== "" &&
      dob !== "" &&
      mobno !== "" &&
      email !== "" &&
      mobnoerr === "" &&
      emailerr === ""
    ) {
      if (check === false) {
        valid = "required";
        setErrMsg("Please Select Disclaimer");
      }
      if (schoolid === 0) {
        if (schname === "") {
          valid = "required";
          setErrMsg("Please Enter School Name");
        }
      }

      if (valid === "") {
        if (multi === 0) {
          const temp2 = new FormData();
          temp2.append("mobile_no", mobno);
          temp2.append("email_id", email);
          temp2.append("first_name", fname);
          temp2.append("gender", gender);
          temp2.append("date_of_birth", dob);
          temp2.append("school_id", schoolid);

          duplicateplayer(temp2).then((dres) => {
            if (dres.status === true) {
              regisplayer();
            } else {
              if (dres.data.msg === 1) {
                setIsProcessing(false);
                Swal.fire({
                  html:
                    "<h4> Mobile - " +
                    mobno +
                    " and Email - " +
                    email +
                    " already exists for the user " +
                    dres.data.user[0].username +
                    "</h4>" +
                    "<b> Child Name - " +
                    dres.data.user[0].first_name +
                    ", Gender - " +
                    dres.data.user[0].gender +
                    ", Class - " +
                    dres.data.user[0].class_name +
                    ", Section - " +
                    dres.data.user[0].section +
                    ", DOB - " +
                    moment(dres.data.user[0].date_of_birth).format(
                      "DD-MM-YYYY"
                    ) +
                    ", School - " +
                    dres.data.user[0].school_name +
                    "</b>",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#4ecc48",
                  cancelButtonColor: "#757575",
                  confirmButtonText: "Continue to payment",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    regisplayer();
                  }
                });
              } else if (dres.data.msg === 2) {
                setIsProcessing(false);
                setDupData(dres.data.user);
                setShow(true);
              }
            }
          });
        } else {
          regisplayer();
        }
      }
    } else {
      setIsProcessing(false);
    }
  }

  const [multi, setMulti] = useState(0);
  function usethisuid(fid, gid, cid, sid, did) {
    setFName(fid);
    setGender(gid);
    setStuClass(cid);
    setStuSec(sid);
    setDOB(did);
    setMobNo(mobno);
    setEmail(email);
    setMulti(1);
    setShow(false);
  }

  function regisplayer() {
    setShow(false);
    const temp1 = new FormData();
    temp1.append("first_name", fname);
    temp1.append("dob", dob);
    temp1.append("gender", gender);
    temp1.append("tournament_meet_id", eventid);
    temp1.append("school_id", schoolid);

    eventplayerpaidornot(temp1).then((res) => {
      if (res.status === true) {
        setLoading(true);
        const temp = new FormData();
        temp.append("first_name", fname);
        temp.append("middle_name", "");
        temp.append("last_name", "");
        temp.append("date_of_birth", dob);
        temp.append("gender", gender);
        temp.append("mobile_no", mobno);
        temp.append("email_id", email);
        temp.append("photograph", "");
        temp.append("section", stusec);
        temp.append("class", stuclass);
        temp.append("tournament_id", eventid);
        temp.append("school_id", schoolid);
        temp.append("school_name", schname);

        if (chnoti === false) {
          temp.append("notification_enable", 0);
        } else if (chnoti === true) {
          temp.append("notification_enable", 1);
        }
        dotracking(
          "submit button from event player register for event " +
            tname +
            " (" +
            eventid +
            ") from player " +
            fname +
            " with DOB " +
            dob +
            " ,gender " +
            gender +
            " ,mobile " +
            mobno +
            " ,email " +
            email +
            " ,class " +
            stuclass +
            " ,section " +
            stusec +
            " $$ " +
            trackobj.tourregheading
        );

        eventindividualregister(temp).then((response) => {
          if (response.status === true) {
            setUser(response.data.entries.username);
            setPassword(response.data.password);
            setLoading(false);
            setIsProcessing(false);
            if (mfree === "N") {
              paynow(
                response.data.RAZORPAY_KEY,
                response.data.entries.order_id,
                response.data.entries.user_id,
                0,
                0
              );
            } else if (mfree === "Y") {
              setPaypage(false);
            }
          } else {
            setLoading(false);
            setIsProcessing(false);
            var arr = "";
            if (response.message.length !== 0) {
              for (let i = 0; i < response.message.length; i++) {
                if (arr === "") {
                  arr = response.message[i];
                } else {
                  arr = arr + " , " + response.message[i];
                }
              }
            }
            setErrMsg(arr);
          }
        });
      } else {
        setLoading(false);
        setIsProcessing(false);
        setErrMsg(res.message);
      }
    });
  }

  async function paynow(pkey, ordid, uid, tamt, pid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Event Registration",
      image: require("../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("user_id", uid);
        temp.append("tournament_meet_id", eventid);
        setLoading(true);
        playereventpayment(temp).then((response) => {
          if (response.status === true) {
            setPaypage(false);
            setLoading(false);
            // navigate("/success");
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button in event register,redirect to Player dashboard $$ " +
              trackobj.dashheading
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        setErrMsg(response.message);
      }
    });
  }

  const [tname, setTName] = useState("");

  const [ddate, setDDate] = useState(false);
  const [ebanner, setEBanner] = useState("");

  const [cir, setCir] = useState("");
  const [curdate, setCurDate] = useState("");
  useEffect(() => {
    var a = new Date().getFullYear() - 7;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));
    const temp = new FormData();
    temp.append("event_id_string", id);
    eventdetailswithstring(temp).then((response) => {
      if (response.status === true) {
        setEventID(response.data.event_info.id);
        setTName(response.data.event_info.event_name);
        setMFree(response.data.event_info.free_or_paid_events);

        if (response.data.event_info.entry_end_date !== null) {
          if (
            moment(new Date()).format("yyyy-MM-DD") >
            response.data.event_info.entry_end_date
          ) {
            setDDate(true);
          }
        }
        if (response.data.event_info.logo !== null) {
          setEBanner(
            response.data.logo_s3_path + response.data.event_info.logo
          );
        }
        if (response.data.event_info.circular_file !== null) {
          setCir(
            response.data.s3_path + response.data.event_info.circular_file
          );
        }
        setAmount(response.data.event_info.fees);
      }
    });
    classlist(id).then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });

    schoollistmaster().then((response) => {
      if (response.status === true) {
        let arr1 = [];

        if (response.data.details.length !== 0) {
          arr1.push({
            value: 0,
            label: "Other",
          });
          for (let i = 0; i < response.data.details.length; i++) {
            arr1.push({
              value: response.data.details[i].id,
              label:
                // response.data.details[i].school_unique_no +
                // " - " +
                response.data.details[i].school_name,
            });
          }
        }
        setSchList(arr1);
      }
    });
  }, []);

  function handleSchool(e) {
    if (e !== null) {
      setSchData(e);
      setSchID(e.value);
      setSchName("");
    } else {
      setSchData("");
      setSchID("");
      setSchName("");
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl registrationpge">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="md-overflow">
          <div className="eventcon">
            <div className="row h-95">
              <div className="col-md-6 pe-0 tab-100 order_a mob-100">
                {ebanner !== "" && (
                  <div
                    className="eventside slideup"
                    style={{ backgroundImage: `url(${ebanner})` }}
                  ></div>
                )}
                {ebanner === "" && (
                  <div className="side slideup">
                    <div className="img-top">
                      <img
                        src={require("../../assets/images/tagline.png")}
                        alt="tagline"
                        className="tagline_logo"
                      />
                    </div>
                    <article className="text-center">
                      <a
                        href="http://pathwayz.sportzvillage.com/"
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/images/pathwayz_logo.png")}
                          alt="pathwayz"
                          className="pathwayz_logo"
                        />
                      </a>
                    </article>
                    <div className="social">
                      <span>Follow Us</span>
                      <a
                        href="https://www.facebook.com/SportzVillage/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@sportzvillage7534"
                        target="_blank"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/sportz-village/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        href="https://twitter.com/sportzvillage"
                        target="_blank"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </div>
                  </div>
                )}
                <div className="border-b slideup"></div>
              </div>
              {ddate && (
                <div className="col-md-6 ps-0 tab-100 mob-100">
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      Registeration Closed
                    </h3>
                  </div>
                </div>
              )}

              {paypage && !ddate && (
                <div className="col-md-6 ps-0 tab-100 mob-100">
                  {cir !== "" && (
                    <div className="circulardiv">
                      <h3>
                        Circular <br />
                        <a
                          href={cir}
                          target="_blank"
                          download={cir}
                          className="text-success"
                        >
                          Click Here <i className="fa fa-download"></i>
                        </a>
                      </h3>
                    </div>
                  )}

                  <div className="form slidedown">
                    <div className="form-text login">
                      <h4 className="mt-0">Create Account</h4>
                    </div>
                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="schoolname px-2">
                          <h3>
                            Event Name :{" "}
                            <b className="font-weight-bold text-muted">
                              {tname}
                            </b>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <form className="login">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-user"></i>Child Name{" "}
                              <b>
                                <span className="text-danger">*</span>
                              </b>
                            </label>
                            <input
                              type="text"
                              placeholder="Child Name"
                              value={fname}
                              onChange={(e) => setFName(e.target.value)}
                              autoFocus
                              onKeyPress={LetterOnly}
                              onPaste={onPasteLetter}
                            />
                            {submitted && !fname && (
                              <span className="text-danger">
                                Child Name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-venus-mars"></i>Gender{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="" defaultValue disabled>
                                --- Select Gender ---
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            {submitted && !gender && (
                              <span className="text-danger">
                                Gender is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-book-open-reader"></i>
                              School <span className="text-danger">*</span>
                            </label>
                            <Select
                              className="dropdown"
                              placeholder="Select School"
                              value={sdata}
                              options={shlist}
                              onChange={handleSchool}
                              isSearchable={true}
                              isClearable
                              hidden
                            />
                            {submitted && schoolid === "" && schoolid !== 0 && (
                              <span className="text-danger">
                                School is required
                              </span>
                            )}
                          </div>
                        </div>
                        {schoolid === 0 && (
                          <div className="col-md-6">
                            <div className="input-field">
                              <label>
                                <i className="fa-solid fa-bank"></i>School Name{" "}
                                <b>
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="text"
                                placeholder="School Name"
                                value={schname}
                                onChange={(e) => setSchName(e.target.value)}
                                onKeyPress={LetterOnly}
                                onPaste={onPasteLetter}
                              />
                              {submitted && !schname && (
                                <span className="text-danger">
                                  School Name is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-book-open-reader"></i>
                              Class <span className="text-danger">*</span>
                            </label>
                            {eventid === 34 && (
                              <select value={stuclass} onChange={selectclass}>
                                <option value="" defaultValue disabled>
                                  --- Select Class ---
                                </option>
                                {sclist.map((data, i) => {
                                  return (
                                    <>
                                      {data.id >= 5 && data.id <= 12  && (
                                        <option value={data.id} key={i}>
                                          {data.class}
                                        </option>
                                      )}
                                    </>
                                  );
                                })}
                              </select>
                            )}
                            {eventid !== 34 && (
                              <select value={stuclass} onChange={selectclass}>
                                <option value="" defaultValue disabled>
                                  --- Select Class ---
                                </option>
                                {sclist.map((data, i) => {
                                  return (
                                    <option value={data.id} key={i}>
                                      {data.class}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                            {submitted && !stuclass && (
                              <span className="text-danger">
                                Class is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-group-arrows-rotate"></i>
                              Section <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Section"
                              value={stusec}
                              onChange={(e) =>
                                setStuSec(e.target.value.toUpperCase())
                              }
                            />

                            {submitted && !stusec && (
                              <span className="text-danger">
                                Section is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-calendar"></i>Date of
                              Birth <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              value={dob}
                              max={curdate}
                              onChange={(e) => setDOB(e.target.value)}
                              onKeyPress={(event) => {
                                event.preventDefault();
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                            />
                            {submitted && !dob && (
                              <span className="text-danger">
                                Date of Birth is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-mobile-button"></i>
                              Emergency Contact{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Contact Number"
                              value={mobno}
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={checkmob}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !mobno && (
                              <span className="text-danger">
                                Emergency Contact is required
                              </span>
                            )}
                            {valmob !== "" && mobno && (
                              <span className="text-danger">{valmob}</span>
                            )}
                            {mobnoerr !== "" && mobno && (
                              <span className="text-danger">{mobnoerr}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-at"></i>E-mail Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onBlur={checkemail}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !email && (
                              <span className="text-danger">
                                E-mail Address is required
                              </span>
                            )}
                            {valemail !== "" && email && (
                              <span className="text-danger">{valemail}</span>
                            )}
                            {emailerr !== "" && email && (
                              <span className="text-danger">{emailerr}</span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="disc-field mb-2">
                        <input
                          type="checkbox"
                          onChange={checknoti}
                          checked={chnoti}
                        />
                        <label className="pl-3">
                          Send me notifications about upcoming tournaments and
                          events
                        </label>
                      </div>
                      {/* <div className="disc-field">
                        <input
                          type="checkbox"
                          onChange={checkterm}
                          checked={check}
                        />
                        <label className="pl-3">
                          By registering my child, I acknowledge and accept that
                          participation involves inherent risks, and I release
                          the school and organizers from liability for any
                          injuries during the event and grant permission to use
                          event photographs for promotional purposes.
                        </label>
                      </div> */}
                      {errorMsg !== "" && (
                        <span className="text-danger">{errorMsg}</span>
                      )}
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <button
                            className="form-btn regis bg-success mt-2"
                            onClick={regsubmit}
                            disabled={isProcessing}
                            type="submit"
                          >
                            {mfree === "N" && <span>Submit & Pay</span>}
                            {mfree === "Y" && <span>Submit</span>}
                          </button>
                        </div>
                        <div className="col-lg-8 col-md-8">
                          <div className="con-text regis text-right mt-3">
                            <p className="mb-0">
                              Already have an Account?{" "}
                              <strong>
                                <a onClick={() => navigate("/login")}>
                                  Back to Login
                                </a>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4>
                    Mobile - {mobno} and Email - {email} already exists for the
                    below players...
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  {dupdata.map((data, i) => {
                    return (
                      <Row className="row-sm mx-0 border-bottom mt-2" key={i}>
                        <Col lg={10} md={10}>
                          <div className="form-group">
                            <span>
                              <h4 className="text-success">
                                {" "}
                                Username - {data.username}, Child Name -{" "}
                                {data.first_name},{" "}
                              </h4>
                              <b>
                                Gender -{data.gender}, Class -{data.class_name},
                                Section -{data.section}, DOB -
                                {moment(data.date_of_birth).format(
                                  "DD-MM-YYYY"
                                )}
                                , School -{data.school_name}
                              </b>
                            </span>
                          </div>
                        </Col>
                        <Col lg={2} md={2}>
                          <div className="form-group">
                            <a
                              className="btn btn-secondary"
                              onClick={() =>
                                usethisuid(
                                  data.first_name,
                                  data.gender,
                                  data.class,
                                  data.section,
                                  data.date_of_birth
                                )
                              }
                            >
                              Use this
                            </a>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  <div>
                    <h4 className="errortext text-center">(OR)</h4>
                    <Row className="row-sm mx-0 border-bottom mt-2">
                      <Col lg={8} md={8}>
                        <div className="form-group">
                          <span>
                            <h4 className="text-success">
                              Child Name - {fname},{" "}
                            </h4>
                            <b>
                              Gender -{gender}, Class -{clsname}, Section -
                              {stusec}, DOB -{moment(dob).format("DD-MM-YYYY")}
                            </b>
                          </span>
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="form-group">
                          {mfree === "N" && (
                            <a
                              className="btn btn-success"
                              onClick={regisplayer}
                            >
                              Continue to Payment
                            </a>
                          )}
                          {mfree === "Y" && (
                            <a
                              className="btn btn-success"
                              onClick={regisplayer}
                            >
                              Continue
                            </a>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={() => setShow(false)}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              {!paypage && !ddate && (
                <div className="col-md-6 ps-0 tab-100 mob-100">
                  <div className="form slidedown">
                    <div className="text-center background-text">
                      <h1 className="mb-3 font-weight-semibold text-danger">
                        Registration successful !
                      </h1>
                    </div>
                    <div className="background-text">
                      <h4 className="mb-3 font-weight-semibold">
                        Hi {fname} ,
                      </h4>
                      <div className="successmeg">
                        <p>
                          You have successfully registered for the{" "}
                          <b className="text-success">{tname}</b>. Kindly check
                          your email.
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <a onClick={dashlogin} className="btn btn-gray">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
