import React, { Fragment, useEffect, useState } from "react";
import "../assets/css/dashboard.css";
import { Footer } from "./footer";
import PrivateRoute from "./privateroute";
import { Sidebar } from "./dashboard/superadmin/sidebar";
import { AthleteSidebar } from "./dashboard/player/sidebar";
import { logoutdata } from "../services/login";
import { Outlet, useNavigate } from "react-router-dom";
import { Dashboard } from "./dashboard/superadmin/dashboard";
import { AthleteDashboard } from "./dashboard/player/dashboard";
import { SchoolSidebar } from "./dashboard/schoolhead/sidebar";
import { CrmSidebar } from "./dashboard/cityregional/sidebar";
import { RefSidebar } from "./dashboard/referee/sidebar";
import { Dropdown } from "react-bootstrap";
import dotracking from "./trackinglogs";
import trackobj from "./trackingpages.json";
import { CoachSidebar } from "./dashboard/coach/sidebar";
import { FunderSidebar } from "./dashboard/funder/sidebar";
export const Header = () => {
  const [sideopen, setSideOpen] = useState(false);
  function settingopen() {
    setPfShow(false);
    setMgShow(false);
    setSideOpen(!sideopen);
  }

  const [pfshow, setPfShow] = useState(false);
  function profileshow() {
    setSideOpen(false);
    setMgShow(false);
    setNotiShow(false);
    setPfShow(!pfshow);
  }

  const [mgshow, setMgShow] = useState(false);
  function messageshow() {
    setSideOpen(false);
    setPfShow(false);
    setNotiShow(false);
    setMgShow(!mgshow);
  }

  const [notishow, setNotiShow] = useState(false);
  function notifyshow() {
    setSideOpen(false);
    setPfShow(false);
    setMgShow(false);
    setNotiShow(!notishow);
  }

  function Fullscreen() {
    setSideOpen(false);
    setPfShow(false);
    setMgShow(false);
    setNotiShow(false);
    if (
      (document.fullScreenElement && document.fullScreenElement === null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const [snavopen, setSNavOpen] = useState("app sidebar-mini rtl");
  const [snavcnt, setSNavCount] = useState(1);

  function sidenavopen(cnt) {
      if (cnt === 2) {
        setSNavCount(cnt);
        setSNavOpen("app sidebar-mini rtl sidenav-toggled");
        // } else if (cnt === 3) {
        // setSNavCount(cnt);
        // setSNavOpen("app sidebar-mini rtl sidenav-toggled1");
      } else if (cnt === 3) {
        setSNavCount(1);
        setSNavOpen("app sidebar-mini rtl");
      }
    
  }

  const [BacktoTop, setBacktopTop] = useState("inline");
  const [date, setDate] = useState(new Date().toLocaleTimeString());
  const tick = () => {
    setDate(new Date().toLocaleTimeString());
  };
  let interval;
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setBacktopTop("inline") : setBacktopTop("none");
    });

    interval = setInterval(() => {
      tick();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function movetotop() {
    window.scrollTo(0, 0);
    setBacktopTop("none");
  }
  const navigate = useNavigate();
  function signout() {
    // dotracking("signout button in header from " + localStorage.getItem("RoleName") + " dashboard (" + localStorage.getItem("UID") + ")");
    if(localStorage.getItem("Role") === "8"){
      localStorage.clear();
        navigate("/login");
    }else{
      dotracking(
      "signout," +
        localStorage.getItem("UID") +
        "," +
        localStorage.getItem("Role") +
        "," +
        localStorage.getItem("RoleName")
    );
    logoutdata().then((response) => {
      if (response.status === true) {
        localStorage.clear();
        navigate("/login");
      }
    });
  }
  }

  function profilelist() {
    dotracking("profile menu in header $$ " + trackobj.profileheading);
    if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/profile");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/profile");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/profile");
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/profile");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/profile");
    }
  }

  function passlist() {
    dotracking("change password menu in header $$ " + trackobj.passwordheading);
    if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/changepassword");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/changepassword");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/changepassword");
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/changepassword");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/changepassword");
    }
  }

  function contactlist() {
    if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/contact");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/contact");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/contact");
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/contact");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/contact");
    }
  }
  return (
    <Fragment>
      <PrivateRoute />
      <div id="headerpage" className={snavopen}>
        <div className="page">
          <div className="page-main">
            {localStorage.getItem("Role") === "1" && <Sidebar />}
            {/* CRM */}
            {localStorage.getItem("Role") === "3" && <CrmSidebar />}
            {/* School Head */}
            {localStorage.getItem("Role") === "4" && <SchoolSidebar />}
            {/* Referee */}
            {localStorage.getItem("Role") === "5" && <RefSidebar />}
            {/* Player */}
            {localStorage.getItem("Role") === "6" && <AthleteSidebar />}
            {localStorage.getItem("Role") === "7" && <CoachSidebar />}
            {localStorage.getItem("Role") === "8" && <FunderSidebar />}

            <div className="app-content mt-3">
              <div className="header header-fixed ">
                <div className="container-fluid">
                  <div className="d-flex">
                    {" "}
                    <a
                      id="horizontal-navtoggle"
                      className="animated-arrow hor-toggle"
                    >
                      <span></span>
                    </a>
                    <a className="header-brand d-md-none">
                      {" "}
                      <img
                        src={require("../assets/images/logo_sv.png")}
                        className="header-brand-img desktop-logo"
                        alt="logo"
                      />{" "}
                      <img
                        src={require("../assets/images/logo_sv.png")}
                        className="header-brand-img mobile-view-logo"
                        alt="logo"
                      />{" "}
                    </a>
                    <a
                      aria-label="Hide Sidebar"
                      className="app-sidebar__toggle"
                      data-toggle="sidebar"
                      onClick={() => sidenavopen(snavcnt + 1)}
                    ></a>
                    <div className="d-flex order-lg-2 ml-auto header-right-icons header-search-icon">
                      {" "}
                      <div
                        className="dropdown d-sm-flex d-none"
                        onClick={Fullscreen}
                      >
                        <a className="nav-link icon full-screen-link nav-link-bg">
                          {" "}
                          <i
                            className="ti-arrows-corner"
                            id="fullscreen-button"
                          ></i>{" "}
                        </a>
                      </div>
                      {/* <div
                        className={
                          notishow
                            ? "dropdown d-sm-flex d-none notifications show"
                            : "dropdown d-sm-flex d-none notifications"
                        }
                        onClick={notifyshow}
                      >
                        <a className="nav-link icon" data-toggle="dropdown">
                          {" "}
                          <i className="ti-bell"></i>{" "}
                          <span className="nav-unread bg-warning-1 pulse"></span>{" "}
                        </a>
                        <div
                          className={
                            notishow
                              ? "dropdown-menu dropdown-menu-right  dropdown-menu-arrow show"
                              : "dropdown-menu dropdown-menu-right  dropdown-menu-arrow"
                          }
                        >
                          <div className="dropdown-item p-4 bg-image-2 text-center">
                            <h4 className="user-semi-title mb-0 font-weight-bold">
                              3 New{" "}
                              <span className="font-weight-normal">
                                Notifications
                              </span>
                            </h4>
                          </div>
                          <a className="dropdown-item mt-2 d-flex pb-3">
                            <div className="notifyimg bg-success">
                              {" "}
                              <i className="fa fa-thumbs-o-up"></i>
                            </div>
                            <div>
                              {" "}
                              <strong>Pay for Tournaments.</strong>
                              <div className="small">3 hours ago</div>
                            </div>
                          </a>
                          <div className="dropdown-divider"></div>{" "}
                          <a className="dropdown-item text-center">
                            View all Notification
                          </a>
                        </div>
                      </div> */}
                      <div
                        className={
                          pfshow
                            ? "dropdown text-center selector profile-1 d-sm-flex d-none show"
                            : "dropdown text-center selector profile-1 d-sm-flex d-none"
                        }
                        onClick={profileshow}
                      >
                        <a
                          data-toggle="dropdown"
                          className="nav-link leading-none d-flex"
                        >
                          <span>
                            <img
                              src={require("../assets/images/users.png")}
                              alt="profile-user"
                              className="avatar brround cover-image mb-1 ml-0"
                            />
                          </span>{" "}
                        </a>
                        <div
                          className={
                            pfshow
                              ? "dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                              : "dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          }
                        >
                          <div className="text-center bg-image p-4">
                            {" "}
                            {localStorage.getItem("Role") === "1" && (
                              <a
                                className="dropdown-item text-center font-weight-bold text-white user p-0 mb-0"
                                data-toggle="dropdown"
                              >
                                Hi, Superadmin
                              </a>
                            )}
                            {localStorage.getItem("Role") !== "1" && (
                              <a
                                className="dropdown-item text-center font-weight-bold text-white user p-0 mb-0"
                                data-toggle="dropdown"
                              >
                                {localStorage.getItem("User")}
                              </a>
                            )}
                          </div>
                          {localStorage.getItem("Role") !== "1" && (
                            <>
                              <a
                                className="dropdown-item"
                                onClick={profilelist}
                              >
                                {" "}
                                <i className="dropdown-icon mdi mdi-account-outline"></i>{" "}
                                Profile{" "}
                              </a>
                              <a className="dropdown-item" onClick={passlist}>
                                {" "}
                                <i className="dropdown-icon  fa fa-key"></i>{" "}
                                Change Password{" "}
                              </a>
                              <a className="dropdown-item" onClick={contactlist}>
                                {" "}
                                <i className="dropdown-icon  fa fa-address-card"></i>{" "}
                                Contact Us{" "}
                              </a>
                              {/* <a className="dropdown-item">
                                {" "}
                                <i className="dropdown-icon mdi mdi-compass-outline"></i>{" "}
                                Need help?{" "}
                              </a> */}
                            </>
                          )}
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" onClick={signout}>
                            {" "}
                            <i className="dropdown-icon mdi  mdi-logout-variant"></i>{" "}
                            Sign out{" "}
                          </a>
                        </div>
                      </div>
                      <button
                        className="navbar-toggler navresponsive-toggler d-sm-none"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent-4"
                        aria-controls="navbarSupportedContent-4"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        {" "}
                        <span className="navbar-toggler-icon fe fe-more-vertical text-white"></span>{" "}
                      </button>
                    </div>
                    <a
                      className="header-toggler d-lg-none ml-lg-0"
                      data-toggle="collapse"
                      data-target="#headerMenuCollapse"
                    >
                      {" "}
                      <span className="header-toggler-icon"></span>{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="mb-1 navbar navbar-expand-lg  responsive-navbar navbar-dark d-sm-none">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent-4"
                >
                  <div className="d-flex order-lg-2 ml-auto">
                    <div className="dropdown d-flex">
                      <a className="nav-link icon full-screen-link nav-link-bg">
                        {" "}
                        <i
                          className="ti-arrows-corner"
                          id="fullscreen-button"
                        ></i>{" "}
                      </a>
                    </div>
                    <div className="dropdown d-flex">
                      <a className="nav-link icon" data-toggle="dropdown">
                        {" "}
                        <i className="ti-bell"></i>{" "}
                        <span className="nav-unread bg-warning-1 pulse"></span>{" "}
                      </a>
                      <div className="dropdown-menu dropdown-menu-right  dropdown-menu-arrow">
                        <div className="dropdown-item p-4 bg-image-2 text-center">
                          <h4 className="user-semi-title mb-0 font-weight-bold">
                            3 New{" "}
                            <span className="font-weight-normal">
                              Notifications
                            </span>
                          </h4>
                        </div>
                        <a className="dropdown-item mt-2 d-flex pb-3">
                          <div className="notifyimg bg-success">
                            {" "}
                            <i className="fa fa-thumbs-o-up"></i>{" "}
                          </div>
                          <div>
                            {" "}
                            <strong>Someone likes our posts.</strong>
                            <div className="small">3 hours ago</div>
                          </div>
                        </a>
                        <a className="dropdown-item d-flex pb-3">
                          <div className="notifyimg bg-warning">
                            {" "}
                            <i className="fa fa-commenting-o"></i>{" "}
                          </div>
                          <div>
                            {" "}
                            <strong> 3 New Comments</strong>
                            <div className="small">5 hour ago</div>
                          </div>
                        </a>
                        <a className="dropdown-item d-flex pb-3">
                          <div className="notifyimg bg-danger">
                            {" "}
                            <i className="fa fa-cogs"></i>{" "}
                          </div>
                          <div>
                            {" "}
                            <strong> Server Rebooted.</strong>
                            <div className="small">45 mintues ago</div>
                          </div>
                        </a>
                        <div className="dropdown-divider"></div>{" "}
                        <a className="dropdown-item text-center">
                          View all Notification
                        </a>
                      </div>
                    </div>
                    {/* <div className="dropdown d-flex">
                      <a
                        className="nav-link icon text-center"
                        data-toggle="dropdown"
                      >
                        {" "}
                        <i className="ti-email floating"></i>{" "}
                        <span className="nav-unread bg-warning-1 pulse"></span>{" "}
                      </a>
                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <div className="dropdown-item p-4 bg-image-3 text-center">
                          <h4 className="user-semi-title mb-0 font-weight-bold">
                            4 New{" "}
                            <span className="font-weight-normal">
                              Messages
                            </span>
                          </h4>
                        </div>
                        <a className="dropdown-item d-flex mt-2 pb-3">
                          <div
                            className="avatar avatar-md brround mr-3 d-block cover-image"
                          //data-image-src="assets/images/faces/male/41.jpg"
                          //style='background: url("assets/images/faces/male/41.jpg") center center;'
                          >
                            <span className="avatar-status bg-green"></span>
                          </div>
                          <div>
                            {" "}
                            <strong>Madeleine</strong>
                            <p className="mb-0 fs-13">
                              Hey! there I' am available
                            </p>
                            <div className="small">3 hours ago</div>
                          </div>
                        </a>
                        <a className="dropdown-item d-flex pb-3">
                          <div
                            className="avatar avatar-md brround mr-3 d-block cover-image"
                          //data-image-//src="assets/images/faces/female/1.jpg"
                          //style='background: url("assets/images/faces/female/1.jpg") center center;'
                          >
                            <span className="avatar-status bg-red"></span>
                          </div>
                          <div>
                            {" "}
                            <strong>Anthony</strong>
                            <p className="mb-0 fs-13 ">
                              New product Launching
                            </p>
                            <div className="small">5 hour ago</div>
                          </div>
                        </a>
                        <a className="dropdown-item d-flex pb-3">
                          <div
                            className="avatar avatar-md brround mr-3 d-block cover-image"
                          //data-image-src="assets/images/faces/female/18.jpg"
                          //style='background: url("assets/images/faces/female/18.jpg") center center;'
                          >
                            <span className="avatar-status bg-yellow"></span>
                          </div>
                          <div>
                            {" "}
                            <strong>Olivia</strong>
                            <p className="mb-0 fs-13 ">
                              New Schedule Realease
                            </p>
                            <div className="small">45 mintues ago</div>
                          </div>
                        </a>
                        <div className="dropdown-divider"></div>{" "}
                        <a className="dropdown-item text-center">
                          See all Messages
                        </a>
                      </div>
                    </div> */}
                    <div className="dropdown text-center selector profile-1 mt-4">
                      <a
                        data-toggle="dropdown"
                        className="nav-link leading-none d-flex"
                      >
                        {" "}
                        <span>
                          <img
                            src={require("../assets/images/users.png")}
                            alt="profile-user"
                            className="avatar brround cover-image mb-1 ml-0"
                          />
                        </span>{" "}
                      </a>
                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <div className="text-center bg-image p-4">
                          {" "}
                          {localStorage.getItem("Role") === "1" && (
                            <a
                              className="dropdown-item text-center font-weight-bold text-white user p-0 mb-0"
                              data-toggle="dropdown"
                            >
                              Hi, Superadmin
                            </a>
                          )}
                          {localStorage.getItem("Role") !== "1" && (
                            <a
                              className="dropdown-item text-center font-weight-bold text-white user p-0 mb-0"
                              data-toggle="dropdown"
                            >
                              {localStorage.getItem("User")}
                            </a>
                          )}
                        </div>
                        {localStorage.getItem("Role") !== "1" && (
                          <>
                            <a className="dropdown-item" onClick={profilelist}>
                              {" "}
                              <i className="dropdown-icon mdi mdi-account-outline"></i>{" "}
                              Profile{" "}
                            </a>
                            {/* <a className="dropdown-item">
                              {" "}
                              <i className="dropdown-icon  mdi mdi-settings"></i>{" "}
                              Settings{" "}
                            </a>

                            <a className="dropdown-item">
                              {" "}
                              <i className="dropdown-icon mdi mdi-compass-outline"></i>{" "}
                              Need help?{" "}
                            </a> */}
                          </>
                        )}
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" onClick={signout}>
                          {" "}
                          <i className="dropdown-icon mdi  mdi-logout-variant"></i>{" "}
                          Sign out{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="sticky-wrapper" className="sticky-wrapper">
                <div className="horizontal-main hor-menu clearfix">
                  <div className="horizontal-mainwrapper clearfix">
                    <nav className="horizontalMenu clearfix">
                      <div className="overlapblackbg"></div>
                      <ul className="horizontalMenu-list">
                        {/* <li aria-haspopup="true"><a ><i className="fa fa-user"></i>Welcome {' '}
                          {localStorage.getItem('Name')}</a></li> */}
                      </ul>
                      <h4 className="datetime">
                        <div className="display-time px-10">{date}</div>
                      </h4>
                    </nav>
                  </div>
                </div>
              </div>
              <Outlet />
            </div>
          </div>

          <Footer />
        </div>
        {/* <!-- BACK-TO-TOP --> */}
        <a id="back-to-top" style={{ display: BacktoTop }} onClick={movetotop}>
          <i className="fa fa-angle-up"></i>
        </a>
      </div>
    </Fragment>
  );
};
