import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { AgGridReact } from "ag-grid-react";
import {
  perfcriteriasportslist,
  perftrackingsportslist,
  tourcategorydetail,
  tourteamplayerlist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  crmtourviewdetail,
  teamselectedplayerlistcityreg,
} from "../../../../../services/cityregionaladmin";
import {
  coachaddperformance,
  coachdeleteperformance,
  coachplayerexternalperformancelist,
  coachplayerperformancedetail,
  coachplayerperformancelist,
  coachtourviewdetail,
  coachupdateperformance,
  teamselectedplayerlistcoach,
} from "../../../../../services/coach";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import { externaltourdetails } from "../../../../../services/common";
export const IndPerformanceList = () => {
  const navigate = useNavigate();
  const { id, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [playerdet, setPlayer] = useState("");
  const [urllink, setUrlLink] = useState("");
  const [tname, setTName] = useState("");
  const [tsportno, setTSportNo] = useState("");
  const [cid, setCatId] = useState("");
  const [tid, setTeamId] = useState("");

  const [catname, setCatname] = useState("");
  const [teamname, setTeamName] = useState("");

  function deleteperf(pid, edata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete performance icon in performance list for " +
            edata.criteria_name +
            " (" +
            edata.criteria_id +
            ")" +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.editperfheading
        );

        const temp = new FormData();
        temp.append("table_id", pid);
        coachdeleteperformance(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall(tsportno, urllink);
          }
        });
      }
    });
  }

  function editperf(pid, edata) {
    const temp = new FormData();
    temp.append("table_id", pid);
    coachplayerperformancedetail(temp).then((response) => {
      if (response.status === true) {
        if (response.data.performance_data.length !== 0) {
          dotracking(
            "edit performance icon in performance list for " +
              edata.criteria_name +
              " (" +
              edata.criteria_id +
              ")" +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.editperfheading
          );
          setTableID(response.data.performance_data[0].id);
          setCriteriaID(response.data.performance_data[0].criteria_id);
          setCriteriaName(response.data.performance_data[0].criteria_name);
          setPText(response.data.performance_data[0].performance_text);
          if (crlist.length !== 0) {
            const ctype = crlist.find(
              (item) =>
                item.id ===
                parseInt(response.data.performance_data[0].criteria_id)
            );
            if (ctype !== undefined) {
              setFType(ctype.feedback_type);
            }
            if (ctype.feedback_type === 1) {
              setRNumber(response.data.performance_data[0].rating_number);
            } else if (ctype.feedback_type === 2) {
              setRText(response.data.performance_data[0].rating_texts);
            }
          }

          setShow(true);
        }
      }
    });
  }
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTSportNo(response.data.tournament.sports_type_no);
          listall(response.data.tournament.sports_type_no, link[1]);
        }
      });
      // tourteamplayerlist(id, tid).then((response) => {
      //   if (response.status === true) {
      //     if (response.data.length !== 0) {
      //       setTeamName(
      //         response.data[0].team_name + " - " + response.data[0].team_code
      //       );
      //     }
      //   }
      // });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTSportNo(response.data.tournament.sports_type_no);
          listall(response.data.tournament.sports_type_no, link[1]);
        }
      });

      // teamselectedplayerlistcityreg(id, tid).then((response) => {
      //   if (response.status === true) {
      //     if (response.data.length !== 0) {
      //       setTeamName(
      //         response.data[0].team_name + " - " + response.data[0].team_code
      //       );
      //     }
      //   }
      // });
    } else if (localStorage.getItem("Role") === "7") {
      if (link[1] === "externalindividualperformance") {
        externaltourdetails(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTName(response.data.tournament.meet_name);
            setTSportNo(response.data.tournament.sports_type_no);
            listall(response.data.tournament.sports_type_no, link[1]);
          }
        });
      } else {
        coachtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTName(response.data.tournament.meet_name);
            setTSportNo(response.data.tournament.sports_type_no);
            listall(response.data.tournament.sports_type_no, link[1]);
          }
        });
      }
    }
  }, []);
  const [crlist, setCRList] = useState([]);

  function listall(sid, stype) {
    perftrackingsportslist(sid).then((response) => {
      if (response.status === true) {
        setCRList(response.data);
      }
    });
    if (localStorage.getItem("Role") === "7") {
      if (stype === "externalindividualperformance") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", eid);
        coachplayerexternalperformancelist(temp).then((response) => {
          if (response.status === true) {
            setCatId(response.data.team_category.age_category_id);
            setTeamId(response.data.team_category.id);
            setPlayer(response.data.player_details);
            setFilterProduct(response.data.performance_data);
            setCatname(response.data.team_category.age_category_name);
            setTeamName(response.data.team_category.team_name);
          }
        });
      } else {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", eid);
        coachplayerperformancelist(temp).then((response) => {
          if (response.status === true) {
            setPlayer(response.data.player_details);
            setFilterProduct(response.data.performance_data);

            if (response.data.team_category !== null) {
              setCatId(response.data.team_category.category_id);
              setTeamId(response.data.team_category.team_id);
              const tempData = new FormData();
              tempData.append(
                "category_id",
                response.data.team_category.category_id
              );
              tourcategorydetail(tempData).then((res) => {
                if (res.status === true) {
                  setCatname(res.data.category_details.category_name);
                }
              });

              teamselectedplayerlistcoach(
                id,
                response.data.team_category.team_id
              ).then((response) => {
                if (response.status === true) {
                  if (response.data.length !== 0) {
                    setTeamName(
                      response.data[0].team_name +
                        " - " +
                        response.data[0].team_code
                    );
                  }
                }
              });
            }
          }
        });
      }
    }
  }
  function backlist() {
    dotracking(
      "back button in performance list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamplaheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/teamplayerlist/" + id + "/" + tid + "/" + cid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamplayerlist/" + id + "/" + tid + "/" + cid);
    } else if (localStorage.getItem("Role") === "7") {
      // navigate("/coach/teamplayerlist/" + id + "/" + tid + "/" + cid);
      navigate(-1);
    }
  }

  const [ptext, setPText] = useState("");
  const [ftype, setFType] = useState("");
  const stars = [1, 2, 3, 4, 5];
  const [rnumber, setRNumber] = useState(0);
  const [rtext, setRText] = useState("");
  const [criteriaid, setCriteriaID] = useState("");
  const [critname, setCriteriaName] = useState("");

  const [tableid, setTableID] = useState("");

  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleType = (e) => {
    setPText("");
    setRNumber(0);
    setRText("");
    setFType("");
    if (e.target.value !== "") {
      setCriteriaID(e.target.value);
      const ctype = crlist.find((item) => item.id === parseInt(e.target.value));
      if (ctype !== undefined) {
        setCriteriaName(ctype.criteria_name);
        setFType(ctype.feedback_type);
      }
    }
  };
  function addind() {
    dotracking(
      "add button in performance list for " +
        critname +
        " ( " +
        criteriaid +
        " ) " +
        catname +
        " (" +
        cid +
        ") category " +
        teamname +
        " (" +
        tid +
        ") team in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.addperfheading
    );
    setTableID("");
    setPText("");
    setCriteriaID("");
    setCriteriaName("");
    setFType("");
    setRNumber(0);
    setRText("");
    setErrorMsg("");
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setTableID("");
    setFType("");
    setCriteriaID("");
    setCriteriaName("");
    setErrorMsg("");
    setPText("");
    setRNumber(0);
    setRText("");
  }

  function addsubmit() {
    var valid = "";
    var ctext = "";
    if (criteriaid !== "") {
      if (ptext !== "") {
        // if (ftype === 1) {
        //   if (rnumber === "") {
        //     valid = "1";
        //     setErrorMsg("Please Enter Rating Number");
        //   } else {
        //     ctext = rnumber;
        //   }
        // }
        // if (ftype === 2) {
        //   if (rtext === "") {
        //     valid = "2";
        //     setErrorMsg("Please Enter Rating Text");
        //   } else {
        //     ctext = rtext;
        //   }
        // }
        if (valid === "") {
          if (tableid === "") {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("tournament_id", id);
            temp.append("category_id", cid);
            temp.append("team_id", tid);
            temp.append("user_id", eid);
            temp.append("criteria_id", criteriaid);
            temp.append("performance_text", ptext);
            if (ftype === 1) {
              temp.append("rating_number", rnumber);
              temp.append("rating_texts", "-");
            } else if (ftype === 2) {
              temp.append("rating_number", 0);
              if (rtext === "") {
                temp.append("rating_texts", "-");
              } else {
                temp.append("rating_texts", rtext);
              }
            }
            if (urllink === "externalindividualperformance") {
              temp.append("internal_or_external", 2);
            } else {
              temp.append("internal_or_external", 1);
            }

            dotracking(
              "add button in performance list for " +
                critname +
                " ( " +
                criteriaid +
                " ) " +
                ptext +
                " " +
                ctext +
                " in " +
                catname +
                " (" +
                cid +
                ") category " +
                teamname +
                " (" +
                tid +
                ") team for " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.listperfheading
            );
            coachaddperformance(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                handleclose();
                listall(tsportno, urllink);
              }
            });
          } else {
            const temp = new FormData();
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("table_id", tableid);
            temp.append("performance_text", ptext);
            if (ftype === 1) {
              temp.append("rating_number", rnumber);
              temp.append("rating_texts", "-");
            } else if (ftype === 2) {
              temp.append("rating_number", 0);
              temp.append("rating_texts", rtext);
            }
            if (urllink === "externalindividualperformance") {
              temp.append("internal_or_external", 2);
            } else {
              temp.append("internal_or_external", 1);
            }
            dotracking(
              "update button in performance list for " +
                critname +
                " ( " +
                criteriaid +
                " ) " +
                ptext +
                " " +
                ctext +
                " in " +
                catname +
                " (" +
                cid +
                ") category " +
                teamname +
                " (" +
                tid +
                ") team for " +
                tname +
                " (" +
                id +
                ") tournament $$ " +
                trackobj.listperfheading
            );
            coachupdateperformance(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                handleclose();
                listall(tsportno, urllink);
              }
            });
          }
        }
      } else {
        setErrorMsg("Please Enter Performance Text");
      }
    } else {
      setErrorMsg("Please Select Criteria Type");
    }
  }

  function handleText(e) {
    // const regex = /^[0-9\b]+$/;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPText(e.target.value);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Individual Performance Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Team Lists</a>
          </li>
          <li className="breadcrumb-item">
            <a>Team Player Lists</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Performance Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-md-12">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                    {catname !== "" && (
                      <h4 className="text-warning tx-20">
                        Category - {catname}
                      </h4>
                    )}
                    {teamname !== "" && (
                      <h4 className="text-success tx-20">{teamname}</h4>
                    )}
                  </div>
                </div>
              </div>
              <h4>PLAYER DETAILS</h4>
              <div className="row tournamentitem">
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Player Name</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.first_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Username</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.username}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{playerdet.gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {moment(playerdet.date_of_birth).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Class</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.class_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Section</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.section}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Email</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.email_id}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Mobile Number</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">
                          {playerdet.mobile_no}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="text-right">
                    <a className="btn btn-success me-2" onClick={addind}>
                      <i className="fa fa-plus"></i> Add
                    </a>
                    <a onClick={backlist} className="btn btn-gray">
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-2">
                <table className="sp-league-table sp-data-table">
                  <thead className="sp-table-header">
                    <tr role="row">
                      <th>Criteria</th>
                      <th>Performance</th>
                      <th>Rating/Feedback</th>
                      <th>Action</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterProduct.map((item, j) => {
                      const getRowColor = () => {
                        return j % 2 === 0 ? "even" : "odd";
                      };
                      return (
                        <>
                          <tr
                            className={`sp-row-no-0 ${getRowColor()}`}
                            key={j}
                          >
                            <td className="data-pct">{item.criteria_name}</td>
                            <td className="data-pct">
                              {item.performance_text}
                            </td>
                            {item.rating_number !== 0 && (
                              <td className="data-pct">{item.rating_number}</td>
                            )}
                            {item.rating_texts !== "-" && (
                              <td className="data-pct">{item.rating_texts}</td>
                            )}
                            {item.rating_number === 0 &&
                              item.rating_texts === "-" && (
                                <td className="data-pct">-</td>
                              )}
                            <td className="data-pct">
                              <a
                                className="btn btn-success btn-sm me-2"
                                onClick={() => editperf(item.id, item)}
                                title="Edit"
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </a>
                            </td>
                            <td className="data-pct">
                              <a
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteperf(item.id, item)}
                                title="Delete"
                              >
                                <i className="fa fa-trash fa-lg"></i>
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={["asc", "desc"]}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div> */}

              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4> Add Performance</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={11} md={11}>
                      <div className="form-group">
                        <label className="mb-2">
                          Criteria Type <span className="errortext"> *</span>
                        </label>
                        {tableid === "" && (
                          <select
                            className="form-control custom-select"
                            value={criteriaid}
                            onChange={handleType}
                          >
                            <option value="" defaultValue disabled>
                              --- Select Criteria Type ---
                            </option>
                            {crlist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.criteria_name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        {tableid !== "" && (
                          <select
                            className="form-control custom-select"
                            value={criteriaid}
                            onChange={handleType}
                            disabled
                          >
                            <option value="" defaultValue disabled>
                              --- Select Criteria Type ---
                            </option>
                            {crlist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.criteria_name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </Col>
                    {criteriaid !== "" && (
                      <>
                        <Col lg={11} md={11}>
                          <div className="form-group">
                            <label className="mb-2">
                              {critname}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              placeholder={critname}
                              className="form-control"
                              value={ptext}
                              onChange={handleText}
                            />
                          </div>
                        </Col>
                      </>
                    )}

                    {ftype === 1 && (
                      <Col lg={11} md={11}>
                        <div className="form-group">
                          <label className="mb-2">
                            Coach Feedback for {critname}{" "}
                            {/* <span className="errortext"> *</span> */}
                          </label>
                          <div className="rating">
                            {stars.map((star) => {
                              return (
                                <i
                                  key={star}
                                  onClick={() => setRNumber(star)}
                                  className={
                                    rnumber >= star
                                      ? `fa fa-star fa-star-active`
                                      : `fa fa-star`
                                  }
                                  aria-hidden="true"
                                ></i>
                              );
                            })}
                          </div>
                          {/* <input
                            type="text"
                            placeholder="Performance Text"
                            className="form-control"
                            value={rnumber}
                            onChange={(e) => setRNumber(e.target.value)}
                          /> */}
                        </div>
                      </Col>
                    )}
                    {ftype === 2 && (
                      <Col lg={11} md={11}>
                        <div className="form-group">
                          <label className="mb-2">
                            Coach Feedback for {critname}{" "}
                            {/* <span className="errortext"> *</span> */}
                          </label>
                          <textarea
                            rows={3}
                            placeholder={
                              critname !== ""
                                ? "Coach Feedback for " + critname
                                : "Coach Feedback"
                            }
                            className="form-control"
                            value={rtext}
                            onChange={(e) => setRText(e.target.value)}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {tableid === "" && (
                    <a className="btn btn-success" onClick={addsubmit}>
                      Submit
                    </a>
                  )}
                  {tableid !== "" && (
                    <a className="btn btn-success" onClick={addsubmit}>
                      Update
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
