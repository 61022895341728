import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import {
  emailkeywordlist,
  emailtemplates,
  emailtemplatesdetails,
  othereventdetails,
  othereventsendregisterlink,
} from "../../../../../services/superadmin";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { useNavigate, useParams } from "react-router-dom";

export const RegistersendLinkTemplate = () => {
  const [keylist, setKeyList] = useState([]);
  const [content, setContent] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [subj, setSubj] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  function toursubmit() {
    if (subj !== "") {
      if (content !== "") {
        if (urllink === "registersendlink") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("template_id", 19);
          temp.append("content", content);
          temp.append("status", 1);
          temp.append("subject", subj);
          temp.append("image_or_video", selectedFile);
          dotracking(
            "submit button in email registeration send link templates $$ " +
              trackobj.sendregtempheading +
              trackobj.emailtemp
          );
          emailtemplates(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              listall();
            } else {
              setErrorMsg(response.message);
            }
          });
        }else{
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("event_id", id);
          temp.append("email_content", content);
          temp.append("subject", subj);
          dotracking(
            "submit button in email send registeration link for " + ename + " (" + id + ") event $$ " +
              trackobj.workreglinkheading
          );
          othereventsendregisterlink(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              navigate("/superadmin/othereventlist");
            } else {
              setErrorMsg(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Enter the text");
      }
    } else {
      setErrorMsg("Please Enter Subject");
    }
  }

  const [selectedFile, setSelectedFile] = useState("");
  const handleFileChange = (event) => {
    setSelectedFile("");
    if (event.target.files.length !== 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  function listall() {
    emailtemplatesdetails(19).then((response) => {
      if (response.status === true) {
        if (response.data.template_info !== null) {
          if (response.data.template_info.subject !== null) {
            setSubj(response.data.template_info.subject);
          }

          if (response.data.template_info.message_content !== null) {
            setContent(response.data.template_info.message_content);
          }
        }
      }
    });
  }
  const [urllink, setUrlLink] = useState("");
  const [ename, setEName] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (link[1] === "registersendlink") {
      listall();
      emailkeywordlist(19).then((response) => {
        if (response.status === true) {
          setKeyList(response.data);
        }
      });
    } else {
      othereventdetails(id).then((response) => {
        if (response.status === true) {
          setEName(response.data.events[0].event_name);
        }
      });
    }
  }, []);
  return (
    <div className="side-app">
      {urllink === "registersendlink" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Templates</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Templates</a>
            </li>
            <li className="breadcrumb-item">
              <a>Email</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Registeration Send Link
            </li>
          </ol>
        </div>
      )}

      {urllink !== "registersendlink" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Send Registeration Link</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Other Events</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Send Registeration Link
            </li>
          </ol>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              {urllink === "registersendlink" && (
                <>
                  <h4>Send Registeration Link Email </h4>
                  <div className="table-responsive">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Keyword</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {keylist.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{data.keywords} </td>
                              <td>{data.description} </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <hr />
                </>
              )}

              {urllink !== "registersendlink" && <h3>{ename}</h3>}
              <div className="row mt-2">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Subject <span className="errortext"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    value={subj}
                    onChange={(e) => setSubj(e.target.value)}
                  />
                </div>
              </div>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "blockQuote",
                    "link",
                    "outdent",
                    "indent",
                    "numberedList",
                    "bulletedList",
                    "imageUpload",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                    "specialCharacters",
                  ],
                }}
              />

              {urllink === "registersendlink" && (
                <>
                  <hr />
                  <div className="form-group">
                    <label className="form-label"> Upload File</label>
                    <input
                      type="file"
                      // value={selectedFile}
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
              <hr />
              <div className="text-right">
                <a className="btn btn-success" onClick={toursubmit}>
                  Submit
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
