import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import {
  statecreate,
  statedetails,
  statemasterlist,
  statestatus,
  stateupdate,
  statevalidate,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const StateMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [stateid, setId] = useState(0);
  const [state, setState] = useState("");
  const [scode, setSCode] = useState("");
  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "State",
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      field: "state_description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "isactive",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deletestate(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "stateuid",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewstate(params.value)}
              title="Edit State"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function viewstate(aid) {
    setShow(true);
    
    statedetails(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.stateuid);
        setState(response.data.statename);
        setSCode(response.data.state_description);
        setStatus(response.data.isactive + "");
        dotracking("edit button in state for " + response.data.statename + " (" + aid + ") $$ " + trackobj.statelistheading);
      }
    });
  }

  function handleshow() {
    setId(0);
    setState("");
    setSCode("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setState("");
    setSCode("");
    setStatus("1");
    setErrorMsg("");
  }

  function deletestate(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("stateuid", list.data.stateuid);
          temp.append("isactive", 0);
          dotracking(
            "status icon in state list from Active to Inactive for " + list.data.statename + " (" + list.data.stateuid + ") $$ " + trackobj.statelistheading
          )
          statestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("stateuid", list.data.stateuid);
          temp.append("isactive", 1);
          dotracking(
            "status icon in state list from Inactive to Active for " + list.data.statename + " (" + list.data.stateuid + ") $$ " + trackobj.statelistheading
          )
          statestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    statemasterlist().then((response) => {
      if (response.status === true) {
        
        setFilterProduct(response.data);
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submitstate(e) {
    e.preventDefault();
    setErrorMsg("");
    if (state !== "") {
      if (scode !== "") {
        if (stateid === 0) {
          statevalidate(state).then((res) => {
            if (res.status === true) {
              const temp = new FormData();
              temp.append("statename", state);
              temp.append("state_shrt_code", scode)
              temp.append("isactive", status);
              dotracking("add button in state list for state " + state + " $$ " + trackobj.statelistheading);
              statecreate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                }
              });
            } else {
              Swal.fire({
                text: state + " " + res.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#4ecc48",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  setId(0);
                  setState("");
                  setStatus("1");
                  setErrorMsg("");
                }
              });
            }
          });
        } else if (stateid !== 0) {
          const temp = new FormData();
          temp.append("stateuid", stateid);
          temp.append("statename", state);
          temp.append("state_shrt_code", scode)
          temp.append("isactive", status);
          dotracking("update button in state list for state " + state + " (" + stateid + ") $$ " + trackobj.statelistheading);
          stateupdate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Enter State Code");
      }
    } else {
      setErrorMsg("Please Enter State Name");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">State Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            State Lists
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add State
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {stateid !== 0 && <h4> State Details</h4>}
                  {stateid === 0 && <h4> Add State</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="State Name"
                          className="form-control"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Code <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="State Code"
                          className="form-control"
                          maxLength={4}
                          value={scode}
                          onChange={(e) => setSCode(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {stateid !== 0 && (
                    <a className="btn btn-success" onClick={submitstate}>
                      Update
                    </a>
                  )}
                  {stateid === 0 && (
                    <a className="btn btn-success" onClick={submitstate}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
