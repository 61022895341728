import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  pointsdelete,
  pointswholedelete,
  tourcategorydetail,
  tourpointslist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import { Reftourdetail } from "../../../../services/referee";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { tourdetails } from "../../../../services/register";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { coachtourviewdetail } from "../../../../services/coach";

export const PointsList = () => {
  const { id, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Team Name",
      field: "team_name",
      width:200,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Played",
      field: "matches_played",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Won",
      field: "matches_won",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Lost",
      field: "matches_lost",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Draw",
      field: "matches_draw",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "GF",
      field: "gf",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "GA",
      field: "ga",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "GD",
      field: "gd",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Points",
      field: "points",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "match_status",
      width:120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      field: "id",
      width:100,
      sortable: false,
      resizable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className='text-center'>
            <a title="Delete Points" onClick={()=>deletepts(params.value,params.data)}
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>

          </div>
        );
      },
    },
  ];


  function deletepts(did, dname) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const tempData = new FormData();
        tempData.append("id", did);
        dotracking(
          dname.team_name + 
          " delete icon in points list for " + catname + " (" +
            eid + ") category in " + tname + " (" + id + ") tournament $$ " + trackobj.pointlistheading);
        pointsdelete(tempData).then((res) => {
          if (res.status === true) {
            toast.success(res.message);
            listall();
          }
        });
      }
    });
  }

  const navigate = useNavigate();
  const [tname, setTName] = useState("");
  const [catname, setCatname] = useState("");

  useEffect(() => {
    listall();
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
  }, []);

  function listall() {
    tourpointslist(id, eid).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  function pointsentry() {
    dotracking(
      "upload points button in points list for " + catname + " (" +
      eid + ") category in " +
        tname + " (" + id + ") tournament $$ " + trackobj.pointuploadheading);
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/pointsentry/" + id + "/" + eid);
    } 
  }

  function backlist() {
    if (localStorage.getItem("Role") !== "6") {
      dotracking(
        catname + " (" +
        eid + ") category back button in points list for " +
          tname + " (" + id + ") tournament $$ " + trackobj.categorylistheading);
    }else{
      dotracking(
        catname + " (" +
        eid + ") category back button in points list for " +
          tname + " (" + id + ") tournament $$ " + trackobj.reglistheading);
    }
   
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "6") {
      navigate("/player/registeredlist");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/categorylist/" + id);
    }
  }

  function deleteptslist(){
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const tempData = new FormData();
        tempData.append("tournament_meet_id", id);
        tempData.append("category_id", eid);
        dotracking(
          catname + " (" +
          eid + ") category delete points button in points list for " +
            tname + " (" + id + ") tournament $$ " + trackobj.pointlistheading);
        pointswholedelete(tempData).then((res) => {
          if (res.status === true) {
            toast.success(res.message);
            listall();
          }
        });
      }
    });
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Points List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Points List
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7 align-self-center">
                  <h3>{tname}</h3>
                  <h4 className="text-success tx-20">Category - {catname}</h4>
                </div>
                <div className="col-md-5">
                  <div className="text-right">
                    {localStorage.getItem("Role") === "1"  && (
                      <>
                      <a
                          className="btn btn-danger me-2 mt-1"
                          onClick={deleteptslist}
                        >
                          <i className="fa fa-trash"></i> Delete Points
                        </a>
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={pointsentry}
                        >
                          <i className="fa-solid fa-upload"></i> Points Upload
                        </a>
                      </>
                    )}
                    <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              

              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
