import React, { Fragment, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
export const RefDashboard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent("");
    setModalIsOpen(false);
  };
  
  const handleEventClick = (info) => {
        
    const clickedEvent = {
      title: info.event.title,
      start: info.event.startStr,
      end: info.event.endStr,
      description: info.event.description,
      sports: info.event.sports,
      event: info.event.event,
    };
    openModal(clickedEvent);
  };

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Dashboard</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </div>

        <div className="row row-cards">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-info">
                  <i className="icon-people text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Total Players</p>
                <p className="h3 text-center text-info">0</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-primary">
                  <i className="icon icon-location-pin text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Total Cities</p>
                <p className="h3 text-center text-primary-1">0</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-teal">
                  <i className="fa fa-bank text-white-1 text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Total Schools</p>
                <p className="h3 text-center text-teal">0</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <div className="card-body text-center feature">
                <div className="fa-stack fa-lg icons shadow-default bg-purple">
                  <i className="fa fa-soccer-ball-o text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">No of Sports</p>
                <p className="h3 text-center text-purple">0</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="ag_courses_head">
            <h4>Tournament & Events Calender</h4>
          </div>
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  eventClick={handleEventClick}
                />

                <Modal show={modalIsOpen} size="xl">
                  <Modal.Header>
                    <Modal.Title>Tournament & Events Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="table-responsive">
                      <table className="sp-league-table sp-data-table">
                        <thead className="sp-table-header">
                          <tr role="row">
                            <th className="text-center">Title</th>
                            <th className="text-center">Start Date</th>
                            <th className="text-center">End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="sp-row-no-0 even" role="row">
                            <td class="data-pct">{selectedEvent.title}</td>
                            <td class="data-pct">
                              {moment(selectedEvent.start).format("DD-MM-YYYY")}
                            </td>
                            {selectedEvent.end !== "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.end).format("DD-MM-YYYY")}
                              </td>
                            )}
                            {selectedEvent.end === "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.start).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-gray" onClick={closeModal}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>  */}
      </div>
    </Fragment>
  );
};
