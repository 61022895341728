import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { schoollist } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useNavigate } from "react-router-dom";
import { schoollistcityreg } from "../../../../services/cityregionaladmin";
import WebUrl from "../../../../AWebUrl";
import Swal from "sweetalert2";
import { funderprofile, fundschoollist } from "../../../../services/funder";

export const SchoolList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3logo, setS3Logo] = useState("");
  const [s3sign, setS3Sign] = useState("");

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => addlist(params)}
              title="Add CMS"
              className="btn-sm btn-success me-2"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Publish Status",
      width: 150,
      field: "website_publised_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: (params) => {
        if (params.value === "Published") {
          return { color: "green" };
        } else {
          return { color: "red" };
        }
      },
    },
    {
      headerName: "Publish Webpage",
      width: 160,
      field: "website_publised_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === "Published") {
          return (
            <div className="text-center">
              <a
                href={WebUrl + params.data.website_url}
                target="_blank"
                title={WebUrl + params.data.website_url}
                className="btn-sm btn-success"
              >
                Visit Webpage
              </a>
            </div>
          );
        } else {
          return <div className="text-center text-danger">{params.value}</div>;
        }
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CHID",
      width: 150,
      field: "school_unique_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      width: 150,
      field: "city_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports Infra",
      width: 150,
      field: "school_sports_infra",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 100,
      field: "school_fees",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "school_logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Signature",
      width: 100,
      field: "princi_signature",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3sign + params.value}
                onClick={() => signshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
  ];

  

  const columnFDefs = [
    // {
    //   headerName: "Actions",
    //   width: 100,
    //   field: "id",
    //   resizable: true,
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <div className="text-center">
    //         <a
    //           onClick={() => addlist(params)}
    //           title="Add CMS"
    //           className="btn-sm btn-success me-2"
    //         >
    //           <i className="fa fa-user-plus fa-lg"></i>
    //         </a>
    //       </div>
    //     );
    //   },
    // },
    
   
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Benificiaries",
      width: 150,
      field: "benificieries_count",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "International Players",
      width: 150,
      field: "internationl_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "National Players",
      width: 150,
      field: "national_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State Players",
      width: 150,
      field: "state_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District Players",
      width: 150,
      field: "district_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Inter School Players",
      width: 150,
      field: "inter_school_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return params.value;
      }
    },
    {
      headerName: "Intra School Players",
      width: 150,
      field: "intra_school_players",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    
  
  ];
  const navigate = useNavigate();
  function addlist(pdata) {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "add cms details icon in school list for " +
          pdata.data.school_name +
          " - " +
          pdata.data.school_unique_no +
          " (" +
          pdata.data.id +
          ") $$ " +
          trackobj.addcmsheading
      );
      navigate("/superadmin/addcms/" + pdata.data.id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "add cms details icon in school list for " +
          pdata.data.school_name +
          " - " +
          pdata.data.school_unique_no +
          " (" +
          pdata.data.id +
          ") $$ " +
          trackobj.addcmsheading
      );
      navigate("/cityregional/addcms/" + pdata.data.id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/addcms/" + pdata);
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/addcms/" + pdata.data.id);
    }
  }

  function plalist(pdata) {
    if (localStorage.getItem("Role") === "8") {
      navigate("/funder/allplayers/" + pdata.data.id);
    }
  }

  function dellist(pdata) {
    if (localStorage.getItem("Role") === "8") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // dotracking(
          //   "add cms details icon in school list for " +
          //     pdata.data.school_name +
          //     " - " +
          //     pdata.data.school_unique_no +
          //     " (" +
          //     pdata.data.id +
          //     ") $$ " +
          //     trackobj.addcmsheading
          // );
          filterProduct.splice(pdata.rowIndex, 1);
          setFilterProduct([...filterProduct]);
        }
      });
    }
  }

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }
  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

  const [sshow, setSShow] = useState(false);
  const [schsign, setSchSign] = useState("");

  function signshow(img) {
    if (img !== null) {
      setSShow(true);
      setSchSign(s3sign + img);
    }
  }
  function signclose() {
    setSShow(false);
    setSchSign("");
  }
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    listall();
  }, [window.location.href]);

  function listall() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "1") {
      schoollist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.details);
          setS3Logo(response.data.s3_logo);
          setS3Sign(response.data.s3_signature);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      schoollistcityreg().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
      // } else if (localStorage.getItem("Role") === "7") {
      //   setFilterProduct([
      //     { id: "1", rep_name: "Repository", description: "Sample Repository" },
      //   ]);
    } else if (localStorage.getItem("Role") === "8") {
      funderprofile().then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("funder_id", res.data.funder_id);
          fundschoollist(temp).then((response) => {
            if (response.status === true) {
              console.log(response.data);
              setFilterProduct(response.data);
            }
          });
        }
      });
    }
  }

  const [ashow, setAShow] = useState(false);
  const [rname, setRName] = useState("");
  const [rdesc, setRDesc] = useState("");

  function repshow() {
    setAShow(true);
    setRName("");
    setRDesc("");
  }
  function repclose() {
    setAShow(false);
    setRName("");
    setRDesc("");
  }

  function addrep(e) {
    e.preventDefault();
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          {localStorage.getItem("Role") !== "7" && (
            <h4 className="mb-0">School Lists</h4>
          )}
          {localStorage.getItem("Role") === "7" && (
            <h4 className="mb-0">Repository Lists</h4>
          )}
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
          {localStorage.getItem("Role") !== "7" && (
            <li className="breadcrumb-item active" aria-current="page">
              School Lists
            </li>
          )}
          {localStorage.getItem("Role") === "7" && (
            <li className="breadcrumb-item active" aria-current="page">
              Repository Lists
            </li>
          )}
        </ol>
      </div>
      {localStorage.getItem("Role") === "7" && (
        <>
          <div align="right">
            <a className="btn btn-success" onClick={repshow}>
              <i className="fa fa-plus"></i> Add Repository{" "}
            </a>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                <div className="card-body pt-2 pb-0 px-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("../../../../assets/images/folder.png")}
                      alt=""
                      className="folder-img"
                    />
                  </div>
                  <h5 className="my-0">Repository</h5>
                  <p className="mb-1 mt-4">
                    {/* <i className="fas fa-images text-primary me-2"></i> */}
                    <span>Sample Repository</span>{" "}
                  </p>
                </div>
                <div className="card-footer text-center py-2 px-3">
                  <a
                    className="btn btn-sm btn-success me-2"
                    title="Add Photos"
                    onClick={() => addlist(1)}
                  >
                    <i className="fa fa-plus"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-secondary me-2"
                    title="Edit Repository"
                  >
                    <i className="fa fa-edit"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-danger"
                    title="Delete Repository"
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                <div className="card-body pt-2 pb-0 px-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("../../../../assets/images/folder.png")}
                      alt=""
                      className="folder-img"
                    />
                  </div>
                  <h5 className="my-0">Repository 2</h5>
                  <p className="mb-1 mt-4">
                    {/* <i className="fas fa-images text-primary me-2"></i> */}
                    <span>Sample Repository 2</span>{" "}
                  </p>
                </div>
                <div className="card-footer text-center py-2 px-3">
                  <a
                    className="btn btn-sm btn-success me-2"
                    title="Add Photos"
                    onClick={() => addlist(2)}
                  >
                    <i className="fa fa-plus"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-secondary me-2"
                    title="Edit Repository"
                  >
                    <i className="fa fa-edit"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-danger"
                    title="Delete Repository"
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                <div className="card-body pt-2 pb-0 px-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("../../../../assets/images/folder.png")}
                      alt=""
                      className="folder-img"
                    />
                  </div>
                  <h5 className="my-0">Repository 3</h5>
                  <p className="mb-1 mt-4">
                    {/* <i className="fas fa-images text-primary me-2"></i> */}
                    <span>Sample Repository 3</span>{" "}
                  </p>
                </div>
                <div className="card-footer text-center py-2 px-3">
                  <a
                    className="btn btn-sm btn-success me-2"
                    title="Add Photos"
                    onClick={() => addlist(3)}
                  >
                    <i className="fa fa-plus"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-secondary me-2"
                    title="Edit Repository"
                  >
                    <i className="fa fa-edit"></i>
                  </a>
                  <a
                    className="btn btn-sm btn-danger"
                    title="Delete Repository"
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal show={ashow} size="lg">
        <Modal.Header>
          <h4> Repository </h4>
        </Modal.Header>
        <Modal.Body>
          <Row className="row-sm mx-0">
            <Col lg={12} md={12}>
              <div className="form-group">
                <label className="form-label">
                  Repository Name <span className="errortext"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Repository Name"
                  value={rname}
                  onChange={(e) => setRName(e.target.value)}
                />
              </div>
            </Col>

            <Col lg={12} md={12}>
              <div className="form-group">
                <label className="form-label">
                  Description <span className="errortext"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  value={rdesc}
                  onChange={(e) => setRDesc(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-danger" onClick={repclose}>
            Close
          </a>
        </Modal.Footer>
      </Modal>
      {localStorage.getItem("Role") !== "7" && (
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style mt-2">
                  {localStorage.getItem("Role") !== "8" && (
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      sortingOrder={["asc", "desc"]}
                    />
                  )}
                  {localStorage.getItem("Role") === "8" &&
                    urllink === "schoollist" && (
                      <AgGridReact
                        columnDefs={columnFDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="10"
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                        sortingOrder={["asc", "desc"]}
                      />
                    )}
                  {localStorage.getItem("Role") === "8" &&
                    urllink === "cmsdetails" && (
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="10"
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                        sortingOrder={["asc", "desc"]}
                      />
                    )}
                </div>

                <Modal show={lshow} size="lg" onHide={logoclose}>
                  <Modal.Header>
                    <h4> School Logo</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <img src={schlogo} alt="Logo" />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={logoclose}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>

                <Modal show={sshow} size="lg" onHide={signclose}>
                  <Modal.Header>
                    <h4> School Signature</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <img src={schsign} alt="Signature" />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={signclose}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
