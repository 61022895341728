import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const SchoolSidebar = () => {
  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [fitact, setFitAct] = useState(false);
  const [fitlistact, setFitListAct] = useState(false);
  const [fittoplistact, setFitTopListAct] = useState(false);
  const [fitbotlistact, setFitBotListAct] = useState(false);

  const [schnatact, setSchNatAct] = useState(false);
  const [fittreact, setFitTreAct] = useState(false);
  const [schstateact,setSchStateAct]=useState(false);
  const [newfittreact, setNewFitTreAct] = useState(false);

  const [peract, setPerAct] = useState(false);
  const [keyhighact, setKeyHighListAct] = useState(false);

  const [teamact, setTeamAct] = useState(false);
  const [stlistact, setSTListAct] = useState(false);

  const [achact, setAchAct] = useState(false);
  const [allachact, setAllAchAct] = useState(false);
  const [othachact, setOthAchAct] = useState(false);

  const [plaact, setPlaAct] = useState(false);
  const [plalistact, setPlaListAct] = useState(false);
  const [addplaact, setAddPlaAct] = useState(false);
  const [coaact, setCoaAct] = useState(false);
  const [addcoaact, setAddCoaAct] = useState(false);
  const [allcoaact, setAllCoaAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [toulistact, setTouListAct] = useState(false);
  const [comtoulistact, setComTouListAct] = useState(false);

  const [eveact, setEveAct] = useState(false);
  const [evelistact, setEveListAct] = useState(false);
  const [comevelistact, setComEveListAct] = useState(false);

  const [epllistact, setEPLListAct] = useState(false);
  const [gallistact, setGalListAct] = useState(false);

  const [proact, setProAct] = useState(false);
  const [pact, setPAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setFitAct(false);
    setFitListAct(false);
    setFitTopListAct(false);
    setFitBotListAct(false);
    setSchNatAct(false);
    setSchStateAct(false);
    setFitTreAct(false);
    setNewFitTreAct(false);

    setPerAct(false);
    setKeyHighListAct(false);

    setTeamAct(false);
    setSTListAct(false);

    setAchAct(false);
    setAllAchAct(false);
    setOthAchAct(false);

    setProAct(false);
    setPAct(false);

    setPlaAct(false);
    setPlaListAct(false);
    setAddPlaAct(false);

    setCoaAct(false);
    setAddCoaAct(false);
    setAllCoaAct(false);

    setTouAct(false);
    setTouListAct(false);
    setComTouListAct(false);
    setEPLListAct(false);
    setGalListAct(false);

    setEveAct(false);
    setEveListAct(false);
    setComEveListAct(false);

    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/schoolhead/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tournaments") {
      setTouAct(true);
    }else if (head === "Events") {
      setEveAct(true);
    }  else if (head === "Profile") {
      setProAct(true);
      setPAct(true);
      dotracking("profile menu $$ " + trackobj.profileheading);
      navigate("/schoolhead/profile");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Student") {
      setFitAct(true);
      // navigate("/schoolhead/fittestlist");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    } else if (head === "Performance") {
      setPerAct(true);
      // setKeyHighListAct(true);
      // navigate("/schoolhead/keyhighlights");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    } else if (head === "Team") {
      setTeamAct(true);
      setSTListAct(true);
      dotracking(
        "school team list in school team menu $$ " +
          trackobj.schteamplayerheading
      );
      navigate("/schoolhead/schoolteamlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Achievements") {
      setAchAct(true);
    } else if (head === "Player") {
      setPlaAct(true);
    }else if (head === "Coaches") {
      setCoaAct(true);
    }
  }
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);

    setTouAct(false);
    setTouListAct(false);
    setComTouListAct(false);
    setEPLListAct(false);
    setGalListAct(false);

    setEveAct(false);
    setEveListAct(false);
    setComEveListAct(false);

    setFitAct(false);
    setFitListAct(false);
    setFitTopListAct(false);
    setFitBotListAct(false);
    setSchNatAct(false);
    setSchStateAct(false);
    setFitTreAct(false);
setNewFitTreAct(false);

    setPerAct(false);
    setKeyHighListAct(false);

    setTeamAct(false);
    setSTListAct(false);

    setAchAct(false);
    setAllAchAct(false);
    setOthAchAct(false);

    setProAct(false);
    setPAct(false);

    setPlaAct(false);
    setPlaListAct(false);
    setAddPlaAct(false);

    setCoaAct(false);
    setAddCoaAct(false);
    setAllCoaAct(false);

    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/schoolhead/dashboard");
    } else if (head === "externaltournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      
      navigate("/schoolhead/externaltournamentdetail/" + id);
    }else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/schoolhead/contact");
    } else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/schoolhead/changepassword");
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setTouListAct(true);
      if (type === true) {
        dotracking(
          "tournament list in tournament menu $$ " + trackobj.tourlistheading
        );
      }
      navigate("/schoolhead/tournamentlist");
    } else if (head === "completedtournament") {
      setTouAct(true);
      setComTouListAct(true);
      if (type === true) {
        dotracking(
          "completed tournament list in tournament menu $$ " +
            trackobj.comtourlistheading
        );
      }
      navigate("/schoolhead/completedtournament");
    } else if (head === "orderofeventlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/orderofeventlist/" + id);
    } else if (head === "eventstartlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/eventstartlist/" + id + "/" + eid);
    } else if (head === "athleticsresults") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/athleticsresults/" + id);
    } else if (head === "overallchampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/individualchampionlist/" + id);
    } else if (head === "overallchampion") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/overallchampion/" + id);
    } else if (head === "individualchampion") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/individualchampion/" + id);
    } else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "playerlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/playerlist/" + id);
    } else if (head === "addathletes") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/addathletes/" + id);
    } else if (head === "summary") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/summary/" + id);
    } else if (head === "confirmation") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/confirmation/" + id);
    } else if (head === "analytics") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/analytics/" + id);
    } else if (head === "tournamentdetails") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/tournamentdetails/" + id);
    } else if (head === "achievementtournament") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/achievementtournament/" + id);
    } else if (head === "tournamentagecategorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/tournamentagecategorylist/" + id);
    } else if (head === "tournamentclassagecategorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/tournamentclassagecategorylist/" + id);
    } else if (head === "photoupload") {
      setTouAct(true);
      setTouListAct(true);

      navigate("/schoolhead/photoupload/" + id);
    } else if (head === "pointslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/pointslist/" + id + "/" + eid);
    } else if (head === "categorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/categorylist/" + id);
    }else if (head === "intercategorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/intercategorylist/" + id);
    } else if (head === "teamlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/teamlist/" + id + "/" + tid);
    } else if (head === "tournamentplayer") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/tournamentplayer/" + id);
    } else if (head === "interschoolpaymentlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/interschoolpaymentlist/" + id);
    } else if (head === "performanceplayerlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/performanceplayerlist/" + id);
    } else if (head === "performancephotos") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/performancephotos/" + id);
    } else if (head === "teamcreate") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/teamcreate/" + id + "/" + eid);
    } else if (head === "teamdetails") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/teamdetails/" + id + "/" + tid + "/" + eid);
    } else if (head === "teamplayerupload") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/teamplayerupload/" + id + "/" + eid + "/" + tid);
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/teamplayerlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "entryplayerslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/entryplayerslist/" + id);
    } else if (head === "athleticsentryplayerslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/athleticsentryplayerslist/" + id);
    } else if (head === "tournamentplayer") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/tournamentplayer/" + id);
    } else if (head === "poollist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/poollist/" + id + "/" + eid);
    } else if (head === "scheduleallocate") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/scheduleallocate/" + id + "/" + eid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/schedulelist/" + id + "/" + eid);
    } else if (head === "epllist") {
      setTouAct(true);
      setEPLListAct(true);
      navigate("/schoolhead/epllist");
    } else if (head === "results") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/results/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/resultlist/" + id + "/" + eid);
    }else if (head === "certificatelist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/certificatelist/" + id );
    } else if (head === "photogallery") {
      setTouAct(true);
      setGalListAct(true);
      if (type === true) {
        dotracking(
          "photo/gallery in tournament menu $$ " + trackobj.photoheading
        );
      }
      navigate("/schoolhead/photogallery");
    } else if (head === "photos") {
      setTouAct(true);
      setGalListAct(true);
      navigate("/schoolhead/photos/" + id);
    } else if (head === "highlights") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/highlights/" + id);
    } else if (head === "leaderboard") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/schoolhead/leaderboard/" + id);
    } else if (head === "fittestlist") {
      setFitListAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "fittest list in student fitness menu $$ " + trackobj.fittestheading
        );
      }
      navigate("/schoolhead/fittestlist");
    } else if (head === "fittesttoplist") {
      setFitTopListAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "fittest top 10 list in student fitness menu $$ " + trackobj.fittesttopheading
        );
      }
      navigate("/schoolhead/fittesttoplist");
    } else if (head === "fittestbottomlist") {
      setFitBotListAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "fittest bottom 10 list in student fitness menu $$ " + trackobj.fittestbotheading
        );
      }
      navigate("/schoolhead/fittestbottomlist");
    } else if (head === "fitnesstrends") {
      setFitTreAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "fitness trends in student fitness menu $$ " +
            trackobj.fitnesstrendsheading
        );
      }
      navigate("/schoolhead/fitnesstrends");
    } else if (head === "genderfitnesstrends") {
      setNewFitTreAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "fitness trends in student fitness menu $$ " +
            trackobj.genderfitnesstrendsheading
        );
      }
      navigate("/schoolhead/genderfitnesstrends");
    } else if (head === "schoolvsnational") {
      setSchNatAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "school vs national in student fitness menu $$ " +
            trackobj.schnatheading
        );
      }
      navigate("/schoolhead/schoolvsnational");
    } else if (head === "schoolvsstate") {
      setSchStateAct(true);
      setFitAct(true);
      if (type === true) {
        dotracking(
          "school vs state in student fitness menu $$ " +
            trackobj.schstateheading
        );
      }
      navigate("/schoolhead/schoolvsstate");
    } else if (head === "keyhighlights") {
      setPerAct(true);
      setKeyHighListAct(true);
      if (type === true) {
        dotracking(
          "key highlights list in sports performance menu $$ " +
            trackobj.keylistheading
        );
      }
      navigate("/schoolhead/keyhighlights");
    } else if (head === "achievementslist") {
      setPerAct(true);
      setAllAchAct(true);
      if (type === true) {
        dotracking(
          "achievements list in sports performance menu $$ " +
            trackobj.achievelistheading
        );
      }
      navigate("/schoolhead/achievementslist");
      // } else if (head === "otherachievements") {
      //   setAchAct(true);
      //   setOthAchAct(true);
      //   if (type === true){
      //     dotracking("other achievements in achievements menu $$ " + trackobj.othachievelistheading);
      //   }
      //   navigate("/schoolhead/otherachievements");
    } else if (head === "schoolteamlist") {
      setTeamAct(true);
      setSTListAct(true);
      navigate("/schoolhead/schoolteamlist");
    } else if (head === "profile") {
      setProAct(true);
      setPAct(true);
      navigate("/schoolhead/profile");
    } else if (head === "editprofile") {
      setProAct(true);
      setPAct(true);
      navigate("/schoolhead/editprofile");
    } else if (head === "addplayers") {
      setPlaAct(true);
      setAddPlaAct(true);
      if (type === true) {
        dotracking(
          "add players in player menu $$ " + trackobj.addplayerheading
        );
      }
      navigate("/schoolhead/addplayers");
    } else if (head === "allplayers") {
      setPlaAct(true);
      setPlaListAct(true);
      if (type === true) {
        dotracking(
          "all players in player menu $$ " + trackobj.playerlistheading
        );
      }
      navigate("/schoolhead/allplayers");
    } else if (head === "viewplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/schoolhead/viewplayer/" + id);
    } else if (head === "viewperformanceplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/schoolhead/viewperformanceplayer/" + id);
    } else if (head === "overallperformanceplayer") {
      setPlaAct(true);
      setPlaListAct(true);
      navigate("/schoolhead/overallperformanceplayer/" + id);
    } else if (head === "othereventdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/schoolhead/othereventdetail/" + id);
    }else if (head === "upcomingeventlist") {
      setEveAct(true);
      setEveListAct(true);
      if (type === true) {
        dotracking(
          "Upcoming event list in tournament menu $$ " + trackobj.workupcominglistheading
        );
      }
      navigate("/schoolhead/upcomingeventlist");
    } else if (head === "completedeventslist") {
      setEveAct(true);
      setComEveListAct(true);
      if (type === true) {
        dotracking(
          "completed tournament list in tournament menu $$ " +
            trackobj.workcomplistheading
        );
      }
      navigate("/schoolhead/completedeventslist");
    } else if (head === "eventsentrylist") {
      setEveAct(true);
      setEveListAct(true);
      navigate("/schoolhead/eventsentrylist/" + id);
    }  else if (head === "addcoach") {
      setCoaAct(true);
      setAddCoaAct(true);
      if (type === true) {
        dotracking(
          "add coaches in coaches menu $$ " + trackobj.addcoachheading
        );
      }
      navigate("/schoolhead/addcoach");
    } else if (head === "coacheslist") {
      setCoaAct(true);
      setAllCoaAct(true);
      if (type === true) {
        dotracking(
          "all coaches in coaches menu $$ " + trackobj.coachlistheading
        );
      }
      navigate("/schoolhead/coacheslist");
    } else if (head === "coachdetail") {
      setCoaAct(true);
      setAllCoaAct(true);
      navigate("/schoolhead/coachdetail/" + id);
    }else if (head === "viewcoaches") {
      setCoaAct(true);
      setAllCoaAct(true);
      navigate("/schoolhead/viewcoaches/" + id);
    }
    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1], false);
  }, []);

  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              {/* 
                            <li data-toggle="tooltip" data-placement="right" title="" data-original-title="Referee"
                                className={
                                    refact
                                        ? "active resp-tab-item hor_1"
                                        : "resp-tab-item hor_1"
                                } onClick={() => mainmenuactive("Referee")} aria-controls="hor_1_tab_item-5" role="tab"
                            ><i
                                className="side-menu__icon mdi mdi-account-convert"></i>
                                <div className="slider-text">Referee</div>
                            </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Events"
                className={
                  eveact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Events")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon fa fa-desktop"></i>
                <div className="slider-text">Other Events</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Student"
                className={
                  fitact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Student")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Student Fitness</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Performance"
                className={
                  peract ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Performance")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon fa fa-bar-chart"></i>
                <div className="slider-text">Sport Performance</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Team"
                className={
                  teamact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Team")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-bank"></i>
                <div className="slider-text">School Team</div>
              </li>
              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Achievements"
                className={
                  achact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Achievements")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon fa-solid fa-award"></i>
                <div className="slider-text">Achievements</div>
              </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Player"
                className={
                  plaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Player")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon fa fa-user"></i>
                <div className="slider-text">Player</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Coaches"
                className={
                  coaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Coaches")}
                aria-controls="hor_1_tab_item-3"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Coaches</div>
              </li>
              
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-6"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Profile</div>
              </li>
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard",true)}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>{" "}
                        Tournaments
                      </h4>{" "}
                      <a
                        className={
                          toulistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Upcoming Tournament
                      </a>
                      <a
                        className={
                          comtoulistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("completedtournament", true)
                        }
                      >
                        Completed Tournament
                      </a>
                      <a
                        className={
                          gallistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("photogallery", true)}
                      >
                        Photo / Gallery
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-desktop"></i>
                  <div className="slider-text">Other Events</div>
                </h2>
                <div
                  className={
                    eveact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-desktop"></i> Other Events
                      </h4>{" "}
                      <a
                        className={
                          evelistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("upcomingeventlist", true)}
                      >
                        Upcoming Events
                      </a>
                      <a
                        className={
                          comevelistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("completedeventslist", true)
                        }
                      >
                        Completed Events
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Student Fitness</div>
                </h2>
                <div
                  className={
                    fitact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>{" "}
                        Student Fitness
                      </h4>{" "}
                      <a
                        className={
                          fitlistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("fittestlist", true)}
                      >
                        Fittest List
                      </a>
                      <a
                        className={
                          fittoplistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("fittesttoplist", true)}
                      >
                        Top 10 List
                      </a>
                      <a
                        className={
                          fitbotlistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("fittestbottomlist", true)}
                      >
                        Bottom 10 List
                      </a>
                      <a
                        className={
                          schnatact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolvsnational", true)}
                      >
                        School Vs National
                      </a>
                      <a
                        className={
                          schstateact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolvsstate", true)}
                      >
                        School Vs State
                      </a>
                      <a
                        className={
                          fittreact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("fitnesstrends", true)}
                      >
                        Fitness Trends
                      </a>
                      {/* <a
                        className={
                          newfittreact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("genderfitnesstrends", true)}
                      >
                        Fitness Trends - Gender
                      </a> */}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-bar-chart"></i>
                  <div className="slider-text">Performance</div>
                </h2>
                <div
                  className={
                    peract
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-bar-chart"></i>{" "}
                        Performance
                      </h4>{" "}
                      <a
                        className={
                          keyhighact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("keyhighlights", true)}
                      >
                        Key Highlights
                      </a>
                      <a
                        className={
                          allachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementslist", true)}
                      >
                        Achievements List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-bank"></i>
                  <div className="slider-text">School Team</div>
                </h2>
                <div
                  className={
                    teamact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-bank"></i> School
                        Team
                      </h4>{" "}
                      <a
                        className={
                          stlistact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("schoolteamlist")}
                      >
                        Team List
                      </a>
                    </div>
                  </div>
                </div>

                {/* <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa-solid fa-award"></i>
                  <div className="slider-text">Achievements</div>
                </h2>
                <div
                  className={
                    achact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa-solid fa-award"></i> Achievements
                      </h4>{" "}
                      <a
                        className={
                          allachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementslist",true)}
                      >
                        All Achievements
                      </a>
                      <a
                        className={
                          othachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("otherachievements",true)}
                      >
                        Other Achievements
                      </a>
                    </div>
                  </div>
                </div> */}

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-user"></i>
                  <div className="slider-text">Player</div>
                </h2>
                <div
                  className={
                    plaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-user"></i> Player
                      </h4>
                      <a
                        className={
                          addplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addplayers", true)}
                      >
                        Add Players
                      </a>
                      <a
                        className={
                          plalistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("allplayers", true)}
                      >
                        All Players
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Coaches</div>
                </h2>
                <div
                  className={
                    coaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>{" "}
                        Coaches
                      </h4>
                      <a
                        className={
                          addcoaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addcoach", true)}
                      >
                        Add Coaches
                      </a>
                      <a
                        className={
                          allcoaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("coacheslist", true)}
                      >
                        Coaches List
                      </a>{" "}
                     
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-network"></i>
                  <div className="slider-text">Profile</div>
                </h2>
                <div
                  className={
                    proact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-network"></i>{" "}
                        Profile
                      </h4>{" "}
                      <a
                        className={pact ? "slide-item active" : "slide-item"}
                        // onClick={() => submenuactive("profile")}
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
