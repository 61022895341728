import { bannertrackingdata } from "../services/login";

const bannerlog = function (bid, bpage) {
    const temp = new FormData();
    temp.append("user_id", localStorage.getItem("UID"));
    temp.append("banner_id", bid);
    temp.append("bannerpage", bpage);
    temp.append("role_id",localStorage.getItem("Role"));

    bannertrackingdata(temp).then((response) => {
      if (response.status === true) {

      }
    });
};

export default bannerlog;
