import { AgGridReact } from 'ag-grid-react'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { tourtypecreate, tourtypedetails, tourtypelist, tourtypestatus, tourtypeupdate, tourtypevalidate } from '../../../../services/superadmin'
import { Modal, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import dotracking from '../../../trackinglogs';
import trackobj from '../../../trackingpages.json';

export const TournamenttypeMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [ttypeid, setId] = useState(0);
    const [typename, setTypeName] = useState("");
    const [roleid, setRoleId] = useState("");
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "Tournament Type",
            field: "meet_type",
            sortable: true,
            resizable: true,
            cellStyle: { textAlign: "left" },
        },

        {
            headerName: "Status",
            field: "is_active",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deletetourtype(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "id",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className='text-center'>
                        <a onClick={() => viewtourtype(params.value)} title="Edit Tournament Type"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>

                );
            },
        },
    ];

    function viewtourtype(aid) {
        setShow(true)
        
        tourtypedetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.id)
                setTypeName(response.data.meet_type)
                setRoleId(response.data.role_id)
                setStatus(response.data.is_active + "")
                dotracking("edit button in tournament type for " + response.data.meet_type + " (" + aid + ") $$ " + trackobj.typelistheading)
            }
        })
    }

    function deletetourtype(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 0)
                    dotracking("status icon in tournament type list from Active to Inactive for " + list.data.meet_type + " (" + list.data.id + ") $$ " + trackobj.typelistheading)
                    tourtypestatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 1)
                    dotracking("status icon in tournament type list from Inactive to Active for " + list.data.meet_type + " (" + list.data.id + ") $$ " + trackobj.typelistheading)
                    tourtypestatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }
    const [show, setShow] = useState(false);

    function handleshow() {
        setId(0);
        setTypeName("");
        setRoleId("");
        setStatus("1")
        setErrorMsg("")
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setTypeName("");
        setRoleId("");
        setStatus("1")
        setErrorMsg("")
        setErrorMsg("")

    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    useEffect(() => {
        listall()
    }, [])

    function listall() {
        setFilterProduct([])
        tourtypelist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }
    function submittype(e) {
        e.preventDefault();
        setErrorMsg("")
        if (typename !== "") {
            if (ttypeid === 0) {
                const temp1 = new FormData();
                temp1.append("meet_type", typename)
                tourtypevalidate(temp1).then((res) => {
                    if (res.status === true) {
                        const temp = new FormData();
                        temp.append("meet_type", typename)
                        temp.append("is_active", status)
                        dotracking("add button in tournament type for " + typename + " $$ " + trackobj.typelistheading)
                        tourtypecreate(temp).then((response) => {
                            if (response.status === true) {
                                handleclose();
                                listall();
                                toast.success(response.message);
                            }
                        })
                    } else {
                        Swal.fire({
                            text: typename + " " + res.message,
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#4ecc48",
                            cancelButtonColor: "#6259ca",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setId(0);
                                setTypeName("");
                                setRoleId("");
                                setStatus("1")
                                setErrorMsg("")
                                setErrorMsg("")
                                
                            }
                        });
                    }
                })

            } else if (ttypeid !== 0) {
                const temp = new FormData();
                temp.append("id", ttypeid)
                temp.append("meet_type", typename)
                // temp.append("role_id", roleid)
                temp.append("is_active", status)
                dotracking("edit button in tournament type for " + typename + " (" + ttypeid + ") $$ " + trackobj.typelistheading)
                tourtypeupdate(temp).then((response) => {
                    if (response.status === true) {
                        handleclose();
                        listall();
                        toast.success(response.message);
                    }
                })
            }
        } else {
            setErrorMsg("Please Enter Tournament Type Name")
        }
    }
    return (
        <Fragment>
            <div className="side-app">
                <div className="page-header">
                    <div className="breadcrumb">
                        <h4 className="mb-0">Tournament Type Lists</h4>
                    </div>
                    <ol className="breadcrumb">
                        {localStorage.getItem("Subrole") === "1" &&
                            <li className="breadcrumb-item">
                                <a>Setup</a>
                            </li>
                        }
                        {localStorage.getItem("Subrole") !== "1" &&
                            <li className="breadcrumb-item">
                                <a>Master</a>
                            </li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">
                            Tournament Type Lists
                        </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form className="card mt-0">
                            <div className="card-body">
                                <div className="text-right">
                                    <a className="btn btn-success" onClick={handleshow}>
                                        <i className="fa fa-plus"></i> Add Tournament Type
                                    </a>
                                </div>
                                <div
                                    className="ag-theme-alpine ag-style mt-2"
                                >
                                    <AgGridReact
                                        columnDefs={columnDefs}
                                        rowData={filterProduct}
                                        pagination="true"
                                        paginationPageSize="10"
                                        sortingOrder={sorting}
                                        suppressExcelExport={true}
                                        suppressDragLeaveHidesColumns={true}
                                        domLayout="autoWidth"
                                        onGridReady={(params) => {
                                            params.api.sizeColumnsToFit();
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Modal show={show} size="lg" onHide={handleclose}>
                    <Modal.Header>
                        {ttypeid !== 0 &&
                            <h4> Tournament Type Details</h4>
                        }
                        {ttypeid === 0 &&
                            <h4> Add Tournament Type</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="row-sm mx-0">
                            <Col lg={12} md={12}>
                                <div className="form-group">
                                    <label className="mb-2">
                                        Tournament Type Name{" "}
                                        <span className="errortext"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Tournament Type Name"
                                        className="form-control"
                                        value={typename}
                                        onChange={(e) => setTypeName(e.target.value)}
                                    />
                                </div>
                            </Col>


                            <Col lg={12} md={12}>
                                <div className="form-group">
                                    <label className="mb-2">
                                        Status <span className="errortext"> *</span>
                                    </label>

                                    <div className="row" onChange={Selectstatus}>
                                        <div className="col-md-6">
                                            <label className="custom-control custom-radio custom-control-md">
                                                <input
                                                    type="radio"
                                                    className="reg-chk"
                                                    id="active"
                                                    value="1"
                                                    checked={status === "1"}
                                                />
                                                {" "} Active
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="custom-control custom-radio custom-control-md">
                                                <input
                                                    type="radio"
                                                    className="reg-chk"
                                                    id="active"
                                                    value="0"
                                                    checked={status === "0"}
                                                />
                                                {" "}Inactive
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            {errorMsg !== "" && (
                                <span className="errortext">{errorMsg}</span>
                            )}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <a
                            className="btn btn-danger"
                            onClick={handleclose}
                        >
                            Close
                        </a>
                        {ttypeid !== 0 &&
                            <a
                                className="btn btn-success" onClick={submittype}
                            >
                                Update
                            </a>
                        }
                        {ttypeid === 0 &&
                            <a
                                className="btn btn-success" onClick={submittype}
                            >
                                Add
                            </a>
                        }


                    </Modal.Footer>
                </Modal>
            </div>

        </Fragment>
    )
}
