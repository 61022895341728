import "./App.css";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./components/login/login";
import Network from "./components/network";
import { Register } from "./components/register/register";
import { Header } from "./components/header";
import { useEffect } from "react";
import { Viewathlete } from "./components/dashboard/common/player/viewathlete";
import { Dashboard } from "./components/dashboard/superadmin/dashboard";
import { AthleteDashboard } from "./components/dashboard/player/dashboard";
import { SchoolDashboard } from "./components/dashboard/schoolhead/dashboard";
import { CrmDashboard } from "./components/dashboard/cityregional/dashboard";
import { BloodgroupMaster } from "./components/dashboard/superadmin/master/bloodgroup";
import { SchoolMaster } from "./components/dashboard/superadmin/master/school";
import { RoleMaster } from "./components/dashboard/superadmin/master/roles";
import { TournamenttypeMaster } from "./components/dashboard/superadmin/master/tournamenttype";
import { ClassMaster } from "./components/dashboard/superadmin/master/class";
import { SectionMaster } from "./components/dashboard/superadmin/master/section";
import { StateMaster } from "./components/dashboard/superadmin/master/state";
import { DistrictMaster } from "./components/dashboard/superadmin/master/district";
import { CityMaster } from "./components/dashboard/superadmin/master/city";
import { SportsCatPosMaster } from "./components/dashboard/superadmin/master/sportscatpos";
import { AgeCategoryMaster } from "./components/dashboard/superadmin/master/agecategory";
import { RejectedResMaster } from "./components/dashboard/superadmin/master/rejectedrea";
import { ViewTour } from "./components/dashboard/common/tournament/common/viewtour.js";
import { AddCRM } from "./components/dashboard/superadmin/cityregional/addcrm";
import { RefDashboard } from "./components/dashboard/referee/dashboard";
import { TournamentEntry } from "./components/tournamententry";
import { SportsMaster } from "./components/dashboard/superadmin/master/sports";
import { PlayerProfile } from "./components/dashboard/player/profile";
import { ListTourPlayer } from "./components/dashboard/player/tournament/tourlist";
import { AllPlayers } from "./components/dashboard/common/player/allplayers";
import { BlockedPlayers } from "./components/dashboard/superadmin/athlete/blockedplayers";
import { Forgot } from "./components/login/forgot";
import { TeamList } from "./components/dashboard/common/tournament/teamlist";
import { TeamPlayerList } from "./components/dashboard/common/tournament/teamplayerlist";
import { CityAddSchool } from "./components/dashboard/cityregional/schoolhead/addschool";
import { CityListSchool } from "./components/dashboard/cityregional/schoolhead/listschool";
import { Baseupload } from "./components/dashboard/superadmin/upload/baseupload";
import { UploadResult } from "./components/dashboard/common/tournament/resultupload";
import { AdminCRMList } from "./components/dashboard/superadmin/cityregional/listcrm";
import { Entryplayerslist } from "./components/dashboard/common/tournament/entryplayerslist";
import { ListTour } from "./components/dashboard/common/tournament/common/listtour.js";
import { TeamCreate } from "./components/dashboard/common/tournament/teamcreate";
import { TeamPlayerCreate } from "./components/dashboard/common/tournament/teamplayercreate";
import { OfflineEntry } from "./components/dashboard/common/tournament/common/offlineentry.js";
import { PlayerResultList } from "./components/dashboard/player/resultlist";
import { ResultList } from "./components/dashboard/common/tournament/resultlist";
import { AddPlayers } from "./components/dashboard/common/player/addplayer";
import { AddSchoolHead } from "./components/dashboard/common/schoolhead/addschoolhead";
import { ListSchoolHead } from "./components/dashboard/common/schoolhead/listschoolhead";
import { EditTour } from "./components/dashboard/common/tournament/common/edittour.js";
import { EditSchoolHead } from "./components/dashboard/common/schoolhead/editschoolhead";
import { EditCRM } from "./components/dashboard/superadmin/cityregional/editcrm";
import { AddTourPlayer } from "./components/dashboard/player/tournament/touradd";
import { ListRegPlayer } from "./components/dashboard/player/tournament/reglist";
import { ListTeam } from "./components/dashboard/player/tournament/teamlist";
import { PlayerTeamList } from "./components/dashboard/player/tournament/playerlist";
import { ScheduleTeamPlayerCreate } from "./components/dashboard/common/tournament/scheduleteam";
import { SchedulePoolList } from "./components/dashboard/common/tournament/schedulepool";
import { Schedule } from "./components/dashboard/common/tournament/schedule";
import { ScheduleAllocate } from "./components/dashboard/common/tournament/scheduleallocate";
import { AddTour } from "./components/dashboard/common/tournament/common/addtour.js";
import { Agecatetour } from "./components/dashboard/common/tournament/agecatetour";
import { ViewReferee } from "./components/dashboard/common/referee/viewreferee";
import { AdminRefereeList } from "./components/dashboard/common/referee/listreferee";
import { PhotoGallery } from "./components/dashboard/common/photos/photogallery";
import { Photos } from "./components/dashboard/common/photos/photos";
import { Profile } from "./components/dashboard/common/profile";
import { Comingsoon } from "./components/comingsoon";
import { AddReferee } from "./components/dashboard/common/referee/addreferee";
import { ViewCityManager } from "./components/dashboard/superadmin/cityregional/viewcrm";
import { ViewSchoolHead } from "./components/dashboard/common/schoolhead/viewschoolhead.js";
import { Tourconfirm } from "./components/dashboard/superadmin/templates/email/tourconfirm";
import { Registeration } from "./components/dashboard/superadmin/templates/email/registeration";
import { ForgotTemplate } from "./components/dashboard/superadmin/templates/email/forgot";
import { BlockedTemplate } from "./components/dashboard/superadmin/templates/email/blocked";
import { CRMRegisterTemplate } from "./components/dashboard/superadmin/templates/email/crmregister";
import { CRMTourAssign } from "./components/dashboard/superadmin/templates/email/crmassign";
import { SchHeadRegTemplate } from "./components/dashboard/superadmin/templates/email/schoolheadregister";
import { RefRegisterTemplate } from "./components/dashboard/superadmin/templates/email/refereeregister";
import { RefereeTourAssign } from "./components/dashboard/superadmin/templates/email/refereeassign";
import { EmailKeyword } from "./components/dashboard/superadmin/master/emailkeyword";
import { WBlockedTemplate } from "./components/dashboard/superadmin/templates/whatsapp/blocked";
import { WRegisteration } from "./components/dashboard/superadmin/templates/whatsapp/registeration";
import { WCRMRegisterTemplate } from "./components/dashboard/superadmin/templates/whatsapp/crmregister";
import { WTourconfirm } from "./components/dashboard/superadmin/templates/whatsapp/tourconfirm";
import { WForgotTemplate } from "./components/dashboard/superadmin/templates/whatsapp/forgot";
import { WCRMTourAssign } from "./components/dashboard/superadmin/templates/whatsapp/crmassign";
import { WSchHeadRegTemplate } from "./components/dashboard/superadmin/templates/whatsapp/schoolheadregister";
import { WRefRegisterTemplate } from "./components/dashboard/superadmin/templates/whatsapp/refereeregister";
import { WRefereeTourAssign } from "./components/dashboard/superadmin/templates/whatsapp/refereeassign";
import { TourPaymentTemplate } from "./components/dashboard/superadmin/templates/email/paymentlink";
import { PaymentPendingRegister } from "./components/dashboard/common/player/paymentpending";
import { WPaymentTemplate } from "./components/dashboard/superadmin/templates/whatsapp/paymentlink";
import { PhotoUpload } from "./components/dashboard/common/tournament/common/photoupload.js";
import { EditPlayer } from "./components/dashboard/common/player/editplayer";
import { Highlights } from "./components/dashboard/common/tournament/common/highlights.js";
import { PlayerRegister } from "./components/register/playerregister";
import { HighlightsPlayer } from "./components/dashboard/player/highlights";
import { HighlightDetailPlayer } from "./components/dashboard/player/highlightdetail";
import { PaymentList } from "./components/dashboard/superadmin/paymentlist";
import { ViewTourPlayer } from "./components/dashboard/player/tournament/tourview";
import { TourSchedule } from "./components/dashboard/superadmin/templates/email/tourschedule";
import { TourRemainder } from "./components/dashboard/superadmin/templates/email/tourremainder";
import { LiveStreamLink } from "./components/dashboard/superadmin/templates/email/livestreamlink";
import { TourPhotos } from "./components/dashboard/superadmin/templates/email/tourphotos";
import { DownloadCertificate } from "./components/dashboard/superadmin/templates/email/downloadcertificate";
import { NewPartnerTour } from "./components/dashboard/superadmin/templates/email/newpartnertour";
import { SchHeadAssign } from "./components/dashboard/superadmin/templates/email/schheadassign";
import { WTourSchedule } from "./components/dashboard/superadmin/templates/whatsapp/tourschedule";
import { WTourRemainder } from "./components/dashboard/superadmin/templates/whatsapp/tourremainder";
import { WLiveStreamLink } from "./components/dashboard/superadmin/templates/whatsapp/livestreamlink";
import { WTourPhotos } from "./components/dashboard/superadmin/templates/whatsapp/tourphotos";
import { WDownloadCertificate } from "./components/dashboard/superadmin/templates/whatsapp/downloadcertificate";
import { WNewPartnerTour } from "./components/dashboard/superadmin/templates/whatsapp/newpartnertour";
import { WSchHeadAssign } from "./components/dashboard/superadmin/templates/whatsapp/schheadassign";
import { EditReferee } from "./components/dashboard/common/referee/editreferee";
import { MergePlayersTemplate } from "./components/dashboard/superadmin/templates/email/mergeplayer";
import { WMergePlayersTemplate } from "./components/dashboard/superadmin/templates/whatsapp/mergeplayer";
import { TeamWithPlayerList } from "./components/dashboard/common/tournament/teamwithplayerlist";
import { PoolTourupload } from "./components/dashboard/common/tournament/importpool";
import { SendRegisterLink } from "./components/dashboard/common/tournament/sendregisterlink";
import { RegistersendLinkTemplate } from "./components/dashboard/superadmin/templates/email/registerationsendlink";
import { WRegisterSendLinkTemplate } from "./components/dashboard/superadmin/templates/whatsapp/registerationsendlink";
import { CategoryList } from "./components/dashboard/common/tournament/categorylist";
import { SendImage } from "./components/dashboard/superadmin/templates/email/sendimage";
import { WSendImage } from "./components/dashboard/superadmin/templates/whatsapp/sendimage";
import { GenerateCertificate } from "./components/dashboard/common/tournament/common/certificatelist.js";
import { CertUpload } from "./components/dashboard/common/tournament/common/certificateupload.js";
import { CertificatePlayer } from "./components/dashboard/player/certificatelist";
import { ViewTourSchHead } from "./components/dashboard/schoolhead/tournament/viewtour";
import { ResultWholeList } from "./components/dashboard/common/tournament/resultwholelist";
import { ResultScheduleList } from "./components/dashboard/common/tournament/resultbasedschedule";
import { TrackingList } from "./components/dashboard/superadmin/trackinglist";
import { AssessmentUpload } from "./components/dashboard/superadmin/upload/assessmentupload";
import { AssessmentListPlayer } from "./components/dashboard/player/assessmentlist";
import { AssessmentDetail } from "./components/dashboard/player/assessmentdetail";
import { DataCube } from "./components/dashboard/superadmin/datacube";
import { PasswordChange } from "./components/changepassword";
import { CheckPayment } from "./components/paymentcheck";
import { Average } from "./components/dashboard/superadmin/averagetimetracking";
import { PointsList } from "./components/dashboard/common/tournament/pointslist";
import { PointsEntry } from "./components/dashboard/common/tournament/pointsupload";
import { AssessmentAdmin } from "./components/dashboard/superadmin/upload/assessmentlist";
import { AssessmentDetailAdmin } from "./components/dashboard/superadmin/upload/assessmentdetail";
import { Analytics } from "./components/dashboard/common/tournament/common/analytics.js";
import { Achievements } from "./components/dashboard/common/achievements";
import { Bannerlist } from "./components/dashboard/superadmin/banner/bannerlist";
import { Bannerupload } from "./components/dashboard/superadmin/banner/bannerupload";
import { Achievementslist } from "./components/dashboard/common/achievements/achievementlist";
import { AchievementUpload } from "./components/dashboard/common/achievements/achievementupload";
import { PlayerAchievementslist } from "./components/dashboard/player/achievements/achievementlist";
import { PlayerAchievementDetail } from "./components/dashboard/player/achievements/achievementdetail";
import { AssessmentPlayerDetAdmin } from "./components/dashboard/superadmin/upload/assessmentplayerdet";
import { EventMaster } from "./components/dashboard/superadmin/master/events";
import { AddAgeCategory } from "./components/dashboard/common/tournament/athletics/agecategory";
import { SchoolTeamListAdmin } from "./components/dashboard/superadmin/upload/schoolteam/schoolteamlist";
import { Schoolteamupload } from "./components/dashboard/superadmin/upload/schoolteam/schoolteamupload";
import { SchoolTeamPlayers } from "./components/dashboard/common/schoolhead/schoolteam.js";
import { AddedPlayersList } from "./components/dashboard/common/tournament/athletics/playerlist";
import { Addathlete } from "./components/dashboard/common/tournament/athletics/addathlete";
import { AgeCategoryList } from "./components/dashboard/common/tournament/athletics/touragecategorylist";
import { EventAgeCategory } from "./components/dashboard/common/tournament/athletics/toureventlist";
import { KeyHighlights } from "./components/dashboard/schoolhead/keyhighlights.js";
import { SummaryPlayers } from "./components/dashboard/common/tournament/athletics/summary.js";
import { ConfirmationPlayer } from "./components/dashboard/common/tournament/athletics/confirmation.js";
import { FittestList } from "./components/dashboard/schoolhead/fitness/fittestkids.js";
import { SchoolNational } from "./components/dashboard/schoolhead/fitness/schoolnational.js";
import { AthleticsEntryplayerslist } from "./components/dashboard/common/tournament/athletics/athleticsentrylist.js";
import { FitnessList } from "./components/dashboard/schoolhead/fitness/fitnesslist.js";
import { SchoolTourlist } from "./components/dashboard/common/tournament/athletics/schooltourlist.js";
import { OrderEventsList } from "./components/dashboard/common/tournament/athletics/orderofeventslist.js";
import { AthleticsResultUpload } from "./components/dashboard/common/tournament/athletics/athleticsresultupload.js";
import { AthleticsOrderEventsUpload } from "./components/dashboard/common/tournament/athletics/athleticsordereventupload.js";
import { AdminAthleticsResultlist } from "./components/dashboard/common/tournament/athletics/athleticsresultlist.js";
import { AdminOverallChampionlist } from "./components/dashboard/common/tournament/athletics/championoveralllist.js";
import { AdminIndividualChampionlist } from "./components/dashboard/common/tournament/athletics/championindividuallist.js";
import { AdminAgeCategoryChampionlist } from "./components/dashboard/common/tournament/athletics/championsagecategorylist.js";
import { CompletedListTour } from "./components/dashboard/schoolhead/tournament/comptourlist.js";
import { OrderEventsStartList } from "./components/dashboard/common/tournament/athletics/orderofeventsstartlist.js";
import { ContactList } from "./components/contactus.js";
import { BannerTrackingList } from "./components/dashboard/superadmin/bannertracklist.js";
import { AddEventWorkshop } from "./components/dashboard/common/workshops/addworkshop.js";
import { EventtypeMaster } from "./components/dashboard/superadmin/master/eventtype.js";
import { ListWorkshop } from "./components/dashboard/common/workshops/listworkshop.js";
import { ViewWorkshop } from "./components/dashboard/common/workshops/viewworkshop.js";
import { EventEntry } from "./components/register/evententry.js";
import { EventRegister } from "./components/register/eventregister.js";
import { WEventRegisteration } from "./components/dashboard/superadmin/templates/eventwhatsapp/registeration.js";
import { WEventConfimation } from "./components/dashboard/superadmin/templates/eventwhatsapp/register.js";
import { EventRegisteration } from "./components/dashboard/superadmin/templates/eventemail/registeration.js";
import { EventRegisterConfirm } from "./components/dashboard/superadmin/templates/eventemail/register.js";
import { EventPaymentPendingRegister } from "./components/dashboard/common/workshops/eventpaymentpending.js";
import { EventEntryplayerslist } from "./components/dashboard/common/workshops/entryplayerlist.js";
import { CriteriaMaster } from "./components/dashboard/superadmin/master/performancecriteria.js";
import { PerformanceList } from "./components/dashboard/common/tournament/common/performancelist.js";
import { PerformancePlayerPhotoAdd } from "./components/dashboard/common/tournament/common/performanceplayerphoto.js";
import { InterschoolPayment } from "./components/dashboard/common/tournament/interschoolpayment.js";
import { SchoolPerformanceList } from "./components/dashboard/common/tournament/common/performance.js";
import { PerformancePlayerPhotoEdit } from "./components/dashboard/common/tournament/common/performanceplayerphotoedit.js";
import { SchoolheadConfirmation } from "./components/dashboard/superadmin/templates/email/schoolheadpayment.js";
import { WSchoolheadConfirmation } from "./components/dashboard/superadmin/templates/whatsapp/schoolheadpayment.js";
import { SchoolheadTournament } from "./components/dashboard/superadmin/templates/email/sendschoolheadtournament.js";
import { WSchoolheadTournament } from "./components/dashboard/superadmin/templates/whatsapp/sendschoolheadtournament.js";
import { ClassAgeCategoryList } from "./components/dashboard/common/tournament/common/classagecatlist.js";
import { UpcomingListEvent } from "./components/dashboard/schoolhead/workshop/upcominglist.js";
import { CompletedListEvent } from "./components/dashboard/schoolhead/workshop/completedlist.js";
import { RefundList } from "./components/dashboard/common/refundlist.js";
import { UpAchievementslist } from "./components/dashboard/superadmin/upload/achieveupload.js";
import { AllTypeCharts } from "./components/dashboard/common/allcharts.js";
import { SchoolDiscountList } from "./components/dashboard/common/tournament/common/schooldiscount.js";
import PrivateRoute from "./components/privateroute.js";
import { AddCoach } from "./components/dashboard/common/coach/addcoach.js";
import { ListCoach } from "./components/dashboard/common/coach/coachlist.js";
import { ViewCoaches } from "./components/dashboard/common/coach/viewcoach.js";
import { EditCoach } from "./components/dashboard/common/coach/editcoach.js";
import { CoachDashboard } from "./components/dashboard/coach/dashboard.js";
import { IndPerformanceList } from "./components/dashboard/common/tournament/common/performancebycoach.js";
import { Performancebyplayer } from "./components/dashboard/common/player/performancebyplayer.js";
import { PerfTrackingMaster } from "./components/dashboard/superadmin/master/performacetracking.js";
import { PerformancebyTour } from "./components/dashboard/common/player/performancebytour.js";
import { AddExternalTour } from "./components/dashboard/common/tournament/common/addexternaltour.js";
import { ListExternalTour } from "./components/dashboard/common/tournament/common/listexternaltour.js";
import { ViewExternalTour } from "./components/dashboard/common/tournament/common/viewexternaltour.js";
import { SchoolState } from "./components/dashboard/schoolhead/fitness/schoolstate.js";
import { FitnessNewList } from "./components/dashboard/schoolhead/fitness/fitnessnewlist.js";
import { FitnessGenderList } from "./components/dashboard/schoolhead/fitness/fitnessgenderlist.js";
import { SchoolList } from "./components/dashboard/superadmin/CMS/schoollist.js";
import { CMSDetails } from "./components/dashboard/superadmin/CMS/cmsdetails.js";
import { FittestTopList } from "./components/dashboard/schoolhead/fitness/fittestkidstop.js";
import { FitBottomList } from "./components/dashboard/schoolhead/fitness/fitbottom.js";
import { TagsMaster } from "./components/dashboard/superadmin/master/cmstags.js";
import { FunderMaster } from "./components/dashboard/superadmin/master/funder.js";
import { FunderCMSList } from "./components/dashboard/superadmin/CMS/funderlist.js";
import { FunderCMSDetails } from "./components/dashboard/superadmin/CMS/fundercms.js";
import { Leaderboard } from "./components/dashboard/common/tournament/common/leaderboard.js";
import { GroupSchoolMaster } from "./components/dashboard/superadmin/master/groupofschools.js";
import { GroupCMSList } from "./components/dashboard/superadmin/CMS/groupschool.js";
import { FunderGroupSchool } from "./components/dashboard/superadmin/master/fundergroupschool.js";
import { SchoolCRM } from "./components/dashboard/superadmin/cityregional/schoolcrm.js";
import { FunderDashboard } from "./components/dashboard/funder/dashboard.js";
import { TempFunderCMSDetails } from "./components/dashboard/superadmin/CMS/tempfunder.js";
import { AllResouces } from "./components/dashboard/superadmin/CMS/allresouces.js";
import { AddFunder } from "./components/dashboard/common/funder/addfunder.js";
import { ListFunder } from "./components/dashboard/common/funder/listfunder.js";
import { ViewFunder } from "./components/dashboard/common/funder/viewfunder.js";
import { EditFunder } from "./components/dashboard/common/funder/editfunder.js";

function App() {
  return (
    <HashRouter>
      <Network />
      <Routes>
        <Route path="/" exact element={<Navigate to="/login" />} />

        <Route path="/login" exact={true} element={<Login />} />
        <Route path="/forgotusername" element={<Forgot />} />
        <Route path="/paymentcheck" element={<CheckPayment />} />
        <Route path="/evententry/:id" element={<EventEntry />} />
        <Route path="/eventregister/:id" element={<EventRegister />} />

        <Route path="/playerregister" element={<PlayerRegister />} />
        <Route path="/register/:id/:schoolid" element={<Register />} />
        <Route
          path="/tournament/payment/:id/:userid"
          element={<PaymentPendingRegister />}
        />
        <Route
          path="/event/payment/:id/:userid"
          element={<EventPaymentPendingRegister />}
        />
        <Route
          path="/tournamententry/:id/:schoolid"
          element={<TournamentEntry />}
        />
        <Route element={<Header />}>
          <Route path="/superadmin/dashboard" element={<Dashboard />} />

          <Route path="/superadmin/trackinglist" element={<TrackingList />} />

          <Route path="/superadmin/cmsdetails" element={<SchoolList />} />
          <Route path="/superadmin/addcms/:id" element={<CMSDetails />} />

          <Route path="/superadmin/funderdetails" element={<FunderCMSList />} />
          <Route
            path="/superadmin/addfundercms/:id"
            element={<FunderCMSDetails />}
          />
 <Route
            path="/superadmin/allresoucescms"
            element={<AllResouces />}
          />
          <Route
            path="/superadmin/groupofschooldetails"
            element={<GroupCMSList />}
          />
          <Route
            path="/superadmin/addgroupofschoolcms/:id"
            element={<FunderCMSDetails />}
          />

          <Route
            path="/superadmin/bannertrackinglist"
            element={<BannerTrackingList />}
          />

          <Route
            path="/superadmin/schooltrackinglist"
            element={<BannerTrackingList />}
          />

          <Route
            path="/superadmin/fundertrackinglist"
            element={<BannerTrackingList />}
          />

          <Route
            path="/superadmin/grouptrackinglist"
            element={<BannerTrackingList />}
          />
          <Route path="/superadmin/allcharts" element={<AllTypeCharts />} />

          {/*Superadmin Player Lists */}
          <Route path="/superadmin/allplayers" element={<AllPlayers />} />
          <Route path="/superadmin/addplayers" element={<AddPlayers />} />
          <Route
            path="/superadmin/blockedplayers"
            element={<BlockedPlayers />}
          />
          <Route path="/superadmin/viewplayer/:id" element={<Viewathlete />} />
          <Route
            path="/superadmin/overallperformanceplayer/:id"
            element={<Performancebyplayer />}
          />
          <Route
            path="/superadmin/viewperformanceplayer/:id"
            element={<PerformancebyTour />}
          />

          <Route path="/superadmin/editplayer/:id" element={<EditPlayer />} />

          <Route
            path="/superadmin/tournamentplayer/:id"
            element={<Viewathlete />}
          />

          {/*Superadmin Player Lists */}

          <Route
            path="/superadmin/addotherevent"
            element={<AddEventWorkshop />}
          />
          <Route
            path="/superadmin/editotherevent/:id"
            element={<AddEventWorkshop />}
          />
          <Route path="/superadmin/othereventlist" element={<ListWorkshop />} />
          <Route
            path="/superadmin/eventsendregisterlink/:id"
            element={<RegistersendLinkTemplate />}
          />
          <Route
            path="/superadmin/othereventdetail/:id"
            element={<ViewWorkshop />}
          />
          <Route
            path="/superadmin/othereventdetails/:id"
            element={<ViewWorkshop />}
          />
          {/*Superadmin Tournament Lists */}
          <Route path="/superadmin/addtournament" element={<AddTour />} />
          <Route
            path="/superadmin/schooldiscountlist/:id"
            element={<SchoolDiscountList />}
          />

          <Route
            path="/superadmin/athleticsagecategory/:id"
            element={<AddAgeCategory />}
          />
          <Route
            path="/superadmin/tournamentschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/superadmin/athleticschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/superadmin/playerlist/:id/:eid"
            element={<AddedPlayersList />}
          />
          <Route
            path="/superadmin/addathletes/:id/:eid"
            element={<Addathlete />}
          />
          <Route
            path="/superadmin/orderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route
            path="/superadmin/eventstartlist/:id/:eid"
            element={<OrderEventsStartList />}
          />

          <Route
            path="/superadmin/orderofeventupload/:id"
            element={<AthleticsOrderEventsUpload />}
          />
          <Route
            path="/superadmin/bibupload/:id"
            element={<AthleticsOrderEventsUpload />}
          />

          <Route
            path="/superadmin/summary/:id/:eid"
            element={<SummaryPlayers />}
          />
          <Route
            path="/superadmin/confirmation/:id/:eid"
            element={<ConfirmationPlayer />}
          />
          <Route
            path="/superadmin/athleticsresultupload/:id"
            element={<AthleticsResultUpload />}
          />
          <Route
            path="/superadmin/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />
          <Route
            path="/superadmin/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/superadmin/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/superadmin/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />

          <Route
            path="/superadmin/tournamentagecategorylist/:id"
            element={<AgeCategoryList />}
          />
          <Route
            path="/superadmin/tournamentclassagecategorylist/:id"
            element={<ClassAgeCategoryList />}
          />
          <Route
            path="/superadmin/agecategorybyevent/:id/:eid"
            element={<EventAgeCategory />}
          />
          <Route
            path="/superadmin/tournamentagecategory/:id"
            element={<Agecatetour />}
          />
          <Route path="/superadmin/tournamentlist" element={<ListTour />} />
          <Route
            path="/superadmin/tournamentdetails/:id"
            element={<ViewTour />}
          />
          <Route
            path="/superadmin/tournamentdetail/:id"
            element={<ViewTour />}
          />
          <Route path="/superadmin/edittournament/:id" element={<EditTour />} />
          <Route
            path="/superadmin/teamcreate/:id/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/superadmin/interteamcreate/:id/:eid/:sid"
            element={<TeamCreate />}
          />
          <Route
            path="/superadmin/teamplayercreate/:id/:tid/:eid"
            element={<TeamPlayerCreate />}
          />
          <Route
            path="/superadmin/interteamplayercreate/:id/:tid/:eid"
            element={<TeamPlayerCreate />}
          />
          <Route
            path="/superadmin/teamdetails/:id/:tid/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/superadmin/interteamdetails/:id/:tid/:eid"
            element={<TeamCreate />}
          />
          <Route path="/superadmin/teamlist/:id/:tid" element={<TeamList />} />
          <Route
            path="/superadmin/interteamlist/:id/:tid/:eid"
            element={<TeamList />}
          />

          <Route
            path="/superadmin/teamplayerlist/:id/:tid/:eid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/superadmin/interteamplayerlist/:id/:tid/:eid/:sid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/superadmin/entryplayerslist/:id"
            element={<Entryplayerslist />}
          />
          <Route path="/superadmin/refundlist/:id" element={<RefundList />} />
          <Route
            path="/superadmin/eventrefundlist/:id"
            element={<RefundList />}
          />
          <Route
            path="/superadmin/athleticsrefundlist/:id"
            element={<RefundList />}
          />
          <Route
            path="/superadmin/athleticsentryplayerslist/:id"
            element={<AthleticsEntryplayerslist />}
          />
          <Route
            path="/superadmin/offlineentry/:id"
            element={<OfflineEntry />}
          />
          <Route
            path="/superadmin/sendregisteration/:id"
            element={<SendRegisterLink />}
          />
          <Route
            path="/superadmin/categorylist/:id"
            element={<CategoryList />}
          />
          <Route
            path="/superadmin/intercategorylist/:id/:tid"
            element={<CategoryList />}
          />
          <Route
            path="/superadmin/performancelist/:id/:eid"
            element={<PerformanceList />}
          />
          <Route
            path="/superadmin/addperformance/:id/:eid"
            element={<PerformancePlayerPhotoAdd />}
          />
          <Route
            path="/superadmin/performanceplayerphotoedit/:id/:tid/:eid"
            element={<PerformancePlayerPhotoEdit />}
          />
          <Route
            path="/superadmin/pointslist/:id/:eid"
            element={<PointsList />}
          />

          <Route
            path="/superadmin/pointsentry/:id/:eid"
            element={<PointsEntry />}
          />
          <Route
            path="/superadmin/certificatelist/:id"
            element={<GenerateCertificate />}
          />
          <Route
            path="/superadmin/uploadcertificate/:id"
            element={<CertUpload />}
          />
          <Route
            path="/superadmin/results/:id"
            element={<ResultScheduleList />}
          />

          <Route
            path="/superadmin/resultlist/:id/:eid"
            element={<ResultScheduleList />}
          />
          <Route
            path="/superadmin/schedulelist/:id/:eid"
            element={<Schedule />}
          />

          <Route
            path="/superadmin/achievements"
            element={<Achievementslist />}
          />
          <Route
            path="/superadmin/achievementlist"
            element={<UpAchievementslist />}
          />
          <Route
            path="/superadmin/achievementsupload"
            element={<AchievementUpload />}
          />

          <Route
            path="/superadmin/achievementindividual"
            element={<AchievementUpload />}
          />

          <Route
            path="/superadmin/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          <Route
            path="/superadmin/importpool/:id"
            element={<PoolTourupload />}
          />
          <Route
            path="/superadmin/importfixture/:id/:eid/:tid"
            element={<PoolTourupload />}
          />

          <Route
            path="/superadmin/poollist/:id/:eid"
            element={<SchedulePoolList />}
          />
          <Route
            path="/superadmin/scheduleteam/:id/:tid/:eid"
            element={<ScheduleTeamPlayerCreate />}
          />
          <Route path="/superadmin/photoupload/:id" element={<PhotoUpload />} />
          <Route path="/superadmin/photogallery" element={<PhotoGallery />} />
          <Route path="/superadmin/photos/:id" element={<Photos />} />
          <Route path="/superadmin/highlights/:id" element={<Highlights />} />
          <Route path="/superadmin/leaderboard/:id" element={<Leaderboard />} />

          <Route path="/superadmin/analytics/:id" element={<Analytics />} />
          <Route
            path="/superadmin/interschoolpaymentlist/:id/:tid"
            element={<InterschoolPayment />}
          />

          <Route
            path="/superadmin/addexternaltournament"
            element={<AddExternalTour />}
          />
          <Route
            path="/superadmin/editexternaltournament/:id"
            element={<AddExternalTour />}
          />

          <Route
            path="/superadmin/externaltournamentlist"
            element={<ListExternalTour />}
          />
          <Route
            path="/superadmin/externaltournamentdetails/:id"
            element={<ViewExternalTour />}
          />
          <Route
            path="/superadmin/externaltournamentdetail/:id"
            element={<ViewExternalTour />}
          />
          <Route
            path="/superadmin/externalteamlist/:id"
            element={<TeamList />}
          />
          {/*Superadmin Tournament Lists */}

          {/*Superadmin CRM Lists */}
          <Route path="/superadmin/addcityregional" element={<AddCRM />} />
          <Route
            path="/superadmin/cityregionaldetail/:id"
            element={<EditCRM />}
          />
          <Route
            path="/superadmin/citymanagerdetail/:id"
            element={<ViewCityManager />}
          />
          <Route
            path="/superadmin/cityregionallist"
            element={<AdminCRMList />}
          />

          <Route path="/superadmin/schoolmanager/:id" element={<SchoolCRM />} />
          <Route path="/superadmin/fundermanager/:id" element={<SchoolCRM />} />
          <Route
            path="/superadmin/groupschoolmanager/:id"
            element={<SchoolCRM />}
          />

          {/*Superadmin CRM Lists */}

          {/*Superadmin Referee Lists */}
          <Route path="/superadmin/addreferee" element={<AddReferee />} />
          <Route
            path="/superadmin/refereelist"
            element={<AdminRefereeList />}
          />
          <Route
            path="/superadmin/refereedetail/:id"
            element={<ViewReferee />}
          />
          <Route path="/superadmin/editreferee/:id" element={<EditReferee />} />
          {/*Superadmin Referee Lists */}

          {/*Superadmin Master lists */}
          <Route path="/superadmin/rolelist" element={<RoleMaster />} />
          <Route
            path="/superadmin/bloodgrouplist"
            element={<BloodgroupMaster />}
          />
          <Route path="/superadmin/schoollist" element={<SchoolMaster />} />
          <Route
            path="/superadmin/groupschoollist"
            element={<GroupSchoolMaster />}
          />
          <Route
            path="/superadmin/funderschools/:id"
            element={<FunderGroupSchool />}
          />
          <Route
            path="/superadmin/groupschools/:id"
            element={<FunderGroupSchool />}
          />

          <Route path="/superadmin/classlist" element={<ClassMaster />} />
          <Route path="/superadmin/sectionlist" element={<SectionMaster />} />
          <Route path="/superadmin/statelist" element={<StateMaster />} />
          <Route path="/superadmin/districtlist" element={<DistrictMaster />} />
          <Route path="/superadmin/citylist" element={<CityMaster />} />
          <Route path="/superadmin/sportslist" element={<SportsMaster />} />
          <Route
            path="/superadmin/sportscategorypositionlist"
            element={<SportsCatPosMaster />}
          />
          <Route
            path="/superadmin/tournamenttypelist"
            element={<TournamenttypeMaster />}
          />
          <Route
            path="/superadmin/agecategorylist"
            element={<AgeCategoryMaster />}
          />
          <Route
            path="/superadmin/rejectedreasonlist"
            element={<RejectedResMaster />}
          />
          <Route path="/superadmin/emailkeyword" element={<EmailKeyword />} />
          <Route path="/superadmin/eventlist" element={<EventMaster />} />
          <Route
            path="/superadmin/eventtypelist"
            element={<EventtypeMaster />}
          />
          <Route path="/superadmin/criterialist" element={<CriteriaMaster />} />
          <Route
            path="/superadmin/performancelist"
            element={<PerfTrackingMaster />}
          />
          <Route path="/superadmin/tagslist" element={<TagsMaster />} />
          <Route path="/superadmin/funderlist" element={<FunderMaster />} />

          {/*Superadmin Master lists */}

          {/*Superadmin School Head lists */}
          <Route path="/superadmin/addschoolhead" element={<AddSchoolHead />} />
          <Route
            path="/superadmin/schoolheadlist"
            element={<ListSchoolHead />}
          />
          <Route
            path="/superadmin/schoolheaddetail/:id"
            element={<EditSchoolHead />}
          />
          <Route
            path="/superadmin/schoolheadview/:id"
            element={<ViewSchoolHead />}
          />

          {/*Superadmin School Head lists */}

          {/*Superadmin Coach lists */}
          <Route path="/superadmin/addcoach" element={<AddCoach />} />
          <Route path="/superadmin/coacheslist" element={<ListCoach />} />
          <Route path="/superadmin/coachdetail/:id" element={<EditCoach />} />
          <Route path="/superadmin/viewcoaches/:id" element={<ViewCoaches />} />

          {/*Superadmin Coach lists */}

          {/*Superadmin Coach lists */}
          <Route path="/superadmin/addfunder" element={<AddFunder />} />
          <Route path="/superadmin/listfunder" element={<ListFunder />} />
          <Route path="/superadmin/funderdetail/:id" element={<EditFunder />} />
          <Route path="/superadmin/viewfunder/:id" element={<ViewFunder />} />

          {/*Superadmin Coach lists */}

          {/*Superadmin Upload */}
          <Route path="/superadmin/baseupload" element={<Baseupload />} />
          <Route
            path="/superadmin/schoolteamlist"
            element={<SchoolTeamListAdmin />}
          />
          <Route
            path="/superadmin/schoolteamupload"
            element={<Schoolteamupload />}
          />
          <Route
            path="/superadmin/assessmentupload"
            element={<AssessmentAdmin />}
          />
          <Route
            path="/superadmin/assessmentdetail/:id"
            element={<AssessmentDetailAdmin />}
          />
          <Route
            path="/superadmin/assessmentplayerdetails/:id/:eid"
            element={<AssessmentPlayerDetAdmin />}
          />

          <Route
            path="/superadmin/resultupload/:id/:eid"
            element={<UploadResult />}
          />
          {/*Superadmin Upload */}

          {/*Superadmin Templates */}
          <Route path="/superadmin/sendfilelinks" element={<SendImage />} />
          <Route
            path="/superadmin/tournamentregister"
            element={<Tourconfirm />}
          />
          <Route path="/superadmin/registeration" element={<Registeration />} />
          <Route
            path="/superadmin/forgotpassword"
            element={<ForgotTemplate />}
          />
          <Route path="/superadmin/blocked" element={<BlockedTemplate />} />
          <Route
            path="/superadmin/citymanagerregister"
            element={<CRMRegisterTemplate />}
          />
          <Route
            path="/superadmin/citymanagerassign"
            element={<CRMTourAssign />}
          />
          <Route
            path="/superadmin/schoolheadregister"
            element={<SchHeadRegTemplate />}
          />
          <Route
            path="/superadmin/refereeregister"
            element={<RefRegisterTemplate />}
          />
          <Route
            path="/superadmin/refereeassign"
            element={<RefereeTourAssign />}
          />
          <Route
            path="/superadmin/tournamentpayment"
            element={<TourPaymentTemplate />}
          />
          <Route
            path="/superadmin/tournamentschedule"
            element={<TourSchedule />}
          />
          <Route
            path="/superadmin/tournamentremainder"
            element={<TourRemainder />}
          />
          <Route path="/superadmin/livestream" element={<LiveStreamLink />} />
          <Route path="/superadmin/tournamentphotos" element={<TourPhotos />} />
          <Route
            path="/superadmin/tournamentcertificate"
            element={<DownloadCertificate />}
          />

          <Route
            path="/superadmin/tournamentpartner"
            element={<NewPartnerTour />}
          />
          <Route
            path="/superadmin/tournamentschoolhead"
            element={<SchHeadAssign />}
          />
          <Route
            path="/superadmin/mergeplayers"
            element={<MergePlayersTemplate />}
          />
          <Route
            path="/superadmin/registersendlink"
            element={<RegistersendLinkTemplate />}
          />
          <Route
            path="/superadmin/schoolheadpaymentconfirmation"
            element={<SchoolheadConfirmation />}
          />
          <Route
            path="/superadmin/schoolheadsendtournament"
            element={<SchoolheadTournament />}
          />

          <Route
            path="/superadmin/whatsappsendfilelinks"
            element={<WSendImage />}
          />
          <Route
            path="/superadmin/whatsapptournamentregister"
            element={<WTourconfirm />}
          />
          <Route
            path="/superadmin/whatsappregisteration"
            element={<WRegisteration />}
          />
          <Route
            path="/superadmin/whatsappforgotpassword"
            element={<WForgotTemplate />}
          />
          <Route
            path="/superadmin/whatsappblocked"
            element={<WBlockedTemplate />}
          />
          <Route
            path="/superadmin/whatsappcitymanagerregister"
            element={<WCRMRegisterTemplate />}
          />
          <Route
            path="/superadmin/whatsappcitymanagerassign"
            element={<WCRMTourAssign />}
          />
          <Route
            path="/superadmin/whatsappschoolheadregister"
            element={<WSchHeadRegTemplate />}
          />
          <Route
            path="/superadmin/whatsapprefereeregister"
            element={<WRefRegisterTemplate />}
          />
          <Route
            path="/superadmin/whatsapprefereeassign"
            element={<WRefereeTourAssign />}
          />
          <Route
            path="/superadmin/whatsapptournamentpayment"
            element={<WPaymentTemplate />}
          />
          <Route
            path="/superadmin/whatsapptournamentschedule"
            element={<WTourSchedule />}
          />
          <Route
            path="/superadmin/whatsapptournamentremainder"
            element={<WTourRemainder />}
          />
          <Route
            path="/superadmin/whatsapplivestream"
            element={<WLiveStreamLink />}
          />
          <Route
            path="/superadmin/whatsapptournamentphotos"
            element={<WTourPhotos />}
          />
          <Route
            path="/superadmin/whatsapptournamentcertificate"
            element={<WDownloadCertificate />}
          />

          <Route
            path="/superadmin/whatsapptournamentpartner"
            element={<WNewPartnerTour />}
          />
          <Route
            path="/superadmin/whatsapptournamentschoolhead"
            element={<WSchHeadAssign />}
          />
          <Route
            path="/superadmin/whatsappmergeplayers"
            element={<WMergePlayersTemplate />}
          />
          <Route
            path="/superadmin/whatsappregistersendlink"
            element={<WRegisterSendLinkTemplate />}
          />
          <Route
            path="/superadmin/whatsappschoolheadpaymentconfirmation"
            element={<WSchoolheadConfirmation />}
          />
          <Route
            path="/superadmin/whatsappschoolheadsendtournament"
            element={<WSchoolheadTournament />}
          />

          {/*Superadmin Event Email Template */}
          <Route
            path="/superadmin/eventregister"
            element={<EventRegisterConfirm />}
          />
          <Route
            path="/superadmin/eventregisteration"
            element={<EventRegisteration />}
          />
          {/*Superadmin Event Email Template */}

          {/*Superadmin Event Whatsapp Template */}
          <Route
            path="/superadmin/whatsappeventregister"
            element={<WEventConfimation />}
          />
          <Route
            path="/superadmin/whatsappeventregisteration"
            element={<WEventRegisteration />}
          />
          {/*Superadmin Event Whatsapp Template */}

          {/*Superadmin Templates */}

          <Route path="/superadmin/datacube" element={<DataCube />} />
          <Route path="/superadmin/timespend" element={<Average />} />
          <Route path="/superadmin/payment" element={<PaymentList />} />
          <Route
            path="/superadmin/paymentdetail/:id"
            element={<Viewathlete />}
          />

          <Route path="/superadmin/bannerlist" element={<Bannerlist />} />
          <Route path="/superadmin/editbanner/:id" element={<Bannerupload />} />
          <Route path="/superadmin/bannerupload" element={<Bannerupload />} />

          <Route path="/player/dashboard" element={<AthleteDashboard />} />
          <Route path="/player/contact" element={<ContactList />} />

          {/*Player Profile lists */}
          <Route path="/player/profile" element={<PlayerProfile />} />
          <Route
            path="/player/overallperformanceplayer"
            element={<Performancebyplayer />}
          />
          <Route
            path="/player/viewperformanceplayer"
            element={<PerformancebyTour />}
          />

          {/*Player Profile lists */}
          <Route path="/player/changepassword" element={<PasswordChange />} />
          <Route path="/player/keyhighlights" element={<Achievements />} />
          <Route
            path="/player/achievements"
            element={<PlayerAchievementslist />}
          />
          <Route
            path="/player/achievementdetails/:id"
            element={<PlayerAchievementDetail />}
          />

          {/*Player Tournament lists */}
          <Route path="/player/tournamentlist" element={<ListTourPlayer />} />
          <Route
            path="/player/registeredorderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route
            path="/player/eligibleorderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route
            path="/player/orderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route path="/player/eventlist/:id" element={<OrderEventsList />} />
          <Route
            path="/player/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/player/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />

          <Route
            path="/player/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/player/registeredoverallchampion/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/player/registeredindividualchampion/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/player/eligibleoverallchampion/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/player/eligibleindividualchampion/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/player/registeredviewtournament/:id"
            element={<ViewTourPlayer />}
          />
          <Route
            path="/player/eligibleviewtournament/:id"
            element={<ViewTourPlayer />}
          />
          <Route
            path="/player/tournamentdetail/:id"
            element={<ViewTourPlayer />}
          />
          <Route
            path="/player/viewtournament/:id"
            element={<ViewTourPlayer />}
          />
          <Route path="/player/registeredlist" element={<ListRegPlayer />} />
          <Route path="/player/teamlist" element={<ListTeam />} />
          <Route
            path="/player/teamplayerlist/:id"
            element={<PlayerTeamList />}
          />
          <Route path="/player/myteams/:id" element={<PlayerTeamList />} />
          <Route path="/player/fixturelist/:id/:eid" element={<Schedule />} />

          <Route path="/player/schedulelist/:id/:eid" element={<Schedule />} />
          <Route path="/player/pointslist/:id/:eid" element={<PointsList />} />
          <Route
            path="/player/performanceplayerlist/:id"
            element={<SchoolPerformanceList />}
          />
          <Route path="/player/performancephotos/:id" element={<Photos />} />
          <Route
            path="/player/tournamentregister/:id"
            element={<AddTourPlayer />}
          />
          <Route path="/player/resultlist/:id" element={<PlayerResultList />} />
          <Route
            path="/player/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />

          <Route path="/player/photogallery" element={<PhotoGallery />} />
          <Route path="/player/photos/:id" element={<Photos />} />
          <Route path="/player/highlights" element={<HighlightsPlayer />} />
          <Route
            path="/player/highlightsdetail/:id"
            element={<HighlightDetailPlayer />}
          />

          <Route path="/player/certificates" element={<CertificatePlayer />} />

          <Route
            path="/player/othereventdetail/:id"
            element={<ViewWorkshop />}
          />

          <Route
            path="/player/externaltournamentdetail/:id"
            element={<ViewExternalTour />}
          />

          {/*Player Tournament lists */}
          <Route
            path="/player/assessmentlist"
            element={<AssessmentListPlayer />}
          />
          <Route
            path="/player/assessmentdetail/:id"
            element={<AssessmentDetail />}
          />

          <Route path="/player/notification" element={<Comingsoon />} />
          <Route path="/player/document" element={<Comingsoon />} />

          <Route path="/cityregional/dashboard" element={<CrmDashboard />} />
          <Route path="/cityregional/fundermanager" element={<SchoolCRM />} />

          <Route path="/cityregional/addfunder" element={<AddFunder />} />
          <Route path="/cityregional/listfunder" element={<ListFunder />} />

          <Route
            path="/cityregional/groupschoolmanager"
            element={<SchoolCRM />}
          />

          <Route
            path="/cityregional/changepassword"
            element={<PasswordChange />}
          />
          <Route path="/cityregional/contact" element={<ContactList />} />

          <Route
            path="/cityregional/addotherevent"
            element={<AddEventWorkshop />}
          />
          <Route
            path="/cityregional/editotherevent/:id"
            element={<AddEventWorkshop />}
          />
          <Route
            path="/cityregional/othereventlist"
            element={<ListWorkshop />}
          />
          <Route
            path="/cityregional/othereventdetail/:id"
            element={<ViewWorkshop />}
          />
          <Route
            path="/cityregional/othereventdetails/:id"
            element={<ViewWorkshop />}
          />
          <Route
            path="/superadmin/eventsentrylist/:id"
            element={<EventEntryplayerslist />}
          />

          {/*Cityregional Tournament Lists */}
          <Route path="/cityregional/addtournament" element={<AddTour />} />
          <Route
            path="/cityregional/athleticsagecategory/:id"
            element={<AddAgeCategory />}
          />
          <Route
            path="/cityregional/tournamentagecategorylist/:id"
            element={<AgeCategoryList />}
          />
          <Route
            path="/cityregional/tournamentclassagecategorylist/:id"
            element={<ClassAgeCategoryList />}
          />
          <Route
            path="/cityregional/agecategorybyevent/:id/:eid"
            element={<EventAgeCategory />}
          />
          <Route
            path="/cityregional/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/cityregional/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/cityregional/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />
          <Route
            path="/cityregional/eventstartlist/:id/:eid"
            element={<OrderEventsStartList />}
          />

          <Route
            path="/cityregional/tournamentagecategory/:id"
            element={<Agecatetour />}
          />
          <Route
            path="/cityregional/tournamentplayer/:id"
            element={<Viewathlete />}
          />
          <Route path="/cityregional/tournamentlist" element={<ListTour />} />
          <Route
            path="/cityregional/tournamentdetails/:id"
            element={<ViewTour />}
          />
          <Route
            path="/cityregional/tournamentdetail/:id"
            element={<ViewTour />}
          />
          <Route
            path="/cityregional/edittournament/:id"
            element={<EditTour />}
          />
          <Route
            path="/cityregional/entryplayerslist/:id"
            element={<Entryplayerslist />}
          />
          <Route path="/cityregional/refundlist/:id" element={<RefundList />} />
          <Route
            path="/cityregional/eventrefundlist/:id"
            element={<RefundList />}
          />
          <Route
            path="/cityregional/athleticsrefundlist/:id"
            element={<RefundList />}
          />

          <Route
            path="/cityregional/athleticsentryplayerslist/:id"
            element={<AthleticsEntryplayerslist />}
          />
          <Route
            path="/cityregional/offlineentry/:id"
            element={<OfflineEntry />}
          />
          <Route
            path="/cityregional/athleticschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/cityregional/tournamentschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/cityregional/playerlist/:id/:eid"
            element={<AddedPlayersList />}
          />
          <Route
            path="/cityregional/addathletes/:id/:eid"
            element={<Addathlete />}
          />
          <Route
            path="/cityregional/summary/:id/:eid"
            element={<SummaryPlayers />}
          />
          <Route
            path="/cityregional/confirmation/:id/:eid"
            element={<ConfirmationPlayer />}
          />
          <Route
            path="/cityregional/orderofeventlist/:id"
            element={<OrderEventsList />}
          />

          <Route
            path="/cityregional/results/:id"
            element={<ResultScheduleList />}
          />
          <Route
            path="/cityregional/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />

          <Route
            path="/cityregional/resultlist/:id/:eid"
            element={<ResultScheduleList />}
          />
          <Route
            path="/cityregional/certificatelist/:id"
            element={<GenerateCertificate />}
          />
          <Route
            path="/cityregional/uploadcertificate/:id"
            element={<CertUpload />}
          />
          <Route path="/cityregional/analytics/:id" element={<Analytics />} />

          <Route
            path="/cityregional/teamlist/:id/:tid"
            element={<TeamList />}
          />
          <Route
            path="/cityregional/teamcreate/:id/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/cityregional/categorylist/:id"
            element={<CategoryList />}
          />
          <Route
            path="/cityregional/performancelist/:id/:eid"
            element={<PerformanceList />}
          />
          <Route
            path="/cityregional/addperformance/:id/:eid"
            element={<PerformancePlayerPhotoAdd />}
          />
          <Route
            path="/cityregional/performanceplayerphotoedit/:id/:tid/:eid"
            element={<PerformancePlayerPhotoEdit />}
          />
          <Route
            path="/cityregional/pointslist/:id/:eid"
            element={<PointsList />}
          />
          <Route
            path="/cityregional/achievements"
            element={<Achievementslist />}
          />
          <Route
            path="/cityregional/achievementlist"
            element={<UpAchievementslist />}
          />
          <Route
            path="/cityregional/achievementindividual"
            element={<AchievementUpload />}
          />
          <Route
            path="/cityregional/achievementsupload"
            element={<AchievementUpload />}
          />
          <Route
            path="/cityregional/teamdetails/:id/:tid/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/cityregional/teamplayercreate/:id/:tid/:eid"
            element={<TeamPlayerCreate />}
          />
          <Route
            path="/cityregional/teamplayerlist/:id/:tid/:eid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/cityregional/schedulelist/:id/:eid"
            element={<Schedule />}
          />
          <Route
            path="/cityregional/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          <Route
            path="/cityregional/importfixture/:id/:eid/:tid"
            element={<PoolTourupload />}
          />
          <Route
            path="/cityregional/poollist/:id/:eid"
            element={<SchedulePoolList />}
          />
          <Route
            path="/cityregional/scheduleteam/:id/:tid/:eid"
            element={<ScheduleTeamPlayerCreate />}
          />
          <Route
            path="/cityregional/photoupload/:id"
            element={<PhotoUpload />}
          />
          <Route path="/cityregional/photogallery" element={<PhotoGallery />} />
          <Route path="/cityregional/photos/:id" element={<Photos />} />
          <Route path="/cityregional/highlights/:id" element={<Highlights />} />
          <Route
            path="/cityregional/leaderboard/:id"
            element={<Leaderboard />}
          />

          <Route
            path="/cityregional/addexternaltournament"
            element={<AddExternalTour />}
          />
          <Route
            path="/cityregional/editexternaltournament/:id"
            element={<AddExternalTour />}
          />

          <Route
            path="/cityregional/externaltournamentlist"
            element={<ListExternalTour />}
          />
          <Route
            path="/cityregional/externaltournamentdetails/:id"
            element={<ViewExternalTour />}
          />
          <Route
            path="/cityregional/externaltournamentdetail/:id"
            element={<ViewExternalTour />}
          />
          {/*Cityregional Tournament Lists */}

          {/*Cityregional Player Lists */}
          <Route path="/cityregional/addplayers" element={<AddPlayers />} />
          <Route path="/cityregional/allplayers" element={<AllPlayers />} />
          <Route
            path="/cityregional/viewplayer/:id"
            element={<Viewathlete />}
          />
          <Route
            path="/cityregional/overallperformanceplayer/:id"
            element={<Performancebyplayer />}
          />
          <Route
            path="/cityregional/viewperformanceplayer/:id"
            element={<PerformancebyTour />}
          />
          {/*Cityregional Player Lists */}

          {/*Cityregional My Profile View */}
          <Route path="/cityregional/profile" element={<Profile />} />
          <Route path="/cityregional/editprofile" element={<EditCRM />} />

          {/*Cityregional My Profile View */}

          {/*Cityregional Referee Lists */}
          <Route
            path="/cityregional/refereelist"
            element={<AdminRefereeList />}
          />
          <Route
            path="/cityregional/refereedetail/:id"
            element={<ViewReferee />}
          />
          {/*Cityregional Referee Lists */}

          {/*Cityregional School Head Lists */}
          <Route
            path="/cityregional/addschoolhead"
            element={<AddSchoolHead />}
          />
          <Route
            path="/cityregional/schoolheadlist"
            element={<ListSchoolHead />}
          />
          <Route
            path="/cityregional/viewschoolhead/:id"
            element={<ViewSchoolHead />}
          />
          <Route
            path="/cityregional/schoolheaddetail/:id"
            element={<EditSchoolHead />}
          />

          <Route path="/cityregional/addschool" element={<CityAddSchool />} />
          <Route
            path="/cityregional/schooldetails/:id"
            element={<CityAddSchool />}
          />
          <Route path="/cityregional/schoollist" element={<CityListSchool />} />

          <Route path="/cityregional/cmsdetails" element={<SchoolList />} />
          <Route path="/cityregional/addcms/:id" element={<CMSDetails />} />

          <Route
            path="/cityregional/funderdetails"
            element={<FunderCMSList />}
          />
          <Route
            path="/cityregional/addfundercms/:id"
            element={<FunderCMSDetails />}
          />

          <Route
            path="/cityregional/groupofschooldetails"
            element={<GroupCMSList />}
          />
          <Route
            path="/cityregional/addgroupofschoolcms/:id"
            element={<FunderCMSDetails />}
          />

          {/*Cityregional School Head Lists */}

          <Route path="/schoolhead/dashboard" element={<SchoolDashboard />} />
          <Route path="/schoolhead/contact" element={<ContactList />} />

          {/*SchoolHead Player Lists */}
          <Route path="/schoolhead/addplayers" element={<AddPlayers />} />
          <Route path="/schoolhead/allplayers" element={<AllPlayers />} />
          <Route path="/schoolhead/viewplayer/:id" element={<Viewathlete />} />
          <Route
            path="/schoolhead/overallperformanceplayer/:id"
            element={<Performancebyplayer />}
          />
          <Route
            path="/schoolhead/viewperformanceplayer/:id"
            element={<PerformancebyTour />}
          />

          <Route
            path="/schoolhead/tournamentplayer/:id"
            element={<Viewathlete />}
          />

          {/*SchoolHead Player Lists */}

          {/*SchoolHead My Profile View */}
          <Route path="/schoolhead/profile" element={<Profile />} />
          <Route path="/schoolhead/editprofile" element={<EditSchoolHead />} />
          <Route
            path="/schoolhead/changepassword"
            element={<PasswordChange />}
          />

          {/*SchoolHead My Profile View */}

          {/*SchoolHead Tournament Lists */}
          <Route path="/schoolhead/tournamentlist" element={<ListTour />} />
          <Route
            path="/schoolhead/completedtournament"
            element={<CompletedListTour />}
          />

          <Route
            path="/schoolhead/tournamentagecategorylist/:id"
            element={<AgeCategoryList />}
          />
          <Route
            path="/schoolhead/tournamentclassagecategorylist/:id"
            element={<ClassAgeCategoryList />}
          />
          <Route
            path="/schoolhead/agecategorybyevent/:id/:eid"
            element={<EventAgeCategory />}
          />
          <Route
            path="/schoolhead/playerlist/:id"
            element={<AddedPlayersList />}
          />
          <Route
            path="/schoolhead/interschoolpaymentlist/:id"
            element={<InterschoolPayment />}
          />
          <Route
            path="/schoolhead/teamcreate/:id/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/schoolhead/teamdetails/:id/:tid/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/schoolhead/teamplayerupload/:id/:eid/:tid"
            element={<OfflineEntry />}
          />
          <Route path="/schoolhead/addathletes/:id" element={<Addathlete />} />
          <Route path="/schoolhead/summary/:id" element={<SummaryPlayers />} />
          <Route
            path="/schoolhead/performanceplayerlist/:id"
            element={<SchoolPerformanceList />}
          />
          <Route
            path="/schoolhead/performancephotos/:id"
            element={<Photos />}
          />

          <Route
            path="/schoolhead/confirmation/:id"
            element={<ConfirmationPlayer />}
          />
          <Route
            path="/schoolhead/orderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route
            path="/schoolhead/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/schoolhead/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />

          <Route
            path="/schoolhead/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/schoolhead/overallchampion/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/schoolhead/individualchampion/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/schoolhead/eventstartlist/:id/:eid"
            element={<OrderEventsStartList />}
          />

          <Route
            path="/schoolhead/achievementtournament/:id"
            element={<ViewTourSchHead />}
          />
          <Route
            path="/schoolhead/tournamentdetails/:id"
            element={<ViewTourSchHead />}
          />

          <Route
            path="/schoolhead/categorylist/:id"
            element={<CategoryList />}
          />
          <Route
            path="/schoolhead/intercategorylist/:id"
            element={<CategoryList />}
          />
          <Route
            path="/schoolhead/pointslist/:id/:eid"
            element={<PointsList />}
          />

          <Route
            path="/schoolhead/entryplayerslist/:id"
            element={<Entryplayerslist />}
          />
          <Route
            path="/schoolhead/athleticsentryplayerslist/:id"
            element={<AthleticsEntryplayerslist />}
          />

          <Route path="/schoolhead/photoupload/:id" element={<PhotoUpload />} />

          <Route
            path="/schoolhead/schedulelist/:id/:eid"
            element={<Schedule />}
          />
          <Route
            path="/schoolhead/results/:id"
            element={<ResultScheduleList />}
          />
          <Route
            path="/schoolhead/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />

          <Route path="/schoolhead/analytics/:id" element={<Analytics />} />
          <Route
            path="/schoolhead/certificatelist/:id"
            element={<GenerateCertificate />}
          />
          <Route
            path="/schoolhead/resultlist/:id/:eid"
            element={<ResultScheduleList />}
          />
          <Route path="/schoolhead/epllist" element={<Comingsoon />} />
          <Route path="/schoolhead/photogallery" element={<PhotoGallery />} />
          <Route path="/schoolhead/photos/:id" element={<Photos />} />
          <Route path="/schoolhead/highlights/:id" element={<Highlights />} />
          <Route path="/schoolhead/leaderboard/:id" element={<Leaderboard />} />

          <Route path="/schoolhead/teamlist/:id/:tid" element={<TeamList />} />

          <Route
            path="/schoolhead/teamplayerlist/:id/:tid/:eid"
            element={<TeamPlayerList />}
          />

          <Route
            path="/schoolhead/poollist/:id/:eid"
            element={<SchedulePoolList />}
          />
          <Route
            path="/schoolhead/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          {/*SchoolHead Tournament Lists */}

          {/* <Route path="/schoolhead/profile" element={<SHProfile />} /> */}
          <Route path="/schoolhead/fittestlist" element={<FittestList />} />
          <Route
            path="/schoolhead/fittesttoplist"
            element={<FittestTopList />}
          />
          <Route
            path="/schoolhead/fittestbottomlist"
            element={<FitBottomList />}
          />

          <Route
            path="/schoolhead/schoolvsnational"
            element={<SchoolNational />}
          />
          <Route path="/schoolhead/schoolvsstate" element={<SchoolState />} />
          <Route
            path="/schoolhead/fitnesstrends"
            element={<FitnessGenderList />}
          />
          <Route
            path="/schoolhead/genderfitnesstrends"
            element={<FitnessGenderList />}
          />

          <Route path="/schoolhead/keyhighlights" element={<KeyHighlights />} />
          <Route
            path="/schoolhead/schoolteamlist"
            element={<SchoolTeamPlayers />}
          />
          <Route
            path="/schoolhead/achievementslist"
            element={<Achievementslist />}
          />
          <Route
            path="/schoolhead/othereventdetail/:id"
            element={<ViewWorkshop />}
          />
          <Route
            path="/schoolhead/upcomingeventlist"
            element={<UpcomingListEvent />}
          />
          <Route
            path="/schoolhead/completedeventslist"
            element={<CompletedListEvent />}
          />
          <Route
            path="/schoolhead/eventsentrylist/:id"
            element={<EventEntryplayerslist />}
          />

          <Route path="/schoolhead/addcoach" element={<AddCoach />} />
          <Route path="/schoolhead/coacheslist" element={<ListCoach />} />
          <Route path="/schoolhead/coachdetail/:id" element={<EditCoach />} />
          <Route path="/schoolhead/viewcoaches/:id" element={<ViewCoaches />} />

          <Route
            path="/schoolhead/externaltournamentdetail/:id"
            element={<ViewExternalTour />}
          />

          {/* <Route path="/schoolhead/otherachievements" element={<Achievementslist />} /> */}

          <Route path="/referee/dashboard" element={<RefDashboard />} />
          <Route path="/referee/contact" element={<ContactList />} />

          {/*Referee My Profile View */}
          <Route path="/referee/profile" element={<Profile />} />
          <Route path="/referee/changepassword" element={<PasswordChange />} />

          <Route path="/referee/editprofile" element={<EditReferee />} />

          {/*Referee My Profile View */}

          {/*Referee Tournament Lists */}

          <Route path="/referee/categorylist/:id" element={<CategoryList />} />
          <Route path="/referee/pointslist/:id/:eid" element={<PointsList />} />
          <Route path="/referee/analytics/:id" element={<Analytics />} />

          <Route
            path="/referee/poollist/:id/:eid"
            element={<SchedulePoolList />}
          />
          <Route path="/referee/tournamentlist" element={<ListTour />} />
          <Route
            path="/referee/athleticschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/referee/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/referee/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/referee/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />

          <Route
            path="/referee/tournamentagecategorylist/:id"
            element={<AgeCategoryList />}
          />
          <Route
            path="/referee/tournamentclassagecategorylist/:id"
            element={<ClassAgeCategoryList />}
          />
          <Route
            path="/referee/agecategorybyevent/:id/:eid"
            element={<EventAgeCategory />}
          />
          <Route path="/referee/tournamentdetails/:id" element={<ViewTour />} />
          <Route path="/referee/teamlist/:id/:tid" element={<TeamList />} />

          <Route
            path="/referee/teamplayerlist/:id/:tid/:eid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/referee/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          <Route
            path="/referee/entryplayerslist/:id"
            element={<Entryplayerslist />}
          />
          <Route
            path="/referee/athleticsentryplayerslist/:id"
            element={<AthleticsEntryplayerslist />}
          />
          <Route path="/referee/results/:id" element={<ResultScheduleList />} />
          <Route
            path="/referee/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />
          <Route
            path="/referee/orderofeventlist/:id"
            element={<OrderEventsList />}
          />
          <Route
            path="/referee/eventstartlist/:id/:eid"
            element={<OrderEventsStartList />}
          />

          <Route
            path="/referee/resultlist/:id/:eid"
            element={<ResultScheduleList />}
          />
          <Route path="/referee/schedulelist/:id/:eid" element={<Schedule />} />
          <Route path="/referee/photoupload/:id" element={<PhotoUpload />} />
          <Route path="/referee/highlights/:id" element={<Highlights />} />
          <Route path="/referee/leaderboard/:id" element={<Leaderboard />} />

          {/*Referee Tournament Lists */}

          <Route path="/coach/dashboard" element={<CoachDashboard />} />
          <Route path="/coach/changepassword" element={<PasswordChange />} />
          <Route path="/coach/contact" element={<ContactList />} />

          <Route path="/coach/profile" element={<Profile />} />
          <Route path="/coach/addplayers" element={<AddPlayers />} />
          <Route path="/coach/allplayers" element={<AllPlayers />} />
          <Route path="/coach/viewplayer/:id" element={<Viewathlete />} />
          <Route
            path="/coach/overallperformanceplayer/:id"
            element={<Performancebyplayer />}
          />
          <Route
            path="/coach/viewperformanceplayer/:id"
            element={<PerformancebyTour />}
          />

          <Route path="/coach/editprofile" element={<EditCoach />} />
          {/*Coach Tournament Lists */}
          <Route path="/coach/addtournament" element={<AddTour />} />

          <Route
            path="/coach/athleticsagecategory/:id"
            element={<AddAgeCategory />}
          />
          <Route
            path="/coach/tournamentagecategorylist/:id"
            element={<AgeCategoryList />}
          />
          <Route
            path="/coach/tournamentclassagecategorylist/:id"
            element={<ClassAgeCategoryList />}
          />
          <Route
            path="/coach/agecategorybyevent/:id/:eid"
            element={<EventAgeCategory />}
          />
          <Route
            path="/coach/overallchampionlist/:id"
            element={<AdminOverallChampionlist />}
          />
          <Route
            path="/coach/individualchampionlist/:id"
            element={<AdminIndividualChampionlist />}
          />
          <Route
            path="/coach/agecategorychampionlist/:id/:eid"
            element={<AdminAgeCategoryChampionlist />}
          />
          <Route
            path="/coach/eventstartlist/:id/:eid"
            element={<OrderEventsStartList />}
          />

          <Route
            path="/coach/tournamentagecategory/:id"
            element={<Agecatetour />}
          />
          <Route path="/coach/tournamentplayer/:id" element={<Viewathlete />} />
          <Route path="/coach/tournamentlist" element={<ListTour />} />

          <Route path="/coach/tournamentdetails/:id" element={<ViewTour />} />
          <Route
            path="/coach/addexternaltournament"
            element={<AddExternalTour />}
          />
          <Route
            path="/coach/editexternaltournament/:id"
            element={<AddExternalTour />}
          />

          <Route
            path="/coach/externaltournamentlist"
            element={<ListExternalTour />}
          />
          <Route
            path="/coach/externaltournamentdetails/:id"
            element={<ViewExternalTour />}
          />

          <Route path="/coach/edittournament/:id" element={<EditTour />} />
          <Route
            path="/coach/entryplayerslist/:id"
            element={<Entryplayerslist />}
          />
          <Route path="/coach/refundlist/:id" element={<RefundList />} />
          <Route path="/coach/eventrefundlist/:id" element={<RefundList />} />
          <Route
            path="/coach/athleticsrefundlist/:id"
            element={<RefundList />}
          />

          <Route
            path="/coach/athleticsentryplayerslist/:id"
            element={<AthleticsEntryplayerslist />}
          />
          <Route path="/coach/offlineentry/:id" element={<OfflineEntry />} />
          <Route
            path="/coach/athleticschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/coach/tournamentschoollist/:id"
            element={<SchoolTourlist />}
          />
          <Route
            path="/coach/playerlist/:id/:eid"
            element={<AddedPlayersList />}
          />
          <Route path="/coach/addathletes/:id/:eid" element={<Addathlete />} />
          <Route path="/coach/summary/:id/:eid" element={<SummaryPlayers />} />
          <Route
            path="/coach/confirmation/:id/:eid"
            element={<ConfirmationPlayer />}
          />
          <Route
            path="/coach/orderofeventlist/:id"
            element={<OrderEventsList />}
          />

          <Route path="/coach/results/:id" element={<ResultScheduleList />} />
          <Route
            path="/coach/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />

          <Route
            path="/coach/resultlist/:id/:eid"
            element={<ResultScheduleList />}
          />
          <Route
            path="/coach/certificatelist/:id"
            element={<GenerateCertificate />}
          />
          <Route path="/coach/uploadcertificate/:id" element={<CertUpload />} />
          <Route path="/coach/analytics/:id" element={<Analytics />} />

          <Route path="/coach/teamlist/:id/:tid" element={<TeamList />} />
          <Route path="/coach/offlineentry/:id" element={<OfflineEntry />} />
          <Route
            path="/coach/interteamlist/:id/:tid/:eid"
            element={<TeamList />}
          />
          <Route path="/coach/externalteamlist/:id" element={<TeamList />} />

          <Route path="/coach/teamcreate/:id/:eid" element={<TeamCreate />} />
          <Route
            path="/coach/externalteamcreate/:id"
            element={<TeamCreate />}
          />

          <Route path="/coach/categorylist/:id" element={<CategoryList />} />
          <Route
            path="/coach/intercategorylist/:id/:tid"
            element={<CategoryList />}
          />
          <Route
            path="/coach/performancelist/:id/:eid"
            element={<PerformanceList />}
          />
          <Route
            path="/coach/addperformance/:id/:eid"
            element={<PerformancePlayerPhotoAdd />}
          />
          <Route
            path="/coach/performanceplayerphotoedit/:id/:tid/:eid"
            element={<PerformancePlayerPhotoEdit />}
          />
          <Route path="/coach/pointslist/:id/:eid" element={<PointsList />} />
          <Route path="/coach/achievements" element={<Achievementslist />} />
          <Route
            path="/coach/achievementsupload"
            element={<AchievementUpload />}
          />
          <Route
            path="/coach/achievementindividual"
            element={<AchievementUpload />}
          />
          <Route
            path="/coach/teamdetails/:id/:tid/:eid"
            element={<TeamCreate />}
          />
          <Route
            path="/coach/externalteamdetails/:id/:tid"
            element={<TeamCreate />}
          />
          <Route
            path="/coach/teamplayercreate/:id/:tid/:eid"
            element={<TeamPlayerCreate />}
          />
          <Route
            path="/coach/teamplayerlist/:id/:tid/:eid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/coach/externalteamplayerlist/:id/:tid"
            element={<TeamPlayerList />}
          />
          <Route
            path="/coach/interteamplayerlist/:id/:tid/:eid/:sid"
            element={<TeamPlayerList />}
          />
          <Route path="/coach/schedulelist/:id/:eid" element={<Schedule />} />
          <Route
            path="/coach/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          <Route
            path="/coach/importfixture/:id/:eid/:tid"
            element={<PoolTourupload />}
          />
          <Route
            path="/coach/poollist/:id/:eid"
            element={<SchedulePoolList />}
          />
          <Route
            path="/coach/scheduleteam/:id/:tid/:eid"
            element={<ScheduleTeamPlayerCreate />}
          />
          <Route path="/coach/photoupload/:id" element={<PhotoUpload />} />
          <Route path="/coach/photogallery" element={<PhotoGallery />} />
          <Route path="/coach/photos/:id" element={<Photos />} />
          <Route path="/coach/highlights/:id" element={<Highlights />} />
          <Route path="/coach/leaderboard/:id" element={<Leaderboard />} />

          <Route
            path="/coach/individualperformance/:id/:eid"
            element={<IndPerformanceList />}
          />
          <Route
            path="/coach/externalindividualperformance/:id/:eid"
            element={<IndPerformanceList />}
          />
          <Route
            path="/coach/schoolteamplayerlist"
            element={<SchoolTeamPlayers />}
          />
          <Route
            path="/coach/schoolteamlist"
            element={<SchoolTeamListAdmin />}
          />
          <Route
            path="/coach/schoolteamupload"
            element={<Schoolteamupload />}
          />
          <Route
            path="/coach/achievementlist"
            element={<UpAchievementslist />}
          />
          <Route
            path="/coach/achievementsupload"
            element={<AchievementUpload />}
          />

          <Route path="/coach/addotherevent" element={<AddEventWorkshop />} />
          <Route
            path="/coach/editotherevent/:id"
            element={<AddEventWorkshop />}
          />
          <Route path="/coach/othereventlist" element={<ListWorkshop />} />

          <Route
            path="/coach/othereventdetail/:id"
            element={<ViewWorkshop />}
          />
          <Route
            path="/coach/othereventdetails/:id"
            element={<ViewWorkshop />}
          />
          {/* <Route path="/coach/cmsdetails" element={<SchoolList />} /> */}
          <Route path="/coach/addcms" element={<CMSDetails />} />

          {/*Coach Tournament Lists */}

          {/* Funder Dashboard */}
          <Route path="/funder/dashboard" element={<FunderDashboard />} />
          <Route path="/funder/schoollist" element={<SchoolList />} />
          {/* <Route path="/funder/allplayers/:id" element={<AllPlayers />} />*/}
          <Route path="/funder/viewplayer/:id" element={<Viewathlete />} /> 
          <Route path="/funder/cmsdetails" element={<SchoolList />} />
          <Route
            path="/funder/addfundercms/:id"
            element={<TempFunderCMSDetails />}
          />
          <Route path="/funder/tournamentlist" element={<ListTour />} />
          <Route path="/funder/tournamentdetails/:id" element={<ViewTour />} />
          {/* <Route path="/funder/categorylist/:id" element={<CategoryList />} /> */}
          {/* <Route
            path="/funder/poollist/:id/:eid"
            element={<SchedulePoolList />}
          /> */}
          {/* <Route
            path="/funder/scheduleallocate/:id/:eid"
            element={<ScheduleAllocate />}
          />
          <Route path="/funder/schedulelist/:id/:eid" element={<Schedule />} />
          <Route path="/funder/pointslist/:id/:eid" element={<PointsList />} /> */}
          <Route path="/funder/results/:id" element={<ResultScheduleList />} />
          <Route
            path="/funder/athleticsresults/:id"
            element={<AdminAthleticsResultlist />}
          />
          <Route path="/funder/keyhighlights" element={<KeyHighlights />} />
          <Route
            path="/funder/achievementlist"
            element={<Achievementslist />}
          />
          <Route
            path="/funder/benificiarieslist"
            element={<Achievementslist />}
          />
           <Route
            path="/funder/assessmentdetail/:id/:eid"
            element={<AssessmentDetail />}
          />
          <Route
            path="/funder/othereventdetail/:id"
            element={<ViewWorkshop />}
          />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
