import React, { Fragment, useEffect, useMemo, useState, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  adminathleticsresultlist,
  athleticsdeleteresult,
} from "../../../../../services/common";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { coachtourviewdetail } from "../../../../../services/coach";

export const AdminAthleticsResultlist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => resdelete(params.value, params.data)}
              title="Edit"
              className="btn-sm btn-danger me-2"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 150,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Heats/Finals",
      width: 150,
      field: "heats_finals",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Place",
      width: 150,
      field: "place",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 150,
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 150,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnSDefs = [
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 150,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Heats/Finals",
      width: 150,
      field: "heats_finals",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Place",
      width: 150,
      field: "place",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Time/Distance",
      width: 150,
      field: "time_distance",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 150,
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function resdelete(rid, rdata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in result list for " +
            rdata.full_name +
            "- " +
            rdata.username +
            " (" +
            rid +
            ") in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.resultheading
        );
        athleticsdeleteresult(rid).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listathletics();
          }
        });
      }
    });
  }
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 
    listathletics();
  }, []);

  function listathletics() {
    setFilterProduct([]);
    adminathleticsresultlist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.details);
      }
    });
  }

  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "back button in results list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
    }

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in results list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in results list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/tournamentlist");
    } 
  }

  function overchamlist() {
    dotracking(
      "overall champions button in results list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.overallchampionheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/overallchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/overallchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/overallchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/overallchampionlist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/overallchampionlist/" + id);
    }
  }

  function indichamlist() {
    dotracking(
      "individual champions button in results list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.individualchampionheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/individualchampionlist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/individualchampionlist/" + id);
    } 
  }
  const gridRef = useRef();
  function importorder() {
    dotracking(
      "export button in result list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.resultuploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/athleticsresultupload/" + id);
    }
  }


  const exportlist = useCallback(() => {
    var excelParams = {
      allColumns: false,
      fileName: "Result List.csv",
    };
    
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Result List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Result List
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="text-center">
                    <h3 className="mb-2">{tname}</h3>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="text-right">
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") && (
                      <a
                        className="btn btn-export me-2"
                        onClick={importorder}
                        title="Import Result"
                      >
                        <i className="fa-solid fa-file-import"></i> Import
                      </a>
                    )}
                    {/* <a className="btn btn-secondary me-2" onClick={exportlist}>
                      <i className="fa-solid fa-file-export"></i> Export
                    </a> */}
                    <a
                      onClick={overchamlist}
                      className="btn btn-secondary me-2"
                    >
                      <i className="fa fa-list"></i> Overall Champions
                    </a>
                    <a onClick={indichamlist} className="btn btn-import me-2">
                      <i className="fa fa-list"></i> Individual Champions
                    </a>
                    <a onClick={backlist} className="btn btn-gray">
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  {localStorage.getItem("Role") === "1" && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      // onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}

                  {localStorage.getItem("Role") !== "1" && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnSDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      // onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
