import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  assessmentdatedetail,
  assessmentplayerlist,
  gettokenfromapi,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { sahassessmentdatelist } from "../../../../services/sahapi";

export const AssessmentPlayerDetAdmin = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sfilterProduct, setSFilterProduct] = useState([]);

  const { id, eid } = useParams();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Name",
      field: "student_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
    {
      headerName: "Class",
      field: "std",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      field: "section",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "height",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "School",
      field: "school_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event Name",
      field: "name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Theme",
      field: "fst_theme_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      field: "grade",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade(Integer)",
      field: "grade_as_int",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Score",
      field: "score",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Units",
      field: "units",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "FQ",
      field: "fq",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "SQ",
      field: "sq",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "TQ",
      field: "tq",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Assessment Grade",
    //   field: "assessment_type_overall_grade",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Assessment Grade(Integer)",
    //   field: "assessment_type_overall_grade_as_int",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
  
    {
      headerName: "Overall Grade",
      field: "overall_grade",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Overall Grade(Integer)",
      field: "overall_grade_as_int",
      width: 180,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Average Score",
    //   field: "all_india_avg_score",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
  ];

  const columnSDefs = [
    {
      headerName: "Name",
      field: "student_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      field: "std",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      field: "section",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "height",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "School",
      field: "school_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Skill Name",
      field: "name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Theme",
      field: "fst_theme_name",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      field: "grade",
      width: 100,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade(Integer)",
      field: "grade_as_int",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    // {
    //   headerName: "Assessment Grade",
    //   field: "assessment_type_overall_grade",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Assessment Grade(Integer)",
    //   field: "assessment_type_overall_grade_as_int",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },

    {
      headerName: "Overall Grade",
      field: "overall_grade",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Overall Grade(Integer)",
      field: "overall_grade_as_int",
      width: 180,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const [assname, setAssName] = useState("");
  const [assdate, setAssDate] = useState("");

  useEffect(() => {
    // const temp1 = new FormData();
    // temp1.append("date_id", id);
    // assessmentdatedetail(temp1).then((response) => {
    //   if (response.status === true) {
    //     setAssName(response.data.assessmentDate.assessment_heading);
    //     setAssDate(response.data.assessmentDate.assessment_date);

    //   }
    // });

    // const temp = new FormData();
    // temp.append("assessment_group_id", eid);
    // assessmentplayerlist(temp).then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data.fitness_assessment_details)
    //     setSFilterProduct(response.data.skill_assessment_details)

    //   }
    // });

    const temp = new FormData();
    temp.append("data_req", "asmnt_data_within_date_range" + id + "010");
    gettokenfromapi(temp).then((res) => {
      if (res.status === true) {
        sahassessmentdatelist(
          "asmnt_data_within_date_range&chid=&player_name=&asmnt_date=" +
            id +
            "&start_limit=" +
            0 +
            "&end_limit=" +
            10,
          res.data
        ).then((response) => {
          if(response.length !== 0){
            for(let i = 0; i < response.length; i++){
              if(response[i].esid === eid && response[i].date===id){
                let fdata=""
                fdata=response[i].assmts.find((item)=>{
                  return item.type === "fitness"
                })

                let sdata=""
                sdata=response[i].assmts.find((item)=>{
                  return item.type === "skill"
                })
                let arr=[];
                if(fdata !== undefined){
                  for(let j = 0; j < fdata.attributes.length; j++){
                    arr.push({
                      student_name: response[i].student_name,
                      std: response[i].std,
                      section: response[i].section,
                      height: response[i].height,
                      weight: response[i].weight,
                      school_name: response[i].school_name,
                      name: fdata.attributes[j].name,
                      fst_theme_name: fdata.attributes[j].fst_theme_name,
                      grade: fdata.attributes[j].grade,
                      grade_as_int: fdata.attributes[j].grade_as_int,
                      fq:fdata.fq,
                      sq: fdata.sq,
                      tq: fdata.tq,
                      units: fdata.attributes[j].units,
                      overall_grade: fdata.overall_grade,
                      overall_grade_as_int: fdata.overall_grade_as_int,
                      score: fdata.attributes[j].score,
                    })
                  }
                }
               
                
                setFilterProduct(arr)
                let arr1=[];
                if(sdata !== undefined){
                  for(let j = 0; j < sdata.attributes.length; j++){
                    arr1.push({
                      student_name: response[i].student_name,
                      std: response[i].std,
                      section: response[i].section,
                      height: response[i].height,
                      weight: response[i].weight,
                      school_name: response[i].school_name,
                      name: sdata.attributes[j].name,
                      fst_theme_name: sdata.attributes[j].fst_theme_name,
                      grade: sdata.attributes[j].grade,
                      grade_as_int: sdata.attributes[j].grade_as_int,
                      fq:sdata.fq,
                      sq: sdata.sq,
                      tq: sdata.tq,
                      units: sdata.attributes[j].units,
                      overall_grade: sdata.overall_grade,
                      overall_grade_as_int: sdata.overall_grade_as_int,
                      score: sdata.attributes[j].score,
                    })
                  }
                }
                setSFilterProduct(arr1)
                // setAssName(response[i].student_name);
              }
            }
          }
          
        });
      }
    });
  }, []);

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in assessment player list for " +
        assname +
        " - " +
        assdate +
        " (" +
        id +
        ") in " +
        eid +
        " $$ " +
        trackobj.assgrplistheading
    );
    navigate("/superadmin/assessmentdetail/" + id);
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Assessment</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Assessment Player Details
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9">
                    
                    <h4>Assessment Date - {id}</h4>
                  </div>
                  <div className="col-md-3">
                    <div className="text-right">
                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className="border-top">
                  <h4 className="text-success mt-4">Fitness Assessment</h4>
                </div>
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
                <hr />
                <h4 className="text-success">Skills Assessment</h4>
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnSDefs}
                    rowData={sfilterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
