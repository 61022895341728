import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { sportslist } from "../../../../services/master";
import Select from "react-select";
import {
  perfcriteriacreate,
  perfcriteriadetails,
  perfcriterialist,
  perfcriteriastatus,
  perfcriteriaupdate,
  perfcriteriavalidate,
} from "../../../../services/superadmin";

export const CriteriaMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [percri, setId] = useState(0);
  const [percriname, setPerCri] = useState("");
  const [sportid, setSport] = useState("");
  const [sports, setSports] = useState("");
  const [ftype, setFType] = useState("");

  const [slist, setSList] = useState([]);
  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Criteria",
      field: "criteria_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sport",
      field: "sports_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Feeback Type",
    //   field: "feedback_type",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deletecriteria(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewcriteria(params.value)}
              title="Edit Criteria"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function viewcriteria(aid) {
    setShow(true);
    perfcriteriadetails(aid).then((res) => {
      if (res.status === true) {
        setId(res.data.id);
        setSports(res.data.sports_id);
        sportslist().then((response) => {
          if (response.status === true) {
            let arr1 = [];
            if (response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                if (
                  response.data[i].sports_cat_uid ===
                  parseInt(res.data.sports_id)
                ) {
                  arr1.push({
                    value: response.data[i].sports_cat_uid,
                    label: response.data[i].sports_category,
                  });
                }
              }
            }
            setSport(arr1);
          }
        });
        setFType(res.data.feedback_type);
        setPerCri(res.data.criteria_name);
        setStatus(res.data.status + "");
        dotracking(
          "edit button in performance criteria for " +
            res.data.criteria_name +
            " (" +
            aid +
            ") in " +
            res.data.sports_category +
            " (" +
            res.data.sports_id +
            ") $$ " +
            trackobj.perfcrilistheading
        );
      }
    });
  }

  function handleshow() {
    setId(0);
    setPerCri("");
    setSports("");
    setFType("");
    setSport("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setPerCri("");
    setFType("");
    setSports("");
    setSport("");
    setStatus("1");
    setErrorMsg("");
  }

  function deletecriteria(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("status", 0);
          dotracking(
            "status icon in performance criteria list from Active to Inactive for " +
              list.data.criteria_name +
              " (" +
              list.data.id +
              " in " +
              list.data.sports_category +
              " (" +
              list.data.sports_id +
              ") " +
              ") $$ " +
              trackobj.perfcrilistheading
          );
          perfcriteriastatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("status", 1);
          dotracking(
            "status icon in performance criteria list from Inactive to Active for " +
              list.data.criteria_name +
              " (" +
              list.data.id +
              " in " +
              list.data.sports_category +
              " (" +
              list.data.sports_id +
              ") " +
              ") $$ " +
              trackobj.perfcrilistheading
          );
          perfcriteriastatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  useEffect(() => {
    listall();
    sportslist().then((response) => {
      if (response.status === true) {
        let arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].sports_cat_uid,
              label: response.data[i].sports_category,
            });
          }
        }
        setSList(arr1);
      }
    });
  }, []);

  function listall() {
    setFilterProduct([]);
    perfcriterialist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            var type = "";
            if (response.data[i].feedback_type === 1) {
              type = "Number Rating";
            } else if (response.data[i].feedback_type === 2) {
              type = "Text Rating";
            }
            arr.push({
              id: response.data[i].id,
              criteria_name: response.data[i].criteria_name,
              sports_category: response.data[i].sports_category,
              feedback_type: type,
              status: response.data[i].status,
            });
          }
          setFilterProduct(arr);
        }
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submitcriteria(e) {
    e.preventDefault();
    setErrorMsg("");
    if (percriname !== "") {
      if (sports !== "") {
        if (ftype !== "") {
          if (percri === 0) {
            const temp1 = new FormData();
            temp1.append("sports_id", sports);
            temp1.append("criteria_name", percriname);

            perfcriteriavalidate(temp1).then((res) => {
              if (res.status === true) {
                const temp = new FormData();
                temp.append("sports_id", sports);
                temp.append("criteria_name", percriname);
                temp.append("status", status);
                temp.append("feedback_type", 1);
                dotracking(
                  "add button for performance criteria in Criteria list for " +
                    percriname +
                    " $$ " +
                    trackobj.perfcrilistheading
                );
                perfcriteriacreate(temp).then((response) => {
                  if (response.status === true) {
                    handleclose();
                    listall();
                    toast.success(response.message);
                  }
                });
              } else {
                Swal.fire({
                  text: percriname + " " + res.message,
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#4ecc48",
                  cancelButtonColor: "#6259ca",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setId(0);
                    setPerCri("");
                    setSport("");
                    setFType("");
                    setSports("");
                    setStatus("1");
                    setErrorMsg("");
                  }
                });
              }
            });
          } else if (percri !== 0) {
            const temp = new FormData();
            temp.append("id", percri);
            temp.append("sports_id", sports);
            temp.append("criteria_name", percriname);
            temp.append("status", status);
            temp.append("feedback_type", 1);

            dotracking(
              "update button for performance criteria in Criteria list for " +
                percriname +
                " (" +
                percri +
                ") $$ " +
                trackobj.perfcrilistheading
            );
            perfcriteriaupdate(temp).then((response) => {
              if (response.status === true) {
                handleclose();
                listall();
                toast.success(response.message);
              }
            });
          }
        } else {
          setErrorMsg("Please Select Feedback Type");
        }
      } else {
        setErrorMsg("Please Select Sports");
      }
    } else {
      setErrorMsg("Please Enter Performance Criteria Name");
    }
  }

  function handleSport(e) {
    if (e !== null) {
      setSport(e);
      setSports(e.value);
    } else {
      setSport("");
      setSports("");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Performance Criteria Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Performance Criteria Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add Performance Criteria
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {percri !== 0 && <h4> Performance Criteria Details</h4>}
                  {percri === 0 && <h4> Add Performance Criteria</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Performance Criteria Name{" "}
                          <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Performance Criteria Name"
                          className="form-control"
                          value={percriname}
                          onChange={(e) => setPerCri(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Sport <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select Sport"
                          value={sportid}
                          options={slist}
                          onChange={handleSport}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </Col>
                    {/*<Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Feedback Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={ftype}
                          onChange={(e) => setFType(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Feedback Type ---
                          </option>
                          <option value={1}>Number Rating</option>
                          <option value={2}>Text Rating</option>
                        </select>
                      </div>
                </Col> */}

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {percri !== 0 && (
                    <a className="btn btn-success" onClick={submitcriteria}>
                      Update
                    </a>
                  )}
                  {percri === 0 && (
                    <a className="btn btn-success" onClick={submitcriteria}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
