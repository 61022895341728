import React, { Fragment } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  analyticslist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import { useState } from "react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../../services/coach";

export const Analytics = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [tsport, setTSport] = useState("");
  const [ttype, setTType] = useState("");

  const navigate = useNavigate();

  const [pvisit, setPVisit] = useState("");
  const [paid, setPaid] = useState("");
  const [online, setOnline] = useState("");
  const [poffline, setPOffline] = useState("");
  const [match, setMatch] = useState("");
  const [cate, setCate] = useState("");
  const [boys, setBoys] = useState("");
  const [girls, setGirls] = useState("");
  const [photo, setPhoto] = useState("");
  const [cert, setCert] = useState("");
  const [fixture, setFixture] = useState("");
  const [result, setResult] = useState("");
  const [high, setHigh] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setTSport(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setTSport(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setTSport(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setTSport(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setTSport(response.data.tournament.sports_type);
        }
      });
    } 

    analyticslist(id).then((response) => {
      if (response.status === true) {
        setPVisit(response.data.total_visits_count);
        setPaid(response.data.paid_entries_count);
        setOnline(response.data.online_entries_count);
        setPOffline(response.data.online_Reg_OffPay_count);
        setMatch(response.data.total_matches);
        setCate(response.data.total_categories);
        setBoys(response.data.no_of_boys_participant);
        setGirls(response.data.no_of_girls_participant);
        setPhoto(response.data.click_on_photos);
        setCert(response.data.certificate_clicks_count);
        setFixture(response.data.click_on_fixture);
        setResult(response.data.click_on_results);
        setHigh(response.data.click_on_Highlights);
      }
    });
  }, []);

  function backlist() {
    dotracking(
      "back icon in analytics for " +
        tname +
        " (" +
        id +
        ") $$ " +
        trackobj.tourlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentlist");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    } 
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Analytics</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Analytics
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-left">
                      <h3>{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row teamslist mt-4">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">
                            Total Player Visit
                          </h3>
                          <h5 className="text-danger col-md-12">
                            ( No of players visited )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{pvisit}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        {ttype === "Inter School Tournament" && (
                          <div className="row">
                            <h3 className="card-title col-md-12">
                              Total Schools Submitted
                            </h3>
                            <h5 className="text-danger col-md-12">
                              ( No of Schools sent their entries )
                            </h5>
                          </div>
                        )}
                        {ttype !== "Inter School Tournament" && (
                          <div className="row">
                            <h3 className="card-title col-md-12">
                              Total Paid Entries
                            </h3>
                            <h5 className="text-danger col-md-12">
                              ( No of paid entry players )
                            </h5>
                          </div>
                        )}
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{paid}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        {ttype === "Inter School Tournament" && (
                          <div className="row">
                            <h3 className="card-title col-md-12">
                              Total Online Entries
                            </h3>
                            <h5 className="text-danger col-md-12">
                              ( No of schools paid online )
                            </h5>
                          </div>
                        )}
                        {ttype !== "Inter School Tournament" && (
                          <div className="row">
                            <h3 className="card-title col-md-12">
                              Total Online Entries
                            </h3>
                            <h5 className="text-danger col-md-12">
                              ( No of players registered through online )
                            </h5>
                          </div>
                        )}
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{online}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          {ttype === "Inter School Tournament" && (
                            <div className="row">
                              <h3 className="card-title col-md-12">
                                Online Entry But Paid Offline
                              </h3>
                              <h5 className="text-danger col-md-12">
                                ( No of school registered through online but
                                paid offline )
                              </h5>
                            </div>
                          )}
                          {ttype !== "Inter School Tournament" && (
                            <div className="row">
                              <h3 className="card-title col-md-12">
                                Online Entry But Paid Offline
                              </h3>
                              <h5 className="text-danger col-md-12">
                                ( No of players registered through online but
                                paid offline )
                              </h5>
                            </div>
                          )}
                        </h3>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{poffline}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">
                            Total Matches
                          </h3>
                          <h5 className="text-danger col-md-12">
                            ( No of matches conducted )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{match}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">
                            Total Categories
                          </h3>
                          <h5 className="text-danger col-md-12">
                            ( No of categories )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{cate}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">
                            Number of Boys
                          </h3>
                          {ttype === "Inter School Tournament" && (
                            <h5 className="text-danger col-md-12">
                              ( Total no of boys added to the team )
                            </h5>
                          )}
                          {ttype !== "Inter School Tournament" && (
                            <h5 className="text-danger col-md-12">
                              ( Total no of boys registered )
                            </h5>
                          )}
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{boys}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">
                            Number of Girls
                          </h3>
                          {ttype === "Inter School Tournament" && (
                            <h5 className="text-danger col-md-12">
                            ( Total no of girls added to the team )
                          </h5>
                          )}
                          {ttype !== "Inter School Tournament" && (
                            <h5 className="text-danger col-md-12">
                            ( Total no of girls registered )
                          </h5>
                          )}
                          
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{girls}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">Photos</h3>
                          <h5 className="text-danger col-md-12">
                            ( No of players viewed photos )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{photo}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">Certificates</h3>
                          <h5 className="text-danger col-md-12">
                            ( No of players downloaded the certificates )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{cert}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  {tsport.toUpperCase() === "ATHLETICS" && tsport !== "" && (
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <div className="row">
                            <h3 className="card-title col-md-12">
                              Order of Events
                            </h3>
                            <h5 className="text-danger col-md-12">
                              ( No of players viewed the order of events )
                            </h5>
                          </div>
                        </div>
                        <div className="card-body alignMe">
                          <h4 className="text-center">
                            <span>{fixture}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}

                  {tsport.toUpperCase() !== "ATHLETICS" && tsport !== "" && (
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <div className="row">
                            <h3 className="card-title col-md-12">Fixtures</h3>
                            <h5 className="text-danger col-md-12">
                              ( No of players viewed the fixtures )
                            </h5>
                          </div>
                        </div>
                        <div className="card-body alignMe">
                          <h4 className="text-center">
                            <span>{fixture}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">Results</h3>
                          <h5 className="text-danger col-md-12">
                            ( No of players viewed the results )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{result}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <h3 className="card-title col-md-12">Highlights</h3>
                          <h5 className="text-danger col-md-12">
                            ( No of players viewed the highlights )
                          </h5>
                        </div>
                      </div>
                      <div className="card-body alignMe">
                        <h4 className="text-center">
                          <span>{high}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
