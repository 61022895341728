import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Playerprofiledetails, assessmentlistplayer } from "../../../services/player";
import dotracking from "../../trackinglogs";
import exportUrl from "../../../exportUtl";
import trackobj from "../../trackingpages.json";
import { gettokenfromapi } from "../../../services/superadmin";
import { sahassessmentdatelist } from "../../../services/sahapi";

export const AssessmentListPlayer = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Assessment Date",
      field: "date",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          let origString = params.value;
          let stringToAdd = "-";
          let indexPosition = 2;
          let newString1 =
            origString.slice(0, indexPosition) +
            stringToAdd +
            origString.slice(indexPosition);
          let indexPosition1 = 5;
          let newString =
            newString1.slice(0, indexPosition1) +
            stringToAdd +
            newString1.slice(indexPosition1);
          return newString;
        }
      },
    },
    {
      headerName: "School",
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      field: "std",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      field: "section",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "height",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      field: "overall_grade",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Action",
      field: "date",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              title="Assessment Detail"
              className="btn-sm btn-secondary"
              onClick={() => viewlist(params.value)}
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function viewlist(vid) {
    dotracking(
      "view icon in assessment list for (" +
        vid +
        ") " +
        localStorage.getItem("User") +
        " (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.assdetheading
    );
    navigate("/player/assessmentdetail/" + vid);
  }
  let arr = [];
  useEffect(() => {
    Playerprofiledetails(localStorage.getItem("Name")).then((res2) => {
      if (res2.status === true) {
        if (res2.data.athletelist !== null) {
          if (res2.data.athletelist.old_user_id !== null) {
            const pltemp = new FormData();
            pltemp.append(
              "data_req",
              "asmnt_data" + res2.data.athletelist.old_user_id
            );
            gettokenfromapi(pltemp).then((res) => {
              if (res.status === true) {
                sahassessmentdatelist(
                  "asmnt_data&esid=" + res2.data.athletelist.old_user_id,
                  res.data
                ).then((response) => {
                  if (response.assmts[0].length !== 0) {
                    setFilterProduct(response.assmts[0]);
                  }
                });
              }
            });
          }
        }
      }
    });

    // assessmentlistplayer(localStorage.getItem("UID")).then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data.assessment_groups);
    //   }
    // });
  }, []);

  function exportass() {
    dotracking(
      "export button in assessment list for " +
        localStorage.getItem("User") +
        " (" +
        localStorage.getItem("UID") +
        ") $$ " +
        trackobj.asslistheading
    );
    window.open(
      exportUrl + "api/exportAssessments/" + localStorage.getItem("UID"),
      "_blank"
    );
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Assessment Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Assessment</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Assessment Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style mt-3">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
