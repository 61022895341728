import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { playerotherachievementlist } from "../../../../services/player";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useNavigate } from "react-router-dom";

export const PlayerAchievementslist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Tournament",
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      field: "level",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament Date",
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Group",
      field: "category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Position",
      field: "position",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Action",
    //   field: "id",
    //   sortable: true,
    //   resizable: true,
    //   filter: false,
    //   floatingFilter: false,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     return (
    //       <div className="text-center">
    //         <a
    //           onClick={() => viewplayer(params.value, params.data)}
    //           title="Achievement details"
    //           className="btn-sm btn-secondary"
    //         >
    //           <i className="fa fa-eye"></i>
    //         </a>
    //       </div>
    //     );
    //   },
    // },
  ];


  const navigate = useNavigate();
  function viewplayer(pid, pdata) {
    dotracking("view icon in achievement list for " + pdata.meet_name + " tournament (" + pid + ") $$ " + trackobj.achievedetailheading );
    navigate(`/player/achievementdetails/${pid}`);
  }

  useEffect(() => {
    setFilterProduct([]);
    playerotherachievementlist(localStorage.getItem("UID")).then((response) => {
      if (response.status === true) {
        let arr=[];
          let arr1=[];
          if(response.data.length !==0){
            if(response.data.our_achievements.length !== 0){
              arr=response.data.our_achievements
            }
            if(response.data.other_achivements.length !== 0){
              arr1=response.data.other_achivements
            }
            let arr2 = arr.concat(arr1)
            setFilterProduct(arr2);
          }       
      }
    });
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Achievements List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Achievements</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Achievements List
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
