import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  playerathleticsresult,
  playergetcategory,
  playerresultlist,
} from "../../../services/player";
import moment from "moment";
import dotracking from "../../trackinglogs";
import {
  schedulelist,
  schedulenoknocklist,
} from "../../../services/superadmin";
import { tourdetails } from "../../../services/register";
import trackobj from "../../trackingpages.json";
export const PlayerResultList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [wgold, setWGold] = useState("");
  const [wsilver, setWSilver] = useState("");
  const [wbronze, setWBronze] = useState("");
  const [wlist, setWList] = useState(false);
  const [tname, setTName] = useState("");
  const [sports, setSports] = useState("");

  const { id } = useParams();
  useEffect(() => {
    // playerresultlist().then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data);
    //   }
    // });
    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        setTName(response.data.tournament.meet_name);
        setSports(response.data.tournament.sports_type);

        if (
          response.data.tournament.sports_type.toUpperCase() !== "ATHLETICS"
        ) {
          playergetcategory(id, localStorage.getItem("UID")).then((res) => {
            if (res.status === true) {
              if (res.data.length !== 0) {
                const temp = new FormData();
                temp.append("tournament_id", id);
                temp.append("category_id", res.data[0].category_id);
                schedulenoknocklist(temp).then((response) => {
                  if (response.status === true) {
                    if (response.data.no_knockout_data.length !== 0) {
                      setWList(true);
                      setWGold(
                        response.data.no_knockout_data[0].winner_team_name
                      );
                      if (
                        response.data.no_knockout_data[0].runner_team_id !== 0
                      ) {
                        setWSilver(
                          response.data.no_knockout_data[0].runner_team_name
                        );
                      }
                      if (
                        response.data.no_knockout_data[0].two_runner_team_id !==
                        0
                      ) {
                        setWBronze(
                          response.data.no_knockout_data[0].two_runner_team_name
                        );
                      }
                    }
                  }
                });
                let arr = [];
                schedulelist(id, res.data[0].category_id).then((response) => {
                  if (response.status === true) {
                    if (response.data.data_fixtures.length !== 0) {
                      for (
                        let i = 0;
                        i < response.data.data_fixtures.length;
                        i++
                      ) {
                        if (response.data.data_fixtures[i].match_round !== 4) {
                          arr.push(response.data.data_fixtures[i]);
                        }
                        if (response.data.data_fixtures[i].match_round === 4) {
                          if (
                            response.data.data_fixtures[i].winner_name !== null
                          ) {
                            setWList(true);
                            setWGold(
                              response.data.data_fixtures[i].winner_name
                            );
                          }

                          if (
                            response.data.data_fixtures[i].winner_id ===
                            response.data.data_fixtures[i].team1_id
                          ) {
                            setWSilver(
                              response.data.data_fixtures[i].team2_name
                            );
                          }
                          if (
                            response.data.data_fixtures[i].winner_id ===
                            response.data.data_fixtures[i].team2_id
                          ) {
                            setWSilver(
                              response.data.data_fixtures[i].team1_name
                            );
                          }
                        }
                      }

                      setFilterProduct(arr);
                    }
                  }
                });
              }
            }
          });
        } else {
          playerathleticsresult(id, localStorage.getItem("UID")).then(
            (response) => {
              if (response.status === true) {
                setFilterProduct(response.data.details);
              }
            }
          );
        }
      }
    });
  }, []);

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in results for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.reglistheading
    );
    navigate("/player/registeredlist");
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Results</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Result List
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-10">
                    <h3>{tname}</h3>
                  </div>
                  <div className="col-md-2">
                    <div className="text-right">
                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="mt-0">
              <div className="">
                <div id="playerlist">
                  <div className="row">
                    {filterProduct.length === 0 && (
                      <div className="col-md-11 mx-auto">
                        <div className="box">
                          <div className="box__content">
                            <h1 className="text-center">No Results Found</h1>
                          </div>
                        </div>
                      </div>
                    )}
                    {sports.toUpperCase() === "ATHLETICS" && (
                      <div className="row">
                        {filterProduct.map((data, i) => {
                          return (
                            <div className="col-md-4" key={i}>
                              <div className="box">
                                <div className="box__head">
                                  <h1>{data.full_name}</h1>
                                </div>
                                <div className="box__content">
                                  <h2>
                                    <b>Player UID</b>{" "}
                                    <span>{data.username}</span>
                                  </h2>
                                  <h2>
                                    <b>Class</b> <span>{data.class_name}</span>
                                  </h2>
                                  <h2>
                                    <b>Section</b> <span>{data.section}</span>
                                  </h2>
                                  <h2>
                                    <b>BIB No</b> <span>{data.bib_no}</span>
                                  </h2>
                                  <h2>
                                    <b>Heats/Finals</b> <span>{data.heats_finals}</span>
                                  </h2>
                                  <h2>
                                    <b>Time/Distance</b> <span>{data.time_distance}</span>
                                  </h2>
                                  <h2>
                                    <b>Category</b> <span>{data.age_category_name}</span>
                                  </h2>
                                  <h2>
                                    <b>Place</b> <span>{data.place}</span>
                                  </h2>
                                </div>
                                <div className="box_footer">
                                  <h4>Event :-</h4>
                                  <p>{data.event}</p>
                                </div>
                               
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {sports.toUpperCase() !== "ATHLETICS" && (
                      <>
                        {wlist && (
                          <div id="playerlist">
                            <div id="winners">
                              <div className="before"></div>
                              <div className="after"></div>
                              {/* <hr /> */}
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="card__content grid">
                                    <div className="card__pricing">
                                      <img
                                        src={require("../../../assets/images/goldcup.png")}
                                        alt=""
                                        className="card__headercup-img"
                                      />
                                    </div>
                                    <header className="card__header">
                                      <div className="card__header-circle grid">
                                        <img
                                          src={require("../../../assets/images/gold.png")}
                                          alt=""
                                          className="card__header-img"
                                        />
                                      </div>
                                      <h1 className="card__header-title">
                                        {wgold}
                                      </h1>
                                    </header>
                                    <ul className="card__list grid">
                                      <li className="card__list-item gold">
                                        <p className="card__list-description text-white">
                                          <b>Place :</b> Gold Medal
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {wsilver !== "" && (
                                  <div className="col-md-4">
                                    <div className="card__content grid">
                                      <div className="card__pricing">
                                        <img
                                          src={require("../../../assets/images/silvercup.png")}
                                          alt=""
                                          className="card__headercup-img"
                                        />
                                      </div>
                                      <header className="card__header">
                                        <div className="card__header-circle grid">
                                          <img
                                            src={require("../../../assets/images/silver.png")}
                                            alt=""
                                            className="card__header-img"
                                          />
                                        </div>
                                        <h1 className="card__header-title">
                                          {wsilver}
                                        </h1>
                                      </header>
                                      <ul className="card__list grid">
                                        <li className="card__list-item silver">
                                          <p className="card__list-description">
                                            <b>Place :</b> Silver Medal
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                                {wbronze !== "" && (
                                  <div className="col-md-4">
                                    <div className="card__content grid">
                                      <div className="card__pricing">
                                        <img
                                          src={require("../../../assets/images/bronzecup.png")}
                                          alt=""
                                          className="card__headercup-img"
                                        />
                                      </div>
                                      <header className="card__header">
                                        <div className="card__header-circle grid">
                                          <img
                                            src={require("../../../assets/images/bronze.png")}
                                            alt=""
                                            className="card__header-img"
                                          />
                                        </div>
                                        {/* <span className="card__header-subtitle">Most popular</span> */}
                                        <h1 className="card__header-title">
                                          {wbronze}
                                        </h1>
                                      </header>
                                      <ul className="card__list grid">
                                        <li className="card__list-item bronze">
                                          <p className="card__list-description text-white">
                                            <b>Place :</b> Bronze Medal
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="mt-4"></div>
                        {filterProduct.map((data, i) => {
                          return (
                            <div className="col-md-11 mx-auto" key={i}>
                              <div className="match">
                                <div className="match-header">
                                  <div className="match-tournament">
                                    <img
                                      src={require("../../../assets/images/results/tournament_name.png")}
                                      alt="profile-user"
                                      className=""
                                    />
                                    {tname}
                                  </div>
                                  <div className="match-actions">
                                    <div className="rounddetails">
                                      {data.match_round === 1 && (
                                        <i className="material-icons-outlined">
                                          League
                                        </i>
                                      )}
                                      {data.match_round === 2 && (
                                        <i className="material-icons-outlined">
                                          QuaterFinal
                                        </i>
                                      )}
                                      {data.match_round === 3 && (
                                        <i className="material-icons-outlined">
                                          SemiFinal
                                        </i>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="match-content">
                                  <div className="column">
                                    <div className="team team--home">
                                      <div className="team-logo">
                                        <img
                                          src={require("../../../assets/images/results/team.png")}
                                          alt="profile-user"
                                          className=""
                                        />
                                      </div>
                                      <h2 className="team-name">
                                        {data.team1_name}
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="column">
                                    <div className="match-details">
                                      <div className="match-date">
                                        <strong>
                                          {data.match_date !== null && (
                                            <>
                                              {moment(data.match_date).format(
                                                "DD MMM YYYY"
                                              )}
                                            </>
                                          )}
                                        </strong>
                                      </div>
                                      <div className="match-score">
                                        <span className="match-score-number match-score-number--leading">
                                          {data.team1_score} -{" "}
                                          {data.team2_score}
                                        </span>
                                      </div>

                                      <div className="match-referee">
                                        Category:{" "}
                                        <strong>{data.category_name}</strong>
                                      </div>

                                      {data.winner_id === 0 && (
                                        <div className="match-draw-place alert alert-danger ">
                                          {data.winner_name}
                                        </div>
                                      )}
                                      {data.winner_id !== 0 && (
                                        <div className="match-bet-place alert alert-success ">
                                          {data.winner_name} Won!
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="column">
                                    <div className="team team--away">
                                      <div className="team-logo">
                                        <img
                                          src={require("../../../assets/images/results/team.png")}
                                          alt="profile-user"
                                          className=""
                                        />
                                      </div>
                                      <h2 className="team-name">
                                        {data.team2_name}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
