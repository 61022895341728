import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  bannerserstrackingadminlist,
  multipleroleslist,
  rolemasterlist,
  webpagetrackinglist,
} from "../../../services/superadmin";
import {
  funderlist,
  grpschlist,
  schoollistmaster,
} from "../../../services/master";

export const BannerTrackingList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");

  const [role, setRole] = useState(0);
  const [rvalue, setRValue] = useState(0);

  const [rlist, setRList] = useState([]);

  const [ulist, setUList] = useState([]);

  const [slist, setSList] = useState([]);
  const [flist, setFList] = useState([]);
  const [glist, setGList] = useState([]);

  const [schid, setSch] = useState(0);
  const [svalue, setSValue] = useState(0);

  const [funid, setFun] = useState(0);
  const [fvalue, setFValue] = useState(0);

  const [grpid, setGrp] = useState(0);
  const [gvalue, setGValue] = useState(0);

  const [wcount, setWCount] = useState(0);
  const [ucount, setUCount] = useState(0);
  const [clist, setCList] = useState([]);

  const columnDefs = [
    {
      headerName: "Banner Name",
      //   width: 200,
      field: "b_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Banner Pages",
      //   width: 200,
      field: "bannerpage",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role",
      //   width: 150,
      field: "role_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        if (params.value === 1) {
          return "Super Admin";
        } else if (params.value === 3) {
          return "City Regional Manager";
        } else if (params.value === 4) {
          return "School Head";
        } else if (params.value === 5) {
          return "Referee";
        } else if (params.value === 6) {
          return "Player";
        }
      },
    },
    {
      headerName: "Name",
      //   width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username (UID)",
      //   width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnWDefs = [
    {
      headerName: "School",
      field: "school_funder_group_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CHID",
      field: "chid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Page",
      field: "current_page",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      field: "created_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "IP Address",
      //   width: 200,
      field: "internet_ip_address",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Latitude",
      //   width: 150,
      field: "latitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Longitude",
      field: "longitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      field: "location_country",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Region",
      field: "location_region",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      field: "location_city",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnFDefs = [
    {
      headerName: "Funder",
      field: "school_funder_group_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Page",
      field: "current_page",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      field: "created_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "IP Address",
      //   width: 200,
      field: "internet_ip_address",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Latitude",
      //   width: 150,
      field: "latitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Longitude",
      field: "longitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      field: "location_country",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Region",
      field: "location_region",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      field: "location_city",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnGDefs = [
    {
      headerName: "Group",
      //   width: 200,
      field: "school_funder_group_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "IP Address",
      //   width: 200,
      field: "internet_ip_address",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Latitude",
      //   width: 150,
      field: "latitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Longitude",
      field: "longitude",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      field: "location_country",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Region",
      field: "location_region",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      field: "location_city",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  let arr = [];
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    setSDate(moment(new Date()).format("YYYY-MM-DD"));
    setEDate(moment(new Date()).format("YYYY-MM-DD"));

    if (link[1] === "bannertrackinglist") {
      const temp = new FormData();
      temp.append("start_date", moment(new Date()).format("YYYY-MM-DD"));
      temp.append("end_date", moment(new Date()).format("YYYY-MM-DD"));
      temp.append("role", 0);
      bannerserstrackingadminlist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });

      rolemasterlist().then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].is_active === 1) {
                arr.push({
                  value: response.data[i].id,
                  label: response.data[i].role,
                });
              }
            }
          }
          setRList(arr);
        }
      });
    } else if (link[1] === "schooltrackinglist") {
      const temp1 = new FormData();
      temp1.append("start_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("end_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("school_funder_group_id", 0);
      temp1.append("school_funder_group_type", 1);

      webpagetrackinglist(temp1).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });

      schoollistmaster().then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.details.length !== 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr1.push({
                value: response.data.details[i].id,
                label: response.data.details[i].school_name,
              });
            }
            setSList(arr1);
          }
        }
      });
    } else if (link[1] === "fundertrackinglist") {
      const temp1 = new FormData();
      temp1.append("start_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("end_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("school_funder_group_id", 0);
      temp1.append("school_funder_group_type", 2);

      webpagetrackinglist(temp1).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });

      funderlist().then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.funders_list.length !== 0) {
            for (let i = 0; i < response.data.funders_list.length; i++) {
              arr1.push({
                value: response.data.funders_list[i].id,
                label: response.data.funders_list[i].funder_name,
              });
            }
            setFList(arr1);
          }
        }
      });
    } else if (link[1] === "grouptrackinglist") {
      const temp1 = new FormData();
      temp1.append("start_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("end_date", moment(new Date()).format("YYYY-MM-DD"));
      temp1.append("school_funder_group_id", 0);
      temp1.append("school_funder_group_type", 3);

      webpagetrackinglist(temp1).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });

      grpschlist().then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.funders_list.length !== 0) {
            for (let i = 0; i < response.data.funders_list.length; i++) {
              arr1.push({
                value: response.data.funders_list[i].id,
                label: response.data.funders_list[i].group_of_school_name,
              });
            }
            setGList(arr1);
          }
        }
      });
    }
  }, [window.location.href]);

  function search() {
    if (urllink === "bannertrackinglist") {
      const temp = new FormData();
      temp.append("start_date", sdate);
      temp.append("end_date", edate);
      temp.append("role_id", rvalue);
      bannerserstrackingadminlist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (urllink === "schooltrackinglist") {
      const temp = new FormData();
      temp.append("start_date", sdate);
      temp.append("end_date", edate);
      temp.append("school_funder_group_id", svalue);
      temp.append("school_funder_group_type", 1);
      webpagetrackinglist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });
    } else if (urllink === "fundertrackinglist") {
      const temp = new FormData();
      temp.append("start_date", sdate);
      temp.append("end_date", edate);
      temp.append("school_funder_group_id", fvalue);
      temp.append("school_funder_group_type", 2);

      webpagetrackinglist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });
    } else if (urllink === "grouptrackinglist") {
      const temp = new FormData();
      temp.append("start_date", sdate);
      temp.append("end_date", edate);
      temp.append("school_funder_group_id", gvalue);
      temp.append("school_funder_group_type", 3);

      webpagetrackinglist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.list);
          setWCount(response.data.webpages_count);
          setUCount(response.data.users_count);
          setCList(response.data.tour_pages);
        }
      });
    }
  }

  function handleRole(e) {
    if (e === null) {
      setRole(0);
      setRValue(0);
    } else {
      setRole(e);
      setRValue(e.value);
    }
  }

  function handleSch(e) {
    if (e === null) {
      setSch(0);
      setSValue(0);
    } else {
      setSch(e);
      setSValue(e.value);
    }
  }

  function handleFun(e) {
    if (e === null) {
      setFun(0);
      setFValue(0);
    } else {
      setFun(e);
      setFValue(e.value);
    }
  }

  function handleGrp(e) {
    if (e === null) {
      setGrp(0);
      setGValue(0);
    } else {
      setGrp(e);
      setGValue(e.value);
    }
  }
  return (
    <div className="side-app">
      {urllink === "bannertrackinglist" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Banners Tracking</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tracking</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Banners Tracking
            </li>
          </ol>
        </div>
      )}

      {urllink === "schooltrackinglist" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">School Webpage Tracking</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tracking</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              School Webpage Tracking
            </li>
          </ol>
        </div>
      )}

      {urllink === "fundertrackinglist" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Funder Webpage Tracking</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tracking</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Funder Webpage Tracking
            </li>
          </ol>
        </div>
      )}

      {urllink === "grouptrackinglist" && (
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Group Webpage Tracking</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tracking</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Group Webpage Tracking
            </li>
          </ol>
        </div>
      )}

      <div className="row">
        <div className="col-md-12">
          <Form className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={sdate}
                      onChange={(e) => setSDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Ends Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={edate}
                      onChange={(e) => setEDate(e.target.value)}
                    />
                  </div>
                </div>
                {urllink === "bannertrackinglist" && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Roles</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Roles"
                        value={role}
                        options={rlist}
                        onChange={handleRole}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}
                {urllink === "schooltrackinglist" && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">School</label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schid}
                        options={slist}
                        onChange={handleSch}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}
                {urllink === "fundertrackinglist" && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Funder</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Funder"
                        value={funid}
                        options={flist}
                        onChange={handleFun}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}
                {urllink === "grouptrackinglist" && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Group of School</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Group of School"
                        value={grpid}
                        options={glist}
                        onChange={handleGrp}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-3">
                  <div className="form-group">
                    <a
                      className="btn btn-success mt-6 mx-7 me-2"
                      onClick={search}
                    >
                      Apply Filter
                    </a>
                  </div>
                </div>
              </div>
{urllink !== "bannertrackinglist" &&
  <div className="row">
                <div className="col-md-3">
                  <div className="card bg-webpage">
                    <div className="card-body text-center feature">
                      <p
                        className="card-text mt-3 mb-3"
                        style={{ fontWeight: "700" }}
                      >
                        {" "}
                        Webpage Count
                      </p>
                      <p className="h3 text-center text-primary-1">{wcount}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card bg-webpage">
                    <div className="card-body text-center feature">
                      <p
                        className="card-text mt-3 mb-3"
                        style={{ fontWeight: "700" }}
                      >
                        {" "}
                        Unique Users Count <br/>(Group by IP Address)
                      </p>
                      <p className="h3 text-center text-primary-1">{ucount}</p>
                    </div>
                  </div>
                </div>

                {clist.map((item, i) => {
                  return (
                    <>
                      <div className="col-md-3" key={i}>
                        <div className="card bg-webpage">
                          <div className="card-body text-center feature">
                            <p
                              className="card-text mt-3 mb-3"
                              style={{ fontWeight: "700" }}
                            >
                              {" "}
                              {item.current_page} Count
                            </p>
                            <p className="h3 text-center text-primary-1">
                              {item.users_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
}
              
            </div>
          </Form>
        </div>
        <div className="col-md-12">
          <form className="card mt-0">
            <div className="card-body">
              {urllink === "bannertrackinglist" && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              )}

              {urllink === "schooltrackinglist" && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnWDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              )}

              {urllink === "fundertrackinglist" && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnFDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              )}

              {urllink === "grouptrackinglist" && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnGDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
