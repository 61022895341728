import React from "react";
import {
  highlightscreate,
  highlightsdelete,
  highlightsdetail,
  highlightslist,
  highlightsupdate,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { Reftourdetail } from "../../../../../services/referee";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { sendhighlights } from "../../../../../services/communication";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../../services/coach";

export const Highlights = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Highlights",
      field: "highlight_links",
      sortable: true,
      resizable: true,
      filter: false,
      // floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      field: "meet_highlights_id",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              href={params.data.highlight_links}
              target="_blank"
              title="View Link"
              className="btn-sm btn-secondary me-2"
              onClick={() =>
                dotracking(
                  "view icon in highlights list for highlights " + params.data.highlight_links + " ("+ params.value + ") in " + tname + " (" + id + ") tournament $$ " +trackobj.highheading
                )
              }
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {localStorage.getItem("Role") !== "1" && (
              <>
                {params.data.created_by ===
                  parseInt(localStorage.getItem("UID")) && (
                  <>
                    <a
                      onClick={() => highedit(params.value,params)}
                      title="Edit Link"
                      className="btn-sm btn-success me-2"
                      
                    >
                      <i className="fa fa-edit fa-lg"></i>
                    </a>
                    <a
                      title="Delete Link"
                      onClick={() => highdelete(params.value,params)}
                      className="btn-sm btn-danger me-2"
                    >
                      <i className="fa fa-trash fa-lg"></i>
                    </a>
                  </>
                )}
              </>
            )}
            {localStorage.getItem("Role") === "1" && (
              <>
                <a
                  onClick={() => highedit(params.value,params)}
                  title="Edit Link"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fa fa-edit fa-lg"></i>
                </a>
                <a
                  title="Delete Link"
                  onClick={() => highdelete(params.value,params)}
                  className="btn-sm btn-danger me-2"
                >
                  <i className="fa fa-trash fa-lg"></i>
                </a>
              </>
            )}
          </div>
        );
      },
    },
  ];

  function highdelete(tid,tdata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
      dotracking("delete icon in highlights for highlights " + tdata.data.highlight_links + " ("+ tid + ") in " + tname + " (" + id + ") tournament $$ " + trackobj.highheading);

        highlightsdelete(tid).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function highedit(tid,tdata) {
    dotracking("edit icon in highlights for highlights " + tdata.data.highlight_links + " ("+ tid + ") in " + tname + " (" + id + ") tournament $$ " + trackobj.highheading);

    highlightsdetail(tid).then((response) => {
      if (response.status === true) {
        if (response.data !== null) {
          setELinkId(response.data.meet_highlights_id);
          setELinks(response.data.highlight_links);
          handleEShow();
        }
      }
    });
  }
  const [eshow, setEShow] = useState(false);
  const [elink, setELinks] = useState("");
  const [elinkid, setELinkId] = useState("");

  function handleEShow() {
    setErrorMsg("");
    setEShow(true);
  }

  function handleEclose() {
    setEShow(false);
    setELinks("");
    setELinkId("");
    setErrorMsg("");
  }

  const [tname, setTName] = useState("");
  const [show, setShow] = useState(false);
  function handleShow() {
    setLinks([""]);
    setErrorMsg("");
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setLinks([""]);
    setErrorMsg("");
  }

  const navigate = useNavigate();
  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
    dotracking("back button in highlights for " + tname + " (" + id + ") tournament $$ " + trackobj.tourlistheading);
    }
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1"){
        dotracking("back button in highlights for " + tname + " (" + id + ") tournament $$ " + trackobj.tourlistheading);
        navigate("/schoolhead/tournamentlist");
      }else if (localStorage.getItem("Page") === "3"){
        dotracking("back button in highlights for " + tname + " (" + id + ") tournament $$ " + trackobj.comtourlistheading);
        navigate("/schoolhead/completedtournament");
      }
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listall();
  }, []);

  const [links, setLinks] = useState([""]);

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const handleDeleteLink = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };

  const handleLinkChange = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index] = value;
    setLinks(updatedLinks);
  };

  const [errorMsg, setErrorMsg] = useState("");
  function submithigh() {
    if (elinkid !== "") {
      if (elink !== "") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("meet_highlights_id", elinkid);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("highlight_links", elink);
        dotracking("update button in add highlights for " + elink + " in " + tname + " ("  + id + ") tournament $$ " + trackobj.highheading);
        highlightsupdate(temp).then((response) => {
          if (response.status === true) {
            handleEclose();
            listall();
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg("Please Enter Link");
      }
    } else {
      var chklink = "";
      if (links.length !== 0) {
        for (let i = 0; i < links.length; i++) {
          if (links[i] !== "") {
            chklink = "";
          } else {
            chklink = "Please Enter Links";
            setErrorMsg("Please Enter Links");
          }
        }
      }

      if (chklink === "") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("highlights_count", links.length);
        temp.append("admin_id", localStorage.getItem("UID"));
        if (links.length !== 0) {
          for (let i = 0; i < links.length; i++) {
            if (links[i] !== "") {
              temp.append("highlight_links_" + [i + 1], links[i]);
            }
          }
        }
        dotracking("add button in add highlights for " + tname + " (" + id + ") tournament $$ " + trackobj.highheading);
        highlightscreate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      }
    }
  }

  function listall() {
    highlightslist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  function sendall() {
    dotracking("send notification button in highlights for " + tname + " (" + id + ") tournament $$ " + trackobj.highheading);
    if (filterProduct.length !== 0) {
      Swal.fire({
        title: "Are you sure want to send?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          sendhighlights(id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Add highlights for " + tname,
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Highlights Upload</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Highlights Upload
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="text-left">
                    <h3>{tname}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <a className="btn btn-success me-2 mt-1" onClick={handleShow}>
                      <i className="fe fe-plus"></i> Add Highlights
                    </a>
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") && (
                      <a className="btn btn-secondary me-2 mt-1" onClick={sendall}>
                        <i className="fa fa-paper-plane"></i> Send Highlights
                        Notification
                      </a>
                    )}
                    <a className="btn btn-gray mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  <h4> Add Highlights</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="text-right">
                    <a onClick={handleAddLink} className="btn btn-success">
                      Add Link
                    </a>
                  </div>

                  {links.map((link, index) => (
                    <Row className="row-sm mx-0" key={index}>
                      <Col lg={11} md={11}>
                        <div className="form-group">
                          <label className="mb-2">
                            Highlights <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Highlight Link"
                            className="form-control"
                            value={link}
                            onChange={(e) =>
                              handleLinkChange(index, e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      {index !== 0 && (
                        <Col lg={1} md={1}>
                          <div className="form-group mt-6">
                            <a
                              className="btn btn-danger"
                              onClick={() => handleDeleteLink(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </a>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  <a className="btn btn-success" onClick={submithigh}>
                    Add
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={eshow} size="lg" onHide={handleEclose}>
                <Modal.Header>
                  <h4> Update Highlights</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Highlights <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Highlight Link"
                          className="form-control"
                          value={elink}
                          onChange={(e) => setELinks(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleEclose}>
                    Close
                  </a>
                  <a className="btn btn-success" onClick={submithigh}>
                    Update
                  </a>
                </Modal.Footer>
              </Modal>

              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
