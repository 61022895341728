import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { useMemo } from "react";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useRef } from "react";
import Select from "react-select";
import { funderschoollist, grpschoollist } from "../../../../services/common";
import { useNavigate, useParams } from "react-router-dom";
import {
  funderdeleteschool,
  funderdetail,
  fundernewschool,
  groupdeleteschool,
  groupnewschool,
  grpschdetail,
  schoollistmaster,
} from "../../../../services/master";

export const FunderGroupSchool = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [schid, setSchId] = useState("");
  const [slist, setSList] = useState([]);
  const [fundname, setFundName] = useState("");

  const [s3logo, setS3Logo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [logofile, setLogoFile] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => deletesch(params.value, params)}
              title="Delete School"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CHID",
      width: 150,
      field: "school_unique_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      width: 150,
      field: "city_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Group Of School",
      width: 150,
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports Infra",
      width: 150,
      field: "school_sports_infra",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 100,
      field: "school_fees",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "school_logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
  ];

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }
  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    listall(link[1]);
    if (link[1] === "groupschools") {
      grpschdetail(id).then((res) => {
        if (res.status === true) {
          setFundName(res.data.funders_details.group_of_school_name);
        }
      });
    } else if (link[1] === "funderschools") {
      funderdetail(id).then((res) => {
        if (res.status === true) {
          setFundName(res.data.funders_details.funder_name);
        }
      });
    }
    schoollistmaster().then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.details.length > 0) {
          for (let i = 0; i < response.data.details.length; i++) {
            arr.push({
              label: response.data.details[i].school_name,
              value: response.data.details[i].id,
            });
          }
          setSList(arr);
        }
      }
    });
  }, []);

  function listall(ltype) {
    setFilterProduct([]);
    if (ltype === "funderschools") {
      funderschoollist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.school_list);
          setS3Logo(response.data.logo_path);
        }
      });
    } else if (ltype === "groupschools") {
      grpschoollist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.school_list);
          setS3Logo(response.data.logo_path);
        }
      });
    }
  }

  const [sshow, setSShow] = useState(false);

  function schshow() {
    setErrorMsg("");
    setSchId("");
    setSShow(true);
  }

  function schclose() {
    setErrorMsg("");
    setSchId("");
    setSShow(false);
  }

  function handleSchool(e) {
    if (e !== null) {
      setSchId(e);
    } else {
      setSchId("");
    }
  }

  function schsubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (urllink === "groupschools") {
      if (schid !== "") {
        dotracking(
          "submit button in group school list " +
            schid.label +
            " (" +
            schid.value +
            ") for group " + fundname +" (" +
            id +
            ") $$ " +
            trackobj.groupschoollist
        );
        groupnewschool(id, schid.value).then((response) => {
          if (response.status === true) {
            schclose();
            listall(urllink);
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg("Please Select School");
      }
    } else if (urllink === "funderschools") {
      if (schid !== "") {
        dotracking(
          "submit button in funder school list " +
            schid.label +
            " (" +
            schid.value +
            ") for funder " + fundname +" (" +
            id +
            ") $$ " +
            trackobj.funderschoollist
        );
        fundernewschool(id, schid.value).then((response) => {
          if (response.status === true) {
            schclose();
            listall(urllink);
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg("Please Select School");
      }
    }
  }

  function deletesch(did, ddata) {
    if (urllink === "funderschools") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete icon in funder school list for" +
              ddata.data.school_name +
              " (" +
              did +
              ") in funder (" +
              id +
              ") $$ " +
              trackobj.funderschoollist
          );

          funderdeleteschool(id, did).then((res) => {
            if (res.status === true) {
              toast.success(res.message);
              listall(urllink);
            }
          });
        }
      });
    } else if (urllink === "groupschools") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete icon in group school list for" +
              ddata.data.school_name +
              " (" +
              did +
              ") in group of school (" +
              id +
              ") $$ " +
              trackobj.groupschoollist
          );

          groupdeleteschool(id, did).then((res) => {
            if (res.status === true) {
              toast.success(res.message);
              listall(urllink);
            }
          });
        }
      });
    }
  }
  function backlist() {
    if (urllink === "funderschools") {
      dotracking(
        "back button in funder school list for " +
          fundname +
          " (" +
          id +
          ") $$ " +
          trackobj.addfunderlist
      );
      navigate("/superadmin/funderlist");
    } else if (urllink === "groupschools") {
      dotracking(
        "back button in group school list for " +
          fundname +
          " (" +
          id +
          ") $$ " +
          trackobj.addgroupschoollist
      );
      navigate("/superadmin/groupschoollist");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          {urllink === "funderschools" && (
            <li className="breadcrumb-item">
              <a>Funder</a>
            </li>
          )}

          {urllink === "groupschools" && (
            <li className="breadcrumb-item">
              <a>Group of Schools</a>
            </li>
          )}

          <li className="breadcrumb-item active" aria-current="page">
            School Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <h4>{fundname}</h4>
                </div>
                <div className="col-md-4">
                  <div className="text-right">
                    <a className="btn btn-success me-2" onClick={schshow}>
                      <i className="fa fa-plus"></i> Add School
                    </a>
                    <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  sortingOrder={sorting}
                />
              </div>

              <Modal show={lshow} size="lg" onHide={logoclose}>
                <Modal.Header>
                  <h4> School Logo</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={schlogo} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={logoclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={sshow} size="lg" onHide={schclose}>
                <Modal.Header>
                  {urllink === "funderschools" && <h4> Funder Schools</h4>}

                  {urllink === "groupschools" && <h4> Group Schools</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          School <span className="errortext"> *</span> 
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select School"
                          value={schid}
                          options={slist}
                          onChange={handleSchool}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </Col>

                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-success me-2" onClick={schsubmit}>
                    Submit
                  </a>
                  <a className="btn btn-danger" onClick={schclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
