import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const RefSidebar = () => {
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();

  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [proact, setProAct] = useState(false);
  const [myprofile, setMyProfAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [toulistact, setTouListAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);
    setProAct(false);

    setTouAct(false);
    setTouListAct(false);

    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/referee/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Profile") {
      setProAct(true);
      setMyProfAct(true);
      dotracking("profile menu $$ " + trackobj.profileheading);
      navigate("/referee/profile");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tournaments") {
      setTouAct(true);
      setTouListAct(true);
      dotracking("tournament menu $$ " + trackobj.tourlistheading);
      navigate("/referee/tournamentlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    }
  }

  const navigate = useNavigate();
  function submenuactive(head) {
    setSubAct(false);
    setDashboard(false);
    setMyProfAct(false);

    setTouAct(false);
    setTouListAct(false);

    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/referee/dashboard");
    }  else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/referee/contact");
    }else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/referee/changepassword");
    }else if (head === "profile") {
      setProAct(true);
      setMyProfAct(true);
      navigate("/referee/profile");
    } else if (head === "editprofile") {
      setProAct(true);
      setMyProfAct(true);
      navigate("/referee/editprofile");
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/tournamentlist");
    } else if (head === "tournamentdetails") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/tournamentdetails/" + id);
    } else if (head === "tournamentagecategorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/tournamentagecategorylist/" + id);
    }else if (head === "tournamentclassagecategorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/tournamentclassagecategorylist/" + id);
    }else if (head === "teamlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/teamlist/" + id + "/" + tid);
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/teamplayerlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "analytics") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/analytics/" + id);
    } else if (head === "results") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/results/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/resultlist/" + id + "/" + eid);
    } else if (head === "pointslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/pointslist/" + id + "/" + eid);
    }else if (head === "categorylist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/categorylist/" + id);
    } else if (head === "poollist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/poollist/" + id + "/" + eid);
    } else if (head === "scheduleteam") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/scheduleteam/" + id + "/" + tid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/schedulelist/" + id + "/" + eid);
    } else if (head === "scheduleallocate") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/scheduleallocate/" + id + "/" + eid);
    } else if (head === "athleticschoollist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/athleticschoollist/" + id);
    }else if (head === "entryplayerslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/entryplayerslist/" + id);
    } else if (head === "athleticsentryplayerslist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/athleticsentryplayerslist/" + id);
    }else if (head === "orderofeventlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/orderofeventlist/" + id);
    } else if (head === "eventstartlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/eventstartlist/" + id + "/" + eid);
    }else if (head === "athleticsresults") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/athleticsresults/" + id);
    }else if (head === "overallchampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/individualchampionlist/" + id);
    }else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "photoupload") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/photoupload/" + id);
    } else if (head === "highlights") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/highlights/" + id);
    }else if (head === "leaderboard") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/referee/leaderboard/" + id);
    }
    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1]);
  }, []);
  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-8"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Profile</div>
              </li>
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <ul className="side-menu">
                        <li className="slide">
                          {" "}
                          <a
                            className={
                              dashboard ? "slide-item active" : "slide-item"
                            }
                            // onClick={() => submenuactive("dashboard")}
                          >
                            Dashboard
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-network"></i>
                  <div className="slider-text">Profile</div>
                </h2>
                <div
                  className={
                    proact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-network"></i>
                        Profile
                      </h4>{" "}
                      <a
                        className={
                          myprofile ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("profile")}
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>{" "}
                        Tournaments
                      </h4>{" "}
                      <a
                        className={
                          toulistact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("tournamentlist")}
                      >
                        Tournament Lists
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
