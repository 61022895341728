import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { classlist, schoollistmaster, sportslist } from "../../services/master";
import {
  accountregister,
  validatemail,
  validatemobile,
} from "../../services/register";
import moment from "moment/moment";
import { logindata } from "../../services/login";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";
import Select from "react-select";

export const PlayerRegister = () => {
  const navigate = useNavigate();

  const [paypage, setPaypage] = useState(true);

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [clslist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");
  const [sslist, setSecList] = useState([]);
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [stusch, setStuSch] = useState("");
  const [schlist, setSchList] = useState([]);
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const [valemail, setValEmail] = useState("");
  const [emailerr, setEmailErr] = useState("");

  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setValEmail("Please Enter a valid Email Address");
      }

      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setEmailErr(response.message);
        }
      });
    }
  }

  const [valmob, setValMob] = useState("");
  const [mobnoerr, setMobErr] = useState("");

  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    setMobErr("");
    var obj = {};
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        setValMob("Enter a Valid  Mobile Number");
      }
      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setMobErr(response.message);
        }
      });
    }
  }

  function selectclass(e) {
    setStuClass(e.target.value);
  }

  const [check, setCheck] = useState(true);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [chnoti, setChkNoti] = useState(true);
  function checknoti(e) {
    setChkNoti(e.target.checked);
  }

  const [errorMsg, setErrMsg] = useState("");
  function regsubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setValMob("");
    setValEmail("");
    setErrMsg("");
    var valid = "";
    var obj = {};

    if (mobno.length !== 10) {
      valid = "Not";
      setValMob("Mobile Number must be 10 characters");
    }
    if (mobno.length === 10) {
      for (let x = 0, length = mobno.length; x < length; x++) {
        var l = mobno.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        valid = "Not";
        setValMob("Enter a Valid  Mobile Number");
      }
    }
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      valid = "Not";
      setValEmail("Please Enter a valid Email Address");
    }

    if (
      valid === "" &&
      fname !== "" &&
      stuclass !== "" &&
      stusec !== "" &&
      gender !== "" &&
      stusch !== "" &&
      dob !== "" &&
      mobno !== "" &&
      email !== "" &&
      mobnoerr === "" &&
      emailerr === ""
    ) {
      if (check === false) {
        valid = "required";
        setErrMsg("Please Select Disclaimer");
      }
      if (valid === "") {
        setLoading(true);
        const temp = new FormData();
        temp.append("first_name", fname);
        temp.append("middle_name", "");
        temp.append("last_name", "");
        temp.append("date_of_birth", moment(dob).format("YYYY-MM-DD"));
        temp.append("gender", gender);
        temp.append("mobile_no", mobno);
        temp.append("email_id", email);
        temp.append("photograph", "");
        temp.append("section", stusec);
        temp.append("class", stuclass);
        temp.append("school_id", stusch.value);
        temp.append("selected_sports", "");
        if (chnoti === false) {
          temp.append("notification_enable", 0);
        } else if (chnoti === true) {
          temp.append("notification_enable", 1);
        }
        dotracking(
          "submit button in player register for " +
            fname +
            " with DOB " +
            dob +
            " ,gender " +
            gender +
            " ,mobile " +
            mobno +
            " ,email " +
            email +
            " ,class " +
            stuclass +
            " ,section " +
            stusec +
            " in school " +
            stusch + " $$ " + trackobj.registerheading
        );

        accountregister(temp).then((res) => {
          if (res.status === true) {
            setUser(res.data.userdetails.username);
            setPassword(res.data.password);
            setPaypage(false);
            setLoading(false);
          } else {
            setLoading(false);
            setErrMsg(res.message);
          }
        });
      }
    }
  }

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking("goto dashboard button from player register,redirect to Player dashboard $$ " + trackobj.dashheading);

          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        setErrMsg(response.message);
      }
    });
  }

  function backpage() {
    dotracking("back to login link in player register from " + user + " $$ "+ trackobj.loginheading);
    navigate("/login");
  }

  const [tname, setTName] = useState("");
  const [agetype, setAgeType] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");

  const [school, setSchool] = useState("");
  const [cir, setCir] = useState("");
  const [curdate, setCurDate] = useState("");
  let arr=[];
  useEffect(() => {
    var a = new Date().getFullYear() - 7;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));
    
    schoollistmaster().then((response) => {
      if (response.status === true) {
        if (response.data.details.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.details.length; i++) {
            arr.push({
              value: response.data.details[i].id,
              label: response.data.details[i].school_unique_no + " - " + response.data.details[i].school_name,
            });
          }
        }
        setSchList(arr);
      }
    });

    classlist().then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });
  }, []);

  function handleSch(e) {
    if (e !== null) {
      setStuSch(e);
    }else{
      setStuSch("");
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl registrationpge">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="md-overflow">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-4 pe-0 tab-100 order_a mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <a
                      href="http://pathwayz.sportzvillage.com/"
                      target="_blank"
                    >
                      <img
                        src={require("../../assets/images/pathwayz_logo.png")}
                        alt="pathwayz"
                        className="pathwayz_logo"
                      />
                    </a>
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              {paypage && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="form-text login">
                      <h4 className="mt-0">Create Account</h4>
                    </div>

                    <form className="login">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-user"></i>Full Name{" "}
                              <b>
                                <span className="text-danger">*</span>
                              </b>
                            </label>
                            <input
                              type="text"
                              placeholder="Full Name"
                              value={fname}
                              onChange={(e) => setFName(e.target.value)}
                              autoFocus
                              onKeyPress={LetterOnly}
                              onPaste={onPasteLetter}
                            />
                            {submitted && !fname && (
                              <span className="text-danger">
                                Full Name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-venus-mars"></i>Gender{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="" defaultValue disabled>
                                --- Select Gender ---
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            {submitted && !gender && (
                              <span className="text-danger">
                                Gender is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-field">
                            <label className="form-label">
                              <i className="fa-solid fa-bank"></i> School{" "}
                              <span className="errortext">*</span>
                            </label>
                            <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={stusch}
                        options={schlist}
                        onChange={handleSch}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                           
                            {submitted && !stusch && (
                              <span className="text-danger">
                                School is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-book-open-reader"></i>
                              Class <span className="text-danger">*</span>
                            </label>
                            <select value={stuclass} onChange={selectclass}>
                              <option value="" defaultValue disabled>
                                --- Select Class ---
                              </option>
                              {clslist.map((data, i) => {
                                return (
                                  <option value={data.id} key={i}>
                                    {data.class}
                                  </option>
                                );
                              })}
                            </select>
                            {submitted && !stuclass && (
                              <span className="text-danger">
                                Class is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-group-arrows-rotate"></i>
                              Section <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Section"
                              value={stusec}
                              onChange={(e) =>
                                setStuSec(e.target.value.toUpperCase())
                              }
                            />

                            {submitted && !stusec && (
                              <span className="text-danger">
                                Section is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-calendar"></i>Date of
                              Birth <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              value={dob}
                              max={curdate}
                              onChange={(e) => setDOB(e.target.value)}
                              onKeyPress={(event) => {
                                event.preventDefault();
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                            />
                            {submitted && !dob && (
                              <span className="text-danger">
                                Date of Birth is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-mobile-button"></i>
                              Emergency Contact{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Contact Number"
                              value={mobno}
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={checkmob}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !mobno && (
                              <span className="text-danger">
                                Emergency Contact is required
                              </span>
                            )}
                            {valmob !== "" && mobno && (
                              <span className="text-danger">{valmob}</span>
                            )}
                            {mobnoerr !== "" && mobno && (
                              <span className="text-danger">{mobnoerr}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-at"></i>E-mail Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onBlur={checkemail}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !email && (
                              <span className="text-danger">
                                E-mail Address is required
                              </span>
                            )}
                            {valemail !== "" && email && (
                              <span className="text-danger">{valemail}</span>
                            )}
                            {emailerr !== "" && email && (
                              <span className="text-danger">{emailerr}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="disc-field mb-2">
                        <input
                          type="checkbox"
                          onChange={checknoti}
                          checked={chnoti}
                        />
                        <label className="pl-3">
                          Send me notifications about upcoming tournaments and
                          events
                        </label>
                      </div>
                      <div className="disc-field">
                        <input
                          type="checkbox"
                          onChange={checkterm}
                          checked={check}
                        />
                        <label className="pl-3">
                          By registering my child, I acknowledge and accept that
                          participation involves inherent risks, and I release
                          the school and organizers from liability for any
                          injuries during the event and grant permission to use
                          event photographs for promotional purposes.
                        </label>
                      </div>
                      {errorMsg !== "" && (
                        <span className="text-danger">{errorMsg}</span>
                      )}
                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          <button
                            className="form-btn regis bg-success mt-2"
                            onClick={regsubmit}
                            type="submit"
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                        <div className="col-lg-6 col-md-8">
                          <div className="con-text regis text-right mt-3">
                            <p className="mb-0">
                              Already have an Account?{" "}
                              <strong>
                                <a onClick={backpage}>Back to Login</a>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                   
                  </div>
                </div>
              )}

              {!paypage && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="text-center background-text">
                      <h1 className="mb-3 font-weight-semibold text-danger">
                        Registration successful !
                      </h1>
                    </div>
                    <div className="background-text">
                      <h4 className="mb-3 font-weight-semibold">
                        Hi {fname} {lname},
                      </h4>
                      <div className="successmeg">
                        <p>
                          You have successfully registered for the{" "}
                          <b className="text-success">Student Athlete Hub</b>.
                          Kindly check your email.
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <a onClick={dashlogin} className="btn btn-gray">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
