import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { schoollist, schoolmanagerlist } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useNavigate } from "react-router-dom";
import { schoollistcityreg } from "../../../../services/cityregionaladmin";
import { funderlist } from "../../../../services/master";
import FunderWebUrl from "../../../../AWebFunder";

export const FunderCMSList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3logo, setS3Logo] = useState("");
  const [s3sign, setS3Sign] = useState("");

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => addlist(params)}
              title="Add CMS"
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Publish Status",
      width: 150,
      field: "website_publised_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: params => {
        if (params.value === 'Published') {
            return {color: 'green'};
        }else{
            return {color: 'red'}
        }
    }
    },

    {
      headerName: "Publish Webpage",
      width: 160,
      field: "website_publised_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === "Published") {
          return (
            <div className="text-center">
              <a href={FunderWebUrl + params.data.website_url} target="_blank"
                title={FunderWebUrl + params.data.website_url}
                className="btn-sm btn-success"
              >Visit Webpage</a>
            </div>
          );
        }else {
          return <div className="text-center text-danger">{params.value}</div>;
        }
      },
    },
    {
        headerName: "Logo",
        width: 100,
        field: "logo",
        sortable: false,
        resizable: true,
        filter: false,
        floatingFilter: false,
        cellStyle: { textAlign: "left" },
        cellRenderer: function (params) {
          if (params.value !== null) {
            return (
              <div className="text-center">
                <img
                  style={{ cursor: "pointer", width: "35px", height: "35px" }}
                  src={s3logo + params.value}
                  onClick={() => logoshow(params.value)}
                />
              </div>
            );
          } else {
            return <div className="text-center">-</div>;
          }
        },
      },
    {
      headerName: "Funder Name",
      field: "funder_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
        headerName: "Description",
        field: "funder_description",
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Mobile",
        field: "mobile",
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Address",
        field: "address",
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        cellStyle: { textAlign: "left" },
      },
    
   
  ];

  const navigate = useNavigate();
  function addlist(pdata) {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "add cms details icon in funder list for " +
          pdata.data.school_name +
          " - " +
          pdata.data.school_unique_no +
          " (" +
          pdata.id +
          ") $$ " +
          trackobj.addfcmsheading
      );
      navigate("/superadmin/addfundercms/" + pdata.data.id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "add cms details icon in funder list for " +
          pdata.data.school_name +
          " - " +
          pdata.data.school_unique_no +
          " (" +
          pdata.id +
          ") $$ " +
          trackobj.addfcmsheading
      );
      navigate("/cityregional/addfundercms/" + pdata.data.id);
    }
  }

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }
  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

  

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    if(localStorage.getItem("Role") === "3"){
      
      schoolmanagerlist(localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.funder_list);
          setS3Logo(response.data.funder_logo_path);
        }
      });
    }else{
      funderlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.funders_list);
          setS3Logo(response.data.logo_path);
        }
      });
    }
      
   
  }

  const [ashow, setAShow] = useState(false);
  const [rname, setRName] = useState("");
  const [rdesc, setRDesc] = useState("");

  function repshow() {
    setAShow(true);
    setRName("");
    setRDesc("");
  }
  function repclose() {
    setAShow(false);
    setRName("");
    setRDesc("");
  }

  function addrep(e) {
    e.preventDefault();
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
            <h4 className="mb-0">Funder Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
            <li className="breadcrumb-item active" aria-current="page">
              Funder Lists
            </li>
          
        </ol>
      </div>
     
      
        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    sortingOrder={["asc", "desc"]}
                  />
                </div>

              

                <Modal show={lshow} size="lg" onHide={logoclose}>
                  <Modal.Header>
                    <h4> Funder Logo</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <img src={schlogo} alt="Logo" />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={logoclose}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>

               
              </div>
            </form>
          </div>
        </div>
    </div>
  );
};
