import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  eventtypeactivelist,
  othereventcreate,
  othereventdetails,
  othereventupdate,
} from "../../../../services/superadmin";
import { coachviewprofile } from "../../../../services/coach";
import { schoollistcityreg } from "../../../../services/cityregionaladmin";
import Select from "react-select";

export const AddEventWorkshop = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [typelist, setTypeList] = useState([]);
  const [curdate, setCurdate] = useState("");
  const [ename, setEName] = useState("");
  const [etype, setEType] = useState("");
  const [sdate, setESDate] = useState("");
  const [edate, setEEDate] = useState("");
  const [eodate, setEEODate] = useState("");
  const [eddate, setEEDDate] = useState("");
  const [efees, setEFees] = useState("");
  const [efree, setEFree] = useState("N");
  const [ecir, setECir] = useState("");
  const [elogo, setELogo] = useState("");
  const [ereg, setEReg] = useState("internal");
  const [elink, setELink] = useState("");
  const [agename, setAgeName] = useState("");

  const [schoolid, setSchoolId] = useState("");
  const [schlist, setSchList] = useState([]);

  const [rlink, setRLink] = useState([]);

  useEffect(() => {
    setCurdate(moment(new Date()).format("YYYY-MM-DD"));
    eventtypeactivelist().then((response) => {
      if (response.status === true) {
        setTypeList(response.data);
      }
    });

    if (id !== undefined) {
      othereventdetails(id).then((response) => {
        if (response.status === true) {
          setEName(response.data.events[0].event_name);
          setEType(response.data.events[0].event_type);
          setESDate(response.data.events[0].start_date);
          setEEDate(response.data.events[0].end_date);
          setEEODate(response.data.events[0].entry_start_date);
          setEEDDate(response.data.events[0].entry_end_date);
          setEFees(response.data.events[0].fees);
          setEFree(response.data.events[0].free_or_paid_events);
          setEReg(response.data.events[0].internalorexternal);
          setAgeName(response.data.events[0].event_age_category);
          if (response.data.events[0].logo !== null) {
            setELogo(response.data.logo_path + response.data.events[0].logo);
          }
          if (response.data.tournament_events !== null) {
            if (response.data.tournament_events.length !== 0) {
              let arr = [];
              for (let i = 0; i < response.data.tournament_events.length; i++) {
                arr.push({
                  id: response.data.tournament_events[i].id,
                  qrcode:
                    response.data.qrcode_path +
                    response.data.tournament_events[i].meet_qr_code,
                  schlink: response.data.tournament_events[i].meet_url,
                });
              }
              setELink(response.data.tournament_events[0].meet_url);
              setRLink(arr);
            }
          }
          if(localStorage.getItem("Role") === "3"){
            schoollistcityreg().then((res) => {
              if (res.status === true) {
                let arr1 = [];
                if (res.data.length !== 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].id == response.data.events[0].school_id) {
                      arr1.push({
                        value: res.data[i].id,
                        label:
                          res.data[i].school_unique_no +
                          " - " +
                          res.data[i].school_name,
                      });
                    }
                  }
                }
                setSchoolId(arr1);
              }
            });
          }
         
        }
      });
    }

    if (localStorage.getItem("Role") === "3") {
      schoollistcityreg().then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr1.push({
                value: response.data[i].id,
                label:
                  response.data[i].school_unique_no +
                  " - " +
                  response.data[i].school_name,
              });
            }
          }
          setSchList(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((response) => {
        if (response.status === true) {
          setSchoolId(response.data.school_id);
        }
      });
    }
  }, []);

  const [cirerror, setCirError] = useState("");
  function circfile(event) {
    setCirError("");
    setECir("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf|jpg|jpeg|png)$/i)) {
        setECir(event.target.files[0]);
      } else {
        setCirError("Circular File should be pdf or jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setCirError("Circular File size should be less than 5MB");
      }
    }
  }

  const [lerror, setLError] = useState("");
  function logofile(event) {
    setLError("");
    setELogo("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setELogo(event.target.files[0]);
        // const selectedFile = event.target.files[0];
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const img = new Image();
        //   img.src = e.target.result;
        //   img.onload = function () {
        //     if (img.width === 650 && img.height === 400) {
        //       setELogo(event.target.files[0]);
        //     } else {
        //       setLError("Event Banner dimensions should be 650X400 pixels");
        //     }
        //   };
        // };
        // reader.readAsDataURL(selectedFile);
      } else {
        setLError("Event Banner should be jpg or jpeg or png format");
      }

      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 10) {
        setCirError("Event Banner size should be less than 10MB");
      }
    }
  }

  function backlist() {
    dotracking(
      "back button in edit other events for " +
        ename +
        " (" +
        id +
        ") $$ " +
        trackobj.workdetheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/othereventdetail/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/othereventdetail/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/othereventdetail/" + id);
    }
  }
  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function toursubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMsg("");
    var serror = "";

    if (
      serror === "" &&
      ename !== "" &&
      etype !== "" &&
      sdate !== "" &&
      edate !== "" &&
      eodate !== "" &&
      eddate !== "" &&
      agename !== ""
    ) {
      if (serror === "") {
        if (efree === "N") {
          if (efees === "") {
            serror = "required";
            setErrorMsg("Fees is required");
          }
          if (efees === "0") {
            serror = "required";
            setErrorMsg("Fees must be greater than zero");
          }
        }

        if (serror === "") {
          if (id === undefined) {
            // if (elogo !== "") {
            const temp = new FormData();
            temp.append("event_name", ename);
            temp.append("event_type", etype);
            temp.append("start_date", sdate);
            temp.append("end_date", edate);
            temp.append("entry_start_date", eodate);
            temp.append("entry_end_date", eddate);
            if (efree === "N") {
              temp.append("fees", efees);
            } else {
              temp.append("fees", 0);
            }
            temp.append("free_or_paid_events", efree);
            temp.append("circular_file_1", ecir);
            temp.append("admin_id", localStorage.getItem("UID"));
            temp.append("internalorexternal", ereg);
            temp.append("external_links", elink);
            if (localStorage.getItem("Role") === "3") {
              temp.append("school_id", schoolid.value);
            } else if (localStorage.getItem("Role") === "7") {
              temp.append("school_id", schoolid);
            } else {
              temp.append("school_id", 0);
            }

            temp.append("logo", elogo);

            temp.append("event_age_category", agename);

            dotracking(
              "submit button in add other events for " +
                ename +
                " $$ " +
                trackobj.workaddheading
            );
            othereventcreate(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                if (localStorage.getItem("Role") === "1") {
                  navigate("/superadmin/othereventlist");
                } else if (localStorage.getItem("Role") === "3") {
                  navigate("/cityregional/othereventlist");
                } else if (localStorage.getItem("Role") === "7") {
                  navigate("/coach/othereventlist");
                }
              }
            });
            // } else {
            //   setErrorMsg("Event Banner is required");
            // }
          } else {
            const temp = new FormData();
            temp.append("tournament_id", id);
            temp.append("event_name", ename);
            temp.append("event_type", etype);
            temp.append("start_date", sdate);
            temp.append("end_date", edate);
            temp.append("entry_start_date", eodate);
            temp.append("entry_end_date", eddate);
            temp.append("admin_id", localStorage.getItem("UID"));
            if (efree === "N") {
              temp.append("fees", efees);
            } else {
              temp.append("fees", 0);
            }
            temp.append("free_or_paid_events", efree);
            temp.append("circular_file_1", ecir);
            temp.append("internalorexternal", ereg);
            temp.append("external_links", elink);
            temp.append("logo", elogo);
            temp.append("event_age_category", agename);
            dotracking(
              "submit button in edit other events for " +
                ename +
                "( " +
                id +
                ") $$ " +
                trackobj.workeditheading
            );
            othereventupdate(temp).then((response) => {
              if (response.status === true) {
                toast.success(response.message);
                if (localStorage.getItem("Role") === "1") {
                  navigate("/superadmin/othereventlist");
                } else if (localStorage.getItem("Role") === "3") {
                  navigate("/cityregional/othereventlist");
                } else if (localStorage.getItem("Role") === "7") {
                  navigate("/coach/othereventlist");
                }
              }
            });
          }
        }
      }
    }
  }

  const handleEDate = (e) => {
    if (sdate !== "") {
      setEEDate(e.target.value);
    } else {
      toast.error("Please Select Event Start On");
    }
  };

  const handleOpen = (e) => {
    if (edate !== "") {
      setEEODate(e.target.value);
    } else {
      toast.error("Please Select Event Ends At");
    }
  };

  const handleLast = (e) => {
    if (edate !== "") {
      setEEDDate(e.target.value);
    } else {
      toast.error("Please Select Entry Open at");
    }
  };
  const handlefees = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setEFees(e.target.value);
    }
  };

  function handleSchool(e) {
    if (e !== null) {
      setSchoolId(e);
    } else {
      setSchoolId("");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          {id !== undefined && <h4 className="mb-0">Edit Other Events</h4>}
          {id === undefined && <h4 className="mb-0">Add Other Events</h4>}
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Other Events</a>
          </li>

          {id !== undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              Edit Other Events
            </li>
          )}
          {id === undefined && (
            <li className="breadcrumb-item active" aria-current="page">
              Add Other Events
            </li>
          )}
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Event Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Event Name"
                      value={ename}
                      onChange={(e) => setEName(e.target.value)}
                    />
                    {submitted && !ename && (
                      <span className="errortext">Event Name is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Event Type <span className="errortext"> *</span>
                    </label>
                    <select
                      className="form-control custom-select"
                      value={etype}
                      onChange={(e) => setEType(e.target.value)}
                      //   onChange={handleType}
                    >
                      <option value="" defaultValue disabled>
                        --- Select Event Type ---
                      </option>
                      {typelist.map((data, i) => {
                        return (
                          <option value={data.id} key={i}>
                            {data.event_name}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !etype && (
                      <span className="errortext">Event Type is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Event Start on <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={sdate}
                      min={localStorage.getItem("Role") === "1" ? "" : curdate}
                      onChange={(e) => setESDate(e.target.value)}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !sdate && (
                      <span className="errortext">
                        Event Start On is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Event Ends at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={edate}
                      min={sdate}
                      onChange={handleEDate}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !edate && (
                      <span className="errortext">
                        Event Ends at is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Opens at <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={eodate}
                      min={curdate}
                      max={edate}
                      onChange={handleOpen}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !eodate && (
                      <span className="errortext">
                        Entry Opens at is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Entry Last Date <span className="errortext"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={eddate}
                      min={eodate}
                      max={edate}
                      onChange={handleLast}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                    {submitted && !eddate && (
                      <span className="errortext">
                        Entry Last Date is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Free Event</label>
                    <select
                      className="form-control custom-select"
                      value={efree}
                      onChange={(e) => setEFree(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Free Event--
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                {efree === "N" && efree !== "" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Fees <span className="errortext"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fees"
                        value={efees}
                        onChange={handlefees}
                      />
                    </div>
                  </div>
                )}
                {localStorage.getItem("Role") === "3" && id !== undefined && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schoolid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                        hidden
                        isDisabled
                      />
                      {submitted && !schoolid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}

                {localStorage.getItem("Role") === "3" && id === undefined && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schoolid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                      {submitted && !schoolid && (
                        <span className="errortext">School is required</span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Age Category <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Age Category"
                      value={agename}
                      onChange={(e) => setAgeName(e.target.value)}
                    />
                    {submitted && !agename && (
                      <span className="errortext">
                        Age Category is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Registration Type</label>
                    <select
                      className="form-control custom-select"
                      value={ereg}
                      onChange={(e) => setEReg(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        --Select Registration Type--
                      </option>
                      <option value="internal">Internal</option>
                      <option value="external">External</option>
                    </select>
                  </div>
                </div>
                {ereg === "external" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Registration Link
                        {/* <span className="errortext"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Registration Link"
                        value={elink}
                        onChange={(e) => setELink(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Upload Circular </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".pdf,.png,.jpg,.jpeg"
                      onChange={circfile}
                    />

                    {cirerror !== "" && (
                      <span className="errortext">{cirerror}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Event Banner {/* 650X400 */}
                      <span className="errortext">
                        {" "}
                        (Maximum Size 10mb)
                      </span>{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".png,.jpg,.jpeg"
                      onChange={logofile}
                    />

                    {lerror !== "" && (
                      <span className="errortext">{lerror}</span>
                    )}
                  </div>
                </div>
              </div>

              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3") &&
                id !== undefined && (
                  <>
                    {rlink.length !== 0 && (
                      <div>
                        <hr />
                        <h4>Register Details</h4>
                      </div>
                    )}

                    {rlink.map((data, i) => {
                      return (
                        <div className="row schooldetails mx-0" key={i}>
                          <div className="col-md-6">
                            <div className="details-group">
                              <div className="">
                                <b className="text-inverse">
                                  Registeration Link
                                </b>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="details-group">
                              <div className="text-center">
                                <b className="text-inverse">QR Code</b>
                                <a
                                  href={data.qrcode}
                                  target={"_blank"}
                                  download={data.qrcode}
                                >
                                  <img
                                    src={data.qrcode}
                                    className="tour_fileQR"
                                  />
                                  <p className="mb-0">
                                    <b>Click to Download</b>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="details-group">
                              <div className="text-center">
                                <b className="text-inverse">Login URL</b>
                                <span className="text-inverse">
                                  <a
                                    href={data.schlink}
                                    target={"_blank"}
                                    className="btn btn-secondary"
                                  >
                                    Click Here
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}

              {elogo !== "" && id !== undefined && (
                <>
                  {" "}
                  <h4>Event Banner</h4> <img src={elogo} width="100%" height="300px"/>
                </>
              )}
            </div>
            <div className="card-footer text-center border-top">
              <button
                type="submit"
                className="btn btn-success me-2"
                onClick={toursubmit}
              >
                Submit
              </button>
              {id !== undefined && (
                <a type="submit" className="btn btn-gray" onClick={backlist}>
                  Back
                </a>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
