import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  addathleteagecategorylist,
  addathleteeventslist,
  athletictouraddorderevents,
  athletictourordereventsdelete,
  athletictourordereventslist,
  athletictourordereventsview,
  athletictourupdateorderevents,
} from "../../../../../services/common";
import { Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";
import { tourdetails } from "../../../../../services/register";
import { ColumnGroup } from "ag-grid-community";
import { playerorderofevents } from "../../../../../services/player";
import { coachtourviewdetail } from "../../../../../services/coach";

export const OrderEventsList = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [sfilterProduct, setSFilterProduct] = useState([]);

  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      field: "events_order_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => listdet(params.value, params)}
              title="Start List"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-list fa-lg"></i>
            </a>

            <a
              onClick={() => editdet(params.value, params)}
              title="Edit Order Events"
              className="btn-sm btn-success me-2"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
            {localStorage.getItem("Role") === "1" && (
              <a
                onClick={() => deletedet(params.value, params)}
                title="Delete Order Events"
                className="btn-sm btn-danger me-2"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Heats/Finals",
      field: "heat_or_final",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "age_categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Date",
      field: "event_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Time",
      field: "time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnSDefs = [
    {
      headerName: "Action",
      field: "events_order_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => listdet(params.value, params)}
              title="Start List"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-list fa-lg"></i>
            </a>
          </div>
        );
      },
    },

    {
      headerName: "Heats/Finals",
      field: "heat_or_final",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "age_categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      field: "event_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Time",
      field: "time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.end_date);
        }
      });
    }
    listall();
  }, []);

  const [datehr, setHourlist] = useState([]);

  function listall() {
    setFilterProduct([]);
    setSFilterProduct([]);

    athletictourordereventslist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
    if (localStorage.getItem("Role") === "6") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("user_id", localStorage.getItem("UID"));
      playerorderofevents(temp).then((response) => {
        if (response.status === true) {
          setSFilterProduct(response.data.player_order_of_events);
        }
      });
    }
    var hr = [];
    var add = "";
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        add = String(i).padStart(2, "0");
      } else {
        add = i;
      }
      hr.push(add);
    }
    setHourlist(hr);
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in order of events list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in order of events list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in order of events list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in order of events list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
    } else if (localStorage.getItem("Role") === "5") {
      dotracking(
        "back button in order of events list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/referee/tournamentlist");
    } else if (localStorage.getItem("Role") === "6") {
      if (urllink !== "eventlist") {
        dotracking(
          "back button in order of events list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
      } else {
        dotracking(
          "back button in order of events list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.reglistheading
        );
      }

      if (urllink === "orderofeventlist") {
        navigate("/player/tournamentdetail/" + id);
      } else if (urllink === "registeredorderofeventlist") {
        navigate("/player/registeredviewtournament/" + id);
      } else if (urllink === "eligibleorderofeventlist") {
        navigate("/player/eligibleviewtournament/" + id);
      } else if (urllink === "eventlist") {
        navigate("/player/registeredlist");
      }
    } else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "back button in order of events list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/coach/tournamentlist");
    }
  }
  function listdet(did, ddata) {
    dotracking(
      "list icon in order of events list for (" +
        did +
        ") " +
        ddata.data.event_date +
        " " +
        ddata.data.time +
        " with " +
        ddata.data.age_categories +
        " (" +
        ddata.data.age_category_id +
        ") " +
        ddata.data.events +
        " (" +
        ddata.data.event_id +
        ") " +
        ddata.data.heat_or_final +
        " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourstartlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/eventstartlist/" + id + "/" + did);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/eventstartlist/" + id + "/" + did);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/eventstartlist/" + id + "/" + did);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/eventstartlist/" + id + "/" + did);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/eventstartlist/" + id + "/" + did);
    }
  }

  function deletedet(did, ddata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in order of events list for (" +
            did +
            ") " +
            ddata.data.event_date +
            " " +
            ddata.data.time +
            " with " +
            ddata.data.age_categories +
            " (" +
            ddata.data.age_category_id +
            ") " +
            ddata.data.events +
            " (" +
            ddata.data.event_id +
            ") " +
            ddata.data.heat_or_final +
            " in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourorderlistheading
        );

        athletictourordereventsdelete(did, localStorage.getItem("UID")).then(
          (response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          }
        );
      }
    });
  }

  function editdet(rid) {
    athletictourordereventsview(rid).then((response) => {
      if (response.status === true) {
        dotracking(
          "edit button in order of events list for " +
            response.data.event_date +
            " " +
            response.data.time +
            " with " +
            response.data.age_categories +
            " (" +
            response.data.age_category_id +
            ") events " +
            response.data.events +
            " (" +
            response.data.event_id +
            ") " +
            response.data.heat_or_final +
            " in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourorderlistheading
        );
        setShow(true);
        setOrderID(rid);
        setODate(response.data.event_date);
        let hr = response.data.time.split(":");
        // let dmin = hr[0].split(":");
        // let dmin =  hr[1].slice(0, 2);
        setOhour(hr[0]);
        setOMin(hr[1].slice(0, 2));
        setOFormat(hr[1].slice(-2));

        setOType(response.data.heat_or_final_id + "");

        addathleteagecategorylist(id).then((res) => {
          if (res.status === true) {
            if (res.data.length !== 0) {
              let arr = [];
              for (let i = 0; i < res.data.age_categories.length; i++) {
                arr.push({
                  value: res.data.age_categories[i].id,
                  label: res.data.age_categories[i].age_categories,
                });
              }
              const edata = arr.find((item) => {
                return item.value === response.data.age_category_id;
              });

              setAgeID(edata);
              setAgelist(arr);
            }
          }
        });

        addathleteeventslist(id, response.data.age_category_id).then((res) => {
          if (res.status === true) {
            if (res.data.length !== 0) {
              let arr = [];
              for (let i = 0; i < res.data.age_category_events.length; i++) {
                arr.push({
                  value: res.data.age_category_events[i].id,
                  label: res.data.age_category_events[i].event_name,
                });
              }
              const edata = arr.find((item) => {
                return item.value === response.data.event_id;
              });
              setEventID(edata);
              setEList(arr);
            }
          }
        });
      }
    });
  }

  const [show, setShow] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [odate, setODate] = useState("");
  const [ohour, setOhour] = useState("");
  const [omin, setOMin] = useState("");
  const [oformat, setOFormat] = useState("");

  const [elist, setEList] = useState([]);
  const [eventid, setEventID] = useState("");
  const [agelist, setAgelist] = useState([]);
  const [ageid, setAgeID] = useState("");
  const [otype, setOType] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function handleShow() {
    // setEList([]);
    // setAgelist([]);
    // setODate("");
    // setOhour("");
    // setOMin("");
    // setOType("");
    // setEventID("");
    // setAgeID("");
    setOrderID("");
    setErrorMsg("");
    addathleteagecategorylist(id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          let arr = [];
          for (let i = 0; i < response.data.age_categories.length; i++) {
            arr.push({
              value: response.data.age_categories[i].id,
              label: response.data.age_categories[i].age_categories,
            });
          }
          setAgelist(arr);
        }
      }
    });
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setEList([]);
    setAgelist([]);
    setODate("");
    setOhour("");
    setOMin("");
    setOFormat("");
    setOType("");
    setEventID("");
    setAgeID("");
    setErrorMsg("");
  }

  function handleAge(e) {
    setAgeID("");
    setEList([]);
    setEventID("");
    if (e !== null) {
      setAgeID(e);
      addathleteeventslist(id, e.value).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.age_category_events.length; i++) {
              arr.push({
                value: response.data.age_category_events[i].id,
                label: response.data.age_category_events[i].event_name,
              });
            }
            setEList(arr);
          }
        }
      });
    } else {
      setAgeID("");
      setEList([]);
      setEventID("");
    }
  }

  function handleEvent(e) {
    if (e !== null) {
      setEventID(e);
    } else {
      setEventID("");
    }
  }

  const [osubmit, setOSubmit] = useState(false);
  function submitorder(e) {
    e.preventDefault();
    setErrorMsg("");
    setOSubmit(true);
    if (
      odate !== "" &&
      ohour !== "" &&
      omin !== "" &&
      otype !== "" &&
      eventid !== "" &&
      ageid !== "" &&
      oformat !== ""
    ) {
      const temp = new FormData();
      temp.append("event_date", odate);
      temp.append("time", ohour + ":" + omin + " " + oformat);
      var gtype = "";
      if (otype === "1") {
        temp.append("heat_or_final", "Heats");
        gtype = "Heats";
      } else if (otype === "2") {
        temp.append("heat_or_final", "Finals");
        gtype = "Finals";
      }
      temp.append("heat_or_final_id", otype);
      temp.append("event_id", eventid.value);
      temp.append("events", eventid.label);
      temp.append("age_category_id", ageid.value);
      temp.append("age_categories", ageid.label);
      temp.append("tournament_meet_id", id);
      temp.append("created_by", localStorage.getItem("UID"));
      if (orderID === "") {
        dotracking(
          "submit button in order of events list for " +
            odate +
            " " +
            ohour +
            ":" +
            omin +
            " " +
            oformat +
            " with " +
            ageid.label +
            " (" +
            ageid.label +
            ")" +
            " ,events " +
            eventid.label +
            " (" +
            eventid.label +
            ") " +
            gtype +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourorderlistheading
        );
        athletictouraddorderevents(temp).then((response) => {
          if (response.status === true) {
            setOSubmit(false);
            handleclose();
            toast.success(response.message);
            listall();
          }
        });
      } else {
        temp.append("events_order_id", orderID);
        dotracking(
          "submit button in order of events list for (" +
            orderID +
            ") " +
            odate +
            " " +
            ohour +
            ":" +
            omin +
            " " +
            oformat +
            " with " +
            ageid.label +
            " (" +
            ageid.label +
            ")" +
            " ,events " +
            eventid.label +
            " (" +
            eventid.label +
            ") " +
            gtype +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourorderlistheading
        );
        athletictourupdateorderevents(temp).then((response) => {
          if (response.status === true) {
            setOSubmit(false);
            handleclose();
            toast.success(response.message);
            listall();
          }
        });
      }
    }
  }
  const navigate = useNavigate();
  const gridRef = useRef();

  function importorder() {
    dotracking(
      "export button in order of events list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.ordereventuploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/orderofeventupload/" + id);
    }
  }
  const onexport = useCallback(() => {
    // gridRef.current.api.exportDataAsExcel();
    var excelParams = {
      columnKeys: [
        "event_date",
        "time",
        "events",
        "age_categories",
        "gender",
        "heat_or_final",
      ],
      allColumns: false,
      fileName: "Order of Events List.csv",
    };
    dotracking(
      "export button in order of events list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourorderlistheading
    );
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Order of Events Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Order of Events Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-6">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3" ||
                        localStorage.getItem("Role") === "7") && (
                        <a
                          onClick={onexport}
                          className="btn btn-secondary me-2 mt-1"
                        >
                          <i className="fa-solid fa-file-export"></i> Export
                        </a>
                      )}
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3") && (
                        <>
                          <a
                            className="btn btn-export me-2 mt-1"
                            onClick={importorder}
                            title="Import Order of Events"
                          >
                            <i className="fa-solid fa-file-import"></i> Import
                          </a>
                          <a
                            onClick={handleShow}
                            className="btn btn-success me-2 mt-1"
                          >
                            <i className="fa fa-plus"></i> Add Order of Events
                          </a>
                        </>
                      )}
                      {localStorage.getItem("Role") === "7" &&
                        localStorage.getItem("UID") === tcreate && (
                          <>
                            <a
                              className="btn btn-export me-2 mt-1"
                              onClick={importorder}
                              title="Import Order of Events"
                            >
                              <i className="fa-solid fa-file-import"></i> Import
                            </a>
                            <a
                              onClick={handleShow}
                              className="btn btn-success me-2 mt-1"
                            >
                              <i className="fa fa-plus"></i> Add Order of Events
                            </a>
                          </>
                        )}
                      <a onClick={backlist} className="btn btn-gray mt-1">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>
                {localStorage.getItem("Role") === "6" && (
                  <>
                    <h4 className="text-uppercase errortext">
                      My Order of Events
                    </h4>
                    <div className="table-responsive">
                      <table className="sp-champion-table sp-data-table">
                        <thead className="sp-table-header">
                          <tr>
                            <th className="text-center">Date</th>
                            <th className="text-center">Time</th>
                            <th className="text-center">Category</th>
                            <th className="text-center">Gender</th>
                            <th className="text-center">Events</th>
                            <th className="text-center">Heats / Finals</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sfilterProduct.map((item, j) => {
                            const getRowColor = () => {
                              return j % 2 === 0 ? "even" : "odd";
                            };
                            return (
                              <>
                                <tr
                                  className={`sp-row-no-0 ${getRowColor()}`}
                                  key={j}
                                >
                                  <td className="data-pct">
                                    {moment(item.event_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="data-pct">{item.time}</td>
                                  <td className="data-pct">
                                    {item.age_categories}
                                  </td>
                                  <td className="data-pct">{item.gender}</td>
                                  <td className="data-pct">{item.events}</td>
                                  <td className="data-pct">
                                    {item.heat_or_final}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {localStorage.getItem("Role") !== "1" &&
                  localStorage.getItem("Role") !== "7" && (
                    <form className="card mt-0">
                      <div className="card-body">
                        <h4 className="text-uppercase">
                          Overall Order of Events
                        </h4>

                        <div className="ag-theme-alpine ag-style">
                          <AgGridReact
                            ref={gridRef}
                            columnDefs={columnSDefs}
                            rowData={filterProduct}
                            pagination="true"
                            paginationPageSize="10"
                            sortingOrder={sorting}
                            suppressExcelExport={true}
                            suppressDragLeaveHidesColumns={true}
                            // domLayout="autoWidth"
                            // onGridReady={(params) => {
                            //   params.api.sizeColumnsToFit();
                            // }}
                          />
                        </div>
                      </div>
                    </form>
                  )}

                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "7") && (
                  <div className="ag-theme-alpine ag-style">
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      // domLayout="autoWidth"
                      // onGridReady={(params) => {
                      //   params.api.sizeColumnsToFit();
                      // }}
                    />
                  </div>
                )}

                <Modal show={show} size="lg">
                  <Modal.Header>
                    <h4> Add Order of Events</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Date <span className="errortext"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            max={ddate}
                            min={sdate}
                            value={odate}
                            onChange={(e) => setODate(e.target.value)}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                          {osubmit && !odate && (
                            <span className="errortext">Date is required</span>
                          )}
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="form-group">
                          <label className="mb-2">
                            Hour <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control"
                            value={ohour}
                            onChange={(e) => setOhour(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Hour
                            </option>
                            {datehr.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                          {osubmit && !ohour && (
                            <span className="errortext">Hour is required</span>
                          )}
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="form-group">
                          <label className="mb-2">
                            Minutes <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control"
                            value={omin}
                            onChange={(e) => setOMin(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Minutes
                            </option>
                            <option value="00">00</option>
                            <option value="05">05</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="35">35</option>
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                            <option value="55">55</option>
                          </select>
                          {osubmit && !omin && (
                            <span className="errortext">
                              Minutes is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="form-group">
                          <label className="mb-2">
                            AM/PM <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control"
                            value={oformat}
                            onChange={(e) => setOFormat(e.target.value)}
                          >
                            <option value="" disabled>
                              Select AM/PM
                            </option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                          {osubmit && !oformat && (
                            <span className="errortext">AM/PM is required</span>
                          )}
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Age Category <span className="errortext"> *</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select Your Age Category"
                            value={ageid}
                            options={agelist}
                            onChange={handleAge}
                            isClearable
                            hidden
                          />
                          {osubmit && !ageid && (
                            <span className="errortext">
                              Age Category is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Events <span className="errortext"> *</span>
                          </label>

                          <Select
                            className="dropdown"
                            placeholder="Select Your Event"
                            value={eventid}
                            options={elist}
                            onChange={handleEvent}
                            isClearable
                            hidden
                          />
                          {osubmit && !eventid && (
                            <span className="errortext">Event is required</span>
                          )}
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Event Type <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control"
                            value={otype}
                            onChange={(e) => setOType(e.target.value)}
                          >
                            <option value="" disabled defaultValue>
                              Select Your Type
                            </option>
                            <option value="1">Heats</option>
                            <option value="2">Finals</option>
                          </select>
                          {osubmit && !otype && (
                            <span className="errortext">
                              Event Type is required
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    {orderID !== "" && (
                      <a className="btn btn-success" onClick={submitorder}>
                        Update
                      </a>
                    )}
                    {orderID === "" && (
                      <a className="btn btn-success" onClick={submitorder}>
                        Submit
                      </a>
                    )}
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
