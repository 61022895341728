import React, { useEffect, useState } from "react";
import {
  emailkeywordlist,
  wapptemplates,
  wapptemplatesdetails,
} from "../../../../../services/superadmin";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";

export const WSendImage = () => {
  const [keylist, setKeyList] = useState([]);
  const [content, setContent] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [ltype, setLType] = useState("");
  function toursubmit() {
    setErrorMsg("");
    if (content !== "") {
      if (selectedFile !== "") {
        if (ltype !== "") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("template_id", 20);
          temp.append("content", content);
          temp.append("status", 1);
          temp.append("image_or_video", ltype);
          temp.append("video_link", selectedFile);
          setLoading(true);
          dotracking(
            "submit button in whatsapp send image templates $$ " +
              trackobj.sendvideotempheading +
              trackobj.whattemp
          );
          wapptemplates(temp).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
              listall();
              setLoading(false);
            } else {
              setErrorMsg(response.message);
            }
          });
        } else {
          setErrorMsg("Please Select Link Type");
        }
      } else {
        setErrorMsg("Please Enter The Link");
      }
    } else {
      setErrorMsg("Please Enter the text");
    }
  }

  function listall() {
    wapptemplatesdetails(20).then((response) => {
      if (response.status === true) {
        if (response.data.template_info.message_content !== null) {
          setContent(response.data.template_info.message_content);
        }
        if (response.data.template_info.video_link !== null) {
          setSelectedFile(response.data.template_info.video_link);
        }
        if (response.data.template_info.image_or_video !== null) {
          setLType(response.data.template_info.image_or_video);
        }
      }
    });
  }

  useEffect(() => {
    listall();
    emailkeywordlist(20).then((response) => {
      if (response.status === true) {
        setKeyList(response.data);
      }
    });
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Templates</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Templates</a>
          </li>
          <li className="breadcrumb-item">
            <a>WhatsApp</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Send Image & Video Links
          </li>
        </ol>
      </div>

      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <h4>Send Image & Video Links WhatsApp </h4>
              <div className="table-responsive">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Keyword</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keylist.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.keywords} </td>
                          <td>{data.description} </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <hr />
              <div className="row mt-2">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Message <span className="errortext"> *</span>
                  </label>
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder="Enter Message"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="form-label"> Links <span className="errortext"> *</span> </label>
                    <input
                      type="text"
                      value={selectedFile}
                      className="form-control"
                      placeholder="Enter The Link"
                      onChange={(e) => setSelectedFile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label"> Link Type <span className="errortext"> *</span> </label>
                    <select
                      className="form-control"
                      value={ltype}
                      onChange={(e) => setLType(e.target.value)}
                    >
                      <option value="">Select Link Type</option>
                      <option value="Image">Image</option>
                      <option value="Video">Video</option>
                    </select>
                  </div>
                </div>
              </div>

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
              <hr />
              <div className="text-right">
                <a className="btn btn-success" onClick={toursubmit}>
                  Submit
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
