import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import {
  bulkgeneratecertificate,
  deletecertificate,
  generatecertificate,
  tourcertificatelist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../../trackinglogs";
import Swal from "sweetalert2";
import trackobj from "../../../../trackingpages.json";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  scheadcertificatelist,
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import baseUrl from "../../../../../Api";
import { coachtourviewdetail } from "../../../../../services/coach";

export const GenerateCertificate = () => {
  const { id } = useParams();
  const { eid } = useParams();

  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        if (params.data.certificate_name === null) {
          return (
            <div className="text-center">
              {(localStorage.getItem("Role") === "1"|| localStorage.getItem("Role") === "3") && (
                <a
                className="btn-sm btn-success me-2"
                title="Generate Certificate"
                onClick={() =>
                  certcheck(params.data.certificate_uid, "generate", params)
                }
              >
                <i className="fa fa-plus fa-lg"></i>
              </a>
              )

              }
              
              {localStorage.getItem("Role") === "1" && (
                <a
                  className="btn-sm btn-danger"
                  title="Delete Certificate"
                  onClick={() =>
                    certdelete(params.data.certificate_uid, params)
                  }
                >
                  <i className="fa fa-trash fa-lg"></i>
                </a>
              )}
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-info me-2"
                title="View Certificate"
                onClick={() =>
                  certcheck(params.data.certificate_uid, "download", params)
                }
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>
              {localStorage.getItem("Role") === "1" && (
                <a
                  className="btn-sm btn-danger"
                  title="Delete Certificate"
                  onClick={() =>
                    certdelete(params.data.certificate_uid, params)
                  }
                >
                  <i className="fa fa-trash fa-lg"></i>
                </a>
              )}
            </div>
          );
        }
      },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 120,
      field: "goals",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 120,
      field: "certitype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "grade",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function certdelete(uid, udata) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in tournament certificate list for " +
            udata.data.username +
            " (" +
            udata.data.user_id +
            ") " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.certheading
        );
        deletecertificate(uid, localStorage.getItem("UID")).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function certcheck(uid, utype, udata) {
    dotracking(
      utype +
        " button in tournament certificate list for " +
        udata.data.username +
        " (" +
        udata.data.user_id +
        ") " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.certheading
    );
    setCerId(uid);
    generatecertificate(id, uid, utype, localStorage.getItem("UID")).then(
      (response) => {
        if (response.status === true) {
          if (utype === "generate") {
            toast.success(response.message);
            // window.open(response.data, "_blank");
            listall();
          } else {
            handleShow(response.data);
          }
        }
      }
    );
  }

  const [show, setShow] = useState(false);
  const [certimg, setCertImg] = useState("");

  function handleShow(img) {
    setCertImg(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setCertImg("");
  }

  function backlist() {
    if (localStorage.getItem("Role") !== "4"){
      dotracking(
        "backlist button in tournament certificate list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
    }
   
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if(localStorage.getItem("Cat") === "1"){
        dotracking(
          "backlist button in tournament certificate list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      }else{
        dotracking(
          "backlist button in tournament certificate list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        navigate("/schoolhead/tournamentdetails/"+id);
      }
      
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    } 
  }

  function uploadlist() {
    dotracking(
      "certificate upload button in tournament certificate list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.certuploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/uploadcertificate/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/uploadcertificate/" + id);
    }
  }
  const [loading, setLoading] = useState(false);

  function bulkgen() {
    setLoading(true);
    dotracking(
      "bulk generate button in tournament certificate list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.certheading
    );
    bulkgeneratecertificate(id, localStorage.getItem("UID")).then(
      (response) => {
        if (response.status === true) {
          setLoading(false);
          toast.success(response.message);
          listall();
        } else {
          setLoading(false);
        }
      }
    );
  }

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      setFilterProduct([]);
      schprofileview(localStorage.getItem("UID")).then((res) => {
        if (res.status === true) {
          scheadcertificatelist(id, res.data.school_id).then((response) => {
            if (response.status === true) {
              setFilterProduct(response.data);
            }
          });
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") !== "4") {
      tourcertificatelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }

  const [pshow, setPShow] = useState(false);
  const handlePclose = () => {
    setPShow(false);
    setShow(false);
    dotracking(
      "close button in share for certificate list in " +
        tname +
        " (" +
        id +
        ") tournament with " +
        certimg +
        " certificate $$ " +
        trackobj.certheading
    );
  };
  const [shareUrl, setShare] = useState("");

  function handlePshow(timg) {
    setShow(false);
    setShare(timg);
    setPShow(true);
    dotracking(
      "share button in certificate list for " +
        tname +
        " (" +
        id +
        ") tournament with " +
        timg +
        " certificate $$ " +
        trackobj.certheading
    );
  }
  const [cerid, setCerId] = useState("");

  function certdownload(cname) {
    dotracking(
      "download icon in certificate list for " +
        tname +
        " (" +
        id +
        ") tournament with " +
        cname +
        " (" +
        cerid +
        ") certificate $$ " +
        trackobj.certheading
    );
    // saveAs(s3path + cname, cname);
    window.open(baseUrl + "/certificate-download-in-pdf/" + cerid, "_blank");
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Certificates</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Generate Certficate
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <div className="row">
            <div className="col-12">
              <form className="card mt-0">
                <div className="card-body">
                  <div className="row pb-3">
                    <div className="col-md-7">
                      <div className="text-left">
                        <h3 className="mb-2">{tname}</h3>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="text-right">
                        {(localStorage.getItem("Role") === "1" ||
                          localStorage.getItem("Role") === "3") && (
                          <>
                            <a
                              className="btn btn-success me-2 mt-1"
                              onClick={uploadlist}
                            >
                              <i className="fa-solid fa-upload"></i> Certificate
                              Upload
                            </a>
                            <a
                              className="btn btn-secondary me-2 mt-1"
                              onClick={bulkgen}
                            >
                              <i className="fa-solid fa-certificate"></i>{" "}
                              Generate
                            </a>
                          </>
                        )}

                        <a onClick={backlist} className="btn btn-gray mt-1">
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="ag-theme-alpine ag-style">
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>

                  <Modal show={show} size="lg" onHide={handleclose}>
                    <Modal.Header>
                      <h4>Certificate</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <Row className="row-sm mx-0">
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <img src={certimg} alt="certificate" />
                          </div>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      {localStorage.getItem("Role") === "4" &&
                      <a
                        className="btn btn-success"
                        onClick={() => certdownload(certimg)}
                      >
                        Download
                      </a>
                      }
                      <a
                        className="btn btn-secondary"
                        onClick={() => handlePshow(certimg)}
                      >
                        Share
                      </a>
                      <a className="btn btn-danger" onClick={handleclose}>
                        Close
                      </a>
                    </Modal.Footer>
                  </Modal>

                  <div className={pshow ? "mymodal open" : "mymodal"}>
                    <div className="modoverlay"></div>
                    <div className="mymodal-content">
                      <h4 className="text-center">Share Certificate</h4>
                      <span className="close-btn" onClick={handlePclose}>
                        &times;
                      </span>
                      <div className="border-bottom"></div>
                      <Row className="row-sm mt-3">
                        <div className="text-center">
                          <WhatsappShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <WhatsappIcon size={44} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <FacebookIcon size={44}></FacebookIcon>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <TwitterIcon size={44}></TwitterIcon>
                          </TwitterShareButton>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
