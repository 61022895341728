import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { districtlist, statelist } from "../../../../services/master";
import { citycreate, citydetails, citymasterlist, citystatus, cityupdate, cityvalidate } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const CityMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [cityid, setId] = useState(0);
    const [city, setCity] = useState("");
    const [dist, setDistrict] = useState("");
    const [dlist, setDList] = useState([]);
    const [stateid, setState] = useState("");
    const [slist, setSList] = useState([]);
    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "City",
            field: "cityname",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "District",
            field: "districtname",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "State",
            field: "statename",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "is_active",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deletecity(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "city_uid",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a onClick={() => viewcity(params.value)} title="Edit City"
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>
                );
            },
        },
    ];

    function viewcity(aid) {
        setShow(true)
        citydetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.city_uid)
                setCity(response.data.cityname)
                setState(response.data.state_id)
                setDistrict(response.data.district_id)
                setStatus(response.data.is_active + "")
                setDList([])
                dotracking("edit button in city for " + response.data.cityname + " (" + aid + ") $$ " + trackobj.citylistheading)

                districtlist(response.data.state_id).then((res) => {
                    if (res.status === true) {
                        setDList(res.data)
                    }
                })
            }
        })
    }
    function handleshow() {
        setId(0);
        setCity("");
        setDistrict("");
        setState("");
        setStatus("1");
        setErrorMsg("");
        setShow(true)

    }
    function handleclose() {
        setShow(false);
        setCity("");
        setDistrict("");
        setState("");
        setStatus("1");
        setErrorMsg("");
    }

    function deletecity(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("city_uid", list.data.city_uid)
                    temp.append("is_active", 0)
                    dotracking("status icon in city list from Active to Inactive for " + list.data.cityname + " (" + list.data.city_uid + ") $$ " + trackobj.citylistheading)
                    citystatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("city_uid", list.data.city_uid)
                    temp.append("is_active", 1)
                    dotracking("status icon in city list from Inactive to Active for " + list.data.cityname + " (" + list.data.city_uid + ") $$ " + trackobj.citylistheading)
                    citystatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }
    useEffect(() => {
        listall();
        statelist().then((response) => {
            if (response.status === true) {
                setSList(response.data)
            }
        })
    }, []);

    function listall() {
        setFilterProduct([]);
        citymasterlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function selectstate(e) {
        setState(e.target.value)
        setDList([])
        setDistrict("");
        if (e.target.value !== "") {
            districtlist(e.target.value).then((res) => {
                if (res.status === true) {
                    setDList(res.data)
                }
            })
        }

    }
    function submitcity(e) {
        e.preventDefault();
        setErrorMsg("")
        if (city !== "") {
            if (dist !== "") {
                if (stateid !== "") {
                    if (cityid === 0) {
                        const temp1 = new FormData();
                        temp1.append("city", city)
                        temp1.append("state_id", stateid)
                        temp1.append("district", dist)
                        cityvalidate(temp1).then((res) => {
                            if (res.status === true) {
                                const temp = new FormData();
                                temp.append("cityname", city)
                                temp.append("state_id", stateid)
                                temp.append("district_id", dist)
                                temp.append("is_active", status)
                                dotracking("add button in city list for " + city + " $$ " + trackobj.citylistheading)
                                citycreate(temp).then((response) => {
                                    if (response.status === true) {
                                        handleclose();
                                        listall();
                                        toast.success(response.message);
                                    }
                                })
                            } else {
                                Swal.fire({
                                    text: city + " " + res.message,
                                    icon: "warning",
                                    showCancelButton: false,
                                    confirmButtonColor: "#4ecc48",
                                    cancelButtonColor: "#6259ca",
                                    confirmButtonText: "Ok",
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setCity("");
                                        setId(0);
                                        setDistrict("");
                                        setState("");
                                        setStatus("1");
                                        setErrorMsg("");
                                    }
                                });
                            }
                        })

                    } else if (cityid !== 0) {
                        const temp = new FormData();
                        temp.append("city_uid", cityid)
                        temp.append("cityname", city)
                        temp.append("state_id", stateid)
                        temp.append("district_id", dist)
                        temp.append("is_active", status)
                        dotracking("update button in city list for " + city + " (" + cityid + ") $$ " + trackobj.citylistheading)
                        cityupdate(temp).then((response) => {
                            if (response.status === true) {
                                handleclose();
                                listall();
                                toast.success(response.message);
                            }
                        })
                    }
                } else {
                    setErrorMsg("Please Select State")
                }

            } else {
                setErrorMsg("Please Select District")
            }
        } else {
            setErrorMsg("Please Enter City Name")
        }

    }


    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">City Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        City Lists
                    </li>
                </ol>
            </div>
            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            <div className="text-right">
                                <a className="btn btn-success" onClick={handleshow}>
                                    <i className="fa fa-plus"></i> Add City
                                </a>
                            </div>
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    // domLayout="autoWidth"
                                    // onGridReady={(params) => {
                                    //     params.api.sizeColumnsToFit();
                                    // }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {cityid !== 0 &&
                                        <h4> cityact Details</h4>
                                    }
                                    {cityid === 0 &&
                                        <h4> Add City</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    City Name{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="City Name"
                                                    className="form-control"
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    State{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <select className="form-control custom-select" value={stateid}
                                                    onChange={selectstate}>
                                                    <option value="" defaultValue disabled>--- Select State ---</option>
                                                    {slist.map((data, i) => {
                                                        return (
                                                            <option value={data.stateuid} key={i}>
                                                                {data.statename}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    District{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <select className="form-control custom-select" value={dist}
                                                    onChange={(e) => setDistrict(e.target.value)}>
                                                    <option value="" defaultValue disabled>--- Select District ---</option>
                                                    {dlist.map((data, i) => {
                                                        return (
                                                            <option value={data.districtuid} key={i}>
                                                                {data.districtname}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>
                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {cityid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitcity}
                                        >
                                            Update
                                        </a>
                                    }
                                    {cityid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitcity}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
