import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { tourdelete, tourlist } from "../../../../../services/superadmin";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  citytourlist,
  crmtourdelete,
} from "../../../../../services/cityregionaladmin";
import { Reftourlist } from "../../../../../services/referee";
import {
  schooltourlist,
  schprofileview,
} from "../../../../../services/schoolhead";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import moment from "moment";
import { sportslist } from "../../../../../services/master";
import { data } from "jquery";
import { interschooltourpaidornot } from "../../../../../services/interschool";
import { coachtourlist, coachviewprofile } from "../../../../../services/coach";
import exportUrl from "../../../../../exportUtl";
import { fundertourlist } from "../../../../../services/common";
import { funderprofile, fundtournamentlist } from "../../../../../services/funder";

export const ListTour = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sportlist, setSportList] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Actions",
      width: 460,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params)}
              title="View Tournament"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {localStorage.getItem("Role") === "1" &&
              params.data.meet_type !== "Inter School Tournament" && (
                <a
                  onClick={() => sendlink(params.value, params)}
                  title="Send Registeration Link"
                  className="btn-sm btn-import me-2"
                >
                  <i className="fa fa-paper-plane fa-lg"></i>
                </a>
              )}
            {params.data.sports_type !== "ATHLETICS" && (
              <a
                onClick={() => catdet(params.value, params)}
                title="Create Category List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-list fa-lg"></i>
              </a>
            )}
            {params.data.sports_type === "ATHLETICS" &&
              params.data.meet_type === "Inter School Tournament" &&
              localStorage.getItem("Role") === "3" && (
                <>
                  {params.data.paid_status === 1 && (
                    <a
                      onClick={() => adddet(params.value, params)}
                      title="Confirmation Players"
                      className="btn-sm btn-gray me-2"
                    >
                      <i className="fa fa-vcard fa-lg"></i>
                    </a>
                  )}
                  {params.data.paid_status !== 1 &&
                    moment(new Date()).format("yyyy-MM-DD") <=
                      params.data.entry_deadline_date && (
                      <a
                        onClick={() => adddet(params.value, params)}
                        title="Add Players"
                        className="btn-sm btn-warning me-2"
                      >
                        <i className="fa fa-user-plus fa-lg"></i>
                      </a>
                    )}
                </>
              )}
            {/* <a
              onClick={() => teamdet(params.value,params)}
              title="Team List"
              className="btn-sm btn-warning me-2"
            >
              <i className="fa fa-list fa-lg"></i>
            </a> */}
            <a
              onClick={() => playerdet(params.value, params)}
              title="Entry List"
              className="btn-sm btn-gray me-2"
            >
              <i className="fas fa-users fa-lg"></i>
            </a>
            {params.data.sports_type === "ATHLETICS" && (
              <a
                onClick={() => orderdet(params.value, params)}
                title="Order Events List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-calendar fa-lg"></i>
              </a>
            )}
            {(localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3" ||
              localStorage.getItem("Role") === "7") && (
              <a
                onClick={() => offdet(params.value, params)}
                title="Offline Entry"
                className="btn-sm btn-success me-2"
              >
                <i className="fas fa-upload fa-lg"></i>
              </a>
            )}

            {/* <a
              onClick={() => schdet(params.value,params)}
              title="Create Pool"
              className="btn-sm btn-warning me-2"
            >
              <i className="fas fa-object-group"></i>
            </a> */}

            <a
              onClick={() => resdet(params.value, params)}
              title="Result List"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fas fa-trophy fa-lg"></i>
            </a>
            <a
              title="Upload Photos"
              onClick={() => photodet(params.value, params)}
              className="btn-sm btn-export me-2"
            >
              <i className="fa fa-picture-o fa-lg"></i>
            </a>

            <a
              title="Upload Highlights"
              onClick={() => highdet(params.value, params)}
              className="btn-sm btn-gray me-2"
            >
              <i className="fa fa-link fa-lg"></i>
            </a>
            {params.data.multi_sports == 1 && (
              <a
                title="Leaderboard"
                onClick={() => leaddet(params.value, params)}
                className="btn-sm btn-leader me-2"
              >
                <i className="fa-solid fa-ranking-star fa-lg"></i>
              </a>
            )}

            {(localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3" ||
              localStorage.getItem("Role") === "7") && (
              <>
                <a
                  title="Upload Certificates"
                  onClick={() => certdet(params.value, params)}
                  className="btn-sm btn-warning me-2"
                >
                  <i className="fa fa-certificate fa-lg"></i>
                </a>
                {/* <a
                  onClick={() => deletetour(params.value,params)}
                  title="Delete Tournament"
                  className="btn-sm btn-danger me-2"
                >
                  <i className="fa fa-trash fa-lg"></i>
                </a> */}
              </>
            )}
            {localStorage.getItem("Role") !== "7" && (
              <a
                title="Analytics"
                onClick={() => analdet(params.value, params)}
                className="btn-sm btn-import me-2"
              >
                <i className="fa fa-line-chart fa-lg"></i>
              </a>
            )}
            {localStorage.getItem("Role") === "7" && (
              <a
                title="Download Tournament Report"
                onClick={() => repdet(params.value, params)}
                className="btn-sm btn-import me-2"
              >
                <i className="fa fa-download fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entryopen",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entrydeadline",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 170,
      field: "meet_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (localStorage.getItem("Role") === "3") {
          return params.data.meettype;
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Category (Class)",
      width: 170,
      field: "standard_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnSchDefs = [
    {
      headerName: "Actions",
      width: 410,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params)}
              title="View Tournament"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {params.data.meettype === "Inter School Tournament" &&
              params.data.sports_type !== "ATHLETICS" &&
              localStorage.getItem("Role") === "4" && (
                <a
                  onClick={() => addcat(params.value, params)}
                  title="Add Players"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fa fa-user-plus fa-lg"></i>
                </a>
              )}
            {params.data.sports_type !== "ATHLETICS" && (
              <a
                onClick={() => catdet(params.value, params)}
                title="Create Category List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-list fa-lg"></i>
              </a>
            )}

            {params.data.sports_type === "ATHLETICS" &&
              params.data.meettype === "Inter School Tournament" &&
              localStorage.getItem("Role") === "4" && (
                <>
                  {params.data.paid_status === 1 && (
                    <a
                      onClick={() => adddet(params.value, params)}
                      title="Confirmation Players"
                      className="btn-sm btn-gray me-2"
                    >
                      <i className="fa fa-vcard fa-lg"></i>
                    </a>
                  )}
                  {params.data.paid_status !== 1 &&
                    moment(new Date()).format("yyyy-MM-DD") <=
                      params.data.entry_deadline_date && (
                      <a
                        onClick={() => adddet(params.value, params)}
                        title="Add Players"
                        className="btn-sm btn-success me-2"
                      >
                        <i className="fa fa-user-plus fa-lg"></i>
                      </a>
                    )}
                </>
              )}
            <a
              onClick={() => playerdet(params.value, params)}
              title="Entry List"
              className="btn-sm btn-gray me-2"
            >
              <i className="fas fa-users fa-lg"></i>
            </a>

            {params.data.sports_type === "ATHLETICS" && (
              <a
                onClick={() => orderdet(params.value, params)}
                title="Order Events List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-calendar fa-lg"></i>
              </a>
            )}

            <a
              onClick={() => resdet(params.value, params)}
              title="Result List"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fas fa-trophy fa-lg"></i>
            </a>
            <a
              title="Upload Photos"
              onClick={() => photodet(params.value, params)}
              className="btn-sm btn-export me-2"
            >
              <i className="fa fa-picture-o fa-lg"></i>
            </a>

            <a
              title="Highlights"
              onClick={() => highdet(params.value, params)}
              className="btn-sm btn-gray me-2"
            >
              <i className="fa fa-link fa-lg"></i>
            </a>
            {params.data.multi_sports == 1 && (
              <a
                title="Leaderboard"
                onClick={() => leaddet(params.value, params)}
                className="btn-sm btn-leader me-2"
              >
                <i className="fa-solid fa-ranking-star fa-lg"></i>
              </a>
            )}
            <a
              title="Certificates"
              onClick={() => certdet(params.value, params)}
              className="btn-sm btn-warning me-2"
            >
              <i className="fa fa-certificate fa-lg"></i>
            </a>
            {localStorage.getItem("Role") === "4" && (
              <a
                title="Download Tournament Report"
                onClick={() => repdet(params.value, params)}
                className="btn-sm btn-import me-2"
              >
                <i className="fa fa-download fa-lg"></i>
              </a>
            )}
            {/* <a
              title="Analytics" onClick={() => analdet(params.value,params)}
              className="btn-sm btn-import me-2"
            >
              <i className="fa fa-line-chart fa-lg"></i>
            </a> */}
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entryopen",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entrydeadline",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 170,
      field: "meettype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Category (Class)",
      width: 170,
      field: "standard_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnFDefs = [
    {
      headerName: "Actions",
      width: 150,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params)}
              title="View Tournament"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>

            {/* {params.data.sports_type !== "ATHLETICS" && (
              <a
                onClick={() => catdet(params.value, params)}
                title="Create Category List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-list fa-lg"></i>
              </a>
            )} */}

            <a
              onClick={() => resdet(params.value, params)}
              title="Result List"
              className="btn-sm btn-export me-2"
            >
              <i className="fas fa-trophy fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Code",
    //   width: 150,
    //   field: "meet_code",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Location",
    //   width: 170,
    //   field: "location",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Entry Open",
    //   width: 150,
    //   field: "entryopen",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Deadline Date",
    //   width: 150,
    //   field: "entrydeadline",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Type",
      width: 170,
      field: "meet_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Category (Class)",
      width: 170,
      field: "standard_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    // {
    //   headerName: "State",
    //   width: 150,
    //   field: "statename",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
  ];

  const navigate = useNavigate();
  function viewdet(tid, tdata) {
    dotracking(
      "view icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "1");
      navigate("/schoolhead/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/tournamentdetails/" + tid);
    }
  }

  function sendlink(sid, tdata) {
    dotracking(
      "send registeration link icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        sid +
        ") tournament $$ " +
        trackobj.reglinkheading
    );
    navigate("/superadmin/sendregisteration/" + sid);
  }
  function catdet(tid, tdata) {
    dotracking(
      "category icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Cat", "1");
      navigate("/schoolhead/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      localStorage.setItem("Cat", "5");

      if (tdata.data.meettype === "Inter School Tournament") {
        coachviewprofile(localStorage.getItem("UID")).then((res) => {
          if (res.status === true) {
            navigate(
              "/coach/intercategorylist/" + tid + "/" + res.data.school_id
            );
          }
        });
      } else if (tdata.data.meettype === "Intra School Tournament") {
        navigate("/coach/categorylist/" + tid);
      }
    } else if (localStorage.getItem("Role") === "8") {
      navigate("/funder/categorylist/" + tid);
    }
  }

  function addcat(tid, tdata) {
    if (localStorage.getItem("Role") === "4") {
      schprofileview(localStorage.getItem("UID")).then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("tournament_meet_id", tid);
          temp.append("school_id", res.data.school_id);
          interschooltourpaidornot(temp).then((response) => {
            if (response.status === true) {
              if (response.data !== null) {
                dotracking(
                  "category list icon in tournament list for " +
                    tdata.data.meet_name +
                    " (" +
                    tid +
                    ") tournament $$ " +
                    trackobj.agecatlistheading
                );
                localStorage.setItem("Age", "1");
                navigate("/schoolhead/intercategorylist/" + tid);
              } else {
                dotracking(
                  "agecategory payment icon in tournament list for " +
                    tdata.data.meet_name +
                    " (" +
                    tid +
                    ") tournament $$ " +
                    trackobj.agecatlistheading
                );
                localStorage.setItem("Age", "1");
                navigate("/schoolhead/interschoolpaymentlist/" + tid);
              }
            }
          });
        }
      });
    }
  }

  function adddet(tid, tdata) {
    if (tdata.data.paid_status === 1) {
      dotracking(
        "confirmation list in tournament list for " +
          tdata.data.meet_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.confirmplayerheading
      );
      if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/confirmation/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "1");
        navigate("/schoolhead/confirmation/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "3") {
        dotracking(
          "player list in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/cityregional/tournamentschoollist/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        dotracking(
          "player list in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        localStorage.setItem("Page", "1");

        navigate("/schoolhead/playerlist/" + tid);
      }
    }
  }

  // function teamdet(tid) {
  //   if (localStorage.getItem("Role") === "1") {
  //     navigate("/superadmin/teamlist/" + tid);
  //   } else if (localStorage.getItem("Role") === "3") {
  //     navigate("/cityregional/teamlist/" + tid);
  //   } else if (localStorage.getItem("Role") === "4") {
  //     navigate("/schoolhead/teamlist/" + tid);
  //   } else if (localStorage.getItem("Role") === "5") {
  //     navigate("/referee/teamlist/" + tid);
  //   }
  // }

  function playerdet(tid, tdata) {
    if (tdata.data.sports_type.toUpperCase() === "ATHLETICS") {
      if (localStorage.getItem("Role") === "1") {
        if (tdata.data.meet_type === "Inter School Tournament") {
          dotracking(
            "school tournament icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourschoollistheading
          );
          navigate("/superadmin/athleticschoollist/" + tid);
        } else {
          dotracking(
            "school tournament icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          navigate("/superadmin/athleticsentryplayerslist/" + tid);
        }
      } else if (localStorage.getItem("Role") === "3") {
        dotracking(
          "school tournament icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/cityregional/athleticschoollist/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        dotracking(
          "player entry list icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        localStorage.setItem("Page", "1");
        navigate("/schoolhead/athleticsentryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        dotracking(
          "player entry list icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/referee/athleticschoollist/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "1") {
        if (tdata.data.meet_type === "Inter School Tournament") {
          dotracking(
            "school tournament icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourschoollistheading
          );
          navigate("/superadmin/tournamentschoollist/" + tid);
        } else {
          dotracking(
            "player entry list icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          navigate("/superadmin/entryplayerslist/" + tid);
        }
      } else if (localStorage.getItem("Role") === "3") {
        if (tdata.data.meettype === "Inter School Tournament") {
          dotracking(
            "school tournament icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourschoollistheading
          );
          navigate("/cityregional/tournamentschoollist/" + tid);
        } else {
          dotracking(
            "player entry list icon in tournament list for " +
              tdata.data.meet_name +
              " (" +
              tid +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          navigate("/cityregional/entryplayerslist/" + tid);
        }
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "1");
        navigate("/schoolhead/entryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/entryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "7") {
        dotracking(
          "player entry list icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerlistheading
        );
        navigate("/coach/entryplayerslist/" + tid);
      }
    }
  }

  function orderdet(tid, tdata) {
    dotracking(
      "order of event list icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.tourorderlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "1");
      navigate("/schoolhead/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/orderofeventlist/" + tid);
    }
  }

  function resdet(tid, tdata) {
    dotracking(
      "result icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.resultheading
    );
    if (tdata.data.sports_type.toUpperCase() === "ATHLETICS") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "1");
        navigate("/schoolhead/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/athleticsresults/" + tid);
      }else if (localStorage.getItem("Role") === "8") {
        navigate("/funder/athleticsresults/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/results/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/results/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "1");
        navigate("/schoolhead/results/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/results/" + tid);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/results/" + tid);
      }else if (localStorage.getItem("Role") === "8") {
        navigate("/funder/results/" + tid);
      }
    }
  }

  function photodet(tid, tdata) {
    dotracking(
      "upload photo icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.photouploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "1");

      navigate("/schoolhead/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/photoupload/" + tid);
    }
  }

  function highdet(tid, tdata) {
    dotracking(
      "upload highlight icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.highheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "1");

      navigate("/schoolhead/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/highlights/" + tid);
    }
  }

  function leaddet(tid, tdata) {
    dotracking(
      "leaderboard icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.leadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/leaderboard/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/leaderboard/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/leaderboard/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/leaderboard/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/leaderboard/" + tid);
    }
  }

  // function schdet(tid) {
  //   if (localStorage.getItem("Role") === "1") {
  //     navigate("/superadmin/poollist/" + tid);
  //   } else if (localStorage.getItem("Role") === "3") {
  //     navigate("/cityregional/poollist/" + tid);
  //   } else if (localStorage.getItem("Role") === "4") {
  //     navigate("/schoolhead/poollist/" + tid);
  //   } else if (localStorage.getItem("Role") === "5") {
  //     navigate("/referee/poollist/" + tid);
  //   }
  // }

  function offdet(tid, tdata) {
    dotracking(
      "upload offline entry icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.offlineheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/offlineentry/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/offlineentry/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/offlineentry/" + tid);
    }
  }

  function certdet(tid, tdata) {
    dotracking(
      "upload certificate icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.certheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/certificatelist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/certificatelist/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Cat", "1");
      navigate("/schoolhead/certificatelist/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/certificatelist/" + tid);
    }
  }

  function repdet(rid, rdata) {
    if (
      localStorage.getItem("Role") === "4" ||
      localStorage.getItem("Role") === "7"
    ) {
      dotracking(
        "download tournament report icon in tournament list for " +
          rdata.data.meet_name +
          " (" +
          rid +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      window.open(
        exportUrl + "export-tournament-report/" + rid + "/1",
        "_blank"
      );
    }
  }

  function analdet(aid, tdata) {
    dotracking(
      "analytics icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        aid +
        ") tournament $$ " +
        trackobj.analyticsheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/analytics/" + aid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/analytics/" + aid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "1");
      navigate("/schoolhead/analytics/" + aid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/analytics/" + aid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/analytics/" + aid);
    }
  }

  function deletetour(tid, tdata) {
    dotracking(
      "delete icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.tourlistheading
    );
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("Role") === "1") {
          tourdelete(tid).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        } else if (localStorage.getItem("Role") === "3") {
          crmtourdelete(tid).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      }
    });
  }
  let arr = [];
  useEffect(() => {
    listall();

    sportslist().then((response) => {
      if (response.status === true) {
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].sports_cat_uid,
              label: response.data[i].sports_category,
            });
          }
          setSportList(arr);
        }
      }
    });
  }, []);

  function listall() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "1") {
      filterall("0", "0");
    } else if (localStorage.getItem("Role") === "3") {
      citytourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schooltourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.upcming_tournaments);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachtourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      fundtournamentlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    }
  }

  function filterall(fid, fsport) {
    setTtype(fid);
    if (fsport === null) {
      setSport("0");
    } else {
      setSport(fsport);
    }
    setSport(fsport);
    setFilterProduct([]);
    if (fid === "0" && fsport === "0") {
      tourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    } else if (fid !== "0" && fsport === "0") {
      tourlist().then((response) => {
        if (response.status === true) {
          if (response.data.tournaments.length !== 0) {
            if (fid === "1") {
              const fdata = response.data.tournaments.filter(
                (item) =>
                  item.start_date < moment(new Date()).format("YYYY-MM-DD")
              );
              setFilterProduct(fdata);
            } else if (fid === "2") {
              const fdata = response.data.tournaments.filter(
                (item) =>
                  item.start_date > moment(new Date()).format("YYYY-MM-DD")
              );
              setFilterProduct(fdata);
            }
          }
        }
      });
    } else if (fid === "0" && fsport !== "0") {
      tourlist().then((response) => {
        if (response.status === true) {
          if (response.data.tournaments.length !== 0) {
            const fdata = response.data.tournaments.filter(
              (item) => item.sports_type_no === fsport
            );
            setFilterProduct(fdata);
          }
        }
      });
    } else if (fid !== "0" && fsport !== "0") {
      tourlist().then((response) => {
        if (response.status === true) {
          if (response.data.tournaments.length !== 0) {
            if (fid === "1") {
              const fdata = response.data.tournaments.filter(
                (item) =>
                  item.start_date < moment(new Date()).format("YYYY-MM-DD")
              );
              const sdata = fdata.filter(
                (item) => item.sports_type_no === fsport
              );
              setFilterProduct(sdata);
            } else if (fid === "2") {
              const fdata = response.data.tournaments.filter(
                (item) =>
                  item.start_date > moment(new Date()).format("YYYY-MM-DD")
              );
              const sdata = fdata.filter(
                (item) => item.sports_type_no === fsport
              );
              setFilterProduct(sdata);
            }
          }
        }
      });
    }
  }
  const [ttype, setTtype] = useState("0");
  const [tsport, setSport] = useState("0");

  const gridRef = useRef();
  // const onPageSizeChanged = useCallback(() => {
  //     var value = document.getElementById('page-size').value;
  //     alert(value)
  //     gridRef.current.api.paginationSetPageSize(Number(value));
  // }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              {localStorage.getItem("Role") === "1" && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        value={ttype}
                        className="form-control"
                        onChange={(e) => filterall(e.target.value, tsport)}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Tournament ---
                        </option>
                        <option value={0}>All Tournaments</option>
                        <option value={1}>Past Tournaments</option>
                        <option value={2}>Upcoming Tournaments</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        value={tsport}
                        className="form-control"
                        onChange={(e) => filterall(ttype, e.target.value)}
                      >
                        <option value={0}>All Sports</option>
                        {sportlist.map((data, i) => {
                          return (
                            <option key={i} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3" ||
                localStorage.getItem("Role") === "7") && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              )}
              {(localStorage.getItem("Role") === "4" ||
                localStorage.getItem("Role") === "5") && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnSchDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              )}

              {localStorage.getItem("Role") === "8" && (
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnFDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
