import moment from "moment";
import { getlocation, trackingdata } from "../services/login";
import exporttrack from "../tracking";

const dotracking = function (aname) {
  getlocation().then((res) => {
    const temp = new FormData();
    let yourString = aname;
    let stringResult = yourString.split(",");
    if (stringResult[0] === "signout") {
      temp.append("user_id", stringResult[1]);
      temp.append("role_id", stringResult[2]);
      temp.append("role_name", stringResult[3]);
      temp.append("action", exporttrack + "signout button");
      temp.append("description", "Signout");
    } else {
      if (localStorage.getItem("UID") !== null) {
        temp.append("user_id", localStorage.getItem("UID"));
      } else {
        temp.append("user_id", 0);
      }
      if (localStorage.getItem("Role") !== null) {
        temp.append("role_id", localStorage.getItem("Role"));
      } else {
        temp.append("role_id", 0);
      }
      if (localStorage.getItem("RoleName") !== null) {
        temp.append("role_name", localStorage.getItem("RoleName"));
      } else {
        temp.append("role_name", "-");
      }
      // var dash = false;
      // if(localStorage.getItem("Role") === "4"){
      //   dash = true;
      // }else if(localStorage.getItem("Role") === "5"){
      //   dash = true;
      // }else if(localStorage.getItem("Role") === "6"){
      //   dash = true;
      // }
      // if (!dash) {
      //   temp.append("action", exporttrack + aname);
      //   temp.append("description", "Signout");
      // } else {
        var curact = aname;
        var curname = curact.split("$$");

        temp.append("action", exporttrack + curname[0]);
        temp.append("description", curname[1]);
      // }
    }

    if (res.IPv4 !== null) {
      temp.append("ip_address", res.IPv4);
    } else {
      temp.append("ip_address", "");
    }
    temp.append("author", "");
    temp.append("operating_system", "");
    temp.append("gamil", "");
    temp.append("other_apps", "");
    temp.append("link_changing", "");
    temp.append("staying_hours", "");
    temp.append("current_status", "");
    temp.append("mobile_no", "");
    temp.append("clicked_menus", "");
    temp.append("type", "");
    temp.append("tying_to_password_time", "");
    temp.append("socialmedia_click_users", "");
    temp.append("country", "");
    temp.append("logged_out_time", "");
    temp.append("last_seen", "");
    temp.append("status", "");
    temp.append("browser", navigator.userAgent);

    temp.append("location", "Lat:" + res.latitude + " Long:" + res.longitude);
    temp.append(
      "logged_in_time",
      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );

    trackingdata(temp).then((response) => {
      if (response.status === true) {

      }
    });
  });
};

export default dotracking;
