import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import JoditEditor from "jodit-react";
import Select from "react-select";
import "../../../../assets/css/funder.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  cmsresourcecreate,
  coachcmsresourcelist,
  fundermedalslist,
  photoslist,
  playsessioncreate,
  playsessionupdate,
  playsessionview,
  schoolstatementcreate,
  schoolstatementupdate,
  schoolstatementview,
  storycreate,
  storydelete,
  storylist,
  storyupdate,
  storyview,
} from "../../../../services/superadmin";
import Swal from "sweetalert2";
import moment from "moment";
import {
  cmstaglist,
  cmstagview,
  fundergallerylist,
  funderpublishtrack,
  funderresourceslist,
  funderschoollist,
  fundertourlist,
  grpgallerylist,
  grpmedalslist,
  grpresourceslist,
  grpschoollist,
  grpschpublishtrack,
  grptourlist,
} from "../../../../services/common";
import { funderdetail, grpschdetail } from "../../../../services/master";
import { toast } from "react-toastify";
import FunderWebUrl from "../../../../AWebFunder";
import GroupWebUrl from "../../../../AWebSchool";
export const FunderCMSDetails = () => {
  const { id } = useParams();
  const [schname, setSchName] = useState("");

  const [fundname, setFundName] = useState("");
  const [funddesc, setFundDesc] = useState("");
  const [fundmail, setFundMail] = useState("");
  const [fundmob, setFundMob] = useState("");
  const [fundaddr, setFundAddr] = useState("");
  const [logofile, setLogoFile] = useState("");
  const [spublish, setSPublish] = useState("");
  const [puburl, setPubUrl] = useState("");

  const [schlist, setSchList] = useState([]);
  const [schpath, setSchPath] = useState("");

  const [tlist, setTList] = useState([]);
  const [elist, setEList] = useState([]);
  const [tpath, setTPath] = useState("");

  const [stlist, setStList] = useState([]);
  const [stpath, setStPath] = useState("");

  const [gllist, setGList] = useState([]);
  const [gtpath, setGtPath] = useState("");

  const [reslist, setResList] = useState([]);
  const [creslist, setCResList] = useState([]);

  const [respath, setResPath] = useState("");

  const [scolor, setSColor] = useState("#fff"); // Default background color
  const [tcolor, setTColor] = useState("#fff"); // Default background color
  const [mcolor, setMColor] = useState("#5f6387"); // Default background color
  const [sscolor, setSSColor] = useState("#fff"); // Default background color
  const [gcolor, setGColor] = useState("#fff"); // Default background color
  const [rcolor, setRColor] = useState("#f6f6f8"); // Default background color

  const colorOptions = [
    { label: "Red", value: "#FF0000" },
    { label: "Blue", value: "#0000FF" },
    { label: "Green", value: "#00FF00" },
    { label: "Yellow", value: "#FFFF00" },
    { label: "Magenta", value: "#800080" },
    { label: "White", value: "#fff" },
    { label: "Jet Gray", value: "#5f6387" },
    { label: "White Smoke", value: "#f6f6f8" },
    { label: "Dark Sky Blue", value: "#3498db" },
    { label: "Mint Green", value: "#2ecc71" },
    { label: "Orangy Red", value: "#e74c3c" },
    { label: "Yellowish Orange", value: "#f39c12" },
    { label: "Purple-Magenta", value: "#9b59b6" },
    { label: "Cyan-Blue", value: "#34495e" },
  ];
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3"
    ) {
      if (link[1] === "addgroupofschoolcms") {
        grpschdetail(id).then((response) => {
          if (response.status === true) {
            setSPublish(response.data.funders_details.website_publised_status);
            setPubUrl(response.data.funders_details.website_url);
            setFundName(response.data.funders_details.group_of_school_name);
            setFundDesc(
              response.data.funders_details.group_of_school_description
            );
            setFundMail(response.data.funders_details.email);
            setFundMob(response.data.funders_details.mobile);
            setFundAddr(response.data.funders_details.address);
            if (response.data.funders_details.logo !== null) {
              setLogoFile(
                response.data.logo_path + response.data.funders_details.logo
              );
            }
          }
        });
      } else {
        funderdetail(id).then((response) => {
          if (response.status === true) {
            setSPublish(response.data.funders_details.website_publised_status);
            setPubUrl(response.data.funders_details.website_url);
            setFundName(response.data.funders_details.funder_name);
            setFundDesc(response.data.funders_details.funder_description);
            setFundMail(response.data.funders_details.email);
            setFundMob(response.data.funders_details.mobile);
            setFundAddr(response.data.funders_details.address);
            if (response.data.funders_details.logo !== null) {
              setLogoFile(
                response.data.logo_path + response.data.funders_details.logo
              );
            }
          }
        });
        listcoachresource(id, "");
      }

      listresource(id, link[1], "");
      listall(id, link[1]);
      liststatement(id, link[1]);
      liststory(link[1]);
      listmedal(link[1]);
    }
    if (link[1] === "addgroupofschoolcms") {
      grpschoollist(id).then((response) => {
        if (response.status === true) {
          setSchList(response.data.school_list);
          setSchPath(response.data.logo_path);
        }
      });
      const temp = new FormData();
      temp.append("year", 0);
      temp.append("sports", 0);
      temp.append("group_of_school_id", id);

      grpmedalslist(temp).then((res) => {
        if (res.status === true) {
          let mcnt = 0;
          let tcnt = 0;
          let scnt = 0;
          for (let i = 0; i < res.data.length; i++) {
            if (typeof res.data[i].meet_count === "string") {
              tcnt += parseInt(res.data[i].meet_count);
            } else {
              tcnt += res.data[i].meet_count;
            }

            if (typeof res.data[i].participants === "string") {
              scnt += parseInt(res.data[i].participants);
            } else {
              scnt += res.data[i].participants;
            }

            if (typeof res.data[i].total_achievements === "string") {
              mcnt += parseInt(res.data[i].total_achievements);
            } else {
              mcnt += res.data[i].total_achievements;
            }
          }
          setMCount(mcnt);
          // setTCount(tcnt);
          setSCount(scnt);
        }
      });
    } else {
      funderschoollist(id).then((response) => {
        if (response.status === true) {
          setSchList(response.data.school_list);
          setSchPath(response.data.logo_path);
        }
      });

      const temp = new FormData();
      temp.append("year", 0);
      temp.append("sports", 0);
      temp.append("funder_id", id);

      fundermedalslist(temp).then((res) => {
        if (res.status === true) {
          let mcnt = 0;
          let tcnt = 0;
          let scnt = 0;
          for (let i = 0; i < res.data.length; i++) {
            if (typeof res.data[i].meet_count === "string") {
              tcnt += parseInt(res.data[i].meet_count);
            } else {
              tcnt += res.data[i].meet_count;
            }

            if (typeof res.data[i].participants === "string") {
              scnt += parseInt(res.data[i].participants);
            } else {
              scnt += res.data[i].participants;
            }

            if (typeof res.data[i].total_achievements === "string") {
              mcnt += parseInt(res.data[i].total_achievements);
            } else {
              mcnt += res.data[i].total_achievements;
            }
          }
          setMCount(mcnt);
          // setTCount(tcnt);
          setSCount(scnt);
        }
      });
    }

    cmstaglist().then((response) => {
      if (response.status === true) {
        let arr1 = [];
        if (response.data.tags_list.length > 0) {
          for (let i = 0; i < response.data.tags_list.length; i++) {
            arr1.push({
              label: response.data.tags_list[i].tag_name,
              value: response.data.tags_list[i].id,
            });
          }
        }
        setGTagList(arr1);
      }
    });
  }, []);

  function listmedal(llink) {
    setMedalShow(false);
    let stype = "";
    if (llink === "addgroupofschoolcms") {
      stype = 3;
    } else {
      stype = 2;
    }
    playsessionview(id, stype).then((res) => {
      if (res.status === true) {
        if (res.data.play_sessions !== null) {
          setMedalShow(true);
          setMedalId(res.data.play_sessions.id);
          setPlaySes(res.data.play_sessions.play_sessions);
        }
      }
    });
  }

  function listall(lid, llink) {
    if (llink === "addgroupofschoolcms") {
      grptourlist(lid).then((response) => {
        if (response.status === true) {
          setTPath(response.data.img_path);
          setTList(response.data.tournament_list);
          setEList(response.data.event_list);
          setTCount(response.data.all_tournament_list.length);
        }
      });
      grpgallerylist(lid).then((response) => {
        if (response.status === true) {
          setGtPath(response.data.img_path);
          setGList(response.data.gallery_list);
        }
      });
    } else {
      fundertourlist(lid).then((response) => {
        if (response.status === true) {
          setTPath(response.data.img_path);
          setTList(response.data.tournament_list);
          setEList(response.data.event_list);
          setTCount(response.data.all_tournament_list.length);
        }
      });
      fundergallerylist(lid).then((response) => {
        if (response.status === true) {
          setGtPath(response.data.img_path);
          setGList(response.data.gallery_list);
        }
      });

      // const temp = new FormData();
      // temp.append("year", 0);
      // temp.append("sports", 0);
      // temp.append("school_id", lid);

      // medalslist(temp).then((res) => {
      //   if (res.status === true) {
      //     let mcnt = 0;
      //     let tcnt = 0;
      //     let scnt = 0;
      //     for (let i = 0; i < res.data.length; i++) {
      //       if (typeof res.data[i].meet_count === "string") {
      //         tcnt += parseInt(res.data[i].meet_count);
      //       } else {
      //         tcnt += res.data[i].meet_count;
      //       }

      //       if (typeof res.data[i].participants === "string") {
      //         scnt += parseInt(res.data[i].participants);
      //       } else {
      //         scnt += res.data[i].participants;
      //       }

      //       if (typeof res.data[i].total_achievements === "string") {
      //         mcnt += parseInt(res.data[i].total_achievements);
      //       } else {
      //         mcnt += res.data[i].total_achievements;
      //       }
      //     }
      //     setMCount(mcnt);
      //     setTCount(tcnt);
      //     setSCount(scnt);
      //   }
      // });
    }
  }
  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/resources/logo-pathway.png");
    ev.target.onerror = null;
  }

  function errorschool(ev) {
    ev.target.src = require("../../../../assets/images/resources/school.png");
    ev.target.onerror = null;
  }

  function errorslogo(ev) {
    ev.target.src = require("../../../../assets/images/resources/slogo.png");
    ev.target.onerror = null;
  }

  function errorimghandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function errortour(ev) {
    ev.target.src = require("../../../../assets/images/resources/logo_epl.png");
    ev.target.onerror = null;
  }

  function liststatement(lid, llink) {
    setSchShow(false);
    let stype = "";
    if (llink === "addgroupofschoolcms") {
      stype = 3;
    } else {
      stype = 2;
    }
    schoolstatementview(lid, stype).then((response) => {
      if (response.status === true) {
        if (response.data.school_statement !== null) {
          setSchShow(true);
          setStatId(response.data.school_statement.id);
          setSHead(response.data.school_statement.heading);
          setDesc(response.data.school_statement.description);
          if (response.data.school_statement.fb_url !== null) {
            setSFace(response.data.school_statement.fb_url);
          }
          if (response.data.school_statement.insta_url !== null) {
            setSInsta(response.data.school_statement.insta_url);
          }
          if (response.data.school_statement.youtube_url !== null) {
            setSYoutube(response.data.school_statement.youtube_url);
          }

          if (response.data.school_statement.image_path !== null) {
            setPhotoUrl(
              response.data.logo_path +
                response.data.school_statement.image_path
            );
            setImage(
              response.data.logo_path +
                response.data.school_statement.image_path
            );
          }
        }
      }
    });
  }

  function liststory(llink) {
    let stype = "";
    if (llink === "addgroupofschoolcms") {
      stype = 3;
    } else {
      stype = 2;
    }
    storylist(id, stype).then((res) => {
      if (res.status === true) {
        setStPath(res.data.logo_path);
        setStList(res.data.success_stories_list);
      }
    });
  }

  const [stags, setSTags] = useState([]);
  function handlefilter(e) {
    if (e != null) {
      setSTags(e);
      let tid = "";
      if (e.length > 0) {
        for (let i = 0; i < e.length; i++) {
          if (tid === "") {
            tid = e[i].value;
          } else {
            tid = tid + "," + e[i].value;
          }
        }
      }
      listcoachresource(id, tid);
    } else {
      listcoachresource(id, "");
    }
  }

  const [gshow, setGShow] = useState(false);

  const [gtags, setGTags] = useState("");
  const [ptype, setPType] = useState("");
  const [photo, setPhoto] = useState([]);
  const [photolist, setPhotoLink] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [gdesc, setGDesc] = useState("");
  const [rtags, setRTags] = useState([]);
  const [rlist, setRTagList] = useState([]);
  function photofile(event) {
    let arr1 = [];
    let arr = [];
    let arr2 = [];
    setResCheck("");

    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length !== 0) {
        if (event.target.files[i].name.match(/\.(jpg|jpeg|png)$/i)) {
          var size = event.target.files[i].size / 1024 / 1024;
          if (size > 50) {
            arr1.push(
              event.target.files[i].name + " size should be less than 50MB"
            );
            setErrorMsg([...arr1]);
          } else {
            arr2.push(URL.createObjectURL(event.target.files[i]));
            arr.push(event.target.files[i]);
          }
        } else {
          arr1.push(
            event.target.files[i].name + " should be jpg or jpeg or png format"
          );
        }
      }
    }
    setPhoto(arr);
    setPhotoLink(arr2);
    setPhoto([...photo, ...arr]);
    setPhotoLink([...photolist, ...arr2]);
    setErrorMsg(arr1);
    setErrorMsg([...errorMsg, ...arr1]);
  }

  function deletecurphoto(did) {
    photo.splice(did, 1);
    setPhoto([...photo]);
    photolist.splice(did, 1);
    setPhotoLink([...photolist]);
  }

  const [fvideo, setVideoFile] = useState("");
  const [fvideourl, setVideoUrl] = useState("");
  const [formvideoerror, setVideoErrors] = useState("");

  function videofile(e) {
    setVideoFile("");
    setVideoErrors("");

    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(mp4|mp3|wav|mkv)$/i)) {
        setVideoFile(e.target.files[0]);
        // setVideoUrl(URL.createObjectURL(e.target.files[0]));
        // var video = document.getElementById("video");
        // var f = e.target.files[0];
        // if (f) {
        //   var r = new FileReader();
        //   r.onload = function (e) {
        //     var contents = e.target.result;
        //     var uint8Array = new Uint8Array(contents);
        //     var arrayBuffer = uint8Array.buffer;
        //     var blob = new Blob([arrayBuffer]);
        //     video.src = URL.createObjectURL(blob);
        //   };
        //   r.readAsArrayBuffer(f);
        // }
      } else {
        setVideoErrors("Video should be mp4 or mp3 or wav or mkv format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 50) {
        setVideoErrors("Video size should be less than 50MB");
      }
    }
  }

  const [reserror, setResError] = useState("");
  const [rfile, setRFile] = useState("");

  function resfile(event) {
    setResError("");
    setRFile("");
    setResCheck("");

    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf)$/i)) {
        setRFile(event.target.files[0]);
      } else {
        setResError("Files should be .pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setResError("Files size should be less than 5MB");
      }
    }
  }
  const [rescheck, setResCheck] = useState("");
  function addresouce(e) {
    e.preventDefault();
    setResCheck("");
    var chk = "";
    if (ptype != "") {
      if (gtags != "") {
        var ctype = "";
        var gtype = "file";
        var fcount = 1;
        if (ptype == 1) {
          ctype = "Photo";
          fcount = photo.length;
          if (photo.length == 0) {
            chk = "Please Select Photo";
            setResCheck("Please Select Photo");
          }
        }

        if (ptype == 2) {
          ctype = "Video";
          fcount = 1;
          if (fvideo !== "" && fvideourl !== "") {
            chk = "Either Upload Video or Enter Video URL";
            setResCheck("Either Upload Video or Enter Video URL");
          }
          if (fvideo == "" && fvideourl == "") {
            chk = "Please Upload Video or Enter Video URL";
            setResCheck("Please Upload Video or Enter Video URL");
          }

          if (fvideo !== "") {
            gtype = "file";
          }
          if (fvideourl !== "") {
            gtype = "url";
          }
        }

        if (ptype == 3) {
          ctype = "PDF";
          fcount = 1;
          if (rfile == "") {
            chk = "Please Select Files";
            setResCheck("Please Select Files");
          }
        }
        if (chk == "") {
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("school_id", id);
          if (localStorage.getItem("Role") === "1") {
            temp.append("uploaded_by", 1);
            temp.append("mark_as_star", 0);
          } else if (localStorage.getItem("Role") === "3") {
            temp.append("uploaded_by", 1);
            temp.append("mark_as_star", 0);
          } else if (localStorage.getItem("Role") === "7") {
            temp.append("uploaded_by", 2);
            temp.append("mark_as_star", 0);
          }
          temp.append("file_type", ctype);
          temp.append("description", gdesc);
          temp.append("file_or_url", gtype);
          temp.append("files_count", fcount);
          temp.append("tags_count", gtags.length);
          for (var i = 0; i < gtags.length; i++) {
            temp.append("tags_id_" + [i + 1], gtags[i].value);
          }
          if (ptype == 1) {
            for (var i = 0; i < photo.length; i++) {
              temp.append("resource_file_" + [i + 1], photo[i]);
            }
          } else if (ptype == 2) {
            if (gtype == "file") {
              temp.append("resource_file_1", fvideo);
            } else {
              temp.append("resource_file_1", fvideourl);
            }
          } else if (ptype == 3) {
            temp.append("resource_file_1", rfile);
          }
          temp.append("school_or_funder", 2);

          dotracking("submit button in resources $$ " + trackobj.fcmsheading);
          cmsresourcecreate(temp).then((res) => {
            if (res.status === true) {
              handlegclose();
              toast.success(res.message);
              listresource(id, "");
              listcoachresource(id, "");
            }
          });
        }
      } else {
        setResCheck("Please Select Tags");
      }
    } else {
      setResCheck("Please Select Type");
    }
  }

  function handletype(e) {
    setPType(e.target.value);
    setRFile("");
    setResError("");
    setVideoUrl("");
    setVideoFile("");
    setVideoErrors("");
    setPhoto([]);
    setPhotoLink([]);
    setErrorMsg([]);
  }

  function handleTags(e, htype) {
    setGTags("");
    setRTags("");
    setRTagList([]);
    let arr = [];

    if (htype == 1) {
      if (e != null) {
        setGTags(e);

        for (let i = 0; i < e.length; i++) {
          cmstagview(e[i].value).then((response) => {
            if (response.status === true) {
              if (response.data.recomanded_tags.length > 0) {
                for (let i = 0; i < response.data.recomanded_tags.length; i++) {
                  arr.push({
                    value: response.data.recomanded_tags[i].recomanded_tags_id,
                    label: response.data.recomanded_tags[i].tag_name,
                  });
                }

                setRTags([...arr]);
              }
            }
          });
        }
      } else {
        setGTags("");
        setRTags("");
        setRTagList([]);
      }
    } else if (htype == 2) {
      const arr1 = [e];
      const combinedTags = [...arr1, ...gtags];
      setGTags(combinedTags);
      for (let i = 0; i < combinedTags.length; i++) {
        cmstagview(combinedTags[i].value).then((response) => {
          if (response.status === true) {
            if (response.data.recomanded_tags.length > 0) {
              for (let i = 0; i < response.data.recomanded_tags.length; i++) {
                arr.push({
                  value: response.data.recomanded_tags[i].recomanded_tags_id,
                  label: response.data.recomanded_tags[i].tag_name,
                });
                // if (arr == "") {
                //   arr = response.data.recomanded_tags[i].tag_name;
                // } else {
                //   arr = arr + " , " + response.data.recomanded_tags[i].tag_name;
                // }
              }

              setRTags([...arr]);
            }
          }
        });
      }
    }
  }
  function handlegshow() {
    setGTags("");
    setPhoto([]);
    setPhotoLink([]);
    setPType("");
    setErrorMsg([]);
    setVideoFile("");
    setVideoUrl("");
    setVideoErrors("");
    setResError("");
    setResCheck("");
    setRFile("");
    setGShow(true);
  }
  function handlegclose() {
    setGShow(false);
    setPhoto([]);
    setPhotoLink([]);
    setPType("");
    setErrorMsg([]);
    setVideoFile("");
    setVideoUrl("");
    setVideoErrors("");
    setResError("");
    setResCheck("");
    setRFile("");
    setGTags("");
  }

  const [atags, setATags] = useState([]);
  function handleAfilter(e) {
    if (e != null) {
      setATags(e);
      let tid = "";
      if (e.length > 0) {
        for (let i = 0; i < e.length; i++) {
          if (tid === "") {
            tid = e[i].value;
          } else {
            tid = tid + "," + e[i].value;
          }
        }
      }
      listresource(id, urllink, tid);
    } else {
      listresource(id, urllink, "");
    }
  }

  function listcoachresource(lid, filt) {
    setCResList([]);
    coachcmsresourcelist(lid, filt, 2).then((res) => {
      if (res.status === true) {
        setResPath(res.data.logo_path);
        let arr = [];
        if (res.data.resources_list.length > 0) {
          for (let i = 0; i < res.data.resources_list.length; i++) {
            arr.push({
              resources_id: res.data.resources_list[i].resources_id,
              tag_name: res.data.resources_list[i].tag_name,
              uploaded_by: res.data.resources_list[i].uploaded_by,
              status: res.data.resources_list[i].status,
              selected_status: res.data.resources_list[i].selected_status,
              file_type: res.data.resources_list[i].file_type,
              file_or_url: res.data.resources_list[i].file_or_url,
              file_name: res.data.resources_list[i].file_name,
              mark_as_star: res.data.resources_list[i].mark_as_star,
              file_select: false,
            });
          }
        }
        setCResList(arr);
      }
    });
  }

  function listresource(lid, llink, srh) {
    setResList([]);
    if (llink === "addgroupofschoolcms") {
      grpresourceslist(lid, srh).then((res) => {
        if (res.status === true) {
          setResPath(res.data.logo_path);
          setResList(res.data.resources_list);
        }
      });
    } else {
      funderresourceslist(lid).then((res) => {
        if (res.status === true) {
          setResPath(res.data.logo_path);
          setResList(res.data.resources_list);
        }
      });
    }
  }
  const [shead, setSHead] = useState("");
  const [desc, setDesc] = useState("");
  const [simage, setImage] = useState("");
  const [statid, setStatId] = useState("");

  const [sface, setSFace] = useState("");
  const [sinsta, setSInsta] = useState("");
  const [syoutube, setSYoutube] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [cirerror, setCirError] = useState("");
  const [photourl, setPhotoUrl] = useState("");

  const [schoolshow, setSchShow] = useState(false);

  function circfile(event) {
    setPhotoUrl("");
    setCirError("");
    setImage("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setImage(event.target.files[0]);
      } else {
        setCirError("Image should be .jpg or .jpeg or .png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setCirError("Image size should be less than 5MB");
      }
    }
  }

  const [show, setShow] = useState(false);
  function handleshow() {
    setSHead("");
    setDesc("");
    setImage("");
    setSFace("");
    setSInsta("");
    setSYoutube("");
    setCirError("");
    setPhotoUrl("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    liststatement(id, urllink);
  }

  function handleedit() {
    liststatement(id, urllink);
    setShow(true);
  }

  function addstatement(e) {
    e.preventDefault();
    setSubmitted(true);
    if (cirerror == "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("heading", shead);
      temp.append("description", desc);
      temp.append("statement_image", simage);
      temp.append("fb_url", sface);
      temp.append("insta_url", sinsta);
      temp.append("youtube_url", syoutube);
      temp.append("school_id", id);
      if (urllink === "addgroupofschoolcms") {
        temp.append("school_or_funder", 3);
      } else {
        temp.append("school_or_funder", 2);
      }

      if (statid === "") {
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "add button in group of school statement $$ " +
              trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "add button in funder statement $$ " + trackobj.addfcmsheading
          );
        }

        schoolstatementcreate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            liststatement(id, urllink);
          }
        });
      } else {
        temp.append("school_statement_id", statid);
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "update button in group of school statement " +
              shead +
              " (" +
              statid +
              ") $$ " +
              trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "update button in funder statement " +
              shead +
              " (" +
              statid +
              ") $$ " +
              trackobj.addfcmsheading
          );
        }

        schoolstatementupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            liststatement(id, urllink);
          }
        });
      }
    }
  }
  const navigate = useNavigate();

  //Success Stories
  const [stitle, setSTitle] = useState("");
  const [sdesc, setSDesc] = useState("");
  const [sphto, setSImage] = useState("");
  const [sdesc1, setSDesc1] = useState("");
  const [stid, setStId] = useState("");
  const [photoerror, setPhotoError] = useState("");
  const [check, setCheck] = useState(false);

  function storyfile(event) {
    setSPhotoUrl("");
    setPhotoError("");
    setSImage("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setSImage(event.target.files[0]);
      } else {
        setPhotoError("Image should be .jpg or .jpeg or .png format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setPhotoError("Image size should be less than 5MB");
      // }
    }
  }
  const [sshow, setSShow] = useState(false);
  function handlesshow() {
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setPhotoError("");
    setSPhotoUrl("");
    setCheck("");
    setSShow(true);
  }
  function handlesclose() {
    setSShow(false);
    setCheck("");
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setPhotoError("");
    setSPhotoUrl("");
  }

  const [sphotourl, setSPhotoUrl] = useState("");
  const [syshow, setSyShow] = useState(false);

  function handlestory(sid, stype) {
    storyview(sid).then((response) => {
      if (response.status === true) {
        if (stype === 1) {
          setSyShow(true);
        } else if (stype === 2) {
          setSShow(true);
        }

        setSTitle(response.data.success_stories_details.article_title);
        setSDesc(response.data.success_stories_details.article_description);
        if (response.data.success_stories_details.article_image !== null) {
          setSPhotoUrl(
            response.data.logo_path +
              response.data.success_stories_details.article_image
          );
          setSImage(
            response.data.logo_path +
              response.data.success_stories_details.article_image
          );
        }

        setSDesc1(response.data.success_stories_details.article_content);
        setStId(response.data.success_stories_details.id);
      }
    });
  }

  function handlestoryclose() {
    setSyShow(false);
    setStId("");
    setSTitle("");
    setSDesc("");
    setSImage("");
    setSDesc1("");
    setSPhotoUrl("");
  }

  function addstory(e) {
    e.preventDefault();
    setCheck(true);
    if (photoerror == "" && sphto != "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("school_id", id);
      temp.append("article_title", stitle);
      temp.append("article_description", sdesc);
      temp.append("article_image", sphto);
      temp.append("article_content", sdesc1);
      if (urllink === "addgroupofschoolcms") {
        temp.append("school_or_funder", 3);
      } else {
        temp.append("school_or_funder", 2);
      }
      if (stid === "") {
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "add button in success stories $$ " + trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "add button in success stories $$ " + trackobj.addfcmsheading
          );
        }

        storycreate(temp).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(urllink);
          }
        });
      } else {
        temp.append("article_id", stid);
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "update button in success stories for " +
              stitle +
              " (" +
              stid +
              ") $$ " +
              trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "update button in success stories for " +
              stitle +
              " (" +
              stid +
              ") $$ " +
              trackobj.addfcmsheading
          );
        }

        storyupdate(temp).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(urllink);
          }
        });
      }
    }
  }

  function deletestory(did) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      width: "800px",
      confirmButtonColor: "#4ecc48",
      cancelButtonColor: "#ac0404",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "delete button in success story $$ " + trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "delete button in success story $$ " + trackobj.addfcmsheading
          );
        }

        storydelete(localStorage.getItem("UID"), did).then((response) => {
          if (response.status === true) {
            handlesclose();
            toast.success(response.message);
            liststory(urllink);
          }
        });
      }
    });
  }

  const [platses, setPlaySes] = useState("0");
  const [mcount, setMCount] = useState("0");
  const [tcount, setTCount] = useState("0");
  const [scount, setSCount] = useState("0");
  const [medalid, setMedalId] = useState("");
  const [medalshow, setMedalShow] = useState(false);

  const [mshow, setMShow] = useState(false);
  const [merrormsg, setMErrorMsg] = useState("");
  function handlemshow() {
    setPlaySes("0");
    setMErrorMsg("");
    setMShow(true);
  }
  function handlemclose() {
    setPlaySes("0");
    setMShow(false);
    listmedal(urllink);
  }

  function handlemeditshow() {
    setMShow(true);
    listmedal(urllink);
  }

  const handlechange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPlaySes(e.target.value);
    }
  };

  function addmedals(e) {
    e.preventDefault();
    if (platses !== "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("play_session", platses);
      temp.append("school_id", id);
      if (urllink === "addgroupofschoolcms") {
        temp.append("school_or_funder", 3);
      } else {
        temp.append("school_or_funder", 2);
      }

      if (medalid === "") {
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "add button in no of sports represented $$ " + trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "add button in no of sports represented $$ " + trackobj.addfcmsheading
          );
        }

        playsessioncreate(temp).then((response) => {
          if (response.status === true) {
            handlemclose();
            toast.success(response.message);
            listmedal(urllink);
          }
        });
      } else {
        temp.append("school_statement_id", medalid);
        if (urllink === "addgroupofschoolcms") {
          dotracking(
            "update button in no of sports represented " +
              platses +
              " (" +
              medalid +
              ") $$ " +
              trackobj.addgscmsheading
          );
        } else {
          dotracking(
            "update button in no of sports represented " +
              platses +
              " (" +
              medalid +
              ") $$ " +
              trackobj.addfcmsheading
          );
        }

        playsessionupdate(temp).then((response) => {
          if (response.status === true) {
            handlemclose();
            toast.success(response.message);
            listmedal(urllink);
          }
        });
      }
    } else {
      setMErrorMsg("Please Enter No of Sports Represented");
    }
  }

  const [glist, setGTagList] = useState([]);

  const [gcpshow, setGCPShow] = useState(false);
  const [gphoto, setGallery] = useState([]);
  function handlegcpopen(cid) {
    setGCPShow(true);
    photoslist(cid).then((response) => {
      if (response.status === true) {
        if (response.data.tournamentlist.length !== 0) {
          let arr1 = [];
          for (let i = 0; i < response.data.tournamentlist.length; i++) {
            arr1.push({
              id: response.data.tournamentlist[i].id,
              src:
                response.data.tournamentimage_path +
                response.data.tournamentlist[i].imagename,
            });
          }
          setGallery(arr1);
        }
      }
    });
  }
  function handlegcpclose() {
    setGCPShow(false);
    setGallery([]);
  }

  function handlepublish(e) {
    e.preventDefault();
    Swal.fire({
      html: "<h3>Do you want to publish this webpage?</h3>",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        var sl = fundname.toLowerCase();
        sl = sl.trim();
        sl = sl.replace(/ /g, "_");
        var sllink = "";
        if (urllink === "addgroupofschoolcms") {
          sllink = GroupWebUrl + sl + "/" + id;
        } else {
          sllink = FunderWebUrl + sl + "/" + id;
        }
        // if(id == 10030){
        Swal.fire({
          html:
            "<h3>Webpage has been published successfully</h3> <br/> <b><span style='color:#000'>WebPage: </span><a style='color:#035399'>" +
            sllink +
            "</a></b> <br/><br/><h4 style='color:red'>Do you want to view this webpage?</h4>",
          showCancelButton: true,
          width: "800px",
          confirmButtonColor: "#4ecc48",
          cancelButtonColor: "#ac0404",
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (urllink === "addgroupofschoolcms") {
              dotracking(
                "publish button in cms details for " +
                  fundname +
                  " (" +
                  id +
                  ") school $$ " +
                  trackobj.addgscmsheading
              );
              var sk = sl + "/" + id;
              grpschpublishtrack(localStorage.getItem("UID"), id, sk).then(
                (res) => {
                  if (res.status === true) {
                    window.open(sllink, "_blank");
                  }
                }
              );
            } else {
              dotracking(
                "publish button in cms details for " +
                  fundname +
                  " (" +
                  id +
                  ") school $$ " +
                  trackobj.addfcmsheading
              );
              var sk = sl + "/" + id;
              funderpublishtrack(localStorage.getItem("UID"), id, sk).then(
                (res) => {
                  if (res.status === true) {
                    window.open(sllink, "_blank");
                  }
                }
              );
            }

            // window.open(sllink, "_blank");
          }
        });
      }
    });
  }

  const [ishow, setIShow] = useState(false);
  const [pimage, setPImage] = useState(false);
  const [pimgtype, setPImgType] = useState("");
  const [pimgurl, setPImgUrl] = useState("");
  const [prem, setPRem] = useState("");

  function handlepop(img, itype, ifile, irem) {
    if (itype === "Video") {
      if (ifile === "url") {
        let newstr1 = img.substr(img.length - 11);
        let curvideo = "";
        let res = img.substring(0, 23);
        curvideo = "https://www.youtube.com";
        if (curvideo == res) {
          setPImage(`https://www.youtube.com/embed/${newstr1}`);
        }
      } else {
        setPImage(respath + img);
      }
    } else {
      setPImage(respath + img);
    }
    setPRem(irem);
    setPImgUrl(ifile);
    setIShow(true);
    setPImgType(itype);
  }

  function imageclose() {
    setIShow(false);
    setPImage("");
    setPRem("");
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add CMS</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add CMS
          </li>
        </ol>
      </div>
      <h3 className="errortext text-center">{fundname}</h3>
      {(localStorage.getItem("Role") === "1" ||
        localStorage.getItem("Role") === "3") && (
        <>
          <Tabs
            // activeKey={tabselected}
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {urllink !== "addgroupofschoolcms" && (
              <Tab eventKey={1} title="Funder Statement">
                <div className="card mt-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Funder Statement</h4>
                      </div>
                      <div className="col-md-3">
                        {/* <select
                        value={scolor}
                        onChange={(e) => setSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                      </div>
                      <div className="col-md-3">
                        {!schoolshow && (
                          <div className="text-right">
                            <a className="btn btn-success" onClick={handleshow}>
                              <i className="fa fa-plus"></i> Add Funder
                              Statement
                            </a>
                          </div>
                        )}
                        {schoolshow && (
                          <div className="text-right">
                            <a
                              className="btn btn-secondary"
                              onClick={handleedit}
                            >
                              <i className="fa fa-plus"></i> Edit Funder
                              Statement
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="vs-menu-wrapper">
                    <div className="vs-menu-area text-center">
                      <div className="mobile-logo">
                        <a>
                          <img
                            src={logofile}
                            alt="Marino"
                            onError={(e) => errorhandle(e)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <header className="vs-header header-layout5">
                    <div className="header-top">
                      <div className="vs-container4"></div>
                    </div>
                    <div className="funsticky-wrapper">
                      <div className="sticky-active">
                        <div className="vs-container2">
                          <div className="row align-items-center justify-content-center">
                            <div className="col-md-3 col-sm-5 align-self-stretch">
                              <div className="vs-logo style2">
                                <a className="">
                                  <img
                                    src={logofile}
                                    alt="logo"
                                    onError={(e) => errorhandle(e)}
                                  />
                                </a>
                                <a className="sticky-logo">
                                  <img
                                    src={logofile}
                                    alt="logo"
                                    onError={(e) => errorhandle(e)}
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-2 mx-0 ">
                              <div className="row gx-50 align-items-center d-none d-md-block">
                                <div className="col d-flex align-items-center justify-content-center">
                                  <nav className="main-menu">
                                    <ul>
                                      <li className="menu-item-has-children">
                                        {fundname}
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-5 text-end">
                              <img
                                src={require("../../../../assets/images/logo_sv.png")}
                                alt=""
                                className="sponsorlogo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div className="hero-layout1 style2 position-relative">
                    <div
                      className="vs-carousel"
                      data-arrows="true"
                      data-dots="false"
                      data-fade="true"
                    >
                      <div className="hero-inner">
                        <div
                          className="hero-bg background-image"
                          style={{
                            backgroundImage: `url(${require("../../../../assets/images/resources/testi-bg-1-1.jpg")})`,
                          }}
                        ></div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="hero-content">
                                <h1 className="hero-title">{shead}</h1>
                                <p className="hero-text">{desc}</p>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div
                                style={{
                                  margin: "0px",
                                  display: "grid",
                                  minHeight: "50vh",
                                  gridAutoFlow: "column",
                                  placeContent: "center",
                                  gap: "40px",
                                }}
                              >
                                {simage !== "" && (
                                  <img src={simage} className="bannerimg" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal show={show} size="xl">
                  <Modal.Header>
                    {statid === "" && <h4>Add Funder Statement</h4>}
                    {statid !== "" && <h4>Edit Funder Statement</h4>}
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Heading <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Heading"
                            value={shead}
                            onChange={(e) => setSHead(e.target.value)}
                          />
                          {submitted && !shead && (
                            <span className="errortext">
                              Heading is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Description <span className="errortext"> *</span>
                          </label>
                          <textarea
                            rows={2}
                            className="form-control"
                            placeholder="Description"
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                          />
                          {submitted && !desc && (
                            <span className="errortext">
                              Description is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Facebook Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Facebook Link"
                            value={sface}
                            onChange={(e) => setSFace(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Instagram Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instagram Link"
                            value={sinsta}
                            onChange={(e) => setSInsta(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Youtube Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Youtube Link"
                            value={syoutube}
                            onChange={(e) => setSYoutube(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Image{" "}
                            <span className="errortext">
                              {" "}
                              * (Max Size 5mb,Accepted files are jpg, jpeg, png){" "}
                            </span>{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".jpg, .jpeg, .png"
                            onChange={circfile}
                          />
                          {photourl !== "" && (
                            <img
                              className="profile_img mt-2"
                              src={photourl}
                              width="100%"
                              alt="photo"
                            />
                          )}

                          {submitted && !simage && (
                            <span className="errortext">Image is required</span>
                          )}
                          {cirerror !== "" && (
                            <span className="errortext">{cirerror}</span>
                          )}
                        </div>
                      </div>

                      <div className="text-right border-top ">
                        {statid === "" && (
                          <a
                            className="btn btn-success me-1 mt-2"
                            onClick={addstatement}
                          >
                            <i className="fa fa-edit"></i> Submit
                          </a>
                        )}
                        {statid !== "" && (
                          <a
                            className="btn btn-success me-1 mt-2"
                            onClick={addstatement}
                          >
                            <i className="fa fa-edit"></i> Update
                          </a>
                        )}

                        <a
                          className="btn btn-danger mt-2"
                          onClick={handleclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Tab>
            )}

            {urllink === "addgroupofschoolcms" && (
              <Tab eventKey={1} title="Group of School Statement">
                <div className="card mt-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Group of School Statement</h4>
                      </div>
                      <div className="col-md-3">
                        {/* <select
                        value={scolor}
                        onChange={(e) => setSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                      </div>
                      <div className="col-md-3">
                        {!schoolshow && (
                          <div className="text-right">
                            <a className="btn btn-success" onClick={handleshow}>
                              <i className="fa fa-plus"></i> Add Group of School
                              Statement
                            </a>
                          </div>
                        )}
                        {schoolshow && (
                          <div className="text-right">
                            <a
                              className="btn btn-secondary"
                              onClick={handleedit}
                            >
                              <i className="fa fa-plus"></i> Edit Group of
                              School Statement
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="vs-menu-wrapper">
                    <div className="vs-menu-area text-center">
                      <div className="mobile-logo">
                        <a>
                          <img
                            src={logofile}
                            alt="Marino"
                            onError={(e) => errorhandle(e)}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <header className="vs-header header-layout5">
                    <div className="header-top">
                      <div className="vs-container4"></div>
                    </div>
                    <div className="funsticky-wrapper">
                      <div className="sticky-active">
                        <div className="vs-container2">
                          <div className="row align-items-center justify-content-center">
                            <div className="col-md-3 col-sm-5 align-self-stretch">
                              <div className="vs-logo style2">
                                <a className="">
                                  <img
                                    src={logofile}
                                    alt="logo"
                                    onError={(e) => errorhandle(e)}
                                  />
                                </a>
                                <a className="sticky-logo">
                                  <img
                                    src={logofile}
                                    alt="logo"
                                    onError={(e) => errorhandle(e)}
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-2 mx-0 ">
                              <div className="row gx-50 align-items-center d-none d-md-block">
                                <div className="col d-flex align-items-center justify-content-center">
                                  <nav className="main-menu">
                                    <ul>
                                      <li className="menu-item-has-children">
                                        {fundname}
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-5 text-end">
                              <img
                                src={require("../../../../assets/images/logo_sv.png")}
                                alt=""
                                className="sponsorlogo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div className="hero-layout1 style2 position-relative">
                    <div
                      className="vs-carousel"
                      data-arrows="true"
                      data-dots="false"
                      data-fade="true"
                    >
                      <div className="hero-inner">
                        <div
                          className="hero-bg background-image"
                          style={{
                            backgroundImage: `url(${require("../../../../assets/images/resources/testi-bg-1-1.jpg")})`,
                          }}
                        ></div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="hero-content">
                                <h1 className="hero-title">{shead}</h1>
                                <p className="hero-text">{desc}</p>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div
                                style={{
                                  margin: "0px",
                                  display: "grid",
                                  minHeight: "50vh",
                                  gridAutoFlow: "column",
                                  placeContent: "center",
                                  gap: "40px",
                                }}
                              >
                                {simage !== "" && (
                                  <img src={simage} className="bannerimg" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal show={show} size="xl">
                  <Modal.Header>
                    {statid === "" && <h4>Add Funder Statement</h4>}
                    {statid !== "" && <h4>Edit Funder Statement</h4>}
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Heading <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Heading"
                            value={shead}
                            onChange={(e) => setSHead(e.target.value)}
                          />
                          {submitted && !shead && (
                            <span className="errortext">
                              Heading is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Description <span className="errortext"> *</span>
                          </label>
                          <textarea
                            rows={2}
                            className="form-control"
                            placeholder="Description"
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                          />
                          {submitted && !desc && (
                            <span className="errortext">
                              Description is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Facebook Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Facebook Link"
                            value={sface}
                            onChange={(e) => setSFace(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Instagram Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instagram Link"
                            value={sinsta}
                            onChange={(e) => setSInsta(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Youtube Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Youtube Link"
                            value={syoutube}
                            onChange={(e) => setSYoutube(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Image{" "}
                            <span className="errortext">
                              {" "}
                              * (Max Size 5mb,Accepted files are jpg, jpeg, png){" "}
                            </span>{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".jpg, .jpeg, .png"
                            onChange={circfile}
                          />
                          {photourl !== "" && (
                            <img
                              className="profile_img mt-2"
                              src={photourl}
                              width="100%"
                              alt="photo"
                            />
                          )}

                          {submitted && !simage && (
                            <span className="errortext">Image is required</span>
                          )}
                          {cirerror !== "" && (
                            <span className="errortext">{cirerror}</span>
                          )}
                        </div>
                      </div>

                      <div className="text-right border-top ">
                        {statid === "" && (
                          <a
                            className="btn btn-success me-1 mt-2"
                            onClick={addstatement}
                          >
                            <i className="fa fa-edit"></i> Submit
                          </a>
                        )}
                        {statid !== "" && (
                          <a
                            className="btn btn-success me-1 mt-2"
                            onClick={addstatement}
                          >
                            <i className="fa fa-edit"></i> Update
                          </a>
                        )}

                        <a
                          className="btn btn-danger mt-2"
                          onClick={handleclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Tab>
            )}

            <Tab eventKey={2} title="Schools">
              <div className="card mt-0">
                <div className="card-body">
                  <h4>Schools</h4>
                </div>
              </div>

              <section className="space service">
                <div className="container">
                  <div className="row vs-carousel">
                    {schlist.map((data, i) => {
                      return (
                        <div
                          className="col-auto slick-slide"
                          style={{ width: "312px" }}
                          key={i}
                        >
                          <div className="service-style1">
                            <div className="service-img">
                              <img
                                onError={(e) => errorschool(e)}
                                src="../../../../assets/images/resources/1.jpg"
                                alt="service thumbnail"
                              />
                            </div>
                            <div className="service-icon">
                              <img
                                src={schpath + data.school_logo}
                                alt="icon"
                                onError={(e) => errorslogo(e)}
                              />
                            </div>
                            <h3 className="service-title h6">
                              <a className="text-inherit">{data.school_name}</a>
                            </h3>
                            <p className="service-text">{data.address}</p>
                            <div className="link-btn">
                              <a>
                                Know More <i className="fa fa-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </Tab>

            <Tab eventKey={3} title="Tournaments & Events">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <h4>Tournaments & Events</h4>
                    </div>
                    <div className="col-md-2">
                      {/* <select
                        value={tcolor}
                        onChange={(e) => setTColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="shop-layout1 bg-white space-top space-extra-bottom"
                style={{
                  backgroundImage: `url(${require("../../../../assets/images/resources/shop-bg2.png")})`,
                }}
              >
                <div className="container">
                  <div className="row vs-carousel">
                    {tlist.map((data, i) => {
                      return (
                        <div
                          className="col-auto slick-slide"
                          style={{ width: "312px" }}
                          key={i}
                        >
                          <div className="product-style2">
                            <div className="product-img">
                              <div className="clipped">
                                {data.tournament_logo !== null && (
                                  <img
                                    src={tpath + data.tournament_logo}
                                    onError={(e) => errortour(e)}
                                    height="150px"
                                  />
                                )}
                                {data.tournament_logo == null && (
                                  <img
                                    src={require("../../../../assets/images/resources/logo_epl.png")}
                                    height="150px"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="product-body">
                              <div className="product-img2">
                                <img
                                  src={
                                    require("../../../../assets/images/shop-bg-shep.svg")
                                      .default
                                  }
                                  alt="shop"
                                />
                              </div>
                              <div className="product-content">
                                <span className="product-price">
                                  {moment(data.start_date).format(
                                    "DD, MMMM YYYY"
                                  )}
                                </span>
                                <h3 className="product-title">
                                  {data.meet_name}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {elist.map((data, i) => {
                      return (
                        <div
                          className="col-auto slick-slide"
                          style={{ width: "312px" }}
                          key={i}
                        >
                          <div className="product-style2">
                            <div className="product-img">
                              <div className="clipped">
                                <img
                                  src={require("../../../../assets/images/resources/logo-pathway.png")}
                                  alt="shop"
                                />
                              </div>
                            </div>
                            <div className="product-body">
                              <div className="product-img2">
                                <img
                                  src={
                                    require("../../../../assets/images/shop-bg-shep.svg")
                                      .default
                                  }
                                  alt="shop"
                                />
                              </div>
                              <div className="product-content">
                                <span className="product-price">
                                  {moment(data.start_date).format(
                                    "DD, MMMM YYYY"
                                  )}
                                </span>
                                <h3 className="product-title">
                                  {data.event_name}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </Tab>
            <Tab eventKey={4} title="Success Stories">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Success Stories</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={sscolor}
                        onChange={(e) => setSSColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      <div className="text-right">
                        <a className="btn btn-success" onClick={handlesshow}>
                          <i className="fa fa-plus"></i> Add Success Stories
                        </a>
                      </div>
                    </div>
                  </div>

                  <Modal show={sshow} size="xl">
                    <Modal.Header>
                      {stid === "" && <h4>Add Success Stories</h4>}
                      {stid !== "" && <h4>Edit Success Stories</h4>}
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Title <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              value={stitle}
                              onChange={(e) => setSTitle(e.target.value)}
                            />
                            {check && !stitle && (
                              <span className="errortext">
                                Title is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Description <span className="errortext"> *</span>
                            </label>
                            <textarea
                              rows={2}
                              className="form-control"
                              placeholder="Description"
                              value={sdesc}
                              onChange={(e) => setSDesc(e.target.value)}
                            />
                            {check && !sdesc && (
                              <span className="errortext">
                                Description is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">Content</label>
                          <JoditEditor
                            // ref={editor}
                            value={sdesc1}
                            onChange={(sdesc1) => setSDesc1(sdesc1)}
                            tabIndex={1}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Image{" "}
                              <span className="errortext">
                                {" "}
                                * (Accepted files are jpg, jpeg, png){" "}
                              </span>{" "}
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              accept=".jpg, .jpeg, .png"
                              onChange={storyfile}
                            />
                            {sphotourl !== "" && (
                              <img
                                className="profile_img mt-2"
                                src={sphotourl}
                                width="100%"
                                alt="photo"
                              />
                            )}

                            {check && !sphto && (
                              <span className="errortext">
                                Image is required
                              </span>
                            )}
                            {photoerror !== "" && (
                              <span className="errortext">{photoerror}</span>
                            )}
                          </div>
                        </div>
                        <div className="text-right border-top mt-2">
                          {stid === "" && (
                            <a
                              className="btn btn-success me-1 mt-2"
                              onClick={addstory}
                            >
                              <i className="fa fa-edit"></i> Submit
                            </a>
                          )}
                          {stid !== "" && (
                            <a
                              className="btn btn-success me-1 mt-2"
                              onClick={addstory}
                            >
                              <i className="fa fa-edit"></i> Update
                            </a>
                          )}
                          <a
                            className="btn btn-danger mt-2"
                            onClick={handlesclose}
                          >
                            <i className="fa fa-times"></i> Close
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal show={syshow} size="xl">
                    <Modal.Header>
                      <h4>Success Stories Details</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="testi-content">
                        <div className="row">
                          <div className="col-md-6">
                            <img src={sphto} width="100%" alt="testi-img" />
                          </div>
                          <div className="col-md-6">
                            <div className="testi-inner">
                              <div className="testi-desc">
                                <div>
                                  <h3 className="testi_author">{stitle}</h3>
                                  <span className="testi_degi">{sdesc}</span>
                                </div>
                              </div>

                              <div className="testi-text">
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: sdesc1 }}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="testi-img">
                          <img src={sphto} width="300px" alt="testi-img" />
                         
                        </div>
                        <div className="testi-inner">
                          <div className="testi-desc">
                            <div>
                              <h3 className="testi_author">{stitle}</h3>
                              <span className="testi_degi">{sdesc}</span>
                            </div>
                          </div>

                          <div className="testi-text">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: sdesc1 }}
                              />
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="text-right">
                        <a
                          className="btn btn-danger"
                          onClick={handlestoryclose}
                        >
                          <i className="fa fa-times"></i> Close
                        </a>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <section className="testimonial-layout1 style2 space">
                <div className="card container">
                  <div className="row vs-carousel">
                    {stlist.map((item, i) => (
                      <div
                        className="col-md-6 slick-slide slick-cloned mt-2"
                        style={{ width: "623px" }}
                        key={i}
                      >
                        <div className="testi-item">
                          <div
                            className="testi-content"
                            onClick={() => handlestory(item.id, 1)}
                          >
                            <div className="testi-img">
                              <img
                                src={stpath + item.article_image}
                                alt="testi-img"
                              />
                              <span className="testi_quote">
                                <i className="fas fa-quote-right"></i>
                              </span>
                            </div>
                            <div className="testi-inner">
                              <div className="testi-text">
                                <p>{item.article_description}</p>
                              </div>
                              <div className="testi-desc">
                                <div>
                                  <h3 className="testi_author">
                                    {item.article_title}
                                  </h3>
                                  {/* <span className="testi_degi">
                                  Indian Badminton
                                </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-4">
                            <a
                              onClick={() => handlestory(item.id, 2)}
                              className="btn btn-sm btn-secondary me-2"
                              title="Edit Story"
                            >
                              <i className="fa fa-edit"></i> Edit
                            </a>
                            <a
                              className="btn btn-sm btn-danger me-2"
                              onClick={() => deletestory(item.id)}
                              title="Delete Story"
                            >
                              <i className="fa fa-trash"></i> Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </Tab>
            <Tab eventKey={5} title="Medals">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Medals</h4>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        value={mcolor}
                        onChange={(e) => setMColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-3">
                      <div className="text-right">
                        {!medalshow && (
                          <a className="btn btn-success" onClick={handlemshow}>
                            <i className="fa fa-plus"></i> Add
                          </a>
                        )}
                        {medalshow && (
                          <a
                            className="btn btn-secondary"
                            onClick={handlemeditshow}
                          >
                            <i className="fa fa-plus"></i> Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={mshow} size="lg">
                <Modal.Header>
                  <h4>Add No of Sports Represented</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                        No of Sports Represented <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="No of Sports Represented"
                          value={platses}
                          onChange={handlechange}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    {merrormsg !== "" && (
                      <span className="errortext">{merrormsg}</span>
                    )}
                    <div className="text-right border-top ">
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={addmedals}
                      >
                        <i className="fa fa-edit"></i> Submit
                      </a>

                      <a className="btn btn-danger mt-2" onClick={handlemclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <section
                className="space position-relative counter"
                style={{
                  backgroundImage: `url(${require("../../../../assets/images/resources/counter-bg3.png")})`,
                }}
              >
                <div className="vs-container2">
                  <div className="container">
                    <div className="row g-5 justify-content-lg-between justify-content-center">
                      <div className="col-lg-auto col-md-6">
                        <div className="media-style3">
                          <div className="media-icon">
                            <div className="icon">
                              <i className="fas fa-school"></i>
                            </div>
                          </div>
                          <div className="media-counter">
                            <h2
                              className="media-title counter-number"
                              data-count="11"
                            >
                              {schlist.length}
                            </h2>
                            <p className="media-text">Schools</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-auto col-md-6">
                        <div className="media-style3">
                          <div className="media-icon">
                            <div className="icon">
                              <img
                                src={
                                  require("../../../../assets/images/resources/ci-2-1.svg")
                                    .default
                                }
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="media-counter">
                            <h2
                              className="media-title counter-number"
                              data-count="11"
                            >
                              {mcount}
                            </h2>
                            <p className="media-text">Medals</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-auto col-md-6">
                        <div className="media-style3">
                          <div className="media-icon">
                            <div className="icon">
                              <img
                                src={
                                  require("../../../../assets/images/resources/ci-2-2.svg")
                                    .default
                                }
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="media-counter">
                            <h2
                              className="media-title counter-number"
                              data-count="4700"
                            >
                              {platses}
                            </h2>
                            <p className="media-text">No of Sports Represented</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-auto col-md-6">
                        <div className="media-style3">
                          <div className="media-icon">
                            <div className="icon">
                              <img
                                src={
                                  require("../../../../assets/images/resources/ci-2-3.svg")
                                    .default
                                }
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="media-counter">
                            <h2
                              className="media-title counter-number"
                              data-count="102"
                            >
                              {scount}
                            </h2>
                            <p className="media-text">Total Participation</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-auto col-md-6">
                        <div className="media-style3">
                          <div className="media-icon">
                            <div className="icon">
                              <img
                                src={
                                  require("../../../../assets/images/resources/ci-2-4.svg")
                                    .default
                                }
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="media-counter">
                            <h2
                              className="media-title counter-number"
                              data-count="45"
                            >
                              {tcount}
                            </h2>
                            <p className="media-text">
                              Tournaments Participated
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Tab>

            <Tab eventKey={6} title="Gallery">
              <div className="card mt-0">
                <div className="card-body">
                  {/* <h4 className="text-center">Gallery</h4> */}
                  <div className="row">
                    <div className="col-md-10">
                      <h4 className="text-center">Gallery</h4>
                    </div>
                    <div className="col-md-2">
                      {/* <select
                        value={gcolor}
                        onChange={(e) => setGColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>

              <section className="space">
                <div className="container-fluid">
                  <div className="row vs-carousel">
                    {gllist.map((item, i) => {
                      return (
                        <div
                          className="col-md-4 slick-slide slick-current slick-center mt-2"
                          key={i}
                        >
                          <div className="service_style5">
                            <div className="service_inner mega-hover">
                              <div className="service_img">
                                <img
                                  src={gtpath + item.imagename}
                                  onError={(e) => errorhandle(e)}
                                  // src={require("../../../../assets/images/resources/1.jpg")}
                                  alt="service-img"
                                />
                              </div>
                              <div className="service_icon">
                                <a className="icon-btn">
                                  <i className="fa fa-arrow-up"></i>
                                </a>
                              </div>
                              <div className="service_content">
                                <a>
                                  <h2 className="service_title">
                                    {item.meet_name}
                                  </h2>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Modal show={gcpshow} size="xl">
                <Modal.Header>
                  <h4>Gallery</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    {gphoto.map((item, index) => {
                      return (
                        <div className="col-md-2 mt-4" key={index}>
                          <img
                            className="photoimg"
                            src={item.src}
                            height="100px"
                            alt="Photo"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="text-right border-top mt-2">
                    <a className="btn btn-danger mt-2" onClick={handlegcpclose}>
                      <i className="fa fa-times"></i> Close
                    </a>
                  </div>
                </Modal.Footer>
              </Modal>
            </Tab>

            <Tab eventKey={7} title="Resources">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5">
                      <h4>Resources</h4>
                    </div>

                    <div className="col-md-7">
                      <div className="text-right">
                        <a
                          className="btn btn-success me-2 mt-1"
                          onClick={handlegshow}
                        >
                          <i className="fa fa-plus"></i> Add Resources
                        </a>
                      </div>
                    </div>
                  </div>
                  {urllink === "addgroupofschoolcms" && (
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">Search By Tags</label>
                        <Select
                          className="dropdown"
                          placeholder="Select Tags"
                          value={atags}
                          options={glist}
                          onChange={handleAfilter}
                          isSearchable={true}
                          isClearable
                          isMulti
                          hidden
                        />
                      </div>
                    </div>
                  )}

                  {/*<div className="col-md-3">
                      <label className="form-label">Background Color</label>
                    <select
                        value={rcolor}
                        onChange={(e) => setRColor(e.target.value)}
                        className="form-control"
                      >
                        {colorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>*/}
                </div>
              </div>

              <section className="space-bottom blog-layout1">
                <div className="container">
                  <div className="row vs-carousel">
                    {reslist.map((item, i) => {
                      return (
                        <div className="col-lg-3 col-md-6" key={i}>
                          <div className="vs-blog blog-style3">
                            <div className="blog-img">
                              <a
                                onClick={(e) =>
                                  handlepop(
                                    item.file_name,
                                    item.file_type,
                                    item.file_or_url,
                                    item.remarks
                                  )
                                }
                              >
                                {item.file_type == "PDF" && (
                                  <img
                                    className="w-100"
                                    src={require("../../../../assets/images/pdf.png")}
                                    alt="Blog Img"
                                  />
                                )}
                                {item.file_type == "Video" && (
                                  <img
                                    className="w-100"
                                    src={require("../../../../assets/images/video.png")}
                                    alt="Blog Img"
                                  />
                                )}

                                {item.file_type == "Photo" && (
                                  <img
                                    className="w-100"
                                    src={respath + item.file_name}
                                    alt="Blog Img"
                                  />
                                )}
                              </a>
                            </div>
                            <div className="blog-content">
                              <h4 className="blog-title">
                                <span className="blogline">
                                  {item.tag_name}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Modal show={gshow} size="lg">
                <Modal.Header>
                  <h4>Add Resources</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Type <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-select"
                          value={ptype}
                          onChange={handletype}
                        >
                          <option value="">Select Type</option>
                          <option value="1">Photos</option>
                          <option value="2">Videos</option>
                          <option value="3">Files</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Tags <span className="errortext"> *</span>
                        </label>
                        <Select
                          className="dropdown"
                          placeholder="Select Tags"
                          value={gtags}
                          options={glist}
                          onChange={(e) => handleTags(e, 1)}
                          isSearchable={true}
                          isMulti
                          isClearable
                          hidden
                        />
                      </div>
                    </div>
                    {rtags !== "" && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Recomanded Tags
                            {/* <span className="errortext"> *</span> */}
                          </label>
                          {rtags.map((item, index) => {
                            return (
                              <div className="col-md-4 mt-2">
                                <a
                                  key={index}
                                  onClick={() => handleTags(item, 2)}
                                  className="btn btn-sm btn-export"
                                >
                                  {item.label}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Description
                          {/* <span className="errortext"> *</span> */}
                        </label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Enter Description"
                          value={gdesc}
                          onChange={(e) => setGDesc(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    {ptype === "1" && (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Photos{" "}
                              <span className="errortext">
                                {" "}
                                * (Max size 50mb,Accepted files are jpg, jpeg,
                                png)
                              </span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              multiple
                              onChange={photofile}
                              accept=".jpg,.jpeg,.png"
                            />
                          </div>
                        </div>

                        {photolist.length !== 0 && (
                          <div className="row">
                            {photolist.map((data, i) => {
                              return (
                                <div className="col-md-2 mt-4" key={i}>
                                  <a
                                    className="photo-mark"
                                    onClick={() => deletecurphoto(i)}
                                  >
                                    <i className="fa fa-trash fa-lg"></i>
                                  </a>
                                  <img
                                    className="photoimg"
                                    src={data}
                                    height="100px"
                                    alt="Photo"
                                    key={i}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}

                    {ptype === "2" && (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Video Url <span className="errortext">* </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Video Url"
                              onChange={(e) => setVideoUrl(e.target.value)}
                              value={fvideourl}
                            />
                          </div>
                        </div>
                        <span className="errortext text-center">(OR)</span>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Upload Video{" "}
                              <span className="errortext">
                                *{" "}
                                <span>
                                  (Accepted Files are .mp4,.mp3,.wav,.mkv & Max
                                  Upload Size 50MB )
                                </span>
                              </span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              onChange={videofile}
                              accept=".mp3,.mp4,.wav,.mkv"
                            />
                            {formvideoerror !== "" && (
                              <span className="errortext">
                                {formvideoerror}
                              </span>
                            )}
                            {/* {fvideourl !== "" && (
                       <div className="mt-2">
                         <video
                          id="video"
                          width="100%"
                          controls
                        />
                       </div>
                      )} */}
                          </div>
                        </div>
                      </>
                    )}

                    {ptype === "3" && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Files{" "}
                            <span className="errortext">
                              {" "}
                              * (Max size 5mb,Accepted Files are .pdf)
                            </span>{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf"
                            onChange={resfile}
                          />

                          {reserror !== "" && (
                            <span className="errortext">{reserror}</span>
                          )}
                        </div>
                      </div>
                    )}
                    {rescheck != "" && (
                      <span className="errortext">{rescheck}</span>
                    )}
                    <div className="text-right border-top mt-2">
                      <a
                        className="btn btn-success me-1 mt-2"
                        onClick={addresouce}
                      >
                        <i className="fa fa-edit"></i> Submit
                      </a>

                      <a className="btn btn-danger mt-2" onClick={handlegclose}>
                        <i className="fa fa-times"></i> Close
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal show={ishow} size="xl" onHide={imageclose}>
                <Modal.Header>
                  <h4> Resources</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        {pimgtype === "Video" && pimgurl === "url" && (
                          <iframe
                            width="100%"
                            height="300"
                            src={pimage}
                            frameBorder="0"
                            // allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        )}

                        {pimgtype === "Video" && pimgurl !== "url" && (
                          <video controls width="100%" height="300">
                            <source src={pimage} type="video/mp4" />
                          </video>
                        )}

                        {pimgtype === "PDF" && (
                          <iframe
                            width="100%"
                            height="400"
                            src={pimage}
                            allowFullScreen
                          />
                        )}

                        {pimgtype === "Photo" && (
                          <img src={pimage} alt="Photo" width="100%" />
                        )}
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <p>{prem}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={imageclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Tab>

            <Tab eventKey={8} title="Review & Publish">
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Review & Publish</h4>
                    </div>
                    <div className="col-md-6 text-right">
                      {urllink === "addgroupofschoolcms" && spublish != 0 && (
                        <a
                          className="btn btn-secondary"
                          href={GroupWebUrl + puburl}
                          title={GroupWebUrl + puburl}
                          target="_blank"
                        >
                          View Webpage
                        </a>
                      )}

                      {urllink !== "addgroupofschoolcms" && spublish != 0 && (
                        <a
                          className="btn btn-secondary"
                          href={FunderWebUrl + puburl}
                          title={FunderWebUrl + puburl}
                          target="_blank"
                        >
                          View Webpage
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="vs-menu-wrapper">
                  <div className="vs-menu-area text-center">
                    <div className="mobile-logo">
                      <a>
                        <img
                          src={logofile}
                          alt="Marino"
                          onError={(e) => errorhandle(e)}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <header className="vs-header header-layout5">
                  <div className="header-top">
                    <div className="vs-container4"></div>
                  </div>
                  <div className="funsticky-wrapper">
                    <div className="sticky-active">
                      <div className="vs-container2">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-3 col-sm-5 align-self-stretch">
                            <div className="vs-logo style2">
                              <a className="">
                                <img
                                  src={logofile}
                                  alt="logo"
                                  onError={(e) => errorhandle(e)}
                                />
                              </a>
                              <a className="sticky-logo">
                                <img
                                  src={logofile}
                                  alt="logo"
                                  onError={(e) => errorhandle(e)}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-2 mx-0 ">
                            <div className="row gx-50 align-items-center d-none d-md-block">
                              <div className="col d-flex align-items-center justify-content-center">
                                <nav className="main-menu">
                                  <ul>
                                    <li className="menu-item-has-children">
                                      {fundname}
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-5 text-end">
                            <img
                              src={require("../../../../assets/images/logo_sv.png")}
                              alt=""
                              className="sponsorlogo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>

                <div className="hero-layout1 style2 position-relative">
                  <div className="vs-carousel">
                    <div className="hero-inner">
                      <div
                        className="hero-bg background-image"
                        style={{
                          backgroundImage: `url(${require("../../../../assets/images/resources/testi-bg-1-1.jpg")})`,
                        }}
                      ></div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="hero-content">
                              <h1 className="hero-title">{shead}</h1>
                              <p className="hero-text">{desc}</p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div
                              style={{
                                margin: "0px",
                                display: "grid",
                                minHeight: "50vh",
                                gridAutoFlow: "column",
                                placeContent: "center",
                                gap: "40px",
                              }}
                            >
                              {simage !== "" && (
                                <img src={simage} className="bannerimg" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {schlist.length > 0 && (
                  <section className="space service">
                    <div className="container">
                      <div
                        className="title-area text-center wow fadeInUp wow-animated"
                        data-wow-delay="0.3s"
                      >
                        <h2 className="sec-title">School Information</h2>
                        <div className="sec-line"></div>
                      </div>
                      <div className="row vs-carousel">
                        {schlist.map((data, i) => {
                          return (
                            <div
                              className="col-auto slick-slide"
                              style={{ width: "312px" }}
                              key={i}
                            >
                              <div className="service-style1">
                                <div className="service-img">
                                  <img
                                    onError={(e) => errorschool(e)}
                                    src="../../../../assets/images/resources/1.jpg"
                                    alt="service thumbnail"
                                  />
                                </div>
                                <div className="service-icon">
                                  <img
                                    src={schpath + data.school_logo}
                                    alt="icon"
                                    onError={(e) => errorslogo(e)}
                                  />
                                </div>
                                <h3 className="service-title h6">
                                  <a className="text-inherit">
                                    {data.school_name}
                                  </a>
                                </h3>
                                <p className="service-text">{data.address}</p>
                                <div className="link-btn">
                                  <a>
                                    Know More{" "}
                                    <i className="fa fa-arrow-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}

                {(tlist.length > 0 || elist.length > 0) && (
                  <section
                    className="shop-layout1 bg-white space-top space-extra-bottom"
                    style={{
                      backgroundImage: `url(${require("../../../../assets/images/resources/shop-bg2.png")})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="container">
                      <div
                        className="title-area text-center wow fadeInUp wow-animated"
                        data-wow-delay="0.3s"
                      >
                        <h2 className="sec-title" style={{ color: "#000" }}>
                          Tournaments &amp; Events
                        </h2>
                        <div className="sec-line"></div>
                      </div>
                      <div className="row vs-carousel">
                        {tlist.map((data, i) => {
                          return (
                            <div
                              className="col-auto slick-slide"
                              style={{ width: "312px" }}
                              key={i}
                            >
                              <div className="product-style2">
                                <div className="product-img">
                                  <div className="clipped">
                                    {/* <img
                                      src={require("../../../../assets/images/resources/logo_epl.png")}
                                      alt="shop"
                                    /> */}
                                    {data.tournament_logo !== null && (
                                      <img
                                        src={tpath + data.tournament_logo}
                                        onError={(e) => errortour(e)}
                                        height="150px"
                                      />
                                    )}
                                    {data.tournament_logo == null && (
                                      <img
                                        src={require("../../../../assets/images/resources/logo_epl.png")}
                                        height="150px"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="product-body">
                                  <div className="product-img2">
                                    <img
                                      src={
                                        require("../../../../assets/images/shop-bg-shep.svg")
                                          .default
                                      }
                                      alt="shop"
                                    />
                                  </div>
                                  <div className="product-content">
                                    <span className="product-price">
                                      {moment(data.start_date).format(
                                        "DD, MMMM YYYY"
                                      )}
                                    </span>
                                    <h3 className="product-title">
                                      {data.meet_name}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {elist.map((data, i) => {
                          return (
                            <div
                              className="col-auto slick-slide"
                              style={{ width: "312px" }}
                              key={i}
                            >
                              <div className="product-style2">
                                <div className="product-img">
                                  <div className="clipped">
                                    <img
                                      src={require("../../../../assets/images/resources/logo-pathway.png")}
                                      alt="shop"
                                    />
                                  </div>
                                </div>
                                <div className="product-body">
                                  <div className="product-img2">
                                    <img
                                      src={
                                        require("../../../../assets/images/shop-bg-shep.svg")
                                          .default
                                      }
                                      alt="shop"
                                    />
                                  </div>
                                  <div className="product-content">
                                    <span className="product-price">
                                      {moment(data.start_date).format(
                                        "DD, MMMM YYYY"
                                      )}
                                    </span>
                                    <h3 className="product-title">
                                      {data.event_name}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}
                {stlist.length !== 0 && (
                  <section className="testimonial-layout1 style2 space">
                    <div className="card container">
                      <div
                        className="title-area text-center wow fadeInUp wow-animated"
                        data-wow-delay="0.3s"
                      >
                        <h2 className="sec-title">Our Success Stories</h2>
                        <div className="sec-line m-auto"></div>
                      </div>
                      <div className="row vs-carousel">
                        {stlist.map((item, i) => (
                          <div
                            className="col-md-6 slick-slide slick-cloned mt-2"
                            style={{ width: "623px" }}
                            key={i}
                          >
                            <div className="testi-item">
                              <div
                                className="testi-content"
                                onClick={() => handlestory(item.id, 1)}
                              >
                                <div className="testi-img">
                                  <img
                                    src={stpath + item.article_image}
                                    alt="testi-img"
                                  />
                                  <span className="testi_quote">
                                    <i className="fas fa-quote-right"></i>
                                  </span>
                                </div>
                                <div className="testi-inner">
                                  <div className="testi-text">
                                    <p>{item.article_description}</p>
                                  </div>
                                  <div className="testi-desc">
                                    <div>
                                      <h3 className="testi_author">
                                        {item.article_title}
                                      </h3>
                                      {/* <span className="testi_degi">
                                  Indian Badminton
                                </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                )}

                <section
                  className="space position-relative counter"
                  style={{
                    backgroundImage: `url(${require("../../../../assets/images/resources/counter-bg3.png")})`,
                  }}
                >
                  <div className="vs-container2">
                    <div className="container">
                      <div className="row g-5 justify-content-lg-between justify-content-center">
                        <div className="col-lg-auto col-md-6">
                          <div className="media-style3">
                            <div className="media-icon">
                              <div className="icon">
                                <i className="fas fa-school"></i>
                              </div>
                            </div>
                            <div className="media-counter">
                              <h2
                                className="media-title counter-number"
                                data-count="11"
                              >
                                {schlist.length}
                              </h2>
                              <p className="media-text">Schools</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-auto col-md-6">
                          <div className="media-style3">
                            <div className="media-icon">
                              <div className="icon">
                                <img
                                  src={
                                    require("../../../../assets/images/resources/ci-2-1.svg")
                                      .default
                                  }
                                  alt="icon"
                                />
                              </div>
                            </div>
                            <div className="media-counter">
                              <h2
                                className="media-title counter-number"
                                data-count="11"
                              >
                                {mcount}
                              </h2>
                              <p className="media-text">Medals</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-auto col-md-6">
                          <div className="media-style3">
                            <div className="media-icon">
                              <div className="icon">
                                <img
                                  src={
                                    require("../../../../assets/images/resources/ci-2-2.svg")
                                      .default
                                  }
                                  alt="icon"
                                />
                              </div>
                            </div>
                            <div className="media-counter">
                              <h2
                                className="media-title counter-number"
                                data-count="4700"
                              >
                                {platses}
                              </h2>
                              <p className="media-text">No of Sports Represented</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-auto col-md-6">
                          <div className="media-style3">
                            <div className="media-icon">
                              <div className="icon">
                                <img
                                  src={
                                    require("../../../../assets/images/resources/ci-2-3.svg")
                                      .default
                                  }
                                  alt="icon"
                                />
                              </div>
                            </div>
                            <div className="media-counter">
                              <h2
                                className="media-title counter-number"
                                data-count="102"
                              >
                                {scount}
                              </h2>
                              <p className="media-text">Total Participation</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-auto col-md-6">
                          <div className="media-style3">
                            <div className="media-icon">
                              <div className="icon">
                                <img
                                  src={
                                    require("../../../../assets/images/resources/ci-2-4.svg")
                                      .default
                                  }
                                  alt="icon"
                                />
                              </div>
                            </div>
                            <div className="media-counter">
                              <h2
                                className="media-title counter-number"
                                data-count="45"
                              >
                                {tcount}
                              </h2>
                              <p className="media-text">
                                Tournaments Participated
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {gllist.length > 0 && (
                  <section className="space">
                    <div className="container-fluid">
                      <div
                        className="title-area text-center wow fadeInUp wow-animated"
                        data-wow-delay="0.3s"
                      >
                        <h2 className="sec-title">Gallery</h2>
                        <div className="sec-line"></div>
                      </div>
                      <div className="row vs-carousel">
                        {gllist.map((item, i) => {
                          return (
                            <div
                              className="col-md-4 slick-slide slick-current slick-center mt-2"
                              key={i}
                            >
                              <div className="service_style5">
                                <div className="service_inner mega-hover">
                                  <div className="service_img">
                                    <img
                                      src={gtpath + item.imagename}
                                      onError={(e) => errorhandle(e)}
                                      // src={require("../../../../assets/images/resources/1.jpg")}
                                      alt="service-img"
                                    />
                                  </div>
                                  <div className="service_icon">
                                    <a className="icon-btn">
                                      <i className="fa fa-arrow-up"></i>
                                    </a>
                                  </div>
                                  <div className="service_content">
                                    <a>
                                      <h2 className="service_title">
                                        {item.meet_name}
                                      </h2>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}

                {reslist.length > 0 && (
                  <section className="space-bottom blog-layout1">
                    <div className="container">
                      <div
                        className="title-area text-center wow fadeInUp wow-animated"
                        data-wow-delay="0.3s"
                      >
                        <h2 className="sec-title">Resources</h2>
                        <div className="sec-line"></div>
                      </div>
                      <div className="row vs-carousel">
                        {reslist.map((item, i) => {
                          return (
                            <div className="col-lg-3 col-md-6" key={i}>
                              <div className="vs-blog blog-style3">
                                <div className="blog-img">
                                  {item.file_type == "PDF" && (
                                    <img
                                      className="w-100"
                                      s
                                      src={require("../../../../assets/images/pdf.png")}
                                      alt="Blog Img"
                                    />
                                  )}
                                  {item.file_type == "Video" && (
                                    <img
                                      className="w-100"
                                      src={require("../../../../assets/images/video.png")}
                                      alt="Blog Img"
                                    />
                                  )}

                                  {item.file_type == "Photo" && (
                                    <img
                                      className="w-100"
                                      src={respath + item.file_name}
                                      alt="Blog Img"
                                    />
                                  )}
                                </div>
                                {/* <div className="blog-content">
                                  <h4 className="blog-title">
                                    <span className="blogline">
                                      {item.tag_name}
                                    </span>
                                  </h4>
                                </div> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}

                {spublish == 0 && urllink !== "addgroupofschoolcms" && (
                  <div className="text-center mt-2">
                    <a className="btn btn-secondary" onClick={handlepublish}>
                      {/*  */}
                      Publish Funder Webpage
                    </a>
                  </div>
                )}

                {spublish == 0 && urllink === "addgroupofschoolcms" && (
                  <div className="text-center mt-2">
                    <a className="btn btn-secondary" onClick={handlepublish}>
                      {/*  */}
                      Publish Group of School Webpage
                    </a>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </>
      )}

      
    </div>
  );
};
