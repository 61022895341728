import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

//Age category list in tournament wise
export async function addathleteagecategorylist(tid) {
  try {
    return await axios
      .get(`${baseUrl}/meetagecategories-list/${tid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Athlete age category view to list of events
export async function atheventview(id, eid) {
    try {
      return await axios
        .get(`${baseUrl}/meetagecategoriesevents-list/${id}/${eid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
//Athlete age category view in edit
export async function athagecatedit(id) {
    try {
      return await axios
        .get(`${baseUrl}/tour-agecategory-view/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  //New Age category update
  export async function createnewagecat(data) {
    try {
      return await axios
        .post(`${baseUrl}/add-new-age-category-to-tournament`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  //Age category update
export async function updateagecat(data) {
    try {
      return await axios
        .post(`${baseUrl}/updateagecategorydetails`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

//player list in event wise for tournament
export async function addeventsplayerlist(tid,sid) {
    try {
      return await axios
        .get(`${baseUrl}/athletics-added-athletelist?tournament_meet_id=${tid}&school_id=${sid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  //player list in tournament wise
export async function addathleteplayerlist(tid,sid) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-added-athlist?tournament_meet_id=${tid}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



//Age category list in tournament wise
export async function addathletedelete(tid,uid) {
    try {
      return await axios
        .get(`${baseUrl}/athletics-delete-athlete/${tid}/${uid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

//Event list in age category wise
export async function addathleteeventslist(tid,cid) {
    try {
      return await axios
        .get(`${baseUrl}/meetagecategoriesevents-list/${tid}/${cid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  //Add Athlete  in age category and event wise
  export async function addathleteplayers(data) {
    try {
      return await axios
        .post(`${baseUrl}/add-athletics-athletes`,data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  //Update Email and Mobile
  export async function updateathletemobemail(data) {
    try {
      return await axios
        .post(`${baseUrl}/update-mobile-email`,data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  //Athlete list in age category and event wise
export async function addathletelist(data) {
    try {
      return await axios
        .post(`${baseUrl}/athletics-added-athlist-eventwise`,data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  
  //Search Athlete list in age category and school wise
export async function addsearchathletelist(data) {
    try {
      return await axios
        .post(`${baseUrl}/athletics-athletes-search`,data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  //Add age category event
export async function addeventagecat(data) {
    try {
      return await axios
        .post(`${baseUrl}/addnewevent`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  //Delete age category event
export async function deleteventagecat(data) {
    try {
      return await axios
        .post(`${baseUrl}/removeevent`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
   
  //Summary
export async function toursummary(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/get-summary-page-info?tournament_id=${id}&school_id=${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Offline Submit and Payment
export async function tourentryofflinepayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/submit-entry-from-admin`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Free Tournament
export async function tourentryfreepayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/submit-from-free-entry-tournament`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


 //Submit and Payment
 export async function tourpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-athletics-razorpay-orderid`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Payment
export async function paymentsuccess(data) {
  try {
    return await axios
      .post(`${baseUrl}/update-athletics-paymentsuccess`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Payment or not for tournament
export async function checkpaymentpaidornot(tid,sid,uid) {
  try {
    return await axios
      .get(`${baseUrl}/check-athletics-paid-or-not?current_meet_id=${tid}&school_id=${sid}&user_id=${uid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Athletics Inter Tournament school list
export async function athletictourschoollist(id) {
  try {
    return await axios
      .get(`${baseUrl}/school-list-of-tournaments/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Other than Athletics Inter Tournament school list
export async function interschooltourschoollist(data) {
  try {
    return await axios
      .post(`${baseUrl}/all-inter-school-list-with-status`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Add/Edit the players in Tournament school list
export async function adminathletictedittour(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-edit-entries-track`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Add
export async function athletictouraddorderevents(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-add-events-order`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Update
export async function athletictourupdateorderevents(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-update-events-order`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Order of Events List
export async function athletictourordereventslist(id) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-events-list/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Start List
export async function athletictoureventstartlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-start-list-of-athletics`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Except Heat Start List
export async function athletictoureventstartexceptheatlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-start-list-of-athletics-except-heat`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Group Heat count Start List
export async function athletictoureventgroupheatlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/heat-group-list-names`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events with Heat Start List
export async function athletictoureventheatlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletes-list-with-heat-name`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Remove athlete from Heat Start List
export async function athletictourremoveheatevent(data) {
  try {
    return await axios
      .post(`${baseUrl}/remove-athlete-from-heats`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Order of Events--> Add Heat
export async function athleticstouraddheattoevents(data) {
  try {
    
    return await axios
      .post(`${baseUrl}/add-athletes-to-heats`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Start List --result update
export async function athleticstartresultupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/add-results-to-start-list`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



//Order of Events Start List -- time/distance list
export async function athleticstartresultlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-results-data`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events View
export async function athletictourordereventsdelete(id,uid) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-delete-events-order/${id}/${uid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events View
export async function athletictourordereventsview(id) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-view-events-order/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Bib number generate and regenerate for tournament players
export async function tournamentgeneratebib(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-set-bibno`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Bib number generate or regenerate for tournament players
export async function bibnogenerateornot(id) {
  try {
    return await axios
      .get(`${baseUrl}/bibnolist/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Result List
export async function adminathleticsresultlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/all-athletics-results/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Result Delete
export async function athleticsdeleteresult(id) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-results-delete/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Overall and Individual Champion List
export async function athleticschampionslist(data) {
  try {
    return await axios
      .post(`${baseUrl}/overall-individual-champions-list`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Overall Champion List
export async function agecategorychampionslist(data) {
  try {
    return await axios
      .post(`${baseUrl}/individual-champions-list-by-age-cat`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Result Log List
export async function athleticsreultloglist(id) {
  try {
    return await axios
      .get(`${baseUrl}/athletics-results-excel-log/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Result Upload
export async function adminathleticsresultupload(data) {
  try {
    return await axios
      .post(`${baseUrl}/import-athletics-results`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Orderof events Upload
export async function athleticsordereventupload(data) {
  try {
    return await axios
      .post(`${baseUrl}/import-event-order`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Order of Events Log List
export async function athleticsordereventloglist(id) {
  try {
    return await axios
      .get(`${baseUrl}/events-order-excel-log/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//BIB Number Upload
export async function athleticsbibupload(data) {
  try {
    return await axios
      .post(`${baseUrl}/import-bib-no`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//BIB Number Log List
export async function athleticsbibloglist(id) {
  try {
    return await axios
      .get(`${baseUrl}/bib-excel-log/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Championslistofschool CARDS
export async function Championslistofschool(data) {
  try {
    return await axios
      .post(`${baseUrl}/individual-champions-list-for-school`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Achievement tournaments detail
export async function achievetourdetails(id) {
  try {
    return await axios
      .get(`${baseUrl}/achievement-view-tournament/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//update offline payment for interschool in Tournament school list
export async function interschoolupdateofflinepayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/update-offline-payment`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//athletics check the payment status for intraschool in Tournament school discount  list
export async function athleticsintratourpaymentstatus(data) {
  try {
    return await axios
      .post(`${baseUrl}/check-athletics-intra-pay-status`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//other than athletics check the payment status for intraschool in school discount list
export async function intratourpaymentstatus(data) {
  try {
    return await axios
      .post(`${baseUrl}/check-other-intra-pay-status`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Add External Tournament
export async function externaltourcreate(data) {
  try {
    
    return await axios
      .post(`${baseUrl}/create-external-tournament-by-coach`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Update External Tournament
export async function externaltourupdate(data) {
  try {
    
    return await axios
      .post(`${baseUrl}/update-external-tournament-by-coach`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
//List External Tournament For Admin
export async function adminexternaltourlist(data) {
  try {
    
    return await axios
      .post(`${baseUrl}/list-external-tournament-by-admin`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//List External Tournament
export async function externaltourlist(data) {
  try {
    
    return await axios
      .post(`${baseUrl}/list-external-tournament-by-coach`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//View External Tournament
export async function externaltourdetails(id) {
  try {
    
    return await axios
      .get(`${baseUrl}/view-external-tournament-by-coach/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Delete External Tournament
export async function externaltourdelete(id) {
  try {
    
    return await axios
      .get(`${baseUrl}/delete-external-tournament-by-coach/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Age Category List External Tournament Wise
export async function externaltouragecatlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-selected-category-for-external-tournament`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
//Team Create External Tournament
export async function externaltourteamcreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/create-team-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Update External Tournament
export async function externaltourteamupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/update-team-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Team List External Tournament
export async function externaltourteamlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/team-list-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team View External Tournament
export async function externaltourteamdetails(id) {
  try {
    return await axios
      .get(`${baseUrl}/view-team-external-tournament/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Delete External Tournament
export async function externaltourteamdelete(id) {
  try {
    return await axios
      .get(`${baseUrl}/delete-team-external-tournament/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



//Team Player List External Tournament
export async function externaltourteamplayerlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/team-player-list-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Team Player List External Tournament School Wise
export async function externalallplayerlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/all-players-from-school-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}




//Add Team Player External Tournament School Wise
export async function externaltourteamplayercreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/add-player-to-team-external-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Delete Team Player External Tournament School Wise
export async function externaltourteamplayerdelete(id) {
  try {
    return await axios
      .get(`${baseUrl}/delete-player-from-team-external-tournament/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Delete Team Player External Tournament School Wise
export async function calendarlistcommon() {
  try {
    return await axios
      .post(`${baseUrl}/all-tournaments-events-calender`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function cmstaglist() {
  try {
    return await axios
      .get(`${baseUrl}/cms/list-mtags`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function cmstagview(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/view-mtags?tags_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function cmstagdelete(id,tid) {
  try {
    return await axios
      .get(`${baseUrl}/cms/delete-mtags?admin_id=${id}&tags_id=${tid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function cmstagcreate(data) {
  try {
   
    return await axios
      .post(`${baseUrl}/cms/create-mtags`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function cmstagupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/update-mtags`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function funderschoollist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/funder-school-list?funder_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function fundertourlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/tournament-event-list-funder?funder_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function fundergallerylist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/gallery-list-funder?funder_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function funderresourceslist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/list-resources-funder?funder_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function schoolpublishtrack(id,sid,url) {
  try {
    return await axios
      .get(`${baseUrl}/cms/publish-school-webpage?admin_id=${id}&school_id=${sid}&webpage_url=${url}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function funderpublishtrack(id,sid,url) {
  try {
    return await axios
      .get(`${baseUrl}/cms/publish-funder-webpage?admin_id=${id}&funder_id=${sid}&webpage_url=${url}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function leaderlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/overall-leaderboard-list?tournament_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function leadercreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/create-overall-leaderboard`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function leaderupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/cms/update-overall-leaderboard`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpschoollist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/group-of-school-list?group_of_school_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grptourlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/tournament-event-list-group-of-schools?group_of_school_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpgallerylist(id) {
  try {
    return await axios
      .get(`${baseUrl}/cms/gallery-list-group-of-schools?group_of_school_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function grpmedalslist(data) {
  try {
    return await axios
    .post(`${baseUrl}/cms/group-of-schools-schools-achievements-summary`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpresourceslist(id,srh) {
  try {
    return await axios
      .get(`${baseUrl}/cms/list-resources-group-of-schools?group_of_school_id=${id}&tags_search=${srh}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function grpschpublishtrack(id,sid,url) {
  try {
    return await axios
      .get(`${baseUrl}/cms/publish-group-of-schools-webpage?admin_id=${id}&funder_id=${sid}&webpage_url=${url}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response)
  if (error.response.status === 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
