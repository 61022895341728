import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  scheadfitnesslist,
  schprofileview,
} from "../../../../services/schoolhead";
import { useState } from "react";
import moment from "moment";
import { gettokenfromapi } from "../../../../services/superadmin";
import { sahassessmentdatelist } from "../../../../services/sahapi";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const FitnessList = () => {
  const [snlist1, set1SNList] = useState("");
  const [std1, set1Std] = useState([]);
  const [school1, set1School] = useState([]);
  const [national1, set1National] = useState([]);

  const [snlist2, set2SNList] = useState("");
  const [std2, set2Std] = useState([]);
  const [school2, set2School] = useState([]);
  const [national2, set2National] = useState([]);

  const [snlist3, set3SNList] = useState("");
  const [std3, set3Std] = useState([]);
  const [school3, set3School] = useState([]);
  const [national3, set3National] = useState([]);

  const [snlist4, set4SNList] = useState("");
  const [std4, set4Std] = useState([]);
  const [school4, set4School] = useState([]);
  const [national4, set4National] = useState([]);

  const [snlist5, set5SNList] = useState("");
  const [std5, set5Std] = useState([]);
  const [school5, set5School] = useState([]);
  const [national5, set5National] = useState([]);

  const [snlist6, set6SNList] = useState("");
  const [std6, set6Std] = useState([]);
  const [school6, set6School] = useState([]);
  const [national6, set6National] = useState([]);

  const [snlist7, set7SNList] = useState("");
  const [std7, set7Std] = useState([]);
  const [school7, set7School] = useState([]);
  const [national7, set7National] = useState([]);

  const [snlistover, setSNOver] = useState("");
  const [schoolover, setOverSchool] = useState([]);
  const [nationalover, setOverNational] = useState([]);

  const [loading, setLoading] = useState(false);

  let arr = [];
  const [odata, setOData] = useState("");
  const [ndata, setNData] = useState("");

  useEffect(() => {
    setLoading(true);
    // console.log('Start',new Date().toLocaleString())
    schprofileview().then((res) => {
      if (res.status === true) {
        const temp = new FormData();
        temp.append("data_req", "fitness_trends" + res.data.school_unique_no);
        gettokenfromapi(temp).then((res2) => {
          if (res2.status === true) {
            sahassessmentdatelist(
              "fitness_trends&chid=" + res.data.school_unique_no,
              res2.data
            ).then((response) => {
              if (response.status === true) {
                // console.log('API',new Date().toLocaleString())
                let arr = [];
                for (let i = 0; i < response.data.length; i++) {
                  if (response.data[i].report_name !== "Overall Report") {
                    if (response.data[i].std_wise_array.length !== 0) {
                      for (
                        let j = 0;
                        j < response.data[i].std_wise_array.length;
                        j++
                      ) {
                        if (
                          response.data[i].std_wise_array[j].fitness_trends_avg
                            .length !== 0
                        ) {
                          if (
                            response.data[i].std_wise_array[j]
                              .fitness_trends_avg[0].old_avg !== 0 ||
                            response.data[i].std_wise_array[j]
                              .fitness_trends_avg[1].latest_avg !== 0
                          ) {
                            setNData(
                              response.data[i].std_wise_array[j]
                                .fitness_trends_avg[1].app_date
                            );
                            setOData(
                              response.data[i].std_wise_array[j]
                                .fitness_trends_avg[0].app_date
                            );

                            arr.push({
                              report_name: response.data[i].report_name,
                              std: response.data[i].std_wise_array[j].std,
                              school:
                                response.data[i].std_wise_array[j]
                                  .fitness_trends_avg[0].old_avg,
                              national:
                                response.data[i].std_wise_array[j]
                                  .fitness_trends_avg[1].latest_avg,
                            });
                          }
                        }
                      }
                    }
                  } else {
                    if (
                      response.data[i].fitness_trends_avg_overall.length !== 0
                    ) {
                      if (
                        response.data[i].fitness_trends_avg_overall[0]
                          .old_avg !== 0 ||
                        response.data[i].fitness_trends_avg_overall[1]
                          .latest_avg !== 0
                      ) {
                        arr.push({
                          report_name: response.data[i].report_name,
                          std: "",
                          school:
                            response.data[i].fitness_trends_avg_overall[0]
                              .old_avg,
                          national:
                            response.data[i].fitness_trends_avg_overall[1]
                              .latest_avg,
                        });
                      }
                    }
                  }
                }
                const filtered = arr.filter((user) =>
                  user.report_name.includes("30m run")
                );
                if (filtered.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered.length; i++) {
                    stan.push(filtered[i].std);
                    sdata.push(filtered[i].school);
                    ndata.push(filtered[i].national);
                  }
                  set1SNList(filtered[0].report_name);
                  set1Std(stan);
                  set1School(sdata);
                  set1National(ndata);
                }
                const filtered2 = arr.filter((user) =>
                  user.report_name.includes("Beep Test")
                );
                if (filtered2.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered2.length; i++) {
                    stan.push(filtered2[i].std);
                    sdata.push(filtered2[i].school);
                    ndata.push(filtered2[i].national);
                  }
                  set2SNList(filtered2[0].report_name);
                  set2Std(stan);
                  set2School(sdata);
                  set2National(ndata);
                }

                const filtered3 = arr.filter((user) =>
                  user.report_name.includes("Medicine Ball Throw")
                );
                if (filtered3.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered3.length; i++) {
                    stan.push(filtered3[i].std);
                    sdata.push(filtered3[i].school);
                    ndata.push(filtered3[i].national);
                  }
                  set3SNList(filtered3[0].report_name);
                  set3Std(stan);
                  set3School(sdata);
                  set3National(ndata);
                }

                const filtered4 = arr.filter((user) =>
                  user.report_name.includes("Sit & Reach (in cm.)")
                );
                if (filtered4.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered4.length; i++) {
                    stan.push(filtered4[i].std);
                    sdata.push(filtered4[i].school);
                    ndata.push(filtered4[i].national);
                  }
                  set4SNList(filtered4[0].report_name);
                  set4Std(stan);
                  set4School(sdata);
                  set4National(ndata);
                }

                const filtered5 = arr.filter((user) =>
                  user.report_name.includes("Standing Broad Jump")
                );
                if (filtered5.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered5.length; i++) {
                    stan.push(filtered5[i].std);
                    sdata.push(filtered5[i].school);
                    ndata.push(filtered5[i].national);
                  }
                  set5SNList(filtered5[0].report_name);
                  set5Std(stan);
                  set5School(sdata);
                  set5National(ndata);
                }

                const filtered6 = arr.filter((user) =>
                  user.report_name.includes("Sit Ups in 30 sec")
                );
                if (filtered6.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered6.length; i++) {
                    stan.push(filtered6[i].std);
                    sdata.push(filtered6[i].school);
                    ndata.push(filtered6[i].national);
                  }
                  set6SNList(filtered6[0].report_name);
                  set6Std(stan);
                  set6School(sdata);
                  set6National(ndata);
                }

                const filtered7 = arr.filter((user) =>
                  user.report_name.includes("Sit Ups in 1 min")
                );
                if (filtered7.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filtered7.length; i++) {
                    stan.push(filtered7[i].std);
                    sdata.push(filtered7[i].school);
                    ndata.push(filtered7[i].national);
                  }
                  set7SNList(filtered7[0].report_name);
                  set7Std(stan);
                  set7School(sdata);
                  set7National(ndata);
                }

                const filteredover = arr.filter((user) =>
                  user.report_name.includes("Overall Report")
                );
                if (filteredover.length !== 0) {
                  var stan = [];
                  var sdata = [];
                  var ndata = [];
                  for (let i = 0; i < filteredover.length; i++) {
                    sdata.push(filteredover[i].school);
                    ndata.push(filteredover[i].national);
                  }
                  setSNOver(filteredover[0].report_name);
                  setOverSchool(sdata);
                  setOverNational(ndata);
                }
              }
              // console.log('End',new Date().toLocaleString())
              setLoading(false);
            });
          }
        });
        // const temp = new FormData();
        // temp.append("school_id", res.data.school_id);
        // scheadfitnesslist(temp).then((response) => {
        //   if (response.status === true) {
        //     if (response.data.length !== 0) {
        //       if (response.data[0].status === "failed") {
        //         set1SNList("");
        //         set2SNList("");
        //         set3SNList("");
        //         set4SNList("");
        //         set5SNList("");
        //         set6SNList("");
        //         set7SNList("");
        //       } else {
        //         arr = [];

        //         for (let i = 0; i < response.data.length; i++) {
        //           if (response.data[i].report_name !== "Overall Report") {
        //             if (response.data[i].std_wise_array.length !== 0) {
        //               for (
        //                 let j = 0;
        //                 j < response.data[i].std_wise_array.length;
        //                 j++
        //               ) {
        //                 if (
        //                   response.data[i].std_wise_array[j].fitness_trends_avg
        //                     .length !== 0
        //                 ) {
        //                   if (
        //                     response.data[i].std_wise_array[j]
        //                       .fitness_trends_avg[0].old_avg !== null ||
        //                     response.data[i].std_wise_array[j]
        //                       .fitness_trends_avg[1].latest_avg !== null
        //                   ) {
        //                     setNData(
        //                       response.data[i].std_wise_array[j]
        //                         .fitness_trends_avg[1].app_date
        //                     );
        //                     setOData(
        //                       response.data[i].std_wise_array[j]
        //                         .fitness_trends_avg[0].app_date
        //                     );

        //                     arr.push({
        //                       report_name: response.data[i].report_name,
        //                       std: response.data[i].std_wise_array[j].std,
        //                       school:
        //                         response.data[i].std_wise_array[j]
        //                           .fitness_trends_avg[0].old_avg,
        //                       national:
        //                         response.data[i].std_wise_array[j]
        //                           .fitness_trends_avg[1].latest_avg,
        //                     });
        //                   }
        //                 }
        //               }
        //             }
        //           } else {
        //             if (
        //               response.data[i].fitness_trends_avg_overall.length !== 0
        //             ) {
        //               if (
        //                 response.data[i].fitness_trends_avg_overall[0]
        //                   .old_avg !== null ||
        //                 response.data[i].fitness_trends_avg_overall[1]
        //                   .latest_avg !== null
        //               ) {
        //                 arr.push({
        //                   report_name: response.data[i].report_name,
        //                   std: "",
        //                   school:
        //                     response.data[i].fitness_trends_avg_overall[0]
        //                       .old_avg,
        //                   national:
        //                     response.data[i].fitness_trends_avg_overall[1]
        //                       .latest_avg,
        //                 });
        //               }
        //             }
        //           }
        //         }
        //         const filtered = arr.filter((user) =>
        //           user.report_name.includes("30m run")
        //         );
        //         if (filtered.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered.length; i++) {
        //             stan.push(filtered[i].std);
        //             sdata.push(filtered[i].school);
        //             ndata.push(filtered[i].national);
        //           }
        //           set1SNList(filtered[0].report_name);
        //           set1Std(stan);
        //           set1School(sdata);
        //           set1National(ndata);
        //         }
        //         const filtered2 = arr.filter((user) =>
        //           user.report_name.includes("Beep Test")
        //         );
        //         if (filtered2.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered2.length; i++) {
        //             stan.push(filtered2[i].std);
        //             sdata.push(filtered2[i].school);
        //             ndata.push(filtered2[i].national);
        //           }
        //           set2SNList(filtered2[0].report_name);
        //           set2Std(stan);
        //           set2School(sdata);
        //           set2National(ndata);
        //         }

        //         const filtered3 = arr.filter((user) =>
        //           user.report_name.includes("Medicine Ball Throw")
        //         );
        //         if (filtered3.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered3.length; i++) {
        //             stan.push(filtered3[i].std);
        //             sdata.push(filtered3[i].school);
        //             ndata.push(filtered3[i].national);
        //           }
        //           set3SNList(filtered3[0].report_name);
        //           set3Std(stan);
        //           set3School(sdata);
        //           set3National(ndata);
        //         }

        //         const filtered4 = arr.filter((user) =>
        //           user.report_name.includes("Sit & Reach (in cm.)")
        //         );
        //         if (filtered4.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered4.length; i++) {
        //             stan.push(filtered4[i].std);
        //             sdata.push(filtered4[i].school);
        //             ndata.push(filtered4[i].national);
        //           }
        //           set4SNList(filtered4[0].report_name);
        //           set4Std(stan);
        //           set4School(sdata);
        //           set4National(ndata);
        //         }

        //         const filtered5 = arr.filter((user) =>
        //           user.report_name.includes("Standing Broad Jump")
        //         );
        //         if (filtered5.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered5.length; i++) {
        //             stan.push(filtered5[i].std);
        //             sdata.push(filtered5[i].school);
        //             ndata.push(filtered5[i].national);
        //           }
        //           set5SNList(filtered5[0].report_name);
        //           set5Std(stan);
        //           set5School(sdata);
        //           set5National(ndata);
        //         }

        //         const filtered6 = arr.filter((user) =>
        //           user.report_name.includes("Sit Ups in 30 sec")
        //         );
        //         if (filtered6.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered6.length; i++) {
        //             stan.push(filtered6[i].std);
        //             sdata.push(filtered6[i].school);
        //             ndata.push(filtered6[i].national);
        //           }
        //           set6SNList(filtered6[0].report_name);
        //           set6Std(stan);
        //           set6School(sdata);
        //           set6National(ndata);
        //         }

        //         const filtered7 = arr.filter((user) =>
        //           user.report_name.includes("Sit Ups in 1 min")
        //         );
        //         if (filtered7.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filtered7.length; i++) {
        //             stan.push(filtered7[i].std);
        //             sdata.push(filtered7[i].school);
        //             ndata.push(filtered7[i].national);
        //           }
        //           set7SNList(filtered7[0].report_name);
        //           set7Std(stan);
        //           set7School(sdata);
        //           set7National(ndata);
        //         }

        //         const filteredover = arr.filter((user) =>
        //           user.report_name.includes("Overall Report")
        //         );
        //         if (filteredover.length !== 0) {
        //           var stan = [];
        //           var sdata = [];
        //           var ndata = [];
        //           for (let i = 0; i < filteredover.length; i++) {
        //             sdata.push(filteredover[i].school);
        //             ndata.push(filteredover[i].national);
        //           }
        //           setSNOver(filteredover[0].report_name);
        //           setOverSchool(sdata);
        //           setOverNational(ndata);
        //         }
        //       }
        //       setLoading(false);
        //     }
        //   }
        // });
      }
    });
  }, []);
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Fitness Trends</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Student Fitness</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Fitness Trends
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading && (
        <>
          {snlist1 === "" &&
            snlist2 === "" &&
            snlist3 === "" &&
            snlist4 === "" &&
            snlist5 === "" &&
            snlist6 === "" && (
              <div className="card mt-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="mb-2 text-success text-danger">
                        Fitness Trends will be updated soon
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="row">
            {snlist1 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std1,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school1,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national1,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist1,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg 30m run (in sec.)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist2 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std2,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school2,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national2,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist2,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg Beep Test (in #laps)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist3 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std3,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school3,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national3,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist3,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg Medicine Ball Throw (in m.)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist4 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std4,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school4,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national4,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist4,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg Sit & Reach (in cm.)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist5 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std5,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school5,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national5,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist5,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg Standing Broad Jump (in m.)",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist6 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std6,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school6,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national6,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist6,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg No. of Sit Ups in 30 sec.",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {snlist7 !== "" && (
              <div className="col-md-6">
                <div className="card mt-0">
                  <div className="card-body">
                    <Bar
                      data={{
                        labels: std7,
                        datasets: [
                          {
                            label: moment(odata).format("MMM YYYY"),
                            data: school7,
                            backgroundColor: "#25e3da",
                          },
                          {
                            label: moment(ndata).format("MMM YYYY"),
                            data: national7,
                            backgroundColor: "#2977ff",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: snlist7,
                          },
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: "Standard",
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Avg No. of Sit Ups in 1 min.",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* {snlistover !== "" && (
            <>
              <hr />

              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: [""],
                          datasets: [
                            {
                              label: moment(odata).format("MMM YYYY"),
                              data: schoolover,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: moment(ndata).format("MMM YYYY"),
                              data: nationalover,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlistover,
                            },
                          },
                          scales: {
                            y: {
                              title: {
                                display: true,
                                text: "Average (in sec)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
        </>
      )}
    </div>
  );
};
