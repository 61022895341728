import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import exportUrl from "../../../../../exportUtl";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import {
  athleticschangerefundpaymentstatus,
  athleticsintertourentrylist,
  athleticsintratourentrylist,
  sendpaymentlink,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  citysendpaymentlink,
  crmtourviewdetail,
} from "../../../../../services/cityregionaladmin";
import {
  scheadtourviewdetail,
} from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  bibnogenerateornot,
  tournamentgeneratebib,
} from "../../../../../services/common";

export const AthleticsEntryplayerslist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");
  const [ttype, setType] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");
  const [eodate, setEODate] = useState("");
  const [curdate, setCurDate] = useState("");
  const [tfree, setFree] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    // {
    //   headerName: "Payment",
    //   width: 150,
    //   field: "sent",
    //   sortable: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     if (params.value !== 1) {
    //       if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
    //         if (localStorage.getItem("Role") === "1") {
    //           return (
    //             <div className="text-center">
    //               <a
    //                 className="btn-sm btn-success"
    //                 onClick={() => sendpayment(params)}
    //               >
    //                 Send Payment Link
    //               </a>
    //             </div>
    //           );
    //         }
    //       } else {
    //         return (
    //           <div className="text-center">
    //             <a className="text-danger">Entry Closed</a>
    //           </div>
    //         );
    //       }
    //     } else if (params.value === 1) {
    //       return (
    //         <div className="text-center">
    //           <a className="text-success">Paid</a>
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 120,
      field: "tournament_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      width: 120,
      field: "eventslist",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Amount",
    //   width: 150,
    //   field: "total_amount",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Payment Status",
    //   width: 150,
    //   field: "orderstatus",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Order Id",
    //   width: 150,
    //   field: "orderid",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    // {
    //   headerName: "Payment Id",
    //   width: 150,
    //   field: "paymentor_tracking_id",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
  ];

  const columnInDefs = [
    // {
    //   headerName: "Action",
    //   width: 100,
    //   field: "entries_id",
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     if (params.data.order_status === "SUCCESS") {
    //       if (moment(new Date()).format("yyyy-MM-DD") < sdate) {
    //         return (
    //           <div className="text-center">
    //             <a
    //               className="btn-sm btn-secondary"
    //               title="Change Payment Status"
    //               onClick={() => handleShow(params.value, params)}
    //             >
    //               <i className="fa fa-edit"></i>
    //             </a>
    //           </div>
    //         );
    //       } else {
    //         return (
    //           <div className="text-center">
    //             <a className="text-danger">Entry Closed</a>
    //           </div>
    //         );
    //       }
    //     }
    //   },
    // },
    {
      headerName: "Refund",
      width: 100,
      field: "entries_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (
          params.data.orderstatus === "SUCCESS" && params.data.sent === 1 &&
          ttype !== "Inter School Tournament"
        ) {
          if (tfree === "N") {
            return (
              <div className="text-center">
                <a
                  className="btn-sm btn-success"
                  title="Refund Payment"
                  onClick={() => handleRShow(params.value, params)}
                >
                  <i className="fa fa-undo"></i> Refund
                </a>
              </div>
            );
          }
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "orderstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment",
      width: 150,
      field: "sent",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== 1) {
          if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
            if (localStorage.getItem("Role") !== "4") {
              return (
                <div className="text-center">
                  <a
                    className="btn-sm btn-success"
                    onClick={() => sendpayment(params)}
                  >
                    Send Payment Link
                  </a>
                </div>
              );
            }
          } else {
            return (
              <div className="text-center">
                <a className="text-danger">Entry Closed</a>
              </div>
            );
          }
        } else if (params.value === 1) {
          return (
            <div className="text-center">
              <a className="text-success">Paid</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BIB No",
      width: 100,
      field: "bib_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 120,
      field: "tournament_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      width: 120,
      field: "eventslist",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    
  ];


  const [rshow, setRShow] = useState(false);
  const [refdate, setRefundDate] = useState("");
  const [refundtype, setRefundType] = useState("");

  const [refid, setRefundID] = useState("");
  const [ename, setEntryName] = useState("");

  function handleRShow(fid, fdata) {
    dotracking(
      "refund icon for refund payment in " +
        tname +
        " (" +
        id +
        ") tournament for player " +
        fdata.data.first_name +
        " (" +
        fdata.data.username +
        " - " +
        fid +
        ") $$ " +
        trackobj.tourplayerlistheading
    );
    setEntryName(
      fdata.data.first_name + " (" + fdata.data.username + " - " + fid + ")"
    );
    setRefundID(fid);
    setRefundDate("");
    setErrorMsg("");
    setRShow(true);
  }

  function handleRclose() {
    dotracking(
      "close button in refund payment for " +
        ename +
        " player in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    setRShow(false);
    setEntryName("");
    setRefundID("");
    setRefundDate("");
    setErrorMsg("");
  }

  function submitrefund(e) {
    e.preventDefault();
    setErrorMsg("");
    var valid = "";
    if (refundtype !== "") {
      if (refundtype === "2") {
        if (refdate === "") {
          valid = "Not";
          setErrorMsg("Please Select Refund Date");
        }
      }
      if (valid === "") {
        const temp = new FormData();
        temp.append("id", refid);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("refunded_date", refdate);
        temp.append("refunded_type", refundtype);

        dotracking(
          "submit button in refund payment for " +
            ename +
            " player in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerlistheading
        );
        athleticschangerefundpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            handleRclose();
            toast.success(response.message);
            listathletics(ttype);
          }
        });
      }
    } else {
      setErrorMsg("Please Select Refund Type");
    }
  }


  function sendpayment(datas) {
    Swal.fire({
      title: "Are you sure want to send a payment link to respective email?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("Role") === "1") {
          dotracking(
            "send payment link button in entry players list for " +
              datas.data.first_name +
              " (" +
              datas.data.username +
              " - " +
              datas.data.user_id +
              ") player" +
              " in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          sendpaymentlink(id, datas.data.user_id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        } else if (localStorage.getItem("Role") === "3") {
          dotracking(
            "send payment link button in entry players list for " +
              datas.data.first_name +
              " (" +
              datas.data.username +
              " - " +
              datas.data.user_id +
              ") player" +
              " in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          citysendpaymentlink(id, datas.data.user_id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      }
    });
  }

  const [bgornot, setBGorNot] = useState(false);
  useEffect(() => {
    setCurDate(moment(new Date()).format("YYYY-MM-DD"));

    bibnogenerateornot(id).then((response) => {
      if (response.status === true) {
        if (response.data !== null) {
          setBGorNot(true);
          setBoysBIB(response.data.boys_start_no);
          setGirlsBIB(response.data.girls_start_no);
        }
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setType(response.data.tournament.meet_type);
          setFree(response.data.tournament.free_tournament);

          listathletics(response.data.tournament.meet_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setType(response.data.tournament.meet_type);
          setFree(response.data.tournament.free_tournament);

          listathletics(response.data.tournament.meet_type);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true) {
          // if (response.data.length !== 0) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setType(response.data.tournament.meet_type);
          setFree(response.data.tournament.free_tournament);

          listathletics(response.data.tournament.meet_type);
          // }
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setType(response.data.tournament.meet_type);
          setFree(response.data.tournament.free_tournament);

          listathletics(response.data.tournament.meet_type);
        }
      });
    }
  }, []);

  function listathletics(atype) {
    setFilterProduct([]);
    if (atype === "Inter School Tournament") {
      athleticsintertourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.inter_school_entries);
        }
      });
    } else if (atype === "Intra School Tournament") {
      athleticsintratourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.individual_entries);
        }
      });
    }
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      if(ttype === "Intra School Tournament"){
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/superadmin/tournamentlist");
      }else{
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/superadmin/athleticschoollist/" + id);
      }
     
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in entry players list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourschoollistheading
      );
      navigate("/cityregional/athleticschoollist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1"){
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      }else if (localStorage.getItem("Page") === "3"){
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
     
    } else if (localStorage.getItem("Role") === "5") {
      dotracking(
        "back button in entry players list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourschoollistheading
      );
      navigate("/referee/athleticschoollist/" + id);
    }
  }
  const gridRef = useRef();

  function importbib() {
    dotracking(
      "import bib button in entry players list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.bibuploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/bibupload/" + id);
    }
  }

  function eplayer() {
    var excelParams = {
      // columnKeys: [
      //   "event_date",
      //   "time",
      //   "events",
      //   "age_categories",
      //   "gender",
      //   "heat_or_final",
      // ],
      // allColumns: false,
      fileName: "Entry List.csv",
    };
    dotracking(
      "export player button in entry players list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    gridRef.current.api.exportDataAsCsv(excelParams);
  }

  const [show, setShow] = useState(false);
  const [boysbib, setBoysBIB] = useState("");
  const [girlsbib, setGirlsBIB] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  function handlebib() {
    setShow(true);
    setErrorMsg("");
  }

  function handleclose() {
    setShow(false);
    setBoysBIB("");
    setGirlsBIB("");
    setErrorMsg("");
  }

  function submitbib(e) {
    e.preventDefault();
    setErrorMsg("");
    if (boysbib === "" && girlsbib === "") {
      setErrorMsg("Please Enter Boys and Girls BIB Number");
    } else if (boysbib === "") {
      setErrorMsg("Please Enter Boys BIB Number");
    } else if (girlsbib === "") {
      setErrorMsg("Please Enter Girls BIB Number");
    } else {
      dotracking(
        "add bib button in entry players list for boys (" +
          boysbib +
          ") and girls (" +
          girlsbib +
          ") in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerlistheading
      );
      const temp = new FormData();
      temp.append("bib_boys_start_no", boysbib);
      temp.append("bib_girls_starts_no", girlsbib);
      temp.append("tournament_meet_id", id);
      temp.append("created_by", localStorage.getItem("UID"));
      if (bgornot === true) {
        temp.append("ins_or_upd", 2);
      } else {
        temp.append("ins_or_upd", 1);
      }

      tournamentgeneratebib(temp).then((response) => {
        if (response.status === true) {
          setBGorNot(true);
          toast.success(response.message);
          setShow(false);
          setErrorMsg("");
          listathletics(ttype);
        }
      });
    }
  }

  function rowDoubleClicked(e) {
    dotracking(
      "row double clicked in entry players list for " +
        e.data.first_name +
        " (" +
        e.data.usename +
        " - " +
        e.data.user_id +
        ") player " +
        " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.playerdetheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/schoolhead/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentplayer/" + e.data.user_id);
    }
  }

  function reflist() {
    dotracking(
      "refund players list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.refundlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/athleticsrefundlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/athleticsrefundlist/" + id);
    }
  }


  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Player Entry Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Player Entry Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-5">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                      {/* <h4>{tloc}</h4> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="text-right">
                    <a className="btn btn-warning me-2" onClick={reflist}>
                        <i className="fa-solid fa-list"></i> Refund List
                      </a>
                      {bgornot && localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-warning me-2" onClick={handlebib}>
                          <i className="fa fa-book"></i> Re-Generate BIB
                        </a>
                      )}
                      {!bgornot && localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-success me-2" onClick={handlebib}>
                          <i className="fa fa-book"></i> Generate BIB
                        </a>
                      )}
                      {localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-export me-2" onClick={importbib}>
                          <i className="fa-solid fa-file-import"></i> Import BIB
                        </a>
                      )}
                      <a className="btn btn-secondary me-2" onClick={eplayer}>
                        <i className="fa-solid fa-file-export"></i> Export
                      </a>
                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  {ttype === "Intra School Tournament" && 
                     <AgGridReact
                     ref={gridRef}
                     columnDefs={columnInDefs}
                     rowData={filterProduct}
                     pagination="true"
                     paginationPageSize="10"
                     sortingOrder={sorting}
                     suppressExcelExport={true}
                     suppressDragLeaveHidesColumns={true}
                     onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                   />
                  }
                   {ttype !== "Intra School Tournament" && 
                     <AgGridReact
                     ref={gridRef}
                     columnDefs={columnDefs}
                     rowData={filterProduct}
                     pagination="true"
                     paginationPageSize="10"
                     sortingOrder={sorting}
                     suppressExcelExport={true}
                     suppressDragLeaveHidesColumns={true}
                     onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                   />
                  }
                 
                </div>

                <Modal show={show} size="lg">
                  <Modal.Header>
                    <h4> Generate BIB Number</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Boys BIB Number{" "}
                            <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder="BIB Number"
                            className="form-control"
                            value={boysbib}
                            onChange={(e) => setBoysBIB(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Girls BIB Number{" "}
                            <span className="errortext"> *</span>
                          </label>
                          <input
                            type="text"
                            placeholder="BIB Number"
                            className="form-control"
                            value={girlsbib}
                            onChange={(e) => setGirlsBIB(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    <a className="btn btn-success" onClick={submitbib}>
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal>

                <Modal show={rshow} size="lg">
                  <Modal.Header>
                    <h4>Refund Payment</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Refund Type <span className="errortext">*</span> </label>
                          <select
                            className="form-control"
                            value={refundtype}
                            onChange={(e) => setRefundType(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Select Refund Type
                            </option>
                            <option value="1">Automatic Refund</option>
                            <option value="2">Manual Refund</option>
                          </select>
                        </div>
                      </Col>
                      {refundtype === "2" && (
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <label className="mb-2">Refund Date <span className="errortext">*</span></label>
                            <input
                              type="date"
                              className="form-control"
                              min={eodate}
                              max={curdate}
                              value={refdate}
                              onChange={(e) => setRefundDate(e.target.value)}
                            />
                          </div>
                        </Col>
                      )}

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleRclose}>
                      Close
                    </a>

                    <a onClick={submitrefund} className="btn btn-success">
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
