import React, { Fragment, useEffect, useState } from "react";
import { forgotpassword } from "../../services/login";
import { useNavigate } from "react-router-dom";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";

export const Forgot = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [valemail, setValEmail] = useState("");
  const navigate = useNavigate();

  function Submitforgot(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMsg("");
    if (email !== "") {
      let valid = "";
      // let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      // if (!regEmail.test(email)) {
      //     valid = "requires"
      //     setErrorMsg("Please Enter a valid Email Address");
      // }
      if (valid === "") {
        dotracking("submit button in forgot password with " + email + " $$ " + trackobj.forgotheading);
        setLoading(true);
        forgotpassword(email).then((response) => {
          if (response.status === true) {
            setLoading(false);
            setSubmitted(false);
            setErrorMsg(response.message);
          } else {
            setLoading(false);
            setErrorMsg(response.message);
          }
        });
      }
    } else {
      setErrorMsg("Please Enter Username");
    }
  }

  function backpage() {
    dotracking("back to login link in forgot passwword $$ " + trackobj.loginheading);
    navigate("/login");
  }
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="overflow-hidden">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-6 pe-0 tab-100 order_c mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <img
                      src={require("../../assets/images/pathwayz_logo.png")}
                      alt="pathwayz"
                      className="pathwayz_logo"
                    />
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="slidedown border-t"></div>
                <div className="form slidedown">
                  <img
                    src={require("../../assets/images/logo_sv.png")}
                    alt="logosv"
                    style={{ margin: "auto" }}
                  />
                  <div className="form-text login">
                    <h3>Forgot Password</h3>
                  </div>
                  <form method="post" id="loginform" className="login">
                    {/* <div className="input-field">
                                            <label>
                                                <i className="fa-solid fa-user"></i>Email Address
                                            </label>

                                            <input
                                                type="text"
                                                id="mail-email"
                                                placeholder="Enter Email Address"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {submitted && !email && (
                                                <span className="text-danger">Email Address is required</span>
                                            )}
                                            {valemail !== "" && email && (
                                                <span className="text-danger">{valemail}</span>
                                            )}
                                            {errorMsg !== "" && email && (
                                                <span className="text-danger">{errorMsg}</span>
                                            )}
                                        </div> */}
                    <div className="input-field">
                      <label>
                        <i className="fa-solid fa-user"></i>Username
                      </label>

                      <input
                        type="text"
                        id="mail-email"
                        placeholder="Enter Username"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {submitted && !email && (
                        <span className="text-danger">
                          Username is required
                        </span>
                      )}
                      {errorMsg !== "" && email && (
                        <span className="text-danger">{errorMsg}</span>
                      )}
                    </div>
                    <button
                      className="form-btn login"
                      id="login"
                      type="button"
                      onClick={Submitforgot}
                    >
                      <span>Submit</span>
                    </button>
                  </form>
                  <hr />
                  <div className="con-text regis">
                    <p className="mb-0">
                      <strong>
                        <a onClick={backpage}>Back to Login</a>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
