import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import {
  sportsvalidate,
  sporttypecreate,
  sporttypedetails,
  sporttypelist,
  sporttypestatus,
  sporttypeupdate,
} from "../../../../services/superadmin";
import Swal from "sweetalert2";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const SportsMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3path, setS3Path] = useState([]);

  const [show, setShow] = useState(false);
  const [sportid, setId] = useState(0);
  const [sportname, setSport] = useState("");
  const [sporticon, setSportIcon] = useState("");
  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Name",
      field: "sports_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Icon",
      field: "icon",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return(
          <div className="text-center">
           <img src={s3path + params.value} width="50" height="50" />
          </div>
          )
        }
      }
    },
    {
      headerName: "Status",
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onClick={() => deletesports(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    // {
    //     headerName: "Actions",
    //     field: "sports_cat_uid",
    //     resizable: true,
    //     filter: false,
    //     cellRenderer: function (params) {
    //         return (
    //             <div className="text-center">
    //                 <a
    //                     onClick={() => viewsports(params.value)} title="Edit Sports"
    //                     className="btn-sm btn-secondary"
    //                 >
    //                     <i className="fa fa-edit fa-lg"></i>
    //                 </a>
    //             </div>

    //         );
    //     },
    // },
  ];

  const columnSDefs = [
    {
      headerName: "Name",
      field: "sports_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Icon",
      field: "icon",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return(
            <div className="text-center">
            <img src={s3path + params.value} width="50" height="50" />
           </div>
          )
        }
      }
    },
    {
      headerName: "Status",
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onClick={() => deletesports(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "sports_cat_uid",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewsports(params.value)}
              title="Edit Sports"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  function deletesports(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("sports_cat_uid", list.data.sports_cat_uid);
          temp.append("active", 0);
          dotracking(
            "status icon in sports category list from Active to Inactive for " +
              list.data.sports_category +
              " (" +
              list.data.sports_cat_uid +
              ") $$ " +
              trackobj.sportlistheading
          );
          sporttypestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("sports_cat_uid", list.data.sports_cat_uid);
          temp.append("active", 1);
          dotracking(
            "status icon in sports category list from Inactive to Active for " +
              list.data.sports_category +
              " (" +
              list.data.sports_cat_uid +
              ") $$ " +
              trackobj.sportlistheading
          );
          sporttypestatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function viewsports(aid) {
    setShow(true);

    sporttypedetails(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.list.sports_cat_uid);
        setSport(response.data.list.sports_category);
        if(response.data.list.icon !== null){
          setSportIcon(response.data.s3+response.data.list.icon);
        }
        
        setStatus(response.data.list.is_active + "");
        dotracking(
          "edit button in sports category for " +
            response.data.list.sports_category +
            " (" +
            aid +
            ") $$ " +
            trackobj.sportlistheading
        );
      }
    });
  }

  function handleshow() {
    setId(0);
    setSport("");
    setSportIcon("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setSport("");
    setSportIcon("");
    setStatus("1");
    setErrorMsg("");
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    sporttypelist().then((response) => {
      if (response.status === true) {
        setS3Path(response.data.s3)
        setFilterProduct(response.data.sports_list);
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submitsports(e) {
    e.preventDefault();
    setErrorMsg("");
    const valid =""
    if (sportname !== "") {
      if (sportid === 0) {
        if(iconerror !== ""){
          valid=iconerror
          setErrorMsg(iconerror);
        }
        if(valid===""){
          const temp1 = new FormData();
          temp1.append("sports", sportname);
          sportsvalidate(temp1).then((res) => {
            if (res.status === true) {
              const temp = new FormData();
              temp.append("sports_category", sportname);
              temp.append("icon", sporticon);
              temp.append("is_active", status);
              dotracking(
                "add button in sports category for " +
                  sportname +
                  " $$ " +
                  trackobj.sportlistheading
              );
              sporttypecreate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                }
              });
            } else {
              Swal.fire({
                text: sportname + " " + res.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#4ecc48",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  setId(0);
                  setSport("");
                  setSportIcon("");
                  setStatus("1");
                  setErrorMsg("");
                }
              });
            }
          });
        }
        
      } else if (sportid !== 0) {

        if(iconerror !== ""){
          valid=iconerror
          setErrorMsg(iconerror);
        }
        if(valid === ""){
          const temp = new FormData();
          temp.append("sports_cat_uid", sportid);
          temp.append("sports_category", sportname);
          temp.append("icon", sporticon);
          temp.append("is_active", status);
          dotracking(
            "edit button in sports category for " +
              sportname +
              " (" +
              sportid +
              ") $$ " +
              trackobj.sportlistheading
          );
          sporttypeupdate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            }
          });
        }
        
      }
    } else {
      setErrorMsg("Please Enter Sport Category Name");
    }
  }

  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      allColumns: false,
      fileName: "Sports List.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  const [iconerror, setIconError] = useState("");
  function iconfile(event) {
    setIconError("");
    setSportIcon("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
        setSportIcon(event.target.files[0]);
      } else {
        setErrorMsg("Sport Category Icon File should be png or jpg or jpeg format");
        setIconError("Sport Category Icon File should be png or jpg or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMsg("Sport Category Icon File size should be less than 3MB");
        setIconError("Sport Category Icon File size should be less than 3MB");
      }
    }
  }


  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Sports Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Sports Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
               
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add Sports
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                {localStorage.getItem("Subrole") === "1" && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnSDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                )}
                {localStorage.getItem("Subrole") !== "1" && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                )}
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {sportid !== 0 && <h4> Sport Category Details</h4>}
                  {sportid === 0 && <h4> Add Sport Category</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Sport Category Name{" "}
                          <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Sport Category Name"
                          className="form-control"
                          value={sportname}
                          onChange={(e) => setSport(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Sport Category Icon{" "}
                        </label>
                        {/* <input
                          type="text"
                          placeholder="Sport Category Icon"
                          className="form-control"
                          value={sporticon}
                          onChange={(e) => setSportIcon(e.target.value)}
                        /> */}
                        <input
                          type="file"
                          className="form-control"
                          accept=".png, .jpg, .jpeg"
                          onChange={iconfile}
                        />
                        {sporticon !== "" && (
                          <img src={sporticon} height={100} width={100}/>
                        )

                        }
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {sportid !== 0 && (
                    <a className="btn btn-success" onClick={submitsports}>
                      Update
                    </a>
                  )}
                  {sportid === 0 && (
                    <a className="btn btn-success" onClick={submitsports}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
