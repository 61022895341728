import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

//Achievements Count
export async function playerachievecount() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/total-achievements-count`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player school team
export async function playerschoolteam(id) {
  try {
    return await axios
      .get(`${baseUrl}/user-selected-view/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//List tournament
export async function playertourbanner(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-banners-by-pagename`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//List tournament
export async function playertourlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/list-all-meets`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Result List tournament
export async function playerresultlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/result-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Register tournament
export async function playerregisteredtour(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/validate-paid-or-not/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player exists or not
export async function Existsornotplayer(name, id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/meet-athlete-exist-validate/${name}/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player details for tournament registeration
export async function Playerviewdetails(name) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete-details-username?username=${name}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player details for tournament registeration
export async function Playerprofiledetails(name) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete-profile-username?username=${name}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player details update
export async function Playerprofileupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "multipart/form-data",
    };
    return await axios
      .post(`${baseUrl}/athlete/edit-details`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player team list for tournament
export async function Playertourteamlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/my-team-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team list players for tournament
export async function teamplayerlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/my-team-players/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Certificate list players for tournament
export async function playertourcertificate(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/certificates/meet-certificate-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

// Ceritificate List
export async function playercertificatelist(id, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/athlete/certificates/user-certificate-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

// Ceritificate Tracking
export async function playercertificatetracking(id, eid, type) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/athlete/certificates/certificate-download?tournament_id=${id}&certificate_uid=${eid}&viewed_or_downloaded=${type}`,
        { headers: header }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

// player category
export async function playergetcategory(id, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/find-athlete-category/${id}/${eid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Assessment list
export async function assessmentlistplayer(id) {
  try {
    return await axios
      .get(`${baseUrl}/assessment-groups-for-player/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Assessment Details
export async function assessmentdetailplayer(id) {
  try {
    return await axios
      .get(`${baseUrl}/entriesdetailslist/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Assessment Details Latest
export async function assessmentlatestdetailplayer(id) {
  try {
    return await axios
      // .get(`${baseUrl}/entriesmoadetailslist/${id}`,)
      .get(`${baseUrl}/latest-assessment-for-players/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player Achievements
export async function playerachievements(id) {
  try {
    return await axios
      .get(`${baseUrl}/match-winner-list/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
//Photo List
export async function playerphotolist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/tournamentuploadimagelistforuser/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Other Achievements List
export async function playerotherachievementlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/athlete-achievements/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Other Achievements details
export async function playerotherachievementdetail(id) {
  try {
    return await axios
      .get(`${baseUrl}/view-achievements/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Athletics Result List
export async function playerathleticsresult(tid, id) {
  try {
    return await axios
      .get(`${baseUrl}/all-athletics-results-player/${tid}/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Key highlights for player
export async function playerhighlights(uid,sid) {
  try {
    return await axios
      .get(`${baseUrl}/achievements-player-summary/${uid}/${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Order of events for player
export async function playerorderofevents(data) {
  try {
    return await axios
      .post(`${baseUrl}/player-order-of-events-list`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//List Other events
export async function playerothereventslist(data) {
  try {
   
    return await axios
      .post(`${baseUrl}/registered-events-for-player`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//List Other events
export async function playercalendarlist(data) {
  try {
   
    return await axios
      .post(`${baseUrl}/player-tournaments-events-calender`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function playerathletetourregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-ind-register-in-dashboard`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function playeroverallperformancer(data) {
  try {
    return await axios
      .post(`${baseUrl}/view-overall-performance-of-players`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function playertournamentperformancer(data) {
  try {
    return await axios
      .post(`${baseUrl}/view-track-performance-by-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response);
  if (error.response.status === 401) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title:
        "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     window.location.reload();
    //   }
    });
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
