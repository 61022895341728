import React, { useEffect, useState } from "react";
import { coachviewprofile } from "../../../../../services/coach";
import {
  adminexternaltourlist,
  externaltourdelete,
  externaltourlist,
} from "../../../../../services/common";
import { AgGridReact } from "ag-grid-react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import exportUrl from "../../../../../exportUtl";

export const ListExternalTour = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const columnDefs = [
    {
      headerName: "Actions",
      width: 150,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params)}
              title="View Tournament"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>

            {params.data.sports_type !== "ATHLETICS" &&
              localStorage.getItem("Role") === "7" && (
                <a
                  onClick={() => teamdet(params.value, params)}
                  title="Create Team List"
                  className="btn-sm btn-warning me-2"
                >
                  <i className="fa fa-list fa-lg"></i>
                </a>
              )}
            <a
              title="Download Tournament Report"
              onClick={() => repdet(params.value, params)}
              className="btn-sm btn-import me-2"
            >
              <i className="fa fa-download fa-lg"></i>
            </a>

            {localStorage.getItem("Role") === "1" && (
              <a
                title="Delete External Tournament"
                onClick={() => deletetour(params.value, params)}
                className="btn-sm btn-danger me-2"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "Type",
      width: 170,
      field: "meet_type_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Categories",
      width: 150,
      field: "categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function viewdet(tid, tdata) {
    dotracking(
      "view icon in external tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.viewexttourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/externaltournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/externaltournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/externaltournamentdetails/" + tid);
    }
  }

  function teamdet(tid, tdata) {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "team icon in external tournament list for " +
          tdata.data.meet_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.teamlistheading
      );
      navigate("/superadmin/externalteamlist/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "team icon in external tournament list for " +
          tdata.data.meet_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.teamlistheading
      );
      navigate("/coach/externalteamlist/" + tid);
    }
  }

  function repdet(rid, rdata) {
    // if (localStorage.getItem("Role") === "4" || localStorage.getItem("Role") === "7") {
    dotracking(
      "download tournament report icon in external tournament list for " +
        rdata.data.meet_name +
        " (" +
        rid +
        ") tournament $$ " +
        trackobj.externaltourlist
    );
    window.open(exportUrl + "export-tournament-report/" + rid + "/2", "_blank");
    // }
  }

  function deletetour(tid, tdata) {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "delete icon in external tournament list for " +
          tdata.data.meet_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.listextheading
      );
      Swal.fire({
        title: "Are you sure want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#d71635",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          externaltourdelete(tid).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("school_id", res.data.school_id);
          externaltourlist(temp).then((response) => {
            if (response.status === true) {
              setFilterProduct(response.data.tournaments);
            }
          });
        }
      });
    } else {
      const temp = new FormData();
      temp.append("school_id", 0);
      adminexternaltourlist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.tournaments);
        }
      });
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">External Tournament Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            External Tournament Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={["asc", "desc"]}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
