import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  classlist,
  schoolview,
  sectionlist,
  sportslist,
} from "../../services/master";
import {
  athleticsregister,
  duplicateplayer,
  geteventlistbydob,
  playertourpayment,
  registeredplayer,
  regpayment,
  tourclasslist,
  tourdetails,
  userregister,
  validatemail,
  validatemobile,
  validateregister,
} from "../../services/register";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { logindata } from "../../services/login";
import ImageUrl from "../../ImageUrl";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";
import { Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { paymentsuccess } from "../../services/common";

export const Register = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { schoolid } = useParams();

  const [paypage, setPaypage] = useState(true);

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [sclist, setClassList] = useState([]);
  const [stuclass, setStuClass] = useState("");
  const [sslist, setSecList] = useState([]);
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [splist, setSportList] = useState([]);
  const [stuspo, setStuSport] = useState("");
  const [amt, setAmount] = useState("0");

  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");

  const [eventid, setEventId] = useState([]);
  const [elist, setEList] = useState([]);
  const [evelist, setEveList] = useState([]);

  const [catid, setCatId] = useState([]);
  const [catlist, setCatList] = useState([
    { value: "1", label: "Singles" },
    { value: "2", label: "Doubles" },
    { value: "3", label: "Mixed Doubles" },
  ]);

  const [maxtrackeve, setMaxTrackEve] = useState(0);
  const [maxfieldeve, setMaxFieldEve] = useState(0);
  const [maxtrackeve1, setMaxTrackEve1] = useState(0);
  const [maxfieldeve1, setMaxFieldEve1] = useState(0);

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const [valemail, setValEmail] = useState("");
  const [emailerr, setEmailErr] = useState("");

  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setValEmail("Please Enter a valid Email Address");
      }

      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setEmailErr(response.message);
        }
      });
    }
  }

  const [valmob, setValMob] = useState("");
  const [mobnoerr, setMobErr] = useState("");

  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    setMobErr("");
    var obj = {};
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        setValMob("Enter a Valid  Mobile Number");
      }
      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setMobErr(response.message);
        }
      });
    }
  }

  const [clsname, setClsName] = useState("");
  function selectclass(e) {
    setStuClass(e.target.value);
    if (e.target.value !== "") {
      const data = sclist.find((item) => item.id === parseInt(e.target.value));
      setClsName(data.class);
    }
  }

  function handlegender(e) {
    if (sportname.toUpperCase() === "ATHLETICS") {
      listevent(dob, e.target.value);
    }
  }

  function handleDOB(e) {
    if (sportname.toUpperCase() === "ATHLETICS") {
      listevent(e.target.value, gender);
    }
  }

  const [agename, setAgeName] = useState([]);
  const [mfree, setMFree] = useState("");

  function listevent(dbirth, dgen) {
    setEventId([]);
    setEList([]);
    setEveList([]);

    let arr = [];

    if (dgen !== "") {
      if (dbirth !== "") {
        const temp = new FormData();
        temp.append("dob", dbirth);
        temp.append("gender", dgen);
        temp.append("school_id", schoolid);
        temp.append("tournament_meet_id", id);
        geteventlistbydob(temp).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              setAgeName(response.data[0]);
              setMaxTrackEve(response.data[0].max_event_athlete);
              setMaxFieldEve(response.data[0].max_event_athlete_field);
              setMaxTrackEve1(response.data[0].max_event_athlete_or);
              setMaxFieldEve1(response.data[0].max_event_athlete_field_or);
              // setMaxTrackEve1(0);
              // setMaxFieldEve1(0);
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  value: response.data[i].eventstable_id,
                  label: response.data[i].event_name,
                });
              }
            }
            setEList(arr);
            setEveList(response.data);
          }
        });
      } else {
        toast.error("Please Select Date of Birth");
      }
    } else {
      toast.error("Please Select Gender");
    }
  }

  const [check, setCheck] = useState(true);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [chnoti, setChkNoti] = useState(true);
  function checknoti(e) {
    setChkNoti(e.target.checked);
  }

  const [errorMsg, setErrMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [show, setShow] = useState(false);
  const [dupdata, setDupData] = useState([]);

  function regsubmit(e) {
    // localStorage.removeItem("RUser");
    e.preventDefault();
    setSubmitted(true);
    setIsProcessing(true);
    setValMob("");
    setValEmail("");
    setErrMsg("");
    var valid = "";
    var obj = {};

    if (mobno.length !== 10) {
      valid = "Not";
      setValMob("Mobile Number must be 10 characters");
    }
    if (mobno.length === 10) {
      for (let x = 0, length = mobno.length; x < length; x++) {
        var l = mobno.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] === 10) {
        valid = "Not";
        setValMob("Enter a Valid  Mobile Number");
      }
    }
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      valid = "Not";
      setValEmail("Please Enter a valid Email Address");
    }

    if (
      valid === "" &&
      fname !== "" &&
      stuclass !== "" &&
      stusec !== "" &&
      gender !== "" &&
      dob !== "" &&
      mobno !== "" &&
      email !== "" &&
      mobnoerr === "" &&
      emailerr === ""
    ) {
      if (check === false) {
        valid = "required";
        setErrMsg("Please Select Disclaimer");
      }
      if (valid === "") {
        if (multi === 0) {
          const temp2 = new FormData();
          temp2.append("mobile_no", mobno);
          temp2.append("email_id", email);
          temp2.append("school_id", schoolid);
          temp2.append("first_name", fname);
          temp2.append("gender", gender);
          temp2.append("date_of_birth", dob);

          duplicateplayer(temp2).then((dres) => {
            if (dres.status === true) {
              regisplayer();
            } else {
              if (dres.data.msg === 1) {
                setIsProcessing(false);
                Swal.fire({
                  html:
                    "<h4> Mobile - " +
                    mobno +
                    " and Email - " +
                    email +
                    " already exists for the user " +
                    dres.data.user[0].username +
                    "</h4>" +
                    "<b> Name - " +
                    dres.data.user[0].first_name +
                    ", Gender - " +
                    dres.data.user[0].gender +
                    ", Class - " +
                    dres.data.user[0].class_name +
                    ", Section - " +
                    dres.data.user[0].section +
                    ", DOB - " +
                    moment(dres.data.user[0].date_of_birth).format(
                      "DD-MM-YYYY"
                    ) +
                    ", School - " +
                    dres.data.user[0].school_name +
                    "</b>",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#4ecc48",
                  cancelButtonColor: "#757575",
                  confirmButtonText: "Continue to payment",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    regisplayer();
                  }
                });
              } else if (dres.data.msg === 2) {
                setIsProcessing(false);
                setDupData(dres.data.user);
                setShow(true);
              }
            }
          });
        } else {
          regisplayer();
        }
      }
    } else {
      setIsProcessing(false);
    }
  }

  const [multi, setMulti] = useState(0);
  function usethisuid(fid, gid, cid, sid, did) {
    setFName(fid);
    setGender(gid);
    setStuClass(cid);
    setStuSec(sid);
    setDOB(did);
    setMobNo(mobno);
    setEmail(email);
    setMulti(1);
    setShow(false);
  }

  function regisplayer() {
    setShow(false);
    const temp1 = new FormData();
    temp1.append("first_name", fname);
    temp1.append("school_id", schoolid);
    temp1.append("dob", dob);
    temp1.append("gender", gender);
    temp1.append("tournament_meet_id", id);

    registeredplayer(temp1).then((res) => {
      if (res.status === true) {
        if (sportname.toUpperCase() === "ATHLETICS") {
          if (eventid.length !== 0) {
            var valid = "";
            if (valid === "") {
              setLoading(true);
              var eve = "";
              var ename = "";
              var aid = "";
              var aname = "";
              if (eventid.length > 0) {
                aid = agename.age_category_id;
                aname = agename.age_category_name;
                for (let j = 0; j < eventid.length; j++) {
                  if (eve === "") {
                    eve = eventid[j].value;
                    ename = eventid[j].label;
                  } else {
                    eve = eve + "," + eventid[j].value;
                    ename = ename + "," + eventid[j].label;
                  }
                }
              }
              const temp = new FormData();
              temp.append("first_name", fname);
              temp.append("middle_name", "");
              temp.append("last_name", "");
              temp.append("date_of_birth", dob);
              temp.append("gender", gender);
              temp.append("mobile_no", mobno);
              temp.append("email_id", email);
              temp.append("photograph", "");
              temp.append("section", stusec);
              temp.append("class", stuclass);
              temp.append("school_id", schoolid);
              temp.append("tournament_id", id);
              if (chnoti === false) {
                temp.append("notification_enable", 0);
              } else if (chnoti === true) {
                temp.append("notification_enable", 1);
              }
              temp.append("category_id", aid);
              temp.append("tournament_category", aname);
              temp.append("eventid", eve);
              temp.append("eventslist", ename);

              dotracking(
                "submit button from tournament player register for tournament " +
                  id +
                  " from player " +
                  fname +
                  " with DOB " +
                  dob +
                  " ,gender " +
                  gender +
                  " ,mobile " +
                  mobno +
                  " ,email " +
                  email +
                  " ,class " +
                  stuclass +
                  " ,section " +
                  stusec +
                  " school " +
                  schoolid +
                  " in age category " +
                  aname +
                  " (" +
                  aid +
                  ") and event " +
                  ename +
                  " (" +
                  eve +
                  ") " +
                  " $$ " +
                  trackobj.tourregheading
              );

              athleticsregister(temp).then((response) => {
                if (response.status === true) {
                  setUser(response.data.entries.crm_or_school_username);
                  setPassword(response.data.password);
                  setLoading(false);
                  setIsProcessing(false);
                  if (mfree === "N") {
                    paynow(
                      response.data.RAZORPAY_KEY,
                      response.data.order_id,
                      response.data.entries.crm_or_school_id,
                      response.data.entries.total_amount,
                      response.data.entries.id
                    );
                  } else if (mfree === "Y") {
                    setPaypage(false);
                  }
                } else {
                  setLoading(false);
                  setIsProcessing(false);
                  var arr = "";
                  if (response.message.length !== 0) {
                    for (let i = 0; i < response.message.length; i++) {
                      if (arr === "") {
                        arr = response.message[i];
                      } else {
                        arr = arr + " , " + response.message[i];
                      }
                    }
                  }
                  setErrMsg(arr);
                }
              });
            }
          } else {
            setIsProcessing(false);
            setErrMsg("Please Select Events");
          }
        } else {
          var valid = "";
          if (sportname.toUpperCase() === "BADMINTON" ||
          sportname.toUpperCase() === "TENNIS" ||
          sportname.toUpperCase() === "TABLE TENNIS" ||
          sportname.toUpperCase() === "SQUASH") {
            if (catid.length !== 0) {
              valid = "";
            } else {
              setIsProcessing(false);
              valid = "Please Select Category";
              setErrMsg("Please Select Category");
            }
          }

          if (valid === "") {
            setLoading(true);
            const temp = new FormData();
            temp.append("first_name", fname);
            temp.append("middle_name", "");
            temp.append("last_name", "");
            temp.append("date_of_birth", dob);
            temp.append("gender", gender);
            temp.append("mobile_no", mobno);
            temp.append("email_id", email);
            temp.append("photograph", "");
            temp.append("section", stusec);
            temp.append("class", stuclass);
            temp.append("school_id", schoolid);
            temp.append("tournament_id", id);
            if (chnoti === false) {
              temp.append("notification_enable", 0);
            } else if (chnoti === true) {
              temp.append("notification_enable", 1);
            }
            if (sportname.toUpperCase() === "BADMINTON" ||
            sportname.toUpperCase() === "TENNIS" ||
            sportname.toUpperCase() === "TABLE TENNIS" ||
            sportname.toUpperCase() === "SQUASH") {
              let sdata = "";
              let ddata = "";
              let sddata = "";

              if (catid.length !== 0) {
                sdata = catid.find((item) => item.value === "1");
                ddata = catid.find((item) => item.value === "2");
                sddata = catid.find((item) => item.value === "3");
              }
              if (sdata !== undefined) {
                temp.append("singles", 1);
              } else {
                temp.append("singles", 0);
              }
              if (ddata !== undefined) {
                temp.append("doubles", 1);
              } else {
                temp.append("doubles", 0);
              }
              if (sddata !== undefined) {
                temp.append("mixed_doubles", 1);
              } else {
                temp.append("mixed_doubles", 0);
              }
            } else {
              temp.append("singles", 0);
              temp.append("doubles", 0);
              temp.append("mixed_doubles", 0);
            }
            dotracking(
              "submit button from tournament player register for tournament " +
                id +
                " from player " +
                fname +
                " with DOB " +
                dob +
                " ,gender " +
                gender +
                " ,mobile " +
                mobno +
                " ,email " +
                email +
                " ,class " +
                stuclass +
                " ,section " +
                stusec +
                " in school " +
                schoolid +
                " $$ " +
                trackobj.tourregheading
            );

            userregister(temp).then((response) => {
              if (response.status === true) {
                setUser(response.data.entries.username);
                setPassword(response.data.password);
                setLoading(false);
                setIsProcessing(false);
                if (mfree === "N") {
                  paynow(
                    response.data.RAZORPAY_KEY,
                    response.data.order_id,
                    response.data.entries.user_id,
                    0,
                    0
                  );
                } else if (mfree === "Y") {
                  setPaypage(false);
                }
              } else {
                setLoading(false);
                setIsProcessing(false);
                var arr = "";
                if (response.message.length !== 0) {
                  for (let i = 0; i < response.message.length; i++) {
                    if (arr === "") {
                      arr = response.message[i];
                    } else {
                      arr = arr + " , " + response.message[i];
                    }
                  }
                }
                setErrMsg(arr);
              }
            });
          }
        }
      } else {
        setLoading(false);
        setIsProcessing(false);
        setErrMsg(res.message);
      }
    });
  }

  async function paynow(pkey, ordid, uid, tamt, pid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Tournament Registration",
      image: require("../../assets/images/favicon.png"),
      handler: function (response) {
        if (sportname.toUpperCase() === "ATHLETICS") {
          const temp = new FormData();
          temp.append("school_id", schoolid);
          temp.append("admin_id", uid);
          temp.append("razorpay_payment_id", response.razorpay_payment_id);
          temp.append("totalAmount", tamt);
          temp.append("current_meet_id", id);
          temp.append("order_id", ordid);
          temp.append("current_ins_id", pid);
          setLoading(true);
          paymentsuccess(temp).then((response) => {
            if (response.status === true) {
              setPaypage(false);
              setLoading(false);
            }
          });
        } else {
          const temp = new FormData();
          temp.append("payment_id", response.razorpay_payment_id);
          temp.append("order_id", ordid);
          temp.append("user_id", uid);
          temp.append("tournament_meet_id", id);
          setLoading(true);
          playertourpayment(temp).then((response) => {
            if (response.status === true) {
              setPaypage(false);
              setLoading(false);
              // navigate("/success");
            }
          });
        }
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  // const handlePaymentClick = () => {
  //   setTimeout(() => {
  //     setIsProcessing(false);
  //   }, 1000);
  // };

  // const combinedOnClick = () => {
  //   // First, call the existingOnClickFunction
  //   regsubmit();
  //   // Then, call handlePaymentClick
  //   handlePaymentClick();
  // };
  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button in register,redirect to Player dashboard $$ " +
              trackobj.dashheading
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        setErrMsg(response.message);
      }
    });
  }

  const [tname, setTName] = useState("");
  const [agetype, setAgeType] = useState("");
  const [sportname, setSports] = useState("");
  const [tourtype, setTourType] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  const [ddate, setDDate] = useState(false);

  const [school, setSchool] = useState("");
  const [disamt, setDisAmt] = useState("");

  const [cir, setCir] = useState("");
  const [curdate, setCurDate] = useState("");
  useEffect(() => {
    var a = new Date().getFullYear() - 7;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));

    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        setTName(response.data.tournament.meet_name);
        setMFree(response.data.tournament.free_tournament);
        setSports(response.data.tournament.sports_type);
        if (
          response.data.tournament.sports_type.toUpperCase() === "ATHLETICS"
        ) {
          if (
            response.data.tournament.meet_type === "Inter School Tournament"
          ) {
            setTourType(
              "Player Registration is not available for the tournament " +
                response.data.tournament.meet_name
            );
          }
        }
        setAgeType(response.data.tournament.agecat_or_std);
        if (response.data.tournament.dob_start_date !== null) {
          setSDOB(response.data.tournament.dob_start_date);
        }
        if (response.data.tournament.dob_end_date !== null) {
          setEDOB(response.data.tournament.dob_end_date);
        }
        if (response.data.tournament.entry_deadline_date !== null) {
          if (
            moment(new Date()).format("yyyy-MM-DD") >
            response.data.tournament.entry_deadline_date
          ) {
            setDDate(true);
          }
        }
        if (response.data.tournament.circular_file_1 !== null) {
          setCir(
            // response.data.circular_path +
            ImageUrl +
              "tournament/circular/" +
              response.data.tournament.circular_file_1
          );
        }
        setAmount(response.data.tournament.registration_fees);

        if (response.data.tournament.registration_fees === 0) {
          setAmount("0");
        }
        if (response.data.mappedschools !== null) {
          for (let i = 0; i < response.data.mappedschools.length; i++) {
            if (
              response.data.mappedschools[i].school_id === parseInt(schoolid)
            ) {
              setDisAmt(response.data.mappedschools[i].discount);
              setSchool(
                response.data.mappedschools[i].school_unique_no +
                  " - " +
                  response.data.mappedschools[i].school_name
              );
            }
          }
        }

        if (response.data.tournament.agecat_or_std === 1) {
          tourclasslist(id).then((response) => {
            if (response.status === true) {
              setClassList(response.data);
            }
          });
        } else {
          classlist(id).then((response) => {
            if (response.status === true) {
              setClassList(response.data);
            }
          });
        }
      }
    });

    sportslist().then((response) => {
      if (response.status === true) {
        setSportList(response.data);
      }
    });
  }, []);

  function handleEvent(e) {
    if (e === null) {
      setEventId([]);
    } else {
      let teve = 0;
      let feve = 0;
      var msg = "";

      let maxtrack = 0;
      let mintrack = 0;
      let maxfield = 0;
      let minfield = 0;

      let maxeventath = 0;
      let maxfieldath = 0;

      if (maxtrackeve > maxtrackeve1) {
        maxtrack = maxtrackeve;
        mintrack = maxtrackeve1;
      } else {
        maxtrack = maxtrackeve1;
        mintrack = maxtrackeve;
      }

      if (maxfieldeve > maxfieldeve1) {
        maxfield = maxfieldeve;
        minfield = maxfieldeve1;
      } else {
        maxfield = maxfieldeve1;
        minfield = maxfieldeve;
      }

      if (e.length !== 0) {
        for (let i = 0; i < e.length; i++) {
          const tdata = evelist.find(
            (item) => item.eventstable_id === e[i].value
          );
          if (tdata.event_type === 1) {
            teve = teve + 1;
          }
          if (tdata.event_type === 3) {
            feve = feve + 1;
          }
        }
        if (teve !== 0) {
          if (maxtrackeve > maxtrackeve1) {
            if (feve >= maxfield && minfield !== 0) {
              maxeventath = maxtrackeve1;
            } else {
              maxeventath = maxtrackeve;
            }
          } else {
            if (feve >= maxfield && minfield !== 0) {
              maxeventath = maxtrackeve;
            } else {
              maxeventath = maxtrackeve1;
            }
          }
        }

        if (feve !== 0) {
          if (maxfieldeve > maxfieldeve1) {
            if (teve >= maxtrack && mintrack !== 0) {
              maxfieldath = maxfieldeve1;
            } else {
              maxfieldath = maxfieldeve;
            }
          } else {
            if (teve >= maxtrack && mintrack !== 0) {
              maxfieldath = maxfieldeve;
            } else {
              maxfieldath = maxfieldeve1;
            }
          }
        }

        if (teve > maxeventath || feve > maxfieldath) {
          if (
            maxtrack !== 0 &&
            mintrack !== 0 &&
            maxfield !== 0 &&
            minfield !== 0
          ) {
            msg = "Maximum " + maxtrack + " track events can be selected";
            Swal.fire({
              text:
                "Maximum " +
                maxtrack +
                " track events & " +
                mintrack +
                " field events allowed or Maximum " +
                maxfield +
                " field events & " +
                minfield +
                " track events allowed per athlete",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#4ecc48",
              cancelButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          } else {
            if (maxtrackeve >= teve) {
            } else {
              msg =
                "Maximum " + maxtrackeve + " track events allowed per athlete";
              toast.error(
                "Maximum " + maxtrackeve + " track events allowed per athlete"
              );
            }

            if (maxfieldeve >= feve) {
            } else {
              msg =
                "Maximum " + maxfieldeve + " field events allowed per athlete";
              toast.error(
                "Maximum " + maxfieldeve + " field events allowed per athlete"
              );
            }
          }
        }

        // if (maxtrackeve >= teve) {
        // } else {
        //   msg = "Maximum " + maxtrackeve + " track events can be selected";
        //   toast.error(
        //     "Maximum " + maxtrackeve + " track events can be selected"
        //   );
        // }

        // if (maxfieldeve >= feve) {
        // } else {
        //   msg = "Maximum " + maxfieldeve + " field events can be selected";
        //   toast.error(
        //     "Maximum " + maxfieldeve + " field events can be selected"
        //   );
        // }

        if (msg === "") {
          setEventId(e);
        }
      }
    }
  }

  function handleCategory(e) {
    if (e === null) {
      setCatId([]);
    } else {
      setCatId(e);
    }
  }
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl registrationpge">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="md-overflow">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-4 pe-0 tab-100 order_a mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <a
                      href="http://pathwayz.sportzvillage.com/"
                      target="_blank"
                    >
                      <img
                        src={require("../../assets/images/pathwayz_logo.png")}
                        alt="pathwayz"
                        className="pathwayz_logo"
                      />
                    </a>
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              {ddate && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      Registration Closed
                    </h3>
                  </div>
                </div>
              )}

              {tourtype !== "" && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">{tourtype}</h3>
                  </div>
                </div>
              )}

              {paypage && !ddate && tourtype === "" && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  {cir !== "" && (
                    <div className="circulardiv">
                      <h3>
                        Circular <br />
                        <a
                          href={cir}
                          target="_blank"
                          download={cir}
                          className="text-success"
                        >
                          Click Here <i className="fa fa-download"></i>
                        </a>
                      </h3>
                    </div>
                  )}

                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="form-text login">
                      <h4 className="mt-0">Create Account</h4>
                    </div>
                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="schoolname px-2">
                          <h3>
                            School Name :{" "}
                            <b className="font-weight-bold text-muted">
                              {school}
                            </b>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <form className="login">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-user"></i>Full Name{" "}
                              <b>
                                <span className="text-danger">*</span>
                              </b>
                            </label>
                            <input
                              type="text"
                              placeholder="Full Name"
                              value={fname}
                              onChange={(e) => setFName(e.target.value)}
                              autoFocus
                              onKeyPress={LetterOnly}
                              onPaste={onPasteLetter}
                            />
                            {submitted && !fname && (
                              <span className="text-danger">
                                Full Name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-venus-mars"></i>Gender{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                              onBlur={handlegender}
                            >
                              <option value="" defaultValue disabled>
                                --- Select Gender ---
                              </option>
                              {/* {id === "169" && (
                                <option value="Male">Male</option>
                              )}
                              {(id === "170" || id === "171") && (
                                <option value="Female">Female</option>
                              )}
                              {id !== "169" && id !== "170" && id !== "171" && (
                                <> */}
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                {/* </>
                              )} */}
                            </select>
                            {submitted && !gender && (
                              <span className="text-danger">
                                Gender is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-book-open-reader"></i>
                              Class <span className="text-danger">*</span>
                            </label>
                            <select value={stuclass} onChange={selectclass}>
                              <option value="" defaultValue disabled>
                                --- Select Class ---
                              </option>
                              {sclist.map((data, i) => {
                                return (
                                  <option value={data.id} key={i}>
                                    {data.class}
                                  </option>
                                );
                              })}
                            </select>
                            {submitted && !stuclass && (
                              <span className="text-danger">
                                Class is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-group-arrows-rotate"></i>
                              Section <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Section"
                              value={stusec}
                              onChange={(e) =>
                                setStuSec(e.target.value.toUpperCase())
                              }
                            />

                            {submitted && !stusec && (
                              <span className="text-danger">
                                Section is required
                              </span>
                            )}
                          </div>
                        </div>
                        {agetype === 1 && (
                          <div className="col-md-6">
                            <div className="input-field">
                              <label>
                                <i className="fa-solid fa-calendar"></i>Date of
                                Birth <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={dob}
                                max={curdate}
                                onChange={(e) => setDOB(e.target.value)}
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              {submitted && !dob && (
                                <span className="text-danger">
                                  Date of Birth is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        {agetype === 2 && (
                          <div className="col-md-6">
                            <div className="input-field">
                              <label>
                                <i className="fa-solid fa-calendar"></i>Date of
                                Birth <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={dob}
                                min={sdob}
                                max={edob}
                                onChange={(e) => setDOB(e.target.value)}
                                onBlur={handleDOB}
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              {submitted && !dob && (
                                <span className="text-danger">
                                  Date of Birth is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-mobile-button"></i>
                              Emergency Contact{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Contact Number"
                              value={mobno}
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={checkmob}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !mobno && (
                              <span className="text-danger">
                                Emergency Contact is required
                              </span>
                            )}
                            {valmob !== "" && mobno && (
                              <span className="text-danger">{valmob}</span>
                            )}
                            {mobnoerr !== "" && mobno && (
                              <span className="text-danger">{mobnoerr}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>
                              <i className="fa-solid fa-at"></i>E-mail Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onBlur={checkemail}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {submitted && !email && (
                              <span className="text-danger">
                                E-mail Address is required
                              </span>
                            )}
                            {valemail !== "" && email && (
                              <span className="text-danger">{valemail}</span>
                            )}
                            {emailerr !== "" && email && (
                              <span className="text-danger">{emailerr}</span>
                            )}
                          </div>
                        </div>
                        {sportname.toUpperCase() === "ATHLETICS" && (
                          <div className="col-md-6">
                            <div className="input-field">
                              <label className="form-label">
                                <i className="fa-solid fa-running"></i> Events{" "}
                                <span className="errortext">*</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Event"
                                value={eventid}
                                options={elist}
                                onChange={handleEvent}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />

                              {submitted && eventid.length === 0 && (
                                <span className="text-danger">
                                  Event is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {(sportname.toUpperCase() === "BADMINTON" ||
      sportname.toUpperCase() === "TENNIS" ||
      sportname.toUpperCase() === "TABLE TENNIS" ||
      sportname.toUpperCase() === "SQUASH") && (
                          <div className="col-md-6">
                            <div className="input-field">
                              <label className="form-label">
                                <i className="fa-solid fa-running"></i> Category{" "}
                                <span className="errortext">*</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Category"
                                value={catid}
                                options={catlist}
                                onChange={handleCategory}
                                isSearchable={true}
                                isMulti
                                isClearable
                                hidden
                              />

                              {submitted && catid.length === 0 && (
                                <span className="text-danger">
                                  Category is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {sportname.toUpperCase() === "ATHLETICS" &&
                        mfree !== "" &&
                        mfree === "N" && (
                          <h4 className="text-danger">
                            Age Category : {agename.age_category_name} ({" "}
                            {agename.fees}/- Per Event ) (Discount - {disamt} %
                            )
                          </h4>
                        )}
                      {sportname.toUpperCase() === "ATHLETICS" &&
                        mfree !== "" &&
                        mfree !== "N" && (
                          <h4 className="text-danger">
                            Age Category : {agename.age_category_name} (Free)
                          </h4>
                        )}

                      {(sportname.toUpperCase() === "BADMINTON" ||
      sportname.toUpperCase() === "TENNIS" ||
      sportname.toUpperCase() === "TABLE TENNIS" ||
      sportname.toUpperCase() === "SQUASH") &&
                        mfree !== "" &&
                        mfree === "N" && (
                          <h4 className="text-danger">
                            Amount : {amt}/- (Discount - {disamt} % )
                          </h4>
                        )}
                      {(sportname.toUpperCase() === "BADMINTON" ||
      sportname.toUpperCase() === "TENNIS" ||
      sportname.toUpperCase() === "TABLE TENNIS" ||
      sportname.toUpperCase() === "SQUASH")&&
                        mfree !== "" &&
                        mfree !== "N" && (
                          <h4 className="text-danger">Amount : (Free)</h4>
                        )}
                      <div className="disc-field mb-2">
                        <input
                          type="checkbox"
                          onChange={checknoti}
                          checked={chnoti}
                        />
                        <label className="pl-3">
                          Send me notifications about upcoming tournaments and
                          events
                        </label>
                      </div>
                      <div className="disc-field">
                        <input
                          type="checkbox"
                          onChange={checkterm}
                          checked={check}
                        />
                        <label className="pl-3">
                          By registering my child, I acknowledge and accept that
                          participation involves inherent risks, and I release
                          the school and organizers from liability for any
                          injuries during the event and grant permission to use
                          event photographs for promotional purposes.
                        </label>
                      </div>
                      {errorMsg !== "" && (
                        <span className="text-danger">{errorMsg}</span>
                      )}
                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          {mfree === "N" && (
                            <button
                              className="form-btn regis bg-success mt-2"
                              onClick={regsubmit}
                              disabled={isProcessing}
                              type="submit"
                            >
                              <span>Submit & Pay</span>
                            </button>
                          )}
                          {mfree === "Y" && (
                            <button
                              className="form-btn regis bg-success mt-2"
                              onClick={regsubmit}
                              disabled={isProcessing}
                              type="submit"
                            >
                              <span>Submit</span>
                            </button>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-8">
                          <div className="con-text regis text-right mt-3">
                            <p className="mb-0">
                              Already have an Account?{" "}
                              <strong>
                                <a onClick={() => navigate("/login")}>
                                  Back to Login
                                </a>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4>
                    Mobile - {mobno} and Email - {email} already exists for the
                    below players...
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  {dupdata.map((data, i) => {
                    return (
                      <Row className="row-sm mx-0 border-bottom mt-2" key={i}>
                        <Col lg={10} md={10}>
                          <div className="form-group">
                            <span>
                              <h4 className="text-success">
                                {" "}
                                Username - {data.username}, Name -{" "}
                                {data.first_name},{" "}
                              </h4>
                              <b>
                                Gender -{data.gender}, Class -{data.class_name},
                                Section -{data.section}, DOB -
                                {moment(data.date_of_birth).format(
                                  "DD-MM-YYYY"
                                )}
                                , School -{data.school_name}
                              </b>
                            </span>
                          </div>
                        </Col>
                        <Col lg={2} md={2}>
                          <div className="form-group">
                            <a
                              className="btn btn-secondary"
                              onClick={() =>
                                usethisuid(
                                  data.first_name,
                                  data.gender,
                                  data.class,
                                  data.section,
                                  data.date_of_birth
                                )
                              }
                            >
                              Use this
                            </a>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  <div>
                    <h4 className="errortext text-center">(OR)</h4>
                    <Row className="row-sm mx-0 border-bottom mt-2">
                      <Col lg={8} md={8}>
                        <div className="form-group">
                          <span>
                            <h4 className="text-success">Name - {fname}, </h4>
                            <b>
                              Gender -{gender}, Class -{clsname}, Section -
                              {stusec}, DOB -{moment(dob).format("DD-MM-YYYY")}
                            </b>
                          </span>
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="form-group">
                          <a className="btn btn-success" onClick={regisplayer}>
                            Continue to Payment
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={() => setShow(false)}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              {!paypage && !ddate && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="text-center background-text">
                      <h1 className="mb-3 font-weight-semibold text-danger">
                        Registration successful !
                      </h1>
                    </div>
                    <div className="background-text">
                      <h4 className="mb-3 font-weight-semibold">
                        Hi {fname} {lname},
                      </h4>
                      <div className="successmeg">
                        <p>
                          You have successfully registered for the{" "}
                          <b className="text-success">{tname}</b>. Kindly check
                          your email.
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <a onClick={dashlogin} className="btn btn-gray">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
