import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { fundermedalslist } from "../../../services/superadmin";
import { funderschoollist, fundertourlist } from "../../../services/common";
import { funderbeneficiarylist, fundercalendarlist, funderprofile } from "../../../services/funder";
import { funderdetail } from "../../../services/master";
import FunderWebUrl from "../../../AWebFunder";

export const FunderDashboard = () => {
  const [mcount, setMCount] = useState("0");
  const [tcount, setTCount] = useState("0");
  const [scount, setSCount] = useState("0");
  const [schcount, setSchCount] = useState("0");

  const [weblink, setWebLink] = useState("");

  const [mlist, setMList] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    funderprofile().then((res3) => {
      if (res3.status === true) {
        funderdetail(res3.data.funder_id).then((res) => {
          if (res.status === true) {
            if (res.data.funders_details.website_publised_status == 1) {
              setWebLink(FunderWebUrl + res.data.funders_details.website_url);
            }
          }
        });
        const temp = new FormData();
        temp.append("year", 0);
        temp.append("sports", 0);
        temp.append("funder_id", res3.data.funder_id);

        fundermedalslist(temp).then((res) => {
          if (res.status === true) {
            setMList(res.data);
            let mcnt = 0;
            let tcnt = 0;
            let scnt = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (typeof res.data[i].meet_count === "string") {
                tcnt += parseInt(res.data[i].meet_count);
              } else {
                tcnt += res.data[i].meet_count;
              }

              if (typeof res.data[i].participants === "string") {
                scnt += parseInt(res.data[i].participants);
              } else {
                scnt += res.data[i].participants;
              }

              if (typeof res.data[i].total_achievements === "string") {
                mcnt += parseInt(res.data[i].total_achievements);
              } else {
                mcnt += res.data[i].total_achievements;
              }
            }
            setMCount(mcnt);
            // setSCount(scnt);
          }
        });

        fundertourlist(res3.data.funder_id).then((response) => {
          if (response.status === true) {
            setTCount(response.data.all_tournament_list.length);
          }
        });

        funderschoollist(res3.data.funder_id).then((response) => {
          if (response.status === true) {
            setSchCount(response.data.school_list.length);
          }
        });

        const ftemp = new FormData();
        ftemp.append("funder_id", 0);
        fundercalendarlist(ftemp).then((response) => {
          if (response.status === true) {
            let schedule = [];
            if (response.data.tournament_and_events.length !== 0) {
              for (
                let i = 0;
                i < response.data.tournament_and_events.length;
                i++
              ) {
                var clr = "";
                var clink = "";
                const url = window.location.href;
                const head = url.split("/#/");
                if (
                  response.data.tournament_and_events[i].type === "Tournament"
                ) {
                  clr = "#005baa";
                  clink =
                    `${head[0]}/#/funder/tournamentdetails/` +
                    response.data.tournament_and_events[i].id;
                } else if (
                  response.data.tournament_and_events[i].type ===
                    "External_1" ||
                  response.data.tournament_and_events[i].type === "External"
                ) {
                  clr = "#c21a1a";
                  clink =
                    `${head[0]}/#/funder/externaltournamentdetails/` +
                    response.data.tournament_and_events[i].id;
                } else {
                  clr = "#e59216";
                  clink =
                    `${head[0]}/#/funder/othereventdetail/` +
                    response.data.tournament_and_events[i].id;
                }
                schedule.push({
                  title: response.data.tournament_and_events[i].meet_name,
                  start: response.data.tournament_and_events[i].startdate,
                  end: response.data.tournament_and_events[i].enddate,
                  description: response.data.tournament_and_events[i].type,
                  url: clink,
                  color: clr,
                });
              }
              setEvents(schedule);
            }
          }
        });
      }
    });
    funderbeneficiarylist().then((response) => {
      if (response.status === true) {
        setSCount(response.data.football_achievements.length);
      }
    })
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent("");
    setModalIsOpen(false);
  };

  const handleEventClick = (info) => {
    const clickedEvent = {
      title: info.event.title,
      start: info.event.startStr,
      end: info.event.endStr,
      description: info.event.description,
      sports: info.event.sports,
      event: info.event.event,
    };
    openModal(clickedEvent);
  };

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Dashboard</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </div>

        <div className="row row-cards">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <a
                className="card-body text-center feature"
                onClick={() => navigate("/funder/schoollist")}
              >
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-teal">
                  <i className="fa fa-bank text-white-1 text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">School</p>
                <p className="h3 text-center text-teal">{schcount}</p>
              </a>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <a
                className="card-body text-center feature"
                onClick={() => navigate("/funder/tournamentlist")}
              >
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-primary">
                  <i className="icon icon-location-pin text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Total Tournaments</p>
                <p className="h3 text-center text-primary-1">{tcount}</p>
              </a>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <a
                className="card-body text-center feature"
                onClick={() => navigate("/funder/achievementlist")}
              >
                <div className="fa-stack fa-lg fa-1x icons shadow-default bg-info">
                  <i className="icon-people text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Total Beneficiaries</p>
                <p className="h3 text-center text-info">{scount}</p>
              </a>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card bg-custom">
              <a
                className="card-body text-center feature"
                onClick={() => navigate("/funder/keyhighlights")}
              >
                <div className="fa-stack fa-lg icons shadow-default bg-purple">
                  <i className="fa fa-soccer-ball-o text-white"></i>
                </div>
                <p className="card-text mt-3 mb-3">Medals</p>
                <p className="h3 text-center text-purple">{mcount}</p>
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="text-center">
            <a
              className="btnfixture"
              href={weblink}
              target="_blank"
            >
              Visit Funder Webpage
            </a>
          </div>
        </div>
        <div className="row">
          <div className="ag_courses_head">
            <h4>Tournament & Events Calender</h4>
          </div>
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  eventClick={handleEventClick}
                />

                <Modal show={modalIsOpen} size="xl">
                  <Modal.Header>
                    <Modal.Title>Tournament & Events Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="table-responsive">
                      <table className="sp-league-table sp-data-table">
                        <thead className="sp-table-header">
                          <tr role="row">
                            <th className="text-center">Title</th>
                            <th className="text-center">Start Date</th>
                            <th className="text-center">End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="sp-row-no-0 even" role="row">
                            <td class="data-pct">{selectedEvent.title}</td>
                            <td class="data-pct">
                              {moment(selectedEvent.start).format("DD-MM-YYYY")}
                            </td>
                            {selectedEvent.end !== "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.end).format("DD-MM-YYYY")}
                              </td>
                            )}
                            {selectedEvent.end === "" && (
                              <td className="data-pct">
                                {moment(selectedEvent.start).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-gray" onClick={closeModal}>
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="ag_courses_head">
          <h4>Players Statistics</h4>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="table-responsive">
              <table className="sp-league-table sp-data-table">
                <thead className="sp-table-header">
                  <tr role="row">
                    <th className="text-center">Players</th>
                    <th className="text-center">Male</th>
                    <th className="text-center">Female</th>
                    <th className="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {mlist.map((item, j) => {
                    const getRowColor = () => {
                      return j % 2 === 0 ? "even" : "odd";
                    };
                    return (
                      <>
                        <tr
                          className={`sp-row-no-0 ${getRowColor()}`}
                          role="row"
                          key={j}
                        >
                          <td className="data-pct">{item.meet_type}</td>
                          <td className="data-pct">{item.male_count}</td>
                          <td className="data-pct">{item.female_count}</td>
                          <td className="data-pct">
                            {item.male_count + item.female_count}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-7">
            <div className="table-responsive">
              <table className="sp-league-table sp-data-table">
                <thead className="sp-table-header">
                  <tr role="row">
                    <th className="text-center">Players</th>
                    <th className="text-center">&lt;8 Years</th>
                    <th className="text-center">8-14 Years</th>
                    <th className="text-center">15-18 Years</th>
                    <th className="text-center">&gt;18 Years</th>
                    <th className="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {mlist.map((item, j) => {
                    const getRowColor = () => {
                      return j % 2 === 0 ? "even" : "odd";
                    };
                    return (
                      <>
                        <tr
                          className={`sp-row-no-0 ${getRowColor()}`}
                          role="row"
                          key={j}
                        >
                          <td className="data-pct">{item.meet_type}</td>
                          <td className="data-pct">
                            {item.female_age_8 + item.male_age_8}
                          </td>
                          <td className="data-pct">
                            {item.female_age_8_14 + item.male_age_8_14}
                          </td>
                          <td className="data-pct">
                            {item.female_age_15_18 + item.male_age_15_18}
                          </td>
                          <td className="data-pct">
                            {item.female_age_18_plus + item.male_age_18_plus}
                          </td>

                          <td className="data-pct">
                            {item.female_age_8 +
                              item.male_age_8 +
                              item.female_age_8_14 +
                              item.male_age_8_14 +
                              item.female_age_15_18 +
                              item.male_age_15_18 +
                              item.female_age_18_plus +
                              item.male_age_18_plus}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
