import { AgGridReact } from "ag-grid-react";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { schoolteamlist } from "../../../../../services/superadmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachviewprofile } from "../../../../../services/coach";

export const SchoolTeamListAdmin = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 200,
      field: "fullname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 100,
      field: "class",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 100,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team",
      width: 200,
      field: "school_team_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Category",
      width: 200,
      field: "agecategory",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team Member",
      width: 150,
      field: "school_teams_member_or_not",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((res) => {
        if (res.status === true) {
          const temp=new FormData();
          temp.append("school_id",res.data.school_id);
          schoolteamlist(temp).then((response) => {
            if (response.status === true) {
              setFilterProduct(response.data);
            }
          });
        }
      });
    } else {
      const temp=new FormData();
          temp.append("school_id",0);
      schoolteamlist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }, []);
  const navigate = useNavigate();
  function adddet() {
    dotracking(
      "upload school team button in school team list $$ " +
        trackobj.schteamuploadheading
    );
    if (localStorage.getItem("Role") === "7") {
      navigate("/coach/schoolteamupload");
    }else{
      navigate("/superadmin/schoolteamupload");
    }
    
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">School Team List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              School Team List
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="text-right">
                  <a className="btn btn-success" onClick={adddet}>
                    <i className="fa fa-plus"></i> Upload School Team
                  </a>
                </div>
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
