import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Existsornotplayer, Playerviewdetails } from "../services/player";
import { useState } from "react";
import {
  checkamount, checkamountsuccess,
} from "../services/register";
import { toast } from "react-toastify";

export const CheckPayment = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [rerrorMsg, setRErrorMsg] = useState("");

  // function regsubmit() {
  //   setErrorMsg("")
  //   if (user !== "") {
  //     Existsornotplayer(user, id).then((response) => {
  //       if (response.status === true) {
  //         setTShow(true);
  //         Playerviewdetails(user).then((res) => {
  //           if (res.status === true) {
  //             setFName(res.data.athletelist.first_name)
  //             setLName(res.data.athletelist.last_name)
  //             if (res.data.athletelist.date_of_birth !== null) {
  //               setDOB(moment(res.data.athletelist.date_of_birth).format('DD-MM-YYYY'))
  //             }
  //             setGender(res.data.athletelist.gender)
  //             setMobNo(res.data.athletelist.mobile_no)
  //             setEmail(res.data.athletelist.email_id)
  //             setSName(res.data.athletelist.school_name)
  //             setClsName(res.data.athletelist.class)
  //             setSecName(res.data.athletelist.section)

  //           }
  //         })
  //       } else {
  //         setErrorMsg(response.message)
  //       }
  //     })

  //   } else {
  //     setErrorMsg("Please Enter Username")
  //   }
  // }

  function playersubmit() {
    setRErrorMsg("");
    const temp = new FormData();
    temp.append("name", user);
    // temp.append("tournament_meet_id", id)
    // temp.append("school_id", schoolid)
    checkamount(temp).then((response) => {
      if (response.status === true) {
        paynow(
          response.data.RAZORPAY_KEY,
          response.data.order_id,
          response.data.id
        );
      } else {
        setRErrorMsg(response.message);
      }
    });
  }

  async function paynow(pkey, ordid, uid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Registration",
      image: require("../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("id", uid);
        checkamountsuccess(temp).then((response) => {
          if (response.status === true) {
            setUser("");
            toast.success("Payment Paid Successfully!!!");
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }
  return (
    <div id="loginpage" className="app sidebar-mini rtl">
      <main className="overflow-hidden">
        <div className="container">
          <div className="row h-95">
            <div className="col-md-4 pe-0 tab-100 order_c mob-100">
              <div className="side slideup ">
                <div className="img-top">
                  <img
                    src={require("../assets/images/tagline.png")}
                    alt="tagline"
                    className="tagline_logo"
                  />
                </div>
                <article className="text-center">
                  <img
                    src={require("../assets/images/pathwayz_logo.png")}
                    alt="pathwayz"
                    className="pathwayz_logo"
                  />
                </article>
                <div className="social">
                  <span>Follow Us</span>
                  <a>
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a>
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  <a>
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a>
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </div>
              </div>
              <div className="border-b slideup"></div>
            </div>
            <div className="col-md-8 ps-0 tab-100 mob-100">
              <div className="slidedown border-t"></div>

              <div className="form slidedown">
                <form className="login">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label>
                          <i className="fa-solid fa-user"></i>User Name{" "}
                          <b>
                            <span className="text-danger">*</span>
                          </b>
                        </label>
                        <input
                          type="text"
                          placeholder="User Name"
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          autoFocus
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    className="form-btn login mt-0 btn-success"
                    type="button"
                    onClick={playersubmit}
                  >
                    <span>Pay Now</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
