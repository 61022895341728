import React, { useEffect, useState } from "react";
import {
  adminupdateplayer,
  schoollist,
  viewprofile,
} from "../../../../services/superadmin";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { classlist } from "../../../../services/master";
import { cityplayerview } from "../../../../services/cityregionaladmin";
import { schoolviewplayer } from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const EditPlayer = () => {
  const { id } = useParams();
  const [fname, setFName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [clas, setClass] = useState("");
  const [section, setSection] = useState("");
  const [status, setStatus] = useState(0);
  const [schid, setSchool] = useState("");
  const [uname, setUser] = useState("");

  const [schlist, setSchList] = useState([]);
  const [sclist, setClassList] = useState([]);

  const [sport, setSport] = useState("");
  const [sportid, setSportId] = useState("");
  const [sportpos, setSportPos] = useState("");
  const [stufq, setStuFQ] = useState("");
  const [stunfq, setStuNFQ] = useState("");

  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  // const [slist, setSList] = useState([]);
  // const [dist, setDist] = useState("");
  // const [dlist, setDList] = useState([]);
  // const [city, setCity] = useState("");
  // const [clist, setCList] = useState([]);
  // const [photo, setPhoto] = useState("");
  // const [addr, setAddr] = useState("");
  // const [pin, setPin] = useState("");
  // const [lname, setLName] = useState("")
  // const [state, setState] = useState("");

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }
  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const [photoerror, setPhotoError] = useState("");
  const [photourl, setPhotoUrl] = useState("");
  const [photo, setPhoto] = useState("");

  function photofile(event) {
    setPhotoError("");
    setPhoto("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUrl(URL.createObjectURL(event.target.files[0]));
        setPhoto(event.target.files[0]);
      } else {
        setPhotoError("Photograph should be jpg, jpeg, or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoError("Photograph size should be less than 5MB");
      }
    }
  }

  useEffect(() => {
   
    schoollist().then((response) => {
      if (response.status === true) {
        setSchList(response.data.details);
      }
    });
    classlist().then((response) => {
      if (response.status === true) {
        setClassList(response.data);
      }
    });

    if(localStorage.getItem("Role")==="1"){
      viewprofile(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          // setLName(response.data.playerlist.last_name);
          setDOB(response.data.playerlist.date_of_birth);
          setGender(response.data.playerlist.gender);
          
          if(response.data.playerlist.email_id !== null){
            setEmail(response.data.playerlist.email_id);
          }
          if(response.data.playerlist.mobile_no !== null){
            setMobNo(response.data.playerlist.mobile_no);
          }
          
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_id);
          setClass(response.data.playerlist.class);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhotoUrl(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.sports_typeid !== null) {
            setSportId(response.data.playerlist.sports_typeid);
          }
          if (response.data.playerlist.sportsposition !== null) {
            setSportPos(response.data.playerlist.sportsposition);
          }
          if (response.data.playerlist.fq !== null) {
            setStuFQ(response.data.playerlist.fq);
          }
          if (response.data.playerlist.fq_bench_mark !== null) {
            setStuNFQ(response.data.playerlist.fq_bench_mark);
          }
          if (response.data.playerlist.sports_type !== null) {
            setSport(response.data.playerlist.sports_type);
          }
        }
      });
    }else if(localStorage.getItem("Role")==="3"){
      cityplayerview(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          // setLName(response.data.playerlist.last_name);
          setDOB(response.data.playerlist.date_of_birth);
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_id);
          setClass(response.data.playerlist.class);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhotoUrl(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.sports_typeid !== null) {
            setSportId(response.data.playerlist.sports_typeid);
          }
          if (response.data.playerlist.sportsposition !== null) {
            setSportPos(response.data.playerlist.sportsposition);
          }
          if (response.data.playerlist.fq !== null) {
            setStuFQ(response.data.playerlist.fq);
          }
          if (response.data.playerlist.fq_bench_mark !== null) {
            setStuNFQ(response.data.playerlist.fq_bench_mark);
          }
          if (response.data.playerlist.sports_type !== null) {
            setSport(response.data.playerlist.sports_type);
          }
        }
      });
    }else if(localStorage.getItem("Role")==="4"){
      schoolviewplayer(id).then((response) => {
        if (response.status === true) {
          setFName(response.data.playerlist.first_name);
          // setLName(response.data.playerlist.last_name);
          setDOB(response.data.playerlist.date_of_birth);
          setGender(response.data.playerlist.gender);
          setEmail(response.data.playerlist.email_id);
          setMobNo(response.data.playerlist.mobile_no);
          setUser(response.data.playerlist.username);
          setStatus(response.data.playerlist.status);
          setSchool(response.data.playerlist.school_id);
          setClass(response.data.playerlist.class);
          setSection(response.data.playerlist.section);
          if (response.data.playerlist.photograph !== null) {
            setPhotoUrl(
              response.data.photo_path + response.data.playerlist.photograph
            );
          }
          if (response.data.playerlist.sports_typeid !== null) {
            setSportId(response.data.playerlist.sports_typeid);
          }
          if (response.data.playerlist.sportsposition !== null) {
            setSportPos(response.data.playerlist.sportsposition);
          }
          if (response.data.playerlist.fq !== null) {
            setStuFQ(response.data.playerlist.fq);
          }
          if (response.data.playerlist.fq_bench_mark !== null) {
            setStuNFQ(response.data.playerlist.fq_bench_mark);
          }
          if (response.data.playerlist.sports_type !== null) {
            setSport(response.data.playerlist.sports_type);
          }
        }
      });
    }
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  function backlist(){
    dotracking("back button in edit player for " + fname + " (" + uname + " - "  + id + ") $$ " + trackobj.playerdetheading);
    navigate("/superadmin/viewplayer/" + id)
  }
  function submitplayer(e) {
    e.preventDefault();
    setSubmitted(true);
    setEmailErr("");
    setMobErr("");
    var valid = "";

    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        valid = "required";
        setEmailErr("Please Enter a valid Email Address");
      }
    }
    if (mobno !== "") {
      if (mobno.length !== 10) {
        valid = "required";
        setMobErr("Mobile Number must be 10 characters");
      }
    }
    if (
      fname !== "" &&
      gender !== "" &&
      dob !== "" &&
      email !== "" &&
      mobno !== "" &&
      clas !== "" &&
      section !== "" &&
      schid !== "" &&
      valid === ""
    ) {
      dotracking("update button in edit player for " + fname + " (" + id + ") $$ " + trackobj.playerdetheading);
      setLoading(true);
      const temp = new FormData();
      temp.append("email_id", email);
      temp.append("mobile_no", mobno);
      temp.append("first_name", fname);
      temp.append("last_name", "");
      temp.append("gender", gender);
      temp.append("date_of_birth", dob);
      temp.append("school_id", schid);
      temp.append("class", clas);
      temp.append("section", section);
      temp.append("user_id", id);
      temp.append("photograph", photo);
      temp.append("position", sportpos);
      temp.append("sports_pos_id", 0);
      temp.append("sports_typeid", sportid);
      temp.append("sports", sport);
      temp.append("fq", stufq);
      temp.append("fq_bench_mark", stunfq);

      adminupdateplayer(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          toast.success(response.message);
          navigate("/superadmin/viewplayer/" + id);

          // if (status === 1) {
          //   navigate("/superadmin/allplayers");
          // } else if (status === 6) {
          //   navigate("/superadmin/blockedplayers");
          // }
        } else {
          setLoading(false);
          setErrorMsg(response.message);
        }
      });
    }
  }
  const [emailerr, setEmailErr] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setEmailErr("Please Enter a valid Email Address");
      }
    }
  }

  const [moberr, setMobErr] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setMobErr("");
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setMobErr("Mobile Number must be 10 characters");
      }
    }
  }

  const [loading, setLoading] = useState(false);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Player Detail</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Player</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Player Detail
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            {loading && (
              <div className="dashloader-wrapper">
                <div className="loader"></div>
                <h3>Loading...Please do not go back or close the window.</h3>
              </div>
            )}

            {!loading && (
              <>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Full Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          value={fname}
                          onChange={(e) => setFName(e.target.value)}
                          autoFocus
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !fname && (
                          <span className="errortext">
                            Full Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Last Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lname}
                      onChange={(e) => setLName(e.target.value)}
                      onKeyPress={LetterOnly}
                      onPaste={onPasteLetter}
                    />
                    {submitted && !lname && (
                      <span className="errortext">Last Name is required</span>
                    )}
                  </div>
                </div> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Gender <span className="errortext"> *</span>
                        </label>
                        <select
                          value={gender}
                          className="form-control"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Gender ---
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {submitted && !gender && (
                          <span className="errortext">Gender is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Date of Birth <span className="errortext"> *</span>
                        </label>
                        <input
                          type="date"
                          value={dob}
                          className="form-control"
                          onChange={(e) => setDOB(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                        {submitted && !dob && (
                          <span className="errortext">
                            Date of Birth is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email Address <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={checkemail}
                        />
                        {submitted && !email && (
                          <span className="errortext">
                            Email Address is required
                          </span>
                        )}
                        {emailerr !== "" && (
                          <span className="errortext">{emailerr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Mobile Number <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          value={mobno}
                          onChange={handleChange}
                          onBlur={checkmob}
                          maxLength={10}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !mobno && (
                          <span className="errortext">
                            Mobile Number is required
                          </span>
                        )}
                        {moberr !== "" && (
                          <span className="errortext">{moberr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">School </label>
                        <select
                          className="form-control"
                          value={schid}
                          onChange={(e) => setSchool(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select School ---
                          </option>
                          {schlist.map((data, i) => {
                            return (
                              <option value={data.id} key={i}>
                                {data.school_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Class <span className="errortext"> *</span>
                        </label>
                        <select
                          value={clas}
                          className="form-control"
                          onChange={(e) => setClass(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Class ---
                          </option>
                          {sclist.map((data, i) => {
                            return (
                              <option value={data.id} key={i}>
                                {data.class}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !clas && (
                          <span className="errortext">Class is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Section <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Section"
                          value={section}
                          onChange={(e) => setSection(e.target.value)}
                          autoFocus
                          //   onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !section && (
                          <span className="errortext">Section is required</span>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          User Id <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Id"
                          value={userid}
                          onChange={(e) => setClass(e.target.value)}
                          autoFocus
                          //   onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !userid && (
                          <span className="errortext">User Id is required</span>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Photograph <span className="errortext"> *</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg,.jpeg,.png"
                          onChange={photofile}
                        />
                        <img
                          className="profile_img mt-2"
                          src={photourl}
                          height="160"
                          alt="photo"
                          onError={(e) => errorhandle(e)}
                        />

                        {submitted && !photo && (
                          <span className="errortext">
                            Photograph is required
                          </span>
                        )}
                        {photoerror !== "" && (
                          <span className="errortext">{photoerror}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </div>
                <div className="card-footer text-center border-top">
                  <button
                    type="submit"
                    className="btn btn-gray me-2"
                    title="Player List"
                    onClick={backlist}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className="btn btn-success"
                    title="Update Player"
                    onClick={submitplayer}
                  >
                    Update
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
