import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import {
  playergetcategory,
  playerregisteredtour,
  playertourlist,
} from "../../../../services/player";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const ListRegPlayer = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      width: 220,
      field: "id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data.meet_name)}
              title="Tournament Detail"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {params.data.sports_type !== "ATHLETICS" && (
              <>
                <a
                  onClick={() => tmlist(params.value, params.data.meet_name)}
                  title="Team List"
                  className="btn-sm btn-warning me-2"
                >
                  <i className="fa fa-list fa-lg"></i>
                </a>
                <a
                  onClick={() => schlist(params.value, params.data.meet_name)}
                  title="Schedule List"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fas fa-object-group fa-lg"></i>
                </a>
              </>
            )}

            {params.data.sports_type === "ATHLETICS" && (
              <a
                onClick={() => orderdet(params.value, params)}
                title="Order Events List"
                className="btn-sm btn-warning me-2"
              >
                <i className="fa fa-calendar fa-lg"></i>
              </a>
            )}

            <a
              onClick={() => reslist(params.value, params.data.meet_name)}
              title="Results List"
              className="btn-sm btn-export me-2"
            >
              <i className="fas fa-trophy fa-lg"></i>
            </a>
            {params.data.sports_type !== "ATHLETICS" && (
              <a
                onClick={() =>
                  pointdetlist(params.value, params.data.meet_name)
                }
                title="Points List"
                className="btn-sm btn-gray"
              >
                <i className="fa fa-list fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entry_open",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Type",
      width: 170,
      field: "meettype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team",
      width: 150,
      field: "team_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function viewlist(vid, vname) {
    dotracking(
      "view icon in registered list for " +
        vname +
        " (" +
        vid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    navigate("/player/registeredviewtournament/" + vid);
  }

  function tmlist(tid, vname) {
    localStorage.setItem("Page", "1");
    dotracking(
      "team list icon in registered list for " +
        vname +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.teamplaheading
    );
    navigate("/player/teamplayerlist/" + tid);
  }

  function orderdet(tid, tdata) {
    dotracking(
      "order of event list icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.tourorderlistheading
    );
    navigate("/player/eventlist/" + tid);
  }
  function schlist(vid, vname) {
    playergetcategory(vid, localStorage.getItem("UID")).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          dotracking(
            "schedule list icon in registered list for " +
              vname +
              " (" +
              vid +
              ") tournament $$ " +
              trackobj.fixlistheading
          );
          navigate(
            "/player/schedulelist/" + vid + "/" + res.data[0].category_id
          );
        }
      }
    });
    // () => navigate("/player/schedulelist/" + params.value)
  }

  function pointdetlist(vid, vname) {
    playergetcategory(vid, localStorage.getItem("UID")).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          dotracking(
            "points list icon in registered list for " +
              vname +
              " (" +
              vid +
              ") tournament $$ " +
              trackobj.pointlistheading
          );
          navigate("/player/pointslist/" + vid + "/" + res.data[0].category_id);
        }
      }
    });
  }

  function reslist(tid, vname) {
    dotracking(
      "results list icon in registered list for " +
        vname +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.resultheading
    );
    navigate("/player/resultlist/" + tid);
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    playertourlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.registered_tour);
      }
    });
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Registered Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Registered Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
