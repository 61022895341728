import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import {
  scheadtourviewdetail,
  schprofileview,
} from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  Championslistofschool,
  athleticschampionslist,
} from "../../../../../services/common";
import moment from "moment";
import {
  MyUniquePerformance,
  mysclperformance,
  tourdetails,
} from "../../../../../services/register";
import {
  Playerprofiledetails,
  playerathleticsresult,
} from "../../../../../services/player";
import { coachtourviewdetail } from "../../../../../services/coach";

export const AdminIndividualChampionlist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");
  const [urllink, setUrlLink] = useState("");
  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [bronze, setBronze] = useState(0);
  const [performance, setPerformance] = useState([]);
  const [total, setTotal] = useState(0);
  const [performfilterProduct, setPerformFilterProduct] = useState([]);
  const [roundsfilterProduct, setRoundsFilterProduct] = useState([]);
  const [championslist, setChampionslist] = useState([]);

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schprofileview().then((response) => {
        if (response.status === true) {
          const temp2 = new FormData();
          temp2.append("tournament_id", id);
          temp2.append("school_id", response.data.school_id);
          Championslistofschool(temp2).then((response) => {
            if (response.status === true) {
              setChampionslist(response.data.individual_champions_of_schools);
            }
          });
        }
      });

      scheadtourviewdetail(id).then((response) => {
        if (response.status === true) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      playerathleticsresult(id, localStorage.getItem("UID")).then(
        (response) => {
          if (response.status === true) {
            setPerformFilterProduct(response.data.finals);
            setRoundsFilterProduct(response.data.heats);
          }
        }
      );
      Playerprofiledetails(localStorage.getItem("Name")).then((response) => {
        if (response.status === true) {
          if (response.data.athletelist !== null) {
            const temp1 = new FormData();
            temp1.append("tournament_id", id);
            temp1.append("user_id", response.data.athletelist.user_id);
            MyUniquePerformance(temp1).then((response) => {
              if (response.status === true) {
                setGold(
                  response.data.overall_champions_my_school[0].total_gold_medals
                );
                setSilver(
                  response.data.overall_champions_my_school[0]
                    .total_silver_medals
                );
                setBronze(
                  response.data.overall_champions_my_school[0]
                    .total_bronze_medals
                );
                setTotal(
                  parseInt(
                    response.data.overall_champions_my_school[0]
                      .total_gold_medals
                  ) +
                    parseInt(
                      response.data.overall_champions_my_school[0]
                        .total_silver_medals
                    ) +
                    parseInt(
                      response.data.overall_champions_my_school[0]
                        .total_bronze_medals
                    )
                );
                setPerformance(response.data.overall_champions_my_school);
              }
            });
          }
        }
      });
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 
    listathletics();
  }, []);

  function listathletics() {
    setFilterProduct([]);
    const temp = new FormData();
    temp.append("tournament_id", id);
    athleticschampionslist(temp).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.individual_champions);
      }
    });
  }

  function viewall(vname, vid) {
    dotracking(
      "view all player button in individual champions list for " +
        vname +
        " (" +
        vid +
        ") age category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.ageecatindividualchampionheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/agecategorychampionlist/" + id + "/" + vid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/agecategorychampionlist/" + id + "/" + vid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/agecategorychampionlist/" + id + "/" + vid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/agecategorychampionlist/" + id + "/" + vid);
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/agecategorychampionlist/" + id + "/" + vid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/agecategorychampionlist/" + id + "/" + vid);
    } 
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in individual champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/superadmin/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      dotracking(
        "back button in individual champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/cityregional/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      if (urllink === "individualchampion") {
        dotracking(
          "back button in individual champions list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.viewtourheading
        );
        navigate("/schoolhead/tournamentdetails/" + id);
      } else {
        dotracking(
          "back button in individual champions list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.resultheading
        );
        navigate("/schoolhead/athleticsresults/" + id);
      }
    } else if (localStorage.getItem("Role") === "5") {
      dotracking(
        "back button in individual champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/referee/athleticsresults/" + id);
    } else if (localStorage.getItem("Role") === "6") {
      dotracking(
        "back button in individual champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.viewtourheading
      );
      if (urllink === "registeredindividualchampion") {
        navigate("/player/registeredviewtournament/" + id);
      } else if (urllink === "eligibleindividualchampion") {
        navigate("/player/eligibleviewtournament/" + id);
      } else {
        navigate("/player/tournamentdetail/" + id);
      }
    }else if (localStorage.getItem("Role") === "7") {
      dotracking(
        "back button in individual champions list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.resultheading
      );
      navigate("/coach/athleticsresults/" + id);
    } 
  }
  const gridRef = useRef();

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Champions List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item">
              <a>Result List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Champions List
            </li>
          </ol>
        </div>

        {localStorage.getItem("Role") === "4" && (
          <div className="row">
            <div className="col-12">
              <form className="card mt-0">
                <div className="card-body">
                  <div className="row pb-3">
                    <div className="col-md-10">
                      <div className="text-left">
                        <h3 className="mb-2">{tname}</h3>
                      </div>
                    </div>
                    {localStorage.getItem("Role") !== "1" && (
                      <div className="col-md-2">
                        <div className="text-right">
                          <a onClick={backlist} className="btn btn-gray">
                            <i className="fe fe-corner-up-left"></i> Back
                          </a>
                        </div>
                      </div>
                    )}
                    <h4 className="mb-2 errortext">
                      MY SCHOOL PLAYERS PERFORMANCE
                    </h4>
                  </div>

                  <div id="playerlist">
                    <div className="row mt-2 border-bottom">
                      {championslist.map((item, j) => {
                        return (
                          <div className="col-md-4" key={j}>
                            <div className="box">
                              <div className="box__head">
                                <h1>{item.first_name}</h1>
                              </div>
                              <div className="box__content">
                                <h2>
                                  <b>Player UID</b> <span>{item.username}</span>
                                </h2>
                                <h2>
                                  <b>Class</b>{" "}
                                  <span>
                                    {item.class} - {item.section}
                                  </span>
                                </h2>
                                <h2>
                                  <b>Gold</b>{" "}
                                  <span className="highblink tx-20">
                                    {item.total_gold}
                                  </span>
                                </h2>
                                <h2>
                                  <b>Silver</b>{" "}
                                  <span className="highblink tx-20">
                                    {item.total_silver}
                                  </span>
                                </h2>
                                <h2>
                                  <b>Bronze</b>{" "}
                                  <span className="highblink tx-20">
                                    {item.total_bronze}
                                  </span>
                                </h2>
                                <h2>
                                  <b>Total Points</b>{" "}
                                  <span>{item.total_points}</span>
                                </h2>
                              </div>
                              <div className="box_footer">
                                <h4>Age Category :-</h4>
                                <p>{item.age_category_name}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {localStorage.getItem("Role") === "6" && (
          <div className="col-md-12">
            <form className="card">
              <div className="card-body">
                <div className="row">
                  <div className="row pb-3">
                    <div className="col-md-10">
                      <div className="text-left">
                        <h3 className="mb-2">{tname}</h3>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="text-right">
                        <a onClick={backlist} className="btn btn-gray">
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  </div>
                  <h4 className="mb-2 errortext">MY PERFORMANCE</h4>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/gold1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{gold}</h2>
                                      <span className="teamach">
                                        <strong>Gold Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/silver1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{silver}</h2>
                                      <span className="teamach">
                                        <strong>Silver Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-uniform">
                      <ul class="ch-grid">
                        <li
                          className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                          style={{ visibility: " visible" }}
                        >
                          <div className="team-section">
                            <div className="ch-item">
                              <div className="ch-info-wrap">
                                <div className="ch-info">
                                  <div
                                    className="ch-info-back"
                                    style={{
                                      backgroundImage: `url(${require("../../../../../assets/images/bronze1.png")})`,
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="team-detail">
                                      <h2>{bronze}</h2>
                                      <span className="teamach">
                                        <strong>Bronze Medals</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="table-responsive">
                  <table className="sp-champion-table sp-data-table">
                    <thead className="sp-table-header">
                      <tr>
                        <th className="text-center">Place</th>
                        <th className="text-center">Age Category</th>
                        <th className="text-center">Bib No</th>
                        <th className="text-center">Event</th>
                        <th className="text-center">Time / distance</th>
                        <th className="text-center">Round</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roundsfilterProduct.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              key={j}
                            >
                              <td className="data-pct">{item.place}</td>
                              <td className="data-pct">
                                {item.age_category_name}
                              </td>
                              <td className="data-pct">{item.bib_no}</td>
                              <td className="data-pct">{item.event}</td>
                              <td className="data-pct">{item.time_distance}</td>
                              <td className="data-pct">{item.heats_finals}</td>
                            </tr>
                          </>
                        );
                      })}
                      <tr></tr>
                      {performfilterProduct.map((item, j) => {
                        const getRowColor = () => {
                          return j % 2 === 0 ? "even" : "odd";
                        };
                        return (
                          <>
                            <tr
                              className={`sp-row-no-0 ${getRowColor()}`}
                              key={j}
                            >
                              <td className="data-pct">{item.place}</td>
                              <td className="data-pct">
                                {item.age_category_name}
                              </td>
                              <td className="data-pct">{item.bib_no}</td>
                              <td className="data-pct">{item.event}</td>
                              <td className="data-pct">{item.time_distance}</td>
                              <td className="data-pct">{item.heats_finals}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-10">
                    <div className="text-left">
                      {localStorage.getItem("Role") !== "4" &&
                        localStorage.getItem("Role") !== "6" && (
                          <h3 className="mb-2">{tname}</h3>
                        )}

                      {(localStorage.getItem("Role") === "4" ||
                        localStorage.getItem("Role") === "6" || localStorage.getItem("Role") === "7") && (
                        <h4>OVERALL INDIVIDUAL CHAMPIONS</h4>
                      )}
                    </div>
                  </div>
                  {(localStorage.getItem("Role") === "1" || localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                    <div className="col-md-2">
                      <div className="text-right">
                        <a onClick={backlist} className="btn btn-gray">
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                <div id="playerlist">
                  {filterProduct.map((item, j) => {
                    return (
                      <div className="row mt-2 border-bottom" key={j}>
                        <div className="col-md-10">
                          <h4 className="text-success mb-2">
                            {item.age_category}
                          </h4>
                        </div>
                        <div className="col-md-2 text-right">
                          {item.champions_list.length !== 0 && (
                            <a
                              className="btn btn-secondary mb-2"
                              onClick={() =>
                                viewall(item.age_category, item.age_category_id)
                              }
                            >
                              View All Players
                            </a>
                          )}
                        </div>

                        {item.champions_list.length !== 0 && (
                          <>
                            {item.champions_list.map((data, i) => {
                              return (
                                <div className="col-md-4" key={i}>
                                  {data.user_id ===
                                    parseInt(localStorage.getItem("UID")) && (
                                    <div className="activebox">
                                      <div className="activebox__head">
                                        <h1>{data.first_name}</h1>
                                        <div className="rounddetails">
                                          {i == 0 && (
                                            <span>1st</span>
                                          )}
                                          {i == 1 && (
                                            <span>2nd</span>
                                          )}
                                          {i == 2 && (
                                            <span>3rd</span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="activebox__content">
                                        <h2>
                                          <b>Player UID</b>{" "}
                                          <span>{data.username}</span>
                                        </h2>
                                        {/* <h2>
                                          <b>Gender</b>{" "}
                                          <span>{data.gender}</span>
                                        </h2>
                                        <h2>
                                          <b>Date of Birth</b>{" "}
                                          <span>
                                            {moment(data.date_of_birth).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </span>{" "}
                                        </h2> */}
                                        <h2>
                                          <b>Class</b>{" "}
                                          <span>
                                            {data.class} - {data.section}
                                          </span>
                                        </h2>
                                        {/* <h2>
                                          <b>Section</b>{" "}
                                          <span>{data.section}</span>
                                        </h2> */}
                                        <h2>
                                          <b>Gold</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_gold}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Silver</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_silver}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Bronze</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_bronze}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Total Points</b>{" "}
                                          <span>{data.total_points}</span>
                                        </h2>
                                        <h2>
                                          <b>Category</b>{" "}
                                          <span>{data.age_category_name}</span>
                                        </h2>
                                      </div>
                                      <div className="activebox_footer">
                                        <h4>School :-</h4>
                                        <p>{data.school_name}</p>
                                      </div>
                                    </div>
                                  )}

                                  {data.user_id !==
                                    parseInt(localStorage.getItem("UID")) && (
                                    <div className="box">
                                      <div className="box__head">
                                        <h1>{data.first_name}</h1>
                                        <div className="rounddetails">
                                          {i == 0 && (
                                            <span>1st</span>
                                          )}
                                          {i == 1 && (
                                            <span>2nd</span>
                                          )}
                                          {i == 2 && (
                                            <span>3rd</span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="box__content">
                                        <h2>
                                          <b>Player UID</b>{" "}
                                          <span>{data.username}</span>
                                        </h2>
                                        {/* <h2>
                                          <b>Gender</b>{" "}
                                          <span>{data.gender}</span>
                                        </h2>
                                        <h2>
                                          <b>Date of Birth</b>{" "}
                                          <span>
                                            {moment(data.date_of_birth).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </span>{" "}
                                        </h2> */}
                                        <h2>
                                          <b>Class</b>{" "}
                                          <span>
                                            {data.class} - {data.section}
                                          </span>
                                        </h2>
                                        {/* <h2>
                                          <b>Section</b>{" "}
                                          <span>{data.section}</span>
                                        </h2> */}
                                        <h2>
                                          <b>Gold</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_gold}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Silver</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_silver}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Bronze</b>{" "}
                                          <span className="highblink tx-20">
                                            {data.total_bronze}
                                          </span>
                                        </h2>
                                        <h2>
                                          <b>Total Points</b>{" "}
                                          <span>{data.total_points}</span>
                                        </h2>
                                        <h2>
                                          <b>Category</b>{" "}
                                          <span>{data.age_category_name}</span>
                                        </h2>
                                      </div>
                                      <div className="box_footer">
                                        <h4>School :-</h4>
                                        <p>{data.school_name}</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}

                        {item.champions_list.length === 0 && (
                          <div className="col-md-4">
                            <div className="box">
                              <div className="box__content text-center">
                                <h2 className="text-center">
                                  No Individual Champions Available
                                </h2>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
