import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function interschoolagecatlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/all-category-for-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function interschoolagecatcreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/save-selected-category-for-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function admininterschoolagecatcreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/save-selected-category-for-tournament-admin`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function interschoolagecatpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/selected-category-for-tournament-pay-success`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Create based on tournament
export async function teamcreateschoolhead(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/schooladmin/create-team`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Update based on tournament
export async function teamupdateschoolhead(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/schooladmin/edit-team`, data, { headers: header })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
export async function interschoolagecatselectlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/all-paid-category-for-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function interschoolplayersupload(data) {
  try {
    return await axios
      .post(`${baseUrl}/add-players-to-category-by-school`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function interschooltourpaidornot(data) {
  try {
    return await axios
      .post(`${baseUrl}/check-school-paid-or-not-for-tournament`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Inter school tournament team list
export async function interschooltourteamlist(id,cid,sid) {
  try {
    return await axios
      .get(`${baseUrl}/inter-school-team-list/${id}/${cid}/${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function interschoolplayerperflist(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/list-school-performance-player-photo/${id}/${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function interperfplayerlist(id,sid) {
  try {
    return await axios
      .get(`${baseUrl}/list-players-performance/${id}/${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}




export async function interschooloverallplayerphotolist(id) {
  try {
    return await axios
      .get(`${baseUrl}/overall-list-performance-players-photo/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Player list for category wise
export async function interschoolcategoryplayerlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/all-players-list-for-inter-school`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Add Player for category wise
export async function interschoolcategoryplayercreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/add-player-to-team-for-inter-school`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//racqet Add Player for category wise
export async function racqetinterschoolcategoryplayercreate(data) {
  try {
    return await axios
      .post(`${baseUrl}/add-player-to-team-for-inter-school-racqet-sports`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Delete Player for category wise
export async function interschoolcategoryplayerdelete(id,uid,tid,sid) {
  try {
    return await axios
      .get(`${baseUrl}/remove-team-player-for-inter-school/${id}/${uid}/${tid}/${sid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Class list and age category for category wise
export async function interschoolcategoryclasslist(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-dob-class-for-inter-school`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response);
  if (error.response.status === 401) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title:
        "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //       window.location.reload();
      //   }
    });
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
