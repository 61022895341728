import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { logindata } from "../../../../services/login";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  eventdetailswithstring,
  eventpaymentorder,
  eventregisterplayerpaymentpending,
  playereventpayment,
  playertourpayment,
} from "../../../../services/register";

export const EventPaymentPendingRegister = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userid } = useParams();
  const [paypage, setPaypage] = useState(true);

  const [eventid, setEventID] = useState("");

  const [fname, setFName] = useState("");
  const [uname, setUName] = useState("");
  const [stuschool, setStuSch] = useState("");
  const [stuclass, setStuClass] = useState("");
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");

  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");

  function regsubmit(e) {
    e.preventDefault();

    const temp = new FormData();
    temp.append("user_id", userid);
    temp.append("tournament_meet_id", eventid);

    eventpaymentorder(temp).then((response) => {
      if (response.status === true) {
        // if(amt !== "0"){
        dotracking(
          "paynow button in event payment for player " +
            fname +
            " (" +
            uname +
            " - " +
            userid +
            ") ,  " +
            tname +
            " (" +
            eventid +
            ")" +
            " tournament $$ " +
            trackobj.payeventheading
        );

        setUser(response.data.entries.username);
        setPassword(response.data.password);
        paynow(response.data.RAZORPAY_KEY, response.data.order_id);
        // }else{
        //   setPaypage(false);
        // }
      } else {
        toast.error(response.message);
      }
    });
  }

  async function paynow(pkey, ordid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Event Registration",
      image: require("../../../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("user_id", userid);
        temp.append("tournament_meet_id", eventid);

        setLoading(true);

        playereventpayment(temp).then((response) => {
          if (response.status === true) {
            setPaypage(false);
            setLoading(false);
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button in event payment for player " +
              fname +
              " (" +
              uname +
              " - " +
              userid +
              "), redirect to Player dashboard $$ " +
              trackobj.dashheading
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        toast.error(response.message);
      }
    });
  }

  const [tname, setTName] = useState("");
  const [status, setStatus] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [eclosed, setEClosed] = useState(false);

  const [amt, setAmount] = useState("0");
  const [free, setFree] = useState("");

  const [school, setSchool] = useState("");
  const [cir, setCir] = useState("");
  useEffect(() => {
    const temp = new FormData();
    temp.append("event_id_string", id);
    eventdetailswithstring(temp).then((res) => {
      if (res.status === true) {
        setEventID(res.data.event_info.id);
        if (res.data.event_info.circular_file !== null) {
          setCir(res.data.s3_path + res.data.event_info.circular_file);
        }

        eventregisterplayerpaymentpending(userid, res.data.event_info.id).then(
          (response) => {
            if (response.status === true) {
              setStatus(response.data.tournamentDetails.pay_status);
              setTName(response.data.tournamentDetails.event_name);
              setSDate(
                moment(response.data.tournamentDetails.start_date).format(
                  "DD-MM-YYYY"
                )
              );
              setEDate(
                moment(response.data.tournamentDetails.end_date).format(
                  "DD-MM-YYYY"
                )
              );
              if (
                moment(new Date()).format("yyyy-MM-DD") >
                response.data.tournamentDetails.entry_end_date
              ) {
                setEClosed(true);
              }
              setDDate(
                moment(response.data.tournamentDetails.entry_end_date).format(
                  "DD-MM-YYYY"
                )
              );

              setAmount(response.data.tournamentDetails.fees);
              setFree(response.data.tournamentDetails.free_or_paid_events);
              setSchool(response.data.tournamentDetails.school_name);
              if (response.data.usersDetails !== null) {
                setFName(response.data.usersDetails.first_name);
                setGender(response.data.usersDetails.gender);
                setDOB(
                  moment(response.data.usersDetails.date_of_birth).format(
                    "DD-MM-yyyy"
                  )
                );
                setStuClass(response.data.usersDetails.class_name);
                setStuSec(response.data.usersDetails.section);
                setMobNo(response.data.usersDetails.mobile_no);
                setEmail(response.data.usersDetails.email_id);
                setStuSch(response.data.usersDetails.school_name);
                setUName(response.data.usersDetails.username);
              }
            }
          }
        );
      }
    });
  }, []);
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl registrationpge">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="md-overflow">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-4 pe-0 tab-100 order_a mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <a
                      href="http://pathwayz.sportzvillage.com/"
                      target="_blank"
                    >
                      <img
                        src={require("../../../../assets/images/pathwayz_logo.png")}
                        alt="pathwayz"
                        className="pathwayz_logo"
                      />
                    </a>
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              {eclosed && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      Registeration Closed
                    </h3>
                  </div>
                </div>
              )}

              {status === "SUCCESS" && !eclosed && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      You have already paid for this event
                    </h3>
                  </div>
                </div>
              )}
              {paypage && !eclosed && status !== "SUCCESS" && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="form-text login">
                      <h4 className="mt-0">Event Payment</h4>
                    </div>

                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="card bg-none">
                          <div className="card-body p-0">
                            <div className="clearfix pb-3 border-bottom">
                              <div className="float-left">
                                <h1 className="card-title tourheader mb-0">
                                  {tname}
                                </h1>
                              </div>
                            </div>
                            <div className="row datedetails mx-0">
                              <div className="col-md-3 col-sm-6">
                                <h3 className="card-title">
                                  Start Date <span>{sdate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  End Date <span>{edate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  Entry Last Date <span>{ddate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  Amount{" "}
                                  <span>
                                    {amt !== "0" && (
                                      <b className="matchstatus px-2 py-1">
                                        Rs. {amt} /-
                                      </b>
                                    )}
                                    {amt === "0" && (
                                      <b className="matchstatus px-2 py-1">
                                        Free
                                      </b>
                                    )}
                                  </span>
                                </h3>
                              </div>
                            </div>
                            <div className="schoolname border-bottom px-3">
                              <h3>
                                School Name :{" "}
                                <b className="font-weight-bold text-muted">
                                  {school}
                                </b>
                              </h3>
                            </div>

                            {cir !== "" && (
                              <div className="row datedetails mx-0">
                                <div className="col-md-6">
                                  <h3 className="card-title">
                                    Circular :{" "}
                                    <a
                                      href={cir}
                                      target="_blank"
                                      download={cir}
                                      className="text-danger"
                                    >
                                      Click Here{" "}
                                      <i className="fa fa-download"></i>
                                    </a>
                                  </h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="card bg-none">
                          <div className="card-body p-0">
                            <div className="clearfix pb-3 border-bottom">
                              <div className="float-left">
                                <h1 className="card-title tourheader mb-0">
                                  Player Details
                                </h1>
                              </div>
                            </div>
                            <div className="row datedetails mx-0">
                              <div className="col-md-8">
                                <h3 className="card-title">
                                  Full Name :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {fname}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-4">
                                <h3 className="card-title">
                                  Username :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {uname}
                                  </b>
                                </h3>
                              </div>
                            </div>

                            <div className="row datedetails mx-0">
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Gender :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {gender}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  DOB :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {dob}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Class :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {stuclass}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Section :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {stusec}
                                  </b>
                                </h3>
                              </div>
                            </div>

                            <div className="schoolname border-bottom px-3">
                              <h3>
                                School Name :{" "}
                                <b className="font-weight-bold text-muted">
                                  {stuschool}
                                </b>
                              </h3>
                            </div>
                            <div className="row datedetails  mx-0">
                              <div className="col-md-6">
                                <h3 className="card-title">
                                  Contact Number :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {mobno}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-6">
                                <h3 className="card-title">
                                  Email :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {email}
                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form className="login">
                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          <button
                            className="form-btn regis bg-success mt-2"
                            onClick={regsubmit}
                            type="submit"
                          >
                            <span>Pay Now</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {!paypage && !eclosed && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="text-center background-text">
                      <h1 className="mb-3 font-weight-semibold text-danger">
                        Payment successful !
                      </h1>
                    </div>
                    <div className="background-text">
                      <h4 className="mb-3 font-weight-semibold">Hi {fname},</h4>
                      <div className="successmeg">
                        <p>
                          You have paid the payment successfully for the{" "}
                          <b className="text-success">{tname}</b>. Kindly check
                          your email.
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <a onClick={dashlogin} className="btn btn-gray">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
