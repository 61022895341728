import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import {
  addeventagecat,
  atheventview,
  deleteventagecat,
} from "../../../../../services/common";
import {
  eventsmasterlist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../../services/coach";
export const EventAgeCategory = () => {
  const { id, eid } = useParams();
  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);
  const [agecatevent, setAgeCatEvent] = useState("");
  const [agename, setAgeName] = useState("");

  const [agecateventlist, setAgeCatEventList] = useState([]);
  const [tname, setTName] = useState("");
  const [show, setShow] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Age Category",
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <a
            title="Edit Event Category"
            onClick={() => deleteagecategory(params.data)}
            className="btn-sm btn-danger"
          >
            <i className="fa fa-trash fa-lg"></i>
          </a>
        );
      },
    },
  ];

  const columnSchDefs = [
    {
      headerName: "Age Category",
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function deleteagecategory(list) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = new FormData();
        temp.append("event_table_id", list.id);
        dotracking(
          "delete icon in age category event list for " +
            list.event_name +
            " (" +
            list.id +
            ") event in " +
            list.age_category_name +
            " (" +
            list.age_category_id +
            ") age category for " +
            tname +
            " (" +
            id +
            ") tournament in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.toureventlistheading
        );
        deleteventagecat(temp).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function handleshow() {
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setErrorMsg("");
    setAgeCatEvent("");
  }

  useEffect(() => {
    listall();
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } 

    eventsmasterlist().then((response) => {
      if (response.status === true) {
        // setAgeCatEventList(response.data);
        if (response.data.length !== 0) {
          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].is_active === 1) {
              arr.push({
                value: response.data[i].event_uid,
                label: response.data[i].event_name,
              });
            }
          }
          setAgeCatEventList(arr);
        }
      }
    });
  }, []);

  function listall() {
    setFilterProduct([]);
    atheventview(id, eid).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.age_category_events);
        if (response.data.age_category_events.length !== 0) {
          setAgeName(response.data.age_category_events[0].age_category_name);
        }
      }
    });
  }

  function handleChange(e) {
    if (e !== null) {
      setAgeCatEvent(e);
    } else {
      setAgeCatEvent("");
    }
  }

  function submitagecat(e) {
    e.preventDefault();
    setErrorMsg("");
    if (agecatevent !== "") {
      const temp = new FormData();
      temp.append("meet_id", id);
      temp.append("age_category_id", eid);
      temp.append("event_id", agecatevent.value);
      const athdata = agecateventlist.find((data) => {
        return data.value === parseInt(agecatevent.value);
      });
      dotracking(
        "add button in age category event list for " +
          athdata.event_name +
          "(" +
          agecatevent +
          ") event in " +
          agename +
          " (" +
          eid +
          ") age category for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.toureventlistheading
      );

      addeventagecat(temp).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          listall();
          setAgeCatEvent("");
          handleclose();
        }
      });
    } else {
      setErrorMsg("Please Enter Your Event");
    }
  }

  function backlist() {
    dotracking(
      "back button in age category event list for " +
        agename +
        " (" +
        eid +
        ") age category for " +
        tname +
        " (" +
        id +
        ") tournament in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentagecategorylist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentagecategorylist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentagecategorylist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentagecategorylist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentagecategorylist/" + id);
    } 
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Event Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Event Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <a className="btn btn-gray mx-2" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                    {(localStorage.getItem("Role") === "1" ||
                      localStorage.getItem("Role") === "3") && (
                      <a className="btn btn-success" onClick={handleshow}>
                        <i className="fa fa-plus"></i> Add Events
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3") && (
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                )}
                {(localStorage.getItem("Role") === "4" ||
                  localStorage.getItem("Role") === "5") && (
                  <AgGridReact
                    columnDefs={columnSchDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                )}
              </div>

              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4> Add Events</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Event <span className="errortext"> *</span>
                        </label>

                        <Select
                          placeholder="Select Event"
                          value={agecatevent}
                          options={agecateventlist}
                          onChange={handleChange}
                          isSearchable={true}
                        />

                        {/* <select
                          className="form-control custom-select"
                          value={agecatevent}
                          onChange={(e) => setAgeCatEvent(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Age Category Event ---
                          </option>
                          {agecateventlist.map((data, i) => {
                            return (
                              <option value={data.event_uid} key={i}>
                                {data.event_name}
                              </option>
                            );
                          })}
                        </select> */}
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  <a className="btn btn-success" onClick={submitagecat}>
                    Add
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
