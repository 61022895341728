import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  athleticsplayerpaymentorder,
  athleticsplayerpaymentpending,
  athleticsplayertourpayment,
  playerpaymentorder,
  playertourpayment,
  registerplayerpaymentpending,
  tourdetails,
} from "../../../../services/register";
import moment from "moment";
import { toast } from "react-toastify";
import { logindata } from "../../../../services/login";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const PaymentPendingRegister = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userid } = useParams();
  const [paypage, setPaypage] = useState(true);

  const [fname, setFName] = useState("");
  const [mfree, setFree] = useState("");

  const [uname, setUName] = useState("");
  const [stuschool, setStuSch] = useState("");
  const [stuclass, setStuClass] = useState("");
  const [stusec, setStuSec] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");

  const [ageevents, setAgeEvents] = useState("");

  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");

  function regsubmit(e) {
    e.preventDefault();
    if (sports.toUpperCase() === "ATHLETICS") {
      const temp = new FormData();
      temp.append("user_id", userid);
      temp.append("tournament_meet_id", id);
      temp.append("amount", amt);

      athleticsplayerpaymentorder(temp).then((response) => {
        if (response.status === true) {
          dotracking(
            "paynow button in payment for player " +
              fname +
              " (" +
              uname +
              " - " +
              userid +
              ") , tournament " +
              id +
              " $$ " +
              trackobj.paytourheading
          );

          setUser(response.data.userdet.username);
          setPassword(response.data.password);
          paynow(response.data.RAZORPAY_KEY, response.data.order_id);
        } else {
          toast.error(response.message);
        }
      });
    } else {
      const temp = new FormData();
      temp.append("user_id", userid);
      temp.append("tournament_meet_id", id);

      playerpaymentorder(temp).then((response) => {
        if (response.status === true) {
          // if(amt !== "0"){
          dotracking(
            "paynow button in payment for player " +
              fname +
              " (" +
              uname +
              " - " +
              userid +
              ") , " +
              tname +
              " (" +
              id +
              ") tournament" +
              " $$ " +
              trackobj.paytourheading
          );

          setUser(response.data.entries.username);
          setPassword(response.data.password);
          paynow(response.data.RAZORPAY_KEY, response.data.order_id);
          // }else{
          //   setPaypage(false);
          // }
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  async function paynow(pkey, ordid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Tournament Registration",
      image: require("../../../../assets/images/favicon.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("razorpay_payment_id", response.razorpay_payment_id);
        temp.append("order_id", ordid);
        temp.append("totalAmount", amt);
        temp.append("user_id", userid);
        temp.append("current_meet_id", id);
        setLoading(true);
        if (sports.toUpperCase() === "ATHLETICS") {
          athleticsplayertourpayment(temp).then((response) => {
            if (response.status === true) {
              setPaypage(false);
              setLoading(false);
            }
          });
        } else {
          playertourpayment(temp).then((response) => {
            if (response.status === true) {
              setPaypage(false);
              setLoading(false);
            }
          });
        }
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function dashlogin() {
    const temp = new FormData();
    temp.append("username", user);
    temp.append("password", pass);
    logindata(temp).then((response) => {
      if (response.status === true) {
        localStorage.setItem("UID", response.data.userid);
        localStorage.setItem("Role", response.data.roleid);
        localStorage.setItem("Name", response.data.username);
        localStorage.setItem("User", response.data.name);

        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("Subrole", response.data.adminsubrole);

        if (response.data.roleid === 6) {
          dotracking(
            "goto dashboard button in payment for player " +
              fname +
              " (" +
              uname +
              " - " +
              userid +
              "), redirect to Player dashboard $$ " +
              trackobj.dashheading
          );
          localStorage.setItem("RoleName", "Player");
          navigate("/player/dashboard");
        }
      } else {
        toast.error(response.message);
      }
    });
  }

  const [tname, setTName] = useState("");
  const [agetype, setAgeType] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [eclosed, setEClosed] = useState(false);

  const [amt, setAmount] = useState("0");
  const [disper, setDisPer] = useState("0");
  const [disamt, setDisAmt] = useState("0");
  const [tamt, setTAmt] = useState("0");

  const [tourcls, setTCls] = useState("");
  const [agecat, setAgeCat] = useState("");
  const [school, setSchool] = useState("");
  const [sports, setSports] = useState("");
  const [cir, setCir] = useState("");
  useEffect(() => {
    tourdetails(id).then((res) => {
      if (res.status === true) {
        if (res.data.tournament !== null) {
          setFree(res.data.tournament.free_tournament);
          if (res.data.tournament.sports_type.toUpperCase() === "ATHLETICS") {
            if (res.data.tournament.circular_file_1 !== null) {
              setCir(
                res.data.circular_path + res.data.tournament.circular_file_1
              );
            }
            if (
              moment(new Date()).format("yyyy-MM-DD") >
              res.data.tournament.entry_deadline_date
            ) {
              setEClosed(true);
            }
            if (res.data.tournament.agecat_or_std === 1) {
              let arr1 = "";
              // if (res.data.age_category.length !== 0) {
              //   for (let j = 0; j < res.data.age_category.length; j++) {
              //     if (arr1 === "") {
              //       arr1 = res.data.age_category[j].standard_name;
              //     } else if (arr1 !== "") {
              //       arr1 =
              //         arr1 + " , " + res.data.age_category[j].standard_name;
              //     }
              //   }
              // }
              setTCls(res.data.tournament.categories);
            }
            const temp = new FormData();
            temp.append("uid", userid);
            temp.append("tid", id);
            athleticsplayerpaymentpending(temp).then((response) => {
              if (response.status === true) {
                if (response.data.tournamentDetails.length !== 0) {
                  setTName(response.data.tournamentDetails[0].meet_name);
                  setAgeType(response.data.tournamentDetails[0].agecat_or_std);
                  setSDate(
                    moment(
                      response.data.tournamentDetails[0].start_date
                    ).format("DD-MM-YYYY")
                  );
                  setEDate(
                    moment(response.data.tournamentDetails[0].end_date).format(
                      "DD-MM-YYYY"
                    )
                  );

                  setDDate(
                    moment(
                      response.data.tournamentDetails[0].entry_deadline_date
                    ).format("DD-MM-YYYY")
                  );
                  setAgeEvents(response.data.tournamentDetails[0].events);
                  setSports(response.data.tournamentDetails[0].sports_type);
                  setAmount(response.data.totalamount);
                  setDisPer(response.data.discount_percentage);
                  setDisAmt(response.data.discount_amount);
                  setTAmt(response.data.over_all_amount);
                  setAgeCat(response.data.tournamentDetails[0].age_categories);
                  setSchool(response.data.tournamentDetails[0].school_name);
                }
                if (response.data.usersDetails !== null) {
                  setFName(response.data.usersDetails.first_name);
                  setGender(response.data.usersDetails.gender);
                  setDOB(
                    moment(response.data.usersDetails.date_of_birth).format(
                      "DD-MM-yyyy"
                    )
                  );
                  setStuClass(response.data.usersDetails.class_name);
                  setStuSec(response.data.usersDetails.section);
                  setMobNo(response.data.usersDetails.mobile_no);
                  setEmail(response.data.usersDetails.email_id);
                  setStuSch(response.data.usersDetails.school_name);
                  setUName(response.data.usersDetails.username);
                }
              }
            });
          } else {
            if (res.data.tournament.circular_file_1 !== null) {
              setCir(
                res.data.circular_path + res.data.tournament.circular_file_1
              );
            }
            if (
              moment(new Date()).format("yyyy-MM-DD") >
              res.data.tournament.entry_deadline_date
            ) {
              setEClosed(true);
            }
            if (res.data.tournament.agecat_or_std === 1) {
              let arr1 = "";
              // if (res.data.age_category.length !== 0) {
              //   for (let j = 0; j < res.data.age_category.length; j++) {
              //     if (arr1 === "") {
              //       arr1 = res.data.age_category[j].standard_name;
              //     } else if (arr1 !== "") {
              //       arr1 =
              //         arr1 + " , " + res.data.age_category[j].standard_name;
              //     }
              //   }
              // }
              setTCls(res.data.tournament.categories);

            }

            registerplayerpaymentpending(userid, id).then((response) => {
              if (response.status === true) {
                if (response.data.tournamentDetails !== null) {
                  setTName(response.data.tournamentDetails.meet_name);
                  setAgeType(response.data.tournamentDetails.agecat_or_std);
                  setSDate(
                    moment(response.data.tournamentDetails.start_date).format(
                      "DD-MM-YYYY"
                    )
                  );
                  setEDate(
                    moment(response.data.tournamentDetails.end_date).format(
                      "DD-MM-YYYY"
                    )
                  );

                  setDDate(
                    moment(
                      response.data.tournamentDetails.entry_deadline_date
                    ).format("DD-MM-YYYY")
                  );

                  setSports(response.data.tournamentDetails.sports_type);
                  setAmount(response.data.tournamentDetails.amount);
                  setDisPer(response.data.discount_percentage);
                  setDisAmt(response.data.tournamentDetails.discount_amount);
                  setTAmt(response.data.tournamentDetails.over_all_amount);
                  if (response.data.tournamentDetails.registration_fees === 0) {
                    setAmount("0");
                  }
                  setAgeCat(response.data.tournamentDetails.categories);
                  setSchool(response.data.tournamentDetails.school_name);
                }
                if (response.data.usersDetails !== null) {
                  setFName(response.data.usersDetails.first_name);
                  setGender(response.data.usersDetails.gender);
                  setDOB(
                    moment(response.data.usersDetails.date_of_birth).format(
                      "DD-MM-yyyy"
                    )
                  );
                  setStuClass(response.data.usersDetails.class_name);
                  setStuSec(response.data.usersDetails.section);
                  setMobNo(response.data.usersDetails.mobile_no);
                  setEmail(response.data.usersDetails.email_id);
                  setStuSch(response.data.usersDetails.school_name);
                  setUName(response.data.usersDetails.username);
                }
              }
            });
          }
        }
      }
    });
  }, []);
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl registrationpge">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        <main className="md-overflow">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-4 pe-0 tab-100 order_a mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <a
                      href="http://pathwayz.sportzvillage.com/"
                      target="_blank"
                    >
                      <img
                        src={require("../../../../assets/images/pathwayz_logo.png")}
                        alt="pathwayz"
                        className="pathwayz_logo"
                      />
                    </a>
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              {eclosed && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      Registeration Closed
                    </h3>
                  </div>
                </div>
              )}
              {mfree === "Y" && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown formregis">
                    <h3 className="text-center text-danger">
                      {tname} is a free tournament.
                    </h3>
                  </div>
                </div>
              )}
              {paypage && !eclosed && mfree === "N" && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  {cir !== "" && (
                    <div className="circulardiv">
                      <h3>
                        Circular <br />
                        <a
                          href={cir}
                          target="_blank"
                          download={cir}
                          className="text-success"
                        >
                          Click Here <i className="fa fa-download"></i>
                        </a>
                      </h3>
                    </div>
                  )}

                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="form-text login">
                      <h4 className="mt-0">Tournament Payment</h4>
                    </div>

                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="card bg-none">
                          <div className="card-body p-0">
                            <div className="clearfix pb-3 border-bottom">
                              <div className="float-left">
                                <h1 className="card-title tourheader mb-0">
                                  {tname}
                                </h1>
                              </div>
                            </div>
                            <div className="row datedetails mx-0">
                              <div className="col-md-3 col-sm-6">
                                <h3 className="card-title">
                                  Start Date <span>{sdate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  End Date <span>{edate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  Entry Last Date <span>{ddate}</span>
                                </h3>
                              </div>
                              <div className="col-md-3 col-sm-6 border-left">
                                <h3 className="card-title">
                                  Sports <span>{sports}</span>
                                </h3>
                              </div>
                            </div>
                            <div className="schoolname border-bottom px-3">
                              <h3>
                                School Name :{" "}
                                <b className="font-weight-bold text-muted">
                                  {school}
                                </b>
                              </h3>
                            </div>
                            <div className="row datedetails  mx-0">
                              {agetype === 1 && (
                                <div className="col-md-12">
                                  <h3 className="card-title">
                                    Class :{" "}
                                    <b className="font-weight-bold text-muted">
                                      {tourcls}
                                    </b>
                                  </h3>
                                </div>
                              )}

                              {agetype === 2 &&
                                sports.toUpperCase() !== "ATHLETICS" && (
                                  <div className="col-md-12">
                                    <h3 className="card-title">
                                      Age Categories :{" "}
                                      <b className="font-weight-bold text-muted">
                                        {agecat}
                                      </b>
                                    </h3>
                                  </div>
                                )}

                              {agetype === 2 &&
                                sports.toUpperCase() === "ATHLETICS" && (
                                  <>
                                    <div className="col-md-6">
                                      <h3 className="card-title">
                                        Age Categories :{" "}
                                        <b className="font-weight-bold text-muted">
                                          {agecat}
                                        </b>
                                      </h3>
                                    </div>
                                    <div className="col-md-6">
                                      <h3 className="card-title">
                                        Events :{" "}
                                        <b className="font-weight-bold text-muted">
                                          {ageevents}
                                        </b>
                                      </h3>
                                    </div>
                                  </>
                                )}
                            </div>

                            <div className="row datedetails mx-0">
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Amount :{" "}
                                  {mfree == "N" && (
                                    <b className="font-weight-bold text-danger">
                                      Rs. {tamt} /-
                                    </b>
                                  )}
                                  {mfree === "Y" && (
                                    <b className="font-weight-bold text-danger">
                                      Free
                                    </b>
                                  )}
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Discount :{" "}
                                  <b className="font-weight-bold text-danger">
                                    {disper} %
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Disc Amt :{" "}
                                  <b className="font-weight-bold text-danger">
                                  Rs. {disamt} /- 
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Total :{" "}
                                  <b className="matchstatus px-2 py-1">
                                  Rs. {amt} /-
                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}

                    <div className="row" id="tournamentbox">
                      <div className="col-md-12">
                        <div className="card bg-none">
                          <div className="card-body p-0">
                            <div className="clearfix pb-3 border-bottom">
                              <div className="float-left">
                                <h1 className="card-title tourheader mb-0">
                                  Player Details
                                </h1>
                              </div>
                            </div>
                            <div className="row datedetails mx-0">
                              <div className="col-md-8">
                                <h3 className="card-title">
                                  Full Name :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {fname}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-4">
                                <h3 className="card-title">
                                  Username :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {uname}
                                  </b>
                                </h3>
                              </div>
                            </div>

                            <div className="row datedetails mx-0">
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Gender :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {gender}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  DOB :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {dob}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Class :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {stuclass}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-3">
                                <h3 className="card-title">
                                  Section :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {stusec}
                                  </b>
                                </h3>
                              </div>
                            </div>

                            <div className="schoolname border-bottom px-3">
                              <h3>
                                School Name :{" "}
                                <b className="font-weight-bold text-muted">
                                  {stuschool}
                                </b>
                              </h3>
                            </div>
                            <div className="row datedetails  mx-0">
                              <div className="col-md-6">
                                <h3 className="card-title">
                                  Contact Number :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {mobno}
                                  </b>
                                </h3>
                              </div>
                              <div className="col-md-6">
                                <h3 className="card-title">
                                  Email :{" "}
                                  <b className="font-weight-bold text-muted">
                                    {email}
                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form className="login">
                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          <button
                            className="form-btn regis bg-success mt-2"
                            onClick={regsubmit}
                            type="submit"
                          >
                            <span>Pay Now</span>
                          </button>
                        </div>
                        {/* <div className="col-lg-6 col-md-8">
                          <div className="con-text regis text-right mt-3">
                            <p className="mb-0">
                              Already have an Account?{" "}
                              <strong>
                                <a onClick={() => navigate("/login")}>
                                  Back to Login
                                </a>
                              </strong>
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {!paypage && !eclosed && (
                <div className="col-md-8 ps-0 tab-100 mob-100">
                  <div className="slidedown border-t"></div>
                  <div className="form slidedown">
                    <div className="text-center background-text">
                      <h1 className="mb-3 font-weight-semibold text-danger">
                        Payment successful !
                      </h1>
                    </div>
                    <div className="background-text">
                      <h4 className="mb-3 font-weight-semibold">Hi {fname},</h4>
                      <div className="successmeg">
                        <p>
                          You have paid the payment successfully for the{" "}
                          <b className="text-success">{tname}</b>. Kindly check
                          your email.
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <a onClick={dashlogin} className="btn btn-gray">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
