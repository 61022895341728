import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

//Referee Profile View
export async function REFprofileview() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/view`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Tournament List
export async function Reftourlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/referee-meet-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Tournament View
export async function Reftourdetail(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/meet-view-details/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Tournament Team list
export async function Refteamlist(id, tid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/teamlist/${id}/${tid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//tournament entry list
export async function Reftourentrylist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/tournament_entries/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Tournament Team Player list
export async function Refteamplayerlist(id,tid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/team-player-list/${id}/${tid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Tournament Team Player list
export async function Refresultlist(id,cid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/referee/results-upload-list/${id}/${cid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response);
  if (error.response.status === 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}