import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  athletictourrefundlist,
  othereventdetails,
  othereventrefundlist,
  tourrefundlist,
  tourviewdetail,
} from "../../../services/superadmin";
import { crmtourviewdetail } from "../../../services/cityregionaladmin";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { coachtourviewdetail } from "../../../services/coach";
export const RefundList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");

  const columnDefs = [
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Refunded Date",
      width: 150,
      field: "refunded_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if(link[1]==="eventrefundlist"){
      othereventdetails(id).then((response) => {
        if (response.status === true ) {
          setTName(response.data.events[0].event_name);
        }
      })
    }else{
      if (localStorage.getItem("Role") === "1") {
        tourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTName(response.data.tournament.meet_name);
          }
        });
      } else if (localStorage.getItem("Role") === "3") {
        crmtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTName(response.data.tournament.meet_name);
          }
        });
      } else if (localStorage.getItem("Role") === "7") {
        coachtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTName(response.data.tournament.meet_name);
          }
        });
      }   
    }
    listall(link[1]);
  }, []);

  function listall(ltype) {
    if(ltype === "refundlist"){
      tourrefundlist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }else if(ltype === "athleticsrefundlist"){
      athletictourrefundlist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }else{
      othereventrefundlist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      })
    }
    
  }
  function backlist() {
    if(urllink === "refundlist"){
      dotracking(
        "back button in refund list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerlistheading
      );
  
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/entryplayerslist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/entryplayerslist/" + id);
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/entryplayerslist/" + id);
      }
    }else if(urllink === "athleticsrefundlist"){
      dotracking(
        "back button in refund list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourplayerlistheading
      );
  
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/athleticsentryplayerslist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/athleticsentryplayerslist/" + id);
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/athleticsentryplayerslist/" + id);
      }
    }else{
      dotracking(
        "back button in refund list for " +
          tname +
          " (" +
          id +
          ") event $$ " +
          trackobj.tourplayerlistheading
      );
  
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/eventsentrylist/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/eventsentrylist/" + id);
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/eventsentrylist/" + id);
      }
    }
    
  }

  const gridRef = useRef();
  const exportlist = useCallback(() => {
    var excelParams = {
      allColumns: false,
      fileName: "Refund List.csv",
    };
    dotracking(
      "export button in refund list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.refundlistheading
    );
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Refund Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Refund Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-md-9">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-right">
                    <a className="btn btn-secondary me-2" onClick={exportlist}>
                      <i className="fa-solid fa-file-export"></i> Export
                    </a>
                    <a onClick={backlist} className="btn btn-gray">
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine ag-style">
                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
