import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Playertourteamlist } from '../../../../services/player';
import dotracking from '../../../trackinglogs';
import trackobj from '../../../trackingpages.json';
export const ListTeam = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        listall();
    }, [])

    function listall() {
        setFilterProduct([]);
        Playertourteamlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function tmlist(vid,vname){
        localStorage.setItem("Page", "2");
        dotracking("team player list icon in team list for " + vname + " (" + vid + ") tournament $$ " + trackobj.teamplaheading);
        navigate("/player/teamplayerlist/" + vid)
    }
    return (
        <Fragment>
            <div className="side-app">
                <div className="page-header">
                    <div className="breadcrumb">
                        <h4 className="mb-0">My Teams</h4>
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a >Tournament</a></li>
                        <li className="breadcrumb-item active" aria-current="page">My Teams</li>
                    </ol>
                </div>


                <div className="row">
                    <div className="col-12">
                        <form className="card mt-0">
                            <div className="card-body">
                                <div className="row teamslist mt-4">
                                    {filterProduct.length === 0 &&
                                        < div className="col-md-4">
                                            <div className="card ">
                                                <div className="card-header">
                                                    <h3 className="card-title">No Team Found</h3>

                                                </div>


                                            </div>
                                        </div>
                                    }
                                    {filterProduct.map((data, i) => {
                                        return (
                                            <div className="col-md-4" key={i}>
                                                <div className="card ">
                                                    <div className="card-header">
                                                        <h3 className="card-title">{data.team_name}</h3>

                                                    </div>
                                                    <div className="card-body alignMe">
                                                        <h4 className="h4 mb-0 mt-3"><b>Team Code</b> <span>{data.team_code}</span></h4>
                                                        {/* <h4 className="h4 mb-0 mt-3"><b>No of Players</b> <span>{data.no_of_players}</span></h4> */}
                                                        {/* <h4 className="h4 mb-0 mt-3"><b>Age Category</b> <span>{data.team_age_category}</span></h4> */}
                                                    </div>
                                                    <div className='alignMe border-top'>
                                                        <h4 className="text-center mt-3"><span>{data.meet_name}</span></h4>

                                                    </div>
                                                    <div className="card-footer border-top text-center">
                                                        <a onClick={() => tmlist(data.tournament_meet_id,data.meet_name)}
                                                            className="btn btn-warning btn-sm ml-1" title='Player List'><i className="fa fa-server"></i></a>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                    }

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
