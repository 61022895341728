import React, { useEffect, useState } from "react";
import {
  Playerprofiledetails,
  playertournamentperformancer,
} from "../../../../services/player";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import exportUrl from "../../../../exportUtl";

export const PerformancebyTour = () => {
  const { id } = useParams();
  const [pladet, setPladet] = useState("");
  const [filterProduct, setFilterProduct] = useState([]);
  const [sportProduct, setSportProduct] = useState([]);
  const [sports, setSports] = useState("");
  const [tourdet, setTourDet] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "6") {
      Playerprofiledetails(localStorage.getItem("Name")).then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("user_id", res.data.athletelist.user_id);
          playertournamentperformancer(temp).then((response) => {
            if (response.status === true) {
              if (response.data.length > 0) {
                setPladet(response.data[0].player_details);
                setFilterProduct(response.data[0].data_arr);
                if (response.data[0].data_arr.length > 0) {
                  setTourDet(response.data[0].data_arr[0]);
                  setSports(response.data[0].data_arr[0].meet_name);
                  if (
                    response.data[0].data_arr[0].performance_data.length !== 0
                  ) {
                    setSportProduct(
                      response.data[0].data_arr[0].performance_data
                    );
                  }
                }
              }
            }
          });
        }
      });
    } else {
      const temp = new FormData();
      temp.append("user_id", id);
      playertournamentperformancer(temp).then((response) => {
        if (response.status === true) {
          if (response.data.length > 0) {
            setPladet(response.data[0].player_details);
            setFilterProduct(response.data[0].data_arr);
            if (response.data[0].data_arr.length > 0) {
              setTourDet(response.data[0].data_arr[0]);
              setSports(response.data[0].data_arr[0].meet_name);
              if (response.data[0].data_arr[0].performance_data.length !== 0) {
                setSportProduct(response.data[0].data_arr[0].performance_data);
              }
            }
          }
        }
      });
    }
  }, []);

  function daywise(tspt) {
    setSportProduct([]);
    setSports(tspt);
    setTourDet("");
    if (filterProduct.length > 0) {
      const temp = filterProduct.find((item) => item.meet_name === tspt);
      if (temp !== undefined) {
        setTourDet(temp);
        setSportProduct(temp.performance_data);
      }
    }
  }

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in tournament wise performance for " +
        pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        id +
        ") $$ " +
        trackobj.playerdetheading
    );
    if (localStorage.getItem("PID") === "1") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/viewplayer/" + id);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/viewplayer/" + id);
      }
    }else if (localStorage.getItem("PID") === "2"){
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/tournamentplayer/" + id);
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/tournamentplayer/" + id);
      }
    }else{
      navigate(-1);
    }
  }

  const downloadPDF = () => {
    dotracking(
      "download PDF button in tournament wise performance for " +
        pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        pladet.id +
        ") player $$ " +
        trackobj.tourperfheading
    );

    const pdf = new jsPDF();
    const content = document.getElementById("pdf-content");
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save(pladet.first_name + " Tournament Wise Performance.pdf");
    });
  };

  function perflist() {
    dotracking(
      "overall performance button in view player for " +
      pladet.first_name +
        " (" +
        pladet.username +
        " - " +
        pladet.id +
        ") $$ " +
        trackobj.overperfheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/overallperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/overallperformanceplayer/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/overallperformanceplayer/" + id);
    }else if (localStorage.getItem("Role") === "6") {
      navigate("/player/overallperformanceplayer");
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/overallperformanceplayer/" + id);
    }
  }

  function exportPDF() {
    dotracking(
      "export button in tournament wise performance for " +
        pladet.first_name +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourperfheading
    );
    window.open(exportUrl + "export-performance-by-tournament/0/" + id, "_blank");
    }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Wise Player Performance</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Player</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Wise Player Performance
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-7"></div>
        <div className="col-md-5">
          <div className="text-right">
          <a onClick={perflist} className="btn btn-success me-2">
              Overall Performance
            </a>
            <a onClick={exportPDF} className="btn btn-export me-2">
            <i className="fa-solid fa-file-export"></i> Export
            </a>
            {/* <a onClick={downloadPDF} className="btn btn-export me-2">
              <i className="fa fa-file-pdf"></i> Download PDF
            </a> */}
            <a onClick={backlist} className="btn btn-gray">
              <i className="fe fe-corner-up-left"></i> Back
            </a>
          </div>
        </div>
      </div>
      <div className="row" id="pdf-content">
        <div className="card mt-2">
          <div className="card-body">
            <div className="row tournamentitem">
              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">First Name</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.first_name}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Username (UID)</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.username}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Gender</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.gender}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Date of Birth</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">
                        {moment(pladet.date_of_birth).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Email</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.email_id}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Contact Number</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.mobile_no}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Class</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.class_name}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="details-group">
                  <div className="row">
                    <div className="col-md-4">
                      <b className="text-inverse">Section</b>
                    </div>
                    <div className="col-md-8">
                      <span className="text-inverse">{pladet.section}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {filterProduct.map((data, i) => {
            return (
              <div className="col-md-6" key={i}>
                <div className="card mt-0">
                  <div className="card-body" >
                    <div className="overperf">
                    {data.performance_data.length === 0 && (
                      <h4 className="errortext">
                        Performance will be updated soon
                      </h4>
                    )}
                    <div className="row tournamentitem">
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Tournament Name</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse text-capitalize errortext">
                                {data.meet_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Start Date</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">
                                {moment(data.start_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">End Date</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">
                                {moment(data.end_date).format("DD-MM-YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Sports</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">
                                {data.sports_type}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {data.performance_data.length !== 0 && (
                      <div className="table-responsive">
                        <table className="sp-league-table sp-data-table">
                          <thead className="sp-table-header">
                            <tr role="row">
                              <th>Criteria</th>
                              <th>Performance</th>
                              <th>Rating/Feedback</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.performance_data.map((item, j) => {
                              const getRowColor = () => {
                                return j % 2 === 0 ? "even" : "odd";
                              };
                              return (
                                <>
                                  <tr
                                    className={`sp-row-no-0 ${getRowColor()}`}
                                    key={j}
                                  >
                                    <td className="data-pct">
                                      {item.criteria_name}
                                    </td>
                                    <td className="data-pct">
                                      {item.performance_text}
                                    </td>
                                    {item.rating_number !== 0 && (
                                      <td className="data-pct">
                                        {item.rating_number}
                                      </td>
                                    )}
                                    {item.rating_texts !== "-" && (
                                      <td className="data-pct">
                                        {item.rating_texts}
                                      </td>
                                    )}
                                    {item.rating_texts === "-" &&
                                      item.rating_number === 0 && (
                                        <td className="data-pct">-</td>
                                      )}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    </div>
                   
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="col-12">
          <Tabs
            activeKey={sports}
            // defaultActiveKey={sports}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={daywise}
          >
            {filterProduct.map((data, i) => {
              return (
                <Tab
                  eventKey={data.meet_name}
                  title={data.meet_name}
                  key={i}
                  style={{ textTransform: "capitalize" }}
                >
                  <div className="card mt-0">
                    <div className="card-body">
                      {sportProduct.length === 0 && (
                        <h4 className="errortext">
                          Performance will be updated soon
                        </h4>
                      )}
                      <div className="row tournamentitem">
                        <div className="col-md-12">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-md-2">
                                <b className="text-inverse">Tournament Name</b>
                              </div>
                              <div className="col-md-10">
                                <span className="text-inverse text-capitalize">
                                  {tourdet.meet_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Start Date</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">
                                  {moment(tourdet.start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">End Date</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">
                                  {moment(tourdet.end_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="details-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Sports</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">
                                  {tourdet.sports_type}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {sportProduct.length !== 0 && (
                        <div className="table-responsive">
                          <table className="sp-champion-table sp-data-table">
                            <thead className="sp-table-header">
                              <tr role="row">
                                <th>Criteria</th>
                                <th>Performance</th>
                                <th>Rating/Feedback</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sportProduct.map((item, j) => {
                                const getRowColor = () => {
                                  return j % 2 === 0 ? "even" : "odd";
                                };
                                return (
                                  <>
                                    <tr
                                      className={`sp-row-no-0 ${getRowColor()}`}
                                      key={j}
                                    >
                                      <td className="data-pct">
                                        {item.criteria_name}
                                      </td>
                                      <td className="data-pct">
                                        {item.performance_text}
                                      </td>
                                      {item.rating_number !== 0 && (
                                        <td className="data-pct">
                                          {item.rating_number}
                                        </td>
                                      )}
                                      {item.rating_texts !== "-" && (
                                        <td className="data-pct">
                                          {item.rating_texts}
                                        </td>
                                      )}
                                      {item.rating_texts === "-" &&
                                        item.rating_number === 0 && (
                                          <td className="data-pct">-</td>
                                        )}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </div> */}
      </div>
    </div>
  );
};
