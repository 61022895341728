import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const Sidebar = () => {
  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);
  const [subrole, setSubRole] = useState(false);

  const [masact, setMasAct] = useState(false);
  const [roleact, setRoleAct] = useState(false);
  const [bgact, setBGAct] = useState(false);
  const [slact, setSlAct] = useState(false);
  const [gschact, setGSchAct] = useState(false);

  const [clsact, setClsAct] = useState(false);
  const [secact, setSecAct] = useState(false);
  const [stact, setStAct] = useState(false);
  const [dtact, setDtAct] = useState(false);
  const [cyact, setCyAct] = useState(false);
  const [sportact, setSportAct] = useState(false);
  const [spcatposact, setSpCatPosAct] = useState(false);
  const [tourtypeact, setTourTypeAct] = useState(false);
  const [agecatact, setAgeCatAct] = useState(false);
  const [rejreaact, setRejResAct] = useState(false);
  const [ekeyact, setEKeyAct] = useState(false);
  const [eventact, setEventAct] = useState(false);
  const [eventtypeact, setEventTypeAct] = useState(false);
  const [criteriaact, setCriteriaAct] = useState(false);
  const [perftraact, setPerfTraAct] = useState(false);
  const [tagact, setTagAct] = useState(false);
  const [funact, setFunAct] = useState(false);

  const [plaact, setPlaAct] = useState(false);
  const [addplaact, setAddPlaAct] = useState(false);
  const [allplaact, setAllPlaAct] = useState(false);
  const [blkplaact, setBlkPlaAct] = useState(false);

  const [coaact, setCoaAct] = useState(false);
  const [addcoaact, setAddCoaAct] = useState(false);
  const [allcoaact, setAllCoaAct] = useState(false);


  const [ffunact, setFFunAct] = useState(false);
  const [addfunact, setAddFunAct] = useState(false);
  const [allfunact, setAllFunAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [addtou, setAddTou] = useState(false);
  const [listtou, setListTou] = useState(false);
  const [phototou, setPhotoTou] = useState(false);
  const [achtou, setAchTou] = useState(false);
  const [exttouadd, setExtTouAdd] = useState(false);
  const [exttoulist, setExtTouList] = useState(false);

  const [workact, setWorkAct] = useState(false);
  const [addwork, setAddWork] = useState(false);
  const [listwork, setListWork] = useState(false);

  const [assupact, setAssUpAct] = useState(false);

  const [cityact, setCityAct] = useState(false);
  const [addcityact, setAddCityAct] = useState(false);
  const [listcityact, setListCityAct] = useState(false);

  const [refact, setRefAct] = useState(false);
  const [addrefact, setAddRefAct] = useState(false);
  const [listrefact, setListRefAct] = useState(false);

  const [schact, setSchAct] = useState(false);
  const [addshact, setAddSHAct] = useState(false);
  const [listshact, setListSHAct] = useState(false);

  const [tempact, setTempAct] = useState(false);
  const [etempact, setEmailTempAct] = useState(false);
  const [tourregact, setTourRegAct] = useState(false);
  const [regtempact, setRegTempAct] = useState(false);
  const [forgottempact, setForgotTempAct] = useState(false);
  const [blktempact, setBlkTempAct] = useState(false);
  const [regcrmtemppact, setCRMRegTempAct] = useState(false);
  const [tourcrmtemppact, setCRMTourTempAct] = useState(false);
  const [regschheadtemppact, setSchHeadRegTempAct] = useState(false);
  const [regreftemppact, setRefRegTempAct] = useState(false);
  const [tourreftemppact, setRefTourTempAct] = useState(false);
  const [tourpaytemppact, setTourPayTempAct] = useState(false);
  const [tourschduleact, setTourScheduleAct] = useState(false);
  const [tourremainderact, setTourRemainderAct] = useState(false);
  const [livestreamact, setLiveStreamAct] = useState(false);
  const [tourphotosact, setTourPhotosAct] = useState(false);
  const [downloadcertiact, setDownloadCertiAct] = useState(false);
  const [newpartneract, setNewPartnerAct] = useState(false);
  const [scheadtouract, setScheadTourAct] = useState(false);
  const [mergeplaact, setMergePlayAct] = useState(false);
  const [reglinkact, setRegLinkAct] = useState(false);
  const [sendimageact, setSendImageAct] = useState(false);
  const [sendpaymentconf, setPaymentConf] = useState(false);
  const [sendschtourlink, setSchTourLink] = useState(false);

  const [wtempact, setWTempAct] = useState(false);
  const [wtourregact, setWTourRegAct] = useState(false);
  const [wregtempact, setWRegTempAct] = useState(false);
  const [wforgottempact, setWForgotTempAct] = useState(false);
  const [wblktempact, setWBlkTempAct] = useState(false);
  const [wregcrmtemppact, setWCRMRegTempAct] = useState(false);
  const [wtourcrmtemppact, setWCRMTourTempAct] = useState(false);
  const [wregschheadtemppact, setWSchHeadRegTempAct] = useState(false);
  const [wregreftemppact, setWRefRegTempAct] = useState(false);
  const [wtourreftemppact, setWRefTourTempAct] = useState(false);
  const [wtourpaytemppact, setWTourPayTempAct] = useState(false);
  const [wtourschduleact, setWTourScheduleAct] = useState(false);
  const [wtourremainderact, setWTourRemainderAct] = useState(false);
  const [wlivestreamact, setWLiveStreamAct] = useState(false);
  const [wtourphotosact, setWTourPhotosAct] = useState(false);
  const [wdownloadcertiact, setWDownloadCertiAct] = useState(false);
  const [wnewpartneract, setWNewPartnerAct] = useState(false);
  const [wscheadtouract, setWScheadTourAct] = useState(false);
  const [wmergeplaact, setWMergePlayAct] = useState(false);
  const [wreglinkact, setWRegLinkAct] = useState(false);
  const [wsendimageact, setWSendImageAct] = useState(false);
  const [wsendpaymentconf, setWPaymentConf] = useState(false);
  const [wsendschtourlink, setWSchTourLink] = useState(false);

  const [eventemailact, setEventEmailAct] = useState(false);
  const [eventregisteract, setEventRegisterAct] = useState(false);
  const [regeventact, setRegEventAct] = useState(false);
  const [eventwappact, setEventWAppAct] = useState(false);
  const [weventregisteract, setWEventRegisterAct] = useState(false);
  const [wregeventact, setWRegEventAct] = useState(false);

  const [dcact, setDCAct] = useState(false);
  const [alldcact, setAllDCAct] = useState(false);
  const [alltimeact, setTimeAct] = useState(false);

  const [payact, setPayAct] = useState(false);
  const [allpayact, setAllPayAct] = useState(false);

  const [upact, setUpAct] = useState(false);
  const [baseact, setBaseAct] = useState(false);
  const [steamact, setSTeamAct] = useState(false);
  const [upachact, setUPAchAct] = useState(false);

  const [bnact, setBnAct] = useState(false);

  const [trackact, setTrackAct] = useState(false);

  const [cmsact, setCMSAct] = useState(false);
  const [schcmsact, setSCMSAct] = useState(false);
  const [funcmsact, setFCMSAct] = useState(false);
  const [gscmsact, setGSCMSAct] = useState(false);
  const [rcmsact, setRCMSAct] = useState(false);

  const [tract, setTrAct] = useState(false);
  const [btract, setBTrAct] = useState(false);
  const [stract, setSTrAct] = useState(false);
  const [ftract, setFTrAct] = useState(false);
  const [gtract, setGTrAct] = useState(false);

  const [chartact, setChartAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setMasAct(false);
    setRoleAct(false);
    setBGAct(false);
    setSlAct(false);
    setGSchAct(false);
    setClsAct(false);
    setSecAct(false);
    setStAct(false);
    setDtAct(false);
    setCyAct(false);
    setSportAct(false);
    setSpCatPosAct(false);
    setTourTypeAct(false);
    setAgeCatAct(false);
    setRejResAct(false);
    setEKeyAct(false);
    setEventAct(false);
    setEventTypeAct(false);
    setCriteriaAct(false);
    setPerfTraAct(false);
    setTagAct(false);
    setFunAct(false);

    setPlaAct(false);
    setAddPlaAct(false);
    setAllPlaAct(false);
    setBlkPlaAct(false);

    setCoaAct(false);
    setAddCoaAct(false);
    setAllCoaAct(false);

    setFFunAct(false);
    setAddFunAct(false);
    setAllFunAct(false);

    setTouAct(false);
    setAddTou(false);
    setListTou(false);
    setPhotoTou(false);
    setAchTou(false);
    setExtTouList(false);
    setExtTouAdd(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setCityAct(false);
    setAddCityAct(false);
    setListCityAct(false);

    setRefAct(false);
    setAddRefAct(false);
    setListRefAct(false);

    setSchAct(false);
    setAddSHAct(false);
    setListSHAct(false);

    setDCAct(false);
    setAllDCAct(false);
    setTimeAct(false);

    setPayAct(false);
    setAllPayAct(false);

    setUpAct(false);
    setBaseAct(false);
    setAssUpAct(false);
    setSTeamAct(false);
    setUPAchAct(false);

    setTempAct(false);
    setEmailTempAct(false);
    setTourRegAct(false);
    setRegTempAct(false);
    setForgotTempAct(false);
    setBlkTempAct(false);
    setCRMRegTempAct(false);
    setCRMTourTempAct(false);
    setSchHeadRegTempAct(false);
    setRefRegTempAct(false);
    setRefTourTempAct(false);
    setTourPayTempAct(false);
    setTourScheduleAct(false);
    setTourRemainderAct(false);
    setLiveStreamAct(false);
    setTourPhotosAct(false);
    setDownloadCertiAct(false);
    setNewPartnerAct(false);
    setScheadTourAct(false);
    setMergePlayAct(false);
    setRegLinkAct(false);
    setSendImageAct(false);
    setPaymentConf(false);
    setSchTourLink(false);

    setWTempAct(false);
    setWTourRegAct(false);
    setWRegTempAct(false);
    setWForgotTempAct(false);
    setWBlkTempAct(false);
    setWCRMRegTempAct(false);
    setWCRMTourTempAct(false);
    setWSchHeadRegTempAct(false);
    setWRefRegTempAct(false);
    setWRefTourTempAct(false);
    setWTourPayTempAct(false);
    setWTourScheduleAct(false);
    setWTourRemainderAct(false);
    setWLiveStreamAct(false);
    setWTourPhotosAct(false);
    setWDownloadCertiAct(false);
    setWNewPartnerAct(false);
    setWScheadTourAct(false);
    setWMergePlayAct(false);
    setWRegLinkAct(false);
    setWSendImageAct(false);
    setWPaymentConf(false);
    setWSchTourLink(false);

    setEventEmailAct(false);
    setEventRegisterAct(false);
    setRegEventAct(false);
    setEventWAppAct(false);
    setWEventRegisterAct(false);
    setWRegEventAct(false);

    setBnAct(false);

    setTrackAct(false);
    setTrAct(false);
    setBTrAct(false);
    setSTrAct(false);
    setFTrAct(false);
    setGTrAct(false);

    setCMSAct(false);
    setSCMSAct(false);
    setFCMSAct(false);
    setGSCMSAct(false);
    setRCMSAct(false);

    setChartAct(false);
    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/superadmin/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Master") {
      setMasAct(true);
    } else if (head === "Players") {
      setPlaAct(true);
    } else if (head === "Tournaments") {
      setTouAct(true);
    } else if (head === "OtherEvents") {
      setWorkAct(true);
    } else if (head === "City") {
      setCityAct(true);
    } else if (head === "Referee") {
      setRefAct(true);
    } else if (head === "School") {
      setSchAct(true);
    } else if (head === "Coaches") {
      setCoaAct(true);
    } else if(head === "Funder"){
      setFFunAct(true);
    }else if (head === "Template") {
      setTempAct(true);
    } else if (head === "Datacube") {
      setDCAct(true);
      // dotracking("datacube menu $$ " + trackobj.datacubeheading);

      // navigate("/superadmin/datacube");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    } else if (head === "Payment") {
      setPayAct(true);
      setAllPayAct(true);
      dotracking("payment menu $$ " + trackobj.paylistheading);
      navigate("/superadmin/payment");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Upload") {
      setUpAct(true);
    } else if (head === "Banner") {
      setBnAct(true);
      dotracking("banner menu $$ " + trackobj.bannerlistheading);
      navigate("/superadmin/bannerlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tracking") {
      setTrackAct(true);
      // dotracking("tracking menu $$ " + trackobj.tracklistheading);
      // navigate("/superadmin/trackinglist");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    } else if (head === "Charts") {
      setChartAct(true);
      dotracking("chart menu $$ " + trackobj.chartlist);
      navigate("/superadmin/allcharts");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "CMS") {
      setCMSAct(true);
      // dotracking("CMS menu $$ " + trackobj.cmsheading);
      // navigate("/superadmin/cmsdetails");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    }
  }

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);

    setMasAct(false);
    setRoleAct(false);
    setBGAct(false);
    setSlAct(false);
    setGSchAct(false);
    setClsAct(false);
    setSecAct(false);
    setStAct(false);
    setDtAct(false);
    setCyAct(false);
    setSportAct(false);
    setSpCatPosAct(false);
    setTourTypeAct(false);
    setAgeCatAct(false);
    setRejResAct(false);
    setEKeyAct(false);
    setEventAct(false);
    setEventTypeAct(false);
    setCriteriaAct(false);
    setPerfTraAct(false);
    setTagAct(false);
    setFunAct(false);

    setPlaAct(false);
    setAddPlaAct(false);
    setAllPlaAct(false);
    setBlkPlaAct(false);

    setCoaAct(false);
    setAddCoaAct(false);
    setAllCoaAct(false);

    setFFunAct(false);
    setAddFunAct(false);
    setAllFunAct(false);

    setTouAct(false);
    setAddTou(false);
    setListTou(false);
    setPhotoTou(false);
    setAchTou(false);
    setExtTouList(false);
    setExtTouAdd(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setCityAct(false);
    setAddCityAct(false);
    setListCityAct(false);

    setRefAct(false);
    setAddRefAct(false);
    setListRefAct(false);

    setSchAct(false);
    setAddSHAct(false);
    setListSHAct(false);

    setDCAct(false);
    setAllDCAct(false);
    setTimeAct(false);

    setPayAct(false);
    setAllPayAct(false);

    setUpAct(false);
    setBaseAct(false);
    setAssUpAct(false);
    setSTeamAct(false);
    setUPAchAct(false);

    setTempAct(false);
    setEmailTempAct(false);
    setTourRegAct(false);
    setRegTempAct(false);
    setForgotTempAct(false);
    setBlkTempAct(false);
    setCRMRegTempAct(false);
    setCRMTourTempAct(false);
    setSchHeadRegTempAct(false);
    setRefRegTempAct(false);
    setRefTourTempAct(false);
    setTourPayTempAct(false);
    setTourScheduleAct(false);
    setTourRemainderAct(false);
    setLiveStreamAct(false);
    setTourPhotosAct(false);
    setDownloadCertiAct(false);
    setNewPartnerAct(false);
    setScheadTourAct(false);
    setMergePlayAct(false);
    setRegLinkAct(false);
    setSendImageAct(false);
    setPaymentConf(false);
    setSchTourLink(false);

    setWTempAct(false);
    setWTourRegAct(false);
    setWRegTempAct(false);
    setWForgotTempAct(false);
    setWBlkTempAct(false);
    setWCRMRegTempAct(false);
    setWCRMTourTempAct(false);
    setWSchHeadRegTempAct(false);
    setWRefRegTempAct(false);
    setWRefTourTempAct(false);
    setWTourPayTempAct(false);
    setWTourScheduleAct(false);
    setWTourRemainderAct(false);
    setWLiveStreamAct(false);
    setWTourPhotosAct(false);
    setWDownloadCertiAct(false);
    setWNewPartnerAct(false);
    setWScheadTourAct(false);
    setWMergePlayAct(false);
    setWRegLinkAct(false);
    setWSendImageAct(false);
    setWPaymentConf(false);
    setWSchTourLink(false);

    setEventEmailAct(false);
    setEventRegisterAct(false);
    setRegEventAct(false);
    setEventWAppAct(false);
    setWEventRegisterAct(false);
    setWRegEventAct(false);

    setBnAct(false);
    setTrackAct(false);
    setTrAct(false);
    setBTrAct(false);
    setSTrAct(false);
    setFTrAct(false);
    setGTrAct(false);

    setChartAct(false);

    setCMSAct(false);
    setSCMSAct(false);
    setFCMSAct(false);
    setGSCMSAct(false);
    setRCMSAct(false);

    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/superadmin/dashboard");
    } else if (head === "tournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/superadmin/tournamentdetail/" + id);
    } else if (head === "othereventdetails") {
      setSubAct(true);
      setDashboard(true);
      navigate("/superadmin/othereventdetails/" + id);
    } else if (head === "rolelist") {
      setMasAct(true);
      setRoleAct(true);
      if (type === true) {
        dotracking("roles in setup/master menu $$ " + trackobj.rolelistheading);
      }
      navigate("/superadmin/rolelist");
    } else if (head === "bloodgrouplist") {
      setMasAct(true);
      setBGAct(true);
      if (type === true) {
        dotracking(
          "bloodgroup in setup/master menu $$ " + trackobj.bloodlistheading
        );
      }
      navigate("/superadmin/bloodgrouplist");
    } else if (head === "schoollist") {
      setMasAct(true);
      setSlAct(true);
      if (type === true) {
        dotracking(
          "school in setup/master menu $$ " + trackobj.schoollistheading
        );
      }
      navigate("/superadmin/schoollist");
    } else if (head === "groupschoollist") {
      setMasAct(true);
      setGSchAct(true);
      if (type === true) {
        dotracking(
          "group of school in setup/master menu $$ " +
            trackobj.addgroupschoollist
        );
      }
      navigate("/superadmin/groupschoollist");
    } else if (head === "groupschools") {
      setMasAct(true);
      setGSchAct(true);
      navigate("/superadmin/groupschools/" + id);
    } else if (head === "classlist") {
      setMasAct(true);
      setClsAct(true);
      if (type === true) {
        dotracking(
          "class in setup/master menu $$ " + trackobj.classlistheading
        );
      }
      navigate("/superadmin/classlist");
    } else if (head === "statelist") {
      setMasAct(true);
      setStAct(true);
      if (type === true) {
        dotracking(
          "state in setup/master menu $$ " + trackobj.statelistheading
        );
      }
      navigate("/superadmin/statelist");
    } else if (head === "districtlist") {
      setMasAct(true);
      setDtAct(true);
      if (type === true) {
        dotracking(
          "district in setup/master menu $$ " + trackobj.distlistheading
        );
      }
      navigate("/superadmin/districtlist");
    } else if (head === "citylist") {
      setMasAct(true);
      setCyAct(true);
      if (type === true) {
        dotracking("city in setup/master menu $$ " + trackobj.citylistheading);
      }
      navigate("/superadmin/citylist");
    } else if (head === "sportslist") {
      setMasAct(true);
      setSportAct(true);
      if (type === true) {
        dotracking(
          "sports in setup/master menu $$ " + trackobj.sportlistheading
        );
      }
      navigate("/superadmin/sportslist");
      // } else if (head === "sportscategorypositionlist") {
      //   setMasAct(true);
      //   setSpCatPosAct(true);
      //   if (type === true){
      //     dotracking("sports category position in setup/master menu");
      //   }
      //   navigate("/superadmin/sportscategorypositionlist");
    } else if (head === "tournamenttypelist") {
      setMasAct(true);
      setTourTypeAct(true);
      if (type === true) {
        dotracking(
          "tournament type in setup/master menu $$ " + trackobj.typelistheading
        );
      }
      navigate("/superadmin/tournamenttypelist");
    } else if (head === "agecategorylist") {
      setMasAct(true);
      setAgeCatAct(true);
      if (type === true) {
        dotracking(
          "age category in setup/master menu $$ " + trackobj.agecatlistheading
        );
      }
      navigate("/superadmin/agecategorylist");
    } else if (head === "rejectedreasonlist") {
      setMasAct(true);
      setRejResAct(true);
      if (type === true) {
        dotracking(
          "rejected reason in setup/master menu $$ " +
            trackobj.reasonlistheading
        );
      }
      navigate("/superadmin/rejectedreasonlist");
    } else if (head === "emailkeyword") {
      setMasAct(true);
      setEKeyAct(true);
      if (type === true) {
        dotracking(
          "template keyword in setup/master menu $$ " +
            trackobj.keywordlistheading
        );
      }
      navigate("/superadmin/emailkeyword");
    } else if (head === "eventlist") {
      setMasAct(true);
      setEventAct(true);
      if (type === true) {
        dotracking(
          "events in setup/master menu $$ " + trackobj.eventlistheading
        );
      }
      navigate("/superadmin/eventlist");
    } else if (head === "eventtypelist") {
      setMasAct(true);
      setEventTypeAct(true);
      if (type === true) {
        dotracking(
          "event type in setup/master menu $$ " + trackobj.eventtypelistheading
        );
      }
      navigate("/superadmin/eventtypelist");
    } else if (head === "criterialist") {
      setMasAct(true);
      setCriteriaAct(true);
      if (type === true) {
        dotracking(
          "performance criteria in setup/master menu $$ " +
            trackobj.criterialistheading
        );
      }
      navigate("/superadmin/criterialist");
    } else if (head === "tagslist") {
      setMasAct(true);
      setTagAct(true);
      if (type === true) {
        dotracking("tags in setup/master menu $$ " + trackobj.addtaglist);
      }
      navigate("/superadmin/tagslist");
    } else if (head === "funderlist") {
      setMasAct(true);
      setFunAct(true);
      if (type === true) {
        dotracking("funder in setup/master menu $$ " + trackobj.addfunderlist);
      }
      navigate("/superadmin/funderlist");
    } else if (head === "funderschools") {
      setMasAct(true);
      setFunAct(true);
      navigate("/superadmin/funderschools/" + id);
    } else if (head === "performancelist") {
      setMasAct(true);
      setPerfTraAct(true);
      if (type === true) {
        dotracking(
          "performance tracking in setup/master menu $$ " +
            trackobj.criterialistheading
        );
      }
      navigate("/superadmin/performancelist");
    } else if (head === "addotherevent") {
      setWorkAct(true);
      setAddWork(true);
      if (type === true) {
        dotracking(
          "add other events in other events menu $$ " + trackobj.workaddheading
        );
      }
      navigate("/superadmin/addotherevent");
    } else if (head === "othereventlist") {
      setWorkAct(true);
      setListWork(true);
      if (type === true) {
        dotracking(
          "list other events in other events menu $$ " +
            trackobj.worklistheading
        );
      }
      navigate("/superadmin/othereventlist");
    } else if (head === "eventsendregisterlink") {
      setWorkAct(true);
      setListWork(true);

      navigate("/superadmin/eventsendregisterlink/" + id);
    } else if (head === "eventsentrylist") {
      setWorkAct(true);
      setListWork(true);
      navigate("/superadmin/eventsentrylist/" + id);
    } else if (head === "othereventdetail") {
      setWorkAct(true);
      setListWork(true);
      navigate("/superadmin/othereventdetail/" + id);
    } else if (head === "editotherevent") {
      setWorkAct(true);
      setListWork(true);
      navigate("/superadmin/editotherevent/" + id);
    } else if (head === "addtournament") {
      setTouAct(true);
      setAddTou(true);
      if (type === true) {
        dotracking(
          "add tournament in tournament menu $$ " + trackobj.touraddheading
        );
      }
      navigate("/superadmin/addtournament");
    } else if (head === "athleticsagecategory") {
      setTouAct(true);
      setAddTou(true);
      navigate("/superadmin/athleticsagecategory/" + id);
    } else if (head === "tournamentagecategory") {
      setTouAct(true);
      setAddTou(true);
      navigate("/superadmin/tournamentagecategory/" + id);
    } else if (head === "addplayers") {
      setPlaAct(true);
      setAddPlaAct(true);
      if (type === true) {
        dotracking(
          "add players in player menu $$ " + trackobj.addplayerheading
        );
      }
      navigate("/superadmin/addplayers");
    } else if (head === "allplayers") {
      setPlaAct(true);
      setAllPlaAct(true);
      if (type === true) {
        dotracking(
          "all players in player menu $$ " + trackobj.playerlistheading
        );
      }
      navigate("/superadmin/allplayers");
    } else if (head === "editplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/superadmin/editplayer/" + id);
    } else if (head === "blockedplayers") {
      setPlaAct(true);
      setBlkPlaAct(true);
      if (type === true) {
        dotracking(
          "blocked players in player menu $$ " + trackobj.blockedplalistheading
        );
      }
      navigate("/superadmin/blockedplayers");
    } else if (head === "viewplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/superadmin/viewplayer/" + id);
    } else if (head === "overallperformanceplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/superadmin/overallperformanceplayer/" + id);
    } else if (head === "viewperformanceplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/superadmin/viewperformanceplayer/" + id);
    } else if (head === "addcoach") {
      setCoaAct(true);
      setAddCoaAct(true);
      if (type === true) {
        dotracking(
          "add coaches in coaches menu $$ " + trackobj.addcoachheading
        );
      }
      navigate("/superadmin/addcoach");
    } else if (head === "coacheslist") {
      setCoaAct(true);
      setAllCoaAct(true);
      if (type === true) {
        dotracking(
          "all coaches in coaches menu $$ " + trackobj.coachlistheading
        );
      }
      navigate("/superadmin/coacheslist");
    } else if (head === "coachdetail") {
      setCoaAct(true);
      setAllCoaAct(true);
      navigate("/superadmin/coachdetail/" + id);
    } else if (head === "viewcoaches") {
      setCoaAct(true);
      setAllCoaAct(true);
      navigate("/superadmin/viewcoaches/" + id);
    } else if (head === "addfunder") {
      setFFunAct(true);
      setAddFunAct(true);
      if (type === true) {
        dotracking(
          "add funder in funder menu $$ " + trackobj.addfunheading
        );
      }
      navigate("/superadmin/addfunder");
    } else if (head === "listfunder") {
      setFFunAct(true);
      setAllFunAct(true);
      if (type === true) {
        dotracking(
          "all funder in funder menu $$ " + trackobj.funlistheading
        );
      }
      navigate("/superadmin/listfunder");
    } else if (head === "funderdetail") {
      setFFunAct(true);
      setAllFunAct(true);
      navigate("/superadmin/funderdetail/" + id);
    } else if (head === "viewfunder") {
      setFFunAct(true);
      setAllFunAct(true);
      navigate("/superadmin/viewfunder/" + id);
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setListTou(true);
      if (type === true) {
        dotracking(
          "tournament list in tournament menu $$ " + trackobj.tourlistheading
        );
      }
      navigate("/superadmin/tournamentlist");
    } else if (head === "schooldiscountlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/schooldiscountlist/" + id);
    } else if (head === "tournamentschoollist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentschoollist/" + id);
    } else if (head === "interschoolpaymentlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/interschoolpaymentlist/" + id + "/" + tid);
    } else if (head === "tournamentplayer") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentplayer/" + id);
    } else if (head === "importpool") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/importpool/" + id);
    } else if (head === "importfixture") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/importfixture/" + id + "/" + eid + "/" + tid);
    } else if (head === "teamlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/teamlist/" + id + "/" + tid);
    } else if (head === "interteamlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/interteamlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "teamcreate") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/teamcreate/" + id + "/" + eid);
    } else if (head === "interteamcreate") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/interteamcreate/" + id + "/" + eid + "/" + sid);
    } else if (head === "teamdetails") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/teamdetails/" + id + "/" + tid + "/" + eid);
    } else if (head === "interteamdetails") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/interteamdetails/" + id + "/" + tid + "/" + eid);
    } else if (head === "pointslist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/pointslist/" + id + "/" + eid);
    } else if (head === "pointsentry") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/pointsentry/" + id + "/" + eid);
    } else if (head === "categorylist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/categorylist/" + id);
    } else if (head === "intercategorylist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/intercategorylist/" + id + "/" + tid);
    } else if (head === "performancelist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/performancelist/" + id + "/" + eid);
    } else if (head === "addperformance") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/addperformance/" + id + "/" + eid);
    } else if (head === "performanceplayerphotoedit") {
      setTouAct(true);
      setListTou(true);
      navigate(
        "/superadmin/performanceplayerphotoedit/" + id + "/" + tid + "/" + eid
      );
    } else if (head === "teamplayercreate") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/teamplayercreate/" + id + "/" + tid + "/" + eid);
    } else if (head === "interteamplayercreate") {
      setTouAct(true);
      setListTou(true);
      navigate(
        "/superadmin/interteamplayercreate/" + id + "/" + tid + "/" + eid
      );
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/teamplayerlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "interteamplayerlist") {
      setTouAct(true);
      setListTou(true);
      navigate(
        "/superadmin/interteamplayerlist/" +
          id +
          "/" +
          tid +
          "/" +
          eid +
          "/" +
          sid
      );
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentlist");
    } else if (head === "tournamentdetails") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentdetails/" + id);
    } else if (head === "sendregisteration") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/sendregisteration/" + id);
    } else if (head === "resultupload") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/resultupload/" + id + "/" + eid);
    } else if (head === "overallchampionlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/individualchampionlist/" + id);
    } else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "athleticsresultupload") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/athleticsresultupload/" + id);
    } else if (head === "athleticsresults") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/athleticsresults/" + id);
    } else if (head === "results") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/results/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/resultlist/" + id + "/" + eid);
    } else if (head === "photoupload") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/photoupload/" + id);
    } else if (head === "highlights") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/highlights/" + id);
    } else if (head === "leaderboard") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/leaderboard/" + id);
    } else if (head === "uploadcertificate") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/uploadcertificate/" + id);
    } else if (head === "analytics") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/analytics/" + id);
    } else if (head === "certificatelist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/certificatelist/" + id);
    } else if (head === "poollist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/poollist/" + id + "/" + eid);
    } else if (head === "scheduleteam") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/scheduleteam/" + id + "/" + tid + "/" + eid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/schedulelist/" + id + "/" + eid);
    } else if (head === "scheduleallocate") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/scheduleallocate/" + id + "/" + eid);
    } else if (head === "tournamentagecategorylist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentagecategorylist/" + id);
    } else if (head === "tournamentclassagecategorylist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/tournamentclassagecategorylist/" + id);
    } else if (head === "agecategorybyevent") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/agecategorybyevent/" + id + "/" + eid);
    } else if (head === "playerlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/playerlist/" + id + "/" + eid);
    } else if (head === "addathletes") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/addathletes/" + id + "/" + eid);
    } else if (head === "summary") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/summary/" + id + "/" + eid);
    } else if (head === "confirmation") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/confirmation/" + id + "/" + eid);
    } else if (head === "offlineentry") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/offlineentry/" + id);
    } else if (head === "bibupload") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/bibupload/" + id);
    } else if (head === "orderofeventupload") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/orderofeventupload/" + id);
    } else if (head === "orderofeventlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/orderofeventlist/" + id);
    } else if (head === "eventstartlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/eventstartlist/" + id + "/" + eid);
    } else if (head === "athleticschoollist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/athleticschoollist/" + id);
    } else if (head === "entryplayerslist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/entryplayerslist/" + id);
    } else if (head === "refundlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/refundlist/" + id);
    } else if (head === "eventrefundlist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/eventrefundlist/" + id);
    } else if (head === "athleticsentryplayerslist") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/athleticsentryplayerslist/" + id);
    } else if (head === "edittournament") {
      setTouAct(true);
      setListTou(true);
      navigate("/superadmin/edittournament/" + id);
    } else if (head === "photogallery") {
      setTouAct(true);
      setPhotoTou(true);
      if (type === true) {
        dotracking(
          "photo/gallery in tournament menu $$ " + trackobj.photoheading
        );
      }
      navigate("/superadmin/photogallery");
    } else if (head === "photos") {
      setTouAct(true);
      setPhotoTou(true);
      navigate("/superadmin/photos/" + id);
    } else if (head === "addcityregional") {
      setCityAct(true);
      setAddCityAct(true);
      if (type === true) {
        dotracking(
          "add city manager in city manager menu $$ " + trackobj.crmaddheading
        );
      }
      navigate("/superadmin/addcityregional");
    } else if (head === "cityregionallist") {
      setCityAct(true);
      setListCityAct(true);
      if (type === true) {
        dotracking(
          "city manager list in city manager menu $$ " + trackobj.crmlistheading
        );
      }
      navigate("/superadmin/cityregionallist");
    } else if (head === "schoolmanager") {
      setCityAct(true);
      setListCityAct(true);
      navigate("/superadmin/schoolmanager/" + id);
    } else if (head === "fundermanager") {
      setCityAct(true);
      setListCityAct(true);
      navigate("/superadmin/fundermanager/" + id);
    } else if (head === "groupschoolmanager") {
      setCityAct(true);
      setListCityAct(true);
      navigate("/superadmin/groupschoolmanager/" + id);
    } else if (head === "cityregionaldetail") {
      setCityAct(true);
      setListCityAct(true);
      navigate("/superadmin/cityregionaldetail/" + id);
    } else if (head === "citymanagerdetail") {
      setCityAct(true);
      setListCityAct(true);
      navigate("/superadmin/citymanagerdetail/" + id);
    } else if (head === "addreferee") {
      setRefAct(true);
      setAddRefAct(true);
      if (type === true) {
        dotracking(
          "add referee in referee menu $$ " + trackobj.refereeaddheading
        );
      }
      navigate("/superadmin/addreferee");
    } else if (head === "refereelist") {
      setRefAct(true);
      setListRefAct(true);
      if (type === true) {
        dotracking(
          "referee list in referee menu $$ " + trackobj.refereelistheading
        );
      }
      navigate("/superadmin/refereelist");
    } else if (head === "refereedetail") {
      setRefAct(true);
      setListRefAct(true);
      navigate("/superadmin/refereedetail/" + id);
    } else if (head === "editreferee") {
      setRefAct(true);
      setListRefAct(true);
      navigate("/superadmin/editreferee/" + id);
    } else if (head === "addschoolhead") {
      setSchAct(true);
      setAddSHAct(true);
      if (type === true) {
        dotracking(
          "add schoolhead in school menu $$ " + trackobj.schheadaddheading
        );
      }
      navigate("/superadmin/addschoolhead");
    } else if (head === "schoolheadlist") {
      setSchAct(true);
      setListSHAct(true);
      if (type === true) {
        dotracking(
          "schoolhead list in school menu $$ " + trackobj.schheadlistheading
        );
      }
      navigate("/superadmin/schoolheadlist");
    } else if (head === "schoolheaddetail") {
      setSchAct(true);
      setListSHAct(true);
      navigate("/superadmin/schoolheaddetail/" + id);
    } else if (head === "schoolheadview") {
      setSchAct(true);
      setListSHAct(true);
      navigate("/superadmin/schoolheadview/" + id);
    } else if (head === "baseupload") {
      setUpAct(true);
      setBaseAct(true);
      if (type === true) {
        dotracking(
          "base upload in upload menu $$ " + trackobj.baseuploadheading
        );
      }
      navigate("/superadmin/baseupload");
    } else if (head === "assessmentupload") {
      setUpAct(true);
      setAssUpAct(true);
      if (type === true) {
        dotracking(
          "assessment upload in upload menu $$ " + trackobj.assdateheading
        );
      }
      navigate("/superadmin/assessmentupload");
    } else if (head === "assessmentdetail") {
      setUpAct(true);
      setAssUpAct(true);
      navigate("/superadmin/assessmentdetail/" + id);
    } else if (head === "assessmentplayerdetails") {
      setUpAct(true);
      setAssUpAct(true);
      navigate("/superadmin/assessmentplayerdetails/" + id + "/" + eid);
    } else if (head === "schoolteamlist") {
      setUpAct(true);
      setSTeamAct(true);
      if (type === true) {
        dotracking(
          "school team list in upload menu $$ " + trackobj.schteamlistheading
        );
      }
      navigate("/superadmin/schoolteamlist");
    } else if (head === "schoolteamupload") {
      setUpAct(true);
      setSTeamAct(true);
      navigate("/superadmin/schoolteamupload");
    } else if (head === "datacube") {
      setDCAct(true);
      setAllDCAct(true);
      if (type === true) {
        dotracking(
          "all datacube in datacube menu $$ " + trackobj.datacubeheading
        );
      }
      navigate("/superadmin/datacube");
    } else if (head === "timespend") {
      setDCAct(true);
      setTimeAct(true);
      if (type === true) {
        dotracking(
          "overall time spend in datacube menu $$ " +
            trackobj.overalltimeheading
        );
      }
      navigate("/superadmin/timespend");
    } else if (head === "payment") {
      setPayAct(true);
      setAllPayAct(true);
      navigate("/superadmin/payment");
    } else if (head === "paymentdetail") {
      setPayAct(true);
      setAllPayAct(true);
      navigate("/superadmin/paymentdetail/" + id);
    } else if (head === "tournamentregister") {
      setTempAct(true);
      setEmailTempAct(true);
      setTourRegAct(true);
      if (type === true) {
        dotracking(
          "tournament register in email template menu $$ " +
            trackobj.tourregtemp +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentregister");
    } else if (head === "registeration") {
      setTempAct(true);
      setEmailTempAct(true);
      setRegTempAct(true);
      if (type === true) {
        dotracking(
          "registeration in email template menu $$ " +
            trackobj.regtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/registeration");
    } else if (head === "forgotpassword") {
      setTempAct(true);
      setEmailTempAct(true);
      setForgotTempAct(true);
      if (type === true) {
        dotracking(
          "forgot password in email template menu $$ " +
            trackobj.forgottempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/forgotpassword");
    } else if (head === "blocked") {
      setTempAct(true);
      setEmailTempAct(true);
      setBlkTempAct(true);
      if (type === true) {
        dotracking(
          "blocked in email template menu $$ " +
            trackobj.blktempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/blocked");
    } else if (head === "citymanagerregister") {
      setTempAct(true);
      setEmailTempAct(true);
      setCRMRegTempAct(true);
      if (type === true) {
        dotracking(
          "city manager register in email template menu $$ " +
            trackobj.crmregtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/citymanagerregister");
    } else if (head === "citymanagerassign") {
      setTempAct(true);
      setEmailTempAct(true);
      setCRMTourTempAct(true);
      if (type === true) {
        dotracking(
          "city manager assign in email template menu $$ " +
            trackobj.crmassigntempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/citymanagerassign");
    } else if (head === "schoolheadregister") {
      setTempAct(true);
      setEmailTempAct(true);
      setSchHeadRegTempAct(true);
      if (type === true) {
        dotracking(
          "school head register in email template menu $$ " +
            trackobj.schheadregtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/schoolheadregister");
    } else if (head === "refereeregister") {
      setTempAct(true);
      setEmailTempAct(true);
      setRefRegTempAct(true);
      if (type === true) {
        dotracking(
          "referee register in email template menu $$ " +
            trackobj.refregtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/refereeregister");
    } else if (head === "refereeassign") {
      setTempAct(true);
      setEmailTempAct(true);
      setRefTourTempAct(true);
      if (type === true) {
        dotracking(
          "referee assign in email template menu $$ " +
            trackobj.refassigntempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/refereeassign");
    } else if (head === "tournamentpayment") {
      setTempAct(true);
      setEmailTempAct(true);
      setTourPayTempAct(true);
      if (type === true) {
        dotracking(
          "payment link in email template menu $$ " +
            trackobj.paylinktempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentpayment");
    } else if (head === "tournamentschedule") {
      setTempAct(true);
      setEmailTempAct(true);
      setTourScheduleAct(true);
      if (type === true) {
        dotracking(
          "tournament schedule in email template menu $$ " +
            trackobj.tourschtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentschedule");
    } else if (head === "tournamentremainder") {
      setTempAct(true);
      setEmailTempAct(true);
      setTourRemainderAct(true);
      if (type === true) {
        dotracking(
          "tournament remainder in email template menu $$ " +
            trackobj.tourremtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentremainder");
    } else if (head === "livestream") {
      setTempAct(true);
      setEmailTempAct(true);
      setLiveStreamAct(true);
      if (type === true) {
        dotracking(
          "livestreaming/highlights in email template menu $$ " +
            trackobj.livetempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/livestream");
    } else if (head === "tournamentphotos") {
      setTempAct(true);
      setEmailTempAct(true);
      setTourPhotosAct(true);
      if (type === true) {
        dotracking(
          "tournament photos in email template menu $$ " +
            trackobj.tourphototempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentphotos");
    } else if (head === "tournamentcertificate") {
      setTempAct(true);
      setEmailTempAct(true);
      setDownloadCertiAct(true);
      if (type === true) {
        dotracking(
          "download certificate in email template menu $$ " +
            trackobj.certtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentcertificate");
    } else if (head === "tournamentpartner") {
      setTempAct(true);
      setEmailTempAct(true);
      setNewPartnerAct(true);
      if (type === true) {
        dotracking(
          "new partner/programs/products in email template menu $$ " +
            trackobj.newpdttempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentpartner");
    } else if (head === "tournamentschoolhead") {
      setTempAct(true);
      setEmailTempAct(true);
      setScheadTourAct(true);
      if (type === true) {
        dotracking(
          "school head after tournament in email template menu $$ " +
            trackobj.schaftertourtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/tournamentschoolhead");
    } else if (head === "mergeplayers") {
      setTempAct(true);
      setEmailTempAct(true);
      setMergePlayAct(true);
      if (type === true) {
        dotracking(
          "merge players in email template menu $$ " +
            trackobj.mergetempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/mergeplayers");
    } else if (head === "registersendlink") {
      setTempAct(true);
      setEmailTempAct(true);
      setRegLinkAct(true);
      if (type === true) {
        dotracking(
          "send registeration link in email template menu $$ " +
            trackobj.sendregtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/registersendlink");
    } else if (head === "sendfilelinks") {
      setTempAct(true);
      setEmailTempAct(true);
      setSendImageAct(true);
      if (type === true) {
        dotracking(
          "send images/video links in email template menu $$ " +
            trackobj.sendvideotempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/sendfilelinks");
    } else if (head === "schoolheadpaymentconfirmation") {
      setTempAct(true);
      setEmailTempAct(true);
      setPaymentConf(true);
      if (type === true) {
        dotracking(
          "school head payment confirmation in email template menu $$ " +
            trackobj.schpayconftempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/schoolheadpaymentconfirmation");
    } else if (head === "schoolheadsendtournament") {
      setTempAct(true);
      setEmailTempAct(true);
      setSchTourLink(true);
      if (type === true) {
        dotracking(
          "send school head tournament link in email template menu $$ " +
            trackobj.sendschtourtempheading +
            trackobj.emailtemp
        );
      }
      navigate("/superadmin/schoolheadsendtournament");
    } else if (head === "whatsapptournamentregister") {
      setTempAct(true);
      setWTempAct(true);
      setWTourRegAct(true);
      if (type === true) {
        dotracking(
          "tournament register in whatsapp template menu $$ " +
            trackobj.tourregtemp +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentregister");
    } else if (head === "whatsappregisteration") {
      setTempAct(true);
      setWTempAct(true);
      setWRegTempAct(true);
      if (type === true) {
        dotracking(
          "registeration in whatsapp template menu $$ " +
            trackobj.regtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappregisteration");
    } else if (head === "whatsappforgotpassword") {
      setTempAct(true);
      setWTempAct(true);
      setWForgotTempAct(true);
      if (type === true) {
        dotracking(
          "forgot password in whatsapp template menu $$ " +
            trackobj.forgottempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappforgotpassword");
    } else if (head === "whatsappblocked") {
      setTempAct(true);
      setWTempAct(true);
      setWBlkTempAct(true);
      if (type === true) {
        dotracking(
          "blocked in whatsapp template menu $$ " +
            trackobj.blktempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappblocked");
    } else if (head === "whatsappcitymanagerregister") {
      setTempAct(true);
      setWTempAct(true);
      setWCRMRegTempAct(true);
      if (type === true) {
        dotracking(
          "city manager register in whatsapp template menu $$ " +
            trackobj.crmregtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappcitymanagerregister");
    } else if (head === "whatsappcitymanagerassign") {
      setTempAct(true);
      setWTempAct(true);
      setWCRMTourTempAct(true);
      if (type === true) {
        dotracking(
          "city manager assign in whatsapp template menu $$ " +
            trackobj.crmassigntempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappcitymanagerassign");
    } else if (head === "whatsappschoolheadregister") {
      setTempAct(true);
      setWTempAct(true);
      setWSchHeadRegTempAct(true);
      if (type === true) {
        dotracking(
          "school head register in whatsapp template menu $$ " +
            trackobj.schheadregtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappschoolheadregister");
    } else if (head === "whatsapprefereeregister") {
      setTempAct(true);
      setWTempAct(true);
      setWRefRegTempAct(true);
      if (type === true) {
        dotracking(
          "referee register in whatsapp template menu $$ " +
            trackobj.refregtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapprefereeregister");
    } else if (head === "whatsapprefereeassign") {
      setTempAct(true);
      setWTempAct(true);
      setWRefTourTempAct(true);
      if (type === true) {
        dotracking(
          "referee assign in whatsapp template menu $$ " +
            trackobj.refassigntempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapprefereeassign");
    } else if (head === "whatsapptournamentpayment") {
      setTempAct(true);
      setWTempAct(true);
      setWTourPayTempAct(true);
      if (type === true) {
        dotracking(
          "payment link in whatsapp template menu $$ " +
            trackobj.paylinktempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentpayment");
    } else if (head === "whatsapptournamentschedule") {
      setTempAct(true);
      setWTempAct(true);
      setWTourScheduleAct(true);
      if (type === true) {
        dotracking(
          "tournament schedule in whatsapp template menu $$ " +
            trackobj.tourschtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentschedule");
    } else if (head === "whatsapptournamentremainder") {
      setTempAct(true);
      setWTempAct(true);
      setWTourRemainderAct(true);
      if (type === true) {
        dotracking(
          "tournament remainder in whatsapp template menu $$ " +
            trackobj.tourremtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentremainder");
    } else if (head === "whatsapplivestream") {
      setTempAct(true);
      setWTempAct(true);
      setWLiveStreamAct(true);
      if (type === true) {
        dotracking(
          "livestreaming/highlights in whatsapp template menu $$ " +
            trackobj.livetempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapplivestream");
    } else if (head === "whatsapptournamentphotos") {
      setTempAct(true);
      setWTempAct(true);
      setWTourPhotosAct(true);
      if (type === true) {
        dotracking(
          "tournament photos in whatsapp template menu $$ " +
            trackobj.tourphototempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentphotos");
    } else if (head === "whatsapptournamentcertificate") {
      setTempAct(true);
      setWTempAct(true);
      setWDownloadCertiAct(true);
      if (type === true) {
        dotracking(
          "download certificate in whatsapp template menu $$ " +
            trackobj.certtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentcertificate");
    } else if (head === "whatsapptournamentpartner") {
      setTempAct(true);
      setWTempAct(true);
      setWNewPartnerAct(true);
      if (type === true) {
        dotracking(
          "new partner/programs/products in whatsapp template menu $$ " +
            trackobj.newpdttempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentpartner");
    } else if (head === "whatsapptournamentschoolhead") {
      setTempAct(true);
      setWTempAct(true);
      setWScheadTourAct(true);
      if (type === true) {
        dotracking(
          "school head after tournament in whatsapp template menu $$ " +
            trackobj.schaftertourtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsapptournamentschoolhead");
    } else if (head === "whatsappmergeplayers") {
      setTempAct(true);
      setWTempAct(true);
      setWMergePlayAct(true);
      if (type === true) {
        dotracking(
          "merge players in whatsapp template menu $$ " +
            trackobj.mergetempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappmergeplayers");
    } else if (head === "whatsappregistersendlink") {
      setTempAct(true);
      setWTempAct(true);
      setWRegLinkAct(true);
      if (type === true) {
        dotracking(
          "send registeration link in whatsapp template menu $$ " +
            trackobj.sendregtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappregistersendlink");
    } else if (head === "whatsappsendfilelinks") {
      setTempAct(true);
      setWTempAct(true);
      setWSendImageAct(true);
      if (type === true) {
        dotracking(
          "send images/video links in whatsapp template menu $$ " +
            trackobj.sendvideotempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappsendfilelinks");
    } else if (head === "whatsappschoolheadpaymentconfirmation") {
      setTempAct(true);
      setWTempAct(true);
      setWPaymentConf(true);
      if (type === true) {
        dotracking(
          "school head payment confirmation in whatsapp template menu $$ " +
            trackobj.schpayconftempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappschoolheadpaymentconfirmation");
    } else if (head === "whatsappschoolheadsendtournament") {
      setTempAct(true);
      setWTempAct(true);
      setWSchTourLink(true);
      if (type === true) {
        dotracking(
          "send school head tournament link in whatsapp template menu $$ " +
            trackobj.sendschtourtempheading +
            trackobj.whattemp
        );
      }
      navigate("/superadmin/whatsappschoolheadsendtournament");
    } else if (head === "eventregister") {
      setTempAct(true);
      setEventEmailAct(true);
      setEventRegisterAct(true);
      if (type === true) {
        dotracking(
          "event register in email template menu $$ " +
            trackobj.eventregtemp +
            trackobj.eventemailtemp
        );
      }
      navigate("/superadmin/eventregister");
    } else if (head === "eventregisteration") {
      setTempAct(true);
      setEventEmailAct(true);
      setRegEventAct(true);
      if (type === true) {
        dotracking(
          "event registeration in email template menu $$ " +
            trackobj.eventregisterationtemp +
            trackobj.eventemailtemp
        );
      }
      navigate("/superadmin/eventregisteration");
    } else if (head === "whatsappeventregister") {
      setTempAct(true);
      setEventWAppAct(true);
      setWEventRegisterAct(true);
      if (type === true) {
        dotracking(
          "event register in whatsapp template menu $$ " +
            trackobj.eventregtemp +
            trackobj.eventwhattemp
        );
      }
      navigate("/superadmin/whatsappeventregister");
    } else if (head === "whatsappeventregisteration") {
      setTempAct(true);
      setEventWAppAct(true);
      setWRegEventAct(true);
      if (type === true) {
        dotracking(
          "event registeration in whatsapp template menu $$ " +
            trackobj.eventregisterationtemp +
            trackobj.eventwhattemp
        );
      }
      navigate("/superadmin/whatsappeventregisteration");
    } else if (head === "bannerlist") {
      setBnAct(true);
      navigate("/superadmin/bannerlist");
    } else if (head === "bannerupload") {
      setBnAct(true);
      navigate("/superadmin/bannerupload");
    } else if (head === "editbanner") {
      setBnAct(true);
      navigate("/superadmin/editbanner/" + id);
    } else if (head === "trackinglist") {
      setTrackAct(true);
      setTrAct(true);
      if (type === true) {
        dotracking(
          "tracking list in tracking menu $$ " + trackobj.tracklistheading
        );
      }
      navigate("/superadmin/trackinglist");
    } else if (head === "bannertrackinglist") {
      setTrackAct(true);
      setBTrAct(true);
      if (type === true) {
        dotracking(
          "banner tracking list in tracking menu $$ " +
            trackobj.bannertracklistheading
        );
      }
      navigate("/superadmin/bannertrackinglist");
    } else if (head === "schooltrackinglist") {
      setTrackAct(true);
      setSTrAct(true);
      if (type === true) {
        dotracking(
          "school webpage tracking list in tracking menu $$ " +
            trackobj.schooltracklistheading
        );
      }
      navigate("/superadmin/schooltrackinglist");
    }else if (head === "fundertrackinglist") {
      setTrackAct(true);
      setFTrAct(true);
      if (type === true) {
        dotracking(
          "funder webpage tracking list in tracking menu $$ " +
            trackobj.fundertracklistheading
        );
      }
      navigate("/superadmin/fundertrackinglist");
    }else if (head === "grouptrackinglist") {
      setTrackAct(true);
      setGTrAct(true);
      if (type === true) {
        dotracking(
          "group webpage tracking list in tracking menu $$ " +
            trackobj.grouptracklistheading
        );
      }
      navigate("/superadmin/grouptrackinglist");
    }else if (head === "achievements") {
      setTouAct(true);
      setAchTou(true);
      if (type === true) {
        dotracking(
          "achievements list in tournament menu $$ " +
            trackobj.achievelistheading
        );
      }
      navigate("/superadmin/achievements");
    } else if (head === "achievementlist") {
      setUpAct(true);
      setUPAchAct(true);
      if (type === true) {
        dotracking(
          "achievements list in upload menu $$ " + trackobj.achievelistheading
        );
      }
      navigate("/superadmin/achievementlist");
    } else if (head === "achievementsupload") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/superadmin/achievementsupload");
    } else if (head === "achievementindividual") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/superadmin/achievementindividual");
    } else if (head === "allcharts") {
      setChartAct(true);

      navigate("/superadmin/allcharts");
    } else if (head === "addcms") {
      setCMSAct(true);
      setSCMSAct(true);

      navigate("/superadmin/addcms/" + id);
    } else if (head === "cmsdetails") {
      setCMSAct(true);
      setSCMSAct(true);
      if (type === true) {
        dotracking("add cms in cms menu $$ " + trackobj.cmsheading);
      }
      navigate("/superadmin/cmsdetails");
    } else if (head === "funderdetails") {
      setCMSAct(true);
      setFCMSAct(true);
      if (type === true) {
        dotracking("add funder in cms menu $$ " + trackobj.fcmsheading);
      }
      navigate("/superadmin/funderdetails");
    } else if (head === "addfundercms") {
      setCMSAct(true);
      setFCMSAct(true);

      navigate("/superadmin/addfundercms/" + id);
    } else if (head === "groupofschooldetails") {
      setCMSAct(true);
      setGSCMSAct(true);
      if (type === true) {
        dotracking("add group of schools in cms menu $$ " + trackobj.gscmsheading);
      }
      navigate("/superadmin/groupofschooldetails");
    }else if(head==="allresoucescms"){
      setCMSAct(true);
      setRCMSAct(true);
      if (type === true) {
        dotracking("all resouces in cms menu $$ " + trackobj.addreslist);
      }
      navigate("/superadmin/allresoucescms");
    } else if (head === "addgroupofschoolcms") {
      setCMSAct(true);
      setGSCMSAct(true);
      navigate("/superadmin/addgroupofschoolcms/" + id);
    } else if (head === "addexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      if (type === true) {
        dotracking(
          "add external tournament in tournament menu $$ " +
            trackobj.tourextheading
        );
      }
      navigate("/superadmin/addexternaltournament");
    } else if (head === "editexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      navigate("/superadmin/editexternaltournament/" + id);
    } else if (head === "externaltournamentlist") {
      setTouAct(true);
      setExtTouList(true);
      if (type === true) {
        dotracking(
          "external tournament list in tournament menu $$ " +
            trackobj.listextheading
        );
      }
      navigate("/superadmin/externaltournamentlist");
    } else if (head === "externaltournamentdetails") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/superadmin/externaltournamentdetails/" + id);
    } else if (head === "externaltournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/superadmin/externaltournamentdetail/" + id);
    }
    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  function subsubmenu(head, hide) {
    setSubAct(false);
    setDashboard(false);

    setMasAct(false);
    setRoleAct(false);
    setBGAct(false);
    setSlAct(false);
    setGSchAct(false);
    setClsAct(false);
    setSecAct(false);
    setStAct(false);
    setDtAct(false);
    setCyAct(false);
    setSportAct(false);
    setSpCatPosAct(false);
    setTourTypeAct(false);
    setAgeCatAct(false);
    setRejResAct(false);
    setEventAct(false);
    setTagAct(false);
    setFunAct(false);

    setPlaAct(false);
    setAddPlaAct(false);
    setAllPlaAct(false);
    setBlkPlaAct(false);

    setCoaAct(false);
    setAllCoaAct(false);
    setAddCoaAct(false);

    setTouAct(false);
    setAddTou(false);
    setListTou(false);
    setPhotoTou(false);
    setAchTou(false);

    setCityAct(false);
    setAddCityAct(false);
    setListCityAct(false);

    setRefAct(false);
    setAddRefAct(false);
    setListRefAct(false);

    setSchAct(false);
    setAddSHAct(false);
    setListSHAct(false);

    setDCAct(false);
    setAllDCAct(false);
    setTimeAct(false);

    setPayAct(false);
    setAllPayAct(false);

    setUpAct(false);
    setBaseAct(false);
    setAssUpAct(false);

    setTempAct(false);
    setEmailTempAct(false);
    setTourRegAct(false);
    setRegTempAct(false);
    setForgotTempAct(false);
    setBlkTempAct(false);
    setCRMRegTempAct(false);
    setCRMTourTempAct(false);
    setSchHeadRegTempAct(false);
    setRefRegTempAct(false);
    setRefTourTempAct(false);
    setTourPayTempAct(false);
    setSendImageAct(false);
    setPaymentConf(false);
    setSchTourLink(false);

    setWTempAct(false);
    setWTourRegAct(false);
    setWRegTempAct(false);
    setWForgotTempAct(false);
    setWBlkTempAct(false);
    setWCRMRegTempAct(false);
    setWCRMTourTempAct(false);
    setWSchHeadRegTempAct(false);
    setWRefRegTempAct(false);
    setWRefTourTempAct(false);
    setWTourPayTempAct(false);
    setWSendImageAct(false);
    setWPaymentConf(false);
    setWSchTourLink(false);

    setEventEmailAct(false);
    setEventRegisterAct(false);
    setRegEventAct(false);
    setEventWAppAct(false);
    setWEventRegisterAct(false);
    setWRegEventAct(false);

    if (head === "email") {
      setTempAct(true);
      setEmailTempAct(hide);
    } else if (head === "whatsapp") {
      setTempAct(true);
      setWTempAct(hide);
    } else if (head === "eventemail") {
      setTempAct(true);
      setEventEmailAct(hide);
    } else if (head === "eventwhatsapp") {
      setTempAct(true);
      setEventWAppAct(hide);
    }
  }
  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();
  const { sid } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    if (localStorage.getItem("Subrole") === "1") {
      setSubRole(true);
    } else {
      setSubRole(false);
    }
    submenuactive(link[1], false);
  }, []);
  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Master"
                className={
                  masact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Master")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon ti-blackboard"></i>
                {subrole && <div className="slider-text"> Setup</div>}
                {!subrole && <div className="slider-text"> Master</div>}
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Players"
                className={
                  plaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Players")}
                aria-controls="hor_1_tab_item-3"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Players</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="OtherEvents"
                className={
                  workact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("OtherEvents")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon fa fa-desktop"></i>
                <div className="slider-text">Other Events</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Manager"
                className={
                  cityact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("City")}
                aria-controls="hor_1_tab_item-8"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Manager</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Referee"
                className={
                  refact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Referee")}
                aria-controls="hor_1_tab_item-9"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-convert"></i>
                <div className="slider-text">Referee</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Schools"
                className={
                  schact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("School")}
                aria-controls="hor_1_tab_item-5"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-bank"></i>
                <div className="slider-text">School</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Coaches"
                className={
                  coaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Coaches")}
                aria-controls="hor_1_tab_item-3"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Coaches</div>
              </li>
              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Funder"
                className={
                  ffunact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Funder")}
                aria-controls="hor_1_tab_item-3"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-box"></i>
                <div className="slider-text">Funder</div>
              </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Schools"
                className={
                  tempact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Template")}
                aria-controls="hor_1_tab_item-5"
                role="tab"
              >
                <i className="side-menu__icon fa fa-file-text"></i>
                <div className="slider-text">Templates</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Data Cube"
                className={
                  dcact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Datacube")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon ti-package"></i>
                <div className="slider-text">Data Cube</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Data Cube"
                className={
                  payact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Payment")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-credit-card"></i>
                <div className="slider-text">Payment</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Upload"
                className={
                  upact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Upload")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-upload-outline"></i>
                <div className="slider-text">Upload</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Banner"
                className={
                  bnact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Banner")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-clipboard"></i>
                <div className="slider-text">Banner</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tracking"
                className={
                  trackact
                    ? "active resp-tab-item hor_1"
                    : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tracking")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-th-list"></i>
                <div className="slider-text">Tracking</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="CMS"
                className={
                  cmsact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("CMS")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-document-text"></i>
                <div className="slider-text">CMS</div>
              </li>
              {/*    <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Charts"
                className={
                  chartact
                    ? "active resp-tab-item hor_1"
                    : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Charts")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-chart-line"></i>
                <div className="slider-text">Charts</div>
              </li> */}
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard")}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="ti-blackboard"></i>
                  <div className="slider-text">Masters</div>
                </h2>
                <div
                  className={
                    masact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      {subrole && (
                        <h4 className="font-weight-normal">
                          <i className="ti-blackboard"></i> Setup
                        </h4>
                      )}
                      {!subrole && (
                        <h4 className="font-weight-normal">
                          <i className="ti-blackboard"></i> Masters
                        </h4>
                      )}
                      {subrole && (
                        <a
                          className={
                            roleact ? "slide-item active" : "slide-item"
                          }
                          onClick={() => submenuactive("rolelist", true)}
                        >
                          Roles
                        </a>
                      )}
                      {subrole && (
                        <a
                          className={bgact ? "slide-item active" : "slide-item"}
                          onClick={() => submenuactive("bloodgrouplist", true)}
                        >
                          Blood Group
                        </a>
                      )}

                      <a
                        className={slact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("schoollist", true)}
                      >
                        School
                      </a>
                      <a
                        className={gschact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("groupschoollist", true)}
                      >
                        Group of Schools
                      </a>
                      <a
                        className={clsact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("classlist", true)}
                      >
                        Class
                      </a>
                      {/* <a
                                                className={secact ? "slide-item active" : "slide-item"} onClick={() => submenuactive("sectionlist")}>Section</a> */}
                      <a
                        className={stact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("statelist", true)}
                      >
                        State
                      </a>
                      <a
                        className={dtact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("districtlist", true)}
                      >
                        District
                      </a>
                      <a
                        className={cyact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("citylist", true)}
                      >
                        City
                      </a>
                      <a
                        className={
                          sportact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("sportslist", true)}
                      >
                        Sports
                      </a>
                      {/* <a
                                                className={spcatposact ? "slide-item active" : "slide-item"} onClick={() => submenuactive("sportscategorypositionlist")}>Sports Category Position</a> */}
                      <a
                        className={
                          tourtypeact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("tournamenttypelist", true)
                        }
                      >
                        Tournament Type
                      </a>
                      <a
                        className={
                          agecatact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("agecategorylist", true)}
                      >
                        Age Category
                      </a>
                      {subrole && (
                        <a
                          className={
                            rejreaact ? "slide-item active" : "slide-item"
                          }
                          onClick={() =>
                            submenuactive("rejectedreasonlist", true)
                          }
                        >
                          Rejected Reason
                        </a>
                      )}
                      {subrole && (
                        <a
                          className={
                            ekeyact ? "slide-item active" : "slide-item"
                          }
                          onClick={() => submenuactive("emailkeyword", true)}
                        >
                          Template
                        </a>
                      )}
                      <a
                        className={
                          eventact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("eventlist", true)}
                      >
                        Events
                      </a>
                      <a
                        className={
                          eventtypeact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("eventtypelist", true)}
                      >
                        Event Type
                      </a>
                      <a
                        className={
                          criteriaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("criterialist", true)}
                      >
                        Performance Criteria
                      </a>
                      <a
                        className={
                          perftraact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("performancelist", true)}
                      >
                        Performance Tracking
                      </a>
                      <a
                        className={tagact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("tagslist", true)}
                      >
                        Tags
                      </a>

                      <a
                        className={funact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("funderlist", true)}
                      >
                        Funder
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Players</div>
                </h2>
                <div
                  className={
                    plaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>{" "}
                        Players
                      </h4>
                      <a
                        className={
                          addplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addplayers", true)}
                      >
                        Add Players
                      </a>
                      <a
                        className={
                          allplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("allplayers", true)}
                      >
                        All Players
                      </a>{" "}
                      <a
                        className={
                          blkplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("blockedplayers", true)}
                      >
                        Blocked Players
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>{" "}
                        Tournaments
                      </h4>{" "}
                      <a
                        className={addtou ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addtournament", true)}
                      >
                        Add Tournament
                      </a>{" "}
                      <a
                        className={listtou ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Tournaments List
                      </a>
                      <a
                        className={
                          exttouadd ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("addexternaltournament", true)
                        }
                      >
                        Add External Tournament
                      </a>
                      <a
                        className={
                          exttoulist ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("externaltournamentlist", true)
                        }
                      >
                        External Tournament List
                      </a>
                      <a
                        className={
                          phototou ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("photogallery", true)}
                      >
                        Photo/Gallery
                      </a>
                      <a
                        className={achtou ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("achievements", true)}
                      >
                        Achievements
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-desktop"></i>
                  <div className="slider-text">Other Events</div>
                </h2>
                <div
                  className={
                    workact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-desktop"></i> Other
                        Events
                      </h4>{" "}
                      <a
                        className={addwork ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addotherevent", true)}
                      >
                        Add Other Events
                      </a>{" "}
                      <a
                        className={
                          listwork ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("othereventlist", true)}
                      >
                        Other Events List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-network"></i>
                  <div className="slider-text">Manager</div>
                </h2>
                <div
                  className={
                    cityact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-network"></i>{" "}
                        Manager
                      </h4>{" "}
                      <a
                        className={
                          addcityact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addcityregional", true)}
                      >
                        Add Manager
                      </a>{" "}
                      <a
                        className={
                          listcityact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("cityregionallist", true)}
                      >
                        Manager List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-convert"></i>
                  <div className="slider-text">Referee</div>
                </h2>
                <div
                  className={
                    refact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-convert"></i>{" "}
                        Referee
                      </h4>{" "}
                      <a
                        className={
                          addrefact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addreferee", true)}
                      >
                        Add Referee
                      </a>{" "}
                      <a
                        className={
                          listrefact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("refereelist", true)}
                      >
                        Referee List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-bank"></i>
                  <div className="slider-text">School</div>
                </h2>
                <div
                  className={
                    schact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-bank"></i> School
                      </h4>{" "}
                      <a
                        className={
                          addshact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addschoolhead", true)}
                      >
                        Add School Head
                      </a>{" "}
                      <a
                        className={
                          listshact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolheadlist", true)}
                      >
                        All School Head
                      </a>
                      {/* <a
                                                className="slide-item">Blocked School Head</a> */}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Coaches</div>
                </h2>
                <div
                  className={
                    coaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>{" "}
                        Coaches
                      </h4>
                      <a
                        className={
                          addcoaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addcoach", true)}
                      >
                        Add Coaches
                      </a>
                      <a
                        className={
                          allcoaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("coacheslist", true)}
                      >
                        Coaches List
                      </a>{" "}
                    </div>
                  </div>
                </div>


                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-box"></i>
                  <div className="slider-text">Funder</div>
                </h2>
                <div
                  className={
                    ffunact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-box"></i>{" "}
                        Funder
                      </h4>
                      <a
                        className={
                          addfunact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addfunder", true)}
                      >
                        Add Funder
                      </a>
                      <a
                        className={
                          allfunact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("listfunder", true)}
                      >
                        Funder List
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-file-text"></i>
                  <div className="slider-text">Templates</div>
                </h2>

                <div
                  className={
                    tempact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-file-text"></i>{" "}
                        Templates
                      </h4>
                      <li className={etempact ? "slide is-expanded" : "slide"}>
                        <a
                          className="side-menu__item"
                          data-toggle="slide"
                          onClick={() => subsubmenu("email", !etempact)}
                        >
                          <i className="side-menu__icon fa fa-envelope me-2"></i>
                          <span className="side-menu__label"> Email</span>
                          <i className="angle fa fa-angle-right"></i>
                        </a>
                        <ul className="slide-menu">
                          <li>
                            <a
                              className={
                                tourregact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentregister", true)
                              }
                            >
                              Tournament Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                regtempact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("registeration", true)
                              }
                            >
                              Registration
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                forgottempact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("forgotpassword", true)
                              }
                            >
                              Forgot Password
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                blktempact ? "slide-item active" : "slide-item"
                              }
                              onClick={() => submenuactive("blocked", true)}
                            >
                              Blocked Player
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                regcrmtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("citymanagerregister", true)
                              }
                            >
                              Manager Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourcrmtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("citymanagerassign", true)
                              }
                            >
                              Manager Assign
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                regschheadtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("schoolheadregister", true)
                              }
                            >
                              SchoolHead Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                regreftemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("refereeregister", true)
                              }
                            >
                              Referee Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourreftemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("refereeassign", true)
                              }
                            >
                              Referee Assign
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourpaytemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentpayment", true)
                              }
                            >
                              Payment Link
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourschduleact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentschedule", true)
                              }
                            >
                              Tournament Schedule
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourremainderact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentremainder", true)
                              }
                            >
                              Tournament Reminder
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                livestreamact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() => submenuactive("livestream", true)}
                            >
                              Live Streaming/ Highlights
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tourphotosact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentphotos", true)
                              }
                            >
                              Tournament Photos
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                downloadcertiact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentcertificate", true)
                              }
                            >
                              Download Certificate
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                newpartneract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentpartner", true)
                              }
                            >
                              New Partner/ Programs/Products
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                scheadtouract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("tournamentschoolhead", true)
                              }
                            >
                              Schoolhead After Tournament
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                mergeplaact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("mergeplayers", true)
                              }
                            >
                              Merge Players
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                reglinkact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("registersendlink", true)
                              }
                            >
                              Send Registeration Link
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                sendimageact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("sendfilelinks", true)
                              }
                            >
                              Send Image / video Links
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                sendpaymentconf
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "schoolheadpaymentconfirmation",
                                  true
                                )
                              }
                            >
                              Schoolhead Payment Confirmation
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                sendschtourlink
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("schoolheadsendtournament", true)
                              }
                            >
                              Send Schoolhead Tournament
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={
                          wtempact ? "slide is-expanded mt-2" : "slide mt-2"
                        }
                      >
                        <a
                          className="side-menu__item"
                          data-toggle="slide"
                          onClick={() => subsubmenu("whatsapp", !wtempact)}
                        >
                          <i className="side-menu__icon fa fa-whatsapp me-2"></i>
                          <span className="side-menu__label"> WhatsApp</span>
                          <i className="angle fa fa-angle-right"></i>
                        </a>
                        <ul className="slide-menu">
                          <li>
                            <a
                              className={
                                wtourregact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsapptournamentregister",
                                  true
                                )
                              }
                            >
                              Tournament Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wregtempact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappregisteration", true)
                              }
                            >
                              Registration
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wforgottempact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappforgotpassword", true)
                              }
                            >
                              Forgot Password
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wblktempact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappblocked", true)
                              }
                            >
                              Blocked Player
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wregcrmtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsappcitymanagerregister",
                                  true
                                )
                              }
                            >
                              Manager Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wtourcrmtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappcitymanagerassign", true)
                              }
                            >
                              Manager Assign
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wregschheadtemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsappschoolheadregister",
                                  true
                                )
                              }
                            >
                              SchoolHead Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wregreftemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapprefereeregister", true)
                              }
                            >
                              Referee Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wtourreftemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapprefereeassign", true)
                              }
                            >
                              Referee Assign
                            </a>
                          </li>

                          <li>
                            <a
                              className={
                                wtourpaytemppact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapptournamentpayment", true)
                              }
                            >
                              Payment Link
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wtourschduleact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsapptournamentschedule",
                                  true
                                )
                              }
                            >
                              Tournament Schedule
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wtourremainderact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsapptournamentremainder",
                                  true
                                )
                              }
                            >
                              Tournament Reminder
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wlivestreamact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapplivestream", true)
                              }
                            >
                              Live Streaming/ Highlights
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wtourphotosact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapptournamentphotos", true)
                              }
                            >
                              Tournament Photos
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wdownloadcertiact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsapptournamentcertificate",
                                  true
                                )
                              }
                            >
                              Download Certificate
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wnewpartneract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsapptournamentpartner", true)
                              }
                            >
                              New Partner/ Programs/Products
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wscheadtouract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsapptournamentschoolhead",
                                  true
                                )
                              }
                            >
                              Schoolhead After Tournament
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wmergeplaact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappmergeplayers", true)
                              }
                            >
                              Merge Players
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wreglinkact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappregistersendlink", true)
                              }
                            >
                              Send Registeration Link
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wsendimageact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappsendfilelinks", true)
                              }
                            >
                              Send Image / video Links
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wsendpaymentconf
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsappschoolheadpaymentconfirmation",
                                  true
                                )
                              }
                            >
                              Schoolhead Payment Confirmation
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wsendschtourlink
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsappschoolheadsendtournament",
                                  true
                                )
                              }
                            >
                              Send Schoolhead Tournament
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li
                        className={
                          eventemailact
                            ? "slide is-expanded mt-2"
                            : "slide mt-2"
                        }
                      >
                        <a
                          className="side-menu__item"
                          data-toggle="slide"
                          onClick={() =>
                            subsubmenu("eventemail", !eventemailact)
                          }
                        >
                          <i className="side-menu__icon fa fa-envelope me-2"></i>
                          <span className="side-menu__label"> Event Email</span>
                          <i className="angle fa fa-angle-right"></i>
                        </a>
                        <ul className="slide-menu">
                          <li>
                            <a
                              className={
                                eventregisteract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("eventregister", true)
                              }
                            >
                              Event Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                regeventact ? "slide-item active" : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("eventregisteration", true)
                              }
                            >
                              Registration
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={
                          eventwappact ? "slide is-expanded mt-2" : "slide mt-2"
                        }
                      >
                        <a
                          className="side-menu__item"
                          data-toggle="slide"
                          onClick={() =>
                            subsubmenu("eventwhatsapp", !eventwappact)
                          }
                        >
                          <i className="side-menu__icon fa fa-whatsapp me-2"></i>
                          <span className="side-menu__label">
                            {" "}
                            Event WhatsApp
                          </span>
                          <i className="angle fa fa-angle-right"></i>
                        </a>
                        <ul className="slide-menu">
                          <li>
                            <a
                              className={
                                weventregisteract
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive("whatsappeventregister", true)
                              }
                            >
                              Event Register
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                wregeventact
                                  ? "slide-item active"
                                  : "slide-item"
                              }
                              onClick={() =>
                                submenuactive(
                                  "whatsappeventregisteration",
                                  true
                                )
                              }
                            >
                              Registration
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon ti-package"></i>
                  <div className="slider-text">Data Cube</div>
                </h2>
                <div
                  className={
                    dcact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon ti-package"></i> Data Cube
                      </h4>
                      <a
                        className={
                          alldcact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("datacube", true)}
                      >
                        All Data cube
                      </a>
                      <a
                        className={
                          alltimeact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("timespend", true)}
                      >
                        Overall Time Spend
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-credit-card"></i>
                  <div className="slider-text">Payment</div>
                </h2>
                <div
                  className={
                    payact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-credit-card"></i>{" "}
                        Payment
                      </h4>
                      <a
                        className={
                          allpayact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("payment")}
                      >
                        All Payment
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-upload-outline"></i>
                  <div className="slider-text">Upload</div>
                </h2>
                <div
                  className={
                    upact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-upload-outline"></i>{" "}
                        Upload
                      </h4>{" "}
                      <a
                        className={baseact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("baseupload", true)}
                      >
                        Base Upload
                      </a>{" "}
                      <a
                        className={
                          assupact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("assessmentupload", true)}
                      >
                        Assessment Upload
                      </a>
                      <a
                        className={
                          steamact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolteamlist", true)}
                      >
                        School Team List
                      </a>
                      <a
                        className={
                          upachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementlist", true)}
                      >
                        Achievement Upload
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-clipboard"></i>
                  <div className="slider-text">Banner</div>
                </h2>
                <div
                  className={
                    bnact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-clipboard"></i>{" "}
                        Banner
                      </h4>{" "}
                      <a className={bnact ? "slide-item active" : "slide-item"}>
                        Banner List
                      </a>{" "}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-th-list"></i>
                  <div className="slider-text">Tracking</div>
                </h2>
                <div
                  className={
                    trackact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-th-list"></i>{" "}
                        Tracking
                      </h4>{" "}
                      <a
                        className={tract ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("trackinglist", true)}
                      >
                        Tracking List
                      </a>{" "}
                      <a
                        className={btract ? "slide-item active" : "slide-item"}
                        onClick={() =>
                          submenuactive("bannertrackinglist", true)
                        }
                      >
                        Banner Tracking
                      </a>{" "}
                      <a
                        className={stract ? "slide-item active" : "slide-item"}
                        onClick={() =>
                          submenuactive("schooltrackinglist", true)
                        }
                      >
                        School Webpage
                      </a>{" "}
                      <a
                        className={ftract ? "slide-item active" : "slide-item"}
                        onClick={() =>
                          submenuactive("fundertrackinglist", true)
                        }
                      >
                        Funder Webpage
                      </a>{" "}
                      <a
                        className={gtract ? "slide-item active" : "slide-item"}
                        onClick={() =>
                          submenuactive("grouptrackinglist", true)
                        }
                      >
                        Group Webpage
                      </a>{" "}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-document-text"></i>
                  <div className="slider-text">CMS</div>
                </h2>
                <div
                  className={
                    cmsact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-document-text"></i>{" "}
                        CMS
                      </h4>{" "}
                      <a
                        className={
                          schcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("cmsdetails", true)}
                      >
                        School
                      </a>
                      <a
                        className={
                          funcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("funderdetails", true)}
                      >
                        Funder
                      </a>
                      <a
                        className={
                          gscmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("groupofschooldetails", true)
                        }
                      >
                        Group of Schools
                      </a>

                      <a
                        className={
                          rcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("allresoucescms", true)
                        }
                      >
                        All resources
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-chart-line"></i>
                  <div className="slider-text">Charts</div>
                </h2>
                <div
                  className={
                    chartact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-chart-line"></i>{" "}
                        Charts
                      </h4>{" "}
                      <a
                        className={
                          chartact ? "slide-item active" : "slide-item"
                        }
                      >
                        Statictics
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
