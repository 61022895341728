import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { playerregisteredtour, playertourlist } from "../../../services/player";
import moment from "moment";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const HighlightsPlayer = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value,params.data.meet_name)}
              title="View Highlights"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
  ];

  function viewlist(vid,vname) {
    dotracking("view icon in highlights list for " + vname + " (" + vid + ") tournament $$ " + trackobj.highdetheading);
    navigate("/player/highlightsdetail/" + vid);
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    playertourlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.registered_tour);
      }
    });
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Highlights Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Highlights Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
