import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUrl from "../../../../ImageUrl";
import { mysclperformance, tourdetails } from "../../../../services/register";
import { highlightslist, photoslist } from "../../../../services/superadmin";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback } from "react";
import {
  schoolcertificatelist,
  schooltourstats,
  schprofileview,
} from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import { saveAs } from "file-saver";
import trackobj from "../../../trackingpages.json";
import moment from "moment";
import { achievetourdetails } from "../../../../services/common";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Col, Row } from "react-bootstrap";

export const ViewTourSchHead = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mcode, setMCode] = useState("");
  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [meodate, setMEODate] = useState("");
  const [meddate, setMEDDate] = useState("");
  const [msport, setMSport] = useState("");
  const [mschool, setMSch] = useState([]);
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");
  const [mgate, setMGate] = useState("");
  const [mcat, setMCat] = useState("");
  const [mevent, setMEvent] = useState("");

  const [mleatype, setMLeaque] = useState("");
  const [mcir, setMCir] = useState("");
  const [reptime, setRepTime] = useState("");

  const [ageid, setAID] = useState("");
  const [agetype, setAType] = useState("");
  const [tourcls, setTCls] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);
  const [certlist, setCertList] = useState([]);
  const [certs3, setCertS3] = useState("");

  const [photolist, setPhotoLink] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const [pplist, setPPList] = useState([]);

  function openPLightbox(index, ilist) {
    setCurrentImage(index);
    if (ilist.length !== 0) {
      setPPList(ilist);
    }

    setViewerIsOpen(true);
  }

  const [pshow, setPShow] = useState(false);
  const handlePclose = () => {
    setPShow(false);
    dotracking(
      "close share " +
        shareTitle +
        " in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
  };
  const [shareUrl, setShare] = useState("");
  const [shareTitle, setTitle] = useState("");

  function handlePshow(timg, title) {
    setShare(timg);
    setTitle(title);
    setPShow(true);
    dotracking(
      "share " +
        title +
        " in view tournament for " +
        timg +
        " in " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [sreg, setSReg] = useState("");
  const [nboys, setNBoys] = useState("");
  const [ngirls, setNGirls] = useState("");
  const [ntot, setTotal] = useState("");

  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [bronze, setBronze] = useState(0);
  const [performance, setPerformance] = useState([]);
  const [mtotal, setMTotal] = useState(0);
  const [urllink, setUrlLink] = useState(0);

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (link[1] !== "achievementtournament") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setMName(response.data.tournament.meet_name);
          if (response.data.tournament.location !== null) {
            setMLoc(response.data.tournament.location);
          }
          setRepTime(response.data.tournament.reporting_time);
          setMCode(response.data.tournament.meet_code);
          setMType(response.data.tournament.meet_type);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);
          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMEODate(
            moment(response.data.tournament.entry_open).format("DD-MM-YYYY")
          );
          setMEDDate(
            moment(response.data.tournament.entry_deadline_date).format(
              "DD-MM-YYYY"
            )
          );
          setAID(response.data.tournament.agecat_or_std);
          if (response.data.tournament.agecat_or_std === 1) {
            setAType("By Class / Grade");
          } else if (response.data.tournament.agecat_or_std === 2) {
            setAType("By Date of Birth");
          }
          if (response.data.tournament.agecat_or_std === 1) {
            let arr1 = "";
            if (response.data.age_category.length !== 0) {
              for (let j = 0; j < response.data.age_category.length; j++) {
                if (arr1 === "") {
                  arr1 = response.data.age_category[j].standard_name;
                } else if (arr1 !== "") {
                  arr1 =
                    arr1 + " , " + response.data.age_category[j].standard_name;
                }
              }
            }
            setTCls(arr1);
          }
          setMSDob(
            moment(response.data.tournament.dob_start_date).format("DD-MM-YYYY")
          );
          setMEDob(
            moment(response.data.tournament.dob_end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);

          if (response.data.tournament.gateway_charges === "N") {
            setMGate("No");
          } else if (response.data.tournament.gateway_charges === "Y") {
            setMGate("Yes");
          }
          setMCat(response.data.tournament.categories);
          setMLeaque(response.data.tournament.league_type);
          if (response.data.tournament.circular_file_1 !== null) {
            setMCir(
              // response.data.circular_path +
              ImageUrl +
                "tournament/circular/" +
                response.data.tournament.circular_file_1
            );
          }
        }
      });

      highlightslist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });

      photoslist(id).then((response) => {
        if (response.status === true) {
          if (response.data.tournamentlist.length !== 0) {
            let arr1 = [];
            for (let i = 0; i < response.data.tournamentlist.length; i++) {
              arr1.push({
                id: response.data.tournamentlist[i].id,
                src:
                  response.data.tournamentimage_path +
                  response.data.tournamentlist[i].imagename,
                width: 1,
                height: 1,
              });
            }
            setPhotoLink(arr1);
          }
        }
      });

      schoolcertificatelist(id).then((response) => {
        if (response.status === true) {
          setCertList(response.data.details);
          setCertS3(response.data.s3);
        }
      });

      schprofileview().then((res) => {
        if (res.status === true) {
          const temp = new FormData();
          temp.append("tournament_meet_id", id);
          temp.append("school_id", res.data.school_id);
          schooltourstats(temp).then((response) => {
            if (response.status === true) {
              setSReg(response.data.no_of_students_registered);
              setNBoys(response.data.no_of_boys);
              setNGirls(response.data.no_of_girls);
              setTotal(response.data.total_matches_played);
            }
          });

          const temp1 = new FormData();
          temp1.append("tournament_id", id);
          temp1.append("school_id", res.data.school_id);
          mysclperformance(temp1).then((response) => {
            if (response.status === true) {
              if (response.data.overall_champions_my_school.length !== 0) {
                setGold(
                  response.data.overall_champions_my_school[0].total_gold
                );
                setSilver(
                  response.data.overall_champions_my_school[0].total_silver
                );
                setBronze(
                  response.data.overall_champions_my_school[0].total_bronze
                );
                setMTotal(
                  parseInt(
                    response.data.overall_champions_my_school[0].total_gold
                  ) +
                    parseInt(
                      response.data.overall_champions_my_school[0].total_silver
                    ) +
                    parseInt(
                      response.data.overall_champions_my_school[0].total_bronze
                    )
                );
              }

              setPerformance(response.data.overall_champions_my_school);
            }
          });
        }
      });
    } else {
      achievetourdetails(id).then((response) => {
        if (response.status === true) {
          setMName(response.data.meet_name);
          setMType(response.data.meet_level);
          setMSDate(moment(response.data.meet_date).format("DD-MM-YYYY"));
          setMSport(response.data.sports_type);
          setMCat(response.data.age_group);
          setMEvent(response.data.event);
        }
      });
    }
  }, []);

  function certcheck(cname) {
    dotracking(
      "download button in view tournament for " +
        mname +
        " (" +
        id +
        ") tournament" +
        " with certificate " +
        cname
    );
    saveAs(certs3 + cname, cname);
  }

  const [show, setShow] = useState(false);
  const [certimg, setCertImg] = useState("");

  function handleShow(img) {
    dotracking(
      "view certificate button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament with certificate " +
        img
    );
    setCertImg(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setCertImg("");
  }

  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "back button in tournament details for " +
          mname +
          " (" +
          id +
          ") tournament "
      );
      navigate(-1);
    } else {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in tournament details for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Page") === "2") {
        dotracking(
          "back button in tournament details for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.dashheading
        );
        navigate("/schoolhead/dashboard");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in tournament details for " +
            mname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
    }
  }

  function overlist() {
    dotracking(
      "overall champions list button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.touragecatlistheading
    );
    navigate("/schoolhead/overallchampion/" + id);
  }

  function indlist() {
    dotracking(
      "individual champion list button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.touragecatlistheading
    );
    navigate("/schoolhead/individualchampion/" + id);
  }

  function agecatlist() {
    dotracking(
      "age category list button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.touragecatlistheading
    );
    navigate("/schoolhead/tournamentagecategorylist/" + id);
  }

  function fixlist() {
    localStorage.setItem("Cat", "2");
    localStorage.removeItem("Age");
    dotracking(
      "fixtures button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    navigate("/schoolhead/categorylist/" + id);
  }

function certslist() {
  localStorage.setItem("Cat", "2");
  localStorage.removeItem("Age");
  dotracking(
    "certificate button in tournament details for " +
      mname +
      " (" +
      id +
      ") tournament $$ " +
      trackobj.certheading
  );
  navigate("/schoolhead/certificatelist/" + id);
}

  function perflist() {
    dotracking(
      "player performance button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );
    navigate("/schoolhead/performanceplayerlist/" + id);
  }

  function perfphoto() {
    dotracking(
      "performance photo button in tournament details for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
    navigate("/schoolhead/performancephotos/" + id);
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tournament Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Details
          </li>
        </ol>
      </div>

      <div className="row ">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              {urllink === "achievementtournament" && (
                <>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <a className="btn btn-gray" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                  <div className="row tournamentitem mt-2">
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-2 col-md-6">
                            <b className="text-inverse">Tournament Name</b>
                          </div>
                          <div className="col-md-10 col-md-6">
                            <span className="text-inverse">{mname}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Tournament Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mtype}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Start Date</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{msdate}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Sports Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{msport}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Age Categories</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mcat}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Event</b>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <span className="text-inverse">{mevent}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {urllink !== "achievementtournament" && (
                <>
                  {msport.toUpperCase() !== "ATHLETICS" && msport !== "" && (
                    <>
                      <a
                        onClick={fixlist}
                        className="btnfixture me-2"
                        title="Fixture"
                      >
                        <i className="fas fa-object-group"></i> Fixtures
                      </a>
                      <a
                        onClick={perflist}
                        className="btn btn-export me-2"
                        title="Player Performance"
                      >
                        <i className="fas fa-list"></i> Player Performance
                      </a>
                      <a onClick={certslist} className="btn btn-secondary" title="Certificate">
                        <i className="fa fa-certificate"></i> Certficate
                      </a>
                      <a
                        className="btn btn-gray"
                        onClick={backlist}
                        style={{ float: "right" }}
                      >
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </>
                  )}
                  {msport.toUpperCase() === "ATHLETICS" && msport !== "" && (
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <a className="btn btn-import me-2" onClick={overlist}>
                          <i className="fas fa-trophy"></i> Overall Champions
                        </a>
                        <a className="btn btn-export me-2" onClick={indlist}>
                          <i className="fas fa-trophy"></i> Individual Champions
                        </a>
                        <a onClick={certslist} className="btn btn-secondary" title="Certificate">
                        <i className="fa fa-certificate"></i> Certficate
                      </a>
                        {/* <a className="btn btn-secondary me-2" onClick={agecatlist}>
                      <i className="fa fa-list-alt"></i> Age Category List
                    </a> */}
                        <a className="btn btn-gray" onClick={backlist}>
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="row tournamentitem mt-2">
                    <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-2 col-md-6">
                            <b className="text-inverse">Tournament Name</b>
                          </div>
                          <div className="col-md-10 col-md-6">
                            <span className="text-inverse">{mname}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Location</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mloc}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Tournament Code</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mcode}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Tournament Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mtype}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">State</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mstate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">District</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mdist}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Start Date</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{msdate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">End Date</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{medate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Entry Opens at</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{meodate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Entry Last Date</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{meddate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Sports Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{msport}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Age Category Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{agetype}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {ageid === 2 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Age Categories</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{mcat}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {ageid === 1 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Class</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{tourcls}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {ageid === 2 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">DOB Start Date</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{msdob}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {ageid === 2 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">DOB End Date</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{medob}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">League Format</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mleatype}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Reporting Time</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{reptime}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Payment Gateway Charges</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse">{mgate}</span>
                      </div>
                    </div>
                  </div>
                </div> */}
                    {mcir !== "" && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">
                                Circular <br />
                                Document
                              </b>
                            </div>
                            <div className="col-md-8 align-self-center">
                              <span className="text-inverse">
                                <a href={mcir} target="_blank" download={mcir}>
                                  <b className="blink">
                                    Click Here!{" "}
                                    <i className="fa fa-download ms-2"></i>
                                  </b>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />

                  <div className="row row-cards">
                    <h4>Statistics</h4>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <div className="card bg-custom">
                        <div className="card-body text-center feature">
                          <div className="fa-stack fa-lg fa-1x icons shadow-default bg-info">
                            <i className="icon-people text-white"></i>
                          </div>
                          <p className="card-text mt-3 mb-3">
                            Total Students Registered
                          </p>
                          <p className="h3 text-center text-info">{sreg}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <div className="card bg-custom">
                        <div className="card-body text-center feature">
                          <div className="fa-stack fa-lg fa-1x icons shadow-default bg-primary">
                            <i className="icon-user text-white"></i>
                          </div>
                          <p className="card-text mt-3 mb-3">No of Boys</p>
                          <p className="h3 text-center text-primary-1">
                            {nboys}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <div className="card bg-custom">
                        <div className="card-body text-center feature">
                          <div className="fa-stack fa-lg fa-1x icons shadow-default bg-teal">
                            <i className="icon-user-female text-white-1 text-white"></i>
                          </div>
                          <p className="card-text mt-3 mb-3">No of Girls</p>
                          <p className="h3 text-center text-teal">{ngirls}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <div className="card bg-custom">
                        <div className="card-body text-center feature">
                          <div className="fa-stack fa-lg icons shadow-default bg-purple">
                            <i className="fa fa-soccer-ball-o text-white"></i>
                          </div>
                          {msport.toUpperCase() === "ATHLETICS" && (
                            <p className="card-text mt-3 mb-3">
                              Total Events Played
                            </p>
                          )}
                          {msport.toUpperCase() !== "ATHLETICS" && (
                            <p className="card-text mt-3 mb-3">
                              Total Matches Played
                            </p>
                          )}
                          <p className="h3 text-center text-purple">{ntot}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <h4>Highlights</h4>
                    {filterProduct.length === 0 && (
                      <div className="col-md-12">
                        <div className="text-center">
                          <h4 className="text-danger">
                            Highlights will be updated soon
                          </h4>
                        </div>
                      </div>
                    )}
                    {filterProduct.map((item, i) => {
                      return (
                        <div className="col-lg-3 col-md-3" key={i}>
                          <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                            <div className="card-body pt-2 pb-0 px-3">
                              <div className="text-center">
                                <img
                                  src={require("../../../../assets/images/video.png")}
                                  alt=""
                                  className="folder-img"
                                />
                              </div>
                            </div>
                            <div className="card-footer text-center py-2 px-3">
                              <a
                                className="btn btn-outline-success w-100"
                                href={item.highlight_links}
                                target="_blank"
                                onClick={() =>
                                  dotracking(
                                    "click here button in tournament details for highlight " +
                                      item.highlight_links +
                                      " in " +
                                      mname +
                                      " (" +
                                      id +
                                      ") tournament $$ " +
                                      trackobj.viewtourheading
                                  )
                                }
                              >
                                <i className="fas fa-eye me-2"></i>Click Here
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <hr />
                  <div className="row">
                    <h4>Photos</h4>
                    {photolist.length === 0 && (
                      <div className="col-md-12">
                        <div className="text-center">
                          <h4 className="text-danger">
                            Photos will be updated soon
                          </h4>
                        </div>
                      </div>
                    )}
                    {/* <Gallery photos={photolist} onClick={openLightbox} /> */}

                    <div className="row">
                      {photolist.map((data, i) => {
                        return (
                          <div className="col-md-2 mt-4" key={i}>
                            <a
                              className="photo-share"
                              onClick={() =>
                                handlePshow(
                                  data.src,
                                  "Hey, check out my photos!"
                                )
                              }
                            >
                              <i className="fas fa-share fa-lg"></i>
                            </a>

                            <img
                              className="photoimg"
                              src={data.src}
                              height="100px"
                              onClick={() => openPLightbox(i, photolist)}
                              alt="Photo"
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className={pshow ? "mymodal open" : "mymodal"}>
                      <div className="modoverlay"></div>
                      <div className="mymodal-content">
                        <h4 className="text-center">Share Photos</h4>
                        <span className="close-btn" onClick={handlePclose}>
                          &times;
                        </span>
                        <div className="border-bottom"></div>
                        <Row className="row-sm mt-3">
                          <div className="text-center">
                            <WhatsappShareButton
                              url={shareUrl}
                              title="Hey, check out my photos!"
                              className="me-3"
                            >
                              <WhatsappIcon size={44} round={true} />
                            </WhatsappShareButton>
                            <FacebookShareButton
                              url={shareUrl}
                              quote="Hey, check out my photos!"
                              className="me-3"
                            >
                              <FacebookIcon size={44}></FacebookIcon>
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={shareUrl}
                              title="Hey, check out my photos!"
                              className="me-3"
                            >
                              <TwitterIcon size={44}></TwitterIcon>
                            </TwitterShareButton>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <ModalGateway>
                      {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={pplist.map((x) => ({
                              ...x,
                              srcset: x.srcSet,
                              caption: x.title,
                            }))}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </div>

                  {msport.toUpperCase() === "ATHLETICS" && msport !== "" && (
                    <>
                      <hr />
                      <div className="row">
                        <h4>Results</h4>
                        {photolist.length === 0 && (
                          <div className="col-md-12">
                            <div className="text-center">
                              <h4 className="text-danger">
                                Photos will be updated soon
                              </h4>
                            </div>
                          </div>
                        )}

                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/gold1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{gold}</h2>
                                            <span className="teamach">
                                              <strong>Gold Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/silver1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{silver}</h2>
                                            <span className="teamach">
                                              <strong>Silver Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="grid-uniform">
                            <ul class="ch-grid">
                              <li
                                className="grid__item wide--one-quarter post-large--one-half large--one-half medium--grid__item small-grid__item wow fadeInUp animated"
                                style={{ visibility: " visible" }}
                              >
                                <div className="team-section">
                                  <div className="ch-item">
                                    <div className="ch-info-wrap">
                                      <div className="ch-info">
                                        <div
                                          className="ch-info-back"
                                          style={{
                                            backgroundImage: `url(${require("../../../../assets/images/bronze1.png")})`,
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        >
                                          <div className="team-detail">
                                            <h2>{bronze}</h2>
                                            <span className="teamach">
                                              <strong>Bronze Medals</strong>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="table-responsive">
                        <table className="sp-champion-table sp-data-table">
                          <thead className="sp-table-header">
                            <tr>
                              <th className="text-center">Gold Medals</th>
                              <th className="text-center">Silver Medals</th>
                              <th className="text-center">Bronze Medals</th>
                              <th className="text-center">Total Medals</th>
                              <th className="text-center">Total Points</th>
                            </tr>
                          </thead>
                          <tbody>
                            {performance.map((item, j) => {
                              const getRowColor = () => {
                                return j % 2 === 0 ? "even" : "odd";
                              };
                              return (
                                <>
                                  <tr
                                    className={`sp-row-no-0 ${getRowColor()}`}
                                    key={j}
                                  >
                                    <td className="data-pct">
                                      {item.total_gold}
                                    </td>
                                    <td className="data-pct">
                                      {item.total_silver}
                                    </td>
                                    <td className="data-pct">
                                      {item.total_bronze}
                                    </td>
                                    <td className="data-pct">{mtotal}</td>
                                    <td className="data-pct">
                                      {item.total_points}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <hr /> */}

                  {/* <div className="row mt-3">
                <h4>Certificates</h4>
                {certlist.length === 0 && (
                  <div className="col-md-12">
                    <div className="text-center">
                      <h4 className="text-danger">
                        Certificates will be updated soon
                      </h4>
                    </div>
                  </div>
                )}
                {certlist.map((item, i) => {
                  return (
                    <div className="col-lg-3 col-md-3" key={i}>
                      <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                        <div className="card-body pt-2 pb-0 px-3">
                          <div className="text-center">
                            {item.certificate_type === "P" && (
                              <img
                                src={require("../../../../assets/images/participate.png")}
                                alt="participate"
                                title="Participate Certificate"
                                className="folder-img"
                              />
                            )}
                            {item.certificate_type === "M" && (
                              <img
                                src={require("../../../../assets/images/merit.png")}
                                alt="merit"
                                title="Merit Certificate"
                                className="folder-img"
                              />
                            )}
                          </div>
                        </div>
                        <div className="card-footer text-center py-2 px-3">
                          <a
                            className="btn btn-outline-success w-100"
                            title="View Certificate"
                            onClick={() => handleShow(item.certificate_name)}
                          >
                            <i className="fa fa-eye me-2"></i>Click Here
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  <h4>Certificate</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={certs3 + certimg} alt="certificate" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-success"
                   onClick={()=>certcheck(certimg)}
                  >
                    Download
                  </a>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
