import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import Select from "react-select";
import trackobj from "../../../trackingpages.json";
import {
  citylist,
  districtlist,
  
  statelist,
} from "../../../../services/master";
import {
  coachrolescreate,
  multiroleemailvalidate,
  schoolactivelist,
} from "../../../../services/superadmin";
import { schoolactivelistcityreg } from "../../../../services/cityregionaladmin";
import { schprofileview } from "../../../../services/schoolhead";
import { coachemailvalidate } from "../../../../services/coach";

export const AddCoach = () => {
  const navigate = useNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [state, setState] = useState("");
  const [slist, setSList] = useState([]);
  const [dist, setDist] = useState("");
  const [dlist, setDList] = useState([]);
  const [city, setCity] = useState("");
  const [clist, setCList] = useState([]);
  const [photo, setPhoto] = useState("");

  const [schid, setSchool] = useState("");
  const [schlist, setSchList] = useState([]);

  const [cityreg, setCityReg] = useState("");

  const [submitted, setSubmitted] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }
  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  function selectstate(e) {
    setState(e.target.value);
    setDist("");
    setDList([]);
    setCList([]);
    setCity("");
    if (e.target.value !== "") {
      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDList(response.data);
        }
      });
      citylist(e.target.value).then((response) => {
        if (response.status === true) {
          setCList(response.data);
        }
      });
    }
  }

  const [photoerror, setPhotoError] = useState("");
  function photofile(event) {
    setPhotoError("");
    setPhoto("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhoto(event.target.files[0]);
      } else {
        setPhotoError("Photograph should be jpg, jpeg, or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoError("Photograph size should be less than 5MB");
      }
    }
  }

  const [curdate, setCurDate] = useState("");
  let arr = [];
  useEffect(() => {
    var a = new Date().getFullYear() - 13;
    var d = new Date(a, 11, 31);
    setCurDate(moment(d).format("YYYY-MM-DD"));
    statelist().then((response) => {
      if (response.status === true) {
        setSList(response.data);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      schoolactivelist().then((response) => {
        if (response.status === true) {
          if (response.data.details.length !== 0) {
            arr = [];
            for (let i = 0; i < response.data.details.length; i++) {
              arr.push({
                value: response.data.details[i].id,
                label:
                  response.data.details[i].school_unique_no +
                  " - " +
                  response.data.details[i].school_name,
              });
            }
          }
          setSchList(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schprofileview().then((res) => {
        if (res.status === true) {
          setSchool(res.data.school_id);
          // schoollistmaster().then((response) => {
          //   if (response.status === true) {
          //     if (response.data.details.length !== 0) {
          //       arr = [];
          //       for (let i = 0; i < response.data.details.length; i++) {
          //         if (response.data.details[i].id === res.data.school_id) {
          //           arr.push({
          //             value: response.data.details[i].id,
          //             label:
          //               response.data.details[i].school_unique_no +
          //               " - " +
          //               response.data.details[i].school_name,
          //           });
          //         }
          //       }
          //     }
          //     setSchList(arr);
          //   }
          // });
        }
      });
    }
  }, []);

  function submitschhead(e) {
    e.preventDefault();
    setSubmitted(true);
    setMobErr("");
    var valid = "";

    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        valid = "required";
        setEmailErr("Please Enter a valid Email Address");
      }
      if (localStorage.getItem("Role") === "1") {
        multiroleemailvalidate(email).then((response) => {
          if (response.status === false) {
            setEmailErr(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "4") {
        coachemailvalidate(email).then((response) => {
          if (response.status === false) {
            setEmailErr(response.message);
          }
        });
      }
    }
    if (mobno !== "") {
      if (mobno.length !== 10) {
        valid = "required";
        setMobErr("Mobile Number must be 10 characters");
      }
    }

    if (
      fname !== "" &&
      lname !== "" &&
      gender !== "" &&
      email !== "" &&
      mobno !== "" &&
      schid !== "" &&
      state !== "" &&
      dist !== "" &&
      city !== "" &&
      valid === "" &&
      photoerror === ""
    ) {
      setLoading(true);
      const temp = new FormData();
      temp.append("email_id", email);
      temp.append("mobile_no", mobno);
      temp.append("first_name", fname);
      temp.append("last_name", lname);
      temp.append("role_id", 7);
      //   temp.append("city_admin_id", cityreg);
      temp.append("gender", gender);
      temp.append("dob", dob);
      temp.append("permenant_district", dist);
      if (localStorage.getItem("Role") === "1") {
        temp.append("school_id", schid.value);
      } else if (localStorage.getItem("Role") === "4") {
        temp.append("school_id", schid);
      }

      temp.append("permenant_state", state);
      temp.append("city", city);
      temp.append("photograph", photo);
      dotracking(
        "submit button in Add Coaches for " +
          fname +
          " " +
          lname +
          " with Email " +
          email +
          " $$ " +
          trackobj.coachlistheading
      );

      coachrolescreate(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          toast.success(response.message);
          navigate("/superadmin/coacheslist");
        } else {
          setLoading(false);
          setErrorMsg(response.message);
        }
      });
    }
  }
  const [emailerr, setEmailErr] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setEmailErr("");
    if (e.target.value !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(e.target.value)) {
        setEmailErr("Please Enter a valid Email Address");
      }
      if (localStorage.getItem("Role") === "1") {
        multiroleemailvalidate(e.target.value).then((response) => {
          if (response.status === false) {
            setEmailErr(response.message);
          }
        });
      } else if (localStorage.getItem("Role") === "4") {
        coachemailvalidate(e.target.value).then((response) => {
          if (response.status === false) {
            setEmailErr(response.message);
          }
        });
      }
    }
  }

  const [moberr, setMobErr] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setMobErr("");
    if (e.target.value !== "") {
      if (e.target.value.length !== 10) {
        setMobErr("Mobile Number must be 10 characters");
      }
    }
  }

  function handleSch(e) {
    setSchool(e);
  }

  const [loading, setLoading] = useState(false);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Coach</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Coach</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Coach
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            {loading && (
              <div className="dashloader-wrapper">
                <div className="loader"></div>
                <h3>Loading...Please do not go back or close the window.</h3>
              </div>
            )}
            {!loading && (
              <>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          First Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={fname}
                          onChange={(e) => setFName(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !fname && (
                          <span className="errortext">
                            First Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Last Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={lname}
                          onChange={(e) => setLName(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                        />
                        {submitted && !lname && (
                          <span className="errortext">
                            Last Name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Gender <span className="errortext"> *</span>
                        </label>
                        <select
                          value={gender}
                          className="form-control"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Gender ---
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {submitted && !gender && (
                          <span className="errortext">Gender is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Date of Birth </label>
                        <input
                          type="date"
                          value={dob}
                          className="form-control"
                          max={curdate}
                          onChange={(e) => setDOB(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                        {/* {submitted && !dob &&
                      <span className='errortext'>Date of Birth is required</span>
                    } */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email Address <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={checkemail}
                        />
                        {submitted && !email && (
                          <span className="errortext">
                            Email Address is required
                          </span>
                        )}
                        {emailerr !== "" && (
                          <span className="errortext">{emailerr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Mobile Number <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          value={mobno}
                          onChange={handleChange}
                          onBlur={checkmob}
                          maxLength={10}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !mobno && (
                          <span className="errortext">
                            Mobile Number is required
                          </span>
                        )}
                        {moberr !== "" && (
                          <span className="errortext">{moberr}</span>
                        )}
                      </div>
                    </div>
                    {localStorage.getItem("Role") === "1" && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            School <span className="errortext"> *</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={schid}
                            options={schlist}
                            onChange={handleSch}
                            isSearchable={true}
                            isClearable
                            hidden
                            // isDisabled
                          />
                          {submitted && !schid && (
                            <span className="errortext">
                              School is required
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          State <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control"
                          value={state}
                          onChange={selectstate}
                        >
                          <option value="" defaultValue disabled>
                            --- Select State ---
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !state && (
                          <span className="errortext">State is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          District <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control"
                          value={dist}
                          onChange={(e) => setDist(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select District ---
                          </option>
                          {dlist.map((data, i) => {
                            return (
                              <option value={data.districtuid} key={i}>
                                {data.districtname}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !dist && (
                          <span className="errortext">
                            District is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          City <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select City ---
                          </option>
                          {clist.map((data, i) => {
                            return (
                              <option value={data.city_uid} key={i}>
                                {data.cityname}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !city && (
                          <span className="errortext">City is required</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Photograph </label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg,.jpeg,.png"
                          onChange={photofile}
                        />
                        {/* {submitted && !photo &&
                      <span className='errortext'>Photograph is required</span>
                    } */}
                        {photoerror !== "" && (
                          <span className="errortext">{photoerror}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                </div>
                <div className="card-footer text-center border-top">
                  <button
                    type="submit"
                    className="btn btn-success"
                    title="Add Coach"
                    onClick={submitschhead}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
