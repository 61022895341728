import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  achievelist,
  achieveplayerdelete,
  achieveplayersearch,
  achieveplayerupdate,
  achieveplayerview,
  achieveupdate,
  highestlevelplayedlist,
  sporttypelist,
  touragecategorylist,
} from "../../../../services/superadmin";
import { Modal } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { schoolachievelist } from "../../../../services/schoolhead";
import moment from "moment";
import {
  coachachievelist,
  coachagecatelist,
  coachsportslist,
} from "../../../../services/coach";
import { managerachlist } from "../../../../services/cityregionaladmin";

export const UpAchievementslist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [plist, setPList] = useState([]);
  const [aid, setAID] = useState(0);

  const [show, setShow] = useState(false);
  const [ashow, setAShow] = useState(false);

  const [levellist, setLevelList] = useState([]);
  const [sportlist, setSportList] = useState([]);
  const [agecatlist, setAgeCatList] = useState([]);

  const [tname, setTName] = useState("");
  const [tlevel, setTLevel] = useState("");
  const [tsport, setTSport] = useState("");
  const [tagecat, setTAgeCat] = useState("");
  const [tposition, setTPosition] = useState("");

  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Action",
      field: "achievements_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.user_id === 0) {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
              <a
                onClick={() => editplayer(params.value, params.data)}
                title="Username Mapping"
                className="btn-sm btn-success me-2"
              >
                <i className="fa fa-map fa-lg"></i>
              </a>
              <a
                onClick={() => deleteplayer(params.value, params.data)}
                title="Delete Achievement"
                className="btn-sm btn-danger"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
              <a
                onClick={() => deleteplayer(params.value, params.data)}
                title="Delete Achievement"
                className="btn-sm btn-danger"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            </div>
          );
        }
      },
    },
    // {
    //   headerName: "CHID",
    //   width: 150,
    //   field: "school_chid",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 150,
      field: "firstname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.data.esid !== 0) {
      //     return params.data.first_name;
      //   } else {
      //     return params.data.student_name;
      //   }
      // },
    },
    {
      headerName: "Gender",
      width: 130,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      width: 150,
      field: "student_grade",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "student_section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament",
      width: 200,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      width: 150,
      field: "meet_level",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament Date",
      width: 180,
      field: "meet_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Group",
      width: 150,
      field: "age_group",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Position",
      width: 150,
      field: "position",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "ESID",
      width: 150,
      field: "esid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 150,
      field: "added_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
  ];

  const columnCDefs = [
    {
      headerName: "Action",
      field: "achievements_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.user_id === 0) {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
              <a
                onClick={() => editplayer(params.value, params.data)}
                title="Username Mapping"
                className="btn-sm btn-success me-2"
              >
                <i className="fa fa-map fa-lg"></i>
              </a>
              <a
                onClick={() => deleteplayer(params.value, params.data)}
                title="Delete Achievement"
                className="btn-sm btn-danger"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
              <a
                onClick={() => deleteplayer(params.value, params.data)}
                title="Delete Achievement"
                className="btn-sm btn-danger"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            </div>
          );
        }
      },
    },
    // {
    //   headerName: "CHID",
    //   width: 150,
    //   field: "school_chid",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 150,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.data.esid !== 0) {
      //     return params.data.first_name;
      //   } else {
      //     return params.data.student_name;
      //   }
      // },
    },
    {
      headerName: "Gender",
      width: 130,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      width: 150,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament",
      width: 200,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      width: 150,
      field: "level",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament Date",
      width: 180,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Group",
      width: 150,
      field: "category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Position",
      width: 150,
      field: "position",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "ESID",
      width: 150,
      field: "esid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
  ];

  const columnCRMDefs = [
    {
      headerName: "Action",
      width: 120,
      field: "achievements_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.user_id === 0) {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
              <a
                onClick={() => editplayer(params.value, params.data)}
                title="Username Mapping"
                className="btn-sm btn-success me-2"
              >
                <i className="fa fa-map fa-lg"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                onClick={() => editachi(params.value, params.data)}
                title="Edit Achievements"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
            </div>
          );
        }
      },
    },
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 150,
      field: "firstname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.data.esid !== 0) {
      //     return params.data.firstname;
      //   } else {
      //     return params.data.student_name;
      //   }
      // },
    },
    {
      headerName: "Gender",
      width: 130,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      width: 150,
      field: "student_grade",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "student_section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament",
      width: 200,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      width: 150,
      field: "meet_level",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Tournament Date",
      width: 180,
      field: "meet_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Age Group",
      width: 150,
      field: "age_group",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Position",
      width: 150,
      field: "position",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "ESID",
      width: 150,
      field: "esid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 150,
      field: "added_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
  ];

  function editachi(eid, edata) {
    setAID(eid);
    setTName("");
    setTLevel("");
    setTAgeCat("");
    setTSport("");
    setTPosition("");
    setErrorMsg("");
    setAShow(true);
    setShow(false);
    achieveplayerview(eid).then((response) => {
      if (response.status === true) {
        setTName(response.data.meet_name);
        setTLevel(response.data.meet_level);
        setTSport(response.data.sports_id);
        if (response.data.age_group_id === 0) {
          setTAgeCat("");
        } else if (response.data.age_group_id === null) {
          setTAgeCat("");
        } else {
          setTAgeCat(response.data.age_group_id);
        }

        setTPosition(response.data.position);
      }
    });
  }

  function editplayer(eid, edata) {
    setAID(eid);
    setSchID(edata.school_id);
    setUser("");
    setPList([]);
    setErrorMsg("");
    setShow(true);
  }
  let arr = [];

  function deleteplayer(did, ddata) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in achievements list for " +
            ddata.username +
            " (" +
            did +
            ") $$ " +
            trackobj.achievelistheading
        );

        achieveplayerdelete(did).then((response) => {
          if (response.status === true) {
            listall(fid, schtype);
            toast.success(response.message);
          }
        });
      }
    });
  }
  function Addacheve(aid) {
    if (aid == 1) {
      dotracking(
        "add achievements upload in achievements list $$ " +
          trackobj.achieveuploadheading
      );
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/achievementsupload");
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/achievementsupload");
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/achievementsupload");
      }
    } else {
      dotracking(
        "add individual achievements in achievements list $$ " +
          trackobj.indachieveuploadheading
      );
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/achievementindividual");
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/achievementindividual");
      } else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/achievementindividual");
      }
    }
  }
  useEffect(() => {
    if (
      localStorage.getItem("Role") === "1" ||
      localStorage.getItem("Role") === "3"
    ) {
      listall(2, "");
    } else if (localStorage.getItem("Role") === "7") {
      coachsportslist().then((response) => {
        if (response.status === true) {
          setSportList(response.data);
        }
      });

      coachagecatelist().then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                age_category_name: response.data[i].age_category_name,
                id: response.data[i].id,
              });
            }
          }
          setAgeCatList(arr);
        }
      });
      listall(2, "");
    }
  }, []);

  const [fid, setFID] = useState("");
  const [schtype, setSchType] = useState("");

  function listall(type, stype) {
    setFilterProduct([]);
    setFID(type);
    setSchType(stype);
    if (localStorage.getItem("Role") === "1") {
      achievelist(type).then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.length !== 0) {
            if (stype !== "") {
              const fdata = response.data.uploaded_data.filter(
                (item) => item.school_type === stype
              );
              setFilterProduct(fdata);
            } else {
              setFilterProduct(response.data.uploaded_data);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      managerachlist(type, localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.length !== 0) {
            if (stype !== "") {
              const fdata = response.data.uploaded_data.filter(
                (item) => item.school_type === stype
              );
              setFilterProduct(fdata);
            } else {
              setFilterProduct(response.data.uploaded_data);
            }
          }
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachachievelist().then((response) => {
        if (response.status === true) {
          let arr1 = [];
          if (response.data.length !== 0) {
            if (response.data.other_achivements.length !== 0) {
              arr1 = response.data.other_achivements;
            }
            if (response.data.football_achievements.length !== 0) {
              arr = response.data.football_achievements;
            }
            let arr2 = arr1.concat(arr);
            setFilterProduct(arr2);
          }
        }
      });
    }
    highestlevelplayedlist().then((response) => {
      if (response.status === true) {
        setLevelList(response.data);
      }
    });

    if (localStorage.getItem("Role") === "1") {
      sporttypelist().then((response) => {
        if (response.status === true) {
          setSportList(response.data.sports_list);
        }
      });

      touragecategorylist().then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].is_active === 1) {
                arr.push({
                  age_category_name: response.data[i].age_category_name,
                  id: response.data[i].id,
                });
              }
            }
          }
          setAgeCatList(arr);
        }
      });
    }
  }
  const [user, setUser] = useState("");
  const [uid, setUID] = useState("");
  const [schid, setSchID] = useState(0);

  const [errormsg, setErrorMsg] = useState("");

  function searchuser(ume) {
    setUser(ume);
    setUID("");
    setPList([]);
    if (ume !== "") {
      achieveplayersearch(ume, schid).then((response) => {
        if (response.status === true) {
          setPList(response.data);
        }
      });
    }
  }

  function onplayer(puid, pname, puser, pcls, psec) {
    setUID(puid);
    setUser(pname + " (" + pcls + " - " + psec + ") ");
    setPList([]);
  }

  function splayer() {
    setErrorMsg("");
    if (user !== "") {
      dotracking(
        "username mapping submit button in achievements list for " +
          user +
          " (" +
          aid +
          ") $$ " +
          trackobj.achievelistheading
      );
      achieveplayerupdate(aid, uid, localStorage.getItem("UID")).then(
        (response) => {
          if (response.status === true) {
            setShow(false);
            toast.success(response.message);
            listall(2, schtype);
          } else {
            setErrorMsg(response.message);
          }
        }
      );
    } else {
      setErrorMsg("Please Enter Name/Username or ESID");
    }
  }
  function sachieve() {
    setErrorMsg("");
    if (tname !== "") {
      if (tlevel !== "") {
        if (tsport !== "") {
          if (tagecat !== "") {
            if (tposition !== "") {
              const temp = new FormData();
              temp.append("id", aid);
              temp.append("meet_name", tname);
              temp.append("meet_level", tlevel);
              temp.append("sports_id", tsport);
              const sdata = sportlist.find(
                (item) => item.sports_cat_uid === parseInt(tsport)
              );
              temp.append("sports_type", sdata.sports_category);
              temp.append("age_group_id", tagecat);
              const adata = agecatlist.find(
                (item) => item.id === parseInt(tagecat)
              );
              temp.append("age_group", adata.age_category_name);
              temp.append("position", tposition);

              dotracking(
                "edit achievement submit button in achievements list for " +
                  user +
                  " (" +
                  aid +
                  ") $$ " +
                  trackobj.achievelistheading
              );
              achieveupdate(temp).then((response) => {
                if (response.status === true) {
                  setAShow(false);
                  toast.success(response.message);
                  listall(2, schtype);
                } else {
                  setErrorMsg(response.message);
                }
              });
            } else {
              setErrorMsg("Please Select Position");
            }
          } else {
            setErrorMsg("Please Enter Age Category");
          }
        } else {
          setErrorMsg("Please Enter Sport");
        }
      } else {
        setErrorMsg("Please Select Tournament Level");
      }
    } else {
      setErrorMsg("Please Enter Tournament Name");
    }
  }

  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      allColumns: false,
      columnKeys: [
        "username",
        "first_name",
        "gender",
        "class_name",
        "section",
        "meet_name",
        "level",
        "start_date",
        "sports_type",
        "category_name",
        "event",
        "position",
        "school_name",
        "school_type",
        "school_funder",
        "esid",
      ],
      fileName: "Achievement List.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Achievements Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Role") === "4" && (
            <li className="breadcrumb-item">
              <a>Sports Performance</a>
            </li>
          )}

          {localStorage.getItem("Role") !== "4" && (
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>
          )}

          <li className="breadcrumb-item active" aria-current="page">
            Achievements Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3") && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Filter With/Without ESID</label>
                      <select
                        className="form-control"
                        value={fid}
                        onChange={(e) => listall(e.target.value, schtype)}
                      >
                        <option value="2">All</option>
                        <option value="1">With ESID</option>
                        <option value="0">Without ESID</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <label className="form-label">School Type</label>
                    <select
                      className="form-control custom-select"
                      value={schtype}
                      onChange={(e) => listall(fid, e.target.value)}
                    >
                      <option value="">-- Filter by School Type--</option>
                      <option value="Private">Private</option>
                      <option value="CSR">CSR</option>
                      <option value="Government">Government</option>
                    </select>
                  </div> */}
                  <div className="col-md-9">
                    <div className="text-right">
                      {/* <a className="btn btn-secondary" onClick={onexport}>
                        <i className="fa-solid fa-file-export"></i> Export
                      </a>{" "} */}
                      <a
                        className="btn btn-export me-2"
                        onClick={() => Addacheve(2)}
                      >
                        <i className="fa fa-plus"></i> Add Individual
                        Achievements
                      </a>
                      <a
                        className="btn btn-success"
                        onClick={() => Addacheve(1)}
                      >
                        <i className="fa fa-plus"></i> Add Achievements
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {localStorage.getItem("Role") === "7" && (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-9">
                    <div className="text-right">
                      <a
                        className="btn btn-export me-2"
                        onClick={() => Addacheve(2)}
                      >
                        <i className="fa fa-plus"></i> Add Individual
                        Achievements
                      </a>
                      <a
                        className="btn btn-success"
                        onClick={() => Addacheve(1)}
                      >
                        <i className="fa fa-plus"></i> Add Achievements
                      </a>
                    </div>
                  </div>
                </div>
              )}

              <div className="ag-theme-alpine ag-style mt-2">
                {localStorage.getItem("Role") === "1" && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                )}

                {localStorage.getItem("Role") === "7" && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnCDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                )}
                {localStorage.getItem("Role") === "3" && (
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnCRMDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Username Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name/Username or ESID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Name/Username or ESID"
                  value={user}
                  onChange={(e) => searchuser(e.target.value)}
                />
                <ul className="suggestions">
                  {plist.map((item, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() =>
                          onplayer(
                            item.user_id,
                            item.first_name,
                            item.username,
                            item.class_name,
                            item.section
                          )
                        }
                      >
                        {item.esid} - {item.first_name} ( {item.class_name} -{" "}
                        {item.section} )
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {errormsg !== "" && <span className="errortext">{errormsg}</span>}
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success text-right me-2"
            onClick={splayer}
          >
            Submit
          </a>
          <a
            className="btn btn-next btn-primary text-right"
            onClick={() => setShow(false)}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>

      <Modal show={ashow}>
        <Modal.Header>
          <Modal.Title>Edit Achievements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Tournament Name <span className="errortext"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Tournament Name"
                  value={tname}
                  onChange={(e) => setTName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Tournament Level <span className="errortext"> *</span>
                </label>
                <select
                  className="form-control custom-select"
                  value={tlevel}
                  onChange={(e) => setTLevel(e.target.value)}
                >
                  <option value="" defaultValue disabled>
                    --- Select Tournament Level ---
                  </option>
                  {levellist.map((data, i) => {
                    return (
                      <option value={data.meet_level} key={i}>
                        {data.meet_level}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Sports <span className="errortext"> *</span>
                </label>
                <select
                  className="form-control custom-select"
                  value={tsport}
                  onChange={(e) => setTSport(e.target.value)}
                >
                  <option value="" defaultValue disabled>
                    --- Select Sports ---
                  </option>
                  {sportlist.map((data, i) => {
                    return (
                      <option value={data.sports_cat_uid} key={i}>
                        {data.sports_category}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Age Category <span className="errortext"> *</span>
                </label>
                <select
                  className="form-control custom-select"
                  value={tagecat}
                  onChange={(e) => setTAgeCat(e.target.value)}
                >
                  <option value="" defaultValue disabled>
                    --- Select Age Category ---
                  </option>
                  {agecatlist.map((data, i) => {
                    return (
                      <option value={data.id} key={i}>
                        {data.age_category_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Position <span className="errortext"> *</span>
                </label>

                <select
                  className="form-control custom-select"
                  value={tposition}
                  onChange={(e) => setTPosition(e.target.value)}
                >
                  <option value="" defaultValue disabled>
                    --- Select Position ---
                  </option>
                  <option value="Gold">Gold</option>
                  <option value="Silver">Silver</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Participation">Participation</option>
                </select>
              </div>
            </div>
          </div>
          {errormsg !== "" && <span className="errortext">{errormsg}</span>}
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success text-right me-2"
            onClick={sachieve}
          >
            Submit
          </a>
          <a
            className="btn btn-next btn-primary text-right"
            onClick={() => setAShow(false)}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
