import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import {
  agecategorycreate,
  agecategorydetails,
  agecategorystatus,
  agecategoryupdate,
  agecategoryvalidate,
  touragecategorylist,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";


export const AgeCategoryMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("1");
  const [gender, setGender] = useState("");
  const [agename, setAgeName] = useState("");
  const [atype, setAgeType] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  const [order, setOrder] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [ageid, setId] = useState(0);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Name",
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //     headerName: "Type",
    //     field: "",
    //     sortable: true,
    //     resizable: true,
    //     filter: true,
    //     floatingFilter: true,
    //     cellStyle: { textAlign: "left" },
    // },

    {
      headerName: "Status",
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deleteagecategory(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <a
            title="Edit Age Category"
            onClick={() => viewage(params.value)}
            className="btn-sm btn-secondary"
          >
            <i className="fa fa-edit fa-lg"></i>
          </a>
        );
      },
    },
  ];

  function viewage(aid) {
    setShow(true);
    agecategorydetails(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.id);
        setAgeName();
    dotracking("edit button in age category for " + response.data.age_category_name + " (" + aid + ") $$ " + trackobj.agecatlistheading);

        // setAgeType(response.data)
        setGender(response.data.gender);
        setStatus(response.data.is_active + "");
      }
    });
  }

  function deleteagecategory(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("is_active", 0);
          dotracking(
            "status icon in age category list from Active to Inactive for " + list.data.age_category_name + " ("
              + list.data.id + ") $$ " + trackobj.agecatlistheading
          );
          agecategorystatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("is_active", 1);
          dotracking(
            "status icon in age category list from Inactive to Active for " + list.data.age_category_name + " ("
            + list.data.id + ") $$ " + trackobj.agecatlistheading
          );

          agecategorystatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function handleshow() {
    setId(0);
    setAgeName("");
    setOrder("");
    setGender("");
    setStatus("1");
    setErrorMsg("");
    setAgeType("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setAgeName("");
    setOrder("");
    setGender("");
    setStatus("1");
    setErrorMsg("");
    setAgeType("");
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    touragecategorylist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  const handleOrder = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };

  function submitagecat(e) {
    e.preventDefault();
    setErrorMsg("");
    if (agename !== "") {
      if (gender !== "") {
        if (ageid === 0) {
          const temp1 = new FormData();
          temp1.append("age_category_name", agename);
          agecategoryvalidate(temp1).then((res) => {
            if (res.status === true) {
              const temp = new FormData();
              temp.append("age_category_name", agename);
              temp.append("gender", gender);
              temp.append("is_active", status);
              dotracking("add button in age category for " + agename + " with gender " + gender + " $$ " + trackobj.agecatlistheading);

              agecategorycreate(temp).then((response) => {
                if (response.status === true) {
                  handleclose();
                  listall();
                  toast.success(response.message);
                }
              });
            } else {
              Swal.fire({
                text: agename + " " + res.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#4ecc48",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  setId(0);
                  setAgeName("");
                  setOrder("");
                  setGender("");
                  setStatus("1");
                  setErrorMsg("");
                  setAgeType("");
                }
              });
            }
          });
        } else if (ageid !== 0) {
          const temp = new FormData();
          temp.append("id", ageid);
          temp.append("age_category_name", agename);
          temp.append("gender", gender);
          temp.append("is_active", status);
          dotracking("update button in age category for " + agename + " ("+ ageid +") with gender " + gender + " $$ " + trackobj.agecatlistheading);
          agecategoryupdate(temp).then((response) => {
            if (response.status === true) {
              handleclose();
              listall();
              toast.success(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Your Gender");
      }
    } else {
      setErrorMsg("Please Enter Your Age Category Name");
    }
  }
  function Selectgender(e) {
    setGender(e.target.value);
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      allColumns: false,
      fileName: "Age Category List.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);


  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Age Category Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Age Category Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
            
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add Age Category
                </a>
              </div>

              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {ageid !== 0 && <h4> Age Category Details</h4>}
                  {ageid === 0 && <h4> Add Age Category</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Age Category Name{" "}
                          <span className="errortext"> *</span>
                        </label>

                        <input
                          type="text"
                          placeholder="Age Category Name"
                          className="form-control"
                          value={agename}
                          onChange={(e) => setAgeName(e.target.value)}
                        />
                      </div>
                    </Col>

                    {/* <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Age Category Type{" "}
                                                    <span className="errortext"> *</span>
                                                </label>

                                                <select className="form-control custom-select" value={atype} onChange={(e) => setAgeType(e.target.value)}>
                                                    <option value="" defaultValue disabled>--Select Age Category Type--</option>
                                                    <option value="1">By Date of Birth</option>
                                                    <option value="2">By Class / Grade</option>
                                                </select>
                                            </div>
                                        </Col> */}

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Gender <span className="errortext"> *</span>
                        </label>
                        <div className="row" onChange={Selectgender}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="Male"
                                checked={gender === "Male"}
                              />{" "}
                              Male
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="Female"
                                checked={gender === "Female"}
                              />{" "}
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>
                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {ageid !== 0 && (
                    <a className="btn btn-success" onClick={submitagecat}>
                      Update
                    </a>
                  )}
                  {ageid === 0 && (
                    <a className="btn btn-success" onClick={submitagecat}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
