import React, { Fragment, useEffect, useState } from "react";
import { multichangepassword } from "../services/login";
import { useNavigate } from "react-router-dom";
import dotracking from "./trackinglogs";
import { toast } from "react-toastify";
import trackobj from "./trackingpages.json";
export const PasswordChange = () => {
  const [curpass, setCurPass] = useState("");
  const [newpass, setNewPass] = useState("");
  const [confpass, setConfPass] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  function submitpassword() {
    setErrorMessage("");

    // if (curpass !== "") {
      if (newpass !== "") {
        if (newpass.length >= 8) {
          if (newpass.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
            if (confpass !== "") {
              if (newpass === confpass) {
                const temp = new FormData();
                temp.append("user_id", localStorage.getItem("UID"));
                // temp.append("currentpassword", curpass);
                temp.append("newpassword", newpass);
                temp.append("confirmpassword", confpass);
                dotracking("submit button in change password $$ " + trackobj.loginheading)
                multichangepassword(temp).then((response) => {
                  if (response.status === true) {
                    localStorage.clear();
                    toast.success(response.message);
                    navigate("/login");
                  } else {
                    setErrorMessage(response.message);
                  }
                });
              } else {
                setErrorMessage(
                  "Confirm Password should be same as New Password"
                );
              }
            } else {
              setErrorMessage("Please Enter Your Confirm Password");
            }
          } else {
            setErrorMessage(
              "Your password should be minimum 8 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
            );
          }
        } else {
          setErrorMessage("New Password should be minimum 8 characters");
        }
      } else {
        setErrorMessage("Please Enter Your New Password");
      }
    // } else {
    //   setErrorMessage("Please Enter Your Current Password");
    // }
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Change Password</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Change Password
            </li>
          </ol>
        </div>

        <div className="row" id="user-profile">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="border-0">
                  <div className="row">
                    {/* <div className="col-md-12">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Current Password</b>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Current Password"
                              maxLength={10}
                              value={curpass}
                              onChange={(e) => setCurPass(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12 mt-2">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">New Password</b>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter New Password"
                              maxLength={10}
                              value={newpass}
                              onChange={(e) => setNewPass(e.target.value)}
                              title="Your password should be minimum 8 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <b className="text-inverse">Confirm Password</b>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Confirm Password"
                              maxLength={10}
                              value={confpass}
                              onChange={(e) => setConfPass(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorMessage !== "" && (
                    <span className="errortext">{errorMessage}</span>
                  )}
                  <hr />
                  <div className="text-right">
                    <a className="btn btn-success" onClick={submitpassword}>
                      {" "}
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
