import React, { useEffect } from "react";
import { Bar, Doughnut, Line, Radar, Pie, PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import { useState } from "react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const AllTypeCharts = () => {

  const [xname, setXName] = useState("");
  const [yname, setYName] = useState("");
  const [selectedChart, setSelectedChart] = useState(1);

  const handleChange = (event) => {
    setSelectedChart(parseInt(event.target.value, 10));
  };

  const renderChart = () => {
    const data = {
      labels: ["S2", "S3", "S4", "S5", "S6"],
      datasets: [
        {
          label: "School Average",
          data: [10, 20, 30, 40, 50],
          backgroundColor: "#25e3da",
        },
        {
          label: "National Average",
          data: [15, 25, 35, 45, 55],
          backgroundColor: "#2977ff",
        },
      ],
    };

    const dataPRP = {
      labels: ["S2", "S3", "S4", "S5", "S6"],
      datasets: [
        {
          label: 'School Average',
          data: [10, 20, 30, 40, 50],
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
        },
        {
          label: "National Average",
          data: [15, 25, 35, 45, 55],
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgb(54, 162, 235)",
        },
      ],
    };

    const dataone = {
      labels: ["S2", "S3", "S4", "S5", "S6"],
      datasets: [
        {
          label: 'School Average',
          data: [10, 20, 30, 40, 50],
          backgroundColor: [
            "#ff0000",
            "#0000ff",
            "#00ff00",
            "#ff69b4",
            "#ffA500",
            "#800080",
            "#ffd700",
          ],
        },
        {
          label: "National Average",
          data: [15, 25, 35, 45, 55],
          backgroundColor: [
            "#ff0000",
            "#0000ff",
            "#00ff00",
            "#ff69b4",
            "#ffA500",
            "#800080",
            "#ffd700",
          ],
        },
      ],
    };

    const opts = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          //   text: 'ABC',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: xname,
          },
        },
        y: {
          title: {
            display: true,
            text: yname,
          },
        },
      },
    };

    switch (selectedChart) {
      case 1:
        return <Bar data={data} options={opts} />;
      case 2:
        return <Doughnut data={dataone} options={opts} />;
      case 3:
        return <Line data={data} options={opts} />;
      case 4:
        return <Radar data={dataPRP} options={opts} />;
      case 5:
        return <PolarArea data={dataPRP} options={opts} />;
      case 6:
        return <Pie data={dataPRP} options={opts} />;
      default:
        return null;
    }
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Charts</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Charts</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Statistics
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Reports charts <span className="errortext"> *</span>
                    </label>
                    <select
                      value={selectedChart}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="" defaultValue disabled>
                        --- Select chart ---
                      </option>
                      <option value={1}>Bar Chart</option>
                      <option value={2}>Doughnut Chart</option>
                      <option value={3}>Line Chart</option>
                      <option value={4}>Radar Chart</option>
                      <option value={5}>Polar Area Chart</option>
                      <option value={6}>Pie Chart</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      X-axis Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="X-axis Name"
                      value={xname}
                      onChange={(e) => setXName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Y-axis Name <span className="errortext"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Y-axis Name"
                      value={yname}
                      onChange={(e) => setYName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="chart-container">{renderChart()}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
