import React, { useState } from 'react'
import { useEffect } from 'react'
import { touraddagecategory, touragecategory, tourviewdetail } from '../../../../services/superadmin'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { agecategorytourcityreg, crmtouraddagecategory, crmtourviewdetail } from '../../../../services/cityregionaladmin';
import dotracking from '../../../trackinglogs';
import trackobj from '../../../trackingpages.json';
import { agecategorytourcoach, coachtouraddagecategory, coachtourviewdetail } from '../../../../services/coach';

export const Agecatetour = () => {
    const { id } = useParams();
    const [alist, setAList] = useState([])
    const [tournament, setTournament] = useState([]);

    const [sdate, setSDate] = useState("")
    const [edate, setEDate] = useState("")
    const [tname, setTName] = useState("");
    const [tloc, setTLoc] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    let arr = [];
    useEffect(() => {
        if (localStorage.getItem("Role") === "1") {
            tourviewdetail(id).then((response) => {
                if (response.status === true && response.data.tournament !== null) {
                    setTName(response.data.tournament.meet_name)
                    setTLoc(response.data.tournament.location)
                    setSDate(response.data.tournament.dob_start_date);
                    setEDate(response.data.tournament.dob_end_date);
                }
            })
            touragecategory(id).then((response) => {
                if (response.status === true) {
                    setAList(response.data)
                    if (response.data.length !== 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            var dtype = 0;
                            if (i == 0) {
                                dtype = 1;
                            }
                            var butt = 0;
                            if (i == 0) {
                                butt = 1;
                            }
                            arr.push({
                                agecategory: "",
                                sdob: "",
                                edob: "",
                                fees: "",
                                active: dtype,
                                button: butt,
                            });
                        }
                    }
                    setTournament(arr);
                }
            })
        } else if (localStorage.getItem("Role") === "3") {
            crmtourviewdetail(id).then((response) => {
                if (response.status === true && response.data.tournament !== null) {
                    setTName(response.data.tournament.meet_name)
                    setTLoc(response.data.tournament.location)
                    setSDate(response.data.tournament.dob_start_date);
                    setEDate(response.data.tournament.dob_end_date);
                }
            })

            agecategorytourcityreg(id).then((response) => {
                if (response.status === true) {
                    setAList(response.data)
                    if (response.data.length !== 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            var dtype = 0;
                            if (i == 0) {
                                dtype = 1;
                            }
                            var butt = 0;
                            if (i == 0) {
                                butt = 1;
                            }
                            arr.push({
                                agecategory: "",
                                sdob: "",
                                edob: "",
                                fees: "",
                                active: dtype,
                                button: butt,
                            });
                        }
                    }
                    setTournament(arr);
                }
            })
        }else if (localStorage.getItem("Role") === "7") {
            coachtourviewdetail(id).then((response) => {
                if (response.status === true && response.data.tournament !== null) {
                    setTName(response.data.tournament.meet_name)
                    setTLoc(response.data.tournament.location)
                    setSDate(response.data.tournament.dob_start_date);
                    setEDate(response.data.tournament.dob_end_date);
                }
            })

            agecategorytourcoach(id).then((response) => {
                if (response.status === true) {
                    setAList(response.data)
                    if (response.data.length !== 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            var dtype = 0;
                            if (i == 0) {
                                dtype = 1;
                            }
                            var butt = 0;
                            if (i == 0) {
                                butt = 1;
                            }
                            arr.push({
                                agecategory: "",
                                sdob: "",
                                edob: "",
                                fees: "",
                                active: dtype,
                                button: butt,
                            });
                        }
                    }
                    setTournament(arr);
                }
            })
        }


    }, [])

    function checkage(e, index) {
        if (tournament.length !== 0) {
            var name = "";
            for (let i = 0; i < tournament.length; i++) {
                if (tournament[i].agecategory !== "") {
                    if (tournament[i].agecategory === e.target.value && i !== index) {
                        if (name === "") {
                            name = tournament[i].agecategory;
                        } else {
                            name = name + "," + tournament[i].agecategory;
                        }
                    }
                }
            }
        }
        if (name !== "") {
            Swal.fire({
                title: name + " Already Exists",
                allowOutsideClick: true,
            });
        } else {
            tournament[index].agecategory = e.target.value;
        }
        setTournament([...tournament]);
    }

    function checksdob(e, index) {
        tournament[index].sdob = e.target.value;
        setTournament([...tournament]);
    }

    function checkedob(e, index) {
        tournament[index].edob = e.target.value;
        setTournament([...tournament]);
    }

    function checkfee(e, index) {
        tournament[index].fees = e.target.value;
        setTournament([...tournament]);
    }

    function addmulti(index) {
        if (index + 1 < tournament.length) {
            tournament[index].button = 0;
            tournament[index + 1].button = 1;
            tournament[index + 1].active = 1;
            setTournament([...tournament]);
        } else {
            Swal.fire({
                title: "Maximum Age Category reached",
                allowOutsideClick: true,
            });
        }
    }

    const navigate = useNavigate();
    function addmeet(e) {
        e.preventDefault();
        setErrorMessage("");
        if (tournament.length !== 0) {
            const tempData = new FormData();
            tempData.append("tournament_meet_id", id);
            tempData.append("age_category_count", tournament.length);

            var msg = "";
            for (let i = 0; i < tournament.length; i++) {
                if (tournament[i].active === 1) {
                    if (tournament[i].agecategory !== "") {
                        if (tournament[i].sdob !== "") {
                            if (tournament[i].edob !== "") {
                                // if (tournament[i].fees !== "") {

                                    tempData.append(
                                        "age_category_" + [i + 1],
                                        tournament[i].agecategory
                                    );
                                    tempData.append(
                                        "dob_start_date_" + [i + 1],
                                        tournament[i].sdob
                                    );
                                    tempData.append(
                                        "dob_end_date_" + [i + 1],
                                        tournament[i].edob
                                    );

                                    // tempData.append("fees_" + [i + 1], tournament[i].fees);
                                    tempData.append("fees_" + [i + 1], 0);


                                // } else {
                                //     msg = "Please Enter Your Fees";
                                //     setErrorMessage("Please Enter Your Fees");
                                //     break;
                                // }
                            } else {
                                msg = "Please Select DOB End Date";
                                setErrorMessage("Please Select DOB End Date");
                                break;
                            }
                        } else {
                            msg = "Please Select DOB Start Date";
                            setErrorMessage("Please Select DOB Start Date");
                            break;
                        }
                    } else {
                        msg = "Please Select Age Category";
                        setErrorMessage("Please Select Age Category");
                        break;
                    }
                } else {
                    msg = "Please Select All Age Categories";
                    setErrorMessage("Please Select All Age Categories");
                }
            }
            if (msg === "") {
                dotracking("submit button in age category for " + tname + " (" + id + ") tournament $$ " + trackobj.tourlistheading);
                if (localStorage.getItem("Role") === "1") {
                    touraddagecategory(tempData).then((response) => {
                        if (response.status === true) {
                            navigate("/superadmin/tournamentlist");
                        }
                    });
                }else if (localStorage.getItem("Role") === "3") {
                    crmtouraddagecategory(tempData).then((response) => {
                        if (response.status === true) {
                            navigate("/cityregional/tournamentlist");
                        }
                    });
                }else if (localStorage.getItem("Role") === "7") {
                    coachtouraddagecategory(tempData).then((response) => {
                        if (response.status === true) {
                            navigate("/coach/tournamentlist");
                        }
                    });
                }

            }
        }
    }

    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Add Age Categories</h4>
                </div>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a >Tournament</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Age Categories</li>
                </ol>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <form className="card mt-0">
                        <div className="card-body">
                            <div className="text-center">
                                <h3>{tname}</h3>
                                {/* <h4>{tloc}</h4> */}
                            </div>
                            {tournament.map((data, i) => {
                                return (
                                    <div key={i}>
                                        {data.active === 1 && (
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Age Category <span className='errortext'> *</span></label>
                                                        <select className='form-control'
                                                            value={data.agecategory}
                                                            onChange={(e) => checkage(e, i)}
                                                        >
                                                            <option value="" disabled>
                                                                Select Your Age Category
                                                            </option>
                                                            {alist.map((age, j) => {
                                                                return (
                                                                    <option
                                                                        value={age.age_categories}
                                                                        key={j}
                                                                    >
                                                                        {age.age_categories}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">DOB Start Date <span className='errortext'> *</span></label>
                                                        <input
                                                            type="date" className='form-control'
                                                            placeholder="Enter Your DOB Start Date"
                                                            min={sdate}
                                                            max={edate}
                                                            value={data.sdob}
                                                            onChange={(e) => checksdob(e, i)}
                                                            onKeyPress={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">DOB End Date <span className='errortext'> *</span></label>
                                                        <input
                                                            type="date" className='form-control'
                                                            placeholder="Enter Your DOB End Date"
                                                            min={sdate}
                                                            max={edate}
                                                            value={data.edob}
                                                            onChange={(e) => checkedob(e, i)}
                                                            onKeyPress={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Fees <span className='errortext'> *</span></label>
                                                        <input
                                                            type="text" className='form-control'
                                                            placeholder="Enter Your Fees"
                                                            maxLength={6}
                                                            value={data.fees}
                                                            onChange={(e) => checkfee(e, i)}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                                {data.button === 1 && (
                                                    <div className="col-md-12">
                                                        <div className="text-right">
                                                            <a
                                                                className="btn btn-secondary"
                                                                onClick={() => addmulti(i)}
                                                            >
                                                                <i className='fa fa-plus'></i> Add
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {data.active === 1 && <hr />}
                                    </div>
                                );
                            })}

                            {errorMessage !== "" && (
                                <span className="errortext">{errorMessage}</span>
                            )}
                            <div className="text-right">
                                <a
                                    className="btn btn-success"
                                    onClick={addmeet}
                                >
                                    Submit
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
