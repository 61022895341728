import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import {
  schoolcreate,
  schooldetails,
  schoollist,
  schoolstatus,
  schoolupdate,
  schoolvalidate,
} from "../../../../services/superadmin";
import {
  citylist,
  districtlist,
  funderlist,
  grpschlist,
  statelist,
} from "../../../../services/master";
import { useMemo } from "react";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { useRef } from "react";
import Select from "react-select";

export const SchoolMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3logo, setS3Logo] = useState("");
  const [s3sign, setS3Sign] = useState("");

  const [show, setShow] = useState(false);
  const [schid, setId] = useState(0);
  const [schname, setSchName] = useState("");
  const [schcode, setSchCode] = useState("");
  const [addr, setSchAddr] = useState("");
  const [state, setState] = useState("");
  const [slist, setSList] = useState([]);
  const [dist, setDist] = useState("");
  const [dlist, setDList] = useState([]);
  const [city, setCity] = useState("");
  const [clist, setCList] = useState([]);
  const [pin, setPin] = useState("");

  const [stype, setSType] = useState("");
  const [funder, setFunder] = useState("");
  const [flist, setFList] = useState([]);

  const [grpsch, setGrpSch] = useState("");
  const [gslist, setGSList] = useState([]);

  const [infra, setInfra] = useState("");
  const [sfees, setFees] = useState("0");

  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const [logofile, setLogoFile] = useState("");
  const [signfile, setSignFile] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CHID",
      width: 150,
      field: "school_unique_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      width: 150,
      field: "city_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Group Of School",
      width: 150,
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports Infra",
      width: 150,
      field: "school_sports_infra",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 100,
      field: "school_fees",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "school_logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Signature",
      width: 100,
      field: "princi_signature",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3sign + params.value}
                onClick={() => signshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Status",
      width: 100,
      field: "is_active",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deleteschool(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewschool(params.value)}
              title="Edit School"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }
  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

  const [sshow, setSShow] = useState(false);
  const [schsign, setSchSign] = useState("");

  function signshow(img) {
    if (img !== null) {
      setSShow(true);
      setSchSign(s3sign + img);
    }
  }
  function signclose() {
    setSShow(false);
    setSchSign("");
  }
  function viewschool(aid) {
    setShow(true);
    setDList([]);
    setCList([]);

    schooldetails(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.details.id);
        setSchName(response.data.details.school_name);
        setSchCode(response.data.details.school_unique_no);
        setSchAddr(response.data.details.address);
        setPin(response.data.details.pincode);
        setCity(response.data.details.city);
        setState(response.data.details.state);
        setDist(response.data.details.district);
        setStatus(response.data.details.is_active + "");
        if (response.data.details.school_type !== null) {
          setSType(response.data.details.school_type);
        }
       
        if (response.data.details.school_sports_infra !== null) {
          setInfra(response.data.details.school_sports_infra);
        }
        if (response.data.details.school_fees !== null) {
          setFees(response.data.details.school_fees);
        }

        dotracking(
          "edit button in school list for " +
            response.data.details.school_name +
            " (" +
            aid +
            ") $$ " +
            trackobj.schoollistheading
        );
        districtlist(response.data.details.state).then((res) => {
          if (res.status === true) {
            setDList(res.data);
          }
        });
        citylist(response.data.details.state).then((res) => {
          if (res.status === true) {
            setCList(res.data);
          }
        });
        if (response.data.details.funder_id !== null) {
          funderlist().then((res) => {
            if (res.status === true) {
              let  arr=[];
              if(res.data.funders_list.length > 0){
                for(let i = 0; i < res.data.funders_list.length; i++){
                  if(res.data.funders_list[i].id == response.data.details.funder_id){
                    arr.push({
                      value: res.data.funders_list[i].id,
                      label: res.data.funders_list[i].funder_name
                    })
                    setFunder(arr[0])
                  }
                }
              }
            }
          })
        }


        if (response.data.details.group_of_school_id !== null) {
          grpschlist().then((res) => {
            if (res.status === true) {
              let  arr=[];
              if(res.data.funders_list.length > 0){
                for(let i = 0; i < res.data.funders_list.length; i++){
                  if(res.data.funders_list[i].id == response.data.details.group_of_school_id){
                    arr.push({
                      value: res.data.funders_list[i].id,
                      label: res.data.funders_list[i].group_of_school_name
                    })
                    setGrpSch(arr[0])
                  }
                }
              }
            }
          })
        }
      }
    });
  }

  function handleshow() {
    setId(0);
    setSchName("");
    setSchCode("");
    setSchAddr("");
    setState("");
    setDist("");
    setCity("");
    setPin("");
    setSType("");
    setFunder("");
    setGrpSch("");
    setInfra("");
    setFees("0");
    setLogoFile("");
    setSignFile("");
    setErrorMsg("");
    setSubmitted(false);
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setSubmitted(false);
    setId(0);
    setSchName("");
    setSchCode("");
    setSchAddr("");
    setState("");
    setDist("");
    setCity("");
    setPin("");
    setSType("");
    setFunder("");
    setGrpSch("");
    setInfra("");
    setFees("0");
    setLogoFile("");
    setSignFile("");
    setErrorMsg("");
  }

  function deleteschool(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("is_active", 0);
          dotracking(
            "status icon in school list from Active to Inactive for " +
              list.data.school_name +
              " (" +
              list.data.id +
              ") $$ " +
              trackobj.schoollistheading
          );
          schoolstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", list.data.id);
          temp.append("is_active", 1);
          dotracking(
            "status icon in school list from Inactive to Active for " +
              list.data.school_name +
              " (" +
              list.data.id +
              ") $$ " +
              trackobj.schoollistheading
          );
          schoolstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }
  useEffect(() => {
    listall();

    statelist().then((response) => {
      if (response.status === true) {
        setSList(response.data);
      }
    });

    funderlist().then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.funders_list.length > 0) {
          for (let i = 0; i < response.data.funders_list.length; i++) {
            arr.push({
              value: response.data.funders_list[i].id,
              label: response.data.funders_list[i].funder_name,
            });
          }
        }
        setFList(arr);
      }
    });

    grpschlist().then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.funders_list.length > 0) {
          for (let i = 0; i < response.data.funders_list.length; i++) {
            arr.push({
              value: response.data.funders_list[i].id,
              label: response.data.funders_list[i].group_of_school_name,
            });
          }
        }
        setGSList(arr);
      }
    })
  }, []);

  function selectstate(e) {
    setState(e.target.value);
    setDist("");
    setDList([]);
    setCList([]);
    setCity("");
    if (e.target.value !== "") {
      districtlist(e.target.value).then((response) => {
        if (response.status === true) {
          setDList(response.data);
        }
      });
      citylist(e.target.value).then((response) => {
        if (response.status === true) {
          setCList(response.data);
        }
      });
    }
  }

  function handleChid(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setSchCode(e.target.value);
    }
  }

  function handlePincode(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPin(e.target.value);
    }
  }

  function handleFees(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFees(e.target.value);
    }
  }

  function listall() {
    setFilterProduct([]);
    schoollist().then((response) => {
      if (response.status === true) {
        
        setFilterProduct(response.data.details);
        setS3Logo(response.data.s3_logo);
        setS3Sign(response.data.s3_signature);
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }
  const [submitted, setSubmitted] = useState(false);
  function submitschool(e) {
    e.preventDefault();
    setErrorMsg("");
    setSubmitted(true);
    if (
      schname !== "" &&
      addr !== "" &&
      state !== "" &&
      dist !== "" &&
      pin !== ""
    ) {
      if (schid === 0) {
        const temp1 = new FormData();
        temp1.append("schoolname", schname);
        temp1.append("state_id", state);
        temp1.append("district_id", dist);
        schoolvalidate(temp1).then((res) => {
          if (res.status === true) {
            const temp = new FormData();
            temp.append("school_name", schname);
            temp.append("address", addr);
            temp.append("pincode", pin);
            temp.append("city", dist); // District same as City
            temp.append("state", state);
            temp.append("district", dist);
            temp.append("school_unique_no", schcode);
            temp.append("is_active", status);
            temp.append("logo", logofile);
            temp.append("signature", signfile);
            temp.append("school_type", stype);
            if (stype !== "Private") {
              if(funder !== ""){
                temp.append("school_funder_id", funder.value);
                temp.append("school_funder", funder.label);

              }else{
                temp.append("school_funder_id", 0);
                temp.append("school_funder", "");
              }
            } else {
              temp.append("school_funder_id", 0);
              temp.append("school_funder", "");
            }
            temp.append("school_sports_infra", infra);
            temp.append("school_fees", sfees);

            if(grpsch !== ""){
              temp.append("group_of_school_id", grpsch.value);
            }else{
              temp.append("group_of_school_id", 0);
            }

            dotracking(
              "add button in school list for " +
                schname +
                " $$ " +
                trackobj.schoollistheading
            );
            schoolcreate(temp).then((response) => {
              if (response.status === true) {
                handleclose();
                listall();
                toast.success(response.message);
              }
            });
          } else {
            Swal.fire({
              text: schname + " " + res.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#4ecc48",
              cancelButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                setSubmitted(false);
                setId(0);
                setSchName("");
                setSchCode("");
                setSchAddr("");
                setState("");
                setDist("");
                setCity("");
                setPin("");
                setSType("");
                setFunder("");
                setInfra("");
                setFees("");
                setLogoFile("");
                setSignFile("");
                setErrorMsg("");
              }
            });
          }
        });
      } else if (schid !== 0) {
        const temp = new FormData();
        temp.append("id", schid);
        temp.append("school_name", schname);
        temp.append("address", addr);
        temp.append("pincode", pin);
        temp.append("city", dist); // District same as City
        temp.append("state", state);
        temp.append("district", dist);
        temp.append("school_unique_no", schcode);
        temp.append("is_active", status);
        temp.append("logo", logofile);
        temp.append("signature", signfile);
        temp.append("school_type", stype);
        if (stype !== "Private") {
          if(funder !== ""){
            temp.append("school_funder_id", funder.value);
            temp.append("school_funder", funder.label);
          }else{
            temp.append("school_funder_id", 0);
            temp.append("school_funder", "");
          }
        } else {
          temp.append("school_funder_id", 0);
          temp.append("school_funder", "");
        }
        temp.append("school_sports_infra", infra);
        temp.append("school_fees", sfees);
        if(grpsch !== ""){
          temp.append("group_of_school_id", grpsch.value);
        }else{
          temp.append("group_of_school_id", 0);
        }
        dotracking(
          "update button in school list for " +
            schname +
            " (" +
            schid +
            ") $$ " +
            trackobj.schoollistheading
        );
        schoolupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        });
      }
    }
  }

  const [lerror, setLError] = useState("");
  function logosch(event) {
    setLogoFile("");
    setLError("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLogoFile(event.target.files[0]);
      } else {
        setLError("Logo should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setLError("Logo size should be less than 5MB");
      }
    }
  }

  const [serror, setSError] = useState("");
  function signsch(event) {
    setSignFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setSignFile(event.target.files[0]);
      } else {
        setSError("Signature should be jpg or jpeg or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setSError("Signature size should be less than 5MB");
      }
    }
  }

  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      columnKeys: [
        "school_name",
        "school_unique_no",
        "statename",
        "districtname",
        "city_name",
      ],
      allColumns: false,
      fileName: "School List.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  function handleFunder(e) {
    if (e === null) {
      setFunder("");
    } else {
      setFunder(e);
    }
  }

  function handleGroup(e) {
    if (e === null) {
      setGrpSch("");
    } else {
      setGrpSch(e);
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            School Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add School
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  sortingOrder={sorting}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {schid !== 0 && <h4> School Details</h4>}
                  {schid === 0 && <h4> Add School</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          School <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="School"
                          className="form-control"
                          value={schname}
                          onChange={(e) => setSchName(e.target.value)}
                        />
                        {submitted && !schname && (
                          <span className="errortext">School is required</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          CHID <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="CHID"
                          className="form-control"
                          value={schcode}
                          onChange={handleChid}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !schcode && (
                          <span className="errortext">CHID is required</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Address <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Address"
                          className="form-control"
                          value={addr}
                          onChange={(e) => setSchAddr(e.target.value)}
                        />
                        {submitted && !addr && (
                          <span className="errortext">Address is required</span>
                        )}
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          State <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={state}
                          onChange={selectstate}
                        >
                          <option value="" defaultValue disabled>
                            --- Select State ---
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !state && (
                          <span className="errortext">State is required</span>
                        )}
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          District <span className="errortext"> *</span>
                        </label>
                        <select
                          className="form-control custom-select"
                          value={dist}
                          onChange={(e) => setDist(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select District ---
                          </option>
                          {dlist.map((data, i) => {
                            return (
                              <option value={data.districtuid} key={i}>
                                {data.districtname}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !dist && (
                          <span className="errortext">
                            District is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Pincode <span className="errortext"> *</span>
                        </label>

                        <input
                          type="text"
                          placeholder="Pincode"
                          className="form-control"
                          maxLength={6}
                          value={pin}
                          onChange={handlePincode}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !pin && (
                          <span className="errortext">Pincode is required</span>
                        )}
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">School Type</label>
                        <select
                          className="form-control custom-select"
                          value={stype}
                          onChange={(e) => setSType(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select School Type ---
                          </option>
                          <option value="Private">Private</option>
                          <option value="CSR">CSR</option>
                          <option value="Government">Government</option>
                        </select>
                      </div>
                    </Col>
                    {stype !== "Private" && stype !== "" && (
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">Funder Name</label>
                          {/* <input
                          type="text"
                          placeholder="Funder"
                          className="form-control"
                          value={funder}
                          onChange={(e) => setFunder(e.target.value)}
                        />*/}
                          <Select
                            className="dropdown"
                            placeholder="Select Funder"
                            value={funder}
                            options={flist}
                            onChange={handleFunder}
                            isSearchable={true}
                            isClearable
                            hidden
                          />
                        </div>
                      </Col>
                    )}

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Sports Infra</label>
                        <input
                          type="text"
                          placeholder="Sports Infra"
                          className="form-control"
                          value={infra}
                          onChange={(e) => setInfra(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Fees</label>

                        <input
                          type="text"
                          placeholder="Fees"
                          className="form-control"
                          maxLength={10}
                          value={sfees}
                          onChange={handleFees}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Group of School</label>
                        <Select
                          className="dropdown"
                          placeholder="Select Group of School"
                          value={grpsch}
                          options={gslist}
                          onChange={handleGroup}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">School Logo</label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                          onChange={logosch}
                        />
                        {lerror !== "" && (
                          <span className="errortext">{lerror}</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Principal Signature</label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                          onChange={signsch}
                        />
                        {serror !== "" && (
                          <span className="errortext">{serror}</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>
                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {schid !== 0 && (
                    <a className="btn btn-success" onClick={submitschool}>
                      Update
                    </a>
                  )}
                  {schid === 0 && (
                    <a className="btn btn-success" onClick={submitschool}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>

              <Modal show={lshow} size="lg" onHide={logoclose}>
                <Modal.Header>
                  <h4> School Logo</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={schlogo} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={logoclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={sshow} size="lg" onHide={signclose}>
                <Modal.Header>
                  <h4> School Signature</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={schsign} alt="Signature" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={signclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
