import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  perfcriteriaphotodelete,
  perfcriteriaphotoupdate,
  perfcriteriaplayerdelete,
  perfcriteriaplayerphotoedit,
  perfcriteriaplayerupdate,
  perfcriteriasportslist,
  tourcategorybasedplayerlist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const PerformancePlayerPhotoEdit = () => {
  const navigate = useNavigate();
  const { id, tid, eid } = useParams();
  const [tname, setTName] = useState("");
  const [catname, setCatname] = useState("");
  const [sportid, setSportID] = useState("");
  const [catid, setCatID] = useState("");
  const [crlist, setCRList] = useState([]);

  const [criteriaid, setCriteriaID] = useState("");
  const [plist, setPList] = useState([]);
  const [plalist, setPlaList] = useState("");

  const [playerid, setPlayerID] = useState("");

  const [errorMsg, setErrorMsg] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [photolist, setPhotoLink] = useState([]);
  const [perfcriteriaedit, setPerfcriteriaEdit] = useState([]);
  const [perfphotos, setPerfphotos] = useState([]);

  useEffect(() => {
    const tempData = new FormData();
    tempData.append("category_id", tid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        // setCatID(res.data.category_details.id);
        setCatname(res.data.category_details.category_name);
      }
    });

    tourcategorybasedplayerlist(id, tid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          setPlaList(response.data);

          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].user_id,
              label:
                response.data[i].first_name +
                " (" +
                response.data[i].username +
                ") - " +
                response.data[i].class +
                " - " +
                response.data[i].section +
                " - " +
                response.data[i].team_name,
            });
          }
          setPList(arr);
        }
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setSportID(response.data.tournament.sports_type_no);
          setTName(response.data.tournament.meet_name);
          listall(response.data.tournament.sports_type_no);
        }
      });
    }
  }, []);

  let arr1 = [];
  function listall(sid) {
    perfcriteriasportslist(sid).then((response) => {
      if (response.status === true) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].id,
            label: response.data[i].criteria_name,
          });
        }
        setCRList(arr1);
      }
    });
    setPerfcriteriaEdit([]);
    setPerfphotos([]);
    perfcriteriaplayerphotoedit(id, eid).then((response) => {
      if (response.status === true) {
        if (
          response.data.performancelist.length !== 0 ||
          response.data.performancephotolist.length !== 0
        ) {
          setPerfcriteriaEdit(response.data.performancelist);
          if (response.data.performancephotolist.length !== 0) {
            setCriteriaID(response.data.performancephotolist[0].criteria_id);
          } else if (response.data.performancelist.length !== 0) {
            setCriteriaID(response.data.performancelist[0].criteria_id);
          }

          let arr2 = [];
          for (let i = 0; i < response.data.performancephotolist.length; i++) {
            arr2.push({
              id: response.data.performancephotolist[i].id,
              src:
                response.data.performanceimage_path +
                response.data.performancephotolist[i].photo_name,
              width: 1,
              height: 1,
            });
          }
          setPerfphotos(arr2);
        }
      }
    });
  }
  function backlist() {
    dotracking(
      "back button in add performance player photo for " +
        catname +
        " (" +
        tid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/performancelist/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/performancelist/" + id + "/" + tid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/performancelist/" + id + "/" + tid);
    }
  }

  function handlePlayer(e) {
    if (e !== null) {
      setPlayerID(e);
    } else {
      setPlayerID("");
    }
  }

  function photofile(event) {
    let arr = [];
    let arr1 = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length !== 0) {
        if (event.target.files[i].name.match(/\.(jpg|jpeg|png)$/i)) {
          var size = event.target.files[i].size / 1024 / 1024;
          if (size > 50) {
            arr1.push(
              event.target.files[i].name + " size should be less than 50MB"
            );
            setErrorMsg([...arr1]);
          } else {
            arr.push(event.target.files[i]);
          }
        } else {
          arr1.push(
            event.target.files[i].name + " should be jpg or jpeg or png format"
          );
        }
      }
    }
    setPhoto(arr);
    setPhoto([...photo, ...arr]);
    setErrorMsg(arr1);
    setErrorMsg([...errorMsg, ...arr1]);
  }

  function deleteplayer(pid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const pdata = perfcriteriaedit.find((p) => p.id === pid);
        dotracking(
          "delete button in performance player for " +
            pdata.criteria_name +
            " (" +
            pid +
            ") criteria in " +
            pdata.first_name +
            " (" +
            pdata.username +
            " - " +
            pdata.user_id +
            ") in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.perfeditheading
        );
        const temp = new FormData();
        temp.append("id", pid);
        perfcriteriaplayerdelete(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall(sportid);
          }
        });
      }
    });
  }

  function deletephoto(phid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const phdata = perfphotos.find((p) => p.id === phid);
        dotracking(
          "delete button in performance photo for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.perfeditheading
        );
        const temp = new FormData();
        temp.append("id", phid);
        perfcriteriaphotodelete(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall(sportid);
          }
        });
      }
    });
  }

  function addperformancePlayer(e) {
    e.preventDefault();
    if (playerid !== "") {
      const tempData = new FormData();
      tempData.append("admin_id", localStorage.getItem("UID"));
      tempData.append("tournament_id", id);
      tempData.append("category_id", tid);
      tempData.append("criteria_id", criteriaid);
      tempData.append("player_count", playerid.length);
      if (playerid.length !== 0) {
        for (let i = 0; i < playerid.length; i++) {
          tempData.append("player_arr_" + [i + 1], playerid[i].value);
          const fdata = plalist.find((item) => {
            return item.user_id === parseInt(playerid[i].value);
          });
          tempData.append("team_arr_" + [i + 1], fdata.id);
        }
      }

      perfcriteriaplayerupdate(tempData).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          setPlayerID("");
          listall(sportid);
        }
      });
    } else {
      toast.error("Please Select Player");
    }
  }

  function addperformancePhoto(e) {
    e.preventDefault();
    if (photo.length !== 0) {
      const tempData = new FormData();
      tempData.append("admin_id", localStorage.getItem("UID"));
      tempData.append("tournament_id", id);
      tempData.append("category_id", tid);
      tempData.append("criteria_id", criteriaid);
      tempData.append("photo_count", photo.length);
      if (photo.length !== 0) {
        for (let i = 0; i < photo.length; i++) {
          tempData.append("image_links_" + [i + 1], photo[i]);
        }
      }

      perfcriteriaphotoupdate(tempData).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          setPhoto([]);
          listall(sportid);
        }
      });
    } else {
      toast.error("Either Select Photo");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Performance</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Category</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Performance
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-md-12">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                    <h4 className="text-success tx-20">Category - {catname}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 border-right">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Criteria Type <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control custom-select"
                            value={criteriaid}
                            onChange={(e) => setCriteriaID(e.target.value)}
                            disabled
                          >
                            {crlist.map((data, i) => {
                              return (
                                <option value={data.value} key={i} defaultValue>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                          {/* {cerrorMsg !== "" && (
                              <span className="errortext">{cerrorMsg}</span>
                            )} */}
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            Players <span className="errortext"> *</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select Players"
                            value={playerid}
                            options={plist}
                            onChange={handlePlayer}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                          {/* {serrorMsg !== "" && (
                              <span className="errortext">{serrorMsg}</span>
                            )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 border-left">
                  <div className="details-group refereebox">
                    <div className="row">
                      {perfcriteriaedit.length !== 0 && (
                        <h4 className="mt-2">Players List</h4>
                      )}

                      {perfcriteriaedit.map((data, i) => {
                        return (
                          <div className="col-md-4 align-self-center" key={i}>
                            <a
                              className="photo-mark"
                              style={{
                                cursor: "pointer",
                                marginBottom: "-20px",
                              }}
                              onClick={() => deleteplayer(data.id)}
                            >
                              <i className="fa fa-trash fa-lg"></i>
                            </a>
                            <div className="form-group">
                              <h4 className="mb-1">{data.first_name}</h4>
                              <h3 className="text-gray mb-0">
                                {data.username}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                      {perfcriteriaedit.length !== 0 && (
                        <div className="border-bottom"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-2">
                <a
                  onClick={addperformancePlayer}
                  className="btn btn-success me-2"
                >
                  Update
                </a>
              </div>

              <hr />

              <div className="row">
                <div className="col-md-4 border-right">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Criteria Type <span className="errortext"> *</span>
                          </label>
                          <select
                            className="form-control custom-select"
                            value={criteriaid}
                            onChange={(e) => setCriteriaID(e.target.value)}
                            disabled
                          >
                            {crlist.map((data, i) => {
                              return (
                                <option value={data.value} key={i} disabled>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                          {/* {cerrorMsg !== "" && (
                              <span className="errortext">{cerrorMsg}</span>
                            )} */}
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Photos <span className="errortext"> *</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={photofile}
                            accept=".jpg,.jpeg,.png"
                          />
                          {photo.map((data, i) => {
                            return <li key={i}>{data.name}</li>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 border-left">
                  <div className="details-group refereebox">
                    <div className="row">
                      {perfcriteriaedit.length !== 0 && (
                        <h4 className="mt-2">Photos List</h4>
                      )}

                      {perfphotos.map((data, i) => {
                        return (
                          <div className="col-md-3 mt-4" key={i}>
                            <a
                              className="photo-mark"
                              onClick={() => deletephoto(data.id)}
                            >
                              <i className="fa fa-trash fa-lg"></i>
                            </a>

                            <img className="photoimg"
                              src={data.src}
                              height="100px"
                              // onClick={() => handleImage(data.src)}
                              alt="Photo"
                            />
                          </div>
                        );
                      })}
                      {perfphotos.length !== 0 && (
                        <div className="border-bottom"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-2">
                <a
                  onClick={addperformancePhoto}
                  className="btn btn-success me-2"
                >
                  Update
                </a>
                <a onClick={backlist} className="btn btn-gray">
                  <i className="fe fe-corner-up-left"></i> Back
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
