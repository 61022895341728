import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  crmtourviewdetail,
  teamdeletecityreg,
  teamlistcityreg,
  teamselectedplayerlistcityreg,
} from "../../../../services/cityregionaladmin";
import { toast } from "react-toastify";
import {
  poollisttour,
  teamdelete,
  tourcategorydetail,
  tourteamlist,
  tourteamplayerlist,
  tourviewdetail,
} from "../../../../services/superadmin";
import {
  Refteamlist,
  Refteamplayerlist,
  Reftourdetail,
} from "../../../../services/referee";
import exportUrl from "../../../../exportUtl";
import {
  scheadtourviewdetail,
  schooltourteamlist,
  schooltourteamplayerlist,
  schprofileview,
} from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  interschoolagecatselectlist,
  interschooltourteamlist,
} from "../../../../services/interschool";
import moment from "moment";
import {
  coachtourviewdetail,
  coachviewprofile,
  teamdeletecoach,
  teamlistcoach,
  teamselectedplayerlistcoach,
} from "../../../../services/coach";
import {
  externaltourdetails,
  externaltourteamdelete,
  externaltourteamlist,
} from "../../../../services/common";

export const TeamList = () => {
  const { id, tid, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [catname, setCatname] = useState("");
  const [totteam, setTotTeam] = useState("");

  const [tcreate, setTCreate] = useState("");

  const [tname, setTName] = useState("");
  const [eclosed, setEClosed] = useState(false);

  const [ttype, setType] = useState("");
  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (
            response.data.tournament.entry_deadline_date <
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }

          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTCreate(response.data.tournament.created_by + "");
          setTName(response.data.tournament.meet_name);
          setType(response.data.tournament.meet_type);
          if (
            response.data.tournament.entry_deadline_date <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            setEClosed(true);
          }
          listall(response.data.tournament.meet_type, link[1]);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      if (link[1] === "externalteamlist") {
        externaltourdetails(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setType(response.data.tournament.meet_type_name);
            if (
              response.data.tournament.end_date <=
              moment(new Date()).format("YYYY-MM-DD")
            ) {
              setEClosed(true);
            }
            listall(response.data.tournament.meet_type, link[1]);
          }
        });
      } else {
        coachtourviewdetail(id).then((response) => {
          if (response.status === true && response.data.tournament !== null) {
            setTCreate(response.data.tournament.created_by + "");
            setTName(response.data.tournament.meet_name);
            setType(response.data.tournament.meet_type);
            if (
              response.data.tournament.entry_deadline_date <=
              moment(new Date()).format("YYYY-MM-DD")
            ) {
              setEClosed(true);
            }
            listall(response.data.tournament.meet_type, link[1]);
          }
        });
        
      }
    }
  }, []);

  function listall(ltype, mtype) {
    setFilterProduct([]);

    if (localStorage.getItem("Role") === "1") {
      if (ltype === "Inter School Tournament" && mtype !== "teamlist") {
        listinter(eid);
      } else {
        tourteamlist(id, tid).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  id: response.data[i].id,
                  team_name: response.data[i].team_name,
                  team_code: response.data[i].team_code,
                  school_name: response.data[i].school_name,
                  plist: [],
                  poollist: [],
                });

                tourteamplayerlist(id, response.data[i].id).then((response) => {
                  if (response.status === true) {
                    arr[i].plist = response.data;
                    setFilterProduct([...arr]);
                  }
                });
                if (arr[i].poollist.length === 0) {
                  poollisttour(id, tid).then((res) => {
                    if (res.status === true) {
                      if (res.data.pool.length !== 0) {
                        for (let j = 0; j < res.data.pool.length; j++) {
                          if (res.data.pool[j].teamlist.length !== 0) {
                            for (
                              let k = 0;
                              k < res.data.pool[j].teamlist.length;
                              k++
                            ) {
                              if (
                                res.data.pool[j].teamlist[k].teams_id ===
                                response.data[i].id
                              ) {
                                arr[i].poollist = res.data.pool[j].teamlist[k];
                                setFilterProduct([...arr]);
                              }
                            }
                          }
                        }
                      }
                    }
                  });
                }
              }
              setFilterProduct(arr);
            }
          }
        });
      }
    } else if (localStorage.getItem("Role") === "3") {
      teamlistcityreg(id, tid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                id: response.data[i].id,
                team_name: response.data[i].team_name,
                team_code: response.data[i].team_code,
                school_name: response.data[i].school_name,
                plist: [],
                poollist: [],
              });
              teamselectedplayerlistcityreg(id, response.data[i].id).then(
                (response) => {
                  if (response.status === true) {
                    arr[i].plist = response.data;
                    setFilterProduct([...arr]);
                  }
                }
              );

              if (arr[i].poollist.length === 0) {
                poollisttour(id, tid).then((res) => {
                  if (res.status === true) {
                    if (res.data.pool.length !== 0) {
                      for (let j = 0; j < res.data.pool.length; j++) {
                        if (res.data.pool[j].teamlist.length !== 0) {
                          for (
                            let k = 0;
                            k < res.data.pool[j].teamlist.length;
                            k++
                          ) {
                            if (
                              res.data.pool[j].teamlist[k].teams_id ===
                              response.data[i].id
                            ) {
                              arr[i].poollist = res.data.pool[j].teamlist[k];
                              setFilterProduct([...arr]);
                            }
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }
          setFilterProduct(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      if (ltype === "Inter School Tournament") {
        schprofileview(localStorage.getItem("UID")).then((res2) => {
          if (res2.status === true) {
            listinter(res2.data.school_id);
          }
        });
      } else {
        schooltourteamlist(id, tid).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                arr.push({
                  id: response.data[i].id,
                  team_name: response.data[i].team_name,
                  team_code: response.data[i].team_code,
                  school_name: response.data[i].school_name,
                  plist: [],
                  poollist: [],
                });
                schooltourteamplayerlist(id, response.data[i].id).then(
                  (res1) => {
                    if (res1.status === true) {
                      arr[i].plist = res1.data;
                      setFilterProduct([...arr]);
                    }
                  }
                );

                if (arr[i].poollist.length === 0) {
                  poollisttour(id, tid).then((res) => {
                    if (res.status === true) {
                      if (res.data.pool.length !== 0) {
                        for (let j = 0; j < res.data.pool.length; j++) {
                          if (res.data.pool[j].teamlist.length !== 0) {
                            for (
                              let k = 0;
                              k < res.data.pool[j].teamlist.length;
                              k++
                            ) {
                              if (
                                res.data.pool[j].teamlist[k].teams_id ===
                                response.data[i].id
                              ) {
                                arr[i].poollist = res.data.pool[j].teamlist[k];
                                setFilterProduct([...arr]);
                              }
                            }
                          }
                        }
                      }
                    }
                  });
                }
              }
            }
            setFilterProduct(arr);
          }
        });
      }
    } else if (localStorage.getItem("Role") === "5") {
      Refteamlist(id, tid).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              arr.push({
                id: response.data[i].id,
                team_name: response.data[i].team_name,
                team_code: response.data[i].team_code,
                school_name: response.data[i].school_name,
                plist: [],
                poollist: [],
              });
              Refteamplayerlist(id, response.data[i].id).then((response) => {
                if (response.status === true) {
                  arr[i].plist = response.data;
                  setFilterProduct([...arr]);
                }
              });

              if (arr[i].poollist.length === 0) {
                poollisttour(id, tid).then((res) => {
                  if (res.status === true) {
                    if (res.data.pool.length !== 0) {
                      for (let j = 0; j < res.data.pool.length; j++) {
                        if (res.data.pool[j].teamlist.length !== 0) {
                          for (
                            let k = 0;
                            k < res.data.pool[j].teamlist.length;
                            k++
                          ) {
                            if (
                              res.data.pool[j].teamlist[k].teams_id ===
                              response.data[i].id
                            ) {
                              arr[i].poollist = res.data.pool[j].teamlist[k];
                              setFilterProduct([...arr]);
                            }
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }
          setFilterProduct(arr);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      if (mtype === "externalteamlist") {
        coachviewprofile().then((res2) => {
          if (res2.status === true) {
            const temp = new FormData();
            temp.append("tournament_meet_id", id);
            temp.append("school_id", res2.data.school_id);
    
            externaltourteamlist(temp).then((response) => {
              if (response.status === true) {
                setFilterProduct(response.data.team_list);
              }
            });
          }
        });

       
      } else {
        if (ltype === "Inter School Tournament") {
          coachviewprofile().then((res2) => {
            if (res2.status === true) {
              listinter(res2.data.school_id);
            }
          });
        } else {
          teamlistcoach(id, tid).then((response) => {
            if (response.status === true) {
              if (response.data.length !== 0) {
                for (let i = 0; i < response.data.length; i++) {
                  arr.push({
                    id: response.data[i].id,
                    team_name: response.data[i].team_name,
                    team_code: response.data[i].team_code,
                    school_name: response.data[i].school_name,
                    plist: [],
                    poollist: [],
                  });
                  teamselectedplayerlistcoach(id, response.data[i].id).then(
                    (response) => {
                      if (response.status === true) {
                        arr[i].plist = response.data;
                        setFilterProduct([...arr]);
                      }
                    }
                  );

                  if (arr[i].poollist.length === 0) {
                    poollisttour(id, tid).then((res) => {
                      if (res.status === true) {
                        if (res.data.pool.length !== 0) {
                          for (let j = 0; j < res.data.pool.length; j++) {
                            if (res.data.pool[j].teamlist.length !== 0) {
                              for (
                                let k = 0;
                                k < res.data.pool[j].teamlist.length;
                                k++
                              ) {
                                if (
                                  res.data.pool[j].teamlist[k].teams_id ===
                                  response.data[i].id
                                ) {
                                  arr[i].poollist =
                                    res.data.pool[j].teamlist[k];
                                  setFilterProduct([...arr]);
                                }
                              }
                            }
                          }
                        }
                      }
                    });
                  }
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      }
    }
    if (mtype !== "externalteamlist") {
      const tempData = new FormData();
      tempData.append("category_id", tid);
      tourcategorydetail(tempData).then((res) => {
        if (res.status === true) {
          setCatname(res.data.category_details.category_name);
        }
      });
    }
  }
  let arr = [];

  function listinter(lsid) {
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    temp.append("school_id", lsid);
    interschoolagecatselectlist(temp).then((response) => {
      if (response.status === true) {
        const fdata = response.data.find(
          (item) => item.category_id === parseInt(tid)
        );
        setTotTeam(fdata.allowed_team_count);
      }
    });

    interschooltourteamlist(id, tid, lsid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              id: response.data[i].id,
              team_name: response.data[i].team_name,
              team_code: response.data[i].team_code,
              school_name: response.data[i].school_name,
              plist: [],
              poollist: [],
            });
            if (localStorage.getItem("Role") === "1") {
              tourteamplayerlist(id, response.data[i].id).then((response) => {
                if (response.status === true) {
                  arr[i].plist = response.data;
                  setFilterProduct([...arr]);
                }
              });
            } else if (localStorage.getItem("Role") === "3") {
              teamselectedplayerlistcityreg(id, response.data[i].id).then(
                (response) => {
                  if (response.status === true) {
                    arr[i].plist = response.data;
                    setFilterProduct([...arr]);
                  }
                }
              );
            } else if (localStorage.getItem("Role") === "4") {
              schooltourteamplayerlist(id, response.data[i].id).then((res1) => {
                if (res1.status === true) {
                  arr[i].plist = res1.data;
                  setFilterProduct([...arr]);
                }
              });
            } else if (localStorage.getItem("Role") === "7") {
              teamselectedplayerlistcoach(id, response.data[i].id).then(
                (response) => {
                  if (response.status === true) {
                    arr[i].plist = response.data;
                    setFilterProduct([...arr]);
                  }
                }
              );
            }

            if (arr[i].poollist.length === 0) {
              poollisttour(id, tid).then((res) => {
                if (res.status === true) {
                  if (res.data.pool.length !== 0) {
                    for (let j = 0; j < res.data.pool.length; j++) {
                      if (res.data.pool[j].teamlist.length !== 0) {
                        for (
                          let k = 0;
                          k < res.data.pool[j].teamlist.length;
                          k++
                        ) {
                          if (
                            res.data.pool[j].teamlist[k].teams_id ===
                            response.data[i].id
                          ) {
                            arr[i].poollist = res.data.pool[j].teamlist[k];
                            setFilterProduct([...arr]);
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        }
        setFilterProduct(arr);
      }
    });
  }

  function addteam() {
    dotracking(
      "add team button for " +
        catname +
        " (" +
        tid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamaddheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamlist") {
        navigate("/superadmin/teamcreate/" + id + "/" + tid);
      } else {
        navigate("/superadmin/interteamcreate/" + id + "/" + tid + "/" + eid);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamcreate/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamcreate/" + id + "/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      if (urllink === "externalteamlist") {
        navigate("/coach/externalteamcreate/" + id);
      } else {
        navigate("/coach/teamcreate/" + id + "/" + tid);
      }
    }
  }

  function backlist() {
    dotracking(
      "back button in team list for " +
        catname +
        " (" +
        tid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamlist") {
        navigate("/superadmin/categorylist/" + id);
      }else if (urllink === "externalteamlist") {
        navigate("/superadmin/externaltournamentlist" );
      } else {
        navigate("/superadmin/intercategorylist/" + id + "/" + eid);
      }
      // navigate(-1);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/intercategorylist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      // navigate("/coach/categorylist/" + id);
      if (urllink === "teamlist") {
        navigate("/coach/categorylist/" + id);
      }else if (urllink === "externalteamlist") {
        navigate("/coach/externaltournamentlist");
      } else {
        navigate("/coach/intercategorylist/" + id + "/" + eid);
      }
    }
  }

  function editteam(did, ename) {
    dotracking(
      "edit team icon in team list for " +
        ename +
        " (" +
        did +
        ") team in " +
        catname +
        " (" +
        tid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teameditheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamlist") {
        navigate("/superadmin/teamdetails/" + id + "/" + did + "/" + tid);
      } else {
        navigate("/superadmin/interteamdetails/" + id + "/" + did + "/" + tid);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamdetails/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamdetails/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      if (urllink === "externalteamlist") {
        navigate("/coach/externalteamdetails/" + id + "/" + did);
      } else {
        navigate("/coach/teamdetails/" + id + "/" + did + "/" + tid);
      }
    }
  }
  function addplayer(did, ename) {
    dotracking(
      "add player icon in team list for " +
        ename +
        " (" +
        did +
        ") team in " +
        catname +
        " (" +
        tid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamaddplaheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamlist") {
        navigate("/superadmin/teamplayercreate/" + id + "/" + did + "/" + tid);
      } else {
        navigate(
          "/superadmin/interteamplayercreate/" + id + "/" + did + "/" + tid
        );
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamplayercreate/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamplayerlist/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/teamplayercreate/" + id + "/" + did + "/" + tid);
    }
  }

  function playerlist(did, ename) {
    dotracking(
      "player list icon in team list for " +
        ename +
        " (" +
        did +
        ") team in " +
        catname +
        " (" +
        tid +
        ") category for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.teamplaheading
    );
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "teamlist") {
        navigate("/superadmin/teamplayerlist/" + id + "/" + did + "/" + tid);
      } else {
        navigate(
          "/superadmin/interteamplayerlist/" +
            id +
            "/" +
            did +
            "/" +
            tid +
            "/" +
            eid
        );
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/teamplayerlist/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/teamplayerlist/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/teamplayerlist/" + id + "/" + did + "/" + tid);
    } else if (localStorage.getItem("Role") === "7") {
      if(urllink === "externalteamlist") {
        navigate("/coach/externalteamplayerlist/" + id + "/" + did);
      } else if (urllink === "teamlist") {
        navigate("/coach/teamplayerlist/" + id + "/" + did + "/" + tid);
      } else {
        navigate(
          "/coach/interteamplayerlist/" + id + "/" + did + "/" + tid + "/" + eid
        );
      }
      
    }
  }

  function deleteteam(did, ename,cdata) {
   
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if(urllink === "externalteamlist")  {

          dotracking(
            "delete team icon in team list for " +
              ename +
              " (" +
              did +
              ") team in " +
              cdata.age_category_name +
              " (" +
              cdata.age_category_id +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.teamlistheading
          );
          if (localStorage.getItem("Role") === "7") {
            externaltourteamdelete(did).then((response) => {
              if (response.status === true) {
                listall(ttype, urllink);
                toast.success(response.message);
              }
            });
          }
        }else{
          dotracking(
            "delete team icon in team list for " +
              ename +
              " (" +
              did +
              ") team in " +
              catname +
              " (" +
              tid +
              ") category for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.teamlistheading
          );
          if (localStorage.getItem("Role") === "1") {
            teamdelete(did).then((response) => {
              if (response.status === true) {
                listall(ttype, urllink);
                toast.success(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "3") {
            teamdeletecityreg(did).then((response) => {
              if (response.status === true) {
                listall(ttype, urllink);
                toast.success(response.message);
              }
            });
          } else if (localStorage.getItem("Role") === "7") {
            teamdeletecoach(did).then((response) => {
              if (response.status === true) {
                listall(ttype, urllink);
                toast.success(response.message);
              }
            });
          }
        }
       
      }
    });
  }

  function expteam() {
    dotracking(
      "export team button in category list for " +
        catname +
        " (" +
        tid +
        ") category in " +
        tname +
        " ( " +
        id +
        ") tournament $$ " +
        trackobj.teamlistheading
    );
    window.open(exportUrl + "export-school-teams/" + id, "_blank");
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Team Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            {urllink !== "externalteamlist" && (
              <li className="breadcrumb-item">
                <a>Tournament Lists</a>
              </li>
            )}
            {urllink === "externalteamlist" && (
              <li className="breadcrumb-item">
                <a>External Tournament Lists</a>
              </li>
            )}

            <li className="breadcrumb-item active" aria-current="page">
              Team Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h3>{tname}</h3>
                    {urllink !== "externalteamlist" && (
                      <h4 className="text-success tx-20">
                        Category - {catname}
                      </h4>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      {(localStorage.getItem("Role") === "1" ||
                        localStorage.getItem("Role") === "3") &&
                        urllink === "teamlist" && (
                          <>
                            <a
                              className="btn btn-secondary me-2 mt-1"
                              onClick={expteam}
                            >
                              <i className="fa-solid fa-file-export"></i> Export
                              Team
                            </a>
                            {ttype !== "Inter School Tournament" && (
                              <a
                                className="btn btn-success me-2 mt-1"
                                onClick={addteam}
                              >
                                <i className="fa fa-plus"></i> Add Team
                              </a>
                            )}
                          </>
                        )}

                      {localStorage.getItem("Role") === "7" &&
                        tcreate === localStorage.getItem("UID") &&
                        urllink === "teamlist" && (
                          <>
                            <a
                              className="btn btn-secondary me-2 mt-1"
                              onClick={expteam}
                            >
                              <i className="fa-solid fa-file-export"></i> Export
                              Team
                            </a>
                            {ttype !== "Inter School Tournament" && (
                              <a
                                className="btn btn-success me-2 mt-1"
                                onClick={addteam}
                              >
                                <i className="fa fa-plus"></i> Add Team
                              </a>
                            )}
                          </>
                        )}

                      {localStorage.getItem("Role") === "7" &&
                        urllink === "externalteamlist" && (
                          <a className="btn btn-success me-2 mt-1" onClick={addteam}>
                            <i className="fa fa-plus"></i> Add Team
                          </a>
                        )}

                      {localStorage.getItem("Role") === "4" &&
                        !eclosed &&
                        filterProduct.length < parseInt(totteam) &&
                        ttype === "Inter School Tournament" && (
                          <a className="btnfixture me-2 mt-1" onClick={addteam}>
                            <i className="fa fa-plus"></i> Add Team
                          </a>
                        )}
                      {localStorage.getItem("Role") !== "4" &&
                        localStorage.getItem("Role") !== "7" &&
                        !eclosed &&
                        urllink !== "teamlist" &&
                        filterProduct.length < parseInt(totteam) &&
                        ttype === "Inter School Tournament" && (
                          <a className="btnfixture me-2 mt-1" onClick={addteam}>
                            <i className="fa fa-plus"></i> Add Team
                          </a>
                        )}
                      <a className="btn btn-gray mt-1" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row teamslist mt-4">
                  {filterProduct.length === 0 && (
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-header">
                          <h3 className="card-title">No Team Found</h3>
                        </div>
                      </div>
                    </div>
                  )}

                  {urllink === "externalteamlist" && (
                    <>
                      {filterProduct.map((data, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div className="card ">
                              <div className="card-header">
                                <h3 className="card-title">{data.team_name}</h3>
                              </div>
                              <div className="card-body alignMe">
                                <h4 className="text-center ">
                                  <span>{data.age_category_name}</span>
                                </h4>
                                <h4 className="borderteam-top"></h4>
                                <h4 className="h4 mb-2 mt-3">
                                  <b>Gender</b> <span>{data.gender}</span>
                                </h4>
                              </div>
                              <div className="card-footer text-center">
                                <a
                                  onClick={() =>
                                    editteam(data.id, data.team_name)
                                  }
                                  className="btn  btn-secondary btn-sm mt-1"
                                  title="Edit Team"
                                >
                                  <i className="fa fa-pencil-square-o"></i> Edit
                                  Team
                                </a>
                                <a
                                  onClick={() =>
                                    playerlist(data.id, data.team_name)
                                  }
                                  className="btn btn-warning btn-sm mt-1 ml-1"
                                  title="Player List"
                                >
                                  <i className="fa fa-server"></i> Player List
                                </a>
                                <a
                                  onClick={() =>
                                    deleteteam(data.id, data.team_name,data)
                                  }
                                  className="btn btn-danger btn-sm mt-1 ml-1"
                                  title="Delete Team"
                                >
                                  <i className="fa fa-trash"></i> Delete Team
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                  {urllink !== "externalteamlist" && (
                    <>
                      {filterProduct.map((data, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div className="card ">
                              <div className="card-header">
                                <h3 className="card-title">{data.team_name}</h3>
                              </div>
                              <div className="card-body alignMe">
                                <h4 className="text-center ">
                                  <span>{data.school_name}</span>
                                </h4>
                                <h4 className="borderteam-top"></h4>
                                <h4 className="h4 mb-2 mt-3">
                                  <b>Team Code</b> <span>{data.team_code}</span>
                                </h4>
                                <h4 className="h4 mb-0 mt-3">
                                  <b>Players Added</b>{" "}
                                  <span>{data.plist.length}</span>
                                </h4>
                              </div>
                              <div className="card-footer text-center">
                                {(localStorage.getItem("Role") === "1" ||
                                  localStorage.getItem("Role") === "3") &&
                                  ttype !== "Inter School Tournament" && (
                                    <a
                                      onClick={() =>
                                        editteam(data.id, data.team_name)
                                      }
                                      className="btn  btn-secondary btn-sm"
                                      title="Edit Team"
                                    >
                                      <i className="fa fa-pencil-square-o"></i>
                                    </a>
                                  )}

                                {localStorage.getItem("Role") === "7" &&
                                  localStorage.getItem("UID") === tcreate &&
                                  ttype !== "Inter School Tournament" && (
                                    <a
                                      onClick={() =>
                                        editteam(data.id, data.team_name)
                                      }
                                      className="btn  btn-secondary btn-sm"
                                      title="Edit Team"
                                    >
                                      <i className="fa fa-pencil-square-o"></i>
                                    </a>
                                  )}

                                {localStorage.getItem("Role") === "4" &&
                                  !eclosed &&
                                  ttype === "Inter School Tournament" && (
                                    <a
                                      onClick={() =>
                                        editteam(data.id, data.team_name)
                                      }
                                      className="btn  btn-secondary btn-sm"
                                      title="Edit Team"
                                    >
                                      <i className="fa fa-pencil-square-o"></i>{" "}
                                      Edit Team
                                    </a>
                                  )}
                                {localStorage.getItem("Role") !== "4" &&
                                  urllink !== "teamlist" &&
                                  !eclosed &&
                                  ttype === "Inter School Tournament" && (
                                    <a
                                      onClick={() =>
                                        editteam(data.id, data.team_name)
                                      }
                                      className="btn  btn-secondary btn-sm"
                                      title="Edit Team"
                                    >
                                      <i className="fa fa-pencil-square-o"></i>{" "}
                                      Edit Team
                                    </a>
                                  )}

                                {(localStorage.getItem("Role") === "1" ||
                                  localStorage.getItem("Role") === "3") &&
                                  ttype !== "Inter School Tournament" && (
                                    <a
                                      onClick={() =>
                                        addplayer(data.id, data.team_name)
                                      }
                                      className="btn btn-success btn-sm ml-1"
                                      title="Add Players"
                                    >
                                      <i className="fa fa-plus-square"></i>
                                    </a>
                                  )}

                                {/* {localStorage.getItem("Role") === "7" &&
                              localStorage.getItem("UID") === tcreate &&
                              ttype !== "Inter School Tournament" && (
                                <a
                                  onClick={() =>
                                    addplayer(data.id, data.team_name)
                                  }
                                  className="btn btn-success btn-sm ml-1"
                                  title="Add Players"
                                >
                                  <i className="fa fa-plus-square"></i>
                                </a>
                              )} */}

                                {ttype === "Inter School Tournament" &&
                                  localStorage.getItem("Role") === "4" && (
                                    <a
                                      onClick={() =>
                                        addplayer(data.id, data.team_name)
                                      }
                                      className="btn btn-success btn-sm ml-1"
                                      title="Add Players"
                                    >
                                      <i className="fa fa-plus-square"></i> Add
                                      Players
                                    </a>
                                  )}

                                {ttype === "Inter School Tournament" &&
                                  urllink !== "teamlist" &&
                                  localStorage.getItem("Role") !== "4" &&
                                  localStorage.getItem("Role") !== "7" && (
                                    <a
                                      onClick={() =>
                                        addplayer(data.id, data.team_name)
                                      }
                                      className="btn btn-success btn-sm ml-1"
                                      title="Add Players"
                                    >
                                      <i className="fa fa-plus-square"></i> Add
                                      Players
                                    </a>
                                  )}

                                {data.plist.length === 0 &&
                                  data.poollist.length === 0 && (
                                    <>
                                      {(localStorage.getItem("Role") === "1" ||
                                        localStorage.getItem("Role") === "3") &&
                                        ttype !== "Inter School Tournament" &&
                                        urllink === "teamlist" && (
                                          <a
                                            onClick={() =>
                                              deleteteam(
                                                data.id,
                                                data.team_name,""
                                              )
                                            }
                                            className="btn btn-danger btn-sm ml-1"
                                            title="Delete Team"
                                          >
                                            <i className="fa fa-trash"></i>
                                          </a>
                                        )}

                                      {localStorage.getItem("Role") === "7" &&
                                        localStorage.getItem("UID") ===
                                          tcreate &&
                                        ttype !== "Inter School Tournament" &&
                                        urllink === "teamlist" && (
                                          <a
                                            onClick={() =>
                                              deleteteam(
                                                data.id,
                                                data.team_name,""
                                              )
                                            }
                                            className="btn btn-danger btn-sm ml-1"
                                            title="Delete Team"
                                          >
                                            <i className="fa fa-trash"></i>
                                          </a>
                                        )}
                                    </>
                                  )}

                                {data.plist.length === 0 &&
                                  data.poollist.length === 0 && (
                                    <>
                                      {(localStorage.getItem("Role") === "1" ||
                                        localStorage.getItem("Role") === "3") &&
                                        ttype === "Inter School Tournament" &&
                                        urllink !== "teamlist" &&
                                        !eclosed && (
                                          <a
                                            onClick={() =>
                                              deleteteam(
                                                data.id,
                                                data.team_name,""
                                              )
                                            }
                                            className="btn btn-danger btn-sm ml-1"
                                            title="Delete Team"
                                          >
                                            <i className="fa fa-trash"></i>{" "}
                                            Delete Team
                                          </a>
                                        )}
                                    </>
                                  )}

                                {localStorage.getItem("Role") !== "4" &&
                                  urllink === "teamlist" && (
                                    <a
                                      onClick={() =>
                                        playerlist(data.id, data.team_name)
                                      }
                                      className="btn btn-warning btn-sm ml-1"
                                      title="Player List"
                                    >
                                      <i className="fa fa-server"></i>
                                    </a>
                                  )}
                                {localStorage.getItem("Role") !== "4" &&
                                  urllink !== "teamlist" && (
                                    <a
                                      onClick={() =>
                                        playerlist(data.id, data.team_name)
                                      }
                                      className="btn btn-warning btn-sm ml-1"
                                      title="Player List"
                                    >
                                      <i className="fa fa-server"></i> Player
                                      List
                                    </a>
                                  )}
                                {localStorage.getItem("Role") === "4" && (
                                  <a
                                    onClick={() =>
                                      playerlist(data.id, data.team_name)
                                    }
                                    className="btn btn-warning btn-sm ml-1"
                                    title="Player List"
                                  >
                                    <i className="fa fa-server"></i> Player List
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
