import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

function PrivateRoute(props) {
  const isAuth = localStorage.getItem("Token");
  return isAuth ? (
    <Routes>
      <Route {...props} />
    </Routes>
  ) : (
    <Navigate to="/login" />
  );
}
export default PrivateRoute;
