import React, { Fragment } from "react";
export const ContactList = () => {
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Contact Us</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
        </div>

        <div className="row" id="user-profile">
          <div className="col-lg-12">
            <form className="card mt-0">
              <div className="card-body">
                <div>
                  <div className="contact-us">
                    <div className="contact-grid">
                      <div className="contact-grid-1">
                        <div className="circle">
                          <img
                            src={require("../assets/images/sahlink.png")}
                            alt=""
                            className="mail"
                          />
                        </div>
                        <h3 className="card-head">To know more about</h3>
                        <p className="card-linkpara">
                          <a
                            href="https://pathwayz.sportzvillage.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mailtext-dark"
                          >
                            PathwayZ
                          </a>
                        </p>
                      </div>
                      <div className="contact-grid-1">
                        <div className="circle">
                          <img
                            src={require("../assets/images/sahmail.png")}
                            alt=""
                            className="mail"
                          />
                        </div>
                        <h3 className="card-head">For any queries please email on</h3>
                        <p className="card-para">pathwayz@sportzvillage.com</p>
                      </div>
                      <div className="contact-grid-1">
                        <div className="circle">
                          <img
                            src={require("../assets/images/sahphone.png")}
                            alt=""
                            className="mail"
                          />
                        </div>
                        <h3 className="card-head">For any queries please call us on</h3>
                        <p className="card-para">86682 75260</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
