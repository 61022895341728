import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import exportUrl from "../../../../../exportUtl";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  adminathletictedittour,
  athletictourschoollist,
  interschooltourschoollist,
  interschoolupdateofflinepayment,
} from "../../../../../services/common";
import Swal from "sweetalert2";
import { coachtourviewdetail } from "../../../../../services/coach";

export const SchoolTourlist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");
  const [ddate, setDDate] = useState("");
  const [tsport, setTSport] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "school_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
          if (
            params.data.orderstatus === "SUCCESS" &&
            localStorage.getItem("Role") === "1"
          ) {
            return (
              <div className="text-center">
                <a
                  onClick={() => editdet(params.value, params.data)}
                  title="Edit Players"
                  className="btn-sm btn-secondary me-2"
                >
                  <i className="fa fa-edit fa-lg"></i>
                </a>
              </div>
            );
          } else {
            return (
              <div className="text-center">
                <a
                  onClick={() => adddet(params.value, params.data)}
                  title="Add Players"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fa fa-user-plus fa-lg"></i>
                </a>
              </div>
            );
          }
        } else {
          return (
            <div className="text-center">
              <a className="text-center">Entry Closed</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "Payment",
      width: 100,
      field: "entry_sent_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
          if (
            params.data.sent === 0 && params.data.total_amount >0 && params.value !== null &&
            localStorage.getItem("Role") === "1" &&
            localStorage.getItem("Subrole") === "1"
          ) {
            return (
              <div className="text-center">
                <a
                  onClick={() => editpay(params.value, params.data)}
                  title="Edit Payment"
                  className="btn-sm btn-secondary me-2"
                >
                  <i className="fa fa-edit fa-lg"></i>
                </a>
              </div>
            );
          }
        } else {
          return (
            <div className="text-center">
              <a className="text-center">Entry Closed</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Players",
      width: 150,
      field: "total_players_added",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Amount",
      width: 150,
      field: "total_amount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order ID",
      width: 200,
      field: "orderid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment ID",
      width: 200,
      field: "paymentor_tracking_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "orderstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnTourDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "payment_row_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
          if (
            params.data.sent === 0 && params.data.total_amount >0 &&
            localStorage.getItem("Role") === "1" &&
            localStorage.getItem("Subrole") === "1"
          ) {
            return (
              <div className="text-center">
                <a
                  onClick={() => editpay(params.value, params.data)}
                  title="Edit Payment"
                  className="btn-sm btn-secondary me-2"
                >
                  <i className="fa fa-edit fa-lg"></i>
                </a>
              </div>
            );
          }
          if(localStorage.getItem("Role") === "1") {
            return (
              <div className="text-center">
                <a
                  onClick={() => addcat(params.value, params.data)}
                  title="Add Players"
                  className="btn-sm btn-success me-2"
                >
                  <i className="fa fa-user-plus fa-lg"></i>
                </a>
              </div>
            );
          }
        } else {
          return (
            <div className="text-center">
              <a className="text-center">Entry Closed</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "order_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount",
      width: 150,
      field: "total_amount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Discount",
      width: 150,
      field: "discount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Category",
      width: 150,
      field: "categories_selected",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Teams Allowed",
      width: 150,
      field: "teams_allowed",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Teams Added",
      width: 150,
      field: "teams_added",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order ID",
      width: 200,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment ID",
      width: 200,
      field: "payment_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
  ];

  const columnRefDefs = [
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Players",
      width: 150,
      field: "total_players_added",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Amount",
      width: 150,
      field: "total_amount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order ID",
      width: 200,
      field: "orderid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment ID",
      width: 200,
      field: "paymentor_tracking_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "orderstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function editdet(aid, adata) {
    Swal.fire({
      title: "Are you sure want to edit/add the players?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "edit player icon in tournament school list for " +
            adata.school_name +
            " (" +
            aid +
            ") school in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        const temp = new FormData();
        temp.append("school_id", aid);
        temp.append("tournament_meet_id", id);
        temp.append("entry_sent_id", adata.entry_sent_id);
        temp.append("admin_id", localStorage.getItem("UID"));
        adminathletictedittour(temp).then((res) => {
          if (res.status === true) {
            if (localStorage.getItem("Role") === "1") {
              navigate("/superadmin/playerlist/" + id + "/" + aid);
            } else if (localStorage.getItem("Role") === "3") {
              navigate("/cityregional/playerlist/" + id + "/" + aid);
            } else if (localStorage.getItem("Role") === "5") {
              navigate("/referee/playerlist/" + id + "/" + aid);
            }else if (localStorage.getItem("Role") === "7") {
              navigate("/coach/playerlist/" + id + "/" + aid);
            } 
          }
        });
      }
    });
  }

  function editpay(aid, adata) {
    Swal.fire({
      title: "Are you sure you want to update the payment status to Offline Payment?",
      showCancelButton: true,
      confirmButtonColor: "#30c102",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "update the payment in tournament school list for " +
            adata.school_name + " - " + adata.school_id +
            " (" +
            aid +
            ") school in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        const temp = new FormData();
        temp.append("payment_row_id", aid);
        temp.append("tournament_meet_id", id);
        interschoolupdateofflinepayment(temp).then((res) => {
          if (res.status === true) {
            if (
              localStorage.getItem("Role") === "1" &&
              localStorage.getItem("Subrole") === "1"
            ) {
              listall(tsport);
            }
          }
        });
      }
    });
  }

  function addcat(aid, adata) {
    dotracking(
      "add category icon in tournament school list for " +
        adata.school_name + " - " + adata.school_id +
        " (" +
        aid +
        ") school in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/interschoolpaymentlist/" + id + "/" + adata.school_id);
    } 
  }

  function adddet(aid, adata) {
    dotracking(
      "add player icon in tournament school list for " +
        adata.school_name +
        " (" +
        aid +
        ") school in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/playerlist/" + id + "/" + aid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/playerlist/" + id + "/" + aid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/playerlist/" + id + "/" + aid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/playerlist/" + id + "/" + aid);
    }
  }
  const [urllink, setUrlLink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setDDate(response.data.tournament.entry_deadline_date);
          setTSport(response.data.tournament.sports_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setDDate(response.data.tournament.entry_deadline_date);
          setTSport(response.data.tournament.sports_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setDDate(response.data.tournament.entry_deadline_date);
          setTSport(response.data.tournament.sports_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setDDate(response.data.tournament.entry_deadline_date);
          setTSport(response.data.tournament.sports_type);
          listall(response.data.tournament.sports_type);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setDDate(response.data.tournament.entry_deadline_date);
          setTSport(response.data.tournament.sports_type);
          listall(response.data.tournament.sports_type);
        }
      });
    } 
  }, []);

  function listall(ltype) {
    setFilterProduct([]);
    if (ltype.toUpperCase() === "ATHLETICS") {
      athletictourschoollist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.schools_list);
        }
      });
    } else {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      interschooltourschoollist(temp).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.schools_list);
        }
      });
    }
  }

  function entrylist() {

    dotracking(
      "players entry list button in school tournament list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    if(tsport.toUpperCase() === "ATHLETICS"){
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/athleticsentryplayerslist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/athleticsentryplayerslist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/athleticsentryplayerslist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/athleticsentryplayerslist/" + id);
    }
  }else{
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/entryplayerslist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/entryplayerslist/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/entryplayerslist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/entryplayerslist/" + id);
    }
  }
  }

  function backlist() {
    dotracking(
      "back button in school tournament list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentlist");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentlist");
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    } 
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Tournament School Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              School Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-9">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                      {/* <h4>{tloc}</h4> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="text-right">
                        <a
                          onClick={entrylist}
                          className="btn btn-secondary me-2"
                        >
                          <i className="fa fa-list-alt"></i> Entry List
                        </a>

                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  {localStorage.getItem("Role") === "4" && (
                    <AgGridReact
                      columnDefs={columnRefDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      //   onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                  {localStorage.getItem("Role") !== "4" && tsport.toUpperCase() === "ATHLETICS" && (
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      //   onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                   {localStorage.getItem("Role") !== "4" && tsport.toUpperCase() !== "ATHLETICS" && (
                    <AgGridReact
                      columnDefs={columnTourDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      //   onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
