import React, { useEffect, useState } from "react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { externaltourdetails } from "../../../../../services/common";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { coachviewprofile } from "../../../../../services/coach";

export const ViewExternalTour = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [mtype, setMType] = useState("");
  const [mstate, setMState] = useState("");
  const [mdist, setMDist] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [msport, setMSport] = useState("");
  const [mschool, setMSch] = useState("");
  const [mcat, setMCat] = useState("");
  const [tourcreate, setTourCreate] = useState("");
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Token") === null) {
      navigate("/login");
    }
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    if (localStorage.getItem("Role") === "7") {
        coachviewprofile().then((response) => {
          if (response.status === true) {
            setMSch(response.data.school_id);
          }
        })
      }
      externaltourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTourCreate(response.data.tournament.school_id);
          setMName(response.data.tournament.meet_name);
          setMLoc(response.data.tournament.location);
          setMType(response.data.tournament.meet_type_name);
          setMState(response.data.tournament.statename);
          setMDist(response.data.tournament.districtname);

          setMSDate(
            moment(response.data.tournament.start_date).format("DD-MM-YYYY")
          );
          setMEDate(
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
          );
          setMSport(response.data.tournament.sports_type);
         
          setMCat(response.data.tournament.categories);
         
        }
      });
     
    
 }, []);

  function backlist() {
    if(urllink === "externaltournamentdetails"){
      dotracking(
        "back button in view external tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.listextheading
      );
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/externaltournamentlist");
      }else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/externaltournamentlist");
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/externaltournamentlist");
      }
    }else{
      dotracking(
        "back button in view external tournament for " +
          mname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.dashheading
      );
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/dashboard");
      }else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/dashboard");
      }else if (localStorage.getItem("Role") === "4") {
        navigate("/schoolhead/dashboard");
      }else if (localStorage.getItem("Role") === "7") {
        navigate("/coach/dashboard");
      }
    }
    
    
  }

  function editpage() {
    dotracking(
      "edit button in view external tournament for " +
        mname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.editexttourheading
    );
   if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/editexternaltournament/" + id);
    }else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/editexternaltournament/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/editexternaltournament/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">External Tournament Details</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
          External Tournament Details
          </li>
        </ol>
      </div>

      <div className="row ">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-2 col-md-6">
                        <b className="text-inverse">Tournament Name</b>
                      </div>
                      <div className="col-md-10 col-md-6">
                        <span className="text-inverse">{mname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Location</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mloc}</span>
                      </div>
                    </div>
                  </div>
                </div>

             
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Tournament Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mtype}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mstate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">District</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{mdist}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msdate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{medate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Sports</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{msport}</span>
                      </div>
                    </div>
                  </div>
                </div>

               

                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Age Categories</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mcat}</span>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
             
              {localStorage.getItem("Role") === "7" && (
                <div className="text-right border-top">
                  {tourcreate === mschool && (
                    <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                  )}
                 
                  <a className="btn btn-gray mt-2" onClick={backlist}>
                    <i className="fe fe-corner-up-left"></i> Back
                  </a>
                </div>
              )}
              {(localStorage.getItem("Role") === "1" || localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "4") && (
                <div className="text-right border-top">
                  {urllink === "externaltournamentdetails" &&
                  <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                  }
                    
                 
                  <a className="btn btn-gray mt-2" onClick={backlist}>
                    <i className="fe fe-corner-up-left"></i> Back
                  </a>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
