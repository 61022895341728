import React, { Fragment, useEffect, useState } from "react";
import { schoolteamplayerslist } from "../../../../services/schoolhead";
import { schprofileview } from "../../../../services/schoolhead";
import { sportslist } from "../../../../services/master";
import Select from "react-select";
import { agecategoryaclivelist } from "../../../../services/superadmin";
import { coachviewprofile } from "../../../../services/coach";

export const SchoolTeamPlayers = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sportlist, setSportList] = useState([]);
  const [agelist, setAgeList] = useState([]);
  const [glist, setGList] = useState([]);
  const [gender, setGender] = useState([]);
  const [ageCat, setAgeCat] = useState([]);
  const [sports, setSports] = useState([]);
  const [schid, setSchID] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "4") {
      schprofileview().then((response) => {
        if (response.status === true) {
          setSchID(response.data.school_id);
          listall(response.data.school_id);
        }
      });
    }
    if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((response) => {
        if (response.status === true) {
          setSchID(response.data.school_id);
          listall(response.data.school_id);
        }
      });
    }
    let arr = [];
    arr.push(
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      }
    );
    setGList(arr);
    sportslist().then((response) => {
      if (response.status === true) {
        let arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].sports_cat_uid,
              label: response.data[i].sports_category,
            });
          }
        }
        setSportList(arr1);
      }
    });
    agecategoryaclivelist().then((response) => {
      if (response.status === true) {
        let arr1 = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              value: response.data[i].id,
              label: response.data[i].age_category_name,
            });
          }
        }
        setAgeList(arr1);
      }
    });
  }, []);

  function listall(scid) {
    const temp = new FormData();
    temp.append("school_id", scid);
    temp.append("gender_count", 0);
    temp.append("agecategory_count", 0);
    temp.append("sports_count", 0);
    schoolteamplayerslist(temp).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          setFilterProduct(res.data);
        }
      }
    });
  }
  function teamfilter() {
    setFilterProduct([]);
    const temp = new FormData();
    if (gender.length > 0) {
      for (let i = 0; i < gender.length; i++) {
        temp.append("gender_arr_" + [i + 1], gender[i].value);
      }
    }
    temp.append("gender_count", gender.length);
    temp.append("school_id", schid);
    temp.append("agecategory_count", ageCat.length);
    if (ageCat.length > 0) {
      for (let i = 0; i < ageCat.length; i++) {
        temp.append("agecategory_arr_" + [i + 1], ageCat[i].value);
      }
    }
    if (sports.length > 0) {
      for (let i = 0; i < sports.length; i++) {
        temp.append("sports_arr_" + [i + 1], sports[i].label);
      }
    }
    temp.append("sports_count", sports.length);
    schoolteamplayerslist(temp).then((res) => {
      if (res.status === true) {
        setFilterProduct(res.data);
      }
    });
  }
  function teamrefresh() {
    setFilterProduct([]);
    setAgeCat([]);
    setGender([]);
    setSports([]);
    const temp = new FormData();
    temp.append("gender_count", 0);
    temp.append("school_id", schid);
    temp.append("agecategory_count", 0);
    temp.append("sports_count", 0);
    schoolteamplayerslist(temp).then((res) => {
      if (res.status === true) {
        setFilterProduct(res.data);
      }
    });
  }

  function handleGender(e) {
    if (e !== null) {
      setGender(e);
    } else {
      setGender([]);
    }
  }

  function handleAgeCat(e) {
    if (e !== null) {
      setAgeCat(e);
    } else {
      setAgeCat([]);
    }
  }

  function handleSports(e) {
    if (e !== null) {
      setSports(e);
    } else {
      setSports([]);
    }
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">School Team Player Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Team Lists</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Team Player Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Gender</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Gender"
                        value={gender}
                        options={glist}
                        onChange={handleGender}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Sports</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Sports"
                        value={sports}
                        options={sportlist}
                        onChange={handleSports}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Age Category</label>
                      <Select
                        className="dropdown"
                        placeholder="Select Age Category"
                        value={ageCat}
                        options={agelist}
                        onChange={handleAgeCat}
                        isSearchable={true}
                        isMulti
                        isClearable
                        hidden
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="text-right mt-5">
                      <a className="btn btn-success me-2" onClick={teamfilter}>
                        Filter
                      </a>
                      <a className="btn btn-secondary" onClick={teamrefresh}>
                        Refresh
                      </a>
                    </div>
                  </div>
                </div>

                <div id="playerlist">
                  <div className="row">
                    {filterProduct.length === 0 && (
                      <div className="col-md-4">
                        <div className="box">
                          <div className="box__content text-center">
                            <h2 className="text-center">
                              Players will be added soon
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                    {filterProduct.map((data, i) => {
                      return (
                        <div className="col-md-4" key={i}>
                          <div className="box">
                            <div className="box__head">
                              <h1>{data.fullname}</h1>
                            </div>
                            <div className="box__content">
                              <h2>
                                <b>Player UID</b> <span>{data.username}</span>
                              </h2>
                              <h2>
                                <b>Class</b> <span>{data.class}</span>
                              </h2>
                              <h2>
                                <b>Section</b> <span>{data.section}</span>
                              </h2>
                              <h2>
                                <b>Age Category</b>{" "}
                                <span>{data.agecategory}</span>
                              </h2>
                              <h2>
                                <b>Sports</b> <span>{data.sports}</span>
                              </h2>
                            </div>
                            <div className="box_footer">
                              <h4>Team Name :-</h4>
                              <p>{data.school_team_name}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
