import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  geteventlistbydob,
  playertourpayment,
  playertourregister,
  tourdetails,
} from "../../../../services/register";
import moment from "moment";
import ImageUrl from "../../../../ImageUrl";
import {
  Playerviewdetails,
  playerathletetourregister,
} from "../../../../services/player";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Select from "react-select";
import { paymentsuccess } from "../../../../services/common";

export const AddTourPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tname, setTName] = useState("");
  const [tloc, setTLoc] = useState("");
  const [tdate, setTDate] = useState("");
  const [atype, setAType] = useState("");
  const [ttype, setTType] = useState("");

  const [agecat, setACat] = useState("");
  const [agecls, setACls] = useState("");
  const [sports, setSports] = useState("");
  const [ltype, setLType] = useState("");
  const [mcir, setMCir] = useState("");
  const [amt, setMAmt] = useState("");
  const [disper, setDisPer] = useState("");
  const [finalamt, setFinalAmt] = useState("");

  const [schoolid, setSchool] = useState("");
  const [maxeve, setMaxEve] = useState(0);
  const [eventid, setEventId] = useState("");
  const [elist, setEList] = useState([]);
  const [evelist, setEveList] = useState([]);
  const [maxtrackeve, setMaxTrackEve] = useState(0);
  const [maxfieldeve, setMaxFieldEve] = useState(0);
  const [agename, setAgeName] = useState([]);

  const [tfree, setTFree] = useState("");
  const [gender, setGender] = useState("");

  const [catid, setCatId] = useState([]);
  const [catlist, setCatList] = useState([
    { value: "1", label: "Singles" },
    { value: "2", label: "Doubles" },
    { value: "3", label: "Mixed Doubles" },
  ]);
  function handleCategory(e) {
    if (e === null) {
      setCatId([]);
    } else {
      setCatId(e);
    }
  }

  let arr = [];
  useEffect(() => {
    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        Playerviewdetails(localStorage.getItem("Name")).then((res1) => {
          if (res1.status === true) {
            setSchool(res1.data.athletelist.school_id);
            setGender(res1.data.athletelist.gender);
            if (
              response.data.tournament.sports_type.toUpperCase() === "ATHLETICS"
            ) {
              const temp = new FormData();
              temp.append("dob", res1.data.athletelist.date_of_birth);
              temp.append("gender", res1.data.athletelist.gender);
              temp.append("school_id", res1.data.athletelist.school_id);
              temp.append("tournament_meet_id", id);
              geteventlistbydob(temp).then((res) => {
                if (res.status === true) {
                  if (res.data.length !== 0) {
                    setACat(res.data[0].age_categories);
                    setAgeName(res.data[0]);

                    if (response.data.tournament.free_tournament !== "N") {
                      setMAmt(" Free");
                    } else {
                      setMAmt(res.data[0].fees + " /- Per Event");
                    }

                    setMaxTrackEve(res.data[0].max_event_athlete);
                    setMaxFieldEve(res.data[0].max_event_athlete_field);

                    setMaxEve(res.data[0].max_event_athlete);
                    for (let i = 0; i < res.data.length; i++) {
                      arr.push({
                        value: res.data[i].eventstable_id,
                        label: res.data[i].event_name,
                      });
                    }
                  }
                  setEList(arr);
                  setEveList(res.data);
                }
              });
            }
            if (response.data.mappedschools.length !== 0) {
              const sdata = response.data.mappedschools.find(
                (item) => item.school_id === res1.data.athletelist.school_id
              );
              setDisPer(sdata.discount);
              if (
                response.data.tournament.sports_type.toUpperCase() !==
                "ATHLETICS"
              ) {
                var famt = 0;
                var tamt = 0;
                famt =
                  (response.data.tournament.registration_fees *
                    sdata.discount) /
                  100;
                tamt = response.data.tournament.registration_fees - famt;
                setFinalAmt(tamt + " /-");
              }
            }
          }
        });

        setTName(response.data.tournament.meet_name);
        setTLoc(response.data.tournament.location);
        setTDate(
          moment(response.data.start_date).format("DD-MM-YYYY") +
            " to " +
            moment(response.data.end_date).format("DD-MM-YYYY")
        );
        setTType(response.data.tournament.meet_type);
        setAType(response.data.tournament.agecat_or_std);
        if (
          response.data.tournament.sports_type.toUpperCase() !== "ATHLETICS"
        ) {
          setACat(response.data.tournament.categories);
          setMAmt(response.data.tournament.registration_fees + "/-");
        }
        setTFree(response.data.tournament.free_tournament);
        if (response.data.tournament.agecat_or_std === 1) {
          var result = [];
          var cat = "";
          result = response.data.age_category.map(
            (option) => option.standard_name
          );
          if (result.length !== 0) {
            for (let i = 0; i < result.length; i++) {
              if (cat === "") {
                cat = result[i];
              } else if (cat !== "") {
                cat = cat + " , " + result[i];
              }
            }
          }
          setACls(cat);
        }
        setSports(response.data.tournament.sports_type);
        setLType(response.data.tournament.league_type);
        if (response.data.tournament.circular_file_1 !== null) {
          setMCir(
            ImageUrl +
              "tournament/circular/" +
              response.data.tournament.circular_file_1
          );
        }
      }
    });
  }, []);

  function backlist() {
    if (localStorage.getItem("Page") === "1") {
      dotracking(
        "back button in tournament register for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.elilistheading
      );
      navigate("/player/tournamentlist");
    } else {
      dotracking(
        "back button in tournament register for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.dashheading
      );
      navigate("/player/dashboard");
    }
  }

  const [errorMsg, setErrorMsg] = useState("");
  function playersubmit() {
    setErrorMsg("");
    if (sports.toUpperCase() === "ATHLETICS") {
      var eve = "";
      var ename = "";
      var aid = "";
      var aname = "";
      if (eventid.length > 0) {
        aid = agename.age_category_id;
        aname = agename.age_category_name;
        for (let j = 0; j < eventid.length; j++) {
          if (eve === "") {
            eve = eventid[j].value;
            ename = eventid[j].label;
          } else {
            eve = eve + "," + eventid[j].value;
            ename = ename + "," + eventid[j].label;
          }
        }
      }
      setLoading(true);

      const temp = new FormData();
      temp.append("user_id", localStorage.getItem("UID"));
      temp.append("tournament_id", id);
      temp.append("eventid", eve);
      temp.append("gender", gender);
      temp.append("category_id", aid);
      temp.append("tournament_category", aname);

      dotracking(
        "register button in tournament register for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourregheading
      );
      playerathletetourregister(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          if (tfree === "N") {
            paynow(
              response.data.RAZORPAY_KEY,
              response.data.order_id,
              response.data.entries.crm_or_school_id,
              response.data.entries.total_amount,
              response.data.entries.id
            );
          } else if (tfree === "Y") {
            if (localStorage.getItem("Page") === "1") {
              dotracking(
                "redirect to eligible list in tournament register for " +
                  tname +
                  " (" +
                  id +
                  ") tournament $$ " +
                  trackobj.elilistheading
              );
              navigate("/player/tournamentlist");
            } else if (localStorage.getItem("Page") === "2") {
              dotracking(
                "redirect to dashboard in tournament register for " +
                  tname +
                  " (" +
                  id +
                  ") tournament $$ " +
                  trackobj.dashheading
              );
              navigate("/player/dashboard");
            }
            toast.success(response.message);
          }
        } else {
          setErrorMsg(response.message);
        }
      });
    } else {
      var valid = "";
      if (sports.toUpperCase() === "BADMINTON" ||
      sports.toUpperCase() === "TENNIS" ||
      sports.toUpperCase() === "TABLE TENNIS" ||
      sports.toUpperCase() === "SQUASH") {
        if (catid.length !== 0) {
          valid = "";
        } else {
          valid = "Please Select Category Type";
          setErrorMsg("Please Select Category Type");
        }
      }

      if (valid === "") {
        setLoading(true);
        const temp = new FormData();
        temp.append("username", localStorage.getItem("Name"));
        temp.append("tournament_meet_id", id);
        temp.append("school_id", schoolid);
        if (sports.toUpperCase() === "BADMINTON" ||
        sports.toUpperCase() === "TENNIS" ||
        sports.toUpperCase() === "TABLE TENNIS" ||
        sports.toUpperCase() === "SQUASH") {
          let sdata = "";
          let ddata = "";
          let sddata = "";

          if (catid.length !== 0) {
            sdata = catid.find((item) => item.value === "1");
            ddata = catid.find((item) => item.value === "2");
            sddata = catid.find((item) => item.value === "3");
          }
          if (sdata !== undefined) {
            temp.append("singles", 1);
          } else {
            temp.append("singles", 0);
          }
          if (ddata !== undefined) {
            temp.append("doubles", 1);
          } else {
            temp.append("doubles", 0);
          }
          if (sddata !== undefined) {
            temp.append("mixed_doubles", 1);
          } else {
            temp.append("mixed_doubles", 0);
          }
        } else {
          temp.append("singles", 0);
          temp.append("doubles", 0);
          temp.append("mixed_doubles", 0);
        }
        dotracking(
          "register button in tournament register for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourregheading
        );
        playertourregister(temp).then((response) => {
          if (response.status === true) {
            setLoading(false);
            if (tfree === "N") {
              paynow(
                response.data.RAZORPAY_KEY,
                response.data.entries.order_id,
                response.data.entries.user_id,
                0,
                0
              );
            } else if (tfree === "Y") {
              if (localStorage.getItem("Page") === "1") {
                dotracking(
                  "redirect to eligible list in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.elilistheading
                );
                navigate("/player/tournamentlist");
              } else if (localStorage.getItem("Page") === "2") {
                dotracking(
                  "redirect to dashboard in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.dashheading
                );
                navigate("/player/dashboard");
              }
              toast.success(response.message);
            }
          } else {
            setLoading(false);
            setErrorMsg(response.message);
          }
        });
      }
    }
  }
  const [loading, setLoading] = useState(false);

  async function paynow(pkey, ordid, uid, tamt, tid) {
    const options = {
      key: pkey,
      order_id: ordid,
      name: "League Management Platform",
      description: "Tournament Registration",
      image: require("../../../../assets/images/favicon.png"),
      handler: function (response) {
        if (sports.toUpperCase() === "ATHLETICS") {
          const temp = new FormData();
          temp.append("school_id", schoolid);
          temp.append("admin_id", uid);
          temp.append("razorpay_payment_id", response.razorpay_payment_id);
          temp.append("totalAmount", tamt);
          temp.append("current_meet_id", id);
          temp.append("order_id", ordid);
          temp.append("current_ins_id", tid);
          setLoading(true);
          paymentsuccess(temp).then((response) => {
            if (response.status === true) {
              setLoading(false);

              if (localStorage.getItem("Page") === "1") {
                dotracking(
                  "redirect to eligible list in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.elilistheading
                );
                navigate("/player/tournamentlist");
              } else if (localStorage.getItem("Page") === "2") {
                dotracking(
                  "redirect to dashboard in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.dashheading
                );
                navigate("/player/dashboard");
              }
              toast.success(response.message);
            }
          });
        } else {
          const temp = new FormData();
          temp.append("payment_id", response.razorpay_payment_id);
          temp.append("order_id", ordid);
          temp.append("user_id", uid);
          temp.append("tournament_meet_id", id);
          setLoading(true);

          playertourpayment(temp).then((response) => {
            if (response.status === true) {
              setLoading(false);

              if (localStorage.getItem("Page") === "1") {
                dotracking(
                  "redirect to eligible list in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.elilistheading
                );
                navigate("/player/tournamentlist");
              } else if (localStorage.getItem("Page") === "2") {
                dotracking(
                  "redirect to dashboard in tournament register for " +
                    tname +
                    " (" +
                    id +
                    ") tournament $$ " +
                    trackobj.dashheading
                );
                navigate("/player/dashboard");
              }
              toast.success(response.message);
            }
          });
        }
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function handleEvent(e) {
    if (e === null) {
      setEventId([]);
    } else {
      let teve = 0;
      let feve = 0;
      var msg = "";
      if (e.length !== 0) {
        for (let i = 0; i < e.length; i++) {
          const tdata = evelist.find(
            (item) => item.eventstable_id === e[i].value
          );
          if (tdata.event_type === 1) {
            teve = teve + 1;
          }
          if (tdata.event_type === 3) {
            feve = feve + 1;
          }
        }
        if (maxtrackeve >= teve) {
        } else {
          msg = "Maximum " + maxtrackeve + " track events can be selected";
          toast.error(
            "Maximum " + maxtrackeve + " track events can be selected"
          );
        }

        if (maxfieldeve >= feve) {
        } else {
          msg = "Maximum " + maxfieldeve + " field events can be selected";
          toast.error(
            "Maximum " + maxfieldeve + " field events can be selected"
          );
        }

        if (msg === "") {
          setEventId(e);
        }
      }
    }
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Tournament Register</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tournament Register
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h3>{tname}</h3>
                    {/* <h4><i className='fa fa-map-marker errortext me-2'></i>{tloc}</h4> */}
                    <h4>
                      <i className="fa fa-calendar text-success me-2"></i>
                      {tdate}
                    </h4>
                  </div>
                  <div className="row tournamentitem">
                    {atype === 2 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Age Category</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{agecat}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {atype === 1 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Class</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{agecls}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Sports</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{sports}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">League</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{ltype}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Circular</b>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <span className="text-inverse">
                              <a href={mcir} target="_blank" download={mcir}>
                                <b className="blink">
                                  Click Here!{" "}
                                  <i className="fa fa-download ms-2"></i>
                                </b>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Fees</b>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <span className="text-inverse">
                              <h4 className="errortext">
                                {tfree === "N" && <b>{amt}</b>}
                                {tfree === "Y" && <b>Free</b>}
                              </h4>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {disper !== 0 && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Discount %</b>
                            </div>
                            <div className="col-md-8 align-self-center">
                              <span className="text-inverse">
                                <h4 className="errortext">
                                  <b>{disper} %</b>
                                </h4>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {disper !== 0 && sports.toUpperCase() !== "ATHLETICS" && (
                      <div className="col-md-6">
                        <div className="details-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Total Amount</b>
                            </div>
                            <div className="col-md-8 align-self-center">
                              <span className="text-inverse">
                                <h4 className="errortext">
                                  <b>{finalamt}</b>
                                </h4>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="details-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Location</b>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <span className="text-inverse">{tloc}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {sports.toUpperCase() === "ATHLETICS" && (
                      <div className="col-md-6">
                        <div className="input-field">
                          <label className="form-label">
                            Events <span className="errortext">*</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select Event"
                            value={eventid}
                            options={elist}
                            onChange={handleEvent}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                        </div>
                      </div>
                    )}
                    {(sports.toUpperCase() === "BADMINTON" ||
      sports.toUpperCase() === "TENNIS" ||
      sports.toUpperCase() === "TABLE TENNIS" ||
      sports.toUpperCase() === "SQUASH") && (
                      <div className="col-md-6">
                        <div className="input-field">
                          <label className="form-label">
                            Category Type <span className="errortext">*</span>
                          </label>
                          <Select
                            className="dropdown"
                            placeholder="Select Category Type"
                            value={catid}
                            options={catlist}
                            onChange={handleCategory}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {errorMsg !== "" && (
                    <span className="errortext">{errorMsg}</span>
                  )}
                  <div className="card-footer text-right border-top">
                    <a className="btn btn-gray me-2" onClick={backlist}>
                      Back
                    </a>
                    {ttype === "Intra School Tournament" && (
                      <a className="btn btn-success" onClick={playersubmit}>
                        Register
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
