import React, { useEffect, useState } from "react";
import { allcmsresourcelist } from "../../../../services/superadmin";
import { funderlist, schoollistmaster } from "../../../../services/master";
import Select from "react-select";
import { cmstaglist } from "../../../../services/common";
import { Col, Modal, Row } from "react-bootstrap";

export const AllResouces = () => {
  const [reslist, setResList] = useState([]);

  const [respath, setResPath] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [tagid, setTagId] = useState([]);
  const [schid, setSchId] = useState([]);

  const [slist, setSList] = useState([]);
  const [tlist, setTList] = useState([]);

  const [funid, setFunId] = useState([]);
  const [flist, setFList] = useState([]);


  useEffect(() => {
    allcmsresourcelist("", "", "", "","").then((res) => {
      if (res.status === true) {
        setResPath(res.data.logo_path);
        setResList(res.data.all_resources);
      }
    });

    schoollistmaster().then((res) => {
      if (res.status === true) {
        let arr = [];
        for (var i = 0; i < res.data.details.length; i++) {
          arr.push({
            label: res.data.details[i].school_name,
            value: res.data.details[i].id,
          });
        }
        setSList(arr);
      }
    });

    funderlist().then((res) => {
      if (res.status === true) {
        let arr = [];
        for (var i = 0; i < res.data.funders_list.length; i++) {
          arr.push({
            label: res.data.funders_list[i].funder_name,
            value: res.data.funders_list[i].id,
          });
        }
        setFList(arr);
      }
    });
    cmstaglist().then((res) => {
      if (res.status === true) {
        let arr = [];
        for (var i = 0; i < res.data.tags_list.length; i++) {
          arr.push({
            label: res.data.tags_list[i].tag_name,
            value: res.data.tags_list[i].id,
          });
        }
        setTList(arr);
      }
    });
  }, []);

  function handleSch(e) {
    if (e === null) {
      setSchId([]);
    } else {
      setSchId(e);
    }
  }

  function handleTag(e) {
    if (e === null) {
      setTagId([]);
    } else {
      setTagId(e);
    }
  }

  function handleFun(e) {
    if (e === null) {
      setFunId([]);
    } else {
      setFunId(e);
    }
  }
  function search() {
    let sid = "";
    if (schid.length !== 0) {
      for (var i = 0; i < schid.length; i++) {
        if (sid == "") {
          sid = schid[i].value;
        } else {
          sid = sid + "," + schid[i].value;
        }
      }
    }

    let tid = "";
    if (tagid.length !== 0) {
      for (var i = 0; i < tagid.length; i++) {
        if (tid == "") {
          tid = tagid[i].value;
        } else {
          tid = tid + "," + tagid[i].value;
        }
      }
    }

    let fid = "";
    if (funid.length !== 0) {
      for (var i = 0; i < funid.length; i++) {
        if (fid == "") {
          fid = funid[i].value;
        } else {
          fid = fid + "," + funid[i].value;
        }
      }
    }
    setResList([]);
    allcmsresourcelist(sdate, edate, tid, sid,fid).then((response) => {
      if (response.status === true) {
        setResPath(response.data.logo_path);
        setResList(response.data.all_resources);
      }
    });
  }

  const [ishow, setIShow] = useState(false);
  const [pimage, setPImage] = useState(false);
  const [pimgtype, setPImgType] = useState("");
  const [pimgurl, setPImgUrl] = useState("");
  const [prem, setPRem] = useState("");
  const [ptag, setPTag] = useState("");
  const [psch, setPSch] = useState("");

  function handlepop(img, itype, ifile, irem, iname, isch) {
    if (itype === "Video") {
      if (ifile === "url") {
        let newstr1 = img.substr(img.length - 11);
        let curvideo = "";
        let res = img.substring(0, 23);
        curvideo = "https://www.youtube.com";
        if (curvideo == res) {
          setPImage(`https://www.youtube.com/embed/${newstr1}`);
        }
      } else {
        setPImage(respath + img);
      }
    } else {
      setPImage(respath + img);

      // setPImage(img+"#toolbar=0&navpanes=0&statusbar=0&view=Fit;readonly=true");
    }
    setPSch(isch);
    setPTag(iname);
    setPRem(irem);
    setPImgUrl(ifile);
    setIShow(true);
    setPImgType(itype);
  }

  function downloaded(iurl, ifile) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", iurl);
    link.download = ifile;
    document.body.appendChild(link);
    link.click();
    link.remove();
    // const fileKey = iurl; 
    // downloadFile(fileKey);
  }

  function imageclose() {
    setIShow(false);
    setPImage("");
    setPRem("");
    setPSch("");
    setPTag("");
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">All Resources</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>CMS</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            All Resources
          </li>
        </ol>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                className="form-control"
                value={sdate}
                onChange={(e) => setSDate(e.target.value)}
                // onBlur={search}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">Ends Date</label>
              <input
                type="date"
                className="form-control"
                value={edate}
                onChange={(e) => setEDate(e.target.value)}
                // onBlur={search}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">School</label>
              <Select
                className="dropdown"
                placeholder="Select School"
                value={schid}
                options={slist}
                onChange={handleSch}
                // onBlur={search}
                isSearchable={true}
                isMulti
                isClearable
                hidden
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">Tags</label>
              <Select
                className="dropdown"
                placeholder="Select Tags"
                value={tagid}
                options={tlist}
                onChange={handleTag}
                // onBlur={search}
                isSearchable={true}
                isMulti
                isClearable
                hidden
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">Funder</label>
              <Select
                className="dropdown"
                placeholder="Select Funder"
                value={funid}
                options={flist}
                onChange={handleFun}
                // onBlur={search}
                isSearchable={true}
                isMulti
                isClearable
                hidden
              />
            </div>
          </div>
          <div className="col-md-4">
             <div className="form-group text-right">
              <a className="btn btn-success mt-6 mx-7 me-2" onClick={search}>
                Apply Filter
              </a>
            </div>
          </div>
        </div>
        {reslist.length == 0 && (
          <div className="container">
            <h3 className="text-center errortext">
              No resources available for the above selection
            </h3>
          </div>
        )}
        {reslist.length !== 0 && (
          <div className="container mt-2">
            <div className="row gutter-y-30">
              {reslist.map((item, index) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                    key={index}
                  >
                    <div className="services-three__card">
                      <div className="photores-download">
                        {item.file_or_url != "url" && (
                          <a
                            onClick={() =>
                              downloaded(
                                respath + item.file_name,
                                item.file_name
                              )
                            }
                          >
                            <i className="fa fa-download fa-lg"></i>
                          </a>
                        )}
                        {item.file_or_url == "url" && (
                          <a href={item.file_name} target="_blank">
                            <i className="fa fa-download fa-lg"></i>
                          </a>
                        )}
                      </div>
                      {item.mark_as_star === 1 && (
                        <div className="photores-star">
                          <a>
                            <i className="fa fa-star fa-lg"></i>
                          </a>
                        </div>
                      )}
                      {item.file_type === "Photo" && (
                        <a
                          className="services-three__card__image"
                          onClick={() =>
                            handlepop(
                              item.file_name,
                              item.file_type,
                              item.file_or_url,
                              item.remarks,
                              item.tag_name,
                              item.school_name
                            )
                          }
                        >
                          <img
                            src={respath + item.file_name}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <img
                            src={respath + item.file_name}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <span className="services-three__card__overlay"></span>
                        </a>
                      )}
                      {item.file_type === "Video" && (
                        <a
                          className="services-three__card__image"
                          onClick={() =>
                            handlepop(
                              item.file_name,
                              item.file_type,
                              item.file_or_url,
                              item.remarks,
                              item.tag_name,
                              item.school_name
                            )
                          }
                        >
                          <img
                            src={require("../../../../assets/images/video.png")}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <img
                            src={require("../../../../assets/images/video.png")}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <span className="services-three__card__overlay"></span>
                        </a>
                      )}

                      {item.file_type === "PDF" && (
                        <a
                          className="services-three__card__image"
                          onClick={() =>
                            handlepop(
                              item.file_name,
                              item.file_type,
                              item.file_or_url,
                              item.remarks,
                              item.tag_name,
                              item.school_name
                            )
                          }
                        >
                          <img
                            src={require("../../../../assets/images/pdf.png")}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <img
                            src={require("../../../../assets/images/pdf.png")}
                            width="200px"
                            height="200px"
                            alt="business loan"
                          />
                          <span className="services-three__card__overlay"></span>
                        </a>
                      )}
                      <div className="services-one__card__content">
                        <h4 className="text-success">{item.school_name}</h4>
                        <h4>{item.tag_name}</h4>
                        <h5>{item.remarks}</h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <Modal show={ishow} size="lg" onHide={imageclose}>
          <Modal.Header>
            <h4> Resources</h4>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-sm mx-0">
              <Col lg={12} md={12}>
                <div className="form-group">
                  {pimgtype === "Video" && pimgurl === "url" && (
                    <iframe
                      width="100%"
                      height="300"
                      src={pimage}
                      frameBorder="0"
                      // allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  )}

                  {pimgtype === "Video" && pimgurl !== "url" && (
                    <video controls width="100%" height="300">
                      <source src={pimage} type="video/mp4" />
                    </video>
                  )}

                  {pimgtype === "PDF" && (
                    <iframe
                      width="100%"
                      height="400"
                      src={pimage}
                      allowFullScreen
                    />
                  )}

                  {pimgtype === "Photo" && (
                    <img src={pimage} alt="Photo" width="100%" />
                  )}
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <h4 className="text-success">{psch}</h4>
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <h4>{ptag}</h4>
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <p>{prem}</p>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-danger" onClick={imageclose}>
              Close
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
