import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  assessmentdatedetail,
  assessmentgrouplist,
  assessmentpullrequest,
  gettokenfromapi,
  schoolactivelist,
  schoolteamlist,
} from "../../../../services/superadmin";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { sahassessmentdatelist } from "../../../../services/sahapi";

export const AssessmentDetailAdmin = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Assessment Date",
      field: "date",
      // field: "assessment_date",

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   if (params.value != null) {
      //     return moment(params.value).format("DD-MM-YYYY");
      //   }
      // },
    },

    {
      headerName: "School",
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      // field: "first_name",
      field: "student_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Username (UID)",
    //   field: "username",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      field: "esid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data)}
              title="Assessment Details"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function viewlist(vid, vdata) {
    dotracking(
      "view icon in assessment list for " +
        // assname +
        // " - " +
        // assdate +
        // " (" +
        // id +
        // " assessment in " +
        vdata.date +
        " (" +
        vid +
        ") $$ " +
        trackobj.assplayerlistheading
    );
    navigate("/superadmin/assessmentplayerdetails/" + id + "/" + vid);
  }
  let arr1 = [];
  const [schlist, setSchList] = useState([]);
  const [schid, setSchId] = useState("");
  const [assname, setAssName] = useState("");
  const [assdate, setAssDate] = useState("");
  const [datestr, setDateStr] = useState("");
  const [dateid, setDateID] = useState("");

  useEffect(() => {
    listall(0, 10, "", "");
    // const temp = new FormData();
    // temp.append("date_id", id);
    // assessmentdatedetail(temp).then((response) => {
    //   if (response.status === true) {
    //     setAssName(response.data.assessmentDate.assessment_heading);
    //     setAssDate(response.data.assessmentDate.assessment_date);
    //     setDateStr(response.data.assessmentDate.assessment_date_string);
    //     setDateID(response.data.assessmentDate.id);
    //   }
    // });

    // schoolteamlist().then((response)=>{
    //   if(response.status === true){
    //     setFilterProduct(response.data)
    //   }
    // })

    schoolactivelist().then((response) => {
      if (response.status === true) {
        if (response.data.details.length !== 0) {
          arr1 = [];
          for (let i = 0; i < response.data.details.length; i++) {
            arr1.push({
              value: response.data.details[i].school_unique_no,
              label:
                response.data.details[i].school_unique_no +
                " - " +
                response.data.details[i].school_name,
            });
          }
        }
        setSchList(arr1);
      }
    });
  }, []);

  function listall(spage, epage, sid, pyr) {
    setFilterProduct([]);
    setLoading(true);
    var ldata = id;
    if (sid !== "") {
      ldata = sid.value + ldata;
    }
    if (pyr !== "") {
      ldata = pyr + ldata;
    }
    ldata = ldata + spage + epage;
    const temp = new FormData();
    temp.append("data_req", "asmnt_data_within_date_range" + ldata);
    gettokenfromapi(temp).then((res) => {
      if (res.status === true) {
        var parr =
          "asmnt_data_within_date_range&chid=&player_name=&asmnt_date=";
        if (sid !== "" && pyr !== "") {
          parr =
            "asmnt_data_within_date_range&chid=" +
            sid.value +
            "&player_name=" +
            pyr +
            "&asmnt_date=";
        } else if (sid !== "") {
          parr =
            "asmnt_data_within_date_range&chid=" +
            sid.value +
            "&player_name=&asmnt_date=";
        } else if (pyr !== "") {
          parr =
            "asmnt_data_within_date_range&chid=&player_name=" +
            pyr +
            "&asmnt_date=";
        }
        sahassessmentdatelist(
          parr + id + "&start_limit=" + spage + "&end_limit=" + epage,
          res.data
        ).then((response) => {
          setLoading(false);
          setFilterProduct(response);
        });
      }
    });

    // assessmentgrouplist(id).then((response) => {
    //   if (response.status === true) {
    //     setFilterProduct(response.data.assessment_groups);
    //   }
    // });
  }
  const [show, setShow] = useState(false);

  function handleshow() {
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setErrorMsg("");
  }

  function handleSchool(e) {
    if (e !== null) {
      setSchId(e);
      listall(spage, epage, e, plyid);
    } else {
      setSchId("");
    }
  }

  const navigate = useNavigate();
  function backlist() {
    dotracking(
      "back button in assessment group list for " +
        // assname +
        // " - " +
        // assdate +
        // " (" +
        id +
        " assessment $$ " +
        trackobj.assgrplistheading
    );
    navigate("/superadmin/assessmentupload");
  }

  function submitass() {
    var sid = "";
    var sname = "";
    if (schid.length !== 0) {
      for (let i = 0; i < schid.length; i++) {
        if (sid === "") {
          sid = schid[i].value;
          sname = schid[i].label;
        } else {
          sid = sid + "," + schid[i].value;
          sname = sname + "," + schid[i].label;
        }
      }
    }
    if (sid !== "") {
      setLoading(true);
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("school_id", sid);
      temp.append("assessment_date_id", dateid);
      temp.append("assessment_date", datestr);
      temp.append("assessment_date_string", assdate);
      dotracking(
        "submit button in assessment group list for " +
          sname +
          " in " +
          assname +
          " - " +
          assdate +
          " (" +
          id +
          ") assessment $$ " +
          trackobj.assgrplistheading
      );

      assessmentpullrequest(temp).then((response) => {
        if (response !== "Unauthorized.") {
          setLoading(false);
          handleclose();
          toast.success(response.message);
          // listall();
        } else {
          handleclose();
          toast.error(response);
        }
      });
    } else {
      setErrorMsg("Please Select School");
    }
  }

  function overallpage(e) {
    setPage(e.target.value);
    setEPage(e.target.value);
    setSPage(0);
    listall(spage, e.target.value, schid, plyid);
  }

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(10);
  const [spage, setSPage] = useState(0);
  const [epage, setEPage] = useState(10);
  const [plyid, setPlayer] = useState("");

  function nextlist() {
    dotracking(
      "next button in assessment group list for " +
        id +
        " assessment $$ " +
        trackobj.assgrplistheading
    );
    setSPage(spage + 10);
    setEPage(epage + 10);
    listall(spage + 10, epage + 10, schid, plyid);
  }
  function prevlist() {
    dotracking(
      "previous button in assessment group list for " +
        id +
        " assessment $$ " +
        trackobj.assgrplistheading
    );
    setSPage(spage - 10);
    setEPage(epage - 10);
    listall(spage - 10, epage - 10, schid, plyid);
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Assessment Group List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Assessment Group List
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <div className="row">
            <div className="col-12">
              <form className="card mt-0">
                <div className="card-body">
                  {/* <div className="row">
                    <div className="col-md-9">
                      <h3>{assname}</h3>
                    </div>
                    <div className="col-md-3">
                      <div className="text-right">
                         <a className="btn btn-success me-2" onClick={handleshow}>
                        <i className="fa fa-plus"></i> Add Assessment
                      </a> 
                        <a className="btn btn-gray" onClick={backlist}>
                          <i className="fe fe-corner-up-left"></i> Back
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">School</label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                        hidden
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label text-left">Player</label>
                      <input
                        value={plyid}
                        placeholder="Enter Player Name"
                        className="form-control"
                        onChange={(e) => setPlayer(e.target.value)}
                        onBlur={(e) =>
                          listall(spage, epage, schid, e.target.value)
                        }
                      />
                    </div>

                    <div className="col-md-2">
                      <label className="form-label">Page Size</label>
                      <select
                        value={page}
                        className="form-control"
                        onChange={overallpage}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4 text-right">
                      {spage > 0 && (
                        <a
                          className="btn btn-secondary me-2 mt-6"
                          onClick={prevlist}
                        >
                          Previous Page
                        </a>
                      )}

                      <a
                        className="btn btn-success me-2 mt-6"
                        onClick={nextlist}
                      >
                        Next Page
                      </a>
                      <a className="btn btn-gray mt-6" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>

                  {/* <Modal show={show} size="lg" onHide={handleclose}>
                  <Modal.Header>
                    <h4> Add Assessment</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            School <span className="errortext"> *</span>
                          </label>

                          <Select
                            className="dropdown"
                            placeholder="Select School"
                            value={schid}
                            options={schlist}
                            onChange={handleSchool}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                        </div>
                      </Col>

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    <a className="btn btn-success" onClick={submitass}>
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal> */}
                </div>
              </form>
            </div>
            <div className="ag-theme-alpine ag-style mt-2" style={{ height: '100%' }}>
              <AgGridReact
                columnDefs={columnDefs}
                rowData={filterProduct}
                // pagination="true"
                // paginationPageSize="10"
                sortingOrder={sorting}
                suppressExcelExport={true}
                suppressDragLeaveHidesColumns={true}
                domLayout="autoHeight"
                // onGridReady={(params) => {
                //   params.api.sizeColumnsToFit();
                // }}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
