import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function userregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/athlete-register`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function athleticsregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-ind-register`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function validatemail(data) {
  try {
    return await axios
      .get(`${baseUrl}/validate-mail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function validatemobile(data) {
  try {
    return await axios
      .get(`${baseUrl}/validate-mobile/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function regpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/athlete-pay-success`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function playertourregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/meet-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function playertourpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/individual_meet_pay_success`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function tourdetails(id) {
  try {
    return await axios
      .get(`${baseUrl}/meet-view-details/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



export async function tourclasslist(id) {
  try {
    return await axios
      .get(`${baseUrl}/listclassfortournament/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Player Class List based on category
export async function tourcategorymappedlist(data) {
  try {
    return await axios
      .post(`${baseUrl}/team-category-mapped-list`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function validateregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/namedobgenderschoolvalid`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function registeredplayer(data) {
  try {
    return await axios
      .post(`${baseUrl}/already-paid-user-or-not`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function registerplayerpaymentpending(uid,tid) {
  try {
    return await axios
      .get(`${baseUrl}/userstournament/${uid}/${tid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function playerpaymentorder(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-orderid-razorpay`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function athleticsplayerpaymentorder(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-razorpay-inter-ind-orderid`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function accountregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/account-register`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function duplicateplayer(data) {
  try {
    return await axios
      .post(`${baseUrl}/duplicate-user-check`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function checkamount(data) {
  try {
    return await axios
      .post(`${baseUrl}/test-razorpay`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function checkamountsuccess(data) {
  try {
    return await axios
      .post(`${baseUrl}/test-razorpay-success`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Athletics Sports Event list
export async function geteventlistbydob(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-events-based-on-details`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



export async function athleticsplayerpaymentpending(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletics-inter-user-pay`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function athleticsplayertourpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/update-inter-ind-payment-success`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function mysclperformance(data) {
  try {
    return await axios
      .post(`${baseUrl}/overall-individual-champions-list-with-school`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function MyUniquePerformance(data) {
  try {
    return await axios
      .post(`${baseUrl}/players-medal-counts`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Event exist player or not
export async function eventexistplayerornot(uname,id) {
  try {
    return await axios
      .get(`${baseUrl}/event-athlete-exist-validate/${uname}/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Event details
export async function eventdetailswithstring(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-event-info`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Event registeration with username
export async function playereventregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/event-registration-by-username`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Event Individual payment
export async function playereventpayment(data) {
  try {
    return await axios
      .post(`${baseUrl}/individual-event-pay-success`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Paid or not for event
export async function eventplayerpaidornot(data) {
  try {
    return await axios
      .post(`${baseUrl}/paid-or-not-for-event`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
//Event Individual register
export async function eventindividualregister(data) {
  try {
    return await axios
      .post(`${baseUrl}/player-event-register`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
//Event player payment pending
export async function eventpaymentorder(data) {
  try {
    return await axios
      .post(`${baseUrl}/get-orderid-razorpay-events`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function eventregisterplayerpaymentpending(uid,tid) {
  try {
    return await axios
      .get(`${baseUrl}/usersevents/${uid}/${tid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


function catcherror(error) {
  console.log(error.response)
  if (error.response.status === 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
