import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function coachplayerlist(st) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/list/${st}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function coachaddplayer(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/players/add-player`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Profile API
export async function schcoachlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/schooladmin/coach/all-coaches`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Multi Role Email Validate
export async function coachemailvalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/schooladmin/roles/validate-roleusers-email/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Profile API
export async function coachviewprofile() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/view`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Tournament List API
export async function coachtourlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Tournament type active list 
export async function coachtourtypelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/meettype-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Sports active list 
export async function coachsportslist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/sports-category-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Age Category  list 
export async function coachagecatelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/age-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//School Active list 
export async function schoolactivelistcoach() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/mschool/Mschool-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Add tournament 
export async function coachtouradd(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "multipart/form-data",
    };
    return await axios
      .post(`${baseUrl}/coach/tournament/create`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Update tournament 
export async function coachtourupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "multipart/form-data",
    };
    return await axios
      .post(`${baseUrl}/coach/tournament/update`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//View tournament 
export async function coachtourviewdetail(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/view-details/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//referee list
export async function coachrefereelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/referee-list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Assign list to Tournament
export async function coachassignrefereelist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/meet-mapped-referee-list/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Assign referee tournament Cityadmin
export async function coachtourrefereeassign(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/tournament/refree-assign-for-meet`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Referee Assign delete to Tournament
export async function coachassignrefdeletelist(id, rid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/meet-referee-delete/${id}/${rid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Add Age Categories to tournament 
export async function coachtouraddagecategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/tournament/create-agecategory`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team List based on tournament
export async function teamlistcoach(id, tid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/teamlist/${id}/${tid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Player List for Tournament
export async function teamselectedplayerlistcoach(id, tid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/team-player-list/${id}/${tid}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Delete based on tournament
export async function teamdeletecoach(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/delete-team/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Create based on tournament
export async function teamcreatecoach(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/create-team`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Update based on tournament
export async function teamupdatecoach(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/edit-team`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team View based on tournament
export async function teamdetailscoach(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/view-team-details/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Player List for Tournament
export async function teamplayerlistcoach(data, tid,cid, eid,sid,sear) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/search-player-list?tournament_meet_id=${data}&team_id=${tid}&class_id=${cid}&category_id=${eid}&school_id=${sid}&usernamesearch=${sear}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Team Player Create for Tournament
export async function teamplayercreatecoach(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/add-players-to-team`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Team Player List for racquet sports Tournament
export async function racquetteamplayerlistcoach(data, tid,cid, eid,sid,sear,group) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/search-player-list-for-racquet-sports?tournament_meet_id=${data}&team_id=${tid}&class_id=${cid}&category_id=${eid}&school_id=${sid}&usernamesearch=${sear}&group_type=${group}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}






//Age Categories based on tournament
export async function agecategorytourcoach(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/category-list-each-tour/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



//Team Player Delete for Tournament
export async function teamplayerdeletecoach(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/remove-team-player/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//tournament entry list
export async function coachtourentrylist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/tournament/tournament_entries/${id}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//View Player
export async function coachplayerview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/players/athlete-details/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Add Performance by player individual
export async function coachaddperformance(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/track-performance-by-coach`,data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


//Update Performance by player individual
export async function coachupdateperformance(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/edit-track-performance-by-coach`,data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



//Delete Performance by player individual
export async function coachdeleteperformance(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/delete-track-performance-by-coach`,data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Performance View by individual
export async function coachplayerperformancedetail(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/view-selected-track-performance-by-coach`, data,{ headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Performance List by individual
export async function coachplayerperformancelist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/view-track-performance-by-coach`, data,{ headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//External Performance List by individual
export async function coachplayerexternalperformancelist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/coach/view-track-performance-by-coach-ext`, data,{ headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Achievement list
export async function coachachievelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/coach/get-achievements-based-on-school`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//Other Event List
export async function coachothereventlist(id) {
  try {
    return await axios
      .get(`${baseUrl}/otherevent/eventlistbyschool/`+id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


function catcherror(error) {
  console.log(error.response);
  if (error.response.status === 401) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status === 422) {
    Swal.fire({
      title:
        "There are few errors occured while inserting/updating the data to the database.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //       window.location.reload();
      //   }
    });
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
