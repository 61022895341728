import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import Select from 'react-select';
import { emailtemplatecreate, emailtempmasterlist, templateemaillist } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const EmailKeyword = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [ekeyid, setId] = useState(0);
    const [ekeyname, setKeyName] = useState("");
    const [etempid, setETempid] = useState("");
    const [etemplist, setETempList] = useState([]);

    const [edesc, setEDesc] = useState("");

    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
    }, []);

    const columnDefs = [
        {
            headerName: "Keyword",
            field: "keywords",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Template",
            field: "template_name",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Description",
            field: "description",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "is_active",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onChange={() => deletekeyword(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        // {
        //     headerName: "Actions",
        //     field: "id",
        //     resizable: true,
        //     filter: false,
        //     cellRenderer: function (params) {
        //         return (
        //             <div className="text-center">
        //                 <a onClick={() => viewkeyword(params.value)} title="Edit Class"
        //                     className="btn-sm btn-secondary"
        //                 >
        //                     <i className="fa fa-edit fa-lg"></i>
        //                 </a>
        //             </div>
        //         );
        //     },
        // },
    ];

    function viewkeyword(aid) {
        setShow(true)
        // classdetails(aid).then((response) => {
        //     if (response.status === true) {
        //         setId(response.data.id)
        //         setKeyName(response.data.class)
        //         setStatus(response.data.is_active + "")
        //     }
        // })
    }

    function handleshow() {
        setId(0);
        setKeyName("")
        setETempid("");
        setEDesc("")
        setStatus("1")
        setErrorMsg("")
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setKeyName("")
        setETempid("");
        setEDesc("")
        setStatus("1")
        setErrorMsg("")
    }

    function deletekeyword(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 0)
                    // classstatus(temp).then((response) => {
                    //     if (response.status === true) {
                    //         listall();
                    //         toast.success(response.message)
                    //     }
                    // })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.id)
                    temp.append("is_active", 1)
                    // classstatus(temp).then((response) => {
                    //     if (response.status === true) {
                    //         listall();
                    //         toast.success(response.message)
                    //     }
                    // })
                }
            });
        }
    }
    useEffect(() => {
        listall();
        templateemaillist().then((response) => {
            if (response.status === true) {
                let arr1 = [];
                for (let i = 0; i < response.data.length; i++) {
                    arr1.push({
                        value: response.data[i].temp_id,
                        label: response.data[i].template_name,
                    });
                }
                setETempList(arr1)
            }
        })
    }, []);

    function listall() {
        setFilterProduct([]);
        emailtempmasterlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }

    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function submitkeyword(e) {
        e.preventDefault();
        setErrorMsg("")
        if (ekeyname !== "") {
            if (etempid !== "") {
                if (edesc !== "") {
                    var eid = ""
                    for (let i = 0; i < etempid.length; i++) {
                        if (eid === "") {
                            eid = etempid[i].value
                        } else {
                            eid = eid + "," + etempid[i].value
                        }
                    }
                    if (ekeyid === 0) {
                        const temp = new FormData();
                        temp.append("keyword_name", ekeyname)
                        temp.append("description", edesc)
                        temp.append("templates", eid)
                        dotracking("add button for keyword in keyword list for " + ekeyname + " $$ " + trackobj.keywordlistheading)
                        emailtemplatecreate(temp).then((response) => {
                            if (response.status === true) {
                                handleclose();
                                listall();
                                toast.success(response.message);
                            }
                        })

                    } else if (ekeyid !== 0) {
                        const temp = new FormData();
                        temp.append("id", ekeyid)
                        temp.append("class", ekeyname)
                        temp.append("is_active", status)
                        // classupdate(temp).then((response) => {
                        //     if (response.status === true) {
                        //         handleclose();
                        //         listall();
                        //         toast.success(response.message);
                        //     }
                        // })
                    }
                } else {
                    setErrorMsg("Please Enter Description")
                }
            } else {
                setErrorMsg("Please Select Template")
            }

        } else {
            setErrorMsg("Please Enter Keyword")
        }
    }

    function handleKey(e) {
        setETempid(e)
    }


    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Template Keyword Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        Template Keyword Lists
                    </li>
                </ol>
            </div>
            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            <div className="text-right">
                                <a className="btn btn-success" onClick={handleshow}>
                                    <i className="fa fa-plus"></i> Add Keyword
                                </a>
                            </div>
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {ekeyid !== 0 &&
                                        <h4> Keyword Details</h4>
                                    }
                                    {ekeyid === 0 &&
                                        <h4> Add Keyword</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Keyword{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Keyword"
                                                    className="form-control"
                                                    value={ekeyname}
                                                    onChange={(e) => setKeyName(e.target.value)}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Template{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Select Template"
                                                    value={etempid}
                                                    options={etemplist}
                                                    onChange={handleKey}
                                                    isSearchable={true}
                                                    isMulti
                                                    isClearable
                                                    hidden
                                                />
                                            </div>
                                        </Col>


                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Description{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <textarea
                                                    rows={3}
                                                    placeholder="Description"
                                                    className="form-control"
                                                    value={edesc}
                                                    onChange={(e) => setEDesc(e.target.value)}
                                                />
                                            </div>
                                        </Col>


                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {ekeyid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitkeyword}
                                        >
                                            Update
                                        </a>
                                    }
                                    {ekeyid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitkeyword}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
