import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { useMemo } from "react";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  funderlist,
  grpschlist,
  schoollistmaster,
} from "../../../../services/master";
import {
  multirolesdetails,
  schoolmanagercreate,
  schoolmanagerdelete,
  schoolmanagerlist,
} from "../../../../services/superadmin";

export const SchoolCRM = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [schid, setSchId] = useState("");
  const [slist, setSList] = useState([]);
  const [fundname, setFundName] = useState("");

  const [s3logo, setS3Logo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => deletesch(params.value, params)}
              title="Delete School"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CHID",
      width: 150,
      field: "school_unique_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "City",
      width: 150,
      field: "city_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 150,
      field: "school_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Group Of School",
      width: 150,
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports Infra",
      width: 150,
      field: "school_sports_infra",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 100,
      field: "school_fees",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "school_logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
  ];

  const columnFDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => deletesch(params.value, params)}
              title="Delete Funder"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Funder Name",
      field: "funder_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Description",
      field: "funder_description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnGDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => deletesch(params.value, params)}
              title="Delete Funder"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Logo",
      width: 100,
      field: "logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Group of School Name",
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Description",
      field: "group_of_school_description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnMGDefs = [
    {
      headerName: "Logo",
      width: 100,
      field: "logo",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return (
            <div className="text-center">
              <img
                style={{ cursor: "pointer", width: "35px", height: "35px" }}
                src={s3logo + params.value}
                onClick={() => logoshow(params.value)}
              />
            </div>
          );
        } else {
          return <div className="text-center">-</div>;
        }
      },
    },
    {
      headerName: "Group of School Name",
      field: "group_of_school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Description",
      field: "group_of_school_description",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const [lshow, setLShow] = useState(false);
  const [schlogo, setSchLogo] = useState("");

  function logoshow(img) {
    if (img !== null) {
      setLShow(true);
      setSchLogo(s3logo + img);
    }
  }
  function logoclose() {
    setLShow(false);
    setSchLogo("");
  }

  const [urllink, setUrlLink] = useState("");
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split("/")[2];
    setUrlLink(path);
    listall(path);
    if (path === "schoolmanager") {
      schoollistmaster().then((response) => {
        if (response.status === true) {
          let arr = [];
          if (response.data.details.length > 0) {
            for (let i = 0; i < response.data.details.length; i++) {
              arr.push({
                label: response.data.details[i].school_name,
                value: response.data.details[i].id,
              });
            }
            setSList(arr);
          }
        }
      });
    } else if (path === "fundermanager") {
      funderlist().then((response) => {
        if (response.status === true) {
          let arr = [];
          if (response.data.funders_list.length > 0) {
            for (let i = 0; i < response.data.funders_list.length; i++) {
              arr.push({
                label: response.data.funders_list[i].funder_name,
                value: response.data.funders_list[i].id,
              });
            }
            setSList(arr);
          }
        }
      });
    } else if (path === "groupschoolmanager") {
      grpschlist().then((response) => {
        if (response.status === true) {
          let arr = [];
          if (response.data.funders_list.length > 0) {
            for (let i = 0; i < response.data.funders_list.length; i++) {
              arr.push({
                label: response.data.funders_list[i].group_of_school_name,
                value: response.data.funders_list[i].id,
              });
            }
            setSList(arr);
          }
        }
      });
    }

    if (localStorage.getItem("Role") === "1") {
      multirolesdetails(3, id).then((response) => {
        if (response.status === true) {
          setFundName(response.data.details.first_name);
        }
      });
    }
  }, [location.pathname]);

  function listall(ltype) {
    setFilterProduct([]);
    if (ltype === "schoolmanager") {
      schoolmanagerlist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.school_list);
          setS3Logo(response.data.school_logo_path);
        }
      });
    } else if (ltype === "fundermanager") {
      if (localStorage.getItem("Role") === "1") {
        schoolmanagerlist(id).then((response) => {
          if (response.status === true) {
            setFilterProduct(response.data.funder_list);
            setS3Logo(response.data.funder_logo_path);
          }
        });
      } else {
        schoolmanagerlist(localStorage.getItem("UID")).then((response) => {
          if (response.status === true) {
            setFilterProduct(response.data.funder_list);
            setS3Logo(response.data.funder_logo_path);
          }
        });
      }
    } else if (ltype === "groupschoolmanager") {
      if (localStorage.getItem("Role") === "1") {
        schoolmanagerlist(id).then((response) => {
          if (response.status === true) {
            setFilterProduct(response.data.group_of_schools_list);
            setS3Logo(response.data.group_logo_path);
          }
        });
      } else {
        schoolmanagerlist(localStorage.getItem("UID")).then((response) => {
          if (response.status === true) {
            setFilterProduct(response.data.group_of_schools_list);
            setS3Logo(response.data.group_logo_path);
          }
        });
      }
    }
  }

  const [sshow, setSShow] = useState(false);

  function schshow() {
    setErrorMsg("");
    setSchId("");
    setSShow(true);
  }

  function schclose() {
    setErrorMsg("");
    setSchId("");
    setSShow(false);
  }

  function handleSchool(e) {
    if (e !== null) {
      setSchId(e);
    } else {
      setSchId("");
    }
  }

  function schsubmit(e) {
    e.preventDefault();
    setErrorMsg("");
    if (urllink === "schoolmanager") {
      if (schid !== "") {
        dotracking(
          "submit button in manager school list " +
            schid.label +
            " (" +
            schid.value +
            ") for manager " +
            fundname +
            " (" +
            id +
            ") $$ " +
            trackobj.crmschlistheading
        );
        schoolmanagercreate(id, 1, schid.value).then((response) => {
          if (response.status === true) {
            schclose();
            listall(urllink);
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        setErrorMsg("Please Select School");
      }
    } else if (urllink === "fundermanager") {
      if (schid !== "") {
        dotracking(
          "submit button in funder school list " +
            schid.label +
            " (" +
            schid.value +
            ") for manager " +
            fundname +
            " (" +
            id +
            ") $$ " +
            trackobj.crmfunlistheading
        );
        if (localStorage.getItem("Role") === "1") {
          schoolmanagercreate(id, 2, schid.value).then((response) => {
            if (response.status === true) {
              schclose();
              listall(urllink);
              toast.success(response.message);
            } else {
              setErrorMsg(response.message);
            }
          });
        } else {
          schoolmanagercreate(localStorage.getItem("UID"), 2, schid.value).then(
            (response) => {
              if (response.status === true) {
                schclose();
                listall(urllink);
                toast.success(response.message);
              } else {
                setErrorMsg(response.message);
              }
            }
          );
        }
      } else {
        setErrorMsg("Please Select Funder");
      }
    } else if (urllink === "groupschoolmanager") {
      if (schid !== "") {
        dotracking(
          "submit button in group of school school list " +
            schid.label +
            " (" +
            schid.value +
            ") for manager " +
            fundname +
            " (" +
            id +
            ") $$ " +
            trackobj.crmgrplistheading
        );
        if (localStorage.getItem("Role") === "1") {
          schoolmanagercreate(id, 3, schid.value).then((response) => {
            if (response.status === true) {
              schclose();
              listall(urllink);
              toast.success(response.message);
            } else {
              setErrorMsg(response.message);
            }
          });
        } else {
          schoolmanagercreate(localStorage.getItem("UID"), 3, schid.value).then(
            (response) => {
              if (response.status === true) {
                schclose();
                listall(urllink);
                toast.success(response.message);
              } else {
                setErrorMsg(response.message);
              }
            }
          );
        }
      } else {
        setErrorMsg("Please Select Funder");
      }
    }
  }

  function deletesch(did, ddata) {
    if (urllink === "schoolmanager") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete icon in manager school list for" +
              ddata.data.school_name +
              " (" +
              did +
              ") for manager " +
              fundname +
              " (" +
              id +
              ") $$ " +
              trackobj.crmschlistheading
          );

          schoolmanagerdelete(id, 1, did).then((res) => {
            if (res.status === true) {
              toast.success(res.message);
              listall(urllink);
            }
          });
        }
      });
    } else if (urllink === "fundermanager") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete icon in manager funder list for" +
              ddata.data.school_name +
              " (" +
              did +
              ") for manager " +
              fundname +
              " (" +
              id +
              ") $$ " +
              trackobj.crmfunlistheading
          );
          if (localStorage.getItem("Role") === "1") {
            schoolmanagerdelete(id, 2, did).then((res) => {
              if (res.status === true) {
                toast.success(res.message);
                listall(urllink);
              }
            });
          } else {
            schoolmanagerdelete(localStorage.getItem("UID"), 2, did).then(
              (res) => {
                if (res.status === true) {
                  toast.success(res.message);
                  listall(urllink);
                }
              }
            );
          }
        }
      });
    } else if (urllink === "groupschoolmanager") {
      Swal.fire({
        text: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Delete",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "delete icon in manager group school list " +
              ddata.data.school_name +
              " (" +
              did +
              ") for manager " +
              fundname +
              " (" +
              id +
              ") $$ " +
              trackobj.crmgrplistheading
          );

          if (localStorage.getItem("Role") === "1") {
            schoolmanagerdelete(id, 3, did).then((res) => {
              if (res.status === true) {
                toast.success(res.message);
                listall(urllink);
              }
            });
          } else {
            schoolmanagerdelete(localStorage.getItem("UID"), 3, did).then(
              (res) => {
                if (res.status === true) {
                  toast.success(res.message);
                  listall(urllink);
                }
              }
            );
          }
        }
      });
    }
  }
  function backlist() {
    if (urllink === "schoolmanager") {
      dotracking(
        "back button in school list for " +
          // tname +
          " (" +
          id +
          ") $$ " +
          trackobj.crmlistheading
      );
    } else if (urllink === "fundermanager") {
      dotracking(
        "back button in funder list for " +
          // tname +
          " (" +
          id +
          ") $$ " +
          trackobj.crmlistheading
      );
    } else if (urllink === "groupschoolmanager") {
      dotracking(
        "back button in group school list for " +
          // tname +
          " (" +
          id +
          ") $$ " +
          trackobj.crmlistheading
      );
    }

    navigate("/superadmin/cityregionallist");
  }

  const getPlaceholder = () => {
    switch (urllink) {
      case "schoolmanager":
        return "Select School";
      case "fundermanager":
        return "Select Funder";
      case "groupschoolmanager":
        return "Select Group of Schools";
      default:
        return "Select Option";
    }
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          {urllink === "schoolmanager" && (
            <li className="breadcrumb-item active">
              <h4 className="mb-0">School Lists</h4>
            </li>
          )}
          {urllink === "fundermanager" && (
            <li className="breadcrumb-item active">
              <h4 className="mb-0">Funder Lists</h4>
            </li>
          )}
          {urllink === "groupschoolmanager" && (
            <li className="breadcrumb-item active">
              <h4 className="mb-0">Group of Schools Lists</h4>
            </li>
          )}
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Manager</a>
          </li>

          {urllink === "schoolmanager" && (
            <li className="breadcrumb-item active">
              <a>School Lists</a>
            </li>
          )}
          {urllink === "fundermanager" && (
            <li className="breadcrumb-item active">
              <a>Funder Lists</a>
            </li>
          )}
          {urllink === "groupschoolmanager" && (
            <li className="breadcrumb-item active">
              <a>Group of Schools Lists</a>
            </li>
          )}
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <h4>{fundname}</h4>
                </div>
                <div className="col-md-4">
                  <div className="text-right">
                    {urllink === "schoolmanager" &&
                      localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-success me-2" onClick={schshow}>
                          <i className="fa fa-plus"></i> Add School
                        </a>
                      )}
                    {urllink === "fundermanager" &&
                      localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-success me-2" onClick={schshow}>
                          <i className="fa fa-plus"></i> Add Funder
                        </a>
                      )}
                    {urllink === "groupschoolmanager" &&
                      localStorage.getItem("Role") === "1" && (
                        <a className="btn btn-success me-2" onClick={schshow}>
                          <i className="fa fa-plus"></i> Add Group of School
                        </a>
                      )}
                    {localStorage.getItem("Role") === "1" && (
                      <a className="btn btn-gray me-2 mt-1" onClick={backlist}>
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine ag-style mt-2">
                {urllink === "schoolmanager" && (
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    sortingOrder={sorting}
                  />
                )}

                {urllink === "fundermanager" && (
                  <AgGridReact
                    columnDefs={columnFDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    sortingOrder={sorting}
                  />
                )}

                {urllink === "groupschoolmanager" &&
                  localStorage.getItem("Role") !== "3" && (
                    <AgGridReact
                      columnDefs={columnGDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      sortingOrder={sorting}
                    />
                  )}
                {urllink === "groupschoolmanager" &&
                  localStorage.getItem("Role") === "3" && (
                    <AgGridReact
                      columnDefs={columnMGDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      sortingOrder={sorting}
                    />
                  )}
              </div>

              <Modal show={lshow} size="lg" onHide={logoclose}>
                <Modal.Header>
                  <h4> School Logo</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <img src={schlogo} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={logoclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={sshow} size="lg" onHide={schclose}>
                <Modal.Header>
                  {urllink === "schoolmanager" && <h4> School</h4>}
                  {urllink === "fundermanager" && <h4> Funder</h4>}
                  {urllink === "groupschoolmanager" && (
                    <h4> Group of Schools</h4>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        {urllink === "schoolmanager" && (
                          <label className="mb-2">
                            School <span className="errortext"> *</span>
                          </label>
                        )}
                        {urllink === "fundermanager" && (
                          <label className="mb-2">
                            Funder <span className="errortext"> *</span>
                          </label>
                        )}
                        {urllink === "groupschoolmanager" && (
                          <label className="mb-2">
                            Group of Schools{" "}
                            <span className="errortext"> *</span>
                          </label>
                        )}

                        <Select
                          className="dropdown"
                          placeholder={getPlaceholder()}
                          value={schid}
                          options={slist}
                          onChange={handleSchool}
                          isSearchable={true}
                          isClearable
                          hidden
                        />
                      </div>
                    </Col>

                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-success me-2" onClick={schsubmit}>
                    Submit
                  </a>
                  <a className="btn btn-danger" onClick={schclose}>
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
