import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import {
  agecatactivelist,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  crmagecatelist,
  crmtourviewdetail,
} from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  addathleteagecategorylist,
  athagecatedit,
  createnewagecat,
  updateagecat,
} from "../../../../../services/common";
import Select from "react-select";

import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourclasslist } from "../../../../../services/register";
import { coachagecatelist, coachtourviewdetail } from "../../../../../services/coach";
import { classlist } from "../../../../../services/master";

export const ClassAgeCategoryList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);

  const [show, setShow] = useState(false);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [tname, setTName] = useState("");
  const [sport, setMSport] = useState("");
  const [agetype, setMAgeType] = useState("");

  const [alist, setAList] = useState([]);

  const [agecatid, setAgeCatID] = useState("");
  const [agecat, setAgeCat] = useState("");
  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  let arr = [];
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      field: "id",
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            {(localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3") && (
              <a
                onClick={() => editline(params.value)}
                title="Edit Age Category"
                className="btn-sm btn-success me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Age Categories",
      field: "age_categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB Start Date",
      field: "dob_start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return moment(params.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "DOB End Date",
      field: "dob_end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return moment(params.value).format("DD-MM-YYYY");
        }
      },
    },
  ];

  const columnSDefs = [
    {
      headerName: "Class",
      field: "class",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMAgeType(response.data.tournament.agecat_or_std);
          setMSport(response.data.tournament.sport_type);
          if (response.data.tournament.agecat_or_std === 2) {
            agecatactivelist(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr.push({
                      value: response.data[i].id,
                      label: response.data[i].age_category_name,
                    });
                  }
                }
                setAList(arr);
              }
            });
          }
          listall(response.data.tournament.agecat_or_std);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMAgeType(response.data.tournament.agecat_or_std);
          setMSport(response.data.tournament.sport_type);
          if (response.data.tournament.agecat_or_std === 2) {
            crmagecatelist(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr.push({
                      value: response.data[i].id,
                      label: response.data[i].age_category_name,
                    });
                  }
                }
                setAList(arr);
              }
            });
          }
          listall(response.data.tournament.agecat_or_std);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMAgeType(response.data.tournament.agecat_or_std);
          setMSport(response.data.tournament.sport_type);
          listall(response.data.tournament.agecat_or_std);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMAgeType(response.data.tournament.agecat_or_std);
          setMSport(response.data.tournament.sport_type);
          listall(response.data.tournament.agecat_or_std);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMAgeType(response.data.tournament.agecat_or_std);
          setMSport(response.data.tournament.sport_type);
          if (response.data.tournament.agecat_or_std === 2) {
            coachagecatelist(id).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  arr = [];
                  for (let i = 0; i < response.data.length; i++) {
                    arr.push({
                      value: response.data[i].id,
                      label: response.data[i].age_category_name,
                    });
                  }
                }
                setAList(arr);
              }
            });
          }
          listall(response.data.tournament.agecat_or_std);
        }
      });
    }
  }, []);

  function listall(lformat) {
    setFilterProduct([]);
    if (lformat === 2) {
      addathleteagecategorylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.age_categories);
        }
      });
    } else {
      tourclasslist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
      if(localStorage.getItem("Role")==="1"){
        
      }
      classlist().then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            let arr1 = [];
            for (let i = 0; i < response.data.length; i++) {
              arr1.push({
                value: response.data[i].id,
                label: response.data[i].class,
              });
            }
            setAList(arr1);
          }
        }
      });
    }
  }

  function handleclose() {
    setShow(false);
    setErrorMessage("");
    setAgeCatID("");
    setAgeCat("");
    setSDOB("");
    setEDOB("");
  }

  function handleshow() {
    setShow(true);
    setErrorMessage("");
    setAgeCatID("");
    setAgeCat("");
    setSDOB("");
    setEDOB("");
  }

  function editline(eid) {
    setShow(true);
    athagecatedit(eid).then((response) => {
      setAgeCatID(response.data.id);
      setAgeCat(response.data.age_categories);
      setSDOB(response.data.dob_start_date);
      setEDOB(response.data.dob_end_date);
    });
  }
  function addmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    var valid = "";
    if (agetype === 2) {
      if (agecat !== "") {
        if (sdob !== "") {
          if (edob !== "") {
            valid = "";
          } else {
            setErrorMessage("Please Enter End Date");
          }
        } else {
          setErrorMessage("Please Enter Start Date");
        }
      } else {
        setErrorMessage("Please Select Age Category");
      }
    } else {
      if (agecat !== "") {
        valid = "";
      } else {
        setErrorMessage("Please Select Class");
      }
    }

    if (valid === "") {
      const tempData = new FormData();
      tempData.append("dob_start_date", sdob);
      tempData.append("dob_end_date", edob);
      tempData.append("tournament_meet_id", id);
      tempData.append("max_event_athlete", 0);
      tempData.append("max_event_athlete_field", 0);
      tempData.append("max_athlete_event", 0);
      tempData.append("max_athlete_field_event", 0);
      tempData.append("athletes_per_relay", 0);
      tempData.append("athletes_per_mixed_relay", 0);
      tempData.append("max_event_athlete_field_or", 0);
      tempData.append("max_event_athlete_or", 0);
      tempData.append("athletes_per_track_count", 0);
      tempData.append("athletes_per_field_count", 0);
      tempData.append("fees", 0);
      tempData.append("gold_points", 0);
      tempData.append("silver_points", 0);
      tempData.append("bronze_points", 0);
      tempData.append("age_or_std", agetype);
      if (agetype === 2) {
        tempData.append("standard_id", 0);
        tempData.append("standard_name", "");
        tempData.append("age_or_std_flag", "dob");
      } else {
        tempData.append("standard_id", agecat.value);
        tempData.append("standard_name", agecat.label);
        tempData.append("age_or_std_flag", "std");
      }
      dotracking(
        "submit button for " +
          agecat +
          " in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touragecatlistheading
      );
      if (agecatid === "") {
        if (agetype === 2) {
          tempData.append("age_category_name", agecat.label);
        } else {
          tempData.append("age_category_name", "");
        }
        createnewagecat(tempData).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall(agetype);
            handleclose();
          } else {
            toast.error(response.message);
          }
        });
      } else {
        tempData.append("age_category_id", agecatid);

        updateagecat(tempData).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            listall(agetype);
            handleclose();
          } else {
            toast.error(response.message);
          }
        });
      }
    }
  }

  function handleAge(e) {
    if (e === null) {
      setAgeCat("");
    } else {
      setAgeCat(e);
    }
  }

  function backlist() {
    dotracking(
      "back button in age category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentdetails/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentdetails/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Age Category Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Age Category Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-3">
                  <div className="text-right">
                    <a className="btn btn-success me-2" onClick={handleshow}>
                      <i className="fa fa-plus"></i> Add
                    </a>
                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              {agetype === 2 && (
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />
                </div>
              )}
              {agetype === 1 && (
                <div className="ag-theme-alpine ag-style mt-2">
                  <AgGridReact
                    columnDefs={columnSDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout="autoWidth"
                    onGridReady={(params) => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              )}

              {agetype === 1 && (
                <Modal show={show} size="lg">
                  <Modal.Header>
                    <h4> Add Class </h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Class <span className="errortext"> *</span>
                              </label>
                              <Select
                                className="dropdown"
                                placeholder="Select Class"
                                value={agecat}
                                options={alist}
                                onChange={handleAge}
                                isSearchable={true}
                                isClearable
                                hidden
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      {errorMessage !== "" && (
                        <span className="errortext">{errorMessage}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    <a className="btn btn-success" onClick={addmeet}>
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal>
              )}

              {agetype === 2 && (
                <Modal show={show} size="lg">
                  <Modal.Header>
                    {agecatid === "" && <h4> Add Age Category </h4>}
                    {agecatid !== "" && <h4> Age Category Detail</h4>}
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Age Category{" "}
                                <span className="errortext"> *</span>
                              </label>
                              {agecatid === "" && (
                                <Select
                                  className="dropdown"
                                  placeholder="Select Age Categories"
                                  value={agecat}
                                  options={alist}
                                  onChange={handleAge}
                                  isSearchable={true}
                                  isClearable
                                  hidden
                                />
                              )}

                              {agecatid !== "" && (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Age Category"
                                  value={agecat}
                                  disabled
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                DOB Start Date{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Enter Your DOB Start Date"
                                min={sdate}
                                max={edate}
                                value={sdob}
                                onChange={(e) => setSDOB(e.target.value)}
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                DOB End Date{" "}
                                <span className="errortext"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Enter Your DOB End Date"
                                min={sdate}
                                max={edate}
                                value={edob}
                                onChange={(e) => setEDOB(e.target.value)}
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      {errorMessage !== "" && (
                        <span className="errortext">{errorMessage}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>
                    {agecatid !== "" && (
                      <a className="btn btn-success" onClick={addmeet}>
                        Update
                      </a>
                    )}
                    {agecatid === "" && (
                      <a className="btn btn-success" onClick={addmeet}>
                        Submit
                      </a>
                    )}
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
