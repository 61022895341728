import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const AthleteSidebar = () => {
  const { id } = useParams();
  const { eid } = useParams();

  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [proact, setProAct] = useState(false);
  const [myproact, setMyProAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [toulistact, setTouListAct] = useState(false);
  const [reglistact, setRegListAct] = useState(false);
  const [teamact, setTeamAct] = useState(false);
  const [phototou, setPhotoTou] = useState(false);
  const [highact, setHighAct] = useState(false);
  const [certact, setCertAct] = useState(false);

  const [achact, setAchAct] = useState(false);
  const [allachact, setAllAchAct] = useState(false);
  const [othachact, setOthAchAct] = useState(false);

  const [resact, setResAct] = useState(false);

  const [assact, setAssAct] = useState(false);
  const [allassact, setAllAssAct] = useState(false);

  const [notiact, setNotiAct] = useState(false);
  const [allnotiact, setAllNotiAct] = useState(false);

  const [childact, setChildAct] = useState(false);
  const [allchildact, setAllChildAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setProAct(false);
    setMyProAct(false);

    setTouAct(false);
    setTouListAct(false);
    setRegListAct(false);
    setTeamAct(false);
    setPhotoTou(false);
    setHighAct(false);
    setCertAct(false);

    setAchAct(false);
    setAllAchAct(false);
    setOthAchAct(false);

    setResAct(false);

    setAssAct(false);
    setAllAssAct(false);
    setNotiAct(false);
    setAllNotiAct(false);

    setChildAct(false);
    setAllChildAct(false);

    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/player/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Profile") {
      setProAct(true);
      setMyProAct(true);
      dotracking("profile menu $$ " + trackobj.profileheading);
      navigate("/player/profile");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Tournaments") {
      setTouAct(true);
    } else if (head === "Assessments") {
      setAssAct(true);
      setAllAssAct(true);
      dotracking("assessments menu $$ " + trackobj.asslistheading);
      navigate("/player/assessmentlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Notifications") {
      setNotiAct(true);
      setAllNotiAct(true);
      navigate("/player/notification");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Childdocuments") {
      setChildAct(true);
      setAllChildAct(true);
      navigate("/player/document");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Achievements") {
      setAchAct(true);
      // dotracking("achievements menu $$ " + trackobj.achieveheading);
      // navigate("/player/achievements");
      // if (window.innerWidth < 767) {
      //   const url = window.location.href;
      //   const head = url.split("/#/");
      //   const link = head[1].split("/");
      //   if (link[1] !== localStorage.getItem("Url")) {
      //     localStorage.setItem("Url", link[1]);
      //     window.location.reload();
      //   }
      // }
    }
  }

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);

    setProAct(false);
    setMyProAct(false);

    setTouAct(false);
    setTouListAct(false);
    setRegListAct(false);
    setTeamAct(false);
    setPhotoTou(false);
    setHighAct(false);
    setCertAct(false);

    setAchAct(false);
    setAllAchAct(false);
    setOthAchAct(false);

    setResAct(false);

    setAssAct(false);
    setAllAssAct(false);
    setNotiAct(false);
    setAllNotiAct(false);

    setChildAct(false);
    setAllChildAct(false);

    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/dashboard");
    }else if (head === "externaltournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/externaltournamentdetail/" + id);
    }  else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/contact");
    }else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/changepassword");
    } else if (head === "profile") {
      setProAct(true);
      setMyProAct(true);
      navigate("/player/profile");
    } else if (head === "overallperformanceplayer") {
      setProAct(true);
      setMyProAct(true);
      navigate("/player/overallperformanceplayer");
    } else if (head === "viewperformanceplayer") {
      setProAct(true);
      setMyProAct(true);
      navigate("/player/viewperformanceplayer");
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setTouListAct(true);
      if (type === true) {
        dotracking(
          "eligible list in tournament menu $$ " + trackobj.elilistheading
        );
      }
      navigate("/player/tournamentlist");
    } else if (head === "keyhighlights") {
      setAchAct(true);
      setAllAchAct(true);
      if (type === true) {
        dotracking(
          "key highlights list in achievements menu $$ " + trackobj.keylistheading
        );
      }
      navigate("/player/keyhighlights");
    } else if (head === "achievements") {
      setAchAct(true);
      setOthAchAct(true);
      if (type === true) {
        dotracking(
          "achievements list in achievements menu $$ " +
            trackobj.achievelistheading
        );
      }
      navigate("/player/achievements");
    } else if (head === "achievementdetails") {
      setAchAct(true);
      setOthAchAct(true);
      navigate("/player/achievementdetails/" + id);
    } else if (head === "tournamentregister") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/tournamentregister/" + id);
    } else if (head === "registeredviewtournament") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/registeredviewtournament/" + id);
    } else if (head === "eligibleviewtournament") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/eligibleviewtournament/" + id);
    } else if (head === "tournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/tournamentdetail/" + id);
    }else if (head === "viewtournament") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/viewtournament/" + id);
    }else if (head === "performanceplayerlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/performanceplayerlist/" + id);
    }else if (head === "performancephotos") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/performancephotos/" + id);
    } else if (head === "registeredlist") {
      setTouAct(true);
      setRegListAct(true);
      if (type === true) {
        dotracking(
          "registered list in tournament menu $$ " + trackobj.reglistheading
        );
      }
      navigate("/player/registeredlist");
    } else if (head === "registeredorderofeventlist") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/registeredorderofeventlist/" + id);
    } else if (head === "eligibleorderofeventlist") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/eligibleorderofeventlist/" + id);
    } else if (head === "overallchampionlist") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/individualchampionlist/" + id);
    }else if (head === "registeredoverallchampion") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/registeredoverallchampion/" + id);
    } else if (head === "registeredindividualchampion") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/registeredindividualchampion/" + id);
    }else if (head === "eligibleoverallchampion") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/eligibleoverallchampion/" + id);
    } else if (head === "eligibleindividualchampion") {
      setTouAct(true);
      setTouListAct(true);
      navigate("/player/eligibleindividualchampion/" + id);
    } else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "orderofeventlist") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/orderofeventlist/" + id);
    } else if (head === "eventlist") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/eventlist/" + id);
    } else if (head === "pointslist") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/pointslist/" + id + "/" + eid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/schedulelist/" + id + "/" + eid);
    } else if (head === "fixturelist") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/fixturelist/" + id + "/" + eid);
    } else if (head === "teamlist") {
      setTouAct(true);
      setTeamAct(true);
      if (type === true) {
        dotracking("my teams in tournament menu $$ " + trackobj.myteamheading);
      }
      navigate("/player/teamlist");
    } else if (head === "myteams") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/myteams/" + id);
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setTeamAct(true);
      navigate("/player/teamplayerlist/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setResAct(true);
      navigate("/player/resultlist/" + id);
    } else if (head === "photogallery") {
      setTouAct(true);
      setPhotoTou(true);
      if (type === true) {
        dotracking("photos in tournament menu $$ " + trackobj.photoheading);
      }
      navigate("/player/photogallery");
    } else if (head === "photos") {
      setTouAct(true);
      setPhotoTou(true);
      navigate("/player/photos/" + id);
    } else if (head === "highlights") {
      setTouAct(true);
      setHighAct(true);
      if (type === true) {
        dotracking(
          "highlights list in tournament menu $$ " + trackobj.highheading
        );
      }
      navigate("/player/highlights");
    } else if (head === "leaderboard") {
      setTouAct(true);
      setRegListAct(true);
      navigate("/player/leaderboard");
    }else if (head === "highlightsdetail") {
      setTouAct(true);
      setHighAct(true);
      navigate("/player/highlightsdetail/" + id);
    } else if (head === "certificates") {
      setTouAct(true);
      setCertAct(true);
      if (type === true) {
        dotracking(
          "certificates in tournament menu $$ " + trackobj.certheading
        );
      }
      navigate("/player/certificates");
    } else if (head === "assessmentlist") {
      setAssAct(true);
      setAllAssAct(true);
      navigate("/player/assessmentlist");
    } else if (head === "assessmentdetail") {
      setAssAct(true);
      setAllAssAct(true);
      navigate("/player/assessmentdetail/" + id);
    } else if (head === "notification") {
      setNotiAct(true);
      setAllNotiAct(true);
      navigate("/player/notification");
    } else if (head === "document") {
      setChildAct(true);
      setAllChildAct(true);
      navigate("/player/document");
    }else if (head === "othereventdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/player/othereventdetail/" + id);
    } 
    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1], false);
  }, []);

  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon ti-blackboard"></i>
                <div className="slider-text">Profile</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Assessments"
                className={
                  assact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Assessments")}
                aria-controls="hor_1_tab_item-5"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-chart-bar-outline"></i>
                <div className="slider-text">Assessments</div>
              </li>

              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Notifications"
                className={
                  notiact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Notifications")}
                aria-controls="hor_1_tab_item-5"
                role="tab"
              >
                <i className="side-menu__icon ti-bell"></i>
                <div className="slider-text">Notifications</div>
              </li>

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Child's Documents"
                className={
                  childact
                    ? "active resp-tab-item hor_1"
                    : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Childdocuments")}
                aria-controls="hor_1_tab_item-6"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-clipboard"></i>
                <div className="slider-text">Child's Documents</div>
              </li> */}

              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Achievements"
                className={
                  achact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Achievements")}
                aria-controls="hor_1_tab_item-6"
                role="tab"
              >
                <i className="side-menu__icon fa-solid fa-award"></i>
                <div className="slider-text">Achievements</div>
              </li>
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard")}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="ti-blackboard"></i>
                  <div className="slider-text">Profile</div>
                </h2>
                <div
                  className={
                    proact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="ti-blackboard"></i> Profile
                      </h4>
                      <a
                        className={
                          myproact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("profile")}
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>{" "}
                        Tournaments
                      </h4>
                      <a
                        className={
                          reglistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("registeredlist", true)}
                      >
                        Registered Lists
                      </a>
                      <a
                        className={teamact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("teamlist", true)}
                      >
                        My Teams
                      </a>

                      {/* <a
                        className={resact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("resultlist")}
                      >
                        Results
                      </a> */}
                      <a
                        className={
                          phototou ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("photogallery", true)}
                      >
                        Photos
                      </a>
                      <a
                        className={highact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("highlights", true)}
                      >
                        Highlights
                      </a>
                      <a
                        className={certact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("certificates", true)}
                      >
                        Certificates
                      </a>
                      <a
                        className={
                          toulistact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Eligible Lists
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-chart-bar-outline"></i>
                  <div className="slider-text">Assessments</div>
                </h2>
                <div
                  className={
                    assact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-chart-bar-outline"></i>{" "}
                        Assessments
                      </h4>{" "}
                      <a
                        className={
                          allassact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("assessment")}
                      >
                        Result List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon ti-bell"></i>
                  <div className="slider-text">Notifications</div>
                </h2>
                <div
                  className={
                    notiact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon ti-bell"></i>{" "}
                        Notifications
                      </h4>{" "}
                      <a
                        className={
                          allnotiact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("notification")}
                      >
                        Notification List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-clipboard"></i>
                  <div className="slider-text">Child's Documents</div>
                </h2>
                <div
                  className={
                    childact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-clipboard"></i>
                        Documents
                      </h4>{" "}
                      <a
                        className={
                          allchildact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("document")}
                      >
                        Documents List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa-solid fa-award"></i>
                  <div className="slider-text">Achievements</div>
                </h2>
                <div
                  className={
                    achact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa-solid fa-award"></i>
                        Achievements
                      </h4>{" "}
                      <a
                        className={
                          allachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("keyhighlights", true)}
                      >
                        Key Highlights
                      </a>
                      <a
                        className={
                          othachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievements", true)}
                      >
                        Achievements List
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
