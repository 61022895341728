import React, { Fragment, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import {
  banneredit,
  bannersave,
  bannerview,
  roledelete,
  rolemasterlist,
  tourlist,
} from "../../../../services/superadmin";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import trackobj from "../../../trackingpages.json";
import dotracking from "../../../trackinglogs";

export const Bannerupload = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bname, setBName] = useState("");
  const [blink, setBLink] = useState("");
  const [ttype, setTtype] = useState("");
  const [btype, setBtype] = useState("");
  const [basefile, setBase] = useState("");
  const [roleslist, setRolesList] = useState([]);
  const [rolesid, setRolesid] = useState("");
  const [rolesdata, setRolesData] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  // const [fileMsg, setFileMsg] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(false);
  const [ishow, setImage] = useState(false);
  // const [selectedValues, setSelectedValues] = useState([]);
  const [parraylist, setPArrayList] = useState([
    {
      id: 1,
      name: "Dashboard",
      check: false,
    },
    {
      id: 2,
      name: "Tournaments - Registered List",
      check: false,
    },
    {
      id: 3,
      name: "Photos",
      check: false,
    },
    {
      id: 4,
      name: "Highlights",
      check: false,
    },
  ]);

  let arr = [];
  let arr1 = [];

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }

  function ImageClose() {
    setImage(false);
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/users.png");
    ev.target.onerror = null;
  }

  useEffect(() => {
    handledit();
    tourlist().then((response) => {
      if (response.status === true) {
        if (response.data.tournaments.length !== 0) {
          for (let i = 0; i < response.data.tournaments.length; i++) {
            arr1.push({
              tourname: response.data.tournaments[i].meet_name,
              tour_id: response.data.tournaments[i].id,
            });
          }
        }
        setFilterProduct(arr1);
      }
    });
    rolemasterlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].is_active === 1) {
              arr.push({
                value: response.data[i].id,
                label: response.data[i].role,
              });
            }
          }
        }
        setRolesList(arr);
      }
    });
  }, []);

  function handledit() {
    if (id !== undefined) {
      bannerview(id).then((response) => {
        if (response.status === true) {
          if (response.data.bannerdetails.length !== 0) {
            setBName(response.data.bannerdetails.b_name);
            setBtype(response.data.bannerdetails.b_type + "");
            setTtype(response.data.bannerdetails.tournamentselection);
            setBase(response.data.bannerdetails.b_file);
            if (response.data.bannerdetails.b_file !== null) {
              setPhoto(
                response.data.banner_path + response.data.bannerdetails.b_file
              );
            }
            let rolesArray = [];
            if (response.data.bannerdetails.banner_list.length !== 0) {
              const bannerList = response.data.bannerdetails.banner_list;
              rolesArray = bannerList.map((banner) => ({
                role_id: banner.id,
                role_name: banner.role,
              }));
            }
            setRolesData(rolesArray);

            if (response.data.banner_pages.length !== 0) {
              for(let i = 0; i < response.data.banner_pages.length; i++){
                if(response.data.banner_pages[i].pagename === "Dashboard"){
                  parraylist[0].check = true;
                }else if(response.data.banner_pages[i].pagename === "Tournaments - Registered List"){
                  parraylist[1].check = true;
                }else if(response.data.banner_pages[i].pagename === "Photos"){
                  parraylist[2].check = true;
                }else if(response.data.banner_pages[i].pagename === "Highlights"){
                  parraylist[3].check = true;
                }
              }
              setPArrayList([...parraylist]);
              // var plist = response.data.bannerdetails.pagename.split(",");
              // if (plist.length !== 0) {
              //   for (let i = 0; i < plist.length; i++) {
              //     if (plist[i] === "Dashboard") {
              //       parraylist[0].check = true;
              //     } else if (plist[i] === "Tournaments - Registered List") {
              //       parraylist[1].check = true;
              //     } else if (plist[i] === "Photos") {
              //       parraylist[2].check = true;
              //     } else if (plist[i] === "Highlights") {
              //       parraylist[3].check = true;
              //     }
              //     setPArrayList([...parraylist]);
              //   }
              // }
            }
          }
        }
      });
    }
  }

  function handleRoles(e) {
    setRolesid(e);
  }
  const [berror, setBError] = useState("");

  function datafile(event) {
    setBase("");
    setErrorMsg("");
    setBError("");
    if (event.target.files.length !== 0) {
      setBase(event.target.files[0]);
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          if (img.width === 900 && img.height === 300) {
          } else {
            setBError("Banner dimensions should be 900X300 pixels");
            setErrorMsg("Banner dimensions should be 900X300 pixels");
          }
        };
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  function upload(e) {
    e.preventDefault();
    setErrorMsg("");
    setSubmitted(true);
    var valid = "";
    if (berror !== "") {
      valid = berror;
      setErrorMsg(berror);
    }
    var pname = "";
    const pdata = parraylist.filter((obj) => {
      return obj.check === true;
    });
    if (pdata.length !== 0) {
      for (let i = 0; i < pdata.length; i++) {
        if (pname === "") {
          pname = pdata[i].name;
        } else {
          pname = pname + "," + pdata[i].name;
        }
      }
    }
    if (bname !== "" && basefile !== "" && valid === "") {
      setLoading(true);
      if (id === undefined) {
        const tempData = new FormData();
        tempData.append("b_name", bname);
        tempData.append("b_type", "");
        tempData.append("bannertype", "");
        tempData.append("tournamentselection", ttype);
        tempData.append("bannername", basefile);
        // tempData.append("role_id", "");
        tempData.append("banner_link", blink);
        tempData.append("pagename", pname);
        tempData.append("status", 1);
        dotracking(
          "submit button in add banner $$ " + trackobj.bannerlistheading
        );
        bannersave(tempData).then((response) => {
          if (response.status === true) {
            setSubmitted(false);
            setBName("");
            setBtype("");
            setTtype("");
            setBase("");
            setRolesid("");
            setLoading(false);
            navigate("/superadmin/bannerlist");
            toast.success(response.message);
          } else {
            setErrorMsg(response.message);
          }
        });
      } else {
        const tempData = new FormData();
        tempData.append("b_name", bname);
        tempData.append("b_type", "");
        tempData.append("bannertype", "");
        tempData.append("tournamentselection", ttype);
        tempData.append("bannername", basefile);
        // tempData.append("role_id", "");
        tempData.append("banner_id", id);
        tempData.append("banner_link", blink);
        tempData.append("pagename", pname);
        tempData.append("status", 1);
        // if (btype === "1") {
        //   tempData.append("bannertype", "Commercial");
        // } else if (btype === "2") {
        //   tempData.append("bannertype", "Advertisement");
        // } else if (btype === "3") {
        //   tempData.append("bannertype", "Digital");
        //   if (rolesid !== "") {
        //     if (rolesid.length !== 0) {
        //       for (let i = 0; i < rolesid.length; i++) {
        //         if (rid === "") {
        //           rid = rolesid[i].value;
        //         } else {
        //           rid = rid + "," + rolesid[i].value;
        //         }
        //       }
        //     }
        //   }
        // }
        dotracking(
          "submit button in edit banner $$ " + trackobj.bannerlistheading
        );

        banneredit(tempData).then((response) => {
          if (response.status === true) {
            setLoading(false);
            setSubmitted(false);
            toast.success(response.message);
            navigate("/superadmin/bannerlist");
          } else {
            setErrorMsg(response.message);
          }
        });
        // alert("hiii");
      }
    }
  }

  function deleteroles(uid, rname) {
    setRolesData([]);
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "delete role in edit banner for " +
          rname +
          " $$ " +
          trackobj.bannereditheading
      );

      roledelete(uid).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          bannerview(id).then((response) => {
            if (response.status === true) {
              if (response.data.bannerdetails.length !== 0) {
                let rolesArray = [];
                if (response.data.bannerdetails.banner_list.length !== 0) {
                  const bannerList = response.data.bannerdetails.banner_list;
                  rolesArray = bannerList.map((banner) => ({
                    role_id: banner.id,
                    role_name: banner.role,
                  }));
                }
                setRolesData(rolesArray);
              }
            }
          });
        }
      });
    }
  }

  function backlist() {
    if (id !== undefined) {
      dotracking("back button in edit banner $$ " + trackobj.bannerlistheading);
    } else {
      dotracking("back button in add banner $$ " + trackobj.bannerlistheading);
    }
    navigate("/superadmin/bannerlist");
  }

  function handleCheck(index, e) {
    parraylist[index].check = e.target.checked;
    setPArrayList([...parraylist]);
  }

  const handleCheckboxChange = (e) => {
    // setSelectedValues((prevValues) =>
    //   prevValues.includes(e.target.value)
    //     ? prevValues.filter((value) => value !== e.target.value)
    //     : [...prevValues, e.target.value]
    // );
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Banner</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Banner
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            {loading && (
              <div className="dashloader-wrapper">
                <div className="loader"></div>
                <h3>Loading...Please do not go back or close the window.</h3>
              </div>
            )}

            {!loading && (
              <>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Banner Name{" "}
                          <b>
                            <span className="errortext">*</span>
                          </b>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Banner Name"
                          value={bname}
                          onChange={(e) => setBName(e.target.value)}
                        />
                        {submitted && !bname && (
                          <span className="errortext">
                            Banner Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Banner Type <span className="errortext">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <select
                          value={btype}
                          className="form-control"
                          onChange={(e) => setBtype(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Banner Type ---
                          </option>
                          <option value="1">Commercial</option>
                          <option value="2">Advertisement</option>
                          <option value="3">Digital</option>
                        </select>
                        {submitted && !btype && (
                          <span className="errortext">
                            Banner Type is required
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Tournament
                          {/* <span className="errortext">*</span> */}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <select
                          value={ttype}
                          className="form-control"
                          onChange={(e) => setTtype(e.target.value)}
                        >
                          <option value="" defaultValue disabled>
                            --- Select Tournament ---
                          </option>
                          {filterProduct.map((data, i) => {
                            return (
                              <option value={data.tour_id} key={i}>
                                {data.tourname}
                              </option>
                            );
                          })}
                        </select>
                        {/* {submitted && !ttype && (
                          <span className="errortext">
                            Tournament is required
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Banner File <span className="errortext"> (Maximum Size 900X300)*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg,.jpeg,.png"
                          onChange={datafile}
                        />
                        {submitted && !basefile && (
                          <span className="errortext">
                            Banner File is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Banner Link
                          {/* <span className="errortext">*</span> */}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Banner File Link"
                          value={blink}
                          onChange={(e) => setBLink(e.target.value)}
                        />
                        {/* {submitted && !blink && (
                          <span className="errortext">
                            Banner link is required
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label"> Banner Pages</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        {parraylist.map((data, i) => {
                          return (
                            <div className="col-md-3" key={i}>
                              {i === 0 && (
                                <div className="form-group">
                                  <div className="form-check mt-2">
                                    {" "}
                                    <input
                                      id="dashboard"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={data.name}
                                      checked={data.check}
                                      onChange={(e) => handleCheck(i, e)}
                                    />{" "}
                                    <label
                                      className="form-check-label form-label"
                                      style={{ paddingTop: "5px" }}
                                    >
                                      {data.name}
                                    </label>{" "}
                                  </div>
                                </div>
                              )}
                              {i !== 0 && ttype !== "" && (
                                <div className="form-group">
                                  <div className="form-check mt-2">
                                    {" "}
                                    <input
                                      id="dashboard"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={data.name}
                                      checked={data.check}
                                      onChange={(e) => handleCheck(i, e)}
                                    />{" "}
                                    <label
                                      className="form-check-label form-label"
                                      style={{ paddingTop: "5px" }}
                                    >
                                      {data.name}
                                    </label>{" "}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                        {/* <div className="col-md-3">
                          <div className="form-group">
                            <div className="form-check mt-2">
                              {" "}
                              <input
                                id="dashboard"
                                className="form-check-input"
                                type="checkbox"
                                value={"Dashboard"}
                                checked={selectedValues.includes("Dashboard")}
                                onChange={handleCheckboxChange}
                              />{" "}
                              <label
                                className="form-check-label form-label"
                                style={{ paddingTop: "5px" }}
                              >
                                Dashboard
                              </label>{" "}
                            </div>
                          </div>
                        </div>
                        {ttype !== "" && (
                          <>
                            <div className="col-md-5">
                              <div className="form-group">
                                <div className="form-check mt-2">
                                  {" "}
                                  <input
                                    id="Tournaments - Registered List"
                                    className="form-check-input"
                                    type="checkbox"
                                    value={"Tournaments - Registered List"}
                                    checked={selectedValues.includes(
                                      "Tournaments - Registered List"
                                    )}
                                    onChange={handleCheckboxChange}
                                  />{" "}
                                  <label
                                    className="form-check-label form-label"
                                    style={{ paddingTop: "5px" }}
                                  >
                                    Tournaments - Registered List
                                  </label>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="form-check mt-2">
                                  {" "}
                                  <input
                                    id="photos"
                                    className="form-check-input"
                                    type="checkbox"
                                    value={"Photos"}
                                    checked={selectedValues.includes("Photos")}
                                    onChange={handleCheckboxChange}
                                  />{" "}
                                  <label
                                    className="form-check-label form-label"
                                    style={{ paddingTop: "5px" }}
                                  >
                                    Photos
                                  </label>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="form-check mt-2">
                                  {" "}
                                  <input
                                    id="highlights"
                                    className="form-check-input"
                                    type="checkbox"
                                    value={"Highlights"}
                                    checked={selectedValues.includes(
                                      "Highlights"
                                    )}
                                    onChange={handleCheckboxChange}
                                  />{" "}
                                  <label
                                    className="form-check-label form-label"
                                    style={{ paddingTop: "5px" }}
                                  >
                                    Highlights
                                  </label>{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>

                    {id !== undefined && photo !== "" && (
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                          <div className="link-btn">
                            <a>
                              <img
                                className="profile_img"
                                src={photo}
                                height="160"
                                alt="photo"
                                onClick={(e) => ImageShow(photo)}
                                // onError={(e) => errorhandle(e)}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mt-2">
                    {btype === "3" && (
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-label"> Select Role</label>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <Select
                              className="dropdown"
                              placeholder="Select Role"
                              value={rolesid}
                              options={roleslist}
                              onChange={handleRoles}
                              isSearchable={true}
                              isClearable
                              isMulti
                              hidden
                            />
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="details-group refereebox deletereferee">
                            <div className="row">
                              <>
                                {rolesdata.length !== 0 && <h4>Roles</h4>}

                                {rolesdata.map((data, i) => {
                                  return (
                                    <div
                                      className="col-md-4 align-self-center"
                                      key={i}
                                    >
                                      <div className="form-group">
                                        <h4 className="mb-1">
                                          {data.role_name}{" "}
                                        </h4>
                                        <a
                                          className="deleterefer"
                                          onClick={() =>
                                            deleteroles(
                                              data.role_id,
                                              data.role_name
                                            )
                                          }
                                        >
                                          <i className="fa fa-close"></i>
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                                {rolesdata.length !== 0 && (
                                  <div className="border-bottom"></div>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <div className="text-right mt-2">
                      <a className="btn btn-success me-2" onClick={upload}>
                        Submit
                      </a>

                      <a className="btn btn-gray" onClick={backlist}>
                        Back
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
          <Modal show={ishow} onHide={ImageClose}>
            <Modal.Body>
              <img
                className="profile_img prof-img"
                src={file}
                height="160"
                alt="photo"
                onError={(e) => errorhandle(e)}
              />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-secondary text-right"
                onClick={ImageClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
