import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const CrmSidebar = () => {
  const [subact, setSubAct] = useState(true);
  const [dashboard, setDashboard] = useState(true);

  const [scheadact, setSchHeadAct] = useState(false);
  const [addscheadact, setAddSchHeadAct] = useState(false);
  const [listscheadact, setListSchHeadAct] = useState(false);

  const [addschact, setAddSchAct] = useState(false);
  const [listschact, setListSchAct] = useState(false);

  const [funact, setFunAct] = useState(false);
  const [flist, setFList] = useState(false);
  const [afunact, setAFunAct] = useState(false);
  const [lfunact, setLFunAct] = useState(false);


  const [grpact, setGrpAct] = useState(false);
  const [gslist, setGSList] = useState(false);

  const [plaact, setPlaAct] = useState(false);
  const [addplaact, setAddPlaAct] = useState(false);
  const [allplaact, setAllPlaAct] = useState(false);

  const [touact, setTouAct] = useState(false);
  const [touadd, setTouAdd] = useState(false);
  const [toulist, setTouList] = useState(false);
  const [phototou, setPhotoTou] = useState(false);
  const [achtou, setAchTou] = useState(false);
  const [exttouadd, setExtTouAdd] = useState(false);
  const [exttoulist, setExtTouList] = useState(false);

  const [workact, setWorkAct] = useState(false);
  const [addwork, setAddWork] = useState(false);
  const [listwork, setListWork] = useState(false);

  const [proact, setProAct] = useState(false);
  const [pviewact, setPViewAct] = useState(false);

  const [refact, setRefAct] = useState(false);
  const [reflistact, setReflistAct] = useState(false);

  const [cmsact, setCMSAct] = useState(false);
  const [schcmsact, setSCMSAct] = useState(false);
  const [funcmsact, setFCMSAct] = useState(false);
  const [gscmsact, setGSCMSAct] = useState(false);

  const [upact, setUpAct] = useState(false);
  const [upachact, setUPAchAct] = useState(false);

  function mainmenuactive(head) {
    setSubAct(false);
    setDashboard(false);

    setFunAct(false);
    setFList(false);
    setAFunAct(false);
    setLFunAct(false);

    setGrpAct(false);
    setGSList(false);

    setSchHeadAct(false);
    setAddSchHeadAct(false);
    setListSchHeadAct(false);
    setAddSchAct(false);
    setListSchAct(false);

    setPlaAct(false);
    setAddPlaAct(false);
    setAllPlaAct(false);

    setTouAct(false);
    setTouList(false);
    setTouAdd(false);
    setPhotoTou(false);
    setAchTou(false);
    setExtTouList(false);
    setExtTouAdd(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setProAct(false);
    setPViewAct(false);

    setRefAct(false);
    setReflistAct(false);

    setCMSAct(false);
    setSCMSAct(false);
    setFCMSAct(false);
    setGSCMSAct(false);

    setUpAct(false);
    setUPAchAct(false);
    if (head === "Dashboard") {
      setSubAct(true);
      setDashboard(true);
      dotracking("dashboard menu $$ " + trackobj.dashheading);
      navigate("/cityregional/dashboard");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "School") {
      setSchHeadAct(true);
    } else if (head === "Funder") {
      setFunAct(true);
      setFList(true);
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Group") {
      setGrpAct(true);
      setGSList(true);
      dotracking("group of school menu $$ " + trackobj.addgroupschoollist);
      navigate("/cityregional/groupschoolmanager");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Players") {
      setPlaAct(true);
    } else if (head === "Tournaments") {
      setTouAct(true);
    } else if (head === "OtherEvents") {
      setWorkAct(true);
    } else if (head === "Profile") {
      setProAct(true);
      setPViewAct(true);
      dotracking("profile menu - $$ " + trackobj.profileheading);
      navigate("/cityregional/profile");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Referee") {
      setRefAct(true);
      setReflistAct(true);
      dotracking("referee menu - $$ " + trackobj.refereelistheading);
      navigate("/cityregional/refereelist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "Upload") {
      setUpAct(true);
      setUPAchAct(true);
      dotracking(
        "achievements list in upload menu $$ " + trackobj.achievelistheading
      );
      navigate("/cityregional/achievementlist");
      if (window.innerWidth < 767) {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        if (link[1] !== localStorage.getItem("Url")) {
          localStorage.setItem("Url", link[1]);
          window.location.reload();
        }
      }
    } else if (head === "CMS") {
      setCMSAct(true);
    }
  }

  const navigate = useNavigate();
  function submenuactive(head, type) {
    setSubAct(false);
    setDashboard(false);
    setTouAct(false);
    setTouList(false);
    setTouAdd(false);
    setPhotoTou(false);
    setAchTou(false);

    setFunAct(false);
    setFList(false);
    setAFunAct(false);
    setLFunAct(false);

    setGrpAct(false);
    setGSList(false);

    setExtTouList(false);
    setExtTouAdd(false);

    setWorkAct(false);
    setAddWork(false);
    setListWork(false);

    setSchHeadAct(false);
    setAddSchHeadAct(false);
    setListSchHeadAct(false);
    setAddSchAct(false);
    setListSchAct(false);

    setPlaAct(false);
    setAddPlaAct(false);
    setAllPlaAct(false);

    setRefAct(false);
    setReflistAct(false);

    setProAct(false);
    setPViewAct(false);

    setCMSAct(false);
    setSCMSAct(false);
    setFCMSAct(false);
    setGSCMSAct(false);

    setUpAct(false);
    setUPAchAct(false);
    if (head === "dashboard") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/dashboard");
    } else if (head === "contact") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/contact");
    } else if (head === "changepassword") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/changepassword");
    } else if (head === "addotherevent") {
      setWorkAct(true);
      setAddWork(true);
      if (type === true) {
        dotracking(
          "add other events in other events menu $$ " + trackobj.workaddheading
        );
      }
      navigate("/cityregional/addotherevent");
    } else if (head === "othereventlist") {
      setWorkAct(true);
      setListWork(true);
      if (type === true) {
        dotracking(
          "list other events in other events menu $$ " +
            trackobj.worklistheading
        );
      }
      navigate("/cityregional/othereventlist");
    } else if (head === "editotherevent") {
      setWorkAct(true);
      setListWork(true);
      navigate("/cityregional/editotherevent/" + id);
    } else if (head === "othereventdetail") {
      setWorkAct(true);
      setListWork(true);
      navigate("/cityregional/othereventdetail/" + id);
    } else if (head === "othereventdetails") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/othereventdetails/" + id);
    } else if (head === "addtournament") {
      setTouAct(true);
      setTouAdd(true);
      if (type === true) {
        dotracking(
          "add tournament in tournament menu $$ " + trackobj.touraddheading
        );
      }
      navigate("/cityregional/addtournament");
    } else if (head === "athleticsagecategory") {
      setTouAct(true);
      setTouAdd(true);
      navigate("/cityregional/athleticsagecategory/" + id);
    } else if (head === "tournamentagecategory") {
      setTouAct(true);
      setTouAdd(true);
      navigate("/cityregional/tournamentagecategory/" + id);
    } else if (head === "tournamentlist") {
      setTouAct(true);
      setTouList(true);
      if (type === true) {
        dotracking(
          "tournament list in tournament menu $$ " + trackobj.tourlistheading
        );
      }
      navigate("/cityregional/tournamentlist");
    } else if (head === "analytics") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/analytics/" + id);
    } else if (head === "tournamentdetails") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/tournamentdetails/" + id);
    } else if (head === "tournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/tournamentdetail/" + id);
    } else if (head === "edittournament") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/edittournament/" + id);
    } else if (head === "tournamentagecategorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/tournamentagecategorylist/" + id);
    } else if (head === "tournamentclassagecategorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/tournamentclassagecategorylist/" + id);
    } else if (head === "tournamentplayer") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/tournamentplayer/" + id);
    } else if (head === "teamcreate") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/teamcreate/" + id + "/" + eid);
    } else if (head === "certificatelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/certificatelist/" + id);
    } else if (head === "uploadcertificate") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/uploadcertificate/" + id);
    } else if (head === "teamlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/teamlist/" + id + "/" + tid);
    } else if (head === "teamdetails") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/teamdetails/" + id + "/" + tid + "/" + eid);
    } else if (head === "teamplayercreate") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/teamplayercreate/" + id + "/" + tid + "/" + eid);
    } else if (head === "teamplayerlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/teamplayerlist/" + id + "/" + tid + "/" + eid);
    } else if (head === "tournamentschoollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/tournamentschoollist/" + id);
    } else if (head === "playerlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/playerlist/" + id + "/" + eid);
    } else if (head === "agecategorychampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/agecategorychampionlist/" + id + "/" + eid);
    } else if (head === "orderofeventlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/orderofeventlist/" + id);
    } else if (head === "eventstartlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/eventstartlist/" + id + "/" + eid);
    } else if (head === "athleticsresults") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/athleticsresults/" + id);
    } else if (head === "overallchampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/overallchampionlist/" + id);
    } else if (head === "individualchampionlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/individualchampionlist/" + id);
    } else if (head === "addathletes") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/addathletes/" + id + "/" + eid);
    } else if (head === "summary") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/summary/" + id + "/" + eid);
    } else if (head === "confirmation") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/confirmation/" + id + "/" + eid);
    } else if (head === "athleticschoollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/athleticschoollist/" + id);
    } else if (head === "entryplayerslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/entryplayerslist/" + id);
    } else if (head === "refundlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/refundlist/" + id);
    } else if (head === "eventrefundlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/eventrefundlist/" + id);
    } else if (head === "athleticsentryplayerslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/athleticsentryplayerslist/" + id);
    } else if (head === "pointslist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/pointslist/" + id + "/" + eid);
    } else if (head === "categorylist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/categorylist/" + id);
    } else if (head === "performancelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/performancelist/" + id + "/" + eid);
    } else if (head === "addperformance") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/addperformance/" + id + "/" + eid);
    } else if (head === "performanceplayerphotoedit") {
      setTouAct(true);
      setTouList(true);
      navigate(
        "/cityregional/performanceplayerphotoedit/" + id + "/" + tid + "/" + eid
      );
    } else if (head === "highlights") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/highlights/" + id);
    } else if (head === "leaderboard") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/leaderboard/" + id);
    } else if (head === "photoupload") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/photoupload/" + id);
    } else if (head === "results") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/results/" + id);
    } else if (head === "resultlist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/resultlist/" + id + "/" + eid);
    } else if (head === "poollist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/poollist/" + id + "/" + eid);
    } else if (head === "importfixture") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/importfixture/" + id + "/" + eid + "/" + tid);
    } else if (head === "scheduleteam") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/scheduleteam/" + id + "/" + tid + "/" + eid);
    } else if (head === "scheduleallocate") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/scheduleallocate/" + id + "/" + eid);
    } else if (head === "schedulelist") {
      setTouAct(true);
      setTouList(true);
      navigate("/cityregional/schedulelist/" + id + "/" + eid);
    } else if (head === "photogallery") {
      setTouAct(true);
      setPhotoTou(true);
      if (type === true) {
        dotracking(
          "photo/gallery in tournament menu $$ " + trackobj.photoheading
        );
      }
      navigate("/cityregional/photogallery");
    } else if (head === "photos") {
      setTouAct(true);
      setPhotoTou(true);
      navigate("/cityregional/photos/" + id);
    } else if (head === "achievements") {
      setTouAct(true);
      setAchTou(true);
      if (type === true) {
        dotracking(
          "achievements list in tournament menu $$ " +
            trackobj.achievelistheading
        );
      }
      navigate("/cityregional/achievements");
    } else if (head === "achievementsupload") {
      setTouAct(true);
      setAchTou(true);
      if (type === true) {
        dotracking(
          "achievements upload in tournament menu $$ " +
            trackobj.achieveuploadheading
        );
      }
      navigate("/cityregional/achievementsupload");
    } else if (head === "addschoolhead") {
      setSchHeadAct(true);
      setAddSchHeadAct(true);
      if (type === true) {
        dotracking(
          "add schoolhead in school menu $$ " + trackobj.schheadaddheading
        );
      }
      navigate("/cityregional/addschoolhead");
    } else if (head === "schoolheadlist") {
      setSchHeadAct(true);
      setListSchHeadAct(true);
      if (type === true) {
        dotracking(
          "schoolhead list in school menu $$ " + trackobj.schheadlistheading
        );
      }
      navigate("/cityregional/schoolheadlist");
    } else if (head === "viewschoolhead") {
      setSchHeadAct(true);
      setListSchHeadAct(true);
      navigate("/cityregional/viewschoolhead/" + id);
    } else if (head === "schoolheaddetail") {
      setSchHeadAct(true);
      setListSchHeadAct(true);
      navigate("/cityregional/schoolheaddetail/" + id);
    } else if (head === "addschool") {
      setSchHeadAct(true);
      setAddSchAct(true);
      if (type === true) {
        dotracking("add school in school menu $$ " + trackobj.schooladdheading);
      }
      navigate("/cityregional/addschool");
    } else if (head === "schoollist") {
      setSchHeadAct(true);
      setListSchAct(true);
      if (type === true) {
        dotracking(
          "school list in school menu $$ " + trackobj.schoollistheading
        );
      }
      navigate("/cityregional/schoollist");
    } else if (head === "schooldetails") {
      setSchHeadAct(true);
      setListSchAct(true);
      navigate("/cityregional/schooldetails/" + id);
    } else if (head === "addplayers") {
      setPlaAct(true);
      setAddPlaAct(true);
      if (type === true) {
        dotracking(
          "add players in player menu $$ " + trackobj.addplayerheading
        );
      }
      navigate("/cityregional/addplayers");
    } else if (head === "allplayers") {
      setPlaAct(true);
      setAllPlaAct(true);
      if (type === true) {
        dotracking(
          "all players in player menu $$ " + trackobj.playerlistheading
        );
      }
      navigate("/cityregional/allplayers");
    } else if (head === "viewplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/cityregional/viewplayer/" + id);
    } else if (head === "overallperformanceplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/cityregional/overallperformanceplayer/" + id);
    } else if (head === "viewperformanceplayer") {
      setPlaAct(true);
      setAllPlaAct(true);
      navigate("/cityregional/viewperformanceplayer/" + id);
    } else if (head === "refereelist") {
      setRefAct(true);
      setReflistAct(true);
      navigate("/cityregional/refereelist");
    } else if (head === "refereedetail") {
      setRefAct(true);
      setReflistAct(true);
      navigate("/cityregional/refereedetail/" + id);
    } else if (head === "profile") {
      setProAct(true);
      setPViewAct(true);
      navigate("/cityregional/profile");
    } else if (head === "editprofile") {
      setProAct(true);
      setPViewAct(true);
      navigate("/cityregional/editprofile");
    } else if (head === "addexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      if (type === true) {
        dotracking(
          "add external tournament in tournament menu $$ " +
            trackobj.tourextheading
        );
      }
      navigate("/cityregional/addexternaltournament");
    } else if (head === "editexternaltournament") {
      setTouAct(true);
      setExtTouAdd(true);
      navigate("/cityregional/editexternaltournament/" + id);
    } else if (head === "externaltournamentlist") {
      setTouAct(true);
      setExtTouList(true);
      if (type === true) {
        dotracking(
          "external tournament list in tournament menu $$ " +
            trackobj.listextheading
        );
      }
      navigate("/cityregional/externaltournamentlist");
    } else if (head === "externaltournamentdetails") {
      setTouAct(true);
      setExtTouList(true);
      navigate("/cityregional/externaltournamentdetails/" + id);
    } else if (head === "externaltournamentdetail") {
      setSubAct(true);
      setDashboard(true);
      navigate("/cityregional/externaltournamentdetail/" + id);
    } else if (head === "fundermanager") {
      setFunAct(true);
      setFList(true);
      if (type === true) {
        dotracking("funder master menu $$ " + trackobj.addfunderlist);
      }
      navigate("/cityregional/fundermanager");
    }else if (head === "addfunder") {
      setFunAct(true);
      setAFunAct(true);
      if (type === true) {
        dotracking("add funder in funder menu $$ " + trackobj.addfunheading);
      }
      navigate("/cityregional/addfunder");
    }else if (head === "listfunder") {
      setFunAct(true);
      setLFunAct(true);
      if (type === true) {
        dotracking("funder list in funder menu $$ " + trackobj.funlistheading);
      }
      navigate("/cityregional/listfunder");
    } else if (head === "groupschoolmanager") {
      setGrpAct(true);
      setGSList(true);
      navigate("/cityregional/groupschoolmanager");
    } else if (head === "addcms") {
      setCMSAct(true);
      setSCMSAct(true);

      navigate("/cityregional/addcms/" + id);
    } else if (head === "cmsdetails") {
      setCMSAct(true);
      setSCMSAct(true);
      if (type === true) {
        dotracking("add cms in cms menu $$ " + trackobj.cmsheading);
      }
      navigate("/cityregional/cmsdetails");
    } else if (head === "funderdetails") {
      setCMSAct(true);
      setFCMSAct(true);
      if (type === true) {
        dotracking("add funder in cms menu $$ " + trackobj.fcmsheading);
      }
      navigate("/cityregional/funderdetails");
    } else if (head === "addfundercms") {
      setCMSAct(true);
      setFCMSAct(true);

      navigate("/cityregional/addfundercms/" + id);
    } else if (head === "groupofschooldetails") {
      setCMSAct(true);
      setGSCMSAct(true);
      if (type === true) {
        dotracking("add funder in cms menu $$ " + trackobj.gscmsheading);
      }
      navigate("/cityregional/groupofschooldetails");
    } else if (head === "addgroupofschoolcms") {
      setCMSAct(true);
      setGSCMSAct(true);
      navigate("/cityregional/addgroupofschoolcms/" + id);
    } else if (head === "achievementlist") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/cityregional/achievementlist");
    } else if (head === "achievementindividual") {
      setUpAct(true);
      setUPAchAct(true);
      navigate("/cityregional/achievementindividual");
    } else if (head === "achievementsupload") {
      setUpAct(true);
      setUPAchAct(true);

      navigate("/cityregional/achievementsupload");
    }
    if (window.innerWidth < 767) {
      const url = window.location.href;
      const head = url.split("/#/");
      const link = head[1].split("/");
      if (link[1] !== localStorage.getItem("Url")) {
        localStorage.setItem("Url", link[1]);
        window.location.reload();
      }
    }
  }

  const { id } = useParams();
  const { tid } = useParams();
  const { eid } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    submenuactive(link[1], false);
  }, []);
  return (
    <Fragment>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <a className="header-brand left-meu-header-brand left-meu-header-style1 left-meu-header-brand-desktop">
          <img
            src={require("../../../assets/images/logo_sv.png")}
            className="header-brand-img desktop-logo"
            alt="Arox logo"
          />
          <img
            src={require("../../../assets/images/favicon.png")}
            className="header-brand-img mobile-view-logo"
            alt="Arox logo"
          />
        </a>
        <div className="navigationhead">
          <h4>Main Navigation</h4>
        </div>
        <div
          className="side-tab-body p-0 border-0 resp-vtabs hor_1"
          id="parentVerticalTab"
        >
          <div className="first-sidemenu">
            <ul className="resp-tabs-list hor_1">
              <li
                data-toggle="tooltip"
                data-placement="right"
                title="Vertical"
                className={
                  subact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Dashboard")}
              >
                <i className="side-menu__icon typcn typcn-device-desktop"></i>
                <div className="slider-text">Dashboard</div>
              </li>
              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Players"
                className={
                  plaact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Players")}
                aria-controls="hor_1_tab_item-3"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Players</div>
              </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Master"
                className={
                  scheadact
                    ? "active resp-tab-item hor_1"
                    : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("School")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-bank"></i>
                <div className="slider-text">School</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Funder"
                className={
                  funact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Funder")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon ti-blackboard"></i>
                <div className="slider-text">Funder</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Group of Schools"
                className={
                  grpact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Group")}
                aria-controls="hor_1_tab_item-2"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-multiple"></i>
                <div className="slider-text">Group of Schools</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Tournaments"
                className={
                  touact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Tournaments")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon pe-7s-ball"></i>
                <div className="slider-text">Tournaments</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="OtherEvents"
                className={
                  workact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("OtherEvents")}
                aria-controls="hor_1_tab_item-4"
                role="tab"
              >
                <i className="side-menu__icon fa fa-desktop"></i>
                <div className="slider-text">Events</div>
              </li>
              {/* <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Referee"
                className={
                  refact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Referee")}
                aria-controls="hor_1_tab_item-9"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-convert"></i>
                <div className="slider-text">Referee</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Profile"
                className={
                  proact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Profile")}
                aria-controls="hor_1_tab_item-8"
                role="tab"
              >
                <i className="side-menu__icon mdi mdi-account-network"></i>
                <div className="slider-text">Profile</div>
              </li> */}
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Upload"
                className={
                  upact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("Upload")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-upload-outline"></i>
                <div className="slider-text">Upload</div>
              </li>
              <li
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="CMS"
                className={
                  cmsact ? "active resp-tab-item hor_1" : "resp-tab-item hor_1"
                }
                onClick={() => mainmenuactive("CMS")}
                aria-controls="hor_1_tab_item-7"
                role="tab"
              >
                <i className="side-menu__icon typcn typcn-document-text"></i>
                <div className="slider-text">CMS</div>
              </li>
            </ul>
            <div className="second-sidemenu">
              <div className="resp-tabs-container hor_1">
                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-device-desktop"></i>
                  <div className="slider-text">Dashboard</div>
                </h2>
                <div
                  className={
                    subact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-device-desktop"></i>{" "}
                        Home
                      </h4>{" "}
                      <a
                        className={
                          dashboard ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("dashboard")}
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Players</div>
                </h2>
                <div
                  className={
                    plaact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>
                        Players
                      </h4>{" "}
                      <a
                        className={
                          addplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addplayers", true)}
                      >
                        Add Players
                      </a>
                      <a
                        className={
                          allplaact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("allplayers", true)}
                      >
                        All Players
                      </a>
                      {/* <a className="slide-item">Blocked Players</a> */}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-bank"></i>
                  <div className="slider-text">School</div>
                </h2>
                <div
                  className={
                    scheadact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-bank"></i>School
                      </h4>{" "}
                      {/* <a
                        className={
                          addschact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addschool",true)}
                      >
                        Add School
                      </a> */}
                      <a
                        className={
                          listschact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoollist", true)}
                      >
                        School List
                      </a>
                      <a
                        className={
                          addscheadact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("addschoolhead", true)}
                      >
                        Add School Head
                      </a>
                      <a
                        className={
                          listscheadact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("schoolheadlist", true)}
                      >
                        School Head List
                      </a>
                      {/* <a className="slide-item">Blocked School Head</a> */}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon ti-blackboard"></i>
                  <div className="slider-text">Funder</div>
                </h2>
                <div
                  className={
                    funact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon ti-blackboard"></i> Funder
                      </h4>{" "}
                      <a
                        className={flist ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("fundermanager")}
                      >
                        Funder Master
                      </a>
                      <a
                        className={afunact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addfunder")}
                      >
                        Add Funder
                      </a>
                      <a
                        className={lfunact ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("listfunder")}
                      >
                        Funder List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-multiple"></i>
                  <div className="slider-text">Group of School</div>
                </h2>
                <div
                  className={
                    grpact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-multiple"></i>{" "}
                        Group of School
                      </h4>{" "}
                      <a
                        className={gslist ? "slide-item active" : "slide-item"}
                        // onClick={() => submenuactive("dashboard")}
                      >
                        Group of School List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon pe-7s-ball"></i>
                  <div className="slider-text">Tournaments</div>
                </h2>
                <div
                  className={
                    touact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon pe-7s-ball"></i>
                        Tournaments
                      </h4>{" "}
                      <a
                        className={touadd ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addtournament", true)}
                      >
                        Add Tournament
                      </a>
                      <a
                        className={toulist ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("tournamentlist", true)}
                      >
                        Tournament List
                      </a>
                      <a
                        className={
                          exttouadd ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("addexternaltournament", true)
                        }
                      >
                        Add External Tournament
                      </a>
                      <a
                        className={
                          exttoulist ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("externaltournamentlist", true)
                        }
                      >
                        External Tournament List
                      </a>
                      <a
                        className={
                          phototou ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("photogallery", true)}
                      >
                        Photos / Gallery
                      </a>
                      <a
                        className={achtou ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("achievements", true)}
                      >
                        Achievements
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon fa fa-desktop"></i>
                  <div className="slider-text">Other Events</div>
                </h2>
                <div
                  className={
                    workact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon fa fa-desktop"></i> Other
                        Events
                      </h4>{" "}
                      <a
                        className={addwork ? "slide-item active" : "slide-item"}
                        onClick={() => submenuactive("addotherevent", true)}
                      >
                        Add Other Events
                      </a>{" "}
                      <a
                        className={
                          listwork ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("othereventlist", true)}
                      >
                        Other Events List
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-convert"></i>
                  <div className="slider-text">Referee</div>
                </h2>
                <div
                  className={
                    refact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-convert"></i>
                        Referees
                      </h4>{" "}
                      <a
                        className={
                          reflistact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("refereelist")}
                      >
                        All Referees
                      </a>
                      {/* <a className="slide-item">Blocked Referees</a> */}
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-0"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon mdi mdi-account-network"></i>
                  <div className="slider-text">Profile</div>
                </h2>
                <div
                  className={
                    proact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon mdi mdi-account-network"></i>
                        Profile
                      </h4>{" "}
                      <a
                        className={
                          pviewact ? "slide-item active" : "slide-item"
                        }
                        // onClick={() => submenuactive("profile")}
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-upload-outline"></i>
                  <div className="slider-text">Upload</div>
                </h2>
                <div
                  className={
                    upact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-upload-outline"></i>{" "}
                        Upload
                      </h4>{" "}
                      <a
                        className={
                          upachact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("achievementlist", true)}
                      >
                        Achievement Upload
                      </a>
                    </div>
                  </div>
                </div>

                <h2
                  className="resp-accordion hor_1"
                  role="tab"
                  aria-controls="hor_1_tab_item-1"
                >
                  <span className="resp-arrow"></span>
                  <i className="side-menu__icon typcn typcn-document-text"></i>
                  <div className="slider-text">CMS</div>
                </h2>
                <div
                  className={
                    cmsact
                      ? "resp-tab-content hor_1 resp-tab-content-active"
                      : "resp-tab-content hor_1"
                  }
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-weight-normal">
                        <i className="side-menu__icon typcn typcn-document-text"></i>{" "}
                        CMS
                      </h4>{" "}
                      <a
                        className={
                          schcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("cmsdetails", true)}
                      >
                        School
                      </a>
                      <a
                        className={
                          funcmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() => submenuactive("funderdetails", true)}
                      >
                        Funder
                      </a>
                      <a
                        className={
                          gscmsact ? "slide-item active" : "slide-item"
                        }
                        onClick={() =>
                          submenuactive("groupofschooldetails", true)
                        }
                      >
                        Group of Schools
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- col-4 --> */}
      </aside>
    </Fragment>
  );
};
