import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tourphotoslist } from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { playerphotolist } from "../../../../services/player";
import {
  schooltourphotolist,
  schprofileview,
} from "../../../../services/schoolhead";
import { coachviewprofile } from "../../../../services/coach";
import { photolistmanager } from "../../../../services/cityregionaladmin";
export const PhotoGallery = () => {
  const navigate = useNavigate();
  function viewlist(pid, pname) {
    dotracking(
      "view icon in photo gallery for tournament " +
        pname +
        " (" +
        pid +
        ") $$ " +
        trackobj.tourphotosheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/photos/" + pid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/photos/" + pid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/photos/" + pid);
    } else if (localStorage.getItem("Role") === "6") {
      navigate("/player/photos/" + pid);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/photos/" + pid);
    }
  }

  const [photolist, setPhotoLink] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("Role") === "4") {
      schprofileview(localStorage.getItem("UID")).then((res) => {
        if (res.status === true) {
          schooltourphotolist(res.data.school_id).then((response) => {
            if (response.status === true) {
              setPhotoLink(response.data.tournamentuploadlist);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      playerphotolist(localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          setPhotoLink(response.data.tournamentuploadlist);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      coachviewprofile().then((res) => {
        if (res.status === true) {
          schooltourphotolist(res.data.school_id).then((response) => {
            if (response.status === true) {
              setPhotoLink(response.data.tournamentuploadlist);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      photolistmanager(localStorage.getItem("UID")).then((response) => {
        if (response.status === true) {
          setPhotoLink(response.data.tournamentuploadlist);
        }
      });
    } else {
      tourphotoslist().then((response) => {
        if (response.status === true) {
          setPhotoLink(response.data.tournamentuploadlist);
        }
      });
    }
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Photo / Gallery</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Photo / Gallery
          </li>
        </ol>
      </div>

      {/* <div className="row border-bottom">
        <div className="col-md-3">
          <form>
            <div className="form-group">
              <label className="font-weight-bold">Filter by Year</label>
              <select className="form-control bg-white">
                <option value="" disabled defaultValue>
                  {" "}
                  --- Filter by Year ---{" "}
                </option>
                <option> 2023 </option>
                <option> 2022 </option>
                <option> 2021 </option>
                <option> 2020 </option>
              </select>
            </div>
          </form>
        </div>
      </div> */}
      <div className="row">
        {photolist.length === 0 && (
          <h4 className="text-center text-danger">
            Photos will be updated soon
          </h4>
        )}

        {photolist.map((item, i) => {
          return (
            <div className="col-lg-3 col-md-3" key={i}>
              <div className="card shadow-1 border radius-5 p-0 mt-4 mb-0">
                <div className="card-body pt-2 pb-0 px-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("../../../../assets/images/folder.png")}
                      alt=""
                      className="folder-img"
                    />
                  </div>
                  <h5 className="my-0">{item.meet_name}</h5>
                  <p className="mb-1 mt-4">
                    <i className="fas fa-images text-primary me-2"></i>
                    <span>{item.photo_count}</span>{" "}
                    {/* <span className="float-end">
                      <i className="fas fa-video text-secondary me-2"></i>50
                    </span> */}
                  </p>
                </div>
                <div className="card-footer text-center py-2 px-3">
                  <a
                    className="btn btn-outline-success w-100"
                    onClick={() => viewlist(item.id, item.meet_name)}
                  >
                    <i className="fas fa-eye me-2"></i>View Gallery
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
