import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { Form } from "react-bootstrap";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import {
  addathleteagecategorylist,
  athagecatedit,
  updateagecat,
} from "../../../../../services/common";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { coachtourviewdetail } from "../../../../../services/coach";

export const AgeCategoryList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);

  const [show, setShow] = useState(false);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [tname, setTName] = useState("");
  const [mfees, setMFees] = useState("N");

  const [agecatid, setAgeCatID] = useState("");
  const [agecat, setAgeCat] = useState("");
  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  const [athtrack, setAthTrack] = useState("");
  const [athfield, setAthField] = useState("");

  const [trackevent, setTrackEvent] = useState("");
  const [fieldevent, setFieldEvent] = useState("");
  const [trackevent1, setTrackEvent1] = useState("");
  const [fieldevent1, setFieldEvent1] = useState("");

  const [athrelay, setAthRelay] = useState("");
  const [athmixedrelay, setAthMixedRelay] = useState("");

  const [fees, setFees] = useState("");
  const [gpoints, setGPoints] = useState("");
  const [spoints, setSPoints] = useState("");
  const [bpoints, setBPoints] = useState("");

  const [trackorder, setTrackOrder] = useState("");
  const [fieldorder, setFieldOrder] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  let arr = [];
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      field: "id",
      width: 100,
      sortable: false,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            {(localStorage.getItem("Role") === "1" ||
              localStorage.getItem("Role") === "3") && (
              <a
                onClick={() => editline(params.value)}
                title="Edit Age Category"
                className="btn-sm btn-success me-2"
              >
                <i className="fa fa-edit fa-lg"></i>
              </a>
            )}

            <a
              title="View Age Category"
              onClick={() => viewage(params.value, params.data)}
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Age Categories",
      field: "age_categories",
      width: 170,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB Start Date",
      field: "dob_start_date",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return moment(params.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "DOB End Date",
      field: "dob_end_date",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return moment(params.value).format("DD-MM-YYYY");
        }
      },
    },

    {
      headerName: "Max Track & Field Event",
      field: "max_event_athlete",
      width: 220,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return params.value + " & " + params.data.max_event_athlete_field;
        }
      },
    },
    {
      headerName: "Or Max Track & Field Event",
      field: "max_event_athlete_or",
      width: 220,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return params.value + " & " + params.data.max_event_athlete_field_or;
        }
      },
    },
    {
      headerName: "Max Athlete Per Track",
      field: "max_athlete_event",
      width: 200,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Max Athlete Per Field",
      field: "max_athlete_field_event",
      width: 200,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Athletes Per Relay",
      field: "athletes_per_relay",
      width: 180,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Athletes Per Mixed Relay",
      field: "athletes_per_mixed_relay",
      width: 220,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      field: "fees",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gold Points",
      field: "gold_points",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Silver Points",
      field: "silver_points",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Bronze Points",
      field: "bronze_points",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Track (Order of Events)",
      field: "athletes_per_track_count",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Field (Order of Events)",
      field: "athletes_per_field_count",
      width: 150,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    listall();
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMFees(response.data.tournament.free_tournament);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMFees(response.data.tournament.free_tournament);

        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMFees(response.data.tournament.free_tournament);

        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMFees(response.data.tournament.free_tournament);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setSDate(response.data.tournament.dob_start_date);
          setEDate(response.data.tournament.dob_end_date);
          setMFees(response.data.tournament.free_tournament);

        }
      });
    }
  }, []);

  function listall() {
    setFilterProduct([]);
    addathleteagecategorylist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.age_categories);
      }
    });
  }

  function viewage(aid, vdata) {
    dotracking(
      "view event list icon in age category list for " +
        vdata.age_categories +
        " (" +
        aid +
        ") age category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.toureventlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/agecategorybyevent/" + id + "/" + aid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/agecategorybyevent/" + id + "/" + aid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/agecategorybyevent/" + id + "/" + aid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/agecategorybyevent/" + id + "/" + aid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/agecategorybyevent/" + id + "/" + aid);
    }
  }

  function editline(eid) {
    setShow(true);
    athagecatedit(eid).then((response) => {
      setAgeCatID(response.data.id);
      setAgeCat(response.data.age_categories);
      setSDOB(response.data.dob_start_date);
      setEDOB(response.data.dob_end_date);
      setTrackEvent(response.data.max_event_athlete);
      setFieldEvent(response.data.max_event_athlete_field);
      setTrackEvent1(response.data.max_event_athlete_or);
      setFieldEvent1(response.data.max_event_athlete_field_or);
      setAthTrack(response.data.max_athlete_event);
      setAthField(response.data.max_athlete_field_event);
      setAthRelay(response.data.athletes_per_relay);
      setAthMixedRelay(response.data.athletes_per_mixed_relay);
      setFees(response.data.fees);
      setGPoints(response.data.gold_points);
      setSPoints(response.data.silver_points);
      setBPoints(response.data.bronze_points);
      setTrackOrder(response.data.athletes_per_track_count);
      setFieldOrder(response.data.athletes_per_field_count);
    });
  }

  function handleclose() {
    setShow(false);
    setAgeCatID("");
    setAgeCat("");
    setSDOB("");
    setEDOB("");
    setAthTrack("");
    setTrackEvent("");
    setFieldEvent("");
    setTrackEvent1("");
    setFieldEvent1("");
    setAthField("");
    setAthRelay("");
    setAthMixedRelay("");
    setFees("");
    setGPoints("");
    setSPoints("");
    setBPoints("");
    setTrackOrder("");
    setFieldOrder("");
  }

  function addmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    var valid = "";
    if (sdob !== "") {
      if (edob !== "") {
        if (trackevent !== "") {
          if (fieldevent !== "") {
            if (mfees === "N") {
              if (fees === "") {
                valid = "Please Enter Fees";
                setErrorMessage("Please Enter Fees");
              } else {
                if (fees > 0) {
                  valid = "";
                } else {
                  valid = "Fees must be greater than zero";
                  setErrorMessage("Fees must be greater than zero");
                }
              }
            }

            if (valid === "") {
              if (gpoints === "") {
                valid = "Please Enter Gold Points";
                setErrorMessage("Please Enter Gold Points");
              } else {
                if (gpoints > 0) {
                  if (spoints === "") {
                    valid = "Please Enter Silver Points";
                    setErrorMessage("Please Enter Silver Points");
                  } else {
                    if (spoints > 0) {
                      if (bpoints === "") {
                        valid = "Please Enter Bronze Points";
                        setErrorMessage("Please Enter Bronze Points");
                      } else {
                        if (bpoints > 0) {
                          if (trackorder === "") {
                            valid = "Please Enter Athletes Per Track (Order of Events)";
                            setErrorMessage("Please Enter Athletes Per Track (Order of Events)");
                          } else {
                            if (trackorder > 0) {
                              if (fieldorder === "") {
                                valid = "Please Enter Athletes Per Field (Order of Events)";
                                setErrorMessage("Please Enter Athletes Per Field (Order of Events)");
                              } else {
                                if (fieldorder > 0) {
                                } else {
                                  valid = "Athletes Per Field (Order of Events) must be greater than zero";
                                  setErrorMessage(
                                    "Athletes Per Field (Order of Events) must be greater than zero"
                                  );
                                }
                              }
                            } else {
                              valid = "Athletes Per Track (Order of Events) must be greater than zero";
                              setErrorMessage(
                                "Athletes Per Track (Order of Events) must be greater than zero"
                              );
                            }
                          }
                        } else {
                          valid = "Bronze Points must be greater than zero";
                          setErrorMessage(
                            "Bronze Points must be greater than zero"
                          );
                        }
                      }
                    } else {
                      valid = "Silver Points must be greater than zero";
                      setErrorMessage(
                        "Silver Points must be greater than zero"
                      );
                    }
                  }
                } else {
                  valid = "Gold Points must be greater than zero";
                  setErrorMessage("Gold Points must be greater than zero");
                }
              }
            }
          } else {
            valid = "Please Enter Max Field Event Per Athlete";
            setErrorMessage("Please Enter Max Field Event Per Athlete");
          }
        } else {
          valid = "Please Enter Max Track Event Per Athlete";
          setErrorMessage("Please Enter Max Track Event Per Athlete");
        }
      } else {
        valid = "Please Enter End Date";
        setErrorMessage("Please Enter End Date");
      }
    } else {
      valid = "Please Enter Start Date";
      setErrorMessage("Please Enter Start Date");
    }

    if (valid === "") {
      const tempData = new FormData();
      tempData.append("age_category_id", agecatid);
      tempData.append("dob_start_date", sdob);
      tempData.append("dob_end_date", edob);
      if (trackevent === "") {
        tempData.append("max_event_athlete", 0);
      } else {
        tempData.append("max_event_athlete", trackevent);
      }

      if (fieldevent === "") {
        tempData.append("max_event_athlete_field", 0);
      } else {
        tempData.append("max_event_athlete_field", fieldevent);
      }

      if (trackevent1 === "") {
        tempData.append("max_event_athlete_or", 0);
      } else {
        tempData.append("max_event_athlete_or", trackevent1);
      }

      if (fieldevent1 === "") {
        tempData.append("max_event_athlete_field_or", 0);
      } else {
        tempData.append("max_event_athlete_field_or", fieldevent1);
      }

      if (athtrack === "") {
        tempData.append("max_athlete_event", 0);
      } else {
        tempData.append("max_athlete_event", athtrack);
      }

      if (athfield === "") {
        tempData.append("max_athlete_field_event", 0);
      } else {
        tempData.append("max_athlete_field_event", athfield);
      }
      if (athrelay === "") {
        tempData.append("athletes_per_relay", 0);
      } else {
        tempData.append("athletes_per_relay", athrelay);
      }
      if (athmixedrelay === "") {
        tempData.append("athletes_per_mixed_relay", 0);
      } else {
        tempData.append("athletes_per_mixed_relay", athmixedrelay);
      }
      if(mfees === ""){
        tempData.append("fees", 0);
      }else{
        tempData.append("fees", fees);
      }
      
      tempData.append("gold_points", gpoints);
      tempData.append("silver_points", spoints);
      tempData.append("bronze_points", bpoints);
      if (trackorder === "") {
        tempData.append("athletes_per_track_count", 0);
      } else {
        tempData.append("athletes_per_track_count", trackorder);
      }
      if (fieldorder === "") {
        tempData.append("athletes_per_field_count", 0);
      } else {
        tempData.append("athletes_per_field_count", fieldorder);
      }
      dotracking(
        "submit button for " +
          agecat +
          " (" +
          agecatid +
          ") age category in " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.touragecatlistheading
      );

      updateagecat(tempData).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          listall();
          handleclose();
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  function backlist() {
    dotracking(
      "back button in age category list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentdetails/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentdetails/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Age Category Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Age Category Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h3>{tname}</h3>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  // domLayout="autoWidth"
                  // onGridReady={(params) => {
                  //   params.api.sizeColumnsToFit();
                  // }}
                />
              </div>
              <Modal show={show} size="lg">
                <Modal.Header>
                  <h4> Age Category </h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Age Category <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Age Category"
                              value={agecat}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              DOB Start Date{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter Your DOB Start Date"
                              min={sdate}
                              max={edate}
                              value={sdob}
                              onChange={(e) => setSDOB(e.target.value)}
                              onKeyPress={(event) => {
                                event.preventDefault();
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              DOB End Date <span className="errortext"> *</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter Your DOB End Date"
                              min={sdate}
                              max={edate}
                              value={edob}
                              onChange={(e) => setEDOB(e.target.value)}
                              onKeyPress={(event) => {
                                event.preventDefault();
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Athlete Per Track Event{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Athlete Per Track Event"
                              value={athtrack}
                              onChange={(e) => setAthTrack(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Athlete Per Field Event{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Athlete Per Field Event"
                              value={athfield}
                              onChange={(e) => setAthField(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Athlete Per Relay{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Athlete Per Relay"
                              value={athrelay}
                              onChange={(e) => setAthRelay(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Athlete Per Mixed Relay{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Athlete Per Mixed Relay"
                              value={athmixedrelay}
                              onChange={(e) => setAthMixedRelay(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        {mfees === "N" && mfees !== "" && (
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                Fees <span className="errortext"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Fees"
                                maxLength={6}
                                value={fees}
                                onChange={(e) => setFees(e.target.value)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Gold Points <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Gold Points"
                              maxLength={6}
                              value={gpoints}
                              onChange={(e) => setGPoints(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Silver Points{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Silver Points"
                              maxLength={6}
                              value={spoints}
                              onChange={(e) => setSPoints(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Bronze Points{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Bronze Points"
                              maxLength={6}
                              value={bpoints}
                              onChange={(e) => setBPoints(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Athletes Per Track (Order of Events){" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Athletes Per Track (Order of Events)"
                              maxLength={6}
                              value={trackorder}
                              onChange={(e) => setTrackOrder(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Athletes Per Field (Order of Events){" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Athletes Per Field (Order of Events)"
                              maxLength={6}
                              value={fieldorder}
                              onChange={(e) => setFieldOrder(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Track Event Per Athlete{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Track Event Per Athlete"
                              value={trackevent}
                              onChange={(e) => setTrackEvent(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <h4 className="text-center errortext">(OR)</h4>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Track Event Per Athlete{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Track Event Per Athlete"
                              value={trackevent1}
                              onChange={(e) => setTrackEvent1(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Field Event Per Athlete{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Field Event Per Athlete"
                              value={fieldevent}
                              onChange={(e) => setFieldEvent(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <h4 className="text-center errortext">(OR)</h4>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Max Field Event Per Athlete{" "}
                              <span className="errortext"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter The Max Field Event Per Athlete"
                              value={fieldevent1}
                              onChange={(e) => setFieldEvent1(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    {errorMessage !== "" && (
                      <span className="errortext">{errorMessage}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  <a className="btn btn-success" onClick={addmeet}>
                    Submit
                  </a>
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
