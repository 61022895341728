import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import moment from "moment";
import {
  schoolheadothereventlist,
  schprofileview,
} from "../../../../services/schoolhead";

export const CompletedListEvent = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 130,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewdet(params.value, params)}
              title="View Event"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            <a
              onClick={() => entrypage(params.value, params)}
              title="Event Entry Players"
              className="btn-sm btn-gray me-2"
            >
              <i className="fas fa-users fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Event Name",
      width: 300,
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Start Date",
      width: 150,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
          if(params.value !== null){
            return moment(params.value).format("DD-MM-YYYY");
          }
      }
    },
    {
      headerName: "End Date",
      width: 150,
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return moment(params.value).format("DD-MM-YYYY");
        }
    }
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entry_start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return moment(params.value).format("DD-MM-YYYY");
        }
    }
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entry_end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value !== null){
          return moment(params.value).format("DD-MM-YYYY");
        }
    }
    },
  ];

  const navigate = useNavigate();
  function viewdet(tid, tdata) {
    dotracking(
      "view icon in event list for " +
        tdata.data.event_name +
        " (" +
        tid +
        ") event $$ " +
        trackobj.workdetheading
    );
    if (localStorage.getItem("Role") === "4") {
    localStorage.setItem("EPage", "3");
      navigate("/schoolhead/othereventdetail/" + tid);
    }
  }

  function entrypage(tid, tdata) {
    dotracking(
      "entry player list icon in event list for " +
        tdata.data.event_name +
        " (" +
        tid +
        ") event $$ " +
        trackobj.tourplayerlistheading
    );
    if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "3");
      navigate("/schoolhead/eventsentrylist/" + tid);
    }
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "4") {
      setFilterProduct([]);
      schprofileview().then((res) => {
        if (res.status === true) {
          schoolheadothereventlist(res.data.school_id).then((response) => {
            if (response.status === true) {
              setFilterProduct(response.data.completed_events);
            }
          });
        }
      });
    }
  }
  const gridRef = useRef();

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Completed Other Event List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Other Events</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Completed Other Event List
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
