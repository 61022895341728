import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { teamplayerlist } from "../../../../services/player";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const PlayerTeamList = () => {
    const { id } = useParams();
    const [filterProduct, setFilterProduct] = useState([]);

    const [tname, setTName] = useState("");
    const [tloc, setTLoc] = useState("");
    const [team, setTeam] = useState("");
    const [tmcode, setTmCode] = useState("");
    const [tmage, setTmAge] = useState("");
    const [tmpla, setTmPla] = useState("");
    const [urllink,setUrlLink]=useState("");


    useEffect(() => {
        const url = window.location.href;
        const head = url.split("/#/");
        const link = head[1].split("/");
        setUrlLink(link[1]);
        teamplayerlist(id).then((response) => {
            if (response.status === true) {
                if (response.data.length !== 0) {
                    setFilterProduct(response.data)
                    setTName(response.data[0].meet_name)
                    setTLoc(response.data[0].location)
                    setTeam(response.data[0].team_name)
                    setTmCode(response.data[0].team_code)
                    setTmAge(response.data[0].team_age_category)
                    setTmPla(response.data[0].no_of_players)

                }

            }
        })
    }, []);

    function backlist(){
        if(urllink === "myteams")        {
            dotracking("back button in team player list for " + tname + " (" + id + ") tournament $$ " + trackobj.dashheading);
            navigate("/player/dashboard")
        }else{
            if(localStorage.getItem("Page") === "1"){
                dotracking("back button in team player list for " + tname + " (" + id + ") tournament $$ " + trackobj.reglistheading);
                navigate("/player/registeredlist" )
            }else{
                dotracking("back button in team player list for " + tname + " (" + id + ") tournament $$ " + trackobj.myteamheading);
                navigate("/player/teamlist" ) 
            }
        }
        
    }

    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="side-app">
                <div className="page-header">
                    <div className="breadcrumb">
                        <h4 className="mb-0">Team Player Lists</h4>
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a>Tournament</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a>Team Lists</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Team Player Lists
                        </li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card mt-0">
                            <div className="card-body">
                                <div className="row mb-3">
                                    {filterProduct.length !== 0 &&
                                        <div className="col-md-9">
                                            <div className="text-left">
                                                <h3>{tname}</h3>
                                                {/* <h4>{tloc}</h4> */}
                                            </div>
                                        </div>
                                    }
                                   {filterProduct.length === 0 &&
                                        <div className="col-md-9">
                                            <div className="text-left">
                                                <h3>You will be soon assigned a team</h3>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-3">
                                        <div className="text-right">
                                            <a className="btn btn-gray" onClick={backlist}>
                                                <i className="fe fe-corner-up-left"></i> Back
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {filterProduct.length !== 0 &&
                    <div className="card ">
                        <div className="col-12">
                            <div className="card-header">
                                <h3 className="card-title text-success">{team}</h3>

                            </div>
                            <div className="card-body alignMe">
                                <h4 className="h4 mb-0 mt-3"><b>Team Code</b> <span>{tmcode}</span></h4>
                                {/* <h4 className="h4 mb-0 mt-3"><b>Age Category</b> <span>{tmage}</span></h4>

                                <h4 className="h4 mb-0 mt-3"><b>No of Players</b> <span>{tmpla}</span></h4> */}
                            </div>
                            <div id="playerlist">

                                <div className="row">

                                    {filterProduct.map((data, i) => {
                                        return (
                                            <div className="col-md-4" key={i}>
                                                {/* {data.username === localStorage.getItem("UID") &&
                                                    <div className="boxselect">
                                                        <div className="box__head">
                                                            <h1>
                                                                {data.first_name} {data.last_name}
                                                            </h1>
                                                        </div>
                                                        <div className="box__contentselect">
                                                            <h2>
                                                                <b>Player UID</b> <span>{data.username}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Team Name</b> <span>{data.team_name}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Team Code</b> <span>{data.team_code}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Gender</b> <span>{data.gender}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>DOB</b> <span>{data.date_of_birth}</span>
                                                            </h2>
                                                        </div>
                                                      

                                                    </div>
                                                }
                                                {data.username !== localStorage.getItem("UID") && */}
                                                    <div className="box">
                                                        <div className="box__head">
                                                            <h1>
                                                                {data.first_name} {data.last_name}
                                                            </h1>
                                                        </div>
                                                        {/* className={data.username === localStorage.getItem("UID") ? "box__contentselect" : "box__content"}  */}
                                                        <div className="box__content">
                                                            <h2>
                                                                <b>Player UID</b> <span>{data.username}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Team Name</b> <span>{data.team_name}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Team Code</b> <span>{data.team_code}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>Gender</b> <span>{data.gender}</span>
                                                            </h2>
                                                            <h2>
                                                                <b>DOB</b> <span>{data.date_of_birth}</span>
                                                            </h2>
                                                        </div>
                                                        {/* <div className="box_footer">
                                                    <h4>School Name :-</h4>
                                                    <p>{data.school_name}</p>
                                                </div> */}

                                                    </div>
                                                {/* } */}

                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </Fragment >
    );
};
