import React, { useEffect, useState } from "react";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourdetails } from "../../../../../services/register";
import { useNavigate, useParams } from "react-router-dom";
import {
  interperfplayerlist,
  interschoolplayerperflist,
} from "../../../../../services/interschool";
import { schprofileview } from "../../../../../services/schoolhead";
import moment from "moment";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Col, Row } from "react-bootstrap";

export const SchoolPerformanceList = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [filterProduct, setFilterProduct] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    tourdetails(id).then((response) => {
      if (response.status === true && response.data.tournament !== null) {
        setTName(response.data.tournament.meet_name);
      }
    });
    if (localStorage.getItem("Role") === "4") {
      schprofileview(id).then((res) => {
        if (res.status === true) {
          interschoolplayerperflist(id, res.data.school_id).then((response) => {
            if (response.data.length !== 0) {
              let arr = [];
              for (let i = 0; i < response.data.length; i++) {
                if (
                  response.data[i].player_performance.length !== 0 ||
                  response.data[i].photo_performance.length !== 0
                ) {
                  arr.push({
                    performance: response.data[i].performance,
                    player_performance: response.data[i].player_performance,
                    photo_performance: response.data[i].photo_performance,
                    s3_path: response.data[i].s3_path,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      interperfplayerlist(id, localStorage.getItem("UID")).then((response) => {
        if (response.data.length !== 0) {
          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            if (
              response.data[i].player_performance.length !== 0 ||
              response.data[i].photo_performance.length !== 0
            ) {
              arr.push({
                performance: response.data[i].performance,
                player_performance: response.data[i].player_performance,
                photo_performance: response.data[i].photo_performance,
                s3_path: response.data[i].s3_path,
              });
            }
          }
          setFilterProduct(arr);
        }
      });
    }
  }, []);
  function backlist() {
    dotracking(
      "back button in player performance list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentdetails/" + id);
    } else if (localStorage.getItem("Role") === "6") {
      if (localStorage.getItem("PPer") === "1") {
        navigate("/player/tournamentdetail/" + id);
      } else if (localStorage.getItem("PPer") === "2") {
        navigate("/player/registeredviewtournament/" + id);
      } else if (localStorage.getItem("PPer") === "3") {
        navigate("/player/eligibleviewtournament/" + id);
      }
    }
  }
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [pplist, setPPList] = useState([]);

  function openPLightbox(index, ilist, path) {
    setCurrentImage(index);
    if (ilist.length !== 0) {
      let arr1 = [];
      for (let i = 0; i < ilist.length; i++) {
        arr1.push({
          id: ilist[i].id,
          src: path + ilist[i].photo_name,
          width: 1,
          height: 1,
        });
      }
      setPPList(arr1);
    }

    setViewerIsOpen(true);
  }

  const [pshow, setPShow] = useState(false);
  const handlePclose = () => {
    setPShow(false);
    dotracking(
      "close share photos in performance for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );
  };
  const [shareUrl, setShare] = useState("");

  function handlePshow(timg) {
    setShare(timg);
    setPShow(true);
    dotracking(
      "share photos in performance for " + timg + " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perflistheading
    );
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Player Performance List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Tournament List</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Player Performance List
          </li>
        </ol>
      </div>

      <div className="col-md-12">
        <form className="card">
          <div className="card-body">
            <div className="row">
              <div className="row pb-3">
                <div className="col-md-10">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="text-right">
                    <a onClick={backlist} className="btn btn-gray">
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <h4 className="mb-2 text-secondary">
                MY SCHOOL PLAYER PERFORMANCE
              </h4>
              <div id="playerlist">
                <div className="row mt-2">
                  {filterProduct.length === 0 && (
                    <h4 className="errortext">
                      Player Performance will be updated soon
                    </h4>
                  )}
                  {filterProduct.map((item, j) => {
                    return (
                      <div className="col-md-12 mt-2" key={j}>
                        <h4 className="text-success">{item.performance}</h4>
                        <div className="row">
                          {item.player_performance.map((player, i) => {
                            return (
                              <div className="col-md-4" key={i}>
                                <div className="box">
                                  <div className="box__head">
                                    <h1>{player.first_name}</h1>
                                  </div>
                                  <div className="box__content">
                                    <h2>
                                      <b>Player UID</b>{" "}
                                      <span>{player.username}</span>
                                    </h2>
                                    <h2>
                                      <b>Gender</b> <span>{player.gender}</span>
                                    </h2>
                                    <h2>
                                      <b>DOB</b>{" "}
                                      <span>
                                        {moment(player.date_of_birth).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </span>
                                    </h2>
                                    <h2>
                                      <b>Category</b>{" "}
                                      <span>{player.category_name}</span>
                                    </h2>
                                  </div>
                                  <div className="box_footer">
                                    <h4>Team :-</h4>
                                    <p>{player.team_name}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="row">
                          {item.photo_performance.map((data, j) => {
                            return (
                              <div className="col-md-2 mt-2" key={j}>
                                <a
                                  className="photo-share"
                                  onClick={() =>
                                    handlePshow(item.s3_path + data.photo_name)
                                  }
                                >
                                  <i className="fas fa-share fa-lg"></i>
                                </a>
                                <img
                                  className="photoimg"
                                  src={item.s3_path + data.photo_name}
                                  height="100px"
                                  onClick={() =>
                                    openPLightbox(
                                      j,
                                      item.photo_performance,
                                      item.s3_path
                                    )
                                  }
                                  alt="Photo"
                                />
                              </div>
                            );
                          })}

                          <div className={pshow ? "mymodal open" : "mymodal"}>
                            <div className="modoverlay"></div>
                            <div className="mymodal-content">
                              <h4 className="text-center">Share Photos</h4>
                              <span
                                className="close-btn"
                                onClick={handlePclose}
                              >
                                &times;
                              </span>
                              <div className="border-bottom"></div>
                              <Row className="row-sm mt-3">
                                <div className="text-center">
                                  <WhatsappShareButton
                                    url={shareUrl}
                                    title="Hey, check out my photos!"
                                    className="me-3"
                                  >
                                    <WhatsappIcon size={44} round={true} />
                                  </WhatsappShareButton>
                                  <FacebookShareButton
                                    url={shareUrl}
                                    quote="Hey, check out my photos!"
                                    className="me-3"
                                  >
                                    <FacebookIcon size={44}></FacebookIcon>
                                  </FacebookShareButton>
                                  <TwitterShareButton
                                    url={shareUrl}
                                    title="Hey, check out my photos!"
                                    className="me-3"
                                  >
                                    <TwitterIcon size={44}></TwitterIcon>
                                  </TwitterShareButton>
                                </div>
                              </Row>
                            </div>
                          </div>
                          <ModalGateway>
                            {viewerIsOpen ? (
                              <Modal onClose={closeLightbox}>
                                <Carousel
                                  currentIndex={currentImage}
                                  views={pplist.map((x) => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                  }))}
                                />
                              </Modal>
                            ) : null}
                          </ModalGateway>
                        </div>
                        <div className="border-bottom mt-2"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
