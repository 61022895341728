import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  citysendpaymentlink,
  crmtourentrylist,
  crmtourviewdetail,
} from "../../../../services/cityregionaladmin";
import {
  changerefundpaymentstatus,
  changetourpaymentstatus,
  sendpaymentlink,
  tourentrylist,
  tourviewdetail,
} from "../../../../services/superadmin";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import exportUrl from "../../../../exportUtl";
import { scheadtourviewdetail, schooltourplayerlist } from "../../../../services/schoolhead";
import { Reftourdetail, Reftourentrylist } from "../../../../services/referee";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { coachtourentrylist, coachtourviewdetail } from "../../../../services/coach";

export const Entryplayerslist = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const [tname, setTName] = useState("");
  const [ttype, setTType] = useState("");
  const [ddate, setDDate] = useState("");
  const [sdate, setSDate] = useState("");
  const [eodate, setEODate] = useState("");
  const [curdate, setCurDate] = useState("");
  const [tfree, setFree] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "entries_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.payment_status === "0") {
          if (moment(new Date()).format("yyyy-MM-DD") < sdate) {
            return (
              <div className="text-center">
                <a
                  className="btn-sm btn-secondary"
                  title="Change Payment Status"
                  onClick={() => handleShow(params.value, params)}
                >
                  <i className="fa fa-edit"></i>
                </a>
              </div>
            );
          } else {
            return (
              <div className="text-center">
                <a className="text-danger">Entry Closed</a>
              </div>
            );
          }
        }
      },
    },
    {
      headerName: "Refund",
      width: 100,
      field: "entries_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (
          params.data.pay_status === "SUCCESS" &&
          ttype !== "Inter School Tournament"
        ) {
          if (tfree === "N") {
            return (
              <div className="text-center">
                <a
                  className="btn-sm btn-success"
                  title="Refund Payment"
                  onClick={() => handleRShow(params.value, params)}
                >
                  <i className="fa fa-undo"></i> Refund
                </a>
              </div>
            );
          }
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment",
      width: 150,
      field: "payment_status",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value === "0") {
          if (moment(new Date()).format("yyyy-MM-DD") <= ddate) {
            if (localStorage.getItem("Role") !== "4") {
              return (
                <div className="text-center">
                  <a
                    className="btn-sm btn-success"
                    onClick={() => sendpayment(params)}
                  >
                    Send Payment Link
                  </a>
                </div>
              );
            }
          } else {
            return (
              <div className="text-center">
                <a className="text-danger">Entry Closed</a>
              </div>
            );
          }
        } else if (params.value === "1") {
          return (
            <div className="text-center">
              <a className="text-success">Paid</a>
            </div>
          );
        }
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Email ID",
      width: 200,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   {
      headerName: "Type",
      width: 160,
      field: "entry_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === 1) {
          return "Online";
        } else if (params.value === 2) {
          return "Offline";
        }
      },
    },
    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
  ];

  const columnSchDefs = [
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Email ID",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },{
      headerName: "Type",
      width: 160,
      field: "entry_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === 1) {
          return "Online";
        } else if (params.value === 2) {
          return "Offline";
        }
      },
    },
    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team Name",
      width: 150,
      field: "team_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnPlaDefs = [
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Email ID",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },{
      headerName: "Type",
      width: 160,
      field: "entry_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === 1) {
          return "Online";
        } else if (params.value === 2) {
          return "Offline";
        }
      },
    },
    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnCoaDefs = [
    {
      headerName: "Performance",
      width: 120,
      field: "user_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-success"
              title="Add Performance"
              onClick={()=>addperf(params.value, params.data)}
            >
              <i className="fa fa-user-plus"></i> 
            </a>
          </div>
        );
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Email ID",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },{
      headerName: "Type",
      width: 160,
      field: "entry_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === 1) {
          return "Online";
        } else if (params.value === 2) {
          return "Offline";
        }
      },
    },
    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Class",
      width: 120,
      field: "class_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Registered Date",
      width: 200,
      field: "updated_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team Name",
      width: 150,
      field: "team_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function addperf(fid, fdata) {
    dotracking(
      "add performance icon in entry player list for " + fdata.first_name + " (" + fdata.username + " - " + fid + ") " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.addperfheading
    );
    navigate("/coach/individualperformance/" + id + "/" + fid)
  }
  const [show, setShow] = useState(false);
  const [payid, setPayId] = useState("");
  const [ordid, setOrdId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [entryid, setEntryId] = useState("");
  const [ename, setEntryName] = useState("");

  function handleShow(fid, fdata) {
    dotracking(
      "edit icon for change payment status in " +
        tname +
        " (" +
        id +
        ") tournament for player " +
        fdata.data.first_name +
        " (" +
        fdata.data.username +
        " - " +
        fid +
        ") $$ " +
        trackobj.tourplayerlistheading
    );
    setEntryName(
      fdata.data.first_name + " (" + fdata.data.username + " - " + fid + ")"
    );
    setOrdId("");
    setPayId("");
    setEntryId(fid);
    setErrorMsg("");
    setShow(true);
  }

  function handleclose() {
    dotracking(
      "close button in change payment status for " +
        ename +
        " player in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    setShow(false);
    setEntryName("");
    setErrorMsg("");
    setOrdId("");
    setPayId("");
    setEntryId("");
  }

  const [rshow, setRShow] = useState(false);
  const [refdate, setRefundDate] = useState("");
  const [refundtype, setRefundType] = useState("");

  const [refid, setRefundID] = useState("");

  function handleRShow(fid, fdata) {
    dotracking(
      "refund icon for refund payment in " +
        tname +
        " (" +
        id +
        ") tournament for player " +
        fdata.data.first_name +
        " (" +
        fdata.data.username +
        " - " +
        fid +
        ") $$ " +
        trackobj.tourplayerlistheading
    );
    setEntryName(
      fdata.data.first_name + " (" + fdata.data.username + " - " + fid + ")"
    );
    setRefundID(fid);
    setRefundDate("");
    setErrorMsg("");
    setRShow(true);
  }

  function handleRclose() {
    dotracking(
      "close button in refund payment for " +
        ename +
        " player in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    setRShow(false);
    setEntryName("");
    setRefundID("");
    setRefundDate("");
    setErrorMsg("");
  }

  function submitrefund(e) {
    e.preventDefault();
    setErrorMsg("");
    var valid = "";
    if (refundtype !== "") {
      if (refundtype === "2") {
        if (refdate === "") {
          valid = "Not";
          setErrorMsg("Please Select Refund Date");
        }
      }
      if (valid === "") {
        const temp = new FormData();
        temp.append("id", refid);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("refunded_date", refdate);
        temp.append("refunded_type", refundtype);

        dotracking(
          "submit button in refund payment for " +
            ename +
            " player in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerlistheading
        );
        changerefundpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            handleRclose();
            toast.success(response.message);
            listall();
          }
        });
      }
    } else {
      setErrorMsg("Please Select Refund Type");
    }
  }

  function submitpay(e) {
    e.preventDefault();
    setErrorMsg("");
    if (payid !== "") {
      if (ordid !== "") {
        const temp = new FormData();
        temp.append("payment_id", payid);
        temp.append("order_id", ordid);
        temp.append("id", entryid);
        temp.append("payment_status", 1);
        temp.append("pay_status", "SUCCESS");
        dotracking(
          "add button in change payment status for " +
            ename +
            " player in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourplayerlistheading
        );
        changetourpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            toast.success(response.message);
            listall();
          }
        });
      } else {
        setErrorMsg("Please Enter Order Id");
      }
    } else {
      setErrorMsg("Please Enter Payment Id");
    }
  }

  function sendpayment(datas) {
    Swal.fire({
      title: "Are you sure want to send a payment link to respective email?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("Role") === "1") {
          dotracking(
            "send payment link button in entry players list for " +
              datas.data.first_name +
              " (" +
              datas.data.username +
              " - " +
              datas.data.user_id +
              ") player" +
              " in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          sendpaymentlink(id, datas.data.user_id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        } else if (localStorage.getItem("Role") === "3") {
          dotracking(
            "send payment link button in entry players list for " +
              datas.data.first_name +
              " (" +
              datas.data.username +
              " - " +
              datas.data.user_id +
              ") player" +
              " in " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.tourplayerlistheading
          );
          citysendpaymentlink(id, datas.data.user_id).then((response) => {
            if (response.status === true) {
              toast.success(response.message);
            }
          });
        }
      }
    });
  }

  useEffect(() => {
    setCurDate(moment(new Date()).format("YYYY-MM-DD"));

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setFree(response.data.tournament.free_tournament);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setFree(response.data.tournament.free_tournament);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setFree(response.data.tournament.free_tournament);
        }
      })
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setFree(response.data.tournament.free_tournament);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setTType(response.data.tournament.meet_type);
          setSDate(response.data.tournament.start_date);
          setDDate(response.data.tournament.entry_deadline_date);
          setEODate(response.data.tournament.entry_open);
          setFree(response.data.tournament.free_tournament);
        }
      });
    } 
    listall();
  }, []);

  function listall() {
    if (localStorage.getItem("Role") === "1") {
      tourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      schooltourplayerlist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourentrylist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }
  function backlist() {
    if (localStorage.getItem("Role") !== "4") {
      dotracking(
        "back button in entry players list for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
    }
    if (localStorage.getItem("Role") === "1") {
      if(ttype === "Intra School Tournament"){
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/superadmin/tournamentlist");
      }else{
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/superadmin/tournamentschoollist/" + id);
      }
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentlist");
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("Page") === "1") {
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        navigate("/schoolhead/tournamentlist");
      } else if (localStorage.getItem("Page") === "3") {
        dotracking(
          "back button in entry players list for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.comtourlistheading
        );
        navigate("/schoolhead/completedtournament");
      }
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentlist");
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentlist");
    }
  }

  function rowDoubleClicked(e) {
    dotracking(
      "row double clicked in entry players list for " +
        e.data.first_name +
        " (" +
        e.data.username +
        " - " +
        e.data.user_id +
        ") player " +
        " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.playerdetheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/schoolhead/tournamentplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/tournamentplayer/" + e.data.user_id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/tournamentplayer/" + e.data.user_id);
    } 
  }

  function reflist() {
    dotracking(
      "refund players list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.refundlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/refundlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/refundlist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/refundlist/" + id);
    }
  }

  function eplayer() {
    dotracking(
      "export player button in entry players list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourplayerlistheading
    );
    window.open(exportUrl + "export-player-register/" + id, "_blank");
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Player Entry Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              Player Entry Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-7">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="text-right">
                      {(localStorage.getItem("Role") === "1" || localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") &&tfree === "N" &&  (
                        <a className="btn btn-warning me-2" onClick={reflist}>
                          <i className="fa-solid fa-list-alt"></i> Refund List
                        </a>
                      )}

                      <a className="btn btn-secondary me-2" onClick={eplayer}>
                        <i className="fa-solid fa-file-export"></i> Export
                        Player
                      </a>
                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  {(localStorage.getItem("Role") === "1" ||
                    localStorage.getItem("Role") === "3") && (
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                  {localStorage.getItem("Role") === "4" && (
                    <AgGridReact
                      columnDefs={columnSchDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}

{localStorage.getItem("Role") === "7" && (
                    <AgGridReact
                      columnDefs={columnCoaDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                  {
                    localStorage.getItem("Role") === "5" && (
                    <AgGridReact
                      columnDefs={columnPlaDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      sortingOrder={sorting}
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                      onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                    />
                  )}
                </div>

                <Modal show={show} size="lg" onHide={handleclose}>
                  <Modal.Header>
                    <h4>Change Payment Status</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Payment ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment ID"
                            value={payid}
                            onChange={(e) => setPayId(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Order ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Order ID"
                            value={ordid}
                            onChange={(e) => setOrdId(e.target.value)}
                          />
                        </div>
                      </Col>

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleclose}>
                      Close
                    </a>

                    <a onClick={submitpay} className="btn btn-success">
                      Add
                    </a>
                  </Modal.Footer>
                </Modal>

                <Modal show={rshow} size="lg">
                  <Modal.Header>
                    <h4>Refund Payment</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">Refund Type <span className="errortext">*</span> </label>
                          <select
                            className="form-control"
                            value={refundtype}
                            onChange={(e) => setRefundType(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Select Refund Type
                            </option>
                            <option value="1">Automatic Refund</option>
                            <option value="2">Manual Refund</option>
                          </select>
                        </div>
                      </Col>
                      {refundtype === "2" && (
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <label className="mb-2">Refund Date <span className="errortext">*</span></label>
                            <input
                              type="date"
                              className="form-control"
                              min={eodate}
                              max={curdate}
                              value={refdate}
                              onChange={(e) => setRefundDate(e.target.value)}
                            />
                          </div>
                        </Col>
                      )}

                      {errorMsg !== "" && (
                        <span className="errortext">{errorMsg}</span>
                      )}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a className="btn btn-danger" onClick={handleRclose}>
                      Close
                    </a>

                    <a onClick={submitrefund} className="btn btn-success">
                      Submit
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
