import React, { useState, useCallback, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useNavigate, useParams } from "react-router-dom";
import { photoslist, tourviewdetail } from "../../../../services/superadmin";
import { tourdetails } from "../../../../services/register";
import { Reftourdetail } from "../../../../services/referee";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import { playertourbanner } from "../../../../services/player";
import bannerlog from "../../../trackingbanner";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Col, Row } from "react-bootstrap";
import { coachtourviewdetail } from "../../../../services/coach";

export const Photos = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photolist, setPhotoLink] = useState([]);

  const { id } = useParams();
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  const [pplist, setPPList] = useState([]);

  function openPLightbox(index, ilist) {
    setCurrentImage(index);
    if (ilist.length !== 0) {
      setPPList(ilist);
    }

    setViewerIsOpen(true);
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const navigate = useNavigate();
  const [tourbanner, setTourBanner] = useState("");
  const [linkbanner, setLinkBanner] = useState("");
  const [bannid, setBannerID] = useState("");

  function banlink() {
    dotracking(
      "banner in photos for " +
        tourbanner +
        " (" +
        bannid +
        ") in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.tourphotosheading
    );
    bannerlog(bannid, trackobj.bannerphotos);
    if (linkbanner !== "") {
      window.open(linkbanner, "_blank");
    }
  }

  function backlist() {
    dotracking(
      "back button in photos for tournament " +
        tname +
        " (" +
        id +
        ") $$ " +
        trackobj.photoheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/photogallery");
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/photogallery");
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/photogallery");
    } else if (localStorage.getItem("Role") === "6") {
      // if (urllink === "performancephotos") {
      //   if (localStorage.getItem("PPer") === "1") {
      //     navigate("/player/tournamentdetail/" + id);
      //   } else if (localStorage.getItem("PPer") === "2") {
      //     navigate("/player/registeredviewtournament/" + id);
      //   } else if (localStorage.getItem("PPer") === "3") {
      //     navigate("/player/eligibleviewtournament/" + id);
      //   }
      // } else {
      navigate("/player/photogallery");
      // }
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/photogallery");
    }
  }
  const [tname, setTName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("pagename", "Photos");
      playertourbanner(temp).then((res) => {
        if (res.status === true && res.data.banners.length !== 0) {
          setBannerID(res.data.banners[0].banner_id);
          setTourBanner(res.data.banner_path + res.data.banners[0].b_file);
          if (res.data.banners[0].banner_link !== null) {
            setLinkBanner(res.data.banners[0].banner_link);
          }
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    photoslist(id).then((response) => {
      if (response.status === true) {
        if (response.data.tournamentlist.length !== 0) {
          let arr1 = [];
          for (let i = 0; i < response.data.tournamentlist.length; i++) {
            arr1.push({
              id: response.data.tournamentlist[i].id,
              src:
                response.data.tournamentimage_path +
                response.data.tournamentlist[i].imagename,
              width: 1,
              height: 1,
            });
          }
          setPhotoLink(arr1);
        }
      }
    });
  }, []);

  const [show, setShow] = useState(false);
  const handleclose = () => {
    setShow(false);
    dotracking(
      "close share photos in photos for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
  };
  const [shareUrl, setShare] = useState("");

  function handleshow(timg) {
    setShare(timg);
    setShow(true);
    dotracking(
      "share photos in photos for " + timg + " in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.photoheading
    );
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Photo / Gallery</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Photo / Gallery
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-0">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-10">
                  <div className="text-left">
                    <h3>{tname}</h3>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="text-right">
                    <a className="btn btn-gray" onClick={backlist}>
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <>
                {tourbanner !== "" && (
                  <a onClick={banlink}>
                    <img
                      src={tourbanner}
                      className="ag-courses-item_img mb-2"
                      alt="bannerimg"
                    />
                  </a>
                )}
                {photolist.length === 0 && (
                  <h4 className="text-danger">Photos will be updated soon</h4>
                )}

                <div className="row">
                  {photolist.map((data, i) => {
                    return (
                      <div className="col-md-2 mt-4" key={i}>
                        <a
                          className="photo-share"
                          onClick={() => handleshow(data.src)}
                        >
                          <i className="fas fa-share fa-lg"></i>
                        </a>

                        <img
                          className="photoimg"
                          src={data.src}
                          height="100px"
                          onClick={() => openPLightbox(i, photolist)}
                          alt="Photo"
                        />
                      </div>
                    );
                  })}
                </div>

                <div className={show ? "mymodal open" : "mymodal"}>
                  <div className="modoverlay"></div>
                  <div className="mymodal-content">
                    <h4 className="text-center">Share Photos</h4>
                    <span className="close-btn" onClick={handleclose}>
                      &times;
                    </span>
                    <div className="border-bottom"></div>
                    <Row className="row-sm mt-3">
                      <div className="text-center">
                          <WhatsappShareButton url={shareUrl} title="Hey, check out my photos!" className="me-3">
                            <WhatsappIcon size={44} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={shareUrl} quote="Hey, check out my photos!" className="me-3">
                            <FacebookIcon size={44}></FacebookIcon>
                          </FacebookShareButton>
                          <TwitterShareButton url={shareUrl} title="Hey, check out my photos!" className="me-3">
                            <TwitterIcon size={44}></TwitterIcon>
                          </TwitterShareButton>
                      </div>
                    </Row>
                    {/* <a className="btn btn-gray" onClick={handleclose}>Close </a> */}
                  </div>
                </div>
                {/* <Gallery
                    photos={photolist}
                    // direction={"column"}
                    // columns={9}
                    onClick={openLightbox}
                  /> */}

                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={pplist.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
