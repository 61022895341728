import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { schoolteamupload } from "../../../../../services/superadmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { useNavigate } from "react-router-dom";
import { coachviewprofile } from "../../../../../services/coach";

export const Schoolteamupload = () => {
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");

  const [school, setSchool] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");

  let arr = [];

  useEffect(() => {
   if(localStorage.getItem("Role") === "7"){
    coachviewprofile().then((response) => {
      if (response.status === true) {
        setSchool(response.data.school_id);
      }
    })
   }else{
    setSchool(0)
   }
  }, []);

  
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("School Team File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("School Team File size should be less than 5MB");
      // }
    }
  }

  function baseupload() {
    setErrorMsg("");
      if (basefile !== "") {
        if (fileMsg === "") {
          setLoading(true);
          const temp = new FormData();
          temp.append("excel", basefile);
          temp.append("from_school_id", school);
          schoolteamupload(temp).then((response) => {
            if (response.status === true) {
              setLoading(false);
              setBase("");
              setTRecord(response.data.total_rows);
              setIRecord(response.data.inserted_count);
              setURecord(response.data.failed_count);
              setStatus(response.data.status);
            } else {
              setLoading(false);
              setErrorMsg(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Base File");
      }
    
  }
  const navigate=useNavigate()
    function backlist(){
    dotracking("back button in upload school team $$ " + trackobj.schteamlistheading)
    if(localStorage.getItem("Role") === "7"){
      navigate("/coach/schoolteamlist")
    }else{
      navigate("/superadmin/schoolteamlist")
    }
    
  }
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">School Team Upload</h4>
          </div>
          <ol className="breadcrumb">
           
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              School Team
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="uploadform col-md-8 mx-auto">
                <Form className="card">
                  <div className="card-body">
                    
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Upload School Team File{" "}
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>Back</a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>

                      <a
                        className="btn btn-secondary"
                        href={require("../../../../../assets/images/selected_school_teams.xlsx")}
                        download
                      
                      >
                        Sample Excel
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Failed Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};
