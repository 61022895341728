import React, { useEffect, useState } from "react";
import {
  emailkeywordlist,
  wapptemplates,
  wapptemplatesdetails,
} from "../../../../../services/superadmin";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";

export const WPaymentTemplate = () => {
  const [keylist, setKeyList] = useState([]);
  const [content, setContent] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function toursubmit() {
    if (content !== "") {
      const temp = new FormData();
      temp.append("admin_id", localStorage.getItem("UID"));
      temp.append("template_id", 10);
      temp.append("content", content);
      temp.append("status", 1);
      dotracking("submit button in whatsapp payment link templates $$ " + trackobj.paylinktempheading+ trackobj.whattemp);
      wapptemplates(temp).then((response) => {
        if (response.status === true) {
          toast.success(response.message);
          listall();
        } else {
          setErrorMsg(response.message);
        }
      });
    } else {
      setErrorMsg("Please Enter the text");
    }
  }

  function listall() {
    wapptemplatesdetails(10).then((response) => {
      if (response.status === true) {
        if (response.data.template_info !== null) {
          setContent(response.data.template_info.message_content);
        }
      }
    });
  }

  useEffect(() => {
    listall();
    emailkeywordlist(10).then((response) => {
      if (response.status === true) {
        setKeyList(response.data);
      }
    });
  }, []);
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Templates</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Templates</a>
          </li>
          <li className="breadcrumb-item">
            <a>WhatsApp</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tournament Payment
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <h4>Tournament Payment Link WhatsApp </h4>
              <div className="table-responsive">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Keyword</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keylist.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.keywords} </td>
                          <td>{data.description} </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <hr />
              <div className="row mt-2">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Message <span className="errortext"> *</span>
                  </label>
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder="Enter Message"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
              </div>

              {errorMsg !== "" && <span className="errortext">{errorMsg}</span>}
              <hr />
              <div className="text-right">
                <a className="btn btn-success" onClick={toursubmit}>
                  Submit
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
