import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";
import {
  assessmentplayerlist,
  gettokenfromapi,
} from "../../../services/superadmin";
import { Playerprofiledetails } from "../../../services/player";
import { sahassessmentdatelist } from "../../../services/sahapi";
import { funderplayerdetail } from "../../../services/funder";

export const AssessmentDetail = () => {
  const navigate = useNavigate();

  const { id,eid } = useParams();

  const [filterProduct, setFilterProduct] = useState([]);
  const [sfilterProduct, setSFilterProduct] = useState([]);
  const [fittot, setFitTot] = useState([]);
  const [skilltot, setSkillTot] = useState([]);

  let arr = [];
  let farr = [];

  useEffect(() => {
    // const temp = new FormData();
    // temp.append("assessment_group_id", id);
    // assessmentplayerlist(temp).then((response) => {
    //   if (response.status === true) {
    //     farr = [];
    //     if (response.data.fitness_assessment_details.length !== 0) {
    //       var last =
    //         response.data.fitness_assessment_details.slice(-1)[0]
    //           .assessment_type_count;

    //       for (let j = 1; j <= last; j++) {
    //         const fqfilter = response.data.fitness_assessment_details.filter(
    //           (data) => {
    //             return data.assessment_type_count === j;
    //           }
    //         );
    //         var tfq = fqfilter.slice(-1)[0].attribute_fq;

    //         const sqfilter = response.data.fitness_assessment_details.filter(
    //           (data) => {
    //             return data.assessment_type_count === j;
    //           }
    //         );
    //         var tsq = sqfilter.slice(-1)[0].attribute_sq;

    //         const tqfilter = response.data.fitness_assessment_details.filter(
    //           (data) => {
    //             return data.assessment_type_count === j;
    //           }
    //         );
    //         var ttq = tqfilter.slice(-1)[0].attribute_tq;

    //         farr.push({
    //           id: j,
    //           fq: tfq,
    //           sq: tsq,
    //           tq: ttq,
    //         });
    //       }
    //       setFitTot(farr);
    //     }

    //     setFilterProduct(response.data.fitness_assessment_details);
    //     arr = [];
    //     if (response.data.skill_assessment_details.length !== 0) {
    //       var last =
    //         response.data.skill_assessment_details.slice(-1)[0]
    //           .assessment_type_count;

    //       for (let j = 1; j <= last; j++) {
    //         const sfilter = response.data.skill_assessment_details.filter(
    //           (data) => {
    //             return data.assessment_type_count === j;
    //           }
    //         );
    //         var lgrade = sfilter.slice(-1)[0].overall_grade;
    //         arr.push({
    //           id: j,
    //           overall_grade: lgrade,
    //         });
    //       }
    //       setSkillTot(arr);
    //     }
    //     setSFilterProduct(response.data.skill_assessment_details);
    //   }
    // });
  if(localStorage.getItem("Role") === "8"){
    funderplayerdetail(eid).then((response) => {
      if (response.status === true) {
        Playerprofiledetails(response.data.playerlist.username).then((res2) => {
          if (res2.status === true) {
            if (res2.data.athletelist !== null) {
              if (res2.data.athletelist.old_user_id !== null) {
                const pltemp = new FormData();
                pltemp.append(
                  "data_req",
                  "asmnt_data" + res2.data.athletelist.old_user_id
                );
                gettokenfromapi(pltemp).then((res) => {
                  if (res.status === true) {
                    sahassessmentdatelist(
                      "asmnt_data&esid=" + res2.data.athletelist.old_user_id,
                      res.data
                    ).then((response) => {
                      if (response.assmts[0].length !== 0) {
                        for (let i = 0; i < response.assmts[0].length; i++) {
                          if (response.assmts[0][i].assmts.length !== 0) {
                            if(response.assmts[0][i].date === id){
                              const fdata = response.assmts[0][i].assmts.filter(
                                (data) => {
                                  return data.type === "fitness";
                                }
                              );
                              setFilterProduct(fdata);
                              const sdata = response.assmts[0][i].assmts.filter(
                                (data) => {
                                  return data.type === "skill";
                                }
                              );
      
                              setSFilterProduct(sdata);
                            }
                          }
                        }
                      }
                    });
                  }
                });
              }
            }
          }
        });
      }
    });
  }else{
    Playerprofiledetails(localStorage.getItem("Name")).then((res2) => {
      if (res2.status === true) {
        if (res2.data.athletelist !== null) {
          if (res2.data.athletelist.old_user_id !== null) {
            const pltemp = new FormData();
            pltemp.append(
              "data_req",
              "asmnt_data" + res2.data.athletelist.old_user_id
            );
            gettokenfromapi(pltemp).then((res) => {
              if (res.status === true) {
                sahassessmentdatelist(
                  "asmnt_data&esid=" + res2.data.athletelist.old_user_id,
                  res.data
                ).then((response) => {
                  if (response.assmts[0].length !== 0) {
                    for (let i = 0; i < response.assmts[0].length; i++) {
                      if (response.assmts[0][i].assmts.length !== 0) {
                        if(response.assmts[0][i].date === id){
                          const fdata = response.assmts[0][i].assmts.filter(
                            (data) => {
                              return data.type === "fitness";
                            }
                          );
                          setFilterProduct(fdata);
                          const sdata = response.assmts[0][i].assmts.filter(
                            (data) => {
                              return data.type === "skill";
                            }
                          );
  
                          setSFilterProduct(sdata);
                        }
                      }
                    }
                  }
                });
              }
            });
          }
        }
      }
    });
  }
    
  }, []);

  function backlist() {
    if(localStorage.getItem("Role") === "8"){
      dotracking(
        "back button in assessment detail for " +
          eid +
          " $$ " +
          trackobj.playerdetheading
      );
      navigate("/funder/viewplayer/" +eid);
    }else{
      dotracking(
        "back button in assessment detail for " +
          id +
          " $$ " +
          trackobj.asslistheading
      );
      navigate("/player/assessmentlist");
    }
    
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Assessment Details</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Assessment</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Assessment Details
            </li>
          </ol>
        </div>

        <div className="container">
          <div className="ag_courses_head">
            <div className="row">
              <div className="col-md-10">
                <h4>Fitness Assessment</h4>
              </div>
              <div className="col-md-2 text-right">
                <a className="btn btn-gray" onClick={backlist}>
                  <i className="fe fe-corner-up-left"></i> Back
                </a>
              </div>
            </div>
          </div>
          {filterProduct.length === 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Fitness Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {filterProduct.length !== 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h4>5 Point Grading System</h4>
                    <span className="errortext">
                      A - 5 Points (Excellent), B - 4 Points (Very Good), C - 3
                      Points (Good) , D - 2 Points (Average) , E - 1 Point
                      (Needs Improvement)
                    </span>
                    {filterProduct.map((item, j) => {
                      return (
                        <>
                          <table
                            className="table table-bordered table-hover mt-3"
                            key={j}
                          >
                            <thead>
                              <tr>
                                <th>Parameters Mesured</th>
                                <th>Grade</th>
                                <th>Score</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.attributes.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.fst_theme_name}</td>
                                    <td>{data.grade}</td>
                                    <td>{data.score}</td>
                                    <td>{data.name}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <thead>
                              <tr>
                                <th>FQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.fq}</th>
                              </tr>
                              <tr>
                                <th>SQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.sq}</th>
                              </tr>
                              <tr>
                                <th>TQ</th>
                                <th></th>
                                <th></th>
                                <th>{item.tq}</th>
                              </tr>
                            </thead>
                          </table>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="ag_courses_head ">
                <h4>Remedial Actions</h4>
              </div>
              {filterProduct.map((item, j) => {
                return (
                  <>
                    <div className="row" key={j}>
                      {item.attributes.map((data, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div className="our_solution_category">
                              <div className="solution_cards_box">
                                <div className="solution_card">
                                  <div className="hover_color_bubble"></div>
                                  <div className="so_top_icon">
                                    {data.fst_theme_name.includes(
                                      "Anaerobic"
                                    ) && (
                                      <img
                                        src={require("../../../assets/images/Anaerobic.png")}
                                        alt="fitness"
                                      />
                                    )}
                                    {data.fst_theme_name.includes(
                                      "Aerobic"
                                    ) && (
                                      <img
                                        src={require("../../../assets/images/Aerobic.png")}
                                        alt="fitness"
                                      />
                                    )}
                                    {data.fst_theme_name.includes(
                                      "Flexibility"
                                    ) && (
                                      <img
                                        src={require("../../../assets/images/Fitness.png")}
                                        alt="fitness"
                                      />
                                    )}
                                    {data.fst_theme_name.includes(
                                      "Abdominal"
                                    ) && (
                                      <img
                                        src={require("../../../assets/images/Abdominal.png")}
                                        alt="fitness"
                                      />
                                    )}
                                    {(data.fst_theme_name.includes("Lower") ||
                                      data.fst_theme_name.includes("LB")) && (
                                      <img
                                        src={require("../../../assets/images/Lower.png")}
                                        alt="fitness"
                                      />
                                    )}
                                    {(data.fst_theme_name.includes("Upper") ||
                                      data.fst_theme_name.includes("UB")) && (
                                      <img
                                        src={require("../../../assets/images/Upper.png")}
                                        alt="fitness"
                                      />
                                    )}
                                  </div>
                                  <div className="solu_title">
                                    <h3>{data.fst_theme_name}</h3>
                                  </div>
                                  <div className="solu_description">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data.student_fdbk,
                                      }}
                                      title={data.student_fdbk}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
          )}

          {sfilterProduct.length === 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="card testimonial-card mt-2 mb-3 shadow">
                  <div className="card-body text-center">
                    <h3 className="errortext">
                      Skill Assessment will be updated soon
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          {sfilterProduct.length !== 0 && (
            <div className="row">
              <div className="row">
                <div className="ag_courses_head">
                  <h4>Skill Assessment</h4>
                </div>
                <div className="col-md-12">
                  <div className="card testimonial-card mt-2 mb-3 shadow">
                    <div className="card-body text-center">
                      <h4>5 Point Grading System</h4>
                      <span className="errortext">
                        A - 5 Points (Very Good), B - 4 Points (Good), C - 3
                        Points (Average) , D - 2 Points (Developing) , E - 1
                        Point (Needs Improvement)
                      </span>
                      {sfilterProduct.map((item, j) => {
                        return (
                          <>
                            <table
                              className="table table-bordered table-hover mt-3"
                              key={j}
                            >
                              <thead>
                                <tr>
                                  <th>Skills Mesured</th>
                                  <th>Grade</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.attributes.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{data.name}</td>
                                      <td>{data.grade}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <thead>
                                <tr>
                                  <th>Overall Grade</th>
                                  <th>{item.overall_grade}</th>
                                </tr>
                              </thead>
                            </table>
                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
