import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { paymentlistadmin } from "../../../services/superadmin";
import dotracking from "../../trackinglogs";
import trackobj from "../../trackingpages.json";

export const PaymentList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      field: "user_id",
      width: 100,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={()=>viewdet(params.value,params)}
              title="Player Detail"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 170,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 150,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
    {
      headerName: "Amount",
      width: 120,
      field: "amount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Order ID",
      width: 200,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment ID",
      width: 200,
      field: "payment_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "pay_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Date",
      width: 150,
      field: "payment_date_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy")
        }
      },
    },
  ];

  function viewdet(pid,pdata) {
    dotracking("view icon button in payment list for " + pdata.data.username + " (" + pid + ") player in " + pdata.data.meet_name + " tournament $$ " + trackobj.playerdetheading);
    navigate("/superadmin/paymentdetail/" + pid)
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    paymentlistadmin().then((response) => {
        if(response.status === true){
            setFilterProduct(response.data)
        }
    })
  }
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Payment Lists</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Payment</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Payment Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
