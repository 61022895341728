import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

import { useNavigate, useParams } from "react-router-dom";
import { crmtourviewdetail } from "../../../../services/cityregionaladmin";
import exportUrl from "../../../../exportUtl";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import {
  pointsentryupload,
  pointsuploadlist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../services/superadmin";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const PointsEntry = () => {
  const { id, eid, tid } = useParams();
  const navigate = useNavigate();
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");
  const [catname, setCatname] = useState("");

  const [tname, setTName] = useState("");
  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");
  const [failid, setFailId] = useState(0);

  let arr = [];

  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Upload Excel",
      width: 170,
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={() =>
                dotracking(
                  "click to download link in points upload for " +
                    catname +
                    "(" +
                    eid +
                    " ) category in " +
                    tname +
                    " (" +
                    id +
                    ") tournament and file " +
                    params.value +
                    " (" +
                    params.data.id +
                    ") $$ " +
                    trackobj.pointuploadheading
                )
              }
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const [s3path, setS3Path] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }

    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    setS3Path("");
    pointsuploadlist(id, eid).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.details);
        setS3Path(response.data.s3path);
      }
    });
  }
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("Points File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("Base Data File size should be less than 5MB");
      // }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (basefile !== "") {
      if (fileMsg === "") {
        setLoading(true);

        const temp = new FormData();
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("tournament_meet_id", id);
        temp.append("excel", basefile);
        temp.append("category_id", eid);
        dotracking(
          "submit button in upload points for " +
            catname +
            "(" +
            eid +
            " ) category in " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.pointuploadheading
        );
        pointsentryupload(temp).then((response) => {
          if (response.status === true) {
            setBase("");
            setLoading(false);
            if (response.data !== null) {
              if (response.data.total_rows !== "") {
                setTRecord(response.data.total_rows);
              }
              if (response.data.inserted_count !== "") {
                setIRecord(response.data.inserted_count);
              }
              if (response.data.failed_count !== "") {
                setURecord(response.data.failed_count);
              }
              setFailId(response.data.uploaded_excel_id);
              setStatus(response.data.status);
            }

            listall();
          } else {
            setLoading(false);
            setErrorMsg(response.message);
          }
        });
      }
    } else {
      setErrorMsg("Please Select Points File");
    }
  }

  function backlist() {
    dotracking(
      "back button in points upload for " +
        catname +
        "(" +
        eid +
        " ) category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.pointlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/pointslist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/pointslist/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/superadmin/teamlist/" + id + "/" + eid);
    }
  }

  function failedent(fid) {
    dotracking(
      " failed click to download link button in points upload for " +
        catname +
        "(" +
        eid +
        " ) category in " +
        tname +
        " (" +
        id +
        ") tournament and file failed (" +
        fid +
        ") $$ " +
        trackobj.pointuploadheading
    );
    window.open(
      exportUrl + "tournament-points-failed-reason-list/" + fid,
      "_blank"
    );
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
            <div className="breadcrumb">
              <h4 className="mb-0">Points</h4>
            </div>
          

          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            
              <li className="breadcrumb-item active" aria-current="page">
                Scheduler Points Entry
              </li>
            
            
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="card">
                <div className="text-left">
                  <h3 className="mt-3">{tname}</h3>
                  <h4 className="text-success tx-20">{catname}</h4>
                </div>
              </div>
              
                  <div className="col-md-6">
                    <Form className="card">
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-label">
                            {" "}
                            Upload Points Entry File{" "}
                            <span className="errortext"> *</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".xlsx,.xls"
                            onChange={datafile}
                          />
                        </div>
                        {errorMsg !== "" && (
                          <span className="errortext">{errorMsg}</span>
                        )}
                        <hr />
                        <div className="form-group">
                          <a className="btn btn-gray me-2" onClick={backlist}>
                            Back
                          </a>
                          <a
                            className="btn btn-success me-2"
                            onClick={baseupload}
                          >
                            Submit
                          </a>
                          <a
                            className="btn btn-secondary"
                            href={require("../../../../assets/images/Sample_points_excel.xlsx")}
                            download
                          >
                            Sample Excel
                          </a>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="ag-theme-alpine ag-style"
                      style={{
                        height: 320,
                      }}
                    >
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="4"
                        sortingOrder={sorting}
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                      />
                    </div>
                  </div>
              
            
              
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Failed Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                    {urecord !== 0 && (
                      <a
                        className="excelfailed text-danger"
                        onClick={() => failedent(failid)}
                      >
                        <b>Click to Download</b>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};
