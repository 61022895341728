import React, { useEffect, useState } from "react";
import { othereventdetails } from "../../../../services/superadmin";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
export const ViewWorkshop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ename, setEName] = useState("");
  const [etype, setEType] = useState("");
  const [sdate, setESDate] = useState("");
  const [edate, setEEDate] = useState("");
  const [eodate, setEEODate] = useState("");
  const [eddate, setEEDDate] = useState("");
  const [efees, setEFees] = useState("");
  const [efree, setEFree] = useState("N");
  const [ecir, setECir] = useState("");
  const [ereg, setEReg] = useState("");
  const [elogo, setLogo] = useState("");
  const [agename, setAgeName] = useState("");
  const [schname, setSchName] = useState("");

  const [rlink, setRLink] = useState([]);
  const [urllink, setUrlLink] = useState("");
  const [createby, setCreateBy] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1]);
    othereventdetails(id).then((response) => {
      if (response.status === true) {
        setCreateBy(response.data.events[0].created_by);
        setEName(response.data.events[0].event_name);
        setEType(response.data.events[0].entryeventname);
        setESDate(response.data.events[0].startdate);
        setEEDate(response.data.events[0].enddate);
        setEEODate(response.data.events[0].entryopen);
        setEEDDate(response.data.events[0].entrydeadline);
        setEFees(response.data.events[0].fees);
        setSchName(response.data.event_school.school_name);
        setAgeName(response.data.events[0].event_age_category);
        if (response.data.events[0].free_or_paid_events === "Y") {
          setEFree("Yes");
        } else if (response.data.events[0].free_or_paid_events === "N") {
          setEFree("No");
        }
        if (response.data.events[0].logo !== null) {
          setLogo(response.data.logo_path + response.data.events[0].logo);
        }

        setEReg(response.data.events[0].internalorexternal);
        if (response.data.events[0].circular_file !== null) {
          setECir(
            response.data.circular_path + response.data.events[0].circular_file
          );
        }
        if (response.data.tournament_events !== null) {
          if (response.data.tournament_events.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.tournament_events.length; i++) {
              var elink = response.data.tournament_events[i].meet_url;
              if (response.data.events[0].internalorexternal === "external") {
                const url = window.location.href;
                const head = url.split("/#/");
                elink =
                  head[0] +
                  "/#/evententry/" +
                  response.data.events[0].encrypted_string;
              }
              arr.push({
                id: response.data.tournament_events[i].id,
                qrcode:
                  response.data.qrcode_path +
                  response.data.tournament_events[i].meet_qr_code,
                schlink: elink,
              });
            }
            setRLink(arr);
          }
        }
      }
    });
  }, []);

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      if (urllink === "othereventdetails") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.dashheading
        );
        navigate("/superadmin/dashboard");
      } else {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.worklistheading
        );
        navigate("/superadmin/othereventlist");
      }
    } else if (localStorage.getItem("Role") === "3") {
      if (urllink === "othereventdetails") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.dashheading
        );
        navigate("/cityregional/dashboard");
      } else {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.worklistheading
        );
        navigate("/cityregional/othereventlist");
      }
    } else if (localStorage.getItem("Role") === "4") {
      if (localStorage.getItem("EPage") === "1") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.dashheading
        );
        navigate("/schoolhead/dashboard");
      } else if (localStorage.getItem("EPage") === "2") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.workupcominglistheading
        );
        navigate("/schoolhead/upcomingeventlist");
      } else if (localStorage.getItem("EPage") === "3") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.workcomplistheading
        );
        navigate("/schoolhead/completedeventslist");
      }
    } else if (localStorage.getItem("Role") === "6") {
      dotracking(
        "back button in view other events details for " +
          ename +
          " (" +
          id +
          ") event $$ " +
          trackobj.dashheading
      );
      navigate("/player/dashboard");
    } else if (localStorage.getItem("Role") === "7") {
      if (urllink === "othereventdetails") {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.dashheading
        );
        navigate("/coach/dashboard");
      } else {
        dotracking(
          "back button in view other events details for " +
            ename +
            " (" +
            id +
            ") event $$ " +
            trackobj.worklistheading
        );
        navigate("/coach/othereventlist");
      }
    } else if (localStorage.getItem("Role") === "8") {
      dotracking(
        "back button in view other events details for " +
          ename +
          " (" +
          id +
          ") event $$ " +
          trackobj.dashheading
      );
      navigate("/funder/dashboard");
    }
  }

  function editpage() {
    dotracking(
      "edit button in view other events details for " +
        ename +
        " (" +
        id +
        ") event $$ " +
        trackobj.workeditheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/editotherevent/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/editotherevent/" + id);
    } else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/editotherevent/" + id);
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Other Events Detail</h4>
        </div>
        <ol className="breadcrumb">
          {(localStorage.getItem("Role") === "6" || localStorage.getItem("Role") === "8") && (
            <li className="breadcrumb-item">
              <a>Dashboard</a>
            </li>
          )}
          {localStorage.getItem("Role") !== "6" && localStorage.getItem("Role") !== "8" && (
            <li className="breadcrumb-item">
              <a>Other Events</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Other Events Detail
          </li>
        </ol>
      </div>

      <div className="row ">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row tournamentitem">
                <div className="col-md-12">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-lg-2 col-md-6">
                        <b className="text-inverse">Event Name</b>
                      </div>
                      <div className="col-md-10 col-md-6">
                        <span className="text-inverse">{ename}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Event Type</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{etype}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{sdate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{edate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Opens at</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{eodate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Last Date</b>
                      </div>
                      <div className="col-md-8">
                        <span className="text-inverse">{eddate}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Free Event</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse">{efree}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {efree === "No" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Fees</b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">{efees}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {localStorage.getItem("Role") === "3" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">School</b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse text-capitalize">
                            {schname}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Age Category</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse text-capitalize">
                          {agename}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="details-group">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Registration Type</b>
                      </div>
                      <div className="col-md-8 align-self-center">
                        <span className="text-inverse text-capitalize">
                          {ereg}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {ecir !== "" && (
                  <div className="col-md-6">
                    <div className="details-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Circular <br />
                            Document
                          </b>
                        </div>
                        <div className="col-md-8 align-self-center">
                          <span className="text-inverse">
                            <a href={ecir} target="_blank" download={ecir}>
                              <b className="blink">
                                Click Here!{" "}
                                <i className="fa fa-download ms-2"></i>
                              </b>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(localStorage.getItem("Role") === "1" ||
                localStorage.getItem("Role") === "3" ||
                localStorage.getItem("Role") === "7") && (
                <>
                  {rlink.length !== 0 && (
                    <div>
                      <hr />
                      <h4>Register Details</h4>
                    </div>
                  )}

                  {rlink.map((data, i) => {
                    return (
                      <div className="row schooldetails mx-0" key={i}>
                        <div className="col-md-6">
                          <div className="details-group">
                            <div className="">
                              <b className="text-inverse">Registeration Link</b>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="details-group">
                            <div className="text-center">
                              <b className="text-inverse">QR Code</b>
                              <a
                                href={data.qrcode}
                                target={"_blank"}
                                download={data.qrcode}
                              >
                                <img
                                  src={data.qrcode}
                                  className="tour_fileQR"
                                />
                                <p className="mb-0">
                                  <b>Click to Download</b>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="details-group">
                            <div className="text-center">
                              <b className="text-inverse">Login URL</b>
                              <span className="text-inverse">
                                <a
                                  href={data.schlink}
                                  target={"_blank"}
                                  className="btn btn-secondary"
                                >
                                  Click Here
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {elogo !== "" && (
                <>
                  {" "}
                  <h4>Event Banner</h4>{" "}
                  <img src={elogo} width="100%" height="300px" />
                </>
              )}
              <div className="text-right border-top">
                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3") &&
                  urllink === "othereventdetail" && (
                    <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                  )}
                {createby == localStorage.getItem("UID") &&
                  localStorage.getItem("Role") === "7" &&
                  urllink === "othereventdetail" && (
                    <a className="btn btn-success me-1 mt-2" onClick={editpage}>
                      <i className="fa fa-edit"></i> Edit
                    </a>
                  )}

                <a className="btn btn-gray mt-2" onClick={backlist}>
                  <i className="fe fe-corner-up-left"></i> Back
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
