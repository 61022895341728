import React from 'react'

export const Comingsoon = () => {
    return (
        <div className="side-app">
            <div className="object">
                <div className="object-rope"></div>
                <div className="object-shape">
                    Coming <span className="soon">Soon</span>
                </div>
            </div>
            <div className="content_coming text-center">
                <h2 className="text-primary mb-2">Page is under Construction ...</h2>
                <p className="fs-5 font-weight-bold text-muted">We're working hard to improve the user experience. Stay tuned!</p>
            </div>
        </div>
    )
}
