import React, { Fragment, useEffect, useState } from "react";
import { logindata } from "../../services/login";
import "./login_style.css";
import "./login_responsive.css";
import "./login_animation.css";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../trackinglogs";
import trackobj from "../trackingpages.json";

export const Login = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  // const { id } = useParams();

  useEffect(() => {
    captcha();
  }, []);
  function submitlogin() {
    setSubmitted(true);
    setErrorMsg("");
    localStorage.clear();
    if (user !== "" && pass !== "") {
      if (ecapvalue !== "") {
        if (capvalue === ecapvalue) {
          
            const temp = new FormData();
            temp.append("username", user);
            temp.append("password", pass);
            logindata(temp).then((response) => {
              if (response.status === true) {
                setSubmitted(false);
                localStorage.setItem("UID", response.data.userid);
                localStorage.setItem("Role", response.data.roleid);
                localStorage.setItem("Name", response.data.username);
                localStorage.setItem("User", response.data.name);
  
                localStorage.setItem("Token", response.data.token);
                localStorage.setItem("Subrole", response.data.adminsubrole);
  
                if (response.data.roleid === 1) {
                  localStorage.setItem("RoleName", "Superadmin");
                  dotracking(
                    "login button, redirect to Superadmin dashboard $$ " +
                      trackobj.dashheading
                  );
                  navigate("/superadmin/dashboard");
                } else if (response.data.roleid === 3) {
                  localStorage.setItem("RoleName", "CityManager");
                  dotracking(
                    "login button, redirect to CityManager dashboard $$ " +
                      trackobj.dashheading
                  );
                  navigate("/cityregional/dashboard");
                } else if (response.data.roleid === 4) {
                  dotracking(
                    "login button, redirect to SchoolHead dashboard $$ " +
                      trackobj.dashheading
                  );
                  localStorage.setItem("RoleName", "SchoolHead");
                  navigate("/schoolhead/dashboard");
                } else if (response.data.roleid === 5) {
                  dotracking(
                    "login button, redirect to Referee dashboard $$ " +
                      trackobj.dashheading
                  );
                  localStorage.setItem("RoleName", "Referee");
                  navigate("/referee/dashboard");
                } else if (response.data.roleid === 6) {
                  dotracking(
                    "login button, redirect to Player dashboard $$ " +
                      trackobj.dashheading
                  );
                  localStorage.setItem("RoleName", "Player");
                  navigate("/player/dashboard");
                }else if (response.data.roleid === 7) {
                  dotracking(
                    "login button, redirect to Coach dashboard $$ " +
                      trackobj.dashheading
                  );
                  localStorage.setItem("RoleName", "Coach");
                  navigate("/coach/dashboard");
                // }else if (response.data.roleid === 8) {
                //   dotracking(
                //     "login button, redirect to Funder dashboard $$ " +
                //       trackobj.dashheading
                //   );
                //   localStorage.setItem("RoleName", "Funder");
                //   navigate("/funder/dashboard");
                }
              } else {
                setErrorMsg(response.message);
              }
            });
          
        } else {
          setErrorMsg("Captcha didn't match");
        }
      }
    }
  }

  function fpass() {
    dotracking(
      "forgot your password link in login $$ " + trackobj.forgotheading
    );
    navigate("/forgotusername");
  }

  function checkreg() {
    dotracking("register link in login $$ " + trackobj.registerheading);
    navigate("/playerregister");
  }

  const [capvalue, setCapvalue] = useState("");
  const [ecapvalue, setECapvalue] = useState("");

  function captcha() {
    setCapvalue("");
    var a = Math.ceil(Math.random() * 10) + "";
    var b = Math.ceil(Math.random() * 10) + "";
    var c = Math.ceil(Math.random() * 10) + "";
    var d = Math.ceil(Math.random() * 10) + "";
    var e = Math.ceil(Math.random() * 10) + "";
    var f = Math.ceil(Math.random() * 10) + "";
    var g = Math.ceil(Math.random() * 10) + "";
    setCapvalue(a + "" + b + "" + c + "" + d + "" + e + "" + f + "" + g);
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setECapvalue(e.target.value);
    }
  };

  return (
    <Fragment>
      <div id="loginpage" className="app sidebar-mini rtl">
        <main className="md-overflow-hidden">
          <div className="container">
            <div className="row h-95">
              <div className="col-md-6 pe-0 tab-100 order_a mob-100">
                <div className="side slideup ">
                  <div className="img-top">
                    <img
                      src={require("../../assets/images/tagline.png")}
                      alt="tagline"
                      className="tagline_logo"
                    />
                  </div>
                  <article className="text-center">
                    <img
                      src={require("../../assets/images/pathwayz_logo.png")}
                      alt="pathwayz"
                      className="pathwayz_logo"
                    />
                  </article>
                  <div className="social">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/SportzVillage/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@sportzvillage7534"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sportz-village/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/sportzvillage" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="border-b slideup"></div>
              </div>
              <div className="col-md-6 ps-0 tab-100 mob-100">
                <div className="slidedown border-t"></div>
                <div className="form slidedown">
                  <img
                    src={require("../../assets/images/logo_sv.png")}
                    alt="logosv"
                    style={{ margin: "auto" }}
                  />
                  <div className="form-text login">
                    <h3>Member Login</h3>
                  </div>
                  {/* <div className="form-text signup">
                    <h3>Sign Up</h3>
                    <span>New? </span>
                    <button className="create">Create an account</button>
                  </div> */}
                  <form method="post" id="loginform" className="login">
                    <div className="input-field">
                      <label>
                        <i className="fa-solid fa-user"></i>Username
                      </label>

                      <input
                        type="text"
                        id="mail-email"
                        placeholder="Enter Username"
                        required
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                      />
                      {submitted && !user && (
                        <span className="text-danger">
                          Username is required
                        </span>
                      )}
                    </div>

                    <div className="input-field delay-100ms">
                      <label>
                        <i className="fa-solid fa-lock"></i>Password
                      </label>
                      <input
                        type="Password"
                        id="password"
                        placeholder="Enter Password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                      />
                      {submitted && !pass && (
                        <span className="text-danger">
                          Password is required
                        </span>
                      )}
                    </div>

                    {/* <div className="form-group d-flex">
                      <div className="w-40">
                        <input type="text" value={capvalue} readOnly className="form-control"/>
                      </div>
                      <div className="w-20 text-center">
                        <a
                          onClick={captcha}
                          className="fa fa-fw fa-refresh refresh"
                          aria-hidden="true"
                        ></a>
                      </div>
                      <div className="w-40">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Here"
                          maxLength={12}
                          autoComplete="off"
                          value={ecapvalue}
                          onChange={handleChange}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !ecapvalue && (
                          <span className="text-danger">
                            Captcha is required
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="captcha-box">
                      <div className="row">
                      <div className="col-md-5">
                        <input
                          type="text"
                          value={capvalue}
                          readOnly
                          className="captcha-two"
                        />
                      </div>
                      <div className="col-md-2 text-center captcha-refresh">
                        <a
                          onClick={captcha}
                          className="fa fa-fw fa-refresh refresh captcha-refresh"
                          aria-hidden="true"
                        ></a>
                      </div>
                      <div className="col-md-5">
                        <input
                          type="text"
                          className="captcha-two"
                          placeholder="Enter The Captcha"
                          maxLength={12}
                          autoComplete="off"
                          value={ecapvalue}
                          onChange={handleChange}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {submitted && !ecapvalue && (
                          <span className="text-danger">
                            Captcha is required
                          </span>
                        )}
                      </div>
                      </div>
                    </div>
                    {errorMsg !== "" && (
                      <span className="text-danger">{errorMsg}</span>
                    )}
                    {/* <div className="radio-field">
                      <input type="checkbox" />
                      <label>Keep me Logged In</label>
                    </div> */}
                    <button
                      className="form-btn login"
                      id="login"
                      type="button"
                      onClick={submitlogin}
                    >
                      <span>log in</span>
                    </button>
                  </form>

                  <div className="con-text login">
                    <p className="mt-4">
                      <a onClick={fpass}>
                        Forgot your <strong>password?</strong>
                      </a>
                    </p>
                  </div>
                  <hr />
                  <div className="con-text regis">
                    <p className="mt-2 mb-0">
                      New player to our Student-Athlete Hub?{" "}
                      <strong>
                        <a onClick={checkreg}>Click Here to Register</a>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
