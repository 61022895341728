import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { schooltourlist } from "../../../../services/schoolhead";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import moment from "moment";
import exportUrl from "../../../../exportUtl";

export const CompletedListTour = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 310,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        if(params.data.meet_tour_type === "Internal"){
          return (
            <div className="text-center">
              <a
                onClick={() => viewdet(params.value, params)}
                title="View Tournament"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>
              {params.data.sports_type !== "ATHLETICS" && (
                <a
                  onClick={() => catdet(params.value, params)}
                  title="Create Category List"
                  className="btn-sm btn-warning me-2"
                >
                  <i className="fa fa-list fa-lg"></i>
                </a>
              )}
              {params.data.sports_type === "ATHLETICS" &&
                params.data.meet_type === 2 &&
                localStorage.getItem("Role") === "4" && (
                  <>
                    {params.data.paid_status === 1 && (
                      <a
                        onClick={() => adddet(params.value, params)}
                        title="Confirmation Players"
                        className="btn-sm btn-gray me-2"
                      >
                        <i className="fa fa-vcard fa-lg"></i>
                      </a>
                    )}
                    {params.data.paid_status !== 1 && moment(new Date()).format("yyyy-MM-DD") <= params.data.entry_deadline_date && (
                      <a
                        onClick={() => adddet(params.value, params)}
                        title="Add Players"
                        className="btn-sm btn-warning me-2"
                      >
                        <i className="fa fa-user-plus fa-lg"></i>
                      </a>
                    )}
                  </>
                )}
              <a
                onClick={() => playerdet(params.value, params)}
                title="Entry List"
                className="btn-sm btn-success me-2"
              >
                <i className="fas fa-users fa-lg"></i>
              </a>
              {params.data.sports_type === "ATHLETICS" && (
                <a
                  onClick={() => orderdet(params.value, params)}
                  title="Order Events List"
                  className="btn-sm btn-warning me-2"
                >
                  <i className="fa fa-calendar fa-lg"></i>
                </a>
              )}
              <a
                onClick={() => resdet(params.value, params)}
                title="Result List"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fas fa-trophy fa-lg"></i>
              </a>
  
              <a
                title="Upload Photos"
                onClick={() => photodet(params.value, params)}
                className="btn-sm btn-export me-2"
              >
                <i className="fa fa-picture-o fa-lg"></i>
              </a>
  
              <a
                title="Upload Highlights"
                onClick={() => highdet(params.value, params)}
                className="btn-sm btn-gray me-2"
              >
                <i className="fa fa-link fa-lg"></i>
              </a>
              {/* <a
                title="Analytics" onClick={() => analdet(params.value,params)}
                className="btn-sm btn-import me-2"
              >
                <i className="fa fa-line-chart fa-lg"></i>
              </a> */}
              {localStorage.getItem("Role") === "4" && (
              <a
                title="Download Tournament Report"
                onClick={() => repdet(params.value, params,1)}
                className="btn-sm btn-import me-2"
              >
                <i className="fa fa-download fa-lg"></i>
              </a>
            )}
            </div>
          );
        }else {
          return (
            <div className="text-center">
              <a
                onClick={() => viewdet(params.value, params)}
                title="View Tournament"
                className="btn-sm btn-secondary me-2"
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>

              
            </div>
          );
        }
        
      },
    },
    {
      headerName: "Tournament Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Code",
      width: 150,
      field: "meet_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Location",
      width: 170,
      field: "location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "startdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "enddate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.data.meet_tour_type === "Internal"){
          return params.value
        }else{
          return "";
        }
      }
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entryopen",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline Date",
      width: 150,
      field: "entrydeadline",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 170,
      field: "meettype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sports",
      width: 150,
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    // {
    //   headerName: "Category (Class)",
    //   width: 170,
    //   field: "standard_name",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },

    // {
    //   headerName: "State",
    //   width: 150,
    //   field: "statename",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
  ];

  const navigate = useNavigate();
  function viewdet(tid, tdata) {
    dotracking(
      "view icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.viewtourheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/tournamentdetails/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "3");
      if(tdata.data.meet_tour_type === "External"){
        navigate("/schoolhead/achievementtournament/" + tid);
      }else{
        navigate("/schoolhead/tournamentdetails/" + tid);
      }      
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/tournamentdetails/" + tid);
    }
  }

  function catdet(tid, tdata) {
    dotracking(
      "category icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.categorylistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Cat", "3");
      navigate("/schoolhead/categorylist/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/categorylist/" + tid);
    }
  }

  function adddet(tid, tdata) {
    if (tdata.data.paid_status === 1) {
      dotracking(
        "confirmation list in tournament list for " +
          tdata.data.meet_name +
          " (" +
          tid +
          ") tournament $$ " +
          trackobj.confirmplayerheading
      );
      if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/confirmation/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/confirmation/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "3") {
        dotracking(
          "player list in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
        navigate("/cityregional/tournamentschoollist/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        dotracking(
          "player list in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/playerlist/" + tid);
      }
    }
  }

  function playerdet(tid, tdata) {
    if (tdata.data.sports_type.toUpperCase() === "ATHLETICS") {
      if (localStorage.getItem("Role") === "4") {
        dotracking(
          "school tournament icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourschoollistheading
        );
      } else {
        dotracking(
          "player entry list icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerheading
        );
      }
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/athleticschoollist/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/athleticschoollist/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/athleticsentryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/athleticschoollist/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "1") {
        dotracking(
          "player entry list icon in tournament list for " +
            tdata.data.meet_name +
            " (" +
            tid +
            ") tournament $$ " +
            trackobj.tourplayerlistheading
        );
        navigate("/superadmin/entryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/entryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/entryplayerslist/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/entryplayerslist/" + tid);
      }
    }
  }

  function orderdet(tid, tdata) {
    dotracking(
      "order of event list icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.tourorderlistheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "3");
      navigate("/schoolhead/orderofeventlist/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/orderofeventlist/" + tid);
    }
  }

  function resdet(tid, tdata) {
    dotracking(
      "result icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.resultheading
    );
    if (tdata.data.sports_type.toUpperCase() === "ATHLETICS") {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/athleticsresults/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/athleticsresults/" + tid);
      }
    } else {
      if (localStorage.getItem("Role") === "1") {
        navigate("/superadmin/results/" + tid);
      } else if (localStorage.getItem("Role") === "3") {
        navigate("/cityregional/results/" + tid);
      } else if (localStorage.getItem("Role") === "4") {
        localStorage.setItem("Page", "3");
        navigate("/schoolhead/results/" + tid);
      } else if (localStorage.getItem("Role") === "5") {
        navigate("/referee/results/" + tid);
      }
    }
  }

  function photodet(tid, tdata) {
    dotracking(
      "upload photo icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.photouploadheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "3");
      navigate("/schoolhead/photoupload/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/photoupload/" + tid);
    }
  }

  function highdet(tid, tdata) {
    dotracking(
      "upload highlight icon in tournament list for " +
        tdata.data.meet_name +
        " (" +
        tid +
        ") tournament $$ " +
        trackobj.highheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "4") {
      localStorage.setItem("Page", "3");
      navigate("/schoolhead/highlights/" + tid);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/highlights/" + tid);
    }
  }

  function  repdet(rid, rdata,rtype) {
    if (localStorage.getItem("Role") === "4" || localStorage.getItem("Role") === "7") {
      dotracking(
        "download tournament report icon in tournament list for " +
          rdata.data.meet_name +
          " (" +
          rid +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      window.open(exportUrl + "export-tournament-report/" + rid + "/" + rtype, "_blank");
    }
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    if (localStorage.getItem("Role") === "4") {
      
      setFilterProduct([]);
      schooltourlist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.completed_tournaments);
        }
      });
    }
  }
  const gridRef = useRef();

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Completed Tournament List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Completed Tournament List
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
