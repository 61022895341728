import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  playercertificatelist,
  playercertificatetracking,
} from "../../../services/player";
import dotracking from "../../trackinglogs";
import { Col, Modal, Row } from "react-bootstrap";
import { saveAs } from "file-saver";
import trackobj from "../../trackingpages.json";
import baseUrl from "../../../Api";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export const CertificatePlayer = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3path, setS3Path] = useState("");

  const navigate = useNavigate();
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Action",
      field: "certificate_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => handleShow(params.value, params)}
              title="Download Certificate"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Tournament Name",
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Start Date",
      field: "meet_start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "End Date",
      field: "meet_end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Sports",
      field: "sports_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      field: "certitype",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function viewlist(cname, mid) {
    dotracking(
      "view icon in certificate list for tournament " +
        mid +
        " - " +
        cname +
        " $$ " +
        trackobj.certdetheading
    );
    window.open(s3path + cname, "_blank");
  }

  function certdownload(cname) {
    dotracking(
      "download icon in certificate list for " +
        tname +
        " (" +
        tourid +
        ") tournament with " +
        cname +
        " (" +
        cerid +
        ") certificate $$ " +
        trackobj.certheading
    );
    // saveAs(s3path + cname, cname);
    window.open(baseUrl + "/certificate-download-in-pdf/" + cerid, "_blank");

    playercertificatetracking(tourid, cerid, 2).then((response) => {
      if (response.status === true) {
      }
    });
  }

  const [show, setShow] = useState(false);
  const [certimg, setCertImg] = useState("");
  const [cerid, setCerId] = useState("");
  const [tourid, setTourId] = useState("");
  const [tname, setTourName] = useState("");

  function handleShow(img, mdata) {
    dotracking(
      "view icon in certificate list for " +
        mdata.data.meet_name +
        " (" +
        mdata.data.meet_uid +
        ") tournament with " +
        img +
        " (" +
        mdata.data.certificate_uid +
        ") certificate $$ " +
        trackobj.certdetheading
    );
    setCertImg(img);
    setTourName(mdata.data.meet_name);
    setCerId(mdata.data.certificate_uid);
    setTourId(mdata.data.meet_uid);
    setShow(true);
    playercertificatetracking(
      mdata.data.meet_uid,
      mdata.data.certificate_uid,
      1
    ).then((response) => {
      if (response.status === true) {
      }
    });
  }

  function handleclose() {
    dotracking(
      "close button in certificate list for " +
        tname +
        " (" +
        tourid +
        ") tournament with " +
        certimg +
        " (" +
        cerid +
        ") certificate $$ " +
        trackobj.certheading
    );
    setShow(false);
    setCertImg("");
    setTourName("");
    setCerId("");
    setTourId("");
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    playercertificatelist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.details);
        setS3Path(response.data.s3);
      }
    });
  }

  const [pshow, setPShow] = useState(false);
  const handlePclose = () => {
    setPShow(false);
    setShow(false);
    dotracking(
      "close button in share for certificate list in " +
        tname +
        " (" +
        tourid +
        ") tournament with " +
        certimg +
        " (" +
        cerid +
        ") certificate $$ " +
        trackobj.certheading
    );
  };
  const [shareUrl, setShare] = useState("");

  function handlePshow(timg) {
    setShow(false);
    setShare(timg);
    setPShow(true);
    dotracking(
      "share button in certificate list for " +
        tname +
        " (" +
        tourid +
        ") tournament with " +
        timg +
        " certificate $$ " +
        trackobj.certheading
    );
  }

  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Certificate</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Certificate Lists
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // domLayout="autoWidth"
                    // onGridReady={(params) => {
                    //   params.api.sizeColumnsToFit();
                    // }}
                  />

                  <Modal show={show} size="lg">
                    <Modal.Header>
                      <h4>Certificate</h4>
                    </Modal.Header>
                    <Modal.Body>
                      <Row className="row-sm mx-0">
                        <Col lg={12} md={12}>
                          <div className="form-group">
                            <img src={s3path + certimg} alt="certificate" />
                          </div>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <a
                        className="btn btn-secondary"
                        onClick={() => handlePshow(s3path + certimg)}
                      >
                        Share
                      </a>
                      <a
                        className="btn btn-success"
                        onclick={() => certdownload(certimg)}
                      >
                        Download
                      </a>
                      <a className="btn btn-danger" onClick={handleclose}>
                        Close
                      </a>
                    </Modal.Footer>
                  </Modal>

                  <div className={pshow ? "mymodal open" : "mymodal"}>
                    <div className="modoverlay"></div>
                    <div className="mymodal-content">
                      <h4 className="text-center">Share Certificate</h4>
                      <span className="close-btn" onClick={handlePclose}>
                        &times;
                      </span>
                      <div className="border-bottom"></div>
                      <Row className="row-sm mt-3">
                        <div className="text-center">
                          <WhatsappShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <WhatsappIcon size={44} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <FacebookIcon size={44}></FacebookIcon>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={shareUrl}
                            title="Hey, check out my certificate!"
                            className="me-3"
                          >
                            <TwitterIcon size={44}></TwitterIcon>
                          </TwitterShareButton>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
