import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { basedataimport, schoollist } from "../../../../services/superadmin";
import Select from "react-select";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const Baseupload = () => {
  const [schlist, setSchList] = useState([]);
  const [schid, setSchool] = useState("");
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");

  const [trecord, setTRecord] = useState(0);
  const [irecord, setIRecord] = useState(0);
  const [urecord, setURecord] = useState(0);
  const [strecord, setStatus] = useState("");

  let arr = [];

  useEffect(() => {
    schoollist().then((response) => {
      if (response.status === true) {
        if (response.data.details.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.details.length; i++) {
            arr.push({
              value: response.data.details[i].id,
              label: response.data.details[i].school_name,
            });
          }
        }
        setSchList(arr);
      }
    });
  }, []);

  function handleSchool(e) {
    setSchool(e);
  }

  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("Base Data File should be xls or xlsx format");
      }
      // var size = event.target.files[0].size / 1024 / 1024;
      // if (size > 5) {
      //   setErrorMsg("Base Data File size should be less than 5MB");
      // }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (schid !== "") {
      if (basefile !== "") {
        if (fileMsg === "") {
          setLoading(true);
          var year = new Date().getFullYear();
          var dateString = new Date().getFullYear() + 1;
          var year1 = dateString.toString().substr(-2);

          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("school_id", schid.value);
          temp.append("school_name", schid.label);
          temp.append("academic_year", year + "/" + year1);
          temp.append("base_data_excel", basefile);
          dotracking("submit button in base data upload for school " + schid.value + " $$ " + trackobj.baseuploadheading);
          basedataimport(temp).then((response) => {
            if (response.status === true) {
              setLoading(false);
              setSchool("");
              setBase("");
              setTRecord(response.data.total_rows);
              setIRecord(response.data.inserted_count);
              setURecord(response.data.updated_count);
              setStatus(response.data.status);
            } else {
              setLoading(false);
              setErrorMsg(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Base File");
      }
    } else {
      setErrorMsg("Please Select School");
    }
  }
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Base Data Upload</h4>
          </div>
          <ol className="breadcrumb">
            
            <li className="breadcrumb-item">
              <a>Upload</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Base Data
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="uploadform col-md-8 mx-auto">
                <Form className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-label">
                        School <span className="errortext"> *</span>
                      </label>
                      <Select
                        className="dropdown"
                        placeholder="Select School"
                        value={schid}
                        options={schlist}
                        onChange={handleSchool}
                        isSearchable={true}
                        isClearable
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Upload Base Data File{" "}
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>

                      <a
                        className="btn btn-secondary"
                        href={require("../../../../assets/images/Sample_Base_Data_Excel.xlsx")}
                        download
                        onClick={() =>
                          dotracking("sample excel button in base data upload $$ " + trackobj.baseuploadheading)
                        }
                      >
                        Sample Excel
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Total Records</strong>
                    </p>
                    <p className="h3 text-center text-info">{trecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Inserted Records</strong>
                    </p>
                    <p className="h3 text-center text-primary-1">{irecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Updated Records</strong>
                    </p>
                    <p className="h3 text-center text-teal">{urecord}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card bg-custom">
                  <div className="card-body text-center feature">
                    <p className="card-text mt-3 mb-3">
                      <strong>Upload Status</strong>
                    </p>
                    {strecord === "Success" && (
                      <p className="h2 badge badge-success">
                        <b>{strecord}</b>
                      </p>
                    )}
                    {strecord !== "Success" && (
                      <p className="h2 badge badge-danger">
                        <b>{strecord}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};
