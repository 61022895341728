import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { tourviewdetail } from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import { scheadtourviewdetail } from "../../../../../services/schoolhead";
import { Reftourdetail } from "../../../../../services/referee";
import moment from "moment";
import { tourdetails } from "../../../../../services/register";
import { agecategorychampionslist } from "../../../../../services/common";
import { AgGridReact } from "ag-grid-react";

export const AdminAgeCategoryChampionlist = () => {
  const { id, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");
  const [agename, setAgeName] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Username (UID)",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 200,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
      
    },
    {
      headerName: "Class",
      width: 120,
      field: "class",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Section",
      width: 120,
      field: "section",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "School",
      width: 200,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gold",
      width: 100,
      field: "total_gold",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Silver",
      width: 100,
      field: "total_silver",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Bronze",
      width: 100,
      field: "total_bronze",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Points",
      width: 150,
      field: "total_points",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      scheadtourviewdetail(id).then((response) => {
        if (response.status === true) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "5") {
      Reftourdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "6") {
      tourdetails(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listathletics();
  }, []);

  function listathletics() {
    setFilterProduct([]);
    const temp = new FormData();
    temp.append("tournament_id", id);
    temp.append("age_category_name", eid);

    agecategorychampionslist(temp).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.individual_champions);
        if (response.data.individual_champions.length !== 0) {
          setAgeName(response.data.individual_champions[0].age_category_name);
        }
      }
    });
  }

  function backlist() {
    dotracking(
      "back button in individual champions list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.individualchampionheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate(-1);
    } else if (localStorage.getItem("Role") === "5") {
      navigate("/referee/individualchampionlist/" + id);
    } else if (localStorage.getItem("Role") === "6") {
      navigate(-1);
    }
  }
  const gridRef = useRef();

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Age Category Champions List</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item">
              <a>Individual Champions List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Age Category Champions List
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-10">
                    <div className="text-left">
                      <h3 className="mb-2">{tname}</h3>
                      <h4 className="text-success">{agename}</h4>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="text-right">
                      <a onClick={backlist} className="btn btn-gray">
                        <i className="fe fe-corner-up-left"></i> Back
                      </a>
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine ag-style">
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    
                  />
                </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
