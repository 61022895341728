import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  schoollistcityreg,
  schoolstatuscityreg,
} from "../../../../services/cityregionaladmin";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import WebUrl from "../../../../AWebUrl";

export const CityListSchool = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data.school_name)}
              title="Edit School"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>

            {/* <a
              onClick={() => deleteschool(params)}
              title="Remove School"
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a> */}
          </div>
        );
      },
    },

    {
      headerName: "Publish Webpage",
      width: 160,
      field: "website_publised_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === "Published") {
          return (
            <div className="text-center">
              <a
                href={WebUrl + params.data.website_url}
                target="_blank"
                title={WebUrl + params.data.website_url}
                className="btn-sm btn-success"
              >
                Visit Webpage
              </a>
            </div>
          );
        } else {
          return <div className="text-center text-danger">{params.value}</div>;
        }
      },
    },
    {
      headerName: "School",
      width: 300,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Unique No",
      width: 120,
      field: "school_unique_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 170,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.state === 0) {
          return params.data.state_name;
        } else {
          return params.data.statename;
        }
      },
    },
    {
      headerName: "District",
      width: 150,
      field: "districtname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.district === 0) {
          return params.data.district_name;
        } else {
          return params.data.districtname;
        }
      },
    },
    {
      headerName: "City",
      width: 130,
      field: "cityname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.data.city === 0) {
          return params.data.city_name;
        } else {
          return params.data.cityname;
        }
      },
    },
    // {
    //   headerName: "Status",
    //   width: 100,
    //   field: "is_active",
    //   resizable: true,
    //   cellRenderer: function (params) {
    //     return (
    //       <div className="text-center">
    //         <label className="custom-switch mb-0">
    //           <input
    //             type="checkbox"
    //             name="custom-switch-checkbox"
    //             className="custom-switch-input"
    //             checked={params.value === 1 ? true : false}
    //             onChange={() => deleteschool(params)}
    //           />

    //           <span className="custom-switch-indicator"></span>
    //         </label>
    //       </div>
    //     );
    //   },
    // },
  ];
  const navigate = useNavigate();
  function viewlist(vid, vname) {
    dotracking(
      "edit icon in school list for school " +
        vname +
        " (" +
        vid +
        ") $$ " +
        trackobj.schooldetheading
    );
    navigate("/cityregional/schooldetails/" + vid);
  }

  function deleteschool(list) {
    // if (list.value === 1) {
    //   Swal.fire({
    //     text: "Are you sure want to Inactive?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#ac0404",
    //     cancelButtonColor: "#6259ca",
    //     confirmButtonText: "Inactive",
    //     allowOutsideClick: false,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       const temp = new FormData();
    //       temp.append("id", list.data.id);
    //       temp.append("is_active", 0);
    //       dotracking(
    //         "status icon in school list from Active to Inactive for school " + list.data.school_name + " (" + list.data.id + ") $$ " + trackobj.schoollistheading
    //       );
    //       schoolstatuscityreg(temp).then((response) => {
    //         if (response.status === true) {
    //           listall();
    //           toast.success(response.message);
    //         }
    //       });
    //     }
    //   });
    // } else {
    //   Swal.fire({
    //     text: "Are you sure want to Active?",
    //     icon: "success",
    //     showCancelButton: true,
    //     confirmButtonColor: "#008000",
    //     cancelButtonColor: "#6259ca",
    //     confirmButtonText: "Active",
    //     allowOutsideClick: false,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       const temp = new FormData();
    //       temp.append("id", list.data.id);
    //       temp.append("is_active", 1);
    //       dotracking(
    //         "status icon in school list from Inactive to Active for school " + list.data.school_name + " (" + list.data.id + ") $$ " + trackobj.schoollistheading
    //       );
    //       schoolstatuscityreg(temp).then((response) => {
    //         if (response.status === true) {
    //           listall();
    //           toast.success(response.message);
    //         }
    //       });
    //     }
    //   });
    // }

    Swal.fire({
      text: "Are you sure want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Remove",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = new FormData();
        temp.append("id", list.data.id);
        temp.append("is_active", 0);
        dotracking(
          "remove icon in school list for school " +
            list.data.school_name +
            " (" +
            list.data.id +
            ") $$ " +
            trackobj.schoollistheading
        );
        schoolstatuscityreg(temp).then((response) => {
          if (response.status === true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    schoollistcityreg().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>School Head</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            School Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
