import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import { sportposcreate, sportposdetails, sportposlist, sportposupdate, sportposstatus, sportsactivelist } from "../../../../services/superadmin";
import Swal from "sweetalert2";
import dotracking from "../../../trackinglogs";

export const SportsCatPosMaster = () => {
    const [filterProduct, setFilterProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [sportposid, setId] = useState(0);
    const [sportid, setSport] = useState("");
    const [sportlist, setSportList] = useState([]);

    const [sportpos, setSportPos] = useState("");
    const [maxpla, setMaxPla] = useState("");

    const [status, setStatus] = useState("1");
    const [errorMsg, setErrorMsg] = useState("");

    const sorting = useMemo(() => {
        return ['asc', 'desc',];
      }, []);

    const columnDefs = [
        {
            headerName: "Sports",
            field: "sports_category",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Position",
            field: "position",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Max Players",
            field: "max_no_of_players_allowed",
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Status",
            field: "status",
            resizable: true,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <label className="custom-switch mb-0">
                            <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked={params.value === 1 ? true : false}
                                onClick={() => deletesports(params)}
                            />

                            <span className="custom-switch-indicator"></span>
                        </label>
                    </div>
                );
            },
        },
        {
            headerName: "Actions",
            field: "id",
            resizable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <div className="text-center">
                        <a onClick={() => viewsportcatpos(params.value)}
                            className="btn-sm btn-secondary"
                        >
                            <i className="fa fa-edit fa-lg"></i>
                        </a>
                    </div>
                );
            },
        },
    ];

    function viewsportcatpos(aid) {
        setShow(true)
        
        sportposdetails(aid).then((response) => {
            if (response.status === true) {
                setId(response.data.id)
                setSport(response.data.sports_category_id)
                setSportPos(response.data.position)
                setMaxPla(response.data.max_no_of_players_allowed)
                setStatus(response.data.status + "")
                dotracking("edit button in sports category position list for " + response.data.position + " (" + aid + ")")
            }
        })
    }

    function deletesports(list) {
        if (list.value === 1) {
            Swal.fire({
                text: "Are you sure want to Inactive?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ac0404",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Inactive",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.sports_category_id)
                    temp.append("status", 0)
                    dotracking("status icon in sports category list from Active to Inactive for " + list.data.position + " (" + list.data.sports_category_id + ")")
                    sportposstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        } else {
            Swal.fire({
                text: "Are you sure want to Active?",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#008000",
                cancelButtonColor: "#6259ca",
                confirmButtonText: "Active",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const temp = new FormData();
                    temp.append("id", list.data.sports_category_id)
                    temp.append("status", 1)
                    dotracking("status icon in sports category list from Inactive to Active for " + list.data.position + " (" + list.data.sports_category_id + ")")
                    sportposstatus(temp).then((response) => {
                        if (response.status === true) {
                            listall();
                            toast.success(response.message)
                        }
                    })
                }
            });
        }
    }

    function handleshow() {
        setId(0);
        setSport("");
        setSportPos("");
        setMaxPla("");
        setErrorMsg("");
        setShow(true);
    }
    function handleclose() {
        setShow(false);
        setId(0);
        setSport("");
        setSportPos("");
        setMaxPla("");
        setErrorMsg("");
    }

    useEffect(() => {
        listall();
        sportsactivelist().then((response) => {
            if (response.status === true) {
                setSportList(response.data)
            }
        })
    }, []);

    function listall() {
        setFilterProduct([]);
        sportposlist().then((response) => {
            if (response.status === true) {
                setFilterProduct(response.data)
            }
        })
    }
    function Selectstatus(e) {
        setStatus(e.target.value);
    }

    function submitsports(e) {
        e.preventDefault();
        setErrorMsg("")
        if (sportpos !== "") {
            if (sportid !== "") {

                if (sportposid === 0) {
                    const temp = new FormData();
                    temp.append("sports_category_id", sportid)
                    temp.append("position", sportpos)
                    temp.append("max_no_of_players_allowed", maxpla)
                    temp.append("status", status)
                    dotracking("add button in sports category for " + sportpos)
                    sportposcreate(temp).then((response) => {
                        if (response.status === true) {
                            handleclose();
                            listall();
                            toast.success(response.message);
                        }
                    })
                } else if (sportposid !== 0) {
                    const temp = new FormData();
                    temp.append("id", sportposid)
                    temp.append("sports_category_id", sportid)
                    temp.append("position", sportpos)
                    temp.append("max_no_of_players_allowed", maxpla)
                    temp.append("status", status)
                    dotracking("update button in sports category for " + sportpos + " (" + sportposid + ")")
                    sportposupdate(temp).then((response) => {
                        if (response.status === true) {
                            handleclose();
                            listall();
                            toast.success(response.message);
                        }
                    })
                }


            } else {
                setErrorMsg("Please Select Sport Category Name")
            }
        } else {
            setErrorMsg("Please Enter Position")
        }
    }


    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Sports Category Position Lists</h4>
                </div>
                <ol className="breadcrumb">
                    {localStorage.getItem("Subrole") === "1" &&
                        <li className="breadcrumb-item">
                            <a>Setup</a>
                        </li>
                    }
                    {localStorage.getItem("Subrole") !== "1" &&
                        <li className="breadcrumb-item">
                            <a>Master</a>
                        </li>
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        Sports Category Position Lists
                    </li>
                </ol>
            </div>

            <div className="row">
                <div className="col-12">
                    <form className="card mt-0">

                        <div className="card-body">
                            {localStorage.getItem("Subrole") === "1" &&
                                <div className="text-right">
                                    <a className="btn btn-success" onClick={handleshow}>
                                        <i className="fa fa-plus"></i> Add Position
                                    </a>
                                </div>
                            }
                            <div className="ag-theme-alpine ag-style mt-2">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    sortingOrder={sorting}
                                    suppressExcelExport={true}
                                    suppressDragLeaveHidesColumns={true}
                                    domLayout="autoWidth"
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                />
                            </div>

                            <Modal show={show} size="lg" onHide={handleclose}>
                                <Modal.Header>
                                    {sportposid !== 0 &&
                                        <h4> Sport Category Position Details</h4>
                                    }
                                    {sportposid === 0 &&
                                        <h4> Add Sport Category Position</h4>
                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="row-sm mx-0">
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Position{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Position"
                                                    className="form-control"
                                                    value={sportpos}
                                                    onChange={(e) => setSportPos(e.target.value)}
                                                />
                                            </div>
                                        </Col>


                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Sport Category{" "}
                                                    <span className="errortext"> *</span>
                                                </label>
                                                <div className="form-group">
                                                    <select className="form-control custom-select" value={sportid}
                                                        onChange={(e) => setSport(e.target.value)}>
                                                        <option value="" defaultValue disabled>--- Select Sport Category ---</option>
                                                        {sportlist.map((data, i) => {
                                                            return (
                                                                <option value={data.sports_cat_uid} key={i}>
                                                                    {data.sports_category}
                                                                </option>
                                                            );
                                                        })}

                                                    </select>
                                                </div>
                                            </div>

                                        </Col>

                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Maximum Players{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Maximum Players"
                                                    className="form-control"
                                                    value={maxpla}
                                                    onChange={(e) => setMaxPla(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            <div className="form-group">
                                                <label className="mb-2">
                                                    Status <span className="errortext"> *</span>
                                                </label>

                                                <div className="row" onChange={Selectstatus}>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="1"
                                                                checked={status === "1"}
                                                            />
                                                            {" "} Active
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="custom-control custom-radio custom-control-md">
                                                            <input
                                                                type="radio"
                                                                className="reg-chk"
                                                                id="active"
                                                                value="0"
                                                                checked={status === "0"}
                                                            />
                                                            {" "}Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        {errorMsg !== "" && (
                                            <span className="errortext">{errorMsg}</span>
                                        )}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a
                                        className="btn btn-danger"
                                        onClick={handleclose}
                                    >
                                        Close
                                    </a>
                                    {sportposid !== 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitsports}
                                        >
                                            Update
                                        </a>
                                    }
                                    {sportposid === 0 &&
                                        <a
                                            className="btn btn-success" onClick={submitsports}
                                        >
                                            Add
                                        </a>
                                    }

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};
