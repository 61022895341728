import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "react-bootstrap";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import {
  cmstagcreate,
  cmstagdelete,
  cmstaglist,
  cmstagupdate,
  cmstagview,
} from "../../../../services/common";
import Select from "react-select";

export const TagsMaster = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [tagid, setId] = useState(0);
  const [tagname, setTagName] = useState("");
  const [tagcnt, setTagCnt] = useState([]);

  const [crem, setCTag] = useState([]);

  const [tlist, setTList] = useState([]);

  const [status, setStatus] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Tags",
      field: "tag_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Recomanded Tags",
      field: "recomanded_tag_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   return (
      //     <div className="text-center">
      //       <label className="custom-switch mb-0">
      //         <input
      //           type="checkbox"
      //           name="custom-switch-checkbox"
      //           className="custom-switch-input"
      //           checked={params.value === 1 ? true : false}
      //           onChange={() => deleteclass(params)}
      //         />

      //         <span className="custom-switch-indicator"></span>
      //       </label>
      //     </div>
      //   );
      // },
    },
    {
      headerName: "Actions",
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewclass(params.value)}
              title="Edit Class"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function viewclass(aid) {
    setShow(true);
    cmstagview(aid).then((response) => {
      if (response.status === true) {
        setId(response.data.tags_details.id);
        setTagName(response.data.tags_details.tag_name);
        setStatus(response.data.tags_details.status + "");
        setCTag(response.data.recomanded_tags);
        dotracking(
          "edit button in tags for " +
            response.data.tags_details.tag_name +
            " (" +
            aid +
            ") $$ " +
            trackobj.addtaglist
        );
      }
    });
  }

  function handleshow() {
    setId(0);
    setTagCnt([]);
    setTagName("");
    setStatus("1");
    setErrorMsg("");
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setId(0);
    setTagCnt([]);
    setTagName("");
    setStatus("1");
    setErrorMsg("");
  }

  function deleteclass(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dotracking(
            "status icon in tags list from Active to Inactive for " +
              list.data.tag_name +
              " (" +
              list.data.id +
              ") $$ " +
              trackobj.addtaglist
          );
          cmstagdelete(localStorage.getItem("UID"), list.data.id).then(
            (response) => {
              if (response.status === true) {
                listall();
                toast.success(response.message);
              }
            }
          );
        }
      });
    }
  }
  useEffect(() => {
    listall();
    cmstaglist().then((res) => {
      if (res.status === true) {
        let arr = [];
        for (var i = 0; i < res.data.tags_list.length; i++) {
          arr.push({
            label: res.data.tags_list[i].tag_name,
            value: res.data.tags_list[i].id,
          });
        }
        setTList(arr);
      }
    });
  }, []);

  function handleTag(e) {
    if (e === null) {
      setTagCnt([]);
    } else {
      setTagCnt(e);
    }
  }

  function listall() {
    // setFilterProduct([{ id: 1, tags: "Delhi", is_active: 1 }, { id: 2, tags: "Odisha", is_active: 1 },{ id: 3, tags: "Event", is_active: 1 },{ id: 4, tags: "Basketball", is_active: 1 },{ id: 5, tags: "Girl Child Involvement", is_active: 1 },{ id: 6, tags: "Success Story", is_active: 1 }]);
    cmstaglist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.tags_list);
      }
    });
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }

  function submittags(e) {
    e.preventDefault();
    setErrorMsg("");
    if (tagname !== "") {
      if (tagid === 0) {
        const temp = new FormData();
        temp.append("tag_name", tagname);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("recomanded_tags_count", tagcnt.length);
        if (tagcnt.length > 0) {
          for (let i = 0; i < tagcnt.length; i++) {
            temp.append("recomanded_tags_id_" + [i + 1], tagcnt[i].value);
          }
        }
        dotracking(
          "add button in tag list for " + tagname + " $$ " + trackobj.addtaglist
        );
        cmstagcreate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        });
      } else if (tagid !== 0) {
        const temp = new FormData();
        temp.append("tag_name", tagname);
        temp.append("tags_id", tagid);
        temp.append("admin_id", localStorage.getItem("UID"));
        temp.append("recomanded_tags_count", tagcnt.length);
        if (tagcnt.length > 0) {
          for (let i = 0; i < tagcnt.length; i++) {
            temp.append("recomanded_tags_id_" + [i + 1], tagcnt[i].value);
          }
        }
        dotracking(
          "update button in tag list for " +
            tagname +
            " (" +
            tagid +
            ") $$ " +
            trackobj.addtaglist
        );
        cmstagupdate(temp).then((response) => {
          if (response.status === true) {
            handleclose();
            listall();
            toast.success(response.message);
          }
        });
      }
    } else {
      setErrorMsg("Please Enter Tags");
    }
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Tags Lists</h4>
        </div>
        <ol className="breadcrumb">
          {localStorage.getItem("Subrole") === "1" && (
            <li className="breadcrumb-item">
              <a>Setup</a>
            </li>
          )}
          {localStorage.getItem("Subrole") !== "1" && (
            <li className="breadcrumb-item">
              <a>Master</a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            Tags Lists
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-right">
                <a className="btn btn-success" onClick={handleshow}>
                  <i className="fa fa-plus"></i> Add Tags
                </a>
              </div>
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  domLayout="autoWidth"
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>

              <Modal show={show} size="lg" onHide={handleclose}>
                <Modal.Header>
                  {tagid !== 0 && <h4> Tags Details</h4>}
                  {tagid === 0 && <h4> Add Tags</h4>}
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Tags Name <span className="errortext"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Tags Name"
                          className="form-control"
                          value={tagname}
                          onChange={(e) => setTagName(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Recomanded Tags</label>
                        <Select
                          className="dropdown"
                          placeholder="Select Recomanded Tags"
                          value={tagcnt}
                          options={tlist}
                          onChange={handleTag}
                          isSearchable={true}
                          isMulti
                          isClearable
                          hidden
                        />
                      </div>
                    </Col>
                    {tagid !== 0 && (
                      <div className="details-group refereebox">
                        <div className="row">
                          {crem.map((data, i) => {
                            return (
                              <div
                                className="col-md-6 align-self-center"
                                key={i}
                              >
                                <div className="form-group">
                                  <h4 className="mb-1">{data.tag_name}</h4>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {/* <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="errortext"> *</span>
                        </label>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />{" "}
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a className="btn btn-danger" onClick={handleclose}>
                    Close
                  </a>
                  {tagid !== 0 && (
                    <a className="btn btn-success" onClick={submittags}>
                      Update
                    </a>
                  )}
                  {tagid === 0 && (
                    <a className="btn btn-success" onClick={submittags}>
                      Add
                    </a>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
