import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import {
  registerentrylist,
  registerentryupload,
  tourviewdetail,
} from "../../../../services/superadmin";
import exportUrl from "../../../../exportUtl";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const SendRegisterLink = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [basefile, setBase] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileMsg, setFileMsg] = useState("");
  const [link1, setLink1] = useState("");

  const [tname, setTName] = useState("");

  const [filterProduct, setFilterProduct] = useState([]);
  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);
  const columnDefs = [
    {
      headerName: "Upload Excel",
      width: 170,
      field: "uploaded_excel_name",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-success"
              href={s3path + params.value}
              download={s3path + params.value}
              target="_blank"
              onClick={() => {
                dotracking(
                  "click to download link in send register link for " +
                    tname +
                    " (" +
                    id +
                    ") tournament and file " +
                    params.value +
                    " (" +
                    params.data.id +
                    ") $$ " +
                    trackobj.reglinkheading
                );
              }}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Failed Excel",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="excelfailed text-danger"
              onClick={() => failedent(params.value)}
            >
              Click to Download
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Uploaded on",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy hh:mm:ss A");
        }
      },
    },
    {
      headerName: "Uploaded by",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const [s3path, setS3Path] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listall();
  }, []);

  function failedent(fid) {
    dotracking(
      "failed click to download link in send register link for " +
        tname +
        " (" +
        id +
        ") tournament and file failed " +
        fid +
        " $$ " +
        trackobj.reglinkheading
    );
    window.open(exportUrl + "export-failed-send-reg-link/" + fid, "_blank");
  }

  function listall() {
    setFilterProduct([]);
    setS3Path("");
    registerentrylist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.details);
        setS3Path(response.data.s3path);
      }
    });
  }
  function datafile(event) {
    setFileMsg("");
    setBase("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setBase(event.target.files[0]);
      } else {
        setFileMsg("Registeration File should be xls or xlsx format");
      }
    }
  }

  function baseupload() {
    setErrorMsg("");
    if (link1 !== "") {
      if (basefile !== "") {
        if (fileMsg === "") {
          setLoading(true);
          const temp = new FormData();
          temp.append("admin_id", localStorage.getItem("UID"));
          temp.append("tournament_id", id);
          temp.append("send_reg_link_excel", basefile);
          temp.append("send_link_or_photos", link1);
          dotracking(
            "submit button in send register link for " +
              tname +
              " (" +
              id +
              ") tournament $$ " +
              trackobj.reglinkheading
          );
          registerentryupload(temp).then((response) => {
            if (response.status === true) {
              setBase("");
              setLoading(false);
              listall();
            } else {
              setLoading(false);
              setErrorMsg(response.message);
            }
          });
        }
      } else {
        setErrorMsg("Please Select Registeration File");
      }
    } else {
      setErrorMsg("Please Select link");
    }
  }

  function backlist() {
    if (localStorage.getItem("Role") === "1") {
      dotracking(
        "back button in send register link for " +
          tname +
          " (" +
          id +
          ") tournament $$ " +
          trackobj.tourlistheading
      );
      navigate("/superadmin/tournamentlist");
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">Send Registeration Link</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Tournament</a>
            </li>
            <li className="breadcrumb-item">
              <a>Tournament List</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Send Registeration Link
            </li>
          </ol>
        </div>

        {loading && (
          <div className="dashloader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <div className="row">
              <div className="card">
                <div className="text-left">
                  <h3 className="mt-3">{tname}</h3>
                </div>
              </div>
              <div className="col-md-6">
                <Form className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-label">
                        Links <span className="errortext"> *</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        value={link1}
                        onChange={(e) => setLink1(e.target.value)}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Link Types ---
                        </option>
                        <option value="1">Send Registration Link</option>
                        <option value="2">Send photos or videos</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Upload Registeration Entry File{" "}
                        <span className="errortext"> *</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".xlsx,.xls"
                        onChange={datafile}
                      />
                    </div>
                    {errorMsg !== "" && (
                      <span className="errortext">{errorMsg}</span>
                    )}
                    <hr />
                    <div className="form-group">
                      <a className="btn btn-gray me-2" onClick={backlist}>
                        Back
                      </a>
                      <a className="btn btn-success me-2" onClick={baseupload}>
                        Submit
                      </a>
                      <a
                        className="btn btn-secondary"
                        href={require("../../../../assets/images/Sample_send_tournament_registration_link_excel.xlsx")}
                        download
                      >
                        Sample Excel
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-md-6">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 320,
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="4"
                    sortingOrder={sorting}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row row-cards mt-3">
              <div className="">
                <h4>Upload Summary</h4>
              </div>
              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="4"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div> */}
          </>
        )}
      </div>
    </Fragment>
  );
};
