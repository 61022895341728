import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  athleticsaddagecategory,
  eventsmasterlist,
  touragecategory,
  tourviewdetail,
} from "../../../../../services/superadmin";
import {
  agecategorytourcityreg,
  crmtourviewdetail,
} from "../../../../../services/cityregionaladmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { agecategorytourcoach, coachtourviewdetail } from "../../../../../services/coach";

export const AddAgeCategory = () => {
  const [agecat, setAgecat] = useState([]);
  const [elist, setEventList] = useState("");
  const [tournament, setTournament] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [mfree, setMFree] = useState("");

  function handleChange(e, index) {
    if (e.length !== 0) {
      tournament[index].eventcat = e;
      setTournament([...tournament]);
    } else {
      tournament[index].eventcat = "";
      setTournament([...tournament]);
    }
  }

  function checkage(e, index) {
    if (tournament.length !== 0) {
      var name = "";
      for (let i = 0; i < tournament.length; i++) {
        if (tournament[i].agecategory !== "") {
          if (tournament[i].agecategory === e.target.value && i !== index) {
            if (name === "") {
              name = tournament[i].agecategory;
            } else {
              name = name + "," + tournament[i].agecategory;
            }
          }
        }
      }
    }
    if (name !== "") {
      Swal.fire({
        title: name + " Already Exists",
        allowOutsideClick: true,
      });
    } else {
      tournament[index].agecategory = e.target.value;
    }
    setTournament([...tournament]);
  }

  function checksdob(e, index) {
    tournament[index].sdob = e.target.value;
    setTournament([...tournament]);
  }

  function checkedob(e, index) {
    tournament[index].edob = e.target.value;
    setTournament([...tournament]);
  }

  function checkathlete(e, index) {
    tournament[index].maxtrackathlete = e.target.value;
    setTournament([...tournament]);
  }

  function checkevent(e, index) {
    tournament[index].maxtrackevents = e.target.value;
    setTournament([...tournament]);
  }

  function checkfieldevent(e, index) {
    tournament[index].maxfieldevents = e.target.value;
    setTournament([...tournament]);
  }

  function checktwoevent(e, index) {
    tournament[index].maxtrackevents1 = e.target.value;
    setTournament([...tournament]);
  }

  function checktwofieldevent(e, index) {
    tournament[index].maxfieldevents1 = e.target.value;
    setTournament([...tournament]);
  }

  function checkfee(e, index) {
    tournament[index].fees = e.target.value;
    setTournament([...tournament]);
  }

  function checkfield(e, index) {
    tournament[index].maxfieldathlete = e.target.value;
    setTournament([...tournament]);
  }

  function checkrelay(e, index) {
    tournament[index].relaycnt = e.target.value;
    setTournament([...tournament]);
  }

  function checkmixedrelay(e, index) {
    tournament[index].mixedrelaycnt = e.target.value;
    setTournament([...tournament]);
  }

  function checkgold(e, index) {
    tournament[index].gold = e.target.value;
    setTournament([...tournament]);
  }

  function checksilver(e, index) {
    tournament[index].silver = e.target.value;
    setTournament([...tournament]);
  }

  function checkbronze(e, index) {
    tournament[index].bronze = e.target.value;
    setTournament([...tournament]);
  }

  function checktrackorder(e, index) {
    tournament[index].ordertrack = e.target.value;
    setTournament([...tournament]);
  }

  function checkfieldorder(e, index) {
    tournament[index].orderfield = e.target.value;
    setTournament([...tournament]);
  }

  function addmulti(index) {
    if (index + 1 < tournament.length) {
      tournament[index].button = 0;
      tournament[index + 1].button = 1;
      tournament[index + 1].active = 1;
      setTournament([...tournament]);
    } else {
      Swal.fire({
        title: "Maximum Age Category reached",
        allowOutsideClick: true,
      });
    }
  }

  const navigate = useNavigate();
  function addmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    if (tournament.length !== 0) {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);
      tempData.append("age_category_count", tournament.length);

      var msg = "";
      for (let i = 0; i < tournament.length; i++) {
        if (tournament[i].active === 1) {
          if (tournament[i].agecategory !== "") {
            if (tournament[i].eventcat !== "") {
              if (tournament[i].sdob !== "") {
                if (tournament[i].edob !== "") {
                  if (tournament[i].maxtrackevents !== "") {
                    if (tournament[i].maxfieldevents !== "") {
                      if (mfree === "N") {
                        if (tournament[i].fees !== "") {
                          if (tournament[i].fees > 0) {
                            msg = "";
                          } else {
                            msg = "Fees must be greater than zero";
                            setErrorMessage("Fees must be greater than zero");
                            break;
                          }
                        } else {
                          msg = "Please Enter Your Fees";
                          setErrorMessage("Please Enter Your Fees");
                          break;
                        }
                      }

                      if (msg === "") {
                        if (tournament[i].gold !== "") {
                          if (tournament[i].gold > 0) {
                            if (tournament[i].silver !== "") {
                              if (tournament[i].silver > 0) {
                                if (tournament[i].bronze !== "") {
                                  if (tournament[i].bronze > 0) {
                                    if (tournament[i].ordertrack !== "") {
                                      if(tournament[i].ordertrack > 0){
                                        if(tournament[i].orderfield !== ""){
                                          if (tournament[i].orderfield > 0) {
                                            var eve = "";
                                            if (
                                              tournament[i].eventcat.length !== 0
                                            ) {
                                              for (
                                                let j = 0;
                                                j < tournament[i].eventcat.length;
                                                j++
                                              ) {
                                                if (eve === "") {
                                                  eve =
                                                    tournament[i].eventcat[j].label;
                                                } else {
                                                  eve =
                                                    eve +
                                                    "," +
                                                    tournament[i].eventcat[j].label;
                                                }
                                              }
                                            }
                                            tempData.append(
                                              "age_category_" + [i + 1],
                                              tournament[i].agecategory
                                            );
                                            tempData.append(
                                              "dob_start_date_" + [i + 1],
                                              tournament[i].sdob
                                            );
                                            tempData.append(
                                              "dob_end_date_" + [i + 1],
                                              tournament[i].edob
                                            );
    
                                            if (
                                              tournament[i].maxtrackevents === ""
                                            ) {
                                              tempData.append(
                                                "max_event_athlete_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_event_athlete_" + [i + 1],
                                                tournament[i].maxtrackevents
                                              );
                                            }
    
                                            if (
                                              tournament[i].maxfieldevents === ""
                                            ) {
                                              tempData.append(
                                                "max_event_athlete_field_" +
                                                  [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_event_athlete_field_" +
                                                  [i + 1],
                                                tournament[i].maxfieldevents
                                              );
                                            }
    
                                            if (
                                              tournament[i].maxtrackevents1 === ""
                                            ) {
                                              tempData.append(
                                                "max_event_athlete_or_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_event_athlete_or_" + [i + 1],
                                                tournament[i].maxtrackevents1
                                              );
                                            }
    
                                            if (
                                              tournament[i].maxfieldevents1 === ""
                                            ) {
                                              tempData.append(
                                                "max_event_athlete_field_or_" +
                                                  [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_event_athlete_field_or_" +
                                                  [i + 1],
                                                tournament[i].maxfieldevents1
                                              );
                                            }
    
                                            if (
                                              tournament[i].maxtrackathlete === ""
                                            ) {
                                              tempData.append(
                                                "max_athlete_event_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_athlete_event_" + [i + 1],
                                                tournament[i].maxtrackathlete
                                              );
                                            }
    
                                            if (
                                              tournament[i].maxfieldathlete === ""
                                            ) {
                                              tempData.append(
                                                "max_athlete_field_event_" +
                                                  [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "max_athlete_field_event_" +
                                                  [i + 1],
                                                tournament[i].maxfieldathlete
                                              );
                                            }
    
                                            tempData.append(
                                              "events_" + [i + 1],
                                              eve
                                            );
                                            tempData.append(
                                              "fees_" + [i + 1],
                                              tournament[i].fees
                                            );
    
                                            if (tournament[i].relaycnt === "") {
                                              tempData.append(
                                                "athletes_per_relay_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "athletes_per_relay_" + [i + 1],
                                                tournament[i].relaycnt
                                              );
                                            }
                                            if (
                                              tournament[i].mixedrelaycnt === ""
                                            ) {
                                              tempData.append(
                                                "athletes_per_mixed_relay_" +
                                                  [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "athletes_per_mixed_relay_" +
                                                  [i + 1],
                                                tournament[i].mixedrelaycnt
                                              );
                                            }
    
                                            if (tournament[i].gold === "") {
                                              tempData.append(
                                                "gold_points_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "gold_points_" + [i + 1],
                                                tournament[i].gold
                                              );
                                            }
                                            if (tournament[i].silver === "") {
                                              tempData.append(
                                                "silver_points_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "silver_points_" + [i + 1],
                                                tournament[i].silver
                                              );
                                            }
    
                                            if (tournament[i].bronze === "") {
                                              tempData.append(
                                                "bronze_points_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "bronze_points_" + [i + 1],
                                                tournament[i].bronze
                                              );
                                            }

                                            if (tournament[i].ordertrack === "") {
                                              tempData.append(
                                                "athletes_per_track_count_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "athletes_per_track_count_" + [i + 1],
                                                tournament[i].ordertrack
                                              );
                                            }
                                            if (tournament[i].orderfield === "") {
                                              tempData.append(
                                                "athletes_per_field_count_" + [i + 1],
                                                0
                                              );
                                            } else {
                                              tempData.append(
                                                "athletes_per_field_count_" + [i + 1],
                                                tournament[i].orderfield
                                              );
                                            }
                                          } else {
                                            msg = "Athlete Per Field(Order of Events) must be greater than zero";
                                        setErrorMessage(
                                          "Athlete Per Field(Order of Events) must be greater than zero"
                                        );
                                          }

                                        }else{
                                          msg =
                                          "Please Enter Athlete Per Field(Order of Events)";
                                        setErrorMessage(
                                          "Please Enter Athlete Per Field(Order of Events)"
                                        );
                                        }
                                      }else{
                                        msg = "Athlete Per Track(Order of Events) must be greater than zero";
                                        setErrorMessage(
                                          "Athlete Per Track(Order of Events) must be greater than zero"
                                        );
                                      }
                                    } else {
                                      msg =
                                        "Please Enter Athlete Per Track(Order of Events)";
                                      setErrorMessage(
                                        "Please Enter Athlete Per Track(Order of Events)"
                                      );
                                    }
                                  } else {
                                    msg =
                                      "Bronze Points must be greater than zero";
                                    setErrorMessage(
                                      "Bronze Points must be greater than zero"
                                    );
                                  }
                                } else {
                                  msg = "Please Enter Your Bronze Points";
                                  setErrorMessage(
                                    "Please Enter Your Bronze Points"
                                  );
                                  break;
                                }
                              } else {
                                msg = "Silver Points must be greater than zero";
                                setErrorMessage(
                                  "Silver Points must be greater than zero"
                                );
                              }
                            } else {
                              msg = "Please Enter Your Silver Points";
                              setErrorMessage(
                                "Please Enter Your Silver Points"
                              );
                              break;
                            }
                          } else {
                            msg = "Gold Points must be greater than zero";
                            setErrorMessage(
                              "Gold Points must be greater than zero"
                            );

                            break;
                          }
                        } else {
                          msg = "Please Enter Your Gold Points";
                          setErrorMessage("Please Enter Your Gold Points");
                          break;
                        }
                      }
                    } else {
                      msg = "Please Enter Maximum Field Event Per Athlete";
                      setErrorMessage(
                        "Please Enter Maximum Field Event Per Athlete"
                      );
                      break;
                    }
                  } else {
                    msg = "Please Enter Maximum Track Event Per Athlete";

                    setErrorMessage(
                      "Please Enter Maximum Track Event Per Athlete"
                    );
                    break;
                  }
                } else {
                  msg = "Please Select DOB End Date";

                  setErrorMessage("Please Select DOB End Date");
                  break;
                }
              } else {
                msg = "Please Select DOB Start Date";

                setErrorMessage("Please Select DOB Start Date");
                break;
              }
            } else {
              msg = "Please Select Event";

              setErrorMessage("Please Select Event");
              break;
            }
          } else {
            msg = "Please Select Age Category";
            setErrorMessage("Please Select Age Category");
            break;
          }
        } else {
          msg = "Please Select All Age Categories";

          setErrorMessage("Please Select All Age Categories");
        }
      }
      if (msg === "") {
        dotracking(
          "submit button in age category for " +
            tname +
            " (" +
            id +
            ") tournament $$ " +
            trackobj.tourlistheading
        );
        athleticsaddagecategory(tempData).then((response) => {
          if (response.status === true) {
            if (localStorage.getItem("Role") === "1") {
              navigate("/superadmin/tournamentlist");
            } else if (localStorage.getItem("Role") === "3") {
              navigate("/cityregional/tournamentlist");
            }else if (localStorage.getItem("Role") === "7") {
              navigate("/coach/tournamentlist");
            }
          }
        });
      }
    }
  }
  var arr = [];
  var arr1 = [];
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");

  useEffect(() => {
    eventsmasterlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].is_active === 1) {
              arr.push({
                value: response.data[i].event_uid,
                label: response.data[i].event_name,
              });
            }
          }
        }
        setEventList(arr);
      }
    });

    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setMSDob(response.data.tournament.dob_start_date);
          setMEDob(response.data.tournament.dob_end_date);
          setMFree(response.data.tournament.free_tournament);
        }
      });
      touragecategory(id).then((response) => {
        if (response.status === true) {
          setAgecat(response.data);
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              var dtype = 0;
              if (i == 0) {
                dtype = 1;
              }
              var butt = 0;
              if (i == 0) {
                butt = 1;
              }
              arr1.push({
                agecategory: "",
                sdob: "",
                edob: "",
                fees: "",
                eventcat: "",
                maxtrackathlete: "",
                maxfieldathlete: "",
                maxtrackevents: "",
                maxfieldevents: "",
                maxtrackevents1: "",
                maxfieldevents1: "",
                relaycnt: "",
                mixedrelaycnt: "",
                gold: "",
                silver: "",
                bronze: "",
                ordertrack: "",
                orderfield: "",
                active: dtype,
                button: butt,
              });
            }
          }
          setTournament(arr1);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setMSDob(response.data.tournament.dob_start_date);
          setMEDob(response.data.tournament.dob_end_date);
          setMFree(response.data.tournament.free_tournament);
        }
      });

      agecategorytourcityreg(id).then((response) => {
        if (response.status === true) {
          setAgecat(response.data);
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              var dtype = 0;
              if (i == 0) {
                dtype = 1;
              }
              var butt = 0;
              if (i == 0) {
                butt = 1;
              }
              arr1.push({
                agecategory: "",
                sdob: "",
                edob: "",
                fees: "",
                eventcat: "",
                maxtrackathlete: "",
                maxfieldathlete: "",
                maxtrackevents: "",
                maxfieldevents: "",
                maxtrackevents1: "",
                maxfieldevents1: "",
                relaycnt: "",
                mixedrelaycnt: "",
                gold: "",
                silver: "",
                bronze: "",
                ordertrack: "",
                orderfield: "",
                active: dtype,
                button: butt,
              });
            }
          }
          setTournament(arr1);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
          setMSDob(response.data.tournament.dob_start_date);
          setMEDob(response.data.tournament.dob_end_date);
          setMFree(response.data.tournament.free_tournament);
        }
      });

      agecategorytourcoach(id).then((response) => {
        if (response.status === true) {
          setAgecat(response.data);
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              var dtype = 0;
              if (i == 0) {
                dtype = 1;
              }
              var butt = 0;
              if (i == 0) {
                butt = 1;
              }
              arr1.push({
                agecategory: "",
                sdob: "",
                edob: "",
                fees: "",
                eventcat: "",
                maxtrackathlete: "",
                maxfieldathlete: "",
                maxtrackevents: "",
                maxfieldevents: "",
                maxtrackevents1: "",
                maxfieldevents1: "",
                relaycnt: "",
                mixedrelaycnt: "",
                gold: "",
                silver: "",
                bronze: "",
                ordertrack: "",
                orderfield: "",
                active: dtype,
                button: butt,
              });
            }
          }
          setTournament(arr1);
        }
      });
    }
  }, []);

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Add Age Categories</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add Age Categories
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="text-center">
                <h3>{tname}</h3>
              </div>
              {tournament.map((data, i) => {
                return (
                  <div key={i}>
                    {data.active === 1 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Age Category{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <select
                                  className="form-control"
                                  value={data.agecategory}
                                  onChange={(e) => checkage(e, i)}
                                >
                                  <option value="" disabled>
                                    Select Your Age Category
                                  </option>
                                  {agecat.map((age, j) => {
                                    return (
                                      <option
                                        value={age.age_categories}
                                        key={j}
                                      >
                                        {age.age_categories}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Events <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <Select
                                  className="dropdown"
                                  placeholder="Select Your Events"
                                  value={data.eventcat}
                                  options={elist}
                                  onChange={(e) => handleChange(e, i)}
                                  isMulti
                                  isClearable
                                  hidden
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  DOB Start Date{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Your DOB Start Date"
                                  min={msdob}
                                  max={medob}
                                  value={data.sdob}
                                  onChange={(e) => checksdob(e, i)}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  DOB End Date{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Your DOB End Date"
                                  min={msdob}
                                  max={medob}
                                  value={data.edob}
                                  onChange={(e) => checkedob(e, i)}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Athlete Per Track Event{" "}
                                  <span className="errortext"> </span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Athlete Per Track Event"
                                  value={data.maxtrackathlete}
                                  onChange={(e) => checkathlete(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Athlete Per Field Event{" "}
                                  <span className="errortext"> </span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Athlete Per Field Event"
                                  value={data.maxfieldathlete}
                                  onChange={(e) => checkfield(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Athletes Per Relay{" "}
                                  <span className="errortext"> </span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Athletes Per Relay"
                                  value={data.relaycnt}
                                  onChange={(e) => checkrelay(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Athletes Per Mixed Relay{" "}
                                  <span className="errortext"> </span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Athletes Per Mixed Relay"
                                  value={data.mixedrelaycnt}
                                  onChange={(e) => checkmixedrelay(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {mfree === "N" && mfree !== "" && (
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <label className="form-label">
                                    Fees <span className="errortext"> *</span>
                                  </label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Fees"
                                    value={data.fees}
                                    onChange={(e) => checkfee(e, i)}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Gold Points{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Gold Points"
                                  value={data.gold}
                                  onChange={(e) => checkgold(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Silver Points{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Silver Points"
                                  value={data.silver}
                                  onChange={(e) => checksilver(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Bronze Points{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Bronze Points"
                                  value={data.bronze}
                                  onChange={(e) => checkbronze(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                       

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Athletes Per Track (Order of Events){" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Athletes Per Track (Order of Events)"
                                  value={data.ordertrack}
                                  onChange={(e) => checktrackorder(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Athletes Per Field (Order of Events){" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Athletes Per Field (Order of Events)"
                                  value={data.orderfield}
                                  onChange={(e) => checkfieldorder(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Track Event Per Athlete{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Track Event Per Athlete"
                                  value={data.maxtrackevents}
                                  onChange={(e) => checkevent(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Field Event Per Athlete{" "}
                                  <span className="errortext"> *</span>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Field Event Per Athlete"
                                  value={data.maxfieldevents}
                                  onChange={(e) => checkfieldevent(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-center errortext">(OR)</h4>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Track Event Per Athlete{" "}
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Track Event Per Athlete"
                                  value={data.maxtrackevents1}
                                  onChange={(e) => checktwoevent(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Max Field Event Per Athlete{" "}
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Max Field Event Per Athlete"
                                  value={data.maxfieldevents1}
                                  onChange={(e) => checktwofieldevent(e, i)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {data.button === 1 && (
                          <div className="col-md-12">
                            <div className="text-right">
                              <a
                                className="btn btn-secondary"
                                onClick={() => addmulti(i)}
                              >
                                <i className="fa fa-plus"></i> Add
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {data.active === 1 && <hr />}
                  </div>
                );
              })}

              {errorMessage !== "" && (
                <span className="errortext">{errorMessage}</span>
              )}
              <div className="text-right">
                <a className="btn btn-success" onClick={addmeet}>
                  Submit
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
