import { AgGridReact } from "ag-grid-react";
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import {
  allplayerlist,
  playerdelete,
  similarmergeplayer,
} from "../../../../services/superadmin";
import { Link, useNavigate, useParams } from "react-router-dom";
import { schoolplayerlist } from "../../../../services/schoolhead";
import { cityplayerlist } from "../../../../services/cityregionaladmin";
import { useMemo } from "react";
import moment from "moment";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Modal, Table } from "react-bootstrap";
import { coachplayerlist } from "../../../../services/coach";

export const AllPlayers = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const {id}=useParams();

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  const columnDefs = [
    {
      headerName: "Action",
      width: 100,
      field: "user_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <input
              type="checkbox"
              checked={params.data.check}
              onChange={(e) => mergecheck(e, params.value)}
            />
          </div>
        );
      },
    },
    {
      headerName: "Mapped",
      width: 150,
      field: "mappedornot",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Last Name",
    //   width: 170,
    //   field: "last_name",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Mobile",
      width: 160,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 160,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "School",
      width: 220,
      field: "school_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Funder",
      width: 150,
      field: "school_funder",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Registered Date",
    //   width: 150,
    //   field: "registerdate",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: function (params) {
    //     if (params.value !== null) {
    //       return moment(params.value).format("DD-MM-yyyy")
    //     }
    //   },
    // },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data)}
              title="View Player"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
            {localStorage.getItem("Subrole") === "1" && (
              <a
                onClick={() => deletelist(params.value, params.data)}
                title="Delete Player"
                className="btn-sm btn-danger"
              >
                <i className="fa fa-trash fa-lg"></i>
              </a>
            )}
          </div>
        );
      },
    },
  ];

  const ShcolumnDefs = [
    // {
    //   headerName: "Mapped",
    //   width: 150,
    //   field: "mappedornot",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params.data)}
              title="View Player"
              className="btn-sm btn-secondary"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 160,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          return moment(params.value).format("DD-MM-yyyy");
        }
      },
    },
    {
      headerName: "Mobile",
      width: 160,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 160,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Team",
      width: 160,
      field: "school_team_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
  ];

  function rowDoubleClicked(e) {
    dotracking(
      "row double clicked in all players list for player " +
        e.data.first_name +
        " (" +
        e.data.username +
        " - " +
        e.data.user_id +
        ") $$ " +
        trackobj.playerdetheading
    );
    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/viewplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/viewplayer/" + e.data.user_id);
    } else if (localStorage.getItem("Role") === "4") {
      navigate("/schoolhead/viewplayer/" + e.data.user_id);
    }
  }

  function deletelist(did, ddata) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dotracking(
          "delete icon in all player list for " +
            ddata.first_name +
            " (" +
            ddata.username +
            " - " +
            +did +
            ") $$ " +
            trackobj.playerlistheading
        );
        const temp = new FormData();
        temp.append("user_id", did);
        playerdelete(temp).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
          }
        });
      }
    });
  }
  const navigate = useNavigate();
  function viewlist(eid, edata) {
    dotracking(
      "view icon in all players list for player " +
        edata.first_name +
        " (" +
        edata.username +
        " - " +
        eid +
        ") $$ " +
        trackobj.playerdetheading
    );
    const url = window.location.href;
    const head = url.split("/#/");
    if (localStorage.getItem("Role") === "1") {
      window.open(`${head[0]}/#/superadmin/viewplayer/` + eid, "_blank");
      // navigate("/superadmin/viewplayer/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      window.open(`${head[0]}/#/cityregional/viewplayer/` + eid, "_blank");
      // navigate("/cityregional/viewplayer/" + eid);
    } else if (localStorage.getItem("Role") === "4") {
      window.open(`${head[0]}/#/schoolhead/viewplayer/` + eid, "_blank");
      // navigate("/schoolhead/viewplayer/" + eid);
    } else if (localStorage.getItem("Role") === "7") {
      window.open(`${head[0]}/#/coach/viewplayer/` + eid, "_blank");
      // navigate("/schoolhead/viewplayer/" + eid);
    }else if (localStorage.getItem("Role") === "8") {
      window.open(`${head[0]}/#/funder/viewplayer/` + eid, "_blank");
      // navigate("/schoolhead/viewplayer/" + eid);
    }
  }

  const [mdata, setMData] = useState([]);
  const [mshow, setMShow] = useState(false);
  const [fshow, setFShow] = useState(false);

  function mergecheck(e, uid) {
    if (filterProduct.length !== 0) {
      for (let i = 0; i < filterProduct.length; i++) {
        if (filterProduct[i].user_id === uid) {
          if (e.target.checked === true) {
            if (mdata.length < 2) {
              filterProduct[i].check = e.target.checked;
            } else {
              toast.error("Cannot merge more than 2 players");
            }
          } else {
            filterProduct[i].check = e.target.checked;
          }
          setFilterProduct([...filterProduct]);
          const totcnt = filterProduct.filter((item) => item.check == true);
          setMData(totcnt);
          break;
        }
      }
    }
  }
  const [errorMsg, setErrorMsg] = useState("");

  function mergedprofiles(e) {
    e.preventDefault();
    setErrorMsg([]);

    if (muname !== "") {
      setLoading(true);
      const temp = new FormData();
      temp.append("main_user_id", muid);
      temp.append("duplicate_user_ids", mduid);
      temp.append("email_id", memail);
      temp.append("mobile_no", mmob);
      temp.append("first_name", mfname);
      temp.append("date_of_birth", mdob);
      temp.append("gender", mgender);
      temp.append("class", mcls);
      temp.append("section", msec);
      temp.append("username", muname);
      dotracking(
        "merge button in all player list for " +
          muid +
          " and " +
          mduid +
          " $$ " +
          trackobj.playerlistheading
      );

      similarmergeplayer(temp).then((response) => {
        if (response.status === true) {
          setLoading(false);
          setFShow(false);
          setMShow(false);
          listall();
          toast.success(response.message);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [muname, setMUName] = useState("");
  const [muid, setMUID] = useState("");
  const [mduid, setMDUID] = useState("");
  function mradiouser(ume) {
    if (mdata.length !== 0) {
      for (let i = 0; i < mdata.length; i++) {
        if (mdata[i].user_id === ume) {
          setMUName(mdata[i].username);
          setMUID(mdata[i].user_id);
          setMFName(mdata[i].first_name);
          setMGender(mdata[i].gender);
          setMDOB(mdata[i].date_of_birth);
          setMMob(mdata[i].mobile_no);
          setMEmail(mdata[i].email_id);
          setMSch(mdata[i].school_id);
          setMClass(mdata[i].class_id);
          setMClassName(mdata[i].class_name);
          setMSection(mdata[i].section);

          mdata[i].radiouser = true;
          setMData([...mdata]);
        } else {
          if (mdata[i].check === true) {
            setMDUID(mdata[i].user_id);
          }
          mdata[i].radiouser = false;
          mdata[i].radioname = false;
          mdata[i].radiogender = false;
          mdata[i].radiodob = false;
          mdata[i].radiomob = false;
          mdata[i].radioemail = false;
          mdata[i].radiocls = false;
          mdata[i].radiosch = false;
          mdata[i].radiosec = false;

          setMData([...mdata]);
        }
      }
    }
  }

  const [mfname, setMFName] = useState("");

  function mradioname(index, ume) {
    if (muname !== "") {
      mdata[index].radioname = !ume;
      setMData([...mdata]);
      if (mdata[index].radioname === true) {
        setMFName(mdata[index].first_name);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radioname = true;
              setMData([...mdata]);
              setMFName(mdata[i].first_name);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mgender, setMGender] = useState("");
  function mradiogender(index, ume) {
    if (muname !== "") {
      mdata[index].radiogender = !ume;
      setMData([...mdata]);
      if (mdata[index].radiogender === true) {
        setMGender(mdata[index].gender);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiogender = true;
              setMData([...mdata]);
              setMGender(mdata[i].gender);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mdob, setMDOB] = useState("");
  function mradiodob(index, ume) {
    if (muname !== "") {
      mdata[index].radiodob = !ume;
      setMData([...mdata]);
      if (mdata[index].radiodob === true) {
        setMDOB(mdata[index].date_of_birth);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiodob = true;
              setMData([...mdata]);
              setMDOB(mdata[i].date_of_birth);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mmob, setMMob] = useState("");
  function mradiomob(index, ume) {
    if (muname !== "") {
      mdata[index].radiomob = !ume;
      setMData([...mdata]);
      if (mdata[index].radiomob === true) {
        setMMob(mdata[index].mobile_no);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiomob = true;
              setMData([...mdata]);
              setMMob(mdata[i].mobile_no);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [memail, setMEmail] = useState("");
  function mradioemail(index, ume) {
    if (muname !== "") {
      mdata[index].radioemail = !ume;
      setMData([...mdata]);
      if (mdata[index].radioemail === true) {
        setMEmail(mdata[index].email_id);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radioemail = true;
              setMData([...mdata]);
              setMEmail(mdata[i].email_id);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [msch, setMSch] = useState("");
  function mradiosch(index, ume) {
    if (muname !== "") {
      mdata[index].radiosch = !ume;
      setMData([...mdata]);
      if (mdata[index].radiosch === true) {
        setMSch(mdata[index].school_id);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiosch = true;
              setMData([...mdata]);
              setMSch(mdata[i].school_id);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [mcls, setMClass] = useState("");
  const [mclsname, setMClassName] = useState("");

  function mradioclass(index, ume) {
    if (muname !== "") {
      mdata[index].radiocls = !ume;
      setMData([...mdata]);
      if (mdata[index].radiocls === true) {
        setMClass(mdata[index].class_id);
        setMClassName(mdata[index].class_name);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiocls = true;
              setMData([...mdata]);
              setMClass(mdata[i].class_id);
              setMClassName(mdata[index].class_name);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  const [msec, setMSection] = useState("");
  function mradiosection(index, ume) {
    if (muname !== "") {
      mdata[index].radiosec = !ume;
      setMData([...mdata]);
      if (mdata[index].radiosec === true) {
        setMSection(mdata[index].section);
      } else {
        for (let i = 0; i < mdata.length; i++) {
          if (mdata[i].check === true) {
            if (i !== index) {
              mdata[i].radiosec = true;
              setMData([...mdata]);
              setMSection(mdata[i].section);
            }
          }
        }
      }
    } else {
      setErrorMsg("Please select Username (UID) of Main Player");
    }
  }

  function listall() {
    setLoading(true);
    allplayerlist(1, 6).then((response) => {
      if (response.status === true) {
        arr = [];
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              mappedornot: response.data[i].mappedornot,
              user_id: response.data[i].user_id,
              first_name: response.data[i].first_name,
              username: response.data[i].username,
              gender: response.data[i].gender,
              date_of_birth: response.data[i].date_of_birth,
              mobile_no: response.data[i].mobile_no,
              email_id: response.data[i].email_id,
              school_name: response.data[i].school_name,
              school_id: response.data[i].school_id,
              class_name: response.data[i].class_name,
              class_id: response.data[i].class,
              section: response.data[i].section,
              check: false,
              radiouser: false,
              radioname: false,
              radiogender: false,
              radiodob: false,
              radiomob: false,
              radioemail: false,
              radiosch: false,
            });
          }
        }
        setFilterProduct(arr);
        setLoading(false);
      }
    });
  }

  let arr = [];
  useEffect(() => {
    if (localStorage.getItem("Role") === "1") {
      listall();
    } else if (localStorage.getItem("Role") === "3") {
      setLoading(true);
      cityplayerlist(1, 6).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
          setLoading(false);
        }
      });
    } else if (localStorage.getItem("Role") === "4") {
      setLoading(true);
      schoolplayerlist(7).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
          setLoading(false);
        }
      });
    } else if (localStorage.getItem("Role") === "7") {
      setLoading(true);
      coachplayerlist(7).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
          setLoading(false);
        }
      });
    } else if (localStorage.getItem("Role") === "8") {
      setFilterProduct([{user_id:"310088",username:"YUV130313",first_name:"Yuvaraj",gender:"Male",email_id:"yuvarekhaa@sportingindia.tech",mobile_no:"9965013232"}]);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="side-app">
        <div className="page-header">
          <div className="breadcrumb">
            <h4 className="mb-0">All Players</h4>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a>Players</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              All Players
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-12">
            <form className="card mt-0">
              <div className="card-body">
                {loading && (
                  <div className="dashloader-wrapper">
                    <div className="loader"></div>
                    <h3>
                      Loading...Please do not go back or close the window.
                    </h3>
                  </div>
                )}
                {!loading && (
                  <div className="ag-theme-alpine ag-style">
                    {localStorage.getItem("Role") === "1" &&
                      mdata.length === 2 && (
                        <div className="text-right mb-2">
                          <a
                            className="btn btn-success"
                            onClick={() => setMShow(true)}
                          >
                            Merge Data
                          </a>
                        </div>
                      )}
                      {localStorage.getItem("Role") === "8" &&
                      (
                        <div className="text-right mb-2">
                          <a
                            className="btn btn-gray"
                            onClick={() => navigate('/funder/schoollist')}
                          >
                            Back
                          </a>
                        </div>
                      )}
                    {(localStorage.getItem("Role") === "4" || localStorage.getItem("Role") === "8") && (
                      <AgGridReact
                        columnDefs={ShcolumnDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="10"
                        sortingOrder={sorting}
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                      />
                    )}

                    {localStorage.getItem("Role") !== "4" && (
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={filterProduct}
                        pagination="true"
                        paginationPageSize="10"
                        sortingOrder={sorting}
                        suppressExcelExport={true}
                        suppressDragLeaveHidesColumns={true}
                        onRowDoubleClicked={(e) => rowDoubleClicked(e)}
                      />
                    )}

                    <Modal show={mshow} size="xl">
                      <Modal.Header>
                        <h5>Merge Player Details</h5>
                        <i
                          className="fa fa-window-close fa-lg"
                          onClick={() => setMShow(false)}
                          aria-hidden="true"
                        ></i>
                      </Modal.Header>
                      <Modal.Body className="mod-body">
                        <div className="table-responsive mt-2">
                          <h5 className="text-success">
                            Select Username (UID) of main profile
                          </h5>
                          <Table bordered>
                            <tbody>
                              <tr>
                                <th>
                                  <b>Username (UID)</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser !== true && (
                                        <>
                                          <div
                                            style={{
                                              float: "left",
                                              width: "50%",
                                            }}
                                          >
                                            {data.username}
                                          </div>
                                          <div
                                            className="text-right"
                                            style={{
                                              float: "right",
                                              width: "50%",
                                            }}
                                          >
                                            <a
                                              className="btn btn-secondary"
                                              onClick={() =>
                                                mradiouser(data.user_id)
                                              }
                                            >
                                              Select if Main UID
                                            </a>
                                          </div>
                                        </>
                                      )}
                                      {data.radiouser === true && (
                                        <>
                                          <span className="font-weight-bold text-success">
                                            {data.username}
                                            <i className="fa fa-check ml-2"></i>
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>Fullname</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.first_name}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radioname}
                                            onClick={() =>
                                              mradioname(i, data.radioname)
                                            }
                                          />
                                          {data.first_name}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>Gender </b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.gender}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiogender}
                                            onClick={() =>
                                              mradiogender(i, data.radiogender)
                                            }
                                          />
                                          {data.gender}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>DOB</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {moment(data.date_of_birth).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiodob}
                                            onClick={() =>
                                              mradiodob(i, data.radiodob)
                                            }
                                          />
                                          {moment(data.date_of_birth).format(
                                            "DD-MM-yyyy"
                                          )}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>Contact</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.mobile_no}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiomob}
                                            onClick={() =>
                                              mradiomob(i, data.radiomob)
                                            }
                                          />
                                          {data.mobile_no}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>Email</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.email_id}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radioemail}
                                            onClick={() =>
                                              mradioemail(i, data.radioemail)
                                            }
                                          />
                                          {data.email_id}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th>
                                  <b>Class</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.class_name}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiocls}
                                            onClick={() =>
                                              mradioclass(i, data.radiocls)
                                            }
                                          />
                                          {data.class_name}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>

                              <tr>
                                <th>
                                  <b>Section</b>
                                </th>
                                {mdata.map((data, i) => {
                                  return (
                                    <td key={i}>
                                      {data.radiouser === true && (
                                        <span className="font-weight-bold text-success">
                                          {data.section}
                                        </span>
                                      )}
                                      {data.radiouser !== true && (
                                        <>
                                          <input
                                            type="radio"
                                            className="me-2"
                                            checked={data.radiosec}
                                            onClick={() =>
                                              mradiosection(i, data.radiosec)
                                            }
                                          />
                                          {data.section}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </Table>
                          {errorMsg !== "" && (
                            <span className="errortext">{errorMsg}</span>
                          )}
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-success"
                          onClick={() => setFShow(true)}
                        >
                          Merge Player
                        </button>
                        <button
                          onClick={() => setMShow(false)}
                          className="btn btn-gray"
                        >
                          Cancel
                        </button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={fshow} size="large">
                      <Modal.Header>
                        <h5>Merge Profile Confirmation</h5>
                        <i
                          className="fa fa-window-close fa-lg"
                          onClick={() => setFShow(false)}
                          aria-hidden="true"
                        ></i>
                      </Modal.Header>
                      <Modal.Body className="mod-body">
                        <div className="alignMe">
                          <h4 className="h4 mb-0 mt-3">
                            <b>Username (UID) </b>{" "}
                            <span className="font-weight-bold text-success">
                              {muname}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Fullname </b>{" "}
                            <span className="font-weight-bold text-success">
                              {mfname}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Gender </b>{" "}
                            <span className="font-weight-bold text-success">
                              {mgender}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>DOB </b>{" "}
                            <span className="font-weight-bold text-success">
                              {moment(mdob).format("DD-MM-yyyy")}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Contact </b>{" "}
                            <span className="font-weight-bold text-success">
                              {mmob}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Email </b>{" "}
                            <span className="font-weight-bold text-success">
                              {memail}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Class </b>{" "}
                            <span className="font-weight-bold text-success">
                              {mclsname}
                            </span>
                          </h4>
                          <h4 className="h4 mb-0 mt-3">
                            <b>Section </b>{" "}
                            <span className="font-weight-bold text-success">
                              {msec}
                            </span>
                          </h4>
                        </div>

                        <br />
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-secondary"
                          onClick={mergedprofiles}
                        >
                          Ok
                        </button>
                        <button
                          onClick={() => setFShow(false)}
                          className="btn btn-gray"
                        >
                          Cancel
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
