import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  perfcriteriacountlist,
  tourcategorydetail,
  tourviewdetail,
} from "../../../../../services/superadmin";
import { crmtourviewdetail } from "../../../../../services/cityregionaladmin";
import dotracking from "../../../../trackinglogs";
import trackobj from "../../../../trackingpages.json";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { coachtourviewdetail } from "../../../../../services/coach";
export const PerformanceList = () => {
  const navigate = useNavigate();
  const { id, eid } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [tname, setTName] = useState("");
  const [catname, setCatname] = useState("");

  const columnDefs = [
    {
      headerName: "Criteria",
      field: "criteria_name",
      width: 400,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Player Count",
      field: "playercount",
      width: 200,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Photo Count",
      field: "photocount",
      width: 200,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      field: "criteria_id",
      width: 150,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        return (
          <div className="text-center">
            <a
              className="btn btn-success btn-sm"
              onClick={() => editperf(params.value,params.data)}
              title="Edit"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function editperf(pid,edata) {
    dotracking(
      "edit performance icon in performance list for " +
        edata.criteria_name +
        " (" +
        pid+
        ")" +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perfeditheading
    );
    navigate("/superadmin/performanceplayerphotoedit/" + id + "/" + eid + "/" + pid);
  }

  useEffect(() => {
    const tempData = new FormData();
    tempData.append("category_id", eid);
    tourcategorydetail(tempData).then((res) => {
      if (res.status === true) {
        setCatname(res.data.category_details.category_name);
      }
    });
    if (localStorage.getItem("Role") === "1") {
      tourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    } else if (localStorage.getItem("Role") === "3") {
      crmtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }else if (localStorage.getItem("Role") === "7") {
      coachtourviewdetail(id).then((response) => {
        if (response.status === true && response.data.tournament !== null) {
          setTName(response.data.tournament.meet_name);
        }
      });
    }
    listall();
  }, []);

  function listall() {
    perfcriteriacountlist(id,eid).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.performance_photos_player);
      }
    });
  }
  function backlist() {
    dotracking(
      "back button in performance list for " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.categorylistheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/categorylist/" + id);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/categorylist/" + id);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/categorylist/" + id);
    }
  }

  function addlist() {
    dotracking(
      "back button in performance list for " +
        catname +
        " (" +
        eid +
        ") category in " +
        tname +
        " (" +
        id +
        ") tournament $$ " +
        trackobj.perfaddheading
    );

    if (localStorage.getItem("Role") === "1") {
      navigate("/superadmin/addperformance/" + id + "/" + eid);
    } else if (localStorage.getItem("Role") === "3") {
      navigate("/cityregional/addperformance/" + id + "/" + eid);
    }else if (localStorage.getItem("Role") === "7") {
      navigate("/coach/addperformance/" + id + "/" + eid);
    }
  }
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Performance Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Tournament</a>
          </li>
          <li className="breadcrumb-item">
            <a>Category</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Performance Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="row pb-3">
                <div className="col-md-9">
                  <div className="text-left">
                    <h3 className="mb-2">{tname}</h3>
                    <h4 className="text-success tx-20">Category - {catname}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-right">
                    <a onClick={addlist} className="btn btn-success me-2">
                      <i className="fa fa-plus"></i> Add
                    </a>
                    <a onClick={backlist} className="btn btn-gray">
                      <i className="fe fe-corner-up-left"></i> Back
                    </a>
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine ag-style">
                {(localStorage.getItem("Role") === "1" ||
                  localStorage.getItem("Role") === "3" || localStorage.getItem("Role") === "7") && (
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    sortingOrder={["asc", "desc"]}
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
