import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  multipleroleslist,
  multirolesstatus,
} from "../../../../services/superadmin";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

export const AdminCRMList = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const sorting = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  
  

  const columnDefs = [
    {
      headerName: "Actions",
      width: 200,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewlist(params.value, params)}
              title="View Manager"
              className="btn-sm btn-secondary me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>

            <a
              onClick={() => schlist(params.value, params)}
              title="Add School"
              className="btn-sm btn-success me-2"
            >
              <i className="fa fa-institution fa-lg"></i>
            </a>

            <a
              onClick={() => funlist(params.value, params)}
              title="Add Funder"
              className="btn-sm btn-warning me-2"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </a>

            <a
              onClick={() => grouplist(params.value, params)}
              title="Add Group of Schools"
              className="btn-sm btn-export me-2"
            >
              <i className="fa fa-users fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Status",
      width: 100,
      field: "status",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => deletecrm(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
    {
      headerName: "UID",
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = "";
        if (params.data.first_name !== null) {
          evt = params.data.first_name;
        }
        if (params.data.last_name !== null) {
          if (params.data.last_name !== "") {
            if (evt === "") {
              evt = params.data.last_name;
            } else if (evt !== "") {
              evt = evt + " " + params.data.last_name;
            }
          }
        }

        return evt;
      },
    },
    {
      headerName: "Email",
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
   
   
  ];
  const navigate = useNavigate();
  function viewlist(vid, vdata) {
    dotracking(
      "view icon in city manager list for " +
        vdata.data.first_name +
        " " +
        vdata.data.last_name +
        " (" +
        vdata.data.username +
        " - " +
        vid +
        ") $$ " +
        trackobj.crmdetheading
    );
    const url = window.location.href;
    const head = url.split("/#/");
    window.open(`${head[0]}/#/superadmin/citymanagerdetail/` + vid, "_blank");
  }


  function schlist(vid, vdata) {
    dotracking(
      "view icon in city manager list for " +
        vdata.data.first_name +
        " " +
        vdata.data.last_name +
        " (" +
        vdata.data.username +
        " - " +
        vid +
        ") $$ " +
        trackobj.crmschlistheading
    );
    navigate("/superadmin/schoolmanager/" + vid);
  }

  function funlist(vid, vdata) {
    dotracking(
      "view icon in city manager list for " +
        vdata.data.first_name +
        " " +
        vdata.data.last_name +
        " (" +
        vdata.data.username +
        " - " +
        vid +
        ") $$ " +
        trackobj.crmfunlistheading
    );
    navigate("/superadmin/fundermanager/" + vid);
  }


  function grouplist(vid, vdata) {
    dotracking(
      "view icon in city manager list for " +
        vdata.data.first_name +
        " " +
        vdata.data.last_name +
        " (" +
        vdata.data.username +
        " - " +
        vid +
        ") $$ " +
        trackobj.crmgrplistheading
    );
    navigate("/superadmin/groupschoolmanager/" + vid);
  }

  function deletecrm(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("remarks", "Active");
          temp.append("status", 0);
          temp.append("role_id", 3);
          temp.append("user_id", list.data.user_id);
          temp.append("admin_id", localStorage.getItem("UID"));
          dotracking(
            "status icon in city manager list from Active to Inactive for " +
              list.data.first_name +
              " " +
              list.data.last_name +
              " (" +
              list.data.username +
              " - " +
              list.data.user_id +
              ") $$ " +
              trackobj.crmdetheading
          );
          multirolesstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("remarks", "Inactive");
          temp.append("status", 1);
          temp.append("role_id", 3);
          temp.append("user_id", list.data.user_id);
          temp.append("admin_id", localStorage.getItem("UID"));
          dotracking(
            "status icon in city manager list from Inctive to Active for " +
              list.data.first_name +
              " " +
              list.data.last_name +
              " (" +
              list.data.username +
              " - " +
              list.data.user_id +
              ") $$ " +
              trackobj.crmdetheading
          );
          multirolesstatus(temp).then((response) => {
            if (response.status === true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    multipleroleslist(3, 1).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Manager Lists</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Manager</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
          Manager Lists
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-12">
          <form className="card mt-0">
            <div className="card-body">
              <div className="ag-theme-alpine ag-style mt-2">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  sortingOrder={sorting}
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                  // domLayout="autoWidth"
                  // onGridReady={(params) => {
                  //   params.api.sizeColumnsToFit();
                  // }}
                 
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
