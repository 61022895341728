import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  scheadschoolvsnational,
  schprofileview,
} from "../../../../services/schoolhead";
import { useState } from "react";
import { gettokenfromapi } from "../../../../services/superadmin";
import { sahassessmentdatelist } from "../../../../services/sahapi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dotracking from "../../../trackinglogs";
import trackobj from "../../../trackingpages.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const SchoolNational = () => {
  const [snlist1, set1SNList] = useState("");
  const [std1, set1Std] = useState([]);
  const [school1, set1School] = useState([]);
  const [national1, set1National] = useState([]);

  const [snlist2, set2SNList] = useState("");
  const [std2, set2Std] = useState([]);
  const [school2, set2School] = useState([]);
  const [national2, set2National] = useState([]);

  const [snlist3, set3SNList] = useState("");
  const [std3, set3Std] = useState([]);
  const [school3, set3School] = useState([]);
  const [national3, set3National] = useState([]);

  const [snlist4, set4SNList] = useState("");
  const [std4, set4Std] = useState([]);
  const [school4, set4School] = useState([]);
  const [national4, set4National] = useState([]);

  const [snlist5, set5SNList] = useState("");
  const [std5, set5Std] = useState([]);
  const [school5, set5School] = useState([]);
  const [national5, set5National] = useState([]);

  const [snlist6, set6SNList] = useState("");
  const [std6, set6Std] = useState([]);
  const [school6, set6School] = useState([]);
  const [national6, set6National] = useState([]);

  const [snlist7, set7SNList] = useState("");
  const [std7, set7Std] = useState([]);
  const [school7, set7School] = useState([]);
  const [national7, set7National] = useState([]);

  const [snlistover, setSNOver] = useState("");
  const [schoolover, setOverSchool] = useState([]);
  const [nationalover, setOverNational] = useState([]);

  let arr = [];
  const [schname, setSchName] = useState("");
  const [loading, setLoading] = useState(false);

  const [datefor, setDateFormat] = useState("");

  useEffect(() => {
    setLoading(true);
    // console.log('Start',new Date().toLocaleString())
    schprofileview().then((res) => {
      if (res.status === true) {
        setSchName(res.data.school_name);
        const temp = new FormData();
        temp.append("data_req", "scl_vs_nsnl_avg" + res.data.school_unique_no);
        gettokenfromapi(temp).then((res2) => {
          if (res2.status === true) {
            sahassessmentdatelist(
              "scl_vs_nsnl_avg&chid=" + res.data.school_unique_no,
              res2.data
            ).then((response) => {
              if (response.status === true) {
                if (response.data.length !== 0) {
                  // console.log('API',new Date().toLocaleString())
                  arr = [];
                  const dfor = response.data.find(
                    (dw) => dw.report_date !== ""
                  );

                  setDateFormat(dfor.report_date);
                  const filtered = response.data.filter((user) =>
                    user.report_name.includes("30m run")
                  );
                  set1SNList(filtered[0].report_name);

                  if (
                    filtered.length !== 0 &&
                    filtered[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered[0].report_name,
                          std: filtered[0].std_wise_array[i].std,
                          school:
                            filtered[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered2 = response.data.filter((user) =>
                    user.report_name.includes("Beep Test")
                  );
                  set2SNList(filtered2[0].report_name);

                  if (
                    filtered2.length !== 0 &&
                    filtered2[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered2[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered2[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered2[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered2[0].report_name,
                          std: filtered2[0].std_wise_array[i].std,
                          school:
                            filtered2[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered2[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered3 = response.data.filter((user) =>
                    user.report_name.includes("Medicine Ball Throw")
                  );
                  set3SNList(filtered3[0].report_name);

                  if (
                    filtered3.length !== 0 &&
                    filtered3[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered3[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered3[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered3[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered3[0].report_name,
                          std: filtered3[0].std_wise_array[i].std,
                          school:
                            filtered3[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered3[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered4 = response.data.filter((user) =>
                    user.report_name.includes("Sit & Reach")
                  );
                  set4SNList(filtered4[0].report_name);

                  if (
                    filtered4.length !== 0 &&
                    filtered4[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered4[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered4[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered4[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered4[0].report_name,
                          std: filtered4[0].std_wise_array[i].std,
                          school:
                            filtered4[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered4[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered5 = response.data.filter((user) =>
                    user.report_name.includes("Standing Broad Jump")
                  );
                  set5SNList(filtered5[0].report_name);

                  if (
                    filtered5.length !== 0 &&
                    filtered5[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered5[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered5[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered5[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered5[0].report_name,
                          std: filtered5[0].std_wise_array[i].std,
                          school:
                            filtered5[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered5[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered6 = response.data.filter((user) =>
                    user.report_name.includes("Sit Ups in 30 sec")
                  );
                  set6SNList(filtered6[0].report_name);

                  if (
                    filtered6.length !== 0 &&
                    filtered6[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered6[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered6[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered6[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered6[0].report_name,
                          std: filtered6[0].std_wise_array[i].std,
                          school:
                            filtered6[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered6[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filtered7 = response.data.filter((user) =>
                    user.report_name.includes("Sit Ups in 1 min")
                  );
                  set7SNList(filtered7[0].report_name);

                  if (
                    filtered7.length !== 0 &&
                    filtered7[0].std_wise_array.length !== 0
                  ) {
                    for (
                      let i = 0;
                      i < filtered7[0].std_wise_array.length;
                      i++
                    ) {
                      if (
                        filtered7[0].std_wise_array[i].skl_vs_nat_avg[0]
                          .scl_avg_score !== 0 ||
                        filtered7[0].std_wise_array[i].skl_vs_nat_avg[1]
                          .nat_avg_score !== 0
                      ) {
                        arr.push({
                          report_name: filtered7[0].report_name,
                          std: filtered7[0].std_wise_array[i].std,
                          school:
                            filtered7[0].std_wise_array[i].skl_vs_nat_avg[0]
                              .scl_avg_score,
                          national:
                            filtered7[0].std_wise_array[i].skl_vs_nat_avg[1]
                              .nat_avg_score,
                        });
                      }
                    }
                  }

                  const filteredover = response.data.filter((user) =>
                    user.report_name.includes("Overall Report")
                  );
                  setSNOver(filteredover[0].report_name);

                  if (
                    filteredover.length !== 0 &&
                    filteredover[0].skl_vs_nat_avg_overall.length !== 0
                  ) {
                    if (
                      filteredover[0].skl_vs_nat_avg_overall[0]
                        .scl_avg_score !== 0 ||
                      filteredover[0].skl_vs_nat_avg_overall[1]
                        .nat_avg_score !== 0
                    ) {
                      arr.push({
                        report_name: filteredover[0].report_name,
                        std: "",
                        school:
                          filteredover[0].skl_vs_nat_avg_overall[0]
                            .scl_avg_score,
                        national:
                          filteredover[0].skl_vs_nat_avg_overall[1]
                            .nat_avg_score,
                      });
                    }
                  }

                  const fdata = arr.filter((user) =>
                    user.report_name.includes("30m run")
                  );
                  if (fdata.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < fdata.length; i++) {
                      stan.push(fdata[i].std);
                      sdata.push(fdata[i].school);
                      ndata.push(fdata[i].national);
                    }
                    set1Std(stan);
                    set1School(sdata);
                    set1National(ndata);
                  }

                  const f2data = arr.filter((user) =>
                    user.report_name.includes("Beep Test")
                  );
                  if (f2data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f2data.length; i++) {
                      stan.push(f2data[i].std);
                      sdata.push(f2data[i].school);
                      ndata.push(f2data[i].national);
                    }
                    set2Std(stan);
                    set2School(sdata);
                    set2National(ndata);
                  }

                  const f3data = arr.filter((user) =>
                    user.report_name.includes("Medicine Ball Throw")
                  );
                  if (f3data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f3data.length; i++) {
                      stan.push(f3data[i].std);
                      sdata.push(f3data[i].school);
                      ndata.push(f3data[i].national);
                    }
                    set3Std(stan);
                    set3School(sdata);
                    set3National(ndata);
                  }

                  const f4data = arr.filter((user) =>
                    user.report_name.includes("Sit & Reach (in cm.)")
                  );
                  if (f4data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f4data.length; i++) {
                      stan.push(f4data[i].std);
                      sdata.push(f4data[i].school);
                      ndata.push(f4data[i].national);
                    }
                    set4Std(stan);
                    set4School(sdata);
                    set4National(ndata);
                  }

                  const f5data = arr.filter((user) =>
                    user.report_name.includes("Standing Broad Jump")
                  );
                  if (f5data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f5data.length; i++) {
                      stan.push(f5data[i].std);
                      sdata.push(f5data[i].school);
                      ndata.push(f5data[i].national);
                    }
                    set5Std(stan);
                    set5School(sdata);
                    set5National(ndata);
                  }

                  const f6data = arr.filter((user) =>
                    user.report_name.includes("Sit Ups in 30 sec")
                  );
                  if (f6data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f6data.length; i++) {
                      stan.push(f6data[i].std);
                      sdata.push(f6data[i].school);
                      ndata.push(f6data[i].national);
                    }
                    set6Std(stan);
                    set6School(sdata);
                    set6National(ndata);
                  }

                  const f7data = arr.filter((user) =>
                    user.report_name.includes("Sit Ups in 1 min")
                  );
                  if (f7data.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < f7data.length; i++) {
                      stan.push(f7data[i].std);
                      sdata.push(f7data[i].school);
                      ndata.push(f7data[i].national);
                    }
                    set7Std(stan);
                    set7School(sdata);
                    set7National(ndata);
                  }

                  const fodata = arr.filter((user) =>
                    user.report_name.includes("Overall Report")
                  );
                  if (fodata.length !== 0) {
                    var stan = [];
                    var sdata = [];
                    var ndata = [];
                    for (let i = 0; i < fodata.length; i++) {
                      sdata.push(fodata[i].school);
                      ndata.push(fodata[i].national);
                    }
                    setOverSchool(sdata);
                    setOverNational(ndata);
                  }
                  // console.log('End',new Date().toLocaleString())
                  setLoading(false);
                }
              }
            });
          }
        });
        // const temp = new FormData();
        // temp.append("school_id", res.data.school_id);
        // scheadschoolvsnational(temp).then((response) => {
        //   if (response.status === true) {
        //     if (response.data.length !== 0) {
        //       if (response.data[0].status === "failed") {
        //         set1SNList("");
        //         set2SNList("");
        //         set3SNList("");
        //         set4SNList("");
        //         set5SNList("");
        //         set6SNList("");
        //       } else {
        //         arr = [];
        //         const dfor = response.data.find((dw) => dw.report_date !== "");

        //         setDateFormat(dfor.report_date);
        //         const filtered = response.data.filter((user) =>
        //           user.report_name.includes("30m run")
        //         );
        //         set1SNList(filtered[0].report_name);

        //         if (
        //           filtered.length !== 0 &&
        //           filtered[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered[0].std_wise_array.length; i++) {
        //             if (
        //               filtered[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered[0].std_wise_array[i].std === "S2") {
        //                 navg = 7.903659505;
        //               } else if (filtered[0].std_wise_array[i].std === "S3") {
        //                 navg = 7.541496746;
        //               } else if (filtered[0].std_wise_array[i].std === "S4") {
        //                 navg = 7.327605955;
        //               } else if (filtered[0].std_wise_array[i].std === "S5") {
        //                 navg = 7.242432469;
        //               } else if (filtered[0].std_wise_array[i].std === "S6") {
        //                 navg = 6.804180772;
        //               } else if (filtered[0].std_wise_array[i].std === "S7") {
        //                 navg = 6.639932399;
        //               } else if (filtered[0].std_wise_array[i].std === "S8") {
        //                 navg = 6.573495702;
        //               } else if (filtered[0].std_wise_array[i].std === "S9") {
        //                 navg = 6.459183449;
        //               } else if (filtered[0].std_wise_array[i].std === "S10") {
        //                 navg = 6.353030534;
        //               } else if (filtered[0].std_wise_array[i].std === "S11") {
        //                 navg = 6.651348066;
        //               } else if (filtered[0].std_wise_array[i].std === "S12") {
        //                 navg = 6.579534884;
        //               }
        //               arr.push({
        //                 report_name: filtered[0].report_name,
        //                 std: filtered[0].std_wise_array[i].std,
        //                 school:
        //                   filtered[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered2 = response.data.filter((user) =>
        //           user.report_name.includes("Beep Test")
        //         );
        //         set2SNList(filtered2[0].report_name);

        //         if (
        //           filtered2.length !== 0 &&
        //           filtered2[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered2[0].std_wise_array.length; i++) {
        //             if (
        //               filtered2[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered2[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered2[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered2[0].std_wise_array[i].std === "S2") {
        //                 navg = 23;
        //               } else if (filtered2[0].std_wise_array[i].std === "S3") {
        //                 navg = 14;
        //               } else if (filtered2[0].std_wise_array[i].std === "S4") {
        //                 navg = 16;
        //               } else if (filtered2[0].std_wise_array[i].std === "S5") {
        //                 navg = 17;
        //               } else if (filtered2[0].std_wise_array[i].std === "S6") {
        //                 navg = 18;
        //               } else if (filtered2[0].std_wise_array[i].std === "S7") {
        //                 navg = 19;
        //               } else if (filtered2[0].std_wise_array[i].std === "S8") {
        //                 navg = 21;
        //               } else if (filtered2[0].std_wise_array[i].std === "S9") {
        //                 navg = 22;
        //               } else if (filtered2[0].std_wise_array[i].std === "S10") {
        //                 navg = 22;
        //               } else if (filtered2[0].std_wise_array[i].std === "S11") {
        //                 navg = 18;
        //               } else if (filtered2[0].std_wise_array[i].std === "S12") {
        //                 navg = 19;
        //               }
        //               arr.push({
        //                 report_name: filtered2[0].report_name,
        //                 std: filtered2[0].std_wise_array[i].std,
        //                 school:
        //                   filtered2[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered3 = response.data.filter((user) =>
        //           user.report_name.includes("Medicine Ball Throw")
        //         );
        //         set3SNList(filtered3[0].report_name);

        //         if (
        //           filtered3.length !== 0 &&
        //           filtered3[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered3[0].std_wise_array.length; i++) {
        //             if (
        //               filtered3[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered3[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered3[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered3[0].std_wise_array[i].std === "S2") {
        //                 navg = 2.552705117;
        //               } else if (filtered3[0].std_wise_array[i].std === "S3") {
        //                 navg = 3.014425373;
        //               } else if (filtered3[0].std_wise_array[i].std === "S4") {
        //                 navg = 3.401474677;
        //               } else if (filtered3[0].std_wise_array[i].std === "S5") {
        //                 navg = 3.851890487;
        //               } else if (filtered3[0].std_wise_array[i].std === "S6") {
        //                 navg = 3.523579586;
        //               } else if (filtered3[0].std_wise_array[i].std === "S7") {
        //                 navg = 3.882900149;
        //               } else if (filtered3[0].std_wise_array[i].std === "S8") {
        //                 navg = 4.26778489;
        //               } else if (filtered3[0].std_wise_array[i].std === "S9") {
        //                 navg = 4.816153483;
        //               } else if (filtered3[0].std_wise_array[i].std === "S10") {
        //                 navg = 4.58220329;
        //               } else if (filtered3[0].std_wise_array[i].std === "S11") {
        //                 navg = 4.494607403;
        //               } else if (filtered3[0].std_wise_array[i].std === "S12") {
        //                 navg = 4.329388451;
        //               }
        //               arr.push({
        //                 report_name: filtered3[0].report_name,
        //                 std: filtered3[0].std_wise_array[i].std,
        //                 school:
        //                   filtered3[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered4 = response.data.filter((user) =>
        //           user.report_name.includes("Sit & Reach")
        //         );
        //         set4SNList(filtered4[0].report_name);

        //         if (
        //           filtered4.length !== 0 &&
        //           filtered4[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered4[0].std_wise_array.length; i++) {
        //             if (
        //               filtered4[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered4[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered4[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered4[0].std_wise_array[i].std === "S2") {
        //                 navg = 21.34056484;
        //               } else if (filtered4[0].std_wise_array[i].std === "S3") {
        //                 navg = 21.00978183;
        //               } else if (filtered4[0].std_wise_array[i].std === "S4") {
        //                 navg = 20.80281765;
        //               } else if (filtered4[0].std_wise_array[i].std === "S5") {
        //                 navg = 20.74795773;
        //               } else if (filtered4[0].std_wise_array[i].std === "S6") {
        //                 navg = 21.50068134;
        //               } else if (filtered4[0].std_wise_array[i].std === "S7") {
        //                 navg = 22.28101121;
        //               } else if (filtered4[0].std_wise_array[i].std === "S8") {
        //                 navg = 22.7385585;
        //               } else if (filtered4[0].std_wise_array[i].std === "S9") {
        //                 navg = 22.72536656;
        //               } else if (filtered4[0].std_wise_array[i].std === "S10") {
        //                 navg = 22.97822765;
        //               } else if (filtered4[0].std_wise_array[i].std === "S11") {
        //                 navg = 22.91208791;
        //               } else if (filtered4[0].std_wise_array[i].std === "S12") {
        //                 navg = 23.28056319;
        //               }
        //               arr.push({
        //                 report_name: filtered4[0].report_name,
        //                 std: filtered4[0].std_wise_array[i].std,
        //                 school:
        //                   filtered4[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered5 = response.data.filter((user) =>
        //           user.report_name.includes("Standing Broad Jump")
        //         );
        //         set5SNList(filtered5[0].report_name);

        //         if (
        //           filtered5.length !== 0 &&
        //           filtered5[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered5[0].std_wise_array.length; i++) {
        //             if (
        //               filtered5[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered5[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered5[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered5[0].std_wise_array[i].std === "S2") {
        //                 navg = 0.990155097;
        //               } else if (filtered5[0].std_wise_array[i].std === "S3") {
        //                 navg = 1.06259226;
        //               } else if (filtered5[0].std_wise_array[i].std === "S4") {
        //                 navg = 1.131092528;
        //               } else if (filtered5[0].std_wise_array[i].std === "S5") {
        //                 navg = 1.181311986;
        //               } else if (filtered5[0].std_wise_array[i].std === "S6") {
        //                 navg = 1.267392112;
        //               } else if (filtered5[0].std_wise_array[i].std === "S7") {
        //                 navg = 1.331500897;
        //               } else if (filtered5[0].std_wise_array[i].std === "S8") {
        //                 navg = 1.379912129;
        //               } else if (filtered5[0].std_wise_array[i].std === "S9") {
        //                 navg = 1.415344854;
        //               } else if (filtered5[0].std_wise_array[i].std === "S10") {
        //                 navg = 1.413969231;
        //               } else if (filtered5[0].std_wise_array[i].std === "S11") {
        //                 navg = 1.341886792;
        //               } else if (filtered5[0].std_wise_array[i].std === "S12") {
        //                 navg = 1.350054274;
        //               }
        //               arr.push({
        //                 report_name: filtered5[0].report_name,
        //                 std: filtered5[0].std_wise_array[i].std,
        //                 school:
        //                   filtered5[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered6 = response.data.filter((user) =>
        //           user.report_name.includes("Sit Ups in 30 sec")
        //         );
        //         set6SNList(filtered6[0].report_name);

        //         if (
        //           filtered6.length !== 0 &&
        //           filtered6[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered6[0].std_wise_array.length; i++) {
        //             if (
        //               filtered6[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered6[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered6[0].std_wise_array[i].std === "S1") {
        //                 navg = 0;
        //               } else if (filtered6[0].std_wise_array[i].std === "S2") {
        //                 navg = 12;
        //               } else if (filtered6[0].std_wise_array[i].std === "S3") {
        //                 navg = 13;
        //               } else if (filtered6[0].std_wise_array[i].std === "S4") {
        //                 navg = 15;
        //               } else if (filtered6[0].std_wise_array[i].std === "S5") {
        //                 navg = 15;
        //               }
        //               arr.push({
        //                 report_name: filtered6[0].report_name,
        //                 std: filtered6[0].std_wise_array[i].std,
        //                 school:
        //                   filtered6[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filtered7 = response.data.filter((user) =>
        //           user.report_name.includes("Sit Ups in 1 min")
        //         );
        //         set7SNList(filtered7[0].report_name);

        //         if (
        //           filtered7.length !== 0 &&
        //           filtered7[0].std_wise_array.length !== 0
        //         ) {
        //           for (let i = 0; i < filtered7[0].std_wise_array.length; i++) {
        //             if (
        //               filtered7[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                 .scl_avg_score !== null ||
        //               filtered7[0].std_wise_array[i].skl_vs_nat_avg[1]
        //                 .nat_avg_score !== null
        //             ) {
        //               var navg = 0;
        //               if (filtered7[0].std_wise_array[i].std === "S2") {
        //                 navg = 19;
        //               } else if (filtered7[0].std_wise_array[i].std === "S3") {
        //                 navg = 22;
        //               } else if (filtered7[0].std_wise_array[i].std === "S6") {
        //                 navg = 22;
        //               } else if (filtered7[0].std_wise_array[i].std === "S7") {
        //                 navg = 24;
        //               } else if (filtered7[0].std_wise_array[i].std === "S8") {
        //                 navg = 25;
        //               } else if (filtered7[0].std_wise_array[i].std === "S9") {
        //                 navg = 27;
        //               } else if (filtered7[0].std_wise_array[i].std === "S10") {
        //                 navg = 28;
        //               } else if (filtered7[0].std_wise_array[i].std === "S11") {
        //                 navg = 25;
        //               } else if (filtered7[0].std_wise_array[i].std === "S12") {
        //                 navg = 25;
        //               }
        //               arr.push({
        //                 report_name: filtered7[0].report_name,
        //                 std: filtered7[0].std_wise_array[i].std,
        //                 school:
        //                   filtered7[0].std_wise_array[i].skl_vs_nat_avg[0]
        //                     .scl_avg_score,
        //                 national: navg,
        //               });
        //             }
        //           }
        //         }

        //         const filteredover = response.data.filter((user) =>
        //           user.report_name.includes("Overall Report")
        //         );
        //         setSNOver(filteredover[0].report_name);

        //         if (
        //           filteredover.length !== 0 &&
        //           filteredover[0].skl_vs_nat_avg_overall.length !== 0
        //         ) {
        //           if (
        //             filteredover[0].skl_vs_nat_avg_overall[0].scl_avg_score !==
        //               null ||
        //             filteredover[0].skl_vs_nat_avg_overall[1].nat_avg_score !==
        //               null
        //           ) {
        //             arr.push({
        //               report_name: filteredover[0].report_name,
        //               std: "",
        //               school:
        //                 filteredover[0].skl_vs_nat_avg_overall[0].scl_avg_score,
        //               national: navg,
        //             });
        //           }
        //         }
        //       }

        //       const fdata = arr.filter((user) =>
        //         user.report_name.includes("30m run")
        //       );
        //       if (fdata.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < fdata.length; i++) {
        //           stan.push(fdata[i].std);
        //           sdata.push(fdata[i].school);
        //           ndata.push(fdata[i].national);
        //         }
        //         set1Std(stan);
        //         set1School(sdata);
        //         set1National(ndata);
        //       }

        //       const f2data = arr.filter((user) =>
        //         user.report_name.includes("Beep Test")
        //       );
        //       if (f2data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f2data.length; i++) {
        //           stan.push(f2data[i].std);
        //           sdata.push(f2data[i].school);
        //           ndata.push(f2data[i].national);
        //         }
        //         set2Std(stan);
        //         set2School(sdata);
        //         set2National(ndata);
        //       }

        //       const f3data = arr.filter((user) =>
        //         user.report_name.includes("Medicine Ball Throw")
        //       );
        //       if (f3data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f3data.length; i++) {
        //           stan.push(f3data[i].std);
        //           sdata.push(f3data[i].school);
        //           ndata.push(f3data[i].national);
        //         }
        //         set3Std(stan);
        //         set3School(sdata);
        //         set3National(ndata);
        //       }

        //       const f4data = arr.filter((user) =>
        //         user.report_name.includes("Sit & Reach (in cm.)")
        //       );
        //       if (f4data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f4data.length; i++) {
        //           stan.push(f4data[i].std);
        //           sdata.push(f4data[i].school);
        //           ndata.push(f4data[i].national);
        //         }
        //         set4Std(stan);
        //         set4School(sdata);
        //         set4National(ndata);
        //       }

        //       const f5data = arr.filter((user) =>
        //         user.report_name.includes("Standing Broad Jump")
        //       );
        //       if (f5data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f5data.length; i++) {
        //           stan.push(f5data[i].std);
        //           sdata.push(f5data[i].school);
        //           ndata.push(f5data[i].national);
        //         }
        //         set5Std(stan);
        //         set5School(sdata);
        //         set5National(ndata);
        //       }

        //       const f6data = arr.filter((user) =>
        //         user.report_name.includes("Sit Ups in 30 sec")
        //       );
        //       if (f6data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f6data.length; i++) {
        //           stan.push(f6data[i].std);
        //           sdata.push(f6data[i].school);
        //           ndata.push(f6data[i].national);
        //         }
        //         set6Std(stan);
        //         set6School(sdata);
        //         set6National(ndata);
        //       }

        //       const f7data = arr.filter((user) =>
        //         user.report_name.includes("Sit Ups in 1 min")
        //       );
        //       if (f7data.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < f7data.length; i++) {
        //           stan.push(f7data[i].std);
        //           sdata.push(f7data[i].school);
        //           ndata.push(f7data[i].national);
        //         }
        //         set7Std(stan);
        //         set7School(sdata);
        //         set7National(ndata);
        //       }

        //       const fodata = arr.filter((user) =>
        //         user.report_name.includes("Overall Report")
        //       );
        //       if (fodata.length !== 0) {
        //         var stan = [];
        //         var sdata = [];
        //         var ndata = [];
        //         for (let i = 0; i < fodata.length; i++) {
        //           sdata.push(fodata[i].school);
        //           ndata.push(fodata[i].national);
        //         }
        //         setOverSchool(sdata);
        //         setOverNational(ndata);
        //       }

        //       setLoading(false);
        //     }
        //   }
        // });
      }
    });
  }, []);

  const downloadPDF = () => {
    dotracking(
      "download PDF button in School Vs National $$ " + trackobj.schnatheading
    );

    const content = document.getElementById("pdf-content");

    if (content) {
      html2canvas(content, { scrollY: -window.scrollY }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 296;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 10;
        pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 10;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("School Vs National.pdf");
      });
    }
  };

  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">School Vs National</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Student Fitness</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            School Vs National
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}

      {!loading && (
        <>
          {snlist1 !== "" &&
            snlist2 !== "" &&
            snlist3 !== "" &&
            snlist4 !== "" &&
            snlist5 !== "" &&
            snlist6 !== "" && (
              <div className="col-12 mt-2">
                <div className="text-right">
                  <a className="btn btn-secondary" onClick={downloadPDF}>
                    <i className="fa fa-file-pdf"></i> Download PDF
                  </a>
                </div>
              </div>
            )}

          <div id="pdf-content">
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <h3>
                      Health Parameters Comparison of School Average Vs National
                      Average ({datefor} Assessment)
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {snlist1 === "" &&
              snlist2 === "" &&
              snlist3 === "" &&
              snlist4 === "" &&
              snlist5 === "" &&
              snlist6 === "" && (
                <div className="card mt-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="mb-2 text-success text-danger">
                          School Vs National will be updated soon
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              {snlist1 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <h4 className="text-center">
                        Note: The higher the fitness level of the child, the
                        lesser the number of seconds to complete the run.
                      </h4>
                      <Bar
                        data={{
                          labels: std1,
                          datasets: [
                            {
                              label: schname,
                              data: school1,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national1,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "30m Run(in seconds) School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average of 30m Run(in seconds)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist2 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: std2,
                          datasets: [
                            {
                              label: schname,
                              data: school2,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national2,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Beep Test (number of Laps) School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average of Beep Test (number of Laps)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist3 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: std3,
                          datasets: [
                            {
                              label: schname,
                              data: school3,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national3,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Medicine Ball throw (in meters) School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average of Medicine Ball throw (in meters)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist6 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <h4 className="text-center">
                        Note: Assessment held only for S4 and S5.
                      </h4>
                      <Bar
                        data={{
                          labels: std6,
                          datasets: [
                            {
                              label: schname,
                              data: school6,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national6,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Number of Sit Ups in 30 seconds School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average Number of Sit Ups in 30 seconds",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist7 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <h4 className="text-center">
                        Note: Assessment held for S6 and over.
                      </h4>

                      <Bar
                        data={{
                          labels: std7,
                          datasets: [
                            {
                              label: schname,
                              data: school7,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national7,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Number of Sit Ups in 1 Minute School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average Number of Sit Ups in 1 Minute",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist4 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-0"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: std4,
                          datasets: [
                            {
                              label: schname,
                              data: school4,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national4,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Sit and Reach (in cm.) School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average of Sit and Reach (in cm.)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {snlist5 !== "" && (
                <div className="col-md-12">
                  <div
                    className="card mt-8"
                    style={{ height: "410px", width: "100%" }}
                  >
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: std5,
                          datasets: [
                            {
                              label: schname,
                              data: school5,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              data: national5,
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "Standing Broad Jump (in meters) School Average Vs National Average",
                            },
                          },
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: "Standard",
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: "Average of Standing Broad Jump (in meters)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* {snlistover !== "" && (
            <>
              <hr />

              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <div className="card-body">
                      <Bar
                        data={{
                          labels: [""],
                          datasets: [
                            {
                              label: schname,
                              data: schoolover,
                              backgroundColor: "#25e3da",
                            },
                            {
                              label: "National Average",
                              // data: nationalover,
                              data: [0.73],
                              backgroundColor: "#2977ff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: snlistover,
                            },
                          },
                          scales: {
                            y: {
                              title: {
                                display: true,
                                text: "Average (in sec)",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
          </div>
        </>
      )}
    </div>
  );
};

// if (response.data.length !== 0) {
//   if (response.data[0].status === "failed") {
//     set1SNList("");
//     set2SNList("");
//     set3SNList("");
//     set4SNList("");
//     set5SNList("");
//     set6SNList("");
//   } else {
//     arr = [];

//     for (let i = 0; i < response.data.length; i++) {
//       if (response.data[i].report_name !== "Overall Report") {
//         if (response.data[i].std_wise_array.length !== 0) {
//           for (
//             let j = 0;
//             j < response.data[i].std_wise_array.length;
//             j++
//           ) {
//             if (
//               response.data[i].std_wise_array[j].skl_vs_nat_avg
//                 .length !== 0
//             ) {
//               if (
//                 response.data[i].std_wise_array[j].skl_vs_nat_avg[0]
//                   .scl_avg_score !== null ||
//                 response.data[i].std_wise_array[j].skl_vs_nat_avg[1]
//                   .nat_avg_score !== null
//               ) {
//                 var navg = 0;
//                 if (
//                   response.data[i].std_wise_array[j]
//                     .skl_vs_nat_avg[1].nat_avg_score !== null
//                 ) {
//                   navg =
//                     response.data[i].std_wise_array[j]
//                       .skl_vs_nat_avg[1].nat_avg_score;
//                 }

//                 arr.push({
//                   report_name: response.data[i].report_name,
//                   std: response.data[i].std_wise_array[j].std,
//                   school:
//                     response.data[i].std_wise_array[j]
//                       .skl_vs_nat_avg[0].scl_avg_score,
//                   national: navg,
//                 });
//               }
//             }
//           }
//         }
//       } else {
//         if (response.data[i].skl_vs_nat_avg_overall.length !== 0) {
//           if (
//             response.data[i].skl_vs_nat_avg_overall[0]
//               .scl_avg_score !== null ||
//             response.data[i].skl_vs_nat_avg_overall[1]
//               .nat_avg_score !== null
//           ) {
//             var navg = 0;
//             if (
//               response.data[i].skl_vs_nat_avg_overall[1]
//                 .nat_avg_score !== null
//             ) {
//               navg =
//                 response.data[i].skl_vs_nat_avg_overall[1]
//                   .nat_avg_score;
//             }
//             arr.push({
//               report_name: response.data[i].report_name,
//               std: "",
//               school:
//                 response.data[i].skl_vs_nat_avg_overall[0]
//                   .scl_avg_score,
//               national: navg,
//             });
//           }
//         }
//       }
//     }

//     const filtered = arr.filter((user) =>
//       user.report_name.includes("30m run (in sec.)")
//     );
//     if (filtered.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered.length; i++) {
//         stan.push(filtered[i].std);
//         sdata.push(filtered[i].school);
//         ndata.push(filtered[i].national);
//       }
//       set1SNList(filtered[0].report_name);
//       set1Std(stan);
//       set1School(sdata);
//       set1National(ndata);
//     }
//     const filtered2 = arr.filter((user) =>
//       user.report_name.includes("Beep Test (in #laps)")
//     );
//     if (filtered2.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered2.length; i++) {
//         stan.push(filtered2[i].std);
//         sdata.push(filtered2[i].school);
//         ndata.push(filtered2[i].national);
//       }
//       set2SNList(filtered2[0].report_name);
//       set2Std(stan);
//       set2School(sdata);
//       set2National(ndata);
//     }

//     const filtered3 = arr.filter((user) =>
//       user.report_name.includes("Medicine Ball Throw (in m.)")
//     );
//     if (filtered3.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered3.length; i++) {
//         stan.push(filtered3[i].std);
//         sdata.push(filtered3[i].school);
//         ndata.push(filtered3[i].national);
//       }
//       set3SNList(filtered3[0].report_name);
//       set3Std(stan);
//       set3School(sdata);
//       set3National(ndata);
//     }

//     const filtered4 = arr.filter((user) =>
//       user.report_name.includes("Sit & Reach (in cm.)")
//     );
//     if (filtered4.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered4.length; i++) {
//         stan.push(filtered4[i].std);
//         sdata.push(filtered4[i].school);
//         ndata.push(filtered4[i].national);
//       }
//       set4SNList(filtered4[0].report_name);
//       set4Std(stan);
//       set4School(sdata);
//       set4National(ndata);
//     }

//     const filtered5 = arr.filter((user) =>
//       user.report_name.includes("Standing Broad Jump (in m.)")
//     );
//     if (filtered5.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered5.length; i++) {
//         stan.push(filtered5[i].std);
//         sdata.push(filtered5[i].school);
//         ndata.push(filtered5[i].national);
//       }
//       set5SNList(filtered5[0].report_name);
//       set5Std(stan);
//       set5School(sdata);
//       set5National(ndata);
//     }

//     const filtered6 = arr.filter((user) =>
//       user.report_name.includes("No. of Sit Ups in 30 sec.")
//     );
//     if (filtered6.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filtered6.length; i++) {
//         stan.push(filtered6[i].std);
//         sdata.push(filtered6[i].school);
//         ndata.push(filtered6[i].national);
//       }
//       set6SNList(filtered6[0].report_name);
//       set6Std(stan);
//       set6School(sdata);
//       set6National(ndata);
//     }

//     const filteredover = arr.filter((user) =>
//       user.report_name.includes("Overall Report")
//     );
//     if (filteredover.length !== 0) {
//       var stan = [];
//       var sdata = [];
//       var ndata = [];
//       for (let i = 0; i < filteredover.length; i++) {
//         sdata.push(filteredover[i].school);
//         ndata.push(filteredover[i].national);
//       }
//       setSNOver(filteredover[0].report_name);
//       setOverSchool(sdata);
//       setOverNational(ndata);
//     }
//   }
//   setLoading(false);
// }
