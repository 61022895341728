import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { schprofileview } from "../../../../services/schoolhead";
import { gettokenfromapi } from "../../../../services/superadmin";
import {
  sahassessmentdatelist,
  schoolteamplayeryesorno,
} from "../../../../services/sahapi";
export const FitBottomList = () => {
  const [flist, setFList] = useState([]);
  const [s3path, setS3Path] = useState([]);

  const [loading, setLoading] = useState(false);

  let arr = [];
  useEffect(() => {
    setLoading(true);
    schprofileview().then((res) => {
      if (res.status === true) {
        const temp = new FormData();
        temp.append(
          "data_req",
          "fittest_kids_list_bottom_10" + res.data.school_unique_no
        );
        gettokenfromapi(temp).then((res2) => {
          if (res2.status === true) {
            sahassessmentdatelist(
              "fittest_kids_list_bottom_10&chid=" + res.data.school_unique_no,
              res2.data
            ).then((response) => {
              if (response.status === true) {
                let arr = [];
                
                for (let i = 0; i < response.data.length; i++) {
                  if (
                    response.data[i].overall_fq_male.length !== 0 &&
                    response.data[i].overall_fq_female.length !== 0
                  ) {
                    arr.push({
                      report_name: response.data[i].report_name + " (Male)",
                      overall: [],
                    });
                    arr.push({
                      report_name: response.data[i].report_name + " (Female)",
                      overall: [],
                    });
                  }

                  for (let j = 0; j < arr.length; j++) {
                    let arr1 = [];
                    if (
                      arr[j].report_name ===
                      response.data[i].report_name + " (Male)"
                    ) {
                      if (response.data[i].overall_fq_male.length > 0) {
                        for (
                          let j = 0;
                          j < response.data[i].overall_fq_male.length;
                          j++
                        ) {
                          arr1.push({
                            attribute_fq:
                              response.data[i].overall_fq_male[j].attribute_fq,
                            user_id:
                              response.data[i].overall_fq_male[j].user_id,
                            username:
                              response.data[i].overall_fq_male[j].username,
                            section:
                              response.data[i].overall_fq_male[j].section,
                            team: [],
                            tlimit: "",
                          });
                        }

                        arr[j].overall = arr1;
                        setFList([...arr]);
                      }
                    }

                    let arr2 = [];
                    if (
                      arr[j].report_name ===
                      response.data[i].report_name + " (Female)"
                    ) {
                      if (response.data[i].overall_fq_female.length > 0) {
                        for (
                          let j = 0;
                          j < response.data[i].overall_fq_female.length;
                          j++
                        ) {
                          arr2.push({
                            attribute_fq:
                              response.data[i].overall_fq_female[j]
                                .attribute_fq,
                            user_id:
                              response.data[i].overall_fq_female[j].user_id,
                            username:
                              response.data[i].overall_fq_female[j].username,
                            section:
                              response.data[i].overall_fq_female[j].section,
                            team: [],
                            tlimit: "",
                          });
                        }
                        arr[j].overall = arr2;
                        setFList([...arr]);
                      }
                    }
                  }
                }

                if (arr.length !== 0) {
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].overall.length !== 0) {
                      for (let j = 0; j < arr[i].overall.length; j++) {
                        schoolteamplayeryesorno(arr[i].overall[j].user_id).then(
                          (res1) => {
                            if (
                              res1.status === true &&
                              res1.data.medal_count_with_sports.length > 0
                            ) {
                              setS3Path(res1.data.s3);
                              let sdata = [];
                              sdata = res1.data.medal_count_with_sports.filter(
                                (item) => {
                                  return item.icon !== null;
                                }
                              );
                              arr[i].overall[j].tlimit = res1.message;
                              arr[i].overall[j].team = sdata;
                              setFList([...arr]);
                            }
                          }
                        );
                      }
                    }
                  }
                }
                setLoading(false);
              }
            });
          }
        });
      }
    });
  }, []);

 
  return (
    <div className="side-app">
      <div className="page-header">
        <div className="breadcrumb">
          <h4 className="mb-0">Fittest Kids - Bottom 10 List</h4>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a>Student Fitness</a>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Fittest Kids
          </li>
        </ol>
      </div>

      {loading && (
        <div className="dashloader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}

      {!loading && (
        <div className="card mt-0">
         
          <div className="card-body" id="pdf-content">
            <div className="row">
              {flist.length === 0 && (
                <div className="col-md-12">
                  <h3 className="mb-2 text-success text-danger">
                    Fitness will be updated soon
                  </h3>
                </div>
              )}
              {flist.length !== 0 && (
                <>
                  {flist.map((data, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        <h4 className="mb-2 text-success text-center">
                          {data.report_name}
                        </h4>
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>Student Name</th>
                              <th>Section</th>
                              <th>Overall FQ</th>
                              <th>Sports</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.overall.map((item, j) => {
                              return (
                                <tr key={j}>
                                  <td>
                                    {item.username}
                                    {item.team.length !== 0 && <br />}

                                    {item.tlimit ===
                                      "School Heads Displayed" && (
                                      <>
                                        <p className="badge bg-primary fw-bold badges text-wrap">
                                          In School Team
                                        </p>
                                      </>
                                    )}
                                  </td>
                                  <td>{item.section} </td>
                                  <td>{item.attribute_fq} </td>
                                  <td>
                                    {" "}
                                    <div className="bagdeicon">
                                      {item.team.map((data1, k) => {
                                        return (
                                          <img
                                            src={s3path + data1.icon}
                                            key={k}
                                            title={
                                              data1.sports_name +
                                              " - " +
                                              data1.total_medals +
                                              " Medal(s)"
                                            }
                                            height={30}
                                            width={30}
                                          />
                                        );
                                      })}
                                    </div>{" "}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <hr />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
