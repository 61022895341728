import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react'
import { emailkeywordlist, emailtemplates, emailtemplatesdetails } from '../../../../../services/superadmin';
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import dotracking from '../../../../trackinglogs';
import trackobj from "../../../../trackingpages.json";

export const TourRemainder = () => {


    const [keylist, setKeyList] = useState([])

    const [content, setContent] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [subj, setSubj] = useState("")

    function toursubmit() {
        if (subj !== "") {
            if (content !== "") {
                const temp = new FormData();
                temp.append("admin_id", localStorage.getItem("UID"))
                temp.append("template_id", 12)
                temp.append("content", content)
                temp.append("status", 1)
                temp.append("subject", subj)
                temp.append("image_or_video", selectedFile);
                dotracking("submit button in email tournament reminder templates $$ " + trackobj.tourremtempheading+ trackobj.emailtemp)
                emailtemplates(temp).then((response) => {
                    if (response.status === true) {
                        toast.success(response.message)
                        listall()
                    } else {
                        setErrorMsg(response.message)
                    }
                })
            } else {
                setErrorMsg("Please Enter the text")
            }
        } else {
            setErrorMsg("Please Enter Subject")
        }
    }

    function listall() {
        emailtemplatesdetails(12).then((response) => {
            if (response.status === true) {
                if (response.data.template_info !== null) {
                    if (response.data.template_info.subject !== null) {
                        setSubj(response.data.template_info.subject);
                      }
                    if(response.data.template_info.message_content !== null){
                        setContent(response.data.template_info.message_content);
                      }
                }
            }
        })

        
    }
    const [selectedFile, setSelectedFile] = useState("");
    const handleFileChange = (event) => {
        setSelectedFile("")
        if (event.target.files.length !== 0) {
          setSelectedFile(event.target.files[0]);
        }
      };

      
    useEffect(() => {
        listall()
        emailkeywordlist(12).then((response) => {
            if (response.status === true) {
                setKeyList(response.data)
            }
        })
    }, [])
    return (
        <div className="side-app">
            <div className="page-header">
                <div className="breadcrumb">
                    <h4 className="mb-0">Templates</h4>
                </div>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a>Templates</a>
                    </li>
                    <li className="breadcrumb-item">
                        <a>Email</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Tournament Reminder
                    </li>
                </ol>
            </div>



            <div className="row"> 
                <div className="col-12">
                    <form className="card mt-0">
                        <div className="card-body">
                            <h4>Tournament Reminder Email </h4>
                            <div className="table-responsive">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Keyword</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keylist.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.keywords} </td>
                          <td>{data.description} </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>


                            <hr />
                            <div className='row mt-2'>
                                <div className="form-group">
                                    <label className="form-label"> Subject <span className='errortext'> *</span></label>
                                    <input type="text" className="form-control" placeholder='Enter Subject' value={subj} onChange={(e) => setSubj(e.target.value)} />
                                </div>
                            </div>
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                }}

                                // config={editorConfiguration}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'blockQuote', 'link', 'outdent',
                                        'indent', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        '|', 'undo', 'redo', 'specialCharacters',
                                    ],
                                    fontFamily: {
                                        options: [
                                            'default',
                                            'Arial, Helvetica, sans-serif',
                                            'Courier New, Courier, monospace',
                                            'Georgia, serif',
                                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                            'Tahoma, Geneva, sans-serif',
                                            'Times New Roman, Times, serif',
                                            'Trebuchet MS, Helvetica, sans-serif',
                                            'Verdana, Geneva, sans-serif'
                                        ],
                                        supportAllValues: true
                                    },
                                }}
                            />
                            <hr />

<div className="form-group">
  <label className="form-label">
    {" "}
    Upload File 
  </label>
  <input
    type="file"
    // value={selectedFile}
    className="form-control"
    onChange={handleFileChange}
  />
</div>

                            {errorMsg !== "" &&
                                <span className='errortext'>{errorMsg}</span>
                            }
                            <hr />
                            <div className='text-right'>
                                <a className="btn btn-success" onClick={toursubmit}>Submit</a>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
